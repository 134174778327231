export const AR = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3897, 35.9647],
            [-90.3779, 35.9957],
            [-90.195, 35.9972],
            [-90.1194, 35.9979],
            [-90.1318, 35.9601],
            [-90.1948, 35.9602],
            [-90.2872, 35.8013],
            [-90.4001, 35.8193],
            [-90.4461, 35.8778],
            [-90.3897, 35.9647],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503710',
        NAME: 'Buffalo Island Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 353398305,
        AWATER: 2287854,
        INTPTLAT: '+35.9110116',
        INTPTLON: '-090.2992056',
        ELSDLEA: '',
        UNSDLEA: '03710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9446, 35.7412],
            [-89.9249, 35.6104],
            [-90, 35.6103],
            [-90.0174, 35.7414],
            [-89.9446, 35.7412],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510950',
        NAME: 'Osceola School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127786948,
        AWATER: 6889784,
        INTPTLAT: '+35.6635207',
        INTPTLON: '-089.9604934',
        ELSDLEA: '',
        UNSDLEA: '10950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2872, 35.8013],
            [-90.1948, 35.9602],
            [-90.1318, 35.9601],
            [-90.1082, 35.8145],
            [-90.1082, 35.7419],
            [-90.213, 35.7574],
            [-90.1774, 35.6553],
            [-90.2867, 35.6999],
            [-90.2872, 35.8013],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500046',
        NAME: 'Manila School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 348768425,
        AWATER: 2093611,
        INTPTLAT: '+35.8077750',
        INTPTLON: '-090.2045131',
        ELSDLEA: '',
        UNSDLEA: '00046',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0266, 34.0638],
            [-91.9536, 34.0934],
            [-91.745, 34.094],
            [-91.7273, 34.1737],
            [-91.6014, 34.132],
            [-91.6069, 34.1123],
            [-91.6045, 34.0427],
            [-91.6984, 33.9087],
            [-91.6447, 33.7907],
            [-91.7687, 33.792],
            [-91.8437, 33.7918],
            [-91.976, 33.7918],
            [-91.976, 33.8899],
            [-92.0266, 34.0638],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500028',
        NAME: 'Star City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1042067577,
        AWATER: 12504441,
        INTPTLAT: '+33.9684358',
        INTPTLON: '-091.8083528',
        ELSDLEA: '',
        UNSDLEA: '00028',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0765, 34.0635],
            [-92.0593, 34.0635],
            [-92.0266, 34.0638],
            [-91.976, 33.8899],
            [-91.976, 33.7918],
            [-91.9758, 33.7044],
            [-92.0375, 33.7044],
            [-92.0277, 33.8355],
            [-92.0753, 33.8626],
            [-92.0765, 34.0635],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '514400',
        NAME: 'Woodlawn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264514358,
        AWATER: 342886,
        INTPTLAT: '+33.8732549',
        INTPTLON: '-092.0322959',
        ELSDLEA: '',
        UNSDLEA: '14400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7473, 35.7051],
            [-90.6438, 35.7048],
            [-90.4667, 35.7053],
            [-90.4647, 35.7051],
            [-90.4658, 35.5719],
            [-90.5736, 35.5216],
            [-90.6073, 35.6196],
            [-90.7473, 35.7051],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500047',
        NAME: 'Trumann Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310438946,
        AWATER: 182699,
        INTPTLAT: '+35.6420632',
        INTPTLON: '-090.5736320',
        ELSDLEA: '',
        UNSDLEA: '00047',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6025, 35.4585],
            [-92.4801, 35.4306],
            [-92.4815, 35.3684],
            [-92.5019, 35.2888],
            [-92.6224, 35.2759],
            [-92.6598, 35.3287],
            [-92.6025, 35.4585],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510410',
        NAME: 'Nemo Vista School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275305271,
        AWATER: 1268233,
        INTPTLAT: '+35.3644082',
        INTPTLON: '-092.5821716',
        ELSDLEA: '',
        UNSDLEA: '10410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5019, 35.2888],
            [-92.4815, 35.3684],
            [-92.4294, 35.3319],
            [-92.3953, 35.2793],
            [-92.2906, 35.2775],
            [-92.2841, 35.1903],
            [-92.2768, 35.1284],
            [-92.286, 35.1292],
            [-92.4345, 35.1573],
            [-92.5076, 35.1367],
            [-92.5297, 35.2283],
            [-92.5019, 35.2888],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506900',
        NAME: 'Greenbrier School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366176276,
        AWATER: 653508,
        INTPTLAT: '+35.2236550',
        INTPTLON: '-092.4031305',
        ELSDLEA: '',
        UNSDLEA: '06900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1386, 36.4186],
            [-91.0939, 36.3719],
            [-90.8382, 36.3393],
            [-90.8066, 36.2669],
            [-90.8091, 36.1789],
            [-90.8824, 36.1505],
            [-91.0828, 36.151],
            [-91.0607, 36.2263],
            [-91.1478, 36.3746],
            [-91.1839, 36.3748],
            [-91.1386, 36.4186],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511610',
        NAME: 'Pocahontas School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 511797645,
        AWATER: 5107397,
        INTPTLAT: '+36.2595408',
        INTPTLON: '-090.9882745',
        ELSDLEA: '',
        UNSDLEA: '11610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1724, 36.4975],
            [-91.1265, 36.4977],
            [-90.9807, 36.4984],
            [-90.7844, 36.4985],
            [-90.8382, 36.3393],
            [-91.0939, 36.3719],
            [-91.1386, 36.4186],
            [-91.1724, 36.4975],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509570',
        NAME: 'Maynard School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 520489326,
        AWATER: 1631055,
        INTPTLAT: '+36.4410471',
        INTPTLON: '-090.9565086',
        ELSDLEA: '',
        UNSDLEA: '09570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2129, 34.2304],
            [-92.1604, 34.2292],
            [-92.0698, 34.2272],
            [-92.0344, 34.2259],
            [-92.0737, 34.1502],
            [-92.0593, 34.0635],
            [-92.0765, 34.0635],
            [-92.2334, 34.0623],
            [-92.2129, 34.2304],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '513930',
        NAME: 'Watson Chapel School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302624003,
        AWATER: 441596,
        INTPTLAT: '+34.1451136',
        INTPTLON: '-092.1455959',
        ELSDLEA: '',
        UNSDLEA: '13930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8382, 36.3393],
            [-90.7844, 36.4985],
            [-90.7262, 36.4986],
            [-90.5762, 36.4985],
            [-90.4315, 36.4984],
            [-90.3708, 36.4983],
            [-90.3715, 36.4202],
            [-90.4442, 36.3476],
            [-90.4269, 36.2596],
            [-90.5932, 36.2652],
            [-90.8066, 36.2669],
            [-90.8382, 36.3393],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500009',
        NAME: 'Corning Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 944756782,
        AWATER: 6112627,
        INTPTLAT: '+36.3710105',
        INTPTLON: '-090.6127243',
        ELSDLEA: '',
        UNSDLEA: '00009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3715, 36.4202],
            [-90.3053, 36.3753],
            [-90.1665, 36.3454],
            [-90.078, 36.2883],
            [-90.0879, 36.2715],
            [-90.1902, 36.2014],
            [-90.321, 36.2006],
            [-90.4269, 36.2596],
            [-90.4442, 36.3476],
            [-90.3715, 36.4202],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504370',
        NAME: 'Rector School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 463605465,
        AWATER: 710710,
        INTPTLAT: '+36.3016307',
        INTPTLON: '-090.2742723',
        ELSDLEA: '',
        UNSDLEA: '04370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-90.8548, 35.9972],
              [-90.8087, 36.0849],
              [-90.6363, 36.0561],
              [-90.4216, 36.089],
              [-90.4208, 36.1181],
              [-90.2356, 36.1417],
              [-90.2485, 36.1308],
              [-90.3779, 35.9957],
              [-90.3897, 35.9647],
              [-90.6874, 35.9519],
              [-90.7192, 35.9522],
              [-90.8521, 35.9681],
              [-90.8548, 35.9972],
            ],
          ],
          [
            [
              [-90.8066, 36.2669],
              [-90.5932, 36.2652],
              [-90.5696, 36.1821],
              [-90.7474, 36.1491],
              [-90.8824, 36.1505],
              [-90.8091, 36.1789],
              [-90.8066, 36.2669],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '513080',
        NAME: 'Greene County Technical School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 891893637,
        AWATER: 4165858,
        INTPTLAT: '+36.0281843',
        INTPTLON: '-090.7179869',
        ELSDLEA: '',
        UNSDLEA: '13080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2515, 34.8229],
            [-92.2334, 34.8205],
            [-92.2201, 34.7454],
            [-92.3308, 34.7874],
            [-92.2515, 34.8229],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510680',
        NAME: 'North Little Rock School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69770285,
        AWATER: 4912427,
        INTPTLAT: '+34.7800016',
        INTPTLON: '-092.2522550',
        ELSDLEA: '',
        UNSDLEA: '10680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2517, 35.3722],
            [-91.0585, 35.3546],
            [-91.0402, 35.2952],
            [-91.0427, 35.1482],
            [-91.0435, 35.104],
            [-91.1318, 35.104],
            [-91.25, 35.1817],
            [-91.2517, 35.3722],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509600',
        NAME: 'McCrory School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 468769609,
        AWATER: 777466,
        INTPTLAT: '+35.2445356',
        INTPTLON: '-091.1440877',
        ELSDLEA: '',
        UNSDLEA: '09600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0215, 35.3348],
            [-92.9761, 35.3775],
            [-92.8708, 35.3292],
            [-92.8543, 35.2561],
            [-92.8961, 35.1707],
            [-93.0165, 35.1647],
            [-93.0304, 35.3125],
            [-93.0215, 35.3348],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502610',
        NAME: 'Atkins Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250902170,
        AWATER: 7714787,
        INTPTLAT: '+35.2628426',
        INTPTLON: '-092.9342647',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.076, 35.7269],
            [-92.9513, 35.7247],
            [-92.8105, 35.6936],
            [-92.8127, 35.5926],
            [-92.8845, 35.565],
            [-92.8506, 35.4629],
            [-92.8708, 35.3292],
            [-92.9761, 35.3775],
            [-93.0945, 35.4517],
            [-93.0453, 35.5093],
            [-93.076, 35.7269],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507620',
        NAME: 'Hector School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 772512098,
        AWATER: 858746,
        INTPTLAT: '+35.6110088',
        INTPTLON: '-092.9109049',
        ELSDLEA: '',
        UNSDLEA: '07620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2801, 35.3898],
            [-93.0215, 35.3348],
            [-93.0304, 35.3125],
            [-93.1469, 35.2207],
            [-93.299, 35.3274],
            [-93.2801, 35.3898],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512060',
        NAME: 'Russellville Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226603364,
        AWATER: 30855534,
        INTPTLAT: '+35.3081371',
        INTPTLON: '-093.1668752',
        ELSDLEA: '',
        UNSDLEA: '12060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1645, 35.7288],
            [-93.076, 35.7269],
            [-93.0453, 35.5093],
            [-93.0945, 35.4517],
            [-92.9761, 35.3775],
            [-93.0215, 35.3348],
            [-93.2801, 35.3898],
            [-93.229, 35.4329],
            [-93.2212, 35.5989],
            [-93.1675, 35.6128],
            [-93.1645, 35.7288],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505430',
        NAME: 'Dover School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 607875609,
        AWATER: 1562436,
        INTPTLAT: '+35.5042527',
        INTPTLON: '-093.1283417',
        ELSDLEA: '',
        UNSDLEA: '05430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8105, 35.6936],
            [-92.6496, 35.7042],
            [-92.5541, 35.79],
            [-92.4318, 35.7887],
            [-92.4317, 35.7015],
            [-92.3858, 35.5845],
            [-92.3156, 35.5514],
            [-92.3159, 35.5369],
            [-92.4801, 35.4306],
            [-92.6025, 35.4585],
            [-92.8506, 35.4629],
            [-92.8845, 35.565],
            [-92.8127, 35.5926],
            [-92.8105, 35.6936],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504410',
        NAME: 'Clinton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1244846074,
        AWATER: 19857432,
        INTPTLAT: '+35.5933103',
        INTPTLON: '-092.5920207',
        ELSDLEA: '',
        UNSDLEA: '04410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3117, 33.5028],
            [-92.2019, 33.4718],
            [-92.2203, 33.5792],
            [-92.1151, 33.5299],
            [-92.0079, 33.5294],
            [-91.9902, 33.3982],
            [-91.9773, 33.302],
            [-92.0365, 33.2685],
            [-92.1375, 33.163],
            [-92.3504, 33.2989],
            [-92.3821, 33.346],
            [-92.3117, 33.5028],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507710',
        NAME: 'Hermitage School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1035871180,
        AWATER: 7361775,
        INTPTLAT: '+33.3689303',
        INTPTLON: '-092.1725620',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3368, 34.0596],
            [-92.2334, 34.0623],
            [-92.0765, 34.0635],
            [-92.0753, 33.8626],
            [-92.0277, 33.8355],
            [-92.0375, 33.7044],
            [-92.2541, 33.7055],
            [-92.3201, 33.6824],
            [-92.3308, 33.708],
            [-92.3382, 33.8325],
            [-92.4756, 33.9727],
            [-92.4724, 34.0381],
            [-92.3368, 34.0596],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500067',
        NAME: 'Cleveland County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1211476375,
        AWATER: 2140682,
        INTPTLAT: '+33.8887674',
        INTPTLON: '-092.2308678',
        ELSDLEA: '',
        UNSDLEA: '00067',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4801, 35.4306],
            [-92.3159, 35.5369],
            [-92.249, 35.5164],
            [-92.3108, 35.4666],
            [-92.3056, 35.3647],
            [-92.4294, 35.3319],
            [-92.4815, 35.3684],
            [-92.4801, 35.4306],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512570',
        NAME: 'South Side-Bee Branch School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 288939197,
        AWATER: 348401,
        INTPTLAT: '+35.4426505',
        INTPTLON: '-092.3774339',
        ELSDLEA: '',
        UNSDLEA: '12570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7074, 36.4316],
            [-92.6378, 36.4618],
            [-92.5336, 36.3507],
            [-92.4693, 36.2534],
            [-92.4259, 36.1783],
            [-92.4105, 36.092],
            [-92.4774, 36.0922],
            [-92.4808, 36.1209],
            [-92.5154, 36.1908],
            [-92.7235, 36.3151],
            [-92.6791, 36.3582],
            [-92.7074, 36.4316],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506150',
        NAME: 'Flippin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339186678,
        AWATER: 28351336,
        INTPTLAT: '+36.2757609',
        INTPTLON: '-092.5762356',
        ELSDLEA: '',
        UNSDLEA: '06150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0568, 35.9587],
            [-92.9422, 35.942],
            [-92.9513, 35.7247],
            [-93.076, 35.7269],
            [-93.1645, 35.7288],
            [-93.3442, 35.7304],
            [-93.4471, 35.7905],
            [-93.4177, 35.9156],
            [-93.1351, 35.9333],
            [-93.0568, 35.9587],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500072',
        NAME: 'Deer/Mount Judea School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1020855817,
        AWATER: 1423894,
        INTPTLAT: '+35.8537270',
        INTPTLON: '-093.1724962',
        ELSDLEA: '',
        UNSDLEA: '00072',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-90.2869, 35.5255],
              [-90.2869, 35.4386],
              [-90.3936, 35.44],
              [-90.3937, 35.44],
              [-90.4013, 35.4933],
              [-90.2869, 35.5255],
            ],
          ],
          [
            [
              [-90.4647, 35.7051],
              [-90.2867, 35.6999],
              [-90.1774, 35.6553],
              [-90.2871, 35.6126],
              [-90.2869, 35.5472],
              [-90.4658, 35.5719],
              [-90.4647, 35.7051],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500048',
        NAME: 'East Poinsett County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 384469156,
        AWATER: 2885745,
        INTPTLAT: '+35.5947273',
        INTPTLON: '-090.3529035',
        ELSDLEA: '',
        UNSDLEA: '00048',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0326, 35.8851],
            [-91.0319, 35.9144],
            [-90.9419, 35.971],
            [-90.8521, 35.9681],
            [-90.7192, 35.9522],
            [-90.7748, 35.8429],
            [-90.8741, 35.8369],
            [-90.9303, 35.7063],
            [-90.9828, 35.7074],
            [-91.037, 35.7084],
            [-91.0326, 35.8851],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504020',
        NAME: 'Westside Consolidated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 538706727,
        AWATER: 2497093,
        INTPTLAT: '+35.8589101',
        INTPTLON: '-090.9067451',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0388, 35.5892],
            [-90.9837, 35.6213],
            [-90.9828, 35.7074],
            [-90.9303, 35.7063],
            [-90.7473, 35.7051],
            [-90.6073, 35.6196],
            [-90.5736, 35.5216],
            [-90.6104, 35.462],
            [-91.0392, 35.4447],
            [-91.0388, 35.5892],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507350',
        NAME: 'Harrisburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 957920471,
        AWATER: 7641796,
        INTPTLAT: '+35.5718528',
        INTPTLON: '-090.8337710',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6109, 35.4436],
            [-90.6104, 35.462],
            [-90.5736, 35.5216],
            [-90.4658, 35.5719],
            [-90.2869, 35.5472],
            [-90.2869, 35.5255],
            [-90.4013, 35.4933],
            [-90.3937, 35.44],
            [-90.5024, 35.4417],
            [-90.6109, 35.4436],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509420',
        NAME: 'Marked Tree School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260468092,
        AWATER: 2662886,
        INTPTLAT: '+35.5062292',
        INTPTLON: '-090.4671592',
        ELSDLEA: '',
        UNSDLEA: '09420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8563, 33.4386],
            [-93.7761, 33.3466],
            [-93.782, 33.2669],
            [-93.8418, 33.3114],
            [-93.9711, 33.3428],
            [-93.9427, 33.4439],
            [-93.8563, 33.4386],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504110',
        NAME: 'Genoa Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204915248,
        AWATER: 319666,
        INTPTLAT: '+33.3643674',
        INTPTLON: '-093.8657434',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.043, 33.2552],
            [-94.043, 33.2712],
            [-93.9711, 33.3428],
            [-93.8418, 33.3114],
            [-93.782, 33.2669],
            [-93.707, 33.2225],
            [-93.8501, 33.1453],
            [-93.8049, 33.0194],
            [-93.8146, 33.0194],
            [-94.043, 33.0192],
            [-94.0429, 33.086],
            [-94.043, 33.2552],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506360',
        NAME: 'Fouke School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 712318696,
        AWATER: 12047512,
        INTPTLAT: '+33.1661941',
        INTPTLON: '-093.9069667',
        ELSDLEA: '',
        UNSDLEA: '06360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4574, 33.389],
            [-91.3531, 33.4309],
            [-91.2336, 33.4435],
            [-91.119, 33.4576],
            [-91.2093, 33.4049],
            [-91.1304, 33.3571],
            [-91.1412, 33.2984],
            [-91.0876, 33.1452],
            [-91.1661, 33.0041],
            [-91.265, 33.0051],
            [-91.4358, 33.0061],
            [-91.4604, 33.0059],
            [-91.4604, 33.1702],
            [-91.3899, 33.2416],
            [-91.5526, 33.2742],
            [-91.4585, 33.3305],
            [-91.4574, 33.389],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508640',
        NAME: 'Lakeside School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 1406909378,
        AWATER: 113885701,
        INTPTLAT: '+33.2417455',
        INTPTLON: '-091.2900227',
        ELSDLEA: '',
        UNSDLEA: '08640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8743, 34.6435],
            [-90.697, 34.6419],
            [-90.7156, 34.5914],
            [-90.6809, 34.4233],
            [-90.716, 34.3736],
            [-90.8749, 34.409],
            [-90.8212, 34.4967],
            [-90.8213, 34.57],
            [-90.8743, 34.6435],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502730',
        NAME: 'Barton-Lexa School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 393536245,
        AWATER: 5312270,
        INTPTLAT: '+34.5108699',
        INTPTLON: '-090.7781934',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6227, 33.4794],
            [-93.7229, 33.4822],
            [-93.7267, 33.4859],
            [-93.7394, 33.6288],
            [-93.6354, 33.6258],
            [-93.598, 33.5812],
            [-93.6227, 33.4794],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512630',
        NAME: 'Spring Hill School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182058057,
        AWATER: 3885503,
        INTPTLAT: '+33.5510579',
        INTPTLON: '-093.6616686',
        ELSDLEA: '',
        UNSDLEA: '12630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9021, 33.6473],
            [-93.7744, 33.6444],
            [-93.7753, 33.7462],
            [-93.7054, 33.8025],
            [-93.4758, 33.6947],
            [-93.483, 33.4799],
            [-93.6227, 33.4794],
            [-93.598, 33.5812],
            [-93.6354, 33.6258],
            [-93.7394, 33.6288],
            [-93.7267, 33.4859],
            [-93.7727, 33.4985],
            [-93.8259, 33.6099],
            [-93.9021, 33.6473],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507840',
        NAME: 'Hope School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 730376303,
        AWATER: 7750701,
        INTPTLAT: '+33.6495600',
        INTPTLON: '-093.6230599',
        ELSDLEA: '',
        UNSDLEA: '07840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3936, 35.44],
            [-90.2869, 35.4386],
            [-90.1403, 35.4366],
            [-90.075, 35.3842],
            [-90.1064, 35.3254],
            [-90.1071, 35.3229],
            [-90.0686, 35.1531],
            [-90.0757, 35.1281],
            [-90.1333, 35.1608],
            [-90.4005, 35.1512],
            [-90.3936, 35.44],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509390',
        NAME: 'Marion School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 785018541,
        AWATER: 17776620,
        INTPTLAT: '+35.2924624',
        INTPTLON: '-090.2513603',
        ELSDLEA: '',
        UNSDLEA: '09390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1714, 35.9968],
            [-91.1175, 36.0788],
            [-90.9219, 36.0586],
            [-90.8548, 35.9972],
            [-90.8521, 35.9681],
            [-90.9419, 35.971],
            [-91.0319, 35.9144],
            [-91.2027, 35.9507],
            [-91.1714, 35.9968],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507990',
        NAME: 'Hoxie School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 321593460,
        AWATER: 2460854,
        INTPTLAT: '+36.0075942',
        INTPTLON: '-091.0164014',
        ELSDLEA: '',
        UNSDLEA: '07990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1774, 35.6553],
            [-90.213, 35.7574],
            [-90.1082, 35.7419],
            [-90.1082, 35.8145],
            [-89.9991, 35.8141],
            [-89.8018, 35.8264],
            [-89.7017, 35.8242],
            [-89.8209, 35.7569],
            [-89.9446, 35.7412],
            [-90.0174, 35.7414],
            [-90, 35.6103],
            [-89.9249, 35.6104],
            [-89.9098, 35.5379],
            [-89.9895, 35.5601],
            [-90.0504, 35.5159],
            [-90.0416, 35.3966],
            [-90.0543, 35.3893],
            [-90.1403, 35.4366],
            [-90.2869, 35.4386],
            [-90.2869, 35.5255],
            [-90.2869, 35.5472],
            [-90.2871, 35.6126],
            [-90.1774, 35.6553],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500045',
        NAME: 'South Mississippi County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 942144142,
        AWATER: 12411969,
        INTPTLAT: '+35.6351245',
        INTPTLON: '-090.0716348',
        ELSDLEA: '',
        UNSDLEA: '00045',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9402, 35.9993],
            [-89.8597, 35.9999],
            [-89.7871, 36.0006],
            [-89.8018, 35.8264],
            [-89.9991, 35.8141],
            [-90.0004, 35.9302],
            [-89.9402, 35.9993],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503320',
        NAME: 'Blytheville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302001328,
        AWATER: 830823,
        INTPTLAT: '+35.8968133',
        INTPTLON: '-089.8972098',
        ELSDLEA: '',
        UNSDLEA: '03320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0585, 35.3546],
            [-91.0392, 35.4447],
            [-90.6104, 35.462],
            [-90.6109, 35.4436],
            [-90.6123, 35.2529],
            [-90.827, 35.2688],
            [-90.8549, 35.3114],
            [-91.0402, 35.2952],
            [-91.0585, 35.3546],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507740',
        NAME: 'Cross County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 755246259,
        AWATER: 3312636,
        INTPTLAT: '+35.3677578',
        INTPTLON: '-090.8163500',
        ELSDLEA: '',
        UNSDLEA: '07740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0402, 35.2952],
            [-90.8549, 35.3114],
            [-90.827, 35.2688],
            [-90.6123, 35.2529],
            [-90.6109, 35.4436],
            [-90.5024, 35.4417],
            [-90.503, 35.1442],
            [-90.503, 35.1434],
            [-91.0427, 35.1482],
            [-91.0402, 35.2952],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '514430',
        NAME: 'Wynne Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 863597775,
        AWATER: 11167461,
        INTPTLAT: '+35.2371840',
        INTPTLON: '-090.7489812',
        ELSDLEA: '',
        UNSDLEA: '14430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5085, 35.8228],
            [-90.4461, 35.8778],
            [-90.4001, 35.8193],
            [-90.2872, 35.8013],
            [-90.2867, 35.6999],
            [-90.4647, 35.7051],
            [-90.4667, 35.7053],
            [-90.5085, 35.8228],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500012',
        NAME: 'Riverside School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254724037,
        AWATER: 5080738,
        INTPTLAT: '+35.7299504',
        INTPTLON: '-090.4132426',
        ELSDLEA: '',
        UNSDLEA: '00012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7748, 35.8429],
            [-90.7192, 35.9522],
            [-90.6874, 35.9519],
            [-90.6751, 35.8935],
            [-90.714, 35.7992],
            [-90.7748, 35.8429],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508280',
        NAME: 'Jonesboro Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94488882,
        AWATER: 579642,
        INTPTLAT: '+35.8615832',
        INTPTLON: '-090.7144848',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6874, 35.9519],
            [-90.3897, 35.9647],
            [-90.4461, 35.8778],
            [-90.5085, 35.8228],
            [-90.5707, 35.8363],
            [-90.6751, 35.8935],
            [-90.6874, 35.9519],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503640',
        NAME: 'Brookland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290529580,
        AWATER: 2141148,
        INTPTLAT: '+35.9140600',
        INTPTLON: '-090.5532851',
        ELSDLEA: '',
        UNSDLEA: '03640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.044, 35.0734],
            [-90.9181, 35.0885],
            [-90.9027, 34.9998],
            [-90.8411, 34.9063],
            [-91.0992, 34.9118],
            [-91.1, 34.8671],
            [-91.1531, 34.8834],
            [-91.1498, 35.0025],
            [-91.0454, 35.001],
            [-91.044, 35.0734],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500051',
        NAME: 'Palestine-Wheatley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 399402327,
        AWATER: 1975541,
        INTPTLAT: '+34.9723459',
        INTPTLON: '-091.0030323',
        ELSDLEA: '',
        UNSDLEA: '00051',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.105, 34.5672],
            [-93.0867, 34.6236],
            [-92.9524, 34.6843],
            [-92.9515, 34.728],
            [-92.7087, 34.6932],
            [-92.6967, 34.62],
            [-92.8783, 34.5481],
            [-93.0173, 34.5518],
            [-93.105, 34.5672],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506420',
        NAME: 'Fountain Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 473880293,
        AWATER: 6636324,
        INTPTLAT: '+34.6235543',
        INTPTLON: '-092.8983348',
        ELSDLEA: '',
        UNSDLEA: '06420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3687, 34.6283],
            [-93.2979, 34.6574],
            [-93.0867, 34.6236],
            [-93.105, 34.5672],
            [-93.1149, 34.5095],
            [-93.3687, 34.5965],
            [-93.4042, 34.5611],
            [-93.3687, 34.6283],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510170',
        NAME: 'Mountain Pine School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189180246,
        AWATER: 79759574,
        INTPTLAT: '+34.5811063',
        INTPTLON: '-093.2295374',
        ELSDLEA: '',
        UNSDLEA: '10170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8783, 34.5481],
            [-92.6967, 34.62],
            [-92.5836, 34.6442],
            [-92.5514, 34.5779],
            [-92.6086, 34.5147],
            [-92.701, 34.5173],
            [-92.7756, 34.5037],
            [-92.8793, 34.5203],
            [-92.8783, 34.5481],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502960',
        NAME: 'Benton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271870060,
        AWATER: 545819,
        INTPTLAT: '+34.5806907',
        INTPTLON: '-092.6822516',
        ELSDLEA: '',
        UNSDLEA: '02960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0173, 34.5518],
            [-92.8783, 34.5481],
            [-92.8793, 34.5203],
            [-92.9154, 34.4917],
            [-93.0201, 34.4941],
            [-93.0173, 34.5518],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504860',
        NAME: 'Cutter-Morning Star School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81454058,
        AWATER: 12920,
        INTPTLAT: '+34.5232918',
        INTPTLON: '-092.9531729',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4042, 34.5611],
            [-93.3687, 34.5965],
            [-93.1149, 34.5095],
            [-93.1074, 34.4463],
            [-93.1225, 34.3919],
            [-93.3019, 34.3953],
            [-93.4068, 34.4239],
            [-93.4042, 34.5611],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508610',
        NAME: 'Lake Hamilton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404966944,
        AWATER: 30374045,
        INTPTLAT: '+34.4853691',
        INTPTLON: '-093.2563763',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4004, 35.1482],
            [-90.4005, 35.1512],
            [-90.1333, 35.1608],
            [-90.0757, 35.1281],
            [-90.1736, 35.1181],
            [-90.2094, 35.0265],
            [-90.3001, 35.0303],
            [-90.402, 35.061],
            [-90.4004, 35.1482],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508040',
        NAME: 'West Memphis School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267644574,
        AWATER: 13196469,
        INTPTLAT: '+35.1047989',
        INTPTLON: '-090.2706195',
        ELSDLEA: '',
        UNSDLEA: '08040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3717, 34.7827],
            [-91.1018, 34.7643],
            [-91.0495, 34.7342],
            [-91.0519, 34.6457],
            [-91.0566, 34.3369],
            [-91.2666, 34.5063],
            [-91.2915, 34.5618],
            [-91.414, 34.5642],
            [-91.3748, 34.666],
            [-91.3717, 34.7827],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504350',
        NAME: 'Clarendon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1016645157,
        AWATER: 28551028,
        INTPTLAT: '+34.6063152',
        INTPTLON: '-091.2096656',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3686, 34.9127],
            [-91.2931, 34.9851],
            [-91.2024, 34.9845],
            [-91.1318, 35.104],
            [-91.0435, 35.104],
            [-91.044, 35.0734],
            [-91.0454, 35.001],
            [-91.1498, 35.0025],
            [-91.1531, 34.8834],
            [-91.1, 34.8671],
            [-91.1018, 34.7643],
            [-91.3717, 34.7827],
            [-91.3631, 34.8563],
            [-91.3686, 34.9127],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503630',
        NAME: 'Brinkley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 591885545,
        AWATER: 9713829,
        INTPTLAT: '+34.8998821',
        INTPTLON: '-091.2021037',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-91.1899, 36.1156],
              [-91.0828, 36.151],
              [-90.8824, 36.1505],
              [-90.7474, 36.1491],
              [-90.8087, 36.0849],
              [-90.8548, 35.9972],
              [-90.9219, 36.0586],
              [-91.1175, 36.0788],
              [-91.1714, 35.9968],
              [-91.1899, 36.1156],
            ],
          ],
          [
            [
              [-91.2027, 35.9507],
              [-91.0319, 35.9144],
              [-91.0326, 35.8851],
              [-91.1986, 35.89],
              [-91.2027, 35.9507],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500082',
        NAME: 'Lawrence County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 487970943,
        AWATER: 6368278,
        INTPTLAT: '+36.0963608',
        INTPTLON: '-090.8615535',
        ELSDLEA: '',
        UNSDLEA: '00082',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4318, 35.7887],
            [-92.4167, 35.7886],
            [-92.3828, 35.7153],
            [-92.2405, 35.7122],
            [-92.2441, 35.5698],
            [-92.3156, 35.5514],
            [-92.3858, 35.5845],
            [-92.4317, 35.7015],
            [-92.4318, 35.7887],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512420',
        NAME: 'Shirley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229547664,
        AWATER: 12324129,
        INTPTLAT: '+35.6483787',
        INTPTLON: '-092.3231461',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4825, 33.765],
            [-94.4815, 33.796],
            [-94.4773, 33.9409],
            [-94.3737, 33.7623],
            [-94.3051, 33.7023],
            [-94.3085, 33.5526],
            [-94.3554, 33.5433],
            [-94.4577, 33.5997],
            [-94.4859, 33.6379],
            [-94.4825, 33.765],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506240',
        NAME: 'Foreman School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 434672835,
        AWATER: 9441812,
        INTPTLAT: '+33.7141009',
        INTPTLON: '-094.4007784',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4753, 34.0159],
            [-94.4019, 33.9809],
            [-94.2696, 33.9988],
            [-94.2268, 33.9623],
            [-94.2316, 33.8017],
            [-94.2796, 33.829],
            [-94.3737, 33.7623],
            [-94.4773, 33.9409],
            [-94.4753, 34.0159],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507860',
        NAME: 'Horatio School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 408169477,
        AWATER: 3995608,
        INTPTLAT: '+33.8918181',
        INTPTLON: '-094.3418709',
        ELSDLEA: '',
        UNSDLEA: '07860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7236, 35.0719],
            [-92.5547, 35.1133],
            [-92.5567, 35.0393],
            [-92.5443, 34.9533],
            [-92.7002, 34.9106],
            [-92.7514, 35.0433],
            [-92.7236, 35.0719],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505580',
        NAME: 'East End School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263053249,
        AWATER: 11272456,
        INTPTLAT: '+35.0081390',
        INTPTLON: '-092.6394437',
        ELSDLEA: '',
        UNSDLEA: '05580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.088, 35.9897],
            [-92.0373, 36.0033],
            [-92.0079, 36.1295],
            [-91.7516, 36.1025],
            [-91.6918, 35.9822],
            [-91.7106, 35.9418],
            [-91.8494, 35.8674],
            [-92.088, 35.9897],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509720',
        NAME: 'Melbourne School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 664203243,
        AWATER: 3214344,
        INTPTLAT: '+36.0112020',
        INTPTLON: '-091.8917423',
        ELSDLEA: '',
        UNSDLEA: '09720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3923, 35.9072],
            [-91.3577, 35.8567],
            [-91.1986, 35.89],
            [-91.3382, 35.7129],
            [-91.3873, 35.5264],
            [-91.5335, 35.5288],
            [-91.5437, 35.6165],
            [-91.5236, 35.7063],
            [-91.4694, 35.7293],
            [-91.4677, 35.8146],
            [-91.5575, 35.8051],
            [-91.5026, 35.893],
            [-91.3923, 35.9072],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500070',
        NAME: 'Cedar Ridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 690406824,
        AWATER: 10228896,
        INTPTLAT: '+35.7235016',
        INTPTLON: '-091.4081582',
        ELSDLEA: '',
        UNSDLEA: '00070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6725, 34.2687],
            [-92.6019, 34.2674],
            [-92.6142, 34.3984],
            [-92.5418, 34.4543],
            [-92.4047, 34.4519],
            [-92.4038, 34.5104],
            [-92.3832, 34.5969],
            [-92.2442, 34.5809],
            [-92.208, 34.4928],
            [-92.2105, 34.3182],
            [-92.2129, 34.2304],
            [-92.2334, 34.0623],
            [-92.3368, 34.0596],
            [-92.4937, 34.1442],
            [-92.6784, 34.1496],
            [-92.6725, 34.2687],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500015',
        NAME: 'Sheridan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1617733793,
        AWATER: 4888460,
        INTPTLAT: '+34.3042711',
        INTPTLON: '-092.3973999',
        ELSDLEA: '',
        UNSDLEA: '00015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5933, 34.4699],
            [-92.6086, 34.5147],
            [-92.5514, 34.5779],
            [-92.4489, 34.5622],
            [-92.4038, 34.5104],
            [-92.4047, 34.4519],
            [-92.5418, 34.4543],
            [-92.6142, 34.3984],
            [-92.6221, 34.4125],
            [-92.5933, 34.4699],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502790',
        NAME: 'Bauxite School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222980067,
        AWATER: 2845336,
        INTPTLAT: '+34.4799333',
        INTPTLON: '-092.5154233',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8219, 33.1949],
            [-92.8046, 33.2744],
            [-92.7262, 33.2809],
            [-92.5265, 33.3187],
            [-92.3896, 33.278],
            [-92.3901, 33.1557],
            [-92.5276, 33.1613],
            [-92.4525, 33.0118],
            [-92.538, 33.0127],
            [-92.6319, 33.1344],
            [-92.7179, 33.179],
            [-92.8222, 33.1811],
            [-92.8219, 33.1949],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505680',
        NAME: 'El Dorado School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 705850559,
        AWATER: 2997200,
        INTPTLAT: '+33.1964354',
        INTPTLON: '-092.5696927',
        ELSDLEA: '',
        UNSDLEA: '05680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8222, 33.1811],
            [-92.7179, 33.179],
            [-92.6319, 33.1344],
            [-92.7415, 33.077],
            [-92.7529, 33.1503],
            [-92.8222, 33.1811],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511220',
        NAME: 'Parkers Chapel School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117346414,
        AWATER: 0,
        INTPTLAT: '+33.1396769',
        INTPTLON: '-092.7355444',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.986, 33.1164],
            [-92.9844, 33.1599],
            [-92.8219, 33.1949],
            [-92.8222, 33.1811],
            [-92.7529, 33.1503],
            [-92.7415, 33.077],
            [-92.6319, 33.1344],
            [-92.538, 33.0127],
            [-92.7247, 33.0144],
            [-92.9887, 33.0174],
            [-92.986, 33.1164],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508340',
        NAME: 'Junction City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 527398111,
        AWATER: 610811,
        INTPTLAT: '+33.0748162',
        INTPTLON: '-092.7935672',
        ELSDLEA: '',
        UNSDLEA: '08340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9785, 33.3773],
            [-92.9442, 33.3764],
            [-92.8389, 33.3734],
            [-92.7236, 33.4596],
            [-92.5672, 33.3672],
            [-92.7264, 33.3392],
            [-92.7262, 33.2809],
            [-92.8046, 33.2744],
            [-92.8219, 33.1949],
            [-92.9844, 33.1599],
            [-92.9785, 33.3773],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512510',
        NAME: 'Smackover School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 623964123,
        AWATER: 2378600,
        INTPTLAT: '+33.3140043',
        INTPTLON: '-092.8289368',
        ELSDLEA: '',
        UNSDLEA: '12510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7586, 34.3571],
            [-92.6701, 34.3563],
            [-92.6221, 34.4125],
            [-92.6142, 34.3984],
            [-92.6019, 34.2674],
            [-92.6725, 34.2687],
            [-92.7586, 34.3571],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511730',
        NAME: 'Poyen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145584120,
        AWATER: 203152,
        INTPTLAT: '+34.3271215',
        INTPTLON: '-092.6536079',
        ELSDLEA: '',
        UNSDLEA: '11730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7756, 34.5037],
            [-92.701, 34.5173],
            [-92.5933, 34.4699],
            [-92.6221, 34.4125],
            [-92.6701, 34.3563],
            [-92.7586, 34.3571],
            [-92.7767, 34.4448],
            [-92.7756, 34.5037],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506630',
        NAME: 'Glen Rose School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195409562,
        AWATER: 217263,
        INTPTLAT: '+34.4351801',
        INTPTLON: '-092.7054767',
        ELSDLEA: '',
        UNSDLEA: '06630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0229, 34.2739],
            [-92.8518, 34.2192],
            [-92.8666, 34.155],
            [-92.8863, 34.1559],
            [-93.042, 34.1599],
            [-93.0763, 34.2362],
            [-93.0229, 34.2739],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510980',
        NAME: 'Ouachita School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188595302,
        AWATER: 805967,
        INTPTLAT: '+34.2226952',
        INTPTLON: '-092.9466839',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.93, 34.4487],
            [-92.9154, 34.4917],
            [-92.8793, 34.5203],
            [-92.7756, 34.5037],
            [-92.7767, 34.4448],
            [-92.8161, 34.4155],
            [-92.93, 34.4487],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509190',
        NAME: 'Magnet Cove School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125252056,
        AWATER: 3341906,
        INTPTLAT: '+34.4708460',
        INTPTLON: '-092.8401215',
        ELSDLEA: '',
        UNSDLEA: '09190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6438, 35.7048],
            [-90.643, 35.7632],
            [-90.5707, 35.8363],
            [-90.5085, 35.8228],
            [-90.4667, 35.7053],
            [-90.6438, 35.7048],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502820',
        NAME: 'Bay School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166958089,
        AWATER: 257927,
        INTPTLAT: '+35.7605351',
        INTPTLON: '-090.5489279',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.714, 35.7992],
            [-90.6751, 35.8935],
            [-90.5707, 35.8363],
            [-90.643, 35.7632],
            [-90.714, 35.7992],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510440',
        NAME: 'Nettleton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107174793,
        AWATER: 526578,
        INTPTLAT: '+35.8153826',
        INTPTLON: '-090.6376163',
        ELSDLEA: '',
        UNSDLEA: '10440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7748, 35.8429],
            [-90.714, 35.7992],
            [-90.643, 35.7632],
            [-90.6438, 35.7048],
            [-90.7473, 35.7051],
            [-90.9303, 35.7063],
            [-90.8741, 35.8369],
            [-90.7748, 35.8429],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '513380',
        NAME: 'Valley View School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274932416,
        AWATER: 806253,
        INTPTLAT: '+35.7590366',
        INTPTLON: '-090.7947828',
        ELSDLEA: '',
        UNSDLEA: '13380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4342, 35.314],
            [-94.4332, 35.3658],
            [-94.4356, 35.3861],
            [-94.431, 35.3937],
            [-94.2786, 35.3596],
            [-94.2447, 35.2826],
            [-94.4357, 35.276],
            [-94.4342, 35.314],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506330',
        NAME: 'Fort Smith Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167050846,
        AWATER: 14916845,
        INTPTLAT: '+35.3528506',
        INTPTLON: '-094.3652886',
        ELSDLEA: '',
        UNSDLEA: '06330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2786, 35.3596],
            [-94.1614, 35.4353],
            [-94.0983, 35.4439],
            [-94.0749, 35.4454],
            [-94.0757, 35.4271],
            [-94.0795, 35.3398],
            [-94.1704, 35.2921],
            [-94.2447, 35.2826],
            [-94.2786, 35.3596],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508730',
        NAME: 'Lavaca Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152200394,
        AWATER: 13279092,
        INTPTLAT: '+35.3595209',
        INTPTLON: '-094.1678314',
        ELSDLEA: '',
        UNSDLEA: '08730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1149, 34.5095],
            [-93.105, 34.5672],
            [-93.0173, 34.5518],
            [-93.0201, 34.4941],
            [-93.1074, 34.4463],
            [-93.1149, 34.5095],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507890',
        NAME: 'Hot Springs School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85634907,
        AWATER: 4187767,
        INTPTLAT: '+34.5007064',
        INTPTLON: '-093.0714926',
        ELSDLEA: '',
        UNSDLEA: '07890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7568, 34.5853],
            [-93.5706, 34.6864],
            [-93.5699, 34.7439],
            [-93.3943, 34.7434],
            [-93.3584, 34.7733],
            [-93.3687, 34.6283],
            [-93.4042, 34.5611],
            [-93.4068, 34.4239],
            [-93.4069, 34.4192],
            [-93.512, 34.4725],
            [-93.7489, 34.5214],
            [-93.7568, 34.5853],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509990',
        NAME: 'Mount Ida School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 767506374,
        AWATER: 51198123,
        INTPTLAT: '+34.5884323',
        INTPTLON: '-093.5274166',
        ELSDLEA: '',
        UNSDLEA: '09990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-91.8035, 34.9361],
              [-91.8037, 34.9215],
              [-91.8215, 34.9289],
              [-91.8035, 34.9361],
            ],
          ],
          [
            [
              [-91.8037, 34.9215],
              [-91.6938, 34.9162],
              [-91.7008, 34.672],
              [-91.6431, 34.6369],
              [-91.6346, 34.5463],
              [-91.678, 34.4819],
              [-91.8141, 34.4842],
              [-91.7557, 34.618],
              [-91.8451, 34.6455],
              [-91.8256, 34.7477],
              [-91.8592, 34.806],
              [-91.8037, 34.9215],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503960',
        NAME: 'Carlisle School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 604141655,
        AWATER: 25180589,
        INTPTLAT: '+34.7034679',
        INTPTLON: '-091.7504410',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.008, 34.6912],
            [-91.8451, 34.6455],
            [-91.7557, 34.618],
            [-91.8141, 34.4842],
            [-92.03, 34.4894],
            [-92.008, 34.6912],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505850',
        NAME: 'England School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391635315,
        AWATER: 8311492,
        INTPTLAT: '+34.5737609',
        INTPTLON: '-091.9230072',
        ELSDLEA: '',
        UNSDLEA: '05850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7002, 34.9106],
            [-92.5443, 34.9533],
            [-92.2995, 34.9289],
            [-92.2275, 35.0145],
            [-92.1213, 35.0122],
            [-92.0708, 35.0118],
            [-92.0748, 34.8592],
            [-92.0314, 34.8515],
            [-92.0782, 34.7351],
            [-92.008, 34.6912],
            [-92.03, 34.4894],
            [-92.1437, 34.4915],
            [-92.208, 34.4928],
            [-92.2442, 34.5809],
            [-92.3832, 34.5969],
            [-92.3995, 34.6264],
            [-92.3079, 34.6633],
            [-92.2201, 34.7454],
            [-92.2334, 34.8205],
            [-92.2515, 34.8229],
            [-92.3308, 34.7874],
            [-92.4433, 34.672],
            [-92.6555, 34.7355],
            [-92.6512, 34.8517],
            [-92.7398, 34.8535],
            [-92.7002, 34.9106],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511850',
        NAME: 'Pulaski County Special School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1766740863,
        AWATER: 117815604,
        INTPTLAT: '+34.6234904',
        INTPTLON: '-092.1861110',
        ELSDLEA: '',
        UNSDLEA: '11850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8035, 34.9361],
            [-91.8027, 35.0087],
            [-91.7082, 35.0626],
            [-91.5851, 35.0252],
            [-91.584, 35.0916],
            [-91.4651, 35.0895],
            [-91.3638, 35.0519],
            [-91.3686, 34.9127],
            [-91.3631, 34.8563],
            [-91.5482, 34.8612],
            [-91.6938, 34.9162],
            [-91.8037, 34.9215],
            [-91.8035, 34.9361],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505190',
        NAME: 'Des Arc Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 688113870,
        AWATER: 24311974,
        INTPTLAT: '+34.9710338',
        INTPTLON: '-091.5544058',
        ELSDLEA: '',
        UNSDLEA: '05190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0029, 35.5765],
            [-91.9221, 35.5653],
            [-91.9462, 35.4578],
            [-91.8817, 35.4561],
            [-92.0073, 35.4183],
            [-92.0866, 35.4503],
            [-92.1037, 35.465],
            [-92.0988, 35.5169],
            [-92.0029, 35.5765],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507560',
        NAME: 'Heber Springs School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171373768,
        AWATER: 15528889,
        INTPTLAT: '+35.4810551',
        INTPTLON: '-092.0132788',
        ELSDLEA: '',
        UNSDLEA: '07560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5192, 33.7527],
            [-91.3416, 33.7821],
            [-91.3533, 33.957],
            [-91.1356, 33.962],
            [-91.079, 33.9542],
            [-91.0468, 33.8154],
            [-91.1433, 33.747],
            [-91.2078, 33.7001],
            [-91.1304, 33.606],
            [-91.2309, 33.5614],
            [-91.4534, 33.5637],
            [-91.5405, 33.6071],
            [-91.5192, 33.7527],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509630',
        NAME: 'McGehee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1293524343,
        AWATER: 85999228,
        INTPTLAT: '+33.7558246',
        INTPTLON: '-091.2689323',
        ELSDLEA: '',
        UNSDLEA: '09630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0866, 35.4503],
            [-92.0073, 35.4183],
            [-91.9802, 35.3596],
            [-91.9224, 35.3114],
            [-91.9517, 35.2275],
            [-92.0305, 35.2066],
            [-92.1147, 35.2343],
            [-92.1493, 35.3247],
            [-92.0866, 35.4503],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512000',
        NAME: 'Rose Bud School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310145770,
        AWATER: 596136,
        INTPTLAT: '+35.3109632',
        INTPTLON: '-092.0371492',
        ELSDLEA: '',
        UNSDLEA: '12000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3308, 34.7874],
            [-92.2201, 34.7454],
            [-92.3079, 34.6633],
            [-92.3995, 34.6264],
            [-92.4433, 34.672],
            [-92.3308, 34.7874],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509000',
        NAME: 'Little Rock School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253810268,
        AWATER: 6637744,
        INTPTLAT: '+34.7205788',
        INTPTLON: '-092.3472913',
        ELSDLEA: '',
        UNSDLEA: '09000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4473, 36.1828],
            [-93.4052, 36.2311],
            [-93.299, 36.2379],
            [-93.2962, 36.3815],
            [-93.2413, 36.3694],
            [-93.2146, 36.24],
            [-93.2992, 36.2324],
            [-93.3008, 36.1215],
            [-93.3945, 36.0941],
            [-93.4335, 36.1387],
            [-93.4473, 36.1828],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502280',
        NAME: 'Alpena School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 323183296,
        AWATER: 908826,
        INTPTLAT: '+36.2310249',
        INTPTLON: '-093.3124142',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.088, 36.3933],
            [-92.9769, 36.4064],
            [-92.9741, 36.3342],
            [-92.8882, 36.3332],
            [-92.8889, 36.2516],
            [-92.9611, 36.2485],
            [-93.0722, 36.2496],
            [-93.1524, 36.3139],
            [-93.088, 36.3933],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503090',
        NAME: 'Bergman School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296053656,
        AWATER: 295062,
        INTPTLAT: '+36.3146657',
        INTPTLON: '-093.0186642',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0722, 36.2496],
            [-92.9611, 36.2485],
            [-92.8895, 36.1767],
            [-92.8907, 36.1141],
            [-93.0343, 36.1163],
            [-93.0957, 36.1193],
            [-93.0722, 36.2496],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '513350',
        NAME: 'Valley Springs School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185332919,
        AWATER: 230968,
        INTPTLAT: '+36.1683484',
        INTPTLON: '-093.0023279',
        ELSDLEA: '',
        UNSDLEA: '13350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0137, 36.4981],
            [-92.9294, 36.4979],
            [-92.854, 36.4978],
            [-92.8312, 36.4575],
            [-92.8325, 36.3506],
            [-92.8882, 36.3332],
            [-92.9741, 36.3342],
            [-92.9769, 36.4064],
            [-93.088, 36.3933],
            [-93.0925, 36.4218],
            [-93.0137, 36.4981],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503420',
        NAME: 'Lead Hill School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271572382,
        AWATER: 29706814,
        INTPTLAT: '+36.4244427',
        INTPTLON: '-092.9427159',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.9611, 36.2485],
              [-92.8889, 36.2516],
              [-92.8895, 36.1767],
              [-92.9611, 36.2485],
            ],
          ],
          [
            [
              [-93.3945, 36.0941],
              [-93.3008, 36.1215],
              [-93.2992, 36.2324],
              [-93.2146, 36.24],
              [-93.2413, 36.3694],
              [-93.0925, 36.4218],
              [-93.088, 36.3933],
              [-93.1524, 36.3139],
              [-93.0722, 36.2496],
              [-93.0957, 36.1193],
              [-93.2652, 36.1203],
              [-93.3945, 36.0941],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507380',
        NAME: 'Harrison School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 543189704,
        AWATER: 819876,
        INTPTLAT: '+36.2199130',
        INTPTLON: '-093.1545968',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.4774, 36.0922],
              [-92.4105, 36.092],
              [-92.4115, 36.0628],
              [-92.4774, 36.0922],
            ],
          ],
          [
            [
              [-92.6306, 36.4982],
              [-92.6378, 36.4618],
              [-92.7074, 36.4316],
              [-92.7622, 36.4979],
              [-92.6306, 36.4982],
            ],
          ],
          [
            [
              [-92.854, 36.4978],
              [-92.7849, 36.4977],
              [-92.8312, 36.4575],
              [-92.854, 36.4978],
            ],
          ],
          [
            [
              [-93.0343, 36.1163],
              [-92.8907, 36.1141],
              [-92.8895, 36.1767],
              [-92.8889, 36.2516],
              [-92.8882, 36.3332],
              [-92.8325, 36.3506],
              [-92.7865, 36.2689],
              [-92.8121, 36.2031],
              [-92.7058, 36.1916],
              [-92.706, 36.1252],
              [-92.6416, 36.0229],
              [-92.6984, 35.9116],
              [-92.8126, 35.9675],
              [-92.9422, 35.942],
              [-93.0568, 35.9587],
              [-93.1231, 36.0914],
              [-93.0343, 36.1163],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500076',
        NAME: 'Ozark Mountain School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 984454293,
        AWATER: 33852430,
        INTPTLAT: '+36.1169982',
        INTPTLON: '-092.8566552',
        ELSDLEA: '',
        UNSDLEA: '00076',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8705, 34.1853],
            [-93.7689, 34.1963],
            [-93.4493, 34.1752],
            [-93.346, 34.1284],
            [-93.3318, 34.026],
            [-93.3734, 33.9571],
            [-93.4567, 33.9568],
            [-93.529, 33.9444],
            [-93.7178, 33.9966],
            [-93.8231, 34.0089],
            [-93.8221, 34.1257],
            [-93.8705, 34.1853],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510320',
        NAME: 'South Pike County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 966415253,
        AWATER: 17449602,
        INTPTLAT: '+34.0805599',
        INTPTLON: '-093.6001474',
        ELSDLEA: '',
        UNSDLEA: '10320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9087, 35.0966],
            [-92.8582, 35.0738],
            [-92.7514, 35.0433],
            [-92.7002, 34.9106],
            [-92.7398, 34.8535],
            [-93.0739, 34.8581],
            [-93.0751, 34.8013],
            [-93.0752, 34.7719],
            [-93.2878, 34.7732],
            [-93.2883, 34.861],
            [-93.1603, 34.9906],
            [-92.9662, 35],
            [-92.9087, 35.0966],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511340',
        NAME: 'Perryville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1025613151,
        AWATER: 11793527,
        INTPTLAT: '+34.9189781',
        INTPTLON: '-093.0097444',
        ELSDLEA: '',
        UNSDLEA: '11340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7588, 35.6811],
            [-91.6871, 35.681],
            [-91.5782, 35.6608],
            [-91.5437, 35.6165],
            [-91.5335, 35.5288],
            [-91.5835, 35.4937],
            [-91.6342, 35.443],
            [-91.7275, 35.4742],
            [-91.794, 35.5341],
            [-91.7588, 35.6811],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500020',
        NAME: 'Midland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 431297784,
        AWATER: 233040,
        INTPTLAT: '+35.5830358',
        INTPTLON: '-091.6562409',
        ELSDLEA: '',
        UNSDLEA: '00020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6989, 35.3998],
            [-91.7097, 35.274],
            [-91.7352, 35.1807],
            [-91.9517, 35.2275],
            [-91.9224, 35.3114],
            [-91.9802, 35.3596],
            [-91.6989, 35.3998],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512210',
        NAME: 'Searcy School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418293445,
        AWATER: 1025296,
        INTPTLAT: '+35.2981329',
        INTPTLON: '-091.8281549',
        ELSDLEA: '',
        UNSDLEA: '12210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5405, 33.6071],
            [-91.4534, 33.5637],
            [-91.2309, 33.5614],
            [-91.2157, 33.5294],
            [-91.2336, 33.4435],
            [-91.3531, 33.4309],
            [-91.4574, 33.389],
            [-91.5964, 33.4132],
            [-91.5953, 33.6081],
            [-91.5405, 33.6071],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505170',
        NAME: 'Dermott School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 609906210,
        AWATER: 18772180,
        INTPTLAT: '+33.4999080',
        INTPTLON: '-091.4329578',
        ELSDLEA: '',
        UNSDLEA: '05170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6069, 34.1123],
            [-91.552, 34.106],
            [-91.3533, 33.957],
            [-91.3416, 33.7821],
            [-91.5192, 33.7527],
            [-91.6447, 33.7907],
            [-91.6984, 33.9087],
            [-91.6045, 34.0427],
            [-91.6069, 34.1123],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505500',
        NAME: 'Dumas School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 913639762,
        AWATER: 29463821,
        INTPTLAT: '+33.9169891',
        INTPTLON: '-091.5189128',
        ELSDLEA: '',
        UNSDLEA: '05500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0918, 33.8271],
            [-94.0562, 33.9065],
            [-93.8955, 33.9237],
            [-93.8286, 33.8344],
            [-93.7449, 33.8488],
            [-93.7054, 33.8025],
            [-93.7753, 33.7462],
            [-93.7744, 33.6444],
            [-93.9021, 33.6473],
            [-93.9694, 33.7281],
            [-94.0932, 33.787],
            [-94.0918, 33.8271],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509780',
        NAME: 'Mineral Springs School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 611019956,
        AWATER: 71899362,
        INTPTLAT: '+33.7706955',
        INTPTLON: '-093.8827651',
        ELSDLEA: '',
        UNSDLEA: '09780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3499, 36.2301],
            [-91.2753, 36.3745],
            [-91.1839, 36.3748],
            [-91.1478, 36.3746],
            [-91.0607, 36.2263],
            [-91.0828, 36.151],
            [-91.1899, 36.1156],
            [-91.3489, 36.1474],
            [-91.3499, 36.2301],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512480',
        NAME: 'Sloan-Hendrix School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 531970307,
        AWATER: 998716,
        INTPTLAT: '+36.2465987',
        INTPTLON: '-091.2093609',
        ELSDLEA: '',
        UNSDLEA: '12480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4728, 36.1174],
            [-91.4728, 36.1605],
            [-91.3499, 36.2301],
            [-91.3489, 36.1474],
            [-91.1899, 36.1156],
            [-91.1714, 35.9968],
            [-91.2027, 35.9507],
            [-91.1986, 35.89],
            [-91.3577, 35.8567],
            [-91.3923, 35.9072],
            [-91.3988, 36.0021],
            [-91.5164, 36.0018],
            [-91.5418, 36.0786],
            [-91.4728, 36.1174],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500071',
        NAME: 'Hillcrest School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 763184261,
        AWATER: 3464899,
        INTPTLAT: '+36.0290766',
        INTPTLON: '-091.3411630',
        ELSDLEA: '',
        UNSDLEA: '00071',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0079, 33.5294],
            [-91.9758, 33.7044],
            [-91.976, 33.7918],
            [-91.8437, 33.7918],
            [-91.8733, 33.7336],
            [-91.873, 33.5813],
            [-91.7151, 33.6241],
            [-91.769, 33.7047],
            [-91.7687, 33.792],
            [-91.6447, 33.7907],
            [-91.5192, 33.7527],
            [-91.5405, 33.6071],
            [-91.5953, 33.6081],
            [-91.5964, 33.4132],
            [-91.6414, 33.3922],
            [-91.9902, 33.3982],
            [-92.0079, 33.5294],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505470',
        NAME: 'Drew Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1455812275,
        AWATER: 3851766,
        INTPTLAT: '+33.5884688',
        INTPTLON: '-091.7678830',
        ELSDLEA: '',
        UNSDLEA: '05470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0757, 35.4271],
            [-94.0256, 35.4258],
            [-93.9549, 35.23],
            [-94.0285, 35.2138],
            [-94.0807, 35.276],
            [-94.1704, 35.2921],
            [-94.0795, 35.3398],
            [-94.0757, 35.4271],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504200',
        NAME: 'Charleston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282869467,
        AWATER: 2006499,
        INTPTLAT: '+35.3132198',
        INTPTLON: '-094.0573431',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.346, 34.1284],
            [-93.2723, 34.2139],
            [-93.183, 34.2477],
            [-93.0763, 34.2362],
            [-93.042, 34.1599],
            [-92.8863, 34.1559],
            [-92.8908, 34.0411],
            [-92.9507, 33.9638],
            [-93.0897, 33.9467],
            [-93.1731, 34.0874],
            [-93.2436, 34.0236],
            [-93.3318, 34.026],
            [-93.346, 34.1284],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502430',
        NAME: 'Arkadelphia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 831168271,
        AWATER: 18233390,
        INTPTLAT: '+34.0986350',
        INTPTLON: '-093.1088171',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3019, 34.3953],
            [-93.1225, 34.3919],
            [-92.9481, 34.3893],
            [-93.0209, 34.3324],
            [-93.0229, 34.2739],
            [-93.0763, 34.2362],
            [-93.183, 34.2477],
            [-93.3568, 34.2803],
            [-93.3019, 34.3953],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503240',
        NAME: 'Bismarck Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452044897,
        AWATER: 11729051,
        INTPTLAT: '+34.3268908',
        INTPTLON: '-093.1573021',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5718, 34.3434],
            [-93.4081, 34.3395],
            [-93.4069, 34.4192],
            [-93.4068, 34.4239],
            [-93.3019, 34.3953],
            [-93.3568, 34.2803],
            [-93.183, 34.2477],
            [-93.2723, 34.2139],
            [-93.346, 34.1284],
            [-93.4493, 34.1752],
            [-93.5718, 34.3434],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506690',
        NAME: 'Centerpoint School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 543026379,
        AWATER: 21683475,
        INTPTLAT: '+34.2804762',
        INTPTLON: '-093.3803649',
        ELSDLEA: '',
        UNSDLEA: '06690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2316, 33.8017],
            [-94.0918, 33.8271],
            [-94.0932, 33.787],
            [-93.9694, 33.7281],
            [-93.9021, 33.6473],
            [-93.8259, 33.6099],
            [-93.9356, 33.5502],
            [-94.0434, 33.5523],
            [-94.2157, 33.5604],
            [-94.3085, 33.5526],
            [-94.3051, 33.7023],
            [-94.3737, 33.7623],
            [-94.2796, 33.829],
            [-94.2316, 33.8017],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502580',
        NAME: 'Ashdown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 829290229,
        AWATER: 68224425,
        INTPTLAT: '+33.6780135',
        INTPTLON: '-094.1256098',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1018, 34.7643],
            [-91.1, 34.8671],
            [-91.0992, 34.9118],
            [-90.8411, 34.9063],
            [-90.6198, 34.9064],
            [-90.4085, 34.9041],
            [-90.408, 34.833],
            [-90.5172, 34.6309],
            [-90.5832, 34.6414],
            [-90.697, 34.6419],
            [-90.8743, 34.6435],
            [-91.0519, 34.6457],
            [-91.0495, 34.7342],
            [-91.1018, 34.7643],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509360',
        NAME: 'Lee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1560781751,
        AWATER: 43720910,
        INTPTLAT: '+34.7798576',
        INTPTLON: '-090.7793454',
        ELSDLEA: '',
        UNSDLEA: '09360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3584, 34.7733],
            [-93.2878, 34.7732],
            [-93.0752, 34.7719],
            [-93.0751, 34.8013],
            [-92.9515, 34.728],
            [-92.9524, 34.6843],
            [-93.0867, 34.6236],
            [-93.2979, 34.6574],
            [-93.3687, 34.6283],
            [-93.3584, 34.7733],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507920',
        NAME: 'Jessieville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 513755114,
        AWATER: 8731718,
        INTPTLAT: '+34.7114051',
        INTPTLON: '-093.1740392',
        ELSDLEA: '',
        UNSDLEA: '07920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1074, 34.4463],
            [-93.0201, 34.4941],
            [-92.9154, 34.4917],
            [-92.93, 34.4487],
            [-92.9481, 34.3893],
            [-93.1225, 34.3919],
            [-93.1074, 34.4463],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508670',
        NAME: 'Lakeside School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 143904330,
        AWATER: 17712325,
        INTPTLAT: '+34.4360135',
        INTPTLON: '-093.0167648',
        ELSDLEA: '',
        UNSDLEA: '08670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6938, 34.9162],
            [-91.5482, 34.8612],
            [-91.3631, 34.8563],
            [-91.3717, 34.7827],
            [-91.3748, 34.666],
            [-91.414, 34.5642],
            [-91.5944, 34.568],
            [-91.6252, 34.4796],
            [-91.678, 34.4819],
            [-91.6346, 34.5463],
            [-91.6431, 34.6369],
            [-91.7008, 34.672],
            [-91.6938, 34.9162],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507530',
        NAME: 'Hazen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 926447987,
        AWATER: 45780185,
        INTPTLAT: '+34.7127046',
        INTPTLON: '-091.5412514',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0749, 35.4454],
            [-93.9157, 35.464],
            [-93.7566, 35.3694],
            [-93.8114, 35.2727],
            [-93.8671, 35.2279],
            [-93.9549, 35.23],
            [-94.0256, 35.4258],
            [-94.0757, 35.4271],
            [-94.0749, 35.4454],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504740',
        NAME: 'County Line School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 363720726,
        AWATER: 7306855,
        INTPTLAT: '+35.3559781',
        INTPTLON: '-093.9096152',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.157, 36.2612],
            [-92.0499, 36.2588],
            [-91.9977, 36.2065],
            [-92.0079, 36.1295],
            [-92.0373, 36.0033],
            [-92.088, 35.9897],
            [-92.0976, 36.0148],
            [-92.3059, 36.0322],
            [-92.2675, 36.1196],
            [-92.1599, 36.1738],
            [-92.157, 36.2612],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503840',
        NAME: 'Calico Rock School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 495019350,
        AWATER: 4327062,
        INTPTLAT: '+36.1176692',
        INTPTLON: '-092.1272287',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6808, 36.1188],
            [-91.6209, 36.1638],
            [-91.4728, 36.1174],
            [-91.5418, 36.0786],
            [-91.5164, 36.0018],
            [-91.3988, 36.0021],
            [-91.3923, 35.9072],
            [-91.5026, 35.893],
            [-91.5575, 35.8051],
            [-91.6498, 35.8069],
            [-91.7106, 35.9418],
            [-91.6918, 35.9822],
            [-91.7516, 36.1025],
            [-91.6808, 36.1188],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504050',
        NAME: 'Cave City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 737916608,
        AWATER: 485111,
        INTPTLAT: '+35.9819609',
        INTPTLON: '-091.5735120',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5672, 33.3672],
            [-92.5265, 33.3187],
            [-92.7262, 33.2809],
            [-92.7264, 33.3392],
            [-92.5672, 33.3672],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510620',
        NAME: 'Norphlet School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129800664,
        AWATER: 2997219,
        INTPTLAT: '+33.3292604',
        INTPTLON: '-092.6284068',
        ELSDLEA: '',
        UNSDLEA: '10620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3896, 33.278],
            [-92.3504, 33.2989],
            [-92.1375, 33.163],
            [-92.1096, 33.0341],
            [-92.0691, 33.0082],
            [-92.4525, 33.0118],
            [-92.5276, 33.1613],
            [-92.3901, 33.1557],
            [-92.3896, 33.278],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512930',
        NAME: 'Strong School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 751956965,
        AWATER: 34277085,
        INTPTLAT: '+33.1209517',
        INTPTLON: '-092.2972614',
        ELSDLEA: '',
        UNSDLEA: '12930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3873, 35.5264],
            [-91.3382, 35.7129],
            [-91.2876, 35.6532],
            [-91.1578, 35.6386],
            [-91.0388, 35.5892],
            [-91.0392, 35.4447],
            [-91.0585, 35.3546],
            [-91.2517, 35.3722],
            [-91.2529, 35.4458],
            [-91.3473, 35.4391],
            [-91.3873, 35.5264],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500023',
        NAME: 'Newport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 863736274,
        AWATER: 14433873,
        INTPTLAT: '+35.5101743',
        INTPTLON: '-091.2012825',
        ELSDLEA: '',
        UNSDLEA: '00023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6342, 35.443],
            [-91.5835, 35.4937],
            [-91.5054, 35.3895],
            [-91.4382, 35.3666],
            [-91.4379, 35.2971],
            [-91.3797, 35.2151],
            [-91.436, 35.1819],
            [-91.6092, 35.2055],
            [-91.606, 35.326],
            [-91.6404, 35.3663],
            [-91.6342, 35.443],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502700',
        NAME: 'Bald Knob School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 476458966,
        AWATER: 6456513,
        INTPTLAT: '+35.3126054',
        INTPTLON: '-091.5240844',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8437, 33.7918],
            [-91.7687, 33.792],
            [-91.769, 33.7047],
            [-91.7151, 33.6241],
            [-91.873, 33.5813],
            [-91.8733, 33.7336],
            [-91.8437, 33.7918],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509840',
        NAME: 'Monticello School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245310472,
        AWATER: 824958,
        INTPTLAT: '+33.6798090',
        INTPTLON: '-091.8029386',
        ELSDLEA: '',
        UNSDLEA: '09840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9902, 33.3982],
            [-91.6414, 33.3922],
            [-91.5964, 33.4132],
            [-91.4574, 33.389],
            [-91.4585, 33.3305],
            [-91.5526, 33.2742],
            [-91.3899, 33.2416],
            [-91.4604, 33.1702],
            [-91.4604, 33.0059],
            [-91.9243, 33.0076],
            [-91.9244, 33.0626],
            [-91.8379, 33.0905],
            [-91.9591, 33.267],
            [-92.0365, 33.2685],
            [-91.9773, 33.302],
            [-91.9902, 33.3982],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500042',
        NAME: 'Hamburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1886833317,
        AWATER: 10122706,
        INTPTLAT: '+33.2135091',
        INTPTLON: '-091.6951076',
        ELSDLEA: '',
        UNSDLEA: '00042',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2768, 35.1284],
            [-92.2841, 35.1903],
            [-92.1493, 35.3247],
            [-92.1147, 35.2343],
            [-92.0305, 35.2066],
            [-92.1166, 35.1498],
            [-92.2768, 35.1284],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510080',
        NAME: 'Mount Vernon-Enola School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276137932,
        AWATER: 231824,
        INTPTLAT: '+35.2156776',
        INTPTLON: '-092.1576295',
        ELSDLEA: '',
        UNSDLEA: '10080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0344, 34.2259],
            [-91.8904, 34.2323],
            [-91.7273, 34.1737],
            [-91.745, 34.094],
            [-91.9536, 34.0934],
            [-92.0266, 34.0638],
            [-92.0593, 34.0635],
            [-92.0737, 34.1502],
            [-92.0344, 34.2259],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500026',
        NAME: 'Pine Bluff School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 456826162,
        AWATER: 17327276,
        INTPTLAT: '+34.1636043',
        INTPTLON: '-091.8986291',
        ELSDLEA: '',
        UNSDLEA: '00026',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.208, 34.4928],
            [-92.1437, 34.4915],
            [-92.0694, 34.3912],
            [-92.0698, 34.2272],
            [-92.1604, 34.2292],
            [-92.1214, 34.3166],
            [-92.2105, 34.3182],
            [-92.208, 34.4928],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '514140',
        NAME: 'White Hall School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268031536,
        AWATER: 8917440,
        INTPTLAT: '+34.3617894',
        INTPTLON: '-092.1335114',
        ELSDLEA: '',
        UNSDLEA: '14140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3318, 34.026],
            [-93.2436, 34.0236],
            [-93.1731, 34.0874],
            [-93.0897, 33.9467],
            [-92.9507, 33.9638],
            [-92.9089, 33.9181],
            [-92.8965, 33.8062],
            [-93.1043, 33.777],
            [-93.1393, 33.8148],
            [-93.2906, 33.8451],
            [-93.3734, 33.9571],
            [-93.3318, 34.026],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507110',
        NAME: 'Gurdon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 878791815,
        AWATER: 2074310,
        INTPTLAT: '+33.9133506',
        INTPTLON: '-093.1390264',
        ELSDLEA: '',
        UNSDLEA: '07110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4931, 35.7592],
            [-94.3916, 35.7573],
            [-94.3726, 35.7572],
            [-94.2835, 35.7548],
            [-94.3069, 35.6092],
            [-94.3388, 35.543],
            [-94.4521, 35.5126],
            [-94.4547, 35.5284],
            [-94.4629, 35.58],
            [-94.4826, 35.6961],
            [-94.4931, 35.7592],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504080',
        NAME: 'Cedarville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 392099757,
        AWATER: 122824,
        INTPTLAT: '+35.6694918',
        INTPTLON: '-094.3895614',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3069, 35.6092],
            [-94.1121, 35.5622],
            [-94.0983, 35.4439],
            [-94.1614, 35.4353],
            [-94.3269, 35.5089],
            [-94.3388, 35.543],
            [-94.3069, 35.6092],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502250',
        NAME: 'Alma School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260725036,
        AWATER: 4130238,
        INTPTLAT: '+35.5229710',
        INTPTLON: '-094.2160532',
        ELSDLEA: '',
        UNSDLEA: '02250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2835, 35.7548],
            [-94.2654, 35.7543],
            [-94.0527, 35.763],
            [-93.9629, 35.7609],
            [-93.9115, 35.7308],
            [-93.9842, 35.6608],
            [-94.0737, 35.6625],
            [-94.0761, 35.5755],
            [-94.1121, 35.5622],
            [-94.3069, 35.6092],
            [-94.2835, 35.7548],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510260',
        NAME: 'Mountainburg Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 506095594,
        AWATER: 3116759,
        INTPTLAT: '+35.6872958',
        INTPTLON: '-094.1377891',
        ELSDLEA: '',
        UNSDLEA: '10260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4521, 35.5126],
            [-94.3388, 35.543],
            [-94.3269, 35.5089],
            [-94.1614, 35.4353],
            [-94.2786, 35.3596],
            [-94.431, 35.3937],
            [-94.4357, 35.4195],
            [-94.4521, 35.5126],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '513410',
        NAME: 'Van Buren School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279317379,
        AWATER: 18287528,
        INTPTLAT: '+35.4407541',
        INTPTLON: '-094.2988725',
        ELSDLEA: '',
        UNSDLEA: '13410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8961, 35.1707],
            [-92.8543, 35.2561],
            [-92.7048, 35.2609],
            [-92.6598, 35.3287],
            [-92.6224, 35.2759],
            [-92.5019, 35.2888],
            [-92.5297, 35.2283],
            [-92.5076, 35.1367],
            [-92.5547, 35.1133],
            [-92.7236, 35.0719],
            [-92.8582, 35.0738],
            [-92.9087, 35.0966],
            [-92.9619, 35.1185],
            [-92.8961, 35.1707],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512520',
        NAME: 'South Conway County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 681326761,
        AWATER: 29397621,
        INTPTLAT: '+35.1764454',
        INTPTLON: '-092.6872501',
        ELSDLEA: '',
        UNSDLEA: '12520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8708, 35.3292],
            [-92.8506, 35.4629],
            [-92.6025, 35.4585],
            [-92.6598, 35.3287],
            [-92.7048, 35.2609],
            [-92.8543, 35.2561],
            [-92.8708, 35.3292],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '514370',
        NAME: 'Wonderview School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410936781,
        AWATER: 6313364,
        INTPTLAT: '+35.3650153',
        INTPTLON: '-092.7572671',
        ELSDLEA: '',
        UNSDLEA: '14370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3579, 36.3595],
            [-94.3542, 36.316],
            [-94.502, 36.3039],
            [-94.5803, 36.3348],
            [-94.5172, 36.3947],
            [-94.3579, 36.3595],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504980',
        NAME: 'Decatur School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128521477,
        AWATER: 240508,
        INTPTLAT: '+36.3451708',
        INTPTLON: '-094.4675701',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1841, 36.4989],
            [-94.116, 36.4986],
            [-94.0318, 36.4988],
            [-94.0156, 36.4399],
            [-94.1508, 36.4029],
            [-94.1841, 36.4989],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503030',
        NAME: 'Pea Ridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136853172,
        AWATER: 32277,
        INTPTLAT: '+36.4664626',
        INTPTLON: '-094.0737164',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2801, 36.4993],
            [-94.2972, 36.4021],
            [-94.3579, 36.3595],
            [-94.5172, 36.3947],
            [-94.5803, 36.3348],
            [-94.5916, 36.3349],
            [-94.5931, 36.3439],
            [-94.6178, 36.4985],
            [-94.6179, 36.4994],
            [-94.2801, 36.4993],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506840',
        NAME: 'Gravette School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 387887347,
        AWATER: 2251590,
        INTPTLAT: '+36.4408116',
        INTPTLON: '-094.4582997',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3412, 36.121],
            [-94.2887, 36.1158],
            [-94.2183, 36.0349],
            [-94.2214, 36.0131],
            [-94.3048, 36.0142],
            [-94.3412, 36.121],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506090',
        NAME: 'Farmington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84506846,
        AWATER: 436141,
        INTPTLAT: '+36.0620761',
        INTPTLON: '-094.2795042',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5352, 36.0048],
            [-94.4167, 36.0567],
            [-94.4232, 36.0023],
            [-94.3369, 35.924],
            [-94.3916, 35.7573],
            [-94.4931, 35.7592],
            [-94.5174, 35.9008],
            [-94.5352, 36.0048],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508940',
        NAME: 'Lincoln School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 376597378,
        AWATER: 2290411,
        INTPTLAT: '+35.8879283',
        INTPTLON: '-094.4400118',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0989, 36.0103],
            [-93.9446, 36.0759],
            [-93.9615, 35.8066],
            [-94.0154, 35.8345],
            [-94.0989, 36.0103],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505760',
        NAME: 'Elkins School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259658748,
        AWATER: 1615614,
        INTPTLAT: '+35.9525811',
        INTPTLON: '-093.9967502',
        ELSDLEA: '',
        UNSDLEA: '05760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4167, 36.0567],
            [-94.3505, 36.1421],
            [-94.3412, 36.121],
            [-94.3048, 36.0142],
            [-94.2214, 36.0131],
            [-94.2518, 35.9417],
            [-94.3244, 35.8922],
            [-94.3726, 35.7572],
            [-94.3916, 35.7573],
            [-94.3369, 35.924],
            [-94.4232, 36.0023],
            [-94.4167, 36.0567],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511760',
        NAME: 'Prairie Grove School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270603451,
        AWATER: 2876955,
        INTPTLAT: '+35.9858181',
        INTPTLON: '-094.3426095',
        ELSDLEA: '',
        UNSDLEA: '11760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7082, 35.0908],
            [-93.6003, 35.1474],
            [-93.5298, 35.1454],
            [-93.457, 35.2158],
            [-93.3857, 35.214],
            [-93.3162, 35.1539],
            [-93.4051, 35.1418],
            [-93.4791, 35.0414],
            [-93.7044, 35.0465],
            [-93.7082, 35.0908],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500041',
        NAME: 'Western Yell County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 400070596,
        AWATER: 11263479,
        INTPTLAT: '+35.1128665',
        INTPTLON: '-093.5239970',
        ELSDLEA: '',
        UNSDLEA: '00041',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.8582, 35.0738],
              [-92.7236, 35.0719],
              [-92.7514, 35.0433],
              [-92.8582, 35.0738],
            ],
          ],
          [
            [
              [-93.7064, 34.9476],
              [-93.5135, 34.9769],
              [-93.375, 35.0395],
              [-93.2824, 35.0371],
              [-93.2992, 35.1285],
              [-93.1212, 35.1368],
              [-93.0866, 35.077],
              [-92.9619, 35.1185],
              [-92.9087, 35.0966],
              [-92.9662, 35],
              [-93.1603, 34.9906],
              [-93.2883, 34.861],
              [-93.2878, 34.7732],
              [-93.3584, 34.7733],
              [-93.3943, 34.7434],
              [-93.5699, 34.7439],
              [-93.7627, 34.7325],
              [-93.7602, 34.9049],
              [-93.7064, 34.9476],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500079',
        NAME: 'Two Rivers School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1582881168,
        AWATER: 15922662,
        INTPTLAT: '+34.9198680',
        INTPTLON: '-093.3918248',
        ELSDLEA: '',
        UNSDLEA: '00079',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4385, 35.2019],
            [-94.4377, 35.238],
            [-94.3752, 35.1484],
            [-94.4401, 35.1497],
            [-94.4385, 35.2019],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507170',
        NAME: 'Hackett Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76723613,
        AWATER: 1223467,
        INTPTLAT: '+35.1898820',
        INTPTLON: '-094.4009260',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4294, 35.3319],
            [-92.3056, 35.3647],
            [-92.2906, 35.2775],
            [-92.3953, 35.2793],
            [-92.4294, 35.3319],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507140',
        NAME: 'Guy-Perkins Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120126460,
        AWATER: 29173,
        INTPTLAT: '+35.3175238',
        INTPTLON: '-092.3363890',
        ELSDLEA: '',
        UNSDLEA: '07140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.286, 35.1292],
            [-92.2768, 35.1284],
            [-92.1166, 35.1498],
            [-92.1197, 35.0665],
            [-92.1213, 35.0122],
            [-92.2275, 35.0145],
            [-92.3567, 34.9744],
            [-92.286, 35.1292],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '513530',
        NAME: 'Vilonia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283156448,
        AWATER: 694991,
        INTPTLAT: '+35.0692948',
        INTPTLON: '-092.2307679',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4703, 34.1899],
            [-94.2963, 34.1917],
            [-94.2435, 34.1479],
            [-94.1566, 34.1923],
            [-94.1559, 34.0612],
            [-94.0697, 34.0011],
            [-94.0562, 33.9065],
            [-94.0918, 33.8271],
            [-94.2316, 33.8017],
            [-94.2268, 33.9623],
            [-94.2696, 33.9988],
            [-94.4019, 33.9809],
            [-94.4753, 34.0159],
            [-94.4703, 34.1899],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500049',
        NAME: 'De Queen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 980307733,
        AWATER: 6642893,
        INTPTLAT: '+34.0372783',
        INTPTLON: '-094.2653173',
        ELSDLEA: '',
        UNSDLEA: '00049',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3382, 35.7129],
            [-91.1986, 35.89],
            [-91.0326, 35.8851],
            [-91.037, 35.7084],
            [-90.9828, 35.7074],
            [-90.9837, 35.6213],
            [-91.0388, 35.5892],
            [-91.1578, 35.6386],
            [-91.2876, 35.6532],
            [-91.3382, 35.7129],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '513230',
        NAME: 'Jackson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 654894465,
        AWATER: 4914464,
        INTPTLAT: '+35.7395931',
        INTPTLON: '-091.1384165',
        ELSDLEA: '',
        UNSDLEA: '13230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2906, 35.2775],
            [-92.3056, 35.3647],
            [-92.3108, 35.4666],
            [-92.249, 35.5164],
            [-92.1517, 35.5225],
            [-92.1037, 35.465],
            [-92.0866, 35.4503],
            [-92.1493, 35.3247],
            [-92.2841, 35.1903],
            [-92.2906, 35.2775],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511880',
        NAME: 'Quitman School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 409234154,
        AWATER: 1562706,
        INTPTLAT: '+35.3863135',
        INTPTLON: '-092.2110271',
        ELSDLEA: '',
        UNSDLEA: '11880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5932, 36.2652],
            [-90.4269, 36.2596],
            [-90.321, 36.2006],
            [-90.1902, 36.2014],
            [-90.2356, 36.1417],
            [-90.4208, 36.1181],
            [-90.4466, 36.1731],
            [-90.5696, 36.1821],
            [-90.5932, 36.2652],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500016',
        NAME: 'Marmaduke School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316155534,
        AWATER: 188740,
        INTPTLAT: '+36.2011066',
        INTPTLON: '-090.4204621',
        ELSDLEA: '',
        UNSDLEA: '00016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8087, 36.0849],
            [-90.7474, 36.1491],
            [-90.5696, 36.1821],
            [-90.4466, 36.1731],
            [-90.4208, 36.1181],
            [-90.4216, 36.089],
            [-90.6363, 36.0561],
            [-90.8087, 36.0849],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500017',
        NAME: 'Paragould School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310604078,
        AWATER: 505243,
        INTPTLAT: '+36.1078289',
        INTPTLON: '-090.5866176',
        ELSDLEA: '',
        UNSDLEA: '00017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4651, 35.0895],
            [-91.436, 35.1819],
            [-91.3797, 35.2151],
            [-91.4379, 35.2971],
            [-91.4382, 35.3666],
            [-91.3473, 35.4391],
            [-91.2529, 35.4458],
            [-91.2517, 35.3722],
            [-91.25, 35.1817],
            [-91.1318, 35.104],
            [-91.2024, 34.9845],
            [-91.2931, 34.9851],
            [-91.3686, 34.9127],
            [-91.3638, 35.0519],
            [-91.4651, 35.0895],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502670',
        NAME: 'Augusta School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 925968664,
        AWATER: 15936253,
        INTPTLAT: '+35.1763583',
        INTPTLON: '-091.3133818',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0566, 34.3369],
            [-91.0519, 34.6457],
            [-90.8743, 34.6435],
            [-90.8213, 34.57],
            [-90.8212, 34.4967],
            [-90.8749, 34.409],
            [-90.716, 34.3736],
            [-90.713, 34.3638],
            [-90.7652, 34.2805],
            [-90.956, 34.1201],
            [-90.8718, 34.0768],
            [-90.8969, 34.0246],
            [-90.9879, 34.019],
            [-91.079, 33.9542],
            [-91.1356, 33.962],
            [-91.1943, 34.0709],
            [-91.0837, 34.1458],
            [-91.0566, 34.3369],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509510',
        NAME: 'Marvell School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1495462325,
        AWATER: 78457358,
        INTPTLAT: '+34.3003748',
        INTPTLON: '-090.9756376',
        ELSDLEA: '',
        UNSDLEA: '09510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6252, 34.4796],
            [-91.5944, 34.568],
            [-91.414, 34.5642],
            [-91.2915, 34.5618],
            [-91.2666, 34.5063],
            [-91.3886, 34.4904],
            [-91.4532, 34.3873],
            [-91.5978, 34.317],
            [-91.6336, 34.3783],
            [-91.6252, 34.4796],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512960',
        NAME: 'Stuttgart School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 528777363,
        AWATER: 21753935,
        INTPTLAT: '+34.4606791',
        INTPTLON: '-091.4931195',
        ELSDLEA: '',
        UNSDLEA: '12960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0852, 35.1421],
            [-94.0285, 35.2138],
            [-93.9549, 35.23],
            [-93.8671, 35.2279],
            [-93.8698, 35.1114],
            [-93.7082, 35.0908],
            [-93.7044, 35.0465],
            [-93.7049, 35.0197],
            [-93.9508, 35.0237],
            [-94.0745, 34.9973],
            [-94.0883, 35.0544],
            [-94.0852, 35.1421],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503450',
        NAME: 'Booneville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 520552175,
        AWATER: 6047735,
        INTPTLAT: '+35.0990110',
        INTPTLON: '-093.9278554',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3857, 35.214],
            [-93.3845, 35.2899],
            [-93.331, 35.3416],
            [-93.299, 35.3274],
            [-93.1469, 35.2207],
            [-93.0847, 35.1274],
            [-93.0165, 35.1647],
            [-92.8961, 35.1707],
            [-92.9619, 35.1185],
            [-93.0866, 35.077],
            [-93.1212, 35.1368],
            [-93.2992, 35.1285],
            [-93.3162, 35.1539],
            [-93.3857, 35.214],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504930',
        NAME: 'Dardanelle Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 454564617,
        AWATER: 26031690,
        INTPTLAT: '+35.1924543',
        INTPTLON: '-093.1985748',
        ELSDLEA: '',
        UNSDLEA: '04930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8671, 35.2279],
            [-93.8114, 35.2727],
            [-93.6703, 35.2216],
            [-93.6003, 35.1474],
            [-93.7082, 35.0908],
            [-93.8698, 35.1114],
            [-93.8671, 35.2279],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509150',
        NAME: 'Magazine Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308792670,
        AWATER: 4395446,
        INTPTLAT: '+35.1736421',
        INTPTLON: '-093.7636974',
        ELSDLEA: '',
        UNSDLEA: '09150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9354, 34.3501],
            [-93.5718, 34.3434],
            [-93.4493, 34.1752],
            [-93.7689, 34.1963],
            [-93.9362, 34.3083],
            [-93.9354, 34.3501],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508490',
        NAME: 'Kirby School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 499648609,
        AWATER: 14987275,
        INTPTLAT: '+34.2613946',
        INTPTLON: '-093.6942139',
        ELSDLEA: '',
        UNSDLEA: '08490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4703, 34.1899],
            [-94.4664, 34.3307],
            [-94.4623, 34.4629],
            [-94.292, 34.444],
            [-94.1991, 34.4808],
            [-94.1831, 34.3976],
            [-93.9349, 34.3938],
            [-93.9354, 34.3501],
            [-93.9362, 34.3083],
            [-94.0193, 34.218],
            [-94.1566, 34.1923],
            [-94.2435, 34.1479],
            [-94.2963, 34.1917],
            [-94.4703, 34.1899],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500405',
        NAME: 'Cossatot River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1231787118,
        AWATER: 6029456,
        INTPTLAT: '+34.3112650',
        INTPTLON: '-094.2339439',
        ELSDLEA: '',
        UNSDLEA: '00405',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7398, 34.8535],
            [-92.6512, 34.8517],
            [-92.6555, 34.7355],
            [-92.4433, 34.672],
            [-92.3995, 34.6264],
            [-92.3832, 34.5969],
            [-92.4038, 34.5104],
            [-92.4489, 34.5622],
            [-92.5514, 34.5779],
            [-92.5836, 34.6442],
            [-92.6967, 34.62],
            [-92.7087, 34.6932],
            [-92.9515, 34.728],
            [-93.0751, 34.8013],
            [-93.0739, 34.8581],
            [-92.7398, 34.8535],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503690',
        NAME: 'Bryant Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 886262362,
        AWATER: 7736495,
        INTPTLAT: '+34.7294711',
        INTPTLON: '-092.7246605',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9481, 34.3893],
            [-92.93, 34.4487],
            [-92.8161, 34.4155],
            [-92.7767, 34.4448],
            [-92.7586, 34.3571],
            [-92.6725, 34.2687],
            [-92.6784, 34.1496],
            [-92.4937, 34.1442],
            [-92.3368, 34.0596],
            [-92.4724, 34.0381],
            [-92.4756, 33.9727],
            [-92.5431, 34.0303],
            [-92.7343, 34.0217],
            [-92.8376, 34.0809],
            [-92.8666, 34.155],
            [-92.8518, 34.2192],
            [-93.0229, 34.2739],
            [-93.0209, 34.3324],
            [-92.9481, 34.3893],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509240',
        NAME: 'Malvern Special School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1181278895,
        AWATER: 3610479,
        INTPTLAT: '+34.1996585',
        INTPTLON: '-092.7176453',
        ELSDLEA: '',
        UNSDLEA: '09240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3708, 36.4983],
            [-90.2207, 36.4979],
            [-90.1523, 36.4979],
            [-90.1188, 36.4125],
            [-90.0636, 36.3842],
            [-90.078, 36.2883],
            [-90.1665, 36.3454],
            [-90.3053, 36.3753],
            [-90.3715, 36.4202],
            [-90.3708, 36.4983],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511370',
        NAME: 'Piggott School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 379551166,
        AWATER: 1026662,
        INTPTLAT: '+36.4137569',
        INTPTLON: '-090.2055300',
        ELSDLEA: '',
        UNSDLEA: '11370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6871, 35.681],
            [-91.6696, 35.7457],
            [-91.5236, 35.7063],
            [-91.5437, 35.6165],
            [-91.5782, 35.6608],
            [-91.6871, 35.681],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512540',
        NAME: 'Southside School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133301205,
        AWATER: 2172752,
        INTPTLAT: '+35.6868674',
        INTPTLON: '-091.5962150',
        ELSDLEA: '',
        UNSDLEA: '12540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9422, 35.942],
            [-92.8126, 35.9675],
            [-92.6984, 35.9116],
            [-92.6416, 36.0229],
            [-92.5529, 36.1079],
            [-92.4808, 36.1209],
            [-92.4774, 36.0922],
            [-92.4115, 36.0628],
            [-92.4123, 36.0339],
            [-92.4167, 35.7886],
            [-92.4318, 35.7887],
            [-92.5541, 35.79],
            [-92.6496, 35.7042],
            [-92.8105, 35.6936],
            [-92.9513, 35.7247],
            [-92.9422, 35.942],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509480',
        NAME: 'Searcy County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1395749408,
        AWATER: 3772387,
        INTPTLAT: '+35.8820193',
        INTPTLON: '-092.6470339',
        ELSDLEA: '',
        UNSDLEA: '09480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8312, 36.4575],
            [-92.7849, 36.4977],
            [-92.7723, 36.4977],
            [-92.7622, 36.4979],
            [-92.7074, 36.4316],
            [-92.6791, 36.3582],
            [-92.7235, 36.3151],
            [-92.5154, 36.1908],
            [-92.4808, 36.1209],
            [-92.5529, 36.1079],
            [-92.6416, 36.0229],
            [-92.706, 36.1252],
            [-92.7058, 36.1916],
            [-92.8121, 36.2031],
            [-92.7865, 36.2689],
            [-92.8325, 36.3506],
            [-92.8312, 36.4575],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '514490',
        NAME: 'Yellville-Summit School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 664435848,
        AWATER: 20811727,
        INTPTLAT: '+36.2359881',
        INTPTLON: '-092.6829240',
        ELSDLEA: '',
        UNSDLEA: '14490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4123, 36.0339],
            [-92.3059, 36.0322],
            [-92.0976, 36.0148],
            [-92.088, 35.9897],
            [-91.8494, 35.8674],
            [-91.8364, 35.7271],
            [-91.9608, 35.7501],
            [-92.0232, 35.7075],
            [-92.2405, 35.7122],
            [-92.3828, 35.7153],
            [-92.4167, 35.7886],
            [-92.4123, 36.0339],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510200',
        NAME: 'Mountain View School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1469127927,
        AWATER: 5996154,
        INTPTLAT: '+35.8406275',
        INTPTLON: '-092.1676262',
        ELSDLEA: '',
        UNSDLEA: '10200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0232, 35.7075],
            [-91.9608, 35.7501],
            [-91.8364, 35.7271],
            [-91.7588, 35.6811],
            [-91.794, 35.5341],
            [-91.8817, 35.4561],
            [-91.9462, 35.4578],
            [-91.9221, 35.5653],
            [-92.0029, 35.5765],
            [-92.0232, 35.7075],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504560',
        NAME: 'Concord Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 526373216,
        AWATER: 447148,
        INTPTLAT: '+35.6063388',
        INTPTLON: '-091.9042136',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0073, 35.4183],
            [-91.8817, 35.4561],
            [-91.794, 35.5341],
            [-91.7275, 35.4742],
            [-91.6989, 35.3998],
            [-91.9802, 35.3596],
            [-92.0073, 35.4183],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511070',
        NAME: 'Pangburn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274533567,
        AWATER: 0,
        INTPTLAT: '+35.4242647',
        INTPTLON: '-091.8356608',
        ELSDLEA: '',
        UNSDLEA: '11070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3159, 35.5369],
            [-92.3156, 35.5514],
            [-92.2441, 35.5698],
            [-92.2405, 35.7122],
            [-92.0232, 35.7075],
            [-92.0029, 35.5765],
            [-92.0988, 35.5169],
            [-92.1037, 35.465],
            [-92.1517, 35.5225],
            [-92.249, 35.5164],
            [-92.3159, 35.5369],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '514040',
        NAME: 'West Side School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 383056721,
        AWATER: 86645783,
        INTPTLAT: '+35.6035645',
        INTPTLON: '-092.1424011',
        ELSDLEA: '',
        UNSDLEA: '14040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5567, 35.0393],
            [-92.5547, 35.1133],
            [-92.5076, 35.1367],
            [-92.4345, 35.1573],
            [-92.286, 35.1292],
            [-92.3567, 34.9744],
            [-92.5241, 34.9922],
            [-92.5567, 35.0393],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504590',
        NAME: 'Conway Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302328399,
        AWATER: 21433910,
        INTPTLAT: '+35.0698412',
        INTPTLON: '-092.4293688',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5567, 35.0393],
            [-92.5241, 34.9922],
            [-92.3567, 34.9744],
            [-92.2275, 35.0145],
            [-92.2995, 34.9289],
            [-92.5443, 34.9533],
            [-92.5567, 35.0393],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509540',
        NAME: 'Mayflower School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198802970,
        AWATER: 18839178,
        INTPTLAT: '+34.9675169',
        INTPTLON: '-092.4211397',
        ELSDLEA: '',
        UNSDLEA: '09540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0499, 36.2588],
            [-91.9874, 36.2572],
            [-91.7136, 36.2536],
            [-91.6808, 36.1188],
            [-91.7516, 36.1025],
            [-92.0079, 36.1295],
            [-91.9977, 36.2065],
            [-92.0499, 36.2588],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500021',
        NAME: 'Izard Cty Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 466422827,
        AWATER: 3358616,
        INTPTLAT: '+36.1888930',
        INTPTLON: '-091.8544768',
        ELSDLEA: '',
        UNSDLEA: '00021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6552, 33.834],
            [-92.5557, 33.8592],
            [-92.481, 33.7994],
            [-92.3787, 33.7968],
            [-92.3308, 33.708],
            [-92.3201, 33.6824],
            [-92.3235, 33.6373],
            [-92.4232, 33.7078],
            [-92.4503, 33.6458],
            [-92.6919, 33.655],
            [-92.7428, 33.6799],
            [-92.7572, 33.8066],
            [-92.6552, 33.834],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500032',
        NAME: 'Bearden School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 650865668,
        AWATER: 821187,
        INTPTLAT: '+33.7364934',
        INTPTLON: '-092.5621022',
        ELSDLEA: '',
        UNSDLEA: '00032',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1194, 35.9979],
            [-89.9594, 35.9991],
            [-89.9402, 35.9993],
            [-90.0004, 35.9302],
            [-89.9991, 35.8141],
            [-90.1082, 35.8145],
            [-90.1318, 35.9601],
            [-90.1194, 35.9979],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500029',
        NAME: 'Gosnell School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279222198,
        AWATER: 9093868,
        INTPTLAT: '+35.9127695',
        INTPTLON: '-090.0467763',
        ELSDLEA: '',
        UNSDLEA: '00029',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7871, 36.0006],
            [-89.7331, 36.0006],
            [-89.6448, 35.9044],
            [-89.7017, 35.8242],
            [-89.8018, 35.8264],
            [-89.7871, 36.0006],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502550',
        NAME: 'Armorel School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151557512,
        AWATER: 18424830,
        INTPTLAT: '+35.9213454',
        INTPTLON: '-089.7439912',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4507, 34.8401],
            [-94.4478, 34.9339],
            [-94.3371, 34.9484],
            [-94.2301, 34.9858],
            [-94.1014, 34.9837],
            [-94.0883, 35.0544],
            [-94.0745, 34.9973],
            [-93.9508, 35.0237],
            [-93.7049, 35.0197],
            [-93.7064, 34.9476],
            [-93.7602, 34.9049],
            [-93.7627, 34.7325],
            [-93.8702, 34.7127],
            [-93.9305, 34.6656],
            [-94.0352, 34.6826],
            [-94.3177, 34.6974],
            [-94.3847, 34.8294],
            [-94.451, 34.8307],
            [-94.4507, 34.8401],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '513680',
        NAME: 'Waldron School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1968478419,
        AWATER: 13586152,
        INTPTLAT: '+34.8531973',
        INTPTLON: '-094.0519539',
        ELSDLEA: '',
        UNSDLEA: '13680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1375, 33.163],
            [-92.0365, 33.2685],
            [-91.9591, 33.267],
            [-91.8379, 33.0905],
            [-91.9244, 33.0626],
            [-91.9243, 33.0076],
            [-92.0691, 33.0082],
            [-92.1096, 33.0341],
            [-92.1375, 33.163],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504800',
        NAME: 'Crossett School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 530632753,
        AWATER: 26240573,
        INTPTLAT: '+33.1324603',
        INTPTLON: '-092.0043174',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-93.8944, 35.7015],
              [-93.8563, 35.773],
              [-93.696, 35.7686],
              [-93.7021, 35.5913],
              [-93.6538, 35.5646],
              [-93.7028, 35.566],
              [-93.7101, 35.3752],
              [-93.7566, 35.3694],
              [-93.9157, 35.464],
              [-93.8604, 35.6574],
              [-93.8944, 35.7015],
            ],
          ],
          [
            [
              [-93.9115, 35.7308],
              [-93.9125, 35.7019],
              [-93.933, 35.609],
              [-94.0761, 35.5755],
              [-94.0737, 35.6625],
              [-93.9842, 35.6608],
              [-93.9115, 35.7308],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511010',
        NAME: 'Ozark School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 839653488,
        AWATER: 14064658,
        INTPTLAT: '+35.5889147',
        INTPTLON: '-093.8289617',
        ELSDLEA: '',
        UNSDLEA: '11010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6378, 36.4618],
            [-92.6306, 36.4982],
            [-92.6157, 36.4982],
            [-92.2595, 36.498],
            [-92.1503, 36.4986],
            [-92.1678, 36.3489],
            [-92.2553, 36.3073],
            [-92.4259, 36.1783],
            [-92.4693, 36.2534],
            [-92.4614, 36.356],
            [-92.5336, 36.3507],
            [-92.6378, 36.4618],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500043',
        NAME: 'Mountain Home School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 826168442,
        AWATER: 80346028,
        INTPTLAT: '+36.3896846',
        INTPTLON: '-092.3885643',
        ELSDLEA: '',
        UNSDLEA: '00043',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5336, 36.3507],
            [-92.4614, 36.356],
            [-92.4693, 36.2534],
            [-92.5336, 36.3507],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504680',
        NAME: 'Cotter School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86857710,
        AWATER: 1831786,
        INTPTLAT: '+36.3055482',
        INTPTLON: '-092.5032312',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4259, 36.1783],
            [-92.2553, 36.3073],
            [-92.157, 36.2612],
            [-92.1599, 36.1738],
            [-92.2675, 36.1196],
            [-92.3059, 36.0322],
            [-92.4123, 36.0339],
            [-92.4115, 36.0628],
            [-92.4105, 36.092],
            [-92.4259, 36.1783],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510560',
        NAME: 'Norfork Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422221311,
        AWATER: 17223954,
        INTPTLAT: '+36.1703715',
        INTPTLON: '-092.2924258',
        ELSDLEA: '',
        UNSDLEA: '10560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2518, 35.9417],
            [-94.2214, 36.0131],
            [-94.2183, 36.0349],
            [-94.0989, 36.0103],
            [-94.0154, 35.8345],
            [-94.0527, 35.763],
            [-94.2654, 35.7543],
            [-94.2638, 35.7978],
            [-94.1287, 35.8555],
            [-94.1092, 35.9068],
            [-94.191, 35.9619],
            [-94.2518, 35.9417],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506930',
        NAME: 'Greenland Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351162640,
        AWATER: 1718687,
        INTPTLAT: '+35.8182840',
        INTPTLON: '-094.0817393',
        ELSDLEA: '',
        UNSDLEA: '06930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1144, 36.1263],
            [-94.0155, 36.1071],
            [-93.9216, 36.1488],
            [-93.9446, 36.0759],
            [-94.0989, 36.0103],
            [-94.2183, 36.0349],
            [-94.2887, 36.1158],
            [-94.1144, 36.1263],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506120',
        NAME: 'Fayetteville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 289450121,
        AWATER: 4775165,
        INTPTLAT: '+36.0816245',
        INTPTLON: '-094.1029435',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-94.0154, 35.8345],
              [-93.9615, 35.8066],
              [-93.9629, 35.7609],
              [-94.0527, 35.763],
              [-94.0154, 35.8345],
            ],
          ],
          [
            [
              [-94.2518, 35.9417],
              [-94.191, 35.9619],
              [-94.1092, 35.9068],
              [-94.1287, 35.8555],
              [-94.2638, 35.7978],
              [-94.2654, 35.7543],
              [-94.2835, 35.7548],
              [-94.3726, 35.7572],
              [-94.3244, 35.8922],
              [-94.2518, 35.9417],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '514010',
        NAME: 'West Fork School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338900040,
        AWATER: 1525695,
        INTPTLAT: '+35.8486353',
        INTPTLON: '-094.1989930',
        ELSDLEA: '',
        UNSDLEA: '14010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6314, 35.5639],
            [-93.4886, 35.589],
            [-93.392, 35.5364],
            [-93.4348, 35.4275],
            [-93.5485, 35.401],
            [-93.5455, 35.4814],
            [-93.6314, 35.5639],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504380',
        NAME: 'Clarksville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287268022,
        AWATER: 14391592,
        INTPTLAT: '+35.5091674',
        INTPTLON: '-093.5156189',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6538, 35.5646],
            [-93.6314, 35.5639],
            [-93.5455, 35.4814],
            [-93.5485, 35.401],
            [-93.6475, 35.3896],
            [-93.7101, 35.3752],
            [-93.7028, 35.566],
            [-93.6538, 35.5646],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '514020',
        NAME: 'Westside School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216745015,
        AWATER: 10803788,
        INTPTLAT: '+35.4664017',
        INTPTLON: '-093.6401147',
        ELSDLEA: '',
        UNSDLEA: '14020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2935, 36.4983],
            [-93.1512, 36.498],
            [-93.0144, 36.4981],
            [-93.0137, 36.4981],
            [-93.0925, 36.4218],
            [-93.2413, 36.3694],
            [-93.2962, 36.3815],
            [-93.2935, 36.4983],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510920',
        NAME: 'Omaha School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242595263,
        AWATER: 2851486,
        INTPTLAT: '+36.4392940',
        INTPTLON: '-093.1776662',
        ELSDLEA: '',
        UNSDLEA: '10920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4758, 33.6947],
            [-93.3955, 33.7222],
            [-93.2453, 33.7326],
            [-93.1097, 33.6281],
            [-93.054, 33.5833],
            [-93.1119, 33.5699],
            [-93.1684, 33.4537],
            [-93.2566, 33.4423],
            [-93.3656, 33.4445],
            [-93.483, 33.4799],
            [-93.4758, 33.6947],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500030',
        NAME: 'Nevada School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 893942176,
        AWATER: 2178492,
        INTPTLAT: '+33.5844530',
        INTPTLON: '-093.3049206',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8863, 34.1559],
            [-92.8666, 34.155],
            [-92.8376, 34.0809],
            [-92.7343, 34.0217],
            [-92.6845, 33.9774],
            [-92.6552, 33.834],
            [-92.7572, 33.8066],
            [-92.7428, 33.6799],
            [-92.6919, 33.655],
            [-92.7148, 33.5569],
            [-92.7759, 33.5442],
            [-92.8772, 33.6725],
            [-92.8965, 33.8062],
            [-92.9089, 33.9181],
            [-92.9507, 33.9638],
            [-92.8908, 34.0411],
            [-92.8863, 34.1559],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507290',
        NAME: 'Harmony Grove School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 934562682,
        AWATER: 6446578,
        INTPTLAT: '+33.8404576',
        INTPTLON: '-092.8184157',
        ELSDLEA: '',
        UNSDLEA: '07290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2566, 33.4423],
            [-93.1684, 33.4537],
            [-93.1119, 33.5699],
            [-93.054, 33.5833],
            [-92.9279, 33.5514],
            [-92.9442, 33.3764],
            [-92.9785, 33.3773],
            [-92.9954, 33.3779],
            [-93.0409, 33.357],
            [-93.2056, 33.3108],
            [-93.2571, 33.3263],
            [-93.2566, 33.4423],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512870',
        NAME: 'Stephens School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 580300706,
        AWATER: 657462,
        INTPTLAT: '+33.4332961',
        INTPTLON: '-093.0863136',
        ELSDLEA: '',
        UNSDLEA: '12870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5335, 35.5288],
            [-91.3873, 35.5264],
            [-91.3473, 35.4391],
            [-91.4382, 35.3666],
            [-91.5054, 35.3895],
            [-91.5835, 35.4937],
            [-91.5335, 35.5288],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503480',
        NAME: 'Bradford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 237934171,
        AWATER: 2394185,
        INTPTLAT: '+35.4668823',
        INTPTLON: '-091.4624404',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1166, 35.1498],
            [-92.0305, 35.2066],
            [-91.9517, 35.2275],
            [-91.7352, 35.1807],
            [-91.7082, 35.0626],
            [-91.8027, 35.0087],
            [-92.0015, 35.0623],
            [-92.1197, 35.0665],
            [-92.1166, 35.1498],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '502880',
        NAME: 'Beebe School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 608990603,
        AWATER: 1501242,
        INTPTLAT: '+35.1166652',
        INTPTLON: '-091.9010117',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7352, 35.1807],
            [-91.7097, 35.274],
            [-91.6436, 35.2796],
            [-91.6404, 35.3663],
            [-91.606, 35.326],
            [-91.6092, 35.2055],
            [-91.436, 35.1819],
            [-91.4651, 35.0895],
            [-91.584, 35.0916],
            [-91.5851, 35.0252],
            [-91.7082, 35.0626],
            [-91.7352, 35.1807],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508400',
        NAME: 'Riverview School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 474839746,
        AWATER: 5947076,
        INTPTLAT: '+35.1539260',
        INTPTLON: '-091.6084825',
        ELSDLEA: '',
        UNSDLEA: '08400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3235, 33.6373],
            [-92.3201, 33.6824],
            [-92.2541, 33.7055],
            [-92.0375, 33.7044],
            [-91.9758, 33.7044],
            [-92.0079, 33.5294],
            [-92.1151, 33.5299],
            [-92.2203, 33.5792],
            [-92.2019, 33.4718],
            [-92.3117, 33.5028],
            [-92.3235, 33.6373],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500006',
        NAME: 'Warren School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 619947845,
        AWATER: 2171151,
        INTPTLAT: '+33.6126113',
        INTPTLON: '-092.1272382',
        ELSDLEA: '',
        UNSDLEA: '00006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6989, 35.3998],
            [-91.7275, 35.4742],
            [-91.6342, 35.443],
            [-91.6404, 35.3663],
            [-91.6436, 35.2796],
            [-91.7097, 35.274],
            [-91.6989, 35.3998],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504170',
        NAME: 'White County Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153649407,
        AWATER: 353125,
        INTPTLAT: '+35.3814759',
        INTPTLON: '-091.6849861',
        ELSDLEA: '',
        UNSDLEA: '04170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1121, 35.5622],
            [-94.0761, 35.5755],
            [-93.933, 35.609],
            [-93.9125, 35.7019],
            [-93.8944, 35.7015],
            [-93.8604, 35.6574],
            [-93.9157, 35.464],
            [-94.0749, 35.4454],
            [-94.0983, 35.4439],
            [-94.1121, 35.5622],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510290',
        NAME: 'Mulberry School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 312954359,
        AWATER: 13180833,
        INTPTLAT: '+35.5422899',
        INTPTLON: '-093.9916665',
        ELSDLEA: '',
        UNSDLEA: '10290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.707, 33.2225],
            [-93.4833, 33.2164],
            [-93.4306, 33.2506],
            [-93.3792, 33.2496],
            [-93.3847, 33.0982],
            [-93.1933, 33.1801],
            [-93.091, 33.178],
            [-92.986, 33.1164],
            [-92.9887, 33.0174],
            [-93.2386, 33.018],
            [-93.521, 33.0186],
            [-93.8049, 33.0194],
            [-93.8501, 33.1453],
            [-93.707, 33.2225],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500068',
        NAME: 'Emerson-Taylor-Bradley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1390883622,
        AWATER: 32059032,
        INTPTLAT: '+33.1067133',
        INTPTLON: '-093.4363125',
        ELSDLEA: '',
        UNSDLEA: '00068',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3656, 33.4445],
            [-93.2566, 33.4423],
            [-93.2571, 33.3263],
            [-93.2056, 33.3108],
            [-93.0409, 33.357],
            [-92.9954, 33.3779],
            [-92.9785, 33.3773],
            [-92.9844, 33.1599],
            [-92.986, 33.1164],
            [-93.091, 33.178],
            [-93.1933, 33.1801],
            [-93.3847, 33.0982],
            [-93.3792, 33.2496],
            [-93.4306, 33.2506],
            [-93.3656, 33.4445],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500044',
        NAME: 'Magnolia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 984427033,
        AWATER: 993559,
        INTPTLAT: '+33.2601349',
        INTPTLON: '-093.2214419',
        ELSDLEA: '',
        UNSDLEA: '00044',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0434, 33.5523],
            [-93.9356, 33.5502],
            [-93.8259, 33.6099],
            [-93.7727, 33.4985],
            [-93.7267, 33.4859],
            [-93.7229, 33.4822],
            [-93.8563, 33.4386],
            [-93.9427, 33.4439],
            [-93.9711, 33.3428],
            [-94.043, 33.2712],
            [-94.0431, 33.4054],
            [-94.0433, 33.5283],
            [-94.0434, 33.5523],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '513110',
        NAME: 'Texarkana School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496399694,
        AWATER: 9616814,
        INTPTLAT: '+33.4682478',
        INTPTLON: '-093.9287349',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4587, 34.594],
            [-94.4561, 34.6798],
            [-94.3362, 34.6232],
            [-94.1614, 34.5772],
            [-94.124, 34.6418],
            [-94.0352, 34.6826],
            [-93.9305, 34.6656],
            [-93.933, 34.5215],
            [-93.9349, 34.3938],
            [-94.1831, 34.3976],
            [-94.1991, 34.4808],
            [-94.292, 34.444],
            [-94.4623, 34.4629],
            [-94.4587, 34.594],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509750',
        NAME: 'Mena Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1118277255,
        AWATER: 6680238,
        INTPTLAT: '+34.5358214',
        INTPTLON: '-094.1806632',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8563, 33.4386],
            [-93.7229, 33.4822],
            [-93.6227, 33.4794],
            [-93.483, 33.4799],
            [-93.3656, 33.4445],
            [-93.4306, 33.2506],
            [-93.4833, 33.2164],
            [-93.707, 33.2225],
            [-93.782, 33.2669],
            [-93.7761, 33.3466],
            [-93.8563, 33.4386],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500065',
        NAME: 'Lafayette County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 984693519,
        AWATER: 21226487,
        INTPTLAT: '+33.3696976',
        INTPTLON: '-093.6093575',
        ELSDLEA: '',
        UNSDLEA: '00065',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7178, 33.9966],
            [-93.529, 33.9444],
            [-93.4567, 33.9568],
            [-93.4609, 33.825],
            [-93.3955, 33.7222],
            [-93.4758, 33.6947],
            [-93.7054, 33.8025],
            [-93.7449, 33.8488],
            [-93.7178, 33.9966],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503300',
        NAME: 'Blevins School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 599966859,
        AWATER: 1879879,
        INTPTLAT: '+33.8514342',
        INTPTLON: '-093.5807033',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4567, 33.9568],
            [-93.3734, 33.9571],
            [-93.2906, 33.8451],
            [-93.1393, 33.8148],
            [-93.1043, 33.777],
            [-93.1097, 33.6281],
            [-93.2453, 33.7326],
            [-93.3955, 33.7222],
            [-93.4609, 33.825],
            [-93.4567, 33.9568],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511820',
        NAME: 'Prescott School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 610963917,
        AWATER: 4500810,
        INTPTLAT: '+33.8002985',
        INTPTLON: '-093.3046341',
        ELSDLEA: '',
        UNSDLEA: '11820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9349, 34.3938],
            [-93.933, 34.5215],
            [-93.8103, 34.5207],
            [-93.7568, 34.5853],
            [-93.7489, 34.5214],
            [-93.512, 34.4725],
            [-93.4069, 34.4192],
            [-93.4081, 34.3395],
            [-93.5718, 34.3434],
            [-93.9354, 34.3501],
            [-93.9349, 34.3938],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503770',
        NAME: 'Caddo Hills School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 828908478,
        AWATER: 2566529,
        INTPTLAT: '+34.4341853',
        INTPTLON: '-093.7013364',
        ELSDLEA: '',
        UNSDLEA: '03770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4756, 33.9727],
            [-92.3382, 33.8325],
            [-92.3308, 33.708],
            [-92.3787, 33.7968],
            [-92.481, 33.7994],
            [-92.5557, 33.8592],
            [-92.6552, 33.834],
            [-92.6845, 33.9774],
            [-92.7343, 34.0217],
            [-92.5431, 34.0303],
            [-92.4756, 33.9727],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506210',
        NAME: 'Fordyce School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 571080426,
        AWATER: 1297646,
        INTPTLAT: '+33.8954841',
        INTPTLON: '-092.5429901',
        ELSDLEA: '',
        UNSDLEA: '06210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7759, 33.5442],
            [-92.7148, 33.5569],
            [-92.6919, 33.655],
            [-92.4503, 33.6458],
            [-92.4232, 33.7078],
            [-92.3235, 33.6373],
            [-92.3117, 33.5028],
            [-92.3821, 33.346],
            [-92.3504, 33.2989],
            [-92.3896, 33.278],
            [-92.5265, 33.3187],
            [-92.5672, 33.3672],
            [-92.7236, 33.4596],
            [-92.7759, 33.5442],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507230',
        NAME: 'Hampton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1242279824,
        AWATER: 8125129,
        INTPTLAT: '+33.5032032',
        INTPTLON: '-092.5171207',
        ELSDLEA: '',
        UNSDLEA: '07230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1508, 36.4029],
            [-94.0156, 36.4399],
            [-94.0318, 36.4988],
            [-93.9895, 36.4987],
            [-93.9266, 36.4987],
            [-93.8668, 36.4987],
            [-93.8668, 36.3945],
            [-93.8156, 36.3066],
            [-93.8878, 36.235],
            [-94.2088, 36.2412],
            [-94.1508, 36.4029],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511970',
        NAME: 'Rogers Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 594626132,
        AWATER: 75720999,
        INTPTLAT: '+36.3462107',
        INTPTLON: '-094.0267533',
        ELSDLEA: '',
        UNSDLEA: '11970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3505, 36.1421],
            [-94.3156, 36.2143],
            [-94.2925, 36.2282],
            [-94.2088, 36.2412],
            [-93.8878, 36.235],
            [-93.9216, 36.1488],
            [-94.0155, 36.1071],
            [-94.1144, 36.1263],
            [-94.2887, 36.1158],
            [-94.3412, 36.121],
            [-94.3505, 36.1421],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512660',
        NAME: 'Springdale School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 457943496,
        AWATER: 18194936,
        INTPTLAT: '+36.1826970',
        INTPTLON: '-094.1198368',
        ELSDLEA: '',
        UNSDLEA: '12660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2801, 36.4993],
            [-94.1841, 36.4989],
            [-94.1508, 36.4029],
            [-94.2088, 36.2412],
            [-94.2925, 36.2282],
            [-94.3542, 36.316],
            [-94.3579, 36.3595],
            [-94.2972, 36.4021],
            [-94.2801, 36.4993],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503060',
        NAME: 'Bentonville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365985340,
        AWATER: 3600024,
        INTPTLAT: '+36.3669692',
        INTPTLON: '-094.2540750',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5916, 36.3349],
            [-94.5803, 36.3348],
            [-94.502, 36.3039],
            [-94.3542, 36.316],
            [-94.2925, 36.2282],
            [-94.3156, 36.2143],
            [-94.4957, 36.2097],
            [-94.5811, 36.2693],
            [-94.5916, 36.3349],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506540',
        NAME: 'Gentry Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222266291,
        AWATER: 2346912,
        INTPTLAT: '+36.2626868',
        INTPTLON: '-094.4510573',
        ELSDLEA: '',
        UNSDLEA: '06540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5811, 36.2693],
            [-94.4957, 36.2097],
            [-94.3156, 36.2143],
            [-94.3505, 36.1421],
            [-94.4167, 36.0567],
            [-94.5352, 36.0048],
            [-94.5499, 36.0894],
            [-94.5627, 36.1619],
            [-94.5678, 36.1909],
            [-94.5811, 36.2693],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512450',
        NAME: 'Siloam Springs Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 369888829,
        AWATER: 3820291,
        INTPTLAT: '+36.1454492',
        INTPTLON: '-094.4550310',
        ELSDLEA: '',
        UNSDLEA: '12450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0697, 34.0011],
            [-93.9985, 34.0718],
            [-93.884, 34.098],
            [-93.8705, 34.1853],
            [-93.8221, 34.1257],
            [-93.8231, 34.0089],
            [-93.7178, 33.9966],
            [-93.7449, 33.8488],
            [-93.8286, 33.8344],
            [-93.8955, 33.9237],
            [-94.0562, 33.9065],
            [-94.0697, 34.0011],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '510380',
        NAME: 'Nashville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 611591237,
        AWATER: 1919570,
        INTPTLAT: '+33.9870338',
        INTPTLON: '-093.8840370',
        ELSDLEA: '',
        UNSDLEA: '10380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1566, 34.1923],
            [-94.0193, 34.218],
            [-93.9362, 34.3083],
            [-93.7689, 34.1963],
            [-93.8705, 34.1853],
            [-93.884, 34.098],
            [-93.9985, 34.0718],
            [-94.0697, 34.0011],
            [-94.1559, 34.0612],
            [-94.1566, 34.1923],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505340',
        NAME: 'Dierks School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 587429064,
        AWATER: 3111800,
        INTPTLAT: '+34.1523713',
        INTPTLON: '-093.9934650',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6799, 36.4991],
            [-93.5843, 36.4989],
            [-93.4748, 36.4988],
            [-93.4588, 36.4388],
            [-93.4903, 36.3075],
            [-93.4473, 36.1828],
            [-93.4335, 36.1387],
            [-93.6023, 36.1863],
            [-93.6329, 36.2016],
            [-93.619, 36.2362],
            [-93.6793, 36.4201],
            [-93.6799, 36.4991],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503150',
        NAME: 'Berryville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 566473482,
        AWATER: 200523,
        INTPTLAT: '+36.3483005',
        INTPTLON: '-093.5508455',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0435, 35.104],
            [-91.0427, 35.1482],
            [-90.503, 35.1434],
            [-90.55, 34.9924],
            [-90.6198, 34.9064],
            [-90.8411, 34.9063],
            [-90.9027, 34.9998],
            [-90.9181, 35.0885],
            [-91.044, 35.0734],
            [-91.0435, 35.104],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506270',
        NAME: 'Forrest City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 880286506,
        AWATER: 14264959,
        INTPTLAT: '+35.0445671',
        INTPTLON: '-090.7539645',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.503, 35.1434],
            [-90.503, 35.1442],
            [-90.4004, 35.1482],
            [-90.402, 35.061],
            [-90.3001, 35.0303],
            [-90.3093, 34.9957],
            [-90.2461, 34.9443],
            [-90.3045, 34.8606],
            [-90.408, 34.833],
            [-90.4085, 34.9041],
            [-90.6198, 34.9064],
            [-90.55, 34.9924],
            [-90.503, 35.1434],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508010',
        NAME: 'Hughes School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 585854439,
        AWATER: 39366075,
        INTPTLAT: '+34.9833278',
        INTPTLON: '-090.4285729',
        ELSDLEA: '',
        UNSDLEA: '08010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9364, 36.4986],
            [-91.9258, 36.4986],
            [-91.7085, 36.4994],
            [-91.673, 36.37],
            [-91.7136, 36.2536],
            [-91.9874, 36.2572],
            [-91.9847, 36.3451],
            [-91.8991, 36.3554],
            [-91.8489, 36.431],
            [-91.9364, 36.4986],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512090',
        NAME: 'Salem School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 586468685,
        AWATER: 309548,
        INTPTLAT: '+36.3681822',
        INTPTLON: '-091.8154116',
        ELSDLEA: '',
        UNSDLEA: '12090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1503, 36.4986],
            [-92.1125, 36.4989],
            [-91.9364, 36.4986],
            [-91.8489, 36.431],
            [-91.8991, 36.3554],
            [-91.9847, 36.3451],
            [-91.9874, 36.2572],
            [-92.0499, 36.2588],
            [-92.157, 36.2612],
            [-92.2553, 36.3073],
            [-92.1678, 36.3489],
            [-92.1503, 36.4986],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '513560',
        NAME: 'Viola School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 597041084,
        AWATER: 15724971,
        INTPTLAT: '+36.3802040',
        INTPTLON: '-092.0606676',
        ELSDLEA: '',
        UNSDLEA: '13560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.1437, 34.4915],
              [-92.03, 34.4894],
              [-91.8141, 34.4842],
              [-91.8148, 34.3966],
              [-91.7079, 34.3952],
              [-91.7118, 34.2339],
              [-91.5991, 34.2315],
              [-91.6014, 34.132],
              [-91.7273, 34.1737],
              [-91.8904, 34.2323],
              [-92.0344, 34.2259],
              [-92.0698, 34.2272],
              [-92.0694, 34.3912],
              [-92.1437, 34.4915],
            ],
          ],
          [
            [
              [-92.2129, 34.2304],
              [-92.2105, 34.3182],
              [-92.1214, 34.3166],
              [-92.1604, 34.2292],
              [-92.2129, 34.2304],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505410',
        NAME: 'Dollarway School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1007911393,
        AWATER: 61686380,
        INTPTLAT: '+34.3335453',
        INTPTLON: '-091.8969250',
        ELSDLEA: '',
        UNSDLEA: '05410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8668, 36.4987],
            [-93.8343, 36.4989],
            [-93.6799, 36.4991],
            [-93.6793, 36.4201],
            [-93.619, 36.2362],
            [-93.6577, 36.3031],
            [-93.8156, 36.3066],
            [-93.8668, 36.3945],
            [-93.8668, 36.4987],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505970',
        NAME: 'Eureka Springs School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391558721,
        AWATER: 19660083,
        INTPTLAT: '+36.3859577',
        INTPTLON: '-093.7574744',
        ELSDLEA: '',
        UNSDLEA: '05970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9615, 35.8066],
            [-93.9446, 36.0759],
            [-93.9216, 36.1488],
            [-93.8878, 36.235],
            [-93.8156, 36.3066],
            [-93.6577, 36.3031],
            [-93.619, 36.2362],
            [-93.6329, 36.2016],
            [-93.6023, 36.1863],
            [-93.606, 36.0423],
            [-93.5732, 35.9965],
            [-93.5761, 35.8971],
            [-93.5236, 35.8816],
            [-93.548, 35.7642],
            [-93.696, 35.7686],
            [-93.8563, 35.773],
            [-93.8944, 35.7015],
            [-93.9125, 35.7019],
            [-93.9115, 35.7308],
            [-93.9629, 35.7609],
            [-93.9615, 35.8066],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508130',
        NAME: 'Huntsville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1910755767,
        AWATER: 6424873,
        INTPTLAT: '+36.0079612',
        INTPTLON: '-093.7495988',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.696, 35.7686],
            [-93.548, 35.7642],
            [-93.5236, 35.8816],
            [-93.5761, 35.8971],
            [-93.5732, 35.9965],
            [-93.606, 36.0423],
            [-93.6023, 36.1863],
            [-93.4335, 36.1387],
            [-93.3945, 36.0941],
            [-93.2652, 36.1203],
            [-93.0957, 36.1193],
            [-93.0343, 36.1163],
            [-93.1231, 36.0914],
            [-93.0568, 35.9587],
            [-93.1351, 35.9333],
            [-93.4177, 35.9156],
            [-93.4471, 35.7905],
            [-93.3442, 35.7304],
            [-93.3985, 35.6731],
            [-93.4861, 35.6644],
            [-93.4886, 35.589],
            [-93.6314, 35.5639],
            [-93.6538, 35.5646],
            [-93.7021, 35.5913],
            [-93.696, 35.7686],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508240',
        NAME: 'Jasper School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1586650692,
        AWATER: 5024261,
        INTPTLAT: '+35.8761554',
        INTPTLON: '-093.4440934',
        ELSDLEA: '',
        UNSDLEA: '08240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1097, 33.6281],
            [-93.1043, 33.777],
            [-92.8965, 33.8062],
            [-92.8772, 33.6725],
            [-92.7759, 33.5442],
            [-92.7236, 33.4596],
            [-92.8389, 33.3734],
            [-92.9442, 33.3764],
            [-92.9279, 33.5514],
            [-93.054, 33.5833],
            [-93.1097, 33.6281],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506060',
        NAME: 'Camden Fairview School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 859685349,
        AWATER: 11591281,
        INTPTLAT: '+33.5899267',
        INTPTLON: '-092.9298257',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4748, 36.4988],
            [-93.3298, 36.4985],
            [-93.2935, 36.4983],
            [-93.2962, 36.3815],
            [-93.299, 36.2379],
            [-93.4052, 36.2311],
            [-93.4473, 36.1828],
            [-93.4903, 36.3075],
            [-93.4588, 36.4388],
            [-93.4748, 36.4988],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506870',
        NAME: 'Green Forest School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 469784561,
        AWATER: 2428433,
        INTPTLAT: '+36.3601750',
        INTPTLON: '-093.4101856',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7049, 35.0197],
            [-93.7044, 35.0465],
            [-93.4791, 35.0414],
            [-93.4051, 35.1418],
            [-93.3162, 35.1539],
            [-93.2992, 35.1285],
            [-93.2824, 35.0371],
            [-93.375, 35.0395],
            [-93.5135, 34.9769],
            [-93.7064, 34.9476],
            [-93.7049, 35.0197],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '504890',
        NAME: 'Danville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 379778830,
        AWATER: 2486852,
        INTPTLAT: '+34.9937223',
        INTPTLON: '-093.6013836',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.697, 34.6419],
            [-90.5832, 34.6414],
            [-90.5693, 34.5249],
            [-90.5807, 34.4106],
            [-90.713, 34.3638],
            [-90.716, 34.3736],
            [-90.6809, 34.4233],
            [-90.7156, 34.5914],
            [-90.697, 34.6419],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507680',
        NAME: 'Helena-West Helena School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324019909,
        AWATER: 34339014,
        INTPTLAT: '+34.5049632',
        INTPTLON: '-090.6447534',
        ELSDLEA: '',
        UNSDLEA: '07680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3442, 35.7304],
            [-93.1645, 35.7288],
            [-93.1675, 35.6128],
            [-93.2212, 35.5989],
            [-93.229, 35.4329],
            [-93.2801, 35.3898],
            [-93.299, 35.3274],
            [-93.331, 35.3416],
            [-93.4189, 35.3271],
            [-93.4348, 35.4275],
            [-93.392, 35.5364],
            [-93.4886, 35.589],
            [-93.4861, 35.6644],
            [-93.3985, 35.6731],
            [-93.3442, 35.7304],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '508700',
        NAME: 'Lamar School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 776859921,
        AWATER: 33675921,
        INTPTLAT: '+35.5586119',
        INTPTLON: '-093.3172422',
        ELSDLEA: '',
        UNSDLEA: '08700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0304, 35.3125],
            [-93.0165, 35.1647],
            [-93.0847, 35.1274],
            [-93.1469, 35.2207],
            [-93.0304, 35.3125],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511700',
        NAME: 'Pottsville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177710198,
        AWATER: 5953100,
        INTPTLAT: '+35.2201226',
        INTPTLON: '-093.0550231',
        ELSDLEA: '',
        UNSDLEA: '11700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5024, 35.4417],
            [-90.3937, 35.44],
            [-90.3936, 35.44],
            [-90.4005, 35.1512],
            [-90.4004, 35.1482],
            [-90.503, 35.1442],
            [-90.5024, 35.4417],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '505550',
        NAME: 'Earle School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350067124,
        AWATER: 3345101,
        INTPTLAT: '+35.3024781',
        INTPTLON: '-090.4459244',
        ELSDLEA: '',
        UNSDLEA: '05550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6086, 34.5147],
            [-92.5933, 34.4699],
            [-92.701, 34.5173],
            [-92.6086, 34.5147],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507320',
        NAME: 'Harmony Grove School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 56748793,
        AWATER: 118861,
        INTPTLAT: '+34.5090563',
        INTPTLON: '-092.6459467',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6475, 35.3896],
            [-93.5485, 35.401],
            [-93.4348, 35.4275],
            [-93.4189, 35.3271],
            [-93.6603, 35.3235],
            [-93.6475, 35.3896],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '512180',
        NAME: 'Scranton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174874607,
        AWATER: 30045287,
        INTPTLAT: '+35.3492648',
        INTPTLON: '-093.5209938',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7566, 35.3694],
            [-93.7101, 35.3752],
            [-93.6475, 35.3896],
            [-93.6603, 35.3235],
            [-93.4189, 35.3271],
            [-93.331, 35.3416],
            [-93.3845, 35.2899],
            [-93.3857, 35.214],
            [-93.457, 35.2158],
            [-93.5298, 35.1454],
            [-93.6003, 35.1474],
            [-93.6703, 35.2216],
            [-93.8114, 35.2727],
            [-93.7566, 35.3694],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '511130',
        NAME: 'Paris School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 579898924,
        AWATER: 11301867,
        INTPTLAT: '+35.2688075',
        INTPTLON: '-093.5909354',
        ELSDLEA: '',
        UNSDLEA: '11130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8494, 35.8674],
            [-91.7106, 35.9418],
            [-91.6498, 35.8069],
            [-91.5575, 35.8051],
            [-91.4677, 35.8146],
            [-91.4694, 35.7293],
            [-91.5236, 35.7063],
            [-91.6696, 35.7457],
            [-91.6871, 35.681],
            [-91.7588, 35.6811],
            [-91.8364, 35.7271],
            [-91.8494, 35.8674],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500019',
        NAME: 'Batesville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559623859,
        AWATER: 6810655,
        INTPTLAT: '+35.8005996',
        INTPTLON: '-091.6994432',
        ELSDLEA: '',
        UNSDLEA: '00019',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8141, 34.4842],
            [-91.678, 34.4819],
            [-91.6252, 34.4796],
            [-91.6336, 34.3783],
            [-91.5978, 34.317],
            [-91.4532, 34.3873],
            [-91.3886, 34.4904],
            [-91.2666, 34.5063],
            [-91.0566, 34.3369],
            [-91.0837, 34.1458],
            [-91.1943, 34.0709],
            [-91.1356, 33.962],
            [-91.3533, 33.957],
            [-91.552, 34.106],
            [-91.6069, 34.1123],
            [-91.6014, 34.132],
            [-91.5991, 34.2315],
            [-91.7118, 34.2339],
            [-91.7079, 34.3952],
            [-91.8148, 34.3966],
            [-91.8141, 34.4842],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500001',
        NAME: 'DeWitt School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2259231975,
        AWATER: 119601501,
        INTPTLAT: '+34.2473999',
        INTPTLON: '-091.3745432',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-93.9305, 34.6656],
              [-93.8702, 34.7127],
              [-93.7627, 34.7325],
              [-93.5699, 34.7439],
              [-93.5706, 34.6864],
              [-93.7568, 34.5853],
              [-93.8103, 34.5207],
              [-93.933, 34.5215],
              [-93.9305, 34.6656],
            ],
          ],
          [
            [
              [-94.4561, 34.6816],
              [-94.4547, 34.724],
              [-94.451, 34.8307],
              [-94.3847, 34.8294],
              [-94.3177, 34.6974],
              [-94.0352, 34.6826],
              [-94.124, 34.6418],
              [-94.1614, 34.5772],
              [-94.3362, 34.6232],
              [-94.4561, 34.6798],
              [-94.4561, 34.6816],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '500073',
        NAME: 'Ouachita River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 909280946,
        AWATER: 4957678,
        INTPTLAT: '+34.5937135',
        INTPTLON: '-093.7836224',
        ELSDLEA: '',
        UNSDLEA: '00073',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3035, 35.1319],
            [-94.0852, 35.1421],
            [-94.0883, 35.0544],
            [-94.1014, 34.9837],
            [-94.2301, 34.9858],
            [-94.3371, 34.9484],
            [-94.3035, 35.1319],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509330',
        NAME: 'Mansfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 397882850,
        AWATER: 3509196,
        INTPTLAT: '+35.0610804',
        INTPTLON: '-094.2042217',
        ELSDLEA: '',
        UNSDLEA: '09330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.673, 36.37],
            [-91.4539, 36.3787],
            [-91.4527, 36.4224],
            [-91.3087, 36.4204],
            [-91.2753, 36.3745],
            [-91.3499, 36.2301],
            [-91.4728, 36.1605],
            [-91.4728, 36.1174],
            [-91.6209, 36.1638],
            [-91.6808, 36.1188],
            [-91.7136, 36.2536],
            [-91.673, 36.37],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507770',
        NAME: 'Highland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 835299811,
        AWATER: 5701720,
        INTPTLAT: '+36.2852836',
        INTPTLON: '-091.5003549',
        ELSDLEA: '',
        UNSDLEA: '07770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7085, 36.4994],
            [-91.6177, 36.4993],
            [-91.4073, 36.4971],
            [-91.1724, 36.4975],
            [-91.1386, 36.4186],
            [-91.1839, 36.3748],
            [-91.2753, 36.3745],
            [-91.3087, 36.4204],
            [-91.4527, 36.4224],
            [-91.4539, 36.3787],
            [-91.673, 36.37],
            [-91.7085, 36.4994],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509270',
        NAME: 'Mammoth Spring Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 585985048,
        AWATER: 914290,
        INTPTLAT: '+36.4455752',
        INTPTLON: '-091.4441198',
        ELSDLEA: '',
        UNSDLEA: '09270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4357, 35.276],
            [-94.2447, 35.2826],
            [-94.1704, 35.2921],
            [-94.0807, 35.276],
            [-94.0285, 35.2138],
            [-94.0852, 35.1421],
            [-94.3035, 35.1319],
            [-94.3752, 35.1484],
            [-94.4377, 35.238],
            [-94.4357, 35.276],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '506990',
        NAME: 'Greenwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 460330210,
        AWATER: 4577921,
        INTPTLAT: '+35.2169742',
        INTPTLON: '-094.2288468',
        ELSDLEA: '',
        UNSDLEA: '06990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4475, 34.9419],
            [-94.4443, 35.0287],
            [-94.4425, 35.0802],
            [-94.4401, 35.1497],
            [-94.3752, 35.1484],
            [-94.3035, 35.1319],
            [-94.3371, 34.9484],
            [-94.4478, 34.9339],
            [-94.4475, 34.9419],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '507410',
        NAME: 'Hartford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234876076,
        AWATER: 2818313,
        INTPTLAT: '+35.0496827',
        INTPTLON: '-094.4031074',
        ELSDLEA: '',
        UNSDLEA: '07410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1197, 35.0665],
            [-92.0015, 35.0623],
            [-91.8027, 35.0087],
            [-91.8035, 34.9361],
            [-91.8215, 34.9289],
            [-91.8899, 34.9224],
            [-91.9401, 34.8501],
            [-92.0314, 34.8515],
            [-92.0748, 34.8592],
            [-92.0708, 35.0118],
            [-92.1213, 35.0122],
            [-92.1197, 35.0665],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '503750',
        NAME: 'Cabot Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 467975083,
        AWATER: 4630060,
        INTPTLAT: '+34.9696929',
        INTPTLON: '-091.9660620',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0314, 34.8515],
            [-91.9401, 34.8501],
            [-91.8899, 34.9224],
            [-91.8215, 34.9289],
            [-91.8037, 34.9215],
            [-91.8592, 34.806],
            [-91.8256, 34.7477],
            [-91.8451, 34.6455],
            [-92.008, 34.6912],
            [-92.0782, 34.7351],
            [-92.0314, 34.8515],
          ],
        ],
      },
      properties: {
        STATEFP: '05',
        SCSDLEA: '',
        GEOID: '509060',
        NAME: 'Lonoke School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 490758037,
        AWATER: 44283872,
        INTPTLAT: '+34.7740892',
        INTPTLON: '-091.9317740',
        ELSDLEA: '',
        UNSDLEA: '09060',
      },
    },
  ],
};

/* eslint-disable consistent-return */
import axios from 'axios';
import { API_ROOT } from '../config/appConfig';

export const getTopicsApi = async (jwt) => {
  const res = await axios.get(`${API_ROOT}/admin-backend/topic`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

export const getTopicByIdApi = async (id, jwt) => {
  const res = await axios.get(`${API_ROOT}/admin-backend/topic/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

import React from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';

import './App.css';
import renderRoutes from './routes/routes';
import NoMatch from './Pages/NoMatch';
import ScrollToTop from './Components/ScrollToTop';
import CustomModal from './Components/CustomModal';
import PageViews from './Pages/PageViews';
import { GA_MEASUREMENT_ID } from './config/appConfig';
import ErrorBoundaries from './Components/ErrorBoundaries/ErrorBoundaries';

if (GA_MEASUREMENT_ID) {
  ReactGA.initialize(GA_MEASUREMENT_ID);
}

exporting(Highcharts);

function App() {
  return (
    <ErrorBoundaries>
      <Router>
        <ScrollToTop />
        <PageViews>
          <Switch>
            {renderRoutes()}
            <Route component={NoMatch} />
          </Switch>
        </PageViews>
        <CustomModal />
      </Router>
    </ErrorBoundaries>
  );
}

export default App;

import { combineReducers } from 'redux';
import bento from './bento';
import dashboard from './dashboard';
import errors from './errors';
import exportFile from './export';
import fileWizard from './fileWizard';
import modal from './modal';
import organizations from './organization';
import reports from './report';
import reportDetails from './report.details';
import sections from './section';
import session from './session';
import shareALink from './share.a.link';
import shareALinkResult from './share.a.link.result';
import surveys from './survey';
import surveyDetails from './survey.details';
import surveySummary from './survey.summary';
import surveyWizard from './survey.wizard';
import topics from './topic';
import users from './users';
import validations from './validations';
import visualizations from './visualization';
import wizard from './wizard';

export default combineReducers({
  session,
  modal,
  bento,
  surveys,
  wizard,
  validations,
  visualizations,
  fileWizard,
  errors,
  users,
  surveyDetails,
  exportFile,
  reports,
  reportDetails,
  surveySummary,
  shareALink,
  dashboard,
  shareALinkResult,
  organizations,
  topics,
  sections,
  surveyWizard,
});

export const FL = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6586, 29.8301],
            [-82.5297, 29.9409],
            [-82.4187, 29.9231],
            [-82.2818, 29.8444],
            [-82.1331, 29.8359],
            [-82.0556, 29.7182],
            [-82.0559, 29.4712],
            [-82.2102, 29.4285],
            [-82.2151, 29.4842],
            [-82.4066, 29.485],
            [-82.5568, 29.4801],
            [-82.5576, 29.5376],
            [-82.6563, 29.5648],
            [-82.6586, 29.8301],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200030',
        NAME: 'Alachua County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2266378728,
        AWATER: 242823265,
        INTPTLAT: '+29.6757403',
        INTPTLON: '-082.3572210',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4189, 30.5818],
            [-82.2147, 30.5686],
            [-82.2107, 30.4256],
            [-82.1702, 30.359],
            [-82.05, 30.3625],
            [-82.0492, 30.2734],
            [-82.0494, 30.1869],
            [-82.0494, 30.1431],
            [-82.1426, 30.1431],
            [-82.4584, 30.1364],
            [-82.4598, 30.5843],
            [-82.4189, 30.5818],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200060',
        NAME: 'Baker County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1515740957,
        AWATER: 9686120,
        INTPTLAT: '+30.3244416',
        INTPTLON: '-082.3022838',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9927, 30.3893],
            [-85.8535, 30.4406],
            [-85.488, 30.4367],
            [-85.4864, 30.5676],
            [-85.4348, 30.5676],
            [-85.3839, 30.5669],
            [-85.3897, 30.201],
            [-85.3887, 29.9244],
            [-85.4463, 29.901],
            [-85.5051, 29.9118],
            [-85.6325, 30.0143],
            [-85.9999, 30.2131],
            [-85.9927, 30.3893],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200090',
        NAME: 'Bay County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1964402061,
        AWATER: 711451659,
        INTPTLAT: '+30.2381172',
        INTPTLON: '-085.6314303',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1426, 30.1431],
            [-82.0494, 30.1431],
            [-82.0492, 29.7187],
            [-82.0556, 29.7182],
            [-82.1331, 29.8359],
            [-82.2818, 29.8444],
            [-82.4187, 29.9231],
            [-82.3134, 29.9547],
            [-82.1473, 30.11],
            [-82.1426, 30.1431],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200120',
        NAME: 'Bradford County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 761365424,
        AWATER: 16904945,
        INTPTLAT: '+29.9523863',
        INTPTLON: '-082.1665990',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6635, 28.7906],
            [-80.523, 28.6082],
            [-80.4694, 28.4531],
            [-80.5194, 28.3911],
            [-80.544, 28.2711],
            [-80.4998, 28.0773],
            [-80.3851, 27.8614],
            [-80.5091, 27.8221],
            [-80.8689, 27.8225],
            [-80.8629, 28.3475],
            [-80.8859, 28.5102],
            [-80.9873, 28.613],
            [-80.9679, 28.7902],
            [-80.6635, 28.7906],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200150',
        NAME: 'Brevard County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2629960540,
        AWATER: 1402786334,
        INTPTLAT: '+28.2983043',
        INTPTLON: '-080.7003309',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8812, 26.3338],
            [-80.2969, 26.3344],
            [-80.0153, 26.321],
            [-80.0523, 25.975],
            [-80.295, 25.9568],
            [-80.68, 25.9569],
            [-80.8729, 25.9794],
            [-80.8798, 26.2595],
            [-80.8812, 26.3338],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200180',
        NAME: 'Broward County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3126184877,
        AWATER: 300028343,
        INTPTLAT: '+26.1935353',
        INTPTLON: '-080.4766834',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3839, 30.5669],
            [-85.1719, 30.5643],
            [-85.1647, 30.6081],
            [-84.9325, 30.6064],
            [-85.0329, 30.4203],
            [-85.0528, 30.2874],
            [-85.1157, 30.2],
            [-85.3897, 30.201],
            [-85.3839, 30.5669],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200210',
        NAME: 'Calhoun County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1469389206,
        AWATER: 18074798,
        INTPTLAT: '+30.3888013',
        INTPTLON: '-085.1979155',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4373, 26.946],
            [-82.2555, 26.9453],
            [-82.2552, 27.033],
            [-82.0575, 27.0321],
            [-81.5622, 27.0338],
            [-81.5659, 26.7696],
            [-82.1986, 26.7709],
            [-82.335, 26.7879],
            [-82.4373, 26.946],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200240',
        NAME: 'Charlotte County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1764034033,
        AWATER: 458806771,
        INTPTLAT: '+26.8689328',
        INTPTLON: '-081.9410769',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8482, 28.9145],
            [-82.7115, 29.031],
            [-82.6127, 29.0093],
            [-82.5356, 29.0449],
            [-82.4776, 29.0525],
            [-82.3117, 28.9604],
            [-82.1714, 28.7898],
            [-82.2631, 28.6676],
            [-82.4184, 28.6949],
            [-82.7784, 28.6942],
            [-82.813, 28.7913],
            [-82.7948, 28.8577],
            [-82.8482, 28.9145],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200270',
        NAME: 'Citrus County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1507100924,
        AWATER: 495589452,
        INTPTLAT: '+28.8436402',
        INTPTLON: '-082.5248099',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0494, 30.1869],
            [-81.6796, 30.1905],
            [-81.6802, 30.1212],
            [-81.6889, 30.0286],
            [-81.6101, 29.9702],
            [-81.5812, 29.8402],
            [-81.8124, 29.8365],
            [-81.9394, 29.7475],
            [-82.0492, 29.7187],
            [-82.0494, 30.1431],
            [-82.0494, 30.1869],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200300',
        NAME: 'Clay County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1566445254,
        AWATER: 100334057,
        INTPTLAT: '+29.9865835',
        INTPTLON: '-081.8650364',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9055, 26.3301],
            [-81.6579, 26.3176],
            [-81.6595, 26.4211],
            [-81.5622, 26.4226],
            [-81.5638, 26.5133],
            [-81.2718, 26.5171],
            [-81.2685, 26.253],
            [-80.8798, 26.2595],
            [-80.8729, 25.9794],
            [-80.8731, 25.8054],
            [-81.571, 25.802],
            [-81.6287, 25.7713],
            [-81.7181, 25.793],
            [-81.7753, 25.8809],
            [-81.8503, 26.0629],
            [-81.8679, 26.2144],
            [-81.9055, 26.3301],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200330',
        NAME: 'Collier County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5176959832,
        AWATER: 792901545,
        INTPTLAT: '+26.1187860',
        INTPTLON: '-081.4009553',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7946, 30.337],
            [-82.7178, 30.3214],
            [-82.6456, 30.4064],
            [-82.7266, 30.5595],
            [-82.6895, 30.5977],
            [-82.584, 30.5915],
            [-82.4598, 30.5843],
            [-82.4584, 30.1364],
            [-82.5693, 29.975],
            [-82.5297, 29.9409],
            [-82.6586, 29.8301],
            [-82.7362, 29.8601],
            [-82.8005, 29.9321],
            [-82.7946, 30.337],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200360',
        NAME: 'Columbia County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2065626671,
        AWATER: 9773963,
        INTPTLAT: '+30.2213332',
        INTPTLON: '-082.6231676',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8729, 25.9794],
            [-80.68, 25.9569],
            [-80.295, 25.9568],
            [-80.0523, 25.975],
            [-80.0561, 25.8377],
            [-80.0965, 25.6528],
            [-80.0435, 25.5981],
            [-80.1157, 25.5414],
            [-80.1418, 25.4347],
            [-80.1126, 25.3754],
            [-80.1502, 25.3141],
            [-80.2655, 25.3543],
            [-80.3721, 25.3102],
            [-80.4293, 25.2357],
            [-80.6513, 25.1926],
            [-80.7054, 25.149],
            [-80.8582, 25.1766],
            [-80.8732, 25.364],
            [-80.8731, 25.8054],
            [-80.8729, 25.9794],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200390',
        NAME: 'Dade County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4917324056,
        AWATER: 1379396151,
        INTPTLAT: '+25.6105796',
        INTPTLON: '-080.4991365',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0565, 27.2078],
            [-82.0558, 27.3383],
            [-81.5637, 27.3407],
            [-81.5622, 27.0338],
            [-82.0575, 27.0321],
            [-82.0565, 27.2078],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200420',
        NAME: 'DeSoto County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1649970328,
        AWATER: 6247257,
        INTPTLAT: '+27.1905809',
        INTPTLON: '-081.8062531',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4813, 29.6303],
            [-83.3664, 29.6657],
            [-83.3189, 29.8226],
            [-82.9206, 29.8241],
            [-82.957, 29.6389],
            [-82.9373, 29.5912],
            [-82.9844, 29.471],
            [-83.0685, 29.344],
            [-83.2399, 29.251],
            [-83.2387, 29.3086],
            [-83.3602, 29.4311],
            [-83.4495, 29.4834],
            [-83.4813, 29.6303],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200450',
        NAME: 'Dixie County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1826212267,
        AWATER: 410631158,
        INTPTLAT: '+29.5808994',
        INTPTLON: '-083.1956656',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0492, 30.2734],
            [-81.8312, 30.4674],
            [-81.6245, 30.5862],
            [-81.543, 30.5239],
            [-81.3588, 30.5015],
            [-81.3249, 30.4247],
            [-81.3202, 30.2529],
            [-81.4369, 30.2523],
            [-81.4337, 30.106],
            [-81.6802, 30.1212],
            [-81.6796, 30.1905],
            [-82.0494, 30.1869],
            [-82.0492, 30.2734],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200480',
        NAME: 'Duval County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1975212116,
        AWATER: 403585670,
        INTPTLAT: '+30.3352449',
        INTPTLON: '-081.6481132',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5988, 30.9975],
            [-87.1631, 30.999],
            [-87.2823, 30.9248],
            [-87.3124, 30.7348],
            [-87.2607, 30.6214],
            [-87.125, 30.5002],
            [-87.179, 30.3346],
            [-86.9201, 30.3866],
            [-86.9166, 30.3187],
            [-87.1388, 30.2785],
            [-87.2965, 30.2727],
            [-87.5183, 30.2295],
            [-87.5, 30.329],
            [-87.3694, 30.4319],
            [-87.4451, 30.5289],
            [-87.3933, 30.6272],
            [-87.6262, 30.8467],
            [-87.5988, 30.9975],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200510',
        NAME: 'Escambia County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1701332835,
        AWATER: 564141200,
        INTPTLAT: '+30.6116382',
        INTPTLON: '-087.3388996',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5237, 29.6224],
            [-81.3241, 29.6256],
            [-81.2626, 29.6662],
            [-81.1508, 29.6711],
            [-81.0457, 29.4325],
            [-81.1559, 29.411],
            [-81.1501, 29.266],
            [-81.4173, 29.2612],
            [-81.434, 29.3986],
            [-81.5206, 29.5002],
            [-81.5237, 29.6224],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200540',
        NAME: 'Flagler County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1257381103,
        AWATER: 221047161,
        INTPTLAT: '+29.4749121',
        INTPTLON: '-081.2863054',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2463, 29.625],
            [-85.2062, 29.7112],
            [-85.0205, 29.8074],
            [-85.0544, 29.9019],
            [-85.0254, 29.9714],
            [-85.0076, 30.0139],
            [-84.5444, 30.011],
            [-84.3252, 29.958],
            [-84.2647, 29.9781],
            [-84.2988, 29.8671],
            [-84.4469, 29.8587],
            [-84.5423, 29.7698],
            [-84.6392, 29.7352],
            [-84.7357, 29.6564],
            [-85.0312, 29.5391],
            [-85.2463, 29.625],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200570',
        NAME: 'Franklin County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1411918810,
        AWATER: 1275526881,
        INTPTLAT: '+29.8101756',
        INTPTLON: '-084.7991742',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8647, 30.7115],
            [-84.8635, 30.7115],
            [-84.3807, 30.69],
            [-84.2826, 30.6853],
            [-84.3714, 30.5811],
            [-84.4205, 30.4639],
            [-84.5206, 30.4512],
            [-84.6464, 30.3883],
            [-84.7811, 30.4598],
            [-84.7818, 30.5187],
            [-84.8826, 30.5331],
            [-84.9325, 30.6064],
            [-84.8647, 30.7115],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200600',
        NAME: 'Gadsden County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1337193226,
        AWATER: 31687293,
        INTPTLAT: '+30.5786866',
        INTPTLON: '-084.6126072',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9206, 29.8241],
            [-82.9187, 29.8241],
            [-82.904, 29.8243],
            [-82.8798, 29.8868],
            [-82.8005, 29.9321],
            [-82.7362, 29.8601],
            [-82.6586, 29.8301],
            [-82.6563, 29.5648],
            [-82.9373, 29.5912],
            [-82.957, 29.6389],
            [-82.9206, 29.8241],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200630',
        NAME: 'Gilchrist County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 905705522,
        AWATER: 14362154,
        INTPTLAT: '+29.7234556',
        INTPTLON: '-082.7958011',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5622, 27.0338],
            [-81.2672, 27.0325],
            [-81.2677, 27.1211],
            [-81.1683, 27.1217],
            [-81.1691, 27.2096],
            [-80.9432, 27.2106],
            [-80.8712, 27.155],
            [-80.8856, 26.9589],
            [-80.9454, 26.769],
            [-81.5659, 26.7696],
            [-81.5622, 27.0338],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200660',
        NAME: 'Glades County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2087857862,
        AWATER: 468345881,
        INTPTLAT: '+26.9548102',
        INTPTLON: '-081.1908197',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4463, 29.901],
            [-85.3887, 29.9244],
            [-85.3897, 30.201],
            [-85.1157, 30.2],
            [-85.1353, 30.0433],
            [-85.0254, 29.9714],
            [-85.0544, 29.9019],
            [-85.0205, 29.8074],
            [-85.2062, 29.7112],
            [-85.2463, 29.625],
            [-85.3817, 29.6129],
            [-85.43, 29.67],
            [-85.475, 29.8315],
            [-85.4463, 29.901],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200690',
        NAME: 'Gulf County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1433780459,
        AWATER: 494266823,
        INTPTLAT: '+29.9072571',
        INTPTLON: '-085.2565366',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3092, 30.6344],
            [-83.1366, 30.624],
            [-82.6895, 30.5977],
            [-82.7266, 30.5595],
            [-82.6456, 30.4064],
            [-82.7178, 30.3214],
            [-82.7946, 30.337],
            [-82.9559, 30.4135],
            [-83.0787, 30.4381],
            [-83.171, 30.3852],
            [-83.2201, 30.4225],
            [-83.2726, 30.6277],
            [-83.3092, 30.6344],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200720',
        NAME: 'Hamilton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1330689578,
        AWATER: 13659798,
        INTPTLAT: '+30.4912264',
        INTPTLON: '-082.9510695',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0543, 27.6464],
            [-81.5633, 27.6466],
            [-81.5637, 27.3407],
            [-82.0558, 27.3383],
            [-82.0543, 27.6464],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200750',
        NAME: 'Hardee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1651878523,
        AWATER: 1439673,
        INTPTLAT: '+27.4928458',
        INTPTLON: '-081.8215798',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5659, 26.7696],
            [-80.9454, 26.769],
            [-80.8856, 26.9589],
            [-80.8812, 26.3338],
            [-80.8798, 26.2595],
            [-81.2685, 26.253],
            [-81.2718, 26.5171],
            [-81.5638, 26.5133],
            [-81.5659, 26.7696],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200780',
        NAME: 'Hendry County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2986009287,
        AWATER: 96120136,
        INTPTLAT: '+26.5399670',
        INTPTLON: '-081.1521142',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7784, 28.6942],
            [-82.4184, 28.6949],
            [-82.2631, 28.6676],
            [-82.2216, 28.5932],
            [-82.138, 28.5655],
            [-82.0547, 28.4784],
            [-82.2533, 28.4785],
            [-82.2534, 28.4348],
            [-82.754, 28.4333],
            [-82.7274, 28.5956],
            [-82.7784, 28.6942],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200810',
        NAME: 'Hernando County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1224611025,
        AWATER: 300943246,
        INTPTLAT: '+28.5730426',
        INTPTLON: '-082.4662272',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5633, 27.6466],
            [-81.1422, 27.6432],
            [-81.2116, 27.531],
            [-81.2051, 27.4897],
            [-80.9432, 27.2106],
            [-81.1691, 27.2096],
            [-81.1683, 27.1217],
            [-81.2677, 27.1211],
            [-81.2672, 27.0325],
            [-81.5622, 27.0338],
            [-81.5637, 27.3407],
            [-81.5633, 27.6466],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200840',
        NAME: 'Highlands County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2632923680,
        AWATER: 231677932,
        INTPTLAT: '+27.3426272',
        INTPTLON: '-081.3409213',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8134, 27.6457],
            [-82.6061, 27.6436],
            [-82.5415, 27.7822],
            [-82.5972, 27.9352],
            [-82.6483, 27.9474],
            [-82.6512, 28.1733],
            [-82.1059, 28.1716],
            [-82.0563, 28.1716],
            [-82.0543, 27.6464],
            [-82.5537, 27.6455],
            [-82.7451, 27.5705],
            [-82.823, 27.5705],
            [-82.8134, 27.6457],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200870',
        NAME: 'Hillsborough County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2642601093,
        AWATER: 635570785,
        INTPTLAT: '+27.9066072',
        INTPTLON: '-082.3497186',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.035, 30.9933],
            [-85.9973, 30.9929],
            [-85.7727, 30.9946],
            [-85.498, 30.9969],
            [-85.5999, 30.8306],
            [-85.619, 30.7439],
            [-85.7361, 30.7464],
            [-85.8216, 30.7908],
            [-85.8444, 30.7031],
            [-86.0376, 30.7046],
            [-86.035, 30.9933],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200900',
        NAME: 'Holmes County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1239970210,
        AWATER: 26656232,
        INTPTLAT: '+30.8662220',
        INTPTLON: '-085.8129589',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8689, 27.8225],
            [-80.5091, 27.8221],
            [-80.3851, 27.8614],
            [-80.3281, 27.7503],
            [-80.2635, 27.5568],
            [-80.6798, 27.5585],
            [-80.7772, 27.5587],
            [-80.778, 27.6432],
            [-80.8731, 27.6423],
            [-80.8689, 27.8225],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200930',
        NAME: 'Indian River County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1302340528,
        AWATER: 295574851,
        INTPTLAT: '+27.7006453',
        INTPTLON: '-080.5747940',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.498, 30.9969],
            [-85.4883, 30.9971],
            [-85.0025, 31.0007],
            [-84.9425, 30.8885],
            [-84.9111, 30.7512],
            [-84.8647, 30.7115],
            [-84.9325, 30.6064],
            [-85.1647, 30.6081],
            [-85.1719, 30.5643],
            [-85.3839, 30.5669],
            [-85.4348, 30.5676],
            [-85.4323, 30.7855],
            [-85.4823, 30.8295],
            [-85.5999, 30.8306],
            [-85.498, 30.9969],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200960',
        NAME: 'Jackson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2376988508,
        AWATER: 95321393,
        INTPTLAT: '+30.7892500',
        INTPTLON: '-085.2088129',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0755, 30.2736],
            [-84.0747, 30.4343],
            [-84.0412, 30.5219],
            [-83.9786, 30.5227],
            [-84.0075, 30.6721],
            [-83.7437, 30.6584],
            [-83.6117, 30.6513],
            [-83.6042, 30.5819],
            [-83.737, 30.5076],
            [-83.7217, 30.4344],
            [-83.8202, 30.3035],
            [-83.9154, 30.2448],
            [-83.9922, 30.0962],
            [-83.9999, 30.0122],
            [-84.0755, 30.0346],
            [-84.0755, 30.2736],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1200990',
        NAME: 'Jefferson County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1549064068,
        AWATER: 99559047,
        INTPTLAT: '+30.4245577',
        INTPTLON: '-083.8908594',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3673, 30.2604],
            [-83.2473, 30.2607],
            [-83.2264, 30.1146],
            [-83.1625, 30.11],
            [-82.9631, 29.9931],
            [-82.8798, 29.8868],
            [-82.904, 29.8243],
            [-82.9187, 29.8241],
            [-82.9206, 29.8241],
            [-83.3189, 29.8226],
            [-83.3709, 29.8879],
            [-83.3673, 30.2604],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201020',
        NAME: 'Lafayette County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1407427927,
        AWATER: 11646352,
        INTPTLAT: '+29.9900661',
        INTPTLON: '-083.1785097',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9542, 28.9601],
            [-81.6587, 28.9603],
            [-81.6387, 29.0478],
            [-81.6419, 29.2768],
            [-81.6123, 29.2029],
            [-81.5326, 29.1776],
            [-81.5021, 29.0978],
            [-81.3546, 28.9844],
            [-81.3669, 28.8792],
            [-81.4144, 28.785],
            [-81.6465, 28.7859],
            [-81.6586, 28.7669],
            [-81.6573, 28.3471],
            [-81.9576, 28.3452],
            [-81.9542, 28.9601],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201050',
        NAME: 'Lake County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2432320587,
        AWATER: 564194105,
        INTPTLAT: '+28.7641128',
        INTPTLON: '-081.7122822',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.335, 26.7879],
            [-82.1986, 26.7709],
            [-81.5659, 26.7696],
            [-81.5638, 26.5133],
            [-81.5622, 26.4226],
            [-81.6595, 26.4211],
            [-81.6579, 26.3176],
            [-81.9055, 26.3301],
            [-81.9781, 26.3997],
            [-82.1122, 26.3778],
            [-82.2365, 26.4773],
            [-82.3219, 26.6651],
            [-82.335, 26.7879],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201080',
        NAME: 'Lee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2031443679,
        AWATER: 1108606586,
        INTPTLAT: '+26.5840711',
        INTPTLON: '-081.9281848',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6464, 30.3883],
            [-84.5206, 30.4512],
            [-84.4205, 30.4639],
            [-84.3714, 30.5811],
            [-84.2826, 30.6853],
            [-84.0838, 30.6758],
            [-84.0075, 30.6721],
            [-83.9786, 30.5227],
            [-84.0412, 30.5219],
            [-84.0747, 30.4343],
            [-84.0755, 30.2736],
            [-84.2477, 30.3035],
            [-84.7134, 30.3002],
            [-84.6464, 30.3883],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201110',
        NAME: 'Leon County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1727171503,
        AWATER: 90454011,
        INTPTLAT: '+30.4593104',
        INTPTLON: '-084.2778004',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2399, 29.251],
            [-83.0685, 29.344],
            [-82.9844, 29.471],
            [-82.9373, 29.5912],
            [-82.6563, 29.5648],
            [-82.5576, 29.5376],
            [-82.5568, 29.4801],
            [-82.4066, 29.485],
            [-82.4032, 29.2156],
            [-82.5349, 29.2147],
            [-82.5356, 29.0449],
            [-82.6127, 29.0093],
            [-82.7115, 29.031],
            [-82.8482, 28.9145],
            [-82.8513, 29.0174],
            [-82.9501, 29.0761],
            [-83.076, 29.0418],
            [-83.1441, 29.0728],
            [-83.1718, 29.1859],
            [-83.2399, 29.251],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201140',
        NAME: 'Levy County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2896185793,
        AWATER: 762933268,
        INTPTLAT: '+29.2844566',
        INTPTLON: '-082.7834620',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1157, 30.2],
            [-85.0528, 30.2874],
            [-85.0329, 30.4203],
            [-84.9325, 30.6064],
            [-84.8826, 30.5331],
            [-84.7818, 30.5187],
            [-84.7811, 30.4598],
            [-84.6464, 30.3883],
            [-84.7134, 30.3002],
            [-84.6693, 30.1252],
            [-84.5444, 30.011],
            [-85.0076, 30.0139],
            [-85.0254, 29.9714],
            [-85.1353, 30.0433],
            [-85.1157, 30.2],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201170',
        NAME: 'Liberty County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2164095689,
        AWATER: 19582444,
        INTPTLAT: '+30.2598494',
        INTPTLON: '-084.8685805',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6117, 30.6513],
            [-83.3577, 30.6374],
            [-83.3409, 30.6363],
            [-83.3092, 30.6344],
            [-83.2726, 30.6277],
            [-83.2201, 30.4225],
            [-83.171, 30.3852],
            [-83.2473, 30.2607],
            [-83.3673, 30.2604],
            [-83.4688, 30.3042],
            [-83.8202, 30.3035],
            [-83.7217, 30.4344],
            [-83.737, 30.5076],
            [-83.6042, 30.5819],
            [-83.6117, 30.6513],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201200',
        NAME: 'Madison County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1802874009,
        AWATER: 50729943,
        INTPTLAT: '+30.4472325',
        INTPTLON: '-083.4704159',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.823, 27.5705],
            [-82.7451, 27.5705],
            [-82.5537, 27.6455],
            [-82.0543, 27.6464],
            [-82.0558, 27.3383],
            [-82.0565, 27.2078],
            [-82.2536, 27.2089],
            [-82.2523, 27.3862],
            [-82.7054, 27.3897],
            [-82.823, 27.5705],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201230',
        NAME: 'Manatee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1924779544,
        AWATER: 387447453,
        INTPTLAT: '+27.4816825',
        INTPTLON: '-082.3687669',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4066, 29.485],
            [-82.2151, 29.4842],
            [-82.2102, 29.4285],
            [-82.0559, 29.4712],
            [-81.843, 29.521],
            [-81.7762, 29.4874],
            [-81.7414, 29.371],
            [-81.6809, 29.3244],
            [-81.6419, 29.2768],
            [-81.6387, 29.0478],
            [-81.6587, 28.9603],
            [-81.9542, 28.9601],
            [-82.3117, 28.9604],
            [-82.4776, 29.0525],
            [-82.5356, 29.0449],
            [-82.5349, 29.2147],
            [-82.4032, 29.2156],
            [-82.4066, 29.485],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201260',
        NAME: 'Marion County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4112985503,
        AWATER: 193293405,
        INTPTLAT: '+29.2028047',
        INTPTLON: '-082.0431004',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6779, 27.206],
            [-80.2855, 27.206],
            [-80.2848, 27.2637],
            [-80.138, 27.2629],
            [-80.0228, 26.9704],
            [-80.1421, 26.9566],
            [-80.8856, 26.9589],
            [-80.6774, 27.1216],
            [-80.6779, 27.206],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201290',
        NAME: 'Martin County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1408245188,
        AWATER: 542261303,
        INTPTLAT: '+27.0836036',
        INTPTLON: '-080.3982011',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-81.571, 25.802],
              [-80.8731, 25.8054],
              [-80.8732, 25.364],
              [-80.8582, 25.1766],
              [-80.7054, 25.149],
              [-80.6513, 25.1926],
              [-80.4293, 25.2357],
              [-80.3721, 25.3102],
              [-80.2655, 25.3543],
              [-80.1502, 25.3141],
              [-80.2179, 25.2952],
              [-80.2498, 25.1795],
              [-80.3904, 25.0572],
              [-80.4534, 24.9637],
              [-80.7834, 24.7613],
              [-80.8522, 24.7489],
              [-81.0476, 24.6402],
              [-81.1477, 24.6491],
              [-81.2717, 24.5971],
              [-81.4747, 24.5535],
              [-81.5399, 24.486],
              [-81.7118, 24.4297],
              [-81.9464, 24.3993],
              [-82.0672, 24.5237],
              [-82.1751, 24.4996],
              [-82.219, 24.5422],
              [-82.1647, 24.6295],
              [-82.0266, 24.6077],
              [-81.7235, 24.7255],
              [-81.6028, 24.7996],
              [-81.4025, 24.8818],
              [-81.2023, 24.8684],
              [-81.0645, 24.7911],
              [-80.9643, 24.8199],
              [-80.994, 24.9105],
              [-81.0985, 25.0665],
              [-81.1844, 25.125],
              [-81.234, 25.2345],
              [-81.2057, 25.4108],
              [-81.3472, 25.6367],
              [-81.4451, 25.7376],
              [-81.571, 25.802],
            ],
          ],
          [
            [
              [-82.9875, 24.6192],
              [-82.8999, 24.7178],
              [-82.8002, 24.7263],
              [-82.7667, 24.6679],
              [-82.8491, 24.5767],
              [-82.9582, 24.5818],
              [-82.9875, 24.6192],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201320',
        NAME: 'Monroe County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2546040739,
        AWATER: 7133347242,
        INTPTLAT: '+25.6154566',
        INTPTLON: '-081.1899714',
        ELSDLEA: '',
        UNSDLEA: '01320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9049, 30.8283],
            [-81.7181, 30.7448],
            [-81.5724, 30.7219],
            [-81.347, 30.7124],
            [-81.3809, 30.6273],
            [-81.3588, 30.5015],
            [-81.543, 30.5239],
            [-81.6245, 30.5862],
            [-81.8312, 30.4674],
            [-82.0492, 30.2734],
            [-82.05, 30.3625],
            [-82.0058, 30.5654],
            [-82.0502, 30.6745],
            [-82.0398, 30.7473],
            [-81.9049, 30.8283],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201350',
        NAME: 'Nassau County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1679962718,
        AWATER: 200159392,
        INTPTLAT: '+30.6059257',
        INTPTLON: '-081.7649287',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7857, 30.997],
            [-86.6883, 30.9945],
            [-86.3886, 30.9942],
            [-86.3975, 30.3254],
            [-86.6459, 30.3462],
            [-86.8006, 30.3345],
            [-86.7857, 30.997],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201380',
        NAME: 'Okaloosa County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2409367476,
        AWATER: 393201926,
        INTPTLAT: '+30.6658510',
        INTPTLON: '-086.5941872',
        ELSDLEA: '',
        UNSDLEA: '01380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1422, 27.6432],
            [-80.8731, 27.6423],
            [-80.778, 27.6432],
            [-80.7772, 27.5587],
            [-80.6798, 27.5585],
            [-80.6779, 27.206],
            [-80.6774, 27.1216],
            [-80.8856, 26.9589],
            [-80.8712, 27.155],
            [-80.9432, 27.2106],
            [-81.2051, 27.4897],
            [-81.2116, 27.531],
            [-81.1422, 27.6432],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201410',
        NAME: 'Okeechobee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1991730024,
        AWATER: 318560291,
        INTPTLAT: '+27.3855924',
        INTPTLON: '-080.8873884',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4144, 28.785],
            [-81.4597, 28.6402],
            [-81.3279, 28.6103],
            [-80.9873, 28.613],
            [-80.8859, 28.5102],
            [-80.8629, 28.3475],
            [-81.6573, 28.3471],
            [-81.6586, 28.7669],
            [-81.6465, 28.7859],
            [-81.4144, 28.785],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201440',
        NAME: 'Orange County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2339574474,
        AWATER: 258790917,
        INTPTLAT: '+28.5144350',
        INTPTLON: '-081.3232950',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6573, 28.3471],
            [-80.8629, 28.3475],
            [-80.8689, 27.8225],
            [-80.8731, 27.6423],
            [-81.1422, 27.6432],
            [-81.1526, 27.7183],
            [-81.2144, 27.8285],
            [-81.2971, 27.8596],
            [-81.3087, 27.9219],
            [-81.381, 27.9834],
            [-81.3464, 28.0847],
            [-81.4558, 28.0849],
            [-81.5242, 28.1428],
            [-81.5575, 28.2594],
            [-81.6572, 28.2592],
            [-81.6573, 28.3471],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201470',
        NAME: 'Osceola County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3438608053,
        AWATER: 461983619,
        INTPTLAT: '+28.0590267',
        INTPTLON: '-081.1393116',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8856, 26.9589],
            [-80.1421, 26.9566],
            [-80.0228, 26.9704],
            [-79.9754, 26.8002],
            [-79.9806, 26.5954],
            [-80.0153, 26.321],
            [-80.2969, 26.3344],
            [-80.8812, 26.3338],
            [-80.8856, 26.9589],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201500',
        NAME: 'Palm Beach County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5101944591,
        AWATER: 1070020998,
        INTPTLAT: '+26.6491240',
        INTPTLON: '-080.4483739',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.754, 28.4333],
            [-82.2534, 28.4348],
            [-82.2533, 28.4785],
            [-82.0547, 28.4784],
            [-82.0558, 28.3128],
            [-82.1062, 28.2592],
            [-82.1059, 28.1716],
            [-82.6512, 28.1733],
            [-82.9046, 28.1719],
            [-82.8789, 28.2555],
            [-82.7953, 28.2998],
            [-82.754, 28.4333],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201530',
        NAME: 'Pasco County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1936599224,
        AWATER: 312708420,
        INTPTLAT: '+28.3074646',
        INTPTLON: '-082.4645839',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9046, 28.1719],
            [-82.6512, 28.1733],
            [-82.6483, 27.9474],
            [-82.5972, 27.9352],
            [-82.5415, 27.7822],
            [-82.6061, 27.6436],
            [-82.8134, 27.6457],
            [-82.8057, 27.7094],
            [-82.9013, 27.8391],
            [-82.8781, 28.0428],
            [-82.9046, 28.1719],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201560',
        NAME: 'Pinellas County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 709120966,
        AWATER: 865924096,
        INTPTLAT: '+27.9031220',
        INTPTLON: '-082.7395180',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0558, 28.3128],
            [-81.9576, 28.3452],
            [-81.6573, 28.3471],
            [-81.6572, 28.2592],
            [-81.5575, 28.2594],
            [-81.5242, 28.1428],
            [-81.4558, 28.0849],
            [-81.3464, 28.0847],
            [-81.381, 27.9834],
            [-81.3087, 27.9219],
            [-81.2971, 27.8596],
            [-81.2144, 27.8285],
            [-81.1526, 27.7183],
            [-81.1422, 27.6432],
            [-81.5633, 27.6466],
            [-82.0543, 27.6464],
            [-82.0563, 28.1716],
            [-82.1059, 28.1716],
            [-82.1062, 28.2592],
            [-82.0558, 28.3128],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201590',
        NAME: 'Polk County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4653250695,
        AWATER: 553800050,
        INTPTLAT: '+27.9531147',
        INTPTLON: '-081.6927831',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0556, 29.7182],
            [-82.0492, 29.7187],
            [-81.9394, 29.7475],
            [-81.8124, 29.8365],
            [-81.5812, 29.8402],
            [-81.5252, 29.7595],
            [-81.5237, 29.6224],
            [-81.5206, 29.5002],
            [-81.434, 29.3986],
            [-81.4509, 29.3785],
            [-81.6809, 29.3244],
            [-81.7414, 29.371],
            [-81.7762, 29.4874],
            [-81.843, 29.521],
            [-82.0559, 29.4712],
            [-82.0556, 29.7182],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201620',
        NAME: 'Putnam County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1885199824,
        AWATER: 256515596,
        INTPTLAT: '+29.6060059',
        INTPTLON: '-081.7408939',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1631, 30.999],
            [-86.7857, 30.997],
            [-86.8006, 30.3345],
            [-86.9166, 30.3187],
            [-86.9201, 30.3866],
            [-87.179, 30.3346],
            [-87.125, 30.5002],
            [-87.2607, 30.6214],
            [-87.3124, 30.7348],
            [-87.2823, 30.9248],
            [-87.1631, 30.999],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201650',
        NAME: 'Santa Rosa County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2620446204,
        AWATER: 419623382,
        INTPTLAT: '+30.7018378',
        INTPTLON: '-087.0127623',
        ELSDLEA: '',
        UNSDLEA: '01650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7054, 27.3897],
            [-82.2523, 27.3862],
            [-82.2536, 27.2089],
            [-82.0565, 27.2078],
            [-82.0575, 27.0321],
            [-82.2552, 27.033],
            [-82.2555, 26.9453],
            [-82.4373, 26.946],
            [-82.4998, 27.0439],
            [-82.5647, 27.2056],
            [-82.7054, 27.3897],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201680',
        NAME: 'Sarasota County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1439467414,
        AWATER: 438988188,
        INTPTLAT: '+27.1843855',
        INTPTLON: '-082.3658351',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4144, 28.785],
            [-81.3669, 28.8792],
            [-81.3205, 28.8345],
            [-81.226, 28.8326],
            [-81.1776, 28.7859],
            [-81.1058, 28.8285],
            [-80.9873, 28.613],
            [-81.3279, 28.6103],
            [-81.4597, 28.6402],
            [-81.4144, 28.785],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201710',
        NAME: 'Seminole County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 801546672,
        AWATER: 92810898,
        INTPTLAT: '+28.7257089',
        INTPTLON: '-081.2196197',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6802, 30.1212],
            [-81.4337, 30.106],
            [-81.4369, 30.2523],
            [-81.3202, 30.2529],
            [-81.2052, 29.8224],
            [-81.1508, 29.6711],
            [-81.2626, 29.6662],
            [-81.3241, 29.6256],
            [-81.5237, 29.6224],
            [-81.5252, 29.7595],
            [-81.5812, 29.8402],
            [-81.6101, 29.9702],
            [-81.6889, 30.0286],
            [-81.6802, 30.1212],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201740',
        NAME: 'St. Johns County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1555777526,
        AWATER: 571964717,
        INTPTLAT: '+29.8906441',
        INTPTLON: '-081.3839483',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6798, 27.5585],
            [-80.2635, 27.5568],
            [-80.138, 27.2629],
            [-80.2848, 27.2637],
            [-80.2855, 27.206],
            [-80.6779, 27.206],
            [-80.6798, 27.5585],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201770',
        NAME: 'St. Lucie County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1480637109,
        AWATER: 300862878,
        INTPTLAT: '+27.3798120',
        INTPTLON: '-080.4435373',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3117, 28.9604],
            [-81.9542, 28.9601],
            [-81.9576, 28.3452],
            [-82.0558, 28.3128],
            [-82.0547, 28.4784],
            [-82.138, 28.5655],
            [-82.2216, 28.5932],
            [-82.2631, 28.6676],
            [-82.1714, 28.7898],
            [-82.3117, 28.9604],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201800',
        NAME: 'Sumter County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1417596662,
        AWATER: 84144128,
        INTPTLAT: '+28.7142938',
        INTPTLON: '-082.0747152',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2473, 30.2607],
            [-83.171, 30.3852],
            [-83.0787, 30.4381],
            [-82.9559, 30.4135],
            [-82.7946, 30.337],
            [-82.8005, 29.9321],
            [-82.8798, 29.8868],
            [-82.9631, 29.9931],
            [-83.1625, 30.11],
            [-83.2264, 30.1146],
            [-83.2473, 30.2607],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201830',
        NAME: 'Suwannee County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1783341102,
        AWATER: 9594316,
        INTPTLAT: '+30.1892438',
        INTPTLON: '-082.9927542',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9999, 30.0122],
            [-83.9922, 30.0962],
            [-83.9154, 30.2448],
            [-83.8202, 30.3035],
            [-83.4688, 30.3042],
            [-83.3673, 30.2604],
            [-83.3709, 29.8879],
            [-83.3189, 29.8226],
            [-83.3664, 29.6657],
            [-83.4813, 29.6303],
            [-83.6093, 29.6984],
            [-83.6473, 29.7971],
            [-83.7452, 29.8938],
            [-83.8085, 29.9043],
            [-83.9999, 30.0122],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201860',
        NAME: 'Taylor County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2702176496,
        AWATER: 489262125,
        INTPTLAT: '+30.0099084',
        INTPTLON: '-083.6094825',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4584, 30.1364],
            [-82.1426, 30.1431],
            [-82.1473, 30.11],
            [-82.3134, 29.9547],
            [-82.4187, 29.9231],
            [-82.5297, 29.9409],
            [-82.5693, 29.975],
            [-82.4584, 30.1364],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201890',
        NAME: 'Union County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 630807238,
        AWATER: 16049079,
        INTPTLAT: '+30.0542796',
        INTPTLON: '-082.3669180',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6809, 29.3244],
            [-81.4509, 29.3785],
            [-81.434, 29.3986],
            [-81.4173, 29.2612],
            [-81.1501, 29.266],
            [-81.1559, 29.411],
            [-81.0457, 29.4325],
            [-80.9068, 29.1447],
            [-80.6635, 28.7906],
            [-80.9679, 28.7902],
            [-80.9873, 28.613],
            [-81.1058, 28.8285],
            [-81.1776, 28.7859],
            [-81.226, 28.8326],
            [-81.3205, 28.8345],
            [-81.3669, 28.8792],
            [-81.3546, 28.9844],
            [-81.5021, 29.0978],
            [-81.5326, 29.1776],
            [-81.6123, 29.2029],
            [-81.6419, 29.2768],
            [-81.6809, 29.3244],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201920',
        NAME: 'Volusia County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2851713758,
        AWATER: 858278452,
        INTPTLAT: '+29.0577690',
        INTPTLON: '-081.1617920',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7134, 30.3002],
            [-84.2477, 30.3035],
            [-84.0755, 30.2736],
            [-84.0755, 30.0346],
            [-84.1413, 29.9935],
            [-84.2647, 29.9781],
            [-84.3252, 29.958],
            [-84.5444, 30.011],
            [-84.6693, 30.1252],
            [-84.7134, 30.3002],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201950',
        NAME: 'Wakulla County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1570621273,
        AWATER: 334901394,
        INTPTLAT: '+30.1403575',
        INTPTLON: '-084.3751122',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3886, 30.9942],
            [-86.1872, 30.994],
            [-86.035, 30.9933],
            [-86.0376, 30.7046],
            [-85.8444, 30.7031],
            [-85.9153, 30.6364],
            [-85.8668, 30.4876],
            [-85.9927, 30.3893],
            [-85.9999, 30.2131],
            [-86.1848, 30.278],
            [-86.3975, 30.3254],
            [-86.3886, 30.9942],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1201980',
        NAME: 'Walton County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2687690444,
        AWATER: 522844944,
        INTPTLAT: '+30.6312106',
        INTPTLON: '-086.1766139',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8444, 30.7031],
            [-85.8216, 30.7908],
            [-85.7361, 30.7464],
            [-85.619, 30.7439],
            [-85.5999, 30.8306],
            [-85.4823, 30.8295],
            [-85.4323, 30.7855],
            [-85.4348, 30.5676],
            [-85.4864, 30.5676],
            [-85.488, 30.4367],
            [-85.8535, 30.4406],
            [-85.9927, 30.3893],
            [-85.8668, 30.4876],
            [-85.9153, 30.6364],
            [-85.8444, 30.7031],
          ],
        ],
      },
      properties: {
        STATEFP: '12',
        SCSDLEA: '',
        GEOID: '1202010',
        NAME: 'Washington County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1509528105,
        AWATER: 85786032,
        INTPTLAT: '+30.6022166',
        INTPTLON: '-085.6627968',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
  ],
};

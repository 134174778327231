import types from '../types';

const initialState = {
  isImporting: false,
  importSucceded: false,
  importError: false,
};

const bentoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.IMPORT_BENTO_PROGRESS: {
      const { isImporting } = action;
      return {
        ...state,
        isImporting,
      };
    }

    case types.reducerTypes.IMPORT_BENTO_SUCCEDED: {
      return {
        ...state,
        isImporting: false,
        importSucceded: true,
        importError: false,
      };
    }

    default:
  }

  return state;
};

export default bentoReducer;

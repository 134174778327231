/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import MetadataRow from '../MetadataRow';
import Spinner from '../../../Spinner';
import {
  getAllMetadatas,
  selectMetadata,
  initializeFileWizardSelectedMetadatas,
} from '../../../../actions/fileWizard.action';
import './style.css';

const RadioContainer = ({ action }) => {
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.session.jwt);
  const allMetadatas = useSelector((state) => state.fileWizard.allMetadatas);
  const isFetchingData = useSelector((state) => state.fileWizard.isFetchingData);
  const selectedMetadatasIds = useSelector((state) => state.fileWizard.selectedMetadatasIds);

  const actionHandler = (actionParam) => {
    switch (actionParam) {
      case 'getDashboardMetadata':
        return getAllMetadatas;
      default:
        console.error('Error with fileWizardStructure action');
        return null;
    }
  };

  const onChangeHandler = (e) => {
    dispatch(selectMetadata(e.target));
  };

  useEffect(() => {
    dispatch(initializeFileWizardSelectedMetadatas());
    if (typeof actionHandler(action) === 'function') {
      if (allMetadatas.length <= 0) {
        dispatch(actionHandler(action)(jwt));
      }
    }
  }, []);

  const isChecked = (value) => {
    if (selectedMetadatasIds.includes(value)) return true;
    return false;
  };

  if (isFetchingData)
    return (
      <div className="spinner-container">
        <Spinner size="lg" />
      </div>
    );

  return (
    <>
      {!isFetchingData &&
        allMetadatas.map((el) => (
          <MetadataRow
            title={el.Data.userData.fileName}
            description={el.Data.userData.description}
            years={el.Data.userData.years}
            labels={el.Data.userData.granularity}
            date={el.Data.userData.createdAt}
            name={`checkbox-${el.Id}`}
            id={el.Id}
            key={el.Id}
            onChangeHandler={onChangeHandler}
            checked={isChecked(el.Id)}
          />
        ))}
    </>
  );
};

RadioContainer.propTypes = {
  action: PropTypes.string.isRequired,
};

export default RadioContainer;

/**
 * If selected visualization don't have VisualizationMetadata
 *  - Select the first one by default
 * If selected visualization have VisualizationMetadata
 *  - Select this one
 * If MetadataId change, status is persisted on the store.
 * */

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, forwardRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Container, Col, Row, Spinner } from 'react-bootstrap';

import { calculateChartHeight, regroupForRadar } from '../../../helpers/charts';
import { HIGHCHARTS_TYPES, HIGHCHARTS_TOOLTIP_DELAY } from '../../../constants/charts';

const RadarChart = forwardRef(
  ({ parsedData, segment_id = null, qtyOptions, chartLoaded }, chartRef) => {
    const [chartOptions, setChartOptions] = useState();
    const [loadingChart, setLoadingChart] = useState(true);

    function tooltipFormatter() {
      return `<div style="opacity: 0.99"><h3 style="text-align: center;">${this.series.name}</h3><hr width="100%" color="${this.point.color}"><table border="0" cellspacing="0" cellpadding="1" align="center"><tr><td>${this.point.y}%</td></tr></div>`;
    }
    useEffect(() => {
      let all_data;
      let xaxis_categories;
      let margin_left;
      let spacing_left = 10;

      if (parsedData.segmentsActive) {
        margin_left = 300;
        spacing_left = 190;
      } else {
        margin_left = 240;
      }

      const legend = {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        symbolRadius: 50,
        symbolWidth: 10,
        symbolHeight: 10,
        itemStyle: {
          color: '#606060',
        },
      };

      // If it's segmented chart get segmented series
      if (parsedData.segmentsActive) {
        all_data = parsedData.dataSeries[segment_id];
        // Otherwise get just the parsed data for non-benchmarked data
      } else {
        [all_data] = parsedData.dataSeries;
      }

      const series_data = regroupForRadar(all_data, xaxis_categories);
      xaxis_categories = parsedData.categories;
      const chartHeight = calculateChartHeight(qtyOptions);

      // This is a default theme for the MLI bar chart
      // These options can be overwritten when creating the chart and passing other options
      const default_theme = {
        chart: {
          events: {
            load() {
              chartLoaded();
            },
          },
          height: chartHeight < 400 ? 400 : chartHeight,
          polar: true,
          type: HIGHCHARTS_TYPES.LINE,
          marginTop: 30,
          marginLeft: margin_left,
          spacingLeft: spacing_left,

          // backgroundColor option is static
          backgroundColor: null,

          // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
          style: {
            fontFamily: 'AvenirNext-Regular',
          },

          // plotBorderColor option is static
          plotBorderColor: '#f7f7f7',
        },
        // title formatting options will be static
        title: {
          text: null,
        },
        credits: { enabled: false },
        navigation: {
          activeColor: '#ac193c',
          buttonOptions: {
            enabled: false,
          },
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
        legend,
        // xAxis formatting will be static
        xAxis: {
          categories: xaxis_categories,
          gridLineColor: '#b8b4b4',
          labels: {
            rotation: 0,
            step: 1,
            padding: 0,
            style: {
              color: '#121111',
              fontSize: '14px',
            },
          },
          lineColor: '#b8b4b4',
          minorGridLineColor: '#b8b4b4',
          tickColor: '#f7f7f7',
          tickInterval: 1,
          title: {
            style: {
              color: '#121111',
            },
          },
          tickmarkPlacement: 'on',
          lineWidth: 0,
        },

        // yAxis formatting will be static
        yAxis: {
          labels: {
            format: '{value}%',
            style: {
              color: '#bdbdbd',
              fontSize: '14px',
            },
          },
          lineColor: '#b8b4b4',
          minorGridLineColor: 'transparent',
          tickColor: '#f7f7f7',
          tickWidth: 1,
          title: {
            style: {
              color: '#121111',
            },
          },
          gridLineInterpolation: 'polygon',
          lineWidth: 0,
          min: 0,
        },
        tooltip: {
          hideDelay: HIGHCHARTS_TOOLTIP_DELAY,
          backgroundColor: 'rgba(255,255,255, 0.98)',
          useHTML: true,
          formatter: tooltipFormatter,
        },
      };

      setChartOptions({ ...default_theme, series: series_data });
      setLoadingChart(false);
    }, []);

    if (loadingChart) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />;
  }
);

RadarChart.displayName = 'RadarChart';

export default React.memo(RadarChart);

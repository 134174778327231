/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, forwardRef } from 'react';
import { Container, Col, Row, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEyeSlash,
  faFileAlt,
  faChartLine,
  faExternalLinkAlt,
  faEye,
  faFilter,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import shortid from 'shortid';

import LineChart from '../Chart/LineChart';
import BarChart from '../Chart/BarChart';
import { getDataSetLabel } from '../../helpers/charts';
import { COMPONENT_ID_MAP } from '../../constants/charts';

const DashboardLine = forwardRef(
  (
    {
      componentsConfig,
      visualizationDetails,
      handleHideChart,
      menuOptions,
      handleUpdateDataSet,
      onAddToReport,
      onExportChart,
      readOnly,
    },
    chartRef
  ) => {
    const [jsonData, setJsonData] = useState();
    const visualizationComponent = useMemo(
      () => visualizationDetails.components.find((c) => c.id === componentsConfig.componentId),
      [visualizationDetails.components, componentsConfig.componentId]
    );
    const dataLabel = getDataSetLabel(visualizationComponent.datasetFilter);

    const onChangeBenchmarkNational = () => {
      const newComponentVisualizationConfig = { ...visualizationComponent };
      newComponentVisualizationConfig.benchmarks.national = !newComponentVisualizationConfig
        .benchmarks.national;
      newComponentVisualizationConfig.benchmarks.state = false;
      handleUpdateDataSet(newComponentVisualizationConfig);
    };

    const onChangeBenchmarkState = () => {
      const newComponentVisualizationConfig = { ...visualizationComponent };
      newComponentVisualizationConfig.benchmarks.state = !newComponentVisualizationConfig.benchmarks
        .state;
      newComponentVisualizationConfig.benchmarks.national = false;
      handleUpdateDataSet(newComponentVisualizationConfig);
    };

    const onDatasetChange = (dataSetLvl1, dataSetLvl2, dataSetLvl3) => {
      const newFilters = [];
      if (dataSetLvl1) {
        newFilters.push({ ...dataSetLvl1, children: [] });
      }
      if (dataSetLvl2) {
        newFilters.push({ ...dataSetLvl2, children: [] });
      }
      if (dataSetLvl3) {
        newFilters.push({ ...dataSetLvl3, children: [] });
      }
      const newVisualizationComponent = { ...visualizationComponent };
      newVisualizationComponent.datasetFilter.filter.constraints[0].value = newFilters;
      handleUpdateDataSet(newVisualizationComponent);
    };

    const handleChartLoaded = () => {};

    const saveChartRef = () => {};

    useEffect(() => {
      setJsonData(JSON.parse(componentsConfig.queryResult.parsedDataJSON));
    }, [componentsConfig.queryResult.parsedDataJSON]);

    return (
      <Container fluid className="p-0">
        {!readOnly && (
          <Container fluid className="p-4 bg-gray-200">
            <Row noGutters>
              <Col md={9} className="d-flex">
                {componentsConfig?.queryResult?.filtered && (
                  <OverlayTrigger overlay={<Tooltip id="map-hide">Filtered</Tooltip>} delay={200}>
                    <FontAwesomeIcon
                      color="#AC193C"
                      icon={faFilter}
                      className="mt-0 mr-5"
                      size="lg"
                    />
                  </OverlayTrigger>
                )}
                <Dropdown>
                  <Dropdown.Toggle className="d-flex p-0 border-0 bg-gray-200 text-dark not-hover">
                    <h3 className="text-left cut-text">
                      {visualizationComponent.datasetFilter.description}
                    </h3>
                  </Dropdown.Toggle>
                  <Dropdown.Menu size="sm" title="">
                    {menuOptions.map((option) => {
                      if (option.children.length) {
                        return (
                          <Dropdown key={shortid.generate()}>
                            <Dropdown.Toggle
                              variant="secondary"
                              className="w-100 d-flex bg-white text-dark border-0 px-4"
                            >
                              {option.label}
                            </Dropdown.Toggle>
                            <Dropdown.Menu size="sm" className="w-100">
                              {option.children.map((subOption) => {
                                if (subOption.children.length) {
                                  return (
                                    <Dropdown key={shortid.generate()}>
                                      <Dropdown.Toggle
                                        variant="secondary"
                                        className="w-100 d-flex bg-white text-dark border-0 px-4"
                                      >
                                        {subOption.label}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu size="sm" className="w-100">
                                        {subOption.children.map((lastOption) => (
                                          <Dropdown.Item
                                            className="w-100 custom-text-wrap"
                                            key={shortid.generate()}
                                            onClick={() =>
                                              onDatasetChange(option, subOption, lastOption)
                                            }
                                          >
                                            {lastOption.label}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  );
                                }
                                return (
                                  <Dropdown.Item
                                    className="w-100 custom-text-wrap"
                                    key={shortid.generate()}
                                    onClick={() => onDatasetChange(option, subOption, null)}
                                  >
                                    {subOption.label}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        );
                      }
                      return (
                        <Dropdown.Item
                          className="w-100 custom-text-wrap"
                          key={shortid.generate()}
                          onClick={() => onDatasetChange(option, null, null)}
                        >
                          {option.label}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col md={3} className="d-flex justify-content-end">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="map-hide">
                      {visualizationComponent.hidden ? 'View' : 'Hide'}
                    </Tooltip>
                  }
                  delay={200}
                >
                  <FontAwesomeIcon
                    icon={visualizationComponent.hidden ? faEye : faEyeSlash}
                    className="cursor-pointer mt-0"
                    size="lg"
                    onClick={handleHideChart}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="map-hide">
                      Benchmark/National {visualizationComponent.benchmarks.national ? 'On' : 'Off'}
                    </Tooltip>
                  }
                  delay={200}
                >
                  <FontAwesomeIcon
                    onClick={onChangeBenchmarkNational}
                    className="ml-5  mt-0 cursor-pointer"
                    icon={faChartLine}
                    size="lg"
                    color={visualizationComponent.benchmarks.national && '#58bfc4'}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="map-hide">
                      Benchmark/State {visualizationComponent.benchmarks.state ? 'On' : 'Off'}
                    </Tooltip>
                  }
                  delay={200}
                >
                  <FontAwesomeIcon
                    onClick={onChangeBenchmarkState}
                    className="ml-5  mt-0 cursor-pointer"
                    icon={faChartBar}
                    size="lg"
                    color={visualizationComponent.benchmarks.state && '#58bfc4'}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Export</Tooltip>} delay={200}>
                  <FontAwesomeIcon
                    className="ml-5 mt-0 cursor-pointer"
                    icon={faExternalLinkAlt}
                    size="lg"
                    onClick={onExportChart}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add to report</Tooltip>} delay={200}>
                  <FontAwesomeIcon
                    className="ml-5  mt-0 cursor-pointer"
                    icon={faFileAlt}
                    size="lg"
                    onClick={onAddToReport}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
          </Container>
        )}
        <Container fluid className="p-4 bg-white">
          {!visualizationComponent.hidden && (
            <>
              {componentsConfig.componentId === COMPONENT_ID_MAP.ID_LINE && jsonData?.line && (
                <LineChart
                  dataLabel={dataLabel}
                  parsedData={jsonData.line}
                  ref={chartRef}
                  showCalculateMoeMessage={false}
                />
              )}
              {componentsConfig.componentId === COMPONENT_ID_MAP.ID_MLI_BAR && jsonData?.bar && (
                <BarChart
                  parsedData={jsonData.bar}
                  benchmarks={visualizationComponent.benchmarks}
                  qtyOptions={10}
                  chartLoaded={handleChartLoaded}
                  saveChart={saveChartRef}
                  ref={chartRef}
                  showCalculateMoeMessage={false}
                  dashboardContext
                />
              )}
            </>
          )}
        </Container>
      </Container>
    );
  }
);

DashboardLine.displayName = 'DashboardLine';

export default React.memo(DashboardLine);

/* eslint-disable no-param-reassign */
import { cloneArrayOfObjects } from '../helpers/helpers';
import types from '../types';

const initialState = {
  surveys: false,
  surveyFetchErr: false,
  isFetchingSurveys: false,
  surveySpinner: {},
  surveyQuestions: {},
  isLoadingSurveyQuestions: false,
  surveyMetadataDescription: [],
};

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.GET_ALL_SURVEYS: {
      const clonedSurveysArr = cloneArrayOfObjects(action.surveys);
      clonedSurveysArr.forEach((survey) => {
        survey.Data.wasClosed = false;
      });
      return {
        ...state,
        surveys: clonedSurveysArr || [],
      };
    }
    case types.reducerTypes.IS_FETCHING_SURVEYS: {
      const { isFetchingSurveys } = action;
      return {
        ...state,
        isFetchingSurveys,
      };
    }
    case types.reducerTypes.SURVEY_TITLE_UPDATED: {
      const clonedSurveysArr = cloneArrayOfObjects(state.surveys);
      clonedSurveysArr.forEach((survey) => {
        if (survey.Id === action.Id) {
          survey.Data.title = action.resTitle;
        }
      });
      return {
        ...state,
        surveys: clonedSurveysArr,
        surveyUpdatetitleErr: false,
      };
    }
    case types.reducerTypes.SURVEY_PUBLISHED: {
      const { Id: surveyId } = action.publishedSurvey;
      const clonedSurveysArr = cloneArrayOfObjects(state.surveys);
      clonedSurveysArr.forEach((survey) => {
        if (survey.Id === surveyId) {
          survey.Data.isPublished = true;
        }
      });

      return {
        ...state,
        surveys: clonedSurveysArr,
      };
    }
    case types.reducerTypes.SURVEY_UNPUBLISHED: {
      const { Id: surveyId } = action.publishedSurvey;
      const clonedSurveysArr = cloneArrayOfObjects(state.surveys);
      clonedSurveysArr.forEach((survey) => {
        if (survey.Id === surveyId) {
          survey.Data.isPublished = false;
        }
      });

      return {
        ...state,
        surveys: clonedSurveysArr,
      };
    }
    case types.reducerTypes.DRAFT_SURVEY_CREATED: {
      const { Id: parentId } = action.updatedParent;
      const clonedSurveysArr = cloneArrayOfObjects(state.surveys);
      const index = clonedSurveysArr.findIndex((element) => element.Id === parentId);
      clonedSurveysArr.splice(index, 1, action.updatedParent);
      return {
        ...state,
        surveys: clonedSurveysArr,
      };
    }

    case types.reducerTypes.DRAFT_SURVEY_CONVERTED: {
      const { Parent } = action;
      const clonedSurveysArr = cloneArrayOfObjects(state.surveys);
      const index = clonedSurveysArr.findIndex((element) => element.Id === Parent);
      clonedSurveysArr.splice(index, 1, action.updatedSurvey);
      return {
        ...state,
        surveys: clonedSurveysArr,
      };
    }

    case types.reducerTypes.SHOW_SURVEY_SNACKBAR: {
      const { id, snackBarType } = action;
      const clonedSurveysArr = cloneArrayOfObjects(state.surveys);
      clonedSurveysArr.forEach((survey) => {
        if (survey.Id === id) {
          survey.Data.snackBar = snackBarType;
        }
      });
      return {
        ...state,
        surveys: clonedSurveysArr,
      };
    }

    case types.reducerTypes.HIDE_SURVEY_SNACKBAR: {
      const { id } = action;
      const clonedSurveysArr = cloneArrayOfObjects(state.surveys);
      if (clonedSurveysArr.length > 0) {
        clonedSurveysArr.forEach((survey) => {
          if (survey.Id === id) {
            survey.Data.snackBar = null;
          }
        });
      }
      return {
        ...state,
        surveys: clonedSurveysArr,
      };
    }

    case types.reducerTypes.DRAFT_DELETED: {
      const { draftSurveyId, parentId } = action.surveyToRemove;
      const clonedSurveysArr = cloneArrayOfObjects(state.surveys);
      const index = clonedSurveysArr.findIndex((element) => element.Id === parentId);
      clonedSurveysArr[index].Drafts = clonedSurveysArr[index].Drafts.filter(
        (surveyDraft) => surveyDraft.Id !== draftSurveyId
      );
      return {
        ...state,
        surveys: clonedSurveysArr,
      };
    }

    case types.reducerTypes.SURVEY_SPINNER: {
      const clonedSurveysArr = cloneArrayOfObjects(state.surveys);
      const { showSpinner, spinnerActionType, spinnerId } = action.payload;

      const addSpinner = (arr = [], elementId) => {
        arr.forEach((element) => {
          if (element.Id === elementId) {
            if (showSpinner) {
              element.Data.spinner = spinnerActionType;
            } else {
              element.Data.spinner = undefined;
            }
          }
          if (element.Drafts) addSpinner(element.Drafts, elementId);
        });
      };

      addSpinner(clonedSurveysArr, spinnerId);
      return {
        ...state,
        surveys: clonedSurveysArr,
      };
    }
    case types.reducerTypes.SURVEY_DELETED: {
      const clonedSurveysArr = cloneArrayOfObjects(state.surveys);
      return {
        ...state,
        surveys: clonedSurveysArr.filter((sd) => sd.Id !== action.id),
      };
    }
    case types.reducerTypes.SURVEY_QUESTIONS_SURVEY_ID_SECTION_PENDING: {
      return {
        ...state,
        surveyQuestions: {},
        isLoadingSurveyQuestions: false,
      };
    }
    case types.reducerTypes.SURVEY_QUESTIONS_SURVEY_ID_SECTION_SUCCESS: {
      return {
        ...state,
        surveyQuestions: action.payload,
        isLoadingSurveyQuestions: false,
      };
    }
    case types.reducerTypes.SURVEY_QUESTIONS_SURVEY_ID_SECTION_ERROR: {
      return {
        ...state,
        surveyQuestions: {},
        isLoadingSurveyQuestions: false,
      };
    }
    case types.reducerTypes.SURVEY_GET_METADATA_DESCRIPTION_SUCCESS: {
      return {
        ...state,
        surveyMetadataDescription: action.payload,
      };
    }
    case types.reducerTypes.SURVEY_UPDATE_METADATA_DESCRIPTION: {
      return {
        ...state,
        surveyMetadataDescription: state.surveyMetadataDescription.map((smd) =>
          smd.Id === action.payload.Id ? action.payload : smd
        ),
      };
    }
    case types.reducerTypes.SURVEY_CREATE_METADATA_DESCRIPTION: {
      return {
        ...state,
        surveyMetadataDescription: [...state.surveyMetadataDescription, action.payload],
      };
    }
    default:
  }
  return state;
};

export default surveyReducer;

import axios from 'axios';
import types from '../types';
import { API_ROOT } from '../config/appConfig';

export const sendMail = async (token, jwt, userData, endMailRequest) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/admin-backend/signup`,
      {
        token,
        userData,
      },
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (res.status === 200) {
      endMailRequest(types.email.success);
      return res.data;
    }
    return false;
  } catch (err) {
    endMailRequest(types.email.error);
    console.error(err);
    return err;
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useEffect, forwardRef, useState, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Alert, Container, Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import shortid from 'shortid';

import { calculateChartHeight, makeMLITooltip } from '../../../helpers/charts';
import { HIGHCHARTS_TYPES, HIGHCHARTS_TOOLTIP_DELAY } from '../../../constants/charts';

const BarChart = forwardRef(
  (
    {
      parsedData,
      benchmarks = {},
      dashboardContext = false,
      // qtyOptions,
      chartLoaded,
      showCalculateMoeMessage = true,
      currentJob,
    },
    chartRef
  ) => {
    const numFormatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
    });
    const [chartOptions, setChartOptions] = useState([]);
    const [loadingChart, setLoadingChart] = useState(true);
    const getTextHeight = (offset_width, font_size, seg_text, font_constant = 2.7) => {
      const line_height = 18;
      const num_chars_per_line = offset_width / (font_size * font_constant);
      const num_lines = Math.ceil(seg_text.length / num_chars_per_line);
      const seg_text_height = num_lines * line_height;

      return seg_text_height;
    };

    const getSegmentTextStart = (seg_text, font_size, offset_height, offset_width) => {
      const seg_text_height = getTextHeight(offset_width, font_size, seg_text);
      const text_start_height = Math.floor((offset_height - seg_text_height) / 2);

      return text_start_height;
    };
    // Bento V2: send value prefix/suffix from server
    const valueLabelPrefix = useMemo(() => {
      return parsedData.valueLabelPrefix !== undefined && parsedData.valueLabelPrefix !== null
        ? parsedData.valueLabelPrefix
        : '';
    }, [parsedData]);

    // if suffix is not sent use the percent symbol by default, which is the expected on survey explorer
    const valueLabelSuffix = useMemo(() => {
      if (currentJob?.questionType === 'OPEN_NUMERIC') {
        return '';
      }
      return parsedData.valueLabelSuffix !== undefined && parsedData.valueLabelSuffix !== null
        ? parsedData.valueLabelSuffix
        : '%';
    }, [parsedData, currentJob]);

    const getDisplayValue = (value) => {
      // , isPercentage) => {
      if (value === null || value === undefined) {
        return null;
      }
      // strip decimals for percentage values, leave them otherwise
      return Number.isNaN(Number(value)) ? value : numFormatter.format(value);
    };

    function xAxisFormatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      return this.value;
    }

    function plotOptionFormater() {
      const display_value = getDisplayValue(this.y, valueLabelSuffix === '%');
      const label = display_value ? `${valueLabelPrefix}${display_value}${valueLabelSuffix}` : '';
      return `<div>${label}</div>`;
    }

    function yAxisFormatter() {
      return `${valueLabelPrefix}${getDisplayValue(this.value)}${valueLabelSuffix}`;
    }

    function tooltipFormatter() {
      let text_color;
      let text;
      let percent_diff;

      const benchmark = this.series.userOptions.benchmark_array
        ? this.series.userOptions.benchmark_array[this.point.x]
        : this.series.userOptions.benchmark_value;
      let benchmark_label = this.series.userOptions.benchmark_labels
        ? this.series.userOptions.benchmark_labels[this.point.x]
        : this.series.userOptions.benchmark_label;
      const this_category = this.point.category;
      let nat_category = false;
      const { filtered, segmented, benchmarked } = this.series.userOptions;

      const isPercentage = valueLabelSuffix === '%';

      const moe_array = Array.isArray(this.series.userOptions.MOE);
      const moe = getDisplayValue(
        moe_array ? this.series.userOptions.MOE[this.point.x] : this.series.userOptions.MOE,
        isPercentage
      );

      if (segmented) {
        if (benchmarked) {
          if (this_category.includes('National')) {
            nat_category = true;
          } else if (!this_category.includes('State') && !filtered) {
            nat_category = true;
          }
        } else if (this_category.includes('National')) {
          nat_category = true;
        } else if (
          (this_category.includes('All respondents') && !filtered) ||
          (this_category.includes('deafult') && !filtered)
        ) {
          nat_category = true;
        }
      } else if (!filtered && !dashboardContext) {
        nat_category = true;
      }

      if (benchmark !== undefined && benchmark !== null && !nat_category) {
        percent_diff = Math.round(this.point.y - benchmark);

        if (percent_diff >= 0) {
          text_color = '#6f9c2c';
          text = 'greater than';
        } else {
          text_color = '#ac193c';
          text = 'less than';
          percent_diff = getDisplayValue(Math.abs(percent_diff), isPercentage);
        }
      } else {
        percent_diff = 'NA';
        text_color = '#cbd0d5';
        text = '';
        benchmark_label = '';
      }

      const current_series_name = dashboardContext ? this.x : this.series.name;

      return makeMLITooltip(
        current_series_name,
        getDisplayValue(this.y, isPercentage),
        percent_diff,
        moe,
        text,
        benchmark_label,
        this.series.color,
        text_color,
        false,
        showCalculateMoeMessage,
        valueLabelPrefix,
        valueLabelSuffix
      );
    }

    const buildChart = (segment_id) => {
      // Construct options for chart
      let series_data;
      let xaxis_categories;
      let margin_top;
      let margin_left;
      let spacing_left;
      margin_top = 90;
      spacing_left = 60;
      const benchmarks_active = benchmarks.state || benchmarks.national;

      if (!dashboardContext) {
        margin_left = 240;
      } else {
        margin_left = 160;
      }
      if (!benchmarks_active && parsedData.segmentsActive) {
        margin_left = dashboardContext ? 140 : 300;
        spacing_left = 290;
      }
      const legend = {
        enabled: true,
        layout: 'horizontal',
        x: 0,
        y: dashboardContext ? 10 : -10,
        margin: 0,
        padding: 0,
        symbolRadius: 0,
        itemStyle: {
          fontSize: '14px',
          color: '#606060',
          textOverflow: 'wrap',
        },
        paddingRight: 20,
        maxHeight: 120,
        width: '100%',
        navigation: {
          activeColor: '#ac193c',
        },
        itemHoverStyle: {
          color: '#606060',
        },
        itemHiddenStyle: {
          color: '#606060',
        },
        align: 'left',
        verticalAlign: 'top',
      };

      // If it's segmented chart get segmented series
      if (parsedData.segmentsActive && parsedData.segmentedSeries) {
        series_data = parsedData.segmentedSeries[0][segment_id];
        xaxis_categories = parsedData.segmentedSeries[1][segment_id];
        if (segment_id !== 0) {
          legend.enabled = false;
          margin_top = 0;
        }
        // Otherwise get just the parsed data for non-benchmarked data
      } else {
        [series_data] = parsedData.dataSeries;
        xaxis_categories = parsedData.categories;
      }

      if (!xaxis_categories) {
        return { noData: true };
      }

      const maxCatSize = Math.max(...xaxis_categories.map((x) => x.length));

      let chartHeight = calculateChartHeight(xaxis_categories.length);

      if (chartHeight < 215 && maxCatSize > 90) {
        chartHeight = 400;
      } else if (segment_id === 0) {
        chartHeight += 100;
      } else if (chartHeight < 115) {
        chartHeight = 400;
      }
      if (
        valueLabelSuffix === '%' &&
        series_data?.length &&
        series_data[0]?.data?.length > 1 /* multiple segments per bar? */
      ) {
        // make sure percentages sum is 100 in each bar when showing percentage values
        for (let di = 0; di < series_data[0].data.length; di += 1) {
          // di: data index
          // find sum of segment values in current series
          let total = 0;
          for (let si = 0; si < series_data.length; si += 1) {
            // si: series index
            total += series_data[si].data[di];
          }

          if (total !== 100) {
            let mustRecalculateTotal = false;

            if (total !== 97 && total !== 98 && total !== 99 && total !== 101 && total !== 102) {
              mustRecalculateTotal = true;
              for (let si = 0; si < series_data.length; si += 1) {
                series_data[si].data[di] = (series_data[si].data[di] * 100.0) / total;
                series_data[si].data[di] -= series_data[si].data[di] % 1; // remove the decimal part
              }
            }
            if (mustRecalculateTotal) {
              total = 0;
              for (let sit = 0; sit < series_data.length; sit += 1) {
                // sit = serie index total
                total += series_data[sit].data[di];
              }
            }
            if (total === 101 || total === 102) {
              let newSi = 0;
              while (
                newSi < series_data.length &&
                (series_data[newSi].data[di] === 0 || series_data[newSi].data[di] === 1)
              ) {
                newSi += 1;
              }
              if (newSi < series_data.length && series_data[newSi].data[di] > 1) {
                series_data[newSi].data[di] -= 1;
                if (total === 102) {
                  series_data[newSi].data[di] -= 1;
                }
              }
            } else if (total === 97 || total === 98 || total === 99) {
              if (
                series_data[0].data &&
                series_data[0].data.length > 0 &&
                di < series_data[0].data.length
              ) {
                series_data[0].data[di] += 100 - total;
              }
            }
          }
        }
      } else if (
        valueLabelSuffix === '%' &&
        series_data?.length &&
        series_data?.length > 1 &&
        series_data[0]?.data?.length === 1 /* multiple segments one bar? */
      ) {
        // make sure percentages sum is 100 in the one and only bar when showing percentage values
        let total = 0;
        for (let si = 0; si < series_data.length; si += 1) {
          // si: series index
          total += series_data[si].data[0];
        }
        if (total === 101 || total === 102) {
          let newSi = 0;
          while (
            newSi < series_data.length &&
            (series_data[newSi].data[0] === 0 || series_data[newSi].data[0] === 1)
          ) {
            newSi += 1;
          }
          if (newSi < series_data.length && series_data[newSi].data[0] > 1) {
            series_data[newSi].data[0] -= 1;
            if (total === 102) {
              series_data[newSi].data[0] -= 1;
            }
          }
        } else if (total === 97 || total === 98 || total === 99) {
          series_data[0].data[0] += 100 - total;
        }
      }

      const barStackingEnabled = parsedData.stackingEnabled;

      // This is a default theme for the MLI bar chart
      // These options can be overwritten when creating the chart and passing other options
      const default_theme = {
        segment_id,
        chart: {
          type: HIGHCHARTS_TYPES.BAR,
          marginTop: margin_top,
          paddingTop: 0,
          height: chartHeight,

          // backgroundColor option is static
          backgroundColor: null,
          marginLeft: margin_left + 60,
          spacingLeft: spacing_left,

          // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
          style: {
            fontFamily: 'AvenirNext-Regular',
          },

          // plotBorderColor option is static
          plotBorderColor: '#f7f7f7',
          events: {
            load() {
              const isBenchmarkActive = benchmarks.state || benchmarks.national;
              if (!isBenchmarkActive && typeof segment_id !== 'undefined' && segment_id !== null) {
                const seg_text = parsedData.categories[segment_id];
                const font_size = 14;
                const text_start_height = getSegmentTextStart(
                  seg_text,
                  font_size,
                  this.container.offsetHeight,
                  this.container.offsetWidth
                );
                const path_length = this.container.offsetHeight;
                this.renderer
                  .label(seg_text, 0, text_start_height, undefined, undefined, undefined, true)
                  .css({
                    width: '170px',
                    textAlign: 'end',
                    color: '#121111',
                    fontSize: `${font_size}px`,
                  })
                  .add();

                const linePosition = dashboardContext ? 115 : 190;
                // Draw a line at the edge of margin
                this.renderer
                  .path(['M', linePosition, 5, 'L', linePosition, path_length])
                  .attr({
                    'stroke-width': 0.6,
                    stroke: '#121111',
                  })
                  .add();
              }
              chartLoaded();
            },
          },
        },

        // title formatting options will be static
        title: {
          text: null,
        },
        credits: { enabled: false },
        navigation: {
          activeColor: '#ac193c',
          buttonOptions: {
            enabled: false,
          },
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },

        // xAxis formatting will be static
        xAxis: {
          categories: xaxis_categories,
          gridLineColor: '#b8b4b4',
          labels: {
            style: {
              color: '#121111',
              fontSize: '14px',
              textAlign: 'right',
              whiteSpace: 'wrap',
              textOverflow: 'none',
            },
            formatter: xAxisFormatter,
            align: 'right',
            x: -10,
            y: null,
          },
          lineColor: '#b8b4b4',
          minorGridLineColor: '#b8b4b4',
          tickColor: '#f7f7f7',
          title: {
            style: {
              color: '#121111',
            },
          },
        },

        // yAxis formatting will be static
        yAxis: {
          reversedStacks: false,
          min: 0,
          gridLineColor: dashboardContext ? 'transparent' : '#b8b4b4',
          labels: {
            enabled: true,
            formatter: yAxisFormatter,
            style: {
              color: '#bdbdbd',
              fontSize: '14px',
            },
          },
          lineColor: '#b8b4b4',
          minorGridLineColor: '#b8b4b4',
          tickColor: '#f7f7f7',
          tickWidth: !dashboardContext ? 1 : 0,
          tickInterval: !dashboardContext ? 20 : 100,
          endOnTick: false,
          title: {
            text: '',
            style: {
              color: '#121111',
            },
          },
        },
        tooltip: {
          hideDelay: HIGHCHARTS_TOOLTIP_DELAY,
          backgroundColor: 'rgba(255,255,255,1)',
          style: {
            color: '#343434',
            fontWeight: 'normal',
          },
          useHTML: true,
          padding: 0,
          formatter: tooltipFormatter,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              formatter: plotOptionFormater,
              padding: 0,
              rotation: 0,
              shadow: false,
              style: {
                textOutline: false,
                fontSize: '14px',
              },
              shape: 'square',
              useHTML: false,
            },
            marker: {
              lineColor: '#b8b4b4',
            },
          },
          bar: {
            stacking: dashboardContext && barStackingEnabled ? 'percent' : 'normal',
            events: {
              legendItemClick: () => {
                return false;
              },
            },
          },
        },
        legend,
      };

      if (series_data) {
        if (dashboardContext) {
          default_theme.plotOptions.series.dataLabels.style.color = '#bdbdbd';
        }
        return { ...default_theme, series: series_data };
      }
      return { noData: true };
    };

    useEffect(() => {
      const chartList = [];
      if (parsedData.segmentsActive && parsedData.segmentedSeries) {
        for (let i = 0; i < parsedData.categories.length; i += 1) {
          if (
            !parsedData.suppressed ||
            parsedData.suppressed === null ||
            !parsedData.suppressed[i]
          ) {
            const c = buildChart(i);
            chartList.push(c);
          } else {
            chartList.push({ suppressed: true });
          }
        }
        const maxChartHeight = Math.max(
          ...chartList.filter((c) => !c.suppressed).map((x) => x.chart.height)
        );
        chartList
          .filter((c) => !c.suppressed)
          .forEach((_, i) => {
            chartList[i].chart.height = i === 0 ? maxChartHeight + 80 : maxChartHeight;
          });
      } else if (
        !parsedData.suppressed ||
        parsedData.suppressed === null ||
        !parsedData.suppressed[0]
      ) {
        const c = buildChart();
        chartList.push(c);
      } else {
        chartList.push({ suppressed: true });
        chartLoaded();
      }
      setChartOptions(chartList);
      setLoadingChart(false);
    }, [parsedData]);

    if (loadingChart) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }

    return chartOptions.map((co) => {
      if (co.suppressed) {
        return (
          <Alert className="m-3" variant="warning" key={shortid.generate()}>
            <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data
            visualization suppressed
          </Alert>
        );
      }
      if (co.noData) {
        return (
          <Alert className="m-3" variant="warning" key={shortid.generate()}>
            <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data not
            available
          </Alert>
        );
      }
      return (
        <HighchartsReact
          key={shortid.generate()}
          highcharts={Highcharts}
          options={co}
          ref={chartRef}
        />
      );
    });
  }
);

BarChart.displayName = 'BarChart';

export default BarChart;

import { getTopicsApi, getTopicByIdApi } from '../api/topics';
import types from '../types';

export const getTopics = (jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.TOPIC_LIST_PENDING });
    const data = await getTopicsApi(jwt);
    dispatch({
      type: types.reducerTypes.TOPIC_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.TOPIC_LIST_ERROR,
      payload: e.message || 'Unexpected Error!',
    });
  }
};

export const getTopicById = (id, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.TOPIC_ID_PENDING });
    const data = await getTopicByIdApi(id, jwt);
    dispatch({
      type: types.reducerTypes.TOPIC_ID_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.TOPIC_ID_ERROR,
      payload: 'Unexpected Error!',
    });
  }
};

export const hideErrorToast = () => ({ type: types.reducerTypes.SURVEY_SUMMARY_HIDE_ERROR_TOAST });

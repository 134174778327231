/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, forwardRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Alert, Container, Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { calculateChartHeight, findFirstValidValue } from '../../../helpers/charts';
import { HIGHCHARTS_TYPES, HIGHCHARTS_TOOLTIP_DELAY } from '../../../constants/charts';

// time tick intervals
const dayTickInterval = 24 * 3600 * 1000;
const weekTickInterval = dayTickInterval * 7;
const monthTickInterval = dayTickInterval * 30;
const yearTickInterval = monthTickInterval * 12;

const LineChart = forwardRef(
  ({ parsedData, dataLabel = null, qtyOptions, chartLoaded }, chartRef) => {
    const numFormatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
    });
    const [chartOptions, setChartOptions] = useState();
    const [loadingChart, setLoadingChart] = useState(true);

    let axisLabelPrefix = '';
    let axisLabelSuffix = '';
    if (parsedData[0].length > 0 && parsedData[0][0].data.length > 0) {
      axisLabelPrefix = findFirstValidValue(parsedData[0][0].data, (p) => p.valuePrefix, '');
      axisLabelSuffix = findFirstValidValue(parsedData[0][0].data, (p) => p.valueSuffix, '');
    }

    function xAxisFormatter() {
      return this.value.toString().replace('20', "'");
    }

    function yAxisFormatter() {
      const valueLabel =
        typeof this.value === 'number' ? numFormatter.format(this.value) : this.value;
      return `${axisLabelPrefix}${valueLabel}${axisLabelSuffix}`;
    }

    function tooltipFormatter() {
      let text;
      let text_color;
      let diff;
      let benchmark_label;
      let label_1;
      let label_2;
      if (dataLabel && dataLabel.data_label) {
        const tooltip_labels = dataLabel.data_label.split(' ');
        if (tooltip_labels.length > 2) {
          label_1 = `${tooltip_labels[0]} ${tooltip_labels[1]}`;
          label_2 = tooltip_labels[2];
        } else {
          label_1 = tooltip_labels[0];
          label_2 = tooltip_labels[1];
        }
      }
      label_1 = label_1 || '';
      label_2 = label_2 || '';
      const moe =
        this.point.stdError !== undefined && this.point.stdError !== null
          ? Math.round(this.point.stdError * 2)
          : 'NA';

      if (
        !this.series.name.includes('nat') &&
        this.point.natAv !== null &&
        (this.point.stateAv === undefined || this.point.stateAv === null)
      ) {
        diff = Math.round(this.point.y - this.point.natAv);
        benchmark_label = 'the US';
      } else if (
        !this.series.name.includes('nat') &&
        this.point.stateAv !== undefined &&
        this.point.stateAv !== null
      ) {
        diff = Math.round(this.point.y - this.point.stateAv);
        benchmark_label = 'State';
      } else {
        diff = null;
        benchmark_label = '';
      }

      if (diff !== null) {
        if (diff >= 0) {
          text_color = '#6f9c2c';
          text = 'greater than';
        } else {
          text_color = '#ac193c';
          text = 'less than';
          diff = Math.abs(diff);
        }
      } else {
        diff = 'NA';
        text_color = '#cbd0d5';
        text = '';
      }

      const display_year = this.point.yearLabel !== null ? this.point.yearLabel : this.x;

      const valuePrefix = this.point.valuePrefix ? this.point.valuePrefix : '';
      const valueSuffix = this.point.valueSuffix ? this.point.valueSuffix : '';

      return `<div><p style="font-size: 14px; text-align: center; line-height:0.8;">
        ${this.series.name} (${display_year})</p><hr width="100%" color="${this.series.color}">
        <table border="0" cellspacing="0" cellpadding="5" align="center">
        <tr>
        <td style="color:#343434;font-size: 12px;border: solid 0 #c5c5c5; text-align: center; white-space: nowrap;line-height: 0.8;">
        ${this.point.valueLabel}
        </td>
        <td style="color:${text_color};font-size: 12px;border: solid 0 #c5c5c5; border-left-width: 1px; text-align: center; white-space: nowrap;line-height: 0.8;">
        ${valuePrefix} ${diff} ${valueSuffix}
        </td>
        <td style="color:#343434;font-size: 12px;border: solid 0 #c5c5c5; border-left-width: 1px; text-align: center;line-height: 0.8;  white-space: nowrap; ">+/- 
        ${valuePrefix}
        ${moe}
        ${valueSuffix}
        </td>
        </tr>
        <tr>
        <td style="color:#343434;font-size: 12px;border: solid 0 #c5c5c5; text-align: center; white-space: nowrap;line-height: 0.8;">
        ${label_1}
        </td>
        <td style="color:${text_color};font-size: 12px;border: solid 0 #c5c5c5; border-left-width: 1px; text-align: center; white-space: nowrap;line-height: 0.8;">
        ${text}
        </td>
        <td style="color:#343434;font-size: 12px;border: solid 0 #c5c5c5; border-left-width: 1px; text-align: center;line-height: 0.8;">Margin</td>
        </tr>
        <tr>
        <td style="color:#343434;font-size: 12px;border: solid 0 #c5c5c5; text-align: center; white-space: nowrap;line-height: 0.8;">
        ${label_2}
        </td>
        <td style="color:${text_color};font-size: 12px;border: solid 0 #c5c5c5; border-left-width: 1px; text-align: center; white-space: nowrap;line-height: 0.8;">
        ${benchmark_label}
        </td>
        <td style="color:#343434;font-size: 12px;border: solid 0 #c5c5c5; border-left-width: 1px; text-align: center; white-space: nowrap;line-height: 0.8;">of Error</td>
        </tr>
        </table>
        </div>`;
    }

    useEffect(() => {
      // determine x-axis properties based on data
      let xAxisTickInterval;
      let xAxisType;
      let xAxisLabelFormatter;
      if (parsedData[0].length > 0 && parsedData[0][0].data.length > 0) {
        const maxTimeValue = parsedData[0][0].data
          .map((point) => point.x)
          .reduce((max, value) => Math.max(max, value));

        // time values might arrive as full years (e.g.: 2020) or millis since epoch
        if (maxTimeValue < 3000) {
          // time values are in full years
          xAxisType = 'linear';
          xAxisTickInterval = 1;
          xAxisLabelFormatter = xAxisFormatter;
        } else {
          // time values are in millis since epoch
          xAxisType = 'datetime';
          xAxisLabelFormatter = undefined; // let highcharts format dates

          // chose day, month or year tick interval based in minimum interval found in data
          const minTickInterval = parsedData[0][0].data
            .map((point) => point.x)
            .reduce((min, _, index, array) => {
              return index > 0 ? Math.min(min, Math.abs(array[index] - array[index - 1])) : min;
            }, yearTickInterval);

          if (minTickInterval < weekTickInterval) {
            xAxisTickInterval = dayTickInterval;
          } else if (minTickInterval < monthTickInterval) {
            xAxisTickInterval = weekTickInterval;
          } else if (minTickInterval < yearTickInterval) {
            xAxisTickInterval = monthTickInterval;
          } else {
            xAxisTickInterval = yearTickInterval;
          }
        }

        const chartHeight = calculateChartHeight(qtyOptions);
        // This is a default theme for the MLI bar chart
        // These options can be overwritten when creating the chart and passing other options
        const default_theme = {
          chart: {
            events: {
              load() {
                if (chartLoaded) {
                  chartLoaded();
                }
              },
            },
            height: chartHeight < 400 ? 400 : chartHeight,
            // backgroundColor option is static
            backgroundColor: null,
            type: HIGHCHARTS_TYPES.LINE,

            // Fonts are currently in a shared Dropbox folder (and also in the dev directory)
            style: {
              fontFamily: 'AvenirNext-Regular',
            },

            // plotBorderColor option is static
            plotBorderColor: '#f7f7f7',
          },

          // title formatting options will be static
          title: {
            align: 'left',
            style: {
              color: '#121111',
              fontSize: '14px',
            },
            text: null,
          },
          credits: { enabled: false },
          navigation: {
            activeColor: '#ac193c',
            buttonOptions: {
              enabled: false,
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          xAxis: {
            type: xAxisType,
            startOfWeek: 0, // for datetime axes, this forces weeks to start on Sunday
            gridLineColor: '#b8b4b4',
            labels: {
              rotation: 0,
              formatter: xAxisLabelFormatter,
              style: {
                color: '#121111',
                fontSize: '14px',
              },
            },
            lineColor: '#b8b4b4',
            minorGridLineColor: '#b8b4b4',
            tickColor: '#f7f7f7',
            tickInterval: xAxisTickInterval,
            title: {
              style: {
                color: '#121111',
              },
            },
          },
          yAxis: {
            plotBands: parsedData[2],
            softMin: axisLabelSuffix === '%' ? 0 : undefined,
            gridLineColor: '#b8b4b4',
            labels: {
              formatter: yAxisFormatter,
              style: {
                color: '#bdbdbd',
                fontSize: '14px',
              },
            },
            lineColor: '#b8b4b4',
            minorGridLineColor: '#b8b4b4',
            tickColor: '#f7f7f7',
            tickWidth: parsedData[2].length > 0 ? 0 : 1,
            minorGridLineWidth: parsedData[2].length > 0 ? 0 : 1,
            gridLineWidth: parsedData[2].length > 0 ? 0 : 1,
            title: {
              text: `${dataLabel.data_label} (${axisLabelSuffix === '%' ? ' (%)' : ''})`,
              style: {
                color: '#121111',
              },
            },
          },
          tooltip: {
            hideDelay: HIGHCHARTS_TOOLTIP_DELAY,
            backgroundColor: 'rgba(255,255,255,1)',
            useHTML: true,
            formatter: tooltipFormatter,
          },
          plotOptions: {
            line: {
              events: {
                legendItemClick: () => {
                  return false;
                },
              },
            },
            series: {
              connectNulls: true,
              marker: {
                enabled: true,
                symbol: 'square',
                radius: 5,
              },
            },
          },
          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            width: '',
            x: 0,
            y: 0,
            symbolHeight: 10,
            symbolWidth: 10,
            symbolRadius: 0,
            padding: 0,
            itemStyle: {
              fontSize: '14px',
              color: '#606060',
              width: 60,
              textOverflow: 'wrap',
            },
            itemHoverStyle: {
              color: '#606060',
            },
            itemHiddenStyle: {
              color: '#606060',
            },
            height: chartHeight < 400 ? 400 : chartHeight,
          },
          series: parsedData[0],
        };

        setChartOptions({ ...default_theme });
      }
      setLoadingChart(false);
    }, []);

    if (loadingChart) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    }

    if (chartOptions) {
      return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />;
    }
    return (
      <Alert className="m-3" variant="warning">
        <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" /> Data not
        available
      </Alert>
    );
  }
);

LineChart.displayName = 'LineChart';

export default React.memo(LineChart);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import types from '../../types';
import { API_ROOT } from '../../config/appConfig';
import { selectFile } from '../../actions/wizard.action';
import modalMessages from '../../messages/modal.messages';
import { openModal } from '../../actions/modal.action';

import InputUploadProgressBar from '../Inputs/InputUpload/InputUploadProgressBar';
import InputUploadFileUploadedMessage from '../Inputs/InputUpload/InputUploadFileUploadedMessage';
import InputUploadLabel from '../Inputs/InputUpload/InputUploadLabel';
import InputUploadButton from '../Inputs/InputUpload/InputUploadButton';

import './style.css';

/**
 * TODO: Replace with real documentation
 * There are 3 states:
 * 1. FE File Progress ( Show the progress bar )
 * 2. BE File Progress ( Show the indeterminate )
 * 3. File Uploaded (Show uploaded status )
 */

const FileUpload = ({ label, type, vendorDataType, required }) => {
  const jwt = useSelector((state) => state.session.jwt) || '';
  const files = useSelector((state) => state.wizard.files) || '';
  const dispatch = useDispatch();

  // File that user Uploads
  const [file, setFile] = useState(null);
  // FE Upload
  const [progress, setProgress] = useState(null);

  const [_isUploading, setIsUploading] = useState(false);
  const [_uploaded, setUploaded] = useState(false);

  const [cancelUpload, setCancelUpload] = useState(null);
  const { CancelToken } = axios;
  const uploadFile = async () => {
    const formData = new FormData();
    formData.set('file', file);
    formData.set('fileType', vendorDataType);
    try {
      const res = await axios.post(`${API_ROOT}/admin-backend/upload`, formData, {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${jwt}`,
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target.getResponseHeader('x-decompressed-content-length');
          setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
        },
        cancelToken: new CancelToken((c) => {
          setCancelUpload(() => c);
        }),
      });
      if (res && res.status !== 202) {
        dispatch({ type: types.reducerTypes.SET_FILE_ERROR });
      }
    } catch (err) {
      dispatch({ type: types.reducerTypes.SET_FILE_ERROR });
    }
  };

  const cancelUploadHandler = () => {
    setProgress(null);
    cancelUpload();
  };

  const removeFileHandler = () => {
    dispatch(selectFile(file));
    dispatch(openModal(modalMessages('removeFile')));
  };

  const resetComponentState = () => {
    setProgress(null);
    setIsUploading(false);
    setUploaded(false);
  };

  useEffect(() => {
    if (file) {
      uploadFile(file);
    }
  }, [file]);

  useEffect(() => {
    if (file) {
      const currentFile = files[file.name];
      if (!currentFile) {
        resetComponentState();
        return;
      }
      const { isUploading, uploaded } = currentFile;
      setIsUploading(isUploading);
      setProgress(null);
      setUploaded(uploaded);
    }
  }, [files]);

  return (
    <>
      <div className="FileUpload">
        <InputUploadLabel label={label} fileType={type} required={required} />
        <div className="FileUpload-container">
          <InputUploadProgressBar
            progress={progress}
            isUploading={_isUploading}
            uploaded={_uploaded}
          />

          <InputUploadFileUploadedMessage uploaded={_uploaded} />

          <InputUploadButton
            setFile={setFile}
            vendorDataType={vendorDataType}
            type={type}
            progress={progress}
            isUploading={_isUploading}
            uploaded={_uploaded}
            cancelUpload={() => cancelUploadHandler()}
            removeFile={() => removeFileHandler()}
          />
        </div>
      </div>
    </>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  vendorDataType: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

export default FileUpload;

import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import types from '../types';

export const checkMandatoryUploadedFiles = (files, numberOfFiles) => {
  // Check there are x files on state and all of them had the uploaded key = true.
  const uploaded = [];
  if (Object.keys(files).length !== numberOfFiles) return false;
  Object.keys(files).forEach((key) => {
    if (files[key].uploaded) {
      uploaded.push(true);
    }
  });
  return uploaded.length === numberOfFiles;
};

const checkVisualizationUploadedFiles = (files, numberOfFiles) => {
  const uploaded = [];
  Object.keys(files).forEach((key) => {
    if (files[key].uploaded) {
      uploaded.push(true);
    }
  });
  return uploaded.length >= numberOfFiles;
};

export const randS0ImportToBentoStatus = () => (dispatch, getState) => {
  const state = getState();
  const { wizard, session } = state;
  const { files, providerSteps, relatedOrgId } = wizard;
  let payload = checkMandatoryUploadedFiles(files, 3);
  const currentUser = jwtDecode(session.jwt);
  if (
    currentUser.role === 'ORGANIZATION_ADMIN' &&
    providerSteps.step0.ui[0].required &&
    !relatedOrgId
  ) {
    payload = false;
  }
  dispatch({ type: types.reducerTypes.RAND_S0_IMPORT_TO_BENTO_STATUS, payload });
};

export const visualizationS1BentoStatus = () => (dispatch, getState) => {
  const state = getState();
  const { wizard } = state;
  const { files } = wizard;
  const payload = checkVisualizationUploadedFiles(files, 1);
  dispatch({ type: types.reducerTypes.VISUALIZATION_S1_BENTO_STATUS, payload });
};

export const replaceDataSetS0ImportToBentoStatus = () => (dispatch, getState) => {
  const state = getState();
  const { wizard } = state;
  const { files, replaceDataSet } = wizard;
  const payload = checkMandatoryUploadedFiles(files, 3) && _.has(replaceDataSet, 'surveyId');
  dispatch({ type: types.reducerTypes.REPLACEDATASET_S0_IMPORT_TO_BENTO_STATUS, payload });
};

export const checkFileValidations = () => (dispatch) => {
  dispatch(randS0ImportToBentoStatus());
  dispatch(visualizationS1BentoStatus());
  dispatch(replaceDataSetS0ImportToBentoStatus());
};

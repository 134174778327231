/* eslint-disable consistent-return */
import axios from 'axios';
import queryStringParser from 'query-string';
import { API_ROOT } from '../config/appConfig';
import { getHeaders, getHeadersIntegration } from '../actions/helper.action';

export const getUsersByEmailListApi = async (emails, jwt) => {
  try {
    const res = await axios.get(
      `${API_ROOT}/user-management/admin/users/email?emails=${emails.join(',')}`,
      getHeaders('application/json', jwt)
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const getUsersListApi = async (filters, jwt) => {
  try {
    const filterString = filters ? `?${queryStringParser.stringify(filters)}` : '';
    const res = await axios.get(
      `${API_ROOT}/user-management/admin/users${filterString}`,
      getHeaders('application/json', jwt)
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const assignOrgToUserApi = async (userId, orgId, isAdmin, jwt) => {
  const url = isAdmin
    ? `${API_ROOT}/user-management/admin/organizations/${orgId}/members?admin=true`
    : `${API_ROOT}/user-management/admin/organizations/${orgId}/members`;
  const res = await axios.post(url, { id: userId }, getHeaders('application/json', jwt));

  return res;
};

export const removeOrgToUserApi = async (userId, orgId, jwt) => {
  const url = `${API_ROOT}/user-management/admin/organizations/${orgId}/members/${userId}`;
  const res = await axios.delete(url, getHeaders('application/json', jwt));

  return res;
};

export const demoteOrgUserApi = async (userId, jwt) => {
  const url = `${API_ROOT}/user-management/admin/users/${userId}/demote`;
  const res = await axios.delete(url, getHeaders('application/json', jwt));

  return res;
};

export const createUserApi = async (newUser, jwt) => {
  const res = await axios.post(
    `${API_ROOT}/user-management/admin/users`,
    newUser,
    getHeaders('application/json', jwt)
  );

  return res;
};

export const updateUserApi = async (updateUser, jwt, userId) => {
  try {
    const res = await axios.patch(
      `${API_ROOT}/user-management/admin/users/${userId}`,
      updateUser,
      getHeaders('application/json', jwt)
    );

    return res;
  } catch (err) {
    return err?.response?.data;
  }
};

export const deleteUserApi = async (ids, jwt) => {
  try {
    const res = await axios.delete(`${API_ROOT}/user-management/admin/users/${ids.join(',')}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    return res;
  } catch (err) {
    console.log(err);
    return err?.response?.data;
  }
};

export const exportUsersApi = async (jwt) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/api/export/users`,
      {},
      getHeadersIntegration('application/json', jwt)
    );

    return res;
  } catch (err) {
    console.log(err);
    return err?.response?.data;
  }
};

export const loginApi = async (userData) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/user-management/auth/login`,
      {
        ...userData,
      },
      {
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (res.status === 200) {
      return res.data;
    }
    return false;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const checkTokenApi = async (token) => {
  try {
    const res = await axios.post(`${API_ROOT}/user-management/auth/logintemp`, token, {
      mode: 'cors',
      headers: {
        'Content-Type': 'text/plain',
      },
    });

    if (res.status === 200) {
      return res.data;
    }
    return false;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const verifyAccountApi = async (payload) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/user-management/auth/verify`,
      { ...payload },
      {
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (res.status === 200) {
      return res.data;
    }
    return false;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const changePasswordApi = async (payload, jwt) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/user-management/auth/changepassword`,
      { ...payload },
      {
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (res.status === 200) {
      return res.data;
    }
    return false;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const resetPasswordApi = async (payload) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/user-management/auth/changetemp`,
      { ...payload },
      {
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (res.status === 200) {
      return res.data;
    }
    return false;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const acceptTermsApi = async (jwt) => {
  try {
    const res = await axios.post(`${API_ROOT}/user-management/auth/accept-terms`, null, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      return res.data;
    }
    return false;
  } catch (err) {
    console.error(err);
    return err;
  }
};
export const recoverApi = async (userData) => {
  try {
    const res = await axios.post(`${API_ROOT}/user-management/auth/recover`, userData, {
      mode: 'cors',
      headers: {
        'Content-Type': 'text/plain',
      },
    });

    if (res.status === 200) {
      return res.data;
    }
    return false;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const feedbackApi = async (jwt, feedbackData) => {
  try {
    const res = await axios.post(
      `${API_ROOT}/user-management/feedback`,
      feedbackData,
      getHeaders('application/json', jwt)
    );

    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};

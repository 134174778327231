export const WY = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6963, 43.0986],
            [-108.637, 43.0924],
            [-108.5007, 43.0244],
            [-108.4984, 42.9456],
            [-108.6064, 42.8993],
            [-108.6062, 42.9827],
            [-108.6959, 43.0266],
            [-108.6963, 43.0986],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5600960',
        NAME: 'Fremont County School District 38',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216412073,
        AWATER: 999746,
        INTPTLAT: '+43.0070294',
        INTPTLON: '-108.5488309',
        ELSDLEA: '00960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.0734, 42.4332],
            [-105.7672, 42.4316],
            [-105.6147, 42.3774],
            [-105.6139, 42.3045],
            [-105.3755, 42.2901],
            [-105.3326, 42.4311],
            [-105.2839, 42.4314],
            [-105.2717, 41.9861],
            [-105.2782, 41.6567],
            [-105.2769, 40.9982],
            [-106.1906, 40.9976],
            [-106.3212, 40.9991],
            [-106.3229, 41.3951],
            [-106.0684, 41.395],
            [-106.0731, 41.7424],
            [-106.0734, 42.4332],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5600730',
        NAME: 'Albany County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11070466383,
        AWATER: 89161368,
        INTPTLAT: '+41.6655141',
        INTPTLON: '-105.7218826',
        ELSDLEA: '',
        UNSDLEA: '00730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.6351, 42.2614],
            [-107.5227, 42.2618],
            [-107.5229, 42.4344],
            [-106.941, 42.4334],
            [-106.9387, 42.1719],
            [-106.8214, 42.1719],
            [-106.8217, 42.1147],
            [-106.6949, 42.0574],
            [-106.6945, 41.8264],
            [-106.9268, 41.8267],
            [-106.9019, 41.6561],
            [-107.0191, 41.6558],
            [-107.0185, 41.5694],
            [-107.1303, 41.569],
            [-107.1171, 41.395],
            [-107.1161, 41.0031],
            [-107.3178, 41.0029],
            [-107.9184, 41.002],
            [-107.9171, 41.3985],
            [-107.9297, 41.6596],
            [-107.5097, 41.6575],
            [-107.5038, 42.088],
            [-107.5228, 42.1745],
            [-107.6328, 42.1744],
            [-107.6351, 42.2614],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5601030',
        NAME: 'Carbon County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9773037490,
        AWATER: 105343974,
        INTPTLAT: '+41.6701404',
        INTPTLON: '-107.3473386',
        ELSDLEA: '',
        UNSDLEA: '01030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6839, 45.0003],
            [-108.2494, 44.9999],
            [-107.9115, 45.0007],
            [-107.8817, 44.9855],
            [-107.865, 44.8736],
            [-107.8318, 44.845],
            [-107.8282, 44.8307],
            [-107.8322, 44.8249],
            [-107.8165, 44.8185],
            [-107.8316, 44.7998],
            [-107.7544, 44.7706],
            [-107.7415, 44.7144],
            [-107.6197, 44.6988],
            [-107.5283, 44.6656],
            [-107.5282, 44.6348],
            [-107.4949, 44.642],
            [-107.4787, 44.6179],
            [-107.4574, 44.6077],
            [-107.4431, 44.6056],
            [-107.425, 44.6115],
            [-107.4228, 44.6035],
            [-107.4034, 44.5998],
            [-107.3929, 44.596],
            [-107.3835, 44.5954],
            [-107.3852, 44.5898],
            [-107.3808, 44.5846],
            [-107.3749, 44.582],
            [-107.3766, 44.58],
            [-107.373, 44.5743],
            [-107.3696, 44.5598],
            [-107.202, 44.4463],
            [-107.145, 44.324],
            [-107.1468, 44.1657],
            [-107.7061, 44.1664],
            [-108.5506, 44.1685],
            [-108.5737, 44.3431],
            [-108.2123, 44.3417],
            [-108.2124, 44.4124],
            [-108.0476, 44.4583],
            [-107.9249, 44.4583],
            [-107.9235, 44.3727],
            [-107.6009, 44.3714],
            [-107.6013, 44.4338],
            [-107.207, 44.4263],
            [-107.3067, 44.4637],
            [-107.2992, 44.5028],
            [-107.3714, 44.5592],
            [-107.3749, 44.5688],
            [-107.3778, 44.5821],
            [-107.387, 44.5881],
            [-107.3863, 44.5939],
            [-107.4066, 44.5981],
            [-107.4139, 44.5978],
            [-107.4231, 44.6016],
            [-107.4252, 44.6085],
            [-107.4353, 44.6039],
            [-107.4552, 44.6058],
            [-107.4711, 44.6107],
            [-107.5294, 44.6337],
            [-107.5538, 44.6742],
            [-107.7423, 44.7132],
            [-107.7995, 44.7803],
            [-107.8353, 44.7985],
            [-107.8195, 44.8148],
            [-107.8345, 44.8253],
            [-107.8306, 44.8322],
            [-107.8443, 44.8555],
            [-107.867, 44.8732],
            [-107.915, 44.9994],
            [-108.6218, 44.998],
            [-108.6838, 44.9889],
            [-108.6839, 45.0003],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5601090',
        NAME: 'Big Horn County School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2906795418,
        AWATER: 12597303,
        INTPTLAT: '+44.3037320',
        INTPTLON: '-107.8189003',
        ELSDLEA: '',
        UNSDLEA: '01090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.5884, 42.9239],
            [-110.3351, 42.9264],
            [-110.3353, 42.8808],
            [-110.1558, 42.8804],
            [-110.1556, 42.7793],
            [-109.9786, 42.7593],
            [-109.9284, 42.6699],
            [-109.7715, 42.6491],
            [-109.7726, 42.4335],
            [-109.7428, 42.2658],
            [-110.0537, 42.2707],
            [-110.0544, 42.011],
            [-110.231, 42.0116],
            [-110.2766, 42.0667],
            [-110.5192, 42.0682],
            [-110.5427, 42.1876],
            [-110.5433, 42.4485],
            [-110.5901, 42.4485],
            [-110.5884, 42.9239],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5601260',
        NAME: 'Sublette County School District 9',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5167544198,
        AWATER: 44824877,
        INTPTLAT: '+42.4702137',
        INTPTLON: '-110.2065072',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6837, 44.9598],
            [-108.6218, 44.9961],
            [-108.373, 44.9963],
            [-108.3724, 44.8489],
            [-108.4341, 44.8444],
            [-108.4776, 44.7523],
            [-108.4741, 44.487],
            [-108.2125, 44.4727],
            [-108.2124, 44.4124],
            [-108.2123, 44.3417],
            [-108.5737, 44.3431],
            [-108.5735, 44.4294],
            [-108.5925, 44.5209],
            [-108.5923, 44.8714],
            [-108.6837, 44.9598],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5601420',
        NAME: 'Big Horn County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1166345415,
        AWATER: 2986930,
        INTPTLAT: '+44.6511788',
        INTPTLON: '-108.4825924',
        ELSDLEA: '',
        UNSDLEA: '01420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6838, 44.9889],
            [-108.6218, 44.998],
            [-107.915, 44.9994],
            [-107.867, 44.8732],
            [-107.8443, 44.8555],
            [-107.8306, 44.8322],
            [-107.8345, 44.8253],
            [-107.8195, 44.8148],
            [-107.8353, 44.7985],
            [-107.7995, 44.7803],
            [-108.085, 44.781],
            [-108.0853, 44.6642],
            [-108.1855, 44.7088],
            [-108.1845, 44.7807],
            [-108.4094, 44.7823],
            [-108.4776, 44.7523],
            [-108.4341, 44.8444],
            [-108.3724, 44.8489],
            [-108.373, 44.9963],
            [-108.6218, 44.9961],
            [-108.6837, 44.9598],
            [-108.6838, 44.9889],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5601460',
        NAME: 'Big Horn County School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1130863608,
        AWATER: 34262336,
        INTPTLAT: '+44.8645112',
        INTPTLON: '-108.1368525',
        ELSDLEA: '',
        UNSDLEA: '01460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.0248, 44.9935],
            [-105.7855, 45.0004],
            [-105.1969, 45.0001],
            [-105.0766, 44.9999],
            [-105.0855, 44.7868],
            [-105.0793, 44.1762],
            [-105.0807, 43.7399],
            [-105.0798, 43.4984],
            [-105.6846, 43.4958],
            [-106.0174, 43.495],
            [-106.0165, 44.1697],
            [-106.0097, 44.564],
            [-106.0248, 44.9935],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5601470',
        NAME: 'Campbell County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12437852902,
        AWATER: 10357549,
        INTPTLAT: '+44.1919995',
        INTPTLON: '-105.5170141',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.941, 42.4334],
            [-106.0759, 42.4332],
            [-106.0734, 42.4332],
            [-106.0731, 41.7424],
            [-106.0684, 41.395],
            [-106.3229, 41.3951],
            [-106.3212, 40.9991],
            [-106.8578, 41.0027],
            [-107.1161, 41.0031],
            [-107.1171, 41.395],
            [-107.1303, 41.569],
            [-107.0185, 41.5694],
            [-107.0191, 41.6558],
            [-106.9019, 41.6561],
            [-106.9268, 41.8267],
            [-106.6945, 41.8264],
            [-106.6949, 42.0574],
            [-106.8217, 42.1147],
            [-106.8214, 42.1719],
            [-106.9387, 42.1719],
            [-106.941, 42.4334],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5601700',
        NAME: 'Carbon County School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10770693168,
        AWATER: 66658557,
        INTPTLAT: '+41.6978908',
        INTPTLON: '-106.5616036',
        ELSDLEA: '',
        UNSDLEA: '01700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.2782, 41.6567],
            [-104.6533, 41.653],
            [-104.5925, 41.5647],
            [-104.5995, 41.0015],
            [-104.6001, 41.0015],
            [-104.9434, 40.9982],
            [-105.2769, 40.9982],
            [-105.2782, 41.6567],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5601980',
        NAME: 'Laramie County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4110990510,
        AWATER: 3824811,
        INTPTLAT: '+41.3214688',
        INTPTLON: '-104.9361616',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.8327, 44.6972],
            [-109.8327, 44.6508],
            [-109.3814, 44.6489],
            [-109.3814, 44.8228],
            [-109.2571, 44.8229],
            [-109.0788, 44.8614],
            [-109.0808, 44.7549],
            [-109.166, 44.6751],
            [-108.9754, 44.6654],
            [-108.8564, 44.5775],
            [-108.7135, 44.5213],
            [-108.5925, 44.5209],
            [-108.5735, 44.4294],
            [-108.7125, 44.4301],
            [-108.8108, 44.3574],
            [-108.8109, 44.2689],
            [-109.158, 44.2705],
            [-109.4, 44.258],
            [-109.4003, 44.1708],
            [-109.4941, 44.1711],
            [-109.5123, 43.9391],
            [-109.6418, 43.9228],
            [-109.6725, 43.8681],
            [-109.7899, 43.8033],
            [-109.8819, 43.9491],
            [-109.9939, 43.9496],
            [-110.0532, 44.008],
            [-110.0534, 44.1326],
            [-110.0022, 44.1325],
            [-110.0015, 44.3194],
            [-110.0707, 44.3431],
            [-110.0606, 44.4211],
            [-110.1726, 44.5445],
            [-109.9081, 44.6369],
            [-109.8327, 44.6972],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5602070',
        NAME: 'Park County School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6602696733,
        AWATER: 32118059,
        INTPTLAT: '+44.3491549',
        INTPTLON: '-109.4725605',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.6846, 43.4958],
            [-105.0798, 43.4984],
            [-104.8999, 43.4996],
            [-104.8994, 42.8045],
            [-104.8924, 42.6089],
            [-105.2865, 42.6058],
            [-105.2839, 42.4314],
            [-105.3326, 42.4311],
            [-105.3755, 42.2901],
            [-105.6139, 42.3045],
            [-105.6147, 42.3774],
            [-105.7672, 42.4316],
            [-105.7693, 42.721],
            [-105.6951, 42.7209],
            [-105.6713, 42.8234],
            [-105.6804, 43.2156],
            [-105.5617, 43.2167],
            [-105.5602, 43.3878],
            [-105.6794, 43.3879],
            [-105.6846, 43.4958],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5602140',
        NAME: 'Converse County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7262602438,
        AWATER: 20238784,
        INTPTLAT: '+42.9480914',
        INTPTLON: '-105.3036394',
        ELSDLEA: '',
        UNSDLEA: '02140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.0781, 43.4945],
            [-106.0174, 43.495],
            [-105.6846, 43.4958],
            [-105.6794, 43.3879],
            [-105.5602, 43.3878],
            [-105.5617, 43.2167],
            [-105.6804, 43.2156],
            [-105.6713, 42.8234],
            [-105.6951, 42.7209],
            [-105.7693, 42.721],
            [-105.7672, 42.4316],
            [-106.0734, 42.4332],
            [-106.0759, 42.4332],
            [-106.0727, 42.7782],
            [-106.0781, 43.4945],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5602150',
        NAME: 'Converse County School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3757466044,
        AWATER: 6146027,
        INTPTLAT: '+43.0106848',
        INTPTLON: '-105.8615318',
        ELSDLEA: '',
        UNSDLEA: '02150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0766, 44.9999],
            [-105.0382, 45.0001],
            [-104.0579, 44.9976],
            [-104.0559, 44.571],
            [-104.0555, 44.316],
            [-104.0545, 44.1804],
            [-104.3952, 44.1814],
            [-105.0793, 44.1762],
            [-105.0855, 44.7868],
            [-105.0766, 44.9999],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5602370',
        NAME: 'Crook County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7393036956,
        AWATER: 28186053,
        INTPTLAT: '+44.5892657',
        INTPTLON: '-104.5672975',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0532, 44.008],
            [-109.9939, 43.9496],
            [-109.8819, 43.9491],
            [-109.7899, 43.8033],
            [-109.6725, 43.8681],
            [-109.6418, 43.9228],
            [-109.5123, 43.9391],
            [-109.4424, 43.9304],
            [-109.3129, 43.8133],
            [-109.1714, 43.6992],
            [-109.1716, 43.6198],
            [-108.9341, 43.6192],
            [-108.7575, 43.5754],
            [-108.8146, 43.4419],
            [-109.0569, 43.4461],
            [-109.0538, 43.6054],
            [-109.19, 43.6066],
            [-109.1882, 43.7022],
            [-109.2598, 43.7136],
            [-109.266, 43.3887],
            [-109.3453, 43.3598],
            [-109.638, 43.3844],
            [-109.7546, 43.4299],
            [-109.7547, 43.4632],
            [-110.0516, 43.4648],
            [-110.0532, 44.008],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5602670',
        NAME: 'Fremont County School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3867930703,
        AWATER: 10448201,
        INTPTLAT: '+43.6273215',
        INTPTLON: '-109.5699937',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0468, 41.156],
            [-111.0468, 41.2009],
            [-111.0468, 41.2516],
            [-111.0458, 41.5798],
            [-110.5837, 41.5792],
            [-110.5796, 41.5792],
            [-110.5441, 41.4058],
            [-110.5457, 40.9963],
            [-111.0468, 40.9979],
            [-111.0468, 41.156],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5602760',
        NAME: 'Uinta County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2645785434,
        AWATER: 13363757,
        INTPTLAT: '+41.2811558',
        INTPTLON: '-110.8010710',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.5704, 43.0997],
            [-109.4425, 43.0628],
            [-109.284, 43.1513],
            [-109.0999, 43.2056],
            [-108.8951, 43.2217],
            [-108.7925, 43.1943],
            [-108.6963, 43.0986],
            [-108.6959, 43.0266],
            [-108.775, 43.0562],
            [-108.8518, 42.9678],
            [-108.8517, 42.8873],
            [-108.8862, 42.8687],
            [-109.3258, 42.8688],
            [-109.3707, 42.9457],
            [-109.5526, 43.0125],
            [-109.5704, 43.0997],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5602820',
        NAME: 'Fremont County School District 21',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1812471940,
        AWATER: 31211094,
        INTPTLAT: '+43.0665784',
        INTPTLON: '-109.0230809',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.7546, 43.4299],
            [-109.638, 43.3844],
            [-109.3453, 43.3598],
            [-109.266, 43.3887],
            [-109.2598, 43.7136],
            [-109.1882, 43.7022],
            [-109.19, 43.6066],
            [-109.0538, 43.6054],
            [-109.0569, 43.4461],
            [-108.8146, 43.4419],
            [-108.7575, 43.5754],
            [-108.5762, 43.5033],
            [-108.5785, 43.3007],
            [-108.46, 43.2724],
            [-108.46, 43.1427],
            [-108.6381, 43.1426],
            [-108.637, 43.0924],
            [-108.6963, 43.0986],
            [-108.7925, 43.1943],
            [-108.8951, 43.2217],
            [-109.0999, 43.2056],
            [-109.284, 43.1513],
            [-109.4425, 43.0628],
            [-109.5704, 43.0997],
            [-109.6206, 43.1256],
            [-109.6927, 43.265],
            [-109.6949, 43.3662],
            [-109.7546, 43.4299],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5602830',
        NAME: 'Fremont County School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3347617308,
        AWATER: 51742688,
        INTPTLAT: '+43.3367799',
        INTPTLON: '-109.1146899',
        ELSDLEA: '',
        UNSDLEA: '02830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.3258, 42.8688],
            [-108.8862, 42.8687],
            [-108.8517, 42.8873],
            [-108.6064, 42.8993],
            [-108.4984, 42.9456],
            [-108.499, 42.9048],
            [-108.2613, 42.9049],
            [-108.2471, 42.699],
            [-108.1294, 42.6979],
            [-108.1282, 42.7875],
            [-107.5435, 42.7814],
            [-107.5229, 42.4344],
            [-107.5227, 42.2618],
            [-107.6351, 42.2614],
            [-109.0439, 42.2637],
            [-109.0695, 42.4335],
            [-109.0729, 42.6935],
            [-109.2146, 42.7618],
            [-109.3258, 42.8688],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5602870',
        NAME: 'Fremont County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8157152563,
        AWATER: 20904346,
        INTPTLAT: '+42.5510894',
        INTPTLON: '-108.3743087',
        ELSDLEA: '',
        UNSDLEA: '02870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.6539, 42.1042],
            [-104.6552, 42.5223],
            [-104.36, 42.5386],
            [-104.3601, 42.6112],
            [-104.0528, 42.6118],
            [-104.053, 42.087],
            [-104.0527, 41.698],
            [-104.0525, 41.5643],
            [-104.5925, 41.5647],
            [-104.6533, 41.653],
            [-104.6533, 41.6959],
            [-104.4789, 41.6963],
            [-104.537, 41.7833],
            [-104.7686, 41.937],
            [-104.6533, 42.0166],
            [-104.6539, 42.1042],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5602990',
        NAME: 'Goshen County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5356687302,
        AWATER: 17585608,
        INTPTLAT: '+42.0724035',
        INTPTLON: '-104.3348268',
        ELSDLEA: '',
        UNSDLEA: '02990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.4776, 44.7523],
            [-108.4094, 44.7823],
            [-108.1845, 44.7807],
            [-108.1855, 44.7088],
            [-108.0853, 44.6642],
            [-108.085, 44.781],
            [-107.7995, 44.7803],
            [-107.7423, 44.7132],
            [-107.5538, 44.6742],
            [-107.5294, 44.6337],
            [-107.4711, 44.6107],
            [-107.4552, 44.6058],
            [-107.4353, 44.6039],
            [-107.4252, 44.6085],
            [-107.4231, 44.6016],
            [-107.4139, 44.5978],
            [-107.4066, 44.5981],
            [-107.3863, 44.5939],
            [-107.387, 44.5881],
            [-107.3778, 44.5821],
            [-107.3749, 44.5688],
            [-107.3714, 44.5592],
            [-107.2992, 44.5028],
            [-107.3067, 44.4637],
            [-107.207, 44.4263],
            [-107.6013, 44.4338],
            [-107.6009, 44.3714],
            [-107.9235, 44.3727],
            [-107.9249, 44.4583],
            [-108.0476, 44.4583],
            [-108.2124, 44.4124],
            [-108.2125, 44.4727],
            [-108.4741, 44.487],
            [-108.4776, 44.7523],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5603170',
        NAME: 'Big Horn County School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3004289868,
        AWATER: 6888618,
        INTPTLAT: '+44.5858205',
        INTPTLON: '-107.9395641',
        ELSDLEA: '',
        UNSDLEA: '03170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.776, 42.6099],
            [-104.6556, 42.6095],
            [-104.6552, 42.5223],
            [-104.6539, 42.1042],
            [-104.7221, 42.1765],
            [-104.8292, 42.1767],
            [-104.8301, 42.2652],
            [-104.908, 42.2785],
            [-104.9086, 42.3627],
            [-104.8317, 42.3918],
            [-104.8309, 42.5089],
            [-104.776, 42.6099],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5603180',
        NAME: 'Platte County School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 796849892,
        AWATER: 13761359,
        INTPTLAT: '+42.3608531',
        INTPTLON: '-104.7388142',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.3129, 43.8133],
            [-109.0936, 43.8176],
            [-109.0929, 43.9045],
            [-108.9128, 43.9048],
            [-108.916, 43.9909],
            [-108.7918, 43.9911],
            [-108.7923, 44.0796],
            [-108.5508, 44.0794],
            [-108.4296, 43.9034],
            [-108.3097, 43.8466],
            [-108.1736, 43.819],
            [-107.9564, 43.8187],
            [-107.9566, 43.7334],
            [-107.7159, 43.7333],
            [-107.7162, 43.6458],
            [-107.5952, 43.6464],
            [-107.5951, 43.5014],
            [-107.5951, 43.4723],
            [-108.1563, 43.4706],
            [-108.3379, 43.4598],
            [-108.5762, 43.5033],
            [-108.7575, 43.5754],
            [-108.9341, 43.6192],
            [-109.1716, 43.6198],
            [-109.1714, 43.6992],
            [-109.3129, 43.8133],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5603310',
        NAME: 'Hot Springs County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5190598635,
        AWATER: 5964358,
        INTPTLAT: '+43.7202092',
        INTPTLON: '-108.4350765',
        ELSDLEA: '',
        UNSDLEA: '03310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.3696, 44.5598],
            [-106.9343, 44.5585],
            [-106.5507, 44.5569],
            [-106.0097, 44.564],
            [-106.0165, 44.1697],
            [-106.0174, 43.495],
            [-106.0781, 43.4945],
            [-106.5949, 43.4915],
            [-107.1107, 43.5003],
            [-107.1468, 44.1657],
            [-107.145, 44.324],
            [-107.202, 44.4463],
            [-107.3696, 44.5598],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5603770',
        NAME: 'Johnson County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10759203372,
        AWATER: 52947545,
        INTPTLAT: '+44.0440477',
        INTPTLON: '-106.5885406',
        ELSDLEA: '',
        UNSDLEA: '03770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.5901, 42.4485],
            [-110.5433, 42.4485],
            [-110.5427, 42.1876],
            [-110.5192, 42.0682],
            [-110.2766, 42.0667],
            [-110.231, 42.0116],
            [-110.0544, 42.011],
            [-110.0464, 42.0069],
            [-110.048, 41.578],
            [-110.2781, 41.5784],
            [-110.5796, 41.5792],
            [-110.5837, 41.5792],
            [-110.7506, 41.5953],
            [-110.8737, 41.7553],
            [-110.8685, 42.1013],
            [-110.837, 42.1909],
            [-110.8382, 42.448],
            [-110.5901, 42.4485],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5604030',
        NAME: 'Lincoln County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4343059516,
        AWATER: 8745057,
        INTPTLAT: '+41.9258636',
        INTPTLON: '-110.5062464',
        ELSDLEA: '',
        UNSDLEA: '04030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0467, 42.5131],
            [-111.0442, 43.0201],
            [-111.0446, 43.3157],
            [-110.814, 43.3159],
            [-110.814, 43.2352],
            [-110.5787, 43.2352],
            [-110.5884, 42.9239],
            [-110.5901, 42.4485],
            [-110.8382, 42.448],
            [-110.837, 42.1909],
            [-110.8685, 42.1013],
            [-110.8737, 41.7553],
            [-110.7506, 41.5953],
            [-110.5837, 41.5792],
            [-111.0458, 41.5798],
            [-111.0467, 42.0017],
            [-111.0467, 42.5131],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5604060',
        NAME: 'Lincoln County School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5126852924,
        AWATER: 7339214,
        INTPTLAT: '+42.5937409',
        INTPTLON: '-110.8307630',
        ELSDLEA: '',
        UNSDLEA: '04060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.5925, 41.5647],
            [-104.0525, 41.5643],
            [-104.0523, 41.3932],
            [-104.0532, 41.0014],
            [-104.5995, 41.0015],
            [-104.5925, 41.5647],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5604120',
        NAME: 'Laramie County School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2845509242,
        AWATER: 444993,
        INTPTLAT: '+41.2791870',
        INTPTLON: '-104.3206170',
        ELSDLEA: '',
        UNSDLEA: '04120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.8999, 43.4996],
            [-104.0548, 43.5033],
            [-104.0548, 43.4778],
            [-104.053, 43.0006],
            [-104.0528, 42.6118],
            [-104.3601, 42.6112],
            [-104.36, 42.5386],
            [-104.6552, 42.5223],
            [-104.6556, 42.6095],
            [-104.776, 42.6099],
            [-104.8924, 42.6089],
            [-104.8994, 42.8045],
            [-104.8999, 43.4996],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5604230',
        NAME: 'Niobrara County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7030781801,
        AWATER: 4969450,
        INTPTLAT: '+43.0364688',
        INTPTLON: '-104.4555149',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.2781, 41.5784],
            [-110.048, 41.578],
            [-110.049, 41.2292],
            [-110.2014, 41.2295],
            [-110.259, 41.2932],
            [-110.3633, 41.3019],
            [-110.3488, 41.4886],
            [-110.2781, 41.5784],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5604260',
        NAME: 'Uinta County School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 855124416,
        AWATER: 1738391,
        INTPTLAT: '+41.4008812',
        INTPTLON: '-110.1900529',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.5735, 44.4294],
            [-108.5737, 44.3431],
            [-108.5506, 44.1685],
            [-108.5508, 44.0794],
            [-108.7923, 44.0796],
            [-108.7918, 43.9911],
            [-108.916, 43.9909],
            [-108.9128, 43.9048],
            [-109.0929, 43.9045],
            [-109.0936, 43.8176],
            [-109.3129, 43.8133],
            [-109.4424, 43.9304],
            [-109.5123, 43.9391],
            [-109.4941, 44.1711],
            [-109.4003, 44.1708],
            [-109.4, 44.258],
            [-109.158, 44.2705],
            [-108.8109, 44.2689],
            [-108.8108, 44.3574],
            [-108.7125, 44.4301],
            [-108.5735, 44.4294],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5604380',
        NAME: 'Park County School District 16',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3008015021,
        AWATER: 8537805,
        INTPTLAT: '+44.1066256',
        INTPTLON: '-109.0433185',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.6959, 43.0266],
            [-108.6062, 42.9827],
            [-108.6064, 42.8993],
            [-108.8517, 42.8873],
            [-108.8518, 42.9678],
            [-108.775, 43.0562],
            [-108.6959, 43.0266],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5604450',
        NAME: 'Fremont County School District 14',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325225751,
        AWATER: 2059733,
        INTPTLAT: '+42.9460176',
        INTPTLON: '-108.7142014',
        ELSDLEA: '',
        UNSDLEA: '04450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.5796, 41.5792],
            [-110.2781, 41.5784],
            [-110.3488, 41.4886],
            [-110.3633, 41.3019],
            [-110.259, 41.2932],
            [-110.2014, 41.2295],
            [-110.049, 41.2292],
            [-110.0485, 40.9973],
            [-110.5457, 40.9963],
            [-110.5441, 41.4058],
            [-110.5796, 41.5792],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5604500',
        NAME: 'Uinta County School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1891006885,
        AWATER: 1243489,
        INTPTLAT: '+41.2189178',
        INTPTLON: '-110.3455708',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.5435, 42.7814],
            [-107.5019, 42.8689],
            [-107.5167, 43.128],
            [-107.517, 43.4727],
            [-107.5349, 43.5014],
            [-107.1107, 43.5003],
            [-106.5949, 43.4915],
            [-106.0781, 43.4945],
            [-106.0727, 42.7782],
            [-106.0759, 42.4332],
            [-106.941, 42.4334],
            [-107.5229, 42.4344],
            [-107.5435, 42.7814],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5604510',
        NAME: 'Natrona County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13831870242,
        AWATER: 91060478,
        INTPTLAT: '+42.9736019',
        INTPTLON: '-106.7650699',
        ELSDLEA: '',
        UNSDLEA: '04510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0807, 43.7399],
            [-104.8383, 43.7441],
            [-104.8387, 43.8291],
            [-104.5979, 43.8309],
            [-104.5971, 43.9188],
            [-104.4909, 43.9179],
            [-104.4746, 44.0931],
            [-104.3956, 44.0928],
            [-104.3952, 44.1814],
            [-104.0545, 44.1804],
            [-104.0546, 44.1411],
            [-104.0555, 43.8535],
            [-104.0548, 43.5033],
            [-104.8999, 43.4996],
            [-105.0798, 43.4984],
            [-105.0807, 43.7399],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5604830',
        NAME: 'Weston County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4192000390,
        AWATER: 3621543,
        INTPTLAT: '+43.7460962',
        INTPTLON: '-104.4499704',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.5787, 43.2352],
            [-110.5784, 43.2916],
            [-110.344, 43.2912],
            [-110.3442, 43.3779],
            [-110.0573, 43.3778],
            [-110.0516, 43.4648],
            [-109.7547, 43.4632],
            [-109.7546, 43.4299],
            [-109.6949, 43.3662],
            [-109.6927, 43.265],
            [-109.6206, 43.1256],
            [-109.5704, 43.0997],
            [-109.5526, 43.0125],
            [-109.3707, 42.9457],
            [-109.3258, 42.8688],
            [-109.2146, 42.7618],
            [-109.0729, 42.6935],
            [-109.0695, 42.4335],
            [-109.0439, 42.2637],
            [-109.7428, 42.2658],
            [-109.7726, 42.4335],
            [-109.7715, 42.6491],
            [-109.9284, 42.6699],
            [-109.9786, 42.7593],
            [-110.1556, 42.7793],
            [-110.1558, 42.8804],
            [-110.3353, 42.8808],
            [-110.3351, 42.9264],
            [-110.5884, 42.9239],
            [-110.5787, 43.2352],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5604860',
        NAME: 'Sublette County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8575903236,
        AWATER: 116369917,
        INTPTLAT: '+42.8697003',
        INTPTLON: '-109.7727706',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.8924, 42.6089],
            [-104.776, 42.6099],
            [-104.8309, 42.5089],
            [-104.8317, 42.3918],
            [-104.9086, 42.3627],
            [-104.908, 42.2785],
            [-104.8301, 42.2652],
            [-104.8292, 42.1767],
            [-104.7221, 42.1765],
            [-104.6539, 42.1042],
            [-104.6533, 42.0166],
            [-104.7686, 41.937],
            [-104.537, 41.7833],
            [-104.4789, 41.6963],
            [-104.6533, 41.6959],
            [-104.6533, 41.653],
            [-105.2782, 41.6567],
            [-105.2717, 41.9861],
            [-105.2839, 42.4314],
            [-105.2865, 42.6058],
            [-104.8924, 42.6089],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5605090',
        NAME: 'Platte County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4778781644,
        AWATER: 55269872,
        INTPTLAT: '+42.0845323',
        INTPTLON: '-104.9922298',
        ELSDLEA: '',
        UNSDLEA: '05090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0015, 45.0034],
            [-109.7985, 45.0023],
            [-109.1947, 45.0057],
            [-108.8457, 45],
            [-108.6839, 45.0003],
            [-108.6838, 44.9889],
            [-108.6837, 44.9598],
            [-108.5923, 44.8714],
            [-108.5925, 44.5209],
            [-108.7135, 44.5213],
            [-108.8564, 44.5775],
            [-108.9754, 44.6654],
            [-109.166, 44.6751],
            [-109.0808, 44.7549],
            [-109.0788, 44.8614],
            [-109.2571, 44.8229],
            [-109.3814, 44.8228],
            [-109.3814, 44.6489],
            [-109.8327, 44.6508],
            [-109.8327, 44.6972],
            [-109.9581, 44.8022],
            [-109.9298, 44.935],
            [-110.0015, 45.0034],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5605160',
        NAME: 'Park County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3779313242,
        AWATER: 13646235,
        INTPTLAT: '+44.9070731',
        INTPTLON: '-109.6402088',
        ELSDLEA: '',
        UNSDLEA: '05160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.46, 43.1427],
            [-108.1547, 43.1419],
            [-108.1459, 42.8707],
            [-107.5019, 42.8689],
            [-107.5435, 42.7814],
            [-108.1282, 42.7875],
            [-108.1294, 42.6979],
            [-108.2471, 42.699],
            [-108.2613, 42.9049],
            [-108.499, 42.9048],
            [-108.4984, 42.9456],
            [-108.5007, 43.0244],
            [-108.637, 43.0924],
            [-108.6381, 43.1426],
            [-108.46, 43.1427],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5605220',
        NAME: 'Fremont County School District 25',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1529011560,
        AWATER: 4691899,
        INTPTLAT: '+42.9433502',
        INTPTLON: '-108.1496940',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.0544, 42.011],
            [-110.0537, 42.2707],
            [-109.7428, 42.2658],
            [-109.0439, 42.2637],
            [-107.6351, 42.2614],
            [-107.6328, 42.1744],
            [-107.5228, 42.1745],
            [-107.5038, 42.088],
            [-107.5097, 41.6575],
            [-107.9297, 41.6596],
            [-107.9171, 41.3985],
            [-107.9184, 41.002],
            [-108.4887, 41.0001],
            [-108.4833, 41.4016],
            [-108.5047, 41.4893],
            [-109.349, 41.4883],
            [-109.4222, 41.5756],
            [-109.4238, 41.7431],
            [-109.5926, 41.7438],
            [-109.5911, 42.0079],
            [-110.0464, 42.0069],
            [-110.0544, 42.011],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5605302',
        NAME: 'Sweetwater County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17314146111,
        AWATER: 34516675,
        INTPTLAT: '+41.8347132',
        INTPTLON: '-108.5458340',
        ELSDLEA: '',
        UNSDLEA: '05302',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.5709, 44.9948],
            [-106.2637, 44.9938],
            [-106.0248, 44.9935],
            [-106.0097, 44.564],
            [-106.5507, 44.5569],
            [-106.6496, 44.6001],
            [-106.6066, 44.8055],
            [-106.4878, 44.8019],
            [-106.44, 44.9459],
            [-106.5709, 44.9948],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5605680',
        NAME: 'Sheridan County School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2062102819,
        AWATER: 1959769,
        INTPTLAT: '+44.7630532',
        INTPTLON: '-106.2920446',
        ELSDLEA: '',
        UNSDLEA: '05680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.9115, 45.0007],
            [-107.8449, 45.0007],
            [-107.5683, 45.0009],
            [-107.0052, 44.9964],
            [-106.9896, 44.9963],
            [-107.03, 44.8336],
            [-107.241, 44.8333],
            [-107.2567, 44.762],
            [-107.1831, 44.6583],
            [-107.1167, 44.6581],
            [-107.0942, 44.7551],
            [-106.9625, 44.7251],
            [-106.866, 44.6048],
            [-106.9343, 44.5585],
            [-107.3696, 44.5598],
            [-107.373, 44.5743],
            [-107.3766, 44.58],
            [-107.3749, 44.582],
            [-107.3808, 44.5846],
            [-107.3852, 44.5898],
            [-107.3835, 44.5954],
            [-107.3929, 44.596],
            [-107.4034, 44.5998],
            [-107.4228, 44.6035],
            [-107.425, 44.6115],
            [-107.4431, 44.6056],
            [-107.4574, 44.6077],
            [-107.4787, 44.6179],
            [-107.4949, 44.642],
            [-107.5282, 44.6348],
            [-107.5283, 44.6656],
            [-107.6197, 44.6988],
            [-107.7415, 44.7144],
            [-107.7544, 44.7706],
            [-107.8316, 44.7998],
            [-107.8165, 44.8185],
            [-107.8322, 44.8249],
            [-107.8282, 44.8307],
            [-107.8318, 44.845],
            [-107.865, 44.8736],
            [-107.8817, 44.9855],
            [-107.9115, 45.0007],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5605690',
        NAME: 'Sheridan County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2603248701,
        AWATER: 4709665,
        INTPTLAT: '+44.8072101',
        INTPTLON: '-107.3873009',
        ELSDLEA: '',
        UNSDLEA: '05690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-106.9896, 44.9963],
            [-106.5709, 44.9948],
            [-106.44, 44.9459],
            [-106.4878, 44.8019],
            [-106.6066, 44.8055],
            [-106.6496, 44.6001],
            [-106.5507, 44.5569],
            [-106.9343, 44.5585],
            [-106.866, 44.6048],
            [-106.9625, 44.7251],
            [-107.0942, 44.7551],
            [-107.1167, 44.6581],
            [-107.1831, 44.6583],
            [-107.2567, 44.762],
            [-107.241, 44.8333],
            [-107.03, 44.8336],
            [-106.9896, 44.9963],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5605695',
        NAME: 'Sheridan County School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1870200868,
        AWATER: 1316889,
        INTPTLAT: '+44.7954108',
        INTPTLON: '-106.8311202',
        ELSDLEA: '',
        UNSDLEA: '05695',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.5762, 43.5033],
            [-108.3379, 43.4598],
            [-108.1563, 43.4706],
            [-107.5951, 43.4723],
            [-107.5951, 43.5014],
            [-107.5349, 43.5014],
            [-107.517, 43.4727],
            [-107.5167, 43.128],
            [-107.5019, 42.8689],
            [-108.1459, 42.8707],
            [-108.1547, 43.1419],
            [-108.46, 43.1427],
            [-108.46, 43.2724],
            [-108.5785, 43.3007],
            [-108.5762, 43.5033],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5605700',
        NAME: 'Fremont County School District 24',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4530268683,
        AWATER: 90068166,
        INTPTLAT: '+43.1902230',
        INTPTLON: '-107.9578793',
        ELSDLEA: '',
        UNSDLEA: '05700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-110.049, 41.2292],
            [-110.048, 41.578],
            [-110.0464, 42.0069],
            [-109.5911, 42.0079],
            [-109.5926, 41.7438],
            [-109.4238, 41.7431],
            [-109.4222, 41.5756],
            [-109.349, 41.4883],
            [-108.5047, 41.4893],
            [-108.4833, 41.4016],
            [-108.4887, 41.0001],
            [-109.05, 41.0007],
            [-110.0007, 40.9975],
            [-110.0485, 40.9973],
            [-110.049, 41.2292],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5605762',
        NAME: 'Sweetwater County School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9601741912,
        AWATER: 132370622,
        INTPTLAT: '+41.3775741',
        INTPTLON: '-109.3765892',
        ELSDLEA: '',
        UNSDLEA: '05762',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-107.7061, 44.1664],
            [-107.1468, 44.1657],
            [-107.1107, 43.5003],
            [-107.5349, 43.5014],
            [-107.5951, 43.5014],
            [-107.5952, 43.6464],
            [-107.7162, 43.6458],
            [-107.7159, 43.7333],
            [-107.701, 43.8134],
            [-107.7061, 44.1664],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5605820',
        NAME: 'Washakie County School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3339116695,
        AWATER: 2555036,
        INTPTLAT: '+43.8403581',
        INTPTLON: '-107.4110338',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0534, 44.6663],
            [-110.6679, 44.6663],
            [-110.6679, 44.5828],
            [-110.3753, 44.583],
            [-110.2765, 44.5157],
            [-110.2966, 44.4313],
            [-110.1502, 44.2897],
            [-110.1192, 44.1326],
            [-110.0534, 44.1326],
            [-110.0532, 44.008],
            [-110.0516, 43.4648],
            [-110.0573, 43.3778],
            [-110.3442, 43.3779],
            [-110.344, 43.2912],
            [-110.5784, 43.2916],
            [-110.5787, 43.2352],
            [-110.814, 43.2352],
            [-110.814, 43.3159],
            [-111.0446, 43.3157],
            [-111.0455, 43.5011],
            [-111.047, 43.9835],
            [-111.0524, 44.4784],
            [-111.0534, 44.6663],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5605830',
        NAME: 'Teton County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10349561687,
        AWATER: 570879628,
        INTPTLAT: '+44.0486634',
        INTPTLON: '-110.4260712',
        ELSDLEA: '',
        UNSDLEA: '05830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-105.0793, 44.1762],
            [-104.3952, 44.1814],
            [-104.3956, 44.0928],
            [-104.4746, 44.0931],
            [-104.4909, 43.9179],
            [-104.5971, 43.9188],
            [-104.5979, 43.8309],
            [-104.8387, 43.8291],
            [-104.8383, 43.7441],
            [-105.0807, 43.7399],
            [-105.0793, 44.1762],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5606090',
        NAME: 'Weston County School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2019069559,
        AWATER: 1380157,
        INTPTLAT: '+43.9951024',
        INTPTLON: '-104.7882557',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-108.5506, 44.1685],
            [-107.7061, 44.1664],
            [-107.701, 43.8134],
            [-107.7159, 43.7333],
            [-107.9566, 43.7334],
            [-107.9564, 43.8187],
            [-108.1736, 43.819],
            [-108.3097, 43.8466],
            [-108.4296, 43.9034],
            [-108.5508, 44.0794],
            [-108.5506, 44.1685],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5606240',
        NAME: 'Washakie County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2458705679,
        AWATER: 8199285,
        INTPTLAT: '+43.9856772',
        INTPTLON: '-108.0488153',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0546, 45.001],
            [-111.0451, 45.0009],
            [-110.7045, 44.9922],
            [-110.0015, 45.0034],
            [-109.9298, 44.935],
            [-109.9581, 44.8022],
            [-109.8327, 44.6972],
            [-109.9081, 44.6369],
            [-110.1726, 44.5445],
            [-110.0606, 44.4211],
            [-110.0707, 44.3431],
            [-110.0015, 44.3194],
            [-110.0022, 44.1325],
            [-110.0534, 44.1326],
            [-110.1192, 44.1326],
            [-110.1502, 44.2897],
            [-110.2966, 44.4313],
            [-110.2765, 44.5157],
            [-110.3753, 44.583],
            [-110.6679, 44.5828],
            [-110.6679, 44.6663],
            [-111.0534, 44.6663],
            [-111.0546, 45.001],
          ],
        ],
      },
      properties: {
        STATEFP: '56',
        SCSDLEA: '',
        GEOID: '5699997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 4499033301,
        AWATER: 11037895,
        INTPTLAT: '+44.7162021',
        INTPTLON: '-110.3725810',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
  ],
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import CheckboxRow from '../../CheckboxRow';
import { getDimensionsBySample } from '../../../../../actions/fileWizard.action';
import './style.css';

const Tabs = ({ onClickHandler, type, isChecked }) => {
  const [isActive, setIsActive] = useState(true);
  const allSampleDimension = useSelector((state) => state.fileWizard.allSampleDimension);
  const allMetadatas = useSelector((state) => state.fileWizard.allMetadatas);
  const jwt = useSelector((state) => state.session.jwt);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDimensionsBySample(jwt));
  }, []);

  const renderDimensions = (metadata, inputName) => {
    if (metadata.length <= 0) return null;
    return metadata.map((sample) => (
      <CheckboxRow
        variableName={sample.Data.identifier}
        name={`${inputName}-${sample.ParentId}`}
        dataName={inputName}
        id={sample.Id}
        parent={sample.ParentId}
        onChangeHandler={onClickHandler}
        key={sample.Id}
        checked={isChecked(sample.Data.identifier, sample.ParentId, type, inputName)}
      />
    ));
  };

  const renderBarConfig = (multipleMetadatas) =>
    multipleMetadatas.map((metadata, i) => (
      <Tab.Pane
        key={shortid.generate()}
        eventKey={`tab-${i}`}
        className={isActive && i === 0 ? 'active show' : null}
      >
        <div className="Flex-container">
          <div className="Flex-container-section Flex-container-left">
            <div className="Flex-container-label">Bar Dimension</div>
            {renderDimensions(metadata, 'barDimensionIdentifier')}
          </div>
          <div className="Flex-container-section Flex-container-right">
            <div className="Flex-container-label">Segment dimension</div>
            {renderDimensions(metadata, 'segmentDimensionIdentifier')}
          </div>
        </div>
      </Tab.Pane>
    ));

  const renderLineConfig = (multipleMetadatas) =>
    multipleMetadatas.map((metadata, i) => (
      <Tab.Pane
        key={shortid.generate()}
        eventKey={`tab-${i}`}
        className={isActive && i === 0 ? 'active show' : null}
      >
        <div className="Flex-container">
          <div className="Flex-container-section Flex-container-left">
            <div className="Flex-container-label">xAxis Dimension</div>
            {renderDimensions(metadata, 'xDimensionIdentifier')}
          </div>
          <div className="Flex-container-section Flex-container-right">
            <div className="Flex-container-label">Line Dimension</div>
            {renderDimensions(metadata, 'lineDimensionIdentifier')}
          </div>
        </div>
      </Tab.Pane>
    ));

  const renderColumnConfig = (multipleMetadatas) =>
    multipleMetadatas.map((metadata, i) => (
      <Tab.Pane
        key={shortid.generate()}
        eventKey={`tab-${i}`}
        className={isActive && i === 0 ? 'active show' : null}
      >
        <div className="Flex-container">
          <div className="Flex-container-section Flex-container-left">
            <div className="Flex-container-label">Year Dimension</div>
            {renderDimensions(metadata, 'yearDimensionIdentifier')}
          </div>
        </div>
      </Tab.Pane>
    ));

  const getMetadataName = (metadataId = []) => {
    if (allMetadatas.length <= 0) return null;
    const filteredMetadata = allMetadatas.filter((metadata) => metadata.Id === metadataId)[0];
    if (filteredMetadata.length <= 0 || !filteredMetadata.Data) return null;
    const { Data } = filteredMetadata;
    return Data.title;
  };

  const parseName = (samples = []) => {
    const parentId = samples.every((sample) => sample.ParentId === samples[0].ParentId);
    if (parentId) {
      return getMetadataName(samples[0].ParentId);
    }
    return 'Default Metadata';
  };

  const setDisable = () => {
    setIsActive(false);
  };

  return (
    <div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row className="no-gutters">
          <Col sm={3} className="custom-border">
            <Nav variant="pills" className="flex-column">
              {allSampleDimension.map((dimensions, i) => (
                <Nav.Item key={shortid.generate()}>
                  <Nav.Link
                    eventKey={`tab-${i}`}
                    onClick={setDisable}
                    className={isActive && i === 0 ? 'active' : null}
                  >
                    {parseName(dimensions)}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              {type === 'bar' && renderBarConfig(allSampleDimension)}
              {type === 'line' && renderLineConfig(allSampleDimension)}
              {type === 'column' && renderColumnConfig(allSampleDimension)}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

Tabs.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default Tabs;

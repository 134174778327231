export const DE = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7838, 39.5905],
            [-75.7136, 39.5839],
            [-75.6465, 39.5047],
            [-75.5591, 39.4958],
            [-75.5502, 39.4467],
            [-75.6011, 39.3737],
            [-75.7643, 39.3493],
            [-75.7666, 39.3776],
            [-75.7838, 39.5905],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1000080',
        NAME: 'Appoquinimink School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328165406,
        AWATER: 21505520,
        INTPTLAT: '+39.4543096',
        INTPTLON: '-075.6895602',
        ELSDLEA: '',
        UNSDLEA: '00080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4157, 38.7579],
            [-75.129, 38.9874],
            [-75.0151, 38.7887],
            [-74.9941, 38.6144],
            [-75.1943, 38.6616],
            [-75.2695, 38.739],
            [-75.4157, 38.7579],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1000170',
        NAME: 'Cape Henlopen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418779561,
        AWATER: 415401204,
        INTPTLAT: '+38.7777387',
        INTPTLON: '-075.1788902',
        ELSDLEA: '',
        UNSDLEA: '00170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7443, 39.0997],
            [-75.6669, 39.1364],
            [-75.4424, 39.1604],
            [-75.2482, 39.1578],
            [-75.1917, 39.079],
            [-75.2614, 39.1014],
            [-75.3994, 39.0663],
            [-75.7387, 39.0297],
            [-75.7443, 39.0997],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1000180',
        NAME: 'Caesar Rodney School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 280789419,
        AWATER: 140024812,
        INTPTLAT: '+39.0995856',
        INTPTLON: '-075.4889145',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.751, 39.1836],
            [-75.6632, 39.1842],
            [-75.5481, 39.2515],
            [-75.3162, 39.2453],
            [-75.2482, 39.1578],
            [-75.4424, 39.1604],
            [-75.6669, 39.1364],
            [-75.7443, 39.0997],
            [-75.7478, 39.1433],
            [-75.751, 39.1836],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1000190',
        NAME: 'Capital School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293847441,
        AWATER: 108948095,
        INTPTLAT: '+39.1849631',
        INTPTLON: '-075.5050681',
        ELSDLEA: '',
        UNSDLEA: '00190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-75.5439, 39.7503],
              [-75.5316, 39.7461],
              [-75.5609, 39.7285],
              [-75.5439, 39.7503],
            ],
          ],
          [
            [
              [-75.7886, 39.7222],
              [-75.7545, 39.7556],
              [-75.7514, 39.7595],
              [-75.6792, 39.6977],
              [-75.6517, 39.6327],
              [-75.7136, 39.5839],
              [-75.7838, 39.5905],
              [-75.7886, 39.7222],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1000200',
        NAME: 'Christina School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171595866,
        AWATER: 857663,
        INTPTLAT: '+39.6671960',
        INTPTLON: '-075.7194738',
        ELSDLEA: '',
        UNSDLEA: '00200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7136, 39.5839],
            [-75.6517, 39.6327],
            [-75.6792, 39.6977],
            [-75.5609, 39.7285],
            [-75.5316, 39.7461],
            [-75.4705, 39.7419],
            [-75.5045, 39.6982],
            [-75.5597, 39.6304],
            [-75.513, 39.5784],
            [-75.528, 39.5004],
            [-75.5591, 39.4958],
            [-75.6465, 39.5047],
            [-75.7136, 39.5839],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1000230',
        NAME: 'Colonial School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204213942,
        AWATER: 110640411,
        INTPTLAT: '+39.6184865',
        INTPTLON: '-075.5636227',
        ELSDLEA: '',
        UNSDLEA: '00230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6985, 38.522],
            [-75.4332, 38.4878],
            [-75.4286, 38.4528],
            [-75.6937, 38.4601],
            [-75.6985, 38.522],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1000270',
        NAME: 'Delmar School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119126214,
        AWATER: 17386,
        INTPTLAT: '+38.4838672',
        INTPTLON: '-075.5638707',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4662, 38.7473],
            [-75.4157, 38.7579],
            [-75.2695, 38.739],
            [-75.1943, 38.6616],
            [-74.9941, 38.6144],
            [-74.9863, 38.4516],
            [-75.3413, 38.452],
            [-75.4286, 38.4528],
            [-75.4332, 38.4878],
            [-75.3717, 38.5238],
            [-75.4548, 38.6178],
            [-75.5313, 38.691],
            [-75.4662, 38.7473],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1000680',
        NAME: 'Indian River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 856590491,
        AWATER: 162503207,
        INTPTLAT: '+38.5796303',
        INTPTLON: '-075.2534463',
        ELSDLEA: '',
        UNSDLEA: '00680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7387, 39.0297],
            [-75.3994, 39.0663],
            [-75.503, 38.9599],
            [-75.5357, 38.8696],
            [-75.6299, 38.882],
            [-75.7241, 38.8467],
            [-75.7387, 39.0297],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1000790',
        NAME: 'Lake Forest School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 417164896,
        AWATER: 1761050,
        INTPTLAT: '+38.9660517',
        INTPTLON: '-075.6034855',
        ELSDLEA: '',
        UNSDLEA: '00790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7016, 38.5607],
            [-75.6529, 38.6044],
            [-75.4548, 38.6178],
            [-75.3717, 38.5238],
            [-75.4332, 38.4878],
            [-75.6985, 38.522],
            [-75.7016, 38.5607],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1000810',
        NAME: 'Laurel School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282329728,
        AWATER: 3903754,
        INTPTLAT: '+38.5477153',
        INTPTLON: '-075.5381577',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3994, 39.0663],
            [-75.2614, 39.1014],
            [-75.1917, 39.079],
            [-75.129, 38.9874],
            [-75.4157, 38.7579],
            [-75.4662, 38.7473],
            [-75.5357, 38.8696],
            [-75.503, 38.9599],
            [-75.3994, 39.0663],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1001080',
        NAME: 'Milford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424034271,
        AWATER: 297054946,
        INTPTLAT: '+38.9432534',
        INTPTLON: '-075.3625670',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5796, 39.8389],
            [-75.5513, 39.8394],
            [-75.4724, 39.8269],
            [-75.4151, 39.8019],
            [-75.4502, 39.7748],
            [-75.4662, 39.7508],
            [-75.4705, 39.7419],
            [-75.5316, 39.7461],
            [-75.5439, 39.7503],
            [-75.5796, 39.8389],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1001240',
        NAME: 'Brandywine School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83789879,
        AWATER: 18325550,
        INTPTLAT: '+39.7971805',
        INTPTLON: '-075.5038423',
        ELSDLEA: '',
        UNSDLEA: '01240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6348, 39.8302],
            [-75.5796, 39.8389],
            [-75.5439, 39.7503],
            [-75.5609, 39.7285],
            [-75.6792, 39.6977],
            [-75.7514, 39.7595],
            [-75.6348, 39.8302],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1001300',
        NAME: 'Red Clay Consolidated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170058021,
        AWATER: 1570798,
        INTPTLAT: '+39.7649978',
        INTPTLON: '-075.6409423',
        ELSDLEA: '',
        UNSDLEA: '01300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7124, 38.698],
            [-75.5313, 38.691],
            [-75.4548, 38.6178],
            [-75.6529, 38.6044],
            [-75.7016, 38.5607],
            [-75.7074, 38.6354],
            [-75.7124, 38.698],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1001530',
        NAME: 'Seaford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190945557,
        AWATER: 3664854,
        INTPTLAT: '+38.6435621',
        INTPTLON: '-075.6147349',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7643, 39.3493],
            [-75.6011, 39.3737],
            [-75.5502, 39.4467],
            [-75.4448, 39.3551],
            [-75.3162, 39.2453],
            [-75.5481, 39.2515],
            [-75.6632, 39.1842],
            [-75.751, 39.1836],
            [-75.756, 39.2461],
            [-75.7643, 39.3493],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1001620',
        NAME: 'Smyrna School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 435288541,
        AWATER: 112074875,
        INTPTLAT: '+39.2944880',
        INTPTLON: '-075.5823213',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7241, 38.8467],
            [-75.6299, 38.882],
            [-75.5357, 38.8696],
            [-75.4662, 38.7473],
            [-75.5313, 38.691],
            [-75.7124, 38.698],
            [-75.7241, 38.8467],
          ],
        ],
      },
      properties: {
        STATEFP: '10',
        SCSDLEA: '',
        GEOID: '1001850',
        NAME: 'Woodbridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370385894,
        AWATER: 488873,
        INTPTLAT: '+38.7841649',
        INTPTLON: '-075.6011957',
        ELSDLEA: '',
        UNSDLEA: '01850',
      },
    },
  ],
};

import React from 'react';
import PropTypes from 'prop-types';

const CardImage = ({ isSurvey, isVisualization, imageSrc }) => (
  <div className="Card-image">
    {
      isSurvey
      && <img src="/survey-art.svg" alt="Survey Art" />
    }
    {
      isVisualization
      && <img src={imageSrc} alt="Visualization Art" />
    }
  </div>
);

CardImage.propTypes = {
  isSurvey: PropTypes.bool,
  isVisualization: PropTypes.bool,
};

CardImage.defaultProps = {
  isSurvey: false,
  isVisualization: false,
};
export default CardImage;

import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../Divider';
import './style.css';

const FormGroup = ({ children }) => (
  <>
    <div className="FormGroup">
      {children}
    </div>
    <Divider spacing="md" />
  </>
);
FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormGroup;

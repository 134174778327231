import _ from 'lodash';

import { CHART_TYPES, DASHBOARD_ALL_CATEGORIES } from '../../constants/charts';

export function makeDistrictAbbr(name) {
  return name.substring(0, 5).toUpperCase();
}

export const calculateChartHeight = (qtyOptions) => {
  return 105 * qtyOptions || 5;
};

export const calculateColumnChartHeight = (qtyOptions) => {
  return 55 * qtyOptions || 5;
};

// Find the first truthy value in the array
export const findFirstValidValue = (
  array,
  value_extractor = (item) => item,
  default_value = null
) => {
  if (!array) return default_value;

  for (let i = 0; i < array.length; i += 1) {
    const value = value_extractor(array[i]);
    if (value) {
      return value;
    }
  }
  return default_value;
};

export const formatNumber = (n, currency = '', decimals = 0) => {
  if (typeof n === 'string') {
    return n; // no formatting required
  }
  return `${currency} 
    ${n.toFixed(decimals).replace(/./g, (c, i, a) => {
      return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? `, ${c}` : c;
    })}`;
};

// Match answer options to colors, types and indices in the legend
// Reshape data to be ingested by a stacked bar chart
// NB: ! --- For segments, benchmarks and other chart types the data needs further re-shaping --- !
const matchCategories = (
  array,
  color_scheme = 0,
  filtered = false,
  segmented = false,
  current_categories
) => {
  const regrouped = [];
  const { option_type, options } = current_categories;

  // assuming that categories will be returned using the same order as the answer options when building questions
  for (let j = 0; j < options.length; j += 1) {
    const cat_options = options[j];
    const cat_name = options[j].name;

    let data_array = [];
    let MOE_array = [];
    let benchmark_array = [];
    let benchmark_labels = [];

    if (array) {
      array.forEach((el) => {
        let MOE_val;

        if (
          el.mliDataPoints === null ||
          el.mliDataPoints === undefined ||
          el.mliDataPoints[j] === undefined
        ) {
          data_array = null;
          MOE_array = null;
          benchmark_array = null;
          benchmark_labels = null;
        } else {
          data_array.push(el.mliDataPoints[j].value);
          MOE_val = el.mliDataPoints[j].marginOfError
            ? Math.round(el.mliDataPoints[j].marginOfError)
            : null;
          MOE_array.push(MOE_val);
          benchmark_array.push(el.mliDataPoints[j].benchmarkValue);
          benchmark_labels.push(el.mliDataPoints[j].benchmarkLabel);
        }
      });
    }

    regrouped.push({
      name: cat_name,
      filtered,
      segmented,
      color: cat_options.color[color_scheme],
      MOE: MOE_array,
      benchmark_array,
      benchmark_labels,
      data: data_array,
      cat: cat_options.type,
      option_type,
    });
  }
  return regrouped;
};

//------------------------------------------
// Map colors and other category options to MLI survey answer categories
const regroupBarForSegments = (
  array_slices,
  categories,
  segment_names,
  filtered = false,
  segmented = true,
  benchmarked = false,
  N_sizes = null
) => {
  const new_data = new Array(categories.length);
  const new_cats = [];

  for (let j = 0; j < categories.length; j += 1) {
    const array_slice = array_slices[0];

    const inner_data = new Array(array_slice.length);

    for (let i = 0; i < array_slice.length; i += 1) {
      inner_data[i] = {};
      inner_data[i].filtered = filtered;
      inner_data[i].segmented = segmented;
      inner_data[i].name = array_slice[i].name;
      inner_data[i].color = array_slice[i].color;
      inner_data[i].cat = array_slice[i].cat;
      inner_data[i].MOE = [];
      inner_data[i].benchmark_array = [];
      inner_data[i].benchmark_labels = [];
      inner_data[i].data = [];

      // Check if there are data points in the array
      if (array_slice[i].data !== null && array_slice[i].data !== undefined) {
        inner_data[i].data.push(array_slice[i].data[j]);
        inner_data[i].benchmark_array.push(array_slice[i].benchmark_array[j]);
        inner_data[i].benchmark_labels.push(array_slice[i].benchmark_labels[j]);
        inner_data[i].MOE.push(array_slice[i].MOE[j]);
        for (let k = 1; k < segment_names.length; k += 1) {
          inner_data[i].data.push(array_slices[k][i].data[j]);
          inner_data[i].benchmark_array.push(array_slices[k][i].benchmark_array[j]);
          inner_data[i].benchmark_labels.push(array_slices[k][i].benchmark_labels[j]);
          inner_data[i].MOE.push(array_slices[k][i].MOE[j]);
        }
      } else if (
        array_slice[i].data === undefined &&
        array_slice[i].y !== undefined &&
        array_slice[i].y !== null
      ) {
        for (let k = 0; k < segment_names.length; k += 1) {
          inner_data[i].data.push(array_slices[k][i].y);
          inner_data[i].benchmark_array.push(array_slices[k][i].benchmark_value);
          inner_data[i].benchmark_labels.push(array_slices[k][i].benchmark_value);
          inner_data[i].MOE.push(array_slices[k][i].MOE);
        }
      } else {
        inner_data[i].data = null;
        inner_data[i].benchmark_array = null;
        inner_data[i].benchmark_labels = null;
        inner_data[i].MOE = null;
      }
    }

    // Adjust segment names if the data was benchmarked
    if (benchmarked) {
      new_cats[j] = new Array(segment_names.length);

      if (segment_names.indexOf('nat-av') >= 0)
        new_cats[j][segment_names.indexOf('nat-av')] = `National (N = ${
          N_sizes[segment_names.indexOf('nat-av')]
        })`;
      if (segment_names.indexOf('state-av') >= 0)
        new_cats[j][segment_names.indexOf('state-av')] = `'State (N = ${
          N_sizes[segment_names.indexOf('state-av')]
        })`;

      let default_id = segment_names.indexOf('All respondents');
      if (default_id < 0) {
        default_id = segment_names.indexOf('default');
      }

      new_cats[j][default_id] = categories[j];
    } else {
      new_cats[j] = segment_names;
    }

    new_data[j] = inner_data;
  }

  return [new_data, new_cats];
};
export const regroupForSplitBar = (array) => {
  const neutral_categories = [];
  const pos_neg_categories = [];

  array.forEach((item, index) => {
    const element = { ...item };
    if (element.cat === 'neutral') {
      neutral_categories.push(element);
    } else {
      if (element.cat === 'negative') {
        element.data = element.data.map((e) => {
          return -1 * e;
        });

        // reverse order of negative bars so they are presented in the same order as in the data array and legend
        element.index = -1 * index;
      } else {
        element.index = index;
      }
      element.legendIndex = index;

      pos_neg_categories.push(element);
    }
  });

  return [
    pos_neg_categories.length > 0 ? pos_neg_categories : null,
    neutral_categories.length > 0 ? neutral_categories : null,
  ];
};

export function makeSidebarMapPalette(min, max, color_scheme = 0, postfix = '%') {
  const factor = 1;
  const dx = 1;
  const decimals = 0;
  /* if (postfix === "%") {
		factor = 10;
		dx = 0.1;
		decimals = 1;
	} */

  const new_min = Math.floor(min * factor) / factor;
  const new_max = Math.ceil(max * factor) / factor;

  const zero = ['#B9DEC3', '#ffe9a8', '#C1D7E9', '#CEE4E1'];
  const two = ['#58BFC4', '#f9da7c', '#A8B5D8', '#A9CDC8'];
  const two_val = Math.round(((new_max - new_min) * 0.2 + new_min) * factor) / factor;
  const four = ['#3984b6', '#ffc425', '#9094C8', '#85B6AF'];
  const four_val = Math.round(((new_max - new_min) * 0.4 + new_min) * factor) / factor;
  const six = ['#4794AA', '#e5a624', '#7873B8', '#609F96'];
  const six_val = Math.round(((new_max - new_min) * 0.6 + new_min) * factor) / factor;
  const eight = ['#3F6585', '#d18029', '#6052A8', '#3C887E'];
  const eight_val = Math.round(((new_max - new_min) * 0.8 + new_min) * factor) / factor;

  const palette = {
    zero: zero[color_scheme],
    two: two[color_scheme],
    two_val,
    four: four[color_scheme],
    four_val,
    six: six[color_scheme],
    six_val,
    eight: eight[color_scheme],
    eight_val,
    null_color: '#C0C0C0',
  };

  let ranges = [];
  let colors = [];

  if (postfix === '%') {
    ranges = [
      `'${new_min} - ${Math.round((two_val - dx) * factor) / factor}'`,
      `'${two_val} - ${Math.round((four_val - dx) * factor) / factor}'`,
      `'${four_val} - ${Math.round((six_val - dx) * factor) / factor}'`,
      `'${six_val} - ${Math.round((eight_val - dx) * factor) / factor}'`,
      `'${eight_val} - ${new_max}'`,
      'NA',
    ];
  } else {
    ranges = [
      `'${formatNumber(new_min, '', decimals)} - ${formatNumber(
        Math.round((two_val - dx) * factor) / factor,
        '',
        decimals
      )}'`,
      `'${formatNumber(two_val, '', decimals)} - ${formatNumber(
        Math.round((four_val - dx) * factor) / factor,
        '',
        decimals
      )}'`,
      `'${formatNumber(four_val, '', decimals)} - ${formatNumber(
        Math.round((six_val - dx) * factor) / factor,
        '',
        decimals
      )}'`,
      `'${formatNumber(six_val, '', decimals)} - ${formatNumber(
        Math.round((eight_val - dx) * factor) / factor,
        '',
        decimals
      )}'`,
      `'${formatNumber(eight_val, '', decimals)} - ${formatNumber(new_max)}`,
      'NA',
    ];
  }

  colors = [
    palette.zero,
    palette.two,
    palette.four,
    palette.six,
    palette.eight,
    palette.null_color,
  ];

  return [palette, ranges, colors];
}

export function setSidebarElementColor(
  element,
  min,
  max,
  selected,
  sidebar = true,
  district = false,
  pal
) {
  let color;

  if (sidebar) {
    const bar_id = district ? `${element.leaid}` : element.label;

    if (element.y < pal.two_val && element.y >= min && bar_id !== selected) {
      color = pal.zero;
    } else if (element.y < pal.four_val && element.y >= pal.two_val && bar_id !== selected) {
      color = pal.two;
    } else if (element.y < pal.six_val && element.y >= pal.four_val && bar_id !== selected) {
      color = pal.four;
    } else if (element.y < pal.eight_val && element.y >= pal.six_val && bar_id !== selected) {
      color = pal.six;
    } else if (element.y <= max && element.y >= pal.eight_val && bar_id !== selected) {
      color = pal.eight;
    } else if (bar_id === selected) {
      color = '#f27b99';
    }
    return color;
  }

  if (element.value === 0 || element.value === null || element.value === undefined) {
    color = pal.null_color;
  } else if (element.value < pal.two_val && element.value >= min) {
    color = pal.zero;
  } else if (element.value < pal.four_val && element.value >= pal.two_val) {
    color = pal.two;
  } else if (element.value < pal.six_val && element.value >= pal.four_val) {
    color = pal.four;
  } else if (element.value < pal.eight_val && element.value >= pal.six_val) {
    color = pal.six;
  } else if (element.value <= max && element.value >= pal.eight_val) {
    color = pal.eight;
  }

  return color;
}
export function regroupForSidebar(data, district_data = false) {
  const regrouped = [];
  let xaxis_categories = [];
  let label;
  let value;

  if (data !== undefined && data !== null && data.length > 0) {
    const initialMinMax = findFirstValidValue(data, (item) => item.mapValue, 0);
    let min = initialMinMax;
    let max = initialMinMax;

    regrouped.push({
      name: findFirstValidValue(data, (item) => item.category, ''),
      nat_av: findFirstValidValue(data, (item) => item.natAv, null),
      state_av: findFirstValidValue(data, (item) => item.stateAvg, null),
      value_prefix: findFirstValidValue(data, (item) => item.valuePrefix, ''),
      value_suffix: findFirstValidValue(data, (item) => item.valueSuffix, ''),
      data: [],
      states: {
        hover: {
          brightness: 0.15,
        },
      },
    });

    data.forEach((element) => {
      value = element.mapValue;

      if (element.abbreviation !== null && element.label !== null && value) {
        if (value < min) {
          min = value;
        }

        if (value > max) {
          max = value;
        }

        if (element.rank && element.rank > 0) {
          if (element.mapValue === 0) {
            label = 'NA';
          } else {
            label = element.label;
          }

          regrouped[0].data.push({
            y: element.mapValue !== 0 ? value : null,
            label: district_data ? element.districtName : element.abbreviation,
            text_label: label,
            leaid: district_data ? element.leaid || element.leaId : null,
            geoid: district_data ? element.geoid || element.geoId : null,
            name: element.name ? element.name : element.stateName,
            id: district_data
              ? (element.leaid || element.geoid || element.leaId || element.geoId || 0).toString()
              : element.abbreviation,
            ranking: element.rank,
          });
        }
      }
    });

    regrouped[0].min = min;
    regrouped[0].max = max;

    regrouped[0].data = _.sortBy(regrouped[0].data, 'ranking');
    xaxis_categories = _.map(regrouped[0].data, 'ranking');
    return [regrouped, xaxis_categories, min, max];
  }
  return [];
}

export function regroupForMap(data, view_mode = 'STATE', color_scheme = 0) {
  const regrouped = [];
  let min = null;
  let max = null;
  let label;
  let value;

  if (data !== undefined && data !== null && data.length > 0) {
    const initialMinMax = findFirstValidValue(data, (item) => item.mapValue, 0);
    min = initialMinMax;
    max = initialMinMax;

    data.forEach((element) => {
      value = element.mapValue;

      if (
        element.abbreviation !== null &&
        element.label !== null &&
        element.mapValue !== null &&
        element.mapValue !== undefined
      ) {
        if (element.mapValue < min) {
          min = element.mapValue;
        }

        if (element.mapValue > max) {
          max = element.mapValue;
        }

        if (element.mapValue === 0) {
          label = 'NA';
        } else {
          label = element.label;
        }

        regrouped.push({
          value,
          label,
          name: view_mode === 'STATE' ? element.stateName : element.districtName,
          code: element.abbreviation,
          ranking: element.mapValue !== 0 ? element.rank : 'NA',
          id: view_mode === 'STATE' ? element.abbreviation : element.leaId.toString(),
          leaid: view_mode === 'STATE' ? null : element.leaId,
          geoid: view_mode === 'STATE' ? null : element.geoId,
        });
      } else if (element.abbreviation !== null && element.label === null) {
        regrouped.push({
          value: 0,
          label: 'NA',
          name: view_mode === 'STATE' ? element.stateName : element.districtName,
          code: element.abbreviation,
          ranking: 'NA',
          id: view_mode === 'STATE' ? element.abbreviation : element.leaId.toString(),
          leaid: view_mode === 'STATE' ? null : element.leaId,
          geoid: view_mode === 'STATE' ? null : element.geoId,
        });
      }
    });
  }

  const valueSuffix = data && Array.isArray(data) ? data[0].valueSuffix : '';
  const pal = makeSidebarMapPalette(min, max, color_scheme, valueSuffix);

  const regroupedWithColor = regrouped.map((el) => ({
    ...el,
    color: setSidebarElementColor(el, min, max, null, false, false, pal[0]),
  }));
  return [regroupedWithColor, min, max, pal];
}

export function getDataSetLabel(datasetFilter) {
  let variableLabel = '';
  // TODO remove this method and send label from backend in a dedicated field
  if (datasetFilter.filter.constraints.length > 0) {
    const constraints = datasetFilter.filter.constraints[0].value || [];
    if (datasetFilter.filter.type === 'METADATA') {
      try {
        let metadataLabel = '';
        let sampleLabel = '';
        constraints.forEach((menuOption) => {
          if (menuOption.tag === 'metadata') {
            metadataLabel = menuOption.label;
          } else if (menuOption.tag === 'sample') {
            sampleLabel = menuOption.label;
          }
        });
        variableLabel = sampleLabel || metadataLabel;
      } catch (e) {
        // TODO temp in case of reading an old constraints format, label will be returned directly from backend in the future
      }
    }
  }

  // TODO remove this structure, postfix/prefix are sent embedded inside value labels in v2
  return {
    data_label: variableLabel,
    postfix: '',
    prefix: '',
  };
}
export function setLocationData(config, viewMode) {
  // Default to National location
  let name = 'National';
  let abbr = 'US';
  let state_abbr = 'US';

  const map_level = viewMode.toLowerCase();

  if (config.state !== null) {
    name = config.state.name;
    abbr = config.state.abbreviation;

    // A location is selected; set scope based on map_level
    if (map_level === 'district') {
      state_abbr = config.state.abbreviation;

      if (config.state.district !== null) {
        name = config.state.district.name;
        abbr = config.state.district.abbreviation;
      }
    }
  }

  return {
    name,
    abbr,
    state_abbr,
    map_level,
    state_name: config?.state?.name || name,
  };
}

//------------------------------------------
// Regroup and add data for heatmap
export const regroupForHeatmap = (data, filtered = false, segmented = false, benchmark = false) => {
  const x_total = data.length;
  const xaxis_categories = new Array(x_total);
  const y_total = data[0]?.data?.length;
  const regrouped = new Array(x_total * y_total);
  let heatmap_id = 0;
  let y_reversed;

  data.forEach((x_el, x_id) => {
    y_reversed = x_el.data.reverse();
    y_reversed.forEach((y_el, y_id) => {
      regrouped[heatmap_id] = {
        x: x_id,
        y: y_id,
        value: y_el,
        borderColor: '#ffffff',
        borderWidth: 2.5,
        benchmark_label: x_el.benchmark_labels[y_id],
        benchmark_value: x_el.benchmark_array[y_id],
        MOE: x_el.MOE[y_id],
        cat_name: x_el.name,
        filtered,
        segmented,
        benchmark,
      };
      heatmap_id += 1;
    });
    xaxis_categories[x_id] = x_el.name;
  });

  return [regrouped, xaxis_categories];
};

// Regroup for donut
export const regroupForDonut = (data) => {
  const regrouped = [];
  let size = '100%';
  const inner_size = '70%';
  let show_in_legend = true;
  let data_labels = false;
  let series_name = 'All respondents';

  for (let i = 0; i < data.numActiveSegments; i += 1) {
    if (data.segmentNames[i] === 'nat-av') {
      series_name = `National (${data.nSize[i]})`;
    } else if (data.segmentNames[i] === 'state-av') {
      series_name = `State (${data.nSize[i]})`;
    } else if (data.segmentNames[i] === 'default') {
      series_name = 'All respondents';
    } else {
      series_name = data.segmentNames[i];
    }

    if (i > 0) {
      size = `${100 - 20 * i}%`;
      show_in_legend = false;
      data_labels = false;
    }

    regrouped[i] = {
      name: series_name,
      data: [],
      size,
      innerSize: inner_size,
      showInLegend: show_in_legend,
      dataLabels: data_labels,
    };

    data.dataSeries[i].forEach((element) => {
      let y;
      let benchmark;
      let moe;
      let benchmark_label;

      if (!element.data && element.y >= 0) {
        y = element.y;
      } else if (element.data && !element.y) {
        [y] = element.data;
      } else {
        y = null;
      }

      if (Array.isArray(element.MOE)) {
        // use array value
        [moe] = element.MOE;
      } else if (element.MOE !== undefined) {
        // use value (null is OK)
        moe = element.MOE;
      } else {
        // use null
        moe = null;
      }

      if (!element.benchmark_array && element.benchmark_value) {
        benchmark = element.benchmark_value;
        benchmark_label = element.benchmark_label;
      } else if (element.benchmark_array && !element.benchmark_value) {
        [benchmark] = element.benchmark_array;
        [benchmark_label] = element.benchmark_labels;
      } else {
        benchmark = null;
        benchmark_label = 'NA';
      }

      regrouped[i].data.push({
        y,
        name: element.name,
        color: element.color,
        total: 100,
        filtered: element.filtered,
        MOE: moe,
        benchmark,
        benchmark_label,
      });
    });
  }

  return regrouped;
};
// Regroup for donut
export function regroupForDonutDashboard(
  data,
  inner_size = '80%',
  size = '100%',
  show_in_legend = true,
  series_name = '',
  color_scheme = null
) {
  const regrouped = [];

  const default_color_scheme = [
    '#dcb33c',
    '#6f9c2c',
    '#484ec1',
    '#a0411a',
    '#606060',
    '#b266ff',
    '#357ba8',
  ];
  const colors = color_scheme !== null ? color_scheme : default_color_scheme;

  regrouped.push({
    name: series_name,
    data: [],
    size,
    innerSize: inner_size,
    showInLegend: show_in_legend,
    dataLabels: false,
  });

  if (data !== null && data !== undefined) {
    data.forEach((element, index) => {
      regrouped[0].data.push({
        y: element.value,
        name: element.variable,
        color: colors[index],
        total: 100,
      });
    });
  }

  return regrouped;
}

export function regroupForColumnDashboard(data, location, color_scheme = 0) {
  // Don't bother regrouping if there's no data
  if (!data?.graduationData?.length) {
    return null;
  }

  const regrouped = [];
  const nat_averages = [];
  const state_averages = [];
  let year = null;
  let year_change = null;
  let regrouped_data_change = null;
  let change_max_value = 0;
  let change_min_value = 0;
  let max_value = 0;
  let min_value = 0;
  let value_prefix = '';
  let value_suffix = '';

  const category_keys = DASHBOARD_ALL_CATEGORIES;
  const category_keys_lower = category_keys.map((e) => e.toLowerCase());
  let index;
  let color;
  const column_colors = ['#426e99', '#006d5d', '#f4c289', '#7D1858'];

  year = data.graduationData[0].yearLabel;
  year_change = data.graduationData[0].yearDif;

  regrouped.push({
    name: location.name,
    color: column_colors[color_scheme],
    data: [],
    type: 'column',
    showInLegend: false,
  });

  nat_averages.push({
    name: 'National Average',
    color: '#e3c3f5',
    data: [],
    type: 'scatter',
    marker: {
      enabled: null,
      lineWidth: 2,
      lineColor: '#e3c3f5',
      symbol: 'avg_line',
    },
  });

  state_averages.push({
    name: 'State Average',
    color: '#dcb33c',
    data: [],
    type: 'scatter',
    marker: {
      enabled: null,
      lineWidth: 2,
      lineColor: '#dcb33c',
      symbol: 'avg_line',
    },
  });

  if (year_change > 0) {
    regrouped_data_change = [];

    regrouped_data_change.push({
      name: data.graduationChangeLabel,
      data: [],
      type: 'column',
      showInLegend: false,
    });
  }

  data.graduationData.forEach((element) => {
    index = category_keys_lower.indexOf(element.category.toLowerCase());

    // N/A category. Add placeholder
    if (element.categoryValue == null) {
      const category_name = `${category_keys[index]} (data not available)`;

      regrouped[0].data.push({
        not_available: true,
        y: 0,
        x: index,
        name: category_name,
        label: 'NA',
        uncertainty: 100,
        nat_av: null,
        state_av: null,
        color: '#6c6c6c',
      });

      nat_averages[0].data.push({
        not_available: true,
        y: null,
        x: index,
        name: category_name,
        label: 'NA',
        uncertainty: 100,
      });

      state_averages[0].data.push({
        not_available: true,
        y: null,
        x: element.index,
        name: category_name,
        label: 'NA',
        uncertainty: 100,
        nat_av: null,
      });

      if (year_change > 0) {
        // Add placeholder for year change
        regrouped_data_change[0].data.push({
          not_available: true,
          y: 0,
          x: index,
          name: category_name,
          label: 'NA',
          color: '#6c6c6c',
        });
      }

      return;
    }

    regrouped[0].data.push({
      y: element.categoryValue,
      x: index,
      name: element.category,
      label: element.displayLabel,
      uncertainty: element.uncertainty,
      nat_av: element.nationalValue,
      state_av: element.stateValue,
    });

    nat_averages[0].data.push({
      y: element.nationalValue,
      x: index,
      name: element.category,
      label: element.nationalDisplayLabel !== null ? element.nationalDisplayLabel : 'NA',
      uncertainty: 0,
    });

    state_averages[0].data.push({
      y: element.stateValue,
      x: index,
      name: element.category,
      label: element.stateDisplayLabel !== null ? element.stateDisplayLabel : 'NA',
      uncertainty: 0,
      nat_av: element.nationalValue,
    });

    if (min_value > element.categoryValue) min_value = element.categoryValue;
    if (max_value < element.categoryValue) max_value = element.categoryValue;

    // Need to change blues to greens per Leemor's last edits
    if (element.yearComparisonChange >= 0 && element.yearComparisonChange <= 3) {
      color = '#acd0ad';
    } else if (element.yearComparisonChange > 3) {
      color = '#4da65a';
    } else if (element.yearComparisonChange <= 0 && element.yearComparisonChange >= -3) {
      color = '#f6bdc4';
    } else if (element.yearComparisonChange < -3) {
      color = '#f67f8e';
    }

    if (change_min_value > element.yearComparisonChange)
      change_min_value = element.yearComparisonChange;
    if (change_max_value < element.yearComparisonChange)
      change_max_value = element.yearComparisonChange;

    if (year_change > 0) {
      if (element.yearComparisonChange !== null) {
        regrouped_data_change[0].data.push({
          y: element.yearComparisonChange,
          x: index,
          name: element.category,
          label: element.yearComparisonChange,
          color,
        });
      } else {
        regrouped_data_change[0].data.push({
          not_available: true,
          y: 0,
          x: index,
          name: element.category,
          label: 'NA',
          color: '#6c6c6c',
        });
      }
    }

    // determine prefix and suffix from the first value in the data set, assuming all values share the same prefix/suffix
    if (value_prefix === '') {
      value_prefix = element.valuePrefix;
    }
    if (value_suffix === '') {
      value_suffix = element.valueSuffix;
    }
  });

  regrouped[0].data = _.sortBy(regrouped[0].data, 'x');
  state_averages[0].data = _.sortBy(state_averages[0].data, 'x');
  nat_averages[0].data = _.sortBy(nat_averages[0].data, 'x');

  return [
    regrouped,
    nat_averages,
    state_averages,
    category_keys,
    year,
    regrouped_data_change,
    min_value,
    max_value,
    change_min_value,
    change_max_value,
    value_prefix,
    value_suffix,
  ];
}

export function regroupForWaffleDashboard(data, square_size = 8, color_scheme = null) {
  const regrouped = [];

  if (data !== undefined && data !== null) {
    const default_color_scheme = [
      '#dcb33c',
      '#6f9c2c',
      '#484ec1',
      '#a0411a',
      '#606060',
      '#b266ff',
      '#357ba8',
    ];
    const colors = color_scheme !== null ? color_scheme : default_color_scheme;

    const m = 10;
    const waffle_ids = new Array(m * m);

    for (let i = 0; i < m; i += 1) {
      for (let j = 0; j < m; j += 1) {
        waffle_ids[i * m + j] = [i, j];
      }
    }

    let percent = 0;
    let begin_id = 0;

    data.forEach((element, index) => {
      percent += element.value;

      const sliced_array = waffle_ids.slice(begin_id, percent);

      regrouped.push({
        name: element.variable,
        data: sliced_array,
        color: colors[index],
        data_point: element.value,
        total: 100,
        showInLegend: true,
        dataLabels: false,
        marker: {
          enabled: true,
          symbol: 'square',
          radius: square_size,
        },
      });

      begin_id = percent;
    });
  }

  return regrouped;
}

//------------------------------------------
// Regroup for waffle
export const regroupForWaffle = (data, square_size) => {
  const regrouped = [];

  const m = 10;
  const waffle_ids = new Array(m * m);

  for (let i = 0; i < m; i += 1) {
    for (let j = 0; j < m; j += 1) {
      waffle_ids[i * m + j] = [i, j];
    }
  }

  for (let i = 0; i < data.numActiveSegments; i += 1) {
    let percent;
    let begin_id;
    let segment_name;
    let sliced_array;
    let y;
    let benchmark;
    let moe;
    let benchmark_label;
    if (data.segmentNames[i] === 'nat-av') {
      segment_name = `National N=${formatNumber(data.nSize[i])}`;
    } else if (data.segmentNames[i] === 'state-av') {
      segment_name = `State N=${formatNumber(data.nSize[i])}`;
    } else if (data.segmentNames[i] === 'default') {
      segment_name = 'All respondents';
    } else {
      segment_name = data.segmentNames[i];
    }

    regrouped[i] = [];
    begin_id = 0;
    percent = 0;

    data.dataSeries[i].forEach((element, index) => {
      if (!element.data && element.y) {
        y = element.y;
      } else if (element.data && !element.y) {
        [y] = element.data;
      } else {
        y = 0;
      }

      if (Array.isArray(element.MOE)) {
        // use array value
        [moe] = element.MOE;
      } else if (element.MOE !== undefined) {
        // use value (null is OK)
        moe = element.MOE;
      } else {
        // use null
        moe = null;
      }

      if (!element.benchmark_array && element.benchmark_value) {
        benchmark = element.benchmark_value;
        benchmark_label = element.benchmark_label;
      } else if (element.benchmark_array && !element.benchmark_value) {
        [benchmark] = element.benchmark_array;
        [benchmark_label] = element.benchmark_labels;
      } else {
        benchmark = null;
        benchmark_label = 'NA';
      }

      percent += y;
      sliced_array = waffle_ids.slice(begin_id, percent);

      regrouped[i].push({
        name: element.name,
        data: sliced_array,
        color: element.color,
        total: 100,
        cat_value: y,
        showInLegend: true,
        dataLabels: false,
        marker: {
          enabled: true,
          symbol: 'square',
          radius: square_size,
        },
        filtered: element.filtered,
        MOE: moe,
        benchmark,
        benchmark_label,
        segment_name,
        series_index: index,
      });

      begin_id = percent;
    });
  }

  return regrouped;
};

//------------------------------------------
// Regroup for radar chart
export const regroupForRadar = (data) => {
  const regrouped = [];

  data.forEach((element) => {
    regrouped.push({
      name: element.name,
      color: element.color,
      data: element.data,
      benchmark_labels: element.benchmark_labels,
      benchmark_array: element.benchmark_array,
      MOE: element.MOE,
      pointPlacement: 'on',
      marker: {
        symbol: 'square',
        width: 20,
        height: 20,
      },
    });
  });

  return regrouped;
};

export const parseMLIData = (
  data,
  queryResult,
  benchmarks,
  current_categories,
  color_scheme = 0,
  chart_type = CHART_TYPES.STACKED_BAR,
  default_segment_enabled = true
) => {
  const parsed_data = {};

  if (data === undefined) {
    console.log('No data to display, empty component rendered!');
  } else {
    // Parse props for the component
    parsed_data.nativeId = queryResult.questionId;
    parsed_data.mliQuestion = data.prompt;
    parsed_data.segmentCount = queryResult.segmentCount;
    parsed_data.filtered = data.filtered;
    parsed_data.numActiveSegments = 1;

    // Parse out data categories
    if (data.answers.length > 0) {
      parsed_data.categories = new Array(data.answers.length);
      parsed_data.answerOptionsType = data.answers[0].answerOptionsType.id;

      data.answers.forEach((el, id) => {
        parsed_data.categories[id] = el.prompt;
      });
    } else {
      parsed_data.categories = [data.prompt];
      parsed_data.answerOptionsType = data.answerOptionsType.id;
    }

    // Offset for data segments is always = 2, because the first segment is
    // a national average (nat_av) and the second segment is a state average (state_av)
    let offset = 2;
    let default_segment_offset = 0;
    let benchmark_id = null;

    if (!default_segment_enabled) {
      default_segment_offset = 1;
    }

    let total_offset = offset + default_segment_offset;

    // Decide whether data is segmented or not
    if (parsed_data.segmentCount === 3 && !benchmarks.state && !benchmarks.national) {
      parsed_data.segmentsActive = false;
      parsed_data.segmentedSeries = null;
    } else if (parsed_data.segmentCount > 3) {
      parsed_data.segmentsActive = true;
      parsed_data.numActiveSegments = parsed_data.segmentCount - total_offset;
    } else if (parsed_data.segmentCount === 3 && (benchmarks.state || benchmarks.national)) {
      parsed_data.segmentsActive = true;
      offset = benchmarks.state && benchmarks.national ? 0 : 1;
      parsed_data.numActiveSegments = parsed_data.segmentCount - offset;
      total_offset = 0;
    } else {
      parsed_data.segmentsActive = null;
      console.log('No segments! Display warning.');
    }

    // Get additional information for sidebar, warnings and menus
    const dataSegments = queryResult.mliDataSegments;

    // Initialize arrays for N-size, segment names and suppressed booleans
    parsed_data.nSize = new Array(parsed_data.numActiveSegments);
    parsed_data.segmentNames = new Array(parsed_data.numActiveSegments);
    parsed_data.suppressed = new Array(parsed_data.numActiveSegments);
    parsed_data.dataSeries = new Array(parsed_data.numActiveSegments);
    parsed_data.segmentsEmpty = true;

    if (dataSegments) {
      // Get N-size, segment names and supressed data information
      for (let i = 0; i < parsed_data.numActiveSegments; i += 1) {
        if (offset === 0 || offset === 2) {
          if (dataSegments[i + total_offset]) {
            parsed_data.segmentsEmpty = false;
            parsed_data.segmentNames[i] =
              dataSegments[i + total_offset].name === 'default'
                ? 'All respondents'
                : dataSegments[i + total_offset].name;
            parsed_data.nSize[i] = dataSegments[i + total_offset].numberOfRespondentsInterval;
            parsed_data.suppressed[i] = dataSegments[i + total_offset].suppressed;

            // For every active segment retrieve data and match it to categories
            parsed_data.dataSeries[i] = matchCategories(
              dataSegments[i + total_offset].mliDataSeries,
              color_scheme,
              parsed_data.filtered,
              parsed_data.segmentsActive,
              current_categories
            );
          } else {
            parsed_data.segmentNames[i] = null;
            parsed_data.suppressed[i] = true;
          }
        } else {
          benchmark_id = benchmarks.national ? 0 : 1;
          parsed_data.segmentsEmpty = false;

          if (i === 0) {
            parsed_data.segmentNames[i] = dataSegments[2].name;
            parsed_data.nSize[i] = dataSegments[2].numberOfRespondentsInterval;
            parsed_data.suppressed[i] = dataSegments[2].suppressed;

            // For every active segment retrieve data and match it to categories
            parsed_data.dataSeries[i] = matchCategories(
              dataSegments[2].mliDataSeries,
              color_scheme,
              parsed_data.filtered,
              parsed_data.segmentsActive,
              current_categories
            );
          } else {
            parsed_data.segmentNames[i] = dataSegments[benchmark_id].name;
            parsed_data.nSize[i] = dataSegments[benchmark_id].numberOfRespondentsInterval;
            parsed_data.suppressed[i] = dataSegments[benchmark_id].suppressed;

            // For every active segment retrieve data and match it to categories
            parsed_data.dataSeries[i] = matchCategories(
              dataSegments[benchmark_id].mliDataSeries,
              color_scheme,
              parsed_data.filtered,
              parsed_data.segmentsActive,
              current_categories
            );
          }
        }
      }
    }

    if (chart_type === CHART_TYPES.HORIZONTAL_BAR) {
      parsed_data.dataSeries = parsed_data.dataSeries.reverse();
      parsed_data.nSize = parsed_data.nSize.reverse();
      parsed_data.segmentNames = parsed_data.segmentNames.reverse();
      parsed_data.suppressed = parsed_data.suppressed.reverse();
    }

    if (
      parsed_data.segmentsActive &&
      (chart_type === CHART_TYPES.STACKED_BAR ||
        chart_type === CHART_TYPES.HEAT_MAP ||
        chart_type === CHART_TYPES.SPLIT_BAR_POS_NEG ||
        chart_type === CHART_TYPES.COMBINED_BAR_POS_NEG)
    ) {
      parsed_data.segmentedSeries = regroupBarForSegments(
        parsed_data.dataSeries,
        parsed_data.categories,
        parsed_data.segmentNames,
        parsed_data.filtered,
        parsed_data.segmentsActive,
        offset !== 2,
        offset === 2 ? null : parsed_data.nSize,
        default_segment_enabled
      );
    }
  }
  return parsed_data;
};

export const makeMLITooltip = (
  series_name,
  value,
  benchmark_diff,
  moe,
  text,
  benchmark_label,
  series_color,
  text_color,
  auto_width,
  showCalculateMoeMessage = true,
  prefix = '',
  suffix = '%'
) => {
  const numFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
  });
  let tooltip_width = '320px';
  if (auto_width) tooltip_width = 'auto';

  let moeMessage = `+/- ${prefix + (moe || 'NA') + suffix} Margin of Error`;
  if (!moe && showCalculateMoeMessage) {
    moeMessage = 'To calculate margin of error, click on the calculator icon in the survey header.';
  }

  if (typeof benchmark_diff === 'string') {
    benchmark_diff.trim();
  }
  const benchmarkMessage = `${prefix}${
    Number.isNaN(Number(benchmark_diff)) ? benchmark_diff : numFormatter.format(benchmark_diff)
  }${suffix} ${text} ${benchmark_label}`;

  const tooltip = `
  <div class="c-tooltip" style="width: ${tooltip_width};">
    <div class="c-tooltip-header" style="border-bottom: 1px solid ${series_color}">
        ${series_name}
    </div>
    <div class="c-tooltip-body">
      <div class="c-tooltip-container c-tooltip-left">
        ${prefix + value + suffix}
      </div>
      <div class="c-tooltip-container c-tooltip-middle">
        ${benchmarkMessage}
      </div>
      <div class="c-tooltip-container c-tooltip-right">
        ${moeMessage}
      </div>
    </div>
   </div>
  `;

  return tooltip;
};

// Function to convert rgb to hex color
export function rgb2hex(rgb) {
  const color = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  if (color && color.length === 4) {
    const colorOne = `0${parseInt(color[1], 10).toString(16)}`.slice(-2);
    const colorTwo = `0${parseInt(color[2], 10).toString(16)}`.slice(-2);
    const colorThree = `0${parseInt(color[3], 10).toString(16)}`.slice(-2);
    return `#${colorOne}${colorTwo}${colorThree}`;
  }
  return '';
}

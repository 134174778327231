export const PA = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8448, 41.7271],
            [-77.7239, 41.7274],
            [-77.7263, 41.8714],
            [-77.6067, 41.872],
            [-77.6055, 41.8083],
            [-77.4933, 41.8105],
            [-77.4578, 41.631],
            [-77.4548, 41.5436],
            [-77.5993, 41.5423],
            [-77.8268, 41.5672],
            [-77.8414, 41.6517],
            [-77.8448, 41.7271],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210530',
        NAME: 'Galeton Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 819380318,
        AWATER: 553472,
        INTPTLAT: '+41.6797245',
        INTPTLON: '-077.6258294',
        ELSDLEA: '',
        UNSDLEA: '10530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.9632, 41.9135],
            [-77.9628, 41.9988],
            [-77.8632, 41.9984],
            [-77.6357, 41.9994],
            [-77.61, 41.9994],
            [-77.6067, 41.872],
            [-77.7263, 41.8714],
            [-77.7239, 41.7274],
            [-77.8448, 41.7271],
            [-77.8759, 41.8164],
            [-77.9632, 41.9135],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217700',
        NAME: 'Northern Potter School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 592340542,
        AWATER: 132922,
        INTPTLAT: '+41.9031335',
        INTPTLON: '-077.7928251',
        ELSDLEA: '',
        UNSDLEA: '17700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9677, 40.7448],
            [-79.8762, 40.7436],
            [-79.8771, 40.6723],
            [-79.9242, 40.673],
            [-80.0554, 40.6741],
            [-79.9677, 40.7448],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214790',
        NAME: 'Mars Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119805789,
        AWATER: 237212,
        INTPTLAT: '+40.7091356',
        INTPTLON: '-079.9780617',
        ELSDLEA: '',
        UNSDLEA: '14790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9818, 41.1718],
            [-79.8226, 41.1731],
            [-79.7861, 41.1015],
            [-79.784, 40.9563],
            [-79.9748, 40.9561],
            [-79.9818, 41.1718],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217100',
        NAME: 'Moniteau School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 389759035,
        AWATER: 782224,
        INTPTLAT: '+41.0641633',
        INTPTLON: '-079.8846468',
        ELSDLEA: '',
        UNSDLEA: '17100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1341, 41.0311],
            [-80.0969, 41.0698],
            [-79.9998, 41.1719],
            [-79.9818, 41.1718],
            [-79.9748, 40.9561],
            [-79.9725, 40.8867],
            [-80.0681, 40.885],
            [-80.16, 40.8869],
            [-80.1625, 40.9378],
            [-80.1341, 41.0311],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221660',
        NAME: 'Slippery Rock Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 348824475,
        AWATER: 12883260,
        INTPTLAT: '+40.9991369',
        INTPTLON: '-080.0557870',
        ELSDLEA: '',
        UNSDLEA: '21660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9701, 40.8164],
            [-79.692, 40.8134],
            [-79.6919, 40.7732],
            [-79.7845, 40.7425],
            [-79.7864, 40.6715],
            [-79.817, 40.6718],
            [-79.8771, 40.6723],
            [-79.8762, 40.7436],
            [-79.9677, 40.7448],
            [-79.9701, 40.8164],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221930',
        NAME: 'South Butler County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247954090,
        AWATER: 132133,
        INTPTLAT: '+40.7572042',
        INTPTLON: '-079.8322363',
        ELSDLEA: '',
        UNSDLEA: '21930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7257, 40.2619],
            [-76.6255, 40.3393],
            [-76.5922, 40.2543],
            [-76.7265, 40.2285],
            [-76.737, 40.2512],
            [-76.7257, 40.2619],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207590',
        NAME: 'Derry Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70454767,
        AWATER: 504890,
        INTPTLAT: '+40.2678293',
        INTPTLON: '-076.6532824',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-76.7493, 40.4442],
              [-76.678, 40.4747],
              [-76.6255, 40.3393],
              [-76.7257, 40.2619],
              [-76.6942, 40.3164],
              [-76.7493, 40.4442],
            ],
          ],
          [
            [
              [-76.7564, 40.1771],
              [-76.7265, 40.2285],
              [-76.5922, 40.2543],
              [-76.5663, 40.1966],
              [-76.7216, 40.1201],
              [-76.7459, 40.1461],
              [-76.7564, 40.1771],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214100',
        NAME: 'Lower Dauphin School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238771801,
        AWATER: 11392112,
        INTPTLAT: '+40.2903038',
        INTPTLON: '-076.6780369',
        ELSDLEA: '',
        UNSDLEA: '14100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7806, 40.4596],
            [-76.5695, 40.5761],
            [-76.6866, 40.5607],
            [-76.7108, 40.5558],
            [-76.732, 40.5516],
            [-76.6397, 40.6198],
            [-76.4568, 40.6314],
            [-76.5354, 40.5552],
            [-76.678, 40.4747],
            [-76.7493, 40.4442],
            [-76.7806, 40.4596],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226400',
        NAME: 'Williams Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157171887,
        AWATER: 2429407,
        INTPTLAT: '+40.5186909',
        INTPTLON: '-076.6445435',
        ELSDLEA: '',
        UNSDLEA: '26400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9221, 40.3027],
            [-76.8369, 40.2673],
            [-76.8732, 40.2377],
            [-76.9139, 40.2719],
            [-76.9221, 40.3027],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211580',
        NAME: 'Harrisburg City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21056175,
        AWATER: 9671283,
        INTPTLAT: '+40.2758906',
        INTPTLON: '-076.8850199',
        ELSDLEA: '',
        UNSDLEA: '11580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5194, 41.5785],
            [-80.4003, 41.5761],
            [-80.3801, 41.4859],
            [-80.3809, 41.4438],
            [-80.5191, 41.4407],
            [-80.5192, 41.4999],
            [-80.5194, 41.5785],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212300',
        NAME: 'Jamestown Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145181889,
        AWATER: 14386592,
        INTPTLAT: '+41.5083718',
        INTPTLON: '-080.4576298',
        ELSDLEA: '',
        UNSDLEA: '12300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7714, 41.7742],
            [-79.6126, 41.775],
            [-79.6129, 41.7053],
            [-79.4941, 41.7061],
            [-79.4935, 41.6247],
            [-79.5589, 41.5065],
            [-79.7466, 41.5273],
            [-79.77, 41.5561],
            [-79.7465, 41.7161],
            [-79.7714, 41.7742],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223490',
        NAME: 'Titusville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 518376298,
        AWATER: 0,
        INTPTLAT: '+41.6391055',
        INTPTLON: '-079.6400239',
        ELSDLEA: '',
        UNSDLEA: '23490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9997, 39.8196],
            [-76.9631, 39.8148],
            [-76.9717, 39.7979],
            [-76.9996, 39.795],
            [-76.9997, 39.8196],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211450',
        NAME: 'Hanover Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9573443,
        AWATER: 0,
        INTPTLAT: '+39.8117556',
        INTPTLON: '-076.9836050',
        ELSDLEA: '',
        UNSDLEA: '11450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7605, 41.3609],
            [-75.7435, 41.3928],
            [-75.742, 41.3487],
            [-75.7605, 41.3609],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218120',
        NAME: 'Old Forge School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8844011,
        AWATER: 0,
        INTPTLAT: '+41.3704794',
        INTPTLON: '-075.7408550',
        ELSDLEA: '',
        UNSDLEA: '18120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4544, 40.3483],
            [-76.4216, 40.3555],
            [-76.4195, 40.3316],
            [-76.4544, 40.3483],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213440',
        NAME: 'Lebanon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11794393,
        AWATER: 16289,
        INTPTLAT: '+40.3413346',
        INTPTLON: '-076.4252314',
        ELSDLEA: '',
        UNSDLEA: '13440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3705, 40.3881],
            [-76.341, 40.4344],
            [-76.3071, 40.4134],
            [-76.1512, 40.316],
            [-76.2113, 40.2927],
            [-76.2839, 40.2644],
            [-76.3365, 40.2513],
            [-76.3705, 40.3881],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4208460',
        NAME: 'Eastern Lebanon County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183788072,
        AWATER: 253519,
        INTPTLAT: '+40.3334436',
        INTPTLON: '-076.2803388',
        ELSDLEA: '',
        UNSDLEA: '08460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2985, 40.6224],
            [-76.2326, 40.6616],
            [-76.1511, 40.6886],
            [-76.0579, 40.6988],
            [-75.9562, 40.7208],
            [-75.8935, 40.6765],
            [-75.9962, 40.63],
            [-76.0192, 40.5738],
            [-76.0336, 40.5772],
            [-76.058, 40.5796],
            [-76.1569, 40.6286],
            [-76.1554, 40.545],
            [-76.2036, 40.5305],
            [-76.2401, 40.5205],
            [-76.2985, 40.6224],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203870',
        NAME: 'Blue Mountain School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 318067649,
        AWATER: 1053893,
        INTPTLAT: '+40.6342927',
        INTPTLON: '-076.1145833',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4568, 40.6314],
            [-76.3941, 40.6651],
            [-76.2985, 40.6224],
            [-76.2401, 40.5205],
            [-76.4402, 40.496],
            [-76.5354, 40.5552],
            [-76.4568, 40.6314],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219140',
        NAME: 'Pine Grove Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 269201147,
        AWATER: 656013,
        INTPTLAT: '+40.5715351',
        INTPTLON: '-076.3898557',
        ELSDLEA: '',
        UNSDLEA: '19140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-76.2439, 40.7882],
              [-76.1943, 40.7972],
              [-76.1809, 40.7816],
              [-76.2239, 40.7721],
              [-76.2304, 40.7774],
              [-76.2439, 40.7882],
            ],
          ],
          [
            [
              [-76.1795, 40.8477],
              [-76.1995, 40.8046],
              [-76.2516, 40.7946],
              [-76.1795, 40.8477],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221490',
        NAME: 'Shenandoah Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30712680,
        AWATER: 371550,
        INTPTLAT: '+40.8034624',
        INTPTLON: '-076.2338304',
        ELSDLEA: '',
        UNSDLEA: '21490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.898, 40.3117],
            [-75.8443, 40.3582],
            [-75.78, 40.3263],
            [-75.8192, 40.2705],
            [-75.8911, 40.297],
            [-75.898, 40.3117],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209480',
        NAME: 'Exeter Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65083628,
        AWATER: 854717,
        INTPTLAT: '+40.3147378',
        INTPTLON: '-075.8354980',
        ELSDLEA: '',
        UNSDLEA: '09480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2036, 40.5305],
            [-76.1554, 40.545],
            [-76.058, 40.5796],
            [-76.0336, 40.5772],
            [-76.0192, 40.5738],
            [-75.9962, 40.63],
            [-75.8746, 40.5258],
            [-75.9482, 40.4776],
            [-76.0724, 40.4835],
            [-76.1972, 40.4683],
            [-76.2036, 40.5305],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211340',
        NAME: 'Hamburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267414466,
        AWATER: 2081655,
        INTPTLAT: '+40.5341163',
        INTPTLON: '-076.0348037',
        ELSDLEA: '',
        UNSDLEA: '11340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.0724, 40.4835],
            [-75.9482, 40.4776],
            [-75.899, 40.4256],
            [-75.9533, 40.3683],
            [-75.9607, 40.3546],
            [-76.051, 40.3895],
            [-76.0724, 40.4835],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220970',
        NAME: 'Schuylkill Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130359769,
        AWATER: 4345829,
        INTPTLAT: '+40.4383498',
        INTPTLON: '-075.9979639',
        ELSDLEA: '',
        UNSDLEA: '20970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5898, 40.4847],
            [-75.5308, 40.4477],
            [-75.4841, 40.4185],
            [-75.4104, 40.3745],
            [-75.4043, 40.3708],
            [-75.4749, 40.3146],
            [-75.5623, 40.412],
            [-75.6279, 40.4206],
            [-75.5898, 40.4847],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224540',
        NAME: 'Upper Perkiomen School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129731347,
        AWATER: 2984847,
        INTPTLAT: '+40.4024189',
        INTPTLON: '-075.5193241',
        ELSDLEA: '',
        UNSDLEA: '24540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9482, 40.4776],
            [-75.8746, 40.5258],
            [-75.7506, 40.4698],
            [-75.7942, 40.4542],
            [-75.8886, 40.4295],
            [-75.899, 40.4256],
            [-75.9482, 40.4776],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209780',
        NAME: 'Fleetwood Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99072480,
        AWATER: 3116543,
        INTPTLAT: '+40.4707774',
        INTPTLON: '-075.8494617',
        ELSDLEA: '',
        UNSDLEA: '09780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.692, 40.8134],
            [-79.6915, 40.8849],
            [-79.581, 40.8939],
            [-79.5702, 40.9856],
            [-79.5513, 40.9805],
            [-79.4938, 40.9215],
            [-79.4148, 40.9385],
            [-79.2153, 40.9125],
            [-79.1022, 40.9102],
            [-79.1011, 40.839],
            [-79.215, 40.8383],
            [-79.2152, 40.7759],
            [-79.2897, 40.6974],
            [-79.3585, 40.6245],
            [-79.495, 40.6458],
            [-79.5647, 40.6726],
            [-79.582, 40.7074],
            [-79.6919, 40.7732],
            [-79.692, 40.8134],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202590',
        NAME: 'Armstrong School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1131647096,
        AWATER: 18736636,
        INTPTLAT: '+40.7960620',
        INTPTLON: '-079.4194435',
        ELSDLEA: '',
        UNSDLEA: '02590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.582, 40.7074],
            [-79.5647, 40.6726],
            [-79.6684, 40.68],
            [-79.582, 40.7074],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213470',
        NAME: 'Leechburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46214781,
        AWATER: 2015855,
        INTPTLAT: '+40.6698360',
        INTPTLON: '-079.6035920',
        ELSDLEA: '',
        UNSDLEA: '13470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7698, 41.7603],
            [-78.6014, 41.7621],
            [-78.6029, 41.88],
            [-78.5326, 41.895],
            [-78.3757, 41.884],
            [-78.3733, 41.7635],
            [-78.2997, 41.6757],
            [-78.2039, 41.6756],
            [-78.2034, 41.6182],
            [-78.4191, 41.6022],
            [-78.4834, 41.6299],
            [-78.7067, 41.6248],
            [-78.7685, 41.6861],
            [-78.7698, 41.7603],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221690',
        NAME: 'Smethport Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 878397714,
        AWATER: 1803444,
        INTPTLAT: '+41.7302747',
        INTPTLON: '-078.4928185',
        ELSDLEA: '',
        UNSDLEA: '21690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9189, 41.9981],
            [-78.6973, 41.9988],
            [-78.5331, 41.9999],
            [-78.5326, 41.895],
            [-78.6029, 41.88],
            [-78.6014, 41.7621],
            [-78.7698, 41.7603],
            [-78.7795, 41.8497],
            [-78.9158, 41.8409],
            [-78.9189, 41.9981],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204020',
        NAME: 'Bradford Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 653905801,
        AWATER: 2700764,
        INTPTLAT: '+41.9184543',
        INTPTLON: '-078.7506074',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8018, 40.2175],
            [-76.8001, 40.2082],
            [-76.8522, 40.235],
            [-76.8397, 40.2434],
            [-76.8225, 40.224],
            [-76.8018, 40.2175],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222800',
        NAME: 'Steelton-Highspire School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6770589,
        AWATER: 56768,
        INTPTLAT: '+40.2180550',
        INTPTLON: '-076.8089400',
        ELSDLEA: '',
        UNSDLEA: '22800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.915, 40.3285],
            [-76.9212, 40.3364],
            [-76.8369, 40.2673],
            [-76.9221, 40.3027],
            [-76.915, 40.3285],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223010',
        NAME: 'Susquehanna Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34548062,
        AWATER: 5007735,
        INTPTLAT: '+40.3132924',
        INTPTLON: '-076.8684550',
        ELSDLEA: '',
        UNSDLEA: '23010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1016, 40.4282],
            [-76.953, 40.4906],
            [-76.9533, 40.4048],
            [-76.9212, 40.3364],
            [-76.915, 40.3285],
            [-76.9769, 40.3099],
            [-77.1329, 40.2951],
            [-77.1016, 40.4282],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223040',
        NAME: 'Susquenita School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228754449,
        AWATER: 8833790,
        INTPTLAT: '+40.3830246',
        INTPTLON: '-077.0316290',
        ELSDLEA: '',
        UNSDLEA: '23040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.953, 40.4906],
            [-76.9844, 40.5146],
            [-76.8701, 40.5299],
            [-76.732, 40.5516],
            [-76.7108, 40.5558],
            [-76.6866, 40.5607],
            [-76.5695, 40.5761],
            [-76.7991, 40.4956],
            [-76.7806, 40.4596],
            [-76.9533, 40.4048],
            [-76.953, 40.4906],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211310',
        NAME: 'Halifax Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212224720,
        AWATER: 10696729,
        INTPTLAT: '+40.4918306',
        INTPTLON: '-076.8325550',
        ELSDLEA: '',
        UNSDLEA: '11310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.113, 40.3489],
            [-77.9616, 40.3138],
            [-77.856, 40.263],
            [-77.7393, 40.3492],
            [-77.703, 40.2636],
            [-77.8645, 40.0616],
            [-77.9259, 40.1019],
            [-78.0784, 40.1332],
            [-78.0292, 40.2616],
            [-78.1239, 40.3425],
            [-78.113, 40.3489],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222320',
        NAME: 'Southern Huntingdon County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 573392043,
        AWATER: 9786,
        INTPTLAT: '+40.2103153',
        INTPTLON: '-077.9111686',
        ELSDLEA: '',
        UNSDLEA: '22320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1892, 40.3955],
            [-78.1296, 40.4906],
            [-77.8626, 40.6232],
            [-77.8943, 40.7162],
            [-77.8245, 40.7437],
            [-77.6996, 40.721],
            [-77.6813, 40.7298],
            [-77.6871, 40.6767],
            [-77.8418, 40.55],
            [-77.8259, 40.4928],
            [-77.9554, 40.4432],
            [-78.113, 40.3489],
            [-78.1239, 40.3425],
            [-78.2431, 40.3225],
            [-78.1892, 40.3955],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212090',
        NAME: 'Huntingdon Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 680611702,
        AWATER: 31262738,
        INTPTLAT: '+40.5160216',
        INTPTLON: '-077.9500957',
        ELSDLEA: '',
        UNSDLEA: '12090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7953, 41.3781],
            [-75.7605, 41.3609],
            [-75.742, 41.3487],
            [-75.6872, 41.3392],
            [-75.6529, 41.2583],
            [-75.8413, 41.2979],
            [-75.7953, 41.3781],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219200',
        NAME: 'Pittston Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100050125,
        AWATER: 1699091,
        INTPTLAT: '+41.3100328',
        INTPTLON: '-075.7386494',
        ELSDLEA: '',
        UNSDLEA: '19200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0724, 40.1575],
            [-75.0671, 40.1414],
            [-75.0477, 40.131],
            [-75.0547, 40.1252],
            [-75.0756, 40.1399],
            [-75.0724, 40.1575],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204090',
        NAME: 'Bryn Athyn School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5001571,
        AWATER: 0,
        INTPTLAT: '+40.1410673',
        INTPTLON: '-075.0674011',
        ELSDLEA: '',
        UNSDLEA: '04090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1686, 40.1106],
            [-75.1382, 40.0941],
            [-75.1317, 40.0893],
            [-75.0967, 40.069],
            [-75.1764, 40.0846],
            [-75.1686, 40.1106],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205760',
        NAME: 'Cheltenham Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23382657,
        AWATER: 4072,
        INTPTLAT: '+40.0792357',
        INTPTLON: '-075.1404991',
        ELSDLEA: '',
        UNSDLEA: '05760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2268, 40.2074],
            [-75.1876, 40.2415],
            [-75.1378, 40.2109],
            [-75.1069, 40.1923],
            [-75.1432, 40.1569],
            [-75.1955, 40.1882],
            [-75.2268, 40.2074],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211610',
        NAME: 'Hatboro-Horsham School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48545315,
        AWATER: 0,
        INTPTLAT: '+40.1976262',
        INTPTLON: '-075.1618315',
        ELSDLEA: '',
        UNSDLEA: '11610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3061, 40.069],
            [-75.2644, 40.0541],
            [-75.2765, 39.977],
            [-75.3207, 40.0162],
            [-75.3495, 40.0529],
            [-75.3061, 40.069],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214160',
        NAME: 'Lower Merion School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62611713,
        AWATER: 415713,
        INTPTLAT: '+40.0299063',
        INTPTLON: '-075.2789689',
        ELSDLEA: '',
        UNSDLEA: '14160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0948, 40.1282],
            [-75.0756, 40.1399],
            [-75.0547, 40.1252],
            [-75.0477, 40.131],
            [-75.0671, 40.1414],
            [-75.0724, 40.1575],
            [-75.0601, 40.1648],
            [-75.0293, 40.1465],
            [-75.0151, 40.138],
            [-75.0583, 40.1059],
            [-75.0948, 40.1282],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214190',
        NAME: 'Lower Moreland Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18857432,
        AWATER: 0,
        INTPTLAT: '+40.1206080',
        INTPTLON: '-075.0748600',
        ELSDLEA: '',
        UNSDLEA: '14190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4101, 40.7649],
            [-76.4115, 40.8335],
            [-76.28, 40.8878],
            [-76.1782, 40.8482],
            [-76.1795, 40.8477],
            [-76.2516, 40.7946],
            [-76.2439, 40.7882],
            [-76.2304, 40.7774],
            [-76.2689, 40.762],
            [-76.3534, 40.7308],
            [-76.4695, 40.7432],
            [-76.4101, 40.7649],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210110',
        NAME: 'North Schuylkill School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185501984,
        AWATER: 1162791,
        INTPTLAT: '+40.8130958',
        INTPTLON: '-076.3102062',
        ELSDLEA: '',
        UNSDLEA: '10110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7016, 40.6581],
            [-76.5049, 40.7303],
            [-76.4695, 40.7432],
            [-76.3534, 40.7308],
            [-76.3941, 40.6651],
            [-76.4568, 40.6314],
            [-76.6397, 40.6198],
            [-76.7016, 40.6581],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223700',
        NAME: 'Tri-Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256721392,
        AWATER: 76096,
        INTPTLAT: '+40.6872492',
        INTPTLON: '-076.4996924',
        ELSDLEA: '',
        UNSDLEA: '23700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6919, 40.7732],
            [-79.582, 40.7074],
            [-79.6684, 40.68],
            [-79.6926, 40.6697],
            [-79.7864, 40.6715],
            [-79.7845, 40.7425],
            [-79.6919, 40.7732],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210440',
        NAME: 'Freeport Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136032430,
        AWATER: 2353812,
        INTPTLAT: '+40.7200839',
        INTPTLON: '-079.6826206',
        ELSDLEA: '',
        UNSDLEA: '10440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9185, 41.3442],
            [-78.7353, 41.2629],
            [-78.6893, 41.2071],
            [-78.7664, 41.2028],
            [-78.8141, 41.1328],
            [-78.9466, 41.1384],
            [-78.8891, 41.2479],
            [-79.0146, 41.2692],
            [-78.9185, 41.3442],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204260',
        NAME: 'Brockway Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 335889156,
        AWATER: 1841464,
        INTPTLAT: '+41.2413120',
        INTPTLON: '-078.8501469',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3534, 40.7308],
            [-76.2689, 40.762],
            [-76.2471, 40.711],
            [-76.2326, 40.6616],
            [-76.2985, 40.6224],
            [-76.3941, 40.6651],
            [-76.3534, 40.7308],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215510',
        NAME: 'Minersville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143198807,
        AWATER: 458618,
        INTPTLAT: '+40.6840988',
        INTPTLON: '-076.3061540',
        ELSDLEA: '',
        UNSDLEA: '15510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2471, 40.711],
            [-76.1511, 40.6886],
            [-76.2326, 40.6616],
            [-76.2471, 40.711],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219710',
        NAME: 'Pottsville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31666745,
        AWATER: 22968,
        INTPTLAT: '+40.6909602',
        INTPTLON: '-076.2067138',
        ELSDLEA: '',
        UNSDLEA: '19710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.966, 41.4768],
            [-74.8902, 41.4382],
            [-74.7369, 41.4294],
            [-74.6949, 41.3574],
            [-74.836, 41.279],
            [-74.8823, 41.1806],
            [-75.0249, 41.2124],
            [-75.045, 41.2899],
            [-74.966, 41.4768],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207530',
        NAME: 'Delaware Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 492628874,
        AWATER: 15325224,
        INTPTLAT: '+41.3291098',
        INTPTLON: '-074.8717870',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9606, 41.2701],
            [-75.9284, 41.2881],
            [-75.8744, 41.3148],
            [-75.8491, 41.2945],
            [-75.9281, 41.241],
            [-75.9514, 41.2347],
            [-75.9606, 41.2701],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225950',
        NAME: 'Wyoming Valley West School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38030277,
        AWATER: 1605647,
        INTPTLAT: '+41.2708918',
        INTPTLON: '-075.9085166',
        ELSDLEA: '',
        UNSDLEA: '25950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.044, 40.2472],
            [-75.9818, 40.313],
            [-75.9564, 40.3179],
            [-75.9101, 40.3227],
            [-75.898, 40.3117],
            [-75.8911, 40.297],
            [-75.9434, 40.1827],
            [-76.044, 40.2472],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210860',
        NAME: 'Governor Mifflin School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105955657,
        AWATER: 986607,
        INTPTLAT: '+40.2570225',
        INTPTLON: '-075.9661323',
        ELSDLEA: '',
        UNSDLEA: '10860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8886, 40.4295],
            [-75.7942, 40.4542],
            [-75.6437, 40.3959],
            [-75.745, 40.3212],
            [-75.78, 40.3263],
            [-75.8443, 40.3582],
            [-75.8986, 40.3669],
            [-75.8886, 40.4295],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218150',
        NAME: 'Oley Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164932336,
        AWATER: 872663,
        INTPTLAT: '+40.3948778',
        INTPTLON: '-075.7807601',
        ELSDLEA: '',
        UNSDLEA: '18150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.16, 40.8869],
            [-80.0681, 40.885],
            [-80.0631, 40.8173],
            [-79.9701, 40.8164],
            [-79.9677, 40.7448],
            [-80.0554, 40.6741],
            [-80.0714, 40.6742],
            [-80.1485, 40.6743],
            [-80.1545, 40.7727],
            [-80.1585, 40.8552],
            [-80.16, 40.8869],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222440',
        NAME: 'Seneca Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246683552,
        AWATER: 282973,
        INTPTLAT: '+40.7815382',
        INTPTLON: '-080.0919702',
        ELSDLEA: '',
        UNSDLEA: '22440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2055, 40.0338],
            [-78.117, 40.0297],
            [-78.1785, 39.9495],
            [-78.0689, 39.9114],
            [-78.089, 39.8104],
            [-78.0305, 39.7979],
            [-78.0994, 39.7221],
            [-78.3428, 39.7223],
            [-78.3806, 39.7226],
            [-78.299, 39.8255],
            [-78.2055, 40.0338],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222290',
        NAME: 'Southern Fulton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 545035318,
        AWATER: 260068,
        INTPTLAT: '+39.8385553',
        INTPTLON: '-078.1899770',
        ELSDLEA: '',
        UNSDLEA: '22290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2067, 40.0864],
            [-78.1342, 40.1649],
            [-78.0784, 40.1332],
            [-77.9259, 40.1019],
            [-77.8645, 40.0616],
            [-77.8893, 40.04],
            [-77.9805, 40.0023],
            [-78.117, 40.0297],
            [-78.2055, 40.0338],
            [-78.2067, 40.0864],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209870',
        NAME: 'Forbes Road School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276947025,
        AWATER: 77968,
        INTPTLAT: '+40.0710638',
        INTPTLON: '-078.0560967',
        ELSDLEA: '',
        UNSDLEA: '09870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4734, 41.9991],
            [-76.473, 41.9991],
            [-76.3815, 41.9985],
            [-76.4147, 41.9138],
            [-76.4734, 41.9991],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220850',
        NAME: 'Sayre Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85651120,
        AWATER: 650773,
        INTPTLAT: '+41.9593254',
        INTPTLON: '-076.4356545',
        ELSDLEA: '',
        UNSDLEA: '20850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6852, 41.5993],
            [-76.5493, 41.6565],
            [-76.4936, 41.6044],
            [-76.3917, 41.6524],
            [-76.2911, 41.7453],
            [-76.3117, 41.8215],
            [-76.1276, 41.8013],
            [-76.089, 41.5974],
            [-76.1872, 41.6045],
            [-76.2201, 41.5413],
            [-76.6944, 41.5799],
            [-76.6852, 41.5993],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226700',
        NAME: 'Wyalusing Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 705248390,
        AWATER: 12654254,
        INTPTLAT: '+41.6462637',
        INTPTLON: '-076.3096185',
        ELSDLEA: '',
        UNSDLEA: '26700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5993, 41.5423],
            [-77.4548, 41.5436],
            [-77.3582, 41.5441],
            [-77.3587, 41.4126],
            [-77.2923, 41.3998],
            [-77.1672, 41.3418],
            [-77.1285, 41.2694],
            [-77.1716, 41.1961],
            [-77.0666, 41.1488],
            [-77.1742, 41.0783],
            [-77.2982, 41.1273],
            [-77.3704, 41.2331],
            [-77.5265, 41.3585],
            [-77.5847, 41.4413],
            [-77.5993, 41.5423],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212480',
        NAME: 'Jersey Shore Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1004617813,
        AWATER: 10491354,
        INTPTLAT: '+41.3145733',
        INTPTLON: '-077.3372846',
        ELSDLEA: '',
        UNSDLEA: '12480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9278, 39.8629],
            [-79.9256, 39.9148],
            [-79.8494, 39.9337],
            [-79.7982, 39.8965],
            [-79.6821, 39.8184],
            [-79.7191, 39.721],
            [-79.7638, 39.7208],
            [-79.9163, 39.7208],
            [-79.9278, 39.8629],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202100',
        NAME: 'Albert Gallatin Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 363888557,
        AWATER: 3007174,
        INTPTLAT: '+39.8168907',
        INTPTLON: '-079.8265042',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.703, 39.9589],
            [-79.585, 39.8874],
            [-79.6821, 39.8184],
            [-79.7982, 39.8965],
            [-79.703, 39.9589],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213320',
        NAME: 'Laurel Highlands School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144475941,
        AWATER: 116579,
        INTPTLAT: '+39.8957992',
        INTPTLON: '-079.6907074',
        ELSDLEA: '',
        UNSDLEA: '13320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6726, 40.0513],
            [-79.622, 40.0466],
            [-79.5355, 40.126],
            [-79.5052, 40.1406],
            [-79.2937, 40.0404],
            [-79.3637, 39.9478],
            [-79.3827, 39.9224],
            [-79.4864, 39.9272],
            [-79.585, 39.8874],
            [-79.703, 39.9589],
            [-79.6726, 40.0513],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206660',
        NAME: 'Connellsville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559194048,
        AWATER: 2988080,
        INTPTLAT: '+40.0067143',
        INTPTLON: '-079.4923463',
        ELSDLEA: '',
        UNSDLEA: '06660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.998, 39.9833],
            [-79.908, 40.0259],
            [-79.8807, 40.0392],
            [-79.8014, 40.0165],
            [-79.8494, 39.9337],
            [-79.9256, 39.9148],
            [-79.9844, 39.952],
            [-79.998, 39.9833],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204080',
        NAME: 'Brownsville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142816068,
        AWATER: 3935459,
        INTPTLAT: '+39.9798781',
        INTPTLON: '-079.8989554',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8538, 40.0781],
            [-79.8429, 40.0836],
            [-79.8425, 40.0839],
            [-79.7444, 40.1282],
            [-79.6594, 40.0973],
            [-79.622, 40.0466],
            [-79.6726, 40.0513],
            [-79.8014, 40.0165],
            [-79.8807, 40.0392],
            [-79.8538, 40.0781],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210350',
        NAME: 'Frazier School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151066758,
        AWATER: 2923475,
        INTPTLAT: '+40.0704802',
        INTPTLON: '-079.7575791',
        ELSDLEA: '',
        UNSDLEA: '10350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9042, 40.152],
            [-79.8707, 40.1621],
            [-79.8706, 40.1974],
            [-79.7818, 40.2277],
            [-79.7444, 40.1282],
            [-79.8425, 40.0839],
            [-79.8501, 40.114],
            [-79.9042, 40.152],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203210',
        NAME: 'Belle Vernon Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110990033,
        AWATER: 2510237,
        INTPTLAT: '+40.1554168',
        INTPTLON: '-079.8129464',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9936, 40.1323],
            [-74.8985, 40.1322],
            [-74.9091, 40.0705],
            [-74.9119, 40.0703],
            [-74.9326, 40.0688],
            [-74.974, 40.0486],
            [-74.9936, 40.1323],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203330',
        NAME: 'Bensalem Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51372224,
        AWATER: 2834243,
        INTPTLAT: '+40.1062228',
        INTPTLON: '-074.9436415',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8985, 40.1322],
            [-74.8688, 40.1658],
            [-74.8156, 40.1277],
            [-74.8287, 40.1203],
            [-74.834, 40.1078],
            [-74.8566, 40.0913],
            [-74.8804, 40.079],
            [-74.8988, 40.0733],
            [-74.9091, 40.0705],
            [-74.8985, 40.1322],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204230',
        NAME: 'Bristol Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41362610,
        AWATER: 3223076,
        INTPTLAT: '+40.1170322',
        INTPTLON: '-074.8728088',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1885, 40.4719],
            [-75.1166, 40.4337],
            [-75.2606, 40.2901],
            [-75.2998, 40.3078],
            [-75.4043, 40.3708],
            [-75.4104, 40.3745],
            [-75.1885, 40.4719],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218750',
        NAME: 'Pennridge School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236318791,
        AWATER: 3176981,
        INTPTLAT: '+40.3826934',
        INTPTLON: '-075.2539401',
        ELSDLEA: '',
        UNSDLEA: '18750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0727, 40.2152],
            [-74.981, 40.3192],
            [-74.9269, 40.3295],
            [-74.8565, 40.2774],
            [-74.8531, 40.2697],
            [-74.8876, 40.2247],
            [-74.9988, 40.1715],
            [-75.0727, 40.2152],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206960',
        NAME: 'Council Rock School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178932292,
        AWATER: 2969427,
        INTPTLAT: '+40.2515602',
        INTPTLON: '-074.9640703',
        ELSDLEA: '',
        UNSDLEA: '06960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4088, 40.4886],
            [-75.3335, 40.5371],
            [-75.2565, 40.5827],
            [-75.2008, 40.6068],
            [-75.1911, 40.5859],
            [-75.1013, 40.568],
            [-75.0872, 40.5563],
            [-75.0668, 40.5365],
            [-75.0654, 40.5176],
            [-75.0607, 40.4213],
            [-75.1166, 40.4337],
            [-75.1885, 40.4719],
            [-75.2312, 40.5171],
            [-75.4088, 40.4886],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218330',
        NAME: 'Palisades School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256865024,
        AWATER: 4585699,
        INTPTLAT: '+40.5187012',
        INTPTLON: '-075.1898573',
        ELSDLEA: '',
        UNSDLEA: '18330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4749, 40.3146],
            [-75.4043, 40.3708],
            [-75.2998, 40.3078],
            [-75.3858, 40.2264],
            [-75.4719, 40.3144],
            [-75.4749, 40.3146],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221870',
        NAME: 'Souderton Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127574012,
        AWATER: 382899,
        INTPTLAT: '+40.3009454',
        INTPTLON: '-075.3882457',
        ELSDLEA: '',
        UNSDLEA: '21870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8986, 40.3669],
            [-75.8443, 40.3582],
            [-75.898, 40.3117],
            [-75.9101, 40.3227],
            [-75.8986, 40.3669],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202480',
        NAME: 'Antietam School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13387838,
        AWATER: 110036,
        INTPTLAT: '+40.3490568',
        INTPTLON: '-075.8874372',
        ELSDLEA: '',
        UNSDLEA: '02480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2897, 40.6974],
            [-79.0891, 40.6468],
            [-79.0886, 40.5909],
            [-79.1275, 40.569],
            [-79.2767, 40.5591],
            [-79.3585, 40.6245],
            [-79.2897, 40.6974],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212150',
        NAME: 'Indiana Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211888285,
        AWATER: 1497758,
        INTPTLAT: '+40.6301671',
        INTPTLON: '-079.2063468',
        ELSDLEA: '',
        UNSDLEA: '12150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1011, 40.839],
            [-78.9033, 40.8159],
            [-78.9029, 40.7367],
            [-79.0188, 40.7095],
            [-79.0891, 40.6468],
            [-79.2897, 40.6974],
            [-79.2152, 40.7759],
            [-79.215, 40.8383],
            [-79.1011, 40.839],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214730',
        NAME: 'Marion Center Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 497501967,
        AWATER: 1359316,
        INTPTLAT: '+40.7685388',
        INTPTLON: '-079.0871597',
        ELSDLEA: '',
        UNSDLEA: '14730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.15, 40.4796],
            [-79.1275, 40.569],
            [-79.0886, 40.5909],
            [-78.8969, 40.5332],
            [-78.9242, 40.4811],
            [-78.9746, 40.396],
            [-79.1022, 40.3689],
            [-79.1619, 40.4108],
            [-79.15, 40.4796],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224240',
        NAME: 'United School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 337779940,
        AWATER: 4930983,
        INTPTLAT: '+40.4914243',
        INTPTLON: '-079.0360096',
        ELSDLEA: '',
        UNSDLEA: '24240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4502, 40.5301],
            [-79.2996, 40.5046],
            [-79.2767, 40.5591],
            [-79.15, 40.4796],
            [-79.1619, 40.4108],
            [-79.1927, 40.4149],
            [-79.3024, 40.4613],
            [-79.4446, 40.4157],
            [-79.5295, 40.4731],
            [-79.4502, 40.5301],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203750',
        NAME: 'Blairsville-Saltsburg School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279725586,
        AWATER: 7376571,
        INTPTLAT: '+40.4806622',
        INTPTLON: '-079.3280705',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2105, 41.0405],
            [-78.9448, 41.071],
            [-78.8042, 41.0402],
            [-78.804, 40.9939],
            [-78.8064, 40.845],
            [-78.8065, 40.8173],
            [-78.9033, 40.8159],
            [-79.1011, 40.839],
            [-79.1022, 40.9102],
            [-79.2153, 40.9125],
            [-79.2105, 41.0405],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219800',
        NAME: 'Punxsutawney Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 706250000,
        AWATER: 3489988,
        INTPTLAT: '+40.9603495',
        INTPTLON: '-078.9986610',
        ELSDLEA: '',
        UNSDLEA: '19800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.0767, 41.25],
            [-75.9606, 41.2701],
            [-75.9514, 41.2347],
            [-75.9869, 41.1667],
            [-76.0634, 41.1088],
            [-76.1461, 41.1433],
            [-76.0413, 41.2111],
            [-76.0767, 41.25],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216290',
        NAME: 'Greater Nanticoke Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 135047882,
        AWATER: 5156745,
        INTPTLAT: '+41.1907090',
        INTPTLON: '-076.0371043',
        ELSDLEA: '',
        UNSDLEA: '16290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3459, 41.6726],
            [-75.3002, 41.4842],
            [-75.2983, 41.3674],
            [-75.3906, 41.2956],
            [-75.4415, 41.2619],
            [-75.4564, 41.5336],
            [-75.4593, 41.5881],
            [-75.4611, 41.6164],
            [-75.3459, 41.6726],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226070',
        NAME: 'Western Wayne School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 421445343,
        AWATER: 16071349,
        INTPTLAT: '+41.4793305',
        INTPTLON: '-075.3820230',
        ELSDLEA: '',
        UNSDLEA: '26070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2113, 40.2927],
            [-76.1512, 40.316],
            [-76.0918, 40.2777],
            [-76.0441, 40.2472],
            [-76.094, 40.1663],
            [-76.2035, 40.2412],
            [-76.2113, 40.2927],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206270',
        NAME: 'Cocalico School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130442177,
        AWATER: 1243875,
        INTPTLAT: '+40.2508112',
        INTPTLON: '-076.1423788',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.633, 40.0574],
            [-76.5218, 40.1708],
            [-76.5151, 40.0638],
            [-76.5339, 40.0508],
            [-76.633, 40.0574],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207650',
        NAME: 'Donegal School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86278414,
        AWATER: 6228986,
        INTPTLAT: '+40.0963920',
        INTPTLON: '-076.5469210',
        ELSDLEA: '',
        UNSDLEA: '07650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5339, 40.0508],
            [-76.5151, 40.0638],
            [-76.3668, 40.1243],
            [-76.3421, 40.0531],
            [-76.3429, 40.0381],
            [-76.4771, 40.0216],
            [-76.4823, 40.0444],
            [-76.5068, 40.0492],
            [-76.5077, 40.0294],
            [-76.489, 40.0207],
            [-76.5122, 40.0134],
            [-76.5339, 40.0508],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211790',
        NAME: 'Hempfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107737326,
        AWATER: 6914591,
        INTPTLAT: '+40.0698067',
        INTPTLON: '-076.4204154',
        ELSDLEA: '',
        UNSDLEA: '11790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5218, 40.1708],
            [-76.4693, 40.2274],
            [-76.4663, 40.2284],
            [-76.3505, 40.2497],
            [-76.336, 40.1271],
            [-76.3668, 40.1243],
            [-76.5151, 40.0638],
            [-76.5218, 40.1708],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214550',
        NAME: 'Manheim Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203029637,
        AWATER: 1291296,
        INTPTLAT: '+40.1687241',
        INTPTLON: '-076.4356560',
        ELSDLEA: '',
        UNSDLEA: '14550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5122, 40.0134],
            [-76.489, 40.0207],
            [-76.4771, 40.0216],
            [-76.3429, 40.0381],
            [-76.3047, 40.0016],
            [-76.2521, 39.9479],
            [-76.2557, 39.8676],
            [-76.3124, 39.8054],
            [-76.3917, 39.9128],
            [-76.4777, 39.9498],
            [-76.5122, 40.0134],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218630',
        NAME: 'Penn Manor School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252159016,
        AWATER: 41439066,
        INTPTLAT: '+39.9414600',
        INTPTLON: '-076.3713593',
        ELSDLEA: '',
        UNSDLEA: '18630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3101, 41.3102],
            [-76.2831, 41.3765],
            [-76.2527, 41.3774],
            [-76.21, 41.2141],
            [-76.0767, 41.25],
            [-76.0413, 41.2111],
            [-76.1461, 41.1433],
            [-76.229, 41.1385],
            [-76.3192, 41.2081],
            [-76.3101, 41.3102],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217790',
        NAME: 'Northwest Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305673245,
        AWATER: 3643818,
        INTPTLAT: '+41.1921772',
        INTPTLON: '-076.2568078',
        ELSDLEA: '',
        UNSDLEA: '17790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8491, 41.2945],
            [-75.8413, 41.2979],
            [-75.6529, 41.2583],
            [-75.6007, 41.1615],
            [-75.6496, 41.1223],
            [-75.7673, 41.0931],
            [-75.7699, 41.1551],
            [-75.8495, 41.189],
            [-75.9281, 41.241],
            [-75.8491, 41.2945],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226300',
        NAME: 'Wilkes-Barre Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296430406,
        AWATER: 5734925,
        INTPTLAT: '+41.2018853',
        INTPTLON: '-075.7571201',
        ELSDLEA: '',
        UNSDLEA: '26300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8787, 41.4151],
            [-75.8347, 41.4272],
            [-75.7953, 41.3781],
            [-75.8413, 41.2979],
            [-75.8491, 41.2945],
            [-75.8744, 41.3148],
            [-75.8787, 41.4151],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226730',
        NAME: 'Wyoming Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69099422,
        AWATER: 3516980,
        INTPTLAT: '+41.3708219',
        INTPTLON: '-075.8404222',
        ELSDLEA: '',
        UNSDLEA: '26730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1297, 41.2521],
            [-75.045, 41.2899],
            [-75.0249, 41.2124],
            [-74.8823, 41.1806],
            [-74.9669, 41.0945],
            [-75.1315, 40.9893],
            [-75.139, 40.9544],
            [-75.1604, 40.9485],
            [-75.2235, 41.0734],
            [-75.2495, 41.1439],
            [-75.1297, 41.2521],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4208670',
        NAME: 'East Stroudsburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 547541002,
        AWATER: 14070162,
        INTPTLAT: '+41.1354986',
        INTPTLON: '-075.0942712',
        ELSDLEA: '',
        UNSDLEA: '08670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6007, 41.1615],
            [-75.5398, 41.2255],
            [-75.3722, 41.238],
            [-75.1297, 41.2521],
            [-75.2495, 41.1439],
            [-75.2235, 41.0734],
            [-75.3554, 40.9425],
            [-75.4161, 41.0221],
            [-75.4974, 40.9884],
            [-75.5897, 41.077],
            [-75.6496, 41.1223],
            [-75.6007, 41.1615],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219500',
        NAME: 'Pocono Mountain School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 817872540,
        AWATER: 14949798,
        INTPTLAT: '+41.1240259',
        INTPTLON: '-075.3926773',
        ELSDLEA: '',
        UNSDLEA: '19500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.519, 40.9874],
            [-80.5191, 41.0328],
            [-80.519, 41.0615],
            [-80.3891, 41.0627],
            [-80.4009, 41.0397],
            [-80.3971, 40.9858],
            [-80.3545, 40.9075],
            [-80.3224, 40.8532],
            [-80.3226, 40.8532],
            [-80.4085, 40.8524],
            [-80.519, 40.8513],
            [-80.5191, 40.9003],
            [-80.519, 40.9874],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215540',
        NAME: 'Mohawk Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262278595,
        AWATER: 4462855,
        INTPTLAT: '+40.9409082',
        INTPTLON: '-080.4454977',
        ELSDLEA: '',
        UNSDLEA: '15540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.4009, 41.0397],
            [-80.3587, 41.0147],
            [-80.3971, 40.9858],
            [-80.4009, 41.0397],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224060',
        NAME: 'Union Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30422875,
        AWATER: 534057,
        INTPTLAT: '+41.0110168',
        INTPTLON: '-080.4058510',
        ELSDLEA: '',
        UNSDLEA: '24060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8969, 40.5332],
            [-78.8751, 40.5753],
            [-78.7998, 40.5447],
            [-78.7911, 40.459],
            [-78.9242, 40.4811],
            [-78.8969, 40.5332],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203690',
        NAME: 'Blacklick Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86689686,
        AWATER: 169316,
        INTPTLAT: '+40.5059965',
        INTPTLON: '-078.8358225',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9745, 40.3949],
            [-78.8954, 40.3935],
            [-78.9032, 40.3603],
            [-78.859, 40.3124],
            [-78.904, 40.2751],
            [-78.9279, 40.2842],
            [-78.9245, 40.2866],
            [-78.927, 40.2931],
            [-78.9324, 40.3327],
            [-78.9372, 40.3284],
            [-79.0339, 40.3165],
            [-78.9745, 40.3949],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210950',
        NAME: 'Greater Johnstown School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74223598,
        AWATER: 1232816,
        INTPTLAT: '+40.3422418',
        INTPTLON: '-078.9475814',
        ELSDLEA: '',
        UNSDLEA: '10950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8467, 40.6356],
            [-78.8081, 40.7206],
            [-78.7127, 40.7244],
            [-78.7126, 40.6369],
            [-78.7633, 40.5715],
            [-78.7998, 40.5447],
            [-78.8751, 40.5753],
            [-78.8467, 40.6356],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217610',
        NAME: 'Northern Cambria School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161563036,
        AWATER: 143663,
        INTPTLAT: '+40.6324824',
        INTPTLON: '-078.7881406',
        ELSDLEA: '',
        UNSDLEA: '17610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8026, 40.2443],
            [-78.6584, 40.2429],
            [-78.7063, 40.1448],
            [-78.8548, 40.1771],
            [-78.8839, 40.2447],
            [-78.8026, 40.2443],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226610',
        NAME: 'Windber Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149008183,
        AWATER: 172091,
        INTPTLAT: '+40.2029364',
        INTPTLON: '-078.7526689',
        ELSDLEA: '',
        UNSDLEA: '26610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4421, 41.1925],
            [-79.4394, 41.2896],
            [-79.2713, 41.2902],
            [-79.3675, 41.1963],
            [-79.3644, 41.1068],
            [-79.4514, 41.081],
            [-79.4421, 41.1925],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206060',
        NAME: 'Clarion Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177704808,
        AWATER: 2910390,
        INTPTLAT: '+41.2082796',
        INTPTLON: '-079.3868180',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6046, 41.2913],
            [-79.4781, 41.3357],
            [-79.4394, 41.2896],
            [-79.4421, 41.1925],
            [-79.5712, 41.0949],
            [-79.6504, 41.2537],
            [-79.6046, 41.2913],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212720',
        NAME: 'Keystone School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315883958,
        AWATER: 3121623,
        INTPTLAT: '+41.2298488',
        INTPTLON: '-079.5376347',
        ELSDLEA: '',
        UNSDLEA: '12720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4781, 41.3357],
            [-79.4778, 41.3865],
            [-79.4003, 41.4361],
            [-79.2072, 41.4309],
            [-79.2089, 41.3319],
            [-79.209, 41.317],
            [-79.2713, 41.2902],
            [-79.4394, 41.2896],
            [-79.4781, 41.3357],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217130',
        NAME: 'North Clarion County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 289935245,
        AWATER: 902867,
        INTPTLAT: '+41.3584643',
        INTPTLON: '-079.3332131',
        ELSDLEA: '',
        UNSDLEA: '17130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6279, 41.0359],
            [-79.5712, 41.0949],
            [-79.4421, 41.1925],
            [-79.4514, 41.081],
            [-79.4559, 40.9882],
            [-79.5513, 40.9805],
            [-79.5702, 40.9856],
            [-79.6279, 41.0359],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224120',
        NAME: 'Union School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194918691,
        AWATER: 4196784,
        INTPTLAT: '+41.0644354',
        INTPTLON: '-079.5163738',
        ELSDLEA: '',
        UNSDLEA: '24120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3182, 41.0786],
            [-80.1341, 41.0311],
            [-80.1625, 40.9378],
            [-80.264, 40.909],
            [-80.3174, 40.9982],
            [-80.3187, 41.02],
            [-80.3182, 41.0786],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213380',
        NAME: 'Laurel School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170281495,
        AWATER: 1385420,
        INTPTLAT: '+40.9956835',
        INTPTLON: '-080.2311460',
        ELSDLEA: '',
        UNSDLEA: '13380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4847, 41.2439],
            [-78.2368, 41.2305],
            [-78.1497, 41.2223],
            [-78.1396, 41.0596],
            [-78.259, 41.05],
            [-78.2831, 40.9693],
            [-78.3945, 40.9825],
            [-78.437, 40.9128],
            [-78.5513, 40.876],
            [-78.4832, 40.9237],
            [-78.496, 41.0528],
            [-78.5835, 41.0528],
            [-78.5794, 41.1401],
            [-78.4852, 41.148],
            [-78.4847, 41.2439],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206150',
        NAME: 'Clearfield Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 894443587,
        AWATER: 5951526,
        INTPTLAT: '+41.1290981',
        INTPTLON: '-078.3738591',
        ELSDLEA: '',
        UNSDLEA: '06150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7075, 40.9932],
            [-78.5944, 41.0022],
            [-78.5835, 41.0528],
            [-78.496, 41.0528],
            [-78.4832, 40.9237],
            [-78.5513, 40.876],
            [-78.6277, 40.8276],
            [-78.6753, 40.8311],
            [-78.7075, 40.9932],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207140',
        NAME: 'Curwensville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297810524,
        AWATER: 4203928,
        INTPTLAT: '+40.9451434',
        INTPTLON: '-078.5949614',
        ELSDLEA: '',
        UNSDLEA: '07140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.5513, 40.876],
            [-78.437, 40.9128],
            [-78.3015, 40.8229],
            [-78.3599, 40.7326],
            [-78.3504, 40.7248],
            [-78.4313, 40.7255],
            [-78.4314, 40.7918],
            [-78.5659, 40.7856],
            [-78.6277, 40.8276],
            [-78.5513, 40.876],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215960',
        NAME: 'Moshannon Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 243808955,
        AWATER: 1825348,
        INTPTLAT: '+40.8152587',
        INTPTLON: '-078.4574146',
        ELSDLEA: '',
        UNSDLEA: '15960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4719, 40.3144],
            [-75.3858, 40.2264],
            [-75.3695, 40.2169],
            [-75.4513, 40.1733],
            [-75.5191, 40.2713],
            [-75.4719, 40.3144],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218930',
        NAME: 'Perkiomen Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79632952,
        AWATER: 960452,
        INTPTLAT: '+40.2365510',
        INTPTLON: '-075.4469270',
        ELSDLEA: '',
        UNSDLEA: '18930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4617, 40.103],
            [-75.4243, 40.1079],
            [-75.3269, 40.1052],
            [-75.3061, 40.069],
            [-75.3495, 40.0529],
            [-75.3611, 40.0655],
            [-75.4566, 40.0939],
            [-75.4617, 40.103],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224480',
        NAME: 'Upper Merion Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47845583,
        AWATER: 1062484,
        INTPTLAT: '+40.0879692',
        INTPTLON: '-075.3704156',
        ELSDLEA: '',
        UNSDLEA: '24480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5938, 40.351],
            [-77.5401, 40.3992],
            [-77.3381, 40.4953],
            [-77.2872, 40.4554],
            [-77.1527, 40.4597],
            [-77.1016, 40.4282],
            [-77.1329, 40.2951],
            [-77.1855, 40.2877],
            [-77.27, 40.2787],
            [-77.4266, 40.2866],
            [-77.5696, 40.2121],
            [-77.6147, 40.1985],
            [-77.646, 40.2827],
            [-77.5938, 40.351],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225740',
        NAME: 'West Perry School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 810648645,
        AWATER: 3255016,
        INTPTLAT: '+40.3473422',
        INTPTLON: '-077.3850289',
        ELSDLEA: '',
        UNSDLEA: '25740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5191, 40.2817],
            [-80.4265, 40.3461],
            [-80.3565, 40.3086],
            [-80.3322, 40.2382],
            [-80.3382, 40.2099],
            [-80.5189, 40.1959],
            [-80.5191, 40.2817],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202760',
        NAME: 'Avella Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192441952,
        AWATER: 35972,
        INTPTLAT: '+40.2496523',
        INTPTLON: '-080.4010323',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.518, 40.3996],
            [-80.519, 40.4774],
            [-80.3619, 40.4776],
            [-80.3086, 40.3854],
            [-80.3565, 40.3086],
            [-80.4265, 40.3461],
            [-80.5191, 40.2817],
            [-80.518, 40.3996],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204500',
        NAME: 'Burgettstown Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 272682183,
        AWATER: 151109,
        INTPTLAT: '+40.3885654',
        INTPTLON: '-080.4415266',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0203, 40.1918],
            [-79.9003, 40.1625],
            [-79.9042, 40.152],
            [-79.8501, 40.114],
            [-79.9891, 40.1121],
            [-80.0203, 40.1918],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205640',
        NAME: 'Charleroi School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66460176,
        AWATER: 1044899,
        INTPTLAT: '+40.1399296',
        INTPTLON: '-079.9456470',
        ELSDLEA: '',
        UNSDLEA: '05640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1162, 40.3123],
            [-80.074, 40.2998],
            [-80.0337, 40.288],
            [-80.0867, 40.2524],
            [-80.1162, 40.3123],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218960',
        NAME: 'Peters Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50625763,
        AWATER: 458202,
        INTPTLAT: '+40.2729319',
        INTPTLON: '-080.0816286',
        ELSDLEA: '',
        UNSDLEA: '18960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3113, 40.25],
            [-80.2401, 40.3082],
            [-80.2145, 40.2235],
            [-80.3113, 40.25],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205730',
        NAME: 'Chartiers-Houston School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64813512,
        AWATER: 0,
        INTPTLAT: '+40.2504289',
        INTPTLON: '-080.2488387',
        ELSDLEA: '',
        UNSDLEA: '05730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0867, 40.2524],
            [-80.0337, 40.288],
            [-80.0331, 40.2878],
            [-79.9691, 40.2687],
            [-79.9141, 40.2525],
            [-79.8706, 40.1974],
            [-79.8707, 40.1621],
            [-79.9003, 40.1625],
            [-80.0203, 40.1918],
            [-80.1086, 40.1923],
            [-80.0867, 40.2524],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220400',
        NAME: 'Ringgold School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140236231,
        AWATER: 2959573,
        INTPTLAT: '+40.2204902',
        INTPTLON: '-079.9976389',
        ELSDLEA: '',
        UNSDLEA: '20400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0666, 41.1488],
            [-76.8938, 41.2305],
            [-76.8499, 41.2294],
            [-76.881, 41.158],
            [-76.9779, 41.0879],
            [-77.0581, 41.0856],
            [-77.1441, 41.0688],
            [-77.1742, 41.0783],
            [-77.0666, 41.1488],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215660',
        NAME: 'Montgomery Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 220226732,
        AWATER: 3213239,
        INTPTLAT: '+41.1545615',
        INTPTLON: '-076.9789772',
        ELSDLEA: '',
        UNSDLEA: '15660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.28, 40.8878],
            [-76.273, 40.8942],
            [-76.2217, 41.0045],
            [-76.0273, 41.0551],
            [-75.9276, 41.0885],
            [-75.7701, 41.0501],
            [-75.7319, 41.0082],
            [-75.9579, 40.8787],
            [-76.0071, 40.8644],
            [-76.1782, 40.8482],
            [-76.28, 40.8878],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211700',
        NAME: 'Hazleton Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 649158979,
        AWATER: 2951157,
        INTPTLAT: '+40.9669496',
        INTPTLON: '-076.0147574',
        ELSDLEA: '',
        UNSDLEA: '11700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7618, 40.8837],
            [-75.7257, 40.981],
            [-75.5897, 41.077],
            [-75.4974, 40.9884],
            [-75.5564, 40.9287],
            [-75.633, 40.8946],
            [-75.7994, 40.8245],
            [-75.7618, 40.8837],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212540',
        NAME: 'Jim Thorpe Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297063067,
        AWATER: 4929437,
        INTPTLAT: '+40.9682923',
        INTPTLON: '-075.6433019',
        ELSDLEA: '',
        UNSDLEA: '12540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7701, 41.0501],
            [-75.7703, 41.0549],
            [-75.7714, 41.0612],
            [-75.7673, 41.0931],
            [-75.6496, 41.1223],
            [-75.5897, 41.077],
            [-75.7257, 40.981],
            [-75.7618, 40.8837],
            [-75.9264, 40.8517],
            [-75.9579, 40.8787],
            [-75.7319, 41.0082],
            [-75.7701, 41.0501],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225140',
        NAME: 'Weatherly Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277003977,
        AWATER: 1272546,
        INTPTLAT: '+40.9836161',
        INTPTLON: '-075.7743762',
        ELSDLEA: '',
        UNSDLEA: '25140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.817, 40.6718],
            [-79.7864, 40.6715],
            [-79.6926, 40.6697],
            [-79.7128, 40.6199],
            [-79.7492, 40.599],
            [-79.764, 40.5927],
            [-79.817, 40.6718],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211880',
        NAME: 'Highlands School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57091082,
        AWATER: 1906491,
        INTPTLAT: '+40.6384705',
        INTPTLON: '-079.7427060',
        ELSDLEA: '',
        UNSDLEA: '11880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8479, 40.3569],
            [-79.8362, 40.365],
            [-79.7744, 40.3597],
            [-79.7879, 40.2977],
            [-79.8376, 40.3147],
            [-79.8919, 40.339],
            [-79.8479, 40.3569],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214940',
        NAME: 'McKeesport Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35937681,
        AWATER: 1689791,
        INTPTLAT: '+40.3393987',
        INTPTLON: '-079.8234713',
        ELSDLEA: '',
        UNSDLEA: '14940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0512, 40.4019],
            [-80.0449, 40.3991],
            [-80.0287, 40.3913],
            [-80.022, 40.3885],
            [-80.0212, 40.3766],
            [-80.0334, 40.3551],
            [-80.0512, 40.351],
            [-80.0727, 40.3638],
            [-80.0512, 40.4019],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216110',
        NAME: 'Mt. Lebanon School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15742024,
        AWATER: 6712,
        INTPTLAT: '+40.3766961',
        INTPTLON: '-080.0489425',
        ELSDLEA: '',
        UNSDLEA: '16110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8956, 40.435],
            [-79.8542, 40.4528],
            [-79.8026, 40.4386],
            [-79.815, 40.4012],
            [-79.8535, 40.3883],
            [-79.8545, 40.3888],
            [-79.8844, 40.4073],
            [-79.8995, 40.4159],
            [-79.8956, 40.435],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216500',
        NAME: 'Woodland Hills School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34141421,
        AWATER: 674863,
        INTPTLAT: '+40.4203035',
        INTPTLON: '-079.8494546',
        ELSDLEA: '',
        UNSDLEA: '16500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1716, 41.1961],
            [-76.9819, 41.2447],
            [-76.9434, 41.2345],
            [-76.8938, 41.2305],
            [-77.0666, 41.1488],
            [-77.1716, 41.1961],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222140',
        NAME: 'South Williamsport Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87412755,
        AWATER: 4599285,
        INTPTLAT: '+41.2008675',
        INTPTLON: '-077.0585728',
        ELSDLEA: '',
        UNSDLEA: '22140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6983, 41.3731],
            [-76.5925, 41.3043],
            [-76.4476, 41.2756],
            [-76.4608, 41.2648],
            [-76.5926, 41.1578],
            [-76.6408, 41.1557],
            [-76.6946, 41.1596],
            [-76.6943, 41.1948],
            [-76.8246, 41.282],
            [-76.6983, 41.3731],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4208490',
        NAME: 'East Lycoming School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378954516,
        AWATER: 2312797,
        INTPTLAT: '+41.2567954',
        INTPTLON: '-076.6501523',
        ELSDLEA: '',
        UNSDLEA: '08490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8499, 41.2294],
            [-76.8246, 41.282],
            [-76.6943, 41.1948],
            [-76.6946, 41.1596],
            [-76.881, 41.158],
            [-76.8499, 41.2294],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216230',
        NAME: 'Muncy School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93883157,
        AWATER: 2369486,
        INTPTLAT: '+41.2168546',
        INTPTLON: '-076.7718322',
        ELSDLEA: '',
        UNSDLEA: '16230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9965, 41.2973],
            [-76.9434, 41.2345],
            [-76.9819, 41.2447],
            [-76.9965, 41.2973],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214310',
        NAME: 'Loyalsock Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54780941,
        AWATER: 891783,
        INTPTLAT: '+41.2680920',
        INTPTLON: '-076.9824516',
        ELSDLEA: '',
        UNSDLEA: '14310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-79.6821, 39.8184],
              [-79.585, 39.8874],
              [-79.4864, 39.9272],
              [-79.3827, 39.9224],
              [-79.3657, 39.8205],
              [-79.3925, 39.7216],
              [-79.4767, 39.7211],
              [-79.7191, 39.721],
              [-79.6821, 39.8184],
            ],
          ],
          [
            [
              [-79.8014, 40.0165],
              [-79.6726, 40.0513],
              [-79.703, 39.9589],
              [-79.7982, 39.8965],
              [-79.8494, 39.9337],
              [-79.8014, 40.0165],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224150',
        NAME: 'Uniontown Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 639499893,
        AWATER: 7078844,
        INTPTLAT: '+39.8363035',
        INTPTLON: '-079.5818106',
        ELSDLEA: '',
        UNSDLEA: '24150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.27, 40.2787],
            [-77.1855, 40.2877],
            [-77.1644, 40.1995],
            [-77.2426, 40.1854],
            [-77.1781, 40.0368],
            [-77.2289, 40.0251],
            [-77.2552, 40.0208],
            [-77.3168, 40.161],
            [-77.244, 40.1901],
            [-77.27, 40.2787],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205010',
        NAME: 'Carlisle Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196572223,
        AWATER: 1705078,
        INTPTLAT: '+40.1577301',
        INTPTLON: '-077.2393201',
        ELSDLEA: '',
        UNSDLEA: '05010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1855, 40.2877],
            [-77.1329, 40.2951],
            [-76.9769, 40.3099],
            [-76.9428, 40.2429],
            [-76.9376, 40.2323],
            [-76.9472, 40.2246],
            [-76.965, 40.2209],
            [-76.9844, 40.2174],
            [-77.0126, 40.1484],
            [-77.0804, 40.111],
            [-77.1644, 40.1995],
            [-77.1855, 40.2877],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207110',
        NAME: 'Cumberland Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262464958,
        AWATER: 3922258,
        INTPTLAT: '+40.2280969',
        INTPTLON: '-077.0678264',
        ELSDLEA: '',
        UNSDLEA: '07110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9844, 40.2174],
            [-76.965, 40.2209],
            [-76.9472, 40.2246],
            [-76.9376, 40.2323],
            [-76.9167, 40.2504],
            [-76.9139, 40.2719],
            [-76.8732, 40.2377],
            [-76.8061, 40.2006],
            [-76.7564, 40.1771],
            [-76.7459, 40.1461],
            [-76.8476, 40.094],
            [-76.9221, 40.1623],
            [-76.9844, 40.2174],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225830',
        NAME: 'West Shore School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191293978,
        AWATER: 1270906,
        INTPTLAT: '+40.1733786',
        INTPTLON: '-076.8634714',
        ELSDLEA: '',
        UNSDLEA: '25830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8225, 40.0821],
            [-75.7795, 40.1159],
            [-75.6638, 40.0824],
            [-75.6063, 40.1082],
            [-75.5812, 40.094],
            [-75.6133, 40.0505],
            [-75.6737, 40.0319],
            [-75.6679, 39.9344],
            [-75.7758, 39.9504],
            [-75.8225, 40.0821],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207710',
        NAME: 'Downingtown Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203419216,
        AWATER: 3828139,
        INTPTLAT: '+40.0365134',
        INTPTLON: '-075.7086265',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5812, 40.094],
            [-75.4964, 40.0836],
            [-75.4488, 39.9969],
            [-75.4361, 39.9849],
            [-75.5021, 39.9544],
            [-75.6133, 40.0505],
            [-75.5812, 40.094],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210870',
        NAME: 'Great Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110733995,
        AWATER: 798979,
        INTPTLAT: '+40.0398463',
        INTPTLON: '-075.5262773',
        ELSDLEA: '',
        UNSDLEA: '10870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8079, 40.1777],
            [-75.6968, 40.2419],
            [-75.6761, 40.2426],
            [-75.6278, 40.2328],
            [-75.5927, 40.2255],
            [-75.5456, 40.1701],
            [-75.6063, 40.1082],
            [-75.6638, 40.0824],
            [-75.7795, 40.1159],
            [-75.8079, 40.1777],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218270',
        NAME: 'Owen J. Roberts School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252402216,
        AWATER: 3456941,
        INTPTLAT: '+40.1705058',
        INTPTLON: '-075.6804175',
        ELSDLEA: '',
        UNSDLEA: '18270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8465, 39.9222],
            [-75.7758, 39.9504],
            [-75.6679, 39.9344],
            [-75.552, 39.8878],
            [-75.5513, 39.8394],
            [-75.5796, 39.8389],
            [-75.6348, 39.8302],
            [-75.7805, 39.8493],
            [-75.849, 39.8665],
            [-75.8465, 39.9222],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224210',
        NAME: 'Unionville-Chadds Ford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197315892,
        AWATER: 1965937,
        INTPTLAT: '+39.8913305',
        INTPTLON: '-075.7042904',
        ELSDLEA: '',
        UNSDLEA: '24210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6133, 40.0505],
            [-75.5021, 39.9544],
            [-75.4749, 39.9062],
            [-75.552, 39.8878],
            [-75.6679, 39.9344],
            [-75.6737, 40.0319],
            [-75.6133, 40.0505],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225290',
        NAME: 'West Chester Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189710973,
        AWATER: 1845187,
        INTPTLAT: '+39.9720309',
        INTPTLON: '-075.5867112',
        ELSDLEA: '',
        UNSDLEA: '25290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2699, 40.169],
            [-80.2542, 40.1851],
            [-80.2273, 40.18],
            [-80.2235, 40.1607],
            [-80.2699, 40.169],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224990',
        NAME: 'Washington School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8796731,
        AWATER: 0,
        INTPTLAT: '+40.1740255',
        INTPTLON: '-080.2448377',
        ELSDLEA: '',
        UNSDLEA: '24990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2231, 40.3658],
            [-80.1162, 40.3123],
            [-80.0867, 40.2524],
            [-80.1086, 40.1923],
            [-80.1349, 40.1828],
            [-80.2145, 40.2235],
            [-80.2401, 40.3082],
            [-80.2231, 40.3658],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204890',
        NAME: 'Canon-McMillan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144707318,
        AWATER: 558293,
        INTPTLAT: '+40.2688282',
        INTPTLON: '-080.1714619',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7219, 40.4085],
            [-77.4978, 40.6095],
            [-77.2879, 40.6936],
            [-77.0355, 40.6769],
            [-76.9655, 40.6471],
            [-76.9397, 40.6384],
            [-76.9494, 40.6282],
            [-77.0638, 40.654],
            [-77.1747, 40.633],
            [-77.1676, 40.5595],
            [-77.3381, 40.4953],
            [-77.5401, 40.3992],
            [-77.5938, 40.351],
            [-77.703, 40.2636],
            [-77.7393, 40.3492],
            [-77.7219, 40.4085],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212600',
        NAME: 'Juniata County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 963193236,
        AWATER: 5602352,
        INTPTLAT: '+40.5271320',
        INTPTLON: '-077.4086745',
        ELSDLEA: '',
        UNSDLEA: '12600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.5331, 41.9999],
            [-78.4442, 41.9997],
            [-78.3071, 41.9994],
            [-78.2641, 41.9262],
            [-78.3757, 41.884],
            [-78.5326, 41.895],
            [-78.5331, 41.9999],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218240',
        NAME: 'Otto-Eldred School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225441130,
        AWATER: 1484457,
        INTPTLAT: '+41.9508045',
        INTPTLON: '-078.4112470',
        ELSDLEA: '',
        UNSDLEA: '18240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7482, 39.9142],
            [-76.6603, 39.9606],
            [-76.6033, 39.8917],
            [-76.6463, 39.8242],
            [-76.7722, 39.8417],
            [-76.7482, 39.9142],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207230',
        NAME: 'Dallastown Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141335768,
        AWATER: 1817293,
        INTPTLAT: '+39.8738749',
        INTPTLON: '-076.6947542',
        ELSDLEA: '',
        UNSDLEA: '07230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1497, 41.2223],
            [-78.0536, 41.2737],
            [-77.9594, 41.2175],
            [-77.973, 41.173],
            [-78.1122, 41.1142],
            [-78.0597, 41.0036],
            [-78.0777, 40.9588],
            [-78.2831, 40.9693],
            [-78.259, 41.05],
            [-78.1396, 41.0596],
            [-78.1497, 41.2223],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225230',
        NAME: 'West Branch Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424645437,
        AWATER: 3785926,
        INTPTLAT: '+41.0927665',
        INTPTLON: '-078.1171019',
        ELSDLEA: '',
        UNSDLEA: '25230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.229, 41.1385],
            [-76.1461, 41.1433],
            [-76.0634, 41.1088],
            [-76.0273, 41.0551],
            [-76.2217, 41.0045],
            [-76.2217, 41.0046],
            [-76.3262, 41.0996],
            [-76.229, 41.1385],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203480',
        NAME: 'Berwick Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230094605,
        AWATER: 5871901,
        INTPTLAT: '+41.0748155',
        INTPTLON: '-076.1887948',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.563, 41.0363],
            [-76.4952, 41.0651],
            [-76.4281, 40.9965],
            [-76.335, 40.9794],
            [-76.2217, 41.0046],
            [-76.2217, 41.0045],
            [-76.273, 40.8942],
            [-76.4214, 40.993],
            [-76.5124, 40.9461],
            [-76.563, 41.0363],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203840',
        NAME: 'Bloomsburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214260441,
        AWATER: 4896819,
        INTPTLAT: '+40.9735174',
        INTPTLON: '-076.3864476',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4115, 40.8335],
            [-76.4101, 40.7649],
            [-76.4906, 40.8252],
            [-76.4115, 40.8335],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215990',
        NAME: 'Mount Carmel Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60612378,
        AWATER: 469121,
        INTPTLAT: '+40.7955413',
        INTPTLON: '-076.4392667',
        ELSDLEA: '',
        UNSDLEA: '15990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6463, 39.8242],
            [-76.6033, 39.8917],
            [-76.6603, 39.9606],
            [-76.6604, 39.9618],
            [-76.6161, 39.9734],
            [-76.5241, 39.9044],
            [-76.4777, 39.9498],
            [-76.3917, 39.9128],
            [-76.3124, 39.8054],
            [-76.297, 39.7912],
            [-76.3433, 39.7557],
            [-76.5281, 39.8394],
            [-76.6714, 39.7836],
            [-76.6463, 39.8242],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220100',
        NAME: 'Red Lion Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 364785207,
        AWATER: 538156,
        INTPTLAT: '+39.8665004',
        INTPTLON: '-076.4936059',
        ELSDLEA: '',
        UNSDLEA: '20100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9572, 39.8557],
            [-76.8731, 39.8105],
            [-76.8005, 39.7206],
            [-76.9993, 39.7201],
            [-76.9994, 39.7602],
            [-76.9996, 39.795],
            [-76.9717, 39.7979],
            [-76.9631, 39.8148],
            [-76.9997, 39.8196],
            [-76.9572, 39.8557],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222470',
        NAME: 'South Western School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139748132,
        AWATER: 4617999,
        INTPTLAT: '+39.7739338',
        INTPTLON: '-076.9201761',
        ELSDLEA: '',
        UNSDLEA: '22470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4202, 41.5154],
            [-78.4191, 41.6022],
            [-78.2034, 41.6182],
            [-78.0504, 41.4755],
            [-77.9892, 41.4748],
            [-77.9888, 41.3675],
            [-78.0536, 41.2737],
            [-78.1497, 41.2223],
            [-78.2368, 41.2305],
            [-78.254, 41.4047],
            [-78.42, 41.405],
            [-78.4202, 41.5154],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204830',
        NAME: 'Cameron County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1026231717,
        AWATER: 5664033,
        INTPTLAT: '+41.4382883',
        INTPTLON: '-078.1983145',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8954, 40.3935],
            [-78.8648, 40.3925],
            [-78.9032, 40.3603],
            [-78.8954, 40.3935],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209750',
        NAME: 'Ferndale Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15100443,
        AWATER: 332357,
        INTPTLAT: '+40.3756633',
        INTPTLON: '-078.9145127',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.9446, 39.9398],
            [-77.8893, 40.04],
            [-77.8645, 40.0616],
            [-77.703, 40.2636],
            [-77.5938, 40.351],
            [-77.646, 40.2827],
            [-77.6147, 40.1985],
            [-77.6275, 40.1886],
            [-77.8632, 40.0009],
            [-77.9446, 39.9398],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209660',
        NAME: 'Fannett-Metal School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315461441,
        AWATER: 242138,
        INTPTLAT: '+40.1698353',
        INTPTLON: '-077.7298721',
        ELSDLEA: '',
        UNSDLEA: '09660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2872, 40.4554],
            [-77.1901, 40.5154],
            [-77.131, 40.5046],
            [-76.9919, 40.5615],
            [-76.9844, 40.5146],
            [-76.953, 40.4906],
            [-77.1016, 40.4282],
            [-77.1527, 40.4597],
            [-77.2872, 40.4554],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216890',
        NAME: 'Newport School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181526584,
        AWATER: 3771145,
        INTPTLAT: '+40.4862315',
        INTPTLON: '-077.1139973',
        ELSDLEA: '',
        UNSDLEA: '16890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7633, 40.5715],
            [-78.7126, 40.6369],
            [-78.7127, 40.7244],
            [-78.5896, 40.7254],
            [-78.5765, 40.6385],
            [-78.4965, 40.6389],
            [-78.5511, 40.5611],
            [-78.6781, 40.5366],
            [-78.7633, 40.5715],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204740',
        NAME: 'Cambria Heights School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287542888,
        AWATER: 119010,
        INTPTLAT: '+40.6310284',
        INTPTLON: '-078.6446952',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3269, 40.1597],
            [-75.2982, 40.1432],
            [-75.3269, 40.1052],
            [-75.4243, 40.1079],
            [-75.3269, 40.1597],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216980',
        NAME: 'Norristown Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40036482,
        AWATER: 1127481,
        INTPTLAT: '+40.1366660',
        INTPTLON: '-075.3536068',
        ELSDLEA: '',
        UNSDLEA: '16980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3269, 40.1052],
            [-75.2982, 40.1432],
            [-75.2334, 40.1506],
            [-75.1963, 40.1278],
            [-75.255, 40.0627],
            [-75.2644, 40.0541],
            [-75.3061, 40.069],
            [-75.3269, 40.1052],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219470',
        NAME: 'Colonial School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62109634,
        AWATER: 712826,
        INTPTLAT: '+40.1035408',
        INTPTLON: '-075.2709952',
        ELSDLEA: '',
        UNSDLEA: '19470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6278, 40.2328],
            [-75.6761, 40.2426],
            [-75.6624, 40.2664],
            [-75.6283, 40.2558],
            [-75.6278, 40.2328],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219680',
        NAME: 'Pottstown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12631749,
        AWATER: 224820,
        INTPTLAT: '+40.2509241',
        INTPTLON: '-075.6447544',
        ELSDLEA: '',
        UNSDLEA: '19680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1963, 40.1278],
            [-75.1705, 40.1118],
            [-75.1686, 40.1106],
            [-75.1764, 40.0846],
            [-75.255, 40.0627],
            [-75.1963, 40.1278],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222620',
        NAME: 'Springfield Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17562712,
        AWATER: 31389,
        INTPTLAT: '+40.0991171',
        INTPTLON: '-075.2002448',
        ELSDLEA: '',
        UNSDLEA: '22620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.959, 41.4527],
            [-78.8143, 41.4541],
            [-78.8144, 41.5182],
            [-78.6485, 41.4409],
            [-78.6797, 41.3756],
            [-78.6467, 41.2536],
            [-78.6893, 41.2071],
            [-78.7353, 41.2629],
            [-78.9185, 41.3442],
            [-78.9595, 41.3715],
            [-78.959, 41.4527],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220310',
        NAME: 'Ridgway Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 473776126,
        AWATER: 3307582,
        INTPTLAT: '+41.3923425',
        INTPTLON: '-078.7916306',
        ELSDLEA: '',
        UNSDLEA: '20310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.956, 41.6234],
            [-78.9487, 41.8305],
            [-78.9158, 41.8409],
            [-78.7795, 41.8497],
            [-78.7698, 41.7603],
            [-78.7685, 41.6861],
            [-78.7067, 41.6248],
            [-78.7975, 41.6249],
            [-78.8144, 41.5182],
            [-78.8143, 41.4541],
            [-78.959, 41.4527],
            [-78.956, 41.6234],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212660',
        NAME: 'Kane Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 629757625,
        AWATER: 5472467,
        INTPTLAT: '+41.6711004',
        INTPTLON: '-078.8613694',
        ELSDLEA: '',
        UNSDLEA: '12660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6485, 41.4409],
            [-78.6423, 41.5181],
            [-78.4202, 41.5154],
            [-78.42, 41.405],
            [-78.254, 41.4047],
            [-78.2368, 41.2305],
            [-78.4847, 41.2439],
            [-78.6467, 41.2536],
            [-78.6797, 41.3756],
            [-78.6485, 41.4409],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220640',
        NAME: 'St. Marys Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 882822242,
        AWATER: 2533679,
        INTPTLAT: '+41.3551378',
        INTPTLON: '-078.4728104',
        ELSDLEA: '',
        UNSDLEA: '20640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9242, 40.4811],
            [-78.7911, 40.459],
            [-78.7998, 40.5447],
            [-78.7633, 40.5715],
            [-78.6781, 40.5366],
            [-78.6974, 40.4335],
            [-78.8165, 40.3925],
            [-78.8648, 40.3925],
            [-78.8954, 40.3935],
            [-78.9745, 40.3949],
            [-78.9746, 40.396],
            [-78.9242, 40.4811],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205340',
        NAME: 'Central Cambria School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257590847,
        AWATER: 1112315,
        INTPTLAT: '+40.4251701',
        INTPTLON: '-078.8940036',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.849, 41.6437],
            [-75.6522, 41.6422],
            [-75.6578, 41.5757],
            [-75.7737, 41.5038],
            [-75.8394, 41.5415],
            [-75.8896, 41.6436],
            [-75.849, 41.6437],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212990',
        NAME: 'Lackawanna Trail School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187116189,
        AWATER: 3084259,
        INTPTLAT: '+41.5932697',
        INTPTLON: '-075.7632767',
        ELSDLEA: '',
        UNSDLEA: '12990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3165, 39.85],
            [-75.2998, 39.9048],
            [-75.2644, 39.8822],
            [-75.2115, 39.867],
            [-75.2286, 39.8586],
            [-75.2571, 39.8518],
            [-75.3165, 39.85],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212170',
        NAME: 'Interboro School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21441431,
        AWATER: 7859403,
        INTPTLAT: '+39.8673905',
        INTPTLON: '-075.2763926',
        ELSDLEA: '',
        UNSDLEA: '12170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0581, 41.0856],
            [-76.9779, 41.0879],
            [-76.8805, 41.0906],
            [-76.7723, 41.0163],
            [-76.7929, 40.9466],
            [-76.864, 40.9319],
            [-76.8651, 41.0199],
            [-76.9778, 41.0195],
            [-77.0581, 41.0856],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215480',
        NAME: 'Milton Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218494389,
        AWATER: 4970942,
        INTPTLAT: '+41.0305778',
        INTPTLON: '-076.8950423',
        ELSDLEA: '',
        UNSDLEA: '15480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9778, 41.0195],
            [-76.8651, 41.0199],
            [-76.864, 40.9319],
            [-76.8002, 40.882],
            [-76.9131, 40.8845],
            [-76.9563, 40.9008],
            [-76.9778, 41.0195],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213590',
        NAME: 'Lewisburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107765381,
        AWATER: 238832,
        INTPTLAT: '+40.9545354',
        INTPTLON: '-076.9062260',
        ELSDLEA: '',
        UNSDLEA: '13590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9494, 40.6282],
            [-76.9919, 40.5615],
            [-77.131, 40.5046],
            [-77.1901, 40.5154],
            [-77.2872, 40.4554],
            [-77.3381, 40.4953],
            [-77.1676, 40.5595],
            [-77.1747, 40.633],
            [-77.0638, 40.654],
            [-76.9494, 40.6282],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211190',
        NAME: 'Greenwood School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249890543,
        AWATER: 1537283,
        INTPTLAT: '+40.5587547',
        INTPTLON: '-077.1316927',
        ELSDLEA: '',
        UNSDLEA: '11190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6445, 40.0581],
            [-76.633, 40.0574],
            [-76.5339, 40.0508],
            [-76.5122, 40.0134],
            [-76.4777, 39.9498],
            [-76.5241, 39.9044],
            [-76.6161, 39.9734],
            [-76.6684, 40.0309],
            [-76.6445, 40.0581],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4208790',
        NAME: 'Eastern York School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142535801,
        AWATER: 67839,
        INTPTLAT: '+39.9991106',
        INTPTLON: '-076.5653197',
        ELSDLEA: '',
        UNSDLEA: '08790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6714, 39.7836],
            [-76.5281, 39.8394],
            [-76.3433, 39.7557],
            [-76.297, 39.7912],
            [-76.2398, 39.7213],
            [-76.5695, 39.7212],
            [-76.6094, 39.7213],
            [-76.6714, 39.7836],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222170',
        NAME: 'South Eastern School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 280307856,
        AWATER: 1430689,
        INTPTLAT: '+39.7643050',
        INTPTLON: '-076.4748437',
        ELSDLEA: '',
        UNSDLEA: '22170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6431, 40.8841],
            [-76.5848, 40.8799],
            [-76.4906, 40.8252],
            [-76.4101, 40.7649],
            [-76.4695, 40.7432],
            [-76.5049, 40.7303],
            [-76.6961, 40.7861],
            [-76.6431, 40.8841],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221240',
        NAME: 'Shamokin Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181584047,
        AWATER: 1199893,
        INTPTLAT: '+40.8018688',
        INTPTLON: '-076.5848850',
        ELSDLEA: '',
        UNSDLEA: '21240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7659, 40.5509],
            [-79.7492, 40.599],
            [-79.7128, 40.6199],
            [-79.6216, 40.5513],
            [-79.6796, 40.5074],
            [-79.7016, 40.5254],
            [-79.7654, 40.5499],
            [-79.7659, 40.5509],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204530',
        NAME: 'Burrell School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68364202,
        AWATER: 664274,
        INTPTLAT: '+40.5665969',
        INTPTLON: '-079.6891004',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4446, 40.4157],
            [-79.3024, 40.4613],
            [-79.1927, 40.4149],
            [-79.3101, 40.2842],
            [-79.4271, 40.3801],
            [-79.4446, 40.4157],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207560',
        NAME: 'Derry Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250478726,
        AWATER: 3145859,
        INTPTLAT: '+40.3744693',
        INTPTLON: '-079.3113306',
        ELSDLEA: '',
        UNSDLEA: '07560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5295, 40.4731],
            [-79.4446, 40.4157],
            [-79.4271, 40.3801],
            [-79.4732, 40.3613],
            [-79.5712, 40.3751],
            [-79.5959, 40.3985],
            [-79.5981, 40.4417],
            [-79.5295, 40.4731],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210920',
        NAME: 'Greensburg Salem School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136303600,
        AWATER: 851673,
        INTPTLAT: '+40.3965261',
        INTPTLON: '-079.5137620',
        ELSDLEA: '',
        UNSDLEA: '10920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6926, 40.6697],
            [-79.6684, 40.68],
            [-79.5647, 40.6726],
            [-79.495, 40.6458],
            [-79.5664, 40.593],
            [-79.4502, 40.5301],
            [-79.5295, 40.4731],
            [-79.5981, 40.4417],
            [-79.6796, 40.5074],
            [-79.6216, 40.5513],
            [-79.7128, 40.6199],
            [-79.6926, 40.6697],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212840',
        NAME: 'Kiski Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264788290,
        AWATER: 7061359,
        INTPTLAT: '+40.5643179',
        INTPTLON: '-079.5865369',
        ELSDLEA: '',
        UNSDLEA: '12840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5578, 40.1866],
            [-79.5425, 40.2338],
            [-79.3839, 40.2057],
            [-79.229, 40.0978],
            [-79.2934, 40.0403],
            [-79.2937, 40.0404],
            [-79.5052, 40.1406],
            [-79.5355, 40.126],
            [-79.5578, 40.1866],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216170',
        NAME: 'Mount Pleasant Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275322178,
        AWATER: 705275,
        INTPTLAT: '+40.1508726',
        INTPTLON: '-079.4071735',
        ELSDLEA: '',
        UNSDLEA: '16170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7654, 40.3853],
            [-79.7222, 40.4091],
            [-79.5959, 40.3985],
            [-79.5712, 40.3751],
            [-79.5993, 40.3283],
            [-79.6319, 40.3295],
            [-79.6716, 40.335],
            [-79.7711, 40.3754],
            [-79.7654, 40.3853],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218660',
        NAME: 'Penn-Trafford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88158916,
        AWATER: 0,
        INTPTLAT: '+40.3719567',
        INTPTLON: '-079.6574717',
        ELSDLEA: '',
        UNSDLEA: '18660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7751, 40.2879],
            [-79.6951, 40.2918],
            [-79.6272, 40.2015],
            [-79.6594, 40.0973],
            [-79.7444, 40.1282],
            [-79.7818, 40.2277],
            [-79.7751, 40.2879],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221150',
        NAME: 'Yough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193089558,
        AWATER: 1868090,
        INTPTLAT: '+40.1981079',
        INTPTLON: '-079.7109835',
        ELSDLEA: '',
        UNSDLEA: '21150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5313, 41.6417],
            [-75.4793, 41.6416],
            [-75.4611, 41.6164],
            [-75.4593, 41.5881],
            [-75.5313, 41.6417],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204980',
        NAME: 'Carbondale Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47952056,
        AWATER: 198753,
        INTPTLAT: '+41.6033780',
        INTPTLON: '-075.5053903',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6223, 41.4427],
            [-75.5603, 41.4128],
            [-75.6249, 41.3927],
            [-75.6223, 41.4427],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207980',
        NAME: 'Dunmore School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23097581,
        AWATER: 197711,
        INTPTLAT: '+41.4139837',
        INTPTLON: '-075.6062530',
        ELSDLEA: '',
        UNSDLEA: '07980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.559, 41.7783],
            [-75.4709, 41.7846],
            [-75.3223, 41.798],
            [-75.3459, 41.6726],
            [-75.4611, 41.6164],
            [-75.4793, 41.6416],
            [-75.5747, 41.7036],
            [-75.559, 41.7783],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209930',
        NAME: 'Forest City Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246389763,
        AWATER: 3814527,
        INTPTLAT: '+41.7297201',
        INTPTLON: '-075.4327000',
        ELSDLEA: '',
        UNSDLEA: '09930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6578, 41.5757],
            [-75.6522, 41.6422],
            [-75.5313, 41.6417],
            [-75.4593, 41.5881],
            [-75.4564, 41.5336],
            [-75.4992, 41.5317],
            [-75.6321, 41.4874],
            [-75.6622, 41.4759],
            [-75.6578, 41.5757],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213050',
        NAME: 'Lakeland School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168774346,
        AWATER: 3098336,
        INTPTLAT: '+41.5733074',
        INTPTLON: '-075.5813301',
        ELSDLEA: '',
        UNSDLEA: '13050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6622, 41.4759],
            [-75.6321, 41.4874],
            [-75.5358, 41.4339],
            [-75.5603, 41.4128],
            [-75.6223, 41.4427],
            [-75.6623, 41.4625],
            [-75.6622, 41.4759],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215170',
        NAME: 'Mid Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39368649,
        AWATER: 166141,
        INTPTLAT: '+41.4515326',
        INTPTLON: '-075.6025311',
        ELSDLEA: '',
        UNSDLEA: '15170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.72, 41.4192],
            [-75.6623, 41.4625],
            [-75.6223, 41.4427],
            [-75.6249, 41.3927],
            [-75.6832, 41.3389],
            [-75.72, 41.4192],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221090',
        NAME: 'Scranton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65550826,
        AWATER: 592027,
        INTPTLAT: '+41.4041782',
        INTPTLON: '-075.6659078',
        ELSDLEA: '',
        UNSDLEA: '21090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7435, 41.3928],
            [-75.72, 41.4192],
            [-75.6832, 41.3389],
            [-75.6872, 41.3392],
            [-75.742, 41.3487],
            [-75.7435, 41.3928],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223250',
        NAME: 'Riverside School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30246283,
        AWATER: 125352,
        INTPTLAT: '+41.3748755',
        INTPTLON: '-075.7086162',
        ELSDLEA: '',
        UNSDLEA: '23250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6321, 41.4874],
            [-75.4992, 41.5317],
            [-75.5358, 41.4339],
            [-75.6321, 41.4874],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224650',
        NAME: 'Valley View School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71582506,
        AWATER: 161947,
        INTPTLAT: '+41.4926741',
        INTPTLON: '-075.5538686',
        ELSDLEA: '',
        UNSDLEA: '24650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8001, 40.2082],
            [-76.8018, 40.2175],
            [-76.737, 40.2512],
            [-76.7265, 40.2285],
            [-76.7564, 40.1771],
            [-76.8061, 40.2006],
            [-76.8001, 40.2082],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215240',
        NAME: 'Middletown Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37969799,
        AWATER: 6646274,
        INTPTLAT: '+40.2136836',
        INTPTLON: '-076.7562870',
        ELSDLEA: '',
        UNSDLEA: '15240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9533, 40.4048],
            [-76.7806, 40.4596],
            [-76.7493, 40.4442],
            [-76.6942, 40.3164],
            [-76.7257, 40.2619],
            [-76.737, 40.2512],
            [-76.8018, 40.2175],
            [-76.8225, 40.224],
            [-76.8397, 40.2434],
            [-76.8522, 40.235],
            [-76.8001, 40.2082],
            [-76.8061, 40.2006],
            [-76.8732, 40.2377],
            [-76.8369, 40.2673],
            [-76.9212, 40.3364],
            [-76.9533, 40.4048],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205400',
        NAME: 'Central Dauphin School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311118572,
        AWATER: 18703974,
        INTPTLAT: '+40.3533595',
        INTPTLON: '-076.8223153',
        ELSDLEA: '',
        UNSDLEA: '05400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-76.5695, 40.5761],
              [-76.7806, 40.4596],
              [-76.7991, 40.4956],
              [-76.5695, 40.5761],
            ],
          ],
          [
            [
              [-76.7016, 40.6581],
              [-76.6397, 40.6198],
              [-76.732, 40.5516],
              [-76.8701, 40.5299],
              [-76.8864, 40.6178],
              [-76.7016, 40.6581],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224360',
        NAME: 'Upper Dauphin Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232793666,
        AWATER: 17973,
        INTPTLAT: '+40.5767925',
        INTPTLON: '-076.7613479',
        ELSDLEA: '',
        UNSDLEA: '24360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9494, 40.6282],
            [-76.8864, 40.6178],
            [-76.8701, 40.5299],
            [-76.9844, 40.5146],
            [-76.9919, 40.5615],
            [-76.9494, 40.6282],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215360',
        NAME: 'Millersburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69036915,
        AWATER: 13839170,
        INTPTLAT: '+40.5607039',
        INTPTLON: '-076.9359199',
        ELSDLEA: '',
        UNSDLEA: '15360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3893, 40.6495],
            [-80.344, 40.6713],
            [-80.2876, 40.6955],
            [-80.2661, 40.6918],
            [-80.2436, 40.6554],
            [-80.3165, 40.6041],
            [-80.3893, 40.6495],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4200824',
        NAME: 'Central Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61001530,
        AWATER: 2905463,
        INTPTLAT: '+40.6535079',
        INTPTLON: '-080.3102902',
        ELSDLEA: '',
        UNSDLEA: '00824',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8584, 40.7988],
            [-75.7994, 40.8245],
            [-75.633, 40.8946],
            [-75.6091, 40.7875],
            [-75.6994, 40.7661],
            [-75.7578, 40.7354],
            [-75.8584, 40.7988],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213500',
        NAME: 'Lehighton Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168077366,
        AWATER: 2142192,
        INTPTLAT: '+40.8117942',
        INTPTLON: '-075.7284406',
        ELSDLEA: '',
        UNSDLEA: '13500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7737, 41.5038],
            [-75.6578, 41.5757],
            [-75.6622, 41.4759],
            [-75.6623, 41.4625],
            [-75.72, 41.4192],
            [-75.7435, 41.3928],
            [-75.7605, 41.3609],
            [-75.7953, 41.3781],
            [-75.8347, 41.4272],
            [-75.7737, 41.5038],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202010',
        NAME: 'Abington Heights School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180034632,
        AWATER: 2666658,
        INTPTLAT: '+41.4716244',
        INTPTLON: '-075.7336432',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.064, 40.776],
            [-77.898, 40.8752],
            [-77.7508, 40.8236],
            [-77.6996, 40.721],
            [-77.8245, 40.7437],
            [-77.8943, 40.7162],
            [-77.9441, 40.6916],
            [-78.064, 40.776],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222770',
        NAME: 'State College Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391359852,
        AWATER: 64297,
        INTPTLAT: '+40.7631154',
        INTPTLON: '-077.8981334',
        ELSDLEA: '',
        UNSDLEA: '22770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.633, 40.8946],
            [-75.5564, 40.9287],
            [-75.4742, 40.8147],
            [-75.609, 40.7874],
            [-75.6091, 40.7875],
            [-75.633, 40.8946],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218360',
        NAME: 'Palmerton Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133760803,
        AWATER: 4927037,
        INTPTLAT: '+40.8510668',
        INTPTLON: '-075.5679031',
        ELSDLEA: '',
        UNSDLEA: '18360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.4011, 40.6469],
            [-80.3893, 40.6495],
            [-80.3165, 40.6041],
            [-80.2436, 40.6554],
            [-80.2331, 40.6318],
            [-80.2783, 40.6181],
            [-80.2352, 40.5859],
            [-80.2334, 40.5696],
            [-80.2751, 40.5388],
            [-80.3608, 40.4776],
            [-80.4011, 40.6469],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212030',
        NAME: 'Hopewell Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152173836,
        AWATER: 2789742,
        INTPTLAT: '+40.5768205',
        INTPTLON: '-080.3256724',
        ELSDLEA: '',
        UNSDLEA: '12030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3226, 40.8532],
            [-80.3224, 40.8532],
            [-80.1585, 40.8552],
            [-80.1545, 40.7727],
            [-80.2394, 40.7696],
            [-80.3141, 40.7685],
            [-80.3226, 40.8532],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217430',
        NAME: 'Riverside Beaver County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126149011,
        AWATER: 1817532,
        INTPTLAT: '+40.8093192',
        INTPTLON: '-080.2337278',
        ELSDLEA: '',
        UNSDLEA: '17430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.519, 40.6374],
            [-80.4688, 40.6369],
            [-80.4445, 40.6238],
            [-80.4011, 40.6469],
            [-80.3608, 40.4776],
            [-80.3609, 40.4775],
            [-80.3619, 40.4776],
            [-80.519, 40.4774],
            [-80.519, 40.6374],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222230',
        NAME: 'South Side Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191135599,
        AWATER: 4612731,
        INTPTLAT: '+40.5532273',
        INTPTLON: '-080.4567019',
        ELSDLEA: '',
        UNSDLEA: '22230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.519, 40.6388],
            [-80.519, 40.6541],
            [-80.519, 40.7337],
            [-80.419, 40.7152],
            [-80.344, 40.6713],
            [-80.3893, 40.6495],
            [-80.4011, 40.6469],
            [-80.4445, 40.6238],
            [-80.4416, 40.6421],
            [-80.4688, 40.6369],
            [-80.519, 40.6374],
            [-80.519, 40.6388],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226040',
        NAME: 'Western Beaver County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86844405,
        AWATER: 3231309,
        INTPTLAT: '+40.6770140',
        INTPTLON: '-080.4579943',
        ELSDLEA: '',
        UNSDLEA: '26040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2689, 40.762],
            [-76.2304, 40.7774],
            [-76.2239, 40.7721],
            [-76.0828, 40.7714],
            [-76.0579, 40.6988],
            [-76.1511, 40.6886],
            [-76.2471, 40.711],
            [-76.2689, 40.762],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222710',
        NAME: 'Saint Clair Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121980906,
        AWATER: 1002285,
        INTPTLAT: '+40.7320184',
        INTPTLON: '-076.1500781',
        ELSDLEA: '',
        UNSDLEA: '22710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.267, 41.8495],
            [-80.2065, 41.849],
            [-80.0352, 41.8501],
            [-79.91, 41.8507],
            [-79.9101, 41.7739],
            [-79.7714, 41.7742],
            [-79.7465, 41.7161],
            [-79.77, 41.5561],
            [-79.8325, 41.5267],
            [-79.8929, 41.5623],
            [-80.062, 41.5818],
            [-80.0948, 41.6686],
            [-80.2717, 41.6622],
            [-80.267, 41.8495],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218740',
        NAME: 'Penncrest School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1050503173,
        AWATER: 5568453,
        INTPTLAT: '+41.7086072',
        INTPTLON: '-080.0037464',
        ELSDLEA: '',
        UNSDLEA: '18740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7578, 40.7354],
            [-75.6994, 40.7661],
            [-75.6386, 40.6797],
            [-75.6203, 40.6207],
            [-75.6943, 40.5511],
            [-75.8915, 40.6773],
            [-75.7578, 40.7354],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217850',
        NAME: 'Northwestern Lehigh School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276277409,
        AWATER: 1415715,
        INTPTLAT: '+40.6558969',
        INTPTLON: '-075.7342829',
        ELSDLEA: '',
        UNSDLEA: '17850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5156, 40.7022],
            [-75.4836, 40.6669],
            [-75.4629, 40.6288],
            [-75.4947, 40.6164],
            [-75.5156, 40.7022],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226250',
        NAME: 'Whitehall-Coplay School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34129079,
        AWATER: 822721,
        INTPTLAT: '+40.6574280',
        INTPTLON: '-075.5042549',
        ELSDLEA: '',
        UNSDLEA: '26250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4947, 40.6164],
            [-75.4629, 40.6288],
            [-75.4237, 40.636],
            [-75.4196, 40.6161],
            [-75.479, 40.5549],
            [-75.4832, 40.5565],
            [-75.5113, 40.5825],
            [-75.5249, 40.5893],
            [-75.4947, 40.6164],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202280',
        NAME: 'Allentown City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45449988,
        AWATER: 1238395,
        INTPTLAT: '+40.5936476',
        INTPTLON: '-075.4783928',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4756, 40.5301],
            [-75.4038, 40.5808],
            [-75.3335, 40.5371],
            [-75.4088, 40.4886],
            [-75.4841, 40.4185],
            [-75.5308, 40.4477],
            [-75.4756, 40.5301],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222350',
        NAME: 'Southern Lehigh School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116780084,
        AWATER: 457694,
        INTPTLAT: '+40.5177887',
        INTPTLON: '-075.4411979',
        ELSDLEA: '',
        UNSDLEA: '22350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7861, 41.1015],
            [-79.6903, 41.1021],
            [-79.6279, 41.0359],
            [-79.5702, 40.9856],
            [-79.581, 40.8939],
            [-79.6915, 40.8849],
            [-79.784, 40.9563],
            [-79.7861, 41.1015],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209600',
        NAME: 'Karns City Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 337565095,
        AWATER: 2381093,
        INTPTLAT: '+40.9881721',
        INTPTLON: '-079.6975873',
        ELSDLEA: '',
        UNSDLEA: '09600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2713, 41.2902],
            [-79.209, 41.317],
            [-79.2112, 41.1126],
            [-79.3644, 41.1068],
            [-79.3675, 41.1963],
            [-79.2713, 41.2902],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206090',
        NAME: 'Clarion-Limestone Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301943998,
        AWATER: 1845663,
        INTPTLAT: '+41.1939030',
        INTPTLON: '-079.2588347',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4514, 41.081],
            [-79.3644, 41.1068],
            [-79.2112, 41.1126],
            [-79.2105, 41.0405],
            [-79.2153, 40.9125],
            [-79.4148, 40.9385],
            [-79.4938, 40.9215],
            [-79.5513, 40.9805],
            [-79.4559, 40.9882],
            [-79.4514, 41.081],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220130',
        NAME: 'Redbank Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426454729,
        AWATER: 6594572,
        INTPTLAT: '+41.0061312',
        INTPTLON: '-079.3462937',
        ELSDLEA: '',
        UNSDLEA: '20130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8282, 41.2665],
            [-79.752, 41.2256],
            [-79.6046, 41.2913],
            [-79.6504, 41.2537],
            [-79.5712, 41.0949],
            [-79.6279, 41.0359],
            [-79.6903, 41.1021],
            [-79.7861, 41.1015],
            [-79.8226, 41.1731],
            [-79.8282, 41.2665],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202310',
        NAME: 'Allegheny-Clarion Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313337464,
        AWATER: 11173476,
        INTPTLAT: '+41.1523801',
        INTPTLON: '-079.7026408',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9937, 42.102],
            [-79.9037, 42.1555],
            [-79.7617, 42.1553],
            [-79.7612, 41.9988],
            [-79.7617, 41.9378],
            [-79.8854, 41.938],
            [-79.892, 41.9988],
            [-80.0008, 41.999],
            [-80.0257, 42.0833],
            [-79.9937, 42.102],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225080',
        NAME: 'Wattsburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370096995,
        AWATER: 2700738,
        INTPTLAT: '+42.0433108',
        INTPTLON: '-079.8534344',
        ELSDLEA: '',
        UNSDLEA: '25080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4608, 41.2648],
            [-76.4476, 41.2756],
            [-76.3101, 41.3102],
            [-76.3192, 41.2081],
            [-76.229, 41.1385],
            [-76.3262, 41.0996],
            [-76.4176, 41.1196],
            [-76.4608, 41.2648],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203390',
        NAME: 'Benton Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249519263,
        AWATER: 2136151,
        INTPTLAT: '+41.2073108',
        INTPTLON: '-076.3744956',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4176, 41.1196],
            [-76.3262, 41.0996],
            [-76.2217, 41.0046],
            [-76.335, 40.9794],
            [-76.4281, 40.9965],
            [-76.4952, 41.0651],
            [-76.4176, 41.1196],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205370',
        NAME: 'Central Columbia School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198600767,
        AWATER: 5182241,
        INTPTLAT: '+41.0516871',
        INTPTLON: '-076.3798767',
        ELSDLEA: '',
        UNSDLEA: '05370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6408, 41.1557],
            [-76.5926, 41.1578],
            [-76.4608, 41.2648],
            [-76.4176, 41.1196],
            [-76.4952, 41.0651],
            [-76.563, 41.0363],
            [-76.6213, 41.0916],
            [-76.6408, 41.1557],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215450',
        NAME: 'Millville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235513107,
        AWATER: 708304,
        INTPTLAT: '+41.1417449',
        INTPTLON: '-076.5171217',
        ELSDLEA: '',
        UNSDLEA: '15450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8181, 41.3914],
            [-79.7333, 41.4069],
            [-79.6382, 41.4426],
            [-79.4778, 41.3865],
            [-79.4781, 41.3357],
            [-79.6046, 41.2913],
            [-79.752, 41.2256],
            [-79.8282, 41.2665],
            [-79.8181, 41.3914],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207050',
        NAME: 'Cranberry Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 405087361,
        AWATER: 578813,
        INTPTLAT: '+41.3515317',
        INTPTLON: '-079.6753998',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8929, 41.5623],
            [-79.8325, 41.5267],
            [-79.73, 41.4703],
            [-79.7333, 41.4069],
            [-79.8181, 41.3914],
            [-79.8822, 41.4204],
            [-79.9426, 41.5253],
            [-79.8929, 41.5623],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224630',
        NAME: 'Valley Grove School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162548340,
        AWATER: 2945658,
        INTPTLAT: '+41.4674312',
        INTPTLON: '-079.8425182',
        ELSDLEA: '',
        UNSDLEA: '24630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8325, 41.5267],
            [-79.77, 41.5561],
            [-79.7466, 41.5273],
            [-79.5589, 41.5065],
            [-79.6382, 41.4426],
            [-79.7333, 41.4069],
            [-79.73, 41.4703],
            [-79.8325, 41.5267],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218090',
        NAME: 'Oil City Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203564608,
        AWATER: 3484322,
        INTPTLAT: '+41.5081538',
        INTPTLON: '-079.6967130',
        ELSDLEA: '',
        UNSDLEA: '18090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9998, 41.4325],
            [-79.9426, 41.5253],
            [-79.8822, 41.4204],
            [-79.8181, 41.3914],
            [-79.8282, 41.2665],
            [-79.8226, 41.1731],
            [-79.9818, 41.1718],
            [-79.9998, 41.1719],
            [-79.9998, 41.2387],
            [-79.9998, 41.4325],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210200',
        NAME: 'Franklin Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 478816253,
        AWATER: 7405537,
        INTPTLAT: '+41.3383694',
        INTPTLON: '-079.9035602',
        ELSDLEA: '',
        UNSDLEA: '10200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2516, 40.7946],
            [-76.1995, 40.8046],
            [-76.1795, 40.8477],
            [-76.1782, 40.8482],
            [-76.0071, 40.8644],
            [-76.0828, 40.7714],
            [-76.2239, 40.7721],
            [-76.1809, 40.7816],
            [-76.1943, 40.7972],
            [-76.2439, 40.7882],
            [-76.2516, 40.7946],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214430',
        NAME: 'Mahanoy Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130397214,
        AWATER: 1310447,
        INTPTLAT: '+40.8154464',
        INTPTLON: '-076.1145057',
        ELSDLEA: '',
        UNSDLEA: '14430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.0071, 40.8644],
            [-75.9579, 40.8787],
            [-75.9264, 40.8517],
            [-75.8584, 40.7988],
            [-75.7578, 40.7354],
            [-75.8915, 40.6773],
            [-75.8935, 40.6765],
            [-75.9562, 40.7208],
            [-76.0579, 40.6988],
            [-76.0828, 40.7714],
            [-76.0071, 40.8644],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223220',
        NAME: 'Tamaqua Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314562714,
        AWATER: 3719801,
        INTPTLAT: '+40.7852318',
        INTPTLON: '-075.9086214',
        ELSDLEA: '',
        UNSDLEA: '23220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.133, 40.1509],
            [-75.0948, 40.1282],
            [-75.0583, 40.1059],
            [-75.0967, 40.069],
            [-75.1317, 40.0893],
            [-75.1212, 40.0968],
            [-75.1309, 40.1026],
            [-75.1382, 40.0941],
            [-75.1686, 40.1106],
            [-75.1705, 40.1118],
            [-75.133, 40.1509],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202040',
        NAME: 'Abington School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41100575,
        AWATER: 613,
        INTPTLAT: '+40.1107172',
        INTPTLON: '-075.1124685',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3269, 40.1597],
            [-75.2935, 40.1933],
            [-75.2268, 40.2074],
            [-75.1955, 40.1882],
            [-75.2334, 40.1506],
            [-75.2982, 40.1432],
            [-75.3269, 40.1597],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202400',
        NAME: 'Wissahickon School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59578723,
        AWATER: 38246,
        INTPTLAT: '+40.1677058',
        INTPTLON: '-075.2593901',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2201, 41.5413],
            [-76.1872, 41.6045],
            [-76.089, 41.5974],
            [-75.9849, 41.6448],
            [-75.8896, 41.6436],
            [-75.8394, 41.5415],
            [-75.7737, 41.5038],
            [-75.8347, 41.4272],
            [-75.8787, 41.4151],
            [-76.0255, 41.3838],
            [-76.0471, 41.4714],
            [-76.2527, 41.3774],
            [-76.2831, 41.3765],
            [-76.2201, 41.5413],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223850',
        NAME: 'Tunkhannock Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 755396158,
        AWATER: 14911489,
        INTPTLAT: '+41.4923097',
        INTPTLON: '-076.0247387',
        ELSDLEA: '',
        UNSDLEA: '23850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1376, 41.9143],
            [-76.0411, 41.9059],
            [-76.0106, 41.7742],
            [-75.8459, 41.767],
            [-75.849, 41.6437],
            [-75.8896, 41.6436],
            [-75.9849, 41.6448],
            [-76.089, 41.5974],
            [-76.1276, 41.8013],
            [-76.1376, 41.9143],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209150',
        NAME: 'Elk Lake School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 501060507,
        AWATER: 4023502,
        INTPTLAT: '+41.7357540',
        INTPTLON: '-076.0230325',
        ELSDLEA: '',
        UNSDLEA: '09150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.495, 40.6458],
            [-79.3585, 40.6245],
            [-79.2767, 40.5591],
            [-79.2996, 40.5046],
            [-79.4502, 40.5301],
            [-79.5664, 40.593],
            [-79.495, 40.6458],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202550',
        NAME: 'Apollo-Ridge School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197614638,
        AWATER: 1364087,
        INTPTLAT: '+40.5722646',
        INTPTLON: '-079.4253936',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8065, 40.8173],
            [-78.8064, 40.845],
            [-78.6753, 40.8311],
            [-78.6277, 40.8276],
            [-78.5659, 40.7856],
            [-78.5896, 40.7254],
            [-78.7127, 40.7244],
            [-78.8081, 40.7206],
            [-78.8065, 40.8173],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211520',
        NAME: 'Harmony Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215016644,
        AWATER: 1215343,
        INTPTLAT: '+40.7706866',
        INTPTLON: '-078.7104912',
        ELSDLEA: '',
        UNSDLEA: '11520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-78.804, 40.9939],
              [-78.7075, 40.9932],
              [-78.6753, 40.8311],
              [-78.8064, 40.845],
              [-78.804, 40.9939],
            ],
          ],
          [
            [
              [-79.0188, 40.7095],
              [-78.9029, 40.7367],
              [-78.9033, 40.8159],
              [-78.8065, 40.8173],
              [-78.8081, 40.7206],
              [-78.8467, 40.6356],
              [-79.0188, 40.7095],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219830',
        NAME: 'Purchase Line School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 376111563,
        AWATER: 1092477,
        INTPTLAT: '+40.8043114',
        INTPTLON: '-078.8282925',
        ELSDLEA: '',
        UNSDLEA: '19830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0891, 40.6468],
            [-79.0188, 40.7095],
            [-78.8467, 40.6356],
            [-78.8751, 40.5753],
            [-78.8969, 40.5332],
            [-79.0886, 40.5909],
            [-79.0891, 40.6468],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218780',
        NAME: 'Penns Manor Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 207836840,
        AWATER: 2314865,
        INTPTLAT: '+40.6221697',
        INTPTLON: '-078.9650299',
        ELSDLEA: '',
        UNSDLEA: '18780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1275, 40.569],
            [-79.15, 40.4796],
            [-79.2767, 40.5591],
            [-79.1275, 40.569],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213290',
        NAME: 'Homer-Center School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105465223,
        AWATER: 1178720,
        INTPTLAT: '+40.5423984',
        INTPTLON: '-079.1873340',
        ELSDLEA: '',
        UNSDLEA: '13290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9343, 40.0476],
            [-75.8225, 40.0821],
            [-75.7758, 39.9504],
            [-75.8465, 39.9222],
            [-75.9514, 40.0164],
            [-75.9343, 40.0476],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206240',
        NAME: 'Coatesville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194442822,
        AWATER: 1911229,
        INTPTLAT: '+39.9987959',
        INTPTLON: '-075.8443606',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9869, 41.1667],
            [-75.8495, 41.189],
            [-75.7699, 41.1551],
            [-75.7673, 41.0931],
            [-75.7714, 41.0612],
            [-75.7703, 41.0549],
            [-75.7701, 41.0501],
            [-75.9276, 41.0885],
            [-76.0273, 41.0551],
            [-76.0634, 41.1088],
            [-75.9869, 41.1667],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205460',
        NAME: 'Crestwood School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276527821,
        AWATER: 3100145,
        INTPTLAT: '+41.1166825',
        INTPTLON: '-075.9135241',
        ELSDLEA: '',
        UNSDLEA: '05460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8787, 41.4151],
            [-75.8744, 41.3148],
            [-75.9284, 41.2881],
            [-76.0255, 41.3838],
            [-75.8787, 41.4151],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207200',
        NAME: 'Dallas School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122341909,
        AWATER: 2349443,
        INTPTLAT: '+41.3535565',
        INTPTLON: '-075.9225076',
        ELSDLEA: '',
        UNSDLEA: '07200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9514, 41.2347],
            [-75.9281, 41.241],
            [-75.8495, 41.189],
            [-75.9869, 41.1667],
            [-75.9514, 41.2347],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211420',
        NAME: 'Hanover Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55996191,
        AWATER: 904692,
        INTPTLAT: '+41.2002059',
        INTPTLON: '-075.9424160',
        ELSDLEA: '',
        UNSDLEA: '11420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9264, 40.8517],
            [-75.7618, 40.8837],
            [-75.7994, 40.8245],
            [-75.8584, 40.7988],
            [-75.9264, 40.8517],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218450',
        NAME: 'Panther Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86932098,
        AWATER: 2078692,
        INTPTLAT: '+40.8530679',
        INTPTLON: '-075.8409763',
        ELSDLEA: '',
        UNSDLEA: '18450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2481, 40.724],
            [-80.2661, 40.6918],
            [-80.2876, 40.6955],
            [-80.3033, 40.7162],
            [-80.2481, 40.724],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220460',
        NAME: 'Rochester Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12537177,
        AWATER: 770963,
        INTPTLAT: '+40.7132341',
        INTPTLON: '-080.2692186',
        ELSDLEA: '',
        UNSDLEA: '20460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5589, 41.5065],
            [-79.4935, 41.6247],
            [-78.956, 41.6234],
            [-78.959, 41.4527],
            [-78.9595, 41.3715],
            [-79.1119, 41.325],
            [-79.2089, 41.3319],
            [-79.2072, 41.4309],
            [-79.4003, 41.4361],
            [-79.4778, 41.3865],
            [-79.6382, 41.4426],
            [-79.5589, 41.5065],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4208280',
        NAME: 'Forest Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1293886011,
        AWATER: 10900777,
        INTPTLAT: '+41.5133040',
        INTPTLON: '-079.2497050',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7067, 41.6248],
            [-78.4834, 41.6299],
            [-78.4191, 41.6022],
            [-78.4202, 41.5154],
            [-78.6423, 41.5181],
            [-78.6485, 41.4409],
            [-78.8144, 41.5182],
            [-78.7975, 41.6249],
            [-78.7067, 41.6248],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212570',
        NAME: 'Johnsonburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 425889365,
        AWATER: 6301868,
        INTPTLAT: '+41.5478162',
        INTPTLON: '-078.6512585',
        ELSDLEA: '',
        UNSDLEA: '12570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7805, 39.8493],
            [-75.6348, 39.8302],
            [-75.7514, 39.7595],
            [-75.7545, 39.7556],
            [-75.7805, 39.8493],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212690',
        NAME: 'Kennett Consolidated School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87647502,
        AWATER: 859412,
        INTPTLAT: '+39.8225243',
        INTPTLON: '-075.7110132',
        ELSDLEA: '',
        UNSDLEA: '12690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.0017, 39.8747],
            [-75.9017, 39.8431],
            [-75.9207, 39.7625],
            [-75.8419, 39.722],
            [-76.1355, 39.7211],
            [-76.1025, 39.724],
            [-76.0017, 39.8747],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218300',
        NAME: 'Oxford Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208835783,
        AWATER: 2189699,
        INTPTLAT: '+39.7800910',
        INTPTLON: '-075.9786560',
        ELSDLEA: '',
        UNSDLEA: '18300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4964, 40.0836],
            [-75.4566, 40.0939],
            [-75.3611, 40.0655],
            [-75.4043, 40.0274],
            [-75.4488, 39.9969],
            [-75.4964, 40.0836],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223640',
        NAME: 'Tredyffrin-Easttown School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72496529,
        AWATER: 330916,
        INTPTLAT: '+40.0551653',
        INTPTLON: '-075.4479057',
        ELSDLEA: '',
        UNSDLEA: '23640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9017, 39.8431],
            [-75.849, 39.8665],
            [-75.7805, 39.8493],
            [-75.7545, 39.7556],
            [-75.7886, 39.7222],
            [-75.8419, 39.722],
            [-75.9207, 39.7625],
            [-75.9017, 39.8431],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202790',
        NAME: 'Avon Grove School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161311366,
        AWATER: 1124250,
        INTPTLAT: '+39.7923685',
        INTPTLON: '-075.8373654',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.0713, 39.9687],
            [-75.9514, 40.0164],
            [-75.8465, 39.9222],
            [-75.849, 39.8665],
            [-75.9017, 39.8431],
            [-76.0017, 39.8747],
            [-76.0713, 39.9687],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218030',
        NAME: 'Octorara Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205692706,
        AWATER: 977220,
        INTPTLAT: '+39.9207546',
        INTPTLON: '-075.9459746',
        ELSDLEA: '',
        UNSDLEA: '18030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5927, 40.2255],
            [-75.5594, 40.2578],
            [-75.5191, 40.2713],
            [-75.4513, 40.1733],
            [-75.4626, 40.1196],
            [-75.5456, 40.1701],
            [-75.5927, 40.2255],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222560',
        NAME: 'Spring-Ford Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108425069,
        AWATER: 1699648,
        INTPTLAT: '+40.2025290',
        INTPTLON: '-075.5186845',
        ELSDLEA: '',
        UNSDLEA: '22560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5456, 40.1701],
            [-75.4626, 40.1196],
            [-75.4617, 40.103],
            [-75.4566, 40.0939],
            [-75.4964, 40.0836],
            [-75.5812, 40.094],
            [-75.6063, 40.1082],
            [-75.5456, 40.1701],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219050',
        NAME: 'Phoenixville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53754462,
        AWATER: 1992727,
        INTPTLAT: '+40.1233539',
        INTPTLON: '-075.5294573',
        ELSDLEA: '',
        UNSDLEA: '19050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5143, 40.9696],
            [-77.3399, 40.9872],
            [-77.1442, 41.0443],
            [-77.3642, 40.8469],
            [-77.6813, 40.7298],
            [-77.6996, 40.721],
            [-77.7508, 40.8236],
            [-77.5143, 40.9696],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218810',
        NAME: 'Penns Valley Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 662651765,
        AWATER: 405712,
        INTPTLAT: '+40.8784704',
        INTPTLON: '-077.4942954',
        ELSDLEA: '',
        UNSDLEA: '18810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.466, 39.8598],
            [-77.3224, 39.7871],
            [-77.3203, 39.7199],
            [-77.4594, 39.72],
            [-77.466, 39.8598],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209540',
        NAME: 'Fairfield Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159189228,
        AWATER: 501696,
        INTPTLAT: '+39.7805770',
        INTPTLON: '-077.4153963',
        ELSDLEA: '',
        UNSDLEA: '09540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4214, 39.9813],
            [-77.3537, 40.0033],
            [-77.2552, 40.0208],
            [-77.2289, 40.0251],
            [-77.1024, 39.9485],
            [-77.1537, 39.9289],
            [-77.2634, 39.8731],
            [-77.4214, 39.9813],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224300',
        NAME: 'Upper Adams School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216194774,
        AWATER: 675394,
        INTPTLAT: '+39.9659364',
        INTPTLON: '-077.2664668',
        ELSDLEA: '',
        UNSDLEA: '24300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1537, 39.9289],
            [-77.1024, 39.9485],
            [-77.1008, 39.9483],
            [-77.0871, 39.9336],
            [-76.9712, 39.932],
            [-76.9572, 39.8557],
            [-76.9997, 39.8196],
            [-76.9996, 39.795],
            [-76.9994, 39.7602],
            [-77.1795, 39.8198],
            [-77.1022, 39.876],
            [-77.1537, 39.9289],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206550',
        NAME: 'Conewago Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192797313,
        AWATER: 1683064,
        INTPTLAT: '+39.8545355',
        INTPTLON: '-077.0612589',
        ELSDLEA: '',
        UNSDLEA: '06550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2112, 41.1126],
            [-79.209, 41.317],
            [-79.2089, 41.3319],
            [-79.1119, 41.325],
            [-78.9595, 41.3715],
            [-78.9185, 41.3442],
            [-79.0146, 41.2692],
            [-78.8891, 41.2479],
            [-78.9466, 41.1384],
            [-78.9448, 41.071],
            [-79.2105, 41.0405],
            [-79.2112, 41.1126],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204320',
        NAME: 'Brookville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 675151071,
        AWATER: 5875970,
        INTPTLAT: '+41.1970741',
        INTPTLON: '-079.0672075',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.519, 40.7838],
            [-80.519, 40.8513],
            [-80.4085, 40.8524],
            [-80.4089, 40.7995],
            [-80.32, 40.7388],
            [-80.3247, 40.7237],
            [-80.419, 40.7152],
            [-80.519, 40.7337],
            [-80.519, 40.7838],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203688',
        NAME: 'Blackhawk School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182891761,
        AWATER: 448884,
        INTPTLAT: '+40.7797947',
        INTPTLON: '-080.4384838',
        ELSDLEA: '',
        UNSDLEA: '03688',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3174, 40.9982],
            [-80.264, 40.909],
            [-80.3495, 40.9075],
            [-80.3174, 40.9982],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221510',
        NAME: 'Shenango Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63987954,
        AWATER: 199284,
        INTPTLAT: '+40.9497572',
        INTPTLON: '-080.2980071',
        ELSDLEA: '',
        UNSDLEA: '21510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.519, 41.1251],
            [-80.3125, 41.1558],
            [-80.2571, 41.1572],
            [-80.0969, 41.0698],
            [-80.1341, 41.0311],
            [-80.3182, 41.0786],
            [-80.3891, 41.0627],
            [-80.519, 41.0615],
            [-80.519, 41.0829],
            [-80.519, 41.1251],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226520',
        NAME: 'Wilmington Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255398269,
        AWATER: 2041785,
        INTPTLAT: '+41.0978136',
        INTPTLON: '-080.3138315',
        ELSDLEA: '',
        UNSDLEA: '26520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4711, 39.9441],
            [-77.4214, 39.9813],
            [-77.2634, 39.8731],
            [-77.1537, 39.9289],
            [-77.1022, 39.876],
            [-77.1795, 39.8198],
            [-77.2002, 39.7198],
            [-77.217, 39.7199],
            [-77.3203, 39.7199],
            [-77.3224, 39.7871],
            [-77.466, 39.8598],
            [-77.4667, 39.8716],
            [-77.4711, 39.9441],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210710',
        NAME: 'Gettysburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 455594129,
        AWATER: 2382881,
        INTPTLAT: '+39.8500190',
        INTPTLON: '-077.2979299',
        ELSDLEA: '',
        UNSDLEA: '10710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2289, 40.0251],
            [-77.1781, 40.0368],
            [-77.1374, 40.0699],
            [-77.0544, 40.0232],
            [-76.9662, 39.9352],
            [-76.9712, 39.932],
            [-77.0871, 39.9336],
            [-77.1008, 39.9483],
            [-77.1024, 39.9485],
            [-77.2289, 40.0251],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203450',
        NAME: 'Bermudian Springs School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192539949,
        AWATER: 2309268,
        INTPTLAT: '+39.9811806',
        INTPTLON: '-077.0824520',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2002, 39.7198],
            [-77.1795, 39.8198],
            [-76.9994, 39.7602],
            [-76.9993, 39.7201],
            [-77.2002, 39.7198],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213980',
        NAME: 'Littlestown Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127084655,
        AWATER: 331627,
        INTPTLAT: '+39.7602808',
        INTPTLON: '-077.0958388',
        ELSDLEA: '',
        UNSDLEA: '13980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4475, 40.5916],
            [-78.302, 40.5739],
            [-78.2489, 40.5472],
            [-78.2701, 40.5302],
            [-78.3893, 40.4561],
            [-78.5371, 40.5099],
            [-78.4475, 40.5916],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202340',
        NAME: 'Altoona Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180804127,
        AWATER: 973687,
        INTPTLAT: '+40.5243984',
        INTPTLON: '-078.3972366',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6203, 40.3272],
            [-78.6019, 40.3532],
            [-78.5391, 40.3458],
            [-78.4343, 40.3023],
            [-78.467, 40.2072],
            [-78.5502, 40.2307],
            [-78.6204, 40.327],
            [-78.6203, 40.3272],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206120',
        NAME: 'Claysburg-Kimmel School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146143632,
        AWATER: 22024,
        INTPTLAT: '+40.3004830',
        INTPTLON: '-078.5166212',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2331, 40.6318],
            [-80.2352, 40.5859],
            [-80.2783, 40.6181],
            [-80.2331, 40.6318],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202130',
        NAME: 'Aliquippa School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10868132,
        AWATER: 1040304,
        INTPTLAT: '+40.6158274',
        INTPTLON: '-080.2551622',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2352, 40.5859],
            [-80.2331, 40.6318],
            [-80.2436, 40.6554],
            [-80.1487, 40.6738],
            [-80.1586, 40.6092],
            [-80.2286, 40.5731],
            [-80.2334, 40.5696],
            [-80.2352, 40.5859],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202440',
        NAME: 'Ambridge Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64403148,
        AWATER: 1871998,
        INTPTLAT: '+40.6450796',
        INTPTLON: '-080.1866476',
        ELSDLEA: '',
        UNSDLEA: '02440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.419, 40.7152],
            [-80.3247, 40.7237],
            [-80.3033, 40.7162],
            [-80.2876, 40.6955],
            [-80.344, 40.6713],
            [-80.419, 40.7152],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203120',
        NAME: 'Beaver Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56523713,
        AWATER: 1637551,
        INTPTLAT: '+40.7029837',
        INTPTLON: '-080.3500256',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.898, 40.8752],
            [-77.6237, 41.0167],
            [-77.5143, 40.9696],
            [-77.7508, 40.8236],
            [-77.898, 40.8752],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203240',
        NAME: 'Bellefonte Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302367102,
        AWATER: 12328,
        INTPTLAT: '+40.9252381',
        INTPTLON: '-077.6941037',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.3599, 40.7326],
            [-78.2789, 40.7366],
            [-78.1359, 40.8169],
            [-78.064, 40.776],
            [-77.9441, 40.6916],
            [-78.1832, 40.5953],
            [-78.2489, 40.5472],
            [-78.302, 40.5739],
            [-78.2604, 40.6378],
            [-78.3587, 40.7097],
            [-78.3504, 40.7248],
            [-78.3599, 40.7326],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224030',
        NAME: 'Tyrone Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 432636556,
        AWATER: 144175,
        INTPTLAT: '+40.6912113',
        INTPTLON: '-078.1815438',
        ELSDLEA: '',
        UNSDLEA: '24030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2701, 40.5302],
            [-78.2489, 40.5472],
            [-78.1832, 40.5953],
            [-78.1296, 40.4906],
            [-78.1892, 40.3955],
            [-78.2908, 40.4181],
            [-78.2701, 40.5302],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226430',
        NAME: 'Williamsburg Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164627759,
        AWATER: 25524,
        INTPTLAT: '+40.4853621',
        INTPTLON: '-078.2208046',
        ELSDLEA: '',
        UNSDLEA: '26430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0597, 41.0036],
            [-78.1122, 41.1142],
            [-77.973, 41.173],
            [-77.9594, 41.2175],
            [-77.8625, 41.1793],
            [-77.7513, 41.0186],
            [-77.6237, 41.0167],
            [-77.898, 40.8752],
            [-78.064, 40.776],
            [-78.1359, 40.8169],
            [-77.9642, 40.9237],
            [-78.0597, 41.0036],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202910',
        NAME: 'Bald Eagle Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 878768057,
        AWATER: 4833558,
        INTPTLAT: '+41.0129787',
        INTPTLON: '-077.9115677',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.9892, 41.4748],
            [-77.8262, 41.477],
            [-77.8268, 41.5672],
            [-77.5993, 41.5423],
            [-77.5847, 41.4413],
            [-77.5265, 41.3585],
            [-77.3704, 41.2331],
            [-77.2982, 41.1273],
            [-77.1742, 41.0783],
            [-77.1441, 41.0688],
            [-77.1442, 41.0443],
            [-77.3399, 40.9872],
            [-77.5143, 40.9696],
            [-77.6237, 41.0167],
            [-77.7513, 41.0186],
            [-77.8625, 41.1793],
            [-77.9594, 41.2175],
            [-78.0536, 41.2737],
            [-77.9888, 41.3675],
            [-77.9892, 41.4748],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212725',
        NAME: 'Keystone Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2491122962,
        AWATER: 23143263,
        INTPTLAT: '+41.2568489',
        INTPTLON: '-077.6584601',
        ELSDLEA: '',
        UNSDLEA: '12725',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3875, 41.2936],
            [-80.26, 41.3032],
            [-80.1814, 41.3033],
            [-80.1159, 41.2366],
            [-80.1315, 41.1773],
            [-80.2571, 41.1572],
            [-80.3125, 41.1558],
            [-80.3862, 41.2353],
            [-80.3869, 41.2713],
            [-80.3875, 41.2936],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215120',
        NAME: 'Mercer Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225846337,
        AWATER: 2975654,
        INTPTLAT: '+41.2359676',
        INTPTLON: '-080.2558898',
        ELSDLEA: '',
        UNSDLEA: '15120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5191, 41.361],
            [-80.3875, 41.2936],
            [-80.3869, 41.2713],
            [-80.5191, 41.268],
            [-80.5191, 41.2729],
            [-80.5191, 41.361],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221420',
        NAME: 'Sharpsville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61915086,
        AWATER: 6951766,
        INTPTLAT: '+41.2916028',
        INTPTLON: '-080.4694932',
        ELSDLEA: '',
        UNSDLEA: '21420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2717, 41.6622],
            [-80.0948, 41.6686],
            [-80.062, 41.5818],
            [-79.8929, 41.5623],
            [-79.9426, 41.5253],
            [-79.9998, 41.4325],
            [-80.1014, 41.4301],
            [-80.1646, 41.4814],
            [-80.1565, 41.5379],
            [-80.2726, 41.5878],
            [-80.2717, 41.6622],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207080',
        NAME: 'Crawford Central School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407747377,
        AWATER: 2258165,
        INTPTLAT: '+41.5618070',
        INTPTLON: '-080.0911753',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5191, 41.4407],
            [-80.3809, 41.4438],
            [-80.3209, 41.3658],
            [-80.2616, 41.3674],
            [-80.26, 41.3032],
            [-80.3875, 41.2936],
            [-80.5191, 41.361],
            [-80.5191, 41.4407],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220220',
        NAME: 'Reynolds School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223049879,
        AWATER: 4992945,
        INTPTLAT: '+41.3584323',
        INTPTLON: '-080.3973664',
        ELSDLEA: '',
        UNSDLEA: '20220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-80.5191, 41.268],
              [-80.3869, 41.2713],
              [-80.3862, 41.2353],
              [-80.4896, 41.1895],
              [-80.4869, 41.2182],
              [-80.5191, 41.2431],
              [-80.5191, 41.268],
            ],
          ],
          [
            [
              [-80.5192, 41.2085],
              [-80.4993, 41.1886],
              [-80.519, 41.1878],
              [-80.5191, 41.203],
              [-80.5192, 41.2085],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211820',
        NAME: 'Hermitage School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75461637,
        AWATER: 232935,
        INTPTLAT: '+41.2295517',
        INTPTLON: '-080.4407040',
        ELSDLEA: '',
        UNSDLEA: '11820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.4445, 40.6238],
            [-80.4688, 40.6369],
            [-80.4416, 40.6421],
            [-80.4445, 40.6238],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215270',
        NAME: 'Midland Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4685353,
        AWATER: 470596,
        INTPTLAT: '+40.6343582',
        INTPTLON: '-080.4580115',
        ELSDLEA: '',
        UNSDLEA: '15270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3247, 40.7237],
            [-80.32, 40.7388],
            [-80.3141, 40.7685],
            [-80.2394, 40.7696],
            [-80.2481, 40.724],
            [-80.3033, 40.7162],
            [-80.3247, 40.7237],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216530',
        NAME: 'New Brighton Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31390963,
        AWATER: 422206,
        INTPTLAT: '+40.7485451',
        INTPTLON: '-080.2741964',
        ELSDLEA: '',
        UNSDLEA: '16530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.4085, 40.8524],
            [-80.3226, 40.8532],
            [-80.3141, 40.7685],
            [-80.32, 40.7388],
            [-80.4089, 40.7995],
            [-80.4085, 40.8524],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203630',
        NAME: 'Big Beaver Falls Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56188893,
        AWATER: 1339128,
        INTPTLAT: '+40.8226396',
        INTPTLON: '-080.3576793',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0426, 39.9615],
            [-78.9807, 39.9817],
            [-78.8727, 39.9363],
            [-78.7824, 40.0388],
            [-78.7632, 40.0567],
            [-78.7589, 39.8898],
            [-78.763, 39.8069],
            [-78.8603, 39.7836],
            [-79.0811, 39.8964],
            [-79.0426, 39.9615],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203420',
        NAME: 'Berlin Brothersvalley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 427255926,
        AWATER: 69706,
        INTPTLAT: '+39.8912400',
        INTPTLON: '-078.8703100',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1498, 39.8526],
            [-79.0811, 39.8964],
            [-78.8603, 39.7836],
            [-78.763, 39.8069],
            [-78.8084, 39.7229],
            [-78.9312, 39.7228],
            [-79.0566, 39.7228],
            [-79.0173, 39.7711],
            [-79.1498, 39.8526],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215150',
        NAME: 'Meyersdale Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 318268252,
        AWATER: 109280,
        INTPTLAT: '+39.7909681',
        INTPTLON: '-078.9548050',
        ELSDLEA: '',
        UNSDLEA: '15150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8986, 40.1689],
            [-78.8548, 40.1771],
            [-78.7063, 40.1448],
            [-78.7632, 40.0567],
            [-78.7824, 40.0388],
            [-78.9073, 40.0842],
            [-78.8986, 40.1689],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221180',
        NAME: 'Shade-Central City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179395592,
        AWATER: 90829,
        INTPTLAT: '+40.1139761',
        INTPTLON: '-078.8300098',
        ELSDLEA: '',
        UNSDLEA: '21180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0737, 40.2648],
            [-78.9559, 40.1649],
            [-78.8986, 40.1689],
            [-78.9073, 40.0842],
            [-78.9656, 40.0534],
            [-79.1632, 40.141],
            [-79.0737, 40.2648],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210115',
        NAME: 'North Star School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267235342,
        AWATER: 3149690,
        INTPTLAT: '+40.1562742',
        INTPTLON: '-079.0276529',
        ELSDLEA: '',
        UNSDLEA: '10115',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1707, 39.8073],
            [-79.1498, 39.8526],
            [-79.0173, 39.7711],
            [-79.0566, 39.7228],
            [-79.2084, 39.722],
            [-79.1707, 39.8073],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220760',
        NAME: 'Salisbury-Elk Lick School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148783722,
        AWATER: 1528204,
        INTPTLAT: '+39.7778187',
        INTPTLON: '-079.1301614',
        ELSDLEA: '',
        UNSDLEA: '20760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2937, 40.0404],
            [-79.2934, 40.0403],
            [-79.2227, 39.9908],
            [-79.0426, 39.9615],
            [-79.0811, 39.8964],
            [-79.1498, 39.8526],
            [-79.1707, 39.8073],
            [-79.2734, 39.8275],
            [-79.3637, 39.9478],
            [-79.2937, 40.0404],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220520',
        NAME: 'Rockwood Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378764421,
        AWATER: 333078,
        INTPTLAT: '+39.9276631',
        INTPTLON: '-079.2183708',
        ELSDLEA: '',
        UNSDLEA: '20520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6684, 40.0309],
            [-76.6161, 39.9734],
            [-76.6604, 39.9618],
            [-76.7034, 39.9784],
            [-76.7063, 39.9787],
            [-76.7263, 39.9741],
            [-76.7308, 39.9715],
            [-76.7317, 39.9709],
            [-76.7611, 39.981],
            [-76.7989, 40.0007],
            [-76.6684, 40.0309],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205490',
        NAME: 'Central York School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75303277,
        AWATER: 71297,
        INTPTLAT: '+40.0016600',
        INTPTLON: '-076.7226836',
        ELSDLEA: '',
        UNSDLEA: '05490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8476, 40.094],
            [-76.7459, 40.1461],
            [-76.7216, 40.1201],
            [-76.6445, 40.0581],
            [-76.6684, 40.0309],
            [-76.7989, 40.0007],
            [-76.8477, 40.0938],
            [-76.8476, 40.094],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217520',
        NAME: 'Northeastern York School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128520945,
        AWATER: 2241478,
        INTPTLAT: '+40.0656554',
        INTPTLON: '-076.7531270',
        ELSDLEA: '',
        UNSDLEA: '17520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7722, 39.8417],
            [-76.6463, 39.8242],
            [-76.6714, 39.7836],
            [-76.6094, 39.7213],
            [-76.7871, 39.7208],
            [-76.8005, 39.7206],
            [-76.8731, 39.8105],
            [-76.7722, 39.8417],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222380',
        NAME: 'Southern York County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175717683,
        AWATER: 22309,
        INTPTLAT: '+39.7756374',
        INTPTLON: '-076.7377015',
        ELSDLEA: '',
        UNSDLEA: '22380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.746, 39.9494],
            [-76.7034, 39.9784],
            [-76.6604, 39.9618],
            [-76.6603, 39.9606],
            [-76.7482, 39.9142],
            [-76.7516, 39.9232],
            [-76.746, 39.9494],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226850',
        NAME: 'York Suburban School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26484850,
        AWATER: 51843,
        INTPTLAT: '+39.9515530',
        INTPTLON: '-076.7100090',
        ELSDLEA: '',
        UNSDLEA: '26850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.745, 40.3212],
            [-75.6437, 40.3959],
            [-75.6279, 40.4206],
            [-75.5623, 40.412],
            [-75.4749, 40.3146],
            [-75.4719, 40.3144],
            [-75.5191, 40.2713],
            [-75.5594, 40.2578],
            [-75.6452, 40.3049],
            [-75.6968, 40.2419],
            [-75.745, 40.3212],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203960',
        NAME: 'Boyertown Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252706531,
        AWATER: 1196726,
        INTPTLAT: '+40.3351915',
        INTPTLON: '-075.6117972',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3071, 40.4134],
            [-76.053, 40.3942],
            [-76.1206, 40.3452],
            [-76.0918, 40.2777],
            [-76.1512, 40.316],
            [-76.3071, 40.4134],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206780',
        NAME: 'Conrad Weiser Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153311560,
        AWATER: 1355322,
        INTPTLAT: '+40.3677307',
        INTPTLON: '-076.1496934',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9607, 40.3546],
            [-75.9533, 40.3683],
            [-75.8986, 40.3669],
            [-75.9101, 40.3227],
            [-75.9564, 40.3179],
            [-75.9416, 40.3285],
            [-75.9392, 40.331],
            [-75.9477, 40.3406],
            [-75.9607, 40.3546],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220040',
        NAME: 'Reading School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25567079,
        AWATER: 668286,
        INTPTLAT: '+40.3392200',
        INTPTLON: '-075.9262773',
        ELSDLEA: '',
        UNSDLEA: '20040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8911, 40.297],
            [-75.8192, 40.2705],
            [-75.8079, 40.1777],
            [-75.7795, 40.1159],
            [-75.8225, 40.0821],
            [-75.9343, 40.0476],
            [-75.9424, 40.1065],
            [-75.9434, 40.1827],
            [-75.8911, 40.297],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224000',
        NAME: 'Twin Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227433064,
        AWATER: 3269424,
        INTPTLAT: '+40.1624562',
        INTPTLON: '-075.8644919',
        ELSDLEA: '',
        UNSDLEA: '24000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.053, 40.3942],
            [-76.051, 40.3895],
            [-75.9607, 40.3546],
            [-75.9477, 40.3406],
            [-75.9818, 40.313],
            [-76.044, 40.2472],
            [-76.0441, 40.2472],
            [-76.0918, 40.2777],
            [-76.1206, 40.3452],
            [-76.053, 40.3942],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226580',
        NAME: 'Wilson School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93498620,
        AWATER: 1806386,
        INTPTLAT: '+40.3382883',
        INTPTLON: '-076.0261523',
        ELSDLEA: '',
        UNSDLEA: '26580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6386, 40.6797],
            [-75.5804, 40.735],
            [-75.5156, 40.7022],
            [-75.4947, 40.6164],
            [-75.5249, 40.5893],
            [-75.5113, 40.5825],
            [-75.5303, 40.5654],
            [-75.6415, 40.5177],
            [-75.6749, 40.539],
            [-75.6943, 40.5511],
            [-75.6203, 40.6207],
            [-75.6386, 40.6797],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218510',
        NAME: 'Parkland School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184438252,
        AWATER: 2192225,
        INTPTLAT: '+40.6260714',
        INTPTLON: '-075.5908381',
        ELSDLEA: '',
        UNSDLEA: '18510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-75.479, 40.5549],
              [-75.4196, 40.6161],
              [-75.3943, 40.593],
              [-75.4038, 40.5808],
              [-75.4756, 40.5301],
              [-75.479, 40.5549],
            ],
          ],
          [
            [
              [-75.5113, 40.5825],
              [-75.4832, 40.5565],
              [-75.5303, 40.5654],
              [-75.5113, 40.5825],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220730',
        NAME: 'Salisbury Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28933714,
        AWATER: 261014,
        INTPTLAT: '+40.5990896',
        INTPTLON: '-075.4260499',
        ELSDLEA: '',
        UNSDLEA: '20730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9698, 41.4742],
            [-76.8326, 41.4968],
            [-76.7724, 41.4717],
            [-76.6983, 41.3731],
            [-76.8246, 41.282],
            [-76.8499, 41.2294],
            [-76.8938, 41.2305],
            [-76.9434, 41.2345],
            [-76.9965, 41.2973],
            [-76.9698, 41.4742],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215750',
        NAME: 'Montoursville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 480847334,
        AWATER: 7042585,
        INTPTLAT: '+41.3901924',
        INTPTLON: '-076.8778634',
        ELSDLEA: '',
        UNSDLEA: '15750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4578, 41.631],
            [-77.2326, 41.6423],
            [-77.1769, 41.6823],
            [-77.1796, 41.8559],
            [-77.0453, 41.856],
            [-77.0483, 41.9134],
            [-76.9144, 41.9149],
            [-76.8839, 41.669],
            [-77.016, 41.6631],
            [-77.0108, 41.5527],
            [-77.0825, 41.4554],
            [-77.1672, 41.3418],
            [-77.2923, 41.3998],
            [-77.1943, 41.459],
            [-77.1931, 41.5458],
            [-77.3582, 41.5441],
            [-77.4548, 41.5436],
            [-77.4578, 41.631],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222370',
        NAME: 'Southern Tioga School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1255719086,
        AWATER: 1741274,
        INTPTLAT: '+41.6495019',
        INTPTLON: '-077.1091154',
        ELSDLEA: '',
        UNSDLEA: '22370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1672, 41.3418],
            [-77.0825, 41.4554],
            [-76.9698, 41.4742],
            [-76.9965, 41.2973],
            [-76.9819, 41.2447],
            [-77.1716, 41.1961],
            [-77.1285, 41.2694],
            [-77.1672, 41.3418],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226460',
        NAME: 'Williamsport Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259093873,
        AWATER: 4826173,
        INTPTLAT: '+41.3259702',
        INTPTLON: '-077.0644641',
        ELSDLEA: '',
        UNSDLEA: '26460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.763, 39.8069],
            [-78.7589, 39.8898],
            [-78.6711, 39.9306],
            [-78.675, 40.0069],
            [-78.5568, 40.0596],
            [-78.4828, 40.1519],
            [-78.3735, 40.0553],
            [-78.5054, 39.8724],
            [-78.5671, 39.8538],
            [-78.6583, 39.7229],
            [-78.8084, 39.7229],
            [-78.763, 39.8069],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203180',
        NAME: 'Bedford Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 750687645,
        AWATER: 3454438,
        INTPTLAT: '+39.9296628',
        INTPTLON: '-078.5801997',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7063, 40.1448],
            [-78.6584, 40.2429],
            [-78.6204, 40.327],
            [-78.5502, 40.2307],
            [-78.467, 40.2072],
            [-78.4828, 40.1519],
            [-78.5568, 40.0596],
            [-78.675, 40.0069],
            [-78.6711, 39.9306],
            [-78.7589, 39.8898],
            [-78.7632, 40.0567],
            [-78.7063, 40.1448],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205880',
        NAME: 'Chestnut Ridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 578823278,
        AWATER: 3002442,
        INTPTLAT: '+40.1156998',
        INTPTLON: '-078.6258722',
        ELSDLEA: '',
        UNSDLEA: '05880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6583, 39.7229],
            [-78.5671, 39.8538],
            [-78.5054, 39.8724],
            [-78.3735, 40.0553],
            [-78.312, 40.0568],
            [-78.2067, 40.0864],
            [-78.2055, 40.0338],
            [-78.299, 39.8255],
            [-78.3806, 39.7226],
            [-78.6583, 39.7229],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209360',
        NAME: 'Everett Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 758900835,
        AWATER: 2892274,
        INTPTLAT: '+39.8876229',
        INTPTLON: '-078.3939348',
        ELSDLEA: '',
        UNSDLEA: '09360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.467, 40.2072],
            [-78.4343, 40.3023],
            [-78.2882, 40.2436],
            [-78.2575, 40.1625],
            [-78.312, 40.0568],
            [-78.3735, 40.0553],
            [-78.4828, 40.1519],
            [-78.467, 40.2072],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217580',
        NAME: 'Northern Bedford County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287430421,
        AWATER: 873528,
        INTPTLAT: '+40.1757126',
        INTPTLON: '-078.3687905',
        ELSDLEA: '',
        UNSDLEA: '17580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2442, 40.8942],
            [-75.1604, 40.9485],
            [-75.139, 40.9544],
            [-75.1205, 40.9684],
            [-75.054, 40.8592],
            [-75.0889, 40.8325],
            [-75.084, 40.8243],
            [-75.1107, 40.7902],
            [-75.1413, 40.7739],
            [-75.175, 40.7738],
            [-75.1815, 40.7602],
            [-75.1905, 40.7565],
            [-75.1956, 40.7526],
            [-75.2205, 40.7781],
            [-75.2442, 40.8942],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203000',
        NAME: 'Bangor Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218886733,
        AWATER: 6907999,
        INTPTLAT: '+40.8661707',
        INTPTLON: '-075.1523056',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4742, 40.8147],
            [-75.3736, 40.8343],
            [-75.3766, 40.6938],
            [-75.4304, 40.6742],
            [-75.4836, 40.6669],
            [-75.5156, 40.7022],
            [-75.5804, 40.735],
            [-75.609, 40.7874],
            [-75.4742, 40.8147],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217370',
        NAME: 'Northampton Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247449121,
        AWATER: 4105150,
        INTPTLAT: '+40.7519716',
        INTPTLON: '-075.4682059',
        ELSDLEA: '',
        UNSDLEA: '17370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8285, 39.9435],
            [-76.7989, 40.0007],
            [-76.7611, 39.981],
            [-76.746, 39.9494],
            [-76.7516, 39.9232],
            [-76.8285, 39.9435],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225980',
        NAME: 'West York Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53025750,
        AWATER: 199601,
        INTPTLAT: '+39.9452999',
        INTPTLON: '-076.7923593',
        ELSDLEA: '',
        UNSDLEA: '25980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7611, 39.981],
            [-76.7317, 39.9709],
            [-76.7308, 39.9715],
            [-76.7263, 39.9741],
            [-76.7063, 39.9787],
            [-76.7034, 39.9784],
            [-76.746, 39.9494],
            [-76.7611, 39.981],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226820',
        NAME: 'York City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13709046,
        AWATER: 126495,
        INTPTLAT: '+39.9647979',
        INTPTLON: '-076.7318403',
        ELSDLEA: '',
        UNSDLEA: '26820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9712, 39.932],
            [-76.9662, 39.9352],
            [-76.8285, 39.9435],
            [-76.7516, 39.9232],
            [-76.7482, 39.9142],
            [-76.7722, 39.8417],
            [-76.8731, 39.8105],
            [-76.9572, 39.8557],
            [-76.9712, 39.932],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222590',
        NAME: 'Spring Grove Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238274658,
        AWATER: 2211468,
        INTPTLAT: '+39.8816110',
        INTPTLON: '-076.8738088',
        ELSDLEA: '',
        UNSDLEA: '22590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8477, 40.0938],
            [-76.7989, 40.0007],
            [-76.8285, 39.9435],
            [-76.9662, 39.9352],
            [-77.0544, 40.0232],
            [-76.9278, 40.0098],
            [-76.8477, 40.0938],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207680',
        NAME: 'Dover Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181591625,
        AWATER: 638652,
        INTPTLAT: '+40.0081064',
        INTPTLON: '-076.9178110',
        ELSDLEA: '',
        UNSDLEA: '07680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4732, 40.3613],
            [-79.4271, 40.3801],
            [-79.3101, 40.2842],
            [-79.3839, 40.2057],
            [-79.5425, 40.2338],
            [-79.4732, 40.3613],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210980',
        NAME: 'Greater Latrobe School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180957299,
        AWATER: 352528,
        INTPTLAT: '+40.2807514',
        INTPTLON: '-079.4260709',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5993, 40.3283],
            [-79.6113, 40.3104],
            [-79.6319, 40.3295],
            [-79.5993, 40.3283],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212330',
        NAME: 'Jeannette City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6062234,
        AWATER: 0,
        INTPTLAT: '+40.3276027',
        INTPTLON: '-079.6138498',
        ELSDLEA: '',
        UNSDLEA: '12330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2803, 39.975],
            [-75.2765, 39.977],
            [-75.2644, 40.0541],
            [-75.255, 40.0627],
            [-75.1764, 40.0846],
            [-75.0967, 40.069],
            [-75.0583, 40.1059],
            [-75.0151, 40.138],
            [-74.9936, 40.1323],
            [-74.974, 40.0486],
            [-74.9828, 40.0418],
            [-74.9902, 40.0363],
            [-75.0138, 40.0202],
            [-75.0306, 40.0162],
            [-75.0599, 39.9915],
            [-75.1238, 39.9627],
            [-75.1289, 39.9074],
            [-75.1398, 39.8883],
            [-75.1456, 39.8845],
            [-75.1893, 39.8807],
            [-75.1999, 39.8732],
            [-75.2115, 39.867],
            [-75.2644, 39.8822],
            [-75.2525, 39.9066],
            [-75.2472, 39.9439],
            [-75.2803, 39.975],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218990',
        NAME: 'Philadelphia City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347519325,
        AWATER: 22089787,
        INTPTLAT: '+40.0093755',
        INTPTLON: '-075.1333459',
        ELSDLEA: '',
        UNSDLEA: '18990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9003, 40.1625],
            [-79.8707, 40.1621],
            [-79.9042, 40.152],
            [-79.9003, 40.1625],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215600',
        NAME: 'Monessen City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7477362,
        AWATER: 354670,
        INTPTLAT: '+40.1517893',
        INTPTLON: '-079.8830704',
        ELSDLEA: '',
        UNSDLEA: '15600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.764, 40.5927],
            [-79.7492, 40.599],
            [-79.7659, 40.5509],
            [-79.7719, 40.563],
            [-79.764, 40.5927],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216740',
        NAME: 'New Kensington-Arnold School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12124121,
        AWATER: 901088,
        INTPTLAT: '+40.5735209',
        INTPTLON: '-079.7535107',
        ELSDLEA: '',
        UNSDLEA: '16740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7744, 40.3597],
            [-79.7711, 40.3754],
            [-79.6716, 40.335],
            [-79.6951, 40.2918],
            [-79.7751, 40.2879],
            [-79.7879, 40.2977],
            [-79.7744, 40.3597],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217940',
        NAME: 'Norwin School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73613631,
        AWATER: 145877,
        INTPTLAT: '+40.3293919',
        INTPTLON: '-079.7321462',
        ELSDLEA: '',
        UNSDLEA: '17940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.3839, 40.2057],
            [-79.3101, 40.2842],
            [-79.1927, 40.4149],
            [-79.1619, 40.4108],
            [-79.1022, 40.3689],
            [-78.9746, 40.396],
            [-78.9745, 40.3949],
            [-79.0339, 40.3165],
            [-79.056, 40.2851],
            [-79.0737, 40.2648],
            [-79.1632, 40.141],
            [-79.229, 40.0978],
            [-79.3839, 40.2057],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213710',
        NAME: 'Ligonier Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 595768428,
        AWATER: 2113517,
        INTPTLAT: '+40.2640078',
        INTPTLON: '-079.1909618',
        ELSDLEA: '',
        UNSDLEA: '13710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5194, 41.7147],
            [-80.5194, 41.7911],
            [-80.5194, 41.8496],
            [-80.267, 41.8495],
            [-80.2717, 41.6622],
            [-80.2726, 41.5878],
            [-80.1565, 41.5379],
            [-80.1646, 41.4814],
            [-80.326, 41.4843],
            [-80.3801, 41.4859],
            [-80.4003, 41.5761],
            [-80.5194, 41.5785],
            [-80.5194, 41.7147],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206590',
        NAME: 'Conneaut School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 783026139,
        AWATER: 42079804,
        INTPTLAT: '+41.6706177',
        INTPTLON: '-080.3681333',
        ELSDLEA: '',
        UNSDLEA: '06590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.5659, 40.7856],
            [-78.4314, 40.7918],
            [-78.4313, 40.7255],
            [-78.3504, 40.7248],
            [-78.3587, 40.7097],
            [-78.4402, 40.6388],
            [-78.4965, 40.6389],
            [-78.5765, 40.6385],
            [-78.5896, 40.7254],
            [-78.5659, 40.7856],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210830',
        NAME: 'Glendale School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248704923,
        AWATER: 7057816,
        INTPTLAT: '+40.7174075',
        INTPTLON: '-078.4916209',
        ELSDLEA: '',
        UNSDLEA: '10830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2831, 40.9693],
            [-78.0777, 40.9588],
            [-78.0597, 41.0036],
            [-77.9642, 40.9237],
            [-78.1359, 40.8169],
            [-78.2789, 40.7366],
            [-78.3599, 40.7326],
            [-78.3015, 40.8229],
            [-78.437, 40.9128],
            [-78.3945, 40.9825],
            [-78.2831, 40.9693],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219020',
        NAME: 'Philipsburg-Osceola Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 579781947,
        AWATER: 2221234,
        INTPTLAT: '+40.8782578',
        INTPTLON: '-078.2183499',
        ELSDLEA: '',
        UNSDLEA: '19020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9466, 41.1384],
            [-78.8141, 41.1328],
            [-78.7664, 41.2028],
            [-78.6893, 41.2071],
            [-78.6467, 41.2536],
            [-78.4847, 41.2439],
            [-78.4852, 41.148],
            [-78.5794, 41.1401],
            [-78.5835, 41.0528],
            [-78.5944, 41.0022],
            [-78.7075, 40.9932],
            [-78.804, 40.9939],
            [-78.8042, 41.0402],
            [-78.9448, 41.071],
            [-78.9466, 41.1384],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207830',
        NAME: 'DuBois Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 663161100,
        AWATER: 5608069,
        INTPTLAT: '+41.1166281',
        INTPTLON: '-078.6996201',
        ELSDLEA: '',
        UNSDLEA: '07830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1139, 40.5755],
            [-80.064, 40.5422],
            [-80.0616, 40.5058],
            [-80.075, 40.4984],
            [-80.1203, 40.5131],
            [-80.1139, 40.5755],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202820',
        NAME: 'Avonworth School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27224013,
        AWATER: 684410,
        INTPTLAT: '+40.5320981',
        INTPTLON: '-080.0906501',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0714, 40.6742],
            [-80.0554, 40.6741],
            [-79.9242, 40.673],
            [-79.9206, 40.6161],
            [-79.9911, 40.6143],
            [-80.0714, 40.6742],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202850',
        NAME: 'Pine-Richland School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81826372,
        AWATER: 33866,
        INTPTLAT: '+40.6473506',
        INTPTLON: '-079.9955736',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.074, 40.2998],
            [-80.0512, 40.351],
            [-80.0334, 40.3551],
            [-80.0171, 40.359],
            [-79.9981, 40.3382],
            [-80.0331, 40.2878],
            [-80.0337, 40.288],
            [-80.074, 40.2998],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203510',
        NAME: 'Bethel Park School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30429120,
        AWATER: 0,
        INTPTLAT: '+40.3241242',
        INTPTLON: '-080.0363000',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.986, 40.3778],
            [-79.9741, 40.3848],
            [-79.9621, 40.3707],
            [-79.9823, 40.3609],
            [-79.986, 40.3778],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204140',
        NAME: 'Brentwood Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3748844,
        AWATER: 0,
        INTPTLAT: '+40.3733698',
        INTPTLON: '-079.9757859',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0807, 40.4398],
            [-80.0764, 40.4418],
            [-80.0612, 40.4406],
            [-80.0787, 40.4165],
            [-80.0998, 40.411],
            [-80.0807, 40.4398],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205040',
        NAME: 'Carlynton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8599883,
        AWATER: 0,
        INTPTLAT: '+40.4191332',
        INTPTLON: '-080.0813144',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1713, 40.5274],
            [-80.1203, 40.5131],
            [-80.075, 40.4984],
            [-80.0675, 40.4923],
            [-80.0991, 40.4994],
            [-80.1464, 40.5068],
            [-80.1713, 40.5274],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206810',
        NAME: 'Cornell School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7406100,
        AWATER: 2452614,
        INTPTLAT: '+40.5053786',
        INTPTLON: '-080.1159088',
        ELSDLEA: '',
        UNSDLEA: '06810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9242, 40.673],
            [-79.8771, 40.6723],
            [-79.817, 40.6718],
            [-79.764, 40.5927],
            [-79.7719, 40.563],
            [-79.8114, 40.5665],
            [-79.9161, 40.5964],
            [-79.9206, 40.6161],
            [-79.9242, 40.673],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207540',
        NAME: 'Deer Lakes School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104858900,
        AWATER: 667691,
        INTPTLAT: '+40.6224613',
        INTPTLON: '-079.8477075',
        ELSDLEA: '',
        UNSDLEA: '07540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8545, 40.3888],
            [-79.8535, 40.3883],
            [-79.8362, 40.365],
            [-79.8479, 40.3569],
            [-79.8545, 40.3888],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4208010',
        NAME: 'Duquesne City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4708648,
        AWATER: 563601,
        INTPTLAT: '+40.3734609',
        INTPTLON: '-079.8501617',
        ELSDLEA: '',
        UNSDLEA: '08010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8535, 40.3883],
            [-79.815, 40.4012],
            [-79.7654, 40.3853],
            [-79.7711, 40.3754],
            [-79.7744, 40.3597],
            [-79.8362, 40.365],
            [-79.8535, 40.3883],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4208060',
        NAME: 'East Allegheny School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24107359,
        AWATER: 428835,
        INTPTLAT: '+40.3801249',
        INTPTLON: '-079.8073714',
        ELSDLEA: '',
        UNSDLEA: '08060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9387, 40.554],
            [-79.9161, 40.5964],
            [-79.8114, 40.5665],
            [-79.8505, 40.5205],
            [-79.8503, 40.4959],
            [-79.8838, 40.4834],
            [-79.9384, 40.491],
            [-79.9387, 40.554],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210070',
        NAME: 'Fox Chapel Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86980601,
        AWATER: 1799417,
        INTPTLAT: '+40.5435091',
        INTPTLON: '-079.8846542',
        ELSDLEA: '',
        UNSDLEA: '10070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8026, 40.4386],
            [-79.7794, 40.461],
            [-79.7038, 40.4435],
            [-79.7222, 40.4091],
            [-79.7654, 40.3853],
            [-79.815, 40.4012],
            [-79.8026, 40.4386],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210620',
        NAME: 'Gateway School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52370094,
        AWATER: 15818,
        INTPTLAT: '+40.4243902',
        INTPTLON: '-079.7582537',
        ELSDLEA: '',
        UNSDLEA: '10620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7942, 40.4542],
            [-75.7506, 40.4698],
            [-75.6749, 40.539],
            [-75.6415, 40.5177],
            [-75.5898, 40.4847],
            [-75.6279, 40.4206],
            [-75.6437, 40.3959],
            [-75.7942, 40.4542],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204050',
        NAME: 'Brandywine Heights Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134754941,
        AWATER: 401821,
        INTPTLAT: '+40.4670541',
        INTPTLON: '-075.6819451',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8192, 40.2705],
            [-75.78, 40.3263],
            [-75.745, 40.3212],
            [-75.6968, 40.2419],
            [-75.8079, 40.1777],
            [-75.8192, 40.2705],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207290',
        NAME: 'Daniel Boone Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110941858,
        AWATER: 1878493,
        INTPTLAT: '+40.2495030',
        INTPTLON: '-075.7558168',
        ELSDLEA: '',
        UNSDLEA: '07290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.899, 40.4256],
            [-75.8886, 40.4295],
            [-75.8986, 40.3669],
            [-75.9533, 40.3683],
            [-75.899, 40.4256],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216200',
        NAME: 'Muhlenberg School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32622113,
        AWATER: 580874,
        INTPTLAT: '+40.3952815',
        INTPTLON: '-075.9237150',
        ELSDLEA: '',
        UNSDLEA: '16200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2401, 40.5205],
            [-76.2036, 40.5305],
            [-76.1972, 40.4683],
            [-76.0724, 40.4835],
            [-76.051, 40.3895],
            [-76.053, 40.3942],
            [-76.3071, 40.4134],
            [-76.341, 40.4344],
            [-76.4402, 40.496],
            [-76.2401, 40.5205],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223820',
        NAME: 'Tulpehocken Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260366941,
        AWATER: 2039683,
        INTPTLAT: '+40.4620249',
        INTPTLON: '-076.2227874',
        ELSDLEA: '',
        UNSDLEA: '23820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9477, 40.3406],
            [-75.9392, 40.331],
            [-75.9416, 40.3285],
            [-75.9564, 40.3179],
            [-75.9818, 40.313],
            [-75.9477, 40.3406],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226760',
        NAME: 'Wyomissing Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9065750,
        AWATER: 43016,
        INTPTLAT: '+40.3263034',
        INTPTLON: '-075.9694162',
        ELSDLEA: '',
        UNSDLEA: '26760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1832, 40.5953],
            [-77.9441, 40.6916],
            [-77.8943, 40.7162],
            [-77.8626, 40.6232],
            [-78.1296, 40.4906],
            [-78.1832, 40.5953],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212630',
        NAME: 'Juniata Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347692522,
        AWATER: 1527380,
        INTPTLAT: '+40.5943549',
        INTPTLON: '-078.0465846',
        ELSDLEA: '',
        UNSDLEA: '12630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2882, 40.2436],
            [-78.2431, 40.3225],
            [-78.1239, 40.3425],
            [-78.0292, 40.2616],
            [-78.0784, 40.1332],
            [-78.1342, 40.1649],
            [-78.2067, 40.0864],
            [-78.312, 40.0568],
            [-78.2575, 40.1625],
            [-78.2882, 40.2436],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223970',
        NAME: 'Tussey Mountain School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 443038281,
        AWATER: 5455240,
        INTPTLAT: '+40.2072905',
        INTPTLON: '-078.1840774',
        ELSDLEA: '',
        UNSDLEA: '23970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6944, 41.5799],
            [-76.2201, 41.5413],
            [-76.2831, 41.3765],
            [-76.3101, 41.3102],
            [-76.4476, 41.2756],
            [-76.5925, 41.3043],
            [-76.6983, 41.3731],
            [-76.7724, 41.4717],
            [-76.8137, 41.59],
            [-76.6944, 41.5799],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222920',
        NAME: 'Sullivan County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1165338400,
        AWATER: 6617028,
        INTPTLAT: '+41.4392848',
        INTPTLON: '-076.5117166',
        ELSDLEA: '',
        UNSDLEA: '22920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.881, 41.158],
            [-76.6946, 41.1596],
            [-76.6408, 41.1557],
            [-76.6213, 41.0916],
            [-76.7723, 41.0163],
            [-76.8805, 41.0906],
            [-76.9779, 41.0879],
            [-76.881, 41.158],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224870',
        NAME: 'Warrior Run School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290328999,
        AWATER: 4901919,
        INTPTLAT: '+41.1068528',
        INTPTLON: '-076.7877534',
        ELSDLEA: '',
        UNSDLEA: '24870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7617, 41.9378],
            [-79.7612, 41.9988],
            [-79.528, 41.9986],
            [-79.511, 41.9985],
            [-79.4739, 41.8972],
            [-79.4923, 41.7892],
            [-79.6126, 41.775],
            [-79.7714, 41.7742],
            [-79.7617, 41.9378],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206860',
        NAME: 'Corry Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 545092329,
        AWATER: 1187073,
        INTPTLAT: '+41.8815805',
        INTPTLON: '-079.6349846',
        ELSDLEA: '',
        UNSDLEA: '06860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.212, 42.0845],
            [-80.1775, 42.0305],
            [-80.2645, 41.9703],
            [-80.2989, 42.0483],
            [-80.212, 42.0845],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209570',
        NAME: 'Fairview School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75029157,
        AWATER: 496361,
        INTPTLAT: '+42.0263133',
        INTPTLON: '-080.2366460',
        ELSDLEA: '',
        UNSDLEA: '09570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.386, 42.0192],
            [-80.2989, 42.0483],
            [-80.2645, 41.9703],
            [-80.2654, 41.94],
            [-80.3581, 41.9601],
            [-80.386, 42.0192],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210740',
        NAME: 'Girard School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92309438,
        AWATER: 720974,
        INTPTLAT: '+41.9852564',
        INTPTLON: '-080.3307610',
        ELSDLEA: '',
        UNSDLEA: '10740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0213, 42.1631],
            [-80.0094, 42.1285],
            [-80.0414, 42.1536],
            [-80.0213, 42.1631],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212210',
        NAME: 'Iroquois School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6125886,
        AWATER: 2815,
        INTPTLAT: '+42.1461602',
        INTPTLON: '-080.0265793',
        ELSDLEA: '',
        UNSDLEA: '12210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5194, 41.9775],
            [-80.386, 42.0192],
            [-80.3581, 41.9601],
            [-80.2654, 41.94],
            [-80.2065, 41.849],
            [-80.267, 41.8495],
            [-80.5194, 41.8496],
            [-80.5194, 41.9775],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217880',
        NAME: 'Northwestern School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 312220291,
        AWATER: 1966363,
        INTPTLAT: '+41.9161890',
        INTPTLON: '-080.3865786',
        ELSDLEA: '',
        UNSDLEA: '17880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.91, 41.8507],
            [-79.8854, 41.938],
            [-79.7617, 41.9378],
            [-79.7714, 41.7742],
            [-79.9101, 41.7739],
            [-79.91, 41.8507],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224090',
        NAME: 'Union City Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197382896,
        AWATER: 1378111,
        INTPTLAT: '+41.8601388',
        INTPTLON: '-079.8383873',
        ELSDLEA: '',
        UNSDLEA: '24090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2654, 41.94],
            [-80.2645, 41.9703],
            [-80.1775, 42.0305],
            [-80.126, 42.0478],
            [-80.0449, 41.9991],
            [-80.0352, 41.8501],
            [-80.2065, 41.849],
            [-80.2654, 41.94],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210650',
        NAME: 'General McLane School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293926533,
        AWATER: 2002787,
        INTPTLAT: '+41.9386098',
        INTPTLON: '-080.1488960',
        ELSDLEA: '',
        UNSDLEA: '10650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1687, 40.402],
            [-80.1681, 40.4219],
            [-80.0998, 40.411],
            [-80.0787, 40.4165],
            [-80.0739, 40.4134],
            [-80.0512, 40.4019],
            [-80.0727, 40.3638],
            [-80.11, 40.3515],
            [-80.1687, 40.402],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205700',
        NAME: 'Chartiers Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48855338,
        AWATER: 126711,
        INTPTLAT: '+40.3945091',
        INTPTLON: '-080.1216282',
        ELSDLEA: '',
        UNSDLEA: '05700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9141, 40.2525],
            [-79.8777, 40.2826],
            [-79.8342, 40.2913],
            [-79.8376, 40.3147],
            [-79.7879, 40.2977],
            [-79.7751, 40.2879],
            [-79.7818, 40.2277],
            [-79.8706, 40.1974],
            [-79.9141, 40.2525],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209090',
        NAME: 'Elizabeth Forward School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109098337,
        AWATER: 3559893,
        INTPTLAT: '+40.2521724',
        INTPTLON: '-079.8671379',
        ELSDLEA: '',
        UNSDLEA: '09090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-80.0212, 40.3766],
              [-80.0067, 40.3749],
              [-80.0016, 40.3748],
              [-80.0171, 40.359],
              [-80.0334, 40.3551],
              [-80.0212, 40.3766],
            ],
          ],
          [
            [
              [-80.0739, 40.4134],
              [-80.0638, 40.4264],
              [-80.0348, 40.4284],
              [-80.0512, 40.4019],
              [-80.0739, 40.4134],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212750',
        NAME: 'Keystone Oaks School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11278213,
        AWATER: 0,
        INTPTLAT: '+40.3955109',
        INTPTLON: '-080.0391304',
        ELSDLEA: '',
        UNSDLEA: '12750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2334, 40.5696],
            [-80.2286, 40.5731],
            [-80.1713, 40.5274],
            [-80.1464, 40.5068],
            [-80.1722, 40.4583],
            [-80.2751, 40.5388],
            [-80.2334, 40.5696],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215830',
        NAME: 'Moon Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67147429,
        AWATER: 1300174,
        INTPTLAT: '+40.5099111',
        INTPTLON: '-080.2078474',
        ELSDLEA: '',
        UNSDLEA: '15830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9406, 40.3747],
            [-79.9088, 40.3767],
            [-79.8844, 40.4073],
            [-79.8545, 40.3888],
            [-79.8479, 40.3569],
            [-79.8919, 40.339],
            [-79.8967, 40.3197],
            [-79.8988, 40.3195],
            [-79.964, 40.345],
            [-79.9406, 40.3747],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225680',
        NAME: 'West Mifflin Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37576968,
        AWATER: 846835,
        INTPTLAT: '+40.3612065',
        INTPTLON: '-079.8956408',
        ELSDLEA: '',
        UNSDLEA: '25680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4147, 41.9138],
            [-76.3815, 41.9985],
            [-76.295, 41.9984],
            [-76.1455, 41.9988],
            [-76.1376, 41.9143],
            [-76.1276, 41.8013],
            [-76.3117, 41.8215],
            [-76.4092, 41.8338],
            [-76.4147, 41.9138],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217460',
        NAME: 'Northeast Bradford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 429141301,
        AWATER: 2917536,
        INTPTLAT: '+41.9002285',
        INTPTLON: '-076.2644140',
        ELSDLEA: '',
        UNSDLEA: '17460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9271, 42.0017],
            [-76.7473, 42.0017],
            [-76.7507, 41.9031],
            [-76.6785, 41.9061],
            [-76.6721, 41.809],
            [-76.5207, 41.7955],
            [-76.5245, 41.7232],
            [-76.634, 41.7083],
            [-76.8839, 41.669],
            [-76.9144, 41.9149],
            [-76.9271, 42.0017],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223790',
        NAME: 'Troy Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 712836107,
        AWATER: 3123098,
        INTPTLAT: '+41.8158009',
        INTPTLON: '-076.7586737',
        ELSDLEA: '',
        UNSDLEA: '23790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3611, 40.0655],
            [-75.3495, 40.0529],
            [-75.3207, 40.0162],
            [-75.3632, 39.9979],
            [-75.4043, 40.0274],
            [-75.3611, 40.0655],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219920',
        NAME: 'Radnor Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35680199,
        AWATER: 35216,
        INTPTLAT: '+40.0228749',
        INTPTLON: '-075.3734336',
        ELSDLEA: '',
        UNSDLEA: '19920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3565, 39.8721],
            [-75.3561, 39.8867],
            [-75.3503, 39.8908],
            [-75.3389, 39.8985],
            [-75.3287, 39.9055],
            [-75.3285, 39.9056],
            [-75.3138, 39.9169],
            [-75.3067, 39.9116],
            [-75.2998, 39.9048],
            [-75.3165, 39.85],
            [-75.3414, 39.8461],
            [-75.3565, 39.8721],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220370',
        NAME: 'Ridley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18626787,
        AWATER: 1879160,
        INTPTLAT: '+39.8785821',
        INTPTLON: '-075.3286026',
        ELSDLEA: '',
        UNSDLEA: '20370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3067, 39.9116],
            [-75.2948, 39.9164],
            [-75.2753, 39.9232],
            [-75.2525, 39.9066],
            [-75.2644, 39.8822],
            [-75.2998, 39.9048],
            [-75.3067, 39.9116],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222400',
        NAME: 'Southeast Delco School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11142068,
        AWATER: 474155,
        INTPTLAT: '+39.9017963',
        INTPTLON: '-075.2756737',
        ELSDLEA: '',
        UNSDLEA: '22400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3648, 39.9302],
            [-75.3351, 39.955],
            [-75.3317, 39.9522],
            [-75.3138, 39.9169],
            [-75.3285, 39.9056],
            [-75.3287, 39.9055],
            [-75.3389, 39.8985],
            [-75.3648, 39.9302],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222600',
        NAME: 'Springfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17297846,
        AWATER: 45741,
        INTPTLAT: '+39.9271435',
        INTPTLON: '-075.3354009',
        ELSDLEA: '',
        UNSDLEA: '22600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6594, 40.0973],
            [-79.6272, 40.2015],
            [-79.5578, 40.1866],
            [-79.5355, 40.126],
            [-79.622, 40.0466],
            [-79.6594, 40.0973],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222410',
        NAME: 'Southmoreland School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109052425,
        AWATER: 74439,
        INTPTLAT: '+40.1272113',
        INTPTLON: '-079.5941228',
        ELSDLEA: '',
        UNSDLEA: '22410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7038, 40.4435],
            [-79.7016, 40.5254],
            [-79.6796, 40.5074],
            [-79.5981, 40.4417],
            [-79.5959, 40.3985],
            [-79.7222, 40.4091],
            [-79.7038, 40.4435],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210230',
        NAME: 'Franklin Regional School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98250938,
        AWATER: 17004,
        INTPTLAT: '+40.4426312',
        INTPTLON: '-079.6541522',
        ELSDLEA: '',
        UNSDLEA: '10230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6951, 40.2918],
            [-79.6716, 40.335],
            [-79.6319, 40.3295],
            [-79.6113, 40.3104],
            [-79.5993, 40.3283],
            [-79.5712, 40.3751],
            [-79.4732, 40.3613],
            [-79.5425, 40.2338],
            [-79.5578, 40.1866],
            [-79.6272, 40.2015],
            [-79.6951, 40.2918],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211760',
        NAME: 'Hempfield Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216515417,
        AWATER: 441929,
        INTPTLAT: '+40.2807347',
        INTPTLON: '-079.5870526',
        ELSDLEA: '',
        UNSDLEA: '11760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7473, 42.0017],
            [-76.7057, 42.0013],
            [-76.5577, 42.0002],
            [-76.5234, 41.9997],
            [-76.4734, 41.9991],
            [-76.4147, 41.9138],
            [-76.4092, 41.8338],
            [-76.5207, 41.7955],
            [-76.6721, 41.809],
            [-76.6785, 41.9061],
            [-76.7507, 41.9031],
            [-76.7473, 42.0017],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202670',
        NAME: 'Athens Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 465269444,
        AWATER: 8690104,
        INTPTLAT: '+41.9017215',
        INTPTLON: '-076.5733967',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-75.2008, 40.6068],
              [-75.1968, 40.6086],
              [-75.1896, 40.5918],
              [-75.1911, 40.5859],
              [-75.2008, 40.6068],
            ],
          ],
          [
            [
              [-75.2502, 40.7591],
              [-75.2205, 40.7781],
              [-75.1956, 40.7526],
              [-75.1861, 40.7257],
              [-75.198, 40.7065],
              [-75.1902, 40.6794],
              [-75.2689, 40.6443],
              [-75.2822, 40.6411],
              [-75.277, 40.6924],
              [-75.2502, 40.7591],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4208850',
        NAME: 'Easton Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77625261,
        AWATER: 3279534,
        INTPTLAT: '+40.7196360',
        INTPTLON: '-075.2293100',
        ELSDLEA: '',
        UNSDLEA: '08850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4836, 40.6669],
            [-75.4304, 40.6742],
            [-75.4237, 40.636],
            [-75.4629, 40.6288],
            [-75.4836, 40.6669],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205160',
        NAME: 'Catasauqua Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16094940,
        AWATER: 237799,
        INTPTLAT: '+40.6511182',
        INTPTLON: '-075.4421634',
        ELSDLEA: '',
        UNSDLEA: '05160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3943, 40.593],
            [-75.2822, 40.6411],
            [-75.2689, 40.6443],
            [-75.2565, 40.5827],
            [-75.3335, 40.5371],
            [-75.4038, 40.5808],
            [-75.3943, 40.593],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211730',
        NAME: 'Saucon Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66320949,
        AWATER: 632215,
        INTPTLAT: '+40.5915826',
        INTPTLON: '-075.3043531',
        ELSDLEA: '',
        UNSDLEA: '11730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3161, 40.8566],
            [-75.2948, 40.8622],
            [-75.2442, 40.8942],
            [-75.2205, 40.7781],
            [-75.2502, 40.7591],
            [-75.3161, 40.8566],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218570',
        NAME: 'Pen Argyl Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71062029,
        AWATER: 783292,
        INTPTLAT: '+40.8217417',
        INTPTLON: '-075.2616776',
        ELSDLEA: '',
        UNSDLEA: '18570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2689, 40.6443],
            [-75.1902, 40.6794],
            [-75.1806, 40.6686],
            [-75.1968, 40.6086],
            [-75.2008, 40.6068],
            [-75.2565, 40.5827],
            [-75.2689, 40.6443],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226550',
        NAME: 'Wilson Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52609788,
        AWATER: 1263848,
        INTPTLAT: '+40.6436458',
        INTPTLON: '-075.2266049',
        ELSDLEA: '',
        UNSDLEA: '26550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4183, 39.8562],
            [-75.3784, 39.8749],
            [-75.3565, 39.8721],
            [-75.3414, 39.8461],
            [-75.3458, 39.8444],
            [-75.3898, 39.8159],
            [-75.4183, 39.8562],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205860',
        NAME: 'Chester-Upland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17912701,
        AWATER: 3021370,
        INTPTLAT: '+39.8482812',
        INTPTLON: '-075.3779810',
        ELSDLEA: '',
        UNSDLEA: '05860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4724, 39.8269],
            [-75.4554, 39.866],
            [-75.4183, 39.8562],
            [-75.3898, 39.8159],
            [-75.4151, 39.8019],
            [-75.4724, 39.8269],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205910',
        NAME: 'Chichester School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25717144,
        AWATER: 2173340,
        INTPTLAT: '+39.8316702',
        INTPTLON: '-075.4330257',
        ELSDLEA: '',
        UNSDLEA: '05910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.552, 39.8878],
            [-75.4749, 39.9062],
            [-75.4503, 39.8939],
            [-75.4554, 39.866],
            [-75.4724, 39.8269],
            [-75.5513, 39.8394],
            [-75.552, 39.8878],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210590',
        NAME: 'Garnet Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55032323,
        AWATER: 63428,
        INTPTLAT: '+39.8681712',
        INTPTLON: '-075.5053802',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3632, 39.9979],
            [-75.3207, 40.0162],
            [-75.2765, 39.977],
            [-75.2803, 39.975],
            [-75.3317, 39.9522],
            [-75.3351, 39.955],
            [-75.3632, 39.9979],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211670',
        NAME: 'Haverford Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25757808,
        AWATER: 0,
        INTPTLAT: '+39.9867378',
        INTPTLON: '-075.3163940',
        ELSDLEA: '',
        UNSDLEA: '11670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4503, 39.8939],
            [-75.3861, 39.8881],
            [-75.3784, 39.8749],
            [-75.4183, 39.8562],
            [-75.4554, 39.866],
            [-75.4503, 39.8939],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218580',
        NAME: 'Penn-Delco School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20092267,
        AWATER: 11849,
        INTPTLAT: '+39.8704547',
        INTPTLON: '-075.4246678',
        ELSDLEA: '',
        UNSDLEA: '18580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.056, 40.2851],
            [-78.9279, 40.2842],
            [-78.904, 40.2751],
            [-78.8839, 40.2447],
            [-78.8548, 40.1771],
            [-78.8986, 40.1689],
            [-78.9559, 40.1649],
            [-79.0737, 40.2648],
            [-79.056, 40.2851],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206420',
        NAME: 'Conemaugh Township Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139576807,
        AWATER: 1700497,
        INTPTLAT: '+40.2305248',
        INTPTLON: '-078.9441523',
        ELSDLEA: '',
        UNSDLEA: '06420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9656, 40.0534],
            [-78.9073, 40.0842],
            [-78.7824, 40.0388],
            [-78.8727, 39.9363],
            [-78.9807, 39.9817],
            [-78.9656, 40.0534],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221270',
        NAME: 'Shanksville-Stonycreek School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168670680,
        AWATER: 2730342,
        INTPTLAT: '+40.0133398',
        INTPTLON: '-078.8813478',
        ELSDLEA: '',
        UNSDLEA: '21270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2934, 40.0403],
            [-79.229, 40.0978],
            [-79.1632, 40.141],
            [-78.9656, 40.0534],
            [-78.9807, 39.9817],
            [-79.0426, 39.9615],
            [-79.2227, 39.9908],
            [-79.2934, 40.0403],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221840',
        NAME: 'Somerset Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347293826,
        AWATER: 1488758,
        INTPTLAT: '+40.0493073',
        INTPTLON: '-079.1171449',
        ELSDLEA: '',
        UNSDLEA: '21840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.3827, 39.9224],
            [-79.3637, 39.9478],
            [-79.2734, 39.8275],
            [-79.1707, 39.8073],
            [-79.2084, 39.722],
            [-79.3925, 39.7216],
            [-79.3657, 39.8205],
            [-79.3827, 39.9224],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223880',
        NAME: 'Turkeyfoot Valley Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260051074,
        AWATER: 5630339,
        INTPTLAT: '+39.8114326',
        INTPTLON: '-079.2941686',
        ELSDLEA: '',
        UNSDLEA: '23880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3317, 39.9522],
            [-75.2803, 39.975],
            [-75.2472, 39.9439],
            [-75.2948, 39.9164],
            [-75.3067, 39.9116],
            [-75.3138, 39.9169],
            [-75.3317, 39.9522],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224320',
        NAME: 'Upper Darby School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22092180,
        AWATER: 0,
        INTPTLAT: '+39.9477463',
        INTPTLON: '-075.2892943',
        ELSDLEA: '',
        UNSDLEA: '24320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3649, 39.9303],
            [-75.3648, 39.9302],
            [-75.3389, 39.8985],
            [-75.3503, 39.8908],
            [-75.3561, 39.8867],
            [-75.3565, 39.8721],
            [-75.3784, 39.8749],
            [-75.3861, 39.8881],
            [-75.3649, 39.9303],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224790',
        NAME: 'Wallingford-Swarthmore School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18100339,
        AWATER: 21141,
        INTPTLAT: '+39.8977526',
        INTPTLON: '-075.3684986',
        ELSDLEA: '',
        UNSDLEA: '24790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1955, 40.1882],
            [-75.1432, 40.1569],
            [-75.133, 40.1509],
            [-75.1705, 40.1118],
            [-75.1963, 40.1278],
            [-75.2334, 40.1506],
            [-75.1955, 40.1882],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224390',
        NAME: 'Upper Dublin School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34275588,
        AWATER: 78555,
        INTPTLAT: '+40.1513407',
        INTPTLON: '-075.1799989',
        ELSDLEA: '',
        UNSDLEA: '24390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1432, 40.1569],
            [-75.1069, 40.1923],
            [-75.1049, 40.1911],
            [-75.0902, 40.1825],
            [-75.0601, 40.1648],
            [-75.0724, 40.1575],
            [-75.0756, 40.1399],
            [-75.0948, 40.1282],
            [-75.133, 40.1509],
            [-75.1432, 40.1569],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224510',
        NAME: 'Upper Moreland Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20648509,
        AWATER: 11927,
        INTPTLAT: '+40.1573623',
        INTPTLON: '-075.1042790',
        ELSDLEA: '',
        UNSDLEA: '24510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4488, 39.9969],
            [-75.4043, 40.0274],
            [-75.3632, 39.9979],
            [-75.3351, 39.955],
            [-75.3648, 39.9302],
            [-75.3649, 39.9303],
            [-75.4361, 39.9849],
            [-75.4488, 39.9969],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214760',
        NAME: 'Marple Newtown School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52369075,
        AWATER: 1001933,
        INTPTLAT: '+39.9785892',
        INTPTLON: '-075.3882756',
        ELSDLEA: '',
        UNSDLEA: '14760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2472, 39.9439],
            [-75.2525, 39.9066],
            [-75.2753, 39.9232],
            [-75.2948, 39.9164],
            [-75.2472, 39.9439],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226390',
        NAME: 'William Penn School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12120827,
        AWATER: 0,
        INTPTLAT: '+39.9305796',
        INTPTLON: '-075.2649940',
        ELSDLEA: '',
        UNSDLEA: '26390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.5021, 39.9544],
            [-75.4361, 39.9849],
            [-75.3649, 39.9303],
            [-75.3861, 39.8881],
            [-75.4503, 39.8939],
            [-75.4749, 39.9062],
            [-75.5021, 39.9544],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220550',
        NAME: 'Rose Tree Media School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76494480,
        AWATER: 606094,
        INTPTLAT: '+39.9259439',
        INTPTLON: '-075.4367941',
        ELSDLEA: '',
        UNSDLEA: '20550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2481, 40.724],
            [-80.2394, 40.7696],
            [-80.1545, 40.7727],
            [-80.1485, 40.6743],
            [-80.1487, 40.6738],
            [-80.2436, 40.6554],
            [-80.2661, 40.6918],
            [-80.2481, 40.724],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4210380',
        NAME: 'Freedom Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89543272,
        AWATER: 818294,
        INTPTLAT: '+40.7242080',
        INTPTLON: '-080.2026226',
        ELSDLEA: '',
        UNSDLEA: '10380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.634, 41.7083],
            [-76.5245, 41.7232],
            [-76.5207, 41.7955],
            [-76.4092, 41.8338],
            [-76.3117, 41.8215],
            [-76.2911, 41.7453],
            [-76.3917, 41.6524],
            [-76.4936, 41.6044],
            [-76.5493, 41.6565],
            [-76.6852, 41.5993],
            [-76.634, 41.7083],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223550',
        NAME: 'Towanda Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412403454,
        AWATER: 8390152,
        INTPTLAT: '+41.7190946',
        INTPTLON: '-076.4468531',
        ELSDLEA: '',
        UNSDLEA: '23550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6872, 41.3392],
            [-75.6832, 41.3389],
            [-75.6249, 41.3927],
            [-75.5603, 41.4128],
            [-75.5358, 41.4339],
            [-75.4992, 41.5317],
            [-75.4564, 41.5336],
            [-75.4415, 41.2619],
            [-75.3906, 41.2956],
            [-75.3722, 41.238],
            [-75.5398, 41.2255],
            [-75.6007, 41.1615],
            [-75.6529, 41.2583],
            [-75.6872, 41.3392],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217310',
        NAME: 'North Pocono School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 489454185,
        AWATER: 7926567,
        INTPTLAT: '+41.3239645',
        INTPTLON: '-075.5421308',
        ELSDLEA: '',
        UNSDLEA: '17310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2322, 40.0078],
            [-80.2264, 40.0078],
            [-80.1573, 39.9996],
            [-80.1266, 39.9889],
            [-80.1007, 39.8683],
            [-80.0417, 39.7213],
            [-80.2938, 39.7212],
            [-80.3009, 39.8063],
            [-80.2231, 39.8243],
            [-80.257, 39.9178],
            [-80.2322, 40.0078],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205430',
        NAME: 'Central Greene School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439435458,
        AWATER: 286005,
        INTPTLAT: '+39.8374011',
        INTPTLON: '-080.1745632',
        ELSDLEA: '',
        UNSDLEA: '05430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9844, 39.952],
            [-79.9256, 39.9148],
            [-79.9278, 39.8629],
            [-80.0797, 39.8699],
            [-79.9844, 39.952],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205070',
        NAME: 'Carmichaels Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99510949,
        AWATER: 1687297,
        INTPTLAT: '+39.8895572',
        INTPTLON: '-079.9933451',
        ELSDLEA: '',
        UNSDLEA: '05070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5191, 39.9622],
            [-80.2874, 40.0192],
            [-80.2322, 40.0078],
            [-80.257, 39.9178],
            [-80.2231, 39.8243],
            [-80.3009, 39.8063],
            [-80.2938, 39.7212],
            [-80.4214, 39.7212],
            [-80.5193, 39.7214],
            [-80.5191, 39.9622],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225440',
        NAME: 'West Greene School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 655047280,
        AWATER: 318817,
        INTPTLAT: '+39.8516489',
        INTPTLON: '-080.3729478',
        ELSDLEA: '',
        UNSDLEA: '25440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1266, 39.9889],
            [-79.998, 39.9833],
            [-79.9844, 39.952],
            [-80.0797, 39.8699],
            [-80.1007, 39.8683],
            [-80.1266, 39.9889],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212390',
        NAME: 'Jefferson-Morgan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122574905,
        AWATER: 661217,
        INTPTLAT: '+39.9368828',
        INTPTLON: '-080.0767201',
        ELSDLEA: '',
        UNSDLEA: '12390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1007, 39.8683],
            [-80.0797, 39.8699],
            [-79.9278, 39.8629],
            [-79.9163, 39.7208],
            [-80.0417, 39.7213],
            [-80.1007, 39.8683],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222200',
        NAME: 'Southeastern Greene School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175132378,
        AWATER: 2300528,
        INTPTLAT: '+39.7890605',
        INTPTLON: '-079.9901817',
        ELSDLEA: '',
        UNSDLEA: '22200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4304, 40.6742],
            [-75.3766, 40.6938],
            [-75.277, 40.6924],
            [-75.2822, 40.6411],
            [-75.3943, 40.593],
            [-75.4196, 40.6161],
            [-75.4237, 40.636],
            [-75.4304, 40.6742],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203570',
        NAME: 'Bethlehem Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107467647,
        AWATER: 1580186,
        INTPTLAT: '+40.6453283',
        INTPTLON: '-075.3532412',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3736, 40.8343],
            [-75.3161, 40.8566],
            [-75.2502, 40.7591],
            [-75.277, 40.6924],
            [-75.3766, 40.6938],
            [-75.3736, 40.8343],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216380',
        NAME: 'Nazareth Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126624423,
        AWATER: 1651399,
        INTPTLAT: '+40.7586717',
        INTPTLON: '-075.3251956',
        ELSDLEA: '',
        UNSDLEA: '16380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.117, 40.0297],
            [-77.9805, 40.0023],
            [-77.8893, 40.04],
            [-77.9446, 39.9398],
            [-78.0305, 39.7979],
            [-78.089, 39.8104],
            [-78.0689, 39.9114],
            [-78.1785, 39.9495],
            [-78.117, 40.0297],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214850',
        NAME: 'Central Fulton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311270457,
        AWATER: 967450,
        INTPTLAT: '+39.9368435',
        INTPTLON: '-078.0379418',
        ELSDLEA: '',
        UNSDLEA: '14850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.834, 40.1078],
            [-74.8382, 40.1011],
            [-74.8566, 40.0913],
            [-74.834, 40.1078],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204200',
        NAME: 'Bristol Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4207303,
        AWATER: 599116,
        INTPTLAT: '+40.1035231',
        INTPTLON: '-074.8528570',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1166, 40.4337],
            [-75.0607, 40.4213],
            [-75.0438, 40.4124],
            [-75.0412, 40.4105],
            [-74.981, 40.3192],
            [-75.0727, 40.2152],
            [-75.1378, 40.2109],
            [-75.1876, 40.2415],
            [-75.2606, 40.2901],
            [-75.1166, 40.4337],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205310',
        NAME: 'Central Bucks School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309999598,
        AWATER: 3500589,
        INTPTLAT: '+40.3165109',
        INTPTLON: '-075.1177634',
        ELSDLEA: '',
        UNSDLEA: '05310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.9988, 40.1715],
            [-74.8876, 40.2247],
            [-74.8688, 40.1658],
            [-74.8985, 40.1322],
            [-74.9936, 40.1323],
            [-75.0151, 40.138],
            [-75.0293, 40.1465],
            [-74.9988, 40.1715],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216410',
        NAME: 'Neshaminy School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71040977,
        AWATER: 1271811,
        INTPTLAT: '+40.1709851',
        INTPTLON: '-074.9281981',
        ELSDLEA: '',
        UNSDLEA: '16410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.2998, 40.3078],
            [-75.2606, 40.2901],
            [-75.1876, 40.2415],
            [-75.2268, 40.2074],
            [-75.2935, 40.1933],
            [-75.3695, 40.2169],
            [-75.3858, 40.2264],
            [-75.2998, 40.3078],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217280',
        NAME: 'North Penn School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110668670,
        AWATER: 5916,
        INTPTLAT: '+40.2454904',
        INTPTLON: '-075.2852603',
        ELSDLEA: '',
        UNSDLEA: '17280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.8531, 40.2697],
            [-74.8195, 40.2385],
            [-74.7819, 40.2219],
            [-74.7838, 40.2036],
            [-74.7609, 40.1992],
            [-74.7517, 40.1838],
            [-74.7242, 40.1473],
            [-74.776, 40.125],
            [-74.8156, 40.1277],
            [-74.8688, 40.1658],
            [-74.8876, 40.2247],
            [-74.8531, 40.2697],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218840',
        NAME: 'Pennsbury School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107884069,
        AWATER: 16382571,
        INTPTLAT: '+40.1911385',
        INTPTLON: '-074.8133967',
        ELSDLEA: '',
        UNSDLEA: '18840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0681, 40.885],
            [-79.9725, 40.8867],
            [-79.9748, 40.9561],
            [-79.784, 40.9563],
            [-79.6915, 40.8849],
            [-79.692, 40.8134],
            [-79.9701, 40.8164],
            [-80.0631, 40.8173],
            [-80.0681, 40.885],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204590',
        NAME: 'Butler Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372166305,
        AWATER: 1419764,
        INTPTLAT: '+40.8750737',
        INTPTLON: '-079.8802606',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3322, 40.2382],
            [-80.3113, 40.25],
            [-80.2145, 40.2235],
            [-80.1349, 40.1828],
            [-80.1602, 40.094],
            [-80.1573, 39.9996],
            [-80.2264, 40.0078],
            [-80.2413, 40.1247],
            [-80.3382, 40.2099],
            [-80.3322, 40.2382],
          ],
          [
            [-80.2699, 40.169],
            [-80.2235, 40.1607],
            [-80.2273, 40.18],
            [-80.2542, 40.1851],
            [-80.2699, 40.169],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4223760',
        NAME: 'Trinity Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232977958,
        AWATER: 438651,
        INTPTLAT: '+40.1220313',
        INTPTLON: '-080.2140220',
        ELSDLEA: '',
        UNSDLEA: '23760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0403, 40.0726],
            [-79.9891, 40.1121],
            [-79.8501, 40.114],
            [-79.8425, 40.0839],
            [-79.8429, 40.0836],
            [-79.8538, 40.0781],
            [-79.8807, 40.0392],
            [-79.908, 40.0259],
            [-80.0403, 40.0726],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204710',
        NAME: 'California Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88241133,
        AWATER: 1315470,
        INTPTLAT: '+40.0761735',
        INTPTLON: '-079.9247819',
        ELSDLEA: '',
        UNSDLEA: '04710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5191, 40.1597],
            [-80.5189, 40.1959],
            [-80.3382, 40.2099],
            [-80.2413, 40.1247],
            [-80.2264, 40.0078],
            [-80.2322, 40.0078],
            [-80.2874, 40.0192],
            [-80.5191, 39.9622],
            [-80.5191, 40.0164],
            [-80.5191, 40.1597],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214880',
        NAME: 'McGuffey School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 513306195,
        AWATER: 779069,
        INTPTLAT: '+40.0865994',
        INTPTLON: '-080.3915937',
        ELSDLEA: '',
        UNSDLEA: '14880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1349, 40.1828],
            [-80.1086, 40.1923],
            [-80.0203, 40.1918],
            [-79.9891, 40.1121],
            [-80.0403, 40.0726],
            [-80.1602, 40.094],
            [-80.1349, 40.1828],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203360',
        NAME: 'Bentworth School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152848491,
        AWATER: 147526,
        INTPTLAT: '+40.1226648',
        INTPTLON: '-080.0707211',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1602, 40.094],
            [-80.0403, 40.0726],
            [-79.908, 40.0259],
            [-79.998, 39.9833],
            [-80.1266, 39.9889],
            [-80.1573, 39.9996],
            [-80.1602, 40.094],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203600',
        NAME: 'Bethlehem-Center School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141634538,
        AWATER: 1658598,
        INTPTLAT: '+40.0263762',
        INTPTLON: '-080.0498362',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6994, 40.7661],
            [-75.6091, 40.7875],
            [-75.609, 40.7874],
            [-75.5804, 40.735],
            [-75.6386, 40.6797],
            [-75.6994, 40.7661],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217670',
        NAME: 'Northern Lehigh School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66310520,
        AWATER: 859926,
        INTPTLAT: '+40.7397189',
        INTPTLON: '-075.6356059',
        ELSDLEA: '',
        UNSDLEA: '17670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6415, 40.5177],
            [-75.5303, 40.5654],
            [-75.4832, 40.5565],
            [-75.479, 40.5549],
            [-75.4756, 40.5301],
            [-75.5308, 40.4477],
            [-75.5898, 40.4847],
            [-75.6415, 40.5177],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4208550',
        NAME: 'East Penn School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116272909,
        AWATER: 449709,
        INTPTLAT: '+40.5137022',
        INTPTLON: '-075.5438785',
        ELSDLEA: '',
        UNSDLEA: '08550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9962, 40.63],
            [-75.8935, 40.6765],
            [-75.8915, 40.6773],
            [-75.6943, 40.5511],
            [-75.6749, 40.539],
            [-75.7506, 40.4698],
            [-75.8746, 40.5258],
            [-75.9962, 40.63],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212930',
        NAME: 'Kutztown Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255979187,
        AWATER: 572357,
        INTPTLAT: '+40.5785624',
        INTPTLON: '-075.8319275',
        ELSDLEA: '',
        UNSDLEA: '12930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0804, 40.111],
            [-77.0126, 40.1484],
            [-76.9221, 40.1623],
            [-76.8476, 40.094],
            [-76.8477, 40.0938],
            [-76.9278, 40.0098],
            [-77.0544, 40.0232],
            [-77.1374, 40.0699],
            [-77.0804, 40.111],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217760',
        NAME: 'Northern York County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216476358,
        AWATER: 2214410,
        INTPTLAT: '+40.0813693',
        INTPTLON: '-076.9883483',
        ELSDLEA: '',
        UNSDLEA: '17760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.058, 40.5796],
            [-76.1554, 40.545],
            [-76.1569, 40.6286],
            [-76.058, 40.5796],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220910',
        NAME: 'Schuylkill Haven Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60941051,
        AWATER: 1032882,
        INTPTLAT: '+40.5931600',
        INTPTLON: '-076.1354488',
        ELSDLEA: '',
        UNSDLEA: '20910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3505, 40.2497],
            [-76.3365, 40.2513],
            [-76.2839, 40.2644],
            [-76.221, 40.1479],
            [-76.2266, 40.1151],
            [-76.336, 40.1271],
            [-76.3505, 40.2497],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224960',
        NAME: 'Warwick School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102261205,
        AWATER: 1119678,
        INTPTLAT: '+40.1810317',
        INTPTLON: '-076.2953802',
        ELSDLEA: '',
        UNSDLEA: '24960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0414, 42.1536],
            [-80.0094, 42.1285],
            [-80.0034, 42.1173],
            [-80.1123, 42.0772],
            [-80.1536, 42.1155],
            [-80.0414, 42.1536],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209300',
        NAME: 'Erie City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56856428,
        AWATER: 15578924,
        INTPTLAT: '+42.1252472',
        INTPTLON: '-080.0849951',
        ELSDLEA: '',
        UNSDLEA: '09300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.126, 42.0478],
            [-80.0257, 42.0833],
            [-80.0008, 41.999],
            [-79.892, 41.9988],
            [-79.8854, 41.938],
            [-79.91, 41.8507],
            [-80.0352, 41.8501],
            [-80.0449, 41.9991],
            [-80.126, 42.0478],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209990',
        NAME: 'Fort LeBoeuf School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283902950,
        AWATER: 1992043,
        INTPTLAT: '+41.9507740',
        INTPTLON: '-079.9876028',
        ELSDLEA: '',
        UNSDLEA: '09990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.907, 42.2155],
            [-79.9037, 42.1555],
            [-79.9937, 42.102],
            [-80.0034, 42.1173],
            [-80.0094, 42.1285],
            [-80.0213, 42.1631],
            [-79.907, 42.2155],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211490',
        NAME: 'Harbor Creek School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88289859,
        AWATER: 56907,
        INTPTLAT: '+42.1540069',
        INTPTLON: '-079.9638475',
        ELSDLEA: '',
        UNSDLEA: '11490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1536, 42.1155],
            [-80.1123, 42.0772],
            [-80.0034, 42.1173],
            [-79.9937, 42.102],
            [-80.0257, 42.0833],
            [-80.126, 42.0478],
            [-80.1775, 42.0305],
            [-80.212, 42.0845],
            [-80.1536, 42.1155],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215330',
        NAME: 'Millcreek Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75776121,
        AWATER: 99101,
        INTPTLAT: '+42.0779755',
        INTPTLON: '-080.1211854',
        ELSDLEA: '',
        UNSDLEA: '15330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.907, 42.2155],
            [-79.7619, 42.2693],
            [-79.7621, 42.2311],
            [-79.7618, 42.1733],
            [-79.7617, 42.1553],
            [-79.9037, 42.1555],
            [-79.907, 42.2155],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217160',
        NAME: 'North East School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112536606,
        AWATER: 609274,
        INTPTLAT: '+42.2004852',
        INTPTLON: '-079.8262289',
        ELSDLEA: '',
        UNSDLEA: '17160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0727, 40.2152],
            [-74.9988, 40.1715],
            [-75.0293, 40.1465],
            [-75.0601, 40.1648],
            [-75.0902, 40.1825],
            [-75.1049, 40.1911],
            [-75.1069, 40.1923],
            [-75.1378, 40.2109],
            [-75.0727, 40.2152],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205190',
        NAME: 'Centennial School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44376618,
        AWATER: 86016,
        INTPTLAT: '+40.1919628',
        INTPTLON: '-075.0695367',
        ELSDLEA: '',
        UNSDLEA: '05190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.7819, 40.2219],
            [-74.7609, 40.1992],
            [-74.7838, 40.2036],
            [-74.7819, 40.2219],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215900',
        NAME: 'Morrisville Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4525745,
        AWATER: 583797,
        INTPTLAT: '+40.2076440',
        INTPTLON: '-074.7802694',
        ELSDLEA: '',
        UNSDLEA: '15900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4841, 40.4185],
            [-75.4088, 40.4886],
            [-75.2312, 40.5171],
            [-75.1885, 40.4719],
            [-75.4104, 40.3745],
            [-75.4841, 40.4185],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219890',
        NAME: 'Quakertown Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183286076,
        AWATER: 4049367,
        INTPTLAT: '+40.4529643',
        INTPTLON: '-075.3488552',
        ELSDLEA: '',
        UNSDLEA: '19890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.0412, 40.4105],
            [-74.986, 40.4068],
            [-74.9666, 40.3982],
            [-74.9541, 40.3765],
            [-74.9458, 40.355],
            [-74.9429, 40.3417],
            [-74.9269, 40.3295],
            [-74.981, 40.3192],
            [-75.0412, 40.4105],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216860',
        NAME: 'New Hope-Solebury School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72109915,
        AWATER: 1885624,
        INTPTLAT: '+40.3675348',
        INTPTLON: '-075.0100998',
        ELSDLEA: '',
        UNSDLEA: '16860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.3757, 41.884],
            [-78.2641, 41.9262],
            [-78.1496, 41.9133],
            [-78.0916, 41.8167],
            [-78.0925, 41.7365],
            [-78.2043, 41.7364],
            [-78.2039, 41.6756],
            [-78.2997, 41.6757],
            [-78.3733, 41.7635],
            [-78.3757, 41.884],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219530',
        NAME: 'Port Allegany School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 441190256,
        AWATER: 1365357,
        INTPTLAT: '+41.8138482',
        INTPTLON: '-078.2304385',
        ELSDLEA: '',
        UNSDLEA: '19530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0925, 41.7365],
            [-78.0689, 41.6506],
            [-77.9643, 41.6229],
            [-77.8414, 41.6517],
            [-77.8268, 41.5672],
            [-77.8262, 41.477],
            [-77.9892, 41.4748],
            [-78.0504, 41.4755],
            [-78.2034, 41.6182],
            [-78.2039, 41.6756],
            [-78.2043, 41.7364],
            [-78.0925, 41.7365],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202700',
        NAME: 'Austin Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 585467099,
        AWATER: 8044,
        INTPTLAT: '+41.6031809',
        INTPTLON: '-078.0097562',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0916, 41.8167],
            [-78.0898, 41.9133],
            [-77.9632, 41.9135],
            [-77.8759, 41.8164],
            [-77.8448, 41.7271],
            [-77.8414, 41.6517],
            [-77.9643, 41.6229],
            [-78.0689, 41.6506],
            [-78.0925, 41.7365],
            [-78.0916, 41.8167],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206930',
        NAME: 'Coudersport Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 548866677,
        AWATER: 63524,
        INTPTLAT: '+41.7655387',
        INTPTLON: '-077.9674654',
        ELSDLEA: '',
        UNSDLEA: '06930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.3071, 41.9994],
            [-78.284, 41.9991],
            [-78.0726, 41.9999],
            [-77.9628, 41.9988],
            [-77.9632, 41.9135],
            [-78.0898, 41.9133],
            [-78.0916, 41.8167],
            [-78.1496, 41.9133],
            [-78.2641, 41.9262],
            [-78.3071, 41.9994],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218210',
        NAME: 'Oswayo Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316878895,
        AWATER: 540621,
        INTPTLAT: '+41.9402076',
        INTPTLON: '-078.1130371',
        ELSDLEA: '',
        UNSDLEA: '18210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-75.1315, 40.9893],
              [-75.1213, 40.9692],
              [-75.1205, 40.9684],
              [-75.139, 40.9544],
              [-75.1315, 40.9893],
            ],
          ],
          [
            [
              [-75.2235, 41.0734],
              [-75.1604, 40.9485],
              [-75.2442, 40.8942],
              [-75.2948, 40.8622],
              [-75.3554, 40.9425],
              [-75.2235, 41.0734],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222860',
        NAME: 'Stroudsburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189161055,
        AWATER: 1532691,
        INTPTLAT: '+40.9665684',
        INTPTLON: '-075.2499352',
        ELSDLEA: '',
        UNSDLEA: '22860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4974, 40.9884],
            [-75.4161, 41.0221],
            [-75.3554, 40.9425],
            [-75.2948, 40.8622],
            [-75.3161, 40.8566],
            [-75.3736, 40.8343],
            [-75.4742, 40.8147],
            [-75.5564, 40.9287],
            [-75.4974, 40.9884],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219290',
        NAME: 'Pleasant Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299358376,
        AWATER: 1451303,
        INTPTLAT: '+40.9133939',
        INTPTLON: '-075.4359677',
        ELSDLEA: '',
        UNSDLEA: '19290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9032, 40.3603],
            [-78.8648, 40.3925],
            [-78.8165, 40.3925],
            [-78.8133, 40.3511],
            [-78.859, 40.3124],
            [-78.9032, 40.3603],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206430',
        NAME: 'Conemaugh Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54785358,
        AWATER: 1088606,
        INTPTLAT: '+40.3524643',
        INTPTLON: '-078.8613725',
        ELSDLEA: '',
        UNSDLEA: '06430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6813, 40.7298],
            [-77.3642, 40.8469],
            [-77.3566, 40.8073],
            [-77.3541, 40.7017],
            [-77.2879, 40.6936],
            [-77.4978, 40.6095],
            [-77.7219, 40.4085],
            [-77.8259, 40.4928],
            [-77.8418, 40.55],
            [-77.6871, 40.6767],
            [-77.6813, 40.7298],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215290',
        NAME: 'Mifflin County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 937451488,
        AWATER: 7194334,
        INTPTLAT: '+40.6373849',
        INTPTLON: '-077.5969746',
        ELSDLEA: '',
        UNSDLEA: '15290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7733, 41.998],
            [-75.7422, 41.9979],
            [-75.6556, 41.9985],
            [-75.6483, 41.9098],
            [-75.5688, 41.9132],
            [-75.5626, 41.822],
            [-75.7891, 41.8127],
            [-75.7733, 41.998],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203900',
        NAME: 'Blue Ridge School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 281808169,
        AWATER: 4454219,
        INTPTLAT: '+41.8973531',
        INTPTLON: '-075.6861696',
        ELSDLEA: '',
        UNSDLEA: '03900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1455, 41.9988],
            [-76.1058, 41.9989],
            [-75.9936, 41.999],
            [-75.7733, 41.998],
            [-75.7891, 41.8127],
            [-75.8459, 41.767],
            [-76.0106, 41.7742],
            [-76.0411, 41.9059],
            [-76.1376, 41.9143],
            [-76.1455, 41.9988],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215810',
        NAME: 'Montrose Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 585115965,
        AWATER: 4965939,
        INTPTLAT: '+41.8971791',
        INTPTLON: '-075.9476212',
        ELSDLEA: '',
        UNSDLEA: '15810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8259, 40.4928],
            [-77.7219, 40.4085],
            [-77.7393, 40.3492],
            [-77.856, 40.263],
            [-77.9616, 40.3138],
            [-78.113, 40.3489],
            [-77.9554, 40.4432],
            [-77.8259, 40.4928],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216020',
        NAME: 'Mount Union Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 383744302,
        AWATER: 3624653,
        INTPTLAT: '+40.3676379',
        INTPTLON: '-077.8741035',
        ELSDLEA: '',
        UNSDLEA: '16020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6974, 40.4335],
            [-78.6859, 40.429],
            [-78.6203, 40.3272],
            [-78.6204, 40.327],
            [-78.6584, 40.2429],
            [-78.8026, 40.2443],
            [-78.8133, 40.3511],
            [-78.8165, 40.3925],
            [-78.6974, 40.4335],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209940',
        NAME: 'Forest Hills School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246835861,
        AWATER: 2267223,
        INTPTLAT: '+40.3268755',
        INTPTLON: '-078.7225093',
        ELSDLEA: '',
        UNSDLEA: '09940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6859, 40.429],
            [-78.5634, 40.3748],
            [-78.6019, 40.3532],
            [-78.6203, 40.3272],
            [-78.6859, 40.429],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219560',
        NAME: 'Portage Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64955950,
        AWATER: 252437,
        INTPTLAT: '+40.3817637',
        INTPTLON: '-078.6488323',
        ELSDLEA: '',
        UNSDLEA: '19560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.904, 40.2751],
            [-78.859, 40.3124],
            [-78.8133, 40.3511],
            [-78.8026, 40.2443],
            [-78.8839, 40.2447],
            [-78.904, 40.2751],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220250',
        NAME: 'Richland School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55491529,
        AWATER: 79228,
        INTPTLAT: '+40.2847440',
        INTPTLON: '-078.8471471',
        ELSDLEA: '',
        UNSDLEA: '20250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0339, 40.3165],
            [-78.9372, 40.3284],
            [-78.9324, 40.3327],
            [-78.927, 40.2931],
            [-78.9245, 40.2866],
            [-78.9279, 40.2842],
            [-79.056, 40.2851],
            [-79.0339, 40.3165],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226130',
        NAME: 'Westmont Hilltop School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40389051,
        AWATER: 84890,
        INTPTLAT: '+40.2981236',
        INTPTLON: '-078.9658717',
        ELSDLEA: '',
        UNSDLEA: '26130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8459, 41.767],
            [-75.7891, 41.8127],
            [-75.5626, 41.822],
            [-75.559, 41.7783],
            [-75.5747, 41.7036],
            [-75.4793, 41.6416],
            [-75.5313, 41.6417],
            [-75.6522, 41.6422],
            [-75.849, 41.6437],
            [-75.8459, 41.767],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216050',
        NAME: 'Mountain View School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 495025839,
        AWATER: 5081546,
        INTPTLAT: '+41.7232297',
        INTPTLON: '-075.6840605',
        ELSDLEA: '',
        UNSDLEA: '16050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6556, 41.9985],
            [-75.5388, 41.9991],
            [-75.4831, 41.9992],
            [-75.4709, 41.7846],
            [-75.559, 41.7783],
            [-75.5626, 41.822],
            [-75.5688, 41.9132],
            [-75.6483, 41.9098],
            [-75.6556, 41.9985],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222980',
        NAME: 'Susquehanna Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262999159,
        AWATER: 4462506,
        INTPTLAT: '+41.8995097',
        INTPTLON: '-075.5394503',
        ELSDLEA: '',
        UNSDLEA: '22980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1586, 40.6092],
            [-80.1487, 40.6738],
            [-80.1485, 40.6743],
            [-80.0714, 40.6742],
            [-79.9911, 40.6143],
            [-79.9889, 40.554],
            [-79.9889, 40.5539],
            [-80.064, 40.5422],
            [-80.1139, 40.5755],
            [-80.1586, 40.6092],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217010',
        NAME: 'North Allegheny School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120089854,
        AWATER: 280184,
        INTPTLAT: '+40.6105560',
        INTPTLON: '-080.0817865',
        ELSDLEA: '',
        UNSDLEA: '17010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9889, 40.5539],
            [-80.0039, 40.4963],
            [-80.0464, 40.4872],
            [-80.0616, 40.5058],
            [-80.064, 40.5422],
            [-79.9889, 40.5539],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217220',
        NAME: 'North Hills School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40104839,
        AWATER: 0,
        INTPTLAT: '+40.5251065',
        INTPTLON: '-080.0248382',
        ELSDLEA: '',
        UNSDLEA: '17220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8838, 40.4834],
            [-79.8503, 40.4959],
            [-79.8197, 40.5174],
            [-79.7794, 40.461],
            [-79.8026, 40.4386],
            [-79.8542, 40.4528],
            [-79.8683, 40.4564],
            [-79.8838, 40.4834],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218590',
        NAME: 'Penn Hills School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49665507,
        AWATER: 618324,
        INTPTLAT: '+40.4781739',
        INTPTLON: '-079.8291014',
        ELSDLEA: '',
        UNSDLEA: '18590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1586, 40.6092],
            [-80.1139, 40.5755],
            [-80.1203, 40.5131],
            [-80.1713, 40.5274],
            [-80.2286, 40.5731],
            [-80.1586, 40.6092],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219860',
        NAME: 'Quaker Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60475871,
        AWATER: 2219657,
        INTPTLAT: '+40.5642174',
        INTPTLON: '-080.1646680',
        ELSDLEA: '',
        UNSDLEA: '19860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0039, 40.4963],
            [-79.9889, 40.5539],
            [-79.9889, 40.554],
            [-79.9387, 40.554],
            [-79.9384, 40.491],
            [-80.0039, 40.4963],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221200',
        NAME: 'Shaler Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37518082,
        AWATER: 562956,
        INTPTLAT: '+40.5141816',
        INTPTLON: '-079.9666832',
        ELSDLEA: '',
        UNSDLEA: '21200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8919, 40.339],
            [-79.8376, 40.3147],
            [-79.8342, 40.2913],
            [-79.8777, 40.2826],
            [-79.8762, 40.2836],
            [-79.8967, 40.3197],
            [-79.8919, 40.339],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221910',
        NAME: 'South Allegheny School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23170645,
        AWATER: 1323230,
        INTPTLAT: '+40.3132201',
        INTPTLON: '-079.8629638',
        ELSDLEA: '',
        UNSDLEA: '21910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9981, 40.3382],
            [-79.9854, 40.3295],
            [-79.9691, 40.2687],
            [-80.0331, 40.2878],
            [-79.9981, 40.3382],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222060',
        NAME: 'South Park School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24009753,
        AWATER: 0,
        INTPTLAT: '+40.2989440',
        INTPTLON: '-079.9943843',
        ELSDLEA: '',
        UNSDLEA: '22060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8995, 40.4159],
            [-79.8844, 40.4073],
            [-79.9088, 40.3767],
            [-79.8995, 40.4159],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222790',
        NAME: 'Steel Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9829238,
        AWATER: 643708,
        INTPTLAT: '+40.3973206',
        INTPTLON: '-079.9046432',
        ELSDLEA: '',
        UNSDLEA: '22790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0991, 40.4994],
            [-80.0675, 40.4923],
            [-80.0532, 40.4822],
            [-80.0777, 40.4647],
            [-80.0991, 40.4994],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222830',
        NAME: 'Sto-Rox School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7870575,
        AWATER: 978366,
        INTPTLAT: '+40.4780894',
        INTPTLON: '-080.0701956',
        ELSDLEA: '',
        UNSDLEA: '22830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.0255, 41.3838],
            [-75.9284, 41.2881],
            [-75.9606, 41.2701],
            [-76.0767, 41.25],
            [-76.21, 41.2141],
            [-76.2527, 41.3774],
            [-76.0471, 41.4714],
            [-76.0255, 41.3838],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213020',
        NAME: 'Lake-Lehman School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360420114,
        AWATER: 7569376,
        INTPTLAT: '+41.3407731',
        INTPTLON: '-076.1068611',
        ELSDLEA: '',
        UNSDLEA: '13020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4282, 42.0001],
            [-77.2005, 41.9998],
            [-76.9657, 42.0013],
            [-76.9271, 42.0017],
            [-76.9144, 41.9149],
            [-77.0483, 41.9134],
            [-77.0453, 41.856],
            [-77.1796, 41.8559],
            [-77.1804, 41.9071],
            [-77.3511, 41.9037],
            [-77.3476, 41.8239],
            [-77.4933, 41.8105],
            [-77.6055, 41.8083],
            [-77.6067, 41.872],
            [-77.61, 41.9994],
            [-77.4282, 42.0001],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217730',
        NAME: 'Northern Tioga School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 849484679,
        AWATER: 4521383,
        INTPTLAT: '+41.9227691',
        INTPTLON: '-077.3026698',
        ELSDLEA: '',
        UNSDLEA: '17730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4935, 40.3765],
            [-76.4708, 40.25],
            [-76.5586, 40.2659],
            [-76.6155, 40.3484],
            [-76.4935, 40.3765],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202490',
        NAME: 'Annville-Cleona School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101459265,
        AWATER: 123191,
        INTPTLAT: '+40.3214938',
        INTPTLON: '-076.5243448',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6155, 40.3484],
            [-76.5586, 40.2659],
            [-76.4708, 40.25],
            [-76.4663, 40.2284],
            [-76.4693, 40.2274],
            [-76.5663, 40.1966],
            [-76.5922, 40.2543],
            [-76.6255, 40.3393],
            [-76.6155, 40.3484],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218390',
        NAME: 'Palmyra Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95922983,
        AWATER: 79409,
        INTPTLAT: '+40.3224236',
        INTPTLON: '-076.5788715',
        ELSDLEA: '',
        UNSDLEA: '18390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4831, 41.9992],
            [-75.3596, 41.9994],
            [-75.2713, 41.8874],
            [-75.1693, 41.8705],
            [-75.0532, 41.7517],
            [-75.0696, 41.6019],
            [-75.1232, 41.5336],
            [-75.3002, 41.4842],
            [-75.3459, 41.6726],
            [-75.3223, 41.798],
            [-75.4709, 41.7846],
            [-75.4831, 41.9992],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224970',
        NAME: 'Wayne Highlands School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1073039148,
        AWATER: 28598020,
        INTPTLAT: '+41.7439191',
        INTPTLON: '-075.2553325',
        ELSDLEA: '',
        UNSDLEA: '24970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.11, 40.3515],
            [-80.0727, 40.3638],
            [-80.0512, 40.351],
            [-80.074, 40.2998],
            [-80.1162, 40.3123],
            [-80.11, 40.3515],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224570',
        NAME: 'Upper Saint Clair School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25428992,
        AWATER: 20332,
        INTPTLAT: '+40.3335175',
        INTPTLON: '-080.0814125',
        ELSDLEA: '',
        UNSDLEA: '24570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.964, 40.345],
            [-79.8988, 40.3195],
            [-79.8762, 40.2836],
            [-79.8777, 40.2826],
            [-79.9141, 40.2525],
            [-79.9691, 40.2687],
            [-79.9854, 40.3295],
            [-79.964, 40.345],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225590',
        NAME: 'West Jefferson Hills School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50501189,
        AWATER: 596785,
        INTPTLAT: '+40.2972381',
        INTPTLON: '-079.9424711',
        ELSDLEA: '',
        UNSDLEA: '25590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8956, 40.435],
            [-79.8683, 40.4564],
            [-79.8542, 40.4528],
            [-79.8956, 40.435],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226370',
        NAME: 'Wilkinsburg Borough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5830414,
        AWATER: 0,
        INTPTLAT: '+40.4442008',
        INTPTLON: '-079.8733331',
        ELSDLEA: '',
        UNSDLEA: '26370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0825, 41.4554],
            [-77.0108, 41.5527],
            [-77.016, 41.6631],
            [-76.8839, 41.669],
            [-76.634, 41.7083],
            [-76.6852, 41.5993],
            [-76.6944, 41.5799],
            [-76.8137, 41.59],
            [-76.7724, 41.4717],
            [-76.8326, 41.4968],
            [-76.9698, 41.4742],
            [-77.0825, 41.4554],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204920',
        NAME: 'Canton Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 544873190,
        AWATER: 4428844,
        INTPTLAT: '+41.5845546',
        INTPTLON: '-076.8733330',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-77.3582, 41.5441],
              [-77.1931, 41.5458],
              [-77.1943, 41.459],
              [-77.2923, 41.3998],
              [-77.3587, 41.4126],
              [-77.3582, 41.5441],
            ],
          ],
          [
            [
              [-77.4933, 41.8105],
              [-77.3476, 41.8239],
              [-77.3511, 41.9037],
              [-77.1804, 41.9071],
              [-77.1796, 41.8559],
              [-77.1769, 41.6823],
              [-77.2326, 41.6423],
              [-77.4578, 41.631],
              [-77.4933, 41.8105],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225170',
        NAME: 'Wellsboro Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 861262472,
        AWATER: 3215945,
        INTPTLAT: '+41.6954154',
        INTPTLON: '-077.3231839',
        ELSDLEA: '',
        UNSDLEA: '25170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4626, 40.1196],
            [-75.4513, 40.1733],
            [-75.3695, 40.2169],
            [-75.2935, 40.1933],
            [-75.3269, 40.1597],
            [-75.4243, 40.1079],
            [-75.4617, 40.103],
            [-75.4626, 40.1196],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214250',
        NAME: 'Methacton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81502401,
        AWATER: 560523,
        INTPTLAT: '+40.1721728',
        INTPTLON: '-075.3914353',
        ELSDLEA: '',
        UNSDLEA: '14250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.6968, 40.2419],
            [-75.6452, 40.3049],
            [-75.5594, 40.2578],
            [-75.5927, 40.2255],
            [-75.6278, 40.2328],
            [-75.6283, 40.2558],
            [-75.6624, 40.2664],
            [-75.6761, 40.2426],
            [-75.6968, 40.2419],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219650',
        NAME: 'Pottsgrove School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39615644,
        AWATER: 377535,
        INTPTLAT: '+40.2651190',
        INTPTLON: '-075.6227333',
        ELSDLEA: '',
        UNSDLEA: '19650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.3002, 41.4842],
            [-75.1232, 41.5336],
            [-75.0696, 41.6019],
            [-75.0016, 41.5075],
            [-74.966, 41.4768],
            [-75.045, 41.2899],
            [-75.1297, 41.2521],
            [-75.3722, 41.238],
            [-75.3906, 41.2956],
            [-75.2983, 41.3674],
            [-75.3002, 41.4842],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224750',
        NAME: 'Wallenpaupack Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 800493017,
        AWATER: 47837867,
        INTPTLAT: '+41.4105472',
        INTPTLON: '-075.1548652',
        ELSDLEA: '',
        UNSDLEA: '24750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3619, 40.4776],
            [-80.3609, 40.4775],
            [-80.2229, 40.3727],
            [-80.2231, 40.3658],
            [-80.2401, 40.3082],
            [-80.3113, 40.25],
            [-80.3322, 40.2382],
            [-80.3565, 40.3086],
            [-80.3086, 40.3854],
            [-80.3619, 40.4776],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209960',
        NAME: 'Fort Cherry School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149381873,
        AWATER: 359052,
        INTPTLAT: '+40.3406394',
        INTPTLON: '-080.2996346',
        ELSDLEA: '',
        UNSDLEA: '09960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6126, 41.775],
            [-79.4923, 41.7892],
            [-79.4739, 41.8972],
            [-79.511, 41.9985],
            [-79.3817, 41.9987],
            [-79.2498, 41.9988],
            [-79.0421, 41.9992],
            [-78.9189, 41.9981],
            [-78.9158, 41.8409],
            [-78.9487, 41.8305],
            [-78.956, 41.6234],
            [-79.4935, 41.6247],
            [-79.4941, 41.7061],
            [-79.6129, 41.7053],
            [-79.6126, 41.775],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4224820',
        NAME: 'Warren County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1970411260,
        AWATER: 37145690,
        INTPTLAT: '+41.8161412',
        INTPTLON: '-079.2226629',
        ELSDLEA: '',
        UNSDLEA: '24820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0305, 39.7979],
            [-77.9446, 39.9398],
            [-77.8632, 40.0009],
            [-77.7435, 39.9528],
            [-77.7493, 39.8561],
            [-77.8105, 39.7668],
            [-77.79, 39.7216],
            [-78.0994, 39.7221],
            [-78.0305, 39.7979],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222740',
        NAME: 'Tuscarora School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 534722780,
        AWATER: 378677,
        INTPTLAT: '+39.8483516',
        INTPTLON: '-077.8876722',
        ELSDLEA: '',
        UNSDLEA: '22740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6275, 40.1886],
            [-77.5608, 40.1044],
            [-77.6309, 40.0483],
            [-77.4711, 39.9441],
            [-77.4667, 39.8716],
            [-77.6411, 39.8509],
            [-77.7493, 39.8561],
            [-77.7435, 39.9528],
            [-77.8632, 40.0009],
            [-77.6275, 40.1886],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4205550',
        NAME: 'Chambersburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 644999273,
        AWATER: 710323,
        INTPTLAT: '+39.9849023',
        INTPTLON: '-077.6452503',
        ELSDLEA: '',
        UNSDLEA: '05550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7493, 39.8561],
            [-77.6411, 39.8509],
            [-77.6696, 39.7207],
            [-77.79, 39.7216],
            [-77.8105, 39.7668],
            [-77.7493, 39.8561],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211010',
        NAME: 'Greencastle-Antrim School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186039060,
        AWATER: 179442,
        INTPTLAT: '+39.7867185',
        INTPTLON: '-077.7224293',
        ELSDLEA: '',
        UNSDLEA: '11010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.1382, 40.0941],
            [-75.1309, 40.1026],
            [-75.1212, 40.0968],
            [-75.1317, 40.0893],
            [-75.1382, 40.0941],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4212420',
        NAME: 'Jenkintown School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1501362,
        AWATER: 0,
        INTPTLAT: '+40.0962559',
        INTPTLON: '-075.1296392',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1429, 40.1606],
            [-76.094, 40.1663],
            [-76.0441, 40.2472],
            [-76.044, 40.2472],
            [-75.9434, 40.1827],
            [-75.9424, 40.1065],
            [-76.0466, 40.0532],
            [-76.1043, 40.0722],
            [-76.1429, 40.1606],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4208820',
        NAME: 'Eastern Lancaster County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249947335,
        AWATER: 1759224,
        INTPTLAT: '+40.1456833',
        INTPTLON: '-076.0271126',
        ELSDLEA: '',
        UNSDLEA: '08820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3668, 40.1243],
            [-76.336, 40.1271],
            [-76.2266, 40.1151],
            [-76.273, 40.0577],
            [-76.3421, 40.0531],
            [-76.3668, 40.1243],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214580',
        NAME: 'Manheim Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61782776,
        AWATER: 537655,
        INTPTLAT: '+40.0910781',
        INTPTLON: '-076.2974764',
        ELSDLEA: '',
        UNSDLEA: '14580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.326, 41.4843],
            [-80.1646, 41.4814],
            [-80.1014, 41.4301],
            [-80.1786, 41.3696],
            [-80.2616, 41.3674],
            [-80.3209, 41.3658],
            [-80.326, 41.4843],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206390',
        NAME: 'Commodore Perry School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190900936,
        AWATER: 1553638,
        INTPTLAT: '+41.4323560',
        INTPTLON: '-080.2279758',
        ELSDLEA: '',
        UNSDLEA: '06390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5189, 41.2194],
            [-80.4869, 41.2182],
            [-80.4896, 41.1895],
            [-80.4993, 41.1886],
            [-80.5192, 41.2085],
            [-80.5189, 41.2194],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209690',
        NAME: 'Farrell Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8283042,
        AWATER: 0,
        INTPTLAT: '+41.2072741',
        INTPTLON: '-080.4970076',
        ELSDLEA: '',
        UNSDLEA: '09690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2571, 41.1572],
            [-80.1315, 41.1773],
            [-80.1159, 41.2366],
            [-79.9998, 41.2387],
            [-79.9998, 41.1719],
            [-80.0969, 41.0698],
            [-80.2571, 41.1572],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211220',
        NAME: 'Grove City Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225741457,
        AWATER: 1653167,
        INTPTLAT: '+41.1604723',
        INTPTLON: '-080.1092050',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6696, 39.7207],
            [-77.6411, 39.8509],
            [-77.4667, 39.8716],
            [-77.466, 39.8598],
            [-77.4594, 39.72],
            [-77.4691, 39.72],
            [-77.6696, 39.7207],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225110',
        NAME: 'Waynesboro Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241068158,
        AWATER: 40161,
        INTPTLAT: '+39.7992816',
        INTPTLON: '-077.5434154',
        ELSDLEA: '',
        UNSDLEA: '25110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5199, 42.3271],
            [-80.0802, 42.3942],
            [-79.7624, 42.5161],
            [-79.7619, 42.2693],
            [-79.907, 42.2155],
            [-80.0213, 42.1631],
            [-80.0414, 42.1536],
            [-80.1536, 42.1155],
            [-80.212, 42.0845],
            [-80.2989, 42.0483],
            [-80.386, 42.0192],
            [-80.5194, 41.9775],
            [-80.5199, 42.3271],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4299997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 0,
        AWATER: 1938676373,
        INTPTLAT: '+42.2520610',
        INTPTLON: '-080.1688890',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2616, 41.3674],
            [-80.1786, 41.3696],
            [-80.1014, 41.4301],
            [-79.9998, 41.4325],
            [-79.9998, 41.2387],
            [-80.1159, 41.2366],
            [-80.1814, 41.3033],
            [-80.26, 41.3032],
            [-80.2616, 41.3674],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213080',
        NAME: 'Lakeview School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366456664,
        AWATER: 6934708,
        INTPTLAT: '+41.3303639',
        INTPTLON: '-080.1181358',
        ELSDLEA: '',
        UNSDLEA: '13080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5191, 41.2431],
            [-80.4869, 41.2182],
            [-80.5189, 41.2194],
            [-80.5191, 41.2431],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221330',
        NAME: 'Sharon City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9763135,
        AWATER: 0,
        INTPTLAT: '+41.2339694',
        INTPTLON: '-080.4998117',
        ELSDLEA: '',
        UNSDLEA: '21330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.519, 41.1878],
            [-80.4993, 41.1886],
            [-80.4896, 41.1895],
            [-80.3862, 41.2353],
            [-80.3125, 41.1558],
            [-80.519, 41.1251],
            [-80.519, 41.1334],
            [-80.519, 41.1878],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225650',
        NAME: 'West Middlesex Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134702741,
        AWATER: 162284,
        INTPTLAT: '+41.1778900',
        INTPTLON: '-080.4099750',
        ELSDLEA: '',
        UNSDLEA: '25650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.915, 40.3285],
            [-76.9221, 40.3027],
            [-76.9139, 40.2719],
            [-76.9167, 40.2504],
            [-76.9428, 40.2429],
            [-76.9769, 40.3099],
            [-76.915, 40.3285],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4208580',
        NAME: 'East Pennsboro Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27164381,
        AWATER: 806011,
        INTPTLAT: '+40.2884950',
        INTPTLON: '-076.9397727',
        ELSDLEA: '',
        UNSDLEA: '08580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1644, 40.1995],
            [-77.0804, 40.111],
            [-77.1374, 40.0699],
            [-77.1781, 40.0368],
            [-77.2426, 40.1854],
            [-77.1644, 40.1995],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222050',
        NAME: 'South Middleton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126233028,
        AWATER: 797352,
        INTPTLAT: '+40.1351493',
        INTPTLON: '-077.1673187',
        ELSDLEA: '',
        UNSDLEA: '22050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9844, 40.2174],
            [-76.9221, 40.1623],
            [-77.0126, 40.1484],
            [-76.9844, 40.2174],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215030',
        NAME: 'Mechanicsburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41193761,
        AWATER: 272756,
        INTPTLAT: '+40.1867865',
        INTPTLON: '-076.9825758',
        ELSDLEA: '',
        UNSDLEA: '15030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4402, 40.6388],
            [-78.3587, 40.7097],
            [-78.2604, 40.6378],
            [-78.302, 40.5739],
            [-78.4475, 40.5916],
            [-78.4402, 40.6388],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203300',
        NAME: 'Bellwood-Antis School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158639359,
        AWATER: 623871,
        INTPTLAT: '+40.6398340',
        INTPTLON: '-078.3589214',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6974, 40.4335],
            [-78.6781, 40.5366],
            [-78.5511, 40.5611],
            [-78.4965, 40.6389],
            [-78.4402, 40.6388],
            [-78.4475, 40.5916],
            [-78.5371, 40.5099],
            [-78.5634, 40.3748],
            [-78.6859, 40.429],
            [-78.6974, 40.4335],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213770',
        NAME: 'Penn Cambria School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283939950,
        AWATER: 396156,
        INTPTLAT: '+40.5086147',
        INTPTLON: '-078.5808882',
        ELSDLEA: '',
        UNSDLEA: '13770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6019, 40.3532],
            [-78.5634, 40.3748],
            [-78.5371, 40.5099],
            [-78.3893, 40.4561],
            [-78.2701, 40.5302],
            [-78.2908, 40.4181],
            [-78.4188, 40.3626],
            [-78.5391, 40.3458],
            [-78.6019, 40.3532],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211940',
        NAME: 'Hollidaysburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313648719,
        AWATER: 1484873,
        INTPTLAT: '+40.4307022',
        INTPTLON: '-078.4122503',
        ELSDLEA: '',
        UNSDLEA: '11940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7723, 41.0163],
            [-76.6213, 41.0916],
            [-76.563, 41.0363],
            [-76.5124, 40.9461],
            [-76.5848, 40.8799],
            [-76.6431, 40.8841],
            [-76.7929, 40.9466],
            [-76.7723, 41.0163],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4207320',
        NAME: 'Danville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317161324,
        AWATER: 7399654,
        INTPTLAT: '+40.9753072',
        INTPTLON: '-076.6374091',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4935, 40.3765],
            [-76.3705, 40.3881],
            [-76.3365, 40.2513],
            [-76.3505, 40.2497],
            [-76.4663, 40.2284],
            [-76.4708, 40.25],
            [-76.4935, 40.3765],
          ],
          [
            [-76.4544, 40.3483],
            [-76.4195, 40.3316],
            [-76.4216, 40.3555],
            [-76.4544, 40.3483],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206840',
        NAME: 'Cornwall-Lebanon School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172001070,
        AWATER: 645929,
        INTPTLAT: '+40.3140213',
        INTPTLON: '-076.4105949',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.678, 40.4747],
            [-76.5354, 40.5552],
            [-76.4402, 40.496],
            [-76.341, 40.4344],
            [-76.3705, 40.3881],
            [-76.4935, 40.3765],
            [-76.6155, 40.3484],
            [-76.6255, 40.3393],
            [-76.678, 40.4747],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217640',
        NAME: 'Northern Lebanon School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372172562,
        AWATER: 609584,
        INTPTLAT: '+40.4498264',
        INTPTLON: '-076.5271771',
        ELSDLEA: '',
        UNSDLEA: '17640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5696, 40.2121],
            [-77.4266, 40.2866],
            [-77.27, 40.2787],
            [-77.244, 40.1901],
            [-77.3168, 40.161],
            [-77.2552, 40.0208],
            [-77.3537, 40.0033],
            [-77.5696, 40.2121],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4203660',
        NAME: 'Big Spring School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 501618383,
        AWATER: 3588615,
        INTPTLAT: '+40.1558772',
        INTPTLON: '-077.3689459',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9428, 40.2429],
            [-76.9167, 40.2504],
            [-76.9376, 40.2323],
            [-76.9428, 40.2429],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4204860',
        NAME: 'Camp Hill School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5495681,
        AWATER: 0,
        INTPTLAT: '+40.2421698',
        INTPTLON: '-076.9273967',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6275, 40.1886],
            [-77.6147, 40.1985],
            [-77.5696, 40.2121],
            [-77.3537, 40.0033],
            [-77.4214, 39.9813],
            [-77.4711, 39.9441],
            [-77.6309, 40.0483],
            [-77.5608, 40.1044],
            [-77.6275, 40.1886],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221570',
        NAME: 'Shippensburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316238165,
        AWATER: 871965,
        INTPTLAT: '+40.0646230',
        INTPTLON: '-077.5072696',
        ELSDLEA: '',
        UNSDLEA: '21570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8114, 40.5665],
            [-79.7719, 40.563],
            [-79.7659, 40.5509],
            [-79.7654, 40.5499],
            [-79.8353, 40.5326],
            [-79.8505, 40.5205],
            [-79.8114, 40.5665],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202190',
        NAME: 'Allegheny Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24681993,
        AWATER: 2195505,
        INTPTLAT: '+40.5483455',
        INTPTLON: '-079.8164820',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8988, 40.3195],
            [-79.8967, 40.3197],
            [-79.8762, 40.2836],
            [-79.8988, 40.3195],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206030',
        NAME: 'Clairton City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7158968,
        AWATER: 603232,
        INTPTLAT: '+40.2975619',
        INTPTLON: '-079.8859197',
        ELSDLEA: '',
        UNSDLEA: '06030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.5391, 40.3458],
            [-78.4188, 40.3626],
            [-78.2908, 40.4181],
            [-78.1892, 40.3955],
            [-78.2431, 40.3225],
            [-78.2882, 40.2436],
            [-78.4343, 40.3023],
            [-78.5391, 40.3458],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222530',
        NAME: 'Spring Cove School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254135655,
        AWATER: 47187,
        INTPTLAT: '+40.3368355',
        INTPTLON: '-078.3415641',
        ELSDLEA: '',
        UNSDLEA: '22530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0616, 40.5058],
            [-80.0464, 40.4872],
            [-80.0532, 40.4822],
            [-80.0675, 40.4923],
            [-80.075, 40.4984],
            [-80.0616, 40.5058],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4217770',
        NAME: 'Northgate School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4210270,
        AWATER: 486007,
        INTPTLAT: '+40.4965865',
        INTPTLON: '-080.0598379',
        ELSDLEA: '',
        UNSDLEA: '17770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0234, 40.8575],
            [-76.9131, 40.8845],
            [-76.8002, 40.882],
            [-76.8495, 40.8094],
            [-76.8554, 40.7288],
            [-76.9397, 40.6384],
            [-76.9655, 40.6471],
            [-76.9494, 40.7043],
            [-77.0192, 40.7681],
            [-77.0234, 40.8575],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221120',
        NAME: 'Selinsgrove Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268625232,
        AWATER: 2904308,
        INTPTLAT: '+40.7877791',
        INTPTLON: '-076.9065873',
        ELSDLEA: '',
        UNSDLEA: '21120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3566, 40.8073],
            [-77.052, 40.8694],
            [-77.0234, 40.8575],
            [-77.0192, 40.7681],
            [-76.9494, 40.7043],
            [-76.9655, 40.6471],
            [-77.0355, 40.6769],
            [-77.2879, 40.6936],
            [-77.3541, 40.7017],
            [-77.3566, 40.8073],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215210',
        NAME: 'Midd-West School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 582837249,
        AWATER: 4269069,
        INTPTLAT: '+40.7581581',
        INTPTLON: '-077.1467964',
        ELSDLEA: '',
        UNSDLEA: '15210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9911, 40.6143],
            [-79.9206, 40.6161],
            [-79.9161, 40.5964],
            [-79.9387, 40.554],
            [-79.9889, 40.554],
            [-79.9911, 40.6143],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211400',
        NAME: 'Hampton Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41952602,
        AWATER: 18582,
        INTPTLAT: '+40.5868028',
        INTPTLON: '-079.9537205',
        ELSDLEA: '',
        UNSDLEA: '11400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3809, 41.4438],
            [-80.3801, 41.4859],
            [-80.326, 41.4843],
            [-80.3209, 41.3658],
            [-80.3809, 41.4438],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4211160',
        NAME: 'Greenville Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73674586,
        AWATER: 92031,
        INTPTLAT: '+41.4187428',
        INTPTLON: '-080.3552431',
        ELSDLEA: '',
        UNSDLEA: '11160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.864, 40.9319],
            [-76.7929, 40.9466],
            [-76.6431, 40.8841],
            [-76.6961, 40.7861],
            [-76.8495, 40.8094],
            [-76.8002, 40.882],
            [-76.864, 40.9319],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221540',
        NAME: 'Shikellamy School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186692419,
        AWATER: 14463744,
        INTPTLAT: '+40.8822780',
        INTPTLON: '-076.7399898',
        ELSDLEA: '',
        UNSDLEA: '21540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5848, 40.8799],
            [-76.5124, 40.9461],
            [-76.4214, 40.993],
            [-76.273, 40.8942],
            [-76.28, 40.8878],
            [-76.4115, 40.8335],
            [-76.4906, 40.8252],
            [-76.5848, 40.8799],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4222260',
        NAME: 'Southern Columbia Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 281577533,
        AWATER: 3289475,
        INTPTLAT: '+40.8842509',
        INTPTLON: '-076.4385331',
        ELSDLEA: '',
        UNSDLEA: '22260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9397, 40.6384],
            [-76.8554, 40.7288],
            [-76.8495, 40.8094],
            [-76.6961, 40.7861],
            [-76.5049, 40.7303],
            [-76.7016, 40.6581],
            [-76.8864, 40.6178],
            [-76.9494, 40.6282],
            [-76.9397, 40.6384],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4214460',
        NAME: 'Line Mountain School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 380562141,
        AWATER: 22786819,
        INTPTLAT: '+40.7168985',
        INTPTLON: '-076.7522073',
        ELSDLEA: '',
        UNSDLEA: '14460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0777, 40.4647],
            [-80.0532, 40.4822],
            [-80.0464, 40.4872],
            [-80.0039, 40.4963],
            [-79.9384, 40.491],
            [-79.8838, 40.4834],
            [-79.8683, 40.4564],
            [-79.8956, 40.435],
            [-79.8995, 40.4159],
            [-79.9088, 40.3767],
            [-79.9406, 40.3747],
            [-79.9741, 40.3848],
            [-79.986, 40.3778],
            [-80.0016, 40.3748],
            [-80.0067, 40.3749],
            [-80.022, 40.3885],
            [-80.0287, 40.3913],
            [-80.0449, 40.3991],
            [-80.0512, 40.4019],
            [-80.0348, 40.4284],
            [-80.0638, 40.4264],
            [-80.0739, 40.4134],
            [-80.0787, 40.4165],
            [-80.0612, 40.4406],
            [-80.0764, 40.4418],
            [-80.0807, 40.4398],
            [-80.0777, 40.4647],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219170',
        NAME: 'Pittsburgh School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141529695,
        AWATER: 7692318,
        INTPTLAT: '+40.4410487',
        INTPTLON: '-079.9770782',
        ELSDLEA: '',
        UNSDLEA: '19170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8353, 40.5326],
            [-79.7654, 40.5499],
            [-79.7016, 40.5254],
            [-79.7038, 40.4435],
            [-79.7794, 40.461],
            [-79.8197, 40.5174],
            [-79.8353, 40.5326],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4219350',
        NAME: 'Plum Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74030472,
        AWATER: 979252,
        INTPTLAT: '+40.5018687',
        INTPTLON: '-079.7481776',
        ELSDLEA: '',
        UNSDLEA: '19350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8505, 40.5205],
            [-79.8353, 40.5326],
            [-79.8197, 40.5174],
            [-79.8503, 40.4959],
            [-79.8505, 40.5205],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220430',
        NAME: 'Riverview School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5421020,
        AWATER: 706998,
        INTPTLAT: '+40.5164473',
        INTPTLON: '-079.8367219',
        ELSDLEA: '',
        UNSDLEA: '20430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2231, 40.3658],
            [-80.2229, 40.3727],
            [-80.1687, 40.402],
            [-80.11, 40.3515],
            [-80.1162, 40.3123],
            [-80.2231, 40.3658],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4220580',
        NAME: 'South Fayette Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52789216,
        AWATER: 11625,
        INTPTLAT: '+40.3698850',
        INTPTLON: '-080.1782038',
        ELSDLEA: '',
        UNSDLEA: '20580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3608, 40.4776],
            [-80.2751, 40.5388],
            [-80.1722, 40.4583],
            [-80.1681, 40.4219],
            [-80.1687, 40.402],
            [-80.2229, 40.3727],
            [-80.3609, 40.4775],
            [-80.3608, 40.4776],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4225200',
        NAME: 'West Allegheny School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150311154,
        AWATER: 14471,
        INTPTLAT: '+40.4500915',
        INTPTLON: '-080.2577227',
        ELSDLEA: '',
        UNSDLEA: '25200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2781, 40.0497],
            [-76.273, 40.0577],
            [-76.2266, 40.1151],
            [-76.221, 40.1479],
            [-76.1429, 40.1606],
            [-76.1043, 40.0722],
            [-76.1614, 40.0106],
            [-76.2585, 40.0296],
            [-76.2662, 40.034],
            [-76.2735, 40.0378],
            [-76.278, 40.0484],
            [-76.2781, 40.0497],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206480',
        NAME: 'Conestoga Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143677270,
        AWATER: 1565108,
        INTPTLAT: '+40.0789698',
        INTPTLON: '-076.1977655',
        ELSDLEA: '',
        UNSDLEA: '06480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7216, 40.1201],
            [-76.5663, 40.1966],
            [-76.4693, 40.2274],
            [-76.5218, 40.1708],
            [-76.633, 40.0574],
            [-76.6445, 40.0581],
            [-76.7216, 40.1201],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209120',
        NAME: 'Elizabethtown Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 134939546,
        AWATER: 10627544,
        INTPTLAT: '+40.1394285',
        INTPTLON: '-076.6145427',
        ELSDLEA: '',
        UNSDLEA: '09120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2735, 40.0378],
            [-76.2662, 40.034],
            [-76.2585, 40.0296],
            [-76.1614, 40.0106],
            [-76.1368, 39.9445],
            [-76.2521, 39.9479],
            [-76.3047, 40.0016],
            [-76.2735, 40.0378],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213110',
        NAME: 'Lampeter-Strasburg School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97126951,
        AWATER: 999821,
        INTPTLAT: '+39.9726001',
        INTPTLON: '-076.2167674',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3124, 39.8054],
            [-76.2557, 39.8676],
            [-76.2521, 39.9479],
            [-76.1368, 39.9445],
            [-76.0713, 39.9687],
            [-76.0017, 39.8747],
            [-76.1025, 39.724],
            [-76.1355, 39.7211],
            [-76.2333, 39.7214],
            [-76.2398, 39.7213],
            [-76.297, 39.7912],
            [-76.3124, 39.8054],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4221810',
        NAME: 'Solanco School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 462686299,
        AWATER: 25032000,
        INTPTLAT: '+39.8384977',
        INTPTLON: '-076.1623028',
        ELSDLEA: '',
        UNSDLEA: '21810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-80.0171, 40.359],
              [-80.0016, 40.3748],
              [-79.986, 40.3778],
              [-79.9823, 40.3609],
              [-79.9621, 40.3707],
              [-79.9741, 40.3848],
              [-79.9406, 40.3747],
              [-79.964, 40.345],
              [-79.9854, 40.3295],
              [-79.9981, 40.3382],
              [-80.0171, 40.359],
            ],
          ],
          [
            [
              [-80.022, 40.3885],
              [-80.0067, 40.3749],
              [-80.0212, 40.3766],
              [-80.022, 40.3885],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4202970',
        NAME: 'Baldwin-Whitehall School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27655898,
        AWATER: 275717,
        INTPTLAT: '+40.3911608',
        INTPTLON: '-079.9576226',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1722, 40.4583],
            [-80.1464, 40.5068],
            [-80.0991, 40.4994],
            [-80.0777, 40.4647],
            [-80.0807, 40.4398],
            [-80.0998, 40.411],
            [-80.1681, 40.4219],
            [-80.1722, 40.4583],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4215720',
        NAME: 'Montour School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56062624,
        AWATER: 426699,
        INTPTLAT: '+40.4634262',
        INTPTLON: '-080.1252136',
        ELSDLEA: '',
        UNSDLEA: '15720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4771, 40.0216],
            [-76.489, 40.0207],
            [-76.5077, 40.0294],
            [-76.5068, 40.0492],
            [-76.4823, 40.0444],
            [-76.4771, 40.0216],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4206360',
        NAME: 'Columbia Borough School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6251963,
        AWATER: 19532,
        INTPTLAT: '+40.0348552',
        INTPTLON: '-076.4958891',
        ELSDLEA: '',
        UNSDLEA: '06360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3545, 40.9075],
            [-80.3495, 40.9075],
            [-80.264, 40.909],
            [-80.1625, 40.9378],
            [-80.16, 40.8869],
            [-80.1585, 40.8552],
            [-80.3224, 40.8532],
            [-80.3545, 40.9075],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209240',
        NAME: 'Ellwood City Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99006266,
        AWATER: 1384652,
        INTPTLAT: '+40.8889332',
        INTPTLON: '-080.2414768',
        ELSDLEA: '',
        UNSDLEA: '09240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3891, 41.0627],
            [-80.3182, 41.0786],
            [-80.3187, 41.02],
            [-80.3587, 41.0147],
            [-80.4009, 41.0397],
            [-80.3891, 41.0627],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216440',
        NAME: 'Neshannock Township School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44639359,
        AWATER: 484804,
        INTPTLAT: '+41.0504990',
        INTPTLON: '-080.3504331',
        ELSDLEA: '',
        UNSDLEA: '16440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3971, 40.9858],
            [-80.3587, 41.0147],
            [-80.3187, 41.02],
            [-80.3174, 40.9982],
            [-80.3495, 40.9075],
            [-80.3545, 40.9075],
            [-80.3971, 40.9858],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4216620',
        NAME: 'New Castle Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35007036,
        AWATER: 1290927,
        INTPTLAT: '+40.9737553',
        INTPTLON: '-080.3542877',
        ELSDLEA: '',
        UNSDLEA: '16620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2839, 40.2644],
            [-76.2113, 40.2927],
            [-76.2035, 40.2412],
            [-76.094, 40.1663],
            [-76.1429, 40.1606],
            [-76.221, 40.1479],
            [-76.2839, 40.2644],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4209270',
        NAME: 'Ephrata Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110938420,
        AWATER: 2673423,
        INTPTLAT: '+40.2116553',
        INTPTLON: '-076.2046321',
        ELSDLEA: '',
        UNSDLEA: '09270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3429, 40.0381],
            [-76.3421, 40.0531],
            [-76.273, 40.0577],
            [-76.2781, 40.0497],
            [-76.278, 40.0484],
            [-76.2735, 40.0378],
            [-76.3047, 40.0016],
            [-76.3429, 40.0381],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4213140',
        NAME: 'Lancaster School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33612733,
        AWATER: 849872,
        INTPTLAT: '+40.0327925',
        INTPTLON: '-076.3112325',
        ELSDLEA: '',
        UNSDLEA: '13140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1043, 40.0722],
            [-76.0466, 40.0532],
            [-75.9424, 40.1065],
            [-75.9343, 40.0476],
            [-75.9514, 40.0164],
            [-76.0713, 39.9687],
            [-76.1368, 39.9445],
            [-76.1614, 40.0106],
            [-76.1043, 40.0722],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4218900',
        NAME: 'Pequea Valley School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209554512,
        AWATER: 965582,
        INTPTLAT: '+40.0287825',
        INTPTLON: '-076.0524992',
        ELSDLEA: '',
        UNSDLEA: '18900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3642, 40.8469],
            [-77.1442, 41.0443],
            [-77.1441, 41.0688],
            [-77.0581, 41.0856],
            [-76.9778, 41.0195],
            [-76.9563, 40.9008],
            [-76.9131, 40.8845],
            [-77.0234, 40.8575],
            [-77.052, 40.8694],
            [-77.3566, 40.8073],
            [-77.3642, 40.8469],
          ],
        ],
      },
      properties: {
        STATEFP: '42',
        SCSDLEA: '',
        GEOID: '4226010',
        NAME: 'Mifflinburg Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 552488640,
        AWATER: 3130916,
        INTPTLAT: '+40.9302095',
        INTPTLON: '-077.1217866',
        ELSDLEA: '',
        UNSDLEA: '26010',
      },
    },
  ],
};

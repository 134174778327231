/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import types from '../types';

const initialState = {
  loading: false,
  surveyDetails: {},
  error: '',
  filters: [],
  visualizations: [],
  questionsCategories: [],
  section: '',
  jobs: null,
  calculatingMoe: false,
  openedQuestions: [],
  isExpandedAll: false,
};

const surveyDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.SURVEY_DETAILS_CLEAR:
      return {
        ...initialState,
      };
    case types.reducerTypes.SURVEY_DETAILS_PENDING:
      return {
        ...initialState,
        loading: true,
      };
    case types.reducerTypes.SURVEY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        surveyDetails: action.payload,
      };
    case types.reducerTypes.SURVEY_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.SURVEY_DETAILS_FILTERS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.SURVEY_DETAILS_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        filters: action.payload,
      };
    case types.reducerTypes.SURVEY_DETAILS_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        visualizations: action.payload,
      };
    case types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.SURVEY_DETAILS_QUESTIONS_CATEGORIES_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.SURVEY_DETAILS_QUESTIONS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        questionsCategories: [...state.questionsCategories, action.payload],
      };
    case types.reducerTypes.SURVEY_DETAILS_QUESTIONS_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.SURVEY_DETAILS_SECTION_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.SURVEY_DETAILS_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        section: action.payload,
        jobs: null,
      };
    case types.reducerTypes.SURVEY_DETAILS_SECTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.SURVEY_DETAILS_CALCULATE_MOE_PENDING:
      return {
        ...state,
        loading: true,
        calculatingMoe: true,
      };
    case types.reducerTypes.SURVEY_DETAILS_CALCULATE_MOE_SUCCESS:
      return {
        ...state,
        loading: false,
        section: action.payload,
        calculatingMoe: false,
        jobs: null,
      };
    case types.reducerTypes.SURVEY_DETAILS_CALCULATE_MOE_ERROR:
      return {
        ...state,
        loading: false,
        calculatingMoe: false,
        error: action.payload,
      };
    case types.reducerTypes.MOE_CALCULATION_IN_PROGRESS_MODAL_HIDE:
      return {
        ...state,
        calculatingMoe: false,
      };
    case types.reducerTypes.SURVEY_DETAILS_JOB_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.SURVEY_DETAILS_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        jobs: action.payload,
      };
    case types.reducerTypes.SURVEY_DETAILS_JOB_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.SURVEY_DETAILS_CLEAR_OPEN_QUESTIONS:
    case types.reducerTypes.SURVEY_DETAILS_CLEAR_ALL_OPEN_QUESTIONS:
      return {
        ...state,
        openedQuestions: [],
        isExpandedAll: false,
      };
    case types.reducerTypes.SURVEY_DETAILS_TOGGLE_OPEN_QUESTIONS:
      let currentQuestions = [...state.openedQuestions];
      if (currentQuestions.includes(action.payload.toggleQuestionId) && !action.payload.isInit) {
        currentQuestions = currentQuestions.filter((q) => q !== action.payload.toggleQuestionId);
      } else if (
        !currentQuestions.includes(action.payload.toggleQuestionId) &&
        !action.payload.isInit
      ) {
        currentQuestions.push(action.payload.toggleQuestionId);
      } else if (currentQuestions.length === 0 && action.payload.isInit) {
        currentQuestions.push(action.payload.toggleQuestionId);
      }
      return {
        ...state,
        openedQuestions: currentQuestions,
        isExpandedAll: false,
      };
    case types.reducerTypes.SURVEY_DETAILS_TOGGLE_ALL_OPEN_QUESTIONS:
      return {
        ...state,
        openedQuestions: action.payload.allQuestionsIds,
        isExpandedAll: true,
      };
    default:
  }
  return state;
};

export default surveyDetailReducer;

/* eslint-disable react/display-name */
import React from 'react';
import { Route } from 'react-router-dom';

import Survey from '../Pages/Survey';
import Dashboard from '../Pages/Dashboard';
import DashboardDataManager from '../Pages/DashboardDataManager';
import NewSurvey from '../Pages/NewSurvey';
import ReplaceDataSet from '../Pages/ReplaceDataSet';
import PrivateRoute from '../Components/PrivateRoute';
import AddNewData from '../Pages/AddNewData';
import UpdateVisualization from '../Pages/UpdateVisualization';
import NoMatch from '../Pages/NoMatch';
import Signup from '../Pages/Signup';
import Login from '../Pages/Login';
import Activate from '../Pages/Activate';
import ResetPassword from '../Pages/ResetPassword';
import ForgotPassword from '../Pages/ForgotPassword';
import ManageUsers from '../Pages/users';
import ManageOrganizations from '../Pages/organizations';
import SurveyDetailsLanding from '../Pages/survey-details-landing';
import SurveyExplorerLanding from '../Pages/survey-explorer';
import SurveyTopicsLanding from '../Pages/survey-topics';
import ReportsLanding from '../Pages/reports';
import ReportDetailsLanding from '../Pages/report-details-landing';
import MainDashboard from '../Pages/main-dashboard';
import HomeLanding from '../Pages/home';
import FeedbackPage from '../Pages/feedback';
import UserProfilePage from '../Pages/user-profile';
import ChangePasswordPage from '../Pages/ChangePasswordPage';
import SurveyEditWizard from '../Pages/survey-wizard';

const routes = [
  {
    path: '/',
    type: 'private',
    id: 'admin',
    component: () => <HomeLanding />,
  },
  {
    path: '/admin/dashboard',
    type: 'private',
    id: 'admin-dashboard',
    component: () => <Dashboard />,
  },
  {
    path: '/admin/dashboard/manager',
    type: 'private',
    id: 'admin-dashboard-manager',
    adminRoute: true,
    component: () => <DashboardDataManager />,
  },
  {
    path: '/admin/dashboard/manager/add',
    type: 'private',
    id: 'admin-dashboard-manager-add',
    component: () => <AddNewData />,
    adminRoute: true,
  },
  {
    path: '/admin/survey',
    type: 'private',
    id: 'admin-survey',
    adminRoute: true,
    component: () => <Survey />,
  },
  {
    path: '/admin/survey/new',
    type: 'private',
    id: 'admin-survey-new',
    adminRoute: true,
    component: () => <NewSurvey />,
  },
  {
    path: '/admin/survey/edit/:id',
    type: 'private',
    id: 'admin-survey-edit',
    adminRoute: true,
    component: () => <SurveyEditWizard />,
  },
  {
    path: '/admin/survey/replace/:id',
    type: 'private',
    id: 'admin-survey-replace',
    adminRoute: true,
    component: () => <ReplaceDataSet />,
  },
  {
    path: '/signup',
    type: 'public',
    id: 'signup',
    component: () => <Signup />,
  },
  {
    path: '/login',
    type: 'public',
    id: 'login',
    component: () => <Login />,
  },
  {
    path: '/activate/:token',
    type: 'public',
    id: 'activate',
    component: () => <Activate />,
  },
  {
    path: '/reset-password/:token',
    type: 'public',
    id: 'activate',
    component: () => <ResetPassword />,
  },
  {
    path: '/forgot-password',
    type: 'public',
    id: 'forgot',
    component: () => <ForgotPassword />,
  },
  {
    path: '/404',
    type: 'public',
    id: 'admin',
    component: () => <NoMatch />,
  },
  {
    path: '/admin/dashboard/update/:id',
    type: 'private',
    id: 'admin-dashboard-update',
    adminRoute: true,
    component: () => <UpdateVisualization />,
  },
  {
    path: '/admin/manage-users',
    type: 'private',
    id: 'admin-user-management',
    component: () => <ManageUsers />,
  },
  {
    path: '/surveys/:id',
    type: 'private',
    id: 'admin-surveys-detail',
    component: () => <SurveyDetailsLanding />,
  },
  {
    path: '/survey-topics',
    type: 'private',
    id: 'survey-topics',
    component: () => <SurveyTopicsLanding />,
  },
  {
    path: '/survey-explorer',
    type: 'private',
    id: 'survey-explorer',
    component: () => <SurveyExplorerLanding />,
  },
  {
    path: '/reports',
    type: 'private',
    id: 'reports',
    component: () => <ReportsLanding />,
  },
  {
    path: '/reports/:id',
    type: 'private',
    id: 'report-details',
    component: () => <ReportDetailsLanding />,
  },
  {
    path: '/home',
    type: 'private',
    id: 'home',
    component: () => <HomeLanding />,
  },
  {
    path: '/dashboard',
    type: 'private',
    id: 'dashboard',
    component: () => <MainDashboard />,
  },
  {
    path: '/feedback',
    type: 'private',
    id: 'feedback',
    component: () => <FeedbackPage />,
  },
  {
    path: '/profile',
    type: 'private',
    id: 'profile',
    component: () => <UserProfilePage />,
  },
  {
    path: '/change-password',
    type: 'private',
    id: 'changePassword',
    component: () => <ChangePasswordPage />,
  },
  {
    path: '/admin/manage-organizations',
    type: 'private',
    id: 'admin-organizations-management',
    component: () => <ManageOrganizations />,
  },
];

const renderRoutes = () => {
  const allRoutes = routes.map((route) => {
    if (route.type === 'public') {
      return <Route exact path={route.path} component={route.component} key={route.id} />;
    }
    return <PrivateRoute exact path={route.path} component={route.component} key={route.id} />;
  });

  return allRoutes;
};

export default renderRoutes;

/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { login } from '../../actions/session.action';
import Spinner from '../Spinner';
import './style.css';

const LoginForm = ({ disableLogin }) => {
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const jwt = useSelector((state) => state.session.jwt);

  const validateAndSend = async (payload) => {
    setIsSending(true);
    dispatch(login(payload));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoginError(false);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const { elements } = form;
      const payload = {};
      Object.keys(elements).forEach((elKey) => {
        const el = elements[elKey];
        if (el.name && el.value) {
          const key = el.name.replace(/[^a-zA-Z0-9]/g, '');
          payload[key] = el.value;
        }
      });
      validateAndSend(payload);
    }
    setValidated(true);
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  useEffect(() => {
    if (typeof jwt === 'undefined') {
      setIsSending(false);
      setShowLoginError(true);
    } else if (jwt && jwt !== 'error') {
      if (rememberMe) {
        sessionStorage.setItem('jwt', jwt);
      }
      const query = new URLSearchParams(search);
      const returnUrl = query.get('returnUrl');
      history.push(returnUrl || '/');
    } else if (jwt === 'error') {
      setIsSending(false);
      setShowLoginError(true);
    }
  }, [jwt]);

  return (
    <Container className="bg-white p-4 m-0 login-form-container">
      <Container className="p-0 mb-5">
        Need a free account?
        <a href="/signup" className="Login-btn ml-3 rounded-0">
          <span className="button-text">Get Account</span>
        </a>
      </Container>
      {isSending && <Spinner size="lg" />}
      {showLoginError && (
        <Alert variant="danger">
          <p className="m-0">Email/Password invalid.</p>
        </Alert>
      )}
      {!isSending && (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              required
              type="email"
              placeholder="Your Email *"
              name="username"
              size="md"
              className="mb-2"
              maxLength="100"
            />
            <Form.Control.Feedback type="invalid">Please complete email.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              required
              type="password"
              placeholder="Password *"
              name="password"
              size="md"
              className="mb-2"
              maxLength="100"
            />
            <Form.Control.Feedback type="invalid">Please complete password.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="Login-form-selector">
            <Form.Check
              onClick={handleRememberMe}
              checked={rememberMe}
              type="checkbox"
              label="Remember me"
            />
          </Form.Group>
          <Form.Group>
            <Container className="p-0">
              <Button disabled={disableLogin} type="submit" className="bento-btn mr-3">
                <span className="button-text">Login</span>
              </Button>
              <a href="/forgot-password">I forgot my password</a>
            </Container>
          </Form.Group>
        </Form>
      )}
    </Container>
  );
};

LoginForm.propTypes = {
  disableLogin: PropTypes.bool,
};

LoginForm.defaultProps = {
  disableLogin: false,
};

export default LoginForm;

export const HI = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-156.1193, 19.7365],
              [-156.0795, 19.8311],
              [-155.9624, 19.9092],
              [-155.8843, 20.0183],
              [-155.9577, 20.1676],
              [-155.948, 20.2617],
              [-155.8368, 20.3212],
              [-155.7515, 20.2945],
              [-155.5788, 20.1801],
              [-155.4382, 20.1488],
              [-155.2524, 20.0634],
              [-155.1403, 19.9895],
              [-155.0316, 19.875],
              [-154.9289, 19.6736],
              [-154.7593, 19.5365],
              [-154.7758, 19.4571],
              [-154.9397, 19.3128],
              [-155.1821, 19.21],
              [-155.258, 19.2222],
              [-155.4949, 19.0716],
              [-155.5656, 18.9345],
              [-155.6943, 18.8668],
              [-155.74, 18.9169],
              [-155.9259, 19.0082],
              [-155.974, 19.1078],
              [-155.9432, 19.3442],
              [-156.0753, 19.621],
              [-156.1193, 19.7365],
            ],
          ],
          [
            [
              [-156.756, 20.5302],
              [-156.7336, 20.5818],
              [-156.5977, 20.6579],
              [-156.5119, 20.7354],
              [-156.625, 20.75],
              [-156.7217, 20.8424],
              [-156.7519, 20.9137],
              [-156.735, 21.0057],
              [-156.6704, 21.0767],
              [-156.591, 21.0924],
              [-156.4907, 21.0354],
              [-156.4511, 20.9688],
              [-156.3392, 21.0042],
              [-156.1953, 20.9715],
              [-156.0765, 20.8808],
              [-156, 20.8521],
              [-155.9268, 20.7817],
              [-155.9615, 20.6605],
              [-156.0163, 20.6124],
              [-156.2905, 20.5369],
              [-156.4599, 20.5577],
              [-156.5335, 20.463],
              [-156.75, 20.4543],
              [-156.756, 20.5302],
            ],
          ],
          [
            [
              [-157.1162, 20.9028],
              [-157.0353, 20.9833],
              [-156.9133, 20.978],
              [-156.8233, 20.9308],
              [-156.75, 20.8076],
              [-156.7954, 20.7353],
              [-156.875, 20.691],
              [-156.9944, 20.6936],
              [-157.0481, 20.8205],
              [-157.1162, 20.9028],
            ],
          ],
          [
            [
              [-157.3663, 21.1167],
              [-157.3053, 21.2529],
              [-157.2202, 21.2785],
              [-156.9103, 21.2296],
              [-156.7221, 21.2261],
              [-156.6596, 21.1819],
              [-156.6726, 21.0957],
              [-156.837, 21],
              [-156.9145, 20.9976],
              [-157.0721, 21.0576],
              [-157.3212, 21.0507],
              [-157.3663, 21.1167],
            ],
          ],
          [
            [
              [-158.3447, 21.5832],
              [-158.3242, 21.6157],
              [-158.1488, 21.642],
              [-158.0501, 21.7399],
              [-157.9418, 21.7546],
              [-157.7509, 21.5165],
              [-157.6609, 21.4968],
              [-157.6082, 21.3531],
              [-157.6077, 21.2691],
              [-157.6758, 21.2138],
              [-157.8102, 21.1992],
              [-157.8987, 21.2499],
              [-158.1101, 21.2371],
              [-158.2762, 21.4493],
              [-158.3447, 21.5832],
            ],
          ],
          [
            [
              [-159.851, 22.0275],
              [-159.8481, 22.0761],
              [-159.75, 22.1994],
              [-159.613, 22.2722],
              [-159.4029, 22.2905],
              [-159.3088, 22.25],
              [-159.2458, 22.1668],
              [-159.2844, 21.9406],
              [-159.444, 21.8178],
              [-159.6313, 21.8467],
              [-159.7936, 21.9398],
              [-159.851, 22.0275],
            ],
          ],
          [
            [
              [-160.3044, 21.8538],
              [-160.2799, 21.9206],
              [-160.174, 21.9915],
              [-160.1404, 22.0733],
              [-160.0567, 22.0683],
              [-159.9972, 21.9925],
              [-160.031, 21.8694],
              [-160.122, 21.825],
              [-160.1703, 21.7396],
              [-160.2658, 21.7462],
              [-160.3044, 21.8538],
            ],
          ],
          [
            [
              [-160.6009, 21.6582],
              [-160.5088, 21.7032],
              [-160.4849, 21.6324],
              [-160.5707, 21.6067],
              [-160.6009, 21.6582],
            ],
          ],
          [
            [
              [-161.9876, 23.0699],
              [-161.8876, 23.1104],
              [-161.8633, 23.0366],
              [-161.9552, 23.0092],
              [-161.9876, 23.0699],
            ],
          ],
          [
            [
              [-164.7648, 23.5817],
              [-164.6826, 23.6228],
              [-164.64, 23.5761],
              [-164.7013, 23.517],
              [-164.7648, 23.5817],
            ],
          ],
          [
            [
              [-166.3865, 23.8464],
              [-166.3071, 23.9249],
              [-166.1823, 23.9023],
              [-166.1482, 23.7855],
              [-166.1068, 23.7425],
              [-166.1168, 23.6113],
              [-166.1944, 23.5895],
              [-166.2054, 23.6916],
              [-166.3158, 23.7401],
              [-166.3865, 23.8464],
            ],
          ],
          [
            [
              [-171.8096, 25.7813],
              [-171.7681, 25.8337],
              [-171.6766, 25.8109],
              [-171.6805, 25.7368],
              [-171.7375, 25.7065],
              [-171.8096, 25.7813],
            ],
          ],
          [
            [
              [-174.0674, 26.0596],
              [-174.0496, 26.1166],
              [-173.9579, 26.1357],
              [-173.9134, 26.0994],
              [-173.926, 26.0196],
              [-174.0064, 25.9903],
              [-174.0674, 26.0596],
            ],
          ],
          [
            [
              [-176.0434, 27.7959],
              [-175.9663, 27.9299],
              [-175.8067, 28.0072],
              [-175.7349, 28],
              [-175.6701, 27.9416],
              [-175.6665, 27.875],
              [-175.7341, 27.7389],
              [-175.9696, 27.6934],
              [-176.0434, 27.7959],
            ],
          ],
          [
            [
              [-178.4436, 28.419],
              [-178.3958, 28.5],
              [-178.3151, 28.5163],
              [-178.2385, 28.4561],
              [-178.233, 28.375],
              [-178.3014, 28.3283],
              [-178.3956, 28.3419],
              [-178.4436, 28.419],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '15',
        SCSDLEA: '',
        GEOID: '1500030',
        NAME: 'Hawaii Department of Education',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16634133472,
        AWATER: 11777724416,
        INTPTLAT: '+19.8281713',
        INTPTLON: '-155.4950401',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
  ],
};

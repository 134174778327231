/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Dropdown, Row, Container, Collapse, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsAlt,
  faExclamationTriangle,
  faAngleDown,
  faAngleUp,
  faTrash,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import BarChart from '../../../../Components/Chart/BarChart';
import ColumnChart from '../../../../Components/Chart/ColumnChart';
import DonutChart from '../../../../Components/Chart/DonutChart';
import HeatMapChart from '../../../../Components/Chart/HeatMapChart';
import RadarChart from '../../../../Components/Chart/RadarChart';
import BarChartUneven from '../../../../Components/Chart/BarChartUneven';
import WaffleChart from '../../../../Components/Chart/WaffleChart';
import { parseMLIData } from '../../../../helpers/charts';
import { HIGHCHARTS_TYPES, CHART_TYPES, COMPONENT_ID_MAP } from '../../../../constants/charts';
import { ChartMenuCustomToggle, HeaderFooterMenuCustomToggle } from '../../../../Components/Chart';
import MapChart from '../../../../Components/Chart/MapChart';
import RankChart from '../../../../Components/Chart/RankChart';
import Demographics from '../../../../Components/Demographics';
import DashboardLine from '../../../../Components/DashboardLine';
import GraduationChart from '../../../../Components/Chart/GraduationChart';
import { getSurveyQuestionsCategoriesApi } from '../../../../api/surveys';

import './style.css';
import RelatedQuestion from '../../../../Components/RelatedQuestion';

const ReportChart = React.forwardRef(
  (
    {
      config,
      context,
      visualization,
      questionCategory,
      generalFilters,
      questionFilters,
      locationDescription,
      dragHandleProps,
      shared,
      onMoveAbove,
      onMoveBelow,
      onSendToTheTop,
      onSendToTheBottom,
      onDelete,
      itemId,
      itemNotes,
      updateNotes,
      handleRef,
    },
    chartRef
  ) => {
    const [showFilters, setShowFilters] = useState(true);
    const [checked, setChecked] = useState(false);
    const [showHeader, setShowHeader] = useState(false);
    const [showFooter, setShowFooter] = useState(false);
    const [headerText, setHeaderText] = useState(itemNotes?.header);
    const [footerText, setFooterText] = useState(itemNotes?.footer);
    const storeJwt = useSelector((state) => state.session.jwt);
    const [stateQuestionCategory, setStateQuestionCategory] = useState(questionCategory);
    const [stateRelatedQuestionCategory, setStateRelatedQuestionCategory] = useState();
    const [parsedData, setParsedData] = useState();
    const [currentChartType] = useState(
      visualization?.details?.chartSettings?.chartSettings[context.id]?.selectedChartType ||
        context?.defaultVisualizationType ||
        ''
    );

    const isRelated = useMemo(() => !!context.relatedQuestionId, [context.relatedQuestionId]);

    const anyGeneralQuestionFilterApplies = useMemo(() => {
      return generalFilters.length > 1;
    }, [visualization?.details?.filter?.constraintGroups]);

    const anySegmentApplies = useMemo(() => {
      return !!visualization?.details?.segments?.find((segment) => segment.active);
    }, [visualization.details.segments]);

    const [currentBenchmark] = useState(
      visualization?.details?.chartSettings?.chartSettings[context.id]?.benchmarks || {
        national: false,
        state: false,
      }
    );
    const qtyOptions = context?.answers?.length || context?.answerOptionsType?.length || 1;

    const isOrganizationSurvey = !!context?.queryResult?.organization?.id;

    const loadAsyncQuestionCategory = async () => {
      if (context.id) {
        const data = await getSurveyQuestionsCategoriesApi(context.id, storeJwt);
        setStateQuestionCategory(data);
      }
    };

    const loadAsyncRelatedQuestionCategory = async () => {
      if (context.relatedQuestionId) {
        const data = await getSurveyQuestionsCategoriesApi(context.relatedQuestionId, storeJwt);
        setStateRelatedQuestionCategory(data);
      }
    };

    const onAddHeader = () => setShowHeader(true);

    const onAddFooter = () => setShowFooter(true);

    useEffect(() => {
      if (context && stateQuestionCategory) {
        const co = parseMLIData(
          context,
          context?.queryResult,
          currentBenchmark,
          stateQuestionCategory,
          0,
          currentChartType,
          visualization?.details?.enableDefaultSegment
        );
        setParsedData(co);
      }
    }, [context, visualization, stateQuestionCategory, currentBenchmark, currentChartType]);

    useEffect(() => {
      if (context && stateQuestionCategory === null) {
        loadAsyncQuestionCategory();
      }
      if (context && isRelated && !stateRelatedQuestionCategory) {
        loadAsyncRelatedQuestionCategory();
      }
    }, [context, stateQuestionCategory, isRelated, stateRelatedQuestionCategory]);

    const handleChartLoaded = useCallback(() => {}, []);
    const handleFiltersToggle = () => setShowFilters(!showFilters);

    const visualizationComponent =
      config.type === 'DASHBOARD_ELEMENT'
        ? visualization.details.components.find((c) => c.id === context.componentId)
        : null;

    const handleHeaderFooterUpdate = () => {
      if (headerText || footerText) {
        updateNotes(JSON.stringify({ header: headerText, footer: footerText }));
      }
    };

    const handleDeleteHeaderUpdate = () => {
      updateNotes(JSON.stringify({ header: '', footer: footerText }));
    };

    const handleDeleteFooterUpdate = () => {
      updateNotes(JSON.stringify({ header: headerText, footer: '' }));
    };

    const handleCancelHeader = () => {
      setHeaderText(itemNotes?.header || '');
      setShowHeader(false);
    };

    const handleCancelFooter = () => {
      setFooterText(itemNotes?.footer || '');
      setShowFooter(false);
    };

    return (
      <Container fluid className="p-5 report-details-item-container">
        {!showHeader && headerText && (
          <Row noGutters className="p-4 d-flex align-items-center report-item-header-text">
            <Col md={11}>
              <p>{headerText}</p>
            </Col>
            <Col md={1} className="d-flex justify-content-center">
              <Button
                variant="outline-secondary"
                onClick={handleDeleteHeaderUpdate}
                className="btn btn-custom white mr-3"
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
              <Button
                variant="primary"
                onClick={() => setShowHeader(true)}
                className="btn btn-custom dark"
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Col>
          </Row>
        )}
        {showHeader && (
          <Row noGutters className="p-4 d-flex align-items-center report-item-header-text">
            <>
              <Col md={10}>
                <Form.Control
                  placeholder="Add a description to this report item..."
                  required
                  as="textarea"
                  maxLength={500}
                  rows={3}
                  onChange={(e) => setHeaderText(e.target.value)}
                  defaultValue={headerText}
                />
              </Col>
              <Col md={2} className="d-flex justify-content-center">
                <Button
                  variant="outline-secondary"
                  className="btn btn-custom white mr-3"
                  onClick={handleCancelHeader}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleHeaderFooterUpdate}
                  className="btn btn-custom dark"
                >
                  Apply
                </Button>
              </Col>
            </>
          </Row>
        )}
        <Row noGutters className="p-4 d-flex align-items-center report-item-header">
          {config.type === 'QUESTION' ? (
            <>
              <Col xs={1}>
                <h3>{context?.displayIdentifier}</h3>
              </Col>
              <Col md={10}>
                <p className="mb-0">{context?.prompt}</p>
              </Col>
            </>
          ) : (
            <>
              <Col xs={1}>
                <h3>DB</h3>
              </Col>
              <Col md={10}>
                <p className="mb-0">{visualizationComponent.datasetFilter.description}</p>
              </Col>
            </>
          )}
          {!shared && (
            <Col md={1} className="d-flex justify-content-end align-items-center">
              <Dropdown>
                <Dropdown.Toggle className="d-flex" as={HeaderFooterMenuCustomToggle} />
                <Dropdown.Menu>
                  <Dropdown.Item onClick={onAddHeader}>Add header text</Dropdown.Item>
                  <Dropdown.Item onClick={onAddFooter}>Add footer text</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle className="w-100 d-flex" as={ChartMenuCustomToggle} />
                <Dropdown.Menu>
                  <Dropdown.Item onClick={onMoveAbove}>Move above</Dropdown.Item>
                  <Dropdown.Item onClick={onMoveBelow}>Move below</Dropdown.Item>
                  <Dropdown.Item onClick={onSendToTheTop}>Send to the top</Dropdown.Item>
                  <Dropdown.Item onClick={onSendToTheBottom}>Send to the bottom</Dropdown.Item>
                  <Dropdown.Item onClick={onDelete}>Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div {...dragHandleProps}>
                <FontAwesomeIcon icon={faArrowsAlt} size="lg" />
              </div>
            </Col>
          )}
        </Row>
        <Row noGutters className="bg-white">
          <Col md="9" className="p-4 border-bottom">
            {config.type === 'DASHBOARD_ELEMENT' &&
              context.componentId === COMPONENT_ID_MAP.ID_MAP && (
                <>
                  <div id={`map-chart-${itemId}-${context.componentId}`}>
                    <MapChart
                      componentConfig={context}
                      visualizationDetails={visualization?.details}
                      readOnly
                      ref={chartRef}
                    />
                  </div>
                  <div id={`rank-chart-${itemId}-${context.componentId}`}>
                    <RankChart
                      componentConfig={context}
                      visualizationDetails={visualization?.details}
                      readOnly
                    />
                  </div>
                </>
              )}
            {config.type === 'DASHBOARD_ELEMENT' &&
              context.componentId === COMPONENT_ID_MAP.ID_DEMOGRAPHICS && (
                <Demographics
                  componentConfig={context}
                  visualizationConfig={visualizationComponent}
                  readOnly
                  ref={chartRef}
                  handleRef={handleRef}
                />
              )}
            {config.type === 'DASHBOARD_ELEMENT' &&
              context.componentId === COMPONENT_ID_MAP.ID_LINE && (
                <DashboardLine
                  componentsConfig={context}
                  visualizationDetails={visualization?.details}
                  readOnly
                  ref={chartRef}
                />
              )}
            {config.type === 'DASHBOARD_ELEMENT' &&
              context.componentId === COMPONENT_ID_MAP.ID_MLI_BAR && (
                <DashboardLine
                  componentsConfig={context}
                  visualizationDetails={visualization?.details}
                  readOnly
                  ref={chartRef}
                />
              )}
            {config.type === 'DASHBOARD_ELEMENT' &&
              context.componentId === COMPONENT_ID_MAP.ID_POINT_IN_TIME && (
                <GraduationChart
                  componentConfig={context}
                  visualizationDetails={visualization?.details}
                  readOnly
                  ref={chartRef}
                />
              )}
            {currentChartType === CHART_TYPES.STACKED_BAR && parsedData && (
              <BarChart
                parsedData={parsedData}
                benchmarks={currentBenchmark}
                qtyOptions={qtyOptions}
                chartLoaded={handleChartLoaded}
                ref={chartRef}
              />
            )}
            {currentChartType === CHART_TYPES.HORIZONTAL_BAR && parsedData && (
              <ColumnChart
                parsedData={parsedData}
                currentJob={context}
                chartType={HIGHCHARTS_TYPES.BAR}
                benchmarks={currentBenchmark}
                qtyOptions={qtyOptions}
                chartLoaded={handleChartLoaded}
                ref={chartRef}
              />
            )}
            {currentChartType === CHART_TYPES.COLUMN && parsedData && (
              <ColumnChart
                parsedData={parsedData}
                currentJob={context}
                benchmarks={currentBenchmark}
                chartLoaded={handleChartLoaded}
                ref={chartRef}
                qtyOptions={qtyOptions}
              />
            )}
            {currentChartType === CHART_TYPES.DONUT && parsedData && (
              <DonutChart
                parsedData={parsedData}
                chartLoaded={handleChartLoaded}
                ref={chartRef}
                benchmarks={currentBenchmark}
              />
            )}
            {currentChartType === CHART_TYPES.HEAT_MAP && parsedData && (
              <HeatMapChart
                parsedData={parsedData}
                chartLoaded={handleChartLoaded}
                ref={chartRef}
                qtyOptions={qtyOptions}
                benchmarks={currentBenchmark}
              />
            )}
            {currentChartType === CHART_TYPES.RADAR && parsedData && (
              <RadarChart parsedData={parsedData} chartLoaded={handleChartLoaded} ref={chartRef} />
            )}
            {(currentChartType === CHART_TYPES.SPLIT_BAR_POS_NEG ||
              currentChartType === CHART_TYPES.COMBINED_BAR_POS_NEG) &&
              parsedData && (
                <BarChartUneven
                  parsedData={parsedData}
                  chartLoaded={handleChartLoaded}
                  ref={chartRef}
                  benchmarks={currentBenchmark}
                />
              )}
            {currentChartType === CHART_TYPES.WAFFLE && parsedData && (
              <WaffleChart
                parsedData={parsedData}
                qtyOptions={qtyOptions}
                chartLoaded={handleChartLoaded}
                ref={chartRef}
                benchmarks={currentBenchmark}
              />
            )}
          </Col>
          <Col md="3" className="p-4 border-left border-bottom d-flex flex-column">
            <h3>Source</h3>
            <div className="pl-4 pt-2">
              {config.type === 'QUESTION' ? config.surveyDatasetName : 'Dashboard'}
            </div>
            {locationDescription && (
              <>
                <h3>Location</h3>
                <div className="pl-4 pt-2">{locationDescription}</div>
              </>
            )}
            {(generalFilters?.length > 0 || questionFilters?.length > 0) && (
              <>
                <h3 onClick={handleFiltersToggle} className="cursor-pointer">
                  Filters
                  <FontAwesomeIcon icon={showFilters ? faAngleUp : faAngleDown} className="ml-2" />
                </h3>
                <Collapse in={showFilters}>
                  <Container noGutters>
                    {generalFilters.map((gf) => (
                      <div className="pl-4 pt-2" key={gf.name}>
                        {gf.name} - {gf.selectedValues}
                      </div>
                    ))}
                    {questionFilters.map((qf) => (
                      <div className="pl-4 pt-2" key={qf.id}>
                        {qf.prompt} - {qf.selectedValues}
                      </div>
                    ))}
                  </Container>
                </Collapse>
              </>
            )}
            {visualization?.details?.enableDefaultSegment && (
              <>
                <h3>N-Size</h3>
                <div className="pl-4 pt-2">
                  {
                    context?.queryResult?.mliDataSegments?.find((ds) => ds.name === 'default')
                      .numberOfRespondentsInterval
                  }
                </div>
              </>
            )}
            {visualization?.details?.segments?.map((s) => {
              const sampleSizeSegment = context?.queryResult?.mliDataSegments?.find(
                (ds) => ds.name === s.label
              );
              const splitNumberInterval = sampleSizeSegment?.numberOfRespondentsInterval?.split(
                '-'
              );
              return (
                <>
                  <h3>N-Size - {sampleSizeSegment?.name}</h3>
                  <div className="pl-4 pt-2">
                    {!isOrganizationSurvey &&
                      Array.isArray(splitNumberInterval) &&
                      Number(splitNumberInterval[0]) < 50 && (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          color="red"
                          className="mr-3"
                        />
                      )}
                    {sampleSizeSegment?.numberOfRespondentsInterval}
                  </div>
                </>
              );
            })}
            {isRelated && !anyGeneralQuestionFilterApplies && !anySegmentApplies && (
              <div className="related-survey-tooltip rel">
                <Form>
                  <Form.Check
                    type="switch"
                    id={`${context?.id}`}
                    onChange={() => {
                      setChecked(!checked);
                    }}
                  />
                </Form>
              </div>
            )}
          </Col>
        </Row>
        {checked && isRelated && context?.queryResult?.relatedQuestions && (
          <Row
            noGutters
            className="bg-white py-5"
            id={`related-question-${context.id}-${context.relatedQuestionId}`}
          >
            <RelatedQuestion
              relatedQuestion={context?.queryResult?.relatedQuestions[0]}
              relatedSurveyName={context?.relatedSurveyName}
              visualization={visualization}
              chartLoaded={handleChartLoaded}
              ref={chartRef}
              currentBenchmark={currentBenchmark}
              currentCategory={stateRelatedQuestionCategory}
            />
          </Row>
        )}

        {!showFooter && footerText && (
          <Row noGutters className="p-4 d-flex align-items-center report-item-footer">
            <Col md={11}>
              <p>{footerText}</p>
            </Col>
            <Col md={1} className="d-flex justify-content-center">
              <Button
                variant="outline-secondary"
                onClick={handleDeleteFooterUpdate}
                className="btn btn-custom white mr-3"
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
              <Button
                variant="primary"
                onClick={() => setShowFooter(true)}
                className="btn btn-custom dark"
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Col>
          </Row>
        )}
        {showFooter && (
          <Row noGutters className="p-4 d-flex align-items-center report-item-footer">
            <>
              <Col md={10}>
                <Form.Control
                  placeholder="Add a description to this report item..."
                  required
                  as="textarea"
                  maxLength={500}
                  rows={3}
                  onChange={(e) => setFooterText(e.target.value)}
                  defaultValue={footerText}
                />
              </Col>
              <Col md={2} className="d-flex justify-content-center">
                <Button
                  variant="outline-secondary"
                  className="btn btn-custom white mr-3"
                  onClick={handleCancelFooter}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleHeaderFooterUpdate}
                  className="btn btn-custom dark"
                >
                  Apply
                </Button>
              </Col>
            </>
          </Row>
        )}
      </Container>
    );
  }
);

ReportChart.displayName = 'ReportChart';

export default React.memo(ReportChart);

// This function is use to parse the file type on the input.
export const parseFileType = (type) => {
  switch (type) {
    case 'csv':
      return '.csv, text/csv';
    case 'txt':
      return 'text/plain';
    case 'mp4':
      return '.mp4';
    default:
  }
  return '';
};

/* This function is use to parse the type
 *  of the uploaded file, in order to match with the input type.
 */
const normalizeFileType = (type) => {
  switch (type) {
    case 'text/csv':
      return '.csv';
    case 'csv':
      return '.csv';
    case '.csv, text/csv':
      return '.csv';
    case 'text/plain':
      return '.txt';
    case 'txt':
      return '.txt';
    case 'video/mp4':
      return '.mp4';
    default:
  }
  return type;
};

export const checkValidFile = (uploadedFileType, inputType) => {
  const tempUploadedFileType = normalizeFileType(inputType);
  const tempInputType = normalizeFileType(uploadedFileType);
  return tempUploadedFileType === tempInputType;
};

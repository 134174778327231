/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import socket from '../../socket';
import VendorSelect from '../VendorSelect';
import Informative from './Informative/Informative';
import Stepper from './Stepper';
import './style.css';

import { isImportingToBento } from '../../actions/bento.action';
import { openModal } from '../../actions/modal.action';
import modalMessages from '../../messages/modal.messages';

import {
  createNewAwsFolder,
  resetWizard,
  selectedSurvey,
  setFileProgress,
  setFileSuccess,
} from '../../actions/wizard.action';

const Wizard = ({ vendorSelect }) => {
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.session.jwt) || '';

  useEffect(() => {
    socket.connect(jwt);
    dispatch(createNewAwsFolder(jwt));
    socket.on('uploadProgress', (file) => {
      dispatch(setFileProgress(file));
    });

    socket.on('uploadComplete', (file) => {
      dispatch(setFileSuccess(file));
    });

    socket.on(
      'lambdaCompleted',
      (data) => {
        dispatch(isImportingToBento(false));
        const { type, MetadataId } = data;
        switch (type) {
          case 'dashboardImport':
            dispatch(openModal(modalMessages('importDashboardBentoSuccess')));
            break;
          case 'adminImport':
            dispatch(selectedSurvey(MetadataId));
            dispatch(openModal(modalMessages('importSampleBentoSuccess')));
            break;
          case 'adminReplace':
            dispatch(selectedSurvey(MetadataId));
            dispatch(openModal(modalMessages('importSampleBentoSuccess')));
            break;
          default:
        }
      },
      []
    );

    socket.on(
      'lambdaError',
      (data) => {
        dispatch(isImportingToBento(false));
        const { type } = data;
        switch (type) {
          case 'dashboardImport':
            dispatch(openModal(modalMessages('importDashboardBentoError')));
            break;
          case 'adminImport':
            dispatch(openModal(modalMessages('importSampleBentoError')));
            break;
          case 'adminReplace':
            dispatch(openModal(modalMessages('importSampleBentoError')));
            break;
          default:
        }
      },
      []
    );

    return () => {
      dispatch(resetWizard());
      socket.close();
    };
  }, []);
  return (
    <div className="Wizard">
      <div className="Wizard-content">
        <div className="Wizard-left">
          <Informative />
        </div>
        <div className="Wizard-right">
          {vendorSelect && <VendorSelect />}
          <Stepper />
        </div>
      </div>
    </div>
  );
};

Wizard.propTypes = {
  vendorSelect: PropTypes.bool,
};

Wizard.defaultProps = {
  vendorSelect: false,
};

export default Wizard;

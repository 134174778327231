export const leas = [
  {
    state_abbr: 'AK',
    leaid: '200006',
    lea_name: 'Mount Edgecumbe High School Agency',
  },
  {
    state_abbr: 'AK',
    leaid: '200010',
    lea_name: 'Aleutian Region School District',
  },
  {
    state_abbr: 'AL',
    leaid: '100194',
    lea_name: 'Pelham City',
  },
  {
    state_abbr: 'AR',
    leaid: '500061',
    lea_name: 'IMBODEN CHARTER SCHOOL DISTRICT',
  },
  {
    state_abbr: 'AR',
    leaid: '500035',
    lea_name: 'ARK. SCHOOL FOR THE BLIND',
  },
  {
    state_abbr: 'AR',
    leaid: '500036',
    lea_name: 'ARK. SCHOOL FOR THE DEAF',
  },
  {
    state_abbr: 'AR',
    leaid: '500055',
    lea_name: 'BENTON COUNTY SCHOOL OF ARTS',
  },
  {
    state_abbr: 'AR',
    leaid: '500059',
    lea_name: 'ACADEMICS PLUS CHARTER SCHOOL',
  },
  {
    state_abbr: 'AR',
    leaid: '500062',
    lea_name: 'KIPP DELTA PUBLIC SCHOOLS',
  },
  {
    state_abbr: 'AR',
    leaid: '500074',
    lea_name: 'LISA ACADEMY CHARTER',
  },
  {
    state_abbr: 'AR',
    leaid: '500078',
    lea_name: 'HAAS HALL ACADEMY',
  },
  {
    state_abbr: 'AR',
    leaid: '500390',
    lea_name: 'ARKANSAS YOUTH SERVICES SCHOOL SYSTEM',
  },
  {
    state_abbr: 'AR',
    leaid: '500394',
    lea_name: 'ARKANSAS VIRTUAL ACADEMY',
  },
  {
    state_abbr: 'AR',
    leaid: '500397',
    lea_name: 'COVENANT KEEPERS MIDDLE CHARTER',
  },
  {
    state_abbr: 'AR',
    leaid: '500398',
    lea_name: 'ESTEM HIGH CHARTER',
  },
  {
    state_abbr: 'AR',
    leaid: '500402',
    lea_name: 'JACKSONVILLE LIGHTHOUSE CHARTER',
  },
  {
    state_abbr: 'AR',
    leaid: '500403',
    lea_name: 'LITTLE ROCK PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'AR',
    leaid: '500406',
    lea_name: 'PINE BLUFF LIGHTHOUSE ACADEMY',
  },
  {
    state_abbr: 'AR',
    leaid: '500407',
    lea_name: 'SIATECH LITTLE ROCK CHARTER',
  },
  {
    state_abbr: 'AR',
    leaid: '500408',
    lea_name: 'RESPONSIVE ED SOLUTIONS PREMIER HIGH SCHOOL OF LITTLE ROCK',
  },
  {
    state_abbr: 'AR',
    leaid: '500409',
    lea_name: 'RESPONSIVE ED SOLUTIONS NORTHWEST ARK CLASSICAL ACADEMY',
  },
  {
    state_abbr: 'AR',
    leaid: '500410',
    lea_name: 'RESPONSIVE ED SOLUTIONS QUEST MIDDLE SCHOOL OF PINE BLUFF',
  },
  {
    state_abbr: 'AR',
    leaid: '500411',
    lea_name: 'EXALT ACADEMY OF SOUTHWEST LITTLE ROCK',
  },
  {
    state_abbr: 'AR',
    leaid: '500412',
    lea_name: 'RESPONSIVE ED SOLUTIONS QUEST MIDDLE SCHOOL OF LITTLE ROCK',
  },
  {
    state_abbr: 'AZ',
    leaid: '400423',
    lea_name: 'Legacy Education Group',
  },
  {
    state_abbr: 'AZ',
    leaid: '400036',
    lea_name: 'Foothills Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400047',
    lea_name: 'Salt River Pima-Maricopa  Community Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400219',
    lea_name: 'Career Success Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400037',
    lea_name: 'Twenty First Century Charter School  Inc. Bennett Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400040',
    lea_name: 'STEP UP Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400041',
    lea_name: 'Maricopa County Community College District dba Gateway Early',
  },
  {
    state_abbr: 'AZ',
    leaid: '400043',
    lea_name: 'New School For The Arts',
  },
  {
    state_abbr: 'AZ',
    leaid: '400016',
    lea_name: 'Center for Academic Success  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400018',
    lea_name: 'Pine Forest Education Association  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400019',
    lea_name: 'Flagstaff Arts And Leadership Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400020',
    lea_name: 'Flagstaff Montessori  L.L.C.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400028',
    lea_name: 'Triumphant Learning Center',
  },
  {
    state_abbr: 'AZ',
    leaid: '400031',
    lea_name: 'Ridgeline Academy  A Challenge Foundation Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400033',
    lea_name: 'Boys & Girls Clubs of the East Valley dba Mesa Arts Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400034',
    lea_name: "Reid Traditional Schools' Valley Academy  Inc.",
  },
  {
    state_abbr: 'AZ',
    leaid: '400052',
    lea_name: 'Espiritu Community Development Corp.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400053',
    lea_name: 'Heritage Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400054',
    lea_name: 'Villa Montessori Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400055',
    lea_name: 'EduPreneurship  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400056',
    lea_name: 'Arizona School For The Arts',
  },
  {
    state_abbr: 'AZ',
    leaid: '400057',
    lea_name: 'Arizona Call-a-Teen Youth Resources  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400061',
    lea_name: 'Intelli-School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400065',
    lea_name: 'Benjamin Franklin Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400067',
    lea_name: 'Victory High School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400068',
    lea_name: 'Montessori Day Public Schools Chartered  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400069',
    lea_name: 'Khalsa Montessori Elementary Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400070',
    lea_name: 'Tempe Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400071',
    lea_name: 'Bright Beginnings School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400072',
    lea_name: 'Montessori Education Centre Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400074',
    lea_name: 'Kingman Academy Of Learning',
  },
  {
    state_abbr: 'AZ',
    leaid: '400075',
    lea_name: 'Young Scholars Academy Charter School Corp.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400077',
    lea_name: 'Career Development  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400078',
    lea_name: 'Edge School  Inc.  The',
  },
  {
    state_abbr: 'AZ',
    leaid: '400079',
    lea_name: 'Tucson Youth Development/ACE Charter High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400081',
    lea_name: 'Presidio School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400083',
    lea_name: 'Portable Practical Educational Preparation  Inc. (PPEP  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400089',
    lea_name: 'Sedona Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400090',
    lea_name: 'Mingus Springs Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400092',
    lea_name: 'Franklin Phonetic Primary School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400093',
    lea_name: 'Skyview School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400094',
    lea_name: 'Az-Tec High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400095',
    lea_name: 'Yuma Private Industry Council  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400096',
    lea_name: 'Mountain School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400097',
    lea_name: 'Northland Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400098',
    lea_name: 'Flagstaff Junior Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400099',
    lea_name: 'Liberty High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400100',
    lea_name: 'Ball Charter Schools (Hearn)',
  },
  {
    state_abbr: 'AZ',
    leaid: '400102',
    lea_name: 'Friendly House  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400103',
    lea_name: 'Ombudsman Educational Services  Ltd. a subsidiary of Educati',
  },
  {
    state_abbr: 'AZ',
    leaid: '400104',
    lea_name: 'Global Renaissance Academy of Distinguished Education',
  },
  {
    state_abbr: 'AZ',
    leaid: '400105',
    lea_name: 'Hillcrest Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400106',
    lea_name: 'Arizona Agribusiness & Equine Center  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400107',
    lea_name: 'International Commerce Secondary Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400108',
    lea_name: 'Humanities and Sciences Academy of the United States  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400109',
    lea_name: 'Phoenix Advantage Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400111',
    lea_name: 'Allen-Cochran Enterprises  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400112',
    lea_name: 'American Leadership Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400113',
    lea_name: 'Fountain Hills Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400115',
    lea_name: 'Hermosa Montessori Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400120',
    lea_name: 'Arizona Department of Corrections',
  },
  {
    state_abbr: 'AZ',
    leaid: '400121',
    lea_name: 'Accelerated Learning Center  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400122',
    lea_name: 'Acclaim Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400124',
    lea_name: 'Genesis Program  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400125',
    lea_name: 'Luz Academy of Tucson  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400126',
    lea_name: 'Montessori Schoolhouse of Tucson  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400128',
    lea_name: 'Academy Of Excellence  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400129',
    lea_name: 'Accelerated Elementary and Secondary Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400130',
    lea_name: 'American Heritage Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400131',
    lea_name: 'The Charter Foundation  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400133',
    lea_name: 'Employ-Ability Unlimited  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400134',
    lea_name: 'BASIS School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400135',
    lea_name: 'Challenge School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400141',
    lea_name: 'Discovery Plus Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400144',
    lea_name: 'Florence Crittenton Services of Arizona  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400145',
    lea_name: 'Gem Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400147',
    lea_name: 'Ha:san Educational Services',
  },
  {
    state_abbr: 'AZ',
    leaid: '400149',
    lea_name: 'Kaizen Education Foundation dba El Dorado High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400150',
    lea_name: 'Mexicayotl Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400151',
    lea_name: 'New Horizon School for the Performing Arts',
  },
  {
    state_abbr: 'AZ',
    leaid: '400153',
    lea_name: 'Phoenix School of Academic Excellence The',
  },
  {
    state_abbr: 'AZ',
    leaid: '400154',
    lea_name: 'Shonto Governing Board of Education  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400157',
    lea_name: 'Edkey  Inc. - Sequoia Choice Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400159',
    lea_name: 'Tucson Preparatory School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400160',
    lea_name: 'Vision Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400161',
    lea_name: "Westwind Children's Services",
  },
  {
    state_abbr: 'AZ',
    leaid: '400163',
    lea_name: 'Coconino County Accommodation School District',
  },
  {
    state_abbr: 'AZ',
    leaid: '400164',
    lea_name: 'Arizona Academy of Science And Technology  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400165',
    lea_name: 'Keystone Montessori Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400181',
    lea_name: 'Benchmark School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400182',
    lea_name: 'Cambridge Academy  East   Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400184',
    lea_name: 'Ball Charter Schools (Dobson)',
  },
  {
    state_abbr: 'AZ',
    leaid: '400185',
    lea_name: 'Phoenix Education Management  LLC',
  },
  {
    state_abbr: 'AZ',
    leaid: '400187',
    lea_name: 'Stepping Stones Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400189',
    lea_name: 'East Valley Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400190',
    lea_name: 'Liberty Traditional Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400191',
    lea_name: 'Fit Kids  Inc. dba Champion Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400192',
    lea_name: 'Sonoran Desert School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400197',
    lea_name: 'Northern Arizona Vocational Institute of Technology',
  },
  {
    state_abbr: 'AZ',
    leaid: '400201',
    lea_name: 'Pima County Board of Supervisors',
  },
  {
    state_abbr: 'AZ',
    leaid: '400202',
    lea_name: 'Academy of Tucson  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400204',
    lea_name: 'Mountain Rose Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400205',
    lea_name: 'Great Expectations Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400206',
    lea_name: 'Desert Springs Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400207',
    lea_name: 'Vechij Himdag Alternative School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400208',
    lea_name: 'Mountain Oak Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400209',
    lea_name: 'Park View School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400210',
    lea_name: 'Painted Pony Ranch Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400211',
    lea_name: 'Mary Ellen Halvorson Educational Foundation. dba: Tri-City P',
  },
  {
    state_abbr: 'AZ',
    leaid: '400213',
    lea_name: 'Painted Desert Demonstration Projects  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400214',
    lea_name: 'The Shelby School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400216',
    lea_name: 'Precision Academy Systems  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400217',
    lea_name: 'Little Lamb Community School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400218',
    lea_name: 'American Basic Schools LLC',
  },
  {
    state_abbr: 'AZ',
    leaid: '400221',
    lea_name: 'Kaizen Education Foundation dba South Pointe Elementary Scho',
  },
  {
    state_abbr: 'AZ',
    leaid: '400223',
    lea_name: 'Daisy Education Corporation dba Sonoran Science Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400225',
    lea_name: 'Horizon Community Learning Center  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400227',
    lea_name: 'Kaizen Education Foundation dba South Pointe Junior High Sch',
  },
  {
    state_abbr: 'AZ',
    leaid: '400229',
    lea_name: 'Milestones Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400230',
    lea_name: 'Pathfinder Charter School Foundation',
  },
  {
    state_abbr: 'AZ',
    leaid: '400231',
    lea_name: 'James Madison Preparatory School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400232',
    lea_name: 'Self Development Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400235',
    lea_name: 'Excalibur Charter Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400237',
    lea_name: 'Paramount Education Studies Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400238',
    lea_name: 'Metropolitan Arts Institute  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400239',
    lea_name: 'P.L.C. Charter Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400241',
    lea_name: 'Paragon Management  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400243',
    lea_name: 'Skyline Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400244',
    lea_name: 'Edkey  Inc. - Sequoia Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400246',
    lea_name: 'Edkey  Inc. - Sequoia Village School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400248',
    lea_name: 'Telesis Center for Learning  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400254',
    lea_name: 'Southern Arizona Community Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400255',
    lea_name: 'Tucson Country Day School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400256',
    lea_name: 'MultiDimensional Literacy Corp.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400257',
    lea_name: "Children's Success Academy  Inc.",
  },
  {
    state_abbr: 'AZ',
    leaid: '400258',
    lea_name: 'Carden of Tucson  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400259',
    lea_name: 'Southgate Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400261',
    lea_name: 'Eastpointe High School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400263',
    lea_name: 'Patagonia Montessori Elementary School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400264',
    lea_name: 'Santa Cruz Valley Opportunities in Education  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400266',
    lea_name: 'PACE Preparatory Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400267',
    lea_name: 'Kestrel Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400268',
    lea_name: 'Juniper Tree Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400273',
    lea_name: 'GAR  LLC dba Student Choice High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400274',
    lea_name: 'AIBT Non-Profit Charter High School - Phoenix',
  },
  {
    state_abbr: 'AZ',
    leaid: '400275',
    lea_name: 'Highland Free School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400276',
    lea_name: 'Academy with Community Partners  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400278',
    lea_name: 'Deer Valley Charter Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400279',
    lea_name: 'West Gilbert Charter Elementary School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400280',
    lea_name: 'Mohave Accelerated Learning Center',
  },
  {
    state_abbr: 'AZ',
    leaid: '400281',
    lea_name: 'Masada Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400282',
    lea_name: 'Harvest Power Community Development Group  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400284',
    lea_name: 'Omega Alpha Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400285',
    lea_name: 'Montessori House  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400288',
    lea_name: 'Premier Charter High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400290',
    lea_name: 'Pan-American Elementary Charter',
  },
  {
    state_abbr: 'AZ',
    leaid: '400296',
    lea_name: 'El Centro for the Study of Primary and Secondary Education',
  },
  {
    state_abbr: 'AZ',
    leaid: '400298',
    lea_name: 'Legacy Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400299',
    lea_name: 'Visions Unlimited Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400303',
    lea_name: 'North Star Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400310',
    lea_name: 'Calibre Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400311',
    lea_name: 'E-Institute Charter Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400312',
    lea_name: 'Ira H. Hayes Memorial Applied Learning Center  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400313',
    lea_name: 'New Visions Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400314',
    lea_name: 'Developing Innovations in Navajo Education  Inc. (DINE  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400315',
    lea_name: 'Yavapai Accommodation School District',
  },
  {
    state_abbr: 'AZ',
    leaid: '400316',
    lea_name: 'Cobre Valley Institute of Technology District',
  },
  {
    state_abbr: 'AZ',
    leaid: '400317',
    lea_name: 'Khalsa Family Services',
  },
  {
    state_abbr: 'AZ',
    leaid: '400318',
    lea_name: 'Aprender Tucson',
  },
  {
    state_abbr: 'AZ',
    leaid: '400320',
    lea_name: 'Acorn Montessori Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400321',
    lea_name: 'Pinnacle Education-WMCB  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400322',
    lea_name: 'Desert Rose Academy Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400323',
    lea_name: 'Crown Charter School  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400326',
    lea_name: 'Success School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400327',
    lea_name: 'Pointe Educational Services',
  },
  {
    state_abbr: 'AZ',
    leaid: '400328',
    lea_name: 'A Center for Creative Education',
  },
  {
    state_abbr: 'AZ',
    leaid: '400329',
    lea_name: 'Primavera Technical Learning Center',
  },
  {
    state_abbr: 'AZ',
    leaid: '400330',
    lea_name: 'Compass High School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400332',
    lea_name: 'James Sandoval Preparatory High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400334',
    lea_name: 'Westwind Middle School Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400338',
    lea_name: 'Accelerated Learning Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400339',
    lea_name: 'Destiny School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400340',
    lea_name: 'Noah Webster Schools - Mesa',
  },
  {
    state_abbr: 'AZ',
    leaid: '400343',
    lea_name: 'Griffin Foundation  Inc. The',
  },
  {
    state_abbr: 'AZ',
    leaid: '400344',
    lea_name: 'Pathways KM Charter Schools  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400345',
    lea_name: 'Cochise Technology District',
  },
  {
    state_abbr: 'AZ',
    leaid: '400347',
    lea_name: 'Coconino Association for Vocation Industry and Technology',
  },
  {
    state_abbr: 'AZ',
    leaid: '400348',
    lea_name: 'Central Arizona Valley Institute of Technology',
  },
  {
    state_abbr: 'AZ',
    leaid: '400349',
    lea_name: 'Gila Institute for Technology',
  },
  {
    state_abbr: 'AZ',
    leaid: '400350',
    lea_name: 'PEAK School Inc.  The',
  },
  {
    state_abbr: 'AZ',
    leaid: '400351',
    lea_name: 'Kaizen Education Foundation dba Vista Grove Preparatory Acad',
  },
  {
    state_abbr: 'AZ',
    leaid: '400352',
    lea_name: 'American Charter Schools Foundation d.b.a. Estrella High Sch',
  },
  {
    state_abbr: 'AZ',
    leaid: '400353',
    lea_name: 'American Charter Schools Foundation d.b.a. Peoria Accelerate',
  },
  {
    state_abbr: 'AZ',
    leaid: '400354',
    lea_name: 'American Charter Schools Foundation d.b.a. Sun Valley High S',
  },
  {
    state_abbr: 'AZ',
    leaid: '400355',
    lea_name: 'American Charter Schools Foundation d.b.a. West Phoenix High',
  },
  {
    state_abbr: 'AZ',
    leaid: '400356',
    lea_name: 'Nosotros  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400357',
    lea_name: 'American Charter Schools Foundation d.b.a. Apache Trail High',
  },
  {
    state_abbr: 'AZ',
    leaid: '400359',
    lea_name: 'Challenger Basic School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400361',
    lea_name: 'Arizona Community Development Corporation',
  },
  {
    state_abbr: 'AZ',
    leaid: '400363',
    lea_name: 'SC Jensen Corporation  Inc. dba Intelli-School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400364',
    lea_name: 'PAS Charter  Inc.  dba Intelli-School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400366',
    lea_name: 'Valley of the Sun Waldorf Education Association  dba Desert',
  },
  {
    state_abbr: 'AZ',
    leaid: '400367',
    lea_name: 'Pima Prevention Partnership dba Pima Partnership School  The',
  },
  {
    state_abbr: 'AZ',
    leaid: '400368',
    lea_name: 'Academy of Mathematics and Science  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400370',
    lea_name: 'Midtown Primary School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400373',
    lea_name: 'Tucson International Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400374',
    lea_name: 'Bell Canyon Charter School  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400376',
    lea_name: 'Cortez Park Charter Middle School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400377',
    lea_name: 'West Gilbert Charter Middle School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400381',
    lea_name: 'Carpe Diem Collegiate High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400382',
    lea_name: 'CPLC Community Schools dba Toltecalli High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400383',
    lea_name: 'Life Skills Center of Arizona  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400384',
    lea_name: 'American Charter Schools Foundation d.b.a. Desert Hills High',
  },
  {
    state_abbr: 'AZ',
    leaid: '400385',
    lea_name: 'American Charter Schools Foundation d.b.a. Crestview College',
  },
  {
    state_abbr: 'AZ',
    leaid: '400386',
    lea_name: 'Kaizen Education Foundation dba Summit High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400387',
    lea_name: 'Kaizen Education Foundation dba Tempe Accelerated High Schoo',
  },
  {
    state_abbr: 'AZ',
    leaid: '400388',
    lea_name: 'Kaizen Education Foundation dba Skyview High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400389',
    lea_name: 'Kaizen Education Foundation dba Maya High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400390',
    lea_name: 'Camelback Education  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400391',
    lea_name: 'Vista Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400393',
    lea_name: 'Lifelong Learning Research Institute  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400394',
    lea_name: 'Avondale Learning dba Precision Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400395',
    lea_name: 'LEAD Charter Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400396',
    lea_name: 'All Aboard Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400397',
    lea_name: 'CAFA  Inc. dba Learning Foundation Performing Arts School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400398',
    lea_name: 'Founding Fathers Academies  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400399',
    lea_name: 'Montessori Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400401',
    lea_name: 'New World Educational Center',
  },
  {
    state_abbr: 'AZ',
    leaid: '400402',
    lea_name: 'Cholla Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400403',
    lea_name: 'Akimel O Otham Pee Posh Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400404',
    lea_name: "Akimel O'Otham Pee Posh Charter School  Inc.",
  },
  {
    state_abbr: 'AZ',
    leaid: '400405',
    lea_name: 'Cornerstone Charter School Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400406',
    lea_name: 'Happy Valley School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400407',
    lea_name: 'Tucson Small School Project',
  },
  {
    state_abbr: 'AZ',
    leaid: '400409',
    lea_name: 'Satori  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400413',
    lea_name: 'Mohave Accelerated Elementary School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400414',
    lea_name: 'Academy of Building Industries  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400415',
    lea_name: 'Arizona Montessori Charter School at Anthem',
  },
  {
    state_abbr: 'AZ',
    leaid: '400416',
    lea_name: 'StarShine Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400417',
    lea_name: 'Arizona Agribusiness & Equine Center  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400418',
    lea_name: 'Ahwatukee Foothills Prep  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400419',
    lea_name: 'Gila County Regional School District',
  },
  {
    state_abbr: 'AZ',
    leaid: '400420',
    lea_name: 'Pillar Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400424',
    lea_name: 'Rosefield Charter Elementary School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400425',
    lea_name: 'East Mesa Charter Elementary School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400426',
    lea_name: 'Arizona Agribusiness & Equine Center  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400427',
    lea_name: 'Arizona Connections Academy Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400429',
    lea_name: 'Country Gardens Charter Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400432',
    lea_name: 'Portable Practical Educational Preparation  Inc. (PPEP  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400433',
    lea_name: 'El Pueblo Integral - Teaching & Learning Collaborative',
  },
  {
    state_abbr: 'AZ',
    leaid: '400438',
    lea_name: 'Imagine Charter Elementary at Desert West  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400439',
    lea_name: 'Desert Star Community School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400440',
    lea_name: 'Chandler Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400441',
    lea_name: 'Imagine Middle at Surprise  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400442',
    lea_name: 'Imagine Middle at East Mesa  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400444',
    lea_name: 'Prescott Valley Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400445',
    lea_name: 'Imagine Charter Elementary at Camelback  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400446',
    lea_name: 'Imagine Elementary at Tempe  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400447',
    lea_name: 'American Charter Schools Foundation d.b.a. South Ridge High',
  },
  {
    state_abbr: 'AZ',
    leaid: '400448',
    lea_name: 'Desert Sky Community School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400462',
    lea_name: 'Ray of Light Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400463',
    lea_name: 'Arizona State School for the Deaf and Blind',
  },
  {
    state_abbr: 'AZ',
    leaid: '400608',
    lea_name: 'BASIS School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400609',
    lea_name: 'Bradley Academy of Excellence  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400610',
    lea_name: 'Desert Heights Charter Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400612',
    lea_name: 'Northeast Arizona Technological Institute of Vocational Educ',
  },
  {
    state_abbr: 'AZ',
    leaid: '400613',
    lea_name: 'Educational Impact  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400614',
    lea_name: 'MCCCD on behalf of Phoenix College Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400615',
    lea_name: 'West-MEC - Western Maricopa Education Center',
  },
  {
    state_abbr: 'AZ',
    leaid: '400616',
    lea_name: 'New School for the Arts Middle School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400618',
    lea_name: 'American Charter Schools Foundation d.b.a. South Pointe High',
  },
  {
    state_abbr: 'AZ',
    leaid: '400619',
    lea_name: 'American Charter Schools Foundation d.b.a. Alta Vista High S',
  },
  {
    state_abbr: 'AZ',
    leaid: '400621',
    lea_name: 'Pinnacle Education-Tempe  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400625',
    lea_name: 'Pinnacle Education-Kino  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400626',
    lea_name: 'Pinnacle Education-Casa Grande  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400631',
    lea_name: 'Patriot Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400632',
    lea_name: 'Cochise Community Development Corporation',
  },
  {
    state_abbr: 'AZ',
    leaid: '400633',
    lea_name: 'Canyon Rose Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400636',
    lea_name: 'Blueprint Education',
  },
  {
    state_abbr: 'AZ',
    leaid: '400637',
    lea_name: 'Edkey  Inc. - Redwood Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400638',
    lea_name: 'Edkey  Inc. - Pathfinder Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400640',
    lea_name: 'Edkey  Inc. - Sequoia School for the Deaf and Hard of Hearin',
  },
  {
    state_abbr: 'AZ',
    leaid: '400641',
    lea_name: 'Edkey  Inc. - Sequoia Ranch School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400649',
    lea_name: 'Heritage Elementary School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400650',
    lea_name: 'Integrity Education Incorporated',
  },
  {
    state_abbr: 'AZ',
    leaid: '400653',
    lea_name: 'Edkey  Inc. - Arizona Conservatory for Arts and Academics',
  },
  {
    state_abbr: 'AZ',
    leaid: '400655',
    lea_name: 'Veritas Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400656',
    lea_name: 'RSD Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400745',
    lea_name: 'Legacy Traditional Charter School - Maricopa',
  },
  {
    state_abbr: 'AZ',
    leaid: '400746',
    lea_name: 'Eduprize Schools  LLC',
  },
  {
    state_abbr: 'AZ',
    leaid: '400747',
    lea_name: 'Scottsdale Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400748',
    lea_name: 'Sage Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400751',
    lea_name: 'Concordia Charter School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400752',
    lea_name: 'Pima County JTED',
  },
  {
    state_abbr: 'AZ',
    leaid: '400753',
    lea_name: 'Ambassador Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400754',
    lea_name: 'Imagine Desert West Middle  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400755',
    lea_name: 'Freedom Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400756',
    lea_name: 'Imagine Camelback Middle  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400757',
    lea_name: 'Arete Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400758',
    lea_name: 'Candeo Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400760',
    lea_name: 'Glendale Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400761',
    lea_name: 'Tempe Preparatory Junior Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400763',
    lea_name: 'EAGLE South Mountain Charter  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400764',
    lea_name: 'ASU Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400766',
    lea_name: 'Imagine Coolidge Elementary  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400768',
    lea_name: 'Pima Prevention Partnership dba Pima Partnership Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400769',
    lea_name: 'Imagine Prep Coolidge  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400770',
    lea_name: 'Imagine Prep Surprise  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400771',
    lea_name: 'Blue Adobe Project',
  },
  {
    state_abbr: 'AZ',
    leaid: '400772',
    lea_name: 'Mountain Institute JTED',
  },
  {
    state_abbr: 'AZ',
    leaid: '400774',
    lea_name: 'Sonoran Science Academy - Broadway',
  },
  {
    state_abbr: 'AZ',
    leaid: '400775',
    lea_name: 'San Tan Montessori School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400776',
    lea_name: 'Daisy Education Corporation dba Sonoran Science Academy - Ph',
  },
  {
    state_abbr: 'AZ',
    leaid: '400777',
    lea_name: 'Santa Cruz County Sheriffs Office',
  },
  {
    state_abbr: 'AZ',
    leaid: '400778',
    lea_name: 'Daisy Education Corporation dba Paragon Science Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400780',
    lea_name: 'Imagine Avondale Elementary  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400781',
    lea_name: 'Math and Science Success Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400782',
    lea_name: "Haven Montessori Children's House  Inc.",
  },
  {
    state_abbr: 'AZ',
    leaid: '400783',
    lea_name: 'Imagine Prep Superstition  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400785',
    lea_name: 'AZ Compass Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400786',
    lea_name: 'West Valley Arts and Technology Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400787',
    lea_name: 'Kaizen Education Foundation dba Vista Grove Preparatory Acad',
  },
  {
    state_abbr: 'AZ',
    leaid: '400788',
    lea_name: 'Phoenix Collegiate Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400789',
    lea_name: 'Kaizen Education Foundation dba Liberty Arts Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400791',
    lea_name: 'Teleos Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400792',
    lea_name: 'Vector School District  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400793',
    lea_name: 'Morrison Education Group  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400794',
    lea_name: 'Western Arizona Vocational District #50',
  },
  {
    state_abbr: 'AZ',
    leaid: '400796',
    lea_name: 'CAFA  Inc. dba Learning Foundation and Performing Arts Alta',
  },
  {
    state_abbr: 'AZ',
    leaid: '400797',
    lea_name: 'Academy Del Sol  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400798',
    lea_name: 'Challenge Foundation Academies of Arizona  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400799',
    lea_name: 'Kaizen Education Foundation dba Gilbert Arts Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400800',
    lea_name: 'The Odyssey Preparatory Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400801',
    lea_name: 'Skyline Gila River Schools  LLC',
  },
  {
    state_abbr: 'AZ',
    leaid: '400802',
    lea_name: 'Imagine Avondale Middle  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400803',
    lea_name: 'CAFA  Inc. dba Learning Foundation and Performing Arts Gilbe',
  },
  {
    state_abbr: 'AZ',
    leaid: '400805',
    lea_name: 'Choice Academies  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400806',
    lea_name: 'Daisy Education Corporation dba Sonoran Science Academy - Ah',
  },
  {
    state_abbr: 'AZ',
    leaid: '400807',
    lea_name: 'Daisy Education Corporation dba. Sonoran Science Academy Dav',
  },
  {
    state_abbr: 'AZ',
    leaid: '400810',
    lea_name: 'Imagine Superstition Middle  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400811',
    lea_name: 'Ombudsman Educational Services  LTD  a subsidiary of Educati',
  },
  {
    state_abbr: 'AZ',
    leaid: '400813',
    lea_name: 'Camp Verde Unified School District dba South Verde Technolog',
  },
  {
    state_abbr: 'AZ',
    leaid: '400814',
    lea_name: 'CPLC Community Schools dba Hiaki High School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400815',
    lea_name: 'Research Based Education Corporation',
  },
  {
    state_abbr: 'AZ',
    leaid: '400816',
    lea_name: 'Educational Options Foundation',
  },
  {
    state_abbr: 'AZ',
    leaid: '400817',
    lea_name: 'Ed Ahead',
  },
  {
    state_abbr: 'AZ',
    leaid: '400818',
    lea_name: 'BASIS School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400819',
    lea_name: 'Anthem Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400820',
    lea_name: 'Compass Points International  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400821',
    lea_name: 'Pima Prevention Partnership',
  },
  {
    state_abbr: 'AZ',
    leaid: '400822',
    lea_name: 'Daisy Education Corporation dba. Sonoran Science Academy Peo',
  },
  {
    state_abbr: 'AZ',
    leaid: '400823',
    lea_name: 'Kaizen Education Foundation dba Mission Heights Preparatory',
  },
  {
    state_abbr: 'AZ',
    leaid: '400824',
    lea_name: 'Leading Edge Academy Maricopa',
  },
  {
    state_abbr: 'AZ',
    leaid: '400826',
    lea_name: 'Lifelong Learning Research Institute  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400827',
    lea_name: 'Ahwatukee Foothills Prep Early College High School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400828',
    lea_name: 'Arizona Agribusiness & Equine Center  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400829',
    lea_name: 'BASIS School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400830',
    lea_name: 'BASIS School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400831',
    lea_name: 'BASIS School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400832',
    lea_name: 'Archway Classical Academy Trivium',
  },
  {
    state_abbr: 'AZ',
    leaid: '400833',
    lea_name: 'Archway Classical Academy Veritas',
  },
  {
    state_abbr: 'AZ',
    leaid: '400834',
    lea_name: 'Archway Classical Academy Chandler',
  },
  {
    state_abbr: 'AZ',
    leaid: '400838',
    lea_name: 'Arizona Agribusiness & Equine Center  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400839',
    lea_name: 'Pima Rose Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400841',
    lea_name: 'Archway Classical Academy Scottsdale',
  },
  {
    state_abbr: 'AZ',
    leaid: '400842',
    lea_name: 'Legacy Traditional School - Northwest Tucson',
  },
  {
    state_abbr: 'AZ',
    leaid: '400843',
    lea_name: 'BASIS School  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400844',
    lea_name: 'La Tierra Community School  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400845',
    lea_name: 'LEAD Charter Schools dba Leading Edge Academy Queen Creek',
  },
  {
    state_abbr: 'AZ',
    leaid: '400846',
    lea_name: 'South Valley Academy  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400847',
    lea_name: 'Legacy Traditional School - Avondale',
  },
  {
    state_abbr: 'AZ',
    leaid: '400848',
    lea_name: 'PLC Arts Academy at Scottsdale  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400849',
    lea_name: 'EAGLE College Prep II  Inc. dba EAGLE College Prep Harmony',
  },
  {
    state_abbr: 'AZ',
    leaid: '400850',
    lea_name: "Reid Traditional Schools' Painted Rock Academy Inc.",
  },
  {
    state_abbr: 'AZ',
    leaid: '400851',
    lea_name: 'Trivium Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400852',
    lea_name: 'Kaizen Education Foundation dba Havasu Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400853',
    lea_name: 'South Phoenix Academy Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400854',
    lea_name: 'Graysmark Schools Corporation',
  },
  {
    state_abbr: 'AZ',
    leaid: '400855',
    lea_name: 'Athlos Traditional Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400857',
    lea_name: 'ASU Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400858',
    lea_name: 'ASU Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400859',
    lea_name: 'ASU Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400861',
    lea_name: 'North Phoenix Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400862',
    lea_name: 'Collaborative Pathways  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400863',
    lea_name: 'The Paideia Academies  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400864',
    lea_name: 'Happy Valley School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400866',
    lea_name: 'Archway Classical Academy Glendale',
  },
  {
    state_abbr: 'AZ',
    leaid: '400867',
    lea_name: 'Ball Charter Schools (Val Vista)',
  },
  {
    state_abbr: 'AZ',
    leaid: '400868',
    lea_name: 'Open Doors Community School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400869',
    lea_name: 'International Charter School of Arizona',
  },
  {
    state_abbr: 'AZ',
    leaid: '400871',
    lea_name: 'Legacy Traditional Charter School - Laveen Village',
  },
  {
    state_abbr: 'AZ',
    leaid: '400872',
    lea_name: 'Institute for Transformative Education  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400873',
    lea_name: 'Archway Classical Academy North Phoenix',
  },
  {
    state_abbr: 'AZ',
    leaid: '400874',
    lea_name: 'Tucson Collegiate Prep  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400875',
    lea_name: 'Maryvale Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400876',
    lea_name: 'George Gervin Youth Center  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400877',
    lea_name: 'Empower College Prep',
  },
  {
    state_abbr: 'AZ',
    leaid: '400878',
    lea_name: 'BASIS School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400879',
    lea_name: 'Scottsdale Country Day School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400880',
    lea_name: 'Southwest Leadership Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400881',
    lea_name: 'BASIS School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400882',
    lea_name: 'Kaizen Education Foundation dba Advance U',
  },
  {
    state_abbr: 'AZ',
    leaid: '400883',
    lea_name: 'Camino Montessori',
  },
  {
    state_abbr: 'AZ',
    leaid: '400884',
    lea_name: 'ASU Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400885',
    lea_name: 'Incito Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400886',
    lea_name: 'Archway Classical Academy Cicero',
  },
  {
    state_abbr: 'AZ',
    leaid: '400887',
    lea_name: 'Vista College Preparatory  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400888',
    lea_name: 'Archway Classical Academy - Arete',
  },
  {
    state_abbr: 'AZ',
    leaid: '400889',
    lea_name: 'Rising Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400890',
    lea_name: 'ASU Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400891',
    lea_name: 'Academy of Mathematics and Science South  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400892',
    lea_name: 'Legacy Traditional Charter Schools - Casa Grande',
  },
  {
    state_abbr: 'AZ',
    leaid: '400893',
    lea_name: 'Hirsch Academy A Challenge Foundation',
  },
  {
    state_abbr: 'AZ',
    leaid: '400894',
    lea_name: 'Kaizen Education Foundation dba Discover U Elementary School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400895',
    lea_name: 'Espiritu Community Development Corp.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400897',
    lea_name: 'BASIS School  Inc',
  },
  {
    state_abbr: 'AZ',
    leaid: '400898',
    lea_name: 'BASIS School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400899',
    lea_name: 'Legacy Traditional School - Gilbert',
  },
  {
    state_abbr: 'AZ',
    leaid: '400900',
    lea_name: 'Cicero Preparatory Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400902',
    lea_name: 'The Farm at Mission Montessori Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400903',
    lea_name: 'BASIS School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400904',
    lea_name: 'Edkey  Inc. - Sequoia Pathway Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400905',
    lea_name: 'BASIS School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400907',
    lea_name: 'StrengthBuilding Partners',
  },
  {
    state_abbr: 'AZ',
    leaid: '400908',
    lea_name: 'PS Charter Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400909',
    lea_name: 'Madison Highland Prep',
  },
  {
    state_abbr: 'AZ',
    leaid: '400911',
    lea_name: 'Heritage Academy Queen Creek  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400912',
    lea_name: 'Eagle South Mountain Charter  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400913',
    lea_name: 'Arizona Autism Charter Schools  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400914',
    lea_name: 'Franklin Phonetic Primary School  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400915',
    lea_name: 'CASA Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400916',
    lea_name: 'EAGLE South Mountain Charter  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400917',
    lea_name: 'Innovative Humanities Education Corporation',
  },
  {
    state_abbr: 'AZ',
    leaid: '400918',
    lea_name: 'Arizona Language Preparatory',
  },
  {
    state_abbr: 'AZ',
    leaid: '400921',
    lea_name: 'Horizon Community Learning Center  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400922',
    lea_name: 'Desert Star Academy',
  },
  {
    state_abbr: 'AZ',
    leaid: '400923',
    lea_name: 'SySTEM Schools',
  },
  {
    state_abbr: 'AZ',
    leaid: '400924',
    lea_name: 'Western School of Science and Technology  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400926',
    lea_name: 'Heritage Academy Laveen  Inc.',
  },
  {
    state_abbr: 'AZ',
    leaid: '400927',
    lea_name: 'Legacy Traditional Charter School',
  },
  {
    state_abbr: 'AZ',
    leaid: '400928',
    lea_name: 'El Pueblo Integral - Teaching & Learning Collaborative',
  },
  {
    state_abbr: 'AZ',
    leaid: '400929',
    lea_name: 'Noah Webster Schools-Pima',
  },
  {
    state_abbr: 'AZ',
    leaid: '402740',
    lea_name: "Mary C O'Brien Accommodation District",
  },
  {
    state_abbr: 'AZ',
    leaid: '403860',
    lea_name: 'Maricopa County Regional District',
  },
  {
    state_abbr: 'AZ',
    leaid: '405360',
    lea_name: 'Pima Accommodation District',
  },
  {
    state_abbr: 'AZ',
    leaid: '405500',
    lea_name: 'Navajo County Accommodation District #99',
  },
  {
    state_abbr: 'AZ',
    leaid: '409731',
    lea_name: 'East Valley Institute of Technology',
  },
  {
    state_abbr: 'AZ',
    leaid: '409734',
    lea_name: 'AZ Dept of Juvenile Corrections',
  },
  {
    state_abbr: 'CA',
    leaid: '600002',
    lea_name: 'California School for the Blind (State Special Schl)',
  },
  {
    state_abbr: 'CA',
    leaid: '600003',
    lea_name: 'California School for the Deaf-Fremont (State Special Schl)',
  },
  {
    state_abbr: 'CA',
    leaid: '600007',
    lea_name: 'California Sch for the Deaf-Riverside (State Special Schl)',
  },
  {
    state_abbr: 'CA',
    leaid: '600071',
    lea_name: 'SBE - Ridgecrest Charter',
  },
  {
    state_abbr: 'CA',
    leaid: '600134',
    lea_name: 'SBE - New West Charter',
  },
  {
    state_abbr: 'CA',
    leaid: '600136',
    lea_name: 'SBE - The School of Arts and Enterprise',
  },
  {
    state_abbr: 'CA',
    leaid: '601322',
    lea_name: 'SBC - High Tech High',
  },
  {
    state_abbr: 'CA',
    leaid: '601323',
    lea_name: 'SBE - Lifeline Education Charter',
  },
  {
    state_abbr: 'CA',
    leaid: '601336',
    lea_name: 'SBE - Barack Obama Charter',
  },
  {
    state_abbr: 'CA',
    leaid: '601344',
    lea_name: 'SBE - Livermore Valley Charter Preparatory High',
  },
  {
    state_abbr: 'CA',
    leaid: '601345',
    lea_name: 'SBE - Ingenium Charter',
  },
  {
    state_abbr: 'CA',
    leaid: '601407',
    lea_name: "SBE - Today's Fresh Start Charter",
  },
  {
    state_abbr: 'CA',
    leaid: '601411',
    lea_name: 'SBE - San Francisco Flex Academy',
  },
  {
    state_abbr: 'CA',
    leaid: '601412',
    lea_name: 'SBE - Mission Preparatory',
  },
  {
    state_abbr: 'CA',
    leaid: '601419',
    lea_name: 'SBE - Synergy',
  },
  {
    state_abbr: 'CA',
    leaid: '601422',
    lea_name: 'California Education Authority (CEA) Headquarters',
  },
  {
    state_abbr: 'CA',
    leaid: '601425',
    lea_name: 'SBE - Magnolia Science Academy Santa Ana',
  },
  {
    state_abbr: 'CA',
    leaid: '601426',
    lea_name: 'Bonsall Unified',
  },
  {
    state_abbr: 'CA',
    leaid: '601427',
    lea_name: 'Penn Valley Union Elementary',
  },
  {
    state_abbr: 'CA',
    leaid: '601428',
    lea_name: 'Wiseburn Unified',
  },
  {
    state_abbr: 'CA',
    leaid: '601429',
    lea_name: 'SBE - Thrive Public',
  },
  {
    state_abbr: 'CA',
    leaid: '601430',
    lea_name: 'Anahuacalmecac Inter. Univ. Prep. High Sch of N. America',
  },
  {
    state_abbr: 'CA',
    leaid: '691003',
    lea_name: 'Calaveras County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691001',
    lea_name: 'Amador County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691002',
    lea_name: 'Butte County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691005',
    lea_name: 'Contra Costa County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691006',
    lea_name: 'El Dorado County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691007',
    lea_name: 'Fresno County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691008',
    lea_name: 'Glenn County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691009',
    lea_name: 'Humboldt County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691010',
    lea_name: 'Imperial County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691011',
    lea_name: 'Inyo County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691012',
    lea_name: 'Kern County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691013',
    lea_name: 'Kings County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691014',
    lea_name: 'Lake County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691015',
    lea_name: 'Lassen County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691017',
    lea_name: 'Madera County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691018',
    lea_name: 'Marin County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691019',
    lea_name: 'Mendocino County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691020',
    lea_name: 'Merced County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691021',
    lea_name: 'Monterey County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691022',
    lea_name: 'Napa County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691023',
    lea_name: 'Nevada County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691024',
    lea_name: 'Orange County Department of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691025',
    lea_name: 'Placer County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691026',
    lea_name: 'Riverside County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691027',
    lea_name: 'Sacramento County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691028',
    lea_name: 'San Benito County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691029',
    lea_name: 'San Bernardino County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691030',
    lea_name: 'San Diego County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691031',
    lea_name: 'San Joaquin County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691032',
    lea_name: 'San Luis Obispo County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691033',
    lea_name: 'San Mateo County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691034',
    lea_name: 'Santa Barbara County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691035',
    lea_name: 'Santa Clara County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691036',
    lea_name: 'Santa Cruz County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691037',
    lea_name: 'Shasta County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691038',
    lea_name: 'Siskiyou County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691040',
    lea_name: 'Sonoma County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691041',
    lea_name: 'Stanislaus County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691042',
    lea_name: 'Sutter County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691043',
    lea_name: 'Tehama County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691044',
    lea_name: 'Trinity County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691045',
    lea_name: 'Tulare County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691046',
    lea_name: 'Tuolumne County Superintendent of Schools',
  },
  {
    state_abbr: 'CA',
    leaid: '691047',
    lea_name: 'Ventura County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691048',
    lea_name: 'Yuba County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691049',
    lea_name: 'Yolo County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691051',
    lea_name: 'Alameda County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691052',
    lea_name: 'Mono County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691058',
    lea_name: 'Alpine County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691063',
    lea_name: 'Del Norte County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691078',
    lea_name: 'Los Angeles County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691090',
    lea_name: 'Mariposa County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691093',
    lea_name: 'Modoc County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691102',
    lea_name: 'Plumas County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691111',
    lea_name: 'San Francisco County Office of Education',
  },
  {
    state_abbr: 'CA',
    leaid: '691122',
    lea_name: 'Solano County Office of Education',
  },
  {
    state_abbr: 'CT',
    leaid: '900002',
    lea_name: 'Connecticut Technical High Sc',
  },
  {
    state_abbr: 'CT',
    leaid: '900003',
    lea_name: 'UNIFIED SCHOOL DISTRICT #1',
  },
  {
    state_abbr: 'CT',
    leaid: '900004',
    lea_name: 'UNIFIED SCHOOL DISTRICT #2',
  },
  {
    state_abbr: 'CT',
    leaid: '900007',
    lea_name: 'JUMOKE ACADEMY DISTRICT',
  },
  {
    state_abbr: 'CT',
    leaid: '900009',
    lea_name: 'ODYSSEY COMMUNITY SCHOOL DIST',
  },
  {
    state_abbr: 'CT',
    leaid: '900010',
    lea_name: 'INTEGRATED DAY CHARTER SCHOOL',
  },
  {
    state_abbr: 'CT',
    leaid: '900011',
    lea_name: 'INTRDIST SCH FOR ARTS AND COM',
  },
  {
    state_abbr: 'CT',
    leaid: '900014',
    lea_name: 'COMMON GROUND HIGH SCHOOL DIS',
  },
  {
    state_abbr: 'CT',
    leaid: '900015',
    lea_name: 'THE BRIDGE ACADEMY DISTRICT',
  },
  {
    state_abbr: 'CT',
    leaid: '900016',
    lea_name: 'SIDE BY SIDE COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'CT',
    leaid: '900018',
    lea_name: 'EXPLORATIONS DISTRICT',
  },
  {
    state_abbr: 'CT',
    leaid: '900023',
    lea_name: 'TRAILBLAZERS ACADEMY DISTRICT',
  },
  {
    state_abbr: 'CT',
    leaid: '900024',
    lea_name: 'AMISTAD ACADEMY DISTRICT',
  },
  {
    state_abbr: 'CT',
    leaid: '900027',
    lea_name: 'DEPARTMENT OF MENTAL HEALTH A',
  },
  {
    state_abbr: 'CT',
    leaid: '900028',
    lea_name: 'NEW BEGINNINGS INC.',
  },
  {
    state_abbr: 'CT',
    leaid: '900031',
    lea_name: 'Stamford Academy',
  },
  {
    state_abbr: 'CT',
    leaid: '900033',
    lea_name: 'Park City Prep Charter School',
  },
  {
    state_abbr: 'CT',
    leaid: '900070',
    lea_name: 'AREA COOPERATIVE EDUCATIONAL',
  },
  {
    state_abbr: 'CT',
    leaid: '900207',
    lea_name: 'Highville Mustard Seed Charte',
  },
  {
    state_abbr: 'CT',
    leaid: '900208',
    lea_name: 'Bridgeport Achievement First',
  },
  {
    state_abbr: 'CT',
    leaid: '900212',
    lea_name: 'Achievement First Hartford Ac',
  },
  {
    state_abbr: 'CT',
    leaid: '900214',
    lea_name: 'Elm City College Preparatory',
  },
  {
    state_abbr: 'CT',
    leaid: '900215',
    lea_name: 'Brass City Charter School Dis',
  },
  {
    state_abbr: 'CT',
    leaid: '900216',
    lea_name: 'Elm City Montessori School Di',
  },
  {
    state_abbr: 'CT',
    leaid: '900217',
    lea_name: 'Booker T Washington Academy D',
  },
  {
    state_abbr: 'CT',
    leaid: '900218',
    lea_name: 'Path Academy District',
  },
  {
    state_abbr: 'CT',
    leaid: '900219',
    lea_name: 'Great Oaks Charter School Bri',
  },
  {
    state_abbr: 'CT',
    leaid: '900700',
    lea_name: 'CAPITOL REGION EDUCATION COUN',
  },
  {
    state_abbr: 'CT',
    leaid: '900910',
    lea_name: 'COOPERATIVE EDUCATIONAL SERVI',
  },
  {
    state_abbr: 'CT',
    leaid: '901360',
    lea_name: 'EASTERN CONNECTICUT REGIONAL',
  },
  {
    state_abbr: 'CT',
    leaid: '903450',
    lea_name: 'LEARN',
  },
  {
    state_abbr: 'CT',
    leaid: '903512',
    lea_name: 'EDUCATION CONNECTION',
  },
  {
    state_abbr: 'CT',
    leaid: '905371',
    lea_name: 'NORWICH FREE ACADEMY',
  },
  {
    state_abbr: 'CT',
    leaid: '905372',
    lea_name: 'THE GILBERT SCHOOL',
  },
  {
    state_abbr: 'CT',
    leaid: '905373',
    lea_name: 'WOODSTOCK ACADEMY',
  },
  {
    state_abbr: 'DC',
    leaid: '1100035',
    lea_name: 'Capital City PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100039',
    lea_name: 'Paul JHS PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100040',
    lea_name: 'Tree of Life PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100004',
    lea_name: 'Carlos Rosario International PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100005',
    lea_name: 'Cesar Chavez Public PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100007',
    lea_name: 'Community Academy PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100008',
    lea_name: 'Friendship PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100009',
    lea_name: 'Friendship PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100010',
    lea_name: 'Washington Hospitality Foundation',
  },
  {
    state_abbr: 'DC',
    leaid: '1100011',
    lea_name: 'Perry Street Preparatory PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100012',
    lea_name: 'Ideal Academy PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100013',
    lea_name: 'IDEA PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100014',
    lea_name: 'Maya Angelou PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100015',
    lea_name: 'Meridian PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100016',
    lea_name: 'Briya PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100017',
    lea_name: 'The Next Step PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100018',
    lea_name: 'Options PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100019',
    lea_name: 'Washington Latin PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100020',
    lea_name: 'Roots PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100022',
    lea_name: 'SEED PCS of Washington DC',
  },
  {
    state_abbr: 'DC',
    leaid: '1100026',
    lea_name: 'Washington Math Science PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100029',
    lea_name: 'Cedar Tree PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100031',
    lea_name: 'KIPP Academy PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100032',
    lea_name: 'Latin America Youth Bilingual Montessori LAMB PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100034',
    lea_name: 'Thurgood Marshall Academy PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100042',
    lea_name: 'DC Bilingual PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100043',
    lea_name: 'EL Haynes PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100044',
    lea_name: 'Mary McLeod Bethune  PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100045',
    lea_name: 'Two Rivers PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100048',
    lea_name: 'DC Preparatory Academy PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100049',
    lea_name: 'Eagle Academy PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100051',
    lea_name: 'Hope Community PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100053',
    lea_name: 'William E Doar Jr PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100054',
    lea_name: 'AppleTree Early Learning Center PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100055',
    lea_name: 'Bridges PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100057',
    lea_name: 'Early Childhood Academy PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100058',
    lea_name: 'Howard University Middle School of Math and Science',
  },
  {
    state_abbr: 'DC',
    leaid: '1100059',
    lea_name: 'Youth Build PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100060',
    lea_name: 'Potomac Preparatory PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100064',
    lea_name: 'St Coletta Special Education PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100072',
    lea_name: 'Achievement Prep Academy PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100073',
    lea_name: 'Center City PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100076',
    lea_name: 'Excel Academy PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100077',
    lea_name: 'Washington Yu Ying PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100079',
    lea_name: 'National Collegiate Prep',
  },
  {
    state_abbr: 'DC',
    leaid: '1100080',
    lea_name: 'Inspired Teaching Demonstration PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100081',
    lea_name: 'Shining Stars Montessori PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100082',
    lea_name: 'Richard Wright PCS for Journalism and Media Arts PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100083',
    lea_name: 'Basis DC PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100084',
    lea_name: 'Creative Minds International PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100085',
    lea_name: 'DC Scholars PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100086',
    lea_name: 'Mundo Verde Bilingual PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100087',
    lea_name: 'DYRS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100088',
    lea_name: 'Sela PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100089',
    lea_name: 'Somerset Preparatory PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100090',
    lea_name: 'Ingenuity Preparatory PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100091',
    lea_name: 'LAYC Career Academy',
  },
  {
    state_abbr: 'DC',
    leaid: '1100092',
    lea_name: 'Community College Prep Academy PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100093',
    lea_name: 'Lee Montessori PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100094',
    lea_name: 'Academy of Hope Adult PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100095',
    lea_name: 'Democracy Prep Congress Heights PCS',
  },
  {
    state_abbr: 'DC',
    leaid: '1100096',
    lea_name: 'Harmony DC Public Charter School',
  },
  {
    state_abbr: 'DC',
    leaid: '1100097',
    lea_name: 'District of Columbia International School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000004',
    lea_name: 'Charter School of Wilmington',
  },
  {
    state_abbr: 'DE',
    leaid: '1000005',
    lea_name: 'Positive Outcomes Charter School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000006',
    lea_name: 'East Side Charter School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000007',
    lea_name: 'Campus Community Charter School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000010',
    lea_name: 'Edison (Thomas A.) Charter School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000011',
    lea_name: 'Sussex Academy of Arts & Sciences',
  },
  {
    state_abbr: 'DE',
    leaid: '1000014',
    lea_name: 'Kuumba Academy Charter School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000015',
    lea_name: 'Newark Charter School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000016',
    lea_name: 'Delaware Military Academy',
  },
  {
    state_abbr: 'DE',
    leaid: '1000017',
    lea_name: 'Academy of Dover Charter School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000018',
    lea_name: 'Providence Creek Academy Charter School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000019',
    lea_name: 'MOT Charter School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000023',
    lea_name: 'Moyer (Maurice J.) Academy',
  },
  {
    state_abbr: 'DE',
    leaid: '1000024',
    lea_name: 'Family Foundations Academy',
  },
  {
    state_abbr: 'DE',
    leaid: '1000026',
    lea_name: 'Odyssey Charter School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000047',
    lea_name: 'Delaware College Preparatory Academy',
  },
  {
    state_abbr: 'DE',
    leaid: '1000048',
    lea_name: 'Prestige Academy',
  },
  {
    state_abbr: 'DE',
    leaid: '1000049',
    lea_name: 'Delaware Academy of Public Safety and Security',
  },
  {
    state_abbr: 'DE',
    leaid: '1000050',
    lea_name: 'Las Americas Aspira Academy',
  },
  {
    state_abbr: 'DE',
    leaid: '1000051',
    lea_name: 'Reach Academy for Girls',
  },
  {
    state_abbr: 'DE',
    leaid: '1000052',
    lea_name: 'Gateway Lab School',
  },
  {
    state_abbr: 'DE',
    leaid: '1000053',
    lea_name: 'Academia Antonia Alonso',
  },
  {
    state_abbr: 'DE',
    leaid: '1000054',
    lea_name: 'First State Montessori Academy',
  },
  {
    state_abbr: 'DE',
    leaid: '1000055',
    lea_name: 'Early College High School at Delaware State University',
  },
  {
    state_abbr: 'DE',
    leaid: '1000750',
    lea_name: 'POLYTECH School District',
  },
  {
    state_abbr: 'DE',
    leaid: '1001280',
    lea_name: 'New Castle County Vocational-Technical School District',
  },
  {
    state_abbr: 'DE',
    leaid: '1001680',
    lea_name: 'Sussex Technical School District',
  },
  {
    state_abbr: 'FL',
    leaid: '1200002',
    lea_name: 'FL VIRTUAL',
  },
  {
    state_abbr: 'FL',
    leaid: '1202011',
    lea_name: 'WASH SPECIAL',
  },
  {
    state_abbr: 'FL',
    leaid: '1202012',
    lea_name: 'FAU LAB SCH',
  },
  {
    state_abbr: 'FL',
    leaid: '1202013',
    lea_name: 'FSU LAB SCH',
  },
  {
    state_abbr: 'FL',
    leaid: '1202014',
    lea_name: 'FAMU LAB SCH',
  },
  {
    state_abbr: 'FL',
    leaid: '1202015',
    lea_name: 'UF LAB SCH',
  },
  {
    state_abbr: 'FL',
    leaid: '1202016',
    lea_name: 'DEAF/BLIND',
  },
  {
    state_abbr: 'GA',
    leaid: '1300005',
    lea_name: 'Commission Charter Schools- CCAT School',
  },
  {
    state_abbr: 'GA',
    leaid: '1300023',
    lea_name: 'State Charter Schools- Odyssey School',
  },
  {
    state_abbr: 'GA',
    leaid: '1300025',
    lea_name: 'Department of Corrections',
  },
  {
    state_abbr: 'GA',
    leaid: '1300026',
    lea_name: 'Department of Juvenile Justice',
  },
  {
    state_abbr: 'GA',
    leaid: '1300027',
    lea_name: 'Department of Human Resources',
  },
  {
    state_abbr: 'GA',
    leaid: '1300214',
    lea_name: 'State Charter Schools- Mountain Education Center School',
  },
  {
    state_abbr: 'GA',
    leaid: '1300215',
    lea_name: 'Commission Charter Schools- Ivy Preparatory Academy School',
  },
  {
    state_abbr: 'GA',
    leaid: '1300217',
    lea_name: 'Commission Charter Schools- Fulton Leadership Academy',
  },
  {
    state_abbr: 'GA',
    leaid: '1300218',
    lea_name: 'Commission Charter Schools- Pataula Charter Academy',
  },
  {
    state_abbr: 'GA',
    leaid: '1300221',
    lea_name: 'Commission Charter Schools- Atlanta Heights Charter School',
  },
  {
    state_abbr: 'GA',
    leaid: '1300222',
    lea_name: 'Commission Charter Schools- Coweta Charter Academy',
  },
  {
    state_abbr: 'GA',
    leaid: '1300223',
    lea_name: 'State Schools- Georgia School for the Deaf',
  },
  {
    state_abbr: 'GA',
    leaid: '1300224',
    lea_name: 'State Schools- Georgia Academy for the Blind',
  },
  {
    state_abbr: 'GA',
    leaid: '1300225',
    lea_name: 'State Schools- Atlanta Area School for the Deaf',
  },
  {
    state_abbr: 'GA',
    leaid: '1300226',
    lea_name: 'State Charter Schools- Ivy Prep Academy at Kirkwood for Gir',
  },
  {
    state_abbr: 'GA',
    leaid: '1300227',
    lea_name: 'State Charter Schools- Georgia Connections Academy',
  },
  {
    state_abbr: 'GA',
    leaid: '1300229',
    lea_name: "State Charter Schools- Ivy Preparatory Young Men's Leadersh",
  },
  {
    state_abbr: 'GA',
    leaid: '1300230',
    lea_name: 'State Charter Schools- Cherokee Charter Academy',
  },
  {
    state_abbr: 'GA',
    leaid: '1300231',
    lea_name: 'State Charter Schools- Provost Academy Georgia',
  },
  {
    state_abbr: 'GA',
    leaid: '1300232',
    lea_name: 'State Charter Schools- Georgia Cyber Academy',
  },
  {
    state_abbr: 'GA',
    leaid: '1300233',
    lea_name: 'State Charter Schools- Utopian Academy for the Arts Charter',
  },
  {
    state_abbr: 'ID',
    leaid: '1600152',
    lea_name: 'CANYON-OWYHEE SCHOOL SERVICE AGENCY (COSSA)',
  },
  {
    state_abbr: 'ID',
    leaid: '1600153',
    lea_name: 'BLACKFOOT CHARTER COMMUNITY LEARNING CENTER',
  },
  {
    state_abbr: 'ID',
    leaid: '1600154',
    lea_name: 'MONTICELLO MONTESSORI CHARTER SCHOOL',
  },
  {
    state_abbr: 'ID',
    leaid: '1600155',
    lea_name: 'ANOTHER CHOICE VIRTUAL CHARTER DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600156',
    lea_name: 'SAGE INTERNATIONAL SCHOOL OF BOISE',
  },
  {
    state_abbr: 'ID',
    leaid: '1600003',
    lea_name: 'VICTORY CHARTER SCHOOL',
  },
  {
    state_abbr: 'ID',
    leaid: '1600004',
    lea_name: 'IDAHO VIRTUAL ACADEMY',
  },
  {
    state_abbr: 'ID',
    leaid: '1600005',
    lea_name: 'RICHARD MCKENNA CHARTER HIGH SCHOOL',
  },
  {
    state_abbr: 'ID',
    leaid: '1600006',
    lea_name: 'ROLLING HILLS CHARTER SCHOOL',
  },
  {
    state_abbr: 'ID',
    leaid: '1600007',
    lea_name: 'COMPASS CHARTER SCHOOL',
  },
  {
    state_abbr: 'ID',
    leaid: '1600008',
    lea_name: 'FALCON RIDGE CHARTER SCHOOL',
  },
  {
    state_abbr: 'ID',
    leaid: '1600011',
    lea_name: 'INSPIRE VIRTUAL CHARTER',
  },
  {
    state_abbr: 'ID',
    leaid: '1600012',
    lea_name: 'LIBERTY CHARTER',
  },
  {
    state_abbr: 'ID',
    leaid: '1600014',
    lea_name: 'THE ACADEMY [ARC]',
  },
  {
    state_abbr: 'ID',
    leaid: '1600015',
    lea_name: 'TAYLORS CROSSING CHARTER SCHOOL',
  },
  {
    state_abbr: 'ID',
    leaid: '1600140',
    lea_name: 'XAVIER CHARTER SCHOOL',
  },
  {
    state_abbr: 'ID',
    leaid: '1600141',
    lea_name: 'VISION CHARTER SCHOOL',
  },
  {
    state_abbr: 'ID',
    leaid: '1600142',
    lea_name: 'WHITE PINE CHARTER SCHOOL',
  },
  {
    state_abbr: 'ID',
    leaid: '1600143',
    lea_name: 'NORTH VALLEY ACADEMY',
  },
  {
    state_abbr: 'ID',
    leaid: '1600144',
    lea_name: 'iSUCCEED VIRTUAL HIGH SCHOOL',
  },
  {
    state_abbr: 'ID',
    leaid: '1600145',
    lea_name: 'IDAHO SCIENCE & TECHNOLOGY CHARTER',
  },
  {
    state_abbr: 'ID',
    leaid: '1600148',
    lea_name: 'IDAHO CONNECTS ONLINE CHARTER DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600149',
    lea_name: 'KOOTENAI BRIDGE ACADEMY',
  },
  {
    state_abbr: 'ID',
    leaid: '1600151',
    lea_name: 'PALOUSE PRAIRIE CHARTER',
  },
  {
    state_abbr: 'ID',
    leaid: '1600157',
    lea_name: 'THE VILLAGE CHARTER SCHOOL DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600158',
    lea_name: 'LEGACY CHARTER SCHOOL DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600159',
    lea_name: 'HERITAGE ACADEMY DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600160',
    lea_name: 'NORTH IDAHO STEM CHARTER ACADEMY DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600161',
    lea_name: 'HERITAGE COMMUNITY CHARTER DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600162',
    lea_name: 'AMERICAN HERITAGE CHARTER DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600163',
    lea_name: 'CHIEF TAHGEE ELEMENTARY ACADEMY DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600165',
    lea_name: 'FORREST M. BIRD CHARTER DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600166',
    lea_name: 'SYRINGA MOUNTAIN CHARTER DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600169',
    lea_name: 'IDAHO STEM ACADEMY DBA BINGHAM ACADEMY CHARTER DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600170',
    lea_name: 'UPPER CARMEN PUBLIC CHARTER DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600171',
    lea_name: 'IDAHO COLLEGE & CAREER READINESS ACADEMY',
  },
  {
    state_abbr: 'ID',
    leaid: '1600172',
    lea_name: 'IDAHO DISTANCE EDUCATION ACADEMY DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1600173',
    lea_name: "COEUR D'ALENE CHARTER ACADEMY DISTRICT",
  },
  {
    state_abbr: 'ID',
    leaid: '1600174',
    lea_name: 'NORTH STAR CHARTER DISTRICT',
  },
  {
    state_abbr: 'ID',
    leaid: '1603511',
    lea_name: 'IDAHO SCHOOL FOR THE DEAF AND BLIND',
  },
  {
    state_abbr: 'IL',
    leaid: '1700006',
    lea_name: 'IDJJ Sch Dist 428',
  },
  {
    state_abbr: 'IL',
    leaid: '1700099',
    lea_name: 'ISU  Laboratory Schools',
  },
  {
    state_abbr: 'IL',
    leaid: '1700101',
    lea_name: 'Il Mathematics & Science Academy',
  },
  {
    state_abbr: 'IL',
    leaid: '1700218',
    lea_name: 'Prairie Crossing Charter School',
  },
  {
    state_abbr: 'IL',
    leaid: '1701400',
    lea_name: 'Southland College Prep',
  },
  {
    state_abbr: 'IL',
    leaid: '1701410',
    lea_name: 'Horizon Science Acad-McKinley Park',
  },
  {
    state_abbr: 'IL',
    leaid: '1701412',
    lea_name: 'Horizon Science Acad-Belmont',
  },
  {
    state_abbr: 'IL',
    leaid: '1701416',
    lea_name: 'Milford Area Public Schools District 124',
  },
  {
    state_abbr: 'IN',
    leaid: '1800018',
    lea_name: 'Christel House Academy South',
  },
  {
    state_abbr: 'IN',
    leaid: '1800021',
    lea_name: 'Signature School Inc',
  },
  {
    state_abbr: 'IN',
    leaid: '1800023',
    lea_name: 'IN Sch for the Blind & Vis Imprd',
  },
  {
    state_abbr: 'IN',
    leaid: '1800024',
    lea_name: 'Indiana School For The Deaf',
  },
  {
    state_abbr: 'IN',
    leaid: '1800025',
    lea_name: 'Thea Bowman Leadership Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800027',
    lea_name: 'Charter School of the Dunes',
  },
  {
    state_abbr: 'IN',
    leaid: '1800028',
    lea_name: 'KIPP Indpls College Preparatory',
  },
  {
    state_abbr: 'IN',
    leaid: '1800078',
    lea_name: 'Old National Trail Spec Serv Coop',
  },
  {
    state_abbr: 'IN',
    leaid: '1800006',
    lea_name: 'IN Department of Correction',
  },
  {
    state_abbr: 'IN',
    leaid: '1800011',
    lea_name: 'Community Montessori Inc',
  },
  {
    state_abbr: 'IN',
    leaid: '1800012',
    lea_name: 'New Community School',
  },
  {
    state_abbr: 'IN',
    leaid: '1800013',
    lea_name: 'Timothy L Johnson Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800014',
    lea_name: 'Irvington Community School',
  },
  {
    state_abbr: 'IN',
    leaid: '1800015',
    lea_name: 'Veritas Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800016',
    lea_name: 'Options Charter School - Carmel',
  },
  {
    state_abbr: 'IN',
    leaid: '1800017',
    lea_name: 'Fall Creek Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800031',
    lea_name: 'University Heights Preparatory Acd',
  },
  {
    state_abbr: 'IN',
    leaid: '1800033',
    lea_name: 'SE Neighborhood Sch of Excellence',
  },
  {
    state_abbr: 'IN',
    leaid: '1800037',
    lea_name: 'Joshua Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800038',
    lea_name: 'Burris Laboratory School',
  },
  {
    state_abbr: 'IN',
    leaid: '1800041',
    lea_name: 'IN Acad for Sci Math Humanities',
  },
  {
    state_abbr: 'IN',
    leaid: '1800042',
    lea_name: 'Andrew J Brown Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800043',
    lea_name: 'Rural Community Schools Inc',
  },
  {
    state_abbr: 'IN',
    leaid: '1800045',
    lea_name: 'Charles A Tindley Accelerated Schl',
  },
  {
    state_abbr: 'IN',
    leaid: '1800046',
    lea_name: '21st Century Charter Sch of Gary',
  },
  {
    state_abbr: 'IN',
    leaid: '1800048',
    lea_name: 'East Chicago Urban Enterprise Acad',
  },
  {
    state_abbr: 'IN',
    leaid: '1800051',
    lea_name: 'Indpls Lighthouse Charter School',
  },
  {
    state_abbr: 'IN',
    leaid: '1800052',
    lea_name: 'Gary Lighthouse Charter School',
  },
  {
    state_abbr: 'IN',
    leaid: '1800054',
    lea_name: 'East Chicago Lighthouse Charter',
  },
  {
    state_abbr: 'IN',
    leaid: '1800056',
    lea_name: 'Options Charter Sch - Noblesville',
  },
  {
    state_abbr: 'IN',
    leaid: '1800057',
    lea_name: 'Challenge Foundation Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800058',
    lea_name: 'Herron Charter',
  },
  {
    state_abbr: 'IN',
    leaid: '1800059',
    lea_name: 'Hope Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800061',
    lea_name: 'Geist Montessori Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800064',
    lea_name: 'Indianapolis Metropolitan High Sch',
  },
  {
    state_abbr: 'IN',
    leaid: '1800065',
    lea_name: 'Renaissance Academy Charter School',
  },
  {
    state_abbr: 'IN',
    leaid: '1800067',
    lea_name: 'Indiana Math and Science Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800071',
    lea_name: 'Aspire Charter Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800072',
    lea_name: 'Hoosier Academy - Indianapolis',
  },
  {
    state_abbr: 'IN',
    leaid: '1800074',
    lea_name: 'Dr Robert H Faulkner Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800075',
    lea_name: 'Beacon Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800077',
    lea_name: 'Anderson Preparatory Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800081',
    lea_name: 'Hoosier Acad Virtual Charter',
  },
  {
    state_abbr: 'IN',
    leaid: '1800082',
    lea_name: 'Hammond Academy of Science & Tech',
  },
  {
    state_abbr: 'IN',
    leaid: '1800083',
    lea_name: 'The Bloomington Project School',
  },
  {
    state_abbr: 'IN',
    leaid: '1800084',
    lea_name: 'Imagine Life Sciences Acad - West',
  },
  {
    state_abbr: 'IN',
    leaid: '1800086',
    lea_name: 'Paramount School Of Excellence Inc',
  },
  {
    state_abbr: 'IN',
    leaid: '1800087',
    lea_name: 'Xavier School of Excellence',
  },
  {
    state_abbr: 'IN',
    leaid: '1800088',
    lea_name: 'Discovery Charter School',
  },
  {
    state_abbr: 'IN',
    leaid: '1800092',
    lea_name: 'Andrew Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800093',
    lea_name: 'Excel Center for Adult Learners',
  },
  {
    state_abbr: 'IN',
    leaid: '1800094',
    lea_name: 'Indiana Math Science Academy North',
  },
  {
    state_abbr: 'IN',
    leaid: '1800096',
    lea_name: 'Padua Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800098',
    lea_name: 'Indiana Connections Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800099',
    lea_name: 'Rock Creek Community Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800101',
    lea_name: 'Career Academy at South Bend',
  },
  {
    state_abbr: 'IN',
    leaid: '1800102',
    lea_name: 'Gary Middle College',
  },
  {
    state_abbr: 'IN',
    leaid: '1800105',
    lea_name: 'Southside Sp Srvs Of Marion Co',
  },
  {
    state_abbr: 'IN',
    leaid: '1800106',
    lea_name: 'Indiana Virtual School',
  },
  {
    state_abbr: 'IN',
    leaid: '1800108',
    lea_name: 'Damar Charter Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800112',
    lea_name: 'CSUSA Manual',
  },
  {
    state_abbr: 'IN',
    leaid: '1800113',
    lea_name: "Neighbors' New Vistas High School",
  },
  {
    state_abbr: 'IN',
    leaid: '1800114',
    lea_name: 'EdPower Arlington',
  },
  {
    state_abbr: 'IN',
    leaid: '1800115',
    lea_name: 'Indiana Cyber Charter Sch Inc',
  },
  {
    state_abbr: 'IN',
    leaid: '1800116',
    lea_name: 'The Excel Center - Anderson',
  },
  {
    state_abbr: 'IN',
    leaid: '1800119',
    lea_name: 'Edison Learning Roosevelt',
  },
  {
    state_abbr: 'IN',
    leaid: '1800121',
    lea_name: 'Thurgood Marshall Leadership Acad',
  },
  {
    state_abbr: 'IN',
    leaid: '1800122',
    lea_name: 'Christel House DORS South',
  },
  {
    state_abbr: 'IN',
    leaid: '1800123',
    lea_name: 'Inspire Academy - A Sch of Inquiry',
  },
  {
    state_abbr: 'IN',
    leaid: '1800124',
    lea_name: 'Phalen Ldrshp Acad - IN Inc',
  },
  {
    state_abbr: 'IN',
    leaid: '1800125',
    lea_name: 'Carpe Diem - Meridian Campus',
  },
  {
    state_abbr: 'IN',
    leaid: '1800126',
    lea_name: 'CSUSA Howe',
  },
  {
    state_abbr: 'IN',
    leaid: '1800127',
    lea_name: 'CSUSA Donnan',
  },
  {
    state_abbr: 'IN',
    leaid: '1800128',
    lea_name: 'Tindley Preparatory Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800129',
    lea_name: 'Canaan Community Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800131',
    lea_name: 'Smith Academy for Excellence',
  },
  {
    state_abbr: 'IN',
    leaid: '1800134',
    lea_name: 'The Excel Center - Richmond',
  },
  {
    state_abbr: 'IN',
    leaid: '1800135',
    lea_name: 'The Excel Center - Kokomo',
  },
  {
    state_abbr: 'IN',
    leaid: '1800136',
    lea_name: 'Nexus Academy of Indianapolis',
  },
  {
    state_abbr: 'IN',
    leaid: '1800137',
    lea_name: 'Enlace Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800138',
    lea_name: 'The Excel Center-Lafayette Square',
  },
  {
    state_abbr: 'IN',
    leaid: '1800139',
    lea_name: 'Tindley Collegiate Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800141',
    lea_name: 'Tindley Renaissance Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800142',
    lea_name: 'The Excel Center - Lafayette',
  },
  {
    state_abbr: 'IN',
    leaid: '1800143',
    lea_name: 'IN Math & Science Academy - South',
  },
  {
    state_abbr: 'IN',
    leaid: '1800146',
    lea_name: 'Vision Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800147',
    lea_name: 'KIPP Indy Unite Elementary',
  },
  {
    state_abbr: 'IN',
    leaid: '1800148',
    lea_name: 'Tindley Summit Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1800149',
    lea_name: 'Christel House Academy West',
  },
  {
    state_abbr: 'IN',
    leaid: '1800152',
    lea_name: 'Indpls Acad of Excel-Chlng Fnd Aca',
  },
  {
    state_abbr: 'IN',
    leaid: '1800154',
    lea_name: 'Indiana Achievement Academy',
  },
  {
    state_abbr: 'IN',
    leaid: '1808320',
    lea_name: 'Northwest Indiana Spec Ed Coop',
  },
  {
    state_abbr: 'KS',
    leaid: '2000008',
    lea_name: 'Lawrence Gardner High School',
  },
  {
    state_abbr: 'KS',
    leaid: '2000018',
    lea_name: 'School for Blind',
  },
  {
    state_abbr: 'KS',
    leaid: '2000019',
    lea_name: 'School for Deaf',
  },
  {
    state_abbr: 'KS',
    leaid: '2000022',
    lea_name: 'St. Hosp. Training Center Parsons',
  },
  {
    state_abbr: 'KS',
    leaid: '2000352',
    lea_name: 'Larned Juvenile Correction',
  },
  {
    state_abbr: 'KY',
    leaid: '2100090',
    lea_name: 'Anchorage Independent',
  },
  {
    state_abbr: 'KY',
    leaid: '2100094',
    lea_name: 'Kentucky School for the Blind',
  },
  {
    state_abbr: 'KY',
    leaid: '2100095',
    lea_name: 'Kentucky School for the Deaf',
  },
  {
    state_abbr: 'KY',
    leaid: '2101590',
    lea_name: 'East Bernstadt Independent',
  },
  {
    state_abbr: 'KY',
    leaid: '2105220',
    lea_name: 'Science Hill Independent',
  },
  {
    state_abbr: 'KY',
    leaid: '2105460',
    lea_name: 'Southgate Independent',
  },
  {
    state_abbr: 'KY',
    leaid: '2105850',
    lea_name: 'West Point Independent',
  },
  {
    state_abbr: 'LA',
    leaid: '2200172',
    lea_name: 'South Louisiana Charter Foundation, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200173',
    lea_name: 'Jefferson Chamber Foundation Academy',
  },
  {
    state_abbr: 'LA',
    leaid: '2200174',
    lea_name: 'Louisiana Key Academy',
  },
  {
    state_abbr: 'LA',
    leaid: '2200018',
    lea_name: 'LA Schools for the Deaf and Visually Impaired',
  },
  {
    state_abbr: 'LA',
    leaid: '2200170',
    lea_name: 'Madison-Tallulah Education Center',
  },
  {
    state_abbr: 'LA',
    leaid: '2200017',
    lea_name: 'Louisiana School For Math Science & the Arts',
  },
  {
    state_abbr: 'LA',
    leaid: '2200020',
    lea_name: 'Louisiana Special Education Center',
  },
  {
    state_abbr: 'LA',
    leaid: '2200022',
    lea_name: 'LSU Laboratory School',
  },
  {
    state_abbr: 'LA',
    leaid: '2200023',
    lea_name: 'Southern University Lab School',
  },
  {
    state_abbr: 'LA',
    leaid: '2200025',
    lea_name: 'New Vision Learning Academy',
  },
  {
    state_abbr: 'LA',
    leaid: '2200032',
    lea_name: 'V. B. Glencoe Charter School',
  },
  {
    state_abbr: 'LA',
    leaid: '2200033',
    lea_name: 'International School of Louisiana',
  },
  {
    state_abbr: 'LA',
    leaid: '2200035',
    lea_name: 'Avoyelles Public Charter School',
  },
  {
    state_abbr: 'LA',
    leaid: '2200036',
    lea_name: 'New Orleans Center for Creative Arts',
  },
  {
    state_abbr: 'LA',
    leaid: '2200038',
    lea_name: 'Delhi Charter School',
  },
  {
    state_abbr: 'LA',
    leaid: '2200041',
    lea_name: 'Belle Chasse Academy, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200042',
    lea_name: 'Milestone Academy',
  },
  {
    state_abbr: 'LA',
    leaid: '2200043',
    lea_name: 'RSD-New Beginnings Schools Foundation',
  },
  {
    state_abbr: 'LA',
    leaid: '2200044',
    lea_name: 'RSD-Institute for Academic Excellence',
  },
  {
    state_abbr: 'LA',
    leaid: '2200045',
    lea_name: 'RSD-Knowledge is Power Program (KIPP) N.O.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200046',
    lea_name: 'RSD-FirstLine Schools, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200047',
    lea_name: 'Office of Juvenile Justice',
  },
  {
    state_abbr: 'LA',
    leaid: '2200048',
    lea_name: 'RSD-Dryades YMCA',
  },
  {
    state_abbr: 'LA',
    leaid: '2200049',
    lea_name: 'RSD-Friends of King',
  },
  {
    state_abbr: 'LA',
    leaid: '2200050',
    lea_name: 'RSD-New Orleans Charter Schools Foundation',
  },
  {
    state_abbr: 'LA',
    leaid: '2200051',
    lea_name: 'RSD-Choice Foundation',
  },
  {
    state_abbr: 'LA',
    leaid: '2200053',
    lea_name: 'RSD-Algiers Charter Schools Association (ACSA)',
  },
  {
    state_abbr: 'LA',
    leaid: '2200054',
    lea_name: 'Recovery School District-LDE',
  },
  {
    state_abbr: 'LA',
    leaid: '2200055',
    lea_name: 'Grambling State Lab Schools',
  },
  {
    state_abbr: 'LA',
    leaid: '2200056',
    lea_name: 'A.E. Phillips Laboratory School',
  },
  {
    state_abbr: 'LA',
    leaid: '2200171',
    lea_name: 'RSD-Better Choice Foundation',
  },
  {
    state_abbr: 'LA',
    leaid: '2200126',
    lea_name: 'The MAX Charter School',
  },
  {
    state_abbr: 'LA',
    leaid: '2200128',
    lea_name: 'RSD-New Orleans College Preparatory Academies',
  },
  {
    state_abbr: 'LA',
    leaid: '2200130',
    lea_name: 'RSD-Broadmoor Charter School Board',
  },
  {
    state_abbr: 'LA',
    leaid: '2200131',
    lea_name: 'RSD-Pelican Educational Foundation',
  },
  {
    state_abbr: 'LA',
    leaid: '2200132',
    lea_name: 'RSD-Collegiate Academies',
  },
  {
    state_abbr: 'LA',
    leaid: '2200133',
    lea_name: 'RSD-Akili Academy of New Orleans',
  },
  {
    state_abbr: 'LA',
    leaid: '2200135',
    lea_name: 'RSD-Miller-McCoy Academy for Math and Business',
  },
  {
    state_abbr: 'LA',
    leaid: '2200141',
    lea_name: 'RSD-Arise Academy',
  },
  {
    state_abbr: 'LA',
    leaid: '2200142',
    lea_name: 'Shreveport Charter School, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200144',
    lea_name: 'RSD-Success Preparatory Academy',
  },
  {
    state_abbr: 'LA',
    leaid: '2200146',
    lea_name: "D'Arbonne Woods Charter School",
  },
  {
    state_abbr: 'LA',
    leaid: '2200148',
    lea_name: 'Community School for Apprenticeship Learning, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200151',
    lea_name: 'Howard School',
  },
  {
    state_abbr: 'LA',
    leaid: '2200152',
    lea_name: 'RSD-Morris Jeff Community School',
  },
  {
    state_abbr: 'LA',
    leaid: '2200153',
    lea_name: 'RSD-Lagniappe Academies of New Orleans',
  },
  {
    state_abbr: 'LA',
    leaid: '2200154',
    lea_name: 'RSD-Spirit of Excellence Academy',
  },
  {
    state_abbr: 'LA',
    leaid: '2200155',
    lea_name: 'RSD-ReNEW-Reinventing Education, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200156',
    lea_name: 'Voices for International Business & Education',
  },
  {
    state_abbr: 'LA',
    leaid: '2200157',
    lea_name: 'Louisiana Connections Academy',
  },
  {
    state_abbr: 'LA',
    leaid: '2200158',
    lea_name: 'Lake Charles Charter Academy Foundation, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200159',
    lea_name: 'Lycee Francais de la Nouvelle-Orleans',
  },
  {
    state_abbr: 'LA',
    leaid: '2200160',
    lea_name: 'New Orleans Military/Maritime Academy',
  },
  {
    state_abbr: 'LA',
    leaid: '2200161',
    lea_name: 'RSD-Crescent City Schools',
  },
  {
    state_abbr: 'LA',
    leaid: '2200162',
    lea_name: 'RSD-Community Leaders Advocating Student Success',
  },
  {
    state_abbr: 'LA',
    leaid: '2200163',
    lea_name: 'Southwest Louisiana Charter Acad. Foundation, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200164',
    lea_name: 'RSD-Crescent Leadership Academy',
  },
  {
    state_abbr: 'LA',
    leaid: '2200166',
    lea_name: 'Outreach Community Development Corporation (OCDC)',
  },
  {
    state_abbr: 'LA',
    leaid: '2200167',
    lea_name: 'RSD-Educators for Quality Alternatives',
  },
  {
    state_abbr: 'LA',
    leaid: '2200168',
    lea_name: 'Northshore Charter School, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200169',
    lea_name: 'Delta Charter Group',
  },
  {
    state_abbr: 'LA',
    leaid: '2200175',
    lea_name: 'RSD-Celerity Schools Louisiana, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200176',
    lea_name: 'Cajun Butterflies, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200177',
    lea_name: 'Lafayette Charter Foundation, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200178',
    lea_name: 'RSD-Baton Rouge University Preparatory School Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200179',
    lea_name: 'Education Explosion, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200181',
    lea_name: 'Learning Solutions, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200182',
    lea_name: 'Louisiana Achievement Charter Academies, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2200183',
    lea_name: 'RSD-Friendship Louisiana, Inc.',
  },
  {
    state_abbr: 'LA',
    leaid: '2280100',
    lea_name: 'Special School District',
  },
  {
    state_abbr: 'MA',
    leaid: '2500021',
    lea_name: 'Academy Of the Pacific Rim Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500022',
    lea_name: 'Benjamin Banneker Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500024',
    lea_name: 'Cape Cod Lighthouse Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500025',
    lea_name: 'Innovation Academy Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500026',
    lea_name: 'City on a Hill Charter Public School Circuit Street (Distric',
  },
  {
    state_abbr: 'MA',
    leaid: '2500027',
    lea_name: 'Community Day Charter Public School - Prospect (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500028',
    lea_name: 'Sabis International Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500029',
    lea_name: 'Neighborhood House Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500030',
    lea_name: 'Benjamin Franklin Classical Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500031',
    lea_name: 'Hilltown Cooperative Charter  Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500032',
    lea_name: 'Lawrence Family Development Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500033',
    lea_name: 'Lowell Middlesex Academy Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500034',
    lea_name: 'Marblehead Community Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500035',
    lea_name: "Martha's Vineyard Charter (District)",
  },
  {
    state_abbr: 'MA',
    leaid: '2500036',
    lea_name: 'Ma Academy for Math and Science',
  },
  {
    state_abbr: 'MA',
    leaid: '2500038',
    lea_name: 'Francis W. Parker Charter Essential (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500039',
    lea_name: 'Boston Renaissance Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500040',
    lea_name: 'South Shore Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500041',
    lea_name: 'Atlantis Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500044',
    lea_name: 'Pioneer Valley Performing Arts Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500045',
    lea_name: 'Seven Hills Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500046',
    lea_name: 'Prospect Hill Academy Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500049',
    lea_name: 'Boston Day and Evening Academy Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500051',
    lea_name: 'Abby Kelley Foster Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500052',
    lea_name: 'Foxborough Regional Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500053',
    lea_name: 'Boston Collegiate Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500054',
    lea_name: 'Edward M. Kennedy Academy for Health Careers (Horace Mann Ch',
  },
  {
    state_abbr: 'MA',
    leaid: '2500055',
    lea_name: 'Mystic Valley Regional Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500057',
    lea_name: 'Rising Tide Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500058',
    lea_name: 'Sturgis Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500060',
    lea_name: 'Conservatory Lab Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500062',
    lea_name: 'River Valley Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500063',
    lea_name: 'Roxbury Preparatory Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500065',
    lea_name: 'Lowell Community Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500066',
    lea_name: 'MATCH Charter Public School (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500068',
    lea_name: 'Christa McAuliffe Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500069',
    lea_name: 'Amesbury Academy Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500070',
    lea_name: 'Codman Academy Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500071',
    lea_name: 'Brooke Charter School Roslindale (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500073',
    lea_name: 'The Sizer School: A North Central Charter Essential (Distric',
  },
  {
    state_abbr: 'MA',
    leaid: '2500075',
    lea_name: 'Excel Academy Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500076',
    lea_name: 'Four Rivers Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500077',
    lea_name: 'Helen Y. Davis Leadership Academy Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500079',
    lea_name: 'Berkshire Arts and Technology Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500080',
    lea_name: 'Boston Preparatory Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500081',
    lea_name: 'Barnstable Community Horace Mann Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500082',
    lea_name: 'KIPP Academy Lynn Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500083',
    lea_name: 'Hill View Montessori Charter Public (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500084',
    lea_name: 'Salem Academy Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500085',
    lea_name: 'Advanced Math and Science Academy Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500086',
    lea_name: 'Community Charter School of Cambridge (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500087',
    lea_name: 'Holyoke Community Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500089',
    lea_name: 'Martin Luther King Jr. Charter School of Excellence (Distric',
  },
  {
    state_abbr: 'MA',
    leaid: '2500090',
    lea_name: 'Phoenix Charter Academy (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500517',
    lea_name: 'Pioneer Valley Chinese Immersion Charter(District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500518',
    lea_name: 'Pioneer Charter School of Science (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500519',
    lea_name: 'Global Learning Charter Public  (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500520',
    lea_name: 'Silver Hill Horace Mann Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500521',
    lea_name: 'Dorchester Collegiate Academy Charter (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500522',
    lea_name: 'Hampden Charter School of Science (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500525',
    lea_name: 'Alma del Mar Charter School (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500526',
    lea_name: 'Boston Green Academy Horace Mann Charter School (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500527',
    lea_name: 'Bridge Boston Charter School (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500528',
    lea_name: 'Brooke Charter School Mattapan (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500532',
    lea_name: 'New Liberty Charter School of Salem (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500533',
    lea_name: 'UP Academy Charter School of Boston (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500535',
    lea_name: 'Community Day Charter Public School - R. Kingman Webster (Di',
  },
  {
    state_abbr: 'MA',
    leaid: '2500536',
    lea_name: 'Veritas Preparatory Charter School (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500537',
    lea_name: 'KIPP Academy Boston Charter School (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500538',
    lea_name: 'Community Day Charter Public School - Gateway (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500539',
    lea_name: 'Brooke Charter School East Boston (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500543',
    lea_name: 'Dudley Street Neighborhood Charter School (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500545',
    lea_name: 'Lowell Collegiate Charter School (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500546',
    lea_name: 'Pioneer Charter School of Science II (PCSS-II) (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500547',
    lea_name: 'Paulo Freire Social Justice Charter School (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500548',
    lea_name: 'City on a Hill Charter Public School Dudley Square (District',
  },
  {
    state_abbr: 'MA',
    leaid: '2500549',
    lea_name: 'UP Academy Charter School of Dorchester (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500550',
    lea_name: 'Baystate Academy Charter Public School (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500551',
    lea_name: 'Massachusetts Virtual Academy at Greenfield Commonwealth Vir',
  },
  {
    state_abbr: 'MA',
    leaid: '2500552',
    lea_name: 'Argosy Collegiate Charter School (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500553',
    lea_name: 'City on a Hill Charter Public School New Bedford (District)',
  },
  {
    state_abbr: 'MA',
    leaid: '2500554',
    lea_name: 'Essex North Shore Agricultural and Technical School District',
  },
  {
    state_abbr: 'MA',
    leaid: '2500555',
    lea_name: 'Phoenix Academy Public Charter High School Springfield (Dist',
  },
  {
    state_abbr: 'MA',
    leaid: '2500556',
    lea_name: 'TEC Connections Academy Commonwealth Virtual School District',
  },
  {
    state_abbr: 'MA',
    leaid: '2502110',
    lea_name: 'Assabet Valley Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2502710',
    lea_name: 'Blackstone Valley Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2502740',
    lea_name: 'Blue Hills Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2503070',
    lea_name: 'Bristol County Agricultural',
  },
  {
    state_abbr: 'MA',
    leaid: '2503080',
    lea_name: 'Bristol-Plymouth Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2503310',
    lea_name: 'Cape Cod Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2505020',
    lea_name: 'Franklin County Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2505470',
    lea_name: 'Greater Lawrence Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2505480',
    lea_name: 'Greater Lowell Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2505485',
    lea_name: 'Greater Fall River Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2507985',
    lea_name: 'Minuteman Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2508050',
    lea_name: 'Montachusett Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2508320',
    lea_name: 'Nashoba Valley Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2508440',
    lea_name: 'Greater New Bedford Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2508650',
    lea_name: 'Norfolk County Agricultural',
  },
  {
    state_abbr: 'MA',
    leaid: '2508860',
    lea_name: 'Northampton-Smith Vocational Agricultural',
  },
  {
    state_abbr: 'MA',
    leaid: '2508950',
    lea_name: 'Northern Berkshire Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2508960',
    lea_name: 'Northeast Metropolitan Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2509140',
    lea_name: 'Old Colony Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2509310',
    lea_name: 'Pathfinder Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2510615',
    lea_name: 'Shawsheen Valley Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2510930',
    lea_name: 'South Shore Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2511020',
    lea_name: 'Southeastern Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2511050',
    lea_name: 'Southern Worcester County Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2511060',
    lea_name: 'South Middlesex Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2511735',
    lea_name: 'Tri County Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2511800',
    lea_name: 'Upper Cape Cod Regional Vocational Technical',
  },
  {
    state_abbr: 'MA',
    leaid: '2512950',
    lea_name: 'Whittier Regional Vocational Technical',
  },
  {
    state_abbr: 'MD',
    leaid: '2400027',
    lea_name: 'SEED School of Maryland',
  },
  {
    state_abbr: 'ME',
    leaid: '2300049',
    lea_name: 'ME Sch of Science & Mathematics',
  },
  {
    state_abbr: 'ME',
    leaid: '2300050',
    lea_name: 'Arthur R. Gould Sch--LCYDC',
  },
  {
    state_abbr: 'ME',
    leaid: '2300056',
    lea_name: 'Mountain View Youth Dev Ctr',
  },
  {
    state_abbr: 'ME',
    leaid: '2304860',
    lea_name: 'Dayton Public Schools',
  },
  {
    state_abbr: 'ME',
    leaid: '2305500',
    lea_name: 'Ellsworth Public Schools',
  },
  {
    state_abbr: 'ME',
    leaid: '2306260',
    lea_name: 'Hancock Public Schools',
  },
  {
    state_abbr: 'ME',
    leaid: '2307250',
    lea_name: 'Lamoine Public Schools',
  },
  {
    state_abbr: 'ME',
    leaid: '2310380',
    lea_name: 'Saco Public Schools',
  },
  {
    state_abbr: 'ME',
    leaid: '2312100',
    lea_name: 'Education in Unorganized Terr',
  },
  {
    state_abbr: 'ME',
    leaid: '2313980',
    lea_name: 'Wiscasset Public Schools',
  },
  {
    state_abbr: 'ME',
    leaid: '2314777',
    lea_name: 'RSU 67',
  },
  {
    state_abbr: 'ME',
    leaid: '2314807',
    lea_name: 'Cornville Regional Charter School',
  },
  {
    state_abbr: 'ME',
    leaid: '2314808',
    lea_name: 'Maine Academy of Natural Sciences',
  },
  {
    state_abbr: 'ME',
    leaid: '2314809',
    lea_name: 'RSU 09',
  },
  {
    state_abbr: 'ME',
    leaid: '2314810',
    lea_name: 'Portage Lake Public Schools',
  },
  {
    state_abbr: 'ME',
    leaid: '2314812',
    lea_name: 'Baxter Academy for Technology and Science',
  },
  {
    state_abbr: 'ME',
    leaid: '2314813',
    lea_name: 'Fiddlehead School of Arts and Sciences',
  },
  {
    state_abbr: 'ME',
    leaid: '2314816',
    lea_name: 'Harpswell Coastal Academy',
  },
  {
    state_abbr: 'ME',
    leaid: '2314819',
    lea_name: 'Maine Connections Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600142',
    lea_name: 'Countryside Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600192',
    lea_name: 'Endeavor Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600193',
    lea_name: 'Grand Blanc Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600087',
    lea_name: 'Renaissance Public School Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600088',
    lea_name: 'Da Vinci Institute',
  },
  {
    state_abbr: 'MI',
    leaid: '2600079',
    lea_name: 'Cole Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600080',
    lea_name: 'El-Hajj Malik El-Shabazz Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600082',
    lea_name: 'Mid-Michigan Leadership Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600001',
    lea_name: 'Michigan Department of Corrections',
  },
  {
    state_abbr: 'MI',
    leaid: '2600004',
    lea_name: 'Michigan Department of Human Services',
  },
  {
    state_abbr: 'MI',
    leaid: '2600020',
    lea_name: 'Windover High School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600067',
    lea_name: 'Bay-Arenac Community High School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600068',
    lea_name: 'Pansophia Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600069',
    lea_name: 'Concord Academy - Boyne',
  },
  {
    state_abbr: 'MI',
    leaid: '2600070',
    lea_name: 'Northwest Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600071',
    lea_name: 'Joseph K. Lumsden Bahweting Anishnabe Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600073',
    lea_name: 'Island City Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600074',
    lea_name: 'Concord Academy - Petoskey',
  },
  {
    state_abbr: 'MI',
    leaid: '2600076',
    lea_name: 'Woodland Park Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600078',
    lea_name: 'Woodland School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600090',
    lea_name: 'New Branches Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600092',
    lea_name: 'West MI Academy of Environmental Science',
  },
  {
    state_abbr: 'MI',
    leaid: '2600093',
    lea_name: 'Excel Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600096',
    lea_name: 'Byron Center Charter School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600097',
    lea_name: 'Vista Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600098',
    lea_name: 'Vanguard Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600099',
    lea_name: 'Kensington Woods High School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600100',
    lea_name: 'Charyl Stockwell Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600101',
    lea_name: 'Macomb Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600103',
    lea_name: 'Nah Tah Wahsh Public School Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600106',
    lea_name: 'AGBU Alex-Marie Manoogian School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600108',
    lea_name: 'Academy of Southfield',
  },
  {
    state_abbr: 'MI',
    leaid: '2600112',
    lea_name: 'Walden Green Montessori',
  },
  {
    state_abbr: 'MI',
    leaid: '2600113',
    lea_name: 'West MI Academy of Arts and Academics',
  },
  {
    state_abbr: 'MI',
    leaid: '2600114',
    lea_name: 'Black River Public School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600115',
    lea_name: 'Vanderbilt Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600121',
    lea_name: 'Honey Creek Community School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600125',
    lea_name: 'Plymouth Educational Center Charter School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600126',
    lea_name: 'Nataki Talibah Schoolhouse of Detroit',
  },
  {
    state_abbr: 'MI',
    leaid: '2600128',
    lea_name: 'Michigan Technical Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600131',
    lea_name: 'Martin Luther King Jr. Education Center Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600132',
    lea_name: 'Gaudior Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600134',
    lea_name: 'Woodward Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600136',
    lea_name: 'Eaton Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600137',
    lea_name: 'Summit Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600139',
    lea_name: 'Cesar Chavez Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600140',
    lea_name: 'Commonwealth Community Development Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600141',
    lea_name: 'Midland Academy of Advanced and Creative Studies',
  },
  {
    state_abbr: 'MI',
    leaid: '2600143',
    lea_name: 'Hillsdale Preparatory School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600144',
    lea_name: 'Morey Montessori Public School Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600145',
    lea_name: 'Grattan Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600148',
    lea_name: 'Knapp Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600149',
    lea_name: 'Walker Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600150',
    lea_name: 'Cross Creek Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600151',
    lea_name: 'Chatfield School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600152',
    lea_name: 'Casman Alternative Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600153',
    lea_name: 'North Star Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600156',
    lea_name: 'Pontiac Academy for Excellence',
  },
  {
    state_abbr: 'MI',
    leaid: '2600157',
    lea_name: 'Great Lakes Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600159',
    lea_name: 'Eagle Crest Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600161',
    lea_name: 'Saginaw Preparatory Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600163',
    lea_name: 'Central Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600164',
    lea_name: 'Washtenaw Technical Middle College',
  },
  {
    state_abbr: 'MI',
    leaid: '2600166',
    lea_name: 'Academy for Business and Technology',
  },
  {
    state_abbr: 'MI',
    leaid: '2600168',
    lea_name: 'Chandler Park Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600169',
    lea_name: 'Marvin L. Winans Academy of Performing Arts',
  },
  {
    state_abbr: 'MI',
    leaid: '2600170',
    lea_name: 'Detroit Community Schools',
  },
  {
    state_abbr: 'MI',
    leaid: '2600171',
    lea_name: 'Henry Ford Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600173',
    lea_name: 'The Dearborn Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600174',
    lea_name: 'Detroit Academy of Arts and Sciences',
  },
  {
    state_abbr: 'MI',
    leaid: '2600175',
    lea_name: 'Dove Academy of Detroit',
  },
  {
    state_abbr: 'MI',
    leaid: '2600178',
    lea_name: 'Timbuktu Academy of Science and Technology',
  },
  {
    state_abbr: 'MI',
    leaid: '2600181',
    lea_name: 'Arbor Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600182',
    lea_name: 'Will Carleton Charter School Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600184',
    lea_name: 'Oakland Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600185',
    lea_name: 'William C. Abney Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600186',
    lea_name: 'Crossroads Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600191',
    lea_name: 'Benton Harbor Charter School Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600194',
    lea_name: 'Northridge Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600195',
    lea_name: 'International Academy of Flint',
  },
  {
    state_abbr: 'MI',
    leaid: '2600197',
    lea_name: 'Linden Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600200',
    lea_name: 'White Pine Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600203',
    lea_name: 'Windemere Park Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600204',
    lea_name: 'Paragon Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600206',
    lea_name: 'Paramount Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600207',
    lea_name: 'Creative Technologies Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600208',
    lea_name: 'Ridge Park Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600209',
    lea_name: 'Chandler Woods Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600210',
    lea_name: 'Conner Creek Academy East',
  },
  {
    state_abbr: 'MI',
    leaid: '2600211',
    lea_name: 'Huron Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600212',
    lea_name: 'Global Preparatory Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600213',
    lea_name: 'New Bedford Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600214',
    lea_name: 'Timberland Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600215',
    lea_name: 'Dr. Joseph F. Pollack Academic Center of Excellence',
  },
  {
    state_abbr: 'MI',
    leaid: '2600216',
    lea_name: 'Holly Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600217',
    lea_name: 'Oakland International Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600218',
    lea_name: 'Francis Reh PSA',
  },
  {
    state_abbr: 'MI',
    leaid: '2600219',
    lea_name: 'North Saginaw Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600220',
    lea_name: 'Landmark Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600222',
    lea_name: 'Ann Arbor Learning Community',
  },
  {
    state_abbr: 'MI',
    leaid: '2600223',
    lea_name: 'South Arbor Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600224',
    lea_name: 'George Crockett Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600225',
    lea_name: 'Summit Academy North',
  },
  {
    state_abbr: 'MI',
    leaid: '2600227',
    lea_name: 'Voyageur Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600228',
    lea_name: 'Star International Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600229',
    lea_name: 'Hope Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600230',
    lea_name: 'Weston Preparatory Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600232',
    lea_name: 'Detroit Edison Public School Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600234',
    lea_name: 'David Ellis Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600235',
    lea_name: 'Ross-Hill Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600237',
    lea_name: 'Universal Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600239',
    lea_name: 'Detroit Service Learning Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600241',
    lea_name: 'Allen Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600242',
    lea_name: 'Old Redford Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600243',
    lea_name: 'Hope of Detroit Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600244',
    lea_name: 'Joy Preparatory Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600245',
    lea_name: 'West Village Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600248',
    lea_name: 'New Beginnings Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600249',
    lea_name: 'George Washington Carver Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600250',
    lea_name: 'Marshall Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600251',
    lea_name: 'Burton Glen Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600252',
    lea_name: 'Grand Rapids Child Discovery Center',
  },
  {
    state_abbr: 'MI',
    leaid: '2600254',
    lea_name: 'Walton Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600255',
    lea_name: 'Advanced Technology Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600257',
    lea_name: 'Blue Water Learning Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600262',
    lea_name: 'Metro Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600263',
    lea_name: 'Canton Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600264',
    lea_name: 'Bay County PSA',
  },
  {
    state_abbr: 'MI',
    leaid: '2600265',
    lea_name: 'Grand Traverse Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600267',
    lea_name: 'Arts and Technology Academy of Pontiac',
  },
  {
    state_abbr: 'MI',
    leaid: '2600269',
    lea_name: 'Presque Isle Academy II',
  },
  {
    state_abbr: 'MI',
    leaid: '2600270',
    lea_name: 'Creative Montessori Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600271',
    lea_name: 'Warrendale Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600272',
    lea_name: 'Blanche Kelso Bruce Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600273',
    lea_name: 'Arts Academy in the Woods',
  },
  {
    state_abbr: 'MI',
    leaid: '2600274',
    lea_name: 'Outlook Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600275',
    lea_name: 'Battle Creek Area Learning Center',
  },
  {
    state_abbr: 'MI',
    leaid: '2600277',
    lea_name: 'Trillium Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600278',
    lea_name: 'Detroit Merit Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600317',
    lea_name: 'Madison Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600281',
    lea_name: 'Merritt Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600283',
    lea_name: 'Mt. Clemens Montessori Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600284',
    lea_name: 'Bradford Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600285',
    lea_name: 'Ojibwe Charter School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600286',
    lea_name: 'Richfield Public School Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600287',
    lea_name: 'Hamtramck Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600288',
    lea_name: 'Three Oaks Public School Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600289',
    lea_name: 'Keystone Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600290',
    lea_name: 'Academy of Waterford',
  },
  {
    state_abbr: 'MI',
    leaid: '2600291',
    lea_name: 'Riverside Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600292',
    lea_name: 'Life Skills Center of Pontiac',
  },
  {
    state_abbr: 'MI',
    leaid: '2600294',
    lea_name: 'Great Oaks Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600295',
    lea_name: 'Crescent Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600296',
    lea_name: 'Laurus Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600297',
    lea_name: 'Mildred C. Wells Preparatory Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600300',
    lea_name: 'Universal Learning Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600301',
    lea_name: 'Frontier International Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600302',
    lea_name: 'Detroit Enterprise Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600305',
    lea_name: 'Hanley International Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600306',
    lea_name: 'Bridge Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600307',
    lea_name: 'Detroit Premier Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600308',
    lea_name: 'Academy of Warren',
  },
  {
    state_abbr: 'MI',
    leaid: '2600310',
    lea_name: 'Prevail Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600311',
    lea_name: 'Triumph Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600312',
    lea_name: 'American Montessori Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600314',
    lea_name: 'Fortis Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600315',
    lea_name: 'Multicultural Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600316',
    lea_name: 'St. Clair County Intervention Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600318',
    lea_name: 'Saginaw Learn to Earn Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600319',
    lea_name: 'Bingham Arts Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600322',
    lea_name: 'Covenant House Academy Detroit',
  },
  {
    state_abbr: 'MI',
    leaid: '2600330',
    lea_name: 'Academic and Career Education Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600331',
    lea_name: 'Taylor Exemplar Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600333',
    lea_name: 'David Ellis Academy West',
  },
  {
    state_abbr: 'MI',
    leaid: '2600943',
    lea_name: 'ACE Academy (SDA)',
  },
  {
    state_abbr: 'MI',
    leaid: '2600945',
    lea_name: 'Flagship Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600946',
    lea_name: 'International Academy of Saginaw',
  },
  {
    state_abbr: 'MI',
    leaid: '2600947',
    lea_name: 'Clara B. Ford Academy (SDA)',
  },
  {
    state_abbr: 'MI',
    leaid: '2600949',
    lea_name: 'Lighthouse Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600950',
    lea_name: 'Reach Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600951',
    lea_name: 'Vista Meadows Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600952',
    lea_name: 'University Preparatory Science and Math (PSAD)',
  },
  {
    state_abbr: 'MI',
    leaid: '2600954',
    lea_name: 'Dream Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600955',
    lea_name: 'Youth Advancement Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600956',
    lea_name: 'University Preparatory Academy (PSAD)',
  },
  {
    state_abbr: 'MI',
    leaid: '2600958',
    lea_name: 'Washington-Parks Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600959',
    lea_name: 'Lansing Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600961',
    lea_name: 'Henry Ford Academy: School for Creative Studies (PSAD)',
  },
  {
    state_abbr: 'MI',
    leaid: '2600963',
    lea_name: 'Achieve Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600964',
    lea_name: 'Four Corners Montessori Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600965',
    lea_name: 'Quest Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600966',
    lea_name: 'Three Lakes Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600967',
    lea_name: 'Michigan Mathematics and Science Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600968',
    lea_name: 'Virtual Learning Academy of St. Clair County',
  },
  {
    state_abbr: 'MI',
    leaid: '2600969',
    lea_name: 'Michigan Connections Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600970',
    lea_name: 'Leelanau Montessori Public School Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600971',
    lea_name: 'University Yes Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600972',
    lea_name: 'Michigan Virtual Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600973',
    lea_name: 'Global Heights Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600974',
    lea_name: 'Detroit Leadership Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600975',
    lea_name: 'Taylor International Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600977',
    lea_name: 'West Michigan Aviation Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600979',
    lea_name: 'Legacy Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600980',
    lea_name: 'DeTour Arts and Technology Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600981',
    lea_name: 'Wellspring Preparatory High School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600982',
    lea_name: 'Relevant Academy of Eaton County',
  },
  {
    state_abbr: 'MI',
    leaid: '2600983',
    lea_name: 'The Greenspire School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600984',
    lea_name: 'FlexTech High School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600985',
    lea_name: 'Regent Park Scholars Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600986',
    lea_name: 'American International Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600987',
    lea_name: 'Hamilton Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600988',
    lea_name: 'Hope Academy of West Michigan',
  },
  {
    state_abbr: 'MI',
    leaid: '2600989',
    lea_name: 'Arbor Preparatory High School',
  },
  {
    state_abbr: 'MI',
    leaid: '2600990',
    lea_name: 'Forest Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600991',
    lea_name: 'GEE Edmonson Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600992',
    lea_name: 'Blue Water Middle College',
  },
  {
    state_abbr: 'MI',
    leaid: '2600993',
    lea_name: 'Jalen Rose Leadership Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600994',
    lea_name: 'New Paradigm Glazer Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600995',
    lea_name: 'Noor International Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600996',
    lea_name: 'East Arbor Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600997',
    lea_name: 'South Canton Scholars Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600998',
    lea_name: 'New Paradigm Loving Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2600999',
    lea_name: 'GEE White Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601000',
    lea_name: 'Bay City Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601001',
    lea_name: 'Education Achievement Authority of Michigan',
  },
  {
    state_abbr: 'MI',
    leaid: '2601002',
    lea_name: 'Academy of International Studies',
  },
  {
    state_abbr: 'MI',
    leaid: '2601003',
    lea_name: 'Detroit Innovation Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601004',
    lea_name: 'Starr Detroit Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601006',
    lea_name: 'WSC Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601007',
    lea_name: 'Michigan School for the Arts',
  },
  {
    state_abbr: 'MI',
    leaid: '2601008',
    lea_name: 'Madison-Carver Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601010',
    lea_name: 'Michigan Educational Choice Center',
  },
  {
    state_abbr: 'MI',
    leaid: '2601011',
    lea_name: 'Innocademy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601012',
    lea_name: 'Rutherford Winans Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601013',
    lea_name: 'Capstone Academy Charter School (SDA)',
  },
  {
    state_abbr: 'MI',
    leaid: '2601014',
    lea_name: 'Highland Park Public School Academy System',
  },
  {
    state_abbr: 'MI',
    leaid: '2601015',
    lea_name: 'Evergreen Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601016',
    lea_name: 'Caniff Liberty Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601017',
    lea_name: 'Nexus Academy of Grand Rapids',
  },
  {
    state_abbr: 'MI',
    leaid: '2601018',
    lea_name: 'The New Standard Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601019',
    lea_name: 'Nexus Academy of Lansing',
  },
  {
    state_abbr: 'MI',
    leaid: '2601020',
    lea_name: 'Plymouth Scholars Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601021',
    lea_name: 'South Pointe Scholars Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601023',
    lea_name: 'W-A-Y Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601025',
    lea_name: 'Grand Rapids Ellington Academy of Arts & Technology',
  },
  {
    state_abbr: 'MI',
    leaid: '2601026',
    lea_name: 'Wolverine Academy (SDA)',
  },
  {
    state_abbr: 'MI',
    leaid: '2601027',
    lea_name: 'Faxon Language Immersion Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601028',
    lea_name: 'Charlton Heston Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601029',
    lea_name: 'Muskegon Heights Public School Academy System',
  },
  {
    state_abbr: 'MI',
    leaid: '2601030',
    lea_name: 'Cornerstone Health School',
  },
  {
    state_abbr: 'MI',
    leaid: '2601031',
    lea_name: 'River City Scholars Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601032',
    lea_name: 'Escuela Avancemos',
  },
  {
    state_abbr: 'MI',
    leaid: '2601033',
    lea_name: 'International Preparatory Academy - MacDowell Campus',
  },
  {
    state_abbr: 'MI',
    leaid: '2601034',
    lea_name: 'Alternative Educational Academy of Iosco County',
  },
  {
    state_abbr: 'MI',
    leaid: '2601035',
    lea_name: 'Macomb Montessori Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601036',
    lea_name: 'Tipton Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601037',
    lea_name: 'Greater Heights Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601039',
    lea_name: 'Genesee STEM Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601040',
    lea_name: 'ICademy Global',
  },
  {
    state_abbr: 'MI',
    leaid: '2601041',
    lea_name: 'Sarah J. Webber Media Arts Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601042',
    lea_name: 'Branch Line School',
  },
  {
    state_abbr: 'MI',
    leaid: '2601043',
    lea_name: 'Oakland FlexTech Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601044',
    lea_name: 'Jackson Preparatory & Early College',
  },
  {
    state_abbr: 'MI',
    leaid: '2601045',
    lea_name: 'Momentum Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601046',
    lea_name: 'Kingsbury Country Day School',
  },
  {
    state_abbr: 'MI',
    leaid: '2601047',
    lea_name: 'Rising Stars Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601048',
    lea_name: 'Detroit Achievement Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601049',
    lea_name: 'Southwest Detroit Lighthouse Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601050',
    lea_name: 'Great Lakes Cyber Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601051',
    lea_name: 'The James and Grace Lee Boggs School',
  },
  {
    state_abbr: 'MI',
    leaid: '2601053',
    lea_name: 'Waterford Montessori Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601054',
    lea_name: 'Success Mile Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601055',
    lea_name: 'Battle Creek Montessori Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601056',
    lea_name: 'WAY Academy - Flint',
  },
  {
    state_abbr: 'MI',
    leaid: '2601057',
    lea_name: 'Mackinac Preparatory Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601058',
    lea_name: 'Life Tech Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601060',
    lea_name: 'Experiencia Preparatory Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601061',
    lea_name: 'Taylor Preparatory High School',
  },
  {
    state_abbr: 'MI',
    leaid: '2601062',
    lea_name: 'Detroit Public Safety Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601063',
    lea_name: 'Lakeside Charter School',
  },
  {
    state_abbr: 'MI',
    leaid: '2601064',
    lea_name: 'Global Tech Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601065',
    lea_name: 'Nexus Academy of Royal Oak',
  },
  {
    state_abbr: 'MI',
    leaid: '2601066',
    lea_name: 'Covenant House Academy Grand Rapids',
  },
  {
    state_abbr: 'MI',
    leaid: '2601067',
    lea_name: 'Muskegon Montessori Academy for Environmental Change',
  },
  {
    state_abbr: 'MI',
    leaid: '2601068',
    lea_name: 'Oakside Scholars Charter Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601069',
    lea_name: 'Jefferson International Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601070',
    lea_name: 'Michigan Great Lakes Virtual Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601071',
    lea_name: 'Innocademy Allegan Campus',
  },
  {
    state_abbr: 'MI',
    leaid: '2601072',
    lea_name: 'Blended Learning Academies Credit Recovery High School',
  },
  {
    state_abbr: 'MI',
    leaid: '2601074',
    lea_name: 'WAY Michigan',
  },
  {
    state_abbr: 'MI',
    leaid: '2601075',
    lea_name: 'New Paradigm College Prep',
  },
  {
    state_abbr: 'MI',
    leaid: '2601076',
    lea_name: 'Pathways Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601077',
    lea_name: 'Insight School of Michigan',
  },
  {
    state_abbr: 'MI',
    leaid: '2601080',
    lea_name: 'Canton Preparatory High School',
  },
  {
    state_abbr: 'MI',
    leaid: '2601081',
    lea_name: 'Detroit Delta Preparatory Academy for Social Justice',
  },
  {
    state_abbr: 'MI',
    leaid: '2601082',
    lea_name: 'Grand River Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601085',
    lea_name: 'Early Career Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601087',
    lea_name: 'Schools for the Future Detroit',
  },
  {
    state_abbr: 'MI',
    leaid: '2601088',
    lea_name: 'Covenant House Academy Muskegon',
  },
  {
    state_abbr: 'MI',
    leaid: '2601090',
    lea_name: 'East Shore Leadership Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601091',
    lea_name: 'Regents Academy',
  },
  {
    state_abbr: 'MI',
    leaid: '2601092',
    lea_name: 'Alternative Educational Academy of Ogemaw County',
  },
  {
    state_abbr: 'MI',
    leaid: '2680180',
    lea_name: 'Branch ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680200',
    lea_name: 'Calhoun ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680000',
    lea_name: 'Wexford-Missaukee ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680100',
    lea_name: 'Allegan Area Educational Service Agency',
  },
  {
    state_abbr: 'MI',
    leaid: '2680120',
    lea_name: 'Alpena-Montmorency-Alcona ESD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680140',
    lea_name: 'Barry ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680160',
    lea_name: 'Berrien RESA',
  },
  {
    state_abbr: 'MI',
    leaid: '2680220',
    lea_name: 'Lewis Cass ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680240',
    lea_name: 'Charlevoix-Emmet ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680260',
    lea_name: 'Cheb-Otsego-Presque Isle ESD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680280',
    lea_name: 'Eastern Upper Peninsula ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680300',
    lea_name: 'Clare-Gladwin Regional Education Service District',
  },
  {
    state_abbr: 'MI',
    leaid: '2680320',
    lea_name: 'Clinton County RESA',
  },
  {
    state_abbr: 'MI',
    leaid: '2680340',
    lea_name: 'Delta-Schoolcraft ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680360',
    lea_name: 'Dickinson-Iron ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680380',
    lea_name: 'Eaton RESA',
  },
  {
    state_abbr: 'MI',
    leaid: '2680400',
    lea_name: 'Genesee ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680420',
    lea_name: 'Gogebic-Ontonagon ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680440',
    lea_name: 'Traverse Bay Area ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680460',
    lea_name: 'Gratiot-Isabella RESD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680480',
    lea_name: 'Hillsdale ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680500',
    lea_name: 'Huron ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680504',
    lea_name: 'Bay-Arenac ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680505',
    lea_name: 'Copper Country ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680520',
    lea_name: 'Ingham ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680540',
    lea_name: 'Ionia ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680560',
    lea_name: 'Iosco RESA',
  },
  {
    state_abbr: 'MI',
    leaid: '2680580',
    lea_name: 'Jackson ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680600',
    lea_name: 'Kalamazoo RESA',
  },
  {
    state_abbr: 'MI',
    leaid: '2680620',
    lea_name: 'Kent ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680660',
    lea_name: 'Lapeer ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680680',
    lea_name: 'Lenawee ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680700',
    lea_name: 'Livingston ESA',
  },
  {
    state_abbr: 'MI',
    leaid: '2680720',
    lea_name: 'Macomb ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680740',
    lea_name: 'Manistee ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680750',
    lea_name: 'Marquette-Alger RESA',
  },
  {
    state_abbr: 'MI',
    leaid: '2680755',
    lea_name: 'West Shore Educational Service District',
  },
  {
    state_abbr: 'MI',
    leaid: '2680770',
    lea_name: 'Mecosta-Osceola ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680790',
    lea_name: 'Menominee ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680800',
    lea_name: 'Midland County Educational Service Agency',
  },
  {
    state_abbr: 'MI',
    leaid: '2680820',
    lea_name: 'Monroe ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680830',
    lea_name: 'Montcalm Area ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680850',
    lea_name: 'Muskegon Area ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680860',
    lea_name: 'Newaygo County RESA',
  },
  {
    state_abbr: 'MI',
    leaid: '2680880',
    lea_name: 'Oakland Schools',
  },
  {
    state_abbr: 'MI',
    leaid: '2680900',
    lea_name: 'Ottawa Area ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680920',
    lea_name: 'C.O.O.R. ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680930',
    lea_name: 'Saginaw ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680940',
    lea_name: 'St. Clair County RESA',
  },
  {
    state_abbr: 'MI',
    leaid: '2680960',
    lea_name: 'St. Joseph County ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680965',
    lea_name: 'Sanilac ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680975',
    lea_name: 'Shiawassee Regional ESD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680980',
    lea_name: 'Tuscola ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680985',
    lea_name: 'Van Buren ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680990',
    lea_name: 'Washtenaw ISD',
  },
  {
    state_abbr: 'MI',
    leaid: '2680995',
    lea_name: 'Wayne RESA',
  },
  {
    state_abbr: 'MN',
    leaid: '2700311',
    lea_name: 'ASPEN ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700354',
    lea_name: 'NEW CITY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700014',
    lea_name: 'FRESHWATER ED. DIST.',
  },
  {
    state_abbr: 'MN',
    leaid: '2700016',
    lea_name: 'RUNESTONE AREA ED. DISTRICT',
  },
  {
    state_abbr: 'MN',
    leaid: '2700025',
    lea_name: 'INTERMEDIATE SCHOOL DISTRICT 287',
  },
  {
    state_abbr: 'MN',
    leaid: '2700026',
    lea_name: 'INTERMEDIATE SCHOOL DISTRICT 917',
  },
  {
    state_abbr: 'MN',
    leaid: '2700027',
    lea_name: 'CEDAR RIVERSIDE COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700028',
    lea_name: 'METRO DEAF SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700033',
    lea_name: 'OAK LAND VOCATIONAL COOPERATVE',
  },
  {
    state_abbr: 'MN',
    leaid: '2700036',
    lea_name: 'WRIGHT TECHNICAL CENTER',
  },
  {
    state_abbr: 'MN',
    leaid: '2700045',
    lea_name: 'MEEKER AND WRIGHT SPECIAL EDUCATION',
  },
  {
    state_abbr: 'MN',
    leaid: '2700046',
    lea_name: 'SOUTHERN PLAINS EDUCATION COOP.',
  },
  {
    state_abbr: 'MN',
    leaid: '2700054',
    lea_name: 'ZUMBRO EDUCATION DISTRICT',
  },
  {
    state_abbr: 'MN',
    leaid: '2700055',
    lea_name: 'HIAWATHA VALLEY ED. DISTRICT',
  },
  {
    state_abbr: 'MN',
    leaid: '2700058',
    lea_name: 'MN RIVER VALLEY EDUCATION DISTRICT',
  },
  {
    state_abbr: 'MN',
    leaid: '2700083',
    lea_name: 'REGION 4-LAKES COUNTRY SERVICE COOP',
  },
  {
    state_abbr: 'MN',
    leaid: '2700086',
    lea_name: 'REGN 6 AND 8-SW/WC SRV COOPERATIVE',
  },
  {
    state_abbr: 'MN',
    leaid: '2700092',
    lea_name: 'MINNESOTA NEW COUNTRY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700093',
    lea_name: 'PACT CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700096',
    lea_name: 'ATHLOS LEADERSHIP ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700115',
    lea_name: 'COMMUNITY OF PEACE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700116',
    lea_name: 'WORLD LEARNER CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700117',
    lea_name: 'MINNESOTA TRANSITIONS CHARTER SCH',
  },
  {
    state_abbr: 'MN',
    leaid: '2700118',
    lea_name: 'ACHIEVE LANGUAGE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700133',
    lea_name: 'DULUTH PUBLIC SCHOOLS ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700138',
    lea_name: 'CYBER VILLAGE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700139',
    lea_name: 'E.C.H.O. CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700140',
    lea_name: 'HIGHER GROUND ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700142',
    lea_name: 'ST. PAUL CITY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700143',
    lea_name: 'CENTRAL MINNESOTA JT. POWERS DIST.',
  },
  {
    state_abbr: 'MN',
    leaid: '2700146',
    lea_name: 'VALLEY CROSSING COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700151',
    lea_name: 'ODYSSEY ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700152',
    lea_name: 'JENNINGS COMMUNITY LEARNING CENTER',
  },
  {
    state_abbr: 'MN',
    leaid: '2700153',
    lea_name: 'HARVEST PREPARATORY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700156',
    lea_name: 'LIFE PREP',
  },
  {
    state_abbr: 'MN',
    leaid: '2700157',
    lea_name: 'FACE TO FACE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700159',
    lea_name: 'SOJOURNER TRUTH ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700160',
    lea_name: 'HIGH SCHOOL FOR RECORDING ARTS',
  },
  {
    state_abbr: 'MN',
    leaid: '2700166',
    lea_name: 'TWIN CITIES ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700167',
    lea_name: 'MATH AND SCIENCE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700173',
    lea_name: 'NORTHWEST PASSAGE HIGH SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700174',
    lea_name: 'LAFAYETTE PUBLIC CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700177',
    lea_name: 'NORTH LAKES ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700178',
    lea_name: 'LACRESCENT MONTESSORI ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700179',
    lea_name: 'NERSTRAND CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700180',
    lea_name: 'ROCHESTER OFF-CAMPUS CHARTER HIGH',
  },
  {
    state_abbr: 'MN',
    leaid: '2700181',
    lea_name: 'EL COLEGIO CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700184',
    lea_name: 'EXCELL ACADEMY CHARTER',
  },
  {
    state_abbr: 'MN',
    leaid: '2700185',
    lea_name: 'ACADEMIA CESAR CHAVEZ CHARTER SCH.',
  },
  {
    state_abbr: 'MN',
    leaid: '2700186',
    lea_name: 'AFSA HIGH SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700187',
    lea_name: 'AVALON SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700188',
    lea_name: 'TWIN CITIES INTERNATIONAL ELEM SCH',
  },
  {
    state_abbr: 'MN',
    leaid: '2700189',
    lea_name: 'MINNESOTA INTERNATIONAL MIDDLE CHTR',
  },
  {
    state_abbr: 'MN',
    leaid: '2700190',
    lea_name: 'FRIENDSHIP ACDMY OF FINE ARTS CHTR.',
  },
  {
    state_abbr: 'MN',
    leaid: '2700191',
    lea_name: 'PILLAGER AREA CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700192',
    lea_name: 'DISCOVERY PUBLIC SCHOOL FARIBAULT',
  },
  {
    state_abbr: 'MN',
    leaid: '2700193',
    lea_name: 'BLUESKY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700194',
    lea_name: 'RIDGEWAY COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700208',
    lea_name: 'NORTH SHORE COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700209',
    lea_name: 'HARBOR CITY INTERNATIONAL CHARTER',
  },
  {
    state_abbr: 'MN',
    leaid: '2700210',
    lea_name: 'WOODSON INSTITUTE FOR EXCELLENCE CH',
  },
  {
    state_abbr: 'MN',
    leaid: '2700211',
    lea_name: 'SAGE ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700212',
    lea_name: 'PRAIRIE CREEK COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700213',
    lea_name: 'WATERSHED HIGH SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700214',
    lea_name: 'NEW CENTURY ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700218',
    lea_name: 'CROSSLAKE COMMUNITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700220',
    lea_name: 'AURORA CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700221',
    lea_name: 'RIVERWAY LEARNING COMMUNITY CHTR',
  },
  {
    state_abbr: 'MN',
    leaid: '2700224',
    lea_name: 'NORTHLAND LEARNING CENTER',
  },
  {
    state_abbr: 'MN',
    leaid: '2700225',
    lea_name: 'SCHOOLCRAFT LEARNING COMMUNITY CHTR',
  },
  {
    state_abbr: 'MN',
    leaid: '2700226',
    lea_name: 'KATO PUBLIC CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700230',
    lea_name: 'HOPE COMMUNITY ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700235',
    lea_name: 'TRIO WOLF CREEK DISTANCE LEARNING',
  },
  {
    state_abbr: 'MN',
    leaid: '2700237',
    lea_name: 'PARTNERSHIP ACADEMY, INC.',
  },
  {
    state_abbr: 'MN',
    leaid: '2700240',
    lea_name: 'PRAIRIE SEEDS ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700241',
    lea_name: 'TEAM ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700245',
    lea_name: 'METRO SCHOOLS CHARTER',
  },
  {
    state_abbr: 'MN',
    leaid: '2700246',
    lea_name: 'TWIN CITIES ACADEMY HIGH SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700247',
    lea_name: 'ROCHESTER MATH AND SCIENCE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700249',
    lea_name: 'SWAN RIVER MONTESSORI CHARTER SCH',
  },
  {
    state_abbr: 'MN',
    leaid: '2700250',
    lea_name: 'MILROY AREA CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700251',
    lea_name: 'PAIDEIA ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700252',
    lea_name: 'STRIDE ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700253',
    lea_name: 'NEW MILLENNIUM ACADEMY CHARTER SCH',
  },
  {
    state_abbr: 'MN',
    leaid: '2700254',
    lea_name: 'GREEN ISLE COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700255',
    lea_name: 'BIRCH GROVE COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700256',
    lea_name: 'NORTHERN LIGHTS COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700260',
    lea_name: 'MINNESOTA ONLINE HIGH SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700261',
    lea_name: 'EDVISIONS OFF CAMPUS SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700262',
    lea_name: 'TWIN CITIES GERMAN IMMERSION CHTR',
  },
  {
    state_abbr: 'MN',
    leaid: '2700263',
    lea_name: 'DUGSI ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700273',
    lea_name: 'LOVEWORKS ACADEMY FOR ARTS',
  },
  {
    state_abbr: 'MN',
    leaid: '2700275',
    lea_name: 'NAYTAHWAUSH COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700276',
    lea_name: 'SPECTRUM HIGH SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700277',
    lea_name: 'NEW DISCOVERIES MONTESSORI ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700278',
    lea_name: 'SOUTHSIDE FAMILY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700281',
    lea_name: 'YINGHUA ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700284',
    lea_name: 'SEVEN HILLS PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700285',
    lea_name: 'LEARNING FOR LEADERSHIP CHARTER',
  },
  {
    state_abbr: 'MN',
    leaid: '2700288',
    lea_name: 'HIAWATHA ACADEMIES',
  },
  {
    state_abbr: 'MN',
    leaid: '2700289',
    lea_name: 'STONEBRIDGE WORLD SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700290',
    lea_name: 'CLARKFIELD CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700291',
    lea_name: 'EAST RANGE ACADEMY OF TECH-SCIENCE',
  },
  {
    state_abbr: 'MN',
    leaid: '2700292',
    lea_name: 'LAURA JEFFREY ACADEMY CHARTER',
  },
  {
    state_abbr: 'MN',
    leaid: '2700293',
    lea_name: 'GLACIAL HILLS ELEMENTARY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700295',
    lea_name: 'NOBLE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700296',
    lea_name: 'INTERNATIONAL SPANISH LANGUAGE ACAD',
  },
  {
    state_abbr: 'MN',
    leaid: '2700299',
    lea_name: 'LINCOLN INTERNATIONAL SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700300',
    lea_name: 'MINISINAAKWAANG LEADERSHIP ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700304',
    lea_name: 'COMMUNITY SCHOOL OF EXCELLENCE',
  },
  {
    state_abbr: 'MN',
    leaid: '2700308',
    lea_name: 'KIPP MINNESOTA CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700309',
    lea_name: 'BEST ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700310',
    lea_name: 'NATURAL SCIENCE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700313',
    lea_name: 'GLOBAL ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700314',
    lea_name: 'RIVERS EDGE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700315',
    lea_name: 'LIONSGATE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700316',
    lea_name: 'BRIGHT WATER ELEMENTARY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700319',
    lea_name: 'COLOGNE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700320',
    lea_name: 'DAVINCI ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700321',
    lea_name: 'OSHKI OGIMAAG CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700324',
    lea_name: 'CANNON RIVER STEM SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700325',
    lea_name: 'COLLEGE PREPARATORY ELEMENTARY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700332',
    lea_name: 'MINNEAPOLIS COLLEGE PREPARATORY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700333',
    lea_name: 'PARNASSUS PREPARATORY CHARTER SCH',
  },
  {
    state_abbr: 'MN',
    leaid: '2700335',
    lea_name: 'HENNEPIN ELEMENTARY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700337',
    lea_name: 'NOVA CLASSICAL ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700339',
    lea_name: 'GREAT EXPECTATIONS',
  },
  {
    state_abbr: 'MN',
    leaid: '2700341',
    lea_name: 'MINNESOTA INTERNSHIP CENTER',
  },
  {
    state_abbr: 'MN',
    leaid: '2700342',
    lea_name: 'HMONG COLLEGE PREP ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700343',
    lea_name: 'PALADIN CAREER AND TECH HIGH SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700344',
    lea_name: 'GREAT RIVER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700345',
    lea_name: 'TREKNORTH HIGH SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700346',
    lea_name: 'VOYAGEURS EXPEDITIONARY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700349',
    lea_name: 'MAIN STREET SCHOOL PERFORMING ARTS',
  },
  {
    state_abbr: 'MN',
    leaid: '2700350',
    lea_name: 'AUGSBURG FAIRVIEW ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700351',
    lea_name: 'ST PAUL CONSERVATORY PERFORMING ART',
  },
  {
    state_abbr: 'MN',
    leaid: '2700352',
    lea_name: 'FRASER ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700353',
    lea_name: 'URBAN ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700355',
    lea_name: 'ARCADIA CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700357',
    lea_name: 'MINNEAPOLIS ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700358',
    lea_name: 'LAKES INTERNATIONAL LANGUAGE ACADEM',
  },
  {
    state_abbr: 'MN',
    leaid: '2700360',
    lea_name: 'KALEIDOSCOPE CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700361',
    lea_name: 'ACADEMIC ARTS HIGH SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700362',
    lea_name: 'ST. CROIX PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700363',
    lea_name: 'UBAH MEDICAL ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700364',
    lea_name: 'EAGLE RIDGE ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700365',
    lea_name: 'DAKOTA AREA COMMUNITY CHARTER SCH',
  },
  {
    state_abbr: 'MN',
    leaid: '2700366',
    lea_name: 'BEACON ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700367',
    lea_name: 'STEP ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700368',
    lea_name: 'CORNERSTONE MONTESSORI ELEMENTARY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700369',
    lea_name: 'DISCOVERY WOODS MONTESSORI SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700370',
    lea_name: 'ROCHESTER STEM ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700372',
    lea_name: 'VERMILION COUNTRY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700373',
    lea_name: 'MASTERY SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700374',
    lea_name: 'WEST SIDE SUMMIT CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700375',
    lea_name: 'WEST CONCORD PUBLIC CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700376',
    lea_name: 'UPPER MISSISSIPPI ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700378',
    lea_name: 'NASHA SHKOLA CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700380',
    lea_name: 'PRODEO ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700384',
    lea_name: 'VENTURE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700386',
    lea_name: 'SEJONG ACADEMY OF MINNESOTA',
  },
  {
    state_abbr: 'MN',
    leaid: '2700387',
    lea_name: 'SOUTHWEST METRO EDUCATIONAL COOP',
  },
  {
    state_abbr: 'MN',
    leaid: '2700389',
    lea_name: 'TECHNICAL ACADEMIES OF MINNESOTA',
  },
  {
    state_abbr: 'MN',
    leaid: '2700390',
    lea_name: 'STAR OF THE NORTH ACADEMY CHARTER S',
  },
  {
    state_abbr: 'MN',
    leaid: '2700393',
    lea_name: 'UNIVERSAL ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2700395',
    lea_name: 'SAINT CLOUD MATH AND SCIENCE ACADEM',
  },
  {
    state_abbr: 'MN',
    leaid: '2700396',
    lea_name: 'BDOTE LEARNING CENTER',
  },
  {
    state_abbr: 'MN',
    leaid: '2700397',
    lea_name: 'ART AND SCIENCE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700398',
    lea_name: 'NORTHEAST COLLEGE PREP',
  },
  {
    state_abbr: 'MN',
    leaid: '2700399',
    lea_name: 'MINNESOTA MATH AND SCIENCE ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700401',
    lea_name: 'WOODBURY LEADERSHIP ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2700411',
    lea_name: 'CROSSWINDS ARTS AND SCIENCE SCHOOL',
  },
  {
    state_abbr: 'MN',
    leaid: '2702264',
    lea_name: 'NORTHEAST METRO 916',
  },
  {
    state_abbr: 'MN',
    leaid: '2705850',
    lea_name: 'BLUFFVIEW MONTESSORI',
  },
  {
    state_abbr: 'MN',
    leaid: '2708955',
    lea_name: 'CITY ACADEMY',
  },
  {
    state_abbr: 'MN',
    leaid: '2723380',
    lea_name: 'NEW HEIGHTS SCHOOL, INC.',
  },
  {
    state_abbr: 'MN',
    leaid: '2791353',
    lea_name: 'RIVER BEND EDUCATION DISTRICT',
  },
  {
    state_abbr: 'MN',
    leaid: '2791443',
    lea_name: 'GOODHUE COUNTY EDUCATION DISTRICT',
  },
  {
    state_abbr: 'MN',
    leaid: '2791455',
    lea_name: 'WEST CENTRAL EDUCATION DISTRICT',
  },
  {
    state_abbr: 'MN',
    leaid: '2791456',
    lea_name: 'PERPICH CENTER FOR ARTS EDUCATION',
  },
  {
    state_abbr: 'MO',
    leaid: '2900009',
    lea_name: 'DIVISION OF YOUTH SERVICE',
  },
  {
    state_abbr: 'MO',
    leaid: '2900011',
    lea_name: 'B. BANNEKER ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900013',
    lea_name: 'DELLA LAMB ELEM.',
  },
  {
    state_abbr: 'MO',
    leaid: '2900014',
    lea_name: 'HOGAN PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900015',
    lea_name: 'GORDON PARKS ELEM.',
  },
  {
    state_abbr: 'MO',
    leaid: '2900016',
    lea_name: 'GENESIS SCHOOL INC.',
  },
  {
    state_abbr: 'MO',
    leaid: '2900017',
    lea_name: 'ACADEMIE LAFAYETTE',
  },
  {
    state_abbr: 'MO',
    leaid: '2900019',
    lea_name: 'SCUOLA VITA NUOVA',
  },
  {
    state_abbr: 'MO',
    leaid: '2900020',
    lea_name: 'MO SCHOOL FOR THE DEAF',
  },
  {
    state_abbr: 'MO',
    leaid: '2900021',
    lea_name: 'MO SCHOOL FOR THE BLIND',
  },
  {
    state_abbr: 'MO',
    leaid: '2900022',
    lea_name: 'MO SCHLS FOR THE SEV DISABLED',
  },
  {
    state_abbr: 'MO',
    leaid: '2900024',
    lea_name: 'BROOKSIDE CHARTER SCH.',
  },
  {
    state_abbr: 'MO',
    leaid: '2900025',
    lea_name: 'ALLEN VILLAGE',
  },
  {
    state_abbr: 'MO',
    leaid: '2900027',
    lea_name: 'UNIVERSITY ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900028',
    lea_name: 'LEE A. TOLBERT COM. ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900029',
    lea_name: 'ALTA VISTA CHARTER SCH.',
  },
  {
    state_abbr: 'MO',
    leaid: '2900031',
    lea_name: 'KIPP: ENDEAVOR ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900574',
    lea_name: 'LIFT FOR LIFE ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900576',
    lea_name: 'PREMIER CHARTER SCHOOL',
  },
  {
    state_abbr: 'MO',
    leaid: '2900579',
    lea_name: 'CONFLUENCE ACADEMIES',
  },
  {
    state_abbr: 'MO',
    leaid: '2900583',
    lea_name: 'CONSTRUCTION CAREERS CENTER',
  },
  {
    state_abbr: 'MO',
    leaid: '2900585',
    lea_name: 'CITY GARDEN MONTESSORI',
  },
  {
    state_abbr: 'MO',
    leaid: '2900586',
    lea_name: 'ST LOUIS LANG IMMERSION SCHOOL',
  },
  {
    state_abbr: 'MO',
    leaid: '2900587',
    lea_name: 'PATHWAY ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900589',
    lea_name: 'NORTH SIDE COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'MO',
    leaid: '2900590',
    lea_name: 'FRONTIER SCHOOL OF INNOVATION',
  },
  {
    state_abbr: 'MO',
    leaid: '2900591',
    lea_name: 'KIPP ST LOUIS',
  },
  {
    state_abbr: 'MO',
    leaid: '2900592',
    lea_name: 'GATEWAY SCIENCE ACAD/ST LOUIS',
  },
  {
    state_abbr: 'MO',
    leaid: '2900593',
    lea_name: 'DELASALLE CHARTER SCHOOL',
  },
  {
    state_abbr: 'MO',
    leaid: '2900594',
    lea_name: 'CARONDELET LEADERSHIP ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900595',
    lea_name: 'GRAND CENTER ARTS ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900597',
    lea_name: 'EWING MARION KAUFFMAN SCHOOL',
  },
  {
    state_abbr: 'MO',
    leaid: '2900598',
    lea_name: 'PRECLARUS MASTERY ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900599',
    lea_name: 'BETTER LEARNING COMM ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900600',
    lea_name: 'SOUTH CITY PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900601',
    lea_name: 'HOPE LEADERSHIP ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900602',
    lea_name: 'JAMAA LEARNING CENTER',
  },
  {
    state_abbr: 'MO',
    leaid: '2900603',
    lea_name: 'CROSSROADS ACAD OF KANSAS CITY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900604',
    lea_name: 'ACADEMY FOR INTEGRATED ARTS',
  },
  {
    state_abbr: 'MO',
    leaid: '2900605',
    lea_name: 'EAGLE COLLEGE PREP ENDEAVOR',
  },
  {
    state_abbr: 'MO',
    leaid: '2900606',
    lea_name: 'LAFAYETTE PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'MO',
    leaid: '2900607',
    lea_name: 'MO VIRTUAL INSTRUCTION PROGRAM',
  },
  {
    state_abbr: 'MO',
    leaid: '2923780',
    lea_name: 'PEMISCOT CO. SPEC. SCH. DIST.',
  },
  {
    state_abbr: 'MO',
    leaid: '2926760',
    lea_name: 'SPECL. SCH. DST. ST. LOUIS CO.',
  },
  {
    state_abbr: 'MS',
    leaid: '2801100',
    lea_name: 'COAHOMA CO AHS',
  },
  {
    state_abbr: 'MS',
    leaid: '2800001',
    lea_name: 'MISSISSIPPI STATE HOSPITAL',
  },
  {
    state_abbr: 'MS',
    leaid: '2800003',
    lea_name: 'MAGNOLIA GROVE SCHOOL',
  },
  {
    state_abbr: 'MS',
    leaid: '2800031',
    lea_name: 'MS SCHOOL FOR THE ARTS',
  },
  {
    state_abbr: 'MS',
    leaid: '2800183',
    lea_name: 'ROGER AMOS MCMURTRY ADM',
  },
  {
    state_abbr: 'MS',
    leaid: '2800185',
    lea_name: 'WEST BOLIVAR CONS SCH',
  },
  {
    state_abbr: 'MS',
    leaid: '2800186',
    lea_name: 'NORTH BOLIVAR CONS SCH',
  },
  {
    state_abbr: 'MS',
    leaid: '2800187',
    lea_name: 'SUNFLOWER CO CONSOLIDATE SCH DIST',
  },
  {
    state_abbr: 'MS',
    leaid: '2801189',
    lea_name: 'MS SCH FOR THE BLIND',
  },
  {
    state_abbr: 'MS',
    leaid: '2801190',
    lea_name: 'MS SCHOOL FOR THE DEAF',
  },
  {
    state_abbr: 'MS',
    leaid: '2801191',
    lea_name: 'MDHS DIVISION OF YOUTH SERVICES',
  },
  {
    state_abbr: 'MS',
    leaid: '2801192',
    lea_name: 'ELLISVILLE STATE SCHOOL ADM',
  },
  {
    state_abbr: 'MS',
    leaid: '2801193',
    lea_name: 'STOVALL SPED COMPLEX ADMINISTRATION',
  },
  {
    state_abbr: 'MS',
    leaid: '2801194',
    lea_name: 'MS SCH FOR MATH AND SC',
  },
  {
    state_abbr: 'MS',
    leaid: '2801196',
    lea_name: 'HUDSPETH CENTER ADMINISTRATION',
  },
  {
    state_abbr: 'MS',
    leaid: '2801510',
    lea_name: 'FORREST COUNTY AG HIGH SCHOOL',
  },
  {
    state_abbr: 'MT',
    leaid: '3000091',
    lea_name: 'Dept of Corrections-Youth',
  },
  {
    state_abbr: 'MT',
    leaid: '3000100',
    lea_name: 'Mont Sch for Deaf Blind',
  },
  {
    state_abbr: 'NC',
    leaid: '3700055',
    lea_name: 'Kestrel Heights School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700062',
    lea_name: 'Lincoln Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700063',
    lea_name: 'Kennedy Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700064',
    lea_name: 'Lake Norman Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700068',
    lea_name: 'Brevard Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700069',
    lea_name: 'Franklin Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700070',
    lea_name: 'East Wake Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700074',
    lea_name: 'Dillard Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700077',
    lea_name: 'Williams Academy (fka Crossnore)',
  },
  {
    state_abbr: 'NC',
    leaid: '3700078',
    lea_name: 'Evergreen Community Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700016',
    lea_name: 'Grandfather Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700017',
    lea_name: 'Francine Delany New School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700020',
    lea_name: 'Chatham Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700021',
    lea_name: 'The Learning Center',
  },
  {
    state_abbr: 'NC',
    leaid: '3700022',
    lea_name: 'Maureen Joy Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700023',
    lea_name: 'Healthy Start Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700025',
    lea_name: 'Quality Education Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700027',
    lea_name: 'Carter G Woodson School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700029',
    lea_name: 'Summit Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700031',
    lea_name: "Children's Village Academy",
  },
  {
    state_abbr: 'NC',
    leaid: '3700032',
    lea_name: 'Community Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700033',
    lea_name: 'The Academy of Moore County',
  },
  {
    state_abbr: 'NC',
    leaid: '3700034',
    lea_name: 'Rocky Mount Preparatory',
  },
  {
    state_abbr: 'NC',
    leaid: '3700035',
    lea_name: 'Orange Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700038',
    lea_name: 'Arapahoe Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700040',
    lea_name: 'CIS Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700041',
    lea_name: 'Bridges Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700043',
    lea_name: 'Exploris Middle School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700045',
    lea_name: 'Magellan Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700046',
    lea_name: 'Sterling Montessori Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700049',
    lea_name: 'Sallie B Howard School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700050',
    lea_name: 'River Mill Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700052',
    lea_name: 'Tiller School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700053',
    lea_name: 'Woods Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700054',
    lea_name: 'Carter Community Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700081',
    lea_name: 'Research Triangle Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700083',
    lea_name: 'Forsyth Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700084',
    lea_name: 'Greensboro Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700085',
    lea_name: 'The Mountain Community Sch',
  },
  {
    state_abbr: 'NC',
    leaid: '3700086',
    lea_name: 'American Renaissance School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700089',
    lea_name: 'Sugar Creek Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700091',
    lea_name: 'Sandhills Theatre Arts Renaiss',
  },
  {
    state_abbr: 'NC',
    leaid: '3700093',
    lea_name: 'Thomas Jefferson Classical Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700096',
    lea_name: 'Vance Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700097',
    lea_name: 'Raleigh Charter High School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700098',
    lea_name: 'Torchlight Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700099',
    lea_name: 'Quest Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700101',
    lea_name: 'Washington Montessori',
  },
  {
    state_abbr: 'NC',
    leaid: '3700102',
    lea_name: 'Charter Day School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700103',
    lea_name: 'Alpha Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700104',
    lea_name: 'Piedmont Community Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700105',
    lea_name: 'Phoenix Academy Inc',
  },
  {
    state_abbr: 'NC',
    leaid: '3700106',
    lea_name: 'Success Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700107',
    lea_name: 'Metrolina Regional Scholars Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700108',
    lea_name: 'Cape Fear Center for Inquiry',
  },
  {
    state_abbr: 'NC',
    leaid: '3700109',
    lea_name: 'Bethel Hill Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700110',
    lea_name: 'Bethany Community Middle',
  },
  {
    state_abbr: 'NC',
    leaid: '3700111',
    lea_name: 'Millennium Charter Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700112',
    lea_name: 'Union Academy Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700113',
    lea_name: 'PreEminent Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700114',
    lea_name: 'Southern Wake Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700115',
    lea_name: 'Haliwa-Saponi Tribal School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700116',
    lea_name: 'Clover Garden',
  },
  {
    state_abbr: 'NC',
    leaid: '3700117',
    lea_name: 'ArtSpace Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700118',
    lea_name: 'The New Dimensions School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700119',
    lea_name: 'Crosscreek Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700121',
    lea_name: 'Guilford Preparatory Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700122',
    lea_name: 'Crossroads Charter High',
  },
  {
    state_abbr: 'NC',
    leaid: '3700123',
    lea_name: 'Gaston College Preparatory',
  },
  {
    state_abbr: 'NC',
    leaid: '3700124',
    lea_name: 'Hope Elementary Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700126',
    lea_name: 'Arts Based School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700127',
    lea_name: 'Queens Grant Community School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700128',
    lea_name: 'Gray Stone Day School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700129',
    lea_name: 'Mountain Discovery Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700130',
    lea_name: 'Central Park School For Children',
  },
  {
    state_abbr: 'NC',
    leaid: '3700131',
    lea_name: 'Casa Esperanza Montessori',
  },
  {
    state_abbr: 'NC',
    leaid: '3700132',
    lea_name: 'The Hawbridge School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700133',
    lea_name: 'Carolina International School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700135',
    lea_name: 'Community School of Davidson',
  },
  {
    state_abbr: 'NC',
    leaid: '3700136',
    lea_name: 'PACE Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700137',
    lea_name: 'Socrates Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700138',
    lea_name: 'Two Rivers Community School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700139',
    lea_name: 'Voyager Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700140',
    lea_name: 'Columbus Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700141',
    lea_name: 'Wilmington Preparatory Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700142',
    lea_name: 'KIPP: Charlotte',
  },
  {
    state_abbr: 'NC',
    leaid: '3700143',
    lea_name: 'Charlotte Secondary School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700144',
    lea_name: 'Neuse Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700145',
    lea_name: 'Pine Lake Preparatory',
  },
  {
    state_abbr: 'NC',
    leaid: '3700175',
    lea_name: 'Roxboro Community School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700314',
    lea_name: 'Endeavor Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700315',
    lea_name: 'Triad Math and Science Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700316',
    lea_name: 'Global Scholars Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700317',
    lea_name: 'Mountain Island Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700318',
    lea_name: 'Lake Lure Classical Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700319',
    lea_name: 'Henderson Collegiate',
  },
  {
    state_abbr: 'NC',
    leaid: '3700320',
    lea_name: 'Deaf and Blind Schools',
  },
  {
    state_abbr: 'NC',
    leaid: '3700321',
    lea_name: 'Northeast Regional School - Biotech/Agri',
  },
  {
    state_abbr: 'NC',
    leaid: '3700322',
    lea_name: 'Bear Grass Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700323',
    lea_name: 'North East Carolina Preparatory School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700324',
    lea_name: 'Triangle Math and Science Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700325',
    lea_name: 'The College Preparatory and Leadership A',
  },
  {
    state_abbr: 'NC',
    leaid: '3700326',
    lea_name: 'Research Triangle High School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700327',
    lea_name: 'Corvian Community School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700328',
    lea_name: 'Cornerstone Charter Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700329',
    lea_name: "Water's Edge Village School",
  },
  {
    state_abbr: 'NC',
    leaid: '3700331',
    lea_name: 'Langtree Charter Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700332',
    lea_name: 'Island Montessori Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700333',
    lea_name: 'Invest Collegiate',
  },
  {
    state_abbr: 'NC',
    leaid: '3700334',
    lea_name: 'Uwharrie Charter Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700335',
    lea_name: 'STEM Education for a Global Society-SEGS',
  },
  {
    state_abbr: 'NC',
    leaid: '3700336',
    lea_name: 'Falls Lake Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700337',
    lea_name: 'Longleaf School of the Arts',
  },
  {
    state_abbr: 'NC',
    leaid: '3700338',
    lea_name: 'Oxford Preparatory High School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700339',
    lea_name: 'Charlotte Choice Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700340',
    lea_name: 'Douglass Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700341',
    lea_name: 'Willow Oak Montessori',
  },
  {
    state_abbr: 'NC',
    leaid: '3700343',
    lea_name: 'The North Carolina Leadership Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700344',
    lea_name: 'Summerfield Charter Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700346',
    lea_name: 'Southeastern Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700347',
    lea_name: 'Cabarrus Charter Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700348',
    lea_name: 'Z.E.C.A. School of Arts and Technology',
  },
  {
    state_abbr: 'NC',
    leaid: '3700349',
    lea_name: 'Flemington Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700350',
    lea_name: 'Aristotle Preparatory Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700351',
    lea_name: 'Paul R. Brown Leadership Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700352',
    lea_name: 'The Institute for the Development of You',
  },
  {
    state_abbr: 'NC',
    leaid: '3700353',
    lea_name: 'Pinnacle Classical Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700354',
    lea_name: 'The Expedition School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700356',
    lea_name: 'Bradford Preparatory School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700357',
    lea_name: 'Charlotte Learning Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700358',
    lea_name: 'Cardinal Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700359',
    lea_name: 'Pioneer Springs Community School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700361',
    lea_name: 'The Franklin School of Innovation',
  },
  {
    state_abbr: 'NC',
    leaid: '3700362',
    lea_name: 'Envision Science Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700363',
    lea_name: 'Reaching All Minds Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700364',
    lea_name: 'Wake Forest Charter Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700365',
    lea_name: 'Heritage Collegiate Leadership Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700366',
    lea_name: 'Entrepreneur High School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700367',
    lea_name: 'United Community School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700368',
    lea_name: 'Dynamic Community Charter',
  },
  {
    state_abbr: 'NC',
    leaid: '3700369',
    lea_name: 'Invest Collegiate - Imagine',
  },
  {
    state_abbr: 'NC',
    leaid: '3700370',
    lea_name: 'The Capitol Encore Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700371',
    lea_name: 'Thunderbird Preparatory School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700372',
    lea_name: 'A.C.E. Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700375',
    lea_name: 'KIPP Halifax College Preparatory',
  },
  {
    state_abbr: 'NC',
    leaid: '3700377',
    lea_name: 'Wayne Preparatory',
  },
  {
    state_abbr: 'NC',
    leaid: '3700378',
    lea_name: 'South Brunswick Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700379',
    lea_name: 'Wilson Preparatory Academy',
  },
  {
    state_abbr: 'NC',
    leaid: '3700381',
    lea_name: 'Anderson Creek Club Charter School',
  },
  {
    state_abbr: 'NC',
    leaid: '3700382',
    lea_name: 'Commonwealth High School',
  },
  {
    state_abbr: 'ND',
    leaid: '3800005',
    lea_name: 'MARMOT SCHOOLS',
  },
  {
    state_abbr: 'ND',
    leaid: '3800006',
    lea_name: 'ND SCHOOL FOR THE DEAF',
  },
  {
    state_abbr: 'ND',
    leaid: '3800053',
    lea_name: 'CARRINGTON 49',
  },
  {
    state_abbr: 'NE',
    leaid: '3100046',
    lea_name: 'KEARNEY WEST HIGH SCHOOL',
  },
  {
    state_abbr: 'NE',
    leaid: '3100047',
    lea_name: 'PINE RIDGE JOB CORPS',
  },
  {
    state_abbr: 'NE',
    leaid: '3100051',
    lea_name: 'GENEVA NORTH SCHOOL',
  },
  {
    state_abbr: 'NE',
    leaid: '3100059',
    lea_name: 'UNIVERSITY OF NEBRASKA HIGH SCHOOL',
  },
  {
    state_abbr: 'NE',
    leaid: '3100177',
    lea_name: 'NE CORRECTIONAL YOUTH FACILITY',
  },
  {
    state_abbr: 'NE',
    leaid: '3100184',
    lea_name: 'RIVERSIDE PUBLIC SCHOOLS',
  },
  {
    state_abbr: 'NE',
    leaid: '3100185',
    lea_name: 'CENTRAL VALLEY PUBLIC SCHOOLS',
  },
  {
    state_abbr: 'NH',
    leaid: '3303279',
    lea_name: "Hale's Location District",
  },
  {
    state_abbr: 'NJ',
    leaid: '3400059',
    lea_name: 'Galloway Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400014',
    lea_name: 'Englewood on the Palisades Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400015',
    lea_name: 'Teaneck Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400017',
    lea_name: "Camden's Promise Chartr School",
  },
  {
    state_abbr: 'NJ',
    leaid: '3400019',
    lea_name: 'chARTer~TECH High School for the Performing Arts',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400020',
    lea_name: 'Discovery Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400021',
    lea_name: 'EAST ORANGE COMMUNITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400022',
    lea_name: 'Marion P. Thomas Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400023',
    lea_name: 'New Horizons Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400024',
    lea_name: 'North Star Academy Charter School of Newark',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400025',
    lea_name: 'Robert Treat Academy Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400026',
    lea_name: 'Maria Varisco Rogers Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400029',
    lea_name: 'Elysian Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400032',
    lea_name: 'Hoboken Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400033',
    lea_name: 'Jersey City Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400034',
    lea_name: 'Jersey City Golden Door Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400035',
    lea_name: 'The Learning Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400036',
    lea_name: 'Soaraing Heights Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400041',
    lea_name: 'International Charter School of Trenton',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400042',
    lea_name: 'Hamilton School DIstrict - Pace Charter School of Hamilton',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400043',
    lea_name: 'Princeton Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400046',
    lea_name: 'The Village Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400047',
    lea_name: 'Greater Brunswick Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400048',
    lea_name: 'Academy Charter High School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400049',
    lea_name: 'Red Bank Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400051',
    lea_name: 'Unity Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400052',
    lea_name: 'Classical Academy Charter School of Clifton',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400055',
    lea_name: 'Sussex County Charter School for Technology School District',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400061',
    lea_name: 'The Queen City Academy Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400064',
    lea_name: 'Gray Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400070',
    lea_name: 'TEAM Academy Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400071',
    lea_name: 'Camden Academy Charter High School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400073',
    lea_name: 'University Academy Charter High School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400075',
    lea_name: 'Central Jersey Arts Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400076',
    lea_name: 'Hope Academy Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400077',
    lea_name: 'Lady Liberty Academy Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400078',
    lea_name: 'LEAP Academy University Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400079',
    lea_name: 'Environment Community Opportunity (ECO) Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400080',
    lea_name: 'Freedom Prep Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400082',
    lea_name: 'Ridge and Valley Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400083',
    lea_name: 'Union County TEAMS Charter School-High School/College LA',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400084',
    lea_name: 'University Heights Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400087',
    lea_name: 'Paterson Charter School for Science and Technology',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400090',
    lea_name: 'CENTRAL JERSEY COLLEGE PREP CHARTER SCHOOL',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400715',
    lea_name: 'Bergen Arts and Sciences Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400717',
    lea_name: 'Foundation Academy Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400718',
    lea_name: 'Community Charter School of Paterson',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400719',
    lea_name: 'Pride Academy Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400721',
    lea_name: "Camden's Pride Charter School",
  },
  {
    state_abbr: 'NJ',
    leaid: '3400723',
    lea_name: 'Paul Robeson Charter School for the Humanities',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400724',
    lea_name: 'Burch Charter School of Excellence',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400727',
    lea_name: 'The Ethical Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400729',
    lea_name: 'Vineland Public Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400730',
    lea_name: 'Newark Educators Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400731',
    lea_name: 'Riverbank Charter School of Excellence',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400734',
    lea_name: 'Hoboken Dual Language Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400735',
    lea_name: 'Hatikvah International Academy Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400736',
    lea_name: 'Newark Legacy Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400740',
    lea_name: 'The Barack Obama Green Charter High',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400741',
    lea_name: 'Academy for Urban Leadership Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400744',
    lea_name: 'Benjamin Banneker Preparatory Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400745',
    lea_name: 'Passaic Arts and Science Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400746',
    lea_name: 'Thomas Edison EnergySmart Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400748',
    lea_name: 'John P Holland Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400749',
    lea_name: 'Millville Public Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400751',
    lea_name: 'Great Oaks Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400752',
    lea_name: 'The Kingdom Charter School of Leadership',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400753',
    lea_name: 'M.E.T.S. CHARTER SCHOOL',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400754',
    lea_name: "People's Preparatory Charter School",
  },
  {
    state_abbr: 'NJ',
    leaid: '3400755',
    lea_name: 'ROSEVILLE COMMUNITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400756',
    lea_name: 'Dr Lena Edwards Academic Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400757',
    lea_name: 'Paulo Freire Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400759',
    lea_name: 'BelovED Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400760',
    lea_name: 'Merit Preparatory Charter School of Newark',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400762',
    lea_name: 'Knowledge A to Z Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400763',
    lea_name: 'Hope Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400764',
    lea_name: "Phillip's Academy  Charter School",
  },
  {
    state_abbr: 'NJ',
    leaid: '3400765',
    lea_name: 'Paterson Arts and Science Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400766',
    lea_name: 'Compass Academy Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400767',
    lea_name: 'Jersey City Global Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400768',
    lea_name: 'Camden Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400770',
    lea_name: 'Trenton Stem-to-Civics Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400771',
    lea_name: 'Atlantic City Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400773',
    lea_name: 'Link Community Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400774',
    lea_name: 'Great Futures Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3400775',
    lea_name: 'International Academy of Trenton Charter School',
  },
  {
    state_abbr: 'NJ',
    leaid: '3402730',
    lea_name: 'Cape May County Technical High School District',
  },
  {
    state_abbr: 'NJ',
    leaid: '3410080',
    lea_name: 'Middlesex County Vocational and Technical School District',
  },
  {
    state_abbr: 'NJ',
    leaid: '3480361',
    lea_name: 'Salem County Special Services School District',
  },
  {
    state_abbr: 'NM',
    leaid: '3500015',
    lea_name: 'SEQUOYAH',
  },
  {
    state_abbr: 'NM',
    leaid: '3500134',
    lea_name: 'LA PROMESA EARLY LEARNING',
  },
  {
    state_abbr: 'NM',
    leaid: '3500011',
    lea_name: 'JUVENILE JUSTICE',
  },
  {
    state_abbr: 'NM',
    leaid: '3500012',
    lea_name: 'NM CORRECTIONS',
  },
  {
    state_abbr: 'NM',
    leaid: '3500013',
    lea_name: 'NM SCHOOL FOR THE BLIND AND VISUALLY IMPAIRED',
  },
  {
    state_abbr: 'NM',
    leaid: '3500014',
    lea_name: 'NM SCHOOL FOR THE DEAF',
  },
  {
    state_abbr: 'NM',
    leaid: '3500016',
    lea_name: 'CHILDRENS PSYC',
  },
  {
    state_abbr: 'NM',
    leaid: '3500106',
    lea_name: 'NORTH VALLEY CHARTER',
  },
  {
    state_abbr: 'NM',
    leaid: '3500107',
    lea_name: 'HORIZON ACADEMY WEST',
  },
  {
    state_abbr: 'NM',
    leaid: '3500108',
    lea_name: 'COTTONWOOD CLASSICAL PREP',
  },
  {
    state_abbr: 'NM',
    leaid: '3500109',
    lea_name: 'MEDIA ARTS CHARTER',
  },
  {
    state_abbr: 'NM',
    leaid: '3500110',
    lea_name: 'NEW AMERICA SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500111',
    lea_name: 'GILBERT L SENA CHARTER HS',
  },
  {
    state_abbr: 'NM',
    leaid: '3500112',
    lea_name: 'SCHOOL OF DREAMS ACADEMY',
  },
  {
    state_abbr: 'NM',
    leaid: '3500113',
    lea_name: 'INTERNATIONAL SCHOOL AT MESA DEL SOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500114',
    lea_name: 'CREATIVE ED PREP #1',
  },
  {
    state_abbr: 'NM',
    leaid: '3500115',
    lea_name: 'CIEN AGUAS INTERNATIONAL  SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500116',
    lea_name: "ALMA D'ARTE CHARTER",
  },
  {
    state_abbr: 'NM',
    leaid: '3500117',
    lea_name: 'CESAR CHAVEZ COMMUNITY  SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500118',
    lea_name: 'TAOS ACADEMY',
  },
  {
    state_abbr: 'NM',
    leaid: '3500119',
    lea_name: 'NM SCHOOL FOR ARTS',
  },
  {
    state_abbr: 'NM',
    leaid: '3500121',
    lea_name: 'SOUTH VALLEY PREP',
  },
  {
    state_abbr: 'NM',
    leaid: '3500122',
    lea_name: 'ABQ SCHOOL OF EXCELLENCE',
  },
  {
    state_abbr: 'NM',
    leaid: '3500123',
    lea_name: 'ABQ SIGN LANGUAGE ACADEMY',
  },
  {
    state_abbr: 'NM',
    leaid: '3500124',
    lea_name: 'TIERRA ADENTRO',
  },
  {
    state_abbr: 'NM',
    leaid: '3500125',
    lea_name: 'THE MASTER PROGRAM',
  },
  {
    state_abbr: 'NM',
    leaid: '3500126',
    lea_name: 'THE ASK ACADEMY',
  },
  {
    state_abbr: 'NM',
    leaid: '3500127',
    lea_name: 'TAOS INTEGRATED SCHOOL OF THE ARTS',
  },
  {
    state_abbr: 'NM',
    leaid: '3500128',
    lea_name: 'ACE LEADERSHIP HIGH SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500129',
    lea_name: 'ACADEMY OF TRADES AND TECH',
  },
  {
    state_abbr: 'NM',
    leaid: '3500130',
    lea_name: 'ALBUQUERQUE INSTITUTE OF MATH & SCIENCE',
  },
  {
    state_abbr: 'NM',
    leaid: '3500131',
    lea_name: 'AMY BIEHL CHARTER HIGH SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500132',
    lea_name: 'EAST MTN HIGH SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500133',
    lea_name: 'SOUTHWEST INTERMEDIATE LEARNING CENTER',
  },
  {
    state_abbr: 'NM',
    leaid: '3500135',
    lea_name: 'MONTESSORI ELEMENTARY SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500136',
    lea_name: 'SOUTHWEST PRIMARY LEARNING CENTER',
  },
  {
    state_abbr: 'NM',
    leaid: '3500137',
    lea_name: 'SOUTHWEST SECONDARY LEARNING CENTER',
  },
  {
    state_abbr: 'NM',
    leaid: '3500138',
    lea_name: 'ALDO LEOPOLD CHARTER',
  },
  {
    state_abbr: 'NM',
    leaid: '3500140',
    lea_name: 'NEW MEXICO INTERNATIONAL SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500141',
    lea_name: 'J PAUL TAYLOR ACADEMY',
  },
  {
    state_abbr: 'NM',
    leaid: '3500142',
    lea_name: 'THE GREAT ACADEMY',
  },
  {
    state_abbr: 'NM',
    leaid: '3500143',
    lea_name: 'RED RIVER VALLEY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500145',
    lea_name: 'LA RESOLANA LEADERSHIP',
  },
  {
    state_abbr: 'NM',
    leaid: '3500147',
    lea_name: 'UPLIFT COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500148',
    lea_name: 'MISSION ACHIEVEMENT AND SUCCESS',
  },
  {
    state_abbr: 'NM',
    leaid: '3500149',
    lea_name: 'WILLIAM W &  JOSEPHINE DORN CHARTER  COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500151',
    lea_name: 'SW AERONAUTICS MATHEMATICS AND SCIENCE ACADEMY',
  },
  {
    state_abbr: 'NM',
    leaid: '3500152',
    lea_name: 'ESTANCIA VALLEY CLASSICAL ACADEMY',
  },
  {
    state_abbr: 'NM',
    leaid: '3500153',
    lea_name: 'MCCURDY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500154',
    lea_name: 'CORAL COMMUNITY CHARTER',
  },
  {
    state_abbr: 'NM',
    leaid: '3500155',
    lea_name: 'LA TIERRA MONTESSORI SCHOOL OF THE ARTS AND SCIENCES',
  },
  {
    state_abbr: 'NM',
    leaid: '3500156',
    lea_name: 'NEW AMERICA SCHOOL - LAS CRUCES',
  },
  {
    state_abbr: 'NM',
    leaid: '3500157',
    lea_name: 'SAGE MONTESSORI CHARTER SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500158',
    lea_name: 'WALATOWA CHARTER HIGH',
  },
  {
    state_abbr: 'NM',
    leaid: '3500159',
    lea_name: 'LA JICARITA COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500160',
    lea_name: 'NEW MEXICO CONNECTIONS ACADEMY',
  },
  {
    state_abbr: 'NM',
    leaid: '3500161',
    lea_name: 'ANTHONY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500162',
    lea_name: 'HEALTH LEADERSHIP HIGH SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500163',
    lea_name: 'TAOS INTERNATIONAL SCHOOL',
  },
  {
    state_abbr: 'NM',
    leaid: '3500164',
    lea_name: 'DREAM DINE',
  },
  {
    state_abbr: 'NM',
    leaid: '3500165',
    lea_name: 'HEALTH SCIENCES ACADEMY',
  },
  {
    state_abbr: 'NM',
    leaid: '3500166',
    lea_name: 'LA ACADEMIA DOLORES HUERTA',
  },
  {
    state_abbr: 'NM',
    leaid: '3500167',
    lea_name: 'EXPLORE ACADEMY',
  },
  {
    state_abbr: 'NV',
    leaid: '3200001',
    lea_name: 'STATE-SPONSORED CHARTER SCHOOLS',
  },
  {
    state_abbr: 'NV',
    leaid: '3200021',
    lea_name: 'DAVIDSON ACADEMY  SCHOOL DISTRICT',
  },
  {
    state_abbr: 'NY',
    leaid: '3600063',
    lea_name: 'BRONX CHARTER SCHOOL FOR THE ARTS',
  },
  {
    state_abbr: 'NY',
    leaid: '3600066',
    lea_name: 'FAMILY LIFE ACADEMY CHARTER SCHOOL I',
  },
  {
    state_abbr: 'NY',
    leaid: '3600067',
    lea_name: 'GLOBAL CONCEPTS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600025',
    lea_name: 'CHARTER SCHOOL FOR APPLIED TECHNOLOGIES',
  },
  {
    state_abbr: 'NY',
    leaid: '3600026',
    lea_name: 'EUGENIO MARIA DE HOSTOS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600029',
    lea_name: 'GENESEE COMMUNITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600030',
    lea_name: "ROOSEVELT CHILDREN'S ACADEMY CHARTER SCHOOL",
  },
  {
    state_abbr: 'NY',
    leaid: '3600032',
    lea_name: 'BRIGHTER CHOICE CHARTER SCHOOL FOR BOYS',
  },
  {
    state_abbr: 'NY',
    leaid: '3600033',
    lea_name: 'BRIGHTER CHOICE CHARTER SCHOOL FOR GIRLS',
  },
  {
    state_abbr: 'NY',
    leaid: '3600035',
    lea_name: 'KING CENTER CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600036',
    lea_name: 'SOUTH BUFFALO CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600038',
    lea_name: 'TAPESTRY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600039',
    lea_name: 'AMBER CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600041',
    lea_name: 'BEGINNING WITH CHILDREN CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600042',
    lea_name: 'BRONX PREPARATORY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600044',
    lea_name: 'CHILD DEVELOPMENT CENTER OF THE HAMPTONS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600045',
    lea_name: 'BROOKLYN CHARTER SCHOOL (THE)',
  },
  {
    state_abbr: 'NY',
    leaid: '3600046',
    lea_name: 'COMMUNITY PARTNERSHIP CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600047',
    lea_name: 'HARBOR SCIENCE AND ARTS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600048',
    lea_name: 'HARRIET TUBMAN CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600049',
    lea_name: 'ICAHN CHARTER SCHOOL 1',
  },
  {
    state_abbr: 'NY',
    leaid: '3600053',
    lea_name: 'JOHN V LINDSAY WILDCAT ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600054',
    lea_name: 'KIPP ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600055',
    lea_name: 'MERRICK ACADEMY-QUEENS PUBLIC CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600057',
    lea_name: 'OUR WORLD NEIGHBORHOOD CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600059',
    lea_name: 'RENAISSANCE CHARTER SCHOOL (THE)',
  },
  {
    state_abbr: 'NY',
    leaid: '3600060',
    lea_name: 'RIVERHEAD CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600061',
    lea_name: 'SISULU-WALKER CHARTER SCHOOL OF HARLEM',
  },
  {
    state_abbr: 'NY',
    leaid: '3600068',
    lea_name: 'HARLEM PREP CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600070',
    lea_name: 'SOUTHSIDE ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600071',
    lea_name: 'BUFFALO UNITED CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600073',
    lea_name: 'ENTERPRISE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600080',
    lea_name: 'HARLEM VILLAGE ACADEMY CHARTER SCHOOL EHVACS',
  },
  {
    state_abbr: 'NY',
    leaid: '3600082',
    lea_name: 'KIPP STAR COLLEGE PREP CHARTER',
  },
  {
    state_abbr: 'NY',
    leaid: '3600089',
    lea_name: 'BRONX CHARTER SCHOOL FOR BETTER LEARNING',
  },
  {
    state_abbr: 'NY',
    leaid: '3600104',
    lea_name: 'SYRACUSE ACADEMY OF SCIENCE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600105',
    lea_name: 'BUFFALO ACADEMY OF SCIENCE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600106',
    lea_name: 'WESTERN NEW YORK MARITIME CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600107',
    lea_name: 'WESTMINSTER COMMUNITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600108',
    lea_name: "HARLEM CHILDREN'S ZONE PROMISE ACADEMY CHARTER SCHOOL",
  },
  {
    state_abbr: 'NY',
    leaid: '3600109',
    lea_name: 'OPPORTUNITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600110',
    lea_name: 'BRONX CHARTER SCHOOL FOR CHILDREN',
  },
  {
    state_abbr: 'NY',
    leaid: '3600111',
    lea_name: 'BRONX LIGHTHOUSE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600112',
    lea_name: 'GRAND CONCOURSE ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600113',
    lea_name: 'BRONX CHARTER SCHOOL FOR EXCELLENCE',
  },
  {
    state_abbr: 'NY',
    leaid: '3600114',
    lea_name: 'EXCELLENCE BOYS CHARTER SCHOOL OF BEDFORD STUYVESANT',
  },
  {
    state_abbr: 'NY',
    leaid: '3600115',
    lea_name: 'WILLIAMSBURG CHARTER HIGH SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600116',
    lea_name: 'BROOKLYN EXCELSIOR CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600118',
    lea_name: 'PENINSULA PREPARATORY ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600126',
    lea_name: 'NEW YORK STATE DEPARTMENT OF CORRECTIONS',
  },
  {
    state_abbr: 'NY',
    leaid: '3600127',
    lea_name: 'NEW YORK STATE OFFICE MENTAL HEALTH (OMH)',
  },
  {
    state_abbr: 'NY',
    leaid: '3600128',
    lea_name: 'KIPP TECH VALLEY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600131',
    lea_name: 'NEW YORK STATE OFFICE OF CHILDREN AND FAMILY SERVICES (OCFS)',
  },
  {
    state_abbr: 'NY',
    leaid: '3600132',
    lea_name: 'ORACLE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600134',
    lea_name: 'URBAN CHOICE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600135',
    lea_name: 'NYC SPECIAL SCHOOLS - DISTRICT 75',
  },
  {
    state_abbr: 'NY',
    leaid: '3600136',
    lea_name: 'GIRLS PREPARATORY CHARTER SCHOOL OF NEW YORK',
  },
  {
    state_abbr: 'NY',
    leaid: '3600137',
    lea_name: 'MANHATTAN CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600138',
    lea_name: 'HARLEM LINK CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600139',
    lea_name: 'FUTURE LEADERS INSTITUTE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600140',
    lea_name: "HARLEM CHILDREN'S ZONE PROMISE ACADEMY II CHARTER SCHOOL",
  },
  {
    state_abbr: 'NY',
    leaid: '3600141',
    lea_name: 'HARLEM VILLAGE ACADEMY LEADERSHIP CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600142',
    lea_name: 'NEW YORK CENTER FOR AUTISM CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600143',
    lea_name: 'KIPP INFINITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600144',
    lea_name: 'SOUTH BRONX CHARTER SCHOOL-INTER CULTURES AND ARTS',
  },
  {
    state_abbr: 'NY',
    leaid: '3600145',
    lea_name: 'WILLIAMSBURG COLLEGIATE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600146',
    lea_name: 'HELLENIC CLASSICAL CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600147',
    lea_name: 'ACHIEVEMENT FIRST CROWN HEIGHTS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600148',
    lea_name: 'KIPP AMP CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600149',
    lea_name: 'ACHIEVEMENT FIRST EAST NEW YORK CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600150',
    lea_name: 'UFT CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600155',
    lea_name: 'CHARTER SCHOOL OF EDUCATIONAL EXCELLENCE',
  },
  {
    state_abbr: 'NY',
    leaid: '3600157',
    lea_name: 'EXPLORE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600158',
    lea_name: 'DEMOCRACY PREPARATORY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600159',
    lea_name: 'COMMUNITY ROOTS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600160',
    lea_name: 'ELMWOOD VILLAGE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600161',
    lea_name: 'SOUTH BRONX CLASSICAL CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600162',
    lea_name: 'ALBANY COMMUNITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600163',
    lea_name: 'INTERNATIONAL LEADERSHIP CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600164',
    lea_name: 'NIAGARA CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600165',
    lea_name: 'HYDE LEADERSHIP CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600166',
    lea_name: 'ACHIEVEMENT FIRST BUSHWICK CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600167',
    lea_name: 'NEW HEIGHTS ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600169',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-HARLEM 1',
  },
  {
    state_abbr: 'NY',
    leaid: '3600170',
    lea_name: 'ACHIEVEMENT FIRST ENDEAVOR CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600172',
    lea_name: 'LEADERSHIP PREPARATORY BEDFORD STUYVESANT CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600173',
    lea_name: 'TRUE NORTH ROCHESTER PREPARATORY CHARTER SCHOOL (THE)',
  },
  {
    state_abbr: 'NY',
    leaid: '3600943',
    lea_name: 'ICAHN CHARTER SCHOOL 2',
  },
  {
    state_abbr: 'NY',
    leaid: '3600944',
    lea_name: 'KINGS COLLEGIATE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600945',
    lea_name: 'GREEN TECH HIGH CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600946',
    lea_name: 'HENRY JOHNSON CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600948',
    lea_name: 'NORTHSIDE CHARTER HIGH SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600949',
    lea_name: 'PAVE ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600950',
    lea_name: 'ROCHESTER ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600951',
    lea_name: 'ETHICAL COMMUNITY CHARTER SCHOOL (THE)',
  },
  {
    state_abbr: 'NY',
    leaid: '3600952',
    lea_name: 'EQUITY PROJECT CHARTER SCHOOL (THE)',
  },
  {
    state_abbr: 'NY',
    leaid: '3600953',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-HARLEM 4',
  },
  {
    state_abbr: 'NY',
    leaid: '3600954',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-HARLEM 2',
  },
  {
    state_abbr: 'NY',
    leaid: '3600955',
    lea_name: 'ICAHN CHARTER SCHOOL 3',
  },
  {
    state_abbr: 'NY',
    leaid: '3600956',
    lea_name: 'BRONX ACADEMY OF PROMISE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600957',
    lea_name: 'VOICE CHARTER SCHOOL OF NEW YORK',
  },
  {
    state_abbr: 'NY',
    leaid: '3600958',
    lea_name: 'ST HOPE LEADERSHIP ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600959',
    lea_name: 'NYC CHARTER HS - AECI',
  },
  {
    state_abbr: 'NY',
    leaid: '3600960',
    lea_name: 'MOTT HAVEN ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600961',
    lea_name: 'LA CIMA CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600962',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-HARLEM 3',
  },
  {
    state_abbr: 'NY',
    leaid: '3600963',
    lea_name: 'UNIVERSITY PREP CHARTER HIGH SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600964',
    lea_name: 'DREAM CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600965',
    lea_name: 'BEDFORD STUYVESANT COLLEGIATE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600966',
    lea_name: 'BROOKLYN ASCEND CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600967',
    lea_name: 'BRONX GLOBAL LEARNING INSTITUTE FOR GIRLS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600968',
    lea_name: 'BRONX COMMUNITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600969',
    lea_name: 'ACHIEVEMENT FIRST BROWNSVILLE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600970',
    lea_name: 'ALOMA D JOHNSON CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600984',
    lea_name: 'BROWNSVILLE COLLEGIATE  CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600986',
    lea_name: 'GIRLS PREPARATORY CHARTER SCHOOL OF THE BRONX',
  },
  {
    state_abbr: 'NY',
    leaid: '3600987',
    lea_name: 'GROWING UP GREEN CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600988',
    lea_name: 'EVERGREEN CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600989',
    lea_name: 'BROOKLYN SCHOLARS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600990',
    lea_name: 'NEW ROOTS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600991',
    lea_name: 'BROOKLYN PROSPECT CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600992',
    lea_name: 'TRUE NORTH TROY PREPARATORY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600993',
    lea_name: 'LEADERSHIP PREPARATORY OCEAN HILL CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600994',
    lea_name: 'EXPLORE EMPOWER CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600995',
    lea_name: 'ICAHN CHARTER SCHOOL 4',
  },
  {
    state_abbr: 'NY',
    leaid: '3600996',
    lea_name: 'JOHN W LAVELLE PREPARATORY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600997',
    lea_name: 'ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600998',
    lea_name: 'SUMMIT ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3600999',
    lea_name: 'FAHARI ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601000',
    lea_name: 'EXCELLENCE GIRLS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601001',
    lea_name: 'ACADEMIC LEADERSHIP CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601002',
    lea_name: 'BROWNSVILLE ASCEND CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601003',
    lea_name: 'HEBREW LANGUAGE ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601004',
    lea_name: 'CONEY ISLAND PREPARATORY PUBLIC CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601005',
    lea_name: 'EQUALITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601006',
    lea_name: 'ALBANY LEADERSHIP CHARTER HIGH SCHOOL FOR GIRLS',
  },
  {
    state_abbr: 'NY',
    leaid: '3601008',
    lea_name: 'UNIVERSITY PREPARATORY CHARTER SCHOOL FOR YOUNG MEN',
  },
  {
    state_abbr: 'NY',
    leaid: '3601009',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-HARLEM 5',
  },
  {
    state_abbr: 'NY',
    leaid: '3601010',
    lea_name: 'DEMOCRACY PREP HARLEM CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601011',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-BRONX 1',
  },
  {
    state_abbr: 'NY',
    leaid: '3601012',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-BRONX 2',
  },
  {
    state_abbr: 'NY',
    leaid: '3601013',
    lea_name: 'NEW HOPE ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601014',
    lea_name: 'CULTURAL ARTS ACADEMY CHARTER SCHOOL AT SPRING CREEK',
  },
  {
    state_abbr: 'NY',
    leaid: '3601015',
    lea_name: 'BROOKLYN DREAMS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601016',
    lea_name: 'LEADERSHIP PREPARATORY BROWNSVILLE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601017',
    lea_name: 'BUSHWICK ASCEND CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601018',
    lea_name: 'CHALLENGE PREPARATORY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601019',
    lea_name: 'NEW WORLD PREPARATORY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601020',
    lea_name: 'BRIGHTER CHOICE CHARTER MIDDLE SCHOOL FOR BOYS',
  },
  {
    state_abbr: 'NY',
    leaid: '3601021',
    lea_name: 'BRIGHTER CHOICE CHARTER MIDDLE SCHOOL FOR GIRLS',
  },
  {
    state_abbr: 'NY',
    leaid: '3601022',
    lea_name: 'HEALTH SCIENCES CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601023',
    lea_name: 'NEW YORK FRENCH-AMERICAN CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601024',
    lea_name: 'INWOOD ACADEMY FOR LEADERSHIP CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601025',
    lea_name: 'METROPOLITAN LIGHTHOUSE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601027',
    lea_name: 'DR RICHARD IZQUIERDO HEALTH AND SCIENCE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601028',
    lea_name: 'ACHIEVEMENT FIRST APOLLO CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601029',
    lea_name: 'BEDFORD STUYVESANT NEW BEGINNINGS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601030',
    lea_name: 'HYDE LEADERSHIP CHARTER SCHOOL OF BROOKLYN',
  },
  {
    state_abbr: 'NY',
    leaid: '3601031',
    lea_name: 'IMAGINE ME LEADERSHIP CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601032',
    lea_name: 'OCEAN HILL COLLEGIATE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601033',
    lea_name: 'BROOKLYN EAST COLLEGIATE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601034',
    lea_name: 'ROCHDALE EARLY ADVANTAGE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601035',
    lea_name: 'RIVERTON STREET CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601036',
    lea_name: 'RENSAISSANCE CHARTER HIGH SCHOOL FOR INNOVATION',
  },
  {
    state_abbr: 'NY',
    leaid: '3601037',
    lea_name: 'STATEN ISLAND COMMUNITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601038',
    lea_name: 'LEFFERTS GARDENS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601039',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-UPPER WEST',
  },
  {
    state_abbr: 'NY',
    leaid: '3601040',
    lea_name: 'TRUE NORTH ROCHESTER PREP CHARTER SCHOOL-WEST CAMPUS',
  },
  {
    state_abbr: 'NY',
    leaid: '3601041',
    lea_name: 'NEW VISIONS CHARTER HIGH SCHOOL FOR HUMANITIES (THE)',
  },
  {
    state_abbr: 'NY',
    leaid: '3601042',
    lea_name: 'EAST HARLEM SCHOLARS ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601043',
    lea_name: 'BROOME STREET ACADEMY CHARTER HIGH SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601044',
    lea_name: 'NEW YORK CITY MONTESSORI CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601045',
    lea_name: 'INNOVATE MANHATTAN CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601046',
    lea_name: 'DISCOVERY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601047',
    lea_name: 'NEW VISIONS CHARTER HS-ADV MATH/SCIE',
  },
  {
    state_abbr: 'NY',
    leaid: '3601048',
    lea_name: 'TEACHING FIRMS OF AMERICA PROFESSIONAL PREP CHARTER',
  },
  {
    state_abbr: 'NY',
    leaid: '3601049',
    lea_name: 'ICAHN CHARTER SCHOOL 5',
  },
  {
    state_abbr: 'NY',
    leaid: '3601050',
    lea_name: 'INVICTUS PREPARATORY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601051',
    lea_name: 'AMANI PUBLIC CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601052',
    lea_name: 'EXPLORE EXCEL CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601053',
    lea_name: 'ACADEMY OF THE CITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601054',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-BEDFORD STUYVESANT 1',
  },
  {
    state_abbr: 'NY',
    leaid: '3601055',
    lea_name: 'WEST BUFFALO CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601056',
    lea_name: 'CENTRAL QUEENS ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601057',
    lea_name: 'ROADS CHARTER SCHOOL 1',
  },
  {
    state_abbr: 'NY',
    leaid: '3601058',
    lea_name: 'EXPLORE EXCEED CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601059',
    lea_name: 'LAUNCH EXPEDITIONARY LEARNING CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601060',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-COBBLE HILL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601061',
    lea_name: 'NEW DAWN CHARTER HIGH SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601062',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-WILLIAMSBURG',
  },
  {
    state_abbr: 'NY',
    leaid: '3601063',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-BED STUY 2',
  },
  {
    state_abbr: 'NY',
    leaid: '3601064',
    lea_name: 'BEGINNING WITH CHILDREN CHARTER SCHOOL II',
  },
  {
    state_abbr: 'NY',
    leaid: '3601065',
    lea_name: 'URBAN DOVE TEAM CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601066',
    lea_name: "CHILDREN'S AID COLLEGE PREPARATORY CHARTER SCHOOL",
  },
  {
    state_abbr: 'NY',
    leaid: '3601067',
    lea_name: 'ROADS CHARTER SCHOOL II',
  },
  {
    state_abbr: 'NY',
    leaid: '3601068',
    lea_name: 'TECH INTERNATIONAL CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601069',
    lea_name: 'ICAHN CHARTER SCHOOL 6',
  },
  {
    state_abbr: 'NY',
    leaid: '3601070',
    lea_name: 'FAMILY LIFE ACADEMY CHARTER SCHOOL II',
  },
  {
    state_abbr: 'NY',
    leaid: '3601071',
    lea_name: 'MOTT HALL CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601072',
    lea_name: 'NEW VISIONS CHTR HS-ADV MA/SCI II',
  },
  {
    state_abbr: 'NY',
    leaid: '3601073',
    lea_name: 'NEW VISIONS CHARTER HIGH SCHOOL FOR HUMANITIES II',
  },
  {
    state_abbr: 'NY',
    leaid: '3601074',
    lea_name: 'HEKETI COMMUNITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601075',
    lea_name: 'KIPP NYC WASHINGTON HEIGHTS ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601076',
    lea_name: 'GLOBAL COMMUNITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601077',
    lea_name: 'NEIGHBORHOOD CHARTER SCHOOL OF HARLEM',
  },
  {
    state_abbr: 'NY',
    leaid: '3601078',
    lea_name: 'DEMOCRACY PREP ENDURANCE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601079',
    lea_name: 'MANHATTAN CHARTER SCHOOL II',
  },
  {
    state_abbr: 'NY',
    leaid: '3601080',
    lea_name: "YOUNG WOMEN'S COLLEGE PREP CHARTER SCHOOL OF ROCHESTER",
  },
  {
    state_abbr: 'NY',
    leaid: '3601081',
    lea_name: 'ROCHESTER CAREER MENTORING CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601082',
    lea_name: 'MIDDLE VILLAGE PREPARATORY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601083',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-UNION SQUARE',
  },
  {
    state_abbr: 'NY',
    leaid: '3601084',
    lea_name: 'UTICA ACADEMY OF SCIENCE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601085',
    lea_name: 'GREAT OAKS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601086',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-FORT GREENE',
  },
  {
    state_abbr: 'NY',
    leaid: '3601088',
    lea_name: 'NEW VISIONS CHTR HS-ADV MA/SCI III',
  },
  {
    state_abbr: 'NY',
    leaid: '3601089',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-PROSPECT HEIGHTS',
  },
  {
    state_abbr: 'NY',
    leaid: '3601090',
    lea_name: 'EAST HARLEM SCHOLARS ACADEMY CHARTER SCHOOL II',
  },
  {
    state_abbr: 'NY',
    leaid: '3601091',
    lea_name: 'ICAHN CHARTER SCHOOL 7',
  },
  {
    state_abbr: 'NY',
    leaid: '3601092',
    lea_name: 'NEW AMERICAN ACADEMY CHARTER SCHOOL (THE)',
  },
  {
    state_abbr: 'NY',
    leaid: '3601093',
    lea_name: 'MATH, ENGINEERING AND SCIENCE ACADEMY CHARTER HIGH SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601094',
    lea_name: 'UNITY PREPARATORY CHARTER SCHOOL OF BROOKLYN',
  },
  {
    state_abbr: 'NY',
    leaid: '3601095',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-CROWN HEIGHTS',
  },
  {
    state_abbr: 'NY',
    leaid: '3601096',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL-BRONX 3',
  },
  {
    state_abbr: 'NY',
    leaid: '3601097',
    lea_name: 'HARLEM HEBREW LANGUAGE ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601098',
    lea_name: 'CITIZENS OF THE WORLD CHARTER SCHOOL 1',
  },
  {
    state_abbr: 'NY',
    leaid: '3601099',
    lea_name: "SUCCESS ACADEMY CHARTER SCHOOL-HELL'S KITCHEN",
  },
  {
    state_abbr: 'NY',
    leaid: '3601100',
    lea_name: 'BROOKLYN URBAN GARDEN CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601101',
    lea_name: 'CITIZENS OF THE WORLD CHARTER SCHOOL 2',
  },
  {
    state_abbr: 'NY',
    leaid: '3601102',
    lea_name: 'CANARSIE ASCEND CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601103',
    lea_name: 'NEW VISIONS CHARTER HIGH SCHOOL FOR THE HUMANITIES III',
  },
  {
    state_abbr: 'NY',
    leaid: '3601104',
    lea_name: 'NEWBURGH PREPARATORY CHARTER HIGH SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601105',
    lea_name: 'SOUTH BRONX CLASSICAL CHARTER SCHOOL II',
  },
  {
    state_abbr: 'NY',
    leaid: '3601106',
    lea_name: 'LEADERSHIP PREPARATORY CANARSIE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601108',
    lea_name: 'BRILLA COLLEGE PREPARATORY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601109',
    lea_name: 'ACHIEVEMENT FIRST ASPIRE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601110',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL - WASHINGTON HEIGHTS',
  },
  {
    state_abbr: 'NY',
    leaid: '3601111',
    lea_name: 'BROOKLYN LABORATORY CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601112',
    lea_name: 'ACHIEVEMENT FIRST LINDEN CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601113',
    lea_name: 'RENAISSANCE ACADEMY CHARTER SCHOOL OF THE ARTS',
  },
  {
    state_abbr: 'NY',
    leaid: '3601114',
    lea_name: 'CENTRAL BROOKLYN ASCEND CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601115',
    lea_name: 'BOYS PREP CHARTER SCHOOL OF NEW YORK',
  },
  {
    state_abbr: 'NY',
    leaid: '3601116',
    lea_name: 'SUCCESS ACADEMY CS - ROSEDALE',
  },
  {
    state_abbr: 'NY',
    leaid: '3601117',
    lea_name: 'VERTUS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601118',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL - SPRINGFIELD',
  },
  {
    state_abbr: 'NY',
    leaid: '3601119',
    lea_name: 'AMERICAN DREAM CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601120',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL - BRONX 4',
  },
  {
    state_abbr: 'NY',
    leaid: '3601121',
    lea_name: 'COMPASS CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601122',
    lea_name: 'SUCCESS ACADEMY CHARTER SCHOOL - BENSONHURST',
  },
  {
    state_abbr: 'NY',
    leaid: '3601123',
    lea_name: 'SUCCESS ACADEMY CS - BERGEN BEACH',
  },
  {
    state_abbr: 'NY',
    leaid: '3601124',
    lea_name: 'ACHIEVEMENT FIRST NORTH BROOKLYN PREP CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3601125',
    lea_name: 'FAMILY LIFE ACADEMY CHARTER SCHOOL III',
  },
  {
    state_abbr: 'NY',
    leaid: '3601126',
    lea_name: 'PUC ACHIEVE CHARTER SCHOOL',
  },
  {
    state_abbr: 'NY',
    leaid: '3604590',
    lea_name: 'BERKSHIRE UNION FREE SCHOOL DISTRICT',
  },
  {
    state_abbr: 'NY',
    leaid: '3607440',
    lea_name: 'RANDOLPH ACADEMY UNION FREE SCHOOL DISTRICT',
  },
  {
    state_abbr: 'NY',
    leaid: '3608470',
    lea_name: 'MOUNT PLEASANT-COTTAGE UNION FREE SCHOOL DISTRICT',
  },
  {
    state_abbr: 'NY',
    leaid: '3610140',
    lea_name: 'GREENBURGH ELEVEN UNION FREE SCHOOL DISTRICT',
  },
  {
    state_abbr: 'NY',
    leaid: '3612400',
    lea_name: 'GREENBURGH-GRAHAM UNION FREE SCHOOL DISTRICT',
  },
  {
    state_abbr: 'NY',
    leaid: '3614070',
    lea_name: 'HAWTHORNE-CEDAR KNOLLS UNION FREE SCHOOL DISTRICT',
  },
  {
    state_abbr: 'NY',
    leaid: '3615030',
    lea_name: 'GEORGE JUNIOR REPUBLIC UNION FREE SCHOOL DISTRICT',
  },
  {
    state_abbr: 'NY',
    leaid: '3617400',
    lea_name: 'LITTLE FLOWER UNION FREE SCHOOL DISTRICT',
  },
  {
    state_abbr: 'NY',
    leaid: '3620170',
    lea_name: 'MOUNT PLEASANT-BLYTHEDALE UNION FREE SCHOOL DISTRICT',
  },
  {
    state_abbr: 'NY',
    leaid: '3627980',
    lea_name: 'GREENBURGH-NORTH CASTLE UNION FREE SCHOOL DISTRICT',
  },
  {
    state_abbr: 'OH',
    leaid: '3900129',
    lea_name: 'Pathway School of Discovery',
  },
  {
    state_abbr: 'OH',
    leaid: '3900314',
    lea_name: 'King Academy Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900315',
    lea_name: 'Emerson Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900321',
    lea_name: 'FCI Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900331',
    lea_name: 'Coshocton Opportunity School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900572',
    lea_name: 'Villaview Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901451',
    lea_name: 'Focus North High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901452',
    lea_name: 'Impact Academy Cincinnati',
  },
  {
    state_abbr: 'OH',
    leaid: '3901555',
    lea_name: 'Rise & Shine Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901556',
    lea_name: 'University Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901558',
    lea_name: 'City Prep Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900001',
    lea_name: 'Ohio School For The Deaf',
  },
  {
    state_abbr: 'OH',
    leaid: '3900072',
    lea_name: 'T.C.P. World Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900073',
    lea_name: 'Richard Allen Preparatory',
  },
  {
    state_abbr: 'OH',
    leaid: '3900127',
    lea_name: 'Glass City Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900002',
    lea_name: 'Ohio School For The Blind',
  },
  {
    state_abbr: 'OH',
    leaid: '3900017',
    lea_name: 'Youngstown Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900019',
    lea_name: 'Constellation Schools: Old Brooklyn Community Elementary',
  },
  {
    state_abbr: 'OH',
    leaid: '3900022',
    lea_name: 'Autism Model School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900023',
    lea_name: 'Aurora Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900025',
    lea_name: 'Green Inspiration Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900027',
    lea_name: 'Middlebury Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900028',
    lea_name: 'Colonial Prep Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900029',
    lea_name: 'City Day Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900031',
    lea_name: 'Cincinnati College Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900032',
    lea_name: 'Citizens Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900033',
    lea_name: 'Edge Academy, The',
  },
  {
    state_abbr: 'OH',
    leaid: '3900036',
    lea_name: 'Millennium Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900038',
    lea_name: 'Summit Academy Akron Elementary School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900040',
    lea_name: 'Horizon Science Acad Cleveland',
  },
  {
    state_abbr: 'OH',
    leaid: '3900042',
    lea_name: 'Horizon Science Academy Columbus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900043',
    lea_name: 'Riverside Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900047',
    lea_name: 'Richard Allen Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900050',
    lea_name: 'Life Skills Ctr Of Youngstown',
  },
  {
    state_abbr: 'OH',
    leaid: '3900051',
    lea_name: 'Lincoln Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900052',
    lea_name: 'Invictus High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900053',
    lea_name: 'Towpath Trail High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900056',
    lea_name: 'Toledo School For The Arts',
  },
  {
    state_abbr: 'OH',
    leaid: '3900057',
    lea_name: 'Dayton Leadership Academies-Dayton Liberty Campus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900065',
    lea_name: 'Intergenerational School, The',
  },
  {
    state_abbr: 'OH',
    leaid: '3900068',
    lea_name: 'Constellation Schools: Parma Community',
  },
  {
    state_abbr: 'OH',
    leaid: '3900071',
    lea_name: 'Summit Academy Community School for Alternative Learn-Canton',
  },
  {
    state_abbr: 'OH',
    leaid: '3900077',
    lea_name: 'Lighthouse Community Sch Inc',
  },
  {
    state_abbr: 'OH',
    leaid: '3900079',
    lea_name: 'Electronic Classroom Of Tomorrow',
  },
  {
    state_abbr: 'OH',
    leaid: '3900080',
    lea_name: 'Graham School, The',
  },
  {
    state_abbr: 'OH',
    leaid: '3900081',
    lea_name: 'Cornerstone Academy Community',
  },
  {
    state_abbr: 'OH',
    leaid: '3900083',
    lea_name: 'Dayton Leadership Academies-Dayton View Campus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900084',
    lea_name: 'Life Skills Of Trumbull County',
  },
  {
    state_abbr: 'OH',
    leaid: '3900086',
    lea_name: 'Life Skills Ctr Of Cincinnati',
  },
  {
    state_abbr: 'OH',
    leaid: '3900096',
    lea_name: 'Summit Acdy Comm Schl for Alternative Learners of Middletown',
  },
  {
    state_abbr: 'OH',
    leaid: '3900097',
    lea_name: 'Summit Academy Community School Alternative Learners -Xenia',
  },
  {
    state_abbr: 'OH',
    leaid: '3900098',
    lea_name: 'Summit Academy Akron Middle School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900099',
    lea_name: 'Springfield Academy of Excellence',
  },
  {
    state_abbr: 'OH',
    leaid: '3900100',
    lea_name: 'Cliff Park High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900101',
    lea_name: 'Marshall High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900102',
    lea_name: 'Constellation Schools: Lorain Community Elementary',
  },
  {
    state_abbr: 'OH',
    leaid: '3900103',
    lea_name: 'Constellation Schools: Elyria Community',
  },
  {
    state_abbr: 'OH',
    leaid: '3900104',
    lea_name: 'Youthbuild Columbus Community',
  },
  {
    state_abbr: 'OH',
    leaid: '3900105',
    lea_name: 'Constellation Schools: Westpark Community Elementary',
  },
  {
    state_abbr: 'OH',
    leaid: '3900106',
    lea_name: 'Dohn Community',
  },
  {
    state_abbr: 'OH',
    leaid: '3900107',
    lea_name: 'Washington Park Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900109',
    lea_name: 'Summit Academy Community School Alternative Learners-Lorain',
  },
  {
    state_abbr: 'OH',
    leaid: '3900113',
    lea_name: 'Phoenix Community Learning Ctr',
  },
  {
    state_abbr: 'OH',
    leaid: '3900117',
    lea_name: 'The Autism Academy Of Learning',
  },
  {
    state_abbr: 'OH',
    leaid: '3900118',
    lea_name: 'Treca Digital Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900119',
    lea_name: 'West Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900120',
    lea_name: 'Eagle Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900121',
    lea_name: 'Hamilton Cnty Math & Science',
  },
  {
    state_abbr: 'OH',
    leaid: '3900123',
    lea_name: 'Sciotoville',
  },
  {
    state_abbr: 'OH',
    leaid: '3900125',
    lea_name: 'Polly Fox Academy Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900126',
    lea_name: 'Phoenix Academy Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900130',
    lea_name: 'Alliance Academy of Cincinnati',
  },
  {
    state_abbr: 'OH',
    leaid: '3900133',
    lea_name: 'Newark Digital Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900135',
    lea_name: 'Hamilton Alternative Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900138',
    lea_name: 'Wildwood Environmental Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900145',
    lea_name: 'Tomorrow Center',
  },
  {
    state_abbr: 'OH',
    leaid: '3900146',
    lea_name: 'Mahoning Unlimited Classroom',
  },
  {
    state_abbr: 'OH',
    leaid: '3900151',
    lea_name: 'Goal Digital Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900152',
    lea_name: 'Akron Digital Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900153',
    lea_name: 'Urbana Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900155',
    lea_name: 'Fairborn Digital Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900175',
    lea_name: 'Life Skills Center  of Canton',
  },
  {
    state_abbr: 'OH',
    leaid: '3900176',
    lea_name: 'Life Skills Center of Elyria',
  },
  {
    state_abbr: 'OH',
    leaid: '3900177',
    lea_name: 'Focus Learning Academy of Southwest Columbus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900178',
    lea_name: 'Focus Learning Academy of Southeastern Columbus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900179',
    lea_name: 'Focus Learning Academy of Northern Columbus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900180',
    lea_name: 'Ohio Virtual Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900181',
    lea_name: 'Hope Academy Northcoast',
  },
  {
    state_abbr: 'OH',
    leaid: '3900184',
    lea_name: 'Mound Street IT Careers Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900185',
    lea_name: 'Mound Street Military Careers Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900186',
    lea_name: 'Mound Street Health Careers Acadmy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900189',
    lea_name: 'Life Skills Center Of Hamilton County',
  },
  {
    state_abbr: 'OH',
    leaid: '3900190',
    lea_name: 'International Acad Of Columbus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900192',
    lea_name: 'Great Western Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900193',
    lea_name: 'Trotwood Fitness & Prep Acad',
  },
  {
    state_abbr: 'OH',
    leaid: '3900194',
    lea_name: 'Middletown Fitness & Prep Acad',
  },
  {
    state_abbr: 'OH',
    leaid: '3900203',
    lea_name: 'Alternative Education Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900211',
    lea_name: 'Constellation Schools: Puritas Community Elementary',
  },
  {
    state_abbr: 'OH',
    leaid: '3900212',
    lea_name: 'Constellation Schools: Stockyard Community Elementary',
  },
  {
    state_abbr: 'OH',
    leaid: '3900214',
    lea_name: 'Lake Erie Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900216',
    lea_name: 'North Dayton School Of Science & Discovery',
  },
  {
    state_abbr: 'OH',
    leaid: '3900217',
    lea_name: 'Virtual Community School Of Ohio',
  },
  {
    state_abbr: 'OH',
    leaid: '3900219',
    lea_name: 'Richard Allen Academy II',
  },
  {
    state_abbr: 'OH',
    leaid: '3900220',
    lea_name: 'Richard Allen Academy III',
  },
  {
    state_abbr: 'OH',
    leaid: '3900223',
    lea_name: 'Arts & College Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900226',
    lea_name: 'Marion City Digital Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900228',
    lea_name: 'Franklin Local Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900231',
    lea_name: 'Life Skills Center Of Toledo',
  },
  {
    state_abbr: 'OH',
    leaid: '3900233',
    lea_name: 'Foxfire High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900234',
    lea_name: 'Southwest Licking Digital Acad',
  },
  {
    state_abbr: 'OH',
    leaid: '3900237',
    lea_name: 'Buckeye United School District',
  },
  {
    state_abbr: 'OH',
    leaid: '3900241',
    lea_name: 'Miami Valley Academies',
  },
  {
    state_abbr: 'OH',
    leaid: '3900250',
    lea_name: 'Ohio Connections Academy, Inc',
  },
  {
    state_abbr: 'OH',
    leaid: '3900252',
    lea_name: 'Quaker Digital Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900259',
    lea_name: 'Massillon Digital Academy, Inc',
  },
  {
    state_abbr: 'OH',
    leaid: '3900262',
    lea_name: 'London Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900263',
    lea_name: 'Pleasant Community Digital',
  },
  {
    state_abbr: 'OH',
    leaid: '3900273',
    lea_name: 'Lorain K-12 Digital Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900275',
    lea_name: 'Ridgedale Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900276',
    lea_name: 'West Central Learning Academy II',
  },
  {
    state_abbr: 'OH',
    leaid: '3900277',
    lea_name: 'Lake Erie International High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900278',
    lea_name: 'Life Skills Center Of Summit County',
  },
  {
    state_abbr: 'OH',
    leaid: '3900279',
    lea_name: 'Life Skills Of Northeast Ohio',
  },
  {
    state_abbr: 'OH',
    leaid: '3900282',
    lea_name: 'Lakewood Digital Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900285',
    lea_name: 'Canton Harbor High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900286',
    lea_name: 'Cleveland Academy for Scholarship Technology and Leadership',
  },
  {
    state_abbr: 'OH',
    leaid: '3900291',
    lea_name: 'Constellation Schools: Puritas Community Middle',
  },
  {
    state_abbr: 'OH',
    leaid: '3900297',
    lea_name: 'Constellation Schools: Outreach Academy for Students with Di',
  },
  {
    state_abbr: 'OH',
    leaid: '3900299',
    lea_name: 'Pinnacle Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900302',
    lea_name: 'Winterfield Venture Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900304',
    lea_name: 'Columbus Humanities, Arts and Technology Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900305',
    lea_name: 'A+ Arts Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900306',
    lea_name: 'Columbus Arts & Technology Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900307',
    lea_name: 'Columbus Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900308',
    lea_name: 'Orion Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900309',
    lea_name: 'Apex Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900311',
    lea_name: 'Virtual Schoolhouse, Inc.',
  },
  {
    state_abbr: 'OH',
    leaid: '3900313',
    lea_name: 'Hope Academy Northwest Campus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900337',
    lea_name: 'Summit Academy Transition High School-Cincinnati',
  },
  {
    state_abbr: 'OH',
    leaid: '3900338',
    lea_name: 'Summit Academy Middle School - Lorain',
  },
  {
    state_abbr: 'OH',
    leaid: '3900339',
    lea_name: 'Summit Academy Middle School - Columbus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900341',
    lea_name: 'Heir Force Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900342',
    lea_name: 'Summit Academy Transition High School-Columbus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900344',
    lea_name: 'Summit Academy Alternative LearnersWarren Middle & Secondary',
  },
  {
    state_abbr: 'OH',
    leaid: '3900348',
    lea_name: 'Summit Academy Transition High School Dayton',
  },
  {
    state_abbr: 'OH',
    leaid: '3900350',
    lea_name: 'Summit Academy-Youngstown',
  },
  {
    state_abbr: 'OH',
    leaid: '3900356',
    lea_name: 'Summit Academy Community School - Painesville',
  },
  {
    state_abbr: 'OH',
    leaid: '3900360',
    lea_name: 'Summit Academy Toledo Learning Center',
  },
  {
    state_abbr: 'OH',
    leaid: '3900361',
    lea_name: 'Summit Academy Secondary School - Middletown',
  },
  {
    state_abbr: 'OH',
    leaid: '3900366',
    lea_name: 'Rittman Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900381',
    lea_name: 'Life Skills Center of Columbus Southeast',
  },
  {
    state_abbr: 'OH',
    leaid: '3900382',
    lea_name: 'New Day Academy Boarding & Day School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900383',
    lea_name: 'Oakstone Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900385',
    lea_name: 'Schnee Learning Center',
  },
  {
    state_abbr: 'OH',
    leaid: '3900396',
    lea_name: 'Zenith Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900399',
    lea_name: 'HBCU Preparatory School 1',
  },
  {
    state_abbr: 'OH',
    leaid: '3900400',
    lea_name: 'HBCU Preparatory School 2',
  },
  {
    state_abbr: 'OH',
    leaid: '3900428',
    lea_name: 'Maritime Academy of Toledo, The',
  },
  {
    state_abbr: 'OH',
    leaid: '3900434',
    lea_name: 'Educational Academy for Boys & Girls',
  },
  {
    state_abbr: 'OH',
    leaid: '3900435',
    lea_name: 'Midnimo Cross Cultural Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900436',
    lea_name: 'Cincinnati Speech & Reading Intervention Center',
  },
  {
    state_abbr: 'OH',
    leaid: '3900441',
    lea_name: 'Horizon Science Academy-Cincinnati',
  },
  {
    state_abbr: 'OH',
    leaid: '3900444',
    lea_name: 'Horizon Science Academy-Dayton',
  },
  {
    state_abbr: 'OH',
    leaid: '3900448',
    lea_name: 'Life Skills Center of Dayton',
  },
  {
    state_abbr: 'OH',
    leaid: '3900451',
    lea_name: 'Horizon Science Academy-Springfield',
  },
  {
    state_abbr: 'OH',
    leaid: '3900454',
    lea_name: 'Horizon Science Academy-Denison Middle School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900457',
    lea_name: 'Bennett Venture Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900467',
    lea_name: 'Stambaugh Charter Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900470',
    lea_name: 'Horizon Science Academy-Cleveland Middle School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900474',
    lea_name: 'Westside Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900478',
    lea_name: 'Interactive Media & Construction (IMAC)',
  },
  {
    state_abbr: 'OH',
    leaid: '3900488',
    lea_name: 'Greater Ohio Virtual School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900490',
    lea_name: 'Auglaize County Educational Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900492',
    lea_name: 'Summit Academy Community School-Columbus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900493',
    lea_name: 'Summit Academy Community School - Dayton',
  },
  {
    state_abbr: 'OH',
    leaid: '3900494',
    lea_name: 'Summit Academy Secondary - Akron',
  },
  {
    state_abbr: 'OH',
    leaid: '3900495',
    lea_name: 'Summit Academy Secondary - Canton',
  },
  {
    state_abbr: 'OH',
    leaid: '3900496',
    lea_name: 'Summit Academy Secondary - Lorain',
  },
  {
    state_abbr: 'OH',
    leaid: '3900497',
    lea_name: 'Summit Academy Community School-Parma',
  },
  {
    state_abbr: 'OH',
    leaid: '3900498',
    lea_name: 'Summit Academy Secondary - Youngstown',
  },
  {
    state_abbr: 'OH',
    leaid: '3900499',
    lea_name: 'Summit Academy Community School-Toledo',
  },
  {
    state_abbr: 'OH',
    leaid: '3900500',
    lea_name: 'Summit Academy Community School-Warren',
  },
  {
    state_abbr: 'OH',
    leaid: '3900501',
    lea_name: 'Summit Academy Community School - Cincinnati',
  },
  {
    state_abbr: 'OH',
    leaid: '3900502',
    lea_name: 'Bridges Community Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900503',
    lea_name: 'Constellation Schools: Westpark Community Middle',
  },
  {
    state_abbr: 'OH',
    leaid: '3900505',
    lea_name: 'Menlo Park Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900506',
    lea_name: 'Constellation Schools: Madison Community Elementary',
  },
  {
    state_abbr: 'OH',
    leaid: '3900507',
    lea_name: 'Constellation Schools: Lorain Community Middle',
  },
  {
    state_abbr: 'OH',
    leaid: '3900508',
    lea_name: 'Constellation Schools: Old Brooklyn Community Middle',
  },
  {
    state_abbr: 'OH',
    leaid: '3900510',
    lea_name: 'Horizon Science Academy Toledo',
  },
  {
    state_abbr: 'OH',
    leaid: '3900514',
    lea_name: 'Miamisburg Secondary Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900523',
    lea_name: 'Mansfield Enhancement Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900525',
    lea_name: 'Mansfield Elective Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900528',
    lea_name: 'Findlay Digital Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900530',
    lea_name: 'Buckeye On-Line School for Success',
  },
  {
    state_abbr: 'OH',
    leaid: '3900533',
    lea_name: 'Columbus Bilingual Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900550',
    lea_name: 'Whitehall Preparatory and Fitness Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900551',
    lea_name: 'Springfield Preparatory and Fitness Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900552',
    lea_name: 'Northland Preparatory and Fitness Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900557',
    lea_name: 'Cleveland Entrepreneurship Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900562',
    lea_name: 'Promise Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900564',
    lea_name: 'Gateway Academy of Ohio',
  },
  {
    state_abbr: 'OH',
    leaid: '3900567',
    lea_name: 'Par Excellence Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900568',
    lea_name: 'Lakewood City Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900569',
    lea_name: 'Cleveland Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900573',
    lea_name: 'Toledo Preparatory and Fitness Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900574',
    lea_name: 'Columbus Preparatory and Fitness Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900575',
    lea_name: 'Mt. Healthy Preparatory and Fitness Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900580',
    lea_name: 'Youngstown Academy of Excellence',
  },
  {
    state_abbr: 'OH',
    leaid: '3900584',
    lea_name: 'Cleveland Arts and Social Sciences Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900585',
    lea_name: 'Center for Student Achievement',
  },
  {
    state_abbr: 'OH',
    leaid: '3900586',
    lea_name: 'Charles School at Ohio Dominican University',
  },
  {
    state_abbr: 'OH',
    leaid: '3900587',
    lea_name: 'Lorain Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900593',
    lea_name: 'Life Skills Center of North Akron',
  },
  {
    state_abbr: 'OH',
    leaid: '3900594',
    lea_name: 'Academy of Arts and Sciences',
  },
  {
    state_abbr: 'OH',
    leaid: '3900638',
    lea_name: 'Early College Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900639',
    lea_name: 'Mahoning Valley Opportunity Center',
  },
  {
    state_abbr: 'OH',
    leaid: '3900640',
    lea_name: 'Groveport Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900641',
    lea_name: 'Harvard Avenue Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900642',
    lea_name: 'Dayton Technology Design High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3900643',
    lea_name: 'Life Skills Center of Columbus North',
  },
  {
    state_abbr: 'OH',
    leaid: '3900644',
    lea_name: 'South Scioto Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3900645',
    lea_name: 'Noble Academy-Columbus',
  },
  {
    state_abbr: 'OH',
    leaid: '3900646',
    lea_name: 'Noble Academy-Cleveland',
  },
  {
    state_abbr: 'OH',
    leaid: '3900647',
    lea_name: 'Eagle Learning Center',
  },
  {
    state_abbr: 'OH',
    leaid: '3901296',
    lea_name: 'Clay Avenue Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901298',
    lea_name: 'Star Academy of Toledo',
  },
  {
    state_abbr: 'OH',
    leaid: '3901301',
    lea_name: 'Foundation Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901302',
    lea_name: 'Dayton Early College Academy, Inc',
  },
  {
    state_abbr: 'OH',
    leaid: '3901305',
    lea_name: 'Columbus Collegiate Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901312',
    lea_name: 'Cincinnati Leadership Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901313',
    lea_name: 'Central Academy of Ohio',
  },
  {
    state_abbr: 'OH',
    leaid: '3901314',
    lea_name: 'Zanesville Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901317',
    lea_name: 'C.M. Grant Leadership Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901319',
    lea_name: 'Northpointe Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901322',
    lea_name: 'Horizon Science Academy Columbus Middle School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901326',
    lea_name: 'Constellation Schools: Westside Community School of the Arts',
  },
  {
    state_abbr: 'OH',
    leaid: '3901329',
    lea_name: 'Mahoning County High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901330',
    lea_name: 'KIPP Columbus',
  },
  {
    state_abbr: 'OH',
    leaid: '3901333',
    lea_name: 'Horizon Science Academy Denison Elementary School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901335',
    lea_name: 'Cesar Chavez College Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901336',
    lea_name: 'Mount Auburn International Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901337',
    lea_name: 'Performance Academy Eastland',
  },
  {
    state_abbr: 'OH',
    leaid: '3901339',
    lea_name: 'L. Hollingworth School for Talented and Gifted',
  },
  {
    state_abbr: 'OH',
    leaid: '3901344',
    lea_name: 'Sullivant Avenue Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901345',
    lea_name: 'Harrisburg Pike Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901346',
    lea_name: 'Madison Avenue School of Arts',
  },
  {
    state_abbr: 'OH',
    leaid: '3901347',
    lea_name: 'Klepinger Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901348',
    lea_name: 'Sciotoville Elementary Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901350',
    lea_name: 'Ashland County Community Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901353',
    lea_name: 'Horizon Science Academy Elementary School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901354',
    lea_name: 'Renaissance Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901356',
    lea_name: 'Rushmore Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901359',
    lea_name: 'LifeLinks Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901361',
    lea_name: 'Falcon Academy of Creative Arts',
  },
  {
    state_abbr: 'OH',
    leaid: '3901362',
    lea_name: 'Achieve Career Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901363',
    lea_name: 'Lakeland Academy Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901365',
    lea_name: 'Horizon Science Academy Lorain',
  },
  {
    state_abbr: 'OH',
    leaid: '3901366',
    lea_name: 'Horizon Science Academy Dayton High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901368',
    lea_name: 'Village Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901369',
    lea_name: 'Greater Summit County Early Learning Center',
  },
  {
    state_abbr: 'OH',
    leaid: '3901370',
    lea_name: 'Bella Academy of Excellence',
  },
  {
    state_abbr: 'OH',
    leaid: '3901372',
    lea_name: 'Pleasant Education Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901374',
    lea_name: 'Dayton Regional STEM School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901375',
    lea_name: 'Columbus Bilingual Academy-North',
  },
  {
    state_abbr: 'OH',
    leaid: '3901376',
    lea_name: 'Northeast Ohio College Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901377',
    lea_name: 'Imagine Akron Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901378',
    lea_name: 'Everest High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901381',
    lea_name: 'Richland Academy School of Excellence',
  },
  {
    state_abbr: 'OH',
    leaid: '3901382',
    lea_name: 'Graham Expeditionary Middle School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901383',
    lea_name: 'Horizon Science Academy Dayton Downtown',
  },
  {
    state_abbr: 'OH',
    leaid: '3901389',
    lea_name: 'Horizon Science Academy Youngstown',
  },
  {
    state_abbr: 'OH',
    leaid: '3901393',
    lea_name: 'Cruiser Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901396',
    lea_name: 'Zenith Academy East',
  },
  {
    state_abbr: 'OH',
    leaid: '3901397',
    lea_name: 'Cleveland College Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901398',
    lea_name: 'Columbus Performance Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901403',
    lea_name: 'Constellation Schools: Stockyard Community Middle',
  },
  {
    state_abbr: 'OH',
    leaid: '3901405',
    lea_name: 'Near West Intergenerational School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901406',
    lea_name: 'Entrepreneurship Preparatory School - Woodland Hills Campus',
  },
  {
    state_abbr: 'OH',
    leaid: '3901407',
    lea_name: 'Foxfire Intermediate School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901409',
    lea_name: 'Thurgood Marshall High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901410',
    lea_name: 'New Beginnings Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901411',
    lea_name: 'Langston Hughes High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901412',
    lea_name: 'Road to Success Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901413',
    lea_name: 'The Arch Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901414',
    lea_name: 'George V. Voinovich Reclamation Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901415',
    lea_name: 'Frederick Douglass Reclamation Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901416',
    lea_name: 'Capital High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901417',
    lea_name: 'Patriot Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901419',
    lea_name: 'Imagine on Superior',
  },
  {
    state_abbr: 'OH',
    leaid: '3901420',
    lea_name: 'North Central Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901424',
    lea_name: 'Quest Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901427',
    lea_name: 'Southside Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901428',
    lea_name: 'Akros Middle School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901429',
    lea_name: 'Hardin Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901430',
    lea_name: 'Broadway Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901432',
    lea_name: 'Constellation Schools: Eastside Arts Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901433',
    lea_name: 'The Academy for Urban Scholars',
  },
  {
    state_abbr: 'OH',
    leaid: '3901434',
    lea_name: 'STEAM Academy of Warren',
  },
  {
    state_abbr: 'OH',
    leaid: '3901436',
    lea_name: 'Great Expectations Elementary School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901437',
    lea_name: 'Imagine Cleveland Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901439',
    lea_name: 'OAK Leadership Institute',
  },
  {
    state_abbr: 'OH',
    leaid: '3901441',
    lea_name: 'Woodland Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901442',
    lea_name: 'Pickerington Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901444',
    lea_name: 'Citizens Leadership Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901445',
    lea_name: 'Imagine Integrity Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901447',
    lea_name: 'Newbridge Math & Reading Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901449',
    lea_name: 'Cincinnati College Preparatory Academy East',
  },
  {
    state_abbr: 'OH',
    leaid: '3901450',
    lea_name: 'Townsend North Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901453',
    lea_name: 'Constellation Schools: Collinwood Village Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901454',
    lea_name: 'STEAM Academy of Akron',
  },
  {
    state_abbr: 'OH',
    leaid: '3901456',
    lea_name: 'Pearl Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901457',
    lea_name: 'University of Cleveland Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901460',
    lea_name: 'Accelerated Achievement Academy of Cincinnati',
  },
  {
    state_abbr: 'OH',
    leaid: '3901462',
    lea_name: 'Garfield Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901463',
    lea_name: 'Beacon Hill Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901465',
    lea_name: 'Global Village Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901466',
    lea_name: 'Imagine Woodbury Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901467',
    lea_name: 'Nexus Academy of Toledo',
  },
  {
    state_abbr: 'OH',
    leaid: '3901468',
    lea_name: 'The Haley School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901470',
    lea_name: 'Canton College Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901471',
    lea_name: 'STEAM Academy of Dayton',
  },
  {
    state_abbr: 'OH',
    leaid: '3901472',
    lea_name: 'Academy for Urban Scholars Youngstown',
  },
  {
    state_abbr: 'OH',
    leaid: '3901473',
    lea_name: 'Hope Academy for Autism',
  },
  {
    state_abbr: 'OH',
    leaid: '3901475',
    lea_name: 'SunBridge Schools',
  },
  {
    state_abbr: 'OH',
    leaid: '3901476',
    lea_name: 'West Carrollton Secondary Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901478',
    lea_name: 'Columbus Collegiate Academy - West',
  },
  {
    state_abbr: 'OH',
    leaid: '3901479',
    lea_name: 'Brookwood Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901480',
    lea_name: "A+ Children's Academy",
  },
  {
    state_abbr: 'OH',
    leaid: '3901482',
    lea_name: 'STEAM Academy of Warrensville Heights',
  },
  {
    state_abbr: 'OH',
    leaid: '3901483',
    lea_name: 'Nexus Academy of Columbus',
  },
  {
    state_abbr: 'OH',
    leaid: '3901485',
    lea_name: 'Cincinnati State STEM Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901486',
    lea_name: 'Citizens Academy East',
  },
  {
    state_abbr: 'OH',
    leaid: '3901487',
    lea_name: 'Ohio College Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901488',
    lea_name: 'Imagine Hill Avenue',
  },
  {
    state_abbr: 'OH',
    leaid: '3901490',
    lea_name: 'Metro Early College High School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901495',
    lea_name: 'Graham Primary School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901496',
    lea_name: 'Nexus Academy of Cleveland',
  },
  {
    state_abbr: 'OH',
    leaid: '3901498',
    lea_name: 'Stepstone Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901499',
    lea_name: 'Lake Erie College Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901501',
    lea_name: 'Southern Ohio Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901502',
    lea_name: 'Academy of Educational Excellence',
  },
  {
    state_abbr: 'OH',
    leaid: '3901503',
    lea_name: 'Akron Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901504',
    lea_name: 'Young Scholars Prep School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901505',
    lea_name: 'Village Preparatory School:: Woodland Hills Campus',
  },
  {
    state_abbr: 'OH',
    leaid: '3901508',
    lea_name: 'DECA PREP',
  },
  {
    state_abbr: 'OH',
    leaid: '3901510',
    lea_name: 'Life Skills High School of Cleveland',
  },
  {
    state_abbr: 'OH',
    leaid: '3901511',
    lea_name: 'Main Street Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901512',
    lea_name: 'Bio-Med Science Academy STEM School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901514',
    lea_name: 'West Park Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901515',
    lea_name: 'Aladdin Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901516',
    lea_name: 'Cincinnati Technology Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901517',
    lea_name: 'The Next Frontier Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901520',
    lea_name: 'Hope Learning Academy of Toledo',
  },
  {
    state_abbr: 'OH',
    leaid: '3901521',
    lea_name: 'Imagine Columbus Primary School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901522',
    lea_name: 'Cincinnati Learning Schools',
  },
  {
    state_abbr: 'OH',
    leaid: '3901523',
    lea_name: 'Provost Academy Ohio',
  },
  {
    state_abbr: 'OH',
    leaid: '3901524',
    lea_name: 'Winton Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901526',
    lea_name: 'East Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901527',
    lea_name: 'Chapelside Cleveland Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901529',
    lea_name: 'United Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901530',
    lea_name: 'Ohio Construction Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901531',
    lea_name: 'Lawrence County Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901533',
    lea_name: 'Franklinton Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901534',
    lea_name: 'Insight School of Ohio',
  },
  {
    state_abbr: 'OH',
    leaid: '3901535',
    lea_name: 'Global Impact STEM Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901536',
    lea_name: 'Dayton SMART Elementary School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901537',
    lea_name: 'Northmont Secondary Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901539',
    lea_name: 'Albert Einstein Academy for Letters, Arts and Sciences-Ohio',
  },
  {
    state_abbr: 'OH',
    leaid: '3901540',
    lea_name: 'STEAM Academy of  Cincinnati',
  },
  {
    state_abbr: 'OH',
    leaid: '3901542',
    lea_name: 'Watkins Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901543',
    lea_name: 'Lincoln Park Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901545',
    lea_name: 'Discovery Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901546',
    lea_name: 'Life Skills High School of Middletown',
  },
  {
    state_abbr: 'OH',
    leaid: '3901549',
    lea_name: 'Imagine Leadership Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901550',
    lea_name: 'Berwyn East Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901551',
    lea_name: 'Liberty Preparatory School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901552',
    lea_name: 'Celerity Tenacia Charter School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901554',
    lea_name: 'East Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901559',
    lea_name: 'Norwood Conversion Community School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901560',
    lea_name: 'REACH Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901561',
    lea_name: 'Cincinnati Generation Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901562',
    lea_name: 'Buckeye Preparatory Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901563',
    lea_name: 'Toledo SMART Elementary School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901564',
    lea_name: 'Lakeshore Intergenerational School',
  },
  {
    state_abbr: 'OH',
    leaid: '3901566',
    lea_name: 'Utica Shale Academy of Ohio',
  },
  {
    state_abbr: 'OH',
    leaid: '3901567',
    lea_name: 'Steel Academy',
  },
  {
    state_abbr: 'OH',
    leaid: '3901568',
    lea_name: 'T2 Honors Academy',
  },
  {
    state_abbr: 'OK',
    leaid: '4000075',
    lea_name: 'OKLAHOMA SCHOOL FOR THE DEAF',
  },
  {
    state_abbr: 'OK',
    leaid: '4000076',
    lea_name: 'OKLAHOMA SCHOOL FOR THE BLIND',
  },
  {
    state_abbr: 'OK',
    leaid: '4000751',
    lea_name: 'DEBORAH BROWN (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000752',
    lea_name: 'SANTA FE SOUTH ES (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000753',
    lea_name: 'DISCOVERY SCHOOLS OF TULSA',
  },
  {
    state_abbr: 'OK',
    leaid: '4000755',
    lea_name: 'CHEROKEE IMMERSION CHARTER SCH',
  },
  {
    state_abbr: 'OK',
    leaid: '4000756',
    lea_name: 'ALEXIS RAINBOW (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000766',
    lea_name: 'KIPP REACH COLL (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000767',
    lea_name: 'HARPER ACADEMY (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000768',
    lea_name: 'SEEWORTH ACADEMY (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000769',
    lea_name: 'LIGHTHOUSE ACADEMY (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000770',
    lea_name: 'DOVE SCIENCE ES(CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000771',
    lea_name: 'DOVE SCIENCE ACADEMY (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000772',
    lea_name: 'SANKOFA MIDDLE SCHL (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000773',
    lea_name: 'HARDING FINE ARTS (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000774',
    lea_name: 'SCHL ARTS/SCI (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000775',
    lea_name: 'HUPFELD/W VILLAGE (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000776',
    lea_name: 'SANTA FE SOUTH HS (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000777',
    lea_name: 'EPIC ONE ON ONE CHARTER SCHOOL',
  },
  {
    state_abbr: 'OK',
    leaid: '4000778',
    lea_name: 'OKLAHOMA VIRTUAL CHARTER ACAD',
  },
  {
    state_abbr: 'OK',
    leaid: '4000779',
    lea_name: 'SANTA FE SOUTH MS (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000780',
    lea_name: 'KIPP (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000781',
    lea_name: 'INDEPENDENCE MIDDLE SCHOOL (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000783',
    lea_name: 'ASTEC CHARTERS',
  },
  {
    state_abbr: 'OK',
    leaid: '4000784',
    lea_name: 'HARDING (CHARTER)',
  },
  {
    state_abbr: 'OK',
    leaid: '4000785',
    lea_name: 'INSIGHT SCHOOL OF OKLAHOMA',
  },
  {
    state_abbr: 'OK',
    leaid: '4000786',
    lea_name: 'OKLAHOMA CONNECTIONS ACADEMY',
  },
  {
    state_abbr: 'OK',
    leaid: '4000787',
    lea_name: 'JOHN W REX CHARTER ELEMENTARY',
  },
  {
    state_abbr: 'OR',
    leaid: '4100009',
    lea_name: 'Oregon Department of Education',
  },
  {
    state_abbr: 'OR',
    leaid: '4100032',
    lea_name: 'Lane ESD',
  },
  {
    state_abbr: 'OR',
    leaid: '4100042',
    lea_name: 'Malheur County SD 51',
  },
  {
    state_abbr: 'OR',
    leaid: '4100043',
    lea_name: 'ODE YCEP District',
  },
  {
    state_abbr: 'OR',
    leaid: '4100046',
    lea_name: 'ODE Head Start',
  },
  {
    state_abbr: 'OR',
    leaid: '4100049',
    lea_name: 'ODE LTCT District',
  },
  {
    state_abbr: 'OR',
    leaid: '4180180',
    lea_name: 'Multnomah ESD',
  },
  {
    state_abbr: 'PA',
    leaid: '4200020',
    lea_name: 'Robert Benjamin Wiley Community CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200021',
    lea_name: 'Dr Robert Ketterer CS Inc.',
  },
  {
    state_abbr: 'PA',
    leaid: '4200023',
    lea_name: 'Centre Learning Community CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200022',
    lea_name: 'Cresson Secure Treatment Unit',
  },
  {
    state_abbr: 'PA',
    leaid: '4200011',
    lea_name: 'Keystone Education Center CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200012',
    lea_name: 'Chester Co Family Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200013',
    lea_name: 'Community Academy of Philadelphia CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200014',
    lea_name: 'Philadelphia Harambee Inst CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200015',
    lea_name: 'World Communications CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200016',
    lea_name: 'Youth Build Phila CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200017',
    lea_name: 'Manchester Academic CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200018',
    lea_name: 'Urban Pathways 6-12 CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200019',
    lea_name: 'Urban League of Greater Pittsburgh CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200024',
    lea_name: 'Nittany Valley CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200025',
    lea_name: 'South Mountain Secure Trmnt Un',
  },
  {
    state_abbr: 'PA',
    leaid: '4200026',
    lea_name: 'La Academia Partnership Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200027',
    lea_name: 'Sylvan Heights Science CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200028',
    lea_name: 'Susq-Cyber CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200030',
    lea_name: 'School Lane CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200035',
    lea_name: 'Chester Community CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200036',
    lea_name: 'Preparatory CS of Mathematics Science Tech and Careers',
  },
  {
    state_abbr: 'PA',
    leaid: '4200038',
    lea_name: 'Imhotep Institute CHS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200039',
    lea_name: 'Alliance for Progress CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200040',
    lea_name: 'Multi-Cultural Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200041',
    lea_name: 'West Oak Lane CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200042',
    lea_name: 'Belmont Academy Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200043',
    lea_name: 'Eugenio Maria De Hostos CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200044',
    lea_name: 'Laboratory CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200047',
    lea_name: 'Wonderland CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200048',
    lea_name: 'Crispus Attucks Youthbuild CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200049',
    lea_name: 'Danville Ctr Adolescent Female',
  },
  {
    state_abbr: 'PA',
    leaid: '4200050',
    lea_name: 'Collegium CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200051',
    lea_name: 'MAST Community Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200052',
    lea_name: 'Christopher Columbus CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200053',
    lea_name: 'Charter High School for Architecture and Design',
  },
  {
    state_abbr: 'PA',
    leaid: '4200054',
    lea_name: 'Imani Education Circle CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200055',
    lea_name: 'Universal Institute CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200056',
    lea_name: 'Math Civics and Sciences CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200058',
    lea_name: 'Young Scholars CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200060',
    lea_name: 'Freire CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200061',
    lea_name: 'Philadelphia Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200062',
    lea_name: 'Mastery CS - Hardy Williams',
  },
  {
    state_abbr: 'PA',
    leaid: '4200063',
    lea_name: 'Sugar Valley Rural CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200064',
    lea_name: 'Spectrum CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200065',
    lea_name: 'Lincoln CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200067',
    lea_name: 'Renaissance Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200068',
    lea_name: 'Roberto Clemente CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200069',
    lea_name: 'Bucks County Montessori CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200070',
    lea_name: 'Souderton CS Collaborative',
  },
  {
    state_abbr: 'PA',
    leaid: '4200071',
    lea_name: 'Pennsylvania Cyber CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200072',
    lea_name: 'Wakisha CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200073',
    lea_name: 'Global Leadership Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200074',
    lea_name: 'Philadelphia Performing Arts CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200075',
    lea_name: 'New Foundations CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200076',
    lea_name: 'Esperanza Academy Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200077',
    lea_name: 'Franklin Towne CHS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200078',
    lea_name: 'Delaware Valley CHS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200079',
    lea_name: 'Mariana Bracetti Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200080',
    lea_name: 'Walter D Palmer Leadership Learning Partners CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200084',
    lea_name: 'Mastery CHS - Lenfest Campus',
  },
  {
    state_abbr: 'PA',
    leaid: '4200085',
    lea_name: 'People for People CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200086',
    lea_name: 'Richard Allen Preparatory CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200087',
    lea_name: 'Independence CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200088',
    lea_name: 'Pennsylvania Virtual CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200091',
    lea_name: '21st Century Cyber CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200092',
    lea_name: 'Russell Byers CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200093',
    lea_name: 'First Philadelphia Preparatory Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200094',
    lea_name: 'City CHS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200095',
    lea_name: 'Central PA Digital Learning Foundation CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200096',
    lea_name: 'Fell CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200098',
    lea_name: 'Lehigh Valley Academy Regional CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200099',
    lea_name: 'Lehigh Valley Charter High School for the Arts',
  },
  {
    state_abbr: 'PA',
    leaid: '4200100',
    lea_name: 'Center for Student Learning CS at Pennsbury',
  },
  {
    state_abbr: 'PA',
    leaid: '4200101',
    lea_name: 'Avon Grove CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200104',
    lea_name: 'Green Woods CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200105',
    lea_name: 'West Phila. Achievement CES',
  },
  {
    state_abbr: 'PA',
    leaid: '4200106',
    lea_name: 'Wissahickon CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200107',
    lea_name: 'Philadelphia Electrical & Tech CHS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200108',
    lea_name: 'Belmont Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200111',
    lea_name: 'Propel CS-Homestead',
  },
  {
    state_abbr: 'PA',
    leaid: '4200114',
    lea_name: 'New Day Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200115',
    lea_name: 'Perseus House CS of Excellence',
  },
  {
    state_abbr: 'PA',
    leaid: '4200118',
    lea_name: 'Infinity CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200119',
    lea_name: 'Commonwealth Connections Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200121',
    lea_name: 'Discovery Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200122',
    lea_name: 'KIPP Philadelphia Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200123',
    lea_name: 'Maritime Academy Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200124',
    lea_name: 'Montessori Regional CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200125',
    lea_name: 'Pennsylvania Distance Learning CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200126',
    lea_name: 'Bear Creek Community CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200127',
    lea_name: 'Pennsylvania Leadership Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200128',
    lea_name: 'Achievement House CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200129',
    lea_name: 'Ad Prima CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200130',
    lea_name: 'Philadelphia Montessori CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200131',
    lea_name: 'Khepera CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200132',
    lea_name: 'New Media Technology CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200133',
    lea_name: 'Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200134',
    lea_name: 'Propel CS-McKeesport',
  },
  {
    state_abbr: 'PA',
    leaid: '4200135',
    lea_name: 'Propel CS-East',
  },
  {
    state_abbr: 'PA',
    leaid: '4200136',
    lea_name: 'Tidioute Community CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200137',
    lea_name: 'Young Scholars of Central PA CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200139',
    lea_name: 'Northwood Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200140',
    lea_name: 'Agora Cyber CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200141',
    lea_name: 'Folk Arts-Cultural Treasures CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200142',
    lea_name: 'Beaver Area Academic CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200143',
    lea_name: 'Lincoln Park Performing Arts CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200144',
    lea_name: 'Mastery CS-Thomas Campus',
  },
  {
    state_abbr: 'PA',
    leaid: '4200145',
    lea_name: 'Mastery CS-Shoemaker Campus',
  },
  {
    state_abbr: 'PA',
    leaid: '4200749',
    lea_name: 'Evergreen Community CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200752',
    lea_name: 'Propel CS-Montour',
  },
  {
    state_abbr: 'PA',
    leaid: '4200753',
    lea_name: 'Widener Partnership CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200758',
    lea_name: 'Southwest Leadership Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200759',
    lea_name: 'Truebright Science Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200760',
    lea_name: 'Boys Latin of Philadelphia CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200761',
    lea_name: 'Mastery CS-Pickett Campus',
  },
  {
    state_abbr: 'PA',
    leaid: '4200762',
    lea_name: 'Keystone Academy Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200810',
    lea_name: 'Pan American Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200812',
    lea_name: 'Environmental Charter School at Frick Park',
  },
  {
    state_abbr: 'PA',
    leaid: '4200813',
    lea_name: 'Antonia Pantoja Community Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200814',
    lea_name: 'Vida Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200815',
    lea_name: 'KIPP West Philadelphia Preparatory Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200816',
    lea_name: 'Sankofa Freedom Academy Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200817',
    lea_name: 'Franklin Towne Charter Elementary School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200818',
    lea_name: 'Seven Generations Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200819',
    lea_name: 'Lincoln Leadership Academy Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200821',
    lea_name: 'Tacony Academy Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200822',
    lea_name: 'Eastern University Academy Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200823',
    lea_name: 'ARISE Academy Charter High School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200825',
    lea_name: 'Helen Thackston Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200827',
    lea_name: 'Universal Daroff Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200828',
    lea_name: 'Mastery CS-Harrity Campus',
  },
  {
    state_abbr: 'PA',
    leaid: '4200829',
    lea_name: 'Mastery CS-Smedley Campus',
  },
  {
    state_abbr: 'PA',
    leaid: '4200830',
    lea_name: 'Young Scholars Frederick Douglas Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200831',
    lea_name: 'Mastery CS-Mann Campus',
  },
  {
    state_abbr: 'PA',
    leaid: '4200832',
    lea_name: 'Lehigh Valley Dual Language Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200833',
    lea_name: 'Propel CS-Braddock Hills',
  },
  {
    state_abbr: 'PA',
    leaid: '4200834',
    lea_name: 'Universal Bluford Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200835',
    lea_name: 'ASPIRA Bilingual Cyber Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200836',
    lea_name: 'Gettysburg Montessori Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200839',
    lea_name: 'John B Stetson Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200840',
    lea_name: 'Mastery CS-Clymer Elementary',
  },
  {
    state_abbr: 'PA',
    leaid: '4200841',
    lea_name: 'Gillingham Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200843',
    lea_name: 'Olney Charter High School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200844',
    lea_name: 'Stone Valley Community CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200845',
    lea_name: 'Young Scholars of Western Pennsylvania CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200846',
    lea_name: 'York Academy Regional Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200847',
    lea_name: 'Urban Pathways K-5 College Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200848',
    lea_name: 'I-LEAD Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200849',
    lea_name: 'Propel CS-Northside',
  },
  {
    state_abbr: 'PA',
    leaid: '4200850',
    lea_name: 'Birney Preparatory Academy',
  },
  {
    state_abbr: 'PA',
    leaid: '4200851',
    lea_name: 'Mastery CS-Gratz Campus',
  },
  {
    state_abbr: 'PA',
    leaid: '4200852',
    lea_name: 'HOPE for Hyndman CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200853',
    lea_name: 'Penn Hills Charter School for Entrepreneurship',
  },
  {
    state_abbr: 'PA',
    leaid: '4200854',
    lea_name: 'Erie Rise Leadership Academy Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200855',
    lea_name: 'Universal Vare Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200856',
    lea_name: 'Universal Audenried Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200857',
    lea_name: 'Chester CS for the Arts',
  },
  {
    state_abbr: 'PA',
    leaid: '4200858',
    lea_name: 'Esperanza Cyber CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200859',
    lea_name: 'Propel CS-Pitcairn',
  },
  {
    state_abbr: 'PA',
    leaid: '4200860',
    lea_name: 'Arts Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200861',
    lea_name: 'Baden Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200862',
    lea_name: 'Mastery CS - Cleveland Elementary',
  },
  {
    state_abbr: 'PA',
    leaid: '4200863',
    lea_name: 'The Philadelphia CS for Arts and Sciences at HR Edmunds',
  },
  {
    state_abbr: 'PA',
    leaid: '4200864',
    lea_name: 'Medical Academy CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200865',
    lea_name: 'Education Plus Academy Cyber CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200866',
    lea_name: 'Universal Creighton Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200867',
    lea_name: 'Howard Gardner Multiple Intelligence CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200868',
    lea_name: 'ACT Academy Cyber CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200870',
    lea_name: 'Memphis Street Academy CS @ JP Jones',
  },
  {
    state_abbr: 'PA',
    leaid: '4200872',
    lea_name: 'Universal Alcorn CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200873',
    lea_name: 'Premier Arts and Science Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200874',
    lea_name: 'Mastery CS - Francis D. Pastorius Elementary',
  },
  {
    state_abbr: 'PA',
    leaid: '4200875',
    lea_name: 'Circle of Seasons Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200876',
    lea_name: 'Capital Area School for the Arts Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200877',
    lea_name: 'Young Scholars Kenderton CS',
  },
  {
    state_abbr: 'PA',
    leaid: '4200879',
    lea_name: 'Executive Education Academy Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200880',
    lea_name: 'Propel CS - Hazelwood',
  },
  {
    state_abbr: 'PA',
    leaid: '4200881',
    lea_name: 'Roberto Clemente Elementary Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4200882',
    lea_name: 'Hill House Passport Academy Charter School',
  },
  {
    state_abbr: 'PA',
    leaid: '4202600',
    lea_name: 'Lenape Tech',
  },
  {
    state_abbr: 'PA',
    leaid: '4202900',
    lea_name: 'Chester County Technical College High School',
  },
  {
    state_abbr: 'PA',
    leaid: '4206365',
    lea_name: 'Columbia-Montour AVTS',
  },
  {
    state_abbr: 'PA',
    leaid: '4207385',
    lea_name: 'Dauphin County Technical School',
  },
  {
    state_abbr: 'PA',
    leaid: '4209931',
    lea_name: 'Youth Forestry Camp #3',
  },
  {
    state_abbr: 'PA',
    leaid: '4209932',
    lea_name: 'Loysville Youth Dev Ctr',
  },
  {
    state_abbr: 'PA',
    leaid: '4209933',
    lea_name: 'North Central Secure Trmnt Unt',
  },
  {
    state_abbr: 'PA',
    leaid: '4209934',
    lea_name: 'Youth Forestry Camp #2',
  },
  {
    state_abbr: 'PA',
    leaid: '4212335',
    lea_name: 'Jefferson County-DuBois AVTS',
  },
  {
    state_abbr: 'PA',
    leaid: '4213390',
    lea_name: 'Lawrence County CTC',
  },
  {
    state_abbr: 'PA',
    leaid: '4225870',
    lea_name: 'West Side CTC',
  },
  {
    state_abbr: 'PA',
    leaid: '4226810',
    lea_name: 'York Co School of Technology',
  },
  {
    state_abbr: 'PA',
    leaid: '4280090',
    lea_name: 'Bucks County Technical High School',
  },
  {
    state_abbr: 'PA',
    leaid: '4280130',
    lea_name: 'Carbon Career & Technical Institute',
  },
  {
    state_abbr: 'PA',
    leaid: '4280220',
    lea_name: 'Connellsville Area Career & Technical Center',
  },
  {
    state_abbr: 'PA',
    leaid: '4280590',
    lea_name: 'North Montco Tech Career Center',
  },
  {
    state_abbr: 'PA',
    leaid: '4289110',
    lea_name: 'Intermediate Unit 1',
  },
  {
    state_abbr: 'PA',
    leaid: '4289130',
    lea_name: 'Allegheny IU 3',
  },
  {
    state_abbr: 'PA',
    leaid: '4289140',
    lea_name: 'Midwestern IU 4',
  },
  {
    state_abbr: 'PA',
    leaid: '4289150',
    lea_name: 'Northwest Tri-County IU 5',
  },
  {
    state_abbr: 'PA',
    leaid: '4289160',
    lea_name: 'Riverview IU 6',
  },
  {
    state_abbr: 'PA',
    leaid: '4289170',
    lea_name: 'Westmoreland IU 7',
  },
  {
    state_abbr: 'PA',
    leaid: '4289180',
    lea_name: 'Appalachia IU 8',
  },
  {
    state_abbr: 'PA',
    leaid: '4289190',
    lea_name: 'Seneca Highlands IU 9',
  },
  {
    state_abbr: 'PA',
    leaid: '4289200',
    lea_name: 'Central IU 10',
  },
  {
    state_abbr: 'PA',
    leaid: '4289220',
    lea_name: 'Lincoln IU 12',
  },
  {
    state_abbr: 'PA',
    leaid: '4289230',
    lea_name: 'Lancaster-Lebanon IU 13',
  },
  {
    state_abbr: 'PA',
    leaid: '4289240',
    lea_name: 'Berks County IU 14',
  },
  {
    state_abbr: 'PA',
    leaid: '4289250',
    lea_name: 'Capital Area IU 15',
  },
  {
    state_abbr: 'PA',
    leaid: '4289260',
    lea_name: 'Central Susquehanna IU 16',
  },
  {
    state_abbr: 'PA',
    leaid: '4289270',
    lea_name: 'BLaST IU 17',
  },
  {
    state_abbr: 'PA',
    leaid: '4289280',
    lea_name: 'Luzerne IU 18',
  },
  {
    state_abbr: 'PA',
    leaid: '4289290',
    lea_name: 'Northeastern Educational IU 19',
  },
  {
    state_abbr: 'PA',
    leaid: '4289300',
    lea_name: 'Colonial IU 20',
  },
  {
    state_abbr: 'PA',
    leaid: '4289310',
    lea_name: 'Carbon-Lehigh IU 21',
  },
  {
    state_abbr: 'PA',
    leaid: '4289320',
    lea_name: 'Bucks County IU 22',
  },
  {
    state_abbr: 'PA',
    leaid: '4289330',
    lea_name: 'Montgomery County IU 23',
  },
  {
    state_abbr: 'PA',
    leaid: '4289340',
    lea_name: 'Chester County IU 24',
  },
  {
    state_abbr: 'PA',
    leaid: '4289350',
    lea_name: 'Delaware County IU 25',
  },
  {
    state_abbr: 'PA',
    leaid: '4289370',
    lea_name: 'Beaver Valley IU 27',
  },
  {
    state_abbr: 'PA',
    leaid: '4289380',
    lea_name: 'ARIN IU 28',
  },
  {
    state_abbr: 'PA',
    leaid: '4289390',
    lea_name: 'Schuylkill IU 29',
  },
  {
    state_abbr: 'RI',
    leaid: '4400001',
    lea_name: 'R.I. Sch for the Deaf',
  },
  {
    state_abbr: 'RI',
    leaid: '4400003',
    lea_name: 'MET Career and Tech',
  },
  {
    state_abbr: 'RI',
    leaid: '4400004',
    lea_name: 'Davies Career and Tech',
  },
  {
    state_abbr: 'RI',
    leaid: '4400005',
    lea_name: 'Urban Collaborative',
  },
  {
    state_abbr: 'RI',
    leaid: '4400006',
    lea_name: 'Learning Community',
  },
  {
    state_abbr: 'RI',
    leaid: '4400008',
    lea_name: 'Beacon Charter School',
  },
  {
    state_abbr: 'RI',
    leaid: '4400009',
    lea_name: 'DCYF',
  },
  {
    state_abbr: 'RI',
    leaid: '4400014',
    lea_name: 'Segue Institute for Learning',
  },
  {
    state_abbr: 'RI',
    leaid: '4400015',
    lea_name: 'Blackstone Valley Prep, A RI Mayoral Academy',
  },
  {
    state_abbr: 'RI',
    leaid: '4400017',
    lea_name: 'The Greene School',
  },
  {
    state_abbr: 'RI',
    leaid: '4400018',
    lea_name: 'Trinity Academy for the Performing Arts',
  },
  {
    state_abbr: 'RI',
    leaid: '4400019',
    lea_name: 'Rhode Island Nurses Institute Middle College',
  },
  {
    state_abbr: 'RI',
    leaid: '4400021',
    lea_name: 'Achievement First Rhode Island',
  },
  {
    state_abbr: 'RI',
    leaid: '4400024',
    lea_name: 'Sheila Skip Nowell Leadership Academy',
  },
  {
    state_abbr: 'RI',
    leaid: '4400025',
    lea_name: 'Village Green Virtual',
  },
  {
    state_abbr: 'RI',
    leaid: '4400027',
    lea_name: 'The Hope Academy',
  },
  {
    state_abbr: 'RI',
    leaid: '4400028',
    lea_name: 'SouthSide Charter School',
  },
  {
    state_abbr: 'RI',
    leaid: '4400031',
    lea_name: 'Highlander',
  },
  {
    state_abbr: 'RI',
    leaid: '4400032',
    lea_name: 'Paul Cuffee Charter Sch',
  },
  {
    state_abbr: 'RI',
    leaid: '4400033',
    lea_name: 'Kingston Hill Academy',
  },
  {
    state_abbr: 'RI',
    leaid: '4400034',
    lea_name: 'International Charter',
  },
  {
    state_abbr: 'RI',
    leaid: '4400035',
    lea_name: 'The Compass School',
  },
  {
    state_abbr: 'RI',
    leaid: '4400036',
    lea_name: 'Blackstone Academy',
  },
  {
    state_abbr: 'SC',
    leaid: '4500001',
    lea_name: 'Dept Of Correction N04',
  },
  {
    state_abbr: 'SC',
    leaid: '4500003',
    lea_name: 'Felton Lab Sch SC H24',
  },
  {
    state_abbr: 'SC',
    leaid: '4500004',
    lea_name: 'Deaf & Blind School',
  },
  {
    state_abbr: 'SC',
    leaid: '4503907',
    lea_name: "Governor' School for Science and Mathematics",
  },
  {
    state_abbr: 'SC',
    leaid: '4503420',
    lea_name: 'Dept of Juvenile Justice',
  },
  {
    state_abbr: 'SC',
    leaid: '4503901',
    lea_name: 'SC Public Charter School District',
  },
  {
    state_abbr: 'SC',
    leaid: '4503903',
    lea_name: 'John de la Howe',
  },
  {
    state_abbr: 'SC',
    leaid: '4503904',
    lea_name: "Governor's School for the Arts and Humanities",
  },
  {
    state_abbr: 'SD',
    leaid: '4600029',
    lea_name: 'SD SCH FOR THE BLIND & VISUALLY IMPAIRED',
  },
  {
    state_abbr: 'SD',
    leaid: '4600035',
    lea_name: 'SD STATE TREATMENT AND REHABILITATION',
  },
  {
    state_abbr: 'TN',
    leaid: '4700143',
    lea_name: 'West Tenn School For Deaf',
  },
  {
    state_abbr: 'TN',
    leaid: '4700144',
    lea_name: 'Alvin C York Institute',
  },
  {
    state_abbr: 'TN',
    leaid: '4700145',
    lea_name: 'Tenn School For Blind',
  },
  {
    state_abbr: 'TN',
    leaid: '4700146',
    lea_name: 'Tenn Sch For Deaf',
  },
  {
    state_abbr: 'TN',
    leaid: '4700147',
    lea_name: 'Achievement School District',
  },
  {
    state_abbr: 'TN',
    leaid: '4700149',
    lea_name: 'Collierville',
  },
  {
    state_abbr: 'TN',
    leaid: '4700150',
    lea_name: 'Millington',
  },
  {
    state_abbr: 'TN',
    leaid: '4700151',
    lea_name: 'Germantown',
  },
  {
    state_abbr: 'TN',
    leaid: '4700152',
    lea_name: 'Arlington',
  },
  {
    state_abbr: 'TN',
    leaid: '4700153',
    lea_name: 'Bartlett',
  },
  {
    state_abbr: 'TN',
    leaid: '4700154',
    lea_name: 'Lakeland',
  },
  {
    state_abbr: 'TN',
    leaid: '4700480',
    lea_name: 'Carroll County',
  },
  {
    state_abbr: 'TX',
    leaid: '4800016',
    lea_name: 'TEXANS CAN ACADEMIES',
  },
  {
    state_abbr: 'TX',
    leaid: '4800017',
    lea_name: 'MEDICAL CENTER CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800018',
    lea_name: 'SER-NINOS CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800019',
    lea_name: 'ARISTOI CLASSICAL ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800020',
    lea_name: 'GEORGE I SANCHEZ CHARTER',
  },
  {
    state_abbr: 'TX',
    leaid: '4800021',
    lea_name: 'GIRLS & BOYS PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800132',
    lea_name: 'KATHERINE ANNE PORTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800004',
    lea_name: 'POR VIDA ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800014',
    lea_name: 'GEORGE GERVIN ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800022',
    lea_name: 'RAUL YZAGUIRRE SCHOOL FOR SUCCESS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800023',
    lea_name: 'IGNITE PUBLIC SCHOOLS AND COMMUNITY SERVICE CENTER',
  },
  {
    state_abbr: 'TX',
    leaid: '4800024',
    lea_name: 'WACO CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800025',
    lea_name: 'DR M L GARZA-GONZALEZ CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800029',
    lea_name: 'PEGASUS SCHOOL OF LIBERAL ARTS AND SCIENCES',
  },
  {
    state_abbr: 'TX',
    leaid: '4800030',
    lea_name: 'UPLIFT EDUCATION - NORTH HILLS PREPARATORY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800031',
    lea_name: 'UNIVERSITY OF HOUSTON CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800032',
    lea_name: 'ACADEMY OF ACCELERATED LEARNING INC',
  },
  {
    state_abbr: 'TX',
    leaid: '4800034',
    lea_name: 'CUMBERLAND ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800035',
    lea_name: 'ALIEF MONTESSORI COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800036',
    lea_name: 'BRIGHT IDEAS CHARTER',
  },
  {
    state_abbr: 'TX',
    leaid: '4800037',
    lea_name: 'BURNHAM WOOD CHARTER SCHOOL DISTRICT',
  },
  {
    state_abbr: 'TX',
    leaid: '4800041',
    lea_name: 'ADVANTAGE ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800042',
    lea_name: 'RAPOPORT ACADEMY PUBLIC SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800043',
    lea_name: 'BAY AREA CHARTER INC',
  },
  {
    state_abbr: 'TX',
    leaid: '4800044',
    lea_name: 'WAYSIDE SCHOOLS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800048',
    lea_name: 'RAVEN SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800049',
    lea_name: 'EXCEL ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800050',
    lea_name: 'HIGGS CARTER KING GIFTED & TALENTED CHARTER ACAD',
  },
  {
    state_abbr: 'TX',
    leaid: '4800053',
    lea_name: 'JOHN H WOOD JR PUBLIC CHARTER DISTRICT',
  },
  {
    state_abbr: 'TX',
    leaid: '4800054',
    lea_name: 'KIPP INC CHARTER',
  },
  {
    state_abbr: 'TX',
    leaid: '4800055',
    lea_name: 'LIFE SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800056',
    lea_name: 'MAINLAND PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800058',
    lea_name: 'NYOS CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800059',
    lea_name: 'POSITIVE SOLUTIONS CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800061',
    lea_name: 'NEW FRONTIERS CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800062',
    lea_name: 'SCHOOL OF EXCELLENCE IN EDUCATION',
  },
  {
    state_abbr: 'TX',
    leaid: '4800063',
    lea_name: 'SOUTHWEST PREPARATORY SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800064',
    lea_name: 'CHAPARRAL STAR ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800065',
    lea_name: 'SOUTH TEXAS EDUCATIONAL TECHNOLOGIES INC',
  },
  {
    state_abbr: 'TX',
    leaid: '4800066',
    lea_name: 'TEXAS EMPOWERMENT ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800067',
    lea_name: 'TRANSFORMATIVE CHARTER ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800068',
    lea_name: 'TREETOPS SCHOOL INTERNATIONAL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800069',
    lea_name: 'TWO DIMENSIONS PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800070',
    lea_name: 'UNIVERSAL ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800071',
    lea_name: 'UNIVERSITY OF TEXAS UNIVERSITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800072',
    lea_name: 'NOVA ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800073',
    lea_name: 'THE VARNETT PUBLIC SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800074',
    lea_name: 'PINEYWOODS COMMUNITY ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800075',
    lea_name: 'RICHARD MILBURN ALTER HIGH SCHOOL (KILLEEN)',
  },
  {
    state_abbr: 'TX',
    leaid: '4800076',
    lea_name: 'PRIORITY CHARTER SCHOOLS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800077',
    lea_name: 'BEXAR COUNTY ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800080',
    lea_name: 'RADIANCE ACADEMY OF LEARNING',
  },
  {
    state_abbr: 'TX',
    leaid: '4800082',
    lea_name: 'SHEKINAH RADIANCE ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800086',
    lea_name: 'BRAZOS SCHOOL FOR INQUIRY & CREATIVITY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800089',
    lea_name: 'LUMIN EDUCATION',
  },
  {
    state_abbr: 'TX',
    leaid: '4800090',
    lea_name: 'ACADEMY OF DALLAS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800091',
    lea_name: 'CHILDREN FIRST ACADEMY OF DALLAS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800092',
    lea_name: 'TRINITY BASIN PREPARATORY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800093',
    lea_name: 'ACADEMY FOR ACADEMIC EXCELLENCE',
  },
  {
    state_abbr: 'TX',
    leaid: '4800094',
    lea_name: 'FAITH FAMILY ACADEMY OF OAK CLIFF',
  },
  {
    state_abbr: 'TX',
    leaid: '4800095',
    lea_name: 'A W BROWN-FELLOWSHIP LEADERSHIP ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800096',
    lea_name: 'FOCUS LEARNING ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800098',
    lea_name: 'JEAN MASSIEU ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800105',
    lea_name: 'WAXAHACHIE FAITH FAMILY ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800106',
    lea_name: 'PASO DEL NORTE ACADEMY CHARTER DISTRICT',
  },
  {
    state_abbr: 'TX',
    leaid: '4800108',
    lea_name: 'ODYSSEY ACADEMY INC',
  },
  {
    state_abbr: 'TX',
    leaid: '4800109',
    lea_name: 'EAST TEXAS CHARTER SCHOOLS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800113',
    lea_name: 'AMIGOS POR VIDA-FRIENDS FOR LIFE PUB CHTR SCH',
  },
  {
    state_abbr: 'TX',
    leaid: '4800115',
    lea_name: 'HOUSTON HEIGHTS HIGH SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800118',
    lea_name: 'HOUSTON GATEWAY ACADEMY INC',
  },
  {
    state_abbr: 'TX',
    leaid: '4800119',
    lea_name: 'HOUSTON HEIGHTS LEARNING ACADEMY INC',
  },
  {
    state_abbr: 'TX',
    leaid: '4800121',
    lea_name: 'LA AMISTAD LOVE & LEARNING ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800124',
    lea_name: 'CALVIN NELMS CHARTER SCHOOLS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800125',
    lea_name: 'SOUTHWEST SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800127',
    lea_name: 'COMQUEST ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800133',
    lea_name: 'MIDVALLEY ACADEMY CHARTER DISTRICT',
  },
  {
    state_abbr: 'TX',
    leaid: '4800139',
    lea_name: 'TEKOA ACADEMY OF ACCELERATED STUDIES STEM SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800141',
    lea_name: 'RISE ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800142',
    lea_name: 'SOUTH PLAINS ACADEMY CHARTER DISTRICT',
  },
  {
    state_abbr: 'TX',
    leaid: '4800148',
    lea_name: 'MIDLAND ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800151',
    lea_name: 'TEXAS SERENITY ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800158',
    lea_name: 'ARLINGTON CLASSICS ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800159',
    lea_name: 'ERATH EXCELS ACADEMY INC',
  },
  {
    state_abbr: 'TX',
    leaid: '4800163',
    lea_name: 'TEXAS COLLEGE PREPARATORY ACADEMIES',
  },
  {
    state_abbr: 'TX',
    leaid: '4800171',
    lea_name: 'RANCH ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800174',
    lea_name: 'GATEWAY ACADEMY CHARTER DISTRICT',
  },
  {
    state_abbr: 'TX',
    leaid: '4800177',
    lea_name: "ST MARY'S ACADEMY CHARTER SCHOOL",
  },
  {
    state_abbr: 'TX',
    leaid: '4800179',
    lea_name: 'JUBILEE ACADEMIC CENTER',
  },
  {
    state_abbr: 'TX',
    leaid: '4800180',
    lea_name: 'SAN ANTONIO TECHNOLOGY ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800184',
    lea_name: 'GATEWAY CHARTER ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800185',
    lea_name: 'ALPHA CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800186',
    lea_name: 'EDUCATION CENTER INTERNATIONAL ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800187',
    lea_name: 'TEXAS EDUCATION CENTERS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800190',
    lea_name: 'BEATRICE MAYES INSTITUTE CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800191',
    lea_name: 'NORTHWEST PREPARATORY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800192',
    lea_name: 'ACCELERATED INTERMEDIATE ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800193',
    lea_name: 'ZOE LEARNING ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800194',
    lea_name: 'TEXAS PREPARATORY SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800195',
    lea_name: 'VANGUARD ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800199',
    lea_name: 'ACADEMY OF CAREERS AND TECHNOLOGIES CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800200',
    lea_name: 'SAN ANTONIO SCHOOL FOR INQUIRY & CREATIVITY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800201',
    lea_name: 'NOVA ACADEMY (SOUTHEAST)',
  },
  {
    state_abbr: 'TX',
    leaid: '4800202',
    lea_name: 'WINFREE ACADEMY CHARTER SCHOOLS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800203',
    lea_name: 'A+ ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800204',
    lea_name: 'INSPIRED VISION ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800205',
    lea_name: 'EL PASO ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800207',
    lea_name: 'PREMIER HIGH SCHOOLS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800209',
    lea_name: 'YES PREP PUBLIC SCHOOLS INC',
  },
  {
    state_abbr: 'TX',
    leaid: '4800210',
    lea_name: 'HARMONY SCIENCE ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800211',
    lea_name: 'IDEA PUBLIC SCHOOLS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800213',
    lea_name: 'PANOLA CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800214',
    lea_name: 'BRAZOS RIVER CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800219',
    lea_name: 'PHOENIX CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800221',
    lea_name: 'EHRHART SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800227',
    lea_name: 'BIG SPRINGS CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800231',
    lea_name: 'FORT WORTH ACADEMY OF FINE ARTS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800232',
    lea_name: 'CEDARS INTERNATIONAL ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800235',
    lea_name: 'ORENDA CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800236',
    lea_name: 'EVOLUTION ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800237',
    lea_name: 'GOLDEN RULE CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800240',
    lea_name: 'PROMISE COMMUNITY SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800241',
    lea_name: 'HARMONY SCIENCE ACADEMY (AUSTIN)',
  },
  {
    state_abbr: 'TX',
    leaid: '4800246',
    lea_name: 'LIGHTHOUSE CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800248',
    lea_name: 'ST ANTHONY SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800251',
    lea_name: 'UNIVERSITY OF TEXAS ELEMENTARY CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800252',
    lea_name: 'TEXAS SCH FOR THE BLIND & VISUALLY IMPAIRED',
  },
  {
    state_abbr: 'TX',
    leaid: '4800253',
    lea_name: 'TEXAS SCH FOR THE DEAF',
  },
  {
    state_abbr: 'TX',
    leaid: '4800254',
    lea_name: 'WESTLAKE ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800255',
    lea_name: 'EAST FORT WORTH MONTESSORI ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800257',
    lea_name: 'KIPP SAN ANTONIO',
  },
  {
    state_abbr: 'TX',
    leaid: '4800258',
    lea_name: 'SCHOOL OF SCIENCE AND TECHNOLOGY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800259',
    lea_name: 'TRINITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800260',
    lea_name: 'KIPP DALLAS-FORT WORTH',
  },
  {
    state_abbr: 'TX',
    leaid: '4800262',
    lea_name: 'MEYERPARK ELEMENTARY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800263',
    lea_name: 'DRAW ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800264',
    lea_name: 'KIPP AUSTIN PUBLIC SCHOOLS INC',
  },
  {
    state_abbr: 'TX',
    leaid: '4800265',
    lea_name: 'AUSTIN DISCOVERY SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800266',
    lea_name: 'HARMONY SCIENCE ACAD (SAN ANTONIO)',
  },
  {
    state_abbr: 'TX',
    leaid: '4800268',
    lea_name: 'BROOKS ACADEMY OF SCIENCE AND ENGINEERING',
  },
  {
    state_abbr: 'TX',
    leaid: '4800269',
    lea_name: 'UPLIFT EDUCATION - PEAK PREPARATORY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800270',
    lea_name: 'LA ACADEMIA DE ESTRELLAS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800271',
    lea_name: 'RICHLAND COLLEGIATE HIGH SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800272',
    lea_name: 'HARMONY SCIENCE ACAD (EL PASO)',
  },
  {
    state_abbr: 'TX',
    leaid: '4800274',
    lea_name: 'HARMONY SCHOOL OF EXCELLENCE',
  },
  {
    state_abbr: 'TX',
    leaid: '4800275',
    lea_name: 'STEP CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800278',
    lea_name: 'CROSSTIMBERS ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800279',
    lea_name: 'CORPUS CHRISTI MONTESSORI SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800283',
    lea_name: 'THE RHODES SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800284',
    lea_name: 'AMBASSADORS PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4800286',
    lea_name: 'LA FE PREPARATORY SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4800287',
    lea_name: 'TEXAS SCHOOL OF THE ARTS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800288',
    lea_name: 'CITYSCAPE SCHOOLS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800289',
    lea_name: 'SEASHORE CHARTER SCHOOLS',
  },
  {
    state_abbr: 'TX',
    leaid: '4800292',
    lea_name: 'HARMONY SCIENCE ACAD (WACO)',
  },
  {
    state_abbr: 'TX',
    leaid: '4801394',
    lea_name: 'VISTA DEL FUTURO CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4801395',
    lea_name: 'STEPHEN F AUSTIN STATE UNIVERSITY CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4801399',
    lea_name: 'UPLIFT EDUCATION - HAMPTON PREPARATORY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801400',
    lea_name: 'SCHOOL OF SCIENCE AND TECHNOLOGY DISCOVERY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801401',
    lea_name: 'UPLIFT EDUCATION-SUMMIT INTERNATIONAL PREPARATORY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801404',
    lea_name: 'MEADOWLAND CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4801405',
    lea_name: 'HARMONY SCHOOL OF SCIENCE - HOUSTON',
  },
  {
    state_abbr: 'TX',
    leaid: '4801406',
    lea_name: 'UPLIFT EDUCATION - WILLIAMS PREPARATORY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801407',
    lea_name: 'CHAPEL HILL ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801409',
    lea_name: 'TEXAS LEADERSHIP',
  },
  {
    state_abbr: 'TX',
    leaid: '4801410',
    lea_name: 'BOB HOPE SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4801411',
    lea_name: 'CITY CENTER HEALTH CAREERS',
  },
  {
    state_abbr: 'TX',
    leaid: '4801412',
    lea_name: 'HENRY FORD ACADEMY ALAMEDA SCHOOL FOR ART + DESIGN',
  },
  {
    state_abbr: 'TX',
    leaid: '4801414',
    lea_name: 'THE EAST AUSTIN COLLEGE PREP ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801415',
    lea_name: 'MANARA ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801416',
    lea_name: 'ARROW ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801417',
    lea_name: 'COMPASS ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4801418',
    lea_name: 'THE LAWSON ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801419',
    lea_name: 'VICTORY PREP',
  },
  {
    state_abbr: 'TX',
    leaid: '4801420',
    lea_name: 'LEADERSHIP PREP SCHOOL',
  },
  {
    state_abbr: 'TX',
    leaid: '4801421',
    lea_name: 'NEWMAN INTERNATIONAL ACADEMY OF ARLINGTON',
  },
  {
    state_abbr: 'TX',
    leaid: '4801422',
    lea_name: 'MERIDIAN WORLD SCHOOL LLC',
  },
  {
    state_abbr: 'TX',
    leaid: '4801423',
    lea_name: 'PREMIER LEARNING ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801424',
    lea_name: 'GLOBAL LEARNING VILLAGE',
  },
  {
    state_abbr: 'TX',
    leaid: '4801425',
    lea_name: 'UME PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801426',
    lea_name: 'FALLBROOK COLLEGE PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801427',
    lea_name: 'UT TYLER INNOVATION ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801428',
    lea_name: 'LEGACY PREPARATORY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801429',
    lea_name: 'IMAGINE INTERNATIONAL ACADEMY OF NORTH TEXAS',
  },
  {
    state_abbr: 'TX',
    leaid: '4801430',
    lea_name: 'PRIME PREP ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801431',
    lea_name: 'AUSTIN ACHIEVE PUBLIC SCHOOLS',
  },
  {
    state_abbr: 'TX',
    leaid: '4801432',
    lea_name: 'TEXAS JUVENILE JUSTICE DEPARTMENT',
  },
  {
    state_abbr: 'TX',
    leaid: '4801433',
    lea_name: 'EXCELLENCE IN LEADERSHIP ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801434',
    lea_name: 'THE PRO-VISION ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801435',
    lea_name: 'ELEANOR KOLITZ HEBREW LANGUAGE ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801436',
    lea_name: 'VILLAGE TECH SCHOOLS',
  },
  {
    state_abbr: 'TX',
    leaid: '4801437',
    lea_name: 'C O R E ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801438',
    lea_name: 'BASIS TEXAS',
  },
  {
    state_abbr: 'TX',
    leaid: '4801439',
    lea_name: 'EL PASO LEADERSHIP ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801440',
    lea_name: 'INTERNATIONAL LEADERSHIP OF TEXAS (ILT)',
  },
  {
    state_abbr: 'TX',
    leaid: '4801441',
    lea_name: 'GREAT HEARTS TEXAS',
  },
  {
    state_abbr: 'TX',
    leaid: '4801442',
    lea_name: 'MONTESSORI FOR ALL',
  },
  {
    state_abbr: 'TX',
    leaid: '4801443',
    lea_name: 'UTPB STEM ACADEMY',
  },
  {
    state_abbr: 'TX',
    leaid: '4801448',
    lea_name: 'THE EXCEL CENTER (FOR ADULTS)',
  },
  {
    state_abbr: 'TX',
    leaid: '4837150',
    lea_name: 'SOUTH TEXAS ISD',
  },
  {
    state_abbr: 'UT',
    leaid: '4900023',
    lea_name: 'BEEHIVE SCIENCE & TECHNOLOGY ACADEMY (BSTA)',
  },
  {
    state_abbr: 'UT',
    leaid: '4900024',
    lea_name: 'WASATCH PEAK ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900025',
    lea_name: 'NORTH STAR ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900070',
    lea_name: 'EDITH BOWEN LABORATORY SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900071',
    lea_name: 'CANYON RIM ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900072',
    lea_name: 'GUADALUPE SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900005',
    lea_name: 'AMERICAN PREPARATORY ACADEMY--LEA',
  },
  {
    state_abbr: 'UT',
    leaid: '4900008',
    lea_name: 'PINNACLE CANYON ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900009',
    lea_name: 'CITY ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900011',
    lea_name: 'SOLDIER HOLLOW CHARTER SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900012',
    lea_name: 'TUACAHN HIGH SCHOOL FOR THE PERFORMING ARTS',
  },
  {
    state_abbr: 'UT',
    leaid: '4900013',
    lea_name: 'UINTAH RIVER HIGH',
  },
  {
    state_abbr: 'UT',
    leaid: '4900014',
    lea_name: 'JOHN HANCOCK CHARTER SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900015',
    lea_name: 'THOMAS EDISON - LEA',
  },
  {
    state_abbr: 'UT',
    leaid: '4900016',
    lea_name: 'TIMPANOGOS ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900017',
    lea_name: 'ACADEMY FOR MATH ENGINEERING & SCIENCE (AMES)',
  },
  {
    state_abbr: 'UT',
    leaid: '4900018',
    lea_name: 'SALT LAKE ARTS ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900019',
    lea_name: 'FAST FORWARD HIGH',
  },
  {
    state_abbr: 'UT',
    leaid: '4900020',
    lea_name: 'UTAH COUNTY ACADEMY OF SCIENCE (UCAS)',
  },
  {
    state_abbr: 'UT',
    leaid: '4900021',
    lea_name: 'ODYSSEY CHARTER SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900022',
    lea_name: 'LINCOLN ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900032',
    lea_name: 'REAGAN ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900033',
    lea_name: 'AMERICAN LEADERSHIP ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900034',
    lea_name: 'NAVIGATOR POINTE ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900035',
    lea_name: 'MOAB CHARTER SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900036',
    lea_name: 'EAST HOLLYWOOD HIGH',
  },
  {
    state_abbr: 'UT',
    leaid: '4900037',
    lea_name: 'SUCCESS ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900038',
    lea_name: 'RENAISSANCE ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900039',
    lea_name: 'INTECH COLLEGIATE HIGH SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900040',
    lea_name: 'CHANNING HALL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900041',
    lea_name: 'ENTHEOS ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900042',
    lea_name: 'SPECTRUM ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900043',
    lea_name: 'LAKEVIEW ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900044',
    lea_name: 'SYRACUSE ARTS ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900045',
    lea_name: 'LEGACY PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900046',
    lea_name: 'GEORGE WASHINGTON ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900047',
    lea_name: 'AMERICAN PREPARATORY ACADEMY - SALEM',
  },
  {
    state_abbr: 'UT',
    leaid: '4900048',
    lea_name: 'NOAH WEBSTER ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900049',
    lea_name: 'MONTICELLO ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900050',
    lea_name: 'SALT LAKE SCHOOL FOR THE PERFORMING ARTS',
  },
  {
    state_abbr: 'UT',
    leaid: '4900051',
    lea_name: 'MOUNTAINVILLE ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900052',
    lea_name: 'PARADIGM HIGH SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900056',
    lea_name: 'KARL G MAESER PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900058',
    lea_name: 'OGDEN PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900061',
    lea_name: 'WALDEN SCHOOL OF LIBERAL ARTS',
  },
  {
    state_abbr: 'UT',
    leaid: '4900062',
    lea_name: 'FREEDOM PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900063',
    lea_name: 'NO UT ACAD FOR MATH ENGINEERING & SCIENCE (NUAMES)',
  },
  {
    state_abbr: 'UT',
    leaid: '4900064',
    lea_name: 'RANCHES ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900065',
    lea_name: 'DAVINCI ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900066',
    lea_name: 'SUMMIT ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900067',
    lea_name: 'ITINERIS EARLY COLLEGE HIGH',
  },
  {
    state_abbr: 'UT',
    leaid: '4900068',
    lea_name: 'NORTH DAVIS PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900069',
    lea_name: 'UTAH SCHOOLS FOR DEAF & BLIND',
  },
  {
    state_abbr: 'UT',
    leaid: '4900073',
    lea_name: 'DUAL IMMERSION ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900074',
    lea_name: 'CS LEWIS ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900118',
    lea_name: 'OPEN CLASSROOM',
  },
  {
    state_abbr: 'UT',
    leaid: '4900122',
    lea_name: 'GATEWAY PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900123',
    lea_name: 'SALT LAKE CENTER FOR SCIENCE EDUCATION',
  },
  {
    state_abbr: 'UT',
    leaid: '4900124',
    lea_name: 'PROVIDENCE HALL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900125',
    lea_name: 'ROCKWELL CHARTER HIGH SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900130',
    lea_name: 'UTAH VIRTUAL ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900131',
    lea_name: 'MERIT COLLEGE PREPARATORY ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900132',
    lea_name: 'QUEST ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900133',
    lea_name: 'VENTURE ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900136',
    lea_name: 'EXCELSIOR ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900137',
    lea_name: 'HAWTHORN ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900138',
    lea_name: 'MOUNTAIN HEIGHTS ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900139',
    lea_name: 'JEFFERSON ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900140',
    lea_name: 'EARLY LIGHT ACADEMY AT DAYBREAK',
  },
  {
    state_abbr: 'UT',
    leaid: '4900141',
    lea_name: 'VISTA AT ENTRADA SCHOOL OF PERFORMING ARTS AND TECHNOLOGY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900143',
    lea_name: 'CANYON GROVE ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900144',
    lea_name: 'MARIA MONTESSORI ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900145',
    lea_name: 'WEILENMANN SCHOOL OF DISCOVERY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900146',
    lea_name: 'BEAR RIVER CHARTER SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900147',
    lea_name: 'GOOD FOUNDATIONS ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900148',
    lea_name: 'SUMMIT ACADEMY HIGH SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900149',
    lea_name: 'ALIANZA ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900151',
    lea_name: 'UTAH CONNECTIONS ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900154',
    lea_name: 'ARISTOTLE ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900155',
    lea_name: 'ENDEAVOR HALL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900156',
    lea_name: 'HIGHMARK CHARTER SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900157',
    lea_name: 'PROMONTORY SCHOOL OF EXPEDITIONARY LEARNING',
  },
  {
    state_abbr: 'UT',
    leaid: '4900158',
    lea_name: 'VALLEY ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900159',
    lea_name: 'PACIFIC HERITAGE ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900160',
    lea_name: 'VOYAGE ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900161',
    lea_name: 'ESPERANZA SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900162',
    lea_name: 'LEADERSHIP LEARNING ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900163',
    lea_name: 'WASATCH INSTITUTE OF TECHNOLOGY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900164',
    lea_name: 'PIONEER HIGH SCHOOL FOR THE PERFORMING ARTS',
  },
  {
    state_abbr: 'UT',
    leaid: '4900165',
    lea_name: 'MANA ACADEMY CHARTER SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900166',
    lea_name: 'UTAH INTERNATIONAL CHARTER SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900167',
    lea_name: 'WEBER STATE UNIVERSITY CHARTER ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900168',
    lea_name: 'WINTER SPORTS SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900169',
    lea_name: 'DIXIE MONTESSORI ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900170',
    lea_name: 'SCHOLAR ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900171',
    lea_name: 'UTAH CAREER PATH HIGH SCHOOL',
  },
  {
    state_abbr: 'UT',
    leaid: '4900172',
    lea_name: 'AMERICAN INTERNATIONAL SCHOOL OF UTAH',
  },
  {
    state_abbr: 'UT',
    leaid: '4900173',
    lea_name: 'KAIROS ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900174',
    lea_name: 'ASCENT ACADEMIES OF UTAH',
  },
  {
    state_abbr: 'UT',
    leaid: '4900175',
    lea_name: 'MOUNTAIN WEST MONTESSORI ACADEMY',
  },
  {
    state_abbr: 'UT',
    leaid: '4900176',
    lea_name: 'UTAH MILITARY ACADEMY',
  },
  {
    state_abbr: 'VA',
    leaid: '5100058',
    lea_name: 'VSDB-STAUNTON',
  },
  {
    state_abbr: 'VA',
    leaid: '5100070',
    lea_name: 'DEPARTMENT OF JUVENILE JUSTICE',
  },
  {
    state_abbr: 'VT',
    leaid: '5000005',
    lea_name: 'Woodside Juvenile Rehabilitation Center',
  },
  {
    state_abbr: 'VT',
    leaid: '5000023',
    lea_name: 'Andover School District',
  },
  {
    state_abbr: 'VT',
    leaid: '5000028',
    lea_name: 'Department of Corrections',
  },
  {
    state_abbr: 'VT',
    leaid: '5003150',
    lea_name: 'Chester School District',
  },
  {
    state_abbr: 'VT',
    leaid: '5004380',
    lea_name: 'Greensboro School District',
  },
  {
    state_abbr: 'WA',
    leaid: '5300005',
    lea_name: 'Educational Service District 112',
  },
  {
    state_abbr: 'WA',
    leaid: '5300007',
    lea_name: 'Olympic Educational Service District 114',
  },
  {
    state_abbr: 'WA',
    leaid: '5300008',
    lea_name: 'Northwest Educational Service District 189',
  },
  {
    state_abbr: 'WA',
    leaid: '5300010',
    lea_name: 'Educational Service District 101',
  },
  {
    state_abbr: 'WA',
    leaid: '5300011',
    lea_name: 'Educational Service District 113',
  },
  {
    state_abbr: 'WA',
    leaid: '5300014',
    lea_name: 'Bates Technical College',
  },
  {
    state_abbr: 'WA',
    leaid: '5300015',
    lea_name: 'WA State Center for Childhood Deafness and Hearing Loss',
  },
  {
    state_abbr: 'WA',
    leaid: '5300313',
    lea_name: 'Lake Washington Institute of Technology',
  },
  {
    state_abbr: 'WA',
    leaid: '5300318',
    lea_name: 'Office of the Governor (Sch for Blind)',
  },
  {
    state_abbr: 'WA',
    leaid: '5300324',
    lea_name: 'Washington Military Department',
  },
  {
    state_abbr: 'WA',
    leaid: '5300328',
    lea_name: 'Suquamish Tribal Education Department',
  },
  {
    state_abbr: 'WI',
    leaid: '5500035',
    lea_name: 'Wisconsin Department of Corrections',
  },
  {
    state_abbr: 'WI',
    leaid: '5500036',
    lea_name: 'Wisconsin Department of Health Services',
  },
  {
    state_abbr: 'WI',
    leaid: '5500039',
    lea_name: 'Downtown Montessori Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500041',
    lea_name: 'Central City Cyberschool Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500042',
    lea_name: 'Milwaukee Academy of Science Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500043',
    lea_name: 'School for Early Development and Achievement (SEDA) Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500045',
    lea_name: '21st Century Preparatory School Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500047',
    lea_name: 'DLH Academy Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500048',
    lea_name: 'Milwaukee College Preparatory School -- 36th Street Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500050',
    lea_name: 'Tenor High School Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500057',
    lea_name: 'Seeds of Health Elementary Program Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500059',
    lea_name: 'Bruce Guadalupe Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500062',
    lea_name: "King's Academy Agency",
  },
  {
    state_abbr: 'WI',
    leaid: '5500063',
    lea_name: 'Urban Day School Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500064',
    lea_name: 'Veritas High Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500065',
    lea_name: 'Milwaukee Scholars Charter School Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500066',
    lea_name: 'Milwaukee Collegiate Academy Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500067',
    lea_name: 'Milwaukee Math and Science Academy Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500068',
    lea_name: 'North Point Lighthouse Charter School Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500069',
    lea_name: 'Escuela Verde Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500070',
    lea_name: 'Woodlands School East Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500071',
    lea_name: 'Rocketship Southside Community Prep Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500072',
    lea_name: 'Milwaukee College Prep School: Lola Rowe North Campus Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500113',
    lea_name: 'Woodlands School Agency',
  },
  {
    state_abbr: 'WI',
    leaid: '5500114',
    lea_name: 'Capitol West Academy Agency',
  },
  {
    state_abbr: 'WV',
    leaid: '5400511',
    lea_name: 'INSTITUTIONAL EDUCATIONAL PROGRAMS',
  },
  {
    state_abbr: 'WV',
    leaid: '5401621',
    lea_name: 'WV SCHOOLS FOR THE DEAF AND THE BLIND',
  },
  {
    state_abbr: 'WY',
    leaid: '5680254',
    lea_name: "Saint Stephen's Indian School Admin Office",
  },
];

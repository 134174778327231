export const DC = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0024, 38.9657],
            [-76.9094, 38.8929],
            [-77.039, 38.7916],
            [-77.048, 38.8413],
            [-77.1198, 38.9343],
            [-77.0024, 38.9657],
          ],
        ],
      },
      properties: {
        STATEFP: '11',
        SCSDLEA: '',
        GEOID: '1100030',
        NAME: 'District of Columbia Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158364990,
        AWATER: 18633403,
        INTPTLAT: '+38.9041031',
        INTPTLON: '-077.0172290',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
  ],
};

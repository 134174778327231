export const OR = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9434, 42.9171],
            [-119.9316, 42.917],
            [-119.9324, 43.179],
            [-119.8968, 43.179],
            [-119.8964, 43.6103],
            [-119.8982, 43.6983],
            [-119.7775, 43.6981],
            [-119.7757, 43.9591],
            [-119.6576, 43.9591],
            [-119.6143, 43.9592],
            [-119.6147, 43.8732],
            [-119.4134, 43.8727],
            [-119.4136, 43.7859],
            [-119.4932, 43.7861],
            [-119.5343, 43.6168],
            [-119.415, 43.6126],
            [-119.4188, 43.4393],
            [-119.1817, 43.4372],
            [-119.0037, 43.4372],
            [-119.0038, 43.4793],
            [-118.828, 43.4789],
            [-118.6719, 43.5912],
            [-118.6616, 43.7415],
            [-118.7178, 43.8719],
            [-118.8168, 43.873],
            [-118.8166, 43.96],
            [-118.8162, 44.0477],
            [-118.4673, 44.0404],
            [-118.4663, 44.0404],
            [-118.2274, 44.04],
            [-118.2325, 43.6123],
            [-118.2321, 43.4368],
            [-118.2285, 43.0861],
            [-118.2285, 43.0428],
            [-118.2164, 42.8059],
            [-118.2147, 42.276],
            [-118.1972, 41.997],
            [-118.8296, 41.9926],
            [-119.3242, 41.9939],
            [-119.3602, 41.9942],
            [-119.3636, 42.2201],
            [-119.3653, 42.749],
            [-119.9439, 42.7463],
            [-119.9434, 42.9171],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '03630',
        GEOID: '4103630',
        NAME: 'Harney County Union High School District 1J',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22857715086,
        AWATER: 238857851,
        INTPTLAT: '+42.9562139',
        INTPTLON: '-118.9338052',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4249, 43.1766],
            [-119.1019, 43.1739],
            [-119.1809, 43.2685],
            [-119.1817, 43.4372],
            [-119.0037, 43.4372],
            [-119.0038, 43.4793],
            [-118.828, 43.4789],
            [-118.6719, 43.5912],
            [-118.6616, 43.7415],
            [-118.5283, 43.7411],
            [-118.4686, 43.7413],
            [-118.4672, 43.6114],
            [-118.2325, 43.6123],
            [-118.2321, 43.4368],
            [-118.2285, 43.0861],
            [-118.4596, 43.0866],
            [-118.4591, 43.1738],
            [-118.8628, 43.1742],
            [-118.8633, 43.0452],
            [-118.9426, 43.0451],
            [-118.944, 42.9083],
            [-119.7004, 42.9155],
            [-119.6988, 43.1788],
            [-119.4249, 43.1766],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103600',
        NAME: 'Harney County School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5278017424,
        AWATER: 210745538,
        INTPTLAT: '+43.2518663',
        INTPTLON: '-118.8745532',
        ELSDLEA: '03600',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.2285, 43.0861],
            [-118.2285, 43.0428],
            [-118.347, 43.0425],
            [-118.3856, 42.9418],
            [-118.4532, 42.9128],
            [-118.4546, 42.7976],
            [-118.5335, 42.7976],
            [-118.5339, 42.6968],
            [-118.7835, 42.6894],
            [-118.784, 43.0451],
            [-118.8633, 43.0452],
            [-118.8628, 43.1742],
            [-118.4591, 43.1738],
            [-118.4596, 43.0866],
            [-118.2285, 43.0861],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104170',
        NAME: 'Diamond School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1630577209,
        AWATER: 825383,
        INTPTLAT: '+42.9659106',
        INTPTLON: '-118.6061945',
        ELSDLEA: '04170',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4188, 43.4393],
            [-119.1817, 43.4372],
            [-119.1809, 43.2685],
            [-119.1019, 43.1739],
            [-119.4249, 43.1766],
            [-119.4188, 43.4393],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104290',
        NAME: 'Double O School District 28',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 571817911,
        AWATER: 5759644,
        INTPTLAT: '+43.2992738',
        INTPTLON: '-119.2819339',
        ELSDLEA: '04290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.4673, 44.0404],
            [-118.4663, 44.0404],
            [-118.2274, 44.04],
            [-118.2325, 43.6123],
            [-118.4672, 43.6114],
            [-118.4686, 43.7413],
            [-118.5283, 43.7411],
            [-118.5281, 43.8663],
            [-118.4676, 43.8666],
            [-118.4673, 44.0404],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104380',
        NAME: 'Drewsey School District 13',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 971124906,
        AWATER: 8414126,
        INTPTLAT: '+43.8674406',
        INTPTLON: '-118.4093710',
        ELSDLEA: '04380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.7835, 42.6894],
            [-118.5339, 42.6968],
            [-118.5335, 42.7976],
            [-118.4546, 42.7976],
            [-118.4532, 42.9128],
            [-118.3856, 42.9418],
            [-118.347, 43.0425],
            [-118.2285, 43.0428],
            [-118.2164, 42.8059],
            [-118.2147, 42.276],
            [-118.1972, 41.997],
            [-118.8296, 41.9926],
            [-118.8311, 42.2764],
            [-118.6929, 42.3194],
            [-118.692, 42.4494],
            [-118.7838, 42.4889],
            [-118.7835, 42.6894],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105020',
        NAME: 'South Harney School District 33',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4420411584,
        AWATER: 3548745,
        INTPTLAT: '+42.4190406',
        INTPTLON: '-118.4795823',
        ELSDLEA: '05020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9434, 42.9171],
            [-119.9316, 42.917],
            [-119.7004, 42.9155],
            [-118.944, 42.9083],
            [-118.9426, 43.0451],
            [-118.8633, 43.0452],
            [-118.784, 43.0451],
            [-118.7835, 42.6894],
            [-118.7838, 42.4889],
            [-118.692, 42.4494],
            [-118.6929, 42.3194],
            [-118.8311, 42.2764],
            [-118.8296, 41.9926],
            [-119.3242, 41.9939],
            [-119.3602, 41.9942],
            [-119.3636, 42.2201],
            [-119.3653, 42.749],
            [-119.9439, 42.7463],
            [-119.9434, 42.9171],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105310',
        NAME: 'Frenchglen School District 16',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5906622806,
        AWATER: 5484439,
        INTPTLAT: '+42.5195585',
        INTPTLON: '-119.1206821',
        ELSDLEA: '05310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.2274, 44.04],
            [-118.2282, 44.1832],
            [-118.1671, 44.183],
            [-118.1663, 43.9964],
            [-118.012, 43.9973],
            [-118.0119, 43.9396],
            [-117.8618, 43.9396],
            [-117.8311, 43.8185],
            [-117.8769, 43.6885],
            [-117.8772, 43.4336],
            [-118.2321, 43.4368],
            [-118.2325, 43.6123],
            [-118.2274, 44.04],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106960',
        NAME: 'Juntura School District 12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1862132464,
        AWATER: 14970301,
        INTPTLAT: '+43.7089031',
        INTPTLON: '-118.0359276',
        ELSDLEA: '06960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.8166, 43.96],
            [-118.8162, 44.0477],
            [-118.4673, 44.0404],
            [-118.4676, 43.8666],
            [-118.5281, 43.8663],
            [-118.5283, 43.7411],
            [-118.6616, 43.7415],
            [-118.7178, 43.8719],
            [-118.8168, 43.873],
            [-118.8166, 43.96],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109690',
        NAME: 'Pine Creek School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 759715318,
        AWATER: 657590,
        INTPTLAT: '+43.9158286',
        INTPTLON: '-118.6361989',
        ELSDLEA: '09690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.8964, 43.6103],
            [-119.8982, 43.6983],
            [-119.7775, 43.6981],
            [-119.7757, 43.9591],
            [-119.6576, 43.9591],
            [-119.6143, 43.9592],
            [-119.6147, 43.8732],
            [-119.4134, 43.8727],
            [-119.4136, 43.7859],
            [-119.4932, 43.7861],
            [-119.5343, 43.6168],
            [-119.415, 43.6126],
            [-119.4188, 43.4393],
            [-119.4249, 43.1766],
            [-119.6988, 43.1788],
            [-119.7004, 42.9155],
            [-119.9316, 42.917],
            [-119.9324, 43.179],
            [-119.8968, 43.179],
            [-119.8964, 43.6103],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111910',
        NAME: 'Suntex School District 10',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3319427928,
        AWATER: 3422386,
        INTPTLAT: '+43.4340605',
        INTPTLON: '-119.6612906',
        ELSDLEA: '11910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.7251, 44.9117],
            [-123.4187, 44.9116],
            [-123.4792, 44.8109],
            [-123.5611, 44.8266],
            [-123.5823, 44.7362],
            [-123.6026, 44.7212],
            [-123.7249, 44.7391],
            [-123.7251, 44.9117],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100003',
        NAME: 'Falls City School District 57',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355260956,
        AWATER: 1907975,
        INTPTLAT: '+44.8317294',
        INTPTLON: '-123.6091855',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6087, 44.4444],
            [-117.4859, 44.3877],
            [-117.4862, 44.3014],
            [-117.4053, 44.3016],
            [-117.3862, 44.1565],
            [-117.1461, 44.1412],
            [-117.0752, 44.0826],
            [-117.1259, 43.9524],
            [-117.2524, 43.9245],
            [-117.2525, 43.8677],
            [-117.4826, 43.8664],
            [-117.4915, 43.9591],
            [-117.5677, 43.9593],
            [-117.6271, 44.0465],
            [-117.6272, 44.1968],
            [-117.7504, 44.1966],
            [-117.7507, 44.3281],
            [-117.6507, 44.3291],
            [-117.6087, 44.4444],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100014',
        NAME: 'Vale School District 84',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1700571269,
        AWATER: 3183199,
        INTPTLAT: '+44.1197475',
        INTPTLON: '-117.4294416',
        ELSDLEA: '',
        UNSDLEA: '00014',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0687, 45.075],
            [-123.0449, 45.0903],
            [-123.0077, 45.171],
            [-122.9436, 45.1655],
            [-122.8309, 45.1005],
            [-122.8377, 45.065],
            [-122.8963, 45.0211],
            [-123.0687, 45.075],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100015',
        NAME: 'Gervais School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179559885,
        AWATER: 1857630,
        INTPTLAT: '+45.0804800',
        INTPTLON: '-122.9424527',
        ELSDLEA: '',
        UNSDLEA: '00015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4649, 45.4338],
            [-123.197, 45.4335],
            [-123.0636, 45.4046],
            [-123.0683, 45.2864],
            [-123.1185, 45.2708],
            [-123.3812, 45.2746],
            [-123.4221, 45.2745],
            [-123.4631, 45.2745],
            [-123.4637, 45.3745],
            [-123.4649, 45.4338],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100016',
        NAME: 'Yamhill-Carlton School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 478145405,
        AWATER: 898172,
        INTPTLAT: '+45.3575371',
        INTPTLON: '-123.2777040',
        ELSDLEA: '',
        UNSDLEA: '00016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2297, 44.346],
            [-123.0072, 44.3016],
            [-122.8835, 44.3021],
            [-122.8841, 44.2589],
            [-122.9858, 44.2002],
            [-123.112, 44.1622],
            [-123.23, 44.2837],
            [-123.2297, 44.346],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100019',
        NAME: 'Harrisburg School District 7J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338696415,
        AWATER: 2744397,
        INTPTLAT: '+44.2656550',
        INTPTLON: '-123.0645537',
        ELSDLEA: '',
        UNSDLEA: '00019',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7627, 44.8604],
            [-122.496, 44.8686],
            [-122.5584, 44.6927],
            [-122.5987, 44.6928],
            [-122.6564, 44.7288],
            [-122.8254, 44.7234],
            [-122.8451, 44.7747],
            [-122.8456, 44.8532],
            [-122.7627, 44.8604],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100020',
        NAME: 'North Santiam School District 29J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 389325108,
        AWATER: 2841863,
        INTPTLAT: '+44.7975498',
        INTPTLON: '-122.6577043',
        ELSDLEA: '',
        UNSDLEA: '00020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.6968, 45.258],
            [-121.4815, 45.2583],
            [-121.481, 45.3458],
            [-121.2417, 45.2904],
            [-121.0244, 45.2897],
            [-121.0243, 45.2195],
            [-120.7719, 45.1627],
            [-120.6886, 45.0837],
            [-120.5041, 45.083],
            [-120.51, 44.8961],
            [-120.6873, 44.8972],
            [-120.708, 44.9689],
            [-120.8939, 44.92],
            [-120.8939, 44.8532],
            [-120.9895, 44.8233],
            [-121.0837, 44.9137],
            [-121.0753, 44.9752],
            [-121.1515, 45.0259],
            [-121.3686, 45.0601],
            [-121.6981, 45.0596],
            [-121.6654, 45.1193],
            [-121.7382, 45.1804],
            [-121.6968, 45.258],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100021',
        NAME: 'South Wasco County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2606803168,
        AWATER: 7870999,
        INTPTLAT: '+45.0949853',
        INTPTLON: '-121.1065816',
        ELSDLEA: '',
        UNSDLEA: '00021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0507, 45.7793],
            [-123.0162, 45.706],
            [-122.9291, 45.6938],
            [-122.9292, 45.6339],
            [-122.847, 45.5775],
            [-122.8889, 45.5352],
            [-122.8595, 45.4146],
            [-122.8652, 45.4046],
            [-122.9449, 45.3727],
            [-123.0629, 45.4046],
            [-123.0855, 45.4427],
            [-123.069, 45.5417],
            [-123.0565, 45.6504],
            [-123.1139, 45.7224],
            [-123.0507, 45.7793],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100023',
        NAME: 'Hillsboro School District 1J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 524098862,
        AWATER: 484843,
        INTPTLAT: '+45.5572882',
        INTPTLON: '-122.9834986',
        ELSDLEA: '',
        UNSDLEA: '00023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6916, 46.2516],
            [-123.5693, 46.2573],
            [-123.4796, 46.2691],
            [-123.4227, 46.1759],
            [-123.4229, 46.1395],
            [-123.6939, 46.1548],
            [-123.6916, 46.2516],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100040',
        NAME: 'Knappa School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175887747,
        AWATER: 82514001,
        INTPTLAT: '+46.1974532',
        INTPTLON: '-123.5767604',
        ELSDLEA: '',
        UNSDLEA: '00040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9988, 45.688],
            [-119.6833, 45.6881],
            [-119.6862, 45.5451],
            [-119.7557, 45.4638],
            [-119.7586, 45.3368],
            [-119.882, 45.256],
            [-120.0067, 45.2575],
            [-120.0149, 45.4319],
            [-120.176, 45.5007],
            [-119.9992, 45.5174],
            [-119.9988, 45.688],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100047',
        NAME: 'Ione School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1086857625,
        AWATER: 0,
        INTPTLAT: '+45.4909288',
        INTPTLON: '-119.8832708',
        ELSDLEA: '',
        UNSDLEA: '00047',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4411, 45.6972],
            [-121.3835, 45.6993],
            [-121.2158, 45.6712],
            [-121.1292, 45.6116],
            [-121.1685, 45.5042],
            [-121.4816, 45.4907],
            [-121.4406, 45.5193],
            [-121.4411, 45.6972],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100048',
        NAME: 'North Wasco School District 21',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 453446201,
        AWATER: 15420645,
        INTPTLAT: '+45.5849590',
        INTPTLON: '-121.3145194',
        ELSDLEA: '',
        UNSDLEA: '00048',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.296, 43.0109],
            [-124.2565, 43.0528],
            [-124.2566, 43.1394],
            [-124.1236, 43.1829],
            [-123.9838, 43.1682],
            [-123.9871, 43.2546],
            [-123.885, 43.2416],
            [-123.7039, 43.2141],
            [-123.702, 43.1124],
            [-123.8208, 42.9959],
            [-123.8197, 42.8843],
            [-124.1612, 42.8748],
            [-124.296, 43.0109],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100640',
        NAME: 'Myrtle Point School District 41',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1317184751,
        AWATER: 4050279,
        INTPTLAT: '+43.0616831',
        INTPTLON: '-123.9827615',
        ELSDLEA: '',
        UNSDLEA: '00640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.046, 42.2214],
            [-119.3636, 42.2201],
            [-119.3602, 41.9942],
            [-119.9992, 41.9945],
            [-120.0471, 41.9951],
            [-120.046, 42.2214],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4100990',
        NAME: 'Adel School District 21',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1410299999,
        AWATER: 6892209,
        INTPTLAT: '+42.1230789',
        INTPTLON: '-119.7166502',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3468, 43.5637],
            [-117.1914, 43.7013],
            [-117.2822, 43.7228],
            [-117.2822, 43.8096],
            [-117.0243, 43.8125],
            [-117.0265, 43.6809],
            [-117.027, 43.5457],
            [-117.0267, 43.4208],
            [-117.1423, 43.4213],
            [-117.1419, 43.5189],
            [-117.3478, 43.5191],
            [-117.3468, 43.5637],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101020',
        NAME: 'Adrian School District 61',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 748964242,
        AWATER: 13893179,
        INTPTLAT: '+43.6332381',
        INTPTLON: '-117.1412385',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.202, 44.7202],
            [-123.1425, 44.7488],
            [-122.9678, 44.6762],
            [-122.8823, 44.6469],
            [-122.9873, 44.6057],
            [-123.0284, 44.5193],
            [-123.2144, 44.491],
            [-123.1624, 44.6504],
            [-123.202, 44.7202],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101120',
        NAME: 'Greater Albany School District 8J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404224392,
        AWATER: 6237997,
        INTPTLAT: '+44.6183531',
        INTPTLON: '-123.0802616',
        ELSDLEA: '',
        UNSDLEA: '01120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5969, 44.4772],
            [-123.4739, 44.4343],
            [-123.5533, 44.3742],
            [-123.5329, 44.2813],
            [-123.6541, 44.2088],
            [-123.6838, 44.231],
            [-123.7683, 44.2545],
            [-123.7152, 44.3606],
            [-123.7204, 44.4331],
            [-123.5969, 44.4772],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101200',
        NAME: 'Alsea School District 7J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 430728498,
        AWATER: 820549,
        INTPTLAT: '+44.3649961',
        INTPTLON: '-123.6231522',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.34, 45.0894],
            [-123.2128, 45.1697],
            [-123.131, 45.1442],
            [-123.0449, 45.0903],
            [-123.0687, 45.075],
            [-123.1638, 45],
            [-123.1968, 45.0001],
            [-123.2079, 45.0195],
            [-123.3424, 45.0522],
            [-123.34, 45.0894],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101230',
        NAME: 'Amity School District 4J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197901719,
        AWATER: 643488,
        INTPTLAT: '+45.0930097',
        INTPTLON: '-123.1905627',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4053, 44.3016],
            [-117.1986, 44.2741],
            [-117.1199, 44.2783],
            [-116.9495, 44.1916],
            [-117.0557, 44.141],
            [-117.1461, 44.1412],
            [-117.3862, 44.1565],
            [-117.4053, 44.3016],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101350',
        NAME: 'Annex School District 29',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 430271391,
        AWATER: 5695426,
        INTPTLAT: '+44.2066333',
        INTPTLON: '-117.2089895',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.6536, 45.7372],
            [-120.4531, 45.6963],
            [-120.2108, 45.726],
            [-120.0551, 45.791],
            [-119.9995, 45.8125],
            [-119.9988, 45.688],
            [-119.9992, 45.5174],
            [-120.176, 45.5007],
            [-120.2346, 45.4676],
            [-120.3734, 45.4937],
            [-120.4099, 45.5941],
            [-120.6536, 45.7372],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101470',
        NAME: 'Arlington School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 920839602,
        AWATER: 42917180,
        INTPTLAT: '+45.6303392',
        INTPTLON: '-120.2190308',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.2321, 43.4368],
            [-117.8772, 43.4336],
            [-117.8764, 43.346],
            [-117.7599, 43.2637],
            [-117.6399, 43.2639],
            [-117.6397, 43.4335],
            [-117.515, 43.4333],
            [-117.5143, 43.3459],
            [-117.3761, 43.3459],
            [-117.3761, 43.1634],
            [-117.6596, 43.1755],
            [-117.7023, 43.133],
            [-117.7136, 42.9894],
            [-117.5096, 42.9895],
            [-117.4496, 42.9441],
            [-117.4486, 42.7655],
            [-117.4775, 42.6321],
            [-117.9849, 42.633],
            [-117.9808, 42.8064],
            [-118.2164, 42.8059],
            [-118.2285, 43.0428],
            [-118.2285, 43.0861],
            [-118.2321, 43.4368],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101500',
        NAME: 'Arock School District 81',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4554161600,
        AWATER: 5333103,
        INTPTLAT: '+43.0501681',
        INTPTLON: '-117.8428014',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6774, 42.2896],
            [-122.2873, 42.2888],
            [-122.2873, 42.203],
            [-122.4424, 42.2031],
            [-122.4421, 42.097],
            [-122.4035, 42.0087],
            [-122.7536, 42.0041],
            [-122.7554, 42.2753],
            [-122.6774, 42.2896],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101560',
        NAME: 'Ashland School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 947421376,
        AWATER: 12208396,
        INTPTLAT: '+42.1823730',
        INTPTLON: '-122.5771135',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3714, 44.8216],
            [-120.3863, 44.6531],
            [-120.3855, 44.564],
            [-120.8273, 44.5626],
            [-120.8933, 44.563],
            [-120.8738, 44.8091],
            [-120.6896, 44.8231],
            [-120.3714, 44.8216],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101590',
        NAME: 'Ashwood School District 8',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1111545191,
        AWATER: 319758,
        INTPTLAT: '+44.6613371',
        INTPTLON: '-120.6140461',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.9274, 46.2386],
            [-123.8501, 46.2406],
            [-123.6916, 46.2516],
            [-123.6939, 46.1548],
            [-123.4229, 46.1395],
            [-123.3647, 46.0991],
            [-123.569, 46.0982],
            [-123.5473, 46.0404],
            [-123.628, 46.0399],
            [-123.6693, 45.9532],
            [-123.8561, 45.9535],
            [-123.8989, 46.0976],
            [-123.8698, 46.1756],
            [-123.9274, 46.2386],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101620',
        NAME: 'Astoria School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 587667415,
        AWATER: 130695480,
        INTPTLAT: '+46.1068247',
        INTPTLON: '-123.7510373',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.5788, 45.9189],
            [-118.4224, 45.882],
            [-118.3623, 45.816],
            [-118.045, 45.8171],
            [-118.0668, 45.6884],
            [-118.117, 45.6738],
            [-118.3748, 45.6028],
            [-118.5401, 45.6028],
            [-118.5185, 45.7755],
            [-118.622, 45.8052],
            [-118.5788, 45.9189],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101660',
        NAME: 'Athena-Weston School District 29J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 842138985,
        AWATER: 187897,
        INTPTLAT: '+45.7453190',
        INTPTLON: '-118.3598234',
        ELSDLEA: '',
        UNSDLEA: '01660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.2448, 44.9583],
            [-118.1182, 44.9783],
            [-117.7775, 44.9922],
            [-117.7473, 45.0356],
            [-117.5635, 45.0361],
            [-117.5631, 45.0796],
            [-117.3819, 45.0803],
            [-117.3834, 44.6876],
            [-117.2597, 44.6882],
            [-117.2579, 44.5167],
            [-117.6089, 44.5313],
            [-117.6286, 44.6174],
            [-118.2343, 44.6301],
            [-118.2361, 44.7464],
            [-118.2987, 44.7463],
            [-118.319, 44.8363],
            [-118.2281, 44.8654],
            [-118.2448, 44.9583],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101710',
        NAME: 'Baker School District 5J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3386864193,
        AWATER: 11256329,
        INTPTLAT: '+44.7823979',
        INTPTLON: '-117.7502692',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.2987, 44.7463],
            [-118.2361, 44.7464],
            [-118.2343, 44.6301],
            [-117.6286, 44.6174],
            [-117.6089, 44.5313],
            [-117.6087, 44.4444],
            [-117.6507, 44.3291],
            [-117.7507, 44.3281],
            [-117.7504, 44.1966],
            [-117.7511, 44.1288],
            [-117.8706, 44.1278],
            [-117.9849, 44.1825],
            [-118.1671, 44.183],
            [-118.2282, 44.1832],
            [-118.2321, 44.2573],
            [-118.4972, 44.2551],
            [-118.4095, 44.3783],
            [-118.422, 44.4485],
            [-118.3067, 44.5866],
            [-118.373, 44.6454],
            [-118.505, 44.6663],
            [-118.3677, 44.7473],
            [-118.2987, 44.7463],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101740',
        NAME: 'Burnt River School District 30J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2996617229,
        AWATER: 6786947,
        INTPTLAT: '+44.4262234',
        INTPTLON: '-118.0512644',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.462, 43.2734],
            [-124.3381, 43.2727],
            [-124.2962, 43.2293],
            [-124.2959, 43.0674],
            [-124.2565, 43.0528],
            [-124.296, 43.0109],
            [-124.521, 43.0268],
            [-124.462, 43.2734],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101800',
        NAME: 'Bandon School District 54',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275132320,
        AWATER: 174535340,
        INTPTLAT: '+43.1316531',
        INTPTLON: '-124.3899914',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.361, 45.7434],
            [-123.1959, 45.78],
            [-123.0512, 45.7793],
            [-123.0507, 45.7793],
            [-123.1139, 45.7224],
            [-123.0565, 45.6504],
            [-123.069, 45.5417],
            [-123.1762, 45.5851],
            [-123.2372, 45.6794],
            [-123.4847, 45.6945],
            [-123.361, 45.7434],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101830',
        NAME: 'Banks School District 13',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 345116070,
        AWATER: 120288,
        INTPTLAT: '+45.6866806',
        INTPTLON: '-123.1934306',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.847, 45.5775],
            [-122.7442, 45.523],
            [-122.7436, 45.4549],
            [-122.8595, 45.4146],
            [-122.8889, 45.5352],
            [-122.847, 45.5775],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101920',
        NAME: 'Beaverton School District 48J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148232179,
        AWATER: 29221,
        INTPTLAT: '+45.4957301',
        INTPTLON: '-122.8255214',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.963, 43.8764],
            [-121.8216, 44.0115],
            [-121.8191, 44.0509],
            [-121.4028, 44.0514],
            [-121.3838, 44.1234],
            [-121.103, 44.11],
            [-120.987, 44.0476],
            [-120.9181, 43.9594],
            [-120.916, 43.789],
            [-120.8734, 43.7889],
            [-120.8735, 43.6156],
            [-121.333, 43.6166],
            [-122.0027, 43.6152],
            [-121.9671, 43.7025],
            [-121.963, 43.8764],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4101980',
        NAME: 'Bend-La Pine Administrative School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4044599869,
        AWATER: 91351781,
        INTPTLAT: '+43.8360961',
        INTPTLON: '-121.4163631',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1509, 44.1276],
            [-123.1482, 44.0606],
            [-123.2985, 44.0981],
            [-123.2989, 44.1254],
            [-123.1509, 44.1276],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102040',
        NAME: 'Bethel School District 52',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78144064,
        AWATER: 6001952,
        INTPTLAT: '+44.0921711',
        INTPTLON: '-123.2040742',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6541, 44.2088],
            [-123.5329, 44.2813],
            [-123.4717, 44.2811],
            [-123.4699, 44.1355],
            [-123.6721, 44.0701],
            [-123.6541, 44.2088],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102160',
        NAME: 'Blachly School District 090',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 288398769,
        AWATER: 1299304,
        INTPTLAT: '+44.1666875',
        INTPTLON: '-123.5747347',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8009, 44.5663],
            [-121.7977, 44.6467],
            [-121.5945, 44.661],
            [-121.5933, 44.3929],
            [-121.8427, 44.3924],
            [-121.8458, 44.4914],
            [-121.8009, 44.5663],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102190',
        NAME: 'Black Butte School District 41',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496512937,
        AWATER: 2181008,
        INTPTLAT: '+44.5036831',
        INTPTLON: '-121.7081163',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.482, 42.224],
            [-124.3242, 42.2345],
            [-124.3004, 42.2936],
            [-123.8475, 42.302],
            [-123.7912, 42.2364],
            [-123.8568, 42.1411],
            [-123.8215, 41.9955],
            [-124.3288, 41.9983],
            [-124.4093, 42.0641],
            [-124.482, 42.224],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102310',
        NAME: 'Brookings-Harbor School District 17',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1374866936,
        AWATER: 194937492,
        INTPTLAT: '+42.1378305',
        INTPTLON: '-124.1334329',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6143, 43.9592],
            [-118.8166, 43.96],
            [-118.8168, 43.873],
            [-118.7178, 43.8719],
            [-118.6616, 43.7415],
            [-118.6719, 43.5912],
            [-118.828, 43.4789],
            [-119.0038, 43.4793],
            [-119.0037, 43.4372],
            [-119.1817, 43.4372],
            [-119.4188, 43.4393],
            [-119.415, 43.6126],
            [-119.5343, 43.6168],
            [-119.4932, 43.7861],
            [-119.4136, 43.7859],
            [-119.4134, 43.8727],
            [-119.6147, 43.8732],
            [-119.6143, 43.9592],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102490',
        NAME: 'Harney County School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3387257110,
        AWATER: 2635138,
        INTPTLAT: '+43.7200398',
        INTPTLON: '-119.1203883',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4801, 42.7075],
            [-122.2837, 42.7075],
            [-122.2877, 42.3921],
            [-122.4421, 42.392],
            [-122.5603, 42.4906],
            [-122.7556, 42.5056],
            [-122.6978, 42.606],
            [-122.478, 42.6347],
            [-122.4801, 42.7075],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102580',
        NAME: 'Butte Falls School District 91',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 886033535,
        AWATER: 2375980,
        INTPTLAT: '+42.5499599',
        INTPTLON: '-122.4603103',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.702, 43.1124],
            [-123.6425, 43.1121],
            [-123.6428, 42.9414],
            [-123.7021, 42.9102],
            [-123.702, 42.8234],
            [-123.8117, 42.8243],
            [-123.8197, 42.8843],
            [-123.8208, 42.9959],
            [-123.702, 43.1124],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102610',
        NAME: 'Camas Valley School District 21J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 342315907,
        AWATER: 48652,
        INTPTLAT: '+42.9567319',
        INTPTLON: '-123.7358306',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8331, 45.2764],
            [-122.6648, 45.3096],
            [-122.5385, 45.2508],
            [-122.6792, 45.1948],
            [-122.7018, 45.1145],
            [-122.7823, 45.1247],
            [-122.7733, 45.161],
            [-122.7415, 45.2587],
            [-122.8347, 45.274],
            [-122.8331, 45.2764],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102640',
        NAME: 'Canby School District 86',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226904524,
        AWATER: 2863263,
        INTPTLAT: '+45.2330391',
        INTPTLON: '-122.6911249',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8572, 44.8889],
            [-122.7627, 44.8604],
            [-122.8456, 44.8532],
            [-122.8451, 44.7747],
            [-122.9169, 44.7286],
            [-123.0102, 44.8021],
            [-122.9604, 44.8891],
            [-122.8572, 44.8889],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102780',
        NAME: 'Cascade School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211769244,
        AWATER: 1121440,
        INTPTLAT: '+44.8177870',
        INTPTLON: '-122.9101936',
        ELSDLEA: '',
        UNSDLEA: '02780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5171, 45.5191],
            [-122.4569, 45.5086],
            [-122.4751, 45.4286],
            [-122.5171, 45.4613],
            [-122.5171, 45.5191],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102800',
        NAME: 'Centennial School District 28J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38161475,
        AWATER: 129516,
        INTPTLAT: '+45.4763604',
        INTPTLON: '-122.4792576',
        ELSDLEA: '',
        UNSDLEA: '02800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1968, 45.0001],
            [-123.1638, 45],
            [-123.1011, 44.9411],
            [-123.133, 44.8424],
            [-123.1425, 44.7488],
            [-123.202, 44.7202],
            [-123.3521, 44.6949],
            [-123.4141, 44.7206],
            [-123.3052, 44.7985],
            [-123.3147, 44.8808],
            [-123.1942, 44.9151],
            [-123.1968, 45.0001],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102840',
        NAME: 'Central School District 13J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 400649701,
        AWATER: 3978455,
        INTPTLAT: '+44.8165079',
        INTPTLON: '-123.2312196',
        ELSDLEA: '',
        UNSDLEA: '02840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2336, 44.4124],
            [-123.2144, 44.491],
            [-123.0284, 44.5193],
            [-122.8806, 44.4174],
            [-122.8835, 44.3021],
            [-123.0072, 44.3016],
            [-123.2297, 44.346],
            [-123.2336, 44.4124],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102910',
        NAME: 'Central Linn School District 552',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 485598373,
        AWATER: 1423547,
        INTPTLAT: '+44.3994647',
        INTPTLON: '-123.0368148',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9907, 42.7585],
            [-122.9318, 42.7744],
            [-122.9322, 42.6926],
            [-122.8763, 42.5936],
            [-122.8559, 42.4171],
            [-122.8872, 42.3648],
            [-123.075, 42.3481],
            [-123.1122, 42.5356],
            [-122.9943, 42.579],
            [-122.9907, 42.7585],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4102940',
        NAME: 'Central Point School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 596113261,
        AWATER: 4680456,
        INTPTLAT: '+42.5081375',
        INTPTLON: '-122.9773820',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4229, 46.1395],
            [-123.4227, 46.1759],
            [-123.2802, 46.1448],
            [-123.2131, 46.1725],
            [-123.073, 46.1634],
            [-123.1126, 45.9963],
            [-123.1995, 45.9963],
            [-123.2614, 46.083],
            [-123.3651, 46.0831],
            [-123.3647, 46.0991],
            [-123.4229, 46.1395],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103260',
        NAME: 'Clatskanie School District 6J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 298343439,
        AWATER: 27957469,
        INTPTLAT: '+46.1005464',
        INTPTLON: '-123.2097095',
        ELSDLEA: '',
        UNSDLEA: '03260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.073, 46.1634],
            [-122.9304, 46.0933],
            [-122.8831, 46.0539],
            [-122.8386, 45.9825],
            [-123.0699, 45.9386],
            [-123.1126, 45.9963],
            [-123.073, 46.1634],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103265',
        NAME: 'Rainier School District 13',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 294408003,
        AWATER: 19678197,
        INTPTLAT: '+46.0144288',
        INTPTLON: '-122.9747962',
        ELSDLEA: '',
        UNSDLEA: '03265',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4531, 45.2741],
            [-122.4324, 45.2849],
            [-122.2534, 45.1718],
            [-122.2575, 44.8857],
            [-122.3177, 44.8857],
            [-122.3789, 44.8857],
            [-122.3794, 45.0349],
            [-122.4984, 45.1],
            [-122.4985, 45.1711],
            [-122.4154, 45.1977],
            [-122.4531, 45.2741],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103270',
        NAME: 'Colton School District 53',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496988471,
        AWATER: 174670,
        INTPTLAT: '+45.0754721',
        INTPTLON: '-122.3358577',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3734, 45.4937],
            [-120.2346, 45.4676],
            [-120.176, 45.5007],
            [-120.0149, 45.4319],
            [-120.0067, 45.2575],
            [-119.882, 45.256],
            [-119.883, 45.1697],
            [-119.7601, 45.1676],
            [-119.7904, 45.0678],
            [-120.3094, 45.0601],
            [-120.5035, 45.0829],
            [-120.4584, 45.1195],
            [-120.5291, 45.2047],
            [-120.5321, 45.3712],
            [-120.4904, 45.4689],
            [-120.3734, 45.4937],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103330',
        NAME: 'Condon School District 25J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2122229236,
        AWATER: 3783366,
        INTPTLAT: '+45.2530414',
        INTPTLON: '-120.2108054',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.2962, 43.2293],
            [-124.0638, 43.2633],
            [-124.0636, 43.3194],
            [-123.8851, 43.3115],
            [-123.885, 43.2416],
            [-123.9871, 43.2546],
            [-123.9838, 43.1682],
            [-124.1236, 43.1829],
            [-124.2566, 43.1394],
            [-124.2565, 43.0528],
            [-124.2959, 43.0674],
            [-124.2962, 43.2293],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103390',
        NAME: 'Coquille School District 8',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 419826353,
        AWATER: 2921062,
        INTPTLAT: '+43.2203103',
        INTPTLON: '-124.1163066',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.3418, 45.5585],
            [-122.2595, 45.5451],
            [-122.1975, 45.5673],
            [-122.1668, 45.5804],
            [-121.9613, 45.6359],
            [-121.9222, 45.6491],
            [-121.9067, 45.5205],
            [-121.8204, 45.4617],
            [-122.2731, 45.4615],
            [-122.3612, 45.5153],
            [-122.3418, 45.5585],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103420',
        NAME: 'Corbett School District 39',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 464879982,
        AWATER: 21043507,
        INTPTLAT: '+45.5685304',
        INTPTLON: '-122.0445034',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.3521, 44.6949],
            [-123.202, 44.7202],
            [-123.1624, 44.6504],
            [-123.2144, 44.491],
            [-123.2336, 44.4124],
            [-123.4739, 44.4343],
            [-123.371, 44.564],
            [-123.3521, 44.6949],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103480',
        NAME: 'Corvallis School District 509J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491007134,
        AWATER: 4135682,
        INTPTLAT: '+44.5524805',
        INTPTLON: '-123.2919605',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9192, 45.3843],
            [-117.8886, 45.4424],
            [-117.7482, 45.4421],
            [-117.6838, 45.4419],
            [-117.6437, 45.3372],
            [-117.5219, 45.2678],
            [-117.5204, 45.2236],
            [-117.7265, 45.225],
            [-117.8977, 45.3045],
            [-117.9192, 45.3843],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103540',
        NAME: 'Cove School District 15',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 517625074,
        AWATER: 539926,
        INTPTLAT: '+45.3543012',
        INTPTLON: '-117.7564700',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.3526, 43.452],
            [-124.2289, 43.452],
            [-124.2397, 43.3876],
            [-124.0829, 43.3934],
            [-124.0637, 43.5228],
            [-123.8755, 43.5159],
            [-123.8168, 43.516],
            [-123.8177, 43.4312],
            [-123.764, 43.388],
            [-123.764, 43.2579],
            [-123.704, 43.2433],
            [-123.7039, 43.2141],
            [-123.885, 43.2416],
            [-123.8851, 43.3115],
            [-124.0636, 43.3194],
            [-124.0638, 43.2633],
            [-124.2962, 43.2293],
            [-124.3381, 43.2727],
            [-124.462, 43.2734],
            [-124.4769, 43.3294],
            [-124.3526, 43.452],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103660',
        NAME: 'Coos Bay School District 9',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1017850342,
        AWATER: 184886659,
        INTPTLAT: '+43.3918451',
        INTPTLON: '-124.0465110',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1883, 43.9215],
            [-123.0495, 43.988],
            [-122.9841, 43.9594],
            [-122.8662, 43.8541],
            [-122.9272, 43.8761],
            [-123.1278, 43.8776],
            [-123.1883, 43.9215],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103690',
        NAME: 'Creswell School District 40',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183931955,
        AWATER: 243087,
        INTPTLAT: '+43.9102069',
        INTPTLON: '-123.0348605',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.1073, 44.3905],
            [-120.9885, 44.39],
            [-120.9887, 44.4764],
            [-120.8272, 44.4766],
            [-120.8273, 44.5626],
            [-120.3855, 44.564],
            [-120.3858, 44.4376],
            [-120.0218, 44.4386],
            [-119.899, 44.3895],
            [-119.8988, 44.3067],
            [-119.6556, 44.307],
            [-119.6549, 44.2203],
            [-119.6576, 43.9591],
            [-119.7757, 43.9591],
            [-119.7775, 43.6981],
            [-119.8982, 43.6983],
            [-119.8964, 43.6103],
            [-120.8735, 43.6156],
            [-120.8734, 43.7889],
            [-120.916, 43.789],
            [-120.9181, 43.9594],
            [-120.987, 44.0476],
            [-120.9873, 44.1338],
            [-121.1026, 44.138],
            [-121.1073, 44.3905],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103720',
        NAME: 'Crook County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9481586774,
        AWATER: 22079246,
        INTPTLAT: '+44.0656491',
        INTPTLON: '-120.3876930',
        ELSDLEA: '',
        UNSDLEA: '03720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2627, 44.0119],
            [-123.1883, 43.9215],
            [-123.1278, 43.8776],
            [-123.186, 43.7797],
            [-123.3477, 43.7802],
            [-123.5797, 43.8681],
            [-123.5931, 43.9824],
            [-123.47, 43.9548],
            [-123.4706, 44.0406],
            [-123.2627, 44.0119],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103780',
        NAME: 'Crow-Applegate-Lorane Sd 66',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 660076499,
        AWATER: 187082,
        INTPTLAT: '+43.8723587',
        INTPTLON: '-123.3554649',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.5945, 44.661],
            [-121.447, 44.5934],
            [-121.0964, 44.5634],
            [-120.9887, 44.4764],
            [-120.9885, 44.39],
            [-121.1073, 44.3905],
            [-121.2304, 44.4513],
            [-121.3453, 44.4521],
            [-121.4054, 44.393],
            [-121.5933, 44.3929],
            [-121.5945, 44.661],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103840',
        NAME: 'Culver School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 839416082,
        AWATER: 9948872,
        INTPTLAT: '+44.4997319',
        INTPTLON: '-121.3268316',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.7251, 45.0005],
            [-123.6017, 44.9705],
            [-123.4387, 44.9693],
            [-123.2079, 45.0195],
            [-123.1968, 45.0001],
            [-123.1942, 44.9151],
            [-123.3147, 44.8808],
            [-123.3052, 44.7985],
            [-123.4141, 44.7206],
            [-123.5823, 44.7362],
            [-123.5611, 44.8266],
            [-123.4792, 44.8109],
            [-123.4187, 44.9116],
            [-123.7251, 44.9117],
            [-123.7251, 45.0005],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103860',
        NAME: 'Dallas School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 609972121,
        AWATER: 283302,
        INTPTLAT: '+44.9489694',
        INTPTLON: '-123.3294507',
        ELSDLEA: '',
        UNSDLEA: '03860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5647, 45.5332],
            [-122.5171, 45.5191],
            [-122.5171, 45.4613],
            [-122.553, 45.461],
            [-122.5647, 45.5332],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103940',
        NAME: 'David Douglas School District 40',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28053879,
        AWATER: 49289,
        INTPTLAT: '+45.4978793',
        INTPTLON: '-122.5382188',
        ELSDLEA: '',
        UNSDLEA: '03940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9874, 43.1126],
            [-122.7546, 43.1693],
            [-122.2816, 43.1695],
            [-122.2822, 43.0675],
            [-122.2819, 42.9966],
            [-122.4005, 42.9964],
            [-122.5205, 42.9391],
            [-122.6773, 42.8668],
            [-122.7948, 42.7794],
            [-122.912, 42.7743],
            [-122.9928, 42.8696],
            [-123.2086, 42.8697],
            [-123.2472, 42.9822],
            [-123.0678, 43.039],
            [-122.9874, 43.1126],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103960',
        NAME: 'Days Creek School District 15',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2070621448,
        AWATER: 542698,
        INTPTLAT: '+42.9872545',
        INTPTLON: '-122.7825805',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1185, 45.2708],
            [-123.0683, 45.2864],
            [-122.9993, 45.2616],
            [-123.0077, 45.171],
            [-123.0449, 45.0903],
            [-123.131, 45.1442],
            [-123.1185, 45.2708],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4103990',
        NAME: 'Dayton School District 8',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146515804,
        AWATER: 3064460,
        INTPTLAT: '+45.1939760',
        INTPTLON: '-123.0652565',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.7778, 44.5656],
            [-119.6545, 44.5341],
            [-119.654, 44.6218],
            [-119.5316, 44.6207],
            [-119.5313, 44.5621],
            [-119.322, 44.5612],
            [-119.2821, 44.561],
            [-119.2936, 44.31],
            [-119.4136, 44.3099],
            [-119.4126, 44.218],
            [-119.6549, 44.2203],
            [-119.6556, 44.307],
            [-119.654, 44.4613],
            [-119.7778, 44.5656],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104020',
        NAME: 'Dayville School District 16J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1139625989,
        AWATER: 32070,
        INTPTLAT: '+44.3851264',
        INTPTLON: '-119.4962730',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.5797, 43.8681],
            [-123.3477, 43.7802],
            [-123.186, 43.7797],
            [-123.1378, 43.6771],
            [-123.2504, 43.6335],
            [-123.4682, 43.6479],
            [-123.468, 43.7345],
            [-123.5775, 43.7336],
            [-123.5796, 43.8628],
            [-123.5797, 43.8681],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104350',
        NAME: 'North Douglas School District 22',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 507566245,
        AWATER: 56517,
        INTPTLAT: '+43.7325131',
        INTPTLON: '-123.4432454',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.4816, 45.4907],
            [-121.1685, 45.5042],
            [-121.1292, 45.6116],
            [-121.0714, 45.6509],
            [-120.9159, 45.6413],
            [-120.9085, 45.5582],
            [-120.8388, 45.5159],
            [-120.8686, 45.4011],
            [-121.0244, 45.2897],
            [-121.2417, 45.2904],
            [-121.481, 45.3458],
            [-121.4816, 45.4907],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104410',
        NAME: 'Dufur School District 29',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1286970484,
        AWATER: 10439482,
        INTPTLAT: '+45.4368880',
        INTPTLON: '-121.1338078',
        ELSDLEA: '',
        UNSDLEA: '04410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9318, 42.7744],
            [-122.912, 42.7743],
            [-122.7948, 42.7794],
            [-122.6773, 42.8668],
            [-122.5205, 42.9391],
            [-122.5197, 42.7726],
            [-122.4801, 42.7075],
            [-122.478, 42.6347],
            [-122.6978, 42.606],
            [-122.7556, 42.5056],
            [-122.5603, 42.4906],
            [-122.4421, 42.392],
            [-122.2877, 42.3921],
            [-122.2873, 42.2888],
            [-122.6774, 42.2896],
            [-122.7551, 42.3153],
            [-122.8559, 42.4171],
            [-122.8763, 42.5936],
            [-122.9322, 42.6926],
            [-122.9318, 42.7744],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104500',
        NAME: 'Eagle Point School District 9',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1686028451,
        AWATER: 11746058,
        INTPTLAT: '+42.7748791',
        INTPTLON: '-122.7554131',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4348, 45.7461],
            [-119.2899, 45.7535],
            [-119.0477, 45.7904],
            [-119.0563, 45.6034],
            [-119.2483, 45.5732],
            [-119.4346, 45.6028],
            [-119.4348, 45.7461],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104530',
        NAME: 'Echo School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 553808822,
        AWATER: 738,
        INTPTLAT: '+45.6819388',
        INTPTLON: '-119.2326637',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.045, 45.8171],
            [-117.9729, 45.8606],
            [-117.7473, 45.861],
            [-117.7876, 45.6896],
            [-117.7271, 45.6173],
            [-117.7273, 45.5147],
            [-117.6838, 45.4419],
            [-117.7482, 45.4421],
            [-117.9195, 45.4848],
            [-117.9511, 45.5712],
            [-118.117, 45.6007],
            [-118.117, 45.6738],
            [-118.0668, 45.6884],
            [-118.045, 45.8171],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104590',
        NAME: 'Elgin School District 23',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 953589897,
        AWATER: 391729,
        INTPTLAT: '+45.6660646',
        INTPTLON: '-117.8942184',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.7034, 43.8629],
            [-123.5796, 43.8628],
            [-123.5775, 43.7336],
            [-123.468, 43.7345],
            [-123.4682, 43.6479],
            [-123.4271, 43.52],
            [-123.5847, 43.4604],
            [-123.6841, 43.4602],
            [-123.7051, 43.5596],
            [-123.7935, 43.6077],
            [-123.7924, 43.6926],
            [-123.7049, 43.6932],
            [-123.7034, 43.8629],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104620',
        NAME: 'Elkton School District 34',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 782062393,
        AWATER: 8815194,
        INTPTLAT: '+43.6412027',
        INTPTLON: '-123.6042323',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4506, 45.3892],
            [-122.438, 45.3958],
            [-122.4092, 45.3962],
            [-122.1685, 45.2863],
            [-122.0035, 45.3226],
            [-122.0021, 45.1802],
            [-121.7382, 45.1804],
            [-121.6654, 45.1193],
            [-121.6981, 45.0596],
            [-121.8051, 45.0113],
            [-121.7342, 44.8858],
            [-122.2575, 44.8857],
            [-122.2534, 45.1718],
            [-122.4324, 45.2849],
            [-122.4508, 45.3843],
            [-122.4506, 45.3892],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104700',
        NAME: 'Estacada School District 108',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1824370090,
        AWATER: 9704527,
        INTPTLAT: '+45.0986475',
        INTPTLON: '-122.0560139',
        ELSDLEA: '',
        UNSDLEA: '04700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1509, 44.1276],
            [-123.112, 44.1622],
            [-122.9858, 44.2002],
            [-122.9857, 44.1712],
            [-123.0467, 44.0857],
            [-123.0495, 43.988],
            [-123.1883, 43.9215],
            [-123.2627, 44.0119],
            [-123.2985, 44.0981],
            [-123.1482, 44.0606],
            [-123.1509, 44.1276],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104740',
        NAME: 'Eugene School District 4J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 382534575,
        AWATER: 12703292,
        INTPTLAT: '+44.1429946',
        INTPTLON: '-123.0582407',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6721, 44.0701],
            [-123.4699, 44.1355],
            [-123.2989, 44.1254],
            [-123.2985, 44.0981],
            [-123.2627, 44.0119],
            [-123.4706, 44.0406],
            [-123.47, 43.9548],
            [-123.5931, 43.9824],
            [-123.5797, 43.8681],
            [-123.5796, 43.8628],
            [-123.7034, 43.8629],
            [-123.7587, 43.9445],
            [-123.6435, 43.9972],
            [-123.6721, 44.0701],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4104950',
        NAME: 'Fern Ridge School District 28J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 542389433,
        AWATER: 21076782,
        INTPTLAT: '+44.0138359',
        INTPTLON: '-123.5223997',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.382, 45.9981],
            [-116.916, 45.9954],
            [-116.796, 45.859],
            [-116.8717, 45.8585],
            [-116.8713, 45.6008],
            [-116.9113, 45.5589],
            [-117.0664, 45.66],
            [-117.2016, 45.6597],
            [-117.1586, 45.4996],
            [-117.1691, 45.3914],
            [-117.375, 45.3566],
            [-117.425, 45.4335],
            [-117.3644, 45.5947],
            [-117.2921, 45.6016],
            [-117.2511, 45.6892],
            [-117.4344, 45.8055],
            [-117.4729, 45.8989],
            [-117.382, 45.9981],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105080',
        NAME: 'Enterprise School District 21',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2112958087,
        AWATER: 1019600,
        INTPTLAT: '+45.7675945',
        INTPTLON: '-117.1601225',
        ELSDLEA: '',
        UNSDLEA: '05080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1883, 44.1903],
            [-123.9492, 44.18],
            [-123.8885, 44.074],
            [-124.0225, 43.9884],
            [-123.8759, 43.914],
            [-123.9563, 43.8538],
            [-124.2359, 43.8315],
            [-124.1883, 44.1903],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105100',
        NAME: 'Siuslaw School District 97J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 571226819,
        AWATER: 259077045,
        INTPTLAT: '+44.0106633',
        INTPTLON: '-124.0842489',
        ELSDLEA: '',
        UNSDLEA: '05100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4847, 45.6945],
            [-123.2372, 45.6794],
            [-123.1762, 45.5851],
            [-123.069, 45.5417],
            [-123.0855, 45.4427],
            [-123.2279, 45.4827],
            [-123.4603, 45.4774],
            [-123.3203, 45.5931],
            [-123.4847, 45.6945],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105160',
        NAME: 'Forest Grove School District 15',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491673821,
        AWATER: 4268549,
        INTPTLAT: '+45.5570098',
        INTPTLON: '-123.2581874',
        ELSDLEA: '',
        UNSDLEA: '05160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.51, 44.8961],
            [-120.5041, 45.083],
            [-120.5035, 45.0829],
            [-120.3094, 45.0601],
            [-119.7904, 45.0678],
            [-119.7912, 45.0235],
            [-119.9126, 45.0095],
            [-120.0199, 44.7547],
            [-120.1626, 44.681],
            [-120.2644, 44.7102],
            [-120.3863, 44.6531],
            [-120.3714, 44.8216],
            [-120.51, 44.8961],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105250',
        NAME: 'Fossil School District 21J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1618006771,
        AWATER: 1159228,
        INTPTLAT: '+44.9231589',
        INTPTLON: '-120.2196697',
        ELSDLEA: '',
        UNSDLEA: '05250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4603, 45.4774],
            [-123.2279, 45.4827],
            [-123.0855, 45.4427],
            [-123.0629, 45.4046],
            [-123.0636, 45.4046],
            [-123.197, 45.4335],
            [-123.4649, 45.4338],
            [-123.4603, 45.4774],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105430',
        NAME: 'Gaston School District 511J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157899363,
        AWATER: 763646,
        INTPTLAT: '+45.4481100',
        INTPTLON: '-123.2750916',
        ELSDLEA: '',
        UNSDLEA: '05430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6178, 45.3757],
            [-122.6, 45.3968],
            [-122.5711, 45.3914],
            [-122.6078, 45.3723],
            [-122.6178, 45.3757],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105610',
        NAME: 'Gladstone School District 115',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5986344,
        AWATER: 95157,
        INTPTLAT: '+45.3856499',
        INTPTLON: '-122.5928265',
        ELSDLEA: '',
        UNSDLEA: '05610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.8117, 42.8243],
            [-123.702, 42.8234],
            [-123.46, 42.8228],
            [-123.2281, 42.8374],
            [-123.2086, 42.8697],
            [-122.9928, 42.8696],
            [-122.912, 42.7743],
            [-122.9318, 42.7744],
            [-122.9907, 42.7585],
            [-123.1514, 42.7561],
            [-123.2299, 42.7025],
            [-123.366, 42.732],
            [-123.4442, 42.7031],
            [-123.7162, 42.7842],
            [-123.8121, 42.7894],
            [-123.8117, 42.8243],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105640',
        NAME: 'Glendale School District 77',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 774155423,
        AWATER: 94298,
        INTPTLAT: '+42.7752691',
        INTPTLON: '-123.3547585',
        ELSDLEA: '',
        UNSDLEA: '05640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1891, 43.3725],
            [-123.0313, 43.377],
            [-122.8933, 43.5174],
            [-122.7415, 43.5176],
            [-122.7408, 43.4371],
            [-122.5085, 43.4412],
            [-122.132, 43.4404],
            [-122.0592, 43.4003],
            [-121.9804, 43.2653],
            [-122.0921, 43.0851],
            [-122.2822, 43.0675],
            [-122.2816, 43.1695],
            [-122.7546, 43.1693],
            [-122.9874, 43.1126],
            [-123.0658, 43.1706],
            [-123.2244, 43.1276],
            [-123.2442, 43.0914],
            [-123.2983, 43.1862],
            [-123.2984, 43.2893],
            [-123.1891, 43.3725],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105670',
        NAME: 'Glide School District 12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3320365157,
        AWATER: 19450577,
        INTPTLAT: '+43.2802522',
        INTPTLON: '-122.6249232',
        ELSDLEA: '',
        UNSDLEA: '05670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.5353, 42.6377],
            [-124.2997, 42.6369],
            [-124.2986, 42.7001],
            [-124.1602, 42.7682],
            [-124.1389, 42.6708],
            [-123.997, 42.6856],
            [-123.9255, 42.7749],
            [-123.8121, 42.7894],
            [-123.7162, 42.7842],
            [-123.8318, 42.6326],
            [-123.8907, 42.5005],
            [-123.9988, 42.5],
            [-124.0206, 42.3614],
            [-123.8922, 42.3528],
            [-123.8475, 42.302],
            [-124.3004, 42.2936],
            [-124.3242, 42.2345],
            [-124.482, 42.224],
            [-124.4942, 42.3961],
            [-124.5672, 42.4778],
            [-124.4898, 42.5429],
            [-124.5353, 42.6377],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105760',
        NAME: 'Central Curry School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2028541450,
        AWATER: 368605231,
        INTPTLAT: '+42.5257440',
        INTPTLON: '-124.1865995',
        ELSDLEA: '',
        UNSDLEA: '05760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4158, 42.4276],
            [-123.3475, 42.4267],
            [-123.347, 42.4842],
            [-123.2981, 42.4846],
            [-123.3285, 42.3754],
            [-123.3863, 42.3822],
            [-123.4158, 42.4276],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4105910',
        NAME: 'Grants Pass School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62890846,
        AWATER: 458691,
        INTPTLAT: '+42.4293373',
        INTPTLON: '-123.3443326',
        ELSDLEA: '',
        UNSDLEA: '05910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.4751, 45.4286],
            [-122.4569, 45.5086],
            [-122.3612, 45.5153],
            [-122.2731, 45.4615],
            [-122.4092, 45.3962],
            [-122.438, 45.3958],
            [-122.4506, 45.3892],
            [-122.4751, 45.4286],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106000',
        NAME: 'Gresham-Barlow School District 1J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129138659,
        AWATER: 726762,
        INTPTLAT: '+45.4655943',
        INTPTLON: '-122.3908811',
        ELSDLEA: '',
        UNSDLEA: '06000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.1671, 44.183],
            [-117.9849, 44.1825],
            [-117.8706, 44.1278],
            [-117.7511, 44.1288],
            [-117.7504, 44.1966],
            [-117.6272, 44.1968],
            [-117.6271, 44.0465],
            [-117.5677, 43.9593],
            [-117.4915, 43.9591],
            [-117.4826, 43.8664],
            [-117.5142, 43.8663],
            [-117.5114, 43.5577],
            [-117.515, 43.4333],
            [-117.6397, 43.4335],
            [-117.6399, 43.2639],
            [-117.7599, 43.2637],
            [-117.8764, 43.346],
            [-117.8772, 43.4336],
            [-117.8769, 43.6885],
            [-117.8311, 43.8185],
            [-117.8618, 43.9396],
            [-118.0119, 43.9396],
            [-118.012, 43.9973],
            [-118.1663, 43.9964],
            [-118.1671, 44.183],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106120',
        NAME: 'Harper School District 66',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3038271951,
        AWATER: 448758,
        INTPTLAT: '+43.7959757',
        INTPTLON: '-117.7611438',
        ELSDLEA: '',
        UNSDLEA: '06120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9862, 45.8643],
            [-118.9778, 46.0002],
            [-118.8681, 46.0005],
            [-118.7252, 46.001],
            [-118.7151, 45.9359],
            [-118.5788, 45.9189],
            [-118.622, 45.8052],
            [-118.7661, 45.7761],
            [-118.9238, 45.8645],
            [-118.9859, 45.8515],
            [-118.9862, 45.8643],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106270',
        NAME: 'Helix School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 578170376,
        AWATER: 878,
        INTPTLAT: '+45.9095477',
        INTPTLON: '-118.7904971',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4326, 45.8793],
            [-119.2865, 45.8791],
            [-119.2658, 45.939],
            [-119.2043, 45.9293],
            [-119.0932, 45.9427],
            [-118.9871, 45.9999],
            [-118.9778, 46.0002],
            [-118.9862, 45.8643],
            [-119.1219, 45.8642],
            [-119.2903, 45.8124],
            [-119.2899, 45.7535],
            [-119.4348, 45.7461],
            [-119.4326, 45.8793],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106300',
        NAME: 'Hermiston School District 8',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 398339021,
        AWATER: 27993159,
        INTPTLAT: '+45.8608244',
        INTPTLON: '-119.2169919',
        ELSDLEA: '',
        UNSDLEA: '06300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.9222, 45.6491],
            [-121.8113, 45.7068],
            [-121.7117, 45.6947],
            [-121.6093, 45.7097],
            [-121.4411, 45.6972],
            [-121.4406, 45.5193],
            [-121.4816, 45.4907],
            [-121.481, 45.3458],
            [-121.4815, 45.2583],
            [-121.6968, 45.258],
            [-121.6993, 45.3785],
            [-121.8204, 45.4617],
            [-121.9067, 45.5205],
            [-121.9222, 45.6491],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106510',
        NAME: 'Hood River County School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1351717728,
        AWATER: 29106865,
        INTPTLAT: '+45.5117753',
        INTPTLON: '-121.6559762',
        ELSDLEA: '',
        UNSDLEA: '06510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6089, 44.5313],
            [-117.2579, 44.5167],
            [-117.2597, 44.6882],
            [-117.0794, 44.6887],
            [-117.0813, 44.6736],
            [-117.1442, 44.5456],
            [-117.2241, 44.4837],
            [-117.2427, 44.3965],
            [-117.1986, 44.2741],
            [-117.4053, 44.3016],
            [-117.4862, 44.3014],
            [-117.4859, 44.3877],
            [-117.6087, 44.4444],
            [-117.6089, 44.5313],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106600',
        NAME: 'Huntington School District 16J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 852008714,
        AWATER: 16705453,
        INTPTLAT: '+44.4549121',
        INTPTLON: '-117.3293124',
        ELSDLEA: '',
        UNSDLEA: '06600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.117, 45.6007],
            [-117.9511, 45.5712],
            [-117.9195, 45.4848],
            [-117.7482, 45.4421],
            [-117.8886, 45.4424],
            [-117.9192, 45.3843],
            [-118.1173, 45.4928],
            [-118.117, 45.6007],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106630',
        NAME: 'Imbler School District 11',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308820303,
        AWATER: 0,
        INTPTLAT: '+45.4985235',
        INTPTLON: '-117.9863469',
        ELSDLEA: '',
        UNSDLEA: '06630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.133, 44.8424],
            [-123.0102, 44.8021],
            [-122.9169, 44.7286],
            [-122.9678, 44.6762],
            [-123.1425, 44.7488],
            [-123.133, 44.8424],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106710',
        NAME: 'Jefferson School District 14J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144293261,
        AWATER: 3777864,
        INTPTLAT: '+44.7493954',
        INTPTLON: '-123.0275932',
        ELSDLEA: '',
        UNSDLEA: '06710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.7342, 44.8858],
            [-121.8051, 45.0113],
            [-121.6981, 45.0596],
            [-121.3686, 45.0601],
            [-121.1515, 45.0259],
            [-121.0753, 44.9752],
            [-121.0837, 44.9137],
            [-120.9895, 44.8233],
            [-120.8939, 44.8532],
            [-120.8939, 44.92],
            [-120.708, 44.9689],
            [-120.6873, 44.8972],
            [-120.51, 44.8961],
            [-120.3714, 44.8216],
            [-120.6896, 44.8231],
            [-120.8738, 44.8091],
            [-120.8933, 44.563],
            [-120.8273, 44.5626],
            [-120.8272, 44.4766],
            [-120.9887, 44.4764],
            [-121.0964, 44.5634],
            [-121.447, 44.5934],
            [-121.5945, 44.661],
            [-121.7977, 44.6467],
            [-121.8192, 44.7897],
            [-121.7342, 44.8858],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106740',
        NAME: 'Jefferson County School District 509J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3794663170,
        AWATER: 15366738,
        INTPTLAT: '+44.8022764',
        INTPTLON: '-121.2606462',
        ELSDLEA: '',
        UNSDLEA: '06740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6693, 45.9532],
            [-123.628, 46.0399],
            [-123.5473, 46.0404],
            [-123.3654, 46.0695],
            [-123.361, 45.7797],
            [-123.7193, 45.7833],
            [-123.6665, 45.8164],
            [-123.6693, 45.9532],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106750',
        NAME: 'Jewell School District 8',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 695444746,
        AWATER: 334562,
        INTPTLAT: '+45.9030945',
        INTPTLON: '-123.5574721',
        ELSDLEA: '',
        UNSDLEA: '06750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.6549, 44.2203],
            [-119.4126, 44.218],
            [-119.4136, 44.3099],
            [-119.2936, 44.31],
            [-119.2821, 44.561],
            [-119.2219, 44.532],
            [-118.991, 44.5323],
            [-118.8182, 44.5611],
            [-118.815, 44.4453],
            [-118.7313, 44.3884],
            [-118.7393, 44.0908],
            [-118.4665, 44.0841],
            [-118.4663, 44.0404],
            [-118.4673, 44.0404],
            [-118.8162, 44.0477],
            [-118.8166, 43.96],
            [-119.6143, 43.9592],
            [-119.6576, 43.9591],
            [-119.6549, 44.2203],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106780',
        NAME: 'John Day School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3784793909,
        AWATER: 530236,
        INTPTLAT: '+44.2137154',
        INTPTLON: '-119.1141867',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.515, 43.4333],
            [-117.5114, 43.5577],
            [-117.3468, 43.5637],
            [-117.3478, 43.5191],
            [-117.1419, 43.5189],
            [-117.1423, 43.4213],
            [-117.0267, 43.4208],
            [-117.0268, 43.3153],
            [-117.0264, 42.4305],
            [-117.1437, 42.4919],
            [-117.2918, 42.7067],
            [-117.4486, 42.7655],
            [-117.4496, 42.9441],
            [-117.5096, 42.9895],
            [-117.7136, 42.9894],
            [-117.7023, 43.133],
            [-117.6596, 43.1755],
            [-117.3761, 43.1634],
            [-117.3761, 43.3459],
            [-117.5143, 43.3459],
            [-117.515, 43.4333],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106820',
        NAME: 'Jordan Valley School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3797479378,
        AWATER: 48730177,
        INTPTLAT: '+43.0672871',
        INTPTLON: '-117.2747209',
        ELSDLEA: '',
        UNSDLEA: '06820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.375, 45.3566],
            [-117.1691, 45.3914],
            [-117.1586, 45.4996],
            [-117.2016, 45.6597],
            [-117.0664, 45.66],
            [-116.9113, 45.5589],
            [-116.8713, 45.6008],
            [-116.8717, 45.8585],
            [-116.796, 45.859],
            [-116.7946, 45.856],
            [-116.5942, 45.7791],
            [-116.4636, 45.6028],
            [-116.5281, 45.5396],
            [-116.687, 45.2679],
            [-116.7242, 45.1649],
            [-116.7839, 45.079],
            [-117.2666, 45.0808],
            [-117.2672, 45.1659],
            [-117.3733, 45.166],
            [-117.375, 45.3566],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106870',
        NAME: 'Joseph School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3475329848,
        AWATER: 12102666,
        INTPTLAT: '+45.4357264',
        INTPTLON: '-116.9015554',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.7162, 42.7842],
            [-123.4442, 42.7031],
            [-123.366, 42.732],
            [-123.2299, 42.7025],
            [-123.2294, 42.3557],
            [-123.1121, 42.3191],
            [-123.151, 42.2182],
            [-123.1494, 42.0835],
            [-123.2308, 42.0039],
            [-123.3425, 41.9993],
            [-123.5179, 42.0009],
            [-123.8215, 41.9955],
            [-123.8568, 42.1411],
            [-123.7912, 42.2364],
            [-123.8475, 42.302],
            [-123.8922, 42.3528],
            [-124.0206, 42.3614],
            [-123.9988, 42.5],
            [-123.8907, 42.5005],
            [-123.8318, 42.6326],
            [-123.7162, 42.7842],
          ],
          [
            [-123.4158, 42.4276],
            [-123.3863, 42.3822],
            [-123.3285, 42.3754],
            [-123.2981, 42.4846],
            [-123.347, 42.4842],
            [-123.3475, 42.4267],
            [-123.4158, 42.4276],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106900',
        NAME: 'Three Rivers School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4426229163,
        AWATER: 5621126,
        INTPTLAT: '+42.3491489',
        INTPTLON: '-123.5215715',
        ELSDLEA: '',
        UNSDLEA: '06900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4717, 44.2811],
            [-123.23, 44.2837],
            [-123.112, 44.1622],
            [-123.1509, 44.1276],
            [-123.2989, 44.1254],
            [-123.4699, 44.1355],
            [-123.4717, 44.2811],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4106930',
        NAME: 'Junction City School District 69',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422774416,
        AWATER: 1429773,
        INTPTLAT: '+44.2061790',
        INTPTLON: '-123.3070442',
        ELSDLEA: '',
        UNSDLEA: '06930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2873, 42.203],
            [-122.2873, 42.2888],
            [-122.2877, 42.3921],
            [-122.2837, 42.7075],
            [-122.2819, 42.9966],
            [-122.2822, 43.0675],
            [-122.0921, 43.0851],
            [-121.9804, 43.2653],
            [-122.0592, 43.4003],
            [-122.132, 43.4404],
            [-122.1309, 43.5571],
            [-122.0027, 43.6152],
            [-121.333, 43.6166],
            [-121.3486, 43.3567],
            [-121.3509, 42.9167],
            [-121.3499, 42.7467],
            [-120.8833, 42.7439],
            [-120.8811, 42.4865],
            [-120.8795, 41.9938],
            [-121.1112, 41.9955],
            [-121.6831, 42.0004],
            [-122.1863, 42.0075],
            [-122.2849, 42.0077],
            [-122.2895, 42.0078],
            [-122.2873, 42.203],
          ],
          [
            [-121.929, 42.2926],
            [-121.8537, 42.2359],
            [-121.7328, 42.2065],
            [-121.7182, 42.3014],
            [-121.7772, 42.3852],
            [-121.929, 42.2926],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107020',
        NAME: 'Klamath County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15199043177,
        AWATER: 502328730,
        INTPTLAT: '+42.6837613',
        INTPTLON: '-121.6461682',
        ELSDLEA: '',
        UNSDLEA: '07020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.929, 42.2926],
            [-121.7772, 42.3852],
            [-121.7182, 42.3014],
            [-121.7328, 42.2065],
            [-121.8537, 42.2359],
            [-121.929, 42.2926],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107080',
        NAME: 'Klamath Falls City Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188169327,
        AWATER: 1426099,
        INTPTLAT: '+42.2641609',
        INTPTLON: '-121.8019191',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.4285, 45.429],
            [-118.198, 45.4272],
            [-118.1173, 45.4928],
            [-117.9192, 45.3843],
            [-117.8977, 45.3045],
            [-117.9008, 45.1525],
            [-118.1465, 45.1674],
            [-118.145, 45.0821],
            [-118.3079, 45.0812],
            [-118.3076, 44.9697],
            [-118.5191, 44.996],
            [-118.5467, 45.197],
            [-118.6558, 45.1969],
            [-118.6968, 45.2577],
            [-118.6977, 45.3452],
            [-118.4283, 45.3552],
            [-118.4285, 45.429],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107200',
        NAME: 'La Grande School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2060835415,
        AWATER: 728602,
        INTPTLAT: '+45.2662958',
        INTPTLON: '-118.2891073',
        ELSDLEA: '',
        UNSDLEA: '07200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7441, 45.4333],
            [-122.6727, 45.4327],
            [-122.6487, 45.4327],
            [-122.6374, 45.3985],
            [-122.7285, 45.3834],
            [-122.7441, 45.4333],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107230',
        NAME: 'Lake Oswego School District 7J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33441609,
        AWATER: 2251203,
        INTPTLAT: '+45.4123049',
        INTPTLON: '-122.6991542',
        ELSDLEA: '',
        UNSDLEA: '07230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.8811, 42.4865],
            [-120.5314, 42.4893],
            [-120.4148, 42.5048],
            [-120.3568, 42.5921],
            [-120.4157, 42.694],
            [-120.4217, 42.9178],
            [-120.2791, 42.9174],
            [-119.9434, 42.9171],
            [-119.9439, 42.7463],
            [-119.9442, 42.6882],
            [-120.1623, 42.6892],
            [-120.1645, 42.2971],
            [-120.0455, 42.2962],
            [-120.046, 42.2214],
            [-120.0471, 41.9951],
            [-120.1607, 41.9946],
            [-120.3285, 41.9932],
            [-120.3236, 42.0456],
            [-120.4131, 42.0466],
            [-120.414, 41.9935],
            [-120.8795, 41.9938],
            [-120.8811, 42.4865],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107280',
        NAME: 'Lakeview School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4628342797,
        AWATER: 259422445,
        INTPTLAT: '+42.3653864',
        INTPTLON: '-120.4003130',
        ELSDLEA: '',
        UNSDLEA: '07280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0284, 44.5193],
            [-122.9873, 44.6057],
            [-122.8823, 44.6469],
            [-122.7641, 44.6511],
            [-122.5991, 44.6189],
            [-122.5609, 44.5611],
            [-122.6621, 44.5087],
            [-122.6848, 44.4506],
            [-122.8806, 44.4174],
            [-123.0284, 44.5193],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107380',
        NAME: 'Lebanon Community School District 9',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 625028883,
        AWATER: 3147828,
        INTPTLAT: '+44.5322410',
        INTPTLON: '-122.8210443',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0958, 45.044],
            [-123.7848, 45.0445],
            [-123.7251, 45.0005],
            [-123.7251, 44.9117],
            [-123.7249, 44.7391],
            [-123.6026, 44.7212],
            [-123.5969, 44.4772],
            [-123.7204, 44.4331],
            [-123.7152, 44.3606],
            [-123.7683, 44.2545],
            [-123.6838, 44.231],
            [-123.9492, 44.18],
            [-124.1883, 44.1903],
            [-124.1635, 44.3748],
            [-124.1476, 44.7691],
            [-124.0862, 45.0029],
            [-124.0958, 45.044],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107500',
        NAME: 'Lincoln County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2799651046,
        AWATER: 610132433,
        INTPTLAT: '+44.6066258',
        INTPTLON: '-123.9130127',
        ELSDLEA: '',
        UNSDLEA: '07500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.3066, 44.9959],
            [-119.1639, 44.9959],
            [-118.6754, 44.997],
            [-118.6782, 44.78],
            [-118.8412, 44.7803],
            [-118.8423, 44.6496],
            [-118.6771, 44.6496],
            [-118.7171, 44.5614],
            [-118.8182, 44.5611],
            [-118.991, 44.5323],
            [-119.2219, 44.532],
            [-119.2821, 44.561],
            [-119.322, 44.5612],
            [-119.3237, 44.6914],
            [-119.1831, 44.8799],
            [-119.3063, 44.8941],
            [-119.3066, 44.9959],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107530',
        NAME: 'Long Creek School District 17',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2150950773,
        AWATER: 18184,
        INTPTLAT: '+44.7773512',
        INTPTLON: '-119.0063990',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7649, 44.0121],
            [-122.6243, 44.0428],
            [-122.2662, 44.0451],
            [-122.2657, 43.9519],
            [-122.3862, 43.9517],
            [-122.5674, 43.8694],
            [-122.5895, 43.7469],
            [-122.746, 43.7816],
            [-122.7467, 43.8608],
            [-122.8644, 43.9829],
            [-122.7649, 44.0121],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107590',
        NAME: 'Lowell School District 71',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 809771256,
        AWATER: 27524704,
        INTPTLAT: '+43.9411012',
        INTPTLON: '-122.5839671',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.9492, 44.18],
            [-123.6838, 44.231],
            [-123.6541, 44.2088],
            [-123.6721, 44.0701],
            [-123.6435, 43.9972],
            [-123.7587, 43.9445],
            [-123.8759, 43.914],
            [-124.0225, 43.9884],
            [-123.8885, 44.074],
            [-123.9492, 44.18],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107710',
        NAME: 'Mapleton School District 32',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 652446149,
        AWATER: 1456340,
        INTPTLAT: '+44.0736192',
        INTPTLON: '-123.8281377',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9858, 44.2002],
            [-122.8841, 44.2589],
            [-122.7245, 44.2882],
            [-122.6457, 44.2534],
            [-122.6456, 44.1645],
            [-122.9057, 44.1645],
            [-122.9857, 44.1712],
            [-122.9858, 44.2002],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107740',
        NAME: 'Marcola School District 79J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301890169,
        AWATER: 0,
        INTPTLAT: '+44.2142867',
        INTPTLON: '-122.7865940',
        ELSDLEA: '',
        UNSDLEA: '07740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.2164, 42.8059],
            [-117.9808, 42.8064],
            [-117.9849, 42.633],
            [-117.4775, 42.6321],
            [-117.4486, 42.7655],
            [-117.2918, 42.7067],
            [-117.1437, 42.4919],
            [-117.0264, 42.4305],
            [-117.0262, 41.9999],
            [-118.1972, 41.997],
            [-118.2147, 42.276],
            [-118.2164, 42.8059],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107880',
        NAME: 'McDermitt School District 51',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7185978820,
        AWATER: 282522,
        INTPTLAT: '+42.3071136',
        INTPTLON: '-117.6338573',
        ELSDLEA: '',
        UNSDLEA: '07880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.6457, 44.2534],
            [-122.3675, 44.2091],
            [-122.3266, 44.2533],
            [-121.7994, 44.2581],
            [-121.786, 44.2332],
            [-121.7721, 44.0901],
            [-121.8191, 44.0509],
            [-121.8216, 44.0115],
            [-121.963, 43.8764],
            [-122.1151, 43.952],
            [-122.2657, 43.9519],
            [-122.2662, 44.0451],
            [-122.6243, 44.0428],
            [-122.6456, 44.1645],
            [-122.6457, 44.2534],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4107980',
        NAME: 'McKenzie School District 68',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1977740385,
        AWATER: 13743952,
        INTPTLAT: '+44.1224463',
        INTPTLON: '-122.1506889',
        ELSDLEA: '',
        UNSDLEA: '07980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1185, 45.2708],
            [-123.131, 45.1442],
            [-123.2128, 45.1697],
            [-123.34, 45.0894],
            [-123.3812, 45.2746],
            [-123.1185, 45.2708],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108010',
        NAME: 'McMinnville School District 40',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305480737,
        AWATER: 127197,
        INTPTLAT: '+45.2119166',
        INTPTLON: '-123.2820914',
        ELSDLEA: '',
        UNSDLEA: '08010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1121, 42.3191],
            [-123.075, 42.3481],
            [-122.8872, 42.3648],
            [-122.8559, 42.4171],
            [-122.7551, 42.3153],
            [-122.8766, 42.2466],
            [-122.8755, 42.0033],
            [-122.9877, 42.0032],
            [-123.1029, 42.0068],
            [-123.2308, 42.0039],
            [-123.1494, 42.0835],
            [-123.151, 42.2182],
            [-123.1121, 42.3191],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108040',
        NAME: 'Medford School District 549',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 949379154,
        AWATER: 4249068,
        INTPTLAT: '+42.2053810',
        INTPTLON: '-122.9932690',
        ELSDLEA: '',
        UNSDLEA: '08040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5991, 44.6189],
            [-122.5987, 44.6928],
            [-122.5584, 44.6927],
            [-122.496, 44.8686],
            [-122.3177, 44.8857],
            [-122.2575, 44.8857],
            [-121.7342, 44.8858],
            [-121.8192, 44.7897],
            [-121.7977, 44.6467],
            [-121.8009, 44.5663],
            [-122.5609, 44.5611],
            [-122.5991, 44.6189],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108100',
        NAME: 'Santiam Canyon School District 129J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2097111784,
        AWATER: 17554705,
        INTPTLAT: '+44.7133366',
        INTPTLON: '-122.1936128',
        ELSDLEA: '',
        UNSDLEA: '08100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.7252, 46.001],
            [-118.5757, 46.0009],
            [-118.5549, 46.0009],
            [-118.3675, 46.0008],
            [-117.9969, 46.0008],
            [-117.9778, 46.0007],
            [-117.9729, 45.8606],
            [-118.045, 45.8171],
            [-118.3623, 45.816],
            [-118.4224, 45.882],
            [-118.5788, 45.9189],
            [-118.7151, 45.9359],
            [-118.7252, 46.001],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108160',
        NAME: 'Milton-Freewater School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 927446551,
        AWATER: 47030,
        INTPTLAT: '+45.9380393',
        INTPTLON: '-118.2690376',
        ELSDLEA: '',
        UNSDLEA: '08160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3855, 44.564],
            [-120.3863, 44.6531],
            [-120.2644, 44.7102],
            [-120.1626, 44.681],
            [-120.0199, 44.7547],
            [-120.0425, 44.6222],
            [-119.8176, 44.6523],
            [-119.7778, 44.5656],
            [-119.654, 44.4613],
            [-119.6556, 44.307],
            [-119.8988, 44.3067],
            [-119.899, 44.3895],
            [-120.0218, 44.4386],
            [-120.3858, 44.4376],
            [-120.3855, 44.564],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108280',
        NAME: 'Mitchell School District 55',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1642645924,
        AWATER: 694283,
        INTPTLAT: '+44.5177771',
        INTPTLON: '-120.0161252',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5385, 45.2508],
            [-122.4531, 45.2741],
            [-122.4154, 45.1977],
            [-122.4985, 45.1711],
            [-122.4984, 45.1],
            [-122.3794, 45.0349],
            [-122.3789, 44.8857],
            [-122.5344, 44.9366],
            [-122.5326, 45.0074],
            [-122.7018, 45.1145],
            [-122.6792, 45.1948],
            [-122.5385, 45.2508],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108310',
        NAME: 'Molalla River School District 35',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 538130778,
        AWATER: 676827,
        INTPTLAT: '+45.0844293',
        INTPTLON: '-122.5192688',
        ELSDLEA: '',
        UNSDLEA: '08310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4739, 44.4343],
            [-123.2336, 44.4124],
            [-123.2297, 44.346],
            [-123.23, 44.2837],
            [-123.4717, 44.2811],
            [-123.5329, 44.2813],
            [-123.5533, 44.3742],
            [-123.4739, 44.4343],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108430',
        NAME: 'Monroe School District 1J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339914129,
        AWATER: 788014,
        INTPTLAT: '+44.3502692',
        INTPTLON: '-123.3924742',
        ELSDLEA: '',
        UNSDLEA: '08430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.672, 44.9944],
            [-119.3066, 44.9959],
            [-119.3063, 44.8941],
            [-119.1831, 44.8799],
            [-119.3237, 44.6914],
            [-119.322, 44.5612],
            [-119.5313, 44.5621],
            [-119.5316, 44.6207],
            [-119.654, 44.6218],
            [-119.6521, 44.8232],
            [-119.672, 44.9944],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108460',
        NAME: 'Monument School District 8',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1396961022,
        AWATER: 53512,
        INTPTLAT: '+44.7603341',
        INTPTLON: '-119.4806379',
        ELSDLEA: '',
        UNSDLEA: '08460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9995, 45.8125],
            [-119.9089, 45.828],
            [-119.8681, 45.836],
            [-119.6699, 45.8569],
            [-119.5716, 45.9255],
            [-119.4563, 45.9156],
            [-119.4319, 45.9183],
            [-119.4326, 45.8793],
            [-119.4348, 45.7461],
            [-119.4346, 45.6028],
            [-119.2483, 45.5732],
            [-119.1454, 45.5161],
            [-119.1469, 45.171],
            [-119.1639, 44.9959],
            [-119.3066, 44.9959],
            [-119.672, 44.9944],
            [-119.7912, 45.0235],
            [-119.7904, 45.0678],
            [-119.7601, 45.1676],
            [-119.883, 45.1697],
            [-119.882, 45.256],
            [-119.7586, 45.3368],
            [-119.7557, 45.4638],
            [-119.6862, 45.5451],
            [-119.6833, 45.6881],
            [-119.9988, 45.688],
            [-119.9995, 45.8125],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108520',
        NAME: 'Morrow School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4269387792,
        AWATER: 43778389,
        INTPTLAT: '+45.3869477',
        INTPTLON: '-119.5259175',
        ELSDLEA: '',
        UNSDLEA: '08520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8309, 45.1005],
            [-122.7958, 45.0934],
            [-122.8377, 45.065],
            [-122.8309, 45.1005],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108550',
        NAME: 'Mount Angel School District 91',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34590160,
        AWATER: 92063,
        INTPTLAT: '+45.0653929',
        INTPTLON: '-122.7958424',
        ELSDLEA: '',
        UNSDLEA: '08550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0654, 45.7831],
            [-123.7193, 45.7833],
            [-123.361, 45.7797],
            [-123.361, 45.7434],
            [-123.4847, 45.6945],
            [-123.3203, 45.5931],
            [-123.5825, 45.6072],
            [-123.6219, 45.5185],
            [-123.722, 45.5627],
            [-124.0368, 45.5685],
            [-124.0108, 45.7083],
            [-124.0654, 45.7831],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108650',
        NAME: 'Neah-Kah-Nie School District 56',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1024624373,
        AWATER: 171612777,
        INTPTLAT: '+45.6788581',
        INTPTLON: '-123.7371536',
        ELSDLEA: '',
        UNSDLEA: '08650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0958, 45.044],
            [-124.0501, 45.1407],
            [-124.0403, 45.2688],
            [-124.0754, 45.3471],
            [-123.8449, 45.348],
            [-123.7622, 45.3769],
            [-123.4637, 45.3745],
            [-123.4631, 45.2745],
            [-123.5076, 45.216],
            [-123.7417, 45.2163],
            [-123.744, 45.0887],
            [-123.7848, 45.0445],
            [-124.0958, 45.044],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108700',
        NAME: 'Nestucca Valley School District 101J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 966396767,
        AWATER: 223918434,
        INTPTLAT: '+45.2455524',
        INTPTLON: '-123.7605784',
        ELSDLEA: '',
        UNSDLEA: '08700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0636, 45.4046],
            [-123.0629, 45.4046],
            [-122.9449, 45.3727],
            [-122.8415, 45.303],
            [-122.8331, 45.2764],
            [-122.8347, 45.274],
            [-122.8825, 45.2553],
            [-122.9993, 45.2616],
            [-123.0683, 45.2864],
            [-123.0636, 45.4046],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108720',
        NAME: 'Newberg School District 29J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209108617,
        AWATER: 1876775,
        INTPTLAT: '+45.3207227',
        INTPTLON: '-122.9824492',
        ELSDLEA: '',
        UNSDLEA: '08720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.2903, 43.6106],
            [-123.8754, 43.6083],
            [-123.8755, 43.5159],
            [-124.0637, 43.5228],
            [-124.0829, 43.3934],
            [-124.2397, 43.3876],
            [-124.2289, 43.452],
            [-124.3526, 43.452],
            [-124.2903, 43.6106],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108820',
        NAME: 'North Bend School District 13',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 469150901,
        AWATER: 128599213,
        INTPTLAT: '+43.5187797',
        INTPTLON: '-124.1486544',
        ELSDLEA: '',
        UNSDLEA: '08820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.661, 45.4578],
            [-122.553, 45.461],
            [-122.5171, 45.4613],
            [-122.4751, 45.4286],
            [-122.4506, 45.3892],
            [-122.4508, 45.3843],
            [-122.5711, 45.3914],
            [-122.6, 45.3968],
            [-122.6313, 45.3946],
            [-122.6374, 45.3985],
            [-122.6487, 45.4327],
            [-122.661, 45.4578],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108830',
        NAME: 'North Clackamas School District 12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104178917,
        AWATER: 2107098,
        INTPTLAT: '+45.4264458',
        INTPTLON: '-122.5518093',
        ELSDLEA: '',
        UNSDLEA: '08830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8882, 45.1941],
            [-122.8825, 45.2553],
            [-122.8347, 45.274],
            [-122.7415, 45.2587],
            [-122.7733, 45.161],
            [-122.8882, 45.1941],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108880',
        NAME: 'North Marion School District 15',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113215651,
        AWATER: 597129,
        INTPTLAT: '+45.2164059',
        INTPTLON: '-122.8145496',
        ELSDLEA: '',
        UNSDLEA: '08880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9008, 45.1525],
            [-117.8686, 45.0576],
            [-117.7775, 44.9922],
            [-118.1182, 44.9783],
            [-118.2448, 44.9583],
            [-118.3076, 44.9697],
            [-118.3079, 45.0812],
            [-118.145, 45.0821],
            [-118.1465, 45.1674],
            [-117.9008, 45.1525],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4108940',
        NAME: 'North Powder School District 8J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 622476503,
        AWATER: 1977657,
        INTPTLAT: '+45.0533247',
        INTPTLON: '-118.0396013',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.4826, 43.8664],
            [-117.2525, 43.8677],
            [-117.2524, 43.9245],
            [-117.1259, 43.9524],
            [-116.9691, 43.9377],
            [-116.9838, 43.88],
            [-117.0243, 43.8125],
            [-117.2822, 43.8096],
            [-117.2822, 43.7228],
            [-117.1914, 43.7013],
            [-117.3468, 43.5637],
            [-117.5114, 43.5577],
            [-117.5142, 43.8663],
            [-117.4826, 43.8664],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109000',
        NAME: 'Nyssa School District 26',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 952477099,
        AWATER: 8752127,
        INTPTLAT: '+43.7777878',
        INTPTLON: '-117.2994213',
        ELSDLEA: '',
        UNSDLEA: '09000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-123.1075, 43.54],
              [-122.7418, 43.5446],
              [-122.7415, 43.5176],
              [-122.8933, 43.5174],
              [-123.1078, 43.5181],
              [-123.1075, 43.54],
            ],
          ],
          [
            [
              [-123.8168, 43.516],
              [-123.7051, 43.5596],
              [-123.6841, 43.4602],
              [-123.5847, 43.4604],
              [-123.4271, 43.52],
              [-123.1078, 43.5181],
              [-123.1088, 43.4452],
              [-123.2663, 43.4326],
              [-123.3474, 43.3578],
              [-123.4565, 43.4336],
              [-123.5531, 43.3953],
              [-123.764, 43.388],
              [-123.8177, 43.4312],
              [-123.8168, 43.516],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109120',
        NAME: 'Oakland School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 699697475,
        AWATER: 4182661,
        INTPTLAT: '+43.4465982',
        INTPTLON: '-123.3919808',
        ELSDLEA: '',
        UNSDLEA: '09120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2657, 43.9519],
            [-122.1151, 43.952],
            [-121.963, 43.8764],
            [-121.9671, 43.7025],
            [-122.0027, 43.6152],
            [-122.1309, 43.5571],
            [-122.132, 43.4404],
            [-122.5085, 43.4412],
            [-122.5095, 43.6964],
            [-122.5895, 43.7469],
            [-122.5674, 43.8694],
            [-122.3862, 43.9517],
            [-122.2657, 43.9519],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109150',
        NAME: 'Oakridge School District 76',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2174386793,
        AWATER: 41424432,
        INTPTLAT: '+43.7085078',
        INTPTLON: '-122.2768999',
        ELSDLEA: '',
        UNSDLEA: '09150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.1461, 44.1412],
            [-117.0557, 44.141],
            [-116.9495, 44.1916],
            [-116.8949, 44.1665],
            [-116.9517, 44.0396],
            [-116.9691, 43.9377],
            [-117.1259, 43.9524],
            [-117.0752, 44.0826],
            [-117.1461, 44.1412],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109270',
        NAME: 'Ontario School District 8',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 319364588,
        AWATER: 5134530,
        INTPTLAT: '+44.0648095',
        INTPTLON: '-117.0183897',
        ELSDLEA: '',
        UNSDLEA: '09270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-122.6313, 45.3946],
              [-122.6, 45.3968],
              [-122.6178, 45.3757],
              [-122.6313, 45.3946],
            ],
          ],
          [
            [
              [-122.6078, 45.3723],
              [-122.5711, 45.3914],
              [-122.4508, 45.3843],
              [-122.4324, 45.2849],
              [-122.4531, 45.2741],
              [-122.5385, 45.2508],
              [-122.6648, 45.3096],
              [-122.6078, 45.3723],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109330',
        NAME: 'Oregon City School District 62',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202138941,
        AWATER: 2773828,
        INTPTLAT: '+45.3314353',
        INTPTLON: '-122.5128817',
        ELSDLEA: '',
        UNSDLEA: '09330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.3509, 42.9167],
            [-120.9962, 42.9178],
            [-120.9979, 43.0044],
            [-120.8794, 43.0056],
            [-120.8796, 43.0922],
            [-120.2786, 43.0927],
            [-120.2791, 42.9174],
            [-120.4217, 42.9178],
            [-120.4157, 42.694],
            [-120.3568, 42.5921],
            [-120.4148, 42.5048],
            [-120.5314, 42.4893],
            [-120.8811, 42.4865],
            [-120.8833, 42.7439],
            [-121.3499, 42.7467],
            [-121.3509, 42.9167],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109430',
        NAME: 'Paisley School District 11',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3557028616,
        AWATER: 133287183,
        INTPTLAT: '+42.7897884',
        INTPTLON: '-120.7853255',
        ELSDLEA: '',
        UNSDLEA: '09430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.635, 45.6093],
            [-122.586, 45.6047],
            [-122.5171, 45.5191],
            [-122.5647, 45.5332],
            [-122.635, 45.6093],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109480',
        NAME: 'Parkrose School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29137710,
        AWATER: 5215183,
        INTPTLAT: '+45.5685716',
        INTPTLON: '-122.5620702',
        ELSDLEA: '',
        UNSDLEA: '09480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.0477, 45.7904],
            [-118.9859, 45.8515],
            [-118.9238, 45.8645],
            [-118.7661, 45.7761],
            [-118.622, 45.8052],
            [-118.5185, 45.7755],
            [-118.5401, 45.6028],
            [-118.3748, 45.6028],
            [-118.117, 45.6738],
            [-118.117, 45.6007],
            [-118.1173, 45.4928],
            [-118.198, 45.4272],
            [-118.4285, 45.429],
            [-118.6221, 45.5152],
            [-118.7459, 45.5368],
            [-119.0042, 45.5383],
            [-119.0563, 45.6034],
            [-119.0477, 45.7904],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109510',
        NAME: 'Pendleton School District 16',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1960782062,
        AWATER: 4583615,
        INTPTLAT: '+45.6172535',
        INTPTLON: '-118.6200325',
        ELSDLEA: '',
        UNSDLEA: '09510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4442, 45.0388],
            [-123.3424, 45.0522],
            [-123.2079, 45.0195],
            [-123.4387, 44.9693],
            [-123.4442, 45.0388],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109530',
        NAME: 'Perrydale School District 21',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141038320,
        AWATER: 284489,
        INTPTLAT: '+45.0178623',
        INTPTLON: '-123.3017335',
        ELSDLEA: '',
        UNSDLEA: '09530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6026, 44.7212],
            [-123.5823, 44.7362],
            [-123.4141, 44.7206],
            [-123.3521, 44.6949],
            [-123.371, 44.564],
            [-123.4739, 44.4343],
            [-123.5969, 44.4772],
            [-123.6026, 44.7212],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109600',
        NAME: 'Philomath School District 17J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 530680751,
        AWATER: 809599,
        INTPTLAT: '+44.5957682',
        INTPTLON: '-123.4770613',
        ELSDLEA: '',
        UNSDLEA: '09600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7551, 42.3153],
            [-122.6774, 42.2896],
            [-122.7554, 42.2753],
            [-122.7536, 42.0041],
            [-122.8755, 42.0033],
            [-122.8766, 42.2466],
            [-122.7551, 42.3153],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109630',
        NAME: 'Phoenix-Talent School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358389300,
        AWATER: 53662,
        INTPTLAT: '+42.1632200',
        INTPTLON: '-122.8117840',
        ELSDLEA: '',
        UNSDLEA: '09630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.2483, 45.5732],
            [-119.0563, 45.6034],
            [-119.0042, 45.5383],
            [-118.7459, 45.5368],
            [-118.6221, 45.5152],
            [-118.4285, 45.429],
            [-118.4283, 45.3552],
            [-118.6977, 45.3452],
            [-118.6968, 45.2577],
            [-119.0233, 45.2576],
            [-119.023, 45.171],
            [-119.1469, 45.171],
            [-119.1454, 45.5161],
            [-119.2483, 45.5732],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109660',
        NAME: 'Pilot Rock School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1608457952,
        AWATER: 103091,
        INTPTLAT: '+45.4092940',
        INTPTLON: '-118.8535816',
        ELSDLEA: '',
        UNSDLEA: '09660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.3819, 45.0803],
            [-117.2666, 45.0808],
            [-116.7839, 45.079],
            [-116.848, 45.0217],
            [-116.832, 44.933],
            [-116.8997, 44.8406],
            [-116.9347, 44.7839],
            [-117.0442, 44.7451],
            [-117.0794, 44.6887],
            [-117.2597, 44.6882],
            [-117.3834, 44.6876],
            [-117.3819, 45.0803],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109720',
        NAME: 'Pine-Eagle School District 61',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1652965375,
        AWATER: 18785303,
        INTPTLAT: '+44.8900891',
        INTPTLON: '-117.1323641',
        ELSDLEA: '',
        UNSDLEA: '09720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2873, 42.203],
            [-122.2895, 42.0078],
            [-122.4035, 42.0087],
            [-122.4421, 42.097],
            [-122.4424, 42.2031],
            [-122.2873, 42.203],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109750',
        NAME: 'Pinehurst School District 94',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 243196721,
        AWATER: 187663,
        INTPTLAT: '+42.0961079',
        INTPTLON: '-122.3534106',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.7649, 44.0121],
            [-122.8644, 43.9829],
            [-122.7467, 43.8608],
            [-122.746, 43.7816],
            [-122.8265, 43.7816],
            [-122.8662, 43.8541],
            [-122.9841, 43.9594],
            [-122.9433, 44.0207],
            [-122.7649, 44.0121],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109870',
        NAME: 'Pleasant Hill School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277699632,
        AWATER: 1279099,
        INTPTLAT: '+43.9054748',
        INTPTLON: '-122.8664390',
        ELSDLEA: '',
        UNSDLEA: '09870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.9439, 42.7463],
            [-119.3653, 42.749],
            [-119.3636, 42.2201],
            [-120.046, 42.2214],
            [-120.0455, 42.2962],
            [-120.1645, 42.2971],
            [-120.1623, 42.6892],
            [-119.9442, 42.6882],
            [-119.9439, 42.7463],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4109960',
        NAME: 'Plush School District 18',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3532006034,
        AWATER: 112664193,
        INTPTLAT: '+42.4238067',
        INTPTLON: '-119.7767205',
        ELSDLEA: '',
        UNSDLEA: '09960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.521, 43.0268],
            [-124.296, 43.0109],
            [-124.1612, 42.8748],
            [-124.1602, 42.7682],
            [-124.2986, 42.7001],
            [-124.2997, 42.6369],
            [-124.5353, 42.6377],
            [-124.5847, 42.7193],
            [-124.7003, 42.784],
            [-124.5724, 42.9225],
            [-124.521, 43.0268],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110020',
        NAME: 'Port Orford-Langlois School District 2J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 913423494,
        AWATER: 419808314,
        INTPTLAT: '+42.8422294',
        INTPTLON: '-124.4027957',
        ELSDLEA: '',
        UNSDLEA: '10020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9291, 45.6938],
            [-122.8162, 45.628],
            [-122.7436, 45.6469],
            [-122.635, 45.6093],
            [-122.5647, 45.5332],
            [-122.553, 45.461],
            [-122.661, 45.4578],
            [-122.6727, 45.4327],
            [-122.7441, 45.4333],
            [-122.7436, 45.4549],
            [-122.7442, 45.523],
            [-122.847, 45.5775],
            [-122.9292, 45.6339],
            [-122.9291, 45.6938],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110040',
        NAME: 'Portland School District 1J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351026146,
        AWATER: 27129845,
        INTPTLAT: '+45.5584071',
        INTPTLON: '-122.7274106',
        ELSDLEA: '',
        UNSDLEA: '10040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1612, 42.8748],
            [-123.8197, 42.8843],
            [-123.8117, 42.8243],
            [-123.8121, 42.7894],
            [-123.9255, 42.7749],
            [-123.997, 42.6856],
            [-124.1389, 42.6708],
            [-124.1602, 42.7682],
            [-124.1612, 42.8748],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110080',
        NAME: 'Powers School District 31',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 533232438,
        AWATER: 730744,
        INTPTLAT: '+42.8012883',
        INTPTLON: '-124.0124053',
        ELSDLEA: '',
        UNSDLEA: '10080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.6754, 44.997],
            [-118.5191, 44.996],
            [-118.3076, 44.9697],
            [-118.2448, 44.9583],
            [-118.2281, 44.8654],
            [-118.319, 44.8363],
            [-118.2987, 44.7463],
            [-118.3677, 44.7473],
            [-118.505, 44.6663],
            [-118.373, 44.6454],
            [-118.3067, 44.5866],
            [-118.422, 44.4485],
            [-118.4095, 44.3783],
            [-118.4972, 44.2551],
            [-118.2321, 44.2573],
            [-118.2282, 44.1832],
            [-118.2274, 44.04],
            [-118.4663, 44.0404],
            [-118.4665, 44.0841],
            [-118.7393, 44.0908],
            [-118.7313, 44.3884],
            [-118.815, 44.4453],
            [-118.8182, 44.5611],
            [-118.7171, 44.5614],
            [-118.6771, 44.6496],
            [-118.8423, 44.6496],
            [-118.8412, 44.7803],
            [-118.6782, 44.78],
            [-118.6754, 44.997],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110110',
        NAME: 'Prairie City School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3315914094,
        AWATER: 1259805,
        INTPTLAT: '+44.4924642',
        INTPTLON: '-118.5390746',
        ELSDLEA: '',
        UNSDLEA: '10110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.5205, 42.9391],
            [-122.4005, 42.9964],
            [-122.2819, 42.9966],
            [-122.2837, 42.7075],
            [-122.4801, 42.7075],
            [-122.5197, 42.7726],
            [-122.5205, 42.9391],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110200',
        NAME: 'Prospect School District 59',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 646846015,
        AWATER: 10157641,
        INTPTLAT: '+42.8308193',
        INTPTLON: '-122.4246084',
        ELSDLEA: '',
        UNSDLEA: '10200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.786, 44.2332],
            [-121.5971, 44.234],
            [-121.5866, 44.1833],
            [-121.4231, 44.1832],
            [-121.3928, 44.2188],
            [-121.4054, 44.393],
            [-121.3453, 44.4521],
            [-121.2304, 44.4513],
            [-121.1073, 44.3905],
            [-121.1026, 44.138],
            [-120.9873, 44.1338],
            [-120.987, 44.0476],
            [-121.103, 44.11],
            [-121.3838, 44.1234],
            [-121.4028, 44.0514],
            [-121.8191, 44.0509],
            [-121.7721, 44.0901],
            [-121.786, 44.2332],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110350',
        NAME: 'Redmond School District 2J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1420627245,
        AWATER: 1925468,
        INTPTLAT: '+44.2175867',
        INTPTLON: '-121.3732308',
        ELSDLEA: '',
        UNSDLEA: '10350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.2359, 43.8315],
            [-123.9563, 43.8538],
            [-123.8759, 43.914],
            [-123.7587, 43.9445],
            [-123.7034, 43.8629],
            [-123.7049, 43.6932],
            [-123.7924, 43.6926],
            [-123.7935, 43.6077],
            [-123.7051, 43.5596],
            [-123.8168, 43.516],
            [-123.8755, 43.5159],
            [-123.8754, 43.6083],
            [-124.2903, 43.6106],
            [-124.2359, 43.8315],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110410',
        NAME: 'Reedsport School District 105',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1195429355,
        AWATER: 183945635,
        INTPTLAT: '+43.7467575',
        INTPTLON: '-123.9530881',
        ELSDLEA: '',
        UNSDLEA: '10410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.586, 45.6047],
            [-122.5823, 45.6041],
            [-122.4758, 45.5787],
            [-122.3836, 45.5755],
            [-122.3418, 45.5585],
            [-122.3612, 45.5153],
            [-122.4569, 45.5086],
            [-122.5171, 45.5191],
            [-122.586, 45.6047],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110520',
        NAME: 'Reynolds School District 7',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65183951,
        AWATER: 20330171,
        INTPTLAT: '+45.5520518',
        INTPTLON: '-122.4412718',
        ELSDLEA: '',
        UNSDLEA: '10520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.6428, 42.9414],
            [-123.426, 42.9796],
            [-123.3235, 42.9136],
            [-123.4628, 42.8901],
            [-123.46, 42.8228],
            [-123.702, 42.8234],
            [-123.7021, 42.9102],
            [-123.6428, 42.9414],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110530',
        NAME: 'Riddle School District 70',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 360307245,
        AWATER: 240125,
        INTPTLAT: '+42.8916338',
        INTPTLON: '-123.5119292',
        ELSDLEA: '',
        UNSDLEA: '10530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.661, 45.4578],
            [-122.6487, 45.4327],
            [-122.6727, 45.4327],
            [-122.661, 45.4578],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110560',
        NAME: 'Riverdale School District 51J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4406071,
        AWATER: 540509,
        INTPTLAT: '+45.4454998',
        INTPTLON: '-122.6613922',
        ELSDLEA: '',
        UNSDLEA: '10560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.2299, 42.7025],
            [-123.1514, 42.7561],
            [-122.9907, 42.7585],
            [-122.9943, 42.579],
            [-123.1122, 42.5356],
            [-123.075, 42.3481],
            [-123.1121, 42.3191],
            [-123.2294, 42.3557],
            [-123.2299, 42.7025],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110680',
        NAME: 'Rogue River School District 35',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 653542233,
        AWATER: 746017,
        INTPTLAT: '+42.5854257',
        INTPTLON: '-123.1092477',
        ELSDLEA: '',
        UNSDLEA: '10680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.764, 43.388],
            [-123.5531, 43.3953],
            [-123.4565, 43.4336],
            [-123.3474, 43.3578],
            [-123.1891, 43.3725],
            [-123.2984, 43.2893],
            [-123.2983, 43.1862],
            [-123.2442, 43.0914],
            [-123.3914, 43.1133],
            [-123.457, 43.1973],
            [-123.5856, 43.2433],
            [-123.704, 43.2433],
            [-123.764, 43.2579],
            [-123.764, 43.388],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110710',
        NAME: 'Roseburg School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 856562364,
        AWATER: 8441764,
        INTPTLAT: '+43.2820696',
        INTPTLON: '-123.4698347',
        ELSDLEA: '',
        UNSDLEA: '10710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0687, 45.075],
            [-122.8963, 45.0211],
            [-122.9277, 44.9554],
            [-122.8572, 44.8889],
            [-122.9604, 44.8891],
            [-123.0102, 44.8021],
            [-123.133, 44.8424],
            [-123.1011, 44.9411],
            [-123.1638, 45],
            [-123.0687, 45.075],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110820',
        NAME: 'Salem-Keizer School District 24J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439232828,
        AWATER: 6187346,
        INTPTLAT: '+44.9449465',
        INTPTLON: '-123.0280604',
        ELSDLEA: '',
        UNSDLEA: '10820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.2731, 45.4615],
            [-121.8204, 45.4617],
            [-121.6993, 45.3785],
            [-121.6968, 45.258],
            [-121.7382, 45.1804],
            [-122.0021, 45.1802],
            [-122.0035, 45.3226],
            [-122.1685, 45.2863],
            [-122.4092, 45.3962],
            [-122.2731, 45.4615],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110920',
        NAME: 'Oregon Trail School District 46',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1103654915,
        AWATER: 5911215,
        INTPTLAT: '+45.3239840',
        INTPTLON: '-121.9904531',
        ELSDLEA: '',
        UNSDLEA: '10920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0517, 45.8659],
            [-122.7957, 45.8152],
            [-122.761, 45.7504],
            [-122.7436, 45.6469],
            [-122.8162, 45.628],
            [-122.9291, 45.6938],
            [-123.0162, 45.706],
            [-123.0507, 45.7793],
            [-123.0512, 45.7793],
            [-123.0517, 45.8659],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4110980',
        NAME: 'Scappoose School District 1J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340287138,
        AWATER: 34609449,
        INTPTLAT: '+45.7750314',
        INTPTLON: '-122.9178865',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9169, 44.7286],
            [-122.8451, 44.7747],
            [-122.8254, 44.7234],
            [-122.6564, 44.7288],
            [-122.5987, 44.6928],
            [-122.5991, 44.6189],
            [-122.7641, 44.6511],
            [-122.8823, 44.6469],
            [-122.9678, 44.6762],
            [-122.9169, 44.7286],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111040',
        NAME: 'Scio School District 95',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278250173,
        AWATER: 389377,
        INTPTLAT: '+44.6836948',
        INTPTLON: '-122.7558049',
        ELSDLEA: '',
        UNSDLEA: '11040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0156, 46.0974],
            [-123.8989, 46.0976],
            [-123.8561, 45.9535],
            [-123.6693, 45.9532],
            [-123.6665, 45.8164],
            [-123.7193, 45.7833],
            [-124.0654, 45.7831],
            [-124.0458, 45.8906],
            [-124.0924, 45.9343],
            [-124.0081, 46.0099],
            [-124.0156, 46.0974],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111100',
        NAME: 'Seaside School District 10',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 513194990,
        AWATER: 244231723,
        INTPTLAT: '+45.9094109',
        INTPTLON: '-123.8912039',
        ELSDLEA: '',
        UNSDLEA: '11100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4426, 45.2137],
            [-123.4221, 45.2745],
            [-123.3812, 45.2746],
            [-123.34, 45.0894],
            [-123.3424, 45.0522],
            [-123.4442, 45.0388],
            [-123.4057, 45.11],
            [-123.4426, 45.2137],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111220',
        NAME: 'Sheridan School District 48J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163090820,
        AWATER: 163105,
        INTPTLAT: '+45.1438782',
        INTPTLON: '-123.4000475',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.0244, 45.2897],
            [-120.8686, 45.4011],
            [-120.8388, 45.5159],
            [-120.9085, 45.5582],
            [-120.9159, 45.6413],
            [-120.8857, 45.65],
            [-120.6536, 45.7372],
            [-120.4099, 45.5941],
            [-120.3734, 45.4937],
            [-120.4904, 45.4689],
            [-120.5321, 45.3712],
            [-120.5291, 45.2047],
            [-120.4584, 45.1195],
            [-120.5035, 45.0829],
            [-120.5041, 45.083],
            [-120.6886, 45.0837],
            [-120.7719, 45.1627],
            [-121.0243, 45.2195],
            [-121.0244, 45.2897],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111250',
        NAME: 'Sherman School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2157882771,
        AWATER: 19403373,
        INTPTLAT: '+45.3992158',
        INTPTLON: '-120.6785124',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8652, 45.4046],
            [-122.7439, 45.3596],
            [-122.8415, 45.303],
            [-122.9449, 45.3727],
            [-122.8652, 45.4046],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111290',
        NAME: 'Sherwood School District 88J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98081737,
        AWATER: 16246,
        INTPTLAT: '+45.3539900',
        INTPTLON: '-122.8452815',
        ELSDLEA: '',
        UNSDLEA: '11290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.333, 43.6166],
            [-120.8735, 43.6156],
            [-119.8964, 43.6103],
            [-119.8968, 43.179],
            [-119.9324, 43.179],
            [-119.9316, 42.917],
            [-119.9434, 42.9171],
            [-120.2791, 42.9174],
            [-120.2786, 43.0927],
            [-120.8796, 43.0922],
            [-120.8794, 43.0056],
            [-120.9979, 43.0044],
            [-120.9962, 42.9178],
            [-121.3509, 42.9167],
            [-121.3486, 43.3567],
            [-121.333, 43.6166],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111400',
        NAME: 'North Lake School District 14',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7952244113,
        AWATER: 11902993,
        INTPTLAT: '+43.2978056',
        INTPTLON: '-120.6056746',
        ELSDLEA: '',
        UNSDLEA: '11400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8963, 45.0211],
            [-122.8377, 45.065],
            [-122.7958, 45.0934],
            [-122.7823, 45.1247],
            [-122.7018, 45.1145],
            [-122.5326, 45.0074],
            [-122.5344, 44.9366],
            [-122.3789, 44.8857],
            [-122.3177, 44.8857],
            [-122.496, 44.8686],
            [-122.7627, 44.8604],
            [-122.8572, 44.8889],
            [-122.9277, 44.9554],
            [-122.8963, 45.0211],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111470',
        NAME: 'Silver Falls School District 4J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 671066414,
        AWATER: 794160,
        INTPTLAT: '+44.9584168',
        INTPTLON: '-122.6897009',
        ELSDLEA: '',
        UNSDLEA: '11470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-121.8427, 44.3924],
            [-121.5933, 44.3929],
            [-121.4054, 44.393],
            [-121.3928, 44.2188],
            [-121.4231, 44.1832],
            [-121.5866, 44.1833],
            [-121.5971, 44.234],
            [-121.786, 44.2332],
            [-121.7994, 44.2581],
            [-121.8427, 44.3924],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111490',
        NAME: 'Sisters School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 702490211,
        AWATER: 481556,
        INTPTLAT: '+44.3085420',
        INTPTLON: '-121.6091702',
        ELSDLEA: '',
        UNSDLEA: '11490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1278, 43.8776],
            [-122.9272, 43.8761],
            [-122.8662, 43.8541],
            [-122.8265, 43.7816],
            [-122.746, 43.7816],
            [-122.5895, 43.7469],
            [-122.5095, 43.6964],
            [-122.5085, 43.4412],
            [-122.7408, 43.4371],
            [-122.7415, 43.5176],
            [-122.7418, 43.5446],
            [-123.1075, 43.54],
            [-123.1378, 43.6771],
            [-123.186, 43.7797],
            [-123.1278, 43.8776],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111580',
        NAME: 'South Lane School District 45J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1742013207,
        AWATER: 12147196,
        INTPTLAT: '+43.6614615',
        INTPTLON: '-122.8421870',
        ELSDLEA: '',
        UNSDLEA: '11580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.426, 42.9796],
            [-123.3914, 43.1133],
            [-123.2442, 43.0914],
            [-123.2244, 43.1276],
            [-123.0658, 43.1706],
            [-122.9874, 43.1126],
            [-123.0678, 43.039],
            [-123.2472, 42.9822],
            [-123.2086, 42.8697],
            [-123.2281, 42.8374],
            [-123.46, 42.8228],
            [-123.4628, 42.8901],
            [-123.3235, 42.9136],
            [-123.426, 42.9796],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111610',
        NAME: 'South Umpqua School District 19',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 644783472,
        AWATER: 50423,
        INTPTLAT: '+42.9713324',
        INTPTLON: '-123.2620983',
        ELSDLEA: '',
        UNSDLEA: '11610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.0199, 44.7547],
            [-119.9126, 45.0095],
            [-119.7912, 45.0235],
            [-119.672, 44.9944],
            [-119.6521, 44.8232],
            [-119.654, 44.6218],
            [-119.6545, 44.5341],
            [-119.7778, 44.5656],
            [-119.8176, 44.6523],
            [-120.0425, 44.6222],
            [-120.0199, 44.7547],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111640',
        NAME: 'Spray School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1155159193,
        AWATER: 0,
        INTPTLAT: '+44.7808675',
        INTPTLON: '-119.8181546',
        ELSDLEA: '',
        UNSDLEA: '11640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9857, 44.1712],
            [-122.9057, 44.1645],
            [-122.6456, 44.1645],
            [-122.6243, 44.0428],
            [-122.7649, 44.0121],
            [-122.9433, 44.0207],
            [-122.9841, 43.9594],
            [-123.0495, 43.988],
            [-123.0467, 44.0857],
            [-122.9857, 44.1712],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111670',
        NAME: 'Springfield School District 19',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 469428903,
        AWATER: 5123897,
        INTPTLAT: '+44.0891223',
        INTPTLON: '-122.8422781',
        ELSDLEA: '',
        UNSDLEA: '11670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.0699, 45.9386],
            [-122.8386, 45.9825],
            [-122.8118, 45.9531],
            [-122.7855, 45.8505],
            [-122.7957, 45.8152],
            [-123.0517, 45.8659],
            [-123.0699, 45.9386],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111720',
        NAME: 'St. Helens School District 502',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260279283,
        AWATER: 14277260,
        INTPTLAT: '+45.9118983',
        INTPTLON: '-122.9043443',
        ELSDLEA: '',
        UNSDLEA: '11720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9993, 45.2616],
            [-122.8825, 45.2553],
            [-122.8882, 45.1941],
            [-122.9436, 45.1655],
            [-123.0077, 45.171],
            [-122.9993, 45.2616],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111760',
        NAME: 'St. Paul School District 45',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107956315,
        AWATER: 3114689,
        INTPTLAT: '+45.2195094',
        INTPTLON: '-122.9484929',
        ELSDLEA: '',
        UNSDLEA: '11760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-118.9862, 45.8643],
            [-118.9859, 45.8515],
            [-119.0477, 45.7904],
            [-119.2899, 45.7535],
            [-119.2903, 45.8124],
            [-119.1219, 45.8642],
            [-118.9862, 45.8643],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111790',
        NAME: 'Stanfield School District 61',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156165105,
        AWATER: 139424,
        INTPTLAT: '+45.8104259',
        INTPTLON: '-119.1586160',
        ELSDLEA: '',
        UNSDLEA: '11790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.1078, 43.5181],
            [-122.8933, 43.5174],
            [-123.0313, 43.377],
            [-123.1891, 43.3725],
            [-123.3474, 43.3578],
            [-123.2663, 43.4326],
            [-123.1088, 43.4452],
            [-123.1078, 43.5181],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111940',
        NAME: 'Sutherlin School District 130',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 349052425,
        AWATER: 1402763,
        INTPTLAT: '+43.4375821',
        INTPTLON: '-123.1140728',
        ELSDLEA: '',
        UNSDLEA: '11940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8806, 44.4174],
            [-122.6848, 44.4506],
            [-122.6621, 44.5087],
            [-122.5609, 44.5611],
            [-121.8009, 44.5663],
            [-121.8458, 44.4914],
            [-121.8427, 44.3924],
            [-121.7994, 44.2581],
            [-122.3266, 44.2533],
            [-122.3675, 44.2091],
            [-122.6457, 44.2534],
            [-122.7245, 44.2882],
            [-122.8841, 44.2589],
            [-122.8835, 44.3021],
            [-122.8806, 44.4174],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4111970',
        NAME: 'Sweet Home School District 55',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2641916126,
        AWATER: 26754873,
        INTPTLAT: '+44.3711535',
        INTPTLON: '-122.2897426',
        ELSDLEA: '',
        UNSDLEA: '11970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8652, 45.4046],
            [-122.8595, 45.4146],
            [-122.7436, 45.4549],
            [-122.7441, 45.4333],
            [-122.7285, 45.3834],
            [-122.7439, 45.3596],
            [-122.8652, 45.4046],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4112240',
        NAME: 'Tigard-Tualatin School District 23J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62251037,
        AWATER: 10496,
        INTPTLAT: '+45.4051652',
        INTPTLON: '-122.7863608',
        ELSDLEA: '',
        UNSDLEA: '12240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.0368, 45.5685],
            [-123.722, 45.5627],
            [-123.6219, 45.5185],
            [-123.5825, 45.6072],
            [-123.3203, 45.5931],
            [-123.4603, 45.4774],
            [-123.4649, 45.4338],
            [-123.4637, 45.3745],
            [-123.7622, 45.3769],
            [-123.8449, 45.348],
            [-124.0754, 45.3471],
            [-124.0368, 45.5685],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4112320',
        NAME: 'Tillamook School District 9',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 876483198,
        AWATER: 200642941,
        INTPTLAT: '+45.4567301',
        INTPTLON: '-123.7526189',
        ELSDLEA: '',
        UNSDLEA: '12320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.6236, 45.9991],
            [-117.6032, 45.9989],
            [-117.4801, 45.9978],
            [-117.382, 45.9981],
            [-117.4729, 45.8989],
            [-117.6229, 45.8611],
            [-117.6236, 45.9991],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4112360',
        NAME: 'Troy School District 54',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190155757,
        AWATER: 0,
        INTPTLAT: '+45.9469021',
        INTPTLON: '-117.5258575',
        ELSDLEA: '',
        UNSDLEA: '12360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.1469, 45.171],
            [-119.023, 45.171],
            [-119.0233, 45.2576],
            [-118.6968, 45.2577],
            [-118.6558, 45.1969],
            [-118.5467, 45.197],
            [-118.5191, 44.996],
            [-118.6754, 44.997],
            [-119.1639, 44.9959],
            [-119.1469, 45.171],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4112540',
        NAME: 'Ukiah School District 80',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1248648213,
        AWATER: 19696,
        INTPTLAT: '+45.1056562',
        INTPTLON: '-118.8551207',
        ELSDLEA: '',
        UNSDLEA: '12540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-119.4319, 45.9183],
            [-119.2658, 45.939],
            [-119.2865, 45.8791],
            [-119.4326, 45.8793],
            [-119.4319, 45.9183],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4112600',
        NAME: 'Umatilla School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58518898,
        AWATER: 8118182,
        INTPTLAT: '+45.9035273',
        INTPTLON: '-119.3442028',
        ELSDLEA: '',
        UNSDLEA: '12600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.8977, 45.3045],
            [-117.7265, 45.225],
            [-117.5204, 45.2236],
            [-117.477, 45.1653],
            [-117.3733, 45.166],
            [-117.2672, 45.1659],
            [-117.2666, 45.0808],
            [-117.3819, 45.0803],
            [-117.5631, 45.0796],
            [-117.5635, 45.0361],
            [-117.7473, 45.0356],
            [-117.7775, 44.9922],
            [-117.8686, 45.0576],
            [-117.9008, 45.1525],
            [-117.8977, 45.3045],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4112690',
        NAME: 'Union School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 884338711,
        AWATER: 2468830,
        INTPTLAT: '+45.1286446',
        INTPTLON: '-117.6518423',
        ELSDLEA: '',
        UNSDLEA: '12690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.3654, 46.0695],
            [-123.3651, 46.0831],
            [-123.2614, 46.083],
            [-123.1995, 45.9963],
            [-123.1126, 45.9963],
            [-123.0699, 45.9386],
            [-123.0517, 45.8659],
            [-123.0512, 45.7793],
            [-123.1959, 45.78],
            [-123.361, 45.7434],
            [-123.361, 45.7797],
            [-123.3654, 46.0695],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4112930',
        NAME: 'Vernonia School District 47J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 674000013,
        AWATER: 220905,
        INTPTLAT: '+45.8986794',
        INTPTLON: '-123.2221692',
        ELSDLEA: '',
        UNSDLEA: '12930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.9778, 46.0007],
            [-117.6236, 45.9991],
            [-117.6229, 45.8611],
            [-117.4729, 45.8989],
            [-117.4344, 45.8055],
            [-117.2511, 45.6892],
            [-117.2921, 45.6016],
            [-117.3644, 45.5947],
            [-117.425, 45.4335],
            [-117.375, 45.3566],
            [-117.3733, 45.166],
            [-117.477, 45.1653],
            [-117.5204, 45.2236],
            [-117.5219, 45.2678],
            [-117.6437, 45.3372],
            [-117.6838, 45.4419],
            [-117.7273, 45.5147],
            [-117.7271, 45.6173],
            [-117.7876, 45.6896],
            [-117.7473, 45.861],
            [-117.9729, 45.8606],
            [-117.9778, 46.0007],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4112990',
        NAME: 'Wallowa School District 12',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2370159118,
        AWATER: 1077064,
        INTPTLAT: '+45.6369759',
        INTPTLON: '-117.5708304',
        ELSDLEA: '',
        UNSDLEA: '12990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-123.3647, 46.0991],
              [-123.3651, 46.0831],
              [-123.3654, 46.0695],
              [-123.5473, 46.0404],
              [-123.569, 46.0982],
              [-123.3647, 46.0991],
            ],
          ],
          [
            [
              [-123.9274, 46.2386],
              [-123.8698, 46.1756],
              [-123.8989, 46.0976],
              [-124.0156, 46.0974],
              [-124.1595, 46.2611],
              [-123.9763, 46.2699],
              [-123.9274, 46.2386],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4113080',
        NAME: 'Warrenton-Hammond School District 30',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154344291,
        AWATER: 200488748,
        INTPTLAT: '+46.1618861',
        INTPTLON: '-123.8753893',
        ELSDLEA: '',
        UNSDLEA: '13080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.8415, 45.303],
            [-122.7439, 45.3596],
            [-122.7285, 45.3834],
            [-122.6374, 45.3985],
            [-122.6313, 45.3946],
            [-122.6178, 45.3757],
            [-122.6078, 45.3723],
            [-122.6648, 45.3096],
            [-122.8331, 45.2764],
            [-122.8415, 45.303],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4113170',
        NAME: 'West Linn School District 3J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107416183,
        AWATER: 4640410,
        INTPTLAT: '+45.3362910',
        INTPTLON: '-122.7083109',
        ELSDLEA: '',
        UNSDLEA: '13170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4631, 45.2745],
            [-123.4221, 45.2745],
            [-123.4426, 45.2137],
            [-123.4057, 45.11],
            [-123.4442, 45.0388],
            [-123.4387, 44.9693],
            [-123.6017, 44.9705],
            [-123.7251, 45.0005],
            [-123.7848, 45.0445],
            [-123.744, 45.0887],
            [-123.7417, 45.2163],
            [-123.5076, 45.216],
            [-123.4631, 45.2745],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4113350',
        NAME: 'Willamina School District 30J',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 672894677,
        AWATER: 223535,
        INTPTLAT: '+45.1139191',
        INTPTLON: '-123.5875097',
        ELSDLEA: '',
        UNSDLEA: '13350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.704, 43.2433],
            [-123.5856, 43.2433],
            [-123.457, 43.1973],
            [-123.3914, 43.1133],
            [-123.426, 42.9796],
            [-123.6428, 42.9414],
            [-123.6425, 43.1121],
            [-123.702, 43.1124],
            [-123.7039, 43.2141],
            [-123.704, 43.2433],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4113490',
        NAME: 'Winston-Dillard School District 116',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 626890888,
        AWATER: 25845,
        INTPTLAT: '+43.0910146',
        INTPTLON: '-123.5560105',
        ELSDLEA: '',
        UNSDLEA: '13490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.9436, 45.1655],
            [-122.8882, 45.1941],
            [-122.7733, 45.161],
            [-122.7823, 45.1247],
            [-122.7958, 45.0934],
            [-122.8309, 45.1005],
            [-122.9436, 45.1655],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4113530',
        NAME: 'Woodburn School District 103',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80684389,
        AWATER: 197914,
        INTPTLAT: '+45.1442410',
        INTPTLON: '-122.8577691',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-123.4682, 43.6479],
            [-123.2504, 43.6335],
            [-123.1378, 43.6771],
            [-123.1075, 43.54],
            [-123.1078, 43.5181],
            [-123.4271, 43.52],
            [-123.4682, 43.6479],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4113650',
        NAME: 'Yoncalla School District 32',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 411730712,
        AWATER: 244130,
        INTPTLAT: '+43.5865635',
        INTPTLON: '-123.2701894',
        ELSDLEA: '',
        UNSDLEA: '13650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-120.3285, 41.9932],
            [-120.414, 41.9935],
            [-120.4131, 42.0466],
            [-120.3236, 42.0456],
            [-120.3285, 41.9932],
          ],
        ],
      },
      properties: {
        STATEFP: '41',
        SCSDLEA: '',
        GEOID: '4199997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 333,
        AWATER: 43747221,
        INTPTLAT: '+41.9933944',
        INTPTLON: '-120.3286912',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
  ],
};

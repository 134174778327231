import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteReportItemModal = ({ open, onRequestToClose, onConfirm }) => {
    const handleConfirmButtonClick = (e) => {
        e.preventDefault();
        onConfirm();
        onRequestToClose();
    };

    return (
        <Modal centered show={open} backdrop="static" onHide={onRequestToClose}>
            <Modal.Header closeButton>
                <Modal.Title as="h2" className="font-weight-normal">
                    Delete report item
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-3 pb-4">Are you sure you want to delete this report item?</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onRequestToClose}>
                    Cancel
                </Button>
                <Button onClick={handleConfirmButtonClick}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteReportItemModal;

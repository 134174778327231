import {
  getDashboardMenuOptionsApi,
  getDashboardVisualizationApi,
  postDashboardApi,
  postDashboardVisualizationsApi,
  getDashboardShareALinkApi,
} from '../api/dashboard';
import { getShareALinkByShareTokenApi } from '../api/surveys';
import DashboardVisualizationDetails from '../helpers/analytics/models/dashboardVisualizationDetails';
import navigationTracker from '../helpers/analytics/navigationTracker';

import types from '../types';

export const getDashboard = (visualization, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.DASHBOARD_PENDING });
    const data = await postDashboardApi(visualization, jwt);
    dispatch({ type: types.reducerTypes.DASHBOARD_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({ type: types.reducerTypes.DASHBOARD_ERROR, payload: 'Unexpected Error!' });
  }
};

export const getDashboardMenuOptions = (jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.DASHBOARD_MENU_OPTIONS_ERROR });
    const data = await getDashboardMenuOptionsApi(jwt);
    dispatch({ type: types.reducerTypes.DASHBOARD_MENU_OPTIONS_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.DASHBOARD_MENU_OPTIONS_ERROR,
      payload: 'Unexpected Error!',
    });
  }
};

export const getDashboardVisualization = (jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.DASHBOARD_VISUALIZATIONS_PENDING });
    const data = await getDashboardVisualizationApi(jwt);
    dispatch({ type: types.reducerTypes.DASHBOARD_VISUALIZATIONS_SUCCESS, payload: data });
    const dashboardDetails = new DashboardVisualizationDetails(data.details);
    navigationTracker.onNavigateWithinDashbaord(dashboardDetails);
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.DASHBOARD_VISUALIZATIONS_ERROR,
      payload: 'Unexpected Error!',
    });
  }
};

export const postDashboardVisualizations = (visualization, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.DASHBOARD_VISUALIZATIONS_PENDING });
    const data = await postDashboardVisualizationsApi(visualization, jwt);
    dispatch({ type: types.reducerTypes.DASHBOARD_VISUALIZATIONS_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.DASHBOARD_VISUALIZATIONS_ERROR,
      payload: 'Unexpected Error!',
    });
  }
};

export const getDashboardShareALink = (idVisualization, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.SHARE_A_LINK_PENDING });
    const data = await getDashboardShareALinkApi(idVisualization, jwt);
    dispatch({ type: types.reducerTypes.SHARE_A_LINK_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.SHARE_A_LINK_ERROR,
      payload: 'Unexpected Error!',
    });
  }
};

export const getDashboardShareALinkByShareToken = (sharedToken, jwt) => async (dispatch) => {
  try {
    dispatch({ type: types.reducerTypes.DASHBOARD_VISUALIZATIONS_PENDING });
    const data = await getShareALinkByShareTokenApi(sharedToken, jwt);
    dispatch({
      type: types.reducerTypes.DASHBOARD_VISUALIZATIONS_SUCCESS,
      payload: data.visualization,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.reducerTypes.DASHBOARD_VISUALIZATIONS_ERROR,
      payload: 'Unexpected Error!',
    });
  }
};

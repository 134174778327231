import React from 'react';
import { useSelector } from 'react-redux';
import SimpleImage from '../../SimpleImage';
import SimpleText from '../../SimpleText';
import './style.css';

const Informative = () => {
  const informative = useSelector((state) => state.wizard.informative);
  const {
    title, imageSrc, imageAlt, description,
  } = informative;
  return (
    <div className="Informative">
      <div className="Informative-content">
        <h3 className="Informative-title">{title}</h3>
        <SimpleImage
          src={imageSrc}
          alt={imageAlt}
          title={imageAlt}
          align="center"
          margin="0 0 35px 0"
        />
        <SimpleText
          max={256}
          padding="0 45px"
          text={[description]}
          align="center"
        />
      </div>
    </div>
  );
};

export default Informative;

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const SimpleImage = ({
  src,
  alt,
  title,
  align,
  margin,
}) => (
  <div className="image-container" style={{ textAlign: align, margin }}>
    <img
      src={src}
      alt={alt}
      title={title}
      align={align}
    />
  </div>
);

SimpleImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  align: PropTypes.string,
  margin: PropTypes.string,
};

SimpleImage.defaultProps = {
  src: '',
  alt: '',
  title: '',
  align: '',
  margin: '0',
};


export default SimpleImage;

export const NC = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.09, 35.0537],
            [-78.9083, 35.1316],
            [-79.0462, 35.2036],
            [-78.7939, 35.2661],
            [-78.6171, 35.2457],
            [-78.6629, 35.0783],
            [-78.6475, 34.9948],
            [-78.4946, 34.8565],
            [-78.843, 34.8474],
            [-78.9011, 34.8348],
            [-78.9562, 34.9138],
            [-79.0347, 34.9536],
            [-79.09, 35.0537],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700011',
        NAME: 'Cumberland County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1513776922,
        AWATER: 14272321,
        INTPTLAT: '+35.0372814',
        INTPTLON: '-078.8050761',
        ELSDLEA: '',
        UNSDLEA: '00011',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7005, 35.6523],
            [-77.6613, 35.6773],
            [-77.3902, 35.8329],
            [-77.3506, 35.8192],
            [-77.174, 35.733],
            [-77.1738, 35.6353],
            [-77.1066, 35.5809],
            [-77.1762, 35.5191],
            [-77.1904, 35.419],
            [-77.391, 35.34],
            [-77.476, 35.4267],
            [-77.5119, 35.4988],
            [-77.7005, 35.6523],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700012',
        NAME: 'Pitt County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1687873422,
        AWATER: 8114836,
        INTPTLAT: '+35.5929650',
        INTPTLON: '-077.3731380',
        ELSDLEA: '',
        UNSDLEA: '00012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4844, 34.6166],
            [-77.4565, 34.7459],
            [-77.332, 34.7319],
            [-77.1927, 34.6174],
            [-77.3326, 34.5304],
            [-77.4844, 34.6166],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700013',
        NAME: 'Camp Lejeune Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 340812973,
        AWATER: 74450053,
        INTPTLAT: '+34.6586228',
        INTPTLON: '-077.3527887',
        ELSDLEA: '',
        UNSDLEA: '00013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0961, 35.1922],
            [-79.0462, 35.2036],
            [-78.9083, 35.1316],
            [-79.09, 35.0537],
            [-79.3369, 35.0465],
            [-79.3804, 35.118],
            [-79.2464, 35.2135],
            [-79.0961, 35.1922],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700014',
        NAME: 'Fort Bragg Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 536954929,
        AWATER: 2374709,
        INTPTLAT: '+35.1227512',
        INTPTLON: '-079.1634175',
        ELSDLEA: '',
        UNSDLEA: '00014',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.542, 35.8996],
            [-79.5324, 36.2413],
            [-79.532, 36.25],
            [-79.257, 36.2439],
            [-79.264, 35.9141],
            [-79.2495, 35.8768],
            [-79.2373, 35.8442],
            [-79.5425, 35.8434],
            [-79.542, 35.8996],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700030',
        NAME: 'Alamance-Burlington Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1098038862,
        AWATER: 27933266,
        INTPTLAT: '+36.0439535',
        INTPTLON: '-079.4005733',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3284, 35.9965],
            [-81.1909, 36.0369],
            [-81.0289, 36.0458],
            [-81.0055, 35.9583],
            [-81.0413, 35.8579],
            [-81.1094, 35.7766],
            [-81.1423, 35.8274],
            [-81.302, 35.801],
            [-81.3343, 35.7965],
            [-81.3284, 35.9965],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700090',
        NAME: 'Alexander County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 673318901,
        AWATER: 9433708,
        INTPTLAT: '+35.9209506',
        INTPTLON: '-081.1774668',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3532, 36.5747],
            [-80.9017, 36.5618],
            [-80.9671, 36.4016],
            [-81.163, 36.4324],
            [-81.2535, 36.3654],
            [-81.2725, 36.4474],
            [-81.3532, 36.5747],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700120',
        NAME: 'Alleghany County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 608802906,
        AWATER: 3853005,
        INTPTLAT: '+36.4893563',
        INTPTLON: '-081.1322988',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2771, 35.1961],
            [-80.1742, 35.148],
            [-80.0754, 35.1431],
            [-79.9993, 35.0854],
            [-79.9279, 35.1177],
            [-79.85, 34.8993],
            [-79.9276, 34.8066],
            [-80.3204, 34.814],
            [-80.2771, 35.1961],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700180',
        NAME: 'Anson County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1376453082,
        AWATER: 14612922,
        INTPTLAT: '+34.9749961',
        INTPTLON: '-080.1099593',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6774, 36.5882],
            [-81.3532, 36.5747],
            [-81.2725, 36.4474],
            [-81.2535, 36.3654],
            [-81.4773, 36.2403],
            [-81.7286, 36.3912],
            [-81.6774, 36.5882],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700210',
        NAME: 'Ashe County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1103726590,
        AWATER: 8074535,
        INTPTLAT: '+36.4434687',
        INTPTLON: '-081.4993343',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8791, 35.7287],
            [-79.7602, 35.7367],
            [-79.7697, 35.6703],
            [-79.8791, 35.7287],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700240',
        NAME: 'Asheboro City Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89536153,
        AWATER: 298992,
        INTPTLAT: '+35.7127445',
        INTPTLON: '-079.8108826',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6229, 35.5699],
            [-82.5575, 35.6555],
            [-82.5292, 35.5723],
            [-82.6229, 35.5699],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700270',
        NAME: 'Asheville City Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56921900,
        AWATER: 710508,
        INTPTLAT: '+35.6129397',
        INTPTLON: '-082.5474947',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0804, 36.103],
            [-82.0331, 36.1204],
            [-81.933, 36.2649],
            [-81.9181, 36.2871],
            [-81.9091, 36.2164],
            [-81.8115, 36.1115],
            [-81.736, 36.0668],
            [-81.8071, 35.9621],
            [-81.8996, 36.0001],
            [-81.9437, 35.9594],
            [-81.9817, 35.9115],
            [-82.0342, 35.9421],
            [-82.0804, 36.103],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700300',
        NAME: 'Avery County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 639940038,
        AWATER: 375485,
        INTPTLAT: '+36.0720900',
        INTPTLON: '-081.9202847',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.174, 35.733],
            [-76.9857, 35.6585],
            [-76.8451, 35.7054],
            [-76.638, 35.7052],
            [-76.5641, 35.6011],
            [-76.5853, 35.4551],
            [-76.5219, 35.3536],
            [-76.6175, 35.3289],
            [-76.6325, 35.2352],
            [-76.8455, 35.216],
            [-76.8955, 35.2535],
            [-77.1904, 35.419],
            [-77.1762, 35.5191],
            [-77.1066, 35.5809],
            [-77.1738, 35.6353],
            [-77.174, 35.733],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700330',
        NAME: 'Beaufort County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2143053946,
        AWATER: 338699480,
        INTPTLAT: '+35.4823128',
        INTPTLON: '-076.8420144',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3277, 36.0745],
            [-77.2443, 36.0965],
            [-77.2918, 36.1685],
            [-77.2088, 36.2469],
            [-76.9713, 36.2382],
            [-76.7066, 36.2439],
            [-76.7363, 36.147],
            [-76.7054, 36.0619],
            [-76.6061, 35.9785],
            [-76.6662, 35.962],
            [-76.7608, 35.8648],
            [-76.8834, 35.8117],
            [-76.9185, 35.8956],
            [-77.0043, 35.8587],
            [-77.0437, 35.9431],
            [-77.1859, 35.931],
            [-77.1975, 35.993],
            [-77.3277, 36.0745],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700360',
        NAME: 'Bertie County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1811097685,
        AWATER: 108727749,
        INTPTLAT: '+36.0590432',
        INTPTLON: '-076.9623641',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9011, 34.8348],
            [-78.843, 34.8474],
            [-78.4946, 34.8565],
            [-78.3255, 34.668],
            [-78.2546, 34.554],
            [-78.1765, 34.4653],
            [-78.2563, 34.3998],
            [-78.3372, 34.3671],
            [-78.4462, 34.3768],
            [-78.6567, 34.4505],
            [-78.8021, 34.462],
            [-78.8715, 34.4827],
            [-78.8051, 34.6744],
            [-78.9011, 34.8348],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700390',
        NAME: 'Bladen County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2264613777,
        AWATER: 33109959,
        INTPTLAT: '+34.5919375',
        INTPTLON: '-078.5394885',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1624, 34.357],
            [-78.0299, 34.3318],
            [-77.9516, 34.2324],
            [-77.9324, 34.0285],
            [-77.9485, 33.9709],
            [-77.897, 33.7868],
            [-77.9641, 33.7529],
            [-78.0666, 33.848],
            [-78.2623, 33.8632],
            [-78.4993, 33.8129],
            [-78.6503, 33.9442],
            [-78.6407, 34.0099],
            [-78.555, 34.0671],
            [-78.5464, 34.1383],
            [-78.4217, 34.1399],
            [-78.3742, 34.205],
            [-78.2545, 34.2163],
            [-78.1624, 34.357],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700420',
        NAME: 'Brunswick County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2199317866,
        AWATER: 520213818,
        INTPTLAT: '+34.0387542',
        INTPTLON: '-078.2277653',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8837, 35.6782],
            [-82.7139, 35.7176],
            [-82.5145, 35.8032],
            [-82.4087, 35.8181],
            [-82.2754, 35.7044],
            [-82.2509, 35.5597],
            [-82.169, 35.5278],
            [-82.2668, 35.4659],
            [-82.3331, 35.5004],
            [-82.4702, 35.4454],
            [-82.6529, 35.4552],
            [-82.7444, 35.4233],
            [-82.798, 35.4721],
            [-82.7663, 35.5683],
            [-82.8837, 35.6782],
          ],
          [
            [-82.6229, 35.5699],
            [-82.5292, 35.5723],
            [-82.5575, 35.6555],
            [-82.6229, 35.5699],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700450',
        NAME: 'Buncombe County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1643862291,
        AWATER: 8258455,
        INTPTLAT: '+35.6093714',
        INTPTLON: '-082.5304259',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9437, 35.9594],
            [-81.8996, 36.0001],
            [-81.8071, 35.9621],
            [-81.7102, 35.8751],
            [-81.556, 35.7773],
            [-81.3641, 35.7679],
            [-81.4199, 35.6929],
            [-81.5344, 35.5688],
            [-81.5378, 35.5645],
            [-81.6923, 35.5798],
            [-81.8242, 35.575],
            [-81.8693, 35.7196],
            [-81.987, 35.8069],
            [-81.9205, 35.8471],
            [-81.9437, 35.9594],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700480',
        NAME: 'Burke County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1313431561,
        AWATER: 20607589,
        INTPTLAT: '+35.7461820',
        INTPTLON: '-081.7061795',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7843, 35.506],
            [-80.7371, 35.5072],
            [-80.6735, 35.5065],
            [-80.6525, 35.4609],
            [-80.5624, 35.5031],
            [-80.2952, 35.5029],
            [-80.5053, 35.1851],
            [-80.5503, 35.2084],
            [-80.6673, 35.269],
            [-80.7843, 35.506],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700530',
        NAME: 'Cabarrus County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 903843167,
        AWATER: 5976564,
        INTPTLAT: '+35.3832518',
        INTPTLON: '-080.5485330',
        ELSDLEA: '',
        UNSDLEA: '00530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8115, 36.1115],
            [-81.5455, 36.1174],
            [-81.4079, 36.0706],
            [-81.3284, 35.9965],
            [-81.3343, 35.7965],
            [-81.3641, 35.7679],
            [-81.556, 35.7773],
            [-81.7102, 35.8751],
            [-81.8071, 35.9621],
            [-81.736, 36.0668],
            [-81.8115, 36.1115],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700580',
        NAME: 'Caldwell County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1221349796,
        AWATER: 7115667,
        INTPTLAT: '+35.9579149',
        INTPTLON: '-081.5300023',
        ELSDLEA: '',
        UNSDLEA: '00580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5416, 36.5505],
            [-76.4915, 36.5506],
            [-76.3132, 36.5506],
            [-76.1586, 36.4263],
            [-76.1141, 36.3575],
            [-75.9696, 36.3063],
            [-75.888, 36.1633],
            [-75.9063, 36.0859],
            [-76.0191, 36.0976],
            [-76.0317, 36.1634],
            [-76.2312, 36.3717],
            [-76.4915, 36.5105],
            [-76.5416, 36.5505],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700600',
        NAME: 'Camden County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 623037914,
        AWATER: 180406784,
        INTPTLAT: '+36.3423438',
        INTPTLON: '-076.1624876',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0903, 34.8028],
            [-76.956, 34.8108],
            [-76.883, 34.8448],
            [-76.6931, 34.8692],
            [-76.6542, 34.9371],
            [-76.7113, 34.982],
            [-76.2775, 35.2163],
            [-76.0658, 35.1252],
            [-76.0069, 35.006],
            [-76.2496, 34.8346],
            [-76.3746, 34.716],
            [-76.492, 34.5465],
            [-76.5837, 34.5526],
            [-76.6147, 34.6183],
            [-76.8189, 34.6433],
            [-77.1172, 34.5844],
            [-77.0994, 34.6697],
            [-77.1658, 34.7849],
            [-77.0903, 34.8028],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700630',
        NAME: 'Carteret County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1311157125,
        AWATER: 2160964137,
        INTPTLAT: '+34.8583130',
        INTPTLON: '-076.5269665',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.511, 36.5408],
            [-79.4702, 36.5408],
            [-79.3427, 36.5414],
            [-79.2186, 36.5416],
            [-79.1379, 36.5417],
            [-79.1537, 36.2421],
            [-79.257, 36.2439],
            [-79.532, 36.25],
            [-79.511, 36.5408],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700660',
        NAME: 'Caswell County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1100481969,
        AWATER: 8622997,
        INTPTLAT: '+36.3942984',
        INTPTLON: '-079.3396090',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5344, 35.5688],
            [-81.4199, 35.6929],
            [-81.3072, 35.7211],
            [-81.302, 35.801],
            [-81.1423, 35.8274],
            [-81.1094, 35.7766],
            [-80.9969, 35.7045],
            [-80.9245, 35.6217],
            [-80.96, 35.5478],
            [-81.5344, 35.5688],
          ],
          [
            [-81.2491, 35.6951],
            [-81.1713, 35.7296],
            [-81.2396, 35.7635],
            [-81.2491, 35.6951],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700690',
        NAME: 'Catawba County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 911276138,
        AWATER: 35430412,
        INTPTLAT: '+35.6532549',
        INTPTLON: '-081.1992954',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9957, 35.9832],
            [-79.0167, 35.8632],
            [-79.1486, 35.867],
            [-79.1405, 35.9749],
            [-78.9957, 35.9832],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700720',
        NAME: 'Chapel Hill-Carrboro Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159811770,
        AWATER: 1523491,
        INTPTLAT: '+35.9217025',
        INTPTLON: '-079.0761614',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5559, 35.5151],
            [-79.5425, 35.8434],
            [-79.2373, 35.8442],
            [-79.2495, 35.8768],
            [-79.1486, 35.867],
            [-79.0167, 35.8632],
            [-78.9062, 35.8679],
            [-78.9951, 35.6102],
            [-78.9133, 35.5831],
            [-78.9698, 35.5214],
            [-79.0518, 35.5936],
            [-79.1393, 35.624],
            [-79.2752, 35.532],
            [-79.35, 35.518],
            [-79.5559, 35.5151],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700750',
        NAME: 'Chatham County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1767036794,
        AWATER: 71417554,
        INTPTLAT: '+35.7049939',
        INTPTLON: '-079.2514542',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2923, 35.2067],
            [-84.107, 35.2514],
            [-84.0291, 35.2922],
            [-83.9588, 35.2169],
            [-83.8306, 35.2559],
            [-83.7025, 35.2484],
            [-83.7381, 35.1559],
            [-83.8571, 35.1371],
            [-84.0054, 34.9872],
            [-84.1294, 34.9875],
            [-84.3219, 34.9884],
            [-84.2923, 35.2067],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700780',
        NAME: 'Cherokee County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1179560304,
        AWATER: 29250735,
        INTPTLAT: '+35.1362316',
        INTPTLON: '-084.0613094',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7066, 36.2439],
            [-76.6966, 36.2962],
            [-76.5597, 36.3519],
            [-76.5899, 36.239],
            [-76.5734, 36.1123],
            [-76.4028, 36.0729],
            [-76.3969, 36.0264],
            [-76.5233, 35.9789],
            [-76.6061, 35.9785],
            [-76.7054, 36.0619],
            [-76.7363, 36.147],
            [-76.7066, 36.2439],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700840',
        NAME: 'Edenton-Chowan Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 446701190,
        AWATER: 157537228,
        INTPTLAT: '+36.1289790',
        INTPTLON: '-076.6027521',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7381, 35.1559],
            [-83.6534, 35.157],
            [-83.4828, 34.9933],
            [-83.5494, 34.9925],
            [-83.9364, 34.9875],
            [-84.0054, 34.9872],
            [-83.8571, 35.1371],
            [-83.7381, 35.1559],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700870',
        NAME: 'Clay County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 556215917,
        AWATER: 15199024,
        INTPTLAT: '+35.0529971',
        INTPTLON: '-083.7522638',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6923, 35.5798],
            [-81.5378, 35.5645],
            [-81.4565, 35.4196],
            [-81.3566, 35.3306],
            [-81.3213, 35.2602],
            [-81.328, 35.1635],
            [-81.3666, 35.1649],
            [-81.7681, 35.1805],
            [-81.7028, 35.3572],
            [-81.6923, 35.5798],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700900',
        NAME: 'Cleveland County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1202562646,
        AWATER: 10201818,
        INTPTLAT: '+35.3346302',
        INTPTLON: '-081.5571145',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4585, 35.0694],
            [-78.3277, 35.0617],
            [-78.2327, 35.0213],
            [-78.2811, 34.9689],
            [-78.3773, 35.0006],
            [-78.4585, 35.0694],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700930',
        NAME: 'Clinton City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189479404,
        AWATER: 621803,
        INTPTLAT: '+35.0132363',
        INTPTLON: '-078.3375422',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8715, 34.4827],
            [-78.8021, 34.462],
            [-78.7685, 34.3562],
            [-78.7876, 34.247],
            [-78.675, 34.2697],
            [-78.6567, 34.4505],
            [-78.4462, 34.3768],
            [-78.3372, 34.3671],
            [-78.2563, 34.3998],
            [-78.1624, 34.357],
            [-78.2545, 34.2163],
            [-78.3742, 34.205],
            [-78.4217, 34.1399],
            [-78.5464, 34.1383],
            [-78.555, 34.0671],
            [-78.6407, 34.0099],
            [-78.6503, 33.9442],
            [-79.0712, 34.2992],
            [-78.9511, 34.449],
            [-78.8715, 34.4827],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3700960',
        NAME: 'Columbus County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2187756945,
        AWATER: 42768589,
        INTPTLAT: '+34.2616017',
        INTPTLON: '-078.6393077',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3132, 36.5506],
            [-76.1224, 36.5506],
            [-75.7975, 36.5509],
            [-75.7091, 36.245],
            [-75.8132, 36.2235],
            [-75.7655, 36.0582],
            [-75.8401, 36.0289],
            [-75.9063, 36.0859],
            [-75.888, 36.1633],
            [-75.9696, 36.3063],
            [-76.1141, 36.3575],
            [-76.1586, 36.4263],
            [-76.3132, 36.5506],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701080',
        NAME: 'Currituck County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 678206439,
        AWATER: 685661625,
        INTPTLAT: '+36.3721739',
        INTPTLON: '-075.9412242',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.8401, 36.0289],
            [-75.7655, 36.0582],
            [-75.8132, 36.2235],
            [-75.7091, 36.245],
            [-75.6701, 36.1561],
            [-75.4376, 35.747],
            [-75.4004, 35.5935],
            [-75.4256, 35.39],
            [-75.4746, 35.202],
            [-75.7366, 35.1406],
            [-75.9012, 35.669],
            [-76.046, 35.6661],
            [-76.0083, 35.8958],
            [-75.9487, 35.9944],
            [-75.8401, 36.0289],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701110',
        NAME: 'Dare County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 992626969,
        AWATER: 3054395166,
        INTPTLAT: '+35.6900677',
        INTPTLON: '-075.7268325',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.3954, 35.9732],
            [-80.2138, 36.0268],
            [-80.0433, 36.0106],
            [-80.0467, 35.9207],
            [-80.0668, 35.5057],
            [-80.1827, 35.5043],
            [-80.2082, 35.5804],
            [-80.3266, 35.6755],
            [-80.3395, 35.7214],
            [-80.4589, 35.7428],
            [-80.4717, 35.8251],
            [-80.3657, 35.8958],
            [-80.3954, 35.9732],
          ],
          [
            [-80.301, 35.8064],
            [-80.2352, 35.7867],
            [-80.223, 35.8426],
            [-80.2874, 35.847],
            [-80.301, 35.8064],
          ],
          [
            [-80.1061, 35.8777],
            [-80.0636, 35.8601],
            [-80.0642, 35.909],
            [-80.1061, 35.8777],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701140',
        NAME: 'Davidson County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1364762141,
        AWATER: 37142820,
        INTPTLAT: '+35.7951312',
        INTPTLON: '-080.2071070',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6932, 36.0511],
            [-80.4964, 36.0465],
            [-80.3954, 35.9732],
            [-80.3657, 35.8958],
            [-80.4717, 35.8251],
            [-80.4589, 35.7428],
            [-80.6318, 35.8458],
            [-80.7078, 35.8529],
            [-80.6932, 36.0511],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701170',
        NAME: 'Davie County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 683500924,
        AWATER: 7616445,
        INTPTLAT: '+35.9293554',
        INTPTLON: '-080.5425524',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1635, 35.1894],
            [-78.0436, 35.1928],
            [-77.8843, 35.163],
            [-77.8342, 35.1778],
            [-77.7691, 35.1455],
            [-77.7308, 35.0083],
            [-77.6754, 34.9734],
            [-77.651, 34.9237],
            [-77.6806, 34.7206],
            [-78.1132, 34.722],
            [-78.2005, 34.7373],
            [-78.1471, 34.9024],
            [-78.1635, 35.1894],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701200',
        NAME: 'Duplin County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2113896314,
        AWATER: 14230328,
        INTPTLAT: '+34.9344033',
        INTPTLON: '-077.9335434',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0167, 35.8632],
            [-78.9957, 35.9832],
            [-78.9508, 36.2392],
            [-78.802, 36.2359],
            [-78.805, 36.0919],
            [-78.749, 36.0712],
            [-78.699, 36.0091],
            [-78.7592, 35.9182],
            [-78.9062, 35.8679],
            [-79.0167, 35.8632],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701260',
        NAME: 'Durham Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 740762056,
        AWATER: 30708529,
        INTPTLAT: '+36.0338223',
        INTPTLON: '-078.8781198',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8284, 35.8672],
            [-77.6979, 36.153],
            [-77.5383, 36.094],
            [-77.5175, 36.0369],
            [-77.4027, 36.0049],
            [-77.3425, 35.9053],
            [-77.3506, 35.8192],
            [-77.3902, 35.8329],
            [-77.6613, 35.6773],
            [-77.72, 35.7302],
            [-77.7516, 35.8279],
            [-77.8284, 35.8672],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701320',
        NAME: 'Edgecombe County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1297689778,
        AWATER: 3616270,
        INTPTLAT: '+35.9170554',
        INTPTLON: '-077.6026551',
        ELSDLEA: '',
        UNSDLEA: '01320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8689, 36.3265],
            [-80.7696, 36.2749],
            [-80.8733, 36.2364],
            [-80.8689, 36.3265],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701380',
        NAME: 'Elkin City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67627994,
        AWATER: 853633,
        INTPTLAT: '+36.2861667',
        INTPTLON: '-080.8337331',
        ELSDLEA: '',
        UNSDLEA: '01380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.4523, 36.2412],
            [-80.452, 36.2616],
            [-80.0355, 36.2574],
            [-80.0433, 36.0106],
            [-80.2138, 36.0268],
            [-80.3954, 35.9732],
            [-80.4964, 36.0465],
            [-80.4396, 36.1366],
            [-80.4523, 36.2412],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701500',
        NAME: 'Forsyth County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1057167073,
        AWATER: 11709671,
        INTPTLAT: '+36.1324661',
        INTPTLON: '-080.2569608',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4965, 36.1752],
            [-78.412, 36.1697],
            [-78.307, 36.2662],
            [-78.1329, 36.2451],
            [-78.0066, 36.2027],
            [-78.2549, 35.8177],
            [-78.3074, 35.8966],
            [-78.4266, 35.9749],
            [-78.5465, 36.0218],
            [-78.4965, 36.1752],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701530',
        NAME: 'Franklin County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1273423252,
        AWATER: 7322093,
        INTPTLAT: '+36.0882406',
        INTPTLON: '-078.2830903',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9554, 35.4008],
            [-81.0151, 35.268],
            [-81.0332, 35.1487],
            [-81.328, 35.1635],
            [-81.3213, 35.2602],
            [-81.3566, 35.3306],
            [-81.4565, 35.4196],
            [-80.9554, 35.4008],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701620',
        NAME: 'Gaston County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 922062221,
        AWATER: 20957604,
        INTPTLAT: '+35.2933443',
        INTPTLON: '-081.1772556',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.916, 36.5438],
            [-76.9159, 36.5521],
            [-76.5416, 36.5505],
            [-76.4915, 36.5105],
            [-76.4537, 36.378],
            [-76.5597, 36.3519],
            [-76.6966, 36.2962],
            [-76.7622, 36.3527],
            [-76.9318, 36.4011],
            [-76.916, 36.5438],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701680',
        NAME: 'Gates County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 881747778,
        AWATER: 13578051,
        INTPTLAT: '+36.4421353',
        INTPTLON: '-076.7023552',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9611, 35.4637],
            [-83.9527, 35.4607],
            [-83.5855, 35.4349],
            [-83.68, 35.2794],
            [-83.7025, 35.2484],
            [-83.8306, 35.2559],
            [-83.9588, 35.2169],
            [-84.0291, 35.2922],
            [-84.0147, 35.412],
            [-83.9611, 35.4637],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701770',
        NAME: 'Graham County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 756452129,
        AWATER: 24827675,
        INTPTLAT: '+35.3483644',
        INTPTLON: '-083.8309147',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.802, 36.2359],
            [-78.7963, 36.5417],
            [-78.7341, 36.5419],
            [-78.457, 36.5425],
            [-78.5113, 36.4543],
            [-78.4965, 36.1752],
            [-78.5465, 36.0218],
            [-78.6833, 36.0745],
            [-78.749, 36.0712],
            [-78.805, 36.0919],
            [-78.802, 36.2359],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701800',
        NAME: 'Granville County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1374998243,
        AWATER: 14526326,
        INTPTLAT: '+36.2998840',
        INTPTLON: '-078.6576338',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8235, 35.5855],
            [-77.7005, 35.6523],
            [-77.5119, 35.4988],
            [-77.476, 35.4267],
            [-77.6805, 35.3478],
            [-77.8056, 35.3698],
            [-77.8235, 35.5855],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701830',
        NAME: 'Greene County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 688750822,
        AWATER: 1220439,
        INTPTLAT: '+35.4819590',
        INTPTLON: '-077.6816876',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0433, 36.0106],
            [-80.0355, 36.2574],
            [-79.5324, 36.2413],
            [-79.542, 35.8996],
            [-80.0467, 35.9207],
            [-80.0433, 36.0106],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701920',
        NAME: 'Guilford County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1672507324,
        AWATER: 30732171,
        INTPTLAT: '+36.0790650',
        INTPTLON: '-079.7886652',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8979, 36.5068],
            [-77.6726, 36.4795],
            [-77.64, 36.4347],
            [-77.663, 36.3549],
            [-77.5855, 36.2097],
            [-77.5001, 36.3078],
            [-77.4417, 36.3241],
            [-77.3867, 36.2137],
            [-77.2918, 36.1685],
            [-77.2443, 36.0965],
            [-77.3277, 36.0745],
            [-77.4027, 36.0049],
            [-77.5175, 36.0369],
            [-77.5383, 36.094],
            [-77.6979, 36.153],
            [-77.8871, 36.1438],
            [-78.0066, 36.2027],
            [-77.912, 36.3823],
            [-77.8979, 36.5068],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3701950',
        NAME: 'Halifax County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1635181842,
        AWATER: 17962149,
        INTPTLAT: '+36.2414527',
        INTPTLON: '-077.6541049',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1833, 35.307],
            [-78.9698, 35.5214],
            [-78.9133, 35.5831],
            [-78.7086, 35.5191],
            [-78.6606, 35.4962],
            [-78.5413, 35.3151],
            [-78.6171, 35.2457],
            [-78.7939, 35.2661],
            [-79.0462, 35.2036],
            [-79.0961, 35.1922],
            [-79.2231, 35.2681],
            [-79.1833, 35.307],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702010',
        NAME: 'Harnett County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1540982455,
        AWATER: 16387527,
        INTPTLAT: '+35.3686348',
        INTPTLON: '-078.8716104',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2555, 35.715],
            [-83.0787, 35.7895],
            [-82.9616, 35.7911],
            [-82.8837, 35.6782],
            [-82.7663, 35.5683],
            [-82.798, 35.4721],
            [-82.7444, 35.4233],
            [-82.7452, 35.4229],
            [-82.832, 35.3184],
            [-82.9208, 35.2919],
            [-82.9968, 35.3728],
            [-83.1579, 35.4963],
            [-83.1608, 35.5004],
            [-83.1791, 35.5105],
            [-83.1801, 35.5188],
            [-83.1819, 35.6711],
            [-83.2542, 35.6958],
            [-83.2555, 35.715],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702040',
        NAME: 'Haywood County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1433683072,
        AWATER: 2414518,
        INTPTLAT: '+35.5570986',
        INTPTLON: '-082.9728229',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7452, 35.4229],
            [-82.7444, 35.4233],
            [-82.6529, 35.4552],
            [-82.4702, 35.4454],
            [-82.3331, 35.5004],
            [-82.2668, 35.4659],
            [-82.2615, 35.393],
            [-82.3466, 35.2829],
            [-82.3536, 35.1908],
            [-82.5749, 35.1455],
            [-82.6278, 35.3118],
            [-82.7452, 35.4229],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702100',
        NAME: 'Henderson County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 966284831,
        AWATER: 5559438,
        INTPTLAT: '+35.3364146',
        INTPTLON: '-082.4797406',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2088, 36.2469],
            [-77.1228, 36.4712],
            [-77.1645, 36.5463],
            [-76.916, 36.5438],
            [-76.9318, 36.4011],
            [-76.7622, 36.3527],
            [-76.6966, 36.2962],
            [-76.7066, 36.2439],
            [-76.9713, 36.2382],
            [-77.2088, 36.2469],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702160',
        NAME: 'Hertford County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 914421714,
        AWATER: 18891657,
        INTPTLAT: '+36.3635174',
        INTPTLON: '-076.9816160',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3641, 35.7679],
            [-81.3343, 35.7965],
            [-81.302, 35.801],
            [-81.3072, 35.7211],
            [-81.4199, 35.6929],
            [-81.3641, 35.7679],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702190',
        NAME: 'Hickory City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67598470,
        AWATER: 2431960,
        INTPTLAT: '+35.7351918',
        INTPTLON: '-081.3491443',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.3804, 35.118],
            [-79.3369, 35.0465],
            [-79.09, 35.0537],
            [-79.0347, 34.9536],
            [-79.1914, 34.8334],
            [-79.3491, 34.8385],
            [-79.3614, 34.9581],
            [-79.4592, 35.0431],
            [-79.3804, 35.118],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702250',
        NAME: 'Hoke County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 651191211,
        AWATER: 2635133,
        INTPTLAT: '+34.9615414',
        INTPTLON: '-079.2439344',
        ELSDLEA: '',
        UNSDLEA: '02250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.638, 35.7052],
            [-76.3953, 35.6975],
            [-76.2693, 35.6907],
            [-76.2978, 35.6091],
            [-76.2143, 35.598],
            [-76.1721, 35.6972],
            [-76.046, 35.6661],
            [-75.9012, 35.669],
            [-75.7366, 35.1406],
            [-75.9086, 35.0715],
            [-76.0069, 35.006],
            [-76.0658, 35.1252],
            [-76.2775, 35.2163],
            [-76.4422, 35.3272],
            [-76.5219, 35.3536],
            [-76.5853, 35.4551],
            [-76.5641, 35.6011],
            [-76.638, 35.7052],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702280',
        NAME: 'Hyde County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1586697529,
        AWATER: 2101506396,
        INTPTLAT: '+35.4081567',
        INTPTLON: '-076.1536867',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0289, 36.0458],
            [-80.8822, 36.0557],
            [-80.6932, 36.0511],
            [-80.7078, 35.8529],
            [-80.7651, 35.6459],
            [-80.8612, 35.5941],
            [-80.7843, 35.506],
            [-80.9472, 35.4885],
            [-80.96, 35.5478],
            [-80.9245, 35.6217],
            [-80.9969, 35.7045],
            [-81.1094, 35.7766],
            [-81.0413, 35.8579],
            [-81.0055, 35.9583],
            [-81.0289, 36.0458],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702310',
        NAME: 'Iredell-Statesville Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1394075492,
        AWATER: 59708690,
        INTPTLAT: '+35.8222687',
        INTPTLON: '-080.8796869',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.1911, 35.5166],
              [-83.1791, 35.5105],
              [-83.1608, 35.5004],
              [-83.2311, 35.5236],
              [-83.1911, 35.5166],
            ],
          ],
          [
            [
              [-83.3569, 35.4478],
              [-83.2266, 35.4434],
              [-83.1579, 35.4963],
              [-82.9968, 35.3728],
              [-82.9208, 35.2919],
              [-83.0086, 35.0275],
              [-83.1086, 35.0007],
              [-83.2817, 35.2289],
              [-83.3397, 35.331],
              [-83.3569, 35.4478],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702340',
        NAME: 'Jackson County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1192188260,
        AWATER: 9779083,
        INTPTLAT: '+35.2767839',
        INTPTLON: '-083.1308683',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.7086, 35.5191],
            [-78.46, 35.7057],
            [-78.2549, 35.8177],
            [-78.1921, 35.7304],
            [-78.1256, 35.6031],
            [-78.0646, 35.5854],
            [-78.1549, 35.4176],
            [-78.1587, 35.3476],
            [-78.3047, 35.2861],
            [-78.4914, 35.2634],
            [-78.5413, 35.3151],
            [-78.6606, 35.4962],
            [-78.7086, 35.5191],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702370',
        NAME: 'Johnston County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2048950607,
        AWATER: 11395639,
        INTPTLAT: '+35.5134002',
        INTPTLON: '-078.3673108',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7308, 35.0083],
            [-77.6018, 35.0712],
            [-77.4737, 35.229],
            [-77.1485, 35.0572],
            [-77.1028, 35.0213],
            [-77.1115, 34.8313],
            [-77.0903, 34.8028],
            [-77.1658, 34.7849],
            [-77.2221, 34.8822],
            [-77.3284, 34.9383],
            [-77.3674, 34.9066],
            [-77.5027, 34.9696],
            [-77.6754, 34.9734],
            [-77.7308, 35.0083],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702400',
        NAME: 'Jones County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1219129318,
        AWATER: 6493541,
        INTPTLAT: '+35.0322677',
        INTPTLON: '-077.3561946',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5624, 35.5031],
            [-80.6525, 35.4609],
            [-80.6735, 35.5065],
            [-80.5624, 35.5031],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702430',
        NAME: 'Kannapolis City Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47138251,
        AWATER: 1182213,
        INTPTLAT: '+35.4952818',
        INTPTLON: '-080.6161891',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.35, 35.518],
            [-79.2752, 35.532],
            [-79.1393, 35.624],
            [-79.0518, 35.5936],
            [-78.9698, 35.5214],
            [-79.1833, 35.307],
            [-79.2657, 35.3453],
            [-79.3549, 35.4652],
            [-79.35, 35.518],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702560',
        NAME: 'Lee County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 660335366,
        AWATER: 10582970,
        INTPTLAT: '+35.4763365',
        INTPTLON: '-079.1721174',
        ELSDLEA: '',
        UNSDLEA: '02560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8056, 35.3698],
            [-77.6805, 35.3478],
            [-77.476, 35.4267],
            [-77.391, 35.34],
            [-77.4737, 35.229],
            [-77.6018, 35.0712],
            [-77.7308, 35.0083],
            [-77.7691, 35.1455],
            [-77.8342, 35.1778],
            [-77.8056, 35.3698],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702610',
        NAME: 'Lenoir County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1037491246,
        AWATER: 5802376,
        INTPTLAT: '+35.2400677',
        INTPTLON: '-077.6355095',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.301, 35.8064],
            [-80.2874, 35.847],
            [-80.223, 35.8426],
            [-80.2352, 35.7867],
            [-80.301, 35.8064],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702640',
        NAME: 'Lexington City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48354026,
        AWATER: 0,
        INTPTLAT: '+35.8163091',
        INTPTLON: '-080.2630790',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5344, 35.5688],
            [-80.96, 35.5478],
            [-80.9472, 35.4885],
            [-80.9554, 35.4008],
            [-81.4565, 35.4196],
            [-81.5378, 35.5645],
            [-81.5344, 35.5688],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702680',
        NAME: 'Lincoln County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 771709959,
        AWATER: 23522687,
        INTPTLAT: '+35.4885020',
        INTPTLON: '-081.2269746',
        ELSDLEA: '',
        UNSDLEA: '02680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7025, 35.2484],
            [-83.68, 35.2794],
            [-83.4557, 35.303],
            [-83.3397, 35.331],
            [-83.2817, 35.2289],
            [-83.1086, 35.0007],
            [-83.4828, 34.9933],
            [-83.6534, 35.157],
            [-83.7381, 35.1559],
            [-83.7025, 35.2484],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702760',
        NAME: 'Macon County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1335302153,
        AWATER: 10722085,
        INTPTLAT: '+35.1529593',
        INTPTLON: '-083.4219010',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8985, 35.9451],
            [-82.7875, 35.9522],
            [-82.7759, 36.0008],
            [-82.6364, 36.0659],
            [-82.6029, 36.0398],
            [-82.6109, 35.9674],
            [-82.5054, 35.9777],
            [-82.4087, 35.8181],
            [-82.5145, 35.8032],
            [-82.7139, 35.7176],
            [-82.8837, 35.6782],
            [-82.9616, 35.7911],
            [-82.8966, 35.8785],
            [-82.8985, 35.9451],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702820',
        NAME: 'Madison County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1164217335,
        AWATER: 4836812,
        INTPTLAT: '+35.8640782',
        INTPTLON: '-082.7127314',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4027, 36.0049],
            [-77.3277, 36.0745],
            [-77.1975, 35.993],
            [-77.1859, 35.931],
            [-77.0437, 35.9431],
            [-77.0043, 35.8587],
            [-76.9185, 35.8956],
            [-76.8834, 35.8117],
            [-76.7608, 35.8648],
            [-76.8451, 35.7054],
            [-76.9857, 35.6585],
            [-77.174, 35.733],
            [-77.3506, 35.8192],
            [-77.3425, 35.9053],
            [-77.4027, 36.0049],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702880',
        NAME: 'Martin County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1194550416,
        AWATER: 751154,
        INTPTLAT: '+35.8410585',
        INTPTLON: '-077.1128666',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2754, 35.7044],
            [-82.1939, 35.7349],
            [-82.1332, 35.8244],
            [-81.9817, 35.9115],
            [-81.9437, 35.9594],
            [-81.9205, 35.8471],
            [-81.987, 35.8069],
            [-81.8693, 35.7196],
            [-81.8242, 35.575],
            [-81.9736, 35.5246],
            [-82.169, 35.5278],
            [-82.2509, 35.5597],
            [-82.2754, 35.7044],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702940',
        NAME: 'McDowell County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1141169556,
        AWATER: 13956785,
        INTPTLAT: '+35.6822317',
        INTPTLON: '-082.0480292',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0332, 35.1487],
            [-81.0151, 35.268],
            [-80.9554, 35.4008],
            [-80.9472, 35.4885],
            [-80.7843, 35.506],
            [-80.6673, 35.269],
            [-80.5503, 35.2084],
            [-80.7704, 35.0342],
            [-80.8403, 35.0017],
            [-80.9064, 35.0767],
            [-81.0415, 35.0447],
            [-81.0332, 35.1487],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3702970',
        NAME: 'Charlotte-Mecklenburg Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1355622646,
        AWATER: 58038574,
        INTPTLAT: '+35.2468623',
        INTPTLON: '-080.8338317',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2221, 36.1569],
            [-82.0804, 36.103],
            [-82.0342, 35.9421],
            [-81.9817, 35.9115],
            [-82.1332, 35.8244],
            [-82.1505, 35.9243],
            [-82.2141, 36.0169],
            [-82.3352, 36.029],
            [-82.4167, 36.0728],
            [-82.2221, 36.1569],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703000',
        NAME: 'Mitchell County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 573522064,
        AWATER: 1679903,
        INTPTLAT: '+36.0131020',
        INTPTLON: '-082.1635541',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1827, 35.5043],
            [-80.0668, 35.5057],
            [-79.7693, 35.5115],
            [-79.698, 35.2515],
            [-79.6143, 35.1637],
            [-79.8518, 35.1834],
            [-80.0754, 35.1431],
            [-80.0982, 35.2552],
            [-80.0517, 35.3726],
            [-80.1085, 35.4595],
            [-80.1827, 35.5043],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703060',
        NAME: 'Montgomery County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1273731471,
        AWATER: 25909694,
        INTPTLAT: '+35.3344151',
        INTPTLON: '-079.9066023',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.7693, 35.5115],
            [-79.5559, 35.5151],
            [-79.35, 35.518],
            [-79.3549, 35.4652],
            [-79.2657, 35.3453],
            [-79.1833, 35.307],
            [-79.2231, 35.2681],
            [-79.0961, 35.1922],
            [-79.2464, 35.2135],
            [-79.3804, 35.118],
            [-79.4592, 35.0431],
            [-79.5704, 35.0659],
            [-79.6143, 35.1637],
            [-79.698, 35.2515],
            [-79.7693, 35.5115],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703090',
        NAME: 'Moore County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1807460825,
        AWATER: 20689517,
        INTPTLAT: '+35.3082717',
        INTPTLON: '-079.4927248',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7651, 35.6459],
            [-80.7371, 35.5072],
            [-80.7843, 35.506],
            [-80.8612, 35.5941],
            [-80.7651, 35.6459],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703120',
        NAME: 'Mooresville City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93130603,
        AWATER: 244343,
        INTPTLAT: '+35.5799723',
        INTPTLON: '-080.7919413',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6329, 36.5586],
            [-80.612, 36.5578],
            [-80.571, 36.5562],
            [-80.6329, 36.5586],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703210',
        NAME: 'Mount Airy City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43914064,
        AWATER: 434072,
        INTPTLAT: '+36.5100965',
        INTPTLON: '-080.5965064',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2549, 35.8177],
            [-78.0066, 36.2027],
            [-77.8871, 36.1438],
            [-77.6979, 36.153],
            [-77.8284, 35.8672],
            [-78.1921, 35.7304],
            [-78.2549, 35.8177],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703270',
        NAME: 'Nash-Rocky Mount Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1410545291,
        AWATER: 6132521,
        INTPTLAT: '+35.9659447',
        INTPTLON: '-077.9875546',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.391, 35.34],
            [-77.1904, 35.419],
            [-76.8955, 35.2535],
            [-76.9619, 35.2007],
            [-76.9363, 35.1076],
            [-76.9673, 35.0407],
            [-76.8933, 34.9803],
            [-76.7806, 34.9504],
            [-76.7113, 34.982],
            [-76.6542, 34.9371],
            [-76.6931, 34.8692],
            [-76.883, 34.8448],
            [-76.956, 34.8108],
            [-77.0903, 34.8028],
            [-77.1115, 34.8313],
            [-77.1028, 35.0213],
            [-77.1485, 35.0572],
            [-77.4737, 35.229],
            [-77.391, 35.34],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703310',
        NAME: 'Craven County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1836193050,
        AWATER: 168875961,
        INTPTLAT: '+35.1181789',
        INTPTLON: '-077.0825413',
        ELSDLEA: '',
        UNSDLEA: '03310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0299, 34.3318],
            [-77.9617, 34.3734],
            [-77.812, 34.3587],
            [-77.6753, 34.2531],
            [-77.7856, 34.1252],
            [-77.899, 33.8546],
            [-77.897, 33.7868],
            [-77.9485, 33.9709],
            [-77.9324, 34.0285],
            [-77.9516, 34.2324],
            [-78.0299, 34.3318],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703330',
        NAME: 'New Hanover County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496660826,
        AWATER: 353919000,
        INTPTLAT: '+34.1778748',
        INTPTLON: '-077.8696735',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2491, 35.6951],
            [-81.2396, 35.7635],
            [-81.1713, 35.7296],
            [-81.2491, 35.6951],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703360',
        NAME: 'Newton-Conover City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53813468,
        AWATER: 190401,
        INTPTLAT: '+35.7075739',
        INTPTLON: '-081.2145632',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8998, 36.5447],
            [-77.7671, 36.5454],
            [-77.2969, 36.5448],
            [-77.1645, 36.5463],
            [-77.1228, 36.4712],
            [-77.2088, 36.2469],
            [-77.2918, 36.1685],
            [-77.3867, 36.2137],
            [-77.4417, 36.3241],
            [-77.5001, 36.3078],
            [-77.586, 36.337],
            [-77.5571, 36.4186],
            [-77.6283, 36.4485],
            [-77.6726, 36.4795],
            [-77.8979, 36.5068],
            [-77.8998, 36.5447],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703420',
        NAME: 'Northampton County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1389757167,
        AWATER: 36301279,
        INTPTLAT: '+36.4217739',
        INTPTLON: '-077.3983518',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6754, 34.9734],
            [-77.5027, 34.9696],
            [-77.3674, 34.9066],
            [-77.3284, 34.9383],
            [-77.2221, 34.8822],
            [-77.1658, 34.7849],
            [-77.0994, 34.6697],
            [-77.1172, 34.5844],
            [-77.4845, 34.3997],
            [-77.5764, 34.4787],
            [-77.6806, 34.7206],
            [-77.651, 34.9237],
            [-77.6754, 34.9734],
          ],
          [
            [-77.4844, 34.6166],
            [-77.3326, 34.5304],
            [-77.1927, 34.6174],
            [-77.332, 34.7319],
            [-77.4565, 34.7459],
            [-77.4844, 34.6166],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703450',
        NAME: 'Onslow County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1634767977,
        AWATER: 296272499,
        INTPTLAT: '+34.8402535',
        INTPTLON: '-077.5359781',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.257, 36.2439],
            [-79.1537, 36.2421],
            [-78.9508, 36.2392],
            [-78.9957, 35.9832],
            [-79.1405, 35.9749],
            [-79.1486, 35.867],
            [-79.2495, 35.8768],
            [-79.264, 35.9141],
            [-79.257, 36.2439],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703480',
        NAME: 'Orange County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 870965156,
        AWATER: 7446667,
        INTPTLAT: '+36.0890593',
        INTPTLON: '-079.1255583',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8955, 35.2535],
            [-76.8455, 35.216],
            [-76.6325, 35.2352],
            [-76.6175, 35.3289],
            [-76.5219, 35.3536],
            [-76.4422, 35.3272],
            [-76.2775, 35.2163],
            [-76.7113, 34.982],
            [-76.7806, 34.9504],
            [-76.8933, 34.9803],
            [-76.9673, 35.0407],
            [-76.9363, 35.1076],
            [-76.9619, 35.2007],
            [-76.8955, 35.2535],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703510',
        NAME: 'Pamlico County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 871740860,
        AWATER: 596135141,
        INTPTLAT: '+35.1475747',
        INTPTLON: '-076.6652684',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4915, 36.5105],
            [-76.2312, 36.3717],
            [-76.0317, 36.1634],
            [-76.0191, 36.0976],
            [-76.158, 36.0595],
            [-76.318, 36.2405],
            [-76.3778, 36.2697],
            [-76.4537, 36.378],
            [-76.4915, 36.5105],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703540',
        NAME: 'Pasquotank County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 587633387,
        AWATER: 162010040,
        INTPTLAT: '+36.2651840',
        INTPTLON: '-076.2606959',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2546, 34.554],
            [-78.1132, 34.722],
            [-77.6806, 34.7206],
            [-77.5764, 34.4787],
            [-77.4845, 34.3997],
            [-77.6753, 34.2531],
            [-77.812, 34.3587],
            [-77.9617, 34.3734],
            [-78.0299, 34.3318],
            [-78.1624, 34.357],
            [-78.2563, 34.3998],
            [-78.1765, 34.4653],
            [-78.2546, 34.554],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703570',
        NAME: 'Pender County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2252361372,
        AWATER: 162975112,
        INTPTLAT: '+34.5125561',
        INTPTLON: '-077.8881063',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5597, 36.3519],
            [-76.4537, 36.378],
            [-76.3778, 36.2697],
            [-76.318, 36.2405],
            [-76.158, 36.0595],
            [-76.3584, 36.02],
            [-76.3969, 36.0264],
            [-76.4028, 36.0729],
            [-76.5734, 36.1123],
            [-76.5899, 36.239],
            [-76.5597, 36.3519],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703600',
        NAME: 'Perquimans County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 640243032,
        AWATER: 211775431,
        INTPTLAT: '+36.1808969',
        INTPTLON: '-076.4032445',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1379, 36.5417],
            [-78.7963, 36.5417],
            [-78.802, 36.2359],
            [-78.9508, 36.2392],
            [-79.1537, 36.2421],
            [-79.1379, 36.5417],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703630',
        NAME: 'Person County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1015343286,
        AWATER: 31216352,
        INTPTLAT: '+36.3863362',
        INTPTLON: '-078.9656441',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2615, 35.393],
            [-82.1222, 35.3925],
            [-81.9961, 35.271],
            [-81.9693, 35.1874],
            [-82.0177, 35.1889],
            [-82.2695, 35.1996],
            [-82.3536, 35.1908],
            [-82.3466, 35.2829],
            [-82.2615, 35.393],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703720',
        NAME: 'Polk County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 615839220,
        AWATER: 1964035,
        INTPTLAT: '+35.2779011',
        INTPTLON: '-082.1676211',
        ELSDLEA: '',
        UNSDLEA: '03720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0467, 35.9207],
            [-79.542, 35.8996],
            [-79.5425, 35.8434],
            [-79.5559, 35.5151],
            [-79.7693, 35.5115],
            [-80.0668, 35.5057],
            [-80.0467, 35.9207],
          ],
          [
            [-79.8791, 35.7287],
            [-79.7697, 35.6703],
            [-79.7602, 35.7367],
            [-79.8791, 35.7287],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703780',
        NAME: 'Randolph County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1935047342,
        AWATER: 19524325,
        INTPTLAT: '+35.6035574',
        INTPTLON: '-079.9496632',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0754, 35.1431],
            [-79.8518, 35.1834],
            [-79.6143, 35.1637],
            [-79.5704, 35.0659],
            [-79.4592, 35.0431],
            [-79.5727, 34.992],
            [-79.5703, 34.9031],
            [-79.6929, 34.805],
            [-79.9276, 34.8066],
            [-79.85, 34.8993],
            [-79.9279, 35.1177],
            [-79.9993, 35.0854],
            [-80.0754, 35.1431],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703870',
        NAME: 'Richmond County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1227298020,
        AWATER: 15684085,
        INTPTLAT: '+35.0046360',
        INTPTLON: '-079.7556946',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6726, 36.4795],
            [-77.6283, 36.4485],
            [-77.64, 36.4347],
            [-77.6726, 36.4795],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703900',
        NAME: 'Roanoke Rapids City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17781528,
        AWATER: 92162,
        INTPTLAT: '+36.4587898',
        INTPTLON: '-077.6564659',
        ELSDLEA: '',
        UNSDLEA: '03900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4618, 34.6304],
            [-79.331, 34.7736],
            [-79.3491, 34.8385],
            [-79.1914, 34.8334],
            [-79.0347, 34.9536],
            [-78.9562, 34.9138],
            [-78.9011, 34.8348],
            [-78.8051, 34.6744],
            [-78.8715, 34.4827],
            [-78.9511, 34.449],
            [-79.0712, 34.2992],
            [-79.45, 34.621],
            [-79.4618, 34.6304],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703930',
        NAME: 'Robeson County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2457924449,
        AWATER: 5110085,
        INTPTLAT: '+34.6392096',
        INTPTLON: '-079.1008811',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0273, 36.5425],
            [-79.7149, 36.5419],
            [-79.511, 36.5408],
            [-79.532, 36.25],
            [-79.5324, 36.2413],
            [-80.0355, 36.2574],
            [-80.0273, 36.5425],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3703990',
        NAME: 'Rockingham County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1464773685,
        AWATER: 18526383,
        INTPTLAT: '+36.3818062',
        INTPTLON: '-079.7827539',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7078, 35.8529],
            [-80.6318, 35.8458],
            [-80.4589, 35.7428],
            [-80.3395, 35.7214],
            [-80.3266, 35.6755],
            [-80.2082, 35.5804],
            [-80.1827, 35.5043],
            [-80.2952, 35.5029],
            [-80.5624, 35.5031],
            [-80.6735, 35.5065],
            [-80.7371, 35.5072],
            [-80.7651, 35.6459],
            [-80.7078, 35.8529],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704050',
        NAME: 'Rowan-Salisbury Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1310097348,
        AWATER: 31776163,
        INTPTLAT: '+35.6413294',
        INTPTLON: '-080.5217216',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2668, 35.4659],
            [-82.169, 35.5278],
            [-81.9736, 35.5246],
            [-81.8242, 35.575],
            [-81.6923, 35.5798],
            [-81.7028, 35.3572],
            [-81.7681, 35.1805],
            [-81.7751, 35.1807],
            [-81.9693, 35.1874],
            [-81.9961, 35.271],
            [-82.1222, 35.3925],
            [-82.2615, 35.393],
            [-82.2668, 35.4659],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704080',
        NAME: 'Rutherford County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1460866914,
        AWATER: 4676879,
        INTPTLAT: '+35.4027469',
        INTPTLON: '-081.9195825',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6171, 35.2457],
            [-78.5413, 35.3151],
            [-78.4914, 35.2634],
            [-78.3047, 35.2861],
            [-78.1635, 35.1894],
            [-78.1471, 34.9024],
            [-78.2005, 34.7373],
            [-78.1132, 34.722],
            [-78.2546, 34.554],
            [-78.3255, 34.668],
            [-78.4946, 34.8565],
            [-78.6475, 34.9948],
            [-78.6629, 35.0783],
            [-78.6171, 35.2457],
          ],
          [
            [-78.4585, 35.0694],
            [-78.3773, 35.0006],
            [-78.2811, 34.9689],
            [-78.2327, 35.0213],
            [-78.3277, 35.0617],
            [-78.4585, 35.0694],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704140',
        NAME: 'Sampson County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2257336201,
        AWATER: 4349093,
        INTPTLAT: '+34.9399448',
        INTPTLON: '-078.3922143',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4592, 35.0431],
            [-79.3614, 34.9581],
            [-79.3491, 34.8385],
            [-79.331, 34.7736],
            [-79.4618, 34.6304],
            [-79.6929, 34.805],
            [-79.5703, 34.9031],
            [-79.5727, 34.992],
            [-79.4592, 35.0431],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704200',
        NAME: 'Scotland County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 825813447,
        AWATER: 3821988,
        INTPTLAT: '+34.8400226',
        INTPTLON: '-079.4773366',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.2952, 35.5029],
            [-80.1827, 35.5043],
            [-80.1085, 35.4595],
            [-80.0517, 35.3726],
            [-80.0982, 35.2552],
            [-80.0754, 35.1431],
            [-80.1742, 35.148],
            [-80.2771, 35.1961],
            [-80.3591, 35.1658],
            [-80.5053, 35.1851],
            [-80.2952, 35.5029],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704320',
        NAME: 'Stanly County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1023264907,
        AWATER: 25262143,
        INTPTLAT: '+35.3104492',
        INTPTLON: '-080.2543742',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.4401, 36.5506],
            [-80.0535, 36.5425],
            [-80.0273, 36.5425],
            [-80.0355, 36.2574],
            [-80.452, 36.2616],
            [-80.4401, 36.5506],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704380',
        NAME: 'Stokes County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1162543117,
        AWATER: 17531433,
        INTPTLAT: '+36.3935056',
        INTPTLON: '-080.2661995',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9017, 36.5618],
            [-80.838, 36.5591],
            [-80.6329, 36.5586],
            [-80.571, 36.5562],
            [-80.4401, 36.5506],
            [-80.452, 36.2616],
            [-80.4523, 36.2412],
            [-80.6317, 36.2851],
            [-80.7696, 36.2749],
            [-80.8689, 36.3265],
            [-80.9671, 36.4016],
            [-80.9017, 36.5618],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704410',
        NAME: 'Surry County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1266762981,
        AWATER: 9427330,
        INTPTLAT: '+36.4149150',
        INTPTLON: '-080.6797970',
        ELSDLEA: '',
        UNSDLEA: '04410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9527, 35.4607],
            [-83.7717, 35.5621],
            [-83.663, 35.5691],
            [-83.4983, 35.563],
            [-83.2542, 35.6958],
            [-83.1819, 35.6711],
            [-83.1801, 35.5188],
            [-83.1911, 35.5166],
            [-83.2311, 35.5236],
            [-83.3484, 35.5086],
            [-83.3569, 35.4478],
            [-83.3397, 35.331],
            [-83.4557, 35.303],
            [-83.68, 35.2794],
            [-83.5855, 35.4349],
            [-83.9527, 35.4607],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704440',
        NAME: 'Swain County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1267801546,
        AWATER: 32685933,
        INTPTLAT: '+35.5109919',
        INTPTLON: '-083.5202504',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1061, 35.8777],
            [-80.0642, 35.909],
            [-80.0636, 35.8601],
            [-80.1061, 35.8777],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704500',
        NAME: 'Thomasville City Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18319739,
        AWATER: 0,
        INTPTLAT: '+35.8835690',
        INTPTLON: '-080.0795887',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9208, 35.2919],
            [-82.832, 35.3184],
            [-82.7452, 35.4229],
            [-82.6278, 35.3118],
            [-82.5749, 35.1455],
            [-82.7645, 35.0682],
            [-82.8975, 35.056],
            [-83.0086, 35.0275],
            [-82.9208, 35.2919],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704530',
        NAME: 'Transylvania County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 980388876,
        AWATER: 5135255,
        INTPTLAT: '+35.2100794',
        INTPTLON: '-082.8166530',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3584, 36.02],
            [-76.158, 36.0595],
            [-76.0191, 36.0976],
            [-75.9063, 36.0859],
            [-75.8401, 36.0289],
            [-75.9487, 35.9944],
            [-76.0083, 35.8958],
            [-76.046, 35.6661],
            [-76.1721, 35.6972],
            [-76.2143, 35.598],
            [-76.2978, 35.6091],
            [-76.2693, 35.6907],
            [-76.3953, 35.6975],
            [-76.406, 35.785],
            [-76.3534, 35.8614],
            [-76.3799, 35.9147],
            [-76.3584, 36.02],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704590',
        NAME: 'Tyrrell County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1007613021,
        AWATER: 531404547,
        INTPTLAT: '+35.8704196',
        INTPTLON: '-076.1653454',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5503, 35.2084],
            [-80.5053, 35.1851],
            [-80.3591, 35.1658],
            [-80.2771, 35.1961],
            [-80.3204, 34.814],
            [-80.5617, 34.8175],
            [-80.7975, 34.8198],
            [-80.782, 34.9358],
            [-80.8403, 35.0017],
            [-80.7704, 35.0342],
            [-80.5503, 35.2084],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704620',
        NAME: 'Union County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1635942670,
        AWATER: 20601757,
        INTPTLAT: '+34.9918171',
        INTPTLON: '-080.5304354',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.457, 36.5425],
            [-78.3239, 36.5438],
            [-78.2775, 36.3062],
            [-78.307, 36.2662],
            [-78.412, 36.1697],
            [-78.4965, 36.1752],
            [-78.5113, 36.4543],
            [-78.457, 36.5425],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704650',
        NAME: 'Vance County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 656633631,
        AWATER: 42196853,
        INTPTLAT: '+36.3654806',
        INTPTLON: '-078.4054343',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9062, 35.8679],
            [-78.7592, 35.9182],
            [-78.699, 36.0091],
            [-78.749, 36.0712],
            [-78.6833, 36.0745],
            [-78.5465, 36.0218],
            [-78.4266, 35.9749],
            [-78.3074, 35.8966],
            [-78.2549, 35.8177],
            [-78.46, 35.7057],
            [-78.7086, 35.5191],
            [-78.9133, 35.5831],
            [-78.9951, 35.6102],
            [-78.9062, 35.8679],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704720',
        NAME: 'Wake County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2163485080,
        AWATER: 56968106,
        INTPTLAT: '+35.7898464',
        INTPTLON: '-078.6506240',
        ELSDLEA: '',
        UNSDLEA: '04720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.3239, 36.5438],
            [-78.0462, 36.5442],
            [-77.8998, 36.5447],
            [-77.8979, 36.5068],
            [-77.912, 36.3823],
            [-78.0066, 36.2027],
            [-78.1329, 36.2451],
            [-78.307, 36.2662],
            [-78.2775, 36.3062],
            [-78.3239, 36.5438],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704740',
        NAME: 'Warren County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1109696072,
        AWATER: 39641550,
        INTPTLAT: '+36.3981051',
        INTPTLON: '-078.0998993',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7608, 35.8648],
            [-76.6662, 35.962],
            [-76.6061, 35.9785],
            [-76.5233, 35.9789],
            [-76.3969, 36.0264],
            [-76.3584, 36.02],
            [-76.3799, 35.9147],
            [-76.3534, 35.8614],
            [-76.406, 35.785],
            [-76.3953, 35.6975],
            [-76.638, 35.7052],
            [-76.8451, 35.7054],
            [-76.7608, 35.8648],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704800',
        NAME: 'Washington County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 901664364,
        AWATER: 196093082,
        INTPTLAT: '+35.8447130',
        INTPTLON: '-076.5722906',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7286, 36.3912],
            [-81.4773, 36.2403],
            [-81.4556, 36.1997],
            [-81.5455, 36.1174],
            [-81.8115, 36.1115],
            [-81.9091, 36.2164],
            [-81.9181, 36.2871],
            [-81.7286, 36.3912],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704830',
        NAME: 'Watauga County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 809500164,
        AWATER: 2341456,
        INTPTLAT: '+36.2353724',
        INTPTLON: '-081.7099229',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0646, 35.5854],
            [-77.8235, 35.5855],
            [-77.8056, 35.3698],
            [-77.8342, 35.1778],
            [-77.8843, 35.163],
            [-78.0436, 35.1928],
            [-78.1635, 35.1894],
            [-78.3047, 35.2861],
            [-78.1587, 35.3476],
            [-78.1549, 35.4176],
            [-78.0646, 35.5854],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704880',
        NAME: 'Wayne County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1432263112,
        AWATER: 9863348,
        INTPTLAT: '+35.3541896',
        INTPTLON: '-078.0086695',
        ELSDLEA: '',
        UNSDLEA: '04880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.64, 36.4347],
            [-77.6283, 36.4485],
            [-77.5571, 36.4186],
            [-77.586, 36.337],
            [-77.5001, 36.3078],
            [-77.5855, 36.2097],
            [-77.663, 36.3549],
            [-77.64, 36.4347],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704890',
        NAME: 'Weldon City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222411709,
        AWATER: 316931,
        INTPTLAT: '+36.3521632',
        INTPTLON: '-077.6140529',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8021, 34.462],
            [-78.6567, 34.4505],
            [-78.675, 34.2697],
            [-78.7876, 34.247],
            [-78.7685, 34.3562],
            [-78.8021, 34.462],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704920',
        NAME: 'Whiteville City Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238122856,
        AWATER: 808070,
        INTPTLAT: '+34.3470838',
        INTPTLON: '-078.7283079',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4773, 36.2403],
            [-81.2535, 36.3654],
            [-81.163, 36.4324],
            [-80.9671, 36.4016],
            [-80.8689, 36.3265],
            [-80.8733, 36.2364],
            [-80.8822, 36.0557],
            [-81.0289, 36.0458],
            [-81.1909, 36.0369],
            [-81.3284, 35.9965],
            [-81.4079, 36.0706],
            [-81.5455, 36.1174],
            [-81.4556, 36.1997],
            [-81.4773, 36.2403],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3704950',
        NAME: 'Wilkes County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1953561144,
        AWATER: 6851576,
        INTPTLAT: '+36.2088833',
        INTPTLON: '-081.1660949',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1921, 35.7304],
            [-77.8284, 35.8672],
            [-77.7516, 35.8279],
            [-77.72, 35.7302],
            [-77.6613, 35.6773],
            [-77.7005, 35.6523],
            [-77.8235, 35.5855],
            [-78.0646, 35.5854],
            [-78.1256, 35.6031],
            [-78.1921, 35.7304],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3705020',
        NAME: 'Wilson County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 953483115,
        AWATER: 14477233,
        INTPTLAT: '+35.7003569',
        INTPTLON: '-077.9215978',
        ELSDLEA: '',
        UNSDLEA: '05020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8733, 36.2364],
            [-80.7696, 36.2749],
            [-80.6317, 36.2851],
            [-80.4523, 36.2412],
            [-80.4396, 36.1366],
            [-80.4964, 36.0465],
            [-80.6932, 36.0511],
            [-80.8822, 36.0557],
            [-80.8733, 36.2364],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3705040',
        NAME: 'Yadkin County Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 867204123,
        AWATER: 6931829,
        INTPTLAT: '+36.1587653',
        INTPTLON: '-080.6651641',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4167, 36.0728],
            [-82.3352, 36.029],
            [-82.2141, 36.0169],
            [-82.1505, 35.9243],
            [-82.1332, 35.8244],
            [-82.1939, 35.7349],
            [-82.2754, 35.7044],
            [-82.4087, 35.8181],
            [-82.5054, 35.9777],
            [-82.4167, 36.0728],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3705070',
        NAME: 'Yancey County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 809619000,
        AWATER: 1458826,
        INTPTLAT: '+35.8894554',
        INTPTLON: '-082.3041188',
        ELSDLEA: '',
        UNSDLEA: '05070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.1801, 35.5188],
              [-83.1791, 35.5105],
              [-83.1911, 35.5166],
              [-83.1801, 35.5188],
            ],
          ],
          [
            [
              [-83.2311, 35.5236],
              [-83.1608, 35.5004],
              [-83.1579, 35.4963],
              [-83.2266, 35.4434],
              [-83.3569, 35.4478],
              [-83.3484, 35.5086],
              [-83.2311, 35.5236],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '37',
        SCSDLEA: '',
        GEOID: '3737099',
        NAME: 'Eastern Cherokee Reservation',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'D',
        FUNCSTAT: 'E',
        ALAND: 178987459,
        AWATER: 0,
        INTPTLAT: '+35.4947611',
        INTPTLON: '-083.2747455',
        ELSDLEA: '',
        UNSDLEA: '37099',
      },
    },
  ],
};

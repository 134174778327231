export const IA = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6421, 41.5381],
            [-92.6036, 41.5092],
            [-92.412, 41.5095],
            [-92.4598, 41.4085],
            [-92.5777, 41.3358],
            [-92.7183, 41.3789],
            [-92.7954, 41.5018],
            [-92.6421, 41.5381],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920850',
        NAME: 'North Mahaska Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475505831,
        AWATER: 766857,
        INTPTLAT: '+41.4450075',
        INTPTLON: '-092.6125964',
        ELSDLEA: '',
        UNSDLEA: '20850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.755, 41.2048],
            [-92.5545, 41.2193],
            [-92.4108, 41.2715],
            [-92.3537, 41.262],
            [-92.4103, 41.1844],
            [-92.41, 41.1119],
            [-92.5528, 40.9715],
            [-92.5723, 40.899],
            [-92.5965, 40.8989],
            [-92.6391, 40.8989],
            [-92.7073, 40.9595],
            [-92.6399, 41.0808],
            [-92.7546, 41.0816],
            [-92.7595, 41.1613],
            [-92.755, 41.2048],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904950',
        NAME: 'Eddyville-Blakesburg-Fremont Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 732129592,
        AWATER: 4250961,
        INTPTLAT: '+41.1238006',
        INTPTLON: '-092.5762789',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.385, 40.9016],
            [-95.1752, 40.9011],
            [-95.128, 40.872],
            [-95.1849, 40.7858],
            [-95.3278, 40.8008],
            [-95.385, 40.9016],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1911040',
        NAME: 'Essex Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233712514,
        AWATER: 421602,
        INTPTLAT: '+40.8506495',
        INTPTLON: '-095.2422957',
        ELSDLEA: '',
        UNSDLEA: '11040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1186, 41.0762],
            [-95.0278, 41.0798],
            [-95.07, 40.9407],
            [-95.0615, 40.8718],
            [-95.1187, 40.8791],
            [-95.128, 40.872],
            [-95.1752, 40.9011],
            [-95.1186, 41.0762],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927240',
        NAME: 'Stanton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202605730,
        AWATER: 75688,
        INTPTLAT: '+40.9795588',
        INTPTLON: '-095.0970516',
        ELSDLEA: '',
        UNSDLEA: '27240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5573, 41.1543],
            [-93.3279, 41.1607],
            [-93.2131, 41.1608],
            [-93.0992, 41.1609],
            [-93.0976, 40.8985],
            [-93.3845, 40.907],
            [-93.4035, 40.9557],
            [-93.5567, 40.9864],
            [-93.5573, 41.1543],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907050',
        NAME: 'Chariton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 957776951,
        AWATER: 9779257,
        INTPTLAT: '+41.0501145',
        INTPTLON: '-093.3129736',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6137, 40.6636],
            [-93.494, 40.7538],
            [-93.3845, 40.907],
            [-93.0976, 40.8985],
            [-93.0976, 40.8269],
            [-93.2121, 40.8213],
            [-93.2122, 40.7176],
            [-93.3029, 40.6377],
            [-93.2889, 40.5808],
            [-93.3744, 40.5803],
            [-93.6331, 40.5787],
            [-93.6137, 40.6636],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930560',
        NAME: 'Wayne Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 929645764,
        AWATER: 3264489,
        INTPTLAT: '+40.7322516',
        INTPTLON: '-093.3734797',
        ELSDLEA: '',
        UNSDLEA: '30560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9148, 40.6278],
            [-94.9147, 40.6316],
            [-94.9147, 40.6497],
            [-94.8333, 40.7775],
            [-94.8527, 40.8386],
            [-94.8289, 40.8424],
            [-94.7003, 40.8347],
            [-94.7003, 40.7846],
            [-94.5858, 40.7627],
            [-94.4998, 40.7048],
            [-94.4713, 40.6757],
            [-94.4432, 40.571],
            [-94.6701, 40.5717],
            [-94.9149, 40.5751],
            [-94.9148, 40.6278],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904560',
        NAME: 'Bedford Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 820751437,
        AWATER: 5426633,
        INTPTLAT: '+40.6742290',
        INTPTLON: '-094.6956193',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2555, 41.8048],
            [-92.1811, 41.8626],
            [-91.9864, 41.8622],
            [-91.9868, 41.8247],
            [-91.9936, 41.7708],
            [-92.0722, 41.7023],
            [-92.1728, 41.696],
            [-92.2555, 41.8048],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914850',
        NAME: 'Iowa Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 269286714,
        AWATER: 379754,
        INTPTLAT: '+41.7864782',
        INTPTLON: '-092.1199380',
        ELSDLEA: '',
        UNSDLEA: '14850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3282, 42.0288],
            [-92.3269, 42.036],
            [-92.2026, 42.0945],
            [-92.0664, 42.0943],
            [-91.8353, 42.037],
            [-91.8333, 41.9494],
            [-91.8312, 41.8255],
            [-91.9868, 41.8247],
            [-91.9864, 41.8622],
            [-92.1811, 41.8626],
            [-92.1819, 41.9494],
            [-92.3373, 41.9927],
            [-92.3282, 42.0288],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904830',
        NAME: 'Benton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 860429441,
        AWATER: 198598,
        INTPTLAT: '+41.9672422',
        INTPTLON: '-092.0497593',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9055, 41.5403],
            [-91.7939, 41.6001],
            [-91.7123, 41.6],
            [-91.5954, 41.5398],
            [-91.6378, 41.4247],
            [-91.7538, 41.4235],
            [-91.754, 41.3403],
            [-91.8685, 41.3078],
            [-91.8583, 41.4241],
            [-91.9461, 41.4611],
            [-91.9055, 41.5403],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1919140',
        NAME: 'Mid-Prairie Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 556858046,
        AWATER: 46425,
        INTPTLAT: '+41.4694149',
        INTPTLON: '-091.7789132',
        ELSDLEA: '',
        UNSDLEA: '19140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.412, 41.5095],
            [-92.3365, 41.5652],
            [-92.1818, 41.51],
            [-92.2199, 41.4239],
            [-92.4112, 41.3361],
            [-92.4598, 41.4085],
            [-92.412, 41.5095],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1928020',
        NAME: 'Tri-County Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332150892,
        AWATER: 390512,
        INTPTLAT: '+41.4572116',
        INTPTLON: '-092.3313709',
        ELSDLEA: '',
        UNSDLEA: '28020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7469, 42.8256],
            [-92.6828, 42.862],
            [-92.6434, 42.8764],
            [-92.5741, 42.8026],
            [-92.7123, 42.7043],
            [-92.7469, 42.8256],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907440',
        NAME: 'Clarksville Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167037327,
        AWATER: 1416893,
        INTPTLAT: '+42.7992319',
        INTPTLON: '-092.6704368',
        ELSDLEA: '',
        UNSDLEA: '07440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1934, 41.5635],
            [-95.1026, 41.6882],
            [-95.0932, 41.6884],
            [-95.015, 41.6223],
            [-95.0181, 41.5336],
            [-95.0229, 41.5192],
            [-95.1084, 41.505],
            [-95.1934, 41.5635],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910710',
        NAME: 'Elk Horn-Kimballton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216766473,
        AWATER: 0,
        INTPTLAT: '+41.5851693',
        INTPTLON: '-095.0868735',
        ELSDLEA: '',
        UNSDLEA: '10710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-95.3495, 41.864],
              [-95.2376, 41.9948],
              [-95.1025, 41.9797],
              [-95.0248, 42.0119],
              [-95.0152, 41.9577],
              [-94.9081, 41.863],
              [-95.0149, 41.863],
              [-95.093, 41.7984],
              [-95.0932, 41.6884],
              [-95.1026, 41.6882],
              [-95.2862, 41.6889],
              [-95.3254, 41.7178],
              [-95.3495, 41.864],
            ],
          ],
          [
            [
              [-95.3546, 41.8639],
              [-95.4022, 41.8635],
              [-95.4022, 41.8705],
              [-95.3546, 41.8639],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914880',
        NAME: 'IKM-Manning Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 819750109,
        AWATER: 146969,
        INTPTLAT: '+41.8565704',
        INTPTLON: '-095.1604935',
        ELSDLEA: '',
        UNSDLEA: '14880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3573, 40.8992],
            [-94.2422, 40.9916],
            [-94.2416, 41.1571],
            [-94.1279, 41.1568],
            [-93.9376, 41.208],
            [-93.9657, 41.139],
            [-94.0144, 41.1135],
            [-94.0257, 40.8971],
            [-94.2625, 40.8694],
            [-94.3573, 40.8992],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910350',
        NAME: 'East Union Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 686984034,
        AWATER: 1565895,
        INTPTLAT: '+41.0252405',
        INTPTLON: '-094.1463439',
        ELSDLEA: '',
        UNSDLEA: '10350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6096, 40.9719],
            [-94.5138, 40.9972],
            [-94.434, 40.8993],
            [-94.4345, 40.8562],
            [-94.5185, 40.8486],
            [-94.5858, 40.7627],
            [-94.7003, 40.7846],
            [-94.7003, 40.8347],
            [-94.6096, 40.9719],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1916620',
        NAME: 'Lenox Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 363782396,
        AWATER: 1388117,
        INTPTLAT: '+40.8822097',
        INTPTLON: '-094.5790764',
        ELSDLEA: '',
        UNSDLEA: '16620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1799, 40.8386],
            [-91.1608, 40.8407],
            [-91.153, 40.8114],
            [-91.1799, 40.8117],
            [-91.1799, 40.8386],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930780',
        NAME: 'West Burlington Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6765209,
        AWATER: 0,
        INTPTLAT: '+40.8241807',
        INTPTLON: '-091.1661327',
        ELSDLEA: '',
        UNSDLEA: '30780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6836, 42.0454],
            [-93.5815, 42.0417],
            [-93.5806, 41.9722],
            [-93.6586, 41.9793],
            [-93.6836, 42.0454],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903540',
        NAME: 'Ames Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93421228,
        AWATER: 230095,
        INTPTLAT: '+42.0161699',
        INTPTLON: '-093.6158567',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5644, 42.1226],
            [-93.463, 42.1232],
            [-93.3863, 42.0925],
            [-93.3574, 41.9502],
            [-93.4829, 41.9208],
            [-93.5806, 41.9722],
            [-93.5815, 42.0417],
            [-93.5644, 42.1226],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920250',
        NAME: 'Nevada Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301446858,
        AWATER: 232165,
        INTPTLAT: '+42.0304841',
        INTPTLON: '-093.4883398',
        ELSDLEA: '',
        UNSDLEA: '20250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8832, 41.9505],
            [-93.8949, 41.9903],
            [-93.842, 42.1375],
            [-93.8008, 42.1592],
            [-93.7909, 42.1664],
            [-93.7472, 42.1557],
            [-93.6836, 42.0454],
            [-93.6586, 41.9793],
            [-93.7373, 41.9218],
            [-93.8691, 41.9214],
            [-93.8832, 41.9505],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1928560',
        NAME: 'United Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332402828,
        AWATER: 525140,
        INTPTLAT: '+42.0232781',
        INTPTLON: '-093.7804311',
        ELSDLEA: '',
        UNSDLEA: '28560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6586, 41.9793],
            [-93.5806, 41.9722],
            [-93.4829, 41.9208],
            [-93.4927, 41.8631],
            [-93.7375, 41.8634],
            [-93.7373, 41.9218],
            [-93.6586, 41.9793],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904200',
        NAME: 'Ballard Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223338056,
        AWATER: 496978,
        INTPTLAT: '+41.9012652',
        INTPTLON: '-093.6218308',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5472, 41.1153],
            [-94.3571, 41.1574],
            [-94.2416, 41.1571],
            [-94.2422, 40.9916],
            [-94.3573, 40.8992],
            [-94.434, 40.8993],
            [-94.5138, 40.9972],
            [-94.5472, 41.1153],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908310',
        NAME: 'Creston Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 515065433,
        AWATER: 4079527,
        INTPTLAT: '+41.0346821',
        INTPTLON: '-094.3848177',
        ELSDLEA: '',
        UNSDLEA: '08310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3322, 42.7979],
            [-94.2666, 42.8275],
            [-94.1296, 42.811],
            [-94.0897, 42.8486],
            [-93.9712, 42.9078],
            [-93.9517, 42.7931],
            [-94.0183, 42.7245],
            [-94.0901, 42.6448],
            [-94.2095, 42.631],
            [-94.3578, 42.6451],
            [-94.3322, 42.7979],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914370',
        NAME: 'Humboldt Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 565014700,
        AWATER: 2419817,
        INTPTLAT: '+42.7697208',
        INTPTLON: '-094.1460066',
        ELSDLEA: '',
        UNSDLEA: '14370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4822, 42.6377],
            [-94.3578, 42.6451],
            [-94.2095, 42.631],
            [-94.2465, 42.4728],
            [-94.3977, 42.4733],
            [-94.4955, 42.4354],
            [-94.6238, 42.4956],
            [-94.5604, 42.6092],
            [-94.4822, 42.6377],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918330',
        NAME: 'Manson Northwest Webster Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 579970960,
        AWATER: 1280145,
        INTPTLAT: '+42.5391146',
        INTPTLON: '-094.3990914',
        ELSDLEA: '',
        UNSDLEA: '18330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1646, 42.3981],
            [-94.0251, 42.4194],
            [-93.9614, 42.3767],
            [-93.996, 42.243],
            [-93.9657, 42.1951],
            [-93.9381, 42.1231],
            [-94.1447, 42.1],
            [-94.1755, 42.1516],
            [-94.1648, 42.1807],
            [-94.1933, 42.2823],
            [-94.1646, 42.3981],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1999019',
        NAME: 'Southeast Webster-Grand Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 570734764,
        AWATER: 3279899,
        INTPTLAT: '+42.2591858',
        INTPTLON: '-094.0742447',
        ELSDLEA: '',
        UNSDLEA: '99019',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4431, 42.8795],
            [-94.4429, 42.8795],
            [-94.3322, 42.7979],
            [-94.3578, 42.6451],
            [-94.4822, 42.6377],
            [-94.5019, 42.7357],
            [-94.4539, 42.7613],
            [-94.4431, 42.8795],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1912600',
        NAME: 'Gilmore City-Bradgate Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240964639,
        AWATER: 1810304,
        INTPTLAT: '+42.7378510',
        INTPTLON: '-094.4118042',
        ELSDLEA: '',
        UNSDLEA: '12600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2095, 42.631],
            [-94.0901, 42.6448],
            [-94.0899, 42.5726],
            [-93.9716, 42.5581],
            [-93.9716, 42.4787],
            [-94.0251, 42.4194],
            [-94.1646, 42.3981],
            [-94.2465, 42.4728],
            [-94.2095, 42.631],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1911820',
        NAME: 'Fort Dodge Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391434237,
        AWATER: 3670336,
        INTPTLAT: '+42.5230831',
        INTPTLON: '-094.1190985',
        ELSDLEA: '',
        UNSDLEA: '11820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9614, 42.3767],
            [-93.865, 42.3504],
            [-93.8447, 42.1882],
            [-93.9657, 42.1951],
            [-93.996, 42.243],
            [-93.9614, 42.3767],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927480',
        NAME: 'Stratford Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201864637,
        AWATER: 1248679,
        INTPTLAT: '+42.2731330',
        INTPTLON: '-093.9176156',
        ELSDLEA: '',
        UNSDLEA: '27480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2995, 41.6015],
            [-92.2995, 41.6031],
            [-92.1827, 41.63],
            [-91.9055, 41.5403],
            [-91.9461, 41.4611],
            [-92.0837, 41.3954],
            [-92.2199, 41.4239],
            [-92.1818, 41.51],
            [-92.3365, 41.5652],
            [-92.2995, 41.6015],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910980',
        NAME: 'English Valleys Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 500522940,
        AWATER: 498944,
        INTPTLAT: '+41.5129071',
        INTPTLON: '-092.1196682',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3537, 41.262],
            [-92.1903, 41.2342],
            [-92.0834, 41.2813],
            [-91.9447, 41.2363],
            [-91.9366, 41.1634],
            [-92.0243, 41.0761],
            [-92.169, 41.0761],
            [-92.2956, 41.0605],
            [-92.41, 41.1119],
            [-92.4103, 41.1844],
            [-92.3537, 41.262],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1922440',
        NAME: 'Pekin Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 678207846,
        AWATER: 871892,
        INTPTLAT: '+41.1699331',
        INTPTLON: '-092.1696048',
        ELSDLEA: '',
        UNSDLEA: '22440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9461, 41.4611],
            [-91.8583, 41.4241],
            [-91.8685, 41.3078],
            [-91.9447, 41.2363],
            [-92.0834, 41.2813],
            [-92.0837, 41.3954],
            [-91.9461, 41.4611],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1915660',
        NAME: 'Keota Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357685777,
        AWATER: 823725,
        INTPTLAT: '+41.3422378',
        INTPTLON: '-091.9570927',
        ELSDLEA: '',
        UNSDLEA: '15660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8107, 43.0408],
            [-95.7232, 43.0623],
            [-95.664, 43.128],
            [-95.5847, 43.1642],
            [-95.5053, 43.0609],
            [-95.398, 43.0541],
            [-95.3686, 42.9998],
            [-95.4276, 42.9099],
            [-95.4674, 42.8795],
            [-95.6228, 42.8882],
            [-95.8594, 42.9091],
            [-95.8107, 43.0408],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900026',
        NAME: "South O'Brien Community School District",
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 789146746,
        AWATER: 368580,
        INTPTLAT: '+43.0048993',
        INTPTLON: '-095.6189322',
        ELSDLEA: '',
        UNSDLEA: '00026',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7103, 41.5474],
            [-94.7147, 41.6094],
            [-94.5098, 41.6018],
            [-94.4712, 41.5614],
            [-94.48, 41.4172],
            [-94.662, 41.3744],
            [-94.7103, 41.5474],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903090',
        NAME: 'Adair-Casey Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 411841851,
        AWATER: 436784,
        INTPTLAT: '+41.5049772',
        INTPTLON: '-094.5910499',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9081, 41.863],
            [-94.8028, 41.8629],
            [-94.7157, 41.7469],
            [-94.7441, 41.6673],
            [-94.855, 41.6705],
            [-95.015, 41.6223],
            [-95.0932, 41.6884],
            [-95.093, 41.7984],
            [-95.0149, 41.863],
            [-94.9081, 41.863],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903960',
        NAME: 'Audubon Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 620416085,
        AWATER: 55980,
        INTPTLAT: '+41.7456948',
        INTPTLON: '-094.9089115',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4712, 41.5614],
            [-94.3863, 41.6088],
            [-94.2026, 41.6296],
            [-94.2026, 41.6955],
            [-94.0945, 41.5861],
            [-94.104, 41.5572],
            [-94.2416, 41.5037],
            [-94.2415, 41.3882],
            [-94.2415, 41.3738],
            [-94.3566, 41.4172],
            [-94.48, 41.4172],
            [-94.4712, 41.5614],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927500',
        NAME: 'West Central Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 599612264,
        AWATER: 810820,
        INTPTLAT: '+41.5220538',
        INTPTLON: '-094.3098132',
        ELSDLEA: '',
        UNSDLEA: '27500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3078, 41.9653],
            [-91.3394, 41.9183],
            [-91.3076, 41.8163],
            [-91.366, 41.8165],
            [-91.4132, 41.8598],
            [-91.4043, 41.9763],
            [-91.3078, 41.9653],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1917250',
        NAME: 'Lisbon Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123567304,
        AWATER: 1949497,
        INTPTLAT: '+41.8889071',
        INTPTLON: '-091.3572394',
        ELSDLEA: '',
        UNSDLEA: '17250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4637, 41.7146],
            [-91.366, 41.7837],
            [-91.2895, 41.7727],
            [-91.1452, 41.6577],
            [-91.1415, 41.6305],
            [-91.2883, 41.6489],
            [-91.4053, 41.617],
            [-91.4637, 41.7146],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930750',
        NAME: 'West Branch Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 326785746,
        AWATER: 1819899,
        INTPTLAT: '+41.6914005',
        INTPTLON: '-091.3184325',
        ELSDLEA: '',
        UNSDLEA: '30750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1415, 41.6305],
            [-91.1452, 41.6577],
            [-91.0351, 41.6991],
            [-90.977, 41.6699],
            [-90.944, 41.5223],
            [-91.1454, 41.5089],
            [-91.1415, 41.6305],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931800',
        NAME: 'Wilton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254138085,
        AWATER: 5272241,
        INTPTLAT: '+41.5919632',
        INTPTLON: '-091.0506092',
        ELSDLEA: '',
        UNSDLEA: '31800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.015, 41.6223],
            [-94.855, 41.6705],
            [-94.7441, 41.6673],
            [-94.7147, 41.6094],
            [-94.7103, 41.5474],
            [-94.8735, 41.501],
            [-95.0181, 41.5336],
            [-95.015, 41.6223],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1911250',
        NAME: 'Exira Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366187727,
        AWATER: 1096280,
        INTPTLAT: '+41.5839735',
        INTPTLON: '-094.8411001',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3485, 41.6588],
            [-93.1447, 41.6372],
            [-92.9961, 41.588],
            [-92.968, 41.5373],
            [-93.0683, 41.4871],
            [-93.0919, 41.4248],
            [-93.1582, 41.4334],
            [-93.2816, 41.4889],
            [-93.3289, 41.5363],
            [-93.3485, 41.6588],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1999017',
        NAME: 'PCM Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491391126,
        AWATER: 3535035,
        INTPTLAT: '+41.5452851',
        INTPTLON: '-093.1637607',
        ELSDLEA: '',
        UNSDLEA: '99017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8101, 43.1495],
            [-92.6928, 43.2125],
            [-92.5942, 43.2128],
            [-92.5544, 43.2128],
            [-92.4955, 43.0522],
            [-92.5935, 43.0234],
            [-92.6728, 42.9362],
            [-92.7905, 42.9728],
            [-92.8302, 43.0571],
            [-92.8101, 43.1495],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907080',
        NAME: 'Charles City Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 581863794,
        AWATER: 604648,
        INTPTLAT: '+43.0916416',
        INTPTLON: '-092.6659298',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2502, 42.8983],
            [-92.0815, 42.9505],
            [-91.9635, 42.8912],
            [-91.9638, 42.8184],
            [-92.0712, 42.7804],
            [-92.1804, 42.7549],
            [-92.2502, 42.8983],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927600',
        NAME: 'Sumner Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 348967386,
        AWATER: 463383,
        INTPTLAT: '+42.8507627',
        INTPTLON: '-092.0983766',
        ELSDLEA: '',
        UNSDLEA: '27600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3296, 42.1533],
            [-96.1989, 42.1931],
            [-96.0104, 42.165],
            [-95.9821, 42.0945],
            [-96.2779, 42.058],
            [-96.272, 42.0892],
            [-96.3296, 42.1533],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931620',
        NAME: 'Whiting Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257189633,
        AWATER: 3506270,
        INTPTLAT: '+42.1270573',
        INTPTLON: '-096.1599897',
        ELSDLEA: '',
        UNSDLEA: '31620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.537, 41.6586],
            [-93.3484, 41.6844],
            [-93.3485, 41.6588],
            [-93.3289, 41.5363],
            [-93.2816, 41.4889],
            [-93.3284, 41.4909],
            [-93.5296, 41.5539],
            [-93.5467, 41.6368],
            [-93.537, 41.6586],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926820',
        NAME: 'Southeast Polk Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 289745252,
        AWATER: 4135852,
        INTPTLAT: '+41.5901199',
        INTPTLON: '-093.4112595',
        ELSDLEA: '',
        UNSDLEA: '26820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7785, 41.9284],
            [-95.7013, 41.9284],
            [-95.5374, 41.9938],
            [-95.5176, 42.0297],
            [-95.4405, 41.9661],
            [-95.4022, 41.8705],
            [-95.4022, 41.8635],
            [-95.5462, 41.7724],
            [-95.7407, 41.8348],
            [-95.7899, 41.8771],
            [-95.7785, 41.9284],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1909570',
        NAME: 'Boyer Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 506752957,
        AWATER: 1192930,
        INTPTLAT: '+41.8791375',
        INTPTLON: '-095.5895997',
        ELSDLEA: '',
        UNSDLEA: '09570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.266, 41.5528],
            [-95.1934, 41.5635],
            [-95.1084, 41.505],
            [-95.2009, 41.3501],
            [-95.27, 41.354],
            [-95.266, 41.5528],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1929640',
        NAME: 'Walnut Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224746750,
        AWATER: 63177,
        INTPTLAT: '+41.4636129',
        INTPTLON: '-095.2037684',
        ELSDLEA: '',
        UNSDLEA: '29640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3548, 41.8221],
            [-90.2727, 41.852],
            [-90.2424, 41.783],
            [-90.3166, 41.7289],
            [-90.3745, 41.7343],
            [-90.3548, 41.8221],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906060',
        NAME: 'Camanche Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91595819,
        AWATER: 6435499,
        INTPTLAT: '+41.7953266',
        INTPTLON: '-090.3108608',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1642, 41.9462],
            [-90.152, 41.9289],
            [-90.188, 41.8032],
            [-90.2424, 41.783],
            [-90.2727, 41.852],
            [-90.1642, 41.9462],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907710',
        NAME: 'Clinton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68498859,
        AWATER: 18125718,
        INTPTLAT: '+41.8649410',
        INTPTLON: '-090.2041710',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5783, 40.687],
            [-95.5271, 40.7203],
            [-95.5278, 40.815],
            [-95.4993, 40.9016],
            [-95.4609, 40.9015],
            [-95.4337, 40.7717],
            [-95.3728, 40.6766],
            [-95.3739, 40.5805],
            [-95.4608, 40.5815],
            [-95.5783, 40.687],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1911520',
        NAME: 'Farragut Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 341089677,
        AWATER: 792655,
        INTPTLAT: '+40.7201216',
        INTPTLON: '-095.4664025',
        ELSDLEA: '',
        UNSDLEA: '11520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8341, 40.7838],
            [-95.8451, 40.8091],
            [-95.8449, 40.8146],
            [-95.5278, 40.815],
            [-95.5271, 40.7203],
            [-95.5783, 40.687],
            [-95.7938, 40.7061],
            [-95.8374, 40.6742],
            [-95.8341, 40.7838],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926250',
        NAME: 'Sidney Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 352348573,
        AWATER: 6142032,
        INTPTLAT: '+40.7518851',
        INTPTLON: '-095.6912781',
        ELSDLEA: '',
        UNSDLEA: '26250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.782, 42.0402],
            [-90.5487, 42.0328],
            [-90.5482, 41.9455],
            [-90.7037, 41.9163],
            [-90.7403, 41.9164],
            [-90.782, 42.0402],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908880',
        NAME: 'Delwood Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181985289,
        AWATER: 44567,
        INTPTLAT: '+41.9821992',
        INTPTLON: '-090.6688608',
        ELSDLEA: '',
        UNSDLEA: '08880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5183, 42.1015],
            [-90.5013, 42.1278],
            [-90.3066, 42.1905],
            [-90.1629, 42.1167],
            [-90.1496, 41.9775],
            [-90.3445, 41.9751],
            [-90.3633, 42.0335],
            [-90.5487, 42.0328],
            [-90.5183, 42.1015],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910130',
        NAME: 'Easton Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 456428391,
        AWATER: 27759666,
        INTPTLAT: '+42.0806599',
        INTPTLON: '-090.3249868',
        ELSDLEA: '',
        UNSDLEA: '10130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5487, 42.0328],
            [-90.3633, 42.0335],
            [-90.3445, 41.9751],
            [-90.1496, 41.9775],
            [-90.1642, 41.9462],
            [-90.2727, 41.852],
            [-90.3548, 41.8221],
            [-90.5482, 41.9455],
            [-90.5487, 42.0328],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921090',
        NAME: 'Northeast Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 446894978,
        AWATER: 6066936,
        INTPTLAT: '+41.9394803',
        INTPTLON: '-090.3769524',
        ELSDLEA: '',
        UNSDLEA: '21090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8989, 41.9185],
            [-90.7816, 41.8656],
            [-90.7403, 41.9164],
            [-90.7037, 41.9163],
            [-90.6701, 41.7636],
            [-90.8116, 41.7202],
            [-90.8982, 41.7988],
            [-90.8989, 41.9185],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906000',
        NAME: 'Calamus-Wheatland Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 319290209,
        AWATER: 286471,
        INTPTLAT: '+41.8197164',
        INTPTLON: '-090.7840410',
        ELSDLEA: '',
        UNSDLEA: '06000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.662, 41.3744],
            [-94.48, 41.4172],
            [-94.3566, 41.4172],
            [-94.2415, 41.3738],
            [-94.2416, 41.3016],
            [-94.2417, 41.2437],
            [-94.5852, 41.244],
            [-94.5854, 41.1579],
            [-94.661, 41.1579],
            [-94.7006, 41.1581],
            [-94.7002, 41.3455],
            [-94.662, 41.3744],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913110',
        NAME: 'Nodaway Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 723980494,
        AWATER: 1441306,
        INTPTLAT: '+41.3093530',
        INTPTLON: '-094.4901566',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2416, 41.3016],
            [-94.1271, 41.2581],
            [-94.1279, 41.1568],
            [-94.2416, 41.1571],
            [-94.3571, 41.1574],
            [-94.5472, 41.1153],
            [-94.5854, 41.1579],
            [-94.5852, 41.244],
            [-94.2417, 41.2437],
            [-94.2416, 41.3016],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921810',
        NAME: 'Orient-Macksburg Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 469245185,
        AWATER: 493164,
        INTPTLAT: '+41.1970559',
        INTPTLON: '-094.3533306',
        ELSDLEA: '',
        UNSDLEA: '21810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3283, 41.2803],
            [-93.2382, 41.2917],
            [-93.1554, 41.2294],
            [-93.2131, 41.1608],
            [-93.3279, 41.1607],
            [-93.3283, 41.2803],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918960',
        NAME: 'Melcher-Dallas Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165279520,
        AWATER: 23079,
        INTPTLAT: '+41.2265724',
        INTPTLON: '-093.2610015',
        ELSDLEA: '',
        UNSDLEA: '18960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1316, 42.0983],
            [-91.0472, 41.9642],
            [-91.2102, 41.9471],
            [-91.2488, 41.9764],
            [-91.2357, 42.0631],
            [-91.1316, 42.0983],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921720',
        NAME: 'Olin Consolidated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 207223090,
        AWATER: 2071858,
        INTPTLAT: '+42.0176415',
        INTPTLON: '-091.1486717',
        ELSDLEA: '',
        UNSDLEA: '21720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.523, 41.5398],
            [-91.5268, 41.5986],
            [-91.4049, 41.5986],
            [-91.3668, 41.5612],
            [-91.3685, 41.4232],
            [-91.484, 41.4238],
            [-91.523, 41.5398],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1917550',
        NAME: 'Lone Tree Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 237234793,
        AWATER: 1655603,
        INTPTLAT: '+41.5143327',
        INTPTLON: '-091.4359494',
        ELSDLEA: '',
        UNSDLEA: '17550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2873, 41.4229],
            [-91.2803, 41.4301],
            [-91.1956, 41.3709],
            [-91.0645, 41.3699],
            [-91.0721, 41.3334],
            [-91.1104, 41.2373],
            [-91.2344, 41.2271],
            [-91.3521, 41.3256],
            [-91.2873, 41.4229],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1917820',
        NAME: 'Louisa-Muscatine Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290284211,
        AWATER: 9227280,
        INTPTLAT: '+41.3130107',
        INTPTLON: '-091.2176266',
        ELSDLEA: '',
        UNSDLEA: '17820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8527, 41.5352],
            [-90.7935, 41.5389],
            [-90.8018, 41.6693],
            [-90.7919, 41.6838],
            [-90.6661, 41.5962],
            [-90.5255, 41.5963],
            [-90.533, 41.5249],
            [-90.5372, 41.5256],
            [-90.6814, 41.4594],
            [-90.7863, 41.4529],
            [-90.8527, 41.5352],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908580',
        NAME: 'Davenport Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370015135,
        AWATER: 9037949,
        INTPTLAT: '+41.5462669',
        INTPTLON: '-090.6936310',
        ELSDLEA: '',
        UNSDLEA: '08580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6701, 41.7636],
            [-90.5376, 41.7764],
            [-90.3745, 41.7343],
            [-90.3166, 41.7289],
            [-90.3131, 41.6996],
            [-90.3402, 41.6556],
            [-90.4518, 41.6554],
            [-90.4931, 41.5966],
            [-90.5255, 41.5963],
            [-90.6661, 41.5962],
            [-90.7919, 41.6838],
            [-90.8116, 41.7202],
            [-90.6701, 41.7636],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920940',
        NAME: 'North Scott Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 549992654,
        AWATER: 7287197,
        INTPTLAT: '+41.6893180',
        INTPTLON: '-090.5571133',
        ELSDLEA: '',
        UNSDLEA: '20940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3799, 43.4995],
            [-93.1005, 43.4996],
            [-93.0654, 43.4996],
            [-93.0351, 43.4738],
            [-93.161, 43.3424],
            [-93.3696, 43.3571],
            [-93.3799, 43.4995],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921210',
        NAME: 'Northwood-Kensett Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418691905,
        AWATER: 248879,
        INTPTLAT: '+43.4231499',
        INTPTLON: '-093.2341684',
        ELSDLEA: '',
        UNSDLEA: '21210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5357, 42.5908],
            [-95.4021, 42.6126],
            [-95.3336, 42.561],
            [-95.3421, 42.4214],
            [-95.3616, 42.4144],
            [-95.4892, 42.4428],
            [-95.6241, 42.4071],
            [-95.6722, 42.5471],
            [-95.5357, 42.5908],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1912230',
        NAME: 'Galva-Holstein Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 449006043,
        AWATER: 57495,
        INTPTLAT: '+42.4946184',
        INTPTLON: '-095.5005102',
        ELSDLEA: '',
        UNSDLEA: '12230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3616, 42.4144],
            [-95.3421, 42.4214],
            [-95.1483, 42.3653],
            [-95.2072, 42.1821],
            [-95.2654, 42.1528],
            [-95.3303, 42.2334],
            [-95.3815, 42.3052],
            [-95.3616, 42.4144],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921600',
        NAME: 'Odebolt-Arthur Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 382913382,
        AWATER: 84486,
        INTPTLAT: '+42.3049078',
        INTPTLON: '-095.2721209',
        ELSDLEA: '',
        UNSDLEA: '21600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3336, 42.561],
            [-95.2697, 42.5718],
            [-95.1019, 42.5611],
            [-95.0011, 42.5239],
            [-95.086, 42.4744],
            [-95.0898, 42.3769],
            [-95.1483, 42.3653],
            [-95.3421, 42.4214],
            [-95.3336, 42.561],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1925380',
        NAME: 'Schaller-Crestland Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 433635400,
        AWATER: 980490,
        INTPTLAT: '+42.4860188',
        INTPTLON: '-095.1986586',
        ELSDLEA: '',
        UNSDLEA: '25380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6527, 42.2113],
            [-95.6401, 42.2112],
            [-95.6326, 42.2112],
            [-95.3621, 42.2326],
            [-95.3623, 42.1057],
            [-95.479, 42.0955],
            [-95.6711, 42.1322],
            [-95.6527, 42.2113],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1925410',
        NAME: 'Schleswig Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317566659,
        AWATER: 372106,
        INTPTLAT: '+42.1725523',
        INTPTLON: '-095.4934873',
        ELSDLEA: '',
        UNSDLEA: '25410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8333, 41.9494],
            [-91.5657, 41.9538],
            [-91.5593, 41.8713],
            [-91.6332, 41.8098],
            [-91.7427, 41.7823],
            [-91.8312, 41.8255],
            [-91.8333, 41.9494],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907860',
        NAME: 'College Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359159654,
        AWATER: 2829972,
        INTPTLAT: '+41.8854235',
        INTPTLON: '-091.7144338',
        ELSDLEA: '',
        UNSDLEA: '07860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6171, 41.7883],
            [-91.4637, 41.7146],
            [-91.4053, 41.617],
            [-91.4049, 41.5986],
            [-91.5268, 41.5986],
            [-91.523, 41.5398],
            [-91.5954, 41.5398],
            [-91.7123, 41.6],
            [-91.5986, 41.6715],
            [-91.6171, 41.7883],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914700',
        NAME: 'Iowa City Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 333686352,
        AWATER: 5836967,
        INTPTLAT: '+41.6474703',
        INTPTLON: '-091.5611275',
        ELSDLEA: '',
        UNSDLEA: '14700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0976, 40.8985],
            [-92.9355, 40.9018],
            [-92.8524, 40.9568],
            [-92.7073, 40.9595],
            [-92.6391, 40.8989],
            [-92.8202, 40.7906],
            [-92.9777, 40.8014],
            [-93.0306, 40.841],
            [-93.0976, 40.8269],
            [-93.0976, 40.8985],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1919710',
        NAME: 'Moravia Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 432174559,
        AWATER: 38506942,
        INTPTLAT: '+40.8820425',
        INTPTLON: '-092.8406097',
        ELSDLEA: '',
        UNSDLEA: '19710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0992, 41.1609],
            [-92.7595, 41.1613],
            [-92.7546, 41.0816],
            [-92.6399, 41.0808],
            [-92.7073, 40.9595],
            [-92.8524, 40.9568],
            [-92.9355, 40.9018],
            [-93.0976, 40.8985],
            [-93.0992, 41.1609],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903270',
        NAME: 'Albia Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 876040456,
        AWATER: 1127523,
        INTPTLAT: '+41.0332888',
        INTPTLON: '-092.9266677',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0306, 40.841],
            [-92.9777, 40.8014],
            [-92.8202, 40.7906],
            [-92.7918, 40.6347],
            [-92.7537, 40.589],
            [-92.9854, 40.5869],
            [-92.9823, 40.6962],
            [-93.0398, 40.7538],
            [-93.0306, 40.841],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906750',
        NAME: 'Centerville Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 453497387,
        AWATER: 7116740,
        INTPTLAT: '+40.7066418',
        INTPTLON: '-092.9122720',
        ELSDLEA: '',
        UNSDLEA: '06750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0886, 43.5001],
            [-93.8884, 43.4998],
            [-93.6972, 43.4997],
            [-93.666, 43.3713],
            [-93.7644, 43.2844],
            [-93.9417, 43.2697],
            [-94.0789, 43.3205],
            [-94.1186, 43.2772],
            [-94.1581, 43.3712],
            [-94.0888, 43.4001],
            [-94.0886, 43.5001],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1905750',
        NAME: 'North Iowa Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 775251254,
        AWATER: 787640,
        INTPTLAT: '+43.3929445',
        INTPTLON: '-093.9021374',
        ELSDLEA: '',
        UNSDLEA: '05750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3947, 43.5003],
            [-94.228, 43.5001],
            [-94.0886, 43.5001],
            [-94.0888, 43.4001],
            [-94.1581, 43.3712],
            [-94.1186, 43.2772],
            [-94.1282, 43.2555],
            [-94.2658, 43.2548],
            [-94.2758, 43.2547],
            [-94.3938, 43.3709],
            [-94.3947, 43.5003],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920830',
        NAME: 'North Kossuth Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 544447724,
        AWATER: 1722546,
        INTPTLAT: '+43.3927867',
        INTPTLON: '-094.2559682',
        ELSDLEA: '',
        UNSDLEA: '20830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1282, 43.2555],
            [-94.1186, 43.2772],
            [-94.0789, 43.3205],
            [-93.9417, 43.2697],
            [-93.9703, 43.1692],
            [-93.9704, 43.1331],
            [-94.0788, 43.1263],
            [-94.1282, 43.2555],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927900',
        NAME: 'Titonka Consolidated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235351767,
        AWATER: 164831,
        INTPTLAT: '+43.2201228',
        INTPTLON: '-094.0395099',
        ELSDLEA: '',
        UNSDLEA: '27900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.3249, 42.9153],
            [-94.1587, 42.9083],
            [-94.0897, 42.8486],
            [-94.1296, 42.811],
            [-94.2666, 42.8275],
            [-94.3322, 42.7979],
            [-94.4429, 42.8795],
            [-94.3249, 42.9153],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1928200',
        NAME: 'Twin Rivers Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 265843584,
        AWATER: 155762,
        INTPTLAT: '+42.8550930',
        INTPTLON: '-094.2592873',
        ELSDLEA: '',
        UNSDLEA: '28200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8613, 43.2947],
            [-95.7038, 43.2874],
            [-95.7425, 43.1716],
            [-95.664, 43.128],
            [-95.7232, 43.0623],
            [-95.8107, 43.0408],
            [-95.9604, 43.1422],
            [-96.0204, 43.237],
            [-95.8613, 43.2947],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1925980',
        NAME: 'Sheldon Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 489166197,
        AWATER: 153863,
        INTPTLAT: '+43.1897543',
        INTPTLON: '-095.8245919',
        ELSDLEA: '',
        UNSDLEA: '25980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1178, 43.2792],
            [-96.1163, 43.3534],
            [-95.9783, 43.5001],
            [-95.8317, 43.5],
            [-95.8613, 43.2947],
            [-96.0204, 43.237],
            [-96.1178, 43.2792],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1912480',
        NAME: 'George-Little Rock Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 456370516,
        AWATER: 48416,
        INTPTLAT: '+43.3670508',
        INTPTLON: '-095.9692819',
        ELSDLEA: '',
        UNSDLEA: '12480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8202, 40.7906],
            [-92.6391, 40.8989],
            [-92.5965, 40.8989],
            [-92.6194, 40.8634],
            [-92.638, 40.6204],
            [-92.6184, 40.5913],
            [-92.7146, 40.5894],
            [-92.7537, 40.589],
            [-92.7918, 40.6347],
            [-92.8202, 40.7906],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1919800',
        NAME: 'Moulton-Udell Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 386580345,
        AWATER: 1423994,
        INTPTLAT: '+40.7122876',
        INTPTLON: '-092.7011588',
        ELSDLEA: '',
        UNSDLEA: '19800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0976, 40.8269],
            [-93.0306, 40.841],
            [-93.0398, 40.7538],
            [-92.9823, 40.6962],
            [-92.9854, 40.5869],
            [-93.2889, 40.5808],
            [-93.3029, 40.6377],
            [-93.2122, 40.7176],
            [-93.2121, 40.8213],
            [-93.0976, 40.8269],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1925620',
        NAME: 'Seymour Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 552640792,
        AWATER: 3021672,
        INTPTLAT: '+40.6884710',
        INTPTLON: '-093.1280762',
        ELSDLEA: '',
        UNSDLEA: '25620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9712, 42.9078],
            [-93.8824, 42.8882],
            [-93.7543, 42.8487],
            [-93.6365, 42.775],
            [-93.5484, 42.717],
            [-93.6172, 42.6737],
            [-93.6275, 42.5866],
            [-93.6958, 42.5575],
            [-93.7449, 42.5865],
            [-93.844, 42.7243],
            [-94.0183, 42.7245],
            [-93.9517, 42.7931],
            [-93.9712, 42.9078],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907380',
        NAME: 'Clarion-Goldfield Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 666198921,
        AWATER: 4120892,
        INTPTLAT: '+42.7425713',
        INTPTLON: '-093.7844629',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0901, 42.6448],
            [-94.0183, 42.7245],
            [-93.844, 42.7243],
            [-93.7449, 42.5865],
            [-93.8287, 42.5575],
            [-93.9716, 42.5581],
            [-94.0899, 42.5726],
            [-94.0901, 42.6448],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1909990',
        NAME: 'Eagle Grove Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424161132,
        AWATER: 0,
        INTPTLAT: '+42.6361867',
        INTPTLON: '-093.9354205',
        ELSDLEA: '',
        UNSDLEA: '09990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5544, 43.2128],
            [-92.5335, 43.2129],
            [-92.199, 43.2209],
            [-92.1411, 43.1984],
            [-92.1607, 43.1409],
            [-92.1992, 43.0376],
            [-92.3037, 42.9727],
            [-92.3374, 42.9071],
            [-92.3768, 42.9071],
            [-92.3771, 42.9942],
            [-92.4955, 43.0522],
            [-92.5544, 43.2128],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920340',
        NAME: 'New Hampton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 706317763,
        AWATER: 229426,
        INTPTLAT: '+43.1156422',
        INTPTLON: '-092.3496714',
        ELSDLEA: '',
        UNSDLEA: '20340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1992, 43.0376],
            [-92.0815, 43.0304],
            [-92.0815, 42.9505],
            [-92.2502, 42.8983],
            [-92.3374, 42.9071],
            [-92.3037, 42.9727],
            [-92.1992, 43.0376],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1911970',
        NAME: 'Fredericksburg Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216140787,
        AWATER: 116002,
        INTPTLAT: '+42.9642538',
        INTPTLON: '-092.2000102',
        ELSDLEA: '',
        UNSDLEA: '11970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9657, 41.139],
            [-93.8653, 41.1284],
            [-93.884, 41.0742],
            [-93.8653, 40.8977],
            [-93.9208, 40.8682],
            [-94.0148, 40.8828],
            [-94.0257, 40.8971],
            [-94.0144, 41.1135],
            [-93.9657, 41.139],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920100',
        NAME: 'Murray Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 335749048,
        AWATER: 235809,
        INTPTLAT: '+41.0121112',
        INTPTLON: '-093.9470334',
        ELSDLEA: '',
        UNSDLEA: '20100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9963, 42.6577],
            [-92.889, 42.7009],
            [-92.7318, 42.657],
            [-92.7022, 42.6425],
            [-92.6776, 42.5353],
            [-92.8103, 42.4994],
            [-92.8788, 42.4995],
            [-92.9869, 42.5784],
            [-92.9963, 42.6577],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903750',
        NAME: 'Aplington-Parkersburg Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 424500544,
        AWATER: 95114,
        INTPTLAT: '+42.5898634',
        INTPTLON: '-092.8343861',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1832, 42.6294],
            [-93.1438, 42.7015],
            [-93.0315, 42.7092],
            [-92.9963, 42.6577],
            [-92.9869, 42.5784],
            [-92.8788, 42.4995],
            [-92.8103, 42.4994],
            [-92.8102, 42.4847],
            [-92.886, 42.354],
            [-92.9343, 42.3468],
            [-93.1284, 42.3901],
            [-93.1191, 42.471],
            [-93.1832, 42.6294],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903060',
        NAME: 'AGWSR Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 686017414,
        AWATER: 347853,
        INTPTLAT: '+42.5209307',
        INTPTLON: '-093.0171769',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2955, 42.819],
            [-93.0551, 42.8476],
            [-93.0253, 42.8766],
            [-92.9561, 42.8367],
            [-92.889, 42.7009],
            [-92.9963, 42.6577],
            [-93.0315, 42.7092],
            [-93.1438, 42.7015],
            [-93.1832, 42.6294],
            [-93.3404, 42.6439],
            [-93.3405, 42.655],
            [-93.3012, 42.7021],
            [-93.2955, 42.819],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913470',
        NAME: 'Hampton-Dumont Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 613746718,
        AWATER: 878838,
        INTPTLAT: '+42.7509788',
        INTPTLON: '-093.1177986',
        ELSDLEA: '',
        UNSDLEA: '13470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5482, 41.9455],
            [-90.3548, 41.8221],
            [-90.3745, 41.7343],
            [-90.5376, 41.7764],
            [-90.6701, 41.7636],
            [-90.7037, 41.9163],
            [-90.5482, 41.9455],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906810',
        NAME: 'Central Clinton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 447660681,
        AWATER: 649382,
        INTPTLAT: '+41.8472547',
        INTPTLON: '-090.5391488',
        ELSDLEA: '',
        UNSDLEA: '06810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5418, 41.6883],
            [-93.5027, 41.7462],
            [-93.4639, 41.7985],
            [-93.3095, 41.8202],
            [-93.3484, 41.6844],
            [-93.537, 41.6586],
            [-93.5418, 41.6883],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1905070',
        NAME: 'Bondurant-Farrar Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222675668,
        AWATER: 892456,
        INTPTLAT: '+41.7478866',
        INTPTLON: '-093.4117719',
        ELSDLEA: '',
        UNSDLEA: '05070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2009, 41.3501],
            [-95.1084, 41.505],
            [-95.0229, 41.5192],
            [-95.0181, 41.5336],
            [-94.8735, 41.501],
            [-94.892, 41.36],
            [-94.9558, 41.2554],
            [-95.0411, 41.3314],
            [-95.1651, 41.3388],
            [-95.2009, 41.3501],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903930',
        NAME: 'Atlantic Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 525611663,
        AWATER: 520771,
        INTPTLAT: '+41.4014660',
        INTPTLON: '-095.0134447',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9108, 41.2319],
            [-95.7563, 41.2591],
            [-95.7087, 41.16],
            [-95.7942, 41.1599],
            [-95.866, 41.1024],
            [-95.8419, 41.1763],
            [-95.9108, 41.2319],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1916680',
        NAME: 'Lewis Central Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163631722,
        AWATER: 7671396,
        INTPTLAT: '+41.2091850',
        INTPTLON: '-095.8037778',
        ELSDLEA: '',
        UNSDLEA: '16680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0725, 43.2554],
            [-95.0325, 43.3712],
            [-94.9641, 43.425],
            [-94.8657, 43.3065],
            [-94.6695, 43.2985],
            [-94.5901, 43.234],
            [-94.7185, 43.1691],
            [-94.7877, 43.1692],
            [-95.0118, 43.1412],
            [-95.0725, 43.2554],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1912810',
        NAME: 'Graettinger-Terril Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 579704057,
        AWATER: 9648114,
        INTPTLAT: '+43.2724995',
        INTPTLON: '-094.8714767',
        ELSDLEA: '',
        UNSDLEA: '12810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9138, 42.9677],
            [-94.8469, 42.9759],
            [-94.8163, 42.9613],
            [-94.7377, 42.8927],
            [-94.7573, 42.7825],
            [-94.8554, 42.7549],
            [-94.8867, 42.8139],
            [-95.063, 42.8143],
            [-95.0534, 42.9017],
            [-94.9531, 42.9097],
            [-94.9138, 42.9677],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1916420',
        NAME: 'Laurens-Marathon Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378296117,
        AWATER: 2629180,
        INTPTLAT: '+42.8775160',
        INTPTLON: '-094.8802990',
        ELSDLEA: '',
        UNSDLEA: '16420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4283, 43.218],
            [-93.366, 43.2276],
            [-93.2901, 43.2277],
            [-93.2609, 43.0526],
            [-93.4187, 43.0168],
            [-93.3888, 43.0676],
            [-93.4283, 43.218],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907620',
        NAME: 'Clear Lake Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224212773,
        AWATER: 9628782,
        INTPTLAT: '+43.1298807',
        INTPTLON: '-093.3473582',
        ELSDLEA: '',
        UNSDLEA: '07620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2901, 43.2277],
            [-93.1414, 43.2094],
            [-93.1224, 43.0829],
            [-93.1024, 43.0526],
            [-93.1123, 43.0526],
            [-93.1178, 43.0641],
            [-93.2609, 43.0526],
            [-93.2901, 43.2277],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918780',
        NAME: 'Mason City Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245754879,
        AWATER: 1158741,
        INTPTLAT: '+43.1454659',
        INTPTLON: '-093.2029375',
        ELSDLEA: '',
        UNSDLEA: '18780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5469, 43.1987],
            [-93.4283, 43.218],
            [-93.3888, 43.0676],
            [-93.4187, 43.0168],
            [-93.4978, 42.9953],
            [-93.5281, 43.0531],
            [-93.5469, 43.1987],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1929100',
        NAME: 'Ventura Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232062810,
        AWATER: 6686482,
        INTPTLAT: '+43.1096017',
        INTPTLON: '-093.4602235',
        ELSDLEA: '',
        UNSDLEA: '29100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0118, 43.1412],
            [-94.7877, 43.1692],
            [-94.7879, 43.0976],
            [-94.8469, 42.9759],
            [-94.9138, 42.9677],
            [-94.9138, 43.0032],
            [-94.9136, 43.0831],
            [-94.992, 43.1124],
            [-95.0118, 43.1412],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1925050',
        NAME: 'Ruthven-Ayrshire Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249964218,
        AWATER: 8950484,
        INTPTLAT: '+43.1050931',
        INTPTLON: '-094.8830027',
        ELSDLEA: '',
        UNSDLEA: '25050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5359, 42.6481],
            [-95.4661, 42.7343],
            [-95.4674, 42.8795],
            [-95.4276, 42.9099],
            [-95.3386, 42.8289],
            [-95.4021, 42.6126],
            [-95.5357, 42.5908],
            [-95.5359, 42.6481],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904020',
        NAME: 'Aurelia Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340565498,
        AWATER: 89345,
        INTPTLAT: '+42.7309125',
        INTPTLON: '-095.4358365',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3686, 42.9998],
            [-95.2305, 42.9681],
            [-95.0929, 42.9838],
            [-94.9138, 43.0032],
            [-94.9138, 42.9677],
            [-94.9531, 42.9097],
            [-95.0534, 42.9017],
            [-95.063, 42.8143],
            [-95.1904, 42.7634],
            [-95.2299, 42.8363],
            [-95.3386, 42.8289],
            [-95.4276, 42.9099],
            [-95.3686, 42.9998],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900023',
        NAME: 'Sioux Central Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 659247707,
        AWATER: 1596486,
        INTPTLAT: '+42.9022669',
        INTPTLON: '-095.1703380',
        ELSDLEA: '',
        UNSDLEA: '00023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2924, 42.58],
            [-91.253, 42.5584],
            [-91.2034, 42.5006],
            [-91.209, 42.3828],
            [-91.1699, 42.3395],
            [-91.1988, 42.3032],
            [-91.3979, 42.2964],
            [-91.4025, 42.2964],
            [-91.4128, 42.4416],
            [-91.2924, 42.58],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918540',
        NAME: 'Maquoketa Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 446573493,
        AWATER: 1893805,
        INTPTLAT: '+42.4093866',
        INTPTLON: '-091.3006127',
        ELSDLEA: '',
        UNSDLEA: '18540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2514, 42.4119],
            [-93.1191, 42.471],
            [-93.1284, 42.3901],
            [-92.9343, 42.3468],
            [-92.9392, 42.3324],
            [-93.0761, 42.2904],
            [-93.152, 42.2087],
            [-93.1956, 42.2034],
            [-93.2317, 42.2089],
            [-93.2201, 42.321],
            [-93.2514, 42.4119],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910690',
        NAME: 'Eldora-New Providence Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 363838986,
        AWATER: 809607,
        INTPTLAT: '+42.3317333',
        INTPTLON: '-093.1348995',
        ELSDLEA: '',
        UNSDLEA: '10690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3965, 42.6301],
            [-93.3404, 42.6439],
            [-93.1832, 42.6294],
            [-93.1191, 42.471],
            [-93.2514, 42.4119],
            [-93.3468, 42.4049],
            [-93.3214, 42.4705],
            [-93.3309, 42.6095],
            [-93.3965, 42.6301],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914730',
        NAME: 'Iowa Falls Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 348808067,
        AWATER: 291518,
        INTPTLAT: '+42.5310813',
        INTPTLON: '-093.2490733',
        ELSDLEA: '',
        UNSDLEA: '14730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0962, 42.6197],
            [-95.958, 42.6851],
            [-95.8603, 42.6998],
            [-95.794, 42.5824],
            [-95.8594, 42.4893],
            [-95.9478, 42.4824],
            [-95.9677, 42.5329],
            [-96.0959, 42.5613],
            [-96.1646, 42.5758],
            [-96.0962, 42.6197],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1915750',
        NAME: 'Kingsley-Pierson Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347290232,
        AWATER: 19083,
        INTPTLAT: '+42.5869118',
        INTPTLON: '-095.9485207',
        ELSDLEA: '',
        UNSDLEA: '15750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3817, 42.9093],
            [-96.2533, 42.9094],
            [-96.0168, 42.9101],
            [-96.0257, 42.8517],
            [-96.1163, 42.7497],
            [-96.1352, 42.641],
            [-96.3604, 42.7139],
            [-96.4389, 42.7001],
            [-96.3705, 42.8288],
            [-96.3817, 42.9093],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1916530',
        NAME: 'Le Mars Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 686275915,
        AWATER: 22701,
        INTPTLAT: '+42.8050952',
        INTPTLON: '-096.2270382',
        ELSDLEA: '',
        UNSDLEA: '16530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8594, 42.9091],
            [-95.6228, 42.8882],
            [-95.5735, 42.7849],
            [-95.6625, 42.7063],
            [-95.7209, 42.6995],
            [-95.7415, 42.5898],
            [-95.794, 42.5824],
            [-95.8603, 42.6998],
            [-95.8982, 42.815],
            [-95.8594, 42.9091],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918630',
        NAME: 'Marcus-Meriden-Cleghorn Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 606258982,
        AWATER: 59641,
        INTPTLAT: '+42.7715179',
        INTPTLON: '-095.7507157',
        ELSDLEA: '',
        UNSDLEA: '18630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1352, 42.641],
            [-96.1163, 42.7497],
            [-96.0257, 42.8517],
            [-96.0168, 42.9101],
            [-95.8594, 42.9091],
            [-95.8982, 42.815],
            [-95.8603, 42.6998],
            [-95.958, 42.6851],
            [-96.0962, 42.6197],
            [-96.1352, 42.641],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1924120',
        NAME: 'Remsen-Union Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 461290118,
        AWATER: 37884,
        INTPTLAT: '+42.7726438',
        INTPTLON: '-095.9870260',
        ELSDLEA: '',
        UNSDLEA: '24120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4988, 42.561],
            [-96.4495, 42.5911],
            [-96.3121, 42.5617],
            [-96.3021, 42.467],
            [-96.3806, 42.4469],
            [-96.4455, 42.4906],
            [-96.4988, 42.561],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926400',
        NAME: 'Sioux City Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155498244,
        AWATER: 2463295,
        INTPTLAT: '+42.5189864',
        INTPTLON: '-096.3897089',
        ELSDLEA: '',
        UNSDLEA: '26400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5385, 42.9088],
            [-96.5137, 43.047],
            [-96.4366, 43.1198],
            [-96.4664, 43.1624],
            [-96.3922, 43.1134],
            [-96.2834, 43.0045],
            [-96.2337, 43.0047],
            [-96.2533, 42.9094],
            [-96.3817, 42.9093],
            [-96.5413, 42.9024],
            [-96.5385, 42.9088],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931290',
        NAME: 'West Sioux Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 401915301,
        AWATER: 2289832,
        INTPTLAT: '+42.9931517',
        INTPTLON: '-096.3963611',
        ELSDLEA: '',
        UNSDLEA: '31290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4426, 43.0829],
            [-94.286, 43.156],
            [-94.2658, 43.2548],
            [-94.1282, 43.2555],
            [-94.0788, 43.1263],
            [-94.04, 43.0525],
            [-94.1681, 42.9878],
            [-94.1587, 42.9083],
            [-94.3249, 42.9153],
            [-94.3247, 42.9951],
            [-94.4327, 43.0095],
            [-94.4426, 43.0829],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903360',
        NAME: 'Algona Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 731332330,
        AWATER: 1807706,
        INTPTLAT: '+43.0763451',
        INTPTLON: '-094.2317459',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.9378, 41.3908],
            [-95.9306, 41.4192],
            [-95.8409, 41.4198],
            [-95.8413, 41.3319],
            [-95.7421, 41.2824],
            [-95.7563, 41.2591],
            [-95.9108, 41.2319],
            [-95.9378, 41.3908],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908220',
        NAME: 'Council Bluffs Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187402849,
        AWATER: 4546327,
        INTPTLAT: '+41.3228705',
        INTPTLON: '-095.8685832',
        ELSDLEA: '',
        UNSDLEA: '08220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8409, 41.4198],
            [-95.824, 41.4191],
            [-95.6695, 41.4413],
            [-95.5273, 41.3904],
            [-95.5748, 41.3326],
            [-95.7421, 41.2824],
            [-95.8413, 41.3319],
            [-95.8409, 41.4198],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1928230',
        NAME: 'Underwood Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322708761,
        AWATER: 937500,
        INTPTLAT: '+41.3646459',
        INTPTLON: '-095.7118673',
        ELSDLEA: '',
        UNSDLEA: '28230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5748, 41.3326],
            [-95.5273, 41.3904],
            [-95.5072, 41.3902],
            [-95.27, 41.354],
            [-95.2009, 41.3501],
            [-95.1651, 41.3388],
            [-95.2698, 41.2959],
            [-95.3058, 41.1597],
            [-95.3843, 41.16],
            [-95.5564, 41.1601],
            [-95.527, 41.2326],
            [-95.5748, 41.3326],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900027',
        NAME: 'Riverside Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 595347664,
        AWATER: 3619091,
        INTPTLAT: '+41.2817276',
        INTPTLON: '-095.4080869',
        ELSDLEA: '',
        UNSDLEA: '00027',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8374, 40.6742],
            [-95.7938, 40.7061],
            [-95.5783, 40.687],
            [-95.4608, 40.5815],
            [-95.4992, 40.5819],
            [-95.7656, 40.5852],
            [-95.8374, 40.6742],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913440',
        NAME: 'Hamburg Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260167687,
        AWATER: 3159681,
        INTPTLAT: '+40.6261546',
        INTPTLON: '-095.6612496',
        ELSDLEA: '',
        UNSDLEA: '13440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8394, 40.9441],
            [-95.7897, 40.9124],
            [-95.671, 40.9873],
            [-95.6143, 40.9016],
            [-95.4993, 40.9016],
            [-95.5278, 40.815],
            [-95.8449, 40.8146],
            [-95.8481, 40.8573],
            [-95.8394, 40.9441],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1912120',
        NAME: 'Fremont-Mills Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374667490,
        AWATER: 4627948,
        INTPTLAT: '+40.8560851',
        INTPTLON: '-095.6805274',
        ELSDLEA: '',
        UNSDLEA: '12120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2165, 43.2575],
            [-96.1178, 43.2792],
            [-96.0204, 43.237],
            [-95.9604, 43.1422],
            [-96.0473, 43.1198],
            [-96.2354, 43.1597],
            [-96.2165, 43.2575],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1905190',
        NAME: 'Boyden-Hull Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 281208074,
        AWATER: 0,
        INTPTLAT: '+43.1979463',
        INTPTLON: '-096.0867299',
        ELSDLEA: '',
        UNSDLEA: '05190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6005, 43.4531],
            [-96.5992, 43.5005],
            [-96.4533, 43.5004],
            [-96.2737, 43.5003],
            [-96.2957, 43.2867],
            [-96.4906, 43.236],
            [-96.555, 43.26],
            [-96.5217, 43.3868],
            [-96.5995, 43.4455],
            [-96.6005, 43.4531],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931020',
        NAME: 'West Lyon Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 640116609,
        AWATER: 282485,
        INTPTLAT: '+43.3793692',
        INTPTLON: '-096.4194647',
        ELSDLEA: '',
        UNSDLEA: '31020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3922, 43.1134],
            [-96.2836, 43.0986],
            [-96.2354, 43.1597],
            [-96.0473, 43.1198],
            [-96.1104, 43.0335],
            [-96.2337, 43.0047],
            [-96.2834, 43.0045],
            [-96.3922, 43.1134],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926370',
        NAME: 'Sioux Center Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278002445,
        AWATER: 110322,
        INTPTLAT: '+43.0769758',
        INTPTLON: '-096.2048361',
        ELSDLEA: '',
        UNSDLEA: '26370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.555, 43.26],
            [-96.4906, 43.236],
            [-96.2957, 43.2867],
            [-96.2165, 43.2575],
            [-96.2354, 43.1597],
            [-96.2836, 43.0986],
            [-96.3922, 43.1134],
            [-96.4664, 43.1624],
            [-96.4691, 43.1847],
            [-96.555, 43.26],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1924660',
        NAME: 'Rock Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322237043,
        AWATER: 266010,
        INTPTLAT: '+43.1952534',
        INTPTLON: '-096.3567959',
        ELSDLEA: '',
        UNSDLEA: '24660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4829, 41.9208],
            [-93.3574, 41.9502],
            [-93.2366, 41.95],
            [-93.2177, 41.8664],
            [-93.2901, 41.8202],
            [-93.3095, 41.8202],
            [-93.4639, 41.7985],
            [-93.4927, 41.8631],
            [-93.4829, 41.9208],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907900',
        NAME: 'Collins-Maxwell Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287384603,
        AWATER: 536189,
        INTPTLAT: '+41.8871250',
        INTPTLON: '-093.3634423',
        ELSDLEA: '',
        UNSDLEA: '07900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4537, 42.2093],
            [-93.2317, 42.2089],
            [-93.1956, 42.2034],
            [-93.2315, 42.1224],
            [-93.2366, 41.95],
            [-93.3574, 41.9502],
            [-93.3863, 42.0925],
            [-93.463, 42.1232],
            [-93.4537, 42.2093],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907920',
        NAME: 'Colo-Nesco Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 444847201,
        AWATER: 576501,
        INTPTLAT: '+42.1149728',
        INTPTLON: '-093.3193472',
        ELSDLEA: '',
        UNSDLEA: '07920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7375, 41.8634],
            [-93.4927, 41.8631],
            [-93.4639, 41.7985],
            [-93.5027, 41.7462],
            [-93.5413, 41.7768],
            [-93.7098, 41.746],
            [-93.7561, 41.7768],
            [-93.7761, 41.7956],
            [-93.7375, 41.8634],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920910',
        NAME: 'North Polk Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241544697,
        AWATER: 12731564,
        INTPTLAT: '+41.8111927',
        INTPTLON: '-093.6207599',
        ELSDLEA: '',
        UNSDLEA: '20910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1956, 42.2034],
            [-93.152, 42.2087],
            [-93.05, 42.1512],
            [-93.0196, 42.0709],
            [-93.0585, 41.9753],
            [-93.058, 41.8626],
            [-93.2177, 41.8664],
            [-93.2366, 41.95],
            [-93.2315, 42.1224],
            [-93.1956, 42.2034],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931080',
        NAME: 'West Marshall Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 498931799,
        AWATER: 860312,
        INTPTLAT: '+42.0249840',
        INTPTLON: '-093.1345766',
        ELSDLEA: '',
        UNSDLEA: '31080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8468, 41.6881],
            [-92.6497, 41.6018],
            [-92.6421, 41.5381],
            [-92.7954, 41.5018],
            [-92.968, 41.5373],
            [-92.9961, 41.588],
            [-92.9416, 41.6667],
            [-92.8468, 41.6881],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918030',
        NAME: 'Lynnville-Sully Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366817368,
        AWATER: 317288,
        INTPTLAT: '+41.5879149',
        INTPTLON: '-092.8209178',
        ELSDLEA: '',
        UNSDLEA: '18030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8708, 41.2993],
            [-92.7181, 41.3354],
            [-92.7183, 41.3789],
            [-92.5777, 41.3358],
            [-92.4598, 41.4085],
            [-92.4112, 41.3361],
            [-92.4108, 41.2715],
            [-92.5545, 41.2193],
            [-92.755, 41.2048],
            [-92.8708, 41.2993],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921870',
        NAME: 'Oskaloosa Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 470590251,
        AWATER: 3399632,
        INTPTLAT: '+41.2943338',
        INTPTLON: '-092.6264335',
        ELSDLEA: '',
        UNSDLEA: '21870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.6625, 42.7063],
            [-95.5735, 42.7849],
            [-95.6228, 42.8882],
            [-95.4674, 42.8795],
            [-95.4661, 42.7343],
            [-95.5359, 42.6481],
            [-95.6625, 42.7063],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907170',
        NAME: 'Cherokee Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302064818,
        AWATER: 155666,
        INTPTLAT: '+42.7920841',
        INTPTLON: '-095.5554406',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5335, 42.354],
            [-94.4955, 42.4354],
            [-94.3977, 42.4733],
            [-94.2465, 42.4728],
            [-94.1646, 42.3981],
            [-94.1933, 42.2823],
            [-94.1648, 42.1807],
            [-94.2036, 42.2099],
            [-94.4364, 42.2093],
            [-94.4365, 42.2671],
            [-94.5335, 42.354],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900028',
        NAME: 'Prairie Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 716818339,
        AWATER: 60233,
        INTPTLAT: '+42.3471777',
        INTPTLON: '-094.3300703',
        ELSDLEA: '',
        UNSDLEA: '00028',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9716, 42.5581],
            [-93.8287, 42.5575],
            [-93.7449, 42.5865],
            [-93.6958, 42.5575],
            [-93.733, 42.3613],
            [-93.865, 42.3504],
            [-93.9614, 42.3767],
            [-94.0251, 42.4194],
            [-93.9716, 42.4787],
            [-93.9716, 42.5581],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930630',
        NAME: 'Webster City Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 513095865,
        AWATER: 449045,
        INTPTLAT: '+42.4645193',
        INTPTLON: '-093.8470518',
        ELSDLEA: '',
        UNSDLEA: '30630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0211, 42.3504],
            [-91.9634, 42.4678],
            [-91.964, 42.5549],
            [-91.7966, 42.5708],
            [-91.7861, 42.4683],
            [-91.8309, 42.4094],
            [-91.7842, 42.3417],
            [-91.8894, 42.3054],
            [-92.0625, 42.2973],
            [-92.0211, 42.3504],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914580',
        NAME: 'Independence Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 442283619,
        AWATER: 2765155,
        INTPTLAT: '+42.4339836',
        INTPTLON: '-091.8979121',
        ELSDLEA: '',
        UNSDLEA: '14580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2906, 42.1293],
            [-94.1755, 42.1516],
            [-94.1447, 42.1],
            [-94.1257, 41.9431],
            [-94.1732, 41.8633],
            [-94.2503, 41.8631],
            [-94.2803, 41.892],
            [-94.3107, 41.9646],
            [-94.2906, 42.1293],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910200',
        NAME: 'East Greene Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358096844,
        AWATER: 646575,
        INTPTLAT: '+42.0110184',
        INTPTLON: '-094.2281631',
        ELSDLEA: '',
        UNSDLEA: '10200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.2527, 42.3804],
              [-96.1733, 42.3512],
              [-96.1643, 42.322],
              [-96.2527, 42.3804],
            ],
          ],
          [
            [
              [-96.4113, 42.3863],
              [-96.3806, 42.4469],
              [-96.3021, 42.467],
              [-96.2527, 42.3804],
              [-96.3687, 42.3047],
              [-96.4113, 42.3863],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1925590',
        NAME: 'Sergeant Bluff-Luton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164648999,
        AWATER: 2832848,
        INTPTLAT: '+42.3801263',
        INTPTLON: '-096.3161112',
        ELSDLEA: '',
        UNSDLEA: '25590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1733, 42.3512],
            [-96.0959, 42.5613],
            [-95.9677, 42.5329],
            [-95.9478, 42.4824],
            [-95.9415, 42.466],
            [-95.9902, 42.4438],
            [-95.9711, 42.2985],
            [-96.1643, 42.322],
            [-96.1733, 42.3512],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931950',
        NAME: 'Woodbury Central Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 348236876,
        AWATER: 602218,
        INTPTLAT: '+42.4278712',
        INTPTLON: '-096.0649148',
        ELSDLEA: '',
        UNSDLEA: '31950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2396, 42.7055],
            [-95.073, 42.6909],
            [-95.1019, 42.5611],
            [-95.2697, 42.5718],
            [-95.2396, 42.7055],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927390',
        NAME: 'Storm Lake Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216831017,
        AWATER: 12622785,
        INTPTLAT: '+42.6272736',
        INTPTLON: '-095.1725507',
        ELSDLEA: '',
        UNSDLEA: '27390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6728, 42.9362],
            [-92.5935, 43.0234],
            [-92.4955, 43.0522],
            [-92.3771, 42.9942],
            [-92.3768, 42.9071],
            [-92.4075, 42.8552],
            [-92.4461, 42.8101],
            [-92.5741, 42.8026],
            [-92.6434, 42.8764],
            [-92.6728, 42.9362],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920190',
        NAME: 'Nashua-Plainfield Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 467404789,
        AWATER: 4304198,
        INTPTLAT: '+42.9296223',
        INTPTLON: '-092.5065741',
        ELSDLEA: '',
        UNSDLEA: '20190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6726, 43.4287],
            [-92.6727, 43.3275],
            [-92.594, 43.2989],
            [-92.5942, 43.2128],
            [-92.6928, 43.2125],
            [-92.8101, 43.1495],
            [-92.9346, 43.2202],
            [-93.0241, 43.2631],
            [-92.7997, 43.4002],
            [-92.6726, 43.4287],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921840',
        NAME: 'Osage Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 577895600,
        AWATER: 238040,
        INTPTLAT: '+43.2768791',
        INTPTLON: '-092.7815306',
        ELSDLEA: '',
        UNSDLEA: '21840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4364, 42.2093],
            [-94.2036, 42.2099],
            [-94.1648, 42.1807],
            [-94.1755, 42.1516],
            [-94.2906, 42.1293],
            [-94.3007, 42.1739],
            [-94.4266, 42.0926],
            [-94.5958, 42.1229],
            [-94.629, 42.1302],
            [-94.5228, 42.2093],
            [-94.4364, 42.2093],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1922380',
        NAME: 'Paton-Churdan Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316096528,
        AWATER: 132394,
        INTPTLAT: '+42.1587980',
        INTPTLON: '-094.4068232',
        ELSDLEA: '',
        UNSDLEA: '22380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2503, 41.8631],
            [-94.1732, 41.8633],
            [-94.1257, 41.9431],
            [-93.9948, 41.9213],
            [-93.9893, 41.806],
            [-94.0796, 41.7769],
            [-94.0964, 41.7174],
            [-94.2219, 41.7173],
            [-94.2807, 41.7317],
            [-94.2503, 41.8631],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1922530',
        NAME: 'Perry Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 397886630,
        AWATER: 1794383,
        INTPTLAT: '+41.8193148',
        INTPTLON: '-094.1378187',
        ELSDLEA: '',
        UNSDLEA: '22530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.073, 42.6909],
            [-94.8858, 42.7338],
            [-94.7963, 42.696],
            [-94.7651, 42.5595],
            [-94.8061, 42.5172],
            [-94.9145, 42.5178],
            [-95.0011, 42.5239],
            [-95.1019, 42.5611],
            [-95.073, 42.6909],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920580',
        NAME: 'Newell-Fonda Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 479775119,
        AWATER: 1646941,
        INTPTLAT: '+42.6111117',
        INTPTLON: '-094.9250506',
        ELSDLEA: '',
        UNSDLEA: '20580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0815, 43.0304],
            [-91.9036, 43.0159],
            [-91.8544, 43.0819],
            [-91.7227, 43.0817],
            [-91.7159, 42.8062],
            [-91.7261, 42.8027],
            [-91.8446, 42.8129],
            [-91.8351, 42.88],
            [-91.9635, 42.8912],
            [-92.0815, 42.9505],
            [-92.0815, 43.0304],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900006',
        NAME: 'North Fayette Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 520461224,
        AWATER: 712568,
        INTPTLAT: '+42.9564824',
        INTPTLON: '-091.8635988',
        ELSDLEA: '',
        UNSDLEA: '00006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8333, 42.1762],
            [-91.7649, 42.1248],
            [-91.6582, 42.0913],
            [-91.6188, 42.0206],
            [-91.5992, 42.0097],
            [-91.5307, 41.9905],
            [-91.5657, 41.9538],
            [-91.8333, 41.9494],
            [-91.8353, 42.037],
            [-91.8333, 42.1762],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906540',
        NAME: 'Cedar Rapids Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309457482,
        AWATER: 9196662,
        INTPTLAT: '+42.0180745',
        INTPTLON: '-091.7226719',
        ELSDLEA: '',
        UNSDLEA: '06540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5211, 42.1336],
            [-91.4207, 42.1474],
            [-91.3749, 42.0164],
            [-91.4191, 41.9817],
            [-91.5111, 41.9904],
            [-91.5211, 42.1336],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927060',
        NAME: 'Springville Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151381967,
        AWATER: 79917,
        INTPTLAT: '+42.0706634',
        INTPTLON: '-091.4545994',
        ELSDLEA: '',
        UNSDLEA: '27060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4045, 43.5008],
            [-91.2177, 43.5005],
            [-91.2148, 43.3659],
            [-91.121, 43.3213],
            [-91.0975, 43.2145],
            [-91.3195, 43.3123],
            [-91.3832, 43.3705],
            [-91.4045, 43.5008],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910410',
        NAME: 'Eastern Allamakee Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 377457314,
        AWATER: 37561442,
        INTPTLAT: '+43.3739113',
        INTPTLON: '-091.2615427',
        ELSDLEA: '',
        UNSDLEA: '10410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.464, 43.1359],
            [-91.3493, 43.1388],
            [-91.1772, 43.0802],
            [-91.1568, 42.9882],
            [-91.1439, 42.9207],
            [-91.2506, 42.9645],
            [-91.3341, 42.9501],
            [-91.4685, 42.957],
            [-91.464, 43.1359],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918120',
        NAME: 'MFL MarMac Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 456686609,
        AWATER: 6329323,
        INTPTLAT: '+43.0306972',
        INTPTLON: '-091.3202527',
        ELSDLEA: '',
        UNSDLEA: '18120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.968, 41.5373],
            [-92.7954, 41.5018],
            [-92.7183, 41.3789],
            [-92.7181, 41.3354],
            [-92.8708, 41.2993],
            [-92.8883, 41.3169],
            [-92.9857, 41.3421],
            [-93.0919, 41.4248],
            [-93.0683, 41.4871],
            [-92.968, 41.5373],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1922470',
        NAME: 'Pella Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 486838615,
        AWATER: 14701473,
        INTPTLAT: '+41.4185522',
        INTPTLON: '-092.9034702',
        ELSDLEA: '',
        UNSDLEA: '22470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8883, 41.3169],
            [-92.8708, 41.2993],
            [-92.755, 41.2048],
            [-92.7595, 41.1613],
            [-93.0992, 41.1609],
            [-93.038, 41.2483],
            [-92.8883, 41.3169],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1928170',
        NAME: 'Twin Cedars Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328866654,
        AWATER: 1031291,
        INTPTLAT: '+41.2150340',
        INTPTLON: '-092.9282263',
        ELSDLEA: '',
        UNSDLEA: '28170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0196, 42.0709],
            [-93.05, 42.1512],
            [-92.8939, 42.1306],
            [-92.844, 42.0496],
            [-92.8833, 41.9709],
            [-93.0192, 41.8625],
            [-93.0497, 41.8626],
            [-93.058, 41.8626],
            [-93.0585, 41.9753],
            [-93.0196, 42.0709],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918720',
        NAME: 'Marshalltown Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365369246,
        AWATER: 642821,
        INTPTLAT: '+42.0302887',
        INTPTLON: '-092.9650940',
        ELSDLEA: '',
        UNSDLEA: '18720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7146, 43.031],
            [-93.5281, 43.0531],
            [-93.4978, 42.9953],
            [-93.5577, 42.9299],
            [-93.4986, 42.8573],
            [-93.4865, 42.7385],
            [-93.5484, 42.717],
            [-93.6365, 42.775],
            [-93.7543, 42.8487],
            [-93.6847, 42.9085],
            [-93.7146, 43.031],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904680',
        NAME: 'Belmond-Klemme Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 514255839,
        AWATER: 675797,
        INTPTLAT: '+42.8923122',
        INTPTLON: '-093.6082337',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9704, 43.1331],
            [-93.9703, 43.1692],
            [-93.7792, 43.1695],
            [-93.7152, 43.1345],
            [-93.7146, 43.031],
            [-93.6847, 42.9085],
            [-93.7543, 42.8487],
            [-93.8824, 42.8882],
            [-93.8618, 43],
            [-93.9704, 43.1331],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1905430',
        NAME: 'West Hancock Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 557205255,
        AWATER: 3435889,
        INTPTLAT: '+43.0302239',
        INTPTLON: '-093.8183101',
        ELSDLEA: '',
        UNSDLEA: '05430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2654, 42.1528],
            [-95.2072, 42.1821],
            [-95.0728, 42.1603],
            [-94.9755, 42.115],
            [-95.0248, 42.0119],
            [-95.1025, 41.9797],
            [-95.2376, 41.9948],
            [-95.2954, 42.0592],
            [-95.2654, 42.1528],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903780',
        NAME: 'Ar-We-Va Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 421097062,
        AWATER: 211131,
        INTPTLAT: '+42.0828465',
        INTPTLON: '-095.1376646',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1483, 42.3653],
            [-95.0898, 42.3769],
            [-95.086, 42.4744],
            [-95.0011, 42.5239],
            [-94.9145, 42.5178],
            [-94.9352, 42.3851],
            [-94.8576, 42.3482],
            [-94.8584, 42.2097],
            [-95.0335, 42.2103],
            [-95.0728, 42.1603],
            [-95.2072, 42.1821],
            [-95.1483, 42.3653],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1929580',
        NAME: 'East Sac County Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 701220563,
        AWATER: 7289773,
        INTPTLAT: '+42.3212277',
        INTPTLON: '-095.0263912',
        ELSDLEA: '',
        UNSDLEA: '29580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.698, 41.4104],
            [-93.6209, 41.479],
            [-93.5579, 41.4971],
            [-93.5584, 41.4531],
            [-93.4002, 41.4015],
            [-93.4003, 41.3926],
            [-93.3959, 41.3491],
            [-93.5631, 41.2487],
            [-93.5957, 41.1908],
            [-93.6735, 41.2491],
            [-93.6735, 41.3075],
            [-93.698, 41.4104],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914640',
        NAME: 'Indianola Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 414299021,
        AWATER: 2690142,
        INTPTLAT: '+41.3573613',
        INTPTLON: '-093.5671294',
        ELSDLEA: '',
        UNSDLEA: '14640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7906, 41.465],
            [-93.7906, 41.5119],
            [-93.6981, 41.512],
            [-93.6209, 41.479],
            [-93.698, 41.4104],
            [-93.7906, 41.465],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921240',
        NAME: 'Norwalk Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115879390,
        AWATER: 1486899,
        INTPTLAT: '+41.4671154',
        INTPTLON: '-093.7017687',
        ELSDLEA: '',
        UNSDLEA: '21240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9357, 41.7683],
            [-93.8145, 41.7175],
            [-93.7716, 41.644],
            [-93.8144, 41.6511],
            [-93.9504, 41.6222],
            [-94.0477, 41.7138],
            [-93.9357, 41.7683],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908520',
        NAME: 'Dallas Center-Grimes Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204874316,
        AWATER: 378239,
        INTPTLAT: '+41.6954569',
        INTPTLON: '-093.9211783',
        ELSDLEA: '',
        UNSDLEA: '08520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8286, 41.5232],
            [-93.8143, 41.6221],
            [-93.7122, 41.6221],
            [-93.6981, 41.512],
            [-93.7906, 41.5119],
            [-93.8286, 41.5087],
            [-93.8286, 41.5232],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930930',
        NAME: 'West Des Moines Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92157515,
        AWATER: 2957910,
        INTPTLAT: '+41.5669593',
        INTPTLON: '-093.7532974',
        ELSDLEA: '',
        UNSDLEA: '30930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9948, 41.9213],
            [-93.8832, 41.9505],
            [-93.8691, 41.9214],
            [-93.8691, 41.8685],
            [-93.7761, 41.7956],
            [-93.7561, 41.7768],
            [-93.8145, 41.7175],
            [-93.9357, 41.7683],
            [-93.9893, 41.806],
            [-93.9948, 41.9213],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1932010',
        NAME: 'Woodward-Granger Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279398221,
        AWATER: 3685612,
        INTPTLAT: '+41.8279049',
        INTPTLON: '-093.8976271',
        ELSDLEA: '',
        UNSDLEA: '32010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7679, 42.2772],
            [-92.6922, 42.3113],
            [-92.661, 42.3842],
            [-92.5339, 42.4134],
            [-92.4651, 42.2972],
            [-92.5618, 42.2688],
            [-92.5908, 42.1447],
            [-92.5909, 42.1379],
            [-92.7668, 42.1235],
            [-92.7865, 42.1668],
            [-92.7679, 42.2772],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1912660',
        NAME: 'Gladbrook-Reinbeck Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 490292808,
        AWATER: 529844,
        INTPTLAT: '+42.2731225',
        INTPTLON: '-092.6399879',
        ELSDLEA: '',
        UNSDLEA: '12660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0823, 41.5807],
            [-95.9464, 41.5901],
            [-95.906, 41.6573],
            [-95.7749, 41.5427],
            [-95.824, 41.4191],
            [-95.8409, 41.4198],
            [-95.9306, 41.4192],
            [-96.0166, 41.4819],
            [-96.0823, 41.5807],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1919440',
        NAME: 'Missouri Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357857384,
        AWATER: 6725106,
        INTPTLAT: '+41.5315875',
        INTPTLON: '-095.9090408',
        ELSDLEA: '',
        UNSDLEA: '19440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2779, 42.058],
            [-95.9821, 42.0945],
            [-95.8469, 42.0301],
            [-95.8375, 41.9574],
            [-95.9614, 41.8635],
            [-96.1091, 41.836],
            [-96.1621, 41.903],
            [-96.1292, 41.9651],
            [-96.2071, 42.0091],
            [-96.2779, 42.058],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931110',
        NAME: 'West Monona Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 592083484,
        AWATER: 6809672,
        INTPTLAT: '+41.9779445',
        INTPTLON: '-096.0410237',
        ELSDLEA: '',
        UNSDLEA: '31110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.356, 42.0211],
            [-92.3282, 42.0288],
            [-92.3373, 41.9927],
            [-92.1819, 41.9494],
            [-92.1811, 41.8626],
            [-92.2555, 41.8048],
            [-92.3985, 41.8336],
            [-92.356, 42.0211],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904620',
        NAME: 'Belle Plaine Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271800474,
        AWATER: 523931,
        INTPTLAT: '+41.9032144',
        INTPTLON: '-092.2826057',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5331, 41.8622],
            [-92.4163, 41.8257],
            [-92.2995, 41.6031],
            [-92.2995, 41.6015],
            [-92.5131, 41.6811],
            [-92.619, 41.6878],
            [-92.5713, 41.7172],
            [-92.5331, 41.8622],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1905490',
        NAME: 'Brooklyn-Guernsey-Malcom Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 384766732,
        AWATER: 611173,
        INTPTLAT: '+41.7323357',
        INTPTLON: '-092.4527348',
        ELSDLEA: '',
        UNSDLEA: '05490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4163, 41.8257],
            [-92.3985, 41.8336],
            [-92.2555, 41.8048],
            [-92.1728, 41.696],
            [-92.1827, 41.63],
            [-92.2995, 41.6031],
            [-92.4163, 41.8257],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913380',
        NAME: 'H-L-V Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 327984016,
        AWATER: 149801,
        INTPTLAT: '+41.7255041',
        INTPTLON: '-092.2670315',
        ELSDLEA: '',
        UNSDLEA: '13380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.434, 40.8993],
            [-94.3573, 40.8992],
            [-94.2625, 40.8694],
            [-94.3002, 40.74],
            [-94.4098, 40.7547],
            [-94.4345, 40.8562],
            [-94.434, 40.8993],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1909060',
        NAME: 'Diagonal Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213048660,
        AWATER: 874795,
        INTPTLAT: '+40.8214907',
        INTPTLON: '-094.3496753',
        ELSDLEA: '',
        UNSDLEA: '09060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6108, 43.5007],
            [-91.5211, 43.5006],
            [-91.4045, 43.5008],
            [-91.3832, 43.3705],
            [-91.3195, 43.3123],
            [-91.0975, 43.2145],
            [-91.1415, 43.163],
            [-91.1772, 43.0802],
            [-91.3493, 43.1388],
            [-91.464, 43.1359],
            [-91.5174, 43.2079],
            [-91.624, 43.2189],
            [-91.6109, 43.3421],
            [-91.6108, 43.5007],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903390',
        NAME: 'Allamakee Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1082219120,
        AWATER: 13434090,
        INTPTLAT: '+43.2848743',
        INTPTLON: '-091.4194828',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9435, 43.349],
            [-91.8744, 43.3456],
            [-91.8695, 43.3564],
            [-91.8433, 43.3275],
            [-91.6207, 43.4031],
            [-91.6119, 43.4607],
            [-91.7408, 43.4718],
            [-91.811, 43.5007],
            [-91.7302, 43.5008],
            [-91.6108, 43.5007],
            [-91.6109, 43.3421],
            [-91.624, 43.2189],
            [-91.6838, 43.1978],
            [-91.953, 43.2917],
            [-91.9435, 43.349],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908730',
        NAME: 'Decorah Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 446851196,
        AWATER: 196774,
        INTPTLAT: '+43.3288715',
        INTPTLON: '-091.7447707',
        ELSDLEA: '',
        UNSDLEA: '08730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4455, 43.5005],
            [-92.2894, 43.5006],
            [-91.9707, 43.5006],
            [-91.9435, 43.349],
            [-91.953, 43.2917],
            [-92.0809, 43.2276],
            [-92.1411, 43.1984],
            [-92.199, 43.2209],
            [-92.5335, 43.2129],
            [-92.4763, 43.3206],
            [-92.3569, 43.3204],
            [-92.356, 43.4288],
            [-92.4455, 43.5005],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914280',
        NAME: 'Howard-Winneshiek Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1102012168,
        AWATER: 933564,
        INTPTLAT: '+43.3627092',
        INTPTLON: '-092.1801856',
        ELSDLEA: '',
        UNSDLEA: '14280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6844, 43.1179],
            [-91.6838, 43.1978],
            [-91.624, 43.2189],
            [-91.5174, 43.2079],
            [-91.464, 43.1359],
            [-91.4685, 42.957],
            [-91.4986, 42.9493],
            [-91.6502, 43.0818],
            [-91.6946, 43.0818],
            [-91.6844, 43.1179],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1923340',
        NAME: 'Postville Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314400509,
        AWATER: 19137,
        INTPTLAT: '+43.1104276',
        INTPTLON: '-091.5602357',
        ELSDLEA: '',
        UNSDLEA: '23340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.104, 41.5572],
            [-94.0132, 41.4453],
            [-94.0663, 41.3881],
            [-94.2415, 41.3882],
            [-94.2416, 41.5037],
            [-94.104, 41.5572],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910050',
        NAME: 'Earlham Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 281778232,
        AWATER: 450129,
        INTPTLAT: '+41.4623495',
        INTPTLON: '-094.1370123',
        ELSDLEA: '',
        UNSDLEA: '10050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9558, 41.2554],
            [-94.892, 41.36],
            [-94.8735, 41.501],
            [-94.7103, 41.5474],
            [-94.662, 41.3744],
            [-94.7002, 41.3455],
            [-94.7006, 41.1581],
            [-94.9099, 41.1585],
            [-94.9558, 41.2554],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1905940',
        NAME: 'CAM Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 722934569,
        AWATER: 1225312,
        INTPTLAT: '+41.3295056',
        INTPTLON: '-094.7976669',
        ELSDLEA: '',
        UNSDLEA: '05940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.661, 41.1579],
            [-94.5854, 41.1579],
            [-94.5472, 41.1153],
            [-94.5138, 40.9972],
            [-94.6096, 40.9719],
            [-94.6806, 41.0154],
            [-94.661, 41.1579],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1923760',
        NAME: 'Prescott Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208055943,
        AWATER: 203635,
        INTPTLAT: '+41.0670990',
        INTPTLON: '-094.5973421',
        ELSDLEA: '',
        UNSDLEA: '23760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6655, 42.3021],
            [-90.6468, 42.302],
            [-90.5357, 42.2072],
            [-90.5013, 42.1278],
            [-90.5183, 42.1015],
            [-90.6262, 42.0807],
            [-90.6658, 42.1785],
            [-90.6655, 42.3021],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903630',
        NAME: 'Andrew Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262935462,
        AWATER: 72572,
        INTPTLAT: '+42.1836754',
        INTPTLON: '-090.6170627',
        ELSDLEA: '',
        UNSDLEA: '03630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5176, 42.0297],
            [-95.479, 42.0955],
            [-95.3623, 42.1057],
            [-95.3621, 42.2326],
            [-95.3303, 42.2334],
            [-95.2654, 42.1528],
            [-95.2954, 42.0592],
            [-95.2376, 41.9948],
            [-95.3495, 41.864],
            [-95.3546, 41.8639],
            [-95.4022, 41.8705],
            [-95.4405, 41.9661],
            [-95.5176, 42.0297],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908910',
        NAME: 'Denison Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 441130249,
        AWATER: 461782,
        INTPTLAT: '+42.0333835',
        INTPTLON: '-095.3579612',
        ELSDLEA: '',
        UNSDLEA: '08910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8858, 42.7338],
            [-94.8554, 42.7549],
            [-94.7573, 42.7825],
            [-94.7377, 42.8927],
            [-94.443, 42.9081],
            [-94.4431, 42.8795],
            [-94.4539, 42.7613],
            [-94.5019, 42.7357],
            [-94.4822, 42.6377],
            [-94.5604, 42.6092],
            [-94.6238, 42.4956],
            [-94.7964, 42.4811],
            [-94.8061, 42.5172],
            [-94.7651, 42.5595],
            [-94.7963, 42.696],
            [-94.8858, 42.7338],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1923190',
        NAME: 'Pocahontas Area Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1040705651,
        AWATER: 1000630,
        INTPTLAT: '+42.7105004',
        INTPTLON: '-094.6486502',
        ELSDLEA: '',
        UNSDLEA: '23190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8163, 42.9613],
            [-94.5101, 43.0316],
            [-94.4426, 43.0829],
            [-94.4327, 43.0095],
            [-94.3247, 42.9951],
            [-94.3249, 42.9153],
            [-94.4429, 42.8795],
            [-94.4431, 42.8795],
            [-94.443, 42.9081],
            [-94.7377, 42.8927],
            [-94.8163, 42.9613],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930720',
        NAME: 'West Bend-Mallard Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 492565913,
        AWATER: 1014623,
        INTPTLAT: '+42.9702695',
        INTPTLON: '-094.5557637',
        ELSDLEA: '',
        UNSDLEA: '30720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8653, 41.1284],
            [-93.6733, 41.1329],
            [-93.5964, 41.1615],
            [-93.5573, 41.1543],
            [-93.5567, 40.9864],
            [-93.6435, 40.9575],
            [-93.7051, 40.8708],
            [-93.7864, 40.8486],
            [-93.9208, 40.8682],
            [-93.8653, 40.8977],
            [-93.884, 41.0742],
            [-93.8653, 41.1284],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907410',
        NAME: 'Clarke Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 760136902,
        AWATER: 1371686,
        INTPTLAT: '+41.0245973',
        INTPTLON: '-093.7310823',
        ELSDLEA: '',
        UNSDLEA: '07410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3711, 41.0159],
            [-91.3276, 41.0741],
            [-91.1834, 41.0842],
            [-90.9494, 41.0727],
            [-90.9438, 41.0265],
            [-90.9629, 40.925],
            [-91.0232, 40.8828],
            [-91.1797, 40.8703],
            [-91.334, 40.9585],
            [-91.3711, 41.0159],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918930',
        NAME: 'Mediapolis Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 590284108,
        AWATER: 20583732,
        INTPTLAT: '+40.9786986',
        INTPTLON: '-091.1374034',
        ELSDLEA: '',
        UNSDLEA: '18930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7563, 41.2591],
            [-95.7421, 41.2824],
            [-95.5748, 41.3326],
            [-95.527, 41.2326],
            [-95.5564, 41.1601],
            [-95.5945, 41.1312],
            [-95.7087, 41.16],
            [-95.7563, 41.2591],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927960',
        NAME: 'Treynor Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246104785,
        AWATER: 192041,
        INTPTLAT: '+41.2283217',
        INTPTLON: '-095.6271888',
        ELSDLEA: '',
        UNSDLEA: '27960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4651, 42.2972],
            [-92.4169, 42.2973],
            [-92.386, 42.1378],
            [-92.3269, 42.036],
            [-92.3282, 42.0288],
            [-92.356, 42.0211],
            [-92.4618, 42.0358],
            [-92.5908, 42.1447],
            [-92.5618, 42.2688],
            [-92.4651, 42.2972],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921000',
        NAME: 'North Tama County Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422299839,
        AWATER: 150772,
        INTPTLAT: '+42.1490420',
        INTPTLON: '-092.4607943',
        ELSDLEA: '',
        UNSDLEA: '21000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3367, 41.1614],
            [-91.216, 41.1469],
            [-91.1834, 41.0842],
            [-91.3276, 41.0741],
            [-91.3367, 41.1614],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1919770',
        NAME: 'Morning Sun Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121150113,
        AWATER: 59552,
        INTPTLAT: '+41.1034813',
        INTPTLON: '-091.2662761',
        ELSDLEA: '',
        UNSDLEA: '19770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9657, 42.1951],
            [-93.8447, 42.1882],
            [-93.8008, 42.1592],
            [-93.842, 42.1375],
            [-93.8949, 41.9903],
            [-93.9706, 42.0371],
            [-93.9381, 42.1231],
            [-93.9657, 42.1951],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1905130',
        NAME: 'Boone Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192282341,
        AWATER: 1638542,
        INTPTLAT: '+42.0909451',
        INTPTLON: '-093.9099412',
        ELSDLEA: '',
        UNSDLEA: '05130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.865, 42.3504],
            [-93.733, 42.3613],
            [-93.5018, 42.3981],
            [-93.5114, 42.2672],
            [-93.5812, 42.2092],
            [-93.7471, 42.2094],
            [-93.7909, 42.1664],
            [-93.8008, 42.1592],
            [-93.8447, 42.1882],
            [-93.865, 42.3504],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926640',
        NAME: 'South Hamilton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 525623838,
        AWATER: 1402194,
        INTPTLAT: '+42.2890433',
        INTPTLON: '-093.6887345',
        ELSDLEA: '',
        UNSDLEA: '26640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.1447, 42.1],
            [-93.9381, 42.1231],
            [-93.9706, 42.0371],
            [-93.8949, 41.9903],
            [-93.8832, 41.9505],
            [-93.9948, 41.9213],
            [-94.1257, 41.9431],
            [-94.1447, 42.1],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921660',
        NAME: 'Ogden Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366890765,
        AWATER: 566530,
        INTPTLAT: '+42.0154778',
        INTPTLON: '-094.0378498',
        ELSDLEA: '',
        UNSDLEA: '21660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.41, 41.1119],
            [-92.2956, 41.0605],
            [-92.3285, 40.9714],
            [-92.4097, 40.8991],
            [-92.5723, 40.899],
            [-92.5528, 40.9715],
            [-92.41, 41.1119],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1922110',
        NAME: 'Ottumwa Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336985900,
        AWATER: 4704010,
        INTPTLAT: '+41.0049486',
        INTPTLON: '-092.4339765',
        ELSDLEA: '',
        UNSDLEA: '22110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2956, 41.0605],
            [-92.169, 41.0761],
            [-92.1597, 40.9103],
            [-92.113, 40.8786],
            [-92.1783, 40.8138],
            [-92.2507, 40.8995],
            [-92.4097, 40.8991],
            [-92.3285, 40.9714],
            [-92.2956, 41.0605],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906240',
        NAME: 'Cardinal Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 377684386,
        AWATER: 5488230,
        INTPTLAT: '+40.9488707',
        INTPTLON: '-092.2471394',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6008, 41.2506],
            [-91.4853, 41.2568],
            [-91.4759, 41.2351],
            [-91.4864, 41.0645],
            [-91.7167, 41.098],
            [-91.7166, 41.1245],
            [-91.6875, 41.2106],
            [-91.6008, 41.2506],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1929490',
        NAME: 'Waco Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 316342444,
        AWATER: 634680,
        INTPTLAT: '+41.1502025',
        INTPTLON: '-091.5906614',
        ELSDLEA: '',
        UNSDLEA: '29490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9417, 43.2697],
            [-93.7644, 43.2844],
            [-93.666, 43.3713],
            [-93.5471, 43.3139],
            [-93.4184, 43.3136],
            [-93.366, 43.2276],
            [-93.4283, 43.218],
            [-93.5469, 43.1987],
            [-93.7338, 43.2056],
            [-93.7792, 43.1695],
            [-93.9703, 43.1692],
            [-93.9417, 43.2697],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1911790',
        NAME: 'Forest City Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 600201890,
        AWATER: 2057278,
        INTPTLAT: '+43.2591727',
        INTPTLON: '-093.6857778',
        ELSDLEA: '',
        UNSDLEA: '11790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6972, 43.4997],
            [-93.6046, 43.4996],
            [-93.3799, 43.4995],
            [-93.3696, 43.3571],
            [-93.4184, 43.3136],
            [-93.5471, 43.3139],
            [-93.666, 43.3713],
            [-93.6972, 43.4997],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1916110',
        NAME: 'Lake Mills Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 461357720,
        AWATER: 6637444,
        INTPTLAT: '+43.4137697',
        INTPTLON: '-093.5225574',
        ELSDLEA: '',
        UNSDLEA: '16110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7227, 43.0817],
            [-91.6946, 43.0818],
            [-91.6502, 43.0818],
            [-91.4986, 42.9493],
            [-91.5967, 42.8695],
            [-91.6084, 42.8129],
            [-91.7159, 42.8062],
            [-91.7227, 43.0817],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1928710',
        NAME: 'Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 400368620,
        AWATER: 119501,
        INTPTLAT: '+42.9311466',
        INTPTLON: '-091.6409260',
        ELSDLEA: '',
        UNSDLEA: '28710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0809, 43.2276],
            [-91.953, 43.2917],
            [-91.6838, 43.1978],
            [-91.6844, 43.1179],
            [-91.6946, 43.0818],
            [-91.7227, 43.0817],
            [-91.8544, 43.0819],
            [-91.904, 43.1688],
            [-92.0812, 43.1692],
            [-92.0809, 43.2276],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926790',
        NAME: 'South Winneshiek Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 472374133,
        AWATER: 142086,
        INTPTLAT: '+43.1763224',
        INTPTLON: '-091.8471145',
        ELSDLEA: '',
        UNSDLEA: '26790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9707, 43.5006],
            [-91.9697, 43.5007],
            [-91.811, 43.5007],
            [-91.7408, 43.4718],
            [-91.6119, 43.4607],
            [-91.6207, 43.4031],
            [-91.8433, 43.3275],
            [-91.8695, 43.3564],
            [-91.8744, 43.3456],
            [-91.9435, 43.349],
            [-91.9707, 43.5006],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921060',
        NAME: 'North Winneshiek Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 356393833,
        AWATER: 80536,
        INTPTLAT: '+43.4183795',
        INTPTLON: '-091.8185521',
        ELSDLEA: '',
        UNSDLEA: '21060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1607, 43.1409],
            [-92.1411, 43.1984],
            [-92.0809, 43.2276],
            [-92.0812, 43.1692],
            [-91.904, 43.1688],
            [-91.8544, 43.0819],
            [-91.9036, 43.0159],
            [-92.0815, 43.0304],
            [-92.1992, 43.0376],
            [-92.1607, 43.1409],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1928110',
        NAME: 'Turkey Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 440698343,
        AWATER: 30081,
        INTPTLAT: '+43.0950875',
        INTPTLON: '-092.0423684',
        ELSDLEA: '',
        UNSDLEA: '28110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0654, 43.4996],
            [-92.8358, 43.4999],
            [-92.6726, 43.5001],
            [-92.6726, 43.4287],
            [-92.7997, 43.4002],
            [-93.0241, 43.2631],
            [-93.161, 43.3424],
            [-93.0351, 43.4738],
            [-93.0654, 43.4996],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1925200',
        NAME: 'St. Ansgar Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 592871464,
        AWATER: 922352,
        INTPTLAT: '+43.4067463',
        INTPTLON: '-092.9353164',
        ELSDLEA: '',
        UNSDLEA: '25200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.04, 43.0525],
            [-94.04, 42.9582],
            [-93.9712, 42.9078],
            [-94.0897, 42.8486],
            [-94.1587, 42.9083],
            [-94.1681, 42.9878],
            [-94.04, 43.0525],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1917880',
        NAME: 'Lu Verne Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205782786,
        AWATER: 0,
        INTPTLAT: '+42.9444339',
        INTPTLON: '-094.0803372',
        ELSDLEA: '',
        UNSDLEA: '17880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9376, 41.208],
            [-93.9076, 41.2831],
            [-93.8084, 41.3405],
            [-93.6735, 41.3075],
            [-93.6735, 41.2491],
            [-93.5957, 41.1908],
            [-93.5964, 41.1615],
            [-93.6733, 41.1329],
            [-93.8653, 41.1284],
            [-93.9657, 41.139],
            [-93.9376, 41.208],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914670',
        NAME: 'Interstate 35 Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 490393896,
        AWATER: 910750,
        INTPTLAT: '+41.2185587',
        INTPTLON: '-093.7766144',
        ELSDLEA: '',
        UNSDLEA: '14670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7122, 41.6221],
            [-93.6977, 41.6367],
            [-93.6412, 41.6368],
            [-93.5467, 41.6368],
            [-93.5296, 41.5539],
            [-93.5579, 41.4971],
            [-93.6209, 41.479],
            [-93.6981, 41.512],
            [-93.7122, 41.6221],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908970',
        NAME: 'Des Moines Independent Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211974789,
        AWATER: 4564594,
        INTPTLAT: '+41.5735890',
        INTPTLON: '-093.6192017',
        ELSDLEA: '',
        UNSDLEA: '08970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5957, 41.1908],
            [-93.5631, 41.2487],
            [-93.3959, 41.3491],
            [-93.3283, 41.2803],
            [-93.3279, 41.1607],
            [-93.5573, 41.1543],
            [-93.5964, 41.1615],
            [-93.5957, 41.1908],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926850',
        NAME: 'Southeast Warren Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 396786714,
        AWATER: 813437,
        INTPTLAT: '+41.2302290',
        INTPTLON: '-093.4269660',
        ELSDLEA: '',
        UNSDLEA: '26850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5579, 41.4971],
            [-93.5296, 41.5539],
            [-93.3284, 41.4909],
            [-93.4002, 41.4015],
            [-93.5584, 41.4531],
            [-93.5579, 41.4971],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906270',
        NAME: 'Carlisle Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190523099,
        AWATER: 3757338,
        INTPTLAT: '+41.4750162',
        INTPTLON: '-093.4675299',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7906, 41.465],
            [-93.698, 41.4104],
            [-93.6735, 41.3075],
            [-93.8084, 41.3405],
            [-93.8764, 41.4543],
            [-93.7906, 41.465],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918750',
        NAME: 'Martensdale-St. Marys Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 197544107,
        AWATER: 873067,
        INTPTLAT: '+41.3756910',
        INTPTLON: '-093.7585395',
        ELSDLEA: '',
        UNSDLEA: '18750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7792, 43.1695],
            [-93.7338, 43.2056],
            [-93.5469, 43.1987],
            [-93.5281, 43.0531],
            [-93.7146, 43.031],
            [-93.7152, 43.1345],
            [-93.7792, 43.1695],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1912330',
        NAME: 'Garner-Hayfield Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 281223255,
        AWATER: 91608,
        INTPTLAT: '+43.1158522',
        INTPTLON: '-093.6311452',
        ELSDLEA: '',
        UNSDLEA: '12330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7472, 42.1557],
            [-93.5644, 42.1226],
            [-93.5815, 42.0417],
            [-93.6836, 42.0454],
            [-93.7472, 42.1557],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1912510',
        NAME: 'Gilbert Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126571976,
        AWATER: 444436,
        INTPTLAT: '+42.1017344',
        INTPTLON: '-093.6555755',
        ELSDLEA: '',
        UNSDLEA: '12510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7909, 42.1664],
            [-93.7471, 42.2094],
            [-93.5812, 42.2092],
            [-93.5114, 42.2672],
            [-93.4537, 42.2093],
            [-93.463, 42.1232],
            [-93.5644, 42.1226],
            [-93.7472, 42.1557],
            [-93.7909, 42.1664],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1924870',
        NAME: 'Roland-Story Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241162289,
        AWATER: 151201,
        INTPTLAT: '+42.1806521',
        INTPTLON: '-093.5877475',
        ELSDLEA: '',
        UNSDLEA: '24870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4978, 42.9953],
            [-93.4187, 43.0168],
            [-93.2609, 43.0526],
            [-93.1178, 43.0641],
            [-93.1123, 43.0526],
            [-93.1024, 43.0526],
            [-93.0234, 43.0019],
            [-93.0251, 42.9075],
            [-93.0253, 42.8766],
            [-93.0551, 42.8476],
            [-93.2955, 42.819],
            [-93.3408, 42.877],
            [-93.4986, 42.8573],
            [-93.5577, 42.9299],
            [-93.4978, 42.9953],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1925920',
        NAME: 'West Fork Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 821282746,
        AWATER: 165107,
        INTPTLAT: '+42.9440130',
        INTPTLON: '-093.2563570',
        ELSDLEA: '',
        UNSDLEA: '25920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2737, 43.5003],
            [-96.1927, 43.5003],
            [-96.1544, 43.5002],
            [-95.9783, 43.5001],
            [-96.1163, 43.3534],
            [-96.1178, 43.2792],
            [-96.2165, 43.2575],
            [-96.2957, 43.2867],
            [-96.2737, 43.5003],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906960',
        NAME: 'Central Lyon Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418115205,
        AWATER: 39922,
        INTPTLAT: '+43.4006956',
        INTPTLON: '-096.1657739',
        ELSDLEA: '',
        UNSDLEA: '06960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3386, 42.8289],
            [-95.2299, 42.8363],
            [-95.1904, 42.7634],
            [-95.2396, 42.7055],
            [-95.2697, 42.5718],
            [-95.3336, 42.561],
            [-95.4021, 42.6126],
            [-95.3386, 42.8289],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903480',
        NAME: 'Alta Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317656677,
        AWATER: 85330,
        INTPTLAT: '+42.7056451',
        INTPTLON: '-095.2993168',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3962, 42.6858],
            [-92.3225, 42.7409],
            [-92.2977, 42.7298],
            [-92.2388, 42.6323],
            [-92.3177, 42.5776],
            [-92.3574, 42.5852],
            [-92.3962, 42.6858],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908940',
        NAME: 'Denver Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148880668,
        AWATER: 140146,
        INTPTLAT: '+42.6522619',
        INTPTLON: '-092.3208979',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3177, 42.5776],
            [-92.2388, 42.6323],
            [-92.1161, 42.642],
            [-92.0824, 42.5549],
            [-92.189, 42.4687],
            [-92.3177, 42.5776],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1909540',
        NAME: 'Dunkerton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211001038,
        AWATER: 620301,
        INTPTLAT: '+42.5629000',
        INTPTLON: '-092.1899566',
        ELSDLEA: '',
        UNSDLEA: '09540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3847, 40.9087],
            [-95.3847, 40.916],
            [-95.3843, 41.16],
            [-95.3058, 41.1597],
            [-95.1186, 41.0762],
            [-95.1752, 40.9011],
            [-95.385, 40.9016],
            [-95.3847, 40.9087],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1924000',
        NAME: 'Red Oak Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 516534729,
        AWATER: 1440085,
        INTPTLAT: '+41.0226696',
        INTPTLON: '-095.2750084',
        ELSDLEA: '',
        UNSDLEA: '24000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1651, 41.3388],
            [-95.0411, 41.3314],
            [-94.9558, 41.2554],
            [-94.9099, 41.1585],
            [-94.9276, 41.1446],
            [-95.0278, 41.0798],
            [-95.1186, 41.0762],
            [-95.3058, 41.1597],
            [-95.2698, 41.2959],
            [-95.1651, 41.3388],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913230',
        NAME: 'Griswold Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 625812426,
        AWATER: 281052,
        INTPTLAT: '+41.2059529',
        INTPTLON: '-095.1099167',
        ELSDLEA: '',
        UNSDLEA: '13230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4075, 42.8552],
            [-92.3768, 42.9071],
            [-92.3374, 42.9071],
            [-92.2502, 42.8983],
            [-92.1804, 42.7549],
            [-92.1803, 42.7442],
            [-92.2977, 42.7298],
            [-92.3225, 42.7409],
            [-92.4075, 42.8552],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1928050',
        NAME: 'Tripoli Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253096450,
        AWATER: 3728276,
        INTPTLAT: '+42.8220230',
        INTPTLON: '-092.2830274',
        ELSDLEA: '',
        UNSDLEA: '28050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7287, 42.3708],
            [-95.6241, 42.4071],
            [-95.4892, 42.4428],
            [-95.3616, 42.4144],
            [-95.3815, 42.3052],
            [-95.3303, 42.2334],
            [-95.3621, 42.2326],
            [-95.6326, 42.2112],
            [-95.6401, 42.2112],
            [-95.6527, 42.2113],
            [-95.7287, 42.3708],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904320',
        NAME: 'Battle Creek-Ida Grove Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 537692281,
        AWATER: 1243898,
        INTPTLAT: '+42.3202550',
        INTPTLON: '-095.5307277',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.3121, 42.5617],
            [-96.1646, 42.5758],
            [-96.0959, 42.5613],
            [-96.1733, 42.3512],
            [-96.2527, 42.3804],
            [-96.3021, 42.467],
            [-96.3121, 42.5617],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1916440',
        NAME: 'Lawton-Bronson Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 304918527,
        AWATER: 347347,
        INTPTLAT: '+42.4751944',
        INTPTLON: '-096.2068654',
        ELSDLEA: '',
        UNSDLEA: '16440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2527, 42.3804],
            [-96.1643, 42.322],
            [-95.9711, 42.2985],
            [-96.0104, 42.165],
            [-96.1989, 42.1931],
            [-96.3296, 42.1533],
            [-96.3444, 42.1619],
            [-96.3471, 42.1912],
            [-96.3564, 42.2765],
            [-96.3687, 42.3047],
            [-96.2527, 42.3804],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931470',
        NAME: 'Westwood Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 557589376,
        AWATER: 5847685,
        INTPTLAT: '+42.2499303',
        INTPTLON: '-096.1642180',
        ELSDLEA: '',
        UNSDLEA: '31470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1582, 41.4334],
            [-93.0919, 41.4248],
            [-92.9857, 41.3421],
            [-92.8883, 41.3169],
            [-93.038, 41.2483],
            [-93.0992, 41.1609],
            [-93.2131, 41.1608],
            [-93.1554, 41.2294],
            [-93.2382, 41.2917],
            [-93.1582, 41.4334],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1915840',
        NAME: 'Knoxville Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391847687,
        AWATER: 21287259,
        INTPTLAT: '+41.3047175',
        INTPTLON: '-093.0957862',
        ELSDLEA: '',
        UNSDLEA: '15840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5484, 42.717],
            [-93.4865, 42.7385],
            [-93.3405, 42.655],
            [-93.3404, 42.6439],
            [-93.3965, 42.6301],
            [-93.4995, 42.5577],
            [-93.6275, 42.5866],
            [-93.6172, 42.6737],
            [-93.5484, 42.717],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1909450',
        NAME: 'Dows Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267149774,
        AWATER: 303125,
        INTPTLAT: '+42.6507153',
        INTPTLON: '-093.5044586',
        ELSDLEA: '',
        UNSDLEA: '09450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7312, 42.0351],
            [-92.6636, 42.0356],
            [-92.5909, 42.1379],
            [-92.5908, 42.1447],
            [-92.4618, 42.0358],
            [-92.356, 42.0211],
            [-92.3985, 41.8336],
            [-92.4163, 41.8257],
            [-92.5331, 41.8622],
            [-92.6885, 41.8623],
            [-92.7572, 41.9818],
            [-92.7312, 42.0351],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926730',
        NAME: 'South Tama County Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 655211888,
        AWATER: 1790696,
        INTPTLAT: '+41.9669866',
        INTPTLON: '-092.5507777',
        ELSDLEA: '',
        UNSDLEA: '26730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.619, 41.6878],
            [-92.5131, 41.6811],
            [-92.2995, 41.6015],
            [-92.3365, 41.5652],
            [-92.412, 41.5095],
            [-92.6036, 41.5092],
            [-92.6421, 41.5381],
            [-92.6497, 41.6018],
            [-92.619, 41.6878],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1919590',
        NAME: 'Montezuma Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403520951,
        AWATER: 1862710,
        INTPTLAT: '+41.5889485',
        INTPTLON: '-092.5144516',
        ELSDLEA: '',
        UNSDLEA: '19590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5657, 41.9538],
            [-91.5307, 41.9905],
            [-91.5111, 41.9904],
            [-91.4191, 41.9817],
            [-91.3749, 42.0164],
            [-91.3032, 41.9728],
            [-91.3078, 41.9653],
            [-91.4043, 41.9763],
            [-91.4132, 41.8598],
            [-91.5593, 41.8713],
            [-91.5657, 41.9538],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920040',
        NAME: 'Mount Vernon Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196690418,
        AWATER: 2716989,
        INTPTLAT: '+41.9340943',
        INTPTLON: '-091.4615261',
        ELSDLEA: '',
        UNSDLEA: '20040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8894, 42.3054],
            [-91.7842, 42.3417],
            [-91.5978, 42.384],
            [-91.4025, 42.2964],
            [-91.3979, 42.2964],
            [-91.4031, 42.253],
            [-91.5971, 42.2486],
            [-91.6831, 42.2177],
            [-91.8321, 42.2446],
            [-91.8895, 42.2982],
            [-91.8894, 42.3054],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920820',
        NAME: 'North Linn Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391630442,
        AWATER: 2822276,
        INTPTLAT: '+42.2892327',
        INTPTLON: '-091.6419676',
        ELSDLEA: '',
        UNSDLEA: '20820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5971, 42.2486],
            [-91.4031, 42.253],
            [-91.364, 42.2091],
            [-91.4207, 42.1474],
            [-91.5211, 42.1336],
            [-91.555, 42.1341],
            [-91.5971, 42.2486],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906780',
        NAME: 'Central City Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193230417,
        AWATER: 2245186,
        INTPTLAT: '+42.1984902',
        INTPTLON: '-091.4885906',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4003, 41.3926],
            [-93.4002, 41.4015],
            [-93.3284, 41.4909],
            [-93.2816, 41.4889],
            [-93.1582, 41.4334],
            [-93.2382, 41.2917],
            [-93.3283, 41.2803],
            [-93.3959, 41.3491],
            [-93.4003, 41.3926],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1923160',
        NAME: 'Pleasantville Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302909263,
        AWATER: 2197889,
        INTPTLAT: '+41.3906259',
        INTPTLON: '-093.2759280',
        ELSDLEA: '',
        UNSDLEA: '23160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.529, 42.6321],
            [-92.3967, 42.585],
            [-92.436, 42.4695],
            [-92.485, 42.4696],
            [-92.5543, 42.5001],
            [-92.5543, 42.6211],
            [-92.529, 42.6321],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906510',
        NAME: 'Cedar Falls Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153694281,
        AWATER: 3683620,
        INTPTLAT: '+42.5389865',
        INTPTLON: '-092.4841101',
        ELSDLEA: '',
        UNSDLEA: '06510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3967, 42.585],
            [-92.3574, 42.5852],
            [-92.3177, 42.5776],
            [-92.189, 42.4687],
            [-92.1812, 42.4063],
            [-92.3673, 42.3338],
            [-92.436, 42.4695],
            [-92.3967, 42.585],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930480',
        NAME: 'Waterloo Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357457191,
        AWATER: 7001911,
        INTPTLAT: '+42.4619135',
        INTPTLON: '-092.3218333',
        ELSDLEA: '',
        UNSDLEA: '30480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4053, 41.617],
            [-91.2883, 41.6489],
            [-91.1415, 41.6305],
            [-91.1454, 41.5089],
            [-91.2803, 41.4301],
            [-91.2873, 41.4229],
            [-91.3685, 41.4232],
            [-91.3668, 41.5612],
            [-91.4049, 41.5986],
            [-91.4053, 41.617],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930990',
        NAME: 'West Liberty Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 379563095,
        AWATER: 4086966,
        INTPTLAT: '+41.5434314',
        INTPTLON: '-091.2661592',
        ELSDLEA: '',
        UNSDLEA: '30990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3078, 41.9653],
            [-91.3032, 41.9728],
            [-91.2488, 41.9764],
            [-91.2102, 41.9471],
            [-91.0472, 41.9642],
            [-90.8989, 41.9185],
            [-90.8982, 41.7988],
            [-91.0155, 41.8009],
            [-91.1914, 41.8456],
            [-91.3076, 41.8163],
            [-91.3394, 41.9183],
            [-91.3078, 41.9653],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1917100',
        NAME: 'North Cedar Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 527723263,
        AWATER: 38998,
        INTPTLAT: '+41.8808381',
        INTPTLON: '-091.1091025',
        ELSDLEA: '',
        UNSDLEA: '17100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.366, 41.8165],
            [-91.3076, 41.8163],
            [-91.1914, 41.8456],
            [-91.0155, 41.8009],
            [-91.0351, 41.6991],
            [-91.1452, 41.6577],
            [-91.2895, 41.7727],
            [-91.366, 41.7837],
            [-91.366, 41.8165],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927870',
        NAME: 'Tipton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359632614,
        AWATER: 2421256,
        INTPTLAT: '+41.7659590',
        INTPTLON: '-091.1651811',
        ELSDLEA: '',
        UNSDLEA: '27870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0155, 41.8009],
            [-90.8982, 41.7988],
            [-90.8116, 41.7202],
            [-90.7919, 41.6838],
            [-90.8018, 41.6693],
            [-90.977, 41.6699],
            [-91.0351, 41.6991],
            [-91.0155, 41.8009],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904740',
        NAME: 'Bennett Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198301899,
        AWATER: 0,
        INTPTLAT: '+41.7292372',
        INTPTLON: '-090.9281194',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.977, 41.6699],
            [-90.8018, 41.6693],
            [-90.7935, 41.5389],
            [-90.8527, 41.5352],
            [-90.944, 41.5223],
            [-90.977, 41.6699],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1909600',
        NAME: 'Durant Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228642451,
        AWATER: 43124,
        INTPTLAT: '+41.6051083',
        INTPTLON: '-090.8794656',
        ELSDLEA: '',
        UNSDLEA: '09600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1316, 42.0983],
            [-91.1129, 42.1181],
            [-91.0259, 42.1444],
            [-91.0141, 42.2084],
            [-90.8838, 42.1713],
            [-90.782, 42.0402],
            [-90.7403, 41.9164],
            [-90.7816, 41.8656],
            [-90.8989, 41.9185],
            [-91.0472, 41.9642],
            [-91.1316, 42.0983],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1919200',
        NAME: 'Midland Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 624852410,
        AWATER: 395099,
        INTPTLAT: '+42.0395119',
        INTPTLON: '-090.9414660',
        ELSDLEA: '',
        UNSDLEA: '19200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.169, 41.0761],
            [-92.0243, 41.0761],
            [-91.9366, 41.1634],
            [-91.764, 41.1333],
            [-91.7166, 41.1245],
            [-91.7167, 41.098],
            [-91.7361, 40.9007],
            [-91.7523, 40.9006],
            [-92.0929, 40.8898],
            [-92.113, 40.8786],
            [-92.1597, 40.9103],
            [-92.169, 41.0761],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1911340',
        NAME: 'Fairfield Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 924273978,
        AWATER: 2901974,
        INTPTLAT: '+41.0137080',
        INTPTLON: '-091.9240417',
        ELSDLEA: '',
        UNSDLEA: '11340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.2013, 43.5008],
            [-94.9248, 43.5009],
            [-94.9641, 43.425],
            [-95.0325, 43.3712],
            [-95.25, 43.3974],
            [-95.2013, 43.5008],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927000',
        NAME: 'Spirit Lake Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234180219,
        AWATER: 38506134,
        INTPTLAT: '+43.4387541',
        INTPTLON: '-095.0682773',
        ELSDLEA: '',
        UNSDLEA: '27000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.5018, 42.3981],
            [-93.4628, 42.4486],
            [-93.3468, 42.4049],
            [-93.2514, 42.4119],
            [-93.2201, 42.321],
            [-93.2317, 42.2089],
            [-93.4537, 42.2093],
            [-93.5114, 42.2672],
            [-93.5018, 42.3981],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914310',
        NAME: 'Hubbard-Radcliffe Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 506368376,
        AWATER: 182176,
        INTPTLAT: '+42.3177103',
        INTPTLON: '-093.3758224',
        ELSDLEA: '',
        UNSDLEA: '14310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.152, 42.2087],
            [-93.0761, 42.2904],
            [-92.9392, 42.3324],
            [-92.7679, 42.2772],
            [-92.7865, 42.1668],
            [-92.8939, 42.1306],
            [-93.05, 42.1512],
            [-93.152, 42.2087],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904440',
        NAME: 'BCLUW Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 473312358,
        AWATER: 362279,
        INTPTLAT: '+42.2313218',
        INTPTLON: '-092.9627820',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.9392, 42.3324],
            [-92.9343, 42.3468],
            [-92.886, 42.354],
            [-92.8102, 42.4847],
            [-92.7591, 42.4665],
            [-92.661, 42.3842],
            [-92.6922, 42.3113],
            [-92.7679, 42.2772],
            [-92.9392, 42.3324],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913290',
        NAME: 'Grundy Center Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 291764492,
        AWATER: 13774,
        INTPTLAT: '+42.3688461',
        INTPTLON: '-092.8068542',
        ELSDLEA: '',
        UNSDLEA: '13290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8103, 42.4994],
            [-92.6776, 42.5353],
            [-92.7022, 42.6425],
            [-92.5543, 42.6211],
            [-92.5543, 42.5001],
            [-92.485, 42.4696],
            [-92.5339, 42.4134],
            [-92.661, 42.3842],
            [-92.7591, 42.4665],
            [-92.8102, 42.4847],
            [-92.8103, 42.4994],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1909120',
        NAME: 'Dike-New Hartford Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 379857070,
        AWATER: 87044,
        INTPTLAT: '+42.5061013',
        INTPTLON: '-092.6427815',
        ELSDLEA: '',
        UNSDLEA: '09120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6726, 43.4287],
            [-92.6726, 43.5001],
            [-92.6288, 43.5002],
            [-92.4455, 43.5005],
            [-92.356, 43.4288],
            [-92.3569, 43.3204],
            [-92.4763, 43.3206],
            [-92.5335, 43.2129],
            [-92.5544, 43.2128],
            [-92.5942, 43.2128],
            [-92.594, 43.2989],
            [-92.6727, 43.3275],
            [-92.6726, 43.4287],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1924150',
        NAME: 'Riceville Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 570012680,
        AWATER: 283904,
        INTPTLAT: '+43.3868531',
        INTPTLON: '-092.5384394',
        ELSDLEA: '',
        UNSDLEA: '24150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7966, 42.5708],
            [-91.7056, 42.6432],
            [-91.7274, 42.5376],
            [-91.6086, 42.4984],
            [-91.5978, 42.384],
            [-91.7842, 42.3417],
            [-91.8309, 42.4094],
            [-91.7861, 42.4683],
            [-91.7966, 42.5708],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910110',
        NAME: 'East Buchanan Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381782881,
        AWATER: 1233992,
        INTPTLAT: '+42.4606583',
        INTPTLON: '-091.7127944',
        ELSDLEA: '',
        UNSDLEA: '10110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0824, 42.5549],
            [-91.9642, 42.5906],
            [-91.964, 42.5549],
            [-91.9634, 42.4678],
            [-92.0211, 42.3504],
            [-92.1812, 42.4063],
            [-92.189, 42.4687],
            [-92.0824, 42.5549],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1915330',
        NAME: 'Jesup Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365243629,
        AWATER: 76835,
        INTPTLAT: '+42.4618452',
        INTPTLON: '-092.0604628',
        ELSDLEA: '',
        UNSDLEA: '15330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0008, 42.7001],
            [-91.9617, 42.7296],
            [-91.7751, 42.7305],
            [-91.7056, 42.6432],
            [-91.7966, 42.5708],
            [-91.964, 42.5549],
            [-91.9642, 42.5906],
            [-92.0008, 42.7001],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921630',
        NAME: 'Oelwein Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 337553991,
        AWATER: 462159,
        INTPTLAT: '+42.6505702',
        INTPTLON: '-091.8720513',
        ELSDLEA: '',
        UNSDLEA: '21630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8144, 41.6511],
            [-93.8143, 41.6221],
            [-93.8286, 41.5232],
            [-93.9354, 41.5897],
            [-93.9504, 41.6222],
            [-93.8144, 41.6511],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930510',
        NAME: 'Waukee Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143329380,
        AWATER: 494758,
        INTPTLAT: '+41.5878381',
        INTPTLON: '-093.8587946',
        ELSDLEA: '',
        UNSDLEA: '30510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.794, 42.5824],
            [-95.7415, 42.5898],
            [-95.7209, 42.6995],
            [-95.6625, 42.7063],
            [-95.5359, 42.6481],
            [-95.5357, 42.5908],
            [-95.6722, 42.5471],
            [-95.6241, 42.4071],
            [-95.7287, 42.3708],
            [-95.8933, 42.4662],
            [-95.9415, 42.466],
            [-95.9478, 42.4824],
            [-95.8594, 42.4893],
            [-95.794, 42.5824],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910340',
        NAME: 'River Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 563182243,
        AWATER: 785240,
        INTPTLAT: '+42.5340145',
        INTPTLON: '-095.7145828',
        ELSDLEA: '',
        UNSDLEA: '10340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.0104, 42.165],
            [-95.9711, 42.2985],
            [-95.9902, 42.4438],
            [-95.9415, 42.466],
            [-95.8933, 42.4662],
            [-95.7287, 42.3708],
            [-95.6527, 42.2113],
            [-95.6711, 42.1322],
            [-95.7491, 42.1173],
            [-95.8469, 42.0301],
            [-95.9821, 42.0945],
            [-96.0104, 42.165],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918480',
        NAME: 'Maple Valley-Anthon Oto Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 967176674,
        AWATER: 2537314,
        INTPTLAT: '+42.2373642',
        INTPTLON: '-095.8442943',
        ELSDLEA: '',
        UNSDLEA: '18480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3341, 42.9501],
            [-91.2506, 42.9645],
            [-91.1439, 42.9207],
            [-91.0789, 42.815],
            [-91.0513, 42.737],
            [-90.897, 42.6751],
            [-90.8973, 42.653],
            [-91.1328, 42.6458],
            [-91.1518, 42.7104],
            [-91.2507, 42.7324],
            [-91.3128, 42.7801],
            [-91.3341, 42.9501],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913350',
        NAME: 'Clayton Ridge Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 554326310,
        AWATER: 30154338,
        INTPTLAT: '+42.8046218',
        INTPTLON: '-091.1643607',
        ELSDLEA: '',
        UNSDLEA: '13350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1904, 42.7634],
            [-95.063, 42.8143],
            [-94.8867, 42.8139],
            [-94.8554, 42.7549],
            [-94.8858, 42.7338],
            [-95.073, 42.6909],
            [-95.2396, 42.7055],
            [-95.1904, 42.7634],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900031',
        NAME: 'Albert City-Truesdale Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303502180,
        AWATER: 142142,
        INTPTLAT: '+42.7560967',
        INTPTLON: '-095.0227882',
        ELSDLEA: '',
        UNSDLEA: '00031',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3965, 42.6301],
            [-93.3309, 42.6095],
            [-93.3214, 42.4705],
            [-93.3468, 42.4049],
            [-93.4628, 42.4486],
            [-93.4995, 42.5577],
            [-93.3965, 42.6301],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900032',
        NAME: 'Alden Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275688816,
        AWATER: 170718,
        INTPTLAT: '+42.5259252',
        INTPTLON: '-093.4028478',
        ELSDLEA: '',
        UNSDLEA: '00032',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5088, 42.6219],
            [-91.2917, 42.6161],
            [-91.2924, 42.58],
            [-91.4128, 42.4416],
            [-91.4025, 42.2964],
            [-91.5978, 42.384],
            [-91.6086, 42.4984],
            [-91.5543, 42.6244],
            [-91.5088, 42.6219],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930900',
        NAME: 'West Delaware County Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 574074545,
        AWATER: 922978,
        INTPTLAT: '+42.4827108',
        INTPTLON: '-091.4783337',
        ELSDLEA: '',
        UNSDLEA: '30900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.253, 42.5584],
            [-91.1339, 42.5878],
            [-91.1328, 42.6458],
            [-90.8973, 42.653],
            [-90.8781, 42.4888],
            [-90.8191, 42.4853],
            [-90.7571, 42.295],
            [-90.7813, 42.2076],
            [-90.8838, 42.1713],
            [-91.0141, 42.2084],
            [-91.1699, 42.3395],
            [-91.209, 42.3828],
            [-91.2034, 42.5006],
            [-91.253, 42.5584],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931350',
        NAME: 'Western Dubuque Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1435778983,
        AWATER: 382252,
        INTPTLAT: '+42.4195420',
        INTPTLON: '-090.9818296',
        ELSDLEA: '',
        UNSDLEA: '31350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.0251, 42.9075],
            [-92.8298, 42.9073],
            [-92.7905, 42.9728],
            [-92.6728, 42.9362],
            [-92.6434, 42.8764],
            [-92.6828, 42.862],
            [-92.7469, 42.8256],
            [-92.7123, 42.7043],
            [-92.7318, 42.657],
            [-92.889, 42.7009],
            [-92.9561, 42.8367],
            [-93.0253, 42.8766],
            [-93.0251, 42.9075],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903450',
        NAME: 'North Butler Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 547218094,
        AWATER: 1340073,
        INTPTLAT: '+42.8292728',
        INTPTLON: '-092.8199309',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.364, 42.2091],
            [-91.1129, 42.1181],
            [-91.1316, 42.0983],
            [-91.2357, 42.0631],
            [-91.2488, 41.9764],
            [-91.3032, 41.9728],
            [-91.3749, 42.0164],
            [-91.4207, 42.1474],
            [-91.364, 42.2091],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903570',
        NAME: 'Anamosa Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344374038,
        AWATER: 1708914,
        INTPTLAT: '+42.0987177',
        INTPTLON: '-091.2929109',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4031, 42.253],
            [-91.3979, 42.2964],
            [-91.1988, 42.3032],
            [-91.1699, 42.3395],
            [-91.0141, 42.2084],
            [-91.0259, 42.1444],
            [-91.1129, 42.1181],
            [-91.364, 42.2091],
            [-91.4031, 42.253],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1919650',
        NAME: 'Monticello Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 438002635,
        AWATER: 219778,
        INTPTLAT: '+42.2249204',
        INTPTLON: '-091.1955308',
        ELSDLEA: '',
        UNSDLEA: '19650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0728, 42.1603],
            [-95.0335, 42.2103],
            [-94.8584, 42.2097],
            [-94.7452, 42.1519],
            [-94.7839, 42.1003],
            [-94.7839, 41.9206],
            [-94.8028, 41.8629],
            [-94.9081, 41.863],
            [-95.0152, 41.9577],
            [-95.0248, 42.0119],
            [-94.9755, 42.115],
            [-95.0728, 42.1603],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906330',
        NAME: 'Carroll Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 701146390,
        AWATER: 631837,
        INTPTLAT: '+42.0564960',
        INTPTLON: '-094.8891076',
        ELSDLEA: '',
        UNSDLEA: '06330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.551, 43.2126],
            [-94.4432, 43.2483],
            [-94.3938, 43.3709],
            [-94.2758, 43.2547],
            [-94.2658, 43.2548],
            [-94.286, 43.156],
            [-94.4426, 43.0829],
            [-94.4717, 43.1977],
            [-94.551, 43.2126],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1925560',
        NAME: 'Sentral Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351618859,
        AWATER: 0,
        INTPTLAT: '+43.2231766',
        INTPTLON: '-094.3834688',
        ELSDLEA: '',
        UNSDLEA: '25560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7877, 43.1692],
            [-94.7185, 43.1691],
            [-94.5901, 43.234],
            [-94.551, 43.2126],
            [-94.4717, 43.1977],
            [-94.4426, 43.0829],
            [-94.5101, 43.0316],
            [-94.8163, 42.9613],
            [-94.8469, 42.9759],
            [-94.7879, 43.0976],
            [-94.7877, 43.1692],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910950',
        NAME: 'Emmetsburg Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 597817285,
        AWATER: 3976489,
        INTPTLAT: '+43.1017365',
        INTPTLON: '-094.6515350',
        ELSDLEA: '',
        UNSDLEA: '10950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2415, 41.3738],
            [-94.2415, 41.3882],
            [-94.0663, 41.3881],
            [-94.0132, 41.4453],
            [-94.0076, 41.4418],
            [-94.0029, 41.4417],
            [-93.9788, 41.4346],
            [-93.8286, 41.5087],
            [-93.7906, 41.5119],
            [-93.7906, 41.465],
            [-93.8764, 41.4543],
            [-93.8084, 41.3405],
            [-93.9076, 41.2831],
            [-93.9376, 41.208],
            [-94.1279, 41.1568],
            [-94.1271, 41.2581],
            [-94.2416, 41.3016],
            [-94.2415, 41.3738],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931860',
        NAME: 'Winterset Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 713920272,
        AWATER: 1066731,
        INTPTLAT: '+41.3157238',
        INTPTLON: '-094.0100359',
        ELSDLEA: '',
        UNSDLEA: '31860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8939, 42.1306],
            [-92.7865, 42.1668],
            [-92.7668, 42.1235],
            [-92.5909, 42.1379],
            [-92.6636, 42.0356],
            [-92.7312, 42.0351],
            [-92.844, 42.0496],
            [-92.8939, 42.1306],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900060',
        NAME: 'GMG Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245386631,
        AWATER: 106427,
        INTPTLAT: '+42.1003023',
        INTPTLON: '-092.7376038',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3879, 43.3132],
            [-95.3486, 43.3133],
            [-95.3089, 43.2627],
            [-95.1999, 43.2496],
            [-95.2494, 43.2056],
            [-95.2206, 43.0836],
            [-95.1023, 43.0791],
            [-95.0929, 42.9838],
            [-95.2305, 42.9681],
            [-95.3686, 42.9998],
            [-95.398, 43.0541],
            [-95.3879, 43.3132],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907470',
        NAME: 'Clay Central-Everly Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559824208,
        AWATER: 982553,
        INTPTLAT: '+43.1053984',
        INTPTLON: '-095.2774081',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1999, 43.2496],
            [-95.0725, 43.2554],
            [-95.0118, 43.1412],
            [-94.992, 43.1124],
            [-94.9136, 43.0831],
            [-94.9138, 43.0032],
            [-95.0929, 42.9838],
            [-95.1023, 43.0791],
            [-95.2206, 43.0836],
            [-95.2494, 43.2056],
            [-95.1999, 43.2496],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926910',
        NAME: 'Spencer Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 432627756,
        AWATER: 3310630,
        INTPTLAT: '+43.1232608',
        INTPTLON: '-095.1026120',
        ELSDLEA: '',
        UNSDLEA: '26910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.484, 41.4238],
            [-91.3685, 41.4232],
            [-91.2873, 41.4229],
            [-91.3521, 41.3256],
            [-91.2344, 41.2271],
            [-91.3514, 41.205],
            [-91.4759, 41.2351],
            [-91.4853, 41.2568],
            [-91.484, 41.4238],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907980',
        NAME: 'Columbus Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350058264,
        AWATER: 8789755,
        INTPTLAT: '+41.3112006',
        INTPTLON: '-091.3864236',
        ELSDLEA: '',
        UNSDLEA: '07980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2803, 41.4301],
            [-91.1454, 41.5089],
            [-90.944, 41.5223],
            [-90.8527, 41.5352],
            [-90.7863, 41.4529],
            [-91.0483, 41.4097],
            [-91.0645, 41.3699],
            [-91.1956, 41.3709],
            [-91.2803, 41.4301],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920130',
        NAME: 'Muscatine Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403727046,
        AWATER: 17837018,
        INTPTLAT: '+41.4594026',
        INTPTLON: '-091.0526017',
        ELSDLEA: '',
        UNSDLEA: '20130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.9354, 41.5897],
            [-93.8286, 41.5232],
            [-93.8286, 41.5087],
            [-93.9788, 41.4346],
            [-93.9835, 41.5464],
            [-93.9354, 41.5897],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1929010',
        NAME: 'Van Meter Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149883582,
        AWATER: 2792766,
        INTPTLAT: '+41.5048977',
        INTPTLON: '-093.9267444',
        ELSDLEA: '',
        UNSDLEA: '29010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8469, 42.0301],
            [-95.7491, 42.1173],
            [-95.6711, 42.1322],
            [-95.479, 42.0955],
            [-95.5176, 42.0297],
            [-95.5374, 41.9938],
            [-95.7013, 41.9284],
            [-95.7785, 41.9284],
            [-95.8375, 41.9574],
            [-95.8469, 42.0301],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907110',
        NAME: 'Charter Oak-Ute Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475961875,
        AWATER: 782141,
        INTPTLAT: '+42.0304158',
        INTPTLON: '-095.6616602',
        ELSDLEA: '',
        UNSDLEA: '07110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.25, 43.3974],
            [-95.0325, 43.3712],
            [-95.0725, 43.2554],
            [-95.1999, 43.2496],
            [-95.3089, 43.2627],
            [-95.3486, 43.3133],
            [-95.25, 43.3974],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900021',
        NAME: 'Okoboji Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 326917949,
        AWATER: 17031898,
        INTPTLAT: '+43.3216958',
        INTPTLON: '-095.1761205',
        ELSDLEA: '',
        UNSDLEA: '00021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0154, 40.7264],
            [-93.8868, 40.6917],
            [-93.8153, 40.6345],
            [-93.8248, 40.577],
            [-94.0721, 40.573],
            [-94.0097, 40.6677],
            [-94.0154, 40.7264],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1916320',
        NAME: 'Lamoni Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 272833283,
        AWATER: 2088250,
        INTPTLAT: '+40.6454737',
        INTPTLON: '-093.9484404',
        ELSDLEA: '',
        UNSDLEA: '16320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0148, 40.8828],
            [-93.9208, 40.8682],
            [-93.7864, 40.8486],
            [-93.7051, 40.8708],
            [-93.6087, 40.7324],
            [-93.6137, 40.6636],
            [-93.6331, 40.5787],
            [-93.6999, 40.5778],
            [-93.7743, 40.5775],
            [-93.8014, 40.5773],
            [-93.8248, 40.577],
            [-93.8153, 40.6345],
            [-93.8868, 40.6917],
            [-94.0154, 40.7264],
            [-94.0148, 40.8828],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906900',
        NAME: 'Central Decatur Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 762507552,
        AWATER: 2100650,
        INTPTLAT: '+40.7342817',
        INTPTLON: '-093.7992598',
        ELSDLEA: '',
        UNSDLEA: '06900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5965, 40.8989],
            [-92.5723, 40.899],
            [-92.4097, 40.8991],
            [-92.2507, 40.8995],
            [-92.1783, 40.8138],
            [-92.1798, 40.6005],
            [-92.3508, 40.5973],
            [-92.6184, 40.5913],
            [-92.638, 40.6204],
            [-92.6194, 40.8634],
            [-92.5965, 40.8989],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908610',
        NAME: 'Davis County Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1223340500,
        AWATER: 6030705,
        INTPTLAT: '+40.7461739',
        INTPTLON: '-092.4044487',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1783, 40.8138],
            [-92.113, 40.8786],
            [-92.0929, 40.8898],
            [-91.7523, 40.9006],
            [-91.7867, 40.7903],
            [-91.8891, 40.7696],
            [-91.8696, 40.608],
            [-91.9073, 40.607],
            [-91.9431, 40.6058],
            [-92.1798, 40.6005],
            [-92.1783, 40.8138],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1928980',
        NAME: 'Van Buren Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 904063280,
        AWATER: 9157020,
        INTPTLAT: '+40.7591419',
        INTPTLON: '-091.9908370',
        ELSDLEA: '',
        UNSDLEA: '28980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1849, 40.7858],
            [-95.128, 40.872],
            [-95.1187, 40.8791],
            [-95.0615, 40.8718],
            [-94.8527, 40.8386],
            [-94.8333, 40.7775],
            [-94.9147, 40.6497],
            [-95.1871, 40.6901],
            [-95.1906, 40.7229],
            [-95.1849, 40.7858],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907350',
        NAME: 'Clarinda Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550461474,
        AWATER: 675332,
        INTPTLAT: '+40.7584501',
        INTPTLON: '-095.0088281',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7038, 43.2874],
            [-95.4579, 43.3425],
            [-95.3879, 43.3132],
            [-95.398, 43.0541],
            [-95.5053, 43.0609],
            [-95.5847, 43.1642],
            [-95.664, 43.128],
            [-95.7425, 43.1716],
            [-95.7038, 43.2874],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913660',
        NAME: 'Hartley-Melvin-Sanborn Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 604917033,
        AWATER: 37858,
        INTPTLAT: '+43.2167017',
        INTPTLON: '-095.5381335',
        ELSDLEA: '',
        UNSDLEA: '13660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.533, 41.5249],
            [-90.5255, 41.5963],
            [-90.4931, 41.5966],
            [-90.4743, 41.5197],
            [-90.533, 41.5249],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904860',
        NAME: 'Bettendorf Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24189414,
        AWATER: 1596177,
        INTPTLAT: '+41.5535314',
        INTPTLON: '-090.5051959',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4931, 41.5966],
            [-90.4518, 41.6554],
            [-90.3402, 41.6556],
            [-90.3561, 41.5828],
            [-90.4251, 41.5526],
            [-90.4614, 41.5235],
            [-90.4743, 41.5197],
            [-90.4931, 41.5966],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1923110',
        NAME: 'Pleasant Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107328083,
        AWATER: 8450267,
        INTPTLAT: '+41.6011546',
        INTPTLON: '-090.4146965',
        ELSDLEA: '',
        UNSDLEA: '23110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6084, 42.8129],
            [-91.5967, 42.8695],
            [-91.4986, 42.9493],
            [-91.4685, 42.957],
            [-91.3341, 42.9501],
            [-91.3128, 42.7801],
            [-91.2507, 42.7324],
            [-91.4374, 42.7316],
            [-91.4878, 42.7898],
            [-91.6084, 42.8129],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906840',
        NAME: 'Central Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 475517301,
        AWATER: 126038,
        INTPTLAT: '+42.8304452',
        INTPTLON: '-091.4523754',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8576, 42.3482],
            [-94.7078, 42.3611],
            [-94.6489, 42.3034],
            [-94.5335, 42.354],
            [-94.4365, 42.2671],
            [-94.4364, 42.2093],
            [-94.5228, 42.2093],
            [-94.629, 42.1302],
            [-94.7452, 42.1519],
            [-94.8584, 42.2097],
            [-94.8576, 42.3482],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900024',
        NAME: 'Southern Cal Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 573016924,
        AWATER: 1805036,
        INTPTLAT: '+42.2536615',
        INTPTLON: '-094.6670777',
        ELSDLEA: '',
        UNSDLEA: '00024',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9145, 42.5178],
            [-94.8061, 42.5172],
            [-94.7964, 42.4811],
            [-94.6238, 42.4956],
            [-94.4955, 42.4354],
            [-94.5335, 42.354],
            [-94.6489, 42.3034],
            [-94.7078, 42.3611],
            [-94.8576, 42.3482],
            [-94.9352, 42.3851],
            [-94.9145, 42.5178],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1924720',
        NAME: 'Rockwell City-Lytton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 519233903,
        AWATER: 3962130,
        INTPTLAT: '+42.4210914',
        INTPTLON: '-094.7240297',
        ELSDLEA: '',
        UNSDLEA: '24720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7098, 41.746],
            [-93.5413, 41.7768],
            [-93.5027, 41.7462],
            [-93.5418, 41.6883],
            [-93.6791, 41.688],
            [-93.7098, 41.746],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903690',
        NAME: 'Ankeny Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128500814,
        AWATER: 5453772,
        INTPTLAT: '+41.7352157',
        INTPTLON: '-093.6111934',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0625, 42.2973],
            [-91.8894, 42.3054],
            [-91.8895, 42.2982],
            [-91.9485, 42.2638],
            [-91.9208, 42.174],
            [-91.8333, 42.1762],
            [-91.8353, 42.037],
            [-92.0664, 42.0943],
            [-92.2026, 42.0945],
            [-92.1811, 42.2395],
            [-92.0625, 42.2973],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1929310',
        NAME: 'Vinton-Shellsburg Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 597476672,
        AWATER: 4839753,
        INTPTLAT: '+42.1668968',
        INTPTLON: '-092.0173444',
        ELSDLEA: '',
        UNSDLEA: '29310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7751, 42.7305],
            [-91.7261, 42.8027],
            [-91.7159, 42.8062],
            [-91.6084, 42.8129],
            [-91.4878, 42.7898],
            [-91.4374, 42.7316],
            [-91.5088, 42.6219],
            [-91.5543, 42.6244],
            [-91.6086, 42.4984],
            [-91.7274, 42.5376],
            [-91.7056, 42.6432],
            [-91.7751, 42.7305],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927270',
        NAME: 'Starmont Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 536406475,
        AWATER: 206207,
        INTPTLAT: '+42.6761178',
        INTPTLON: '-091.6162719',
        ELSDLEA: '',
        UNSDLEA: '27270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9635, 42.8912],
            [-91.8351, 42.88],
            [-91.8446, 42.8129],
            [-91.7261, 42.8027],
            [-91.7751, 42.7305],
            [-91.9617, 42.7296],
            [-92.0008, 42.7001],
            [-92.0712, 42.7804],
            [-91.9638, 42.8184],
            [-91.9635, 42.8912],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930870',
        NAME: 'West Central Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 327871337,
        AWATER: 172066,
        INTPTLAT: '+42.7918062',
        INTPTLON: '-091.9106833',
        ELSDLEA: '',
        UNSDLEA: '30870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6791, 41.688],
            [-93.5418, 41.6883],
            [-93.537, 41.6586],
            [-93.5467, 41.6368],
            [-93.6412, 41.6368],
            [-93.6791, 41.688],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1925320',
        NAME: 'Saydel Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58445118,
        AWATER: 301765,
        INTPTLAT: '+41.6639829',
        INTPTLON: '-093.6059580',
        ELSDLEA: '',
        UNSDLEA: '25320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8144, 41.6511],
            [-93.7716, 41.644],
            [-93.6977, 41.6367],
            [-93.7122, 41.6221],
            [-93.8143, 41.6221],
            [-93.8144, 41.6511],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1928680',
        NAME: 'Urbandale Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22520880,
        AWATER: 17662,
        INTPTLAT: '+41.6348284',
        INTPTLON: '-093.7602267',
        ELSDLEA: '',
        UNSDLEA: '28680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8631, 41.0937],
            [-95.866, 41.1024],
            [-95.7942, 41.1599],
            [-95.7087, 41.16],
            [-95.5945, 41.1312],
            [-95.671, 40.9873],
            [-95.7897, 40.9124],
            [-95.8394, 40.9441],
            [-95.833, 40.9587],
            [-95.8795, 41.0533],
            [-95.8631, 41.0937],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1912690',
        NAME: 'Glenwood Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422179001,
        AWATER: 4981765,
        INTPTLAT: '+41.0579415',
        INTPTLON: '-095.7462066',
        ELSDLEA: '',
        UNSDLEA: '12690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6605, 40.7628],
            [-91.507, 40.7259],
            [-91.4882, 40.5885],
            [-91.3697, 40.5888],
            [-91.3637, 40.4931],
            [-91.3675, 40.4762],
            [-91.4717, 40.4983],
            [-91.5237, 40.4556],
            [-91.6912, 40.5657],
            [-91.6605, 40.7628],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906930',
        NAME: 'Central Lee Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 517352400,
        AWATER: 22878040,
        INTPTLAT: '+40.5936952',
        INTPTLON: '-091.5490845',
        ELSDLEA: '',
        UNSDLEA: '06930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7051, 40.8708],
            [-93.6435, 40.9575],
            [-93.5567, 40.9864],
            [-93.4035, 40.9557],
            [-93.3845, 40.907],
            [-93.494, 40.7538],
            [-93.6137, 40.6636],
            [-93.6087, 40.7324],
            [-93.7051, 40.8708],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1919740',
        NAME: 'Mormon Trail Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 527759660,
        AWATER: 456160,
        INTPTLAT: '+40.8498089',
        INTPTLON: '-093.5509224',
        ELSDLEA: '',
        UNSDLEA: '19740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8973, 42.653],
            [-90.897, 42.6751],
            [-90.8282, 42.6608],
            [-90.7202, 42.6408],
            [-90.6615, 42.568],
            [-90.6428, 42.5085],
            [-90.5273, 42.4063],
            [-90.4738, 42.3815],
            [-90.6468, 42.302],
            [-90.6655, 42.3021],
            [-90.7571, 42.295],
            [-90.8191, 42.4853],
            [-90.8781, 42.4888],
            [-90.8973, 42.653],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1909480',
        NAME: 'Dubuque Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 657434285,
        AWATER: 21058643,
        INTPTLAT: '+42.4755166',
        INTPTLON: '-090.7289062',
        ELSDLEA: '',
        UNSDLEA: '09480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6468, 42.302],
            [-90.4738, 42.3815],
            [-90.4184, 42.3276],
            [-90.3911, 42.2255],
            [-90.3179, 42.1939],
            [-90.3066, 42.1905],
            [-90.5013, 42.1278],
            [-90.5357, 42.2072],
            [-90.6468, 42.302],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904650',
        NAME: 'Bellevue Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347400748,
        AWATER: 13300086,
        INTPTLAT: '+42.2439248',
        INTPTLON: '-090.4688167',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5945, 41.1312],
            [-95.5564, 41.1601],
            [-95.3843, 41.16],
            [-95.3847, 40.916],
            [-95.4609, 40.9015],
            [-95.4993, 40.9016],
            [-95.6143, 40.9016],
            [-95.671, 40.9873],
            [-95.5945, 41.1312],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918240',
        NAME: 'East Mills Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 567579014,
        AWATER: 2041304,
        INTPTLAT: '+41.0266161',
        INTPTLON: '-095.5081719',
        ELSDLEA: '',
        UNSDLEA: '18240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8838, 42.1713],
            [-90.7813, 42.2076],
            [-90.7571, 42.295],
            [-90.6655, 42.3021],
            [-90.6658, 42.1785],
            [-90.6262, 42.0807],
            [-90.5183, 42.1015],
            [-90.5487, 42.0328],
            [-90.782, 42.0402],
            [-90.8838, 42.1713],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918510',
        NAME: 'Maquoketa Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445272765,
        AWATER: 1743690,
        INTPTLAT: '+42.1366632',
        INTPTLON: '-090.7444355',
        ELSDLEA: '',
        UNSDLEA: '18510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.8028, 41.8629],
            [-94.7839, 41.9206],
            [-94.6292, 41.9792],
            [-94.5127, 41.9424],
            [-94.477, 41.8697],
            [-94.4689, 41.7768],
            [-94.7157, 41.7469],
            [-94.8028, 41.8629],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908070',
        NAME: 'Coon Rapids-Bayard Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 478642993,
        AWATER: 233455,
        INTPTLAT: '+41.8611007',
        INTPTLON: '-094.6388148',
        ELSDLEA: '',
        UNSDLEA: '08070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7441, 41.6673],
            [-94.7157, 41.7469],
            [-94.4689, 41.7768],
            [-94.4447, 41.7529],
            [-94.437, 41.7293],
            [-94.3863, 41.6088],
            [-94.4712, 41.5614],
            [-94.5098, 41.6018],
            [-94.7147, 41.6094],
            [-94.7441, 41.6673],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913320',
        NAME: 'Guthrie Center Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 480937855,
        AWATER: 187705,
        INTPTLAT: '+41.6825013',
        INTPTLON: '-094.5787721',
        ELSDLEA: '',
        UNSDLEA: '13320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9248, 43.5009],
            [-94.8546, 43.5009],
            [-94.7445, 43.501],
            [-94.5673, 43.5004],
            [-94.581, 43.4307],
            [-94.6695, 43.2985],
            [-94.8657, 43.3065],
            [-94.9641, 43.425],
            [-94.9248, 43.5009],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1911070',
        NAME: 'Estherville Lincoln Central Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 515856189,
        AWATER: 12976195,
        INTPTLAT: '+43.4067776',
        INTPTLON: '-094.7581559',
        ELSDLEA: '',
        UNSDLEA: '11070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5858, 40.7627],
            [-94.5185, 40.8486],
            [-94.4345, 40.8562],
            [-94.4098, 40.7547],
            [-94.4998, 40.7048],
            [-94.5858, 40.7627],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907650',
        NAME: 'Clearfield Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188166532,
        AWATER: 689545,
        INTPTLAT: '+40.7819592',
        INTPTLON: '-094.4809478',
        ELSDLEA: '',
        UNSDLEA: '07650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.9276, 41.1446],
            [-94.9099, 41.1585],
            [-94.7006, 41.1581],
            [-94.661, 41.1579],
            [-94.6806, 41.0154],
            [-94.6096, 40.9719],
            [-94.7003, 40.8347],
            [-94.8289, 40.8424],
            [-94.8527, 40.9441],
            [-94.9256, 41.0083],
            [-94.9276, 41.1446],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908130',
        NAME: 'Corning Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 668417453,
        AWATER: 4902402,
        INTPTLAT: '+41.0090723',
        INTPTLON: '-094.7688123',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.4098, 40.7547],
            [-94.3002, 40.74],
            [-94.2625, 40.8694],
            [-94.0257, 40.8971],
            [-94.0148, 40.8828],
            [-94.0154, 40.7264],
            [-94.0097, 40.6677],
            [-94.0721, 40.573],
            [-94.2322, 40.5719],
            [-94.4432, 40.571],
            [-94.4713, 40.6757],
            [-94.4998, 40.7048],
            [-94.4098, 40.7547],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1919860',
        NAME: 'Mount Ayr Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 987196916,
        AWATER: 6841596,
        INTPTLAT: '+40.7131873',
        INTPTLON: '-094.2092609',
        ELSDLEA: '',
        UNSDLEA: '19860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.0788, 43.1263],
            [-93.9704, 43.1331],
            [-93.8618, 43],
            [-93.8824, 42.8882],
            [-93.9712, 42.9078],
            [-94.04, 42.9582],
            [-94.04, 43.0525],
            [-94.0788, 43.1263],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908190',
        NAME: 'Corwith-Wesley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 265795368,
        AWATER: 0,
        INTPTLAT: '+43.0166068',
        INTPTLON: '-093.9659972',
        ELSDLEA: '',
        UNSDLEA: '08190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.4986, 42.8573],
            [-93.3408, 42.877],
            [-93.2955, 42.819],
            [-93.3012, 42.7021],
            [-93.3405, 42.655],
            [-93.4865, 42.7385],
            [-93.4986, 42.8573],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1905970',
        NAME: 'Cal Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313068828,
        AWATER: 186855,
        INTPTLAT: '+42.7890749',
        INTPTLON: '-093.4093613',
        ELSDLEA: '',
        UNSDLEA: '05970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1728, 41.696],
            [-92.0722, 41.7023],
            [-91.9936, 41.7708],
            [-91.8525, 41.723],
            [-91.7939, 41.6001],
            [-91.9055, 41.5403],
            [-92.1827, 41.63],
            [-92.1728, 41.696],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931680',
        NAME: 'Williamsburg Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 535246451,
        AWATER: 584555,
        INTPTLAT: '+41.6480491',
        INTPTLON: '-091.9741467',
        ELSDLEA: '',
        UNSDLEA: '31680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5954, 41.5398],
            [-91.523, 41.5398],
            [-91.484, 41.4238],
            [-91.4853, 41.2568],
            [-91.6008, 41.2506],
            [-91.5993, 41.4103],
            [-91.6378, 41.4247],
            [-91.5954, 41.5398],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914010',
        NAME: 'Highland Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 313173772,
        AWATER: 1115748,
        INTPTLAT: '+41.3986438',
        INTPTLON: '-091.5541199',
        ELSDLEA: '',
        UNSDLEA: '14010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9868, 41.8247],
            [-91.8312, 41.8255],
            [-91.7427, 41.7823],
            [-91.6332, 41.8098],
            [-91.6171, 41.7883],
            [-91.5986, 41.6715],
            [-91.7123, 41.6],
            [-91.7939, 41.6001],
            [-91.8525, 41.723],
            [-91.9936, 41.7708],
            [-91.9868, 41.8247],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1907590',
        NAME: 'Clear Creek-Amana Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 453096990,
        AWATER: 3214227,
        INTPTLAT: '+41.7293541',
        INTPTLON: '-091.7775387',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6332, 41.8098],
            [-91.5593, 41.8713],
            [-91.4132, 41.8598],
            [-91.366, 41.8165],
            [-91.366, 41.7837],
            [-91.4637, 41.7146],
            [-91.6171, 41.7883],
            [-91.6332, 41.8098],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926580',
        NAME: 'Solon Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218523239,
        AWATER: 10966313,
        INTPTLAT: '+41.8002107',
        INTPTLON: '-091.4925299',
        ELSDLEA: '',
        UNSDLEA: '26580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.477, 41.8697],
            [-94.2803, 41.892],
            [-94.2503, 41.8631],
            [-94.2807, 41.7317],
            [-94.2219, 41.7173],
            [-94.2026, 41.6955],
            [-94.2026, 41.6296],
            [-94.3863, 41.6088],
            [-94.437, 41.7293],
            [-94.4447, 41.7529],
            [-94.4689, 41.7768],
            [-94.477, 41.8697],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900025',
        NAME: 'Panorama Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 489861988,
        AWATER: 5577008,
        INTPTLAT: '+41.7414866',
        INTPTLON: '-094.3382121',
        ELSDLEA: '',
        UNSDLEA: '00025',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.8691, 41.9214],
            [-93.7373, 41.9218],
            [-93.7375, 41.8634],
            [-93.7761, 41.7956],
            [-93.8691, 41.8685],
            [-93.8691, 41.9214],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918180',
        NAME: 'Madrid Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106954948,
        AWATER: 4096989,
        INTPTLAT: '+41.8713052',
        INTPTLON: '-093.8021682',
        ELSDLEA: '',
        UNSDLEA: '18180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.2219, 41.7173],
            [-94.0964, 41.7174],
            [-94.0796, 41.7769],
            [-93.9893, 41.806],
            [-93.9357, 41.7683],
            [-94.0477, 41.7138],
            [-93.9504, 41.6222],
            [-93.9354, 41.5897],
            [-93.9835, 41.5464],
            [-93.9788, 41.4346],
            [-94.0029, 41.4417],
            [-94.0076, 41.4418],
            [-94.0132, 41.4453],
            [-94.104, 41.5572],
            [-94.0945, 41.5861],
            [-94.2026, 41.6955],
            [-94.2219, 41.7173],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903150',
        NAME: 'Adel-De Soto-Minburn Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365145409,
        AWATER: 1994603,
        INTPTLAT: '+41.6453645',
        INTPTLON: '-094.0467068',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4485, 41.0167],
            [-91.3711, 41.0159],
            [-91.334, 40.9585],
            [-91.4104, 40.8782],
            [-91.4093, 40.8127],
            [-91.4479, 40.813],
            [-91.4485, 41.0167],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920460',
        NAME: 'New London Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172905655,
        AWATER: 1428839,
        INTPTLAT: '+40.9229606',
        INTPTLON: '-091.4052088',
        ELSDLEA: '',
        UNSDLEA: '20460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.7452, 42.1519],
            [-94.629, 42.1302],
            [-94.5958, 42.1229],
            [-94.6292, 41.9792],
            [-94.7839, 41.9206],
            [-94.7839, 42.1003],
            [-94.7452, 42.1519],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1912750',
        NAME: 'Glidden-Ralston Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 298991727,
        AWATER: 1253476,
        INTPTLAT: '+42.0592424',
        INTPTLON: '-094.6992904',
        ELSDLEA: '',
        UNSDLEA: '12750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6292, 41.9792],
            [-94.5958, 42.1229],
            [-94.4266, 42.0926],
            [-94.3007, 42.1739],
            [-94.2906, 42.1293],
            [-94.3107, 41.9646],
            [-94.2803, 41.892],
            [-94.477, 41.8697],
            [-94.5127, 41.9424],
            [-94.6292, 41.9792],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1915210',
        NAME: 'Jefferson-Scranton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 630000656,
        AWATER: 2918462,
        INTPTLAT: '+42.0039015',
        INTPTLON: '-094.4445893',
        ELSDLEA: '',
        UNSDLEA: '15210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4112, 41.3361],
            [-92.2199, 41.4239],
            [-92.0837, 41.3954],
            [-92.0834, 41.2813],
            [-92.1903, 41.2342],
            [-92.3537, 41.262],
            [-92.4108, 41.2715],
            [-92.4112, 41.3361],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926280',
        NAME: 'Sigourney Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 432962879,
        AWATER: 435576,
        INTPTLAT: '+41.3154846',
        INTPTLON: '-092.2286196',
        ELSDLEA: '',
        UNSDLEA: '26280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3847, 40.916],
            [-95.3847, 40.9087],
            [-95.385, 40.9016],
            [-95.3278, 40.8008],
            [-95.1849, 40.7858],
            [-95.1906, 40.7229],
            [-95.2856, 40.7091],
            [-95.259, 40.5791],
            [-95.3739, 40.5805],
            [-95.3728, 40.6766],
            [-95.4337, 40.7717],
            [-95.4609, 40.9015],
            [-95.3847, 40.916],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926070',
        NAME: 'Shenandoah Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378888269,
        AWATER: 328614,
        INTPTLAT: '+40.7355273',
        INTPTLON: '-095.3469851',
        ELSDLEA: '',
        UNSDLEA: '26070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.1906, 40.7229],
            [-95.1871, 40.6901],
            [-94.9147, 40.6497],
            [-94.9147, 40.6316],
            [-94.9148, 40.6278],
            [-94.9149, 40.5751],
            [-94.9533, 40.5756],
            [-95.2023, 40.5785],
            [-95.259, 40.5791],
            [-95.2856, 40.7091],
            [-95.1906, 40.7229],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1926670',
        NAME: 'South Page Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 361814573,
        AWATER: 319898,
        INTPTLAT: '+40.6328167',
        INTPTLON: '-095.1133165',
        ELSDLEA: '',
        UNSDLEA: '26670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.0278, 41.0798],
            [-94.9276, 41.1446],
            [-94.9256, 41.0083],
            [-94.8527, 40.9441],
            [-94.8289, 40.8424],
            [-94.8527, 40.8386],
            [-95.0615, 40.8718],
            [-95.07, 40.9407],
            [-95.0278, 41.0798],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1929280',
        NAME: 'Villisca Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 413277412,
        AWATER: 812059,
        INTPTLAT: '+40.9722660',
        INTPTLON: '-094.9533786',
        ELSDLEA: '',
        UNSDLEA: '29280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.4978, 41.6023],
            [-95.3662, 41.602],
            [-95.2887, 41.5603],
            [-95.266, 41.5528],
            [-95.27, 41.354],
            [-95.5072, 41.3902],
            [-95.5362, 41.5566],
            [-95.4978, 41.6023],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904080',
        NAME: 'A-H-S-T Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491540132,
        AWATER: 2064332,
        INTPTLAT: '+41.4836590',
        INTPTLON: '-095.3919969',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7899, 41.8771],
            [-95.7407, 41.8348],
            [-95.5462, 41.7724],
            [-95.5918, 41.6388],
            [-95.7079, 41.6604],
            [-95.8569, 41.7763],
            [-95.7899, 41.8771],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931920',
        NAME: 'Woodbine Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 373540184,
        AWATER: 1016596,
        INTPTLAT: '+41.7617348',
        INTPTLON: '-095.7191333',
        ELSDLEA: '',
        UNSDLEA: '31920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.1091, 41.836],
            [-95.9614, 41.8635],
            [-95.8375, 41.9574],
            [-95.7785, 41.9284],
            [-95.7899, 41.8771],
            [-95.8569, 41.7763],
            [-95.9249, 41.7502],
            [-95.906, 41.6573],
            [-95.9464, 41.5901],
            [-96.0823, 41.5807],
            [-96.0953, 41.6503],
            [-96.1199, 41.6926],
            [-96.0649, 41.7917],
            [-96.1091, 41.836],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930960',
        NAME: 'West Harrison Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 609299464,
        AWATER: 6306872,
        INTPTLAT: '+41.7640828',
        INTPTLON: '-095.9623809',
        ELSDLEA: '',
        UNSDLEA: '30960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.7749, 41.5427],
            [-95.5919, 41.6315],
            [-95.4978, 41.6023],
            [-95.5362, 41.5566],
            [-95.5072, 41.3902],
            [-95.5273, 41.3904],
            [-95.6695, 41.4413],
            [-95.824, 41.4191],
            [-95.7749, 41.5427],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1927990',
        NAME: 'Tri-Center Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 444519822,
        AWATER: 1312233,
        INTPTLAT: '+41.5022952',
        INTPTLON: '-095.6502810',
        ELSDLEA: '',
        UNSDLEA: '27990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8569, 41.7763],
            [-95.7079, 41.6604],
            [-95.5918, 41.6388],
            [-95.5919, 41.6315],
            [-95.7749, 41.5427],
            [-95.906, 41.6573],
            [-95.9249, 41.7502],
            [-95.8569, 41.7763],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1917460',
        NAME: 'Logan-Magnolia Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338563410,
        AWATER: 400181,
        INTPTLAT: '+41.6513109',
        INTPTLON: '-095.7885907',
        ELSDLEA: '',
        UNSDLEA: '17460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5918, 41.6388],
            [-95.5462, 41.7724],
            [-95.4022, 41.8635],
            [-95.3546, 41.8639],
            [-95.3495, 41.864],
            [-95.3254, 41.7178],
            [-95.2862, 41.6889],
            [-95.1026, 41.6882],
            [-95.1934, 41.5635],
            [-95.266, 41.5528],
            [-95.2887, 41.5603],
            [-95.3662, 41.602],
            [-95.4978, 41.6023],
            [-95.5919, 41.6315],
            [-95.5918, 41.6388],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913500',
        NAME: 'Harlan Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 721071127,
        AWATER: 1124398,
        INTPTLAT: '+41.6959503',
        INTPTLON: '-095.3723424',
        ELSDLEA: '',
        UNSDLEA: '13500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.2177, 41.8664],
            [-93.058, 41.8626],
            [-93.0497, 41.8626],
            [-93.1161, 41.7539],
            [-93.1788, 41.7578],
            [-93.2901, 41.8202],
            [-93.2177, 41.8664],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1904380',
        NAME: 'Baxter Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176482933,
        AWATER: 297738,
        INTPTLAT: '+41.8280714',
        INTPTLON: '-093.1511746',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1788, 41.7578],
            [-93.1161, 41.7539],
            [-93.0497, 41.8626],
            [-93.0192, 41.8625],
            [-92.9757, 41.8089],
            [-92.8833, 41.8273],
            [-92.8468, 41.6881],
            [-92.9416, 41.6667],
            [-92.9961, 41.588],
            [-93.1447, 41.6372],
            [-93.1788, 41.7578],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920610',
        NAME: 'Newton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 507631421,
        AWATER: 758304,
        INTPTLAT: '+41.7214055',
        INTPTLON: '-093.0196448',
        ELSDLEA: '',
        UNSDLEA: '20610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3095, 41.8202],
            [-93.2901, 41.8202],
            [-93.1788, 41.7578],
            [-93.1447, 41.6372],
            [-93.3485, 41.6588],
            [-93.3484, 41.6844],
            [-93.3095, 41.8202],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900009',
        NAME: 'Colfax-Mingo Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278302501,
        AWATER: 1977100,
        INTPTLAT: '+41.7246818',
        INTPTLON: '-093.2560863',
        ELSDLEA: '',
        UNSDLEA: '00009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8833, 41.8273],
            [-92.6885, 41.8623],
            [-92.5331, 41.8622],
            [-92.5713, 41.7172],
            [-92.619, 41.6878],
            [-92.6497, 41.6018],
            [-92.8468, 41.6881],
            [-92.8833, 41.8273],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913200',
        NAME: 'Grinnell-Newburg Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 571852836,
        AWATER: 2628646,
        INTPTLAT: '+41.7526634',
        INTPTLON: '-092.7138115',
        ELSDLEA: '',
        UNSDLEA: '13200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5237, 40.4556],
            [-91.4717, 40.4983],
            [-91.3675, 40.4762],
            [-91.4194, 40.3783],
            [-91.5237, 40.4556],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1915630',
        NAME: 'Keokuk Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115146518,
        AWATER: 8475246,
        INTPTLAT: '+40.4319511',
        INTPTLON: '-091.4446218',
        ELSDLEA: '',
        UNSDLEA: '15630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7523, 40.9006],
            [-91.7361, 40.9007],
            [-91.6607, 40.8427],
            [-91.6747, 40.7776],
            [-91.6605, 40.7628],
            [-91.6912, 40.5657],
            [-91.7291, 40.6136],
            [-91.8696, 40.608],
            [-91.8891, 40.7696],
            [-91.7867, 40.7903],
            [-91.7523, 40.9006],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1913530',
        NAME: 'Harmony Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 430514086,
        AWATER: 5153537,
        INTPTLAT: '+40.7180089',
        INTPTLON: '-091.7524017',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.5673, 43.5004],
            [-94.4375, 43.5004],
            [-94.3947, 43.5003],
            [-94.3938, 43.3709],
            [-94.4432, 43.2483],
            [-94.551, 43.2126],
            [-94.5901, 43.234],
            [-94.6695, 43.2985],
            [-94.581, 43.4307],
            [-94.5673, 43.5004],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903850',
        NAME: 'Armstrong-Ringsted Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 493078426,
        AWATER: 1630966,
        INTPTLAT: '+43.3635187',
        INTPTLON: '-094.5321705',
        ELSDLEA: '',
        UNSDLEA: '03850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.6958, 42.5575],
            [-93.6275, 42.5866],
            [-93.4995, 42.5577],
            [-93.4628, 42.4486],
            [-93.5018, 42.3981],
            [-93.733, 42.3613],
            [-93.6958, 42.5575],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1921120',
        NAME: 'Northeast Hamilton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378366803,
        AWATER: 0,
        INTPTLAT: '+42.4708383',
        INTPTLON: '-093.6105878',
        ELSDLEA: '',
        UNSDLEA: '21120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.7561, 41.7768],
            [-93.7098, 41.746],
            [-93.6791, 41.688],
            [-93.6412, 41.6368],
            [-93.6977, 41.6367],
            [-93.7716, 41.644],
            [-93.8145, 41.7175],
            [-93.7561, 41.7768],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1915450',
        NAME: 'Johnston Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99301357,
        AWATER: 10058002,
        INTPTLAT: '+41.6996049',
        INTPTLON: '-093.7343263',
        ELSDLEA: '',
        UNSDLEA: '15450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.844, 42.0496],
            [-92.7312, 42.0351],
            [-92.7572, 41.9818],
            [-92.6885, 41.8623],
            [-92.8833, 41.8273],
            [-92.9757, 41.8089],
            [-93.0192, 41.8625],
            [-92.8833, 41.9709],
            [-92.844, 42.0496],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900040',
        NAME: 'East Marshall Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 420316940,
        AWATER: 392347,
        INTPTLAT: '+41.9158230',
        INTPTLON: '-092.8510332',
        ELSDLEA: '',
        UNSDLEA: '00040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.5, 43.5],
            [-95.3946, 43.5002],
            [-95.2013, 43.5008],
            [-95.25, 43.3974],
            [-95.3486, 43.3133],
            [-95.3879, 43.3132],
            [-95.4579, 43.3425],
            [-95.5, 43.5],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1916140',
        NAME: 'Harris-Lake Park Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 350985593,
        AWATER: 5608908,
        INTPTLAT: '+43.4227843',
        INTPTLON: '-095.3483603',
        ELSDLEA: '',
        UNSDLEA: '16140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.8317, 43.5],
            [-95.774, 43.4999],
            [-95.5651, 43.4999],
            [-95.5, 43.5],
            [-95.4579, 43.3425],
            [-95.7038, 43.2874],
            [-95.8613, 43.2947],
            [-95.8317, 43.5],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900015',
        NAME: 'Sibley-Ocheyedan Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 607072036,
        AWATER: 1032879,
        INTPTLAT: '+43.3966244',
        INTPTLON: '-095.6728129',
        ELSDLEA: '',
        UNSDLEA: '00015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1797, 40.8703],
            [-91.0232, 40.8828],
            [-91.0969, 40.8114],
            [-91.1145, 40.7206],
            [-91.1125, 40.6963],
            [-91.258, 40.752],
            [-91.2572, 40.798],
            [-91.1797, 40.8703],
          ],
          [
            [-91.1799, 40.8386],
            [-91.1799, 40.8117],
            [-91.153, 40.8114],
            [-91.1608, 40.8407],
            [-91.1799, 40.8386],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1905790',
        NAME: 'Burlington Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213547633,
        AWATER: 13462240,
        INTPTLAT: '+40.8010378',
        INTPTLON: '-091.1609678',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.334, 40.9585],
            [-91.1797, 40.8703],
            [-91.2572, 40.798],
            [-91.4093, 40.8127],
            [-91.4104, 40.8782],
            [-91.334, 40.9585],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1908550',
        NAME: 'Danville Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204644415,
        AWATER: 841963,
        INTPTLAT: '+40.8605427',
        INTPTLON: '-091.3004281',
        ELSDLEA: '',
        UNSDLEA: '08550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3514, 41.205],
            [-91.2344, 41.2271],
            [-91.1104, 41.2373],
            [-90.9897, 41.1557],
            [-90.9494, 41.0727],
            [-91.1834, 41.0842],
            [-91.216, 41.1469],
            [-91.3367, 41.1614],
            [-91.3514, 41.205],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1929730',
        NAME: 'Wapello Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 365298681,
        AWATER: 26900406,
        INTPTLAT: '+41.1516749',
        INTPTLON: '-091.1291814',
        ELSDLEA: '',
        UNSDLEA: '29730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.3696, 43.3571],
            [-93.161, 43.3424],
            [-93.0241, 43.2631],
            [-92.9346, 43.2202],
            [-92.972, 43.1023],
            [-93.1224, 43.0829],
            [-93.1414, 43.2094],
            [-93.2901, 43.2277],
            [-93.366, 43.2276],
            [-93.4184, 43.3136],
            [-93.3696, 43.3571],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1920760',
        NAME: 'Central Springs Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 542235792,
        AWATER: 175686,
        INTPTLAT: '+43.2346254',
        INTPTLON: '-093.1514241',
        ELSDLEA: '',
        UNSDLEA: '20760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-93.1224, 43.0829],
            [-92.972, 43.1023],
            [-92.9346, 43.2202],
            [-92.8101, 43.1495],
            [-92.8302, 43.0571],
            [-92.7905, 42.9728],
            [-92.8298, 42.9073],
            [-93.0251, 42.9075],
            [-93.0234, 43.0019],
            [-93.1024, 43.0526],
            [-93.1224, 43.0829],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1924960',
        NAME: 'Rudd-Rockford-Marble Rock Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 525103694,
        AWATER: 1028448,
        INTPTLAT: '+43.0395680',
        INTPTLON: '-092.9295190',
        ELSDLEA: '',
        UNSDLEA: '24960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6747, 40.7776],
            [-91.6223, 40.8139],
            [-91.4479, 40.813],
            [-91.4093, 40.8127],
            [-91.2572, 40.798],
            [-91.258, 40.752],
            [-91.1125, 40.6963],
            [-91.2479, 40.6384],
            [-91.3697, 40.5888],
            [-91.4882, 40.5885],
            [-91.507, 40.7259],
            [-91.6605, 40.7628],
            [-91.6747, 40.7776],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1911850',
        NAME: 'Fort Madison Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 602023638,
        AWATER: 23834821,
        INTPTLAT: '+40.7180146',
        INTPTLON: '-091.3859906',
        ELSDLEA: '',
        UNSDLEA: '11850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7167, 41.098],
            [-91.4864, 41.0645],
            [-91.4485, 41.0167],
            [-91.4479, 40.813],
            [-91.6223, 40.8139],
            [-91.6747, 40.7776],
            [-91.6607, 40.8427],
            [-91.7361, 40.9007],
            [-91.7167, 41.098],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1919890',
        NAME: 'Mount Pleasant Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 637467638,
        AWATER: 4179146,
        INTPTLAT: '+40.9454164',
        INTPTLON: '-091.5937246',
        ELSDLEA: '',
        UNSDLEA: '19890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.2337, 43.0047],
            [-96.1104, 43.0335],
            [-96.0473, 43.1198],
            [-95.9604, 43.1422],
            [-95.8107, 43.0408],
            [-95.8594, 42.9091],
            [-96.0168, 42.9101],
            [-96.2533, 42.9094],
            [-96.2337, 43.0047],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918840',
        NAME: 'MOC-Floyd Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 600108143,
        AWATER: 217977,
        INTPTLAT: '+43.0043093',
        INTPTLON: '-096.0252545',
        ELSDLEA: '',
        UNSDLEA: '18840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.8895, 42.2982],
            [-91.8321, 42.2446],
            [-91.6831, 42.2177],
            [-91.7649, 42.1248],
            [-91.8333, 42.1762],
            [-91.9208, 42.174],
            [-91.9485, 42.2638],
            [-91.8895, 42.2982],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1906660',
        NAME: 'Center Point-Urbana Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230400109,
        AWATER: 1405988,
        INTPTLAT: '+42.2062875',
        INTPTLON: '-091.8137136',
        ELSDLEA: '',
        UNSDLEA: '06660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7649, 42.1248],
            [-91.6831, 42.2177],
            [-91.5971, 42.2486],
            [-91.555, 42.1341],
            [-91.6582, 42.0913],
            [-91.7649, 42.1248],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903300',
        NAME: 'Alburnett Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168405061,
        AWATER: 467666,
        INTPTLAT: '+42.1570187',
        INTPTLON: '-091.6586308',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6582, 42.0913],
            [-91.555, 42.1341],
            [-91.5211, 42.1336],
            [-91.5111, 41.9904],
            [-91.5307, 41.9905],
            [-91.5992, 42.0097],
            [-91.5791, 42.0397],
            [-91.6191, 42.0438],
            [-91.6188, 42.0206],
            [-91.6582, 42.0913],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1917220',
        NAME: 'Linn-Mar Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168228263,
        AWATER: 98722,
        INTPTLAT: '+42.0635890',
        INTPTLON: '-091.5747921',
        ELSDLEA: '',
        UNSDLEA: '17220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5992, 42.0097],
            [-91.6188, 42.0206],
            [-91.6191, 42.0438],
            [-91.5791, 42.0397],
            [-91.5992, 42.0097],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1918690',
        NAME: 'Marion Independent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9845234,
        AWATER: 0,
        INTPTLAT: '+42.0286184',
        INTPTLON: '-091.5992232',
        ELSDLEA: '',
        UNSDLEA: '18690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4374, 42.7316],
            [-91.2507, 42.7324],
            [-91.1518, 42.7104],
            [-91.1328, 42.6458],
            [-91.1339, 42.5878],
            [-91.253, 42.5584],
            [-91.2924, 42.58],
            [-91.2917, 42.6161],
            [-91.5088, 42.6219],
            [-91.4374, 42.7316],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1910500',
        NAME: 'Edgewood-Colesburg Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 380666382,
        AWATER: 87789,
        INTPTLAT: '+42.6524501',
        INTPTLON: '-091.3218008',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3673, 42.3338],
            [-92.1812, 42.4063],
            [-92.0211, 42.3504],
            [-92.0625, 42.2973],
            [-92.1811, 42.2395],
            [-92.2026, 42.0945],
            [-92.3269, 42.036],
            [-92.386, 42.1378],
            [-92.4169, 42.2973],
            [-92.3673, 42.3338],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1900022',
        NAME: 'Union Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 668092782,
        AWATER: 4916597,
        INTPTLAT: '+42.2399548',
        INTPTLON: '-092.2498054',
        ELSDLEA: '',
        UNSDLEA: '00022',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2977, 42.7298],
            [-92.1803, 42.7442],
            [-92.1804, 42.7549],
            [-92.0712, 42.7804],
            [-92.0008, 42.7001],
            [-91.9642, 42.5906],
            [-92.0824, 42.5549],
            [-92.1161, 42.642],
            [-92.2388, 42.6323],
            [-92.2977, 42.7298],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1929760',
        NAME: 'Wapsie Valley Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329095792,
        AWATER: 1196049,
        INTPTLAT: '+42.6765813',
        INTPTLON: '-092.1209048',
        ELSDLEA: '',
        UNSDLEA: '29760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7123, 42.7043],
            [-92.5741, 42.8026],
            [-92.4461, 42.8101],
            [-92.4075, 42.8552],
            [-92.3225, 42.7409],
            [-92.3962, 42.6858],
            [-92.529, 42.6321],
            [-92.5543, 42.6211],
            [-92.7022, 42.6425],
            [-92.7318, 42.657],
            [-92.7123, 42.7043],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930540',
        NAME: 'Waverly-Shell Rock Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 425933166,
        AWATER: 4242097,
        INTPTLAT: '+42.7227469',
        INTPTLON: '-092.5226497',
        ELSDLEA: '',
        UNSDLEA: '30540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.485, 42.4696],
            [-92.436, 42.4695],
            [-92.3673, 42.3338],
            [-92.4169, 42.2973],
            [-92.4651, 42.2972],
            [-92.5339, 42.4134],
            [-92.485, 42.4696],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914340',
        NAME: 'Hudson Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163291456,
        AWATER: 508137,
        INTPTLAT: '+42.3920325',
        INTPTLON: '-092.4467826',
        ELSDLEA: '',
        UNSDLEA: '14340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3962, 42.6858],
            [-92.3574, 42.5852],
            [-92.3967, 42.585],
            [-92.529, 42.6321],
            [-92.3962, 42.6858],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1915180',
        NAME: 'Janesville Consolidated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110122260,
        AWATER: 1808520,
        INTPTLAT: '+42.6354475',
        INTPTLON: '-092.4432881',
        ELSDLEA: '',
        UNSDLEA: '15180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4759, 41.2351],
            [-91.3514, 41.205],
            [-91.3367, 41.1614],
            [-91.3276, 41.0741],
            [-91.3711, 41.0159],
            [-91.4485, 41.0167],
            [-91.4864, 41.0645],
            [-91.4759, 41.2351],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1931830',
        NAME: 'Winfield-Mount Union Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250143563,
        AWATER: 0,
        INTPTLAT: '+41.1213298',
        INTPTLON: '-091.4171195',
        ELSDLEA: '',
        UNSDLEA: '31830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9447, 41.2363],
            [-91.8685, 41.3078],
            [-91.754, 41.3403],
            [-91.7538, 41.4235],
            [-91.6378, 41.4247],
            [-91.5993, 41.4103],
            [-91.6008, 41.2506],
            [-91.6875, 41.2106],
            [-91.7166, 41.1245],
            [-91.764, 41.1333],
            [-91.9366, 41.1634],
            [-91.9447, 41.2363],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1930240',
        NAME: 'Washington Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 532170265,
        AWATER: 4099680,
        INTPTLAT: '+41.2653796',
        INTPTLON: '-091.7417618',
        ELSDLEA: '',
        UNSDLEA: '30240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5413, 42.9024],
            [-96.3817, 42.9093],
            [-96.3705, 42.8288],
            [-96.4389, 42.7001],
            [-96.4495, 42.5911],
            [-96.4988, 42.561],
            [-96.5015, 42.5902],
            [-96.6356, 42.7408],
            [-96.5413, 42.9024],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1903220',
        NAME: 'Akron-Westfield Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 466270606,
        AWATER: 1857179,
        INTPTLAT: '+42.7574849',
        INTPTLON: '-096.4975850',
        ELSDLEA: '',
        UNSDLEA: '03220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4389, 42.7001],
            [-96.3604, 42.7139],
            [-96.1352, 42.641],
            [-96.0962, 42.6197],
            [-96.1646, 42.5758],
            [-96.3121, 42.5617],
            [-96.4495, 42.5911],
            [-96.4389, 42.7001],
          ],
        ],
      },
      properties: {
        STATEFP: '19',
        SCSDLEA: '',
        GEOID: '1914160',
        NAME: 'Hinton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 333204105,
        AWATER: 161852,
        INTPTLAT: '+42.6304508',
        INTPTLON: '-096.3044076',
        ELSDLEA: '',
        UNSDLEA: '14160',
      },
    },
  ],
};

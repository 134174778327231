/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SectionTitle from '../Components/SectionTitle';
import FileWizard from '../Components/FileWizard';
import Spinner from '../Components/Spinner';
import { setVisualization } from '../actions/fileWizard.action';

const UpdateVisualization = () => {
  const jwt = useSelector((state) => state.session.jwt);
  const visualization = useSelector((state) => state.fileWizard.visualization);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(setVisualization(id, jwt));
  }, []);

  const getVisualizationId = (_visualization = {}) => {
    const { Data } = _visualization;
    const { position } = Data;
    return position;
  };

  if (!visualization)
    return (
      <div className="spinner-container centered">
        <Spinner size="lg" />
      </div>
    );

  return (
    <>
      <SectionTitle margin="lg" title={`Update Chart (${visualization.Data.title})`} />
      <Container>
        <Row>
          {!visualization && (
            <div className="spinner-container">
              <Spinner size="lg" />
            </div>
          )}
          {visualization && <FileWizard visualizationId={getVisualizationId(visualization)} />}
        </Row>
      </Container>
    </>
  );
};

export default UpdateVisualization;

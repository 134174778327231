/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SignUpForm from '../Components/SignUpForm';
import './css/login.css';

export default function SignUp() {
  return (
    <div className="Login signup-responsive">
      <Container fluid>
        <Row className="d-flex justify-content-center">
          <Col md="4">
            <Container>
              <Row className="flex-column mt-2">
                <Col className="Login-info-logo">
                  <img src="/bento-logo.png" alt="Bento Logo" />
                  <span>Bento</span>
                </Col>
                <Col className="Login-info-welcome">Welcome to</Col>
                <h2 className="Login-info-title">Bento</h2>
                <h3 className="Login-info-subtitle">The Benchmarking Tool</h3>
              </Row>
              <Row className="mt-4">
                <SignUpForm />
              </Row>
            </Container>
          </Col>
          <Col md="5" className="flex-column video-container">
            <div className="invisible-header w-100" />
            <video controls autoPlay width="100%" height="auto" loop>
              <source
                src="https://s3.us-east-2.amazonaws.com/admin-ui-uat.bentobento.info/Bento+Intro+Video+Final.mp4"
                type="video/mp4"
              />
            </video>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

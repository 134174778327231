export const MA = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8808, 42.433],
            [-72.8556, 42.481],
            [-72.7637, 42.4636],
            [-72.7577, 42.446],
            [-72.701, 42.4529],
            [-72.6692, 42.4097],
            [-72.6721, 42.3714],
            [-72.7412, 42.3627],
            [-72.7367, 42.2842],
            [-72.7365, 42.2766],
            [-72.6677, 42.2255],
            [-72.6869, 42.1834],
            [-72.781, 42.1997],
            [-72.8144, 42.2568],
            [-72.8211, 42.3387],
            [-72.8808, 42.433],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '05740',
        GEOID: '2505740',
        NAME: 'Hampshire School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334232793,
        AWATER: 4970068,
        INTPTLAT: '+42.3494979',
        INTPTLON: '-072.7821402',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0589, 42.7325],
            [-71.0316, 42.718],
            [-70.9764, 42.6944],
            [-70.9486, 42.6807],
            [-70.9653, 42.6719],
            [-70.9036, 42.6598],
            [-70.9311, 42.6124],
            [-70.9647, 42.5996],
            [-71.0027, 42.5633],
            [-71.0348, 42.5655],
            [-71.0352, 42.5704],
            [-71.0588, 42.609],
            [-71.0223, 42.6416],
            [-71.1086, 42.7353],
            [-71.0589, 42.7325],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '07410',
        GEOID: '2507410',
        NAME: 'Masconomet School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126737583,
        AWATER: 7181523,
        INTPTLAT: '+42.6521665',
        INTPTLON: '-071.0010008',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4559, 42.0349],
            [-71.3594, 42.0895],
            [-71.3802, 42.133],
            [-71.3756, 42.1355],
            [-71.3396, 42.1494],
            [-71.3038, 42.1495],
            [-71.2803, 42.0885],
            [-71.2851, 42.0824],
            [-71.2891, 42.045],
            [-71.2883, 42.0145],
            [-71.2883, 42.0144],
            [-71.3813, 41.985],
            [-71.3814, 42.0188],
            [-71.4581, 42.0178],
            [-71.4559, 42.0349],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '06510',
        GEOID: '2506510',
        NAME: 'King Philip School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123303865,
        AWATER: 4983471,
        INTPTLAT: '+42.0642414',
        INTPTLON: '-071.3453665',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8992, 41.9964],
            [-70.8969, 42.0191],
            [-70.8334, 42.0196],
            [-70.8073, 42.0129],
            [-70.7993, 42.0136],
            [-70.7697, 42.0221],
            [-70.6733, 42.0002],
            [-70.7535, 41.9332],
            [-70.7587, 41.9475],
            [-70.8298, 41.9277],
            [-70.8609, 41.955],
            [-70.9085, 41.9663],
            [-70.8992, 41.9964],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '10830',
        GEOID: '2510830',
        NAME: 'Silver Lake School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127775131,
        AWATER: 9504279,
        INTPTLAT: '+41.9785757',
        INTPTLON: '-070.7954401',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8961, 41.7944],
            [-70.7718, 41.8089],
            [-70.7788, 41.7612],
            [-70.6766, 41.6911],
            [-70.691, 41.6605],
            [-70.7508, 41.6696],
            [-70.8423, 41.6245],
            [-70.8582, 41.6812],
            [-70.865, 41.7002],
            [-70.9072, 41.7635],
            [-70.8961, 41.7944],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '09150',
        GEOID: '2509150',
        NAME: 'Old Rochester School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168202483,
        AWATER: 55414584,
        INTPTLAT: '+41.7182359',
        INTPTLON: '-070.8113435',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4201, 42.2381],
            [-71.4193, 42.2573],
            [-71.3904, 42.2719],
            [-71.3324, 42.2477],
            [-71.3085, 42.2707],
            [-71.2948, 42.2731],
            [-71.2957, 42.2725],
            [-71.2999, 42.2695],
            [-71.2324, 42.2585],
            [-71.2515, 42.2026],
            [-71.2696, 42.2005],
            [-71.3384, 42.2152],
            [-71.3442, 42.2007],
            [-71.3848, 42.192],
            [-71.4201, 42.2381],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '04290',
        GEOID: '2504290',
        NAME: 'Dover-Sherborn School District',
        LSAD: '00',
        LOGRADE: '06',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80123689,
        AWATER: 1778080,
        INTPTLAT: '+42.2356194',
        INTPTLON: '-071.3329541',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3117, 42.5046],
            [-73.262, 42.6313],
            [-73.307, 42.6326],
            [-73.265, 42.7459],
            [-73.1636, 42.744],
            [-73.1659, 42.7118],
            [-73.1698, 42.6602],
            [-73.1716, 42.6287],
            [-73.2121, 42.5424],
            [-73.1658, 42.5109],
            [-73.1822, 42.4854],
            [-73.3117, 42.5046],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '08160',
        GEOID: '2508160',
        NAME: 'Mount Greylock School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230679032,
        AWATER: 2272242,
        INTPTLAT: '+42.6152902',
        INTPTLON: '-073.2397272',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-71.3663, 42.4127],
              [-71.2897, 42.4662],
              [-71.2727, 42.4597],
              [-71.2672, 42.4244],
              [-71.2828, 42.3997],
              [-71.3345, 42.3961],
              [-71.3663, 42.4127],
            ],
          ],
          [
            [
              [-71.4755, 42.3806],
              [-71.4727, 42.39],
              [-71.4695, 42.4026],
              [-71.4304, 42.4369],
              [-71.3663, 42.4127],
              [-71.39, 42.3652],
              [-71.3967, 42.3409],
              [-71.4833, 42.3531],
              [-71.4755, 42.3806],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '06930',
        GEOID: '2506930',
        NAME: 'Lincoln-Sudbury School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99716489,
        AWATER: 3187350,
        INTPTLAT: '+42.4008258',
        INTPTLON: '-071.3896887',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5299, 42.5197],
            [-71.4735, 42.5002],
            [-71.4285, 42.5288],
            [-71.3996, 42.534],
            [-71.3849, 42.5045],
            [-71.4304, 42.4369],
            [-71.4643, 42.4503],
            [-71.4985, 42.4638],
            [-71.5433, 42.4665],
            [-71.5299, 42.5197],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '01710',
        GEOID: '2501710',
        NAME: 'Acton-Boxborough School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78091032,
        AWATER: 1431399,
        INTPTLAT: '+42.4846429',
        INTPTLON: '-071.4671408',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3849, 42.5045],
            [-71.3996, 42.534],
            [-71.3884, 42.5499],
            [-71.3325, 42.5608],
            [-71.3066, 42.5206],
            [-71.3175, 42.5024],
            [-71.2897, 42.4662],
            [-71.3663, 42.4127],
            [-71.4304, 42.4369],
            [-71.3849, 42.5045],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '03870',
        GEOID: '2503870',
        NAME: 'Concord-Carlisle School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103052103,
        AWATER: 4059275,
        INTPTLAT: '+42.4903850',
        INTPTLON: '-071.3532719',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1532, 41.7896],
            [-71.1196, 41.791],
            [-71.1087, 41.8541],
            [-71.0075, 41.8385],
            [-71.1145, 41.7788],
            [-71.124, 41.7667],
            [-71.2012, 41.6819],
            [-71.2083, 41.6905],
            [-71.1532, 41.7896],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '00541',
        GEOID: '2500541',
        NAME: 'Somerset-Berkley School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63229351,
        AWATER: 12711057,
        INTPTLAT: '+41.8045317',
        INTPTLON: '-071.1114096',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9497, 42.7051],
            [-72.9272, 42.7392],
            [-72.8643, 42.7378],
            [-72.8576, 42.674],
            [-72.9556, 42.6545],
            [-72.9497, 42.7051],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25007',
        GEOID: '2525007',
        NAME: 'Mohawk Trail School District in Rowe (7-12)',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 60740002,
        AWATER: 1502321,
        INTPTLAT: '+42.6955322',
        INTPTLON: '-072.9091479',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9508, 42.4443],
            [-70.906, 42.4383],
            [-70.8514, 42.3932],
            [-70.9179, 42.386],
            [-70.9595, 42.4075],
            [-70.9508, 42.4443],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25005',
        GEOID: '2525005',
        NAME: 'Swampscott School District in Nahant (7-12)',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 2708928,
        AWATER: 37363972,
        INTPTLAT: '+42.4192788',
        INTPTLON: '-070.9300913',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0229, 42.7411],
            [-73.0186, 42.7411],
            [-72.9303, 42.7393],
            [-72.9272, 42.7392],
            [-72.9497, 42.7051],
            [-73.0229, 42.7411],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25014',
        GEOID: '2525014',
        NAME: 'North Adams School District in Monroe (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 27682171,
        AWATER: 250766,
        INTPTLAT: '+42.7277722',
        INTPTLON: '-072.9853464',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4108, 42.3517],
            [-73.3915, 42.4038],
            [-73.3886, 42.4116],
            [-73.3835, 42.4255],
            [-73.3389, 42.4186],
            [-73.2953, 42.4119],
            [-73.3308, 42.352],
            [-73.4108, 42.3517],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25006',
        GEOID: '2525006',
        NAME: 'Pittsfield School District in Richmond (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 48394450,
        AWATER: 839832,
        INTPTLAT: '+42.3834973',
        INTPTLON: '-073.3591868',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.491, 42.604],
            [-72.3647, 42.6094],
            [-72.3623, 42.5953],
            [-72.4661, 42.5767],
            [-72.491, 42.604],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25003',
        GEOID: '2525003',
        NAME: 'Gill-Montague School District in Erving (7-12)',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 35786535,
        AWATER: 1492350,
        INTPTLAT: '+42.6134177',
        INTPTLON: '-072.4264274',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1636, 42.744],
            [-73.1425, 42.7436],
            [-73.0485, 42.7415],
            [-73.0595, 42.7084],
            [-73.1659, 42.7118],
            [-73.1636, 42.744],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25002',
        GEOID: '2525002',
        NAME: 'North Adams School District in Clarksburg (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 32865596,
        AWATER: 267080,
        INTPTLAT: '+42.7227205',
        INTPTLON: '-073.1058185',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1221, 41.7588],
            [-70.0231, 41.7882],
            [-70.0074, 41.7998],
            [-70.041, 41.8758],
            [-70.0853, 41.9564],
            [-69.9967, 41.9668],
            [-69.9521, 41.8806],
            [-69.9311, 41.8096],
            [-69.9284, 41.7225],
            [-69.9916, 41.7287],
            [-70.1338, 41.7061],
            [-70.1221, 41.7588],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '04560',
        GEOID: '2504560',
        NAME: 'Nauset School District',
        LSAD: '00',
        LOGRADE: '06',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183286654,
        AWATER: 99540996,
        INTPTLAT: '+41.8333183',
        INTPTLON: '-070.0037867',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1536, 42.0805],
            [-70.06, 42.0467],
            [-69.9967, 41.9668],
            [-70.0853, 41.9564],
            [-70.1475, 42.0567],
            [-70.1536, 42.0805],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25012',
        GEOID: '2525012',
        NAME: 'Nauset/Provincetown School Districts in Turo (7-12)',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 54267195,
        AWATER: 13686378,
        INTPTLAT: '+42.0135600',
        INTPTLON: '-070.0641518',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1621, 42.197],
            [-73.1616, 42.2475],
            [-73.008, 42.2384],
            [-73.0349, 42.1437],
            [-73.0533, 42.0399],
            [-73.1272, 42.0421],
            [-73.1722, 42.0433],
            [-73.1621, 42.197],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25015',
        GEOID: '2525015',
        NAME: 'Lee/Berkshire Hills in Farmington River Regional (7-12)',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 226227594,
        AWATER: 9501152,
        INTPTLAT: '+42.1524327',
        INTPTLON: '-073.1139841',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.943, 41.7447],
            [-70.9072, 41.7635],
            [-70.865, 41.7002],
            [-70.8582, 41.6812],
            [-70.9172, 41.6636],
            [-70.943, 41.7447],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25010',
        GEOID: '2525010',
        NAME: 'Fairhaven/New Bedford School Districts in Acushnet (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 47726624,
        AWATER: 1350995,
        INTPTLAT: '+41.7182175',
        INTPTLON: '-070.9011509',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3473, 42.5242],
            [-73.307, 42.6326],
            [-73.262, 42.6313],
            [-73.3117, 42.5046],
            [-73.3389, 42.4186],
            [-73.3835, 42.4255],
            [-73.3473, 42.5242],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25013',
        GEOID: '2525013',
        NAME: 'Mount Greylock/New Lebanon (NY) School Districts in Hancock (7-12)',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 92374173,
        AWATER: 178575,
        INTPTLAT: '+42.5246927',
        INTPTLON: '-073.3232066',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7637, 42.4636],
            [-72.6755, 42.553],
            [-72.6368, 42.578],
            [-72.5781, 42.5785],
            [-72.5568, 42.5065],
            [-72.5209, 42.5102],
            [-72.531, 42.4289],
            [-72.5442, 42.4271],
            [-72.5811, 42.4225],
            [-72.6692, 42.4097],
            [-72.701, 42.4529],
            [-72.7577, 42.446],
            [-72.7637, 42.4636],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '05100',
        GEOID: '2505100',
        NAME: 'Frontier School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270509813,
        AWATER: 5884826,
        INTPTLAT: '+42.4871695',
        INTPTLON: '-072.6475795',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2743, 42.1439],
            [-72.2639, 42.1839],
            [-72.1418, 42.1609],
            [-72.1113, 42.2385],
            [-72.073, 42.2321],
            [-72.0724, 42.1693],
            [-72.0294, 42.1744],
            [-72.0421, 42.1116],
            [-72.0998, 42.0287],
            [-72.1022, 42.0288],
            [-72.1357, 42.0302],
            [-72.1988, 42.031],
            [-72.2051, 42.0311],
            [-72.2707, 42.0317],
            [-72.2701, 42.0925],
            [-72.2743, 42.1439],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '11490',
        GEOID: '2511490',
        NAME: 'Tantasqua School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299234546,
        AWATER: 11230220,
        INTPTLAT: '+42.1215862',
        INTPTLON: '-072.1650141',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5442, 42.4271],
            [-72.531, 42.4289],
            [-72.5209, 42.5102],
            [-72.4596, 42.5164],
            [-72.4409, 42.5104],
            [-72.375, 42.4208],
            [-72.3554, 42.3407],
            [-72.4723, 42.3515],
            [-72.4642, 42.308],
            [-72.5279, 42.3014],
            [-72.535, 42.3042],
            [-72.5442, 42.4271],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '01920',
        GEOID: '2501920',
        NAME: 'Amherst-Pelham School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264288396,
        AWATER: 6057163,
        INTPTLAT: '+42.4167592',
        INTPTLON: '-072.4501320',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4661, 42.5767],
            [-72.3623, 42.5953],
            [-72.3647, 42.6094],
            [-72.272, 42.6747],
            [-72.2715, 42.5472],
            [-72.245, 42.5136],
            [-72.1856, 42.5504],
            [-72.1009, 42.4837],
            [-72.2704, 42.3904],
            [-72.2887, 42.352],
            [-72.3146, 42.3435],
            [-72.3559, 42.3034],
            [-72.3554, 42.3407],
            [-72.375, 42.4208],
            [-72.4409, 42.5104],
            [-72.4596, 42.5164],
            [-72.4661, 42.5767],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '09900',
        GEOID: '2509900',
        NAME: 'Ralph C. Mahar School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 429804342,
        AWATER: 75691078,
        INTPTLAT: '+42.4654045',
        INTPTLON: '-072.3087328',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7419, 42.3868],
            [-71.726, 42.3903],
            [-71.6788, 42.3892],
            [-71.6606, 42.4182],
            [-71.6043, 42.3977],
            [-71.6032, 42.3673],
            [-71.6247, 42.3505],
            [-71.6762, 42.3648],
            [-71.6879, 42.3255],
            [-71.7644, 42.3129],
            [-71.7419, 42.3868],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '02580',
        GEOID: '2502580',
        NAME: 'Berlin-Boylston School District',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75196932,
        AWATER: 10086377,
        INTPTLAT: '+42.3717812',
        INTPTLON: '-071.6811961',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8962, 41.3701],
            [-70.6876, 41.4753],
            [-70.65, 41.488],
            [-70.5598, 41.5093],
            [-70.5028, 41.5006],
            [-70.4897, 41.463],
            [-70.4364, 41.4689],
            [-70.3236, 41.3916],
            [-70.2343, 41.3832],
            [-70.3282, 41.3802],
            [-70.4933, 41.28],
            [-70.6035, 41.2995],
            [-70.685, 41.2932],
            [-70.8059, 41.1987],
            [-70.8773, 41.2241],
            [-70.8962, 41.3701],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '07380',
        GEOID: '2507380',
        NAME: "Martha's Vineyard School District",
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233302651,
        AWATER: 675236962,
        INTPTLAT: '+41.3661733',
        INTPTLON: '-070.6325377',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6879, 42.3255],
            [-71.6762, 42.3648],
            [-71.6247, 42.3505],
            [-71.5852, 42.311],
            [-71.4868, 42.3301],
            [-71.497, 42.2898],
            [-71.5222, 42.2664],
            [-71.5495, 42.2661],
            [-71.5801, 42.3059],
            [-71.6701, 42.2788],
            [-71.6879, 42.3255],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '08910',
        GEOID: '2508910',
        NAME: 'Northborough-Southborough School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84169693,
        AWATER: 4626874,
        INTPTLAT: '+42.3133469',
        INTPTLON: '-071.5932103',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0633, 42.6547],
            [-73.0595, 42.7084],
            [-73.0485, 42.7415],
            [-73.0229, 42.7411],
            [-72.9497, 42.7051],
            [-72.9556, 42.6545],
            [-72.951, 42.641],
            [-73.0636, 42.6499],
            [-73.0633, 42.6547],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25009',
        GEOID: '2525009',
        NAME: 'North Adams School District in Florida (9-12)',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 63094488,
        AWATER: 592395,
        INTPTLAT: '+42.6800415',
        INTPTLON: '-073.0158137',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0636, 42.6499],
            [-72.951, 42.641],
            [-72.9754, 42.5559],
            [-72.979, 42.5416],
            [-73.0854, 42.5595],
            [-73.0636, 42.6499],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '25008',
        GEOID: '2525008',
        NAME: 'Adams-Cheshire School District in Savoy (7-12)',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 92838293,
        AWATER: 452294,
        INTPTLAT: '+42.5941657',
        INTPTLON: '-073.0223195',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.951, 42.641],
            [-72.9556, 42.6545],
            [-72.8576, 42.674],
            [-72.8215, 42.6369],
            [-72.8769, 42.5412],
            [-72.9754, 42.5559],
            [-72.951, 42.641],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '22222',
        GEOID: '2522222',
        NAME: 'Mohawk Trail Regional School District in Hawley and Charlemont towns',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 147069728,
        AWATER: 1189483,
        INTPTLAT: '+42.6104497',
        INTPTLON: '-072.8877351',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8808, 42.433],
            [-72.8556, 42.481],
            [-72.7637, 42.4636],
            [-72.7577, 42.446],
            [-72.7771, 42.3582],
            [-72.8211, 42.3387],
            [-72.8808, 42.433],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2500014',
        NAME: 'Chesterfield-Goshen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124734751,
        AWATER: 2060816,
        INTPTLAT: '+42.4030657',
        INTPTLON: '-072.8251006',
        ELSDLEA: '00014',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8962, 41.3701],
            [-70.6876, 41.4753],
            [-70.6151, 41.4137],
            [-70.6035, 41.2995],
            [-70.685, 41.2932],
            [-70.8059, 41.1987],
            [-70.8773, 41.2241],
            [-70.8962, 41.3701],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2500043',
        NAME: 'Up-Island Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127990920,
        AWATER: 345900590,
        INTPTLAT: '+41.3422896',
        INTPTLON: '-070.7103916',
        ELSDLEA: '00043',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4735, 42.5002],
            [-71.4285, 42.5288],
            [-71.3996, 42.534],
            [-71.3849, 42.5045],
            [-71.4304, 42.4369],
            [-71.4643, 42.4503],
            [-71.4985, 42.4638],
            [-71.4735, 42.5002],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2501680',
        NAME: 'Acton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51450916,
        AWATER: 1115113,
        INTPTLAT: '+42.4839530',
        INTPTLON: '-071.4384947',
        ELSDLEA: '01680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.943, 41.7447],
            [-70.9072, 41.7635],
            [-70.865, 41.7002],
            [-70.8582, 41.6812],
            [-70.9172, 41.6636],
            [-70.943, 41.7447],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2501740',
        NAME: 'Acushnet School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47726624,
        AWATER: 1350995,
        INTPTLAT: '+41.7182175',
        INTPTLON: '-070.9011509',
        ELSDLEA: '01740',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5442, 42.4271],
            [-72.531, 42.4289],
            [-72.4899, 42.4337],
            [-72.4838, 42.4075],
            [-72.4723, 42.3515],
            [-72.4642, 42.308],
            [-72.5279, 42.3014],
            [-72.535, 42.3042],
            [-72.5442, 42.4271],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2501890',
        NAME: 'Amherst School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71498409,
        AWATER: 338818,
        INTPTLAT: '+42.3635343',
        INTPTLON: '-072.5072913',
        ELSDLEA: '01890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1087, 41.8541],
            [-71.0075, 41.8385],
            [-71.1145, 41.7788],
            [-71.1196, 41.791],
            [-71.1087, 41.8541],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502520',
        NAME: 'Berkley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42768272,
        AWATER: 2366289,
        INTPTLAT: '+41.8380611',
        INTPTLON: '-071.0776152',
        ELSDLEA: '02520',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6606, 42.4182],
            [-71.6043, 42.3977],
            [-71.6032, 42.3673],
            [-71.6247, 42.3505],
            [-71.6762, 42.3648],
            [-71.6788, 42.3892],
            [-71.6606, 42.4182],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502550',
        NAME: 'Berlin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33598202,
        AWATER: 523987,
        INTPTLAT: '+42.3849290',
        INTPTLON: '-071.6338895',
        ELSDLEA: '02550',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5299, 42.5197],
            [-71.4735, 42.5002],
            [-71.4985, 42.4638],
            [-71.5433, 42.4665],
            [-71.5299, 42.5197],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502850',
        NAME: 'Boxborough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26640116,
        AWATER: 316286,
        INTPTLAT: '+42.4859849',
        INTPTLON: '-071.5209848',
        ELSDLEA: '02850',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0589, 42.7325],
            [-71.0316, 42.718],
            [-70.9764, 42.6944],
            [-70.9486, 42.6807],
            [-70.9653, 42.6719],
            [-70.9767, 42.6209],
            [-71.0223, 42.6416],
            [-71.1086, 42.7353],
            [-71.0589, 42.7325],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502880',
        NAME: 'Boxford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61013955,
        AWATER: 2188514,
        INTPTLAT: '+42.6831083',
        INTPTLON: '-071.0183302',
        ELSDLEA: '02880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7419, 42.3868],
            [-71.726, 42.3903],
            [-71.6788, 42.3892],
            [-71.6762, 42.3648],
            [-71.6879, 42.3255],
            [-71.7644, 42.3129],
            [-71.7419, 42.3868],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502910',
        NAME: 'Boylston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41598730,
        AWATER: 9562390,
        INTPTLAT: '+42.3550489',
        INTPTLON: '-071.7161570',
        ELSDLEA: '02910',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1221, 41.7588],
            [-70.0231, 41.7882],
            [-69.9916, 41.7287],
            [-70.1338, 41.7061],
            [-70.1221, 41.7588],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502970',
        NAME: 'Brewster School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59267738,
        AWATER: 6632906,
        INTPTLAT: '+41.7475128',
        INTPTLON: '-070.0662227',
        ELSDLEA: '02970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2743, 42.1439],
            [-72.2639, 42.1839],
            [-72.1418, 42.1609],
            [-72.135, 42.1617],
            [-72.135, 42.0907],
            [-72.1926, 42.0915],
            [-72.2701, 42.0925],
            [-72.2743, 42.1439],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503060',
        NAME: 'Brimfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89982655,
        AWATER: 1420270,
        INTPTLAT: '+42.1281757',
        INTPTLON: '-072.2053516',
        ELSDLEA: '03060',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1113, 42.2385],
            [-72.073, 42.2321],
            [-72.0724, 42.1693],
            [-72.135, 42.1617],
            [-72.1418, 42.1609],
            [-72.1113, 42.2385],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503120',
        NAME: 'Brookfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40262977,
        AWATER: 2733266,
        INTPTLAT: '+42.1807876',
        INTPTLON: '-072.1078905',
        ELSDLEA: '03120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3996, 42.534],
            [-71.3884, 42.5499],
            [-71.3325, 42.5608],
            [-71.3066, 42.5206],
            [-71.3175, 42.5024],
            [-71.3849, 42.5045],
            [-71.3996, 42.534],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503330',
        NAME: 'Carlisle School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39536099,
        AWATER: 641972,
        INTPTLAT: '+42.5366203',
        INTPTLON: '-071.3618321',
        ELSDLEA: '03330',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1636, 42.744],
            [-73.1425, 42.7436],
            [-73.0485, 42.7415],
            [-73.0595, 42.7084],
            [-73.1659, 42.7118],
            [-73.1636, 42.744],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503720',
        NAME: 'Clarksburg School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32865596,
        AWATER: 267080,
        INTPTLAT: '+42.7227205',
        INTPTLON: '-073.1058185',
        ELSDLEA: '03720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3849, 42.5045],
            [-71.3175, 42.5024],
            [-71.2897, 42.4662],
            [-71.3663, 42.4127],
            [-71.4304, 42.4369],
            [-71.3849, 42.5045],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503840',
        NAME: 'Concord School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63516004,
        AWATER: 3417303,
        INTPTLAT: '+42.4629107',
        INTPTLON: '-071.3644962',
        ELSDLEA: '03840',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7637, 42.4636],
            [-72.6755, 42.553],
            [-72.6562, 42.47],
            [-72.701, 42.4529],
            [-72.7577, 42.446],
            [-72.7637, 42.4636],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503900',
        NAME: 'Conway School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97611937,
        AWATER: 465155,
        INTPTLAT: '+42.5085673',
        INTPTLON: '-072.7071592',
        ELSDLEA: '03900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6368, 42.578],
            [-72.5781, 42.5785],
            [-72.5568, 42.5065],
            [-72.5855, 42.4668],
            [-72.6562, 42.47],
            [-72.6755, 42.553],
            [-72.6368, 42.578],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504080',
        NAME: 'Deerfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83896521,
        AWATER: 2750334,
        INTPTLAT: '+42.5179668',
        INTPTLON: '-072.6131394',
        ELSDLEA: '04080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3324, 42.2477],
            [-71.3085, 42.2707],
            [-71.2948, 42.2731],
            [-71.2957, 42.2725],
            [-71.2999, 42.2695],
            [-71.2324, 42.2585],
            [-71.2515, 42.2026],
            [-71.2696, 42.2005],
            [-71.3384, 42.2152],
            [-71.3324, 42.2477],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504260',
        NAME: 'Dover School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39156774,
        AWATER: 816719,
        INTPTLAT: '+42.2341598',
        INTPTLON: '-071.2911666',
        ELSDLEA: '04260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.041, 41.8758],
            [-69.9521, 41.8806],
            [-69.9311, 41.8096],
            [-70.0074, 41.7998],
            [-70.041, 41.8758],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504530',
        NAME: 'Eastham School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36152447,
        AWATER: 30405395,
        INTPTLAT: '+41.8415724',
        INTPTLON: '-069.9889257',
        ELSDLEA: '04530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.5598, 41.5093],
            [-70.5028, 41.5006],
            [-70.4897, 41.463],
            [-70.6151, 41.4137],
            [-70.5598, 41.5093],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504650',
        NAME: 'Oak Bluffs School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18931125,
        AWATER: 48189394,
        INTPTLAT: '+41.4571596',
        INTPTLON: '-070.5561006',
        ELSDLEA: '04650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.491, 42.604],
            [-72.3647, 42.6094],
            [-72.3623, 42.5953],
            [-72.4661, 42.5767],
            [-72.491, 42.604],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504710',
        NAME: 'Erving School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35786535,
        AWATER: 1492350,
        INTPTLAT: '+42.6134177',
        INTPTLON: '-072.4264274',
        ELSDLEA: '04710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0633, 42.6547],
            [-73.0595, 42.7084],
            [-73.0485, 42.7415],
            [-73.0229, 42.7411],
            [-73.0186, 42.7411],
            [-72.9303, 42.7393],
            [-72.9272, 42.7392],
            [-72.9497, 42.7051],
            [-72.9556, 42.6545],
            [-72.951, 42.641],
            [-73.0636, 42.6499],
            [-73.0633, 42.6547],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504920',
        NAME: 'Florida School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90776659,
        AWATER: 843161,
        INTPTLAT: '+42.6911580',
        INTPTLON: '-073.0115750',
        ELSDLEA: '04920',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8992, 41.9964],
            [-70.8969, 42.0191],
            [-70.8334, 42.0196],
            [-70.8073, 42.0129],
            [-70.8609, 41.955],
            [-70.9085, 41.9663],
            [-70.8992, 41.9964],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505610',
        NAME: 'Halifax School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41448330,
        AWATER: 3604908,
        INTPTLAT: '+42.0028107',
        INTPTLON: '-070.8634730',
        ELSDLEA: '05610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3473, 42.5242],
            [-73.307, 42.6326],
            [-73.262, 42.6313],
            [-73.3117, 42.5046],
            [-73.3389, 42.4186],
            [-73.3835, 42.4255],
            [-73.3473, 42.5242],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505760',
        NAME: 'Hancock School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92374173,
        AWATER: 178575,
        INTPTLAT: '+42.5246927',
        INTPTLON: '-073.3232066',
        ELSDLEA: '05760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.951, 42.641],
            [-72.9556, 42.6545],
            [-72.8576, 42.674],
            [-72.8215, 42.6369],
            [-72.8769, 42.5412],
            [-72.9754, 42.5559],
            [-72.951, 42.641],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506000',
        NAME: 'Hawlemont School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147069728,
        AWATER: 1189483,
        INTPTLAT: '+42.6104497',
        INTPTLON: '-072.8877351',
        ELSDLEA: '06000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1926, 42.0915],
            [-72.135, 42.0907],
            [-72.1357, 42.0302],
            [-72.1988, 42.031],
            [-72.1926, 42.0915],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506210',
        NAME: 'Holland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31841694,
        AWATER: 1990804,
        INTPTLAT: '+42.0467994',
        INTPTLON: '-072.1801440',
        ELSDLEA: '06210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7697, 42.0221],
            [-70.6733, 42.0002],
            [-70.7535, 41.9332],
            [-70.7587, 41.9475],
            [-70.7993, 42.0136],
            [-70.7697, 42.0221],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506540',
        NAME: 'Kingston School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48321373,
        AWATER: 4793624,
        INTPTLAT: '+41.9871959',
        INTPTLON: '-070.7419417',
        ELSDLEA: '06540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3117, 42.5046],
            [-73.262, 42.6313],
            [-73.1716, 42.6287],
            [-73.2121, 42.5424],
            [-73.1658, 42.5109],
            [-73.1822, 42.4854],
            [-73.3117, 42.5046],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506630',
        NAME: 'Lanesborough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109571495,
        AWATER: 1942407,
        INTPTLAT: '+42.5528150',
        INTPTLON: '-073.2331130',
        ELSDLEA: '06630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5209, 42.5102],
            [-72.4596, 42.5164],
            [-72.4409, 42.5104],
            [-72.4899, 42.4337],
            [-72.531, 42.4289],
            [-72.5209, 42.5102],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506810',
        NAME: 'Leverett School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59071081,
        AWATER: 425597,
        INTPTLAT: '+42.4688984',
        INTPTLON: '-072.4845789',
        ELSDLEA: '06810',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3663, 42.4127],
            [-71.2897, 42.4662],
            [-71.2727, 42.4597],
            [-71.2672, 42.4244],
            [-71.2828, 42.3997],
            [-71.3345, 42.3961],
            [-71.3663, 42.4127],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506900',
        NAME: 'Lincoln School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36852677,
        AWATER: 1943245,
        INTPTLAT: '+42.4255064',
        INTPTLON: '-071.3108115',
        ELSDLEA: '06900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7991, 41.7101],
            [-70.7788, 41.7612],
            [-70.6766, 41.6911],
            [-70.691, 41.6605],
            [-70.7508, 41.6696],
            [-70.7991, 41.7101],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507290',
        NAME: 'Marion School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36253733,
        AWATER: 31432793,
        INTPTLAT: '+41.7068394',
        INTPTLON: '-070.7509605',
        ELSDLEA: '07290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.865, 41.7002],
            [-70.7991, 41.7101],
            [-70.7508, 41.6696],
            [-70.8423, 41.6245],
            [-70.8582, 41.6812],
            [-70.865, 41.7002],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507470',
        NAME: 'Mattapoisett School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44952067,
        AWATER: 17642100,
        INTPTLAT: '+41.6613631',
        INTPTLON: '-070.8120237',
        ELSDLEA: '07470',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0588, 42.609],
            [-71.0223, 42.6416],
            [-70.9767, 42.6209],
            [-70.9647, 42.5996],
            [-71.0027, 42.5633],
            [-71.0348, 42.5655],
            [-71.0352, 42.5704],
            [-71.0588, 42.609],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507830',
        NAME: 'Middleton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34846435,
        AWATER: 2668481,
        INTPTLAT: '+42.6105133',
        INTPTLON: '-071.0071181',
        ELSDLEA: '07830',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9508, 42.4443],
            [-70.906, 42.4383],
            [-70.8514, 42.3932],
            [-70.9179, 42.386],
            [-70.9595, 42.4075],
            [-70.9508, 42.4443],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508220',
        NAME: 'Nahant School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2708928,
        AWATER: 37363972,
        INTPTLAT: '+42.4192788',
        INTPTLON: '-070.9300913',
        ELSDLEA: '08220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4661, 42.5767],
            [-72.3623, 42.5953],
            [-72.2715, 42.5472],
            [-72.245, 42.5136],
            [-72.2914, 42.4795],
            [-72.3146, 42.3435],
            [-72.3559, 42.3034],
            [-72.3554, 42.3407],
            [-72.375, 42.4208],
            [-72.4409, 42.5104],
            [-72.4596, 42.5164],
            [-72.4661, 42.5767],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508530',
        NAME: 'New Salem-Wendell School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198427250,
        AWATER: 36949507,
        INTPTLAT: '+42.4813245',
        INTPTLON: '-072.3578719',
        ELSDLEA: '08530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3802, 42.133],
            [-71.3756, 42.1355],
            [-71.3396, 42.1494],
            [-71.3038, 42.1495],
            [-71.2803, 42.0885],
            [-71.2851, 42.0824],
            [-71.3594, 42.0895],
            [-71.3802, 42.133],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508640',
        NAME: 'Norfolk School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38600893,
        AWATER: 1290326,
        INTPTLAT: '+42.1162441',
        INTPTLON: '-071.3292739',
        ELSDLEA: '08640',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6879, 42.3255],
            [-71.6762, 42.3648],
            [-71.6247, 42.3505],
            [-71.5852, 42.311],
            [-71.5801, 42.3059],
            [-71.6701, 42.2788],
            [-71.6879, 42.3255],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508880',
        NAME: 'Northborough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47852202,
        AWATER: 705495,
        INTPTLAT: '+42.3233423',
        INTPTLON: '-071.6462358',
        ELSDLEA: '08880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.6151, 41.4137],
            [-70.4897, 41.463],
            [-70.4364, 41.4689],
            [-70.3236, 41.3916],
            [-70.2343, 41.3832],
            [-70.3282, 41.3802],
            [-70.4933, 41.28],
            [-70.6035, 41.2995],
            [-70.6151, 41.4137],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509090',
        NAME: 'Edgartown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69451153,
        AWATER: 248594843,
        INTPTLAT: '+41.3729500',
        INTPTLON: '-070.4823491',
        ELSDLEA: '09090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3647, 42.6094],
            [-72.272, 42.6747],
            [-72.2715, 42.5472],
            [-72.3623, 42.5953],
            [-72.3647, 42.6094],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509180',
        NAME: 'Orange School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90899268,
        AWATER: 2403751,
        INTPTLAT: '+42.6054372',
        INTPTLON: '-072.2920738',
        ELSDLEA: '09180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.0074, 41.7998],
            [-69.9311, 41.8096],
            [-69.9284, 41.7225],
            [-69.9916, 41.7287],
            [-70.0231, 41.7882],
            [-70.0074, 41.7998],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509210',
        NAME: 'Orleans School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36603791,
        AWATER: 22047164,
        INTPTLAT: '+41.7693501',
        INTPTLON: '-069.9690339',
        ELSDLEA: '09210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.375, 42.4208],
            [-72.3554, 42.3407],
            [-72.4723, 42.3515],
            [-72.4838, 42.4075],
            [-72.375, 42.4208],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509390',
        NAME: 'Pelham School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65038100,
        AWATER: 3735674,
        INTPTLAT: '+42.3716476',
        INTPTLON: '-072.4124883',
        ELSDLEA: '09390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.245, 42.5136],
            [-72.1856, 42.5504],
            [-72.1009, 42.4837],
            [-72.2704, 42.3904],
            [-72.2887, 42.352],
            [-72.3146, 42.3435],
            [-72.2914, 42.4795],
            [-72.245, 42.5136],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509540',
        NAME: 'Petersham School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140477824,
        AWATER: 36337820,
        INTPTLAT: '+42.4592215',
        INTPTLON: '-072.2163354',
        ELSDLEA: '09540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3814, 42.0188],
            [-71.2891, 42.045],
            [-71.2883, 42.0145],
            [-71.2883, 42.0144],
            [-71.3813, 41.985],
            [-71.3814, 42.0188],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509690',
        NAME: 'Plainville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28482633,
        AWATER: 1350190,
        INTPTLAT: '+42.0127966',
        INTPTLON: '-071.3365776',
        ELSDLEA: '09690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8073, 42.0129],
            [-70.7993, 42.0136],
            [-70.7587, 41.9475],
            [-70.8298, 41.9277],
            [-70.8609, 41.955],
            [-70.8073, 42.0129],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509780',
        NAME: 'Plympton School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38005428,
        AWATER: 1105747,
        INTPTLAT: '+41.9591000',
        INTPTLON: '-070.8027525',
        ELSDLEA: '09780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4108, 42.3517],
            [-73.3915, 42.4038],
            [-73.3886, 42.4116],
            [-73.3835, 42.4255],
            [-73.3389, 42.4186],
            [-73.2953, 42.4119],
            [-73.3308, 42.352],
            [-73.4108, 42.3517],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510080',
        NAME: 'Richmond School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48394450,
        AWATER: 839832,
        INTPTLAT: '+42.3834973',
        INTPTLON: '-073.3591868',
        ELSDLEA: '10080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8961, 41.7944],
            [-70.7718, 41.8089],
            [-70.7788, 41.7612],
            [-70.7991, 41.7101],
            [-70.865, 41.7002],
            [-70.9072, 41.7635],
            [-70.8961, 41.7944],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510140',
        NAME: 'Rochester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86996683,
        AWATER: 6339691,
        INTPTLAT: '+41.7598460',
        INTPTLON: '-070.8384492',
        ELSDLEA: '10140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9497, 42.7051],
            [-72.9272, 42.7392],
            [-72.8643, 42.7378],
            [-72.8576, 42.674],
            [-72.9556, 42.6545],
            [-72.9497, 42.7051],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510230',
        NAME: 'Rowe School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60740002,
        AWATER: 1502321,
        INTPTLAT: '+42.6955322',
        INTPTLON: '-072.9091479',
        ELSDLEA: '10230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0636, 42.6499],
            [-72.951, 42.641],
            [-72.9754, 42.5559],
            [-72.979, 42.5416],
            [-73.0854, 42.5595],
            [-73.0636, 42.6499],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510530',
        NAME: 'Savoy School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92838293,
        AWATER: 452294,
        INTPTLAT: '+42.5941657',
        INTPTLON: '-073.0223195',
        ELSDLEA: '10530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4201, 42.2381],
            [-71.4193, 42.2573],
            [-71.3904, 42.2719],
            [-71.3324, 42.2477],
            [-71.3384, 42.2152],
            [-71.3442, 42.2007],
            [-71.3848, 42.192],
            [-71.4201, 42.2381],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510710',
        NAME: 'Sherborn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40966915,
        AWATER: 961361,
        INTPTLAT: '+42.2319469',
        INTPTLON: '-071.3729634',
        ELSDLEA: '10710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4899, 42.4337],
            [-72.4409, 42.5104],
            [-72.375, 42.4208],
            [-72.4838, 42.4075],
            [-72.4899, 42.4337],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510800',
        NAME: 'Shutesbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68680806,
        AWATER: 1557074,
        INTPTLAT: '+42.4569676',
        INTPTLON: '-072.4172444',
        ELSDLEA: '10800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1532, 41.7896],
            [-71.1196, 41.791],
            [-71.1145, 41.7788],
            [-71.124, 41.7667],
            [-71.2012, 41.6819],
            [-71.2083, 41.6905],
            [-71.1532, 41.7896],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510860',
        NAME: 'Somerset School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20461079,
        AWATER: 10344768,
        INTPTLAT: '+41.7384794',
        INTPTLON: '-071.1650650',
        ELSDLEA: '10860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8144, 42.2568],
            [-72.7365, 42.2766],
            [-72.6677, 42.2255],
            [-72.6869, 42.1834],
            [-72.781, 42.1997],
            [-72.8144, 42.2568],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510950',
        NAME: 'Southampton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72913798,
        AWATER: 2077740,
        INTPTLAT: '+42.2269485',
        INTPTLON: '-072.7415876',
        ELSDLEA: '10950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5852, 42.311],
            [-71.4868, 42.3301],
            [-71.497, 42.2898],
            [-71.5222, 42.2664],
            [-71.5495, 42.2661],
            [-71.5801, 42.3059],
            [-71.5852, 42.311],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510980',
        NAME: 'Southborough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36317491,
        AWATER: 3921379,
        INTPTLAT: '+42.2996253',
        INTPTLON: '-071.5284274',
        ELSDLEA: '10980',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.135, 42.0907],
            [-72.135, 42.1617],
            [-72.0724, 42.1693],
            [-72.0294, 42.1744],
            [-72.0421, 42.1116],
            [-72.0998, 42.0287],
            [-72.1022, 42.0288],
            [-72.1357, 42.0302],
            [-72.135, 42.0907],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511310',
        NAME: 'Sturbridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96406997,
        AWATER: 4487577,
        INTPTLAT: '+42.1048104',
        INTPTLON: '-072.0877401',
        ELSDLEA: '11310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4755, 42.3806],
            [-71.4727, 42.39],
            [-71.4695, 42.4026],
            [-71.4304, 42.4369],
            [-71.3663, 42.4127],
            [-71.39, 42.3652],
            [-71.3967, 42.3409],
            [-71.4833, 42.3531],
            [-71.4755, 42.3806],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511340',
        NAME: 'Sudbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62863812,
        AWATER: 1244105,
        INTPTLAT: '+42.3833674',
        INTPTLON: '-071.4210704',
        ELSDLEA: '11340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5855, 42.4668],
            [-72.5568, 42.5065],
            [-72.5209, 42.5102],
            [-72.531, 42.4289],
            [-72.5442, 42.4271],
            [-72.5811, 42.4225],
            [-72.5855, 42.4668],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511370',
        NAME: 'Sunderland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36856285,
        AWATER: 1349398,
        INTPTLAT: '+42.4666914',
        INTPTLON: '-072.5467508',
        ELSDLEA: '11370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9767, 42.6209],
            [-70.9653, 42.6719],
            [-70.9036, 42.6598],
            [-70.9311, 42.6124],
            [-70.9647, 42.5996],
            [-70.9767, 42.6209],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511670',
        NAME: 'Topsfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30877193,
        AWATER: 2324528,
        INTPTLAT: '+42.6413794',
        INTPTLON: '-070.9434401',
        ELSDLEA: '11670',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1536, 42.0805],
            [-70.06, 42.0467],
            [-69.9967, 41.9668],
            [-70.0853, 41.9564],
            [-70.1475, 42.0567],
            [-70.1536, 42.0805],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511730',
        NAME: 'Truro School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54267195,
        AWATER: 13686378,
        INTPTLAT: '+42.0135600',
        INTPTLON: '-070.0641518',
        ELSDLEA: '11730',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2701, 42.0925],
            [-72.1926, 42.0915],
            [-72.1988, 42.031],
            [-72.2051, 42.0311],
            [-72.2707, 42.0317],
            [-72.2701, 42.0925],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511940',
        NAME: 'Wales School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40740223,
        AWATER: 598303,
        INTPTLAT: '+42.0613129',
        INTPTLON: '-072.2344109',
        ELSDLEA: '11940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.0853, 41.9564],
            [-69.9967, 41.9668],
            [-69.9521, 41.8806],
            [-70.041, 41.8758],
            [-70.0853, 41.9564],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512300',
        NAME: 'Wellfleet School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51262678,
        AWATER: 40455531,
        INTPTLAT: '+41.9168051',
        INTPTLON: '-070.0271001',
        ELSDLEA: '12300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.65, 41.488],
            [-70.5598, 41.5093],
            [-70.6151, 41.4137],
            [-70.6876, 41.4753],
            [-70.65, 41.488],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512570',
        NAME: 'Tisbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16929453,
        AWATER: 32552135,
        INTPTLAT: '+41.4730705',
        INTPTLON: '-070.6174223',
        ELSDLEA: '12570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7771, 42.3582],
            [-72.7412, 42.3627],
            [-72.7367, 42.2842],
            [-72.7365, 42.2766],
            [-72.8144, 42.2568],
            [-72.8211, 42.3387],
            [-72.7771, 42.3582],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512690',
        NAME: 'Westhampton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70373750,
        AWATER: 478376,
        INTPTLAT: '+42.3140779',
        INTPTLON: '-072.7779767',
        ELSDLEA: '12690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.701, 42.4529],
            [-72.6562, 42.47],
            [-72.5855, 42.4668],
            [-72.5811, 42.4225],
            [-72.6692, 42.4097],
            [-72.701, 42.4529],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512870',
        NAME: 'Whately School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52145070,
        AWATER: 1319939,
        INTPTLAT: '+42.4309950',
        INTPTLON: '-072.6424368',
        ELSDLEA: '12870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7577, 42.446],
            [-72.701, 42.4529],
            [-72.6692, 42.4097],
            [-72.6721, 42.3714],
            [-72.7412, 42.3627],
            [-72.7771, 42.3582],
            [-72.7577, 42.446],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512990',
        NAME: 'Williamsburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66210494,
        AWATER: 353136,
        INTPTLAT: '+42.4054143',
        INTPTLON: '-072.7260428',
        ELSDLEA: '12990',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.265, 42.7459],
            [-73.1636, 42.744],
            [-73.1659, 42.7118],
            [-73.1698, 42.6602],
            [-73.1716, 42.6287],
            [-73.262, 42.6313],
            [-73.307, 42.6326],
            [-73.265, 42.7459],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2513020',
        NAME: 'Williamstown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121107537,
        AWATER: 329835,
        INTPTLAT: '+42.6957427',
        INTPTLON: '-073.2221178',
        ELSDLEA: '13020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4559, 42.0349],
            [-71.3594, 42.0895],
            [-71.2851, 42.0824],
            [-71.2891, 42.045],
            [-71.3814, 42.0188],
            [-71.4581, 42.0178],
            [-71.4559, 42.0349],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2513290',
        NAME: 'Wrentham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56220339,
        AWATER: 2342955,
        INTPTLAT: '+42.0526709',
        INTPTLON: '-071.3568580',
        ELSDLEA: '13290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1621, 42.197],
            [-73.1616, 42.2475],
            [-73.008, 42.2384],
            [-73.0349, 42.1437],
            [-73.0533, 42.0399],
            [-73.1272, 42.0421],
            [-73.1722, 42.0433],
            [-73.1621, 42.197],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2513321',
        NAME: 'Farmington River Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226227594,
        AWATER: 9501152,
        INTPTLAT: '+42.1524327',
        INTPTLON: '-073.1139841',
        ELSDLEA: '13321',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1009, 42.4837],
            [-72.0014, 42.5466],
            [-71.9797, 42.5324],
            [-71.9198, 42.4914],
            [-72.0351, 42.4039],
            [-71.9895, 42.3102],
            [-72.0412, 42.3087],
            [-72.1348, 42.2811],
            [-72.2108, 42.2945],
            [-72.2887, 42.352],
            [-72.2704, 42.3904],
            [-72.1009, 42.4837],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2500001',
        NAME: 'Quabbin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 428888135,
        AWATER: 10412084,
        INTPTLAT: '+42.3975002',
        INTPTLON: '-072.1036444',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.073, 42.2321],
            [-72.0412, 42.3087],
            [-71.9895, 42.3102],
            [-71.9606, 42.2814],
            [-71.9394, 42.1892],
            [-72.0294, 42.1744],
            [-72.0724, 42.1693],
            [-72.073, 42.2321],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2500002',
        NAME: 'Spencer-East Brookfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110554617,
        AWATER: 4468585,
        INTPTLAT: '+42.2311461',
        INTPTLON: '-072.0057166',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0349, 42.1437],
            [-72.8539, 42.115],
            [-72.706, 42.0808],
            [-72.7141, 42.0369],
            [-72.7747, 42.0022],
            [-72.8636, 42.0377],
            [-73.0087, 42.0389],
            [-73.0533, 42.0399],
            [-73.0349, 42.1437],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2500013',
        NAME: 'Southwick-Tolland-Granville Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271013705,
        AWATER: 7439168,
        INTPTLAT: '+42.0808071',
        INTPTLON: '-072.9006158',
        ELSDLEA: '',
        UNSDLEA: '00013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8169, 42.6413],
            [-70.7338, 42.6513],
            [-70.713, 42.5707],
            [-70.7336, 42.504],
            [-70.8101, 42.5841],
            [-70.8169, 42.6413],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2500067',
        NAME: 'Manchester Essex Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60089846,
        AWATER: 28525633,
        INTPTLAT: '+42.5968756',
        INTPTLON: '-070.7599354',
        ELSDLEA: '',
        UNSDLEA: '00067',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6646, 42.6116],
            [-71.6605, 42.6351],
            [-71.5115, 42.5694],
            [-71.5389, 42.5431],
            [-71.6358, 42.5241],
            [-71.679, 42.5304],
            [-71.6646, 42.6116],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2500542',
        NAME: 'Ayer-Shirley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64162078,
        AWATER: 1668144,
        INTPTLAT: '+42.5690045',
        INTPTLON: '-071.6186757',
        ELSDLEA: '',
        UNSDLEA: '00542',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1338, 41.7061],
            [-69.9916, 41.7287],
            [-69.9284, 41.7225],
            [-69.9523, 41.6405],
            [-70.0683, 41.6065],
            [-70.0942, 41.6082],
            [-70.1177, 41.6556],
            [-70.1338, 41.7061],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2500544',
        NAME: 'Monomoy Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95866759,
        AWATER: 53124588,
        INTPTLAT: '+41.6773500',
        INTPTLON: '-070.0297640',
        ELSDLEA: '',
        UNSDLEA: '00544',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9813, 42.1355],
            [-70.9408, 42.1513],
            [-70.9158, 42.1001],
            [-70.9755, 42.0874],
            [-71.002, 42.1265],
            [-70.9813, 42.1355],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2501650',
        NAME: 'Abington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25766399,
        AWATER: 617645,
        INTPTLAT: '+42.1199641',
        INTPTLON: '-070.9572156',
        ELSDLEA: '',
        UNSDLEA: '01650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1716, 42.6287],
            [-73.1698, 42.6602],
            [-73.0633, 42.6547],
            [-73.0636, 42.6499],
            [-73.0854, 42.5595],
            [-73.1658, 42.5109],
            [-73.2121, 42.5424],
            [-73.1716, 42.6287],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2501780',
        NAME: 'Adams-Cheshire School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128729166,
        AWATER: 2010828,
        INTPTLAT: '+42.5932210',
        INTPTLON: '-073.1343307',
        ELSDLEA: '',
        UNSDLEA: '01780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.706, 42.0808],
            [-72.6949, 42.1059],
            [-72.5885, 42.0932],
            [-72.5894, 42.0723],
            [-72.6079, 42.0308],
            [-72.7141, 42.0369],
            [-72.706, 42.0808],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2501800',
        NAME: 'Agawam School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60372114,
        AWATER: 2699489,
        INTPTLAT: '+42.0647311',
        INTPTLON: '-072.6534774',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9975, 42.8644],
            [-70.9145, 42.8866],
            [-70.9042, 42.8339],
            [-70.9403, 42.8225],
            [-71.0049, 42.8633],
            [-70.9975, 42.8644],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2501860',
        NAME: 'Amesbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31761133,
        AWATER: 3785540,
        INTPTLAT: '+42.8512932',
        INTPTLON: '-070.9558104',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2561, 42.6567],
            [-71.2381, 42.6695],
            [-71.1889, 42.6962],
            [-71.1345, 42.6768],
            [-71.0761, 42.6041],
            [-71.1354, 42.5992],
            [-71.165, 42.598],
            [-71.2561, 42.6567],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2501950',
        NAME: 'Andover School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79898675,
        AWATER: 3424075,
        INTPTLAT: '+42.6480437',
        INTPTLON: '-071.1617514',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1823, 42.4363],
            [-71.1498, 42.4358],
            [-71.1323, 42.4152],
            [-71.1345, 42.4042],
            [-71.1524, 42.4008],
            [-71.2026, 42.4148],
            [-71.1823, 42.4363],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2501980',
        NAME: 'Arlington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13328027,
        AWATER: 898397,
        INTPTLAT: '+42.4187215',
        INTPTLON: '-071.1639124',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.986, 42.63],
            [-71.9816, 42.7133],
            [-71.9288, 42.7122],
            [-71.8987, 42.7114],
            [-71.8584, 42.6338],
            [-71.8378, 42.5438],
            [-71.842, 42.5147],
            [-71.9198, 42.4914],
            [-71.9797, 42.5324],
            [-71.986, 42.63],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502040',
        NAME: 'Ashburnham-Westminster School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191118042,
        AWATER: 11474518,
        INTPTLAT: '+42.6165940',
        INTPTLON: '-071.9184553',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5222, 42.2664],
            [-71.497, 42.2898],
            [-71.4193, 42.2573],
            [-71.4201, 42.2381],
            [-71.4672, 42.2308],
            [-71.5222, 42.2664],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502100',
        NAME: 'Ashland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31933903,
        AWATER: 1395324,
        INTPTLAT: '+42.2577546',
        INTPTLON: '-071.4735257',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.283, 42.7215],
            [-72.1111, 42.7172],
            [-72.1166, 42.6266],
            [-72.1856, 42.5504],
            [-72.245, 42.5136],
            [-72.2715, 42.5472],
            [-72.272, 42.6747],
            [-72.283, 42.7215],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502160',
        NAME: 'Athol-Royalston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191886389,
        AWATER: 4574140,
        INTPTLAT: '+42.6344953',
        INTPTLON: '-072.1975135',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3815, 41.9148],
            [-71.2687, 41.9873],
            [-71.2593, 41.9742],
            [-71.2082, 41.9089],
            [-71.3007, 41.9037],
            [-71.3387, 41.8984],
            [-71.3817, 41.8932],
            [-71.3815, 41.9148],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502190',
        NAME: 'Attleboro School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69440158,
        AWATER: 2578247,
        INTPTLAT: '+41.9316531',
        INTPTLON: '-071.2945028',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8951, 42.1924],
            [-71.8566, 42.2267],
            [-71.797, 42.21],
            [-71.8314, 42.1644],
            [-71.8951, 42.1924],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502220',
        NAME: 'Auburn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40099426,
        AWATER: 2408374,
        INTPTLAT: '+42.1987079',
        INTPTLON: '-071.8460062',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0565, 42.1538],
            [-71.0351, 42.1356],
            [-71.0219, 42.1187],
            [-71.0612, 42.1032],
            [-71.0565, 42.1538],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502250',
        NAME: 'Avon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11113467,
        AWATER: 639902,
        INTPTLAT: '+42.1324607',
        INTPTLON: '-071.0540130',
        ELSDLEA: '',
        UNSDLEA: '02250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.4587, 41.664],
            [-70.3836, 41.7397],
            [-70.2611, 41.714],
            [-70.2754, 41.6251],
            [-70.4501, 41.5877],
            [-70.4587, 41.664],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502310',
        NAME: 'Barnstable School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154893547,
        AWATER: 42832315,
        INTPTLAT: '+41.6614734',
        INTPTLON: '-070.3569530',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3175, 42.5024],
            [-71.3066, 42.5206],
            [-71.2319, 42.5064],
            [-71.2409, 42.49],
            [-71.2727, 42.4597],
            [-71.2897, 42.4662],
            [-71.3175, 42.5024],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502400',
        NAME: 'Bedford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35375294,
        AWATER: 460838,
        INTPTLAT: '+42.4992951',
        INTPTLON: '-071.2818888',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4723, 42.3515],
            [-72.3554, 42.3407],
            [-72.3559, 42.3034],
            [-72.3356, 42.2202],
            [-72.3955, 42.1858],
            [-72.4471, 42.2273],
            [-72.4642, 42.308],
            [-72.4723, 42.3515],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502430',
        NAME: 'Belchertown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136345952,
        AWATER: 6943850,
        INTPTLAT: '+42.2790098',
        INTPTLON: '-072.4004682',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5015, 42.1168],
            [-71.4785, 42.1314],
            [-71.4413, 42.1349],
            [-71.4559, 42.0349],
            [-71.4581, 42.0178],
            [-71.4983, 42.0172],
            [-71.4983, 42.0648],
            [-71.4996, 42.1035],
            [-71.5015, 42.1168],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502460',
        NAME: 'Bellingham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47523259,
        AWATER: 1424289,
        INTPTLAT: '+42.0766822',
        INTPTLON: '-071.4744898',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2026, 42.4148],
            [-71.1524, 42.4008],
            [-71.1545, 42.3751],
            [-71.1942, 42.386],
            [-71.2113, 42.4061],
            [-71.2026, 42.4148],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502490',
        NAME: 'Belmont School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12045938,
        AWATER: 185784,
        INTPTLAT: '+42.3953169',
        INTPTLON: '-071.1802841',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4121, 42.3482],
            [-73.4108, 42.3517],
            [-73.3308, 42.352],
            [-73.2883, 42.3235],
            [-73.2568, 42.2417],
            [-73.2873, 42.1591],
            [-73.4084, 42.1603],
            [-73.4313, 42.2961],
            [-73.4121, 42.3482],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502530',
        NAME: 'Berkshire Hills School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222772274,
        AWATER: 5494667,
        INTPTLAT: '+42.2468306',
        INTPTLON: '-073.3441755',
        ELSDLEA: '',
        UNSDLEA: '02530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9223, 42.5915],
            [-70.8101, 42.5841],
            [-70.7336, 42.504],
            [-70.7436, 42.496],
            [-70.7967, 42.5398],
            [-70.9084, 42.5456],
            [-70.9223, 42.5915],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502640',
        NAME: 'Beverly School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39091373,
        AWATER: 19422278,
        INTPTLAT: '+42.5570876',
        INTPTLON: '-070.8430660',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3325, 42.5608],
            [-71.297, 42.613],
            [-71.2963, 42.6127],
            [-71.2111, 42.5733],
            [-71.205, 42.5445],
            [-71.2319, 42.5064],
            [-71.3066, 42.5206],
            [-71.3325, 42.5608],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502670',
        NAME: 'Billerica School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66215811,
        AWATER: 1929712,
        INTPTLAT: '+42.5596734',
        INTPTLON: '-071.2609469',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.592, 42.0637],
            [-71.4983, 42.0648],
            [-71.4983, 42.0172],
            [-71.5291, 42.015],
            [-71.5911, 42.0137],
            [-71.592, 42.0637],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502715',
        NAME: 'Blackstone-Millville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41391421,
        AWATER: 970721,
        INTPTLAT: '+42.0402513',
        INTPTLON: '-071.5440359',
        ELSDLEA: '',
        UNSDLEA: '02715',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1912, 42.2831],
            [-71.1648, 42.3038],
            [-71.1166, 42.3239],
            [-71.1229, 42.3519],
            [-71.1568, 42.3302],
            [-71.1676, 42.3601],
            [-71.1435, 42.365],
            [-71.073, 42.3733],
            [-71.0735, 42.3918],
            [-71.0553, 42.3871],
            [-71.0134, 42.3974],
            [-70.9861, 42.3881],
            [-70.9179, 42.386],
            [-70.8514, 42.3932],
            [-70.8512, 42.3725],
            [-70.9602, 42.2995],
            [-71.0428, 42.277],
            [-71.1308, 42.2279],
            [-71.1313, 42.2283],
            [-71.1882, 42.2804],
            [-71.1912, 42.2831],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502790',
        NAME: 'Boston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125266891,
        AWATER: 106901961,
        INTPTLAT: '+42.3319600',
        INTPTLON: '-071.0201725',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.6766, 41.6911],
            [-70.6326, 41.7628],
            [-70.5374, 41.8108],
            [-70.5194, 41.7933],
            [-70.568, 41.6456],
            [-70.6596, 41.6592],
            [-70.691, 41.6605],
            [-70.6766, 41.6911],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502820',
        NAME: 'Bourne School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105268715,
        AWATER: 31632371,
        INTPTLAT: '+41.7174595',
        INTPTLON: '-070.5918241',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.968, 42.238],
            [-70.9766, 42.1683],
            [-71.0217, 42.169],
            [-71.0495, 42.2068],
            [-70.968, 42.238],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2502940',
        NAME: 'Braintree School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35614626,
        AWATER: 2103165,
        INTPTLAT: '+42.2061879',
        INTPTLON: '-071.0023199',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0872, 41.9908],
            [-71.0547, 41.9851],
            [-70.9796, 42.011],
            [-70.8992, 41.9964],
            [-70.9085, 41.9663],
            [-70.9929, 41.9064],
            [-71.0872, 41.9908],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503030',
        NAME: 'Bridgewater-Raynham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123815394,
        AWATER: 3608196,
        INTPTLAT: '+41.9569130',
        INTPTLON: '-071.0047834',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0802, 42.0957],
            [-71.0612, 42.1032],
            [-71.0219, 42.1187],
            [-71.002, 42.1265],
            [-70.9755, 42.0874],
            [-70.9752, 42.0712],
            [-70.9872, 42.0466],
            [-71.0695, 42.0497],
            [-71.0802, 42.0957],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503090',
        NAME: 'Brockton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55239746,
        AWATER: 508596,
        INTPTLAT: '+42.0825430',
        INTPTLON: '-071.0246378',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1568, 42.3302],
            [-71.1229, 42.3519],
            [-71.1166, 42.3239],
            [-71.1648, 42.3038],
            [-71.1568, 42.3302],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503150',
        NAME: 'Brookline School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17483378,
        AWATER: 161576,
        INTPTLAT: '+42.3238534',
        INTPTLON: '-071.1420033',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2319, 42.5064],
            [-71.205, 42.5445],
            [-71.1701, 42.5156],
            [-71.2043, 42.4659],
            [-71.2409, 42.49],
            [-71.2319, 42.5064],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503240',
        NAME: 'Burlington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30392539,
        AWATER: 323493,
        INTPTLAT: '+42.5032267',
        INTPTLON: '-071.2017130',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1524, 42.4008],
            [-71.1345, 42.4042],
            [-71.073, 42.3733],
            [-71.1435, 42.365],
            [-71.1545, 42.3751],
            [-71.1524, 42.4008],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503270',
        NAME: 'Cambridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16549500,
        AWATER: 1857002,
        INTPTLAT: '+42.3760428',
        INTPTLON: '-071.1186798',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.155, 42.197],
            [-71.146, 42.2092],
            [-71.1313, 42.2283],
            [-71.1308, 42.2279],
            [-71.1029, 42.2007],
            [-71.0666, 42.164],
            [-71.1492, 42.1386],
            [-71.178, 42.164],
            [-71.155, 42.197],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503300',
        NAME: 'Canton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48682898,
        AWATER: 2114233,
        INTPTLAT: '+42.1757371',
        INTPTLON: '-071.1253849',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7587, 41.9475],
            [-70.7535, 41.9332],
            [-70.6813, 41.8463],
            [-70.7177, 41.8015],
            [-70.7703, 41.8258],
            [-70.8298, 41.9277],
            [-70.7587, 41.9475],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503360',
        NAME: 'Carver School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96892457,
        AWATER: 6018630,
        INTPTLAT: '+41.8786436',
        INTPTLON: '-070.7431985',
        ELSDLEA: '',
        UNSDLEA: '03360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.222, 42.4005],
            [-73.1822, 42.4854],
            [-73.1658, 42.5109],
            [-73.0854, 42.5595],
            [-72.979, 42.5416],
            [-72.8556, 42.481],
            [-72.8808, 42.433],
            [-72.9926, 42.4491],
            [-73.008, 42.2384],
            [-73.1616, 42.2475],
            [-73.2364, 42.3645],
            [-73.222, 42.4005],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503390',
        NAME: 'Central Berkshire School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 544871180,
        AWATER: 10409651,
        INTPTLAT: '+42.3981410',
        INTPTLON: '-073.0732349',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4177, 42.6538],
            [-71.3809, 42.649],
            [-71.297, 42.613],
            [-71.3325, 42.5608],
            [-71.3884, 42.5499],
            [-71.4177, 42.6538],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503510',
        NAME: 'Chelmsford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57938351,
        AWATER: 1849839,
        INTPTLAT: '+42.5970548',
        INTPTLON: '-071.3696996',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0332, 42.4142],
            [-71.0134, 42.3974],
            [-71.0553, 42.3871],
            [-71.0332, 42.4142],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503540',
        NAME: 'Chelsea School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5729493,
        AWATER: 638248,
        INTPTLAT: '+42.3968243',
        INTPTLON: '-071.0313479',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5934, 42.2117],
            [-72.5464, 42.2165],
            [-72.5243, 42.2187],
            [-72.516, 42.1608],
            [-72.6215, 42.1238],
            [-72.6273, 42.162],
            [-72.5934, 42.2117],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503660',
        NAME: 'Chicopee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59131117,
        AWATER: 2698864,
        INTPTLAT: '+42.1756682',
        INTPTLON: '-072.5722571',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.726, 42.3903],
            [-71.7184, 42.4103],
            [-71.6606, 42.4182],
            [-71.6788, 42.3892],
            [-71.726, 42.3903],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503750',
        NAME: 'Clinton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14645924,
        AWATER: 4178500,
        INTPTLAT: '+42.4118873',
        INTPTLON: '-071.6900054',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8446, 42.2605],
            [-70.8003, 42.2931],
            [-70.751, 42.2931],
            [-70.827, 42.2008],
            [-70.8446, 42.2605],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503780',
        NAME: 'Cohasset School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25353968,
        AWATER: 56062469,
        INTPTLAT: '+42.2624280',
        INTPTLON: '-070.7988829',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9647, 42.5996],
            [-70.9311, 42.6124],
            [-70.9223, 42.5915],
            [-70.9084, 42.5456],
            [-70.9135, 42.5415],
            [-71.0027, 42.5633],
            [-70.9647, 42.5996],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2503990',
        NAME: 'Danvers School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34383737,
        AWATER: 2348756,
        INTPTLAT: '+42.5741744',
        INTPTLON: '-070.9505165',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0767, 41.6875],
            [-71.0119, 41.7118],
            [-70.9655, 41.7339],
            [-70.9697, 41.6487],
            [-70.9117, 41.5818],
            [-70.8643, 41.5074],
            [-70.9745, 41.4541],
            [-71.0041, 41.484],
            [-71.0767, 41.6875],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504020',
        NAME: 'Dartmouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157794871,
        AWATER: 94818389,
        INTPTLAT: '+41.5665098',
        INTPTLON: '-070.9582285',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1882, 42.2804],
            [-71.1313, 42.2283],
            [-71.146, 42.2092],
            [-71.2317, 42.2593],
            [-71.1882, 42.2804],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504050',
        NAME: 'Dedham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26539781,
        AWATER: 1037581,
        INTPTLAT: '+42.2468719',
        INTPTLON: '-071.1794622',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.2611, 41.714],
            [-70.1221, 41.7588],
            [-70.1338, 41.7061],
            [-70.1177, 41.6556],
            [-70.2754, 41.6251],
            [-70.2611, 41.714],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504140',
        NAME: 'Dennis-Yarmouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115665383,
        AWATER: 15062109,
        INTPTLAT: '+41.6885538',
        INTPTLON: '-070.2034714',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3007, 41.9037],
            [-71.2082, 41.9089],
            [-71.1939, 41.9108],
            [-71.1087, 41.8541],
            [-71.1196, 41.791],
            [-71.1532, 41.7896],
            [-71.2925, 41.7749],
            [-71.3007, 41.9037],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504200',
        NAME: 'Dighton-Rehoboth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178613982,
        AWATER: 2906269,
        INTPTLAT: '+41.8372629',
        INTPTLON: '-071.2190465',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7972, 42.0992],
            [-71.6967, 42.0953],
            [-71.6809, 42.0115],
            [-71.7992, 42.0081],
            [-71.8047, 42.0235],
            [-71.83, 42.0927],
            [-71.7972, 42.0992],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504230',
        NAME: 'Douglas School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94272064,
        AWATER: 3844005,
        INTPTLAT: '+42.0530722',
        INTPTLON: '-071.7522414',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3698, 42.6983],
            [-71.2552, 42.7367],
            [-71.2381, 42.6695],
            [-71.2561, 42.6567],
            [-71.2752, 42.6562],
            [-71.3689, 42.6618],
            [-71.3698, 42.6983],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504320',
        NAME: 'Dracut School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53445521,
        AWATER: 1949421,
        INTPTLAT: '+42.6797230',
        INTPTLON: '-071.3006796',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0294, 42.1744],
            [-71.9394, 42.1892],
            [-71.917, 42.1909],
            [-71.8815, 42.0759],
            [-71.8833, 42.0243],
            [-71.963, 42.0262],
            [-71.9931, 42.0269],
            [-72.0421, 42.1116],
            [-72.0294, 42.1744],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504360',
        NAME: 'Dudley-Charlton Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163170203,
        AWATER: 6896167,
        INTPTLAT: '+42.1074452',
        INTPTLON: '-071.9610981',
        ELSDLEA: '',
        UNSDLEA: '04360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7497, 42.097],
            [-70.6298, 42.0772],
            [-70.5986, 42.0137],
            [-70.6733, 42.0002],
            [-70.7697, 42.0221],
            [-70.7497, 42.097],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504410',
        NAME: 'Duxbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61481357,
        AWATER: 35910949,
        INTPTLAT: '+42.0443242',
        INTPTLON: '-070.7060726',
        ELSDLEA: '',
        UNSDLEA: '04410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9752, 42.0712],
            [-70.8969, 42.0191],
            [-70.8992, 41.9964],
            [-70.9796, 42.011],
            [-70.9872, 42.0466],
            [-70.9752, 42.0712],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504440',
        NAME: 'East Bridgewater School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44570191,
        AWATER: 842755,
        INTPTLAT: '+42.0372780',
        INTPTLON: '-070.9404591',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4712, 42.0897],
            [-72.4582, 42.0341],
            [-72.5092, 42.0341],
            [-72.5316, 42.034],
            [-72.5366, 42.0637],
            [-72.4712, 42.0897],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504500',
        NAME: 'East Longmeadow School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33686063,
        AWATER: 181930,
        INTPTLAT: '+42.0623782',
        INTPTLON: '-072.4981110',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6147, 42.2902],
            [-72.6131, 42.2863],
            [-72.6677, 42.2255],
            [-72.7365, 42.2766],
            [-72.7367, 42.2842],
            [-72.6147, 42.2902],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504590',
        NAME: 'Easthampton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34524322,
        AWATER: 709763,
        INTPTLAT: '+42.2654347',
        INTPTLON: '-072.6708131',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1687, 42.0612],
            [-71.1386, 42.0728],
            [-71.0802, 42.0957],
            [-71.0695, 42.0497],
            [-71.0547, 41.9851],
            [-71.0872, 41.9908],
            [-71.1009, 41.9932],
            [-71.1587, 42.0126],
            [-71.1687, 42.0612],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504620',
        NAME: 'Easton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74479741,
        AWATER: 1230388,
        INTPTLAT: '+42.0405153',
        INTPTLON: '-071.1093286',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0732, 42.413],
            [-71.0283, 42.4241],
            [-71.0332, 42.4142],
            [-71.0553, 42.3871],
            [-71.0735, 42.3918],
            [-71.076, 42.3964],
            [-71.0732, 42.413],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504770',
        NAME: 'Everett School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8871171,
        AWATER: 630367,
        INTPTLAT: '+42.4059375',
        INTPTLON: '-071.0546487',
        ELSDLEA: '',
        UNSDLEA: '04770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9172, 41.6636],
            [-70.8582, 41.6812],
            [-70.8423, 41.6245],
            [-70.906, 41.6238],
            [-70.9172, 41.6636],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504800',
        NAME: 'Fairhaven School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31947058,
        AWATER: 4542702,
        INTPTLAT: '+41.6336185',
        INTPTLON: '-070.8713056',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2012, 41.6819],
            [-71.124, 41.7667],
            [-71.0119, 41.7118],
            [-71.0767, 41.6875],
            [-71.1385, 41.6036],
            [-71.2012, 41.6819],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504830',
        NAME: 'Fall River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85813620,
        AWATER: 18422493,
        INTPTLAT: '+41.7253505',
        INTPTLON: '-071.0941619',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.6975, 41.5272],
            [-70.6472, 41.5785],
            [-70.6596, 41.6592],
            [-70.568, 41.6456],
            [-70.5396, 41.6372],
            [-70.528, 41.5475],
            [-70.6695, 41.5133],
            [-70.6975, 41.5272],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504860',
        NAME: 'Falmouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114130935,
        AWATER: 26850273,
        INTPTLAT: '+41.5949388',
        INTPTLON: '-070.5992432',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8584, 42.6338],
            [-71.7753, 42.6367],
            [-71.763, 42.5639],
            [-71.8378, 42.5438],
            [-71.8584, 42.6338],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504890',
        NAME: 'Fitchburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72075318,
        AWATER: 745618,
        INTPTLAT: '+42.6019793',
        INTPTLON: '-071.8158757',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2851, 42.0824],
            [-71.2803, 42.0885],
            [-71.2388, 42.1064],
            [-71.1785, 42.0574],
            [-71.2883, 42.0145],
            [-71.2891, 42.045],
            [-71.2851, 42.0824],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504950',
        NAME: 'Foxborough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51406167,
        AWATER: 2593194,
        INTPTLAT: '+42.0613376',
        INTPTLON: '-071.2458016',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4868, 42.3301],
            [-71.4833, 42.3531],
            [-71.3967, 42.3409],
            [-71.3777, 42.3147],
            [-71.3904, 42.2719],
            [-71.4193, 42.2573],
            [-71.497, 42.2898],
            [-71.4868, 42.3301],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2504980',
        NAME: 'Framingham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64848090,
        AWATER: 3788911,
        INTPTLAT: '+42.3079052',
        INTPTLON: '-071.4361957',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4413, 42.1349],
            [-71.3802, 42.133],
            [-71.3594, 42.0895],
            [-71.4559, 42.0349],
            [-71.4413, 42.1349],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505010',
        NAME: 'Franklin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68977038,
        AWATER: 1022522,
        INTPTLAT: '+42.0848585',
        INTPTLON: '-071.4105714',
        ELSDLEA: '',
        UNSDLEA: '05010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1145, 41.7788],
            [-71.0075, 41.8385],
            [-70.9873, 41.9058],
            [-70.9192, 41.879],
            [-70.8961, 41.7944],
            [-70.9072, 41.7635],
            [-70.943, 41.7447],
            [-70.9655, 41.7339],
            [-71.0119, 41.7118],
            [-71.124, 41.7667],
            [-71.1145, 41.7788],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505070',
        NAME: 'Freetown-Lakeville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165842225,
        AWATER: 21994691,
        INTPTLAT: '+41.7987732',
        INTPTLON: '-070.9847419',
        ELSDLEA: '',
        UNSDLEA: '05070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.986, 42.63],
            [-71.9797, 42.5324],
            [-72.0014, 42.5466],
            [-72.0461, 42.6018],
            [-71.986, 42.63],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505130',
        NAME: 'Gardner School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57192419,
        AWATER: 2443917,
        INTPTLAT: '+42.5842062',
        INTPTLON: '-071.9887369',
        ELSDLEA: '',
        UNSDLEA: '05130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8808, 42.433],
            [-72.8211, 42.3387],
            [-72.8144, 42.2568],
            [-72.781, 42.1997],
            [-72.8539, 42.115],
            [-73.0349, 42.1437],
            [-73.008, 42.2384],
            [-72.9926, 42.4491],
            [-72.8808, 42.433],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505160',
        NAME: 'Gateway School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 525802483,
        AWATER: 9402850,
        INTPTLAT: '+42.2594751',
        INTPTLON: '-072.9161403',
        ELSDLEA: '',
        UNSDLEA: '05160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9597, 42.7538],
            [-70.9764, 42.6944],
            [-71.0316, 42.718],
            [-70.9597, 42.7538],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505220',
        NAME: 'Georgetown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33307268,
        AWATER: 750792,
        INTPTLAT: '+42.7278794',
        INTPTLON: '-070.9820364',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5406, 42.6557],
            [-72.491, 42.604],
            [-72.4661, 42.5767],
            [-72.4596, 42.5164],
            [-72.5209, 42.5102],
            [-72.5568, 42.5065],
            [-72.5781, 42.5785],
            [-72.5406, 42.6557],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505270',
        NAME: 'Gill-Montague School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113781126,
        AWATER: 6006361,
        INTPTLAT: '+42.5776213',
        INTPTLON: '-072.5152615',
        ELSDLEA: '',
        UNSDLEA: '05270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7338, 42.6513],
            [-70.709, 42.6909],
            [-70.6396, 42.695],
            [-70.6431, 42.6346],
            [-70.5679, 42.6185],
            [-70.713, 42.5707],
            [-70.7338, 42.6513],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505280',
        NAME: 'Gloucester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67840398,
        AWATER: 39650432,
        INTPTLAT: '+42.6288499',
        INTPTLON: '-070.6858747',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0519, 41.4167],
            [-70.9745, 41.4541],
            [-70.8643, 41.5074],
            [-70.7585, 41.5609],
            [-70.6975, 41.5272],
            [-70.6695, 41.5133],
            [-70.65, 41.488],
            [-70.6876, 41.4753],
            [-70.8962, 41.3701],
            [-70.9907, 41.3513],
            [-71.0519, 41.4167],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505340',
        NAME: 'Gosnold School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34153243,
        AWATER: 328890671,
        INTPTLAT: '+41.4431098',
        INTPTLON: '-070.8627068',
        ELSDLEA: '',
        UNSDLEA: '05340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7335, 42.2381],
            [-71.7312, 42.2414],
            [-71.6745, 42.2524],
            [-71.6444, 42.2266],
            [-71.6422, 42.1657],
            [-71.6913, 42.1631],
            [-71.7235, 42.1915],
            [-71.7335, 42.2381],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505370',
        NAME: 'Grafton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59086094,
        AWATER: 1305070,
        INTPTLAT: '+42.2091512',
        INTPTLON: '-071.6836442',
        ELSDLEA: '',
        UNSDLEA: '05370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5279, 42.3014],
            [-72.4642, 42.308],
            [-72.4471, 42.2273],
            [-72.5243, 42.2187],
            [-72.5464, 42.2165],
            [-72.5279, 42.3014],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505400',
        NAME: 'Granby School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72079712,
        AWATER: 667247,
        INTPTLAT: '+42.2449487',
        INTPTLON: '-072.5001426',
        ELSDLEA: '',
        UNSDLEA: '05400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6206, 42.6469],
            [-72.5406, 42.6557],
            [-72.5781, 42.5785],
            [-72.6368, 42.578],
            [-72.6206, 42.6469],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505490',
        NAME: 'Greenfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55496638,
        AWATER: 1180578,
        INTPTLAT: '+42.6146712',
        INTPTLON: '-072.5970627',
        ELSDLEA: '',
        UNSDLEA: '05490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5425, 42.7027],
            [-71.4577, 42.7005],
            [-71.4935, 42.6174],
            [-71.498, 42.5769],
            [-71.5115, 42.5694],
            [-71.6605, 42.6351],
            [-71.5425, 42.7027],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505500',
        NAME: 'Groton-Dunstable School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127457670,
        AWATER: 3377306,
        INTPTLAT: '+42.6305825',
        INTPTLON: '-071.5342696',
        ELSDLEA: '',
        UNSDLEA: '05500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.634, 42.3512],
            [-72.5811, 42.4225],
            [-72.5442, 42.4271],
            [-72.535, 42.3042],
            [-72.6092, 42.2855],
            [-72.6131, 42.2863],
            [-72.6147, 42.2902],
            [-72.634, 42.3512],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505580',
        NAME: 'Hadley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59792577,
        AWATER: 3938991,
        INTPTLAT: '+42.3564911',
        INTPTLON: '-072.5686319',
        ELSDLEA: '',
        UNSDLEA: '05580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9311, 42.6124],
            [-70.9036, 42.6598],
            [-70.8169, 42.6413],
            [-70.8101, 42.5841],
            [-70.9223, 42.5915],
            [-70.9311, 42.6124],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505670',
        NAME: 'Hamilton-Wenham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56577737,
        AWATER: 3136392,
        INTPTLAT: '+42.6192038',
        INTPTLON: '-070.8668183',
        ELSDLEA: '',
        UNSDLEA: '05670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3953, 42.1848],
            [-72.3889, 42.1524],
            [-72.367, 42.0327],
            [-72.3974, 42.0331],
            [-72.4582, 42.0341],
            [-72.4712, 42.0897],
            [-72.4846, 42.1565],
            [-72.3953, 42.1848],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505730',
        NAME: 'Hampden-Wilbraham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108273147,
        AWATER: 671019,
        INTPTLAT: '+42.1035888',
        INTPTLON: '-072.4216951',
        ELSDLEA: '',
        UNSDLEA: '05730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8843, 42.1537],
            [-70.8035, 42.1117],
            [-70.8425, 42.0948],
            [-70.8876, 42.088],
            [-70.8843, 42.1537],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505790',
        NAME: 'Hanover School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40427612,
        AWATER: 218489,
        INTPTLAT: '+42.1229562',
        INTPTLON: '-070.8563099',
        ELSDLEA: '',
        UNSDLEA: '05790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5389, 42.5431],
            [-71.5299, 42.5197],
            [-71.5433, 42.4665],
            [-71.6358, 42.5241],
            [-71.5389, 42.5431],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505880',
        NAME: 'Harvard School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68467429,
        AWATER: 1901033,
        INTPTLAT: '+42.5042565',
        INTPTLON: '-071.5885131',
        ELSDLEA: '',
        UNSDLEA: '05880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6692, 42.4097],
            [-72.5811, 42.4225],
            [-72.634, 42.3512],
            [-72.6721, 42.3714],
            [-72.6692, 42.4097],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505940',
        NAME: 'Hatfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41202069,
        AWATER: 2403748,
        INTPTLAT: '+42.3894016',
        INTPTLON: '-072.6168064',
        ELSDLEA: '',
        UNSDLEA: '05940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1667, 42.8083],
            [-71.0536, 42.8331],
            [-71.0589, 42.7325],
            [-71.1086, 42.7353],
            [-71.1156, 42.7412],
            [-71.1824, 42.7942],
            [-71.1667, 42.8083],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2505970',
        NAME: 'Haverhill School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85386016,
        AWATER: 6895100,
        INTPTLAT: '+42.7829453',
        INTPTLON: '-071.0874356',
        ELSDLEA: '',
        UNSDLEA: '05970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9141, 42.2888],
            [-70.8446, 42.2605],
            [-70.827, 42.2008],
            [-70.8388, 42.1948],
            [-70.8903, 42.1717],
            [-70.9249, 42.1576],
            [-70.9141, 42.2888],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506090',
        NAME: 'Hingham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57568303,
        AWATER: 10578449,
        INTPTLAT: '+42.2230757',
        INTPTLON: '-070.8869380',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0351, 42.1356],
            [-71.0217, 42.169],
            [-70.9766, 42.1683],
            [-70.9813, 42.1355],
            [-71.002, 42.1265],
            [-71.0219, 42.1187],
            [-71.0351, 42.1356],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506150',
        NAME: 'Holbrook School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18780283,
        AWATER: 282430,
        INTPTLAT: '+42.1402442',
        INTPTLON: '-070.9973471',
        ELSDLEA: '',
        UNSDLEA: '06150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5026, 42.1914],
            [-71.4672, 42.2308],
            [-71.4201, 42.2381],
            [-71.3848, 42.192],
            [-71.4022, 42.1788],
            [-71.4781, 42.1568],
            [-71.5026, 42.1914],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506240',
        NAME: 'Holliston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48291187,
        AWATER: 1069008,
        INTPTLAT: '+42.1959508',
        INTPTLON: '-071.4534301',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6677, 42.2255],
            [-72.6131, 42.2863],
            [-72.6092, 42.2855],
            [-72.5934, 42.2117],
            [-72.6273, 42.162],
            [-72.6848, 42.163],
            [-72.6869, 42.1834],
            [-72.6677, 42.2255],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506270',
        NAME: 'Holyoke School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55116744,
        AWATER: 4025639,
        INTPTLAT: '+42.2116562',
        INTPTLON: '-072.6424480',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.561, 42.1583],
            [-71.5015, 42.1168],
            [-71.4996, 42.1035],
            [-71.561, 42.1583],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506300',
        NAME: 'Hopedale School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13392922,
        AWATER: 443989,
        INTPTLAT: '+42.1230066',
        INTPTLON: '-071.5314040',
        ELSDLEA: '',
        UNSDLEA: '06300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5993, 42.226],
            [-71.5495, 42.2661],
            [-71.5222, 42.2664],
            [-71.4672, 42.2308],
            [-71.5026, 42.1914],
            [-71.5556, 42.1826],
            [-71.5993, 42.226],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506330',
        NAME: 'Hopkinton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68016797,
        AWATER: 4215054,
        INTPTLAT: '+42.2240957',
        INTPTLON: '-071.5404885',
        ELSDLEA: '',
        UNSDLEA: '06330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6043, 42.3977],
            [-71.4727, 42.39],
            [-71.4755, 42.3806],
            [-71.6032, 42.3673],
            [-71.6043, 42.3977],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506390',
        NAME: 'Hudson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29841392,
        AWATER: 890269,
        INTPTLAT: '+42.3890712',
        INTPTLON: '-071.5458637',
        ELSDLEA: '',
        UNSDLEA: '06390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9602, 42.2995],
            [-70.8512, 42.3725],
            [-70.8003, 42.2931],
            [-70.8446, 42.2605],
            [-70.9141, 42.2888],
            [-70.9377, 42.2775],
            [-70.9602, 42.2995],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506420',
        NAME: 'Hull School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7275374,
        AWATER: 62303576,
        INTPTLAT: '+42.3402327',
        INTPTLON: '-070.8833012',
        ELSDLEA: '',
        UNSDLEA: '06420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9486, 42.6807],
            [-70.7858, 42.7401],
            [-70.709, 42.6909],
            [-70.7338, 42.6513],
            [-70.8169, 42.6413],
            [-70.9036, 42.6598],
            [-70.9653, 42.6719],
            [-70.9486, 42.6807],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506480',
        NAME: 'Ipswich School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83162107,
        AWATER: 26941548,
        INTPTLAT: '+42.6959806',
        INTPTLON: '-070.8356476',
        ELSDLEA: '',
        UNSDLEA: '06480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1341, 42.7215],
            [-71.1345, 42.6768],
            [-71.1889, 42.6962],
            [-71.1341, 42.7215],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506660',
        NAME: 'Lawrence School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17937592,
        AWATER: 1242749,
        INTPTLAT: '+42.7002733',
        INTPTLON: '-071.1613574',
        ELSDLEA: '',
        UNSDLEA: '06660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2883, 42.3235],
            [-73.2364, 42.3645],
            [-73.1616, 42.2475],
            [-73.1621, 42.197],
            [-73.2568, 42.2417],
            [-73.2883, 42.3235],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506690',
        NAME: 'Lee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116029382,
        AWATER: 2839699,
        INTPTLAT: '+42.2832945',
        INTPTLON: '-073.2273068',
        ELSDLEA: '',
        UNSDLEA: '06690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9606, 42.2814],
            [-71.8841, 42.2814],
            [-71.8566, 42.2267],
            [-71.8951, 42.1924],
            [-71.917, 42.1909],
            [-71.9394, 42.1892],
            [-71.9606, 42.2814],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506720',
        NAME: 'Leicester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60221037,
        AWATER: 3572329,
        INTPTLAT: '+42.2384267',
        INTPTLON: '-071.9123398',
        ELSDLEA: '',
        UNSDLEA: '06720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2953, 42.4119],
            [-73.222, 42.4005],
            [-73.2364, 42.3645],
            [-73.2883, 42.3235],
            [-73.3308, 42.352],
            [-73.2953, 42.4119],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506750',
        NAME: 'Lenox School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54958356,
        AWATER: 1168734,
        INTPTLAT: '+42.3678582',
        INTPTLON: '-073.2697633',
        ELSDLEA: '',
        UNSDLEA: '06750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.842, 42.5147],
            [-71.8378, 42.5438],
            [-71.763, 42.5639],
            [-71.7022, 42.5339],
            [-71.7284, 42.4751],
            [-71.842, 42.5147],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506780',
        NAME: 'Leominster School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74629276,
        AWATER: 2233725,
        INTPTLAT: '+42.5208343',
        INTPTLON: '-071.7705075',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2727, 42.4597],
            [-71.2409, 42.49],
            [-71.2043, 42.4659],
            [-71.1896, 42.4498],
            [-71.1823, 42.4363],
            [-71.2026, 42.4148],
            [-71.2113, 42.4061],
            [-71.2672, 42.4244],
            [-71.2727, 42.4597],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506840',
        NAME: 'Lexington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42563412,
        AWATER: 543865,
        INTPTLAT: '+42.4457434',
        INTPTLON: '-071.2309085',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5115, 42.5694],
            [-71.498, 42.5769],
            [-71.4285, 42.5288],
            [-71.4735, 42.5002],
            [-71.5299, 42.5197],
            [-71.5389, 42.5431],
            [-71.5115, 42.5694],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506960',
        NAME: 'Littleton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42793829,
        AWATER: 2580901,
        INTPTLAT: '+42.5359311',
        INTPTLON: '-071.4905688',
        ELSDLEA: '',
        UNSDLEA: '06960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5894, 42.0723],
            [-72.5366, 42.0637],
            [-72.5316, 42.034],
            [-72.6069, 42.025],
            [-72.6079, 42.0308],
            [-72.5894, 42.0723],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2506990',
        NAME: 'Longmeadow School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23619287,
        AWATER: 1331610,
        INTPTLAT: '+42.0475652',
        INTPTLON: '-072.5711305',
        ELSDLEA: '',
        UNSDLEA: '06990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3689, 42.6618],
            [-71.2752, 42.6562],
            [-71.2963, 42.6127],
            [-71.297, 42.613],
            [-71.3809, 42.649],
            [-71.3689, 42.6618],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507020',
        NAME: 'Lowell School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35179749,
        AWATER: 2441956,
        INTPTLAT: '+42.6389800',
        INTPTLON: '-071.3211407',
        ELSDLEA: '',
        UNSDLEA: '07020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4471, 42.2273],
            [-72.3955, 42.1858],
            [-72.3953, 42.1848],
            [-72.4846, 42.1565],
            [-72.516, 42.1608],
            [-72.5243, 42.2187],
            [-72.4471, 42.2273],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507050',
        NAME: 'Ludlow School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70461180,
        AWATER: 2835835,
        INTPTLAT: '+42.1835768',
        INTPTLON: '-072.4578116',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6646, 42.6116],
            [-71.679, 42.5304],
            [-71.7022, 42.5339],
            [-71.763, 42.5639],
            [-71.7753, 42.6367],
            [-71.6646, 42.6116],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507080',
        NAME: 'Lunenburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68425511,
        AWATER: 3423861,
        INTPTLAT: '+42.5826069',
        INTPTLON: '-071.7204641',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0116, 42.5017],
            [-70.9869, 42.5118],
            [-70.9499, 42.4948],
            [-70.934, 42.4908],
            [-70.9015, 42.4496],
            [-70.906, 42.4383],
            [-70.9508, 42.4443],
            [-70.9686, 42.4444],
            [-71.0116, 42.5017],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507110',
        NAME: 'Lynn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27819883,
        AWATER: 7207059,
        INTPTLAT: '+42.4748618',
        INTPTLON: '-070.9621851',
        ELSDLEA: '',
        UNSDLEA: '07110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0753, 42.5311],
            [-71.071, 42.5559],
            [-71.0352, 42.5704],
            [-71.0348, 42.5655],
            [-70.9869, 42.5118],
            [-71.0116, 42.5017],
            [-71.0361, 42.5052],
            [-71.0753, 42.5311],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507140',
        NAME: 'Lynnfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25595719,
        AWATER: 1512429,
        INTPTLAT: '+42.5341456',
        INTPTLON: '-071.0383332',
        ELSDLEA: '',
        UNSDLEA: '07140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0857, 42.4447],
            [-71.0821, 42.444],
            [-71.0259, 42.4447],
            [-71.0258, 42.4448],
            [-71.0283, 42.4241],
            [-71.0732, 42.413],
            [-71.0857, 42.4447],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507170',
        NAME: 'Malden School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13063237,
        AWATER: 98374,
        INTPTLAT: '+42.4293799',
        INTPTLON: '-071.0587065',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2883, 42.0145],
            [-71.1785, 42.0574],
            [-71.1687, 42.0612],
            [-71.1587, 42.0126],
            [-71.2593, 41.9742],
            [-71.2687, 41.9873],
            [-71.2883, 42.0144],
            [-71.2883, 42.0145],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507230',
        NAME: 'Mansfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52060776,
        AWATER: 830936,
        INTPTLAT: '+42.0173020',
        INTPTLON: '-071.2164095',
        ELSDLEA: '',
        UNSDLEA: '07230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7676, 42.4846],
            [-70.8386, 42.4555],
            [-70.899, 42.4847],
            [-70.7676, 42.4846],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507260',
        NAME: 'Marblehead School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11360652,
        AWATER: 39376628,
        INTPTLAT: '+42.4956288',
        INTPTLON: '-070.8358197',
        ELSDLEA: '',
        UNSDLEA: '07260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6247, 42.3505],
            [-71.6032, 42.3673],
            [-71.4755, 42.3806],
            [-71.4833, 42.3531],
            [-71.4868, 42.3301],
            [-71.5852, 42.311],
            [-71.6247, 42.3505],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507320',
        NAME: 'Marlborough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54041935,
        AWATER: 3200305,
        INTPTLAT: '+42.3496175',
        INTPTLON: '-071.5472140',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7524, 42.1639],
            [-70.6696, 42.1294],
            [-70.6298, 42.0772],
            [-70.7497, 42.097],
            [-70.7769, 42.117],
            [-70.7524, 42.1639],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507350',
        NAME: 'Marshfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74127489,
        AWATER: 8134278,
        INTPTLAT: '+42.1151393',
        INTPTLON: '-070.7103308',
        ELSDLEA: '',
        UNSDLEA: '07350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.5396, 41.6372],
            [-70.4587, 41.664],
            [-70.4501, 41.5877],
            [-70.528, 41.5475],
            [-70.5396, 41.6372],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507440',
        NAME: 'Mashpee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60589881,
        AWATER: 9941924,
        INTPTLAT: '+41.6176232',
        INTPTLON: '-070.4927131',
        ELSDLEA: '',
        UNSDLEA: '07440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4643, 42.4503],
            [-71.4304, 42.4369],
            [-71.4695, 42.4026],
            [-71.4643, 42.4503],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507500',
        NAME: 'Maynard School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13502215,
        AWATER: 407076,
        INTPTLAT: '+42.4259551',
        INTPTLON: '-071.4562562',
        ELSDLEA: '',
        UNSDLEA: '07500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3384, 42.2152],
            [-71.2696, 42.2005],
            [-71.3038, 42.1495],
            [-71.3396, 42.1494],
            [-71.3442, 42.2007],
            [-71.3384, 42.2152],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507530',
        NAME: 'Medfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37301496,
        AWATER: 605187,
        INTPTLAT: '+42.1845991',
        INTPTLON: '-071.3053065',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1498, 42.4358],
            [-71.1194, 42.4534],
            [-71.0857, 42.4447],
            [-71.0732, 42.413],
            [-71.076, 42.3964],
            [-71.1323, 42.4152],
            [-71.1498, 42.4358],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507560',
        NAME: 'Medford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20981873,
        AWATER: 1443128,
        INTPTLAT: '+42.4238438',
        INTPTLON: '-071.1092309',
        ELSDLEA: '',
        UNSDLEA: '07560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4781, 42.1568],
            [-71.4022, 42.1788],
            [-71.3756, 42.1355],
            [-71.3802, 42.133],
            [-71.4413, 42.1349],
            [-71.4785, 42.1314],
            [-71.4781, 42.1568],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507590',
        NAME: 'Medway School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29895582,
        AWATER: 335627,
        INTPTLAT: '+42.1562029',
        INTPTLON: '-071.4303445',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0821, 42.444],
            [-71.078, 42.474],
            [-71.0535, 42.4759],
            [-71.0259, 42.4447],
            [-71.0821, 42.444],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507620',
        NAME: 'Melrose School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12117823,
        AWATER: 219780,
        INTPTLAT: '+42.4557230',
        INTPTLON: '-071.0590194',
        ELSDLEA: '',
        UNSDLEA: '07620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6444, 42.2266],
            [-71.5993, 42.226],
            [-71.5556, 42.1826],
            [-71.561, 42.1583],
            [-71.4996, 42.1035],
            [-71.4983, 42.0648],
            [-71.592, 42.0637],
            [-71.5926, 42.1142],
            [-71.6422, 42.1657],
            [-71.6444, 42.2266],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507680',
        NAME: 'Mendon-Upton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101898403,
        AWATER: 1145906,
        INTPTLAT: '+42.1414149',
        INTPTLON: '-071.5837098',
        ELSDLEA: '',
        UNSDLEA: '07680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2455, 42.7426],
            [-71.1824, 42.7942],
            [-71.1156, 42.7412],
            [-71.1341, 42.7215],
            [-71.1889, 42.6962],
            [-71.2381, 42.6695],
            [-71.2552, 42.7367],
            [-71.2455, 42.7426],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507740',
        NAME: 'Methuen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57627002,
        AWATER: 2037920,
        INTPTLAT: '+42.7424680',
        INTPTLON: '-071.1792179',
        ELSDLEA: '',
        UNSDLEA: '07740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9085, 41.9663],
            [-70.8609, 41.955],
            [-70.8298, 41.9277],
            [-70.7703, 41.8258],
            [-70.7718, 41.8089],
            [-70.8961, 41.7944],
            [-70.9192, 41.879],
            [-70.9873, 41.9058],
            [-70.9929, 41.9064],
            [-70.9085, 41.9663],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507770',
        NAME: 'Middleborough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178905966,
        AWATER: 7984661,
        INTPTLAT: '+41.8780043',
        INTPTLON: '-070.8692669',
        ELSDLEA: '',
        UNSDLEA: '07770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5556, 42.1826],
            [-71.5026, 42.1914],
            [-71.4781, 42.1568],
            [-71.4785, 42.1314],
            [-71.5015, 42.1168],
            [-71.561, 42.1583],
            [-71.5556, 42.1826],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507860',
        NAME: 'Milford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38204296,
        AWATER: 711614,
        INTPTLAT: '+42.1586917',
        INTPTLON: '-071.5214188',
        ELSDLEA: '',
        UNSDLEA: '07860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8314, 42.1644],
            [-71.797, 42.21],
            [-71.7335, 42.2381],
            [-71.7235, 42.1915],
            [-71.8294, 42.1449],
            [-71.8314, 42.1644],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507890',
        NAME: 'Millbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40684001,
        AWATER: 1884788,
        INTPTLAT: '+42.1922464',
        INTPTLON: '-071.7776490',
        ELSDLEA: '',
        UNSDLEA: '07890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3848, 42.192],
            [-71.3442, 42.2007],
            [-71.3396, 42.1494],
            [-71.3756, 42.1355],
            [-71.4022, 42.1788],
            [-71.3848, 42.192],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507920',
        NAME: 'Millis School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31135997,
        AWATER: 602476,
        INTPTLAT: '+42.1731025',
        INTPTLON: '-071.3637564',
        ELSDLEA: '',
        UNSDLEA: '07920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0428, 42.277],
            [-71.0827, 42.2043],
            [-71.1029, 42.2007],
            [-71.1308, 42.2279],
            [-71.0428, 42.277],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507980',
        NAME: 'Milton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33692902,
        AWATER: 756207,
        INTPTLAT: '+42.2415568',
        INTPTLON: '-071.0824318',
        ELSDLEA: '',
        UNSDLEA: '07980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.979, 42.5416],
            [-72.9754, 42.5559],
            [-72.8769, 42.5412],
            [-72.8215, 42.6369],
            [-72.8576, 42.674],
            [-72.8643, 42.7378],
            [-72.8091, 42.7366],
            [-72.6862, 42.7332],
            [-72.6746, 42.7329],
            [-72.6206, 42.6469],
            [-72.6368, 42.578],
            [-72.6755, 42.553],
            [-72.7637, 42.4636],
            [-72.8556, 42.481],
            [-72.979, 42.5416],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2507990',
        NAME: 'Mohawk Trail School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 445348537,
        AWATER: 3126367,
        INTPTLAT: '+42.6073829',
        INTPTLON: '-072.7901374',
        ELSDLEA: '',
        UNSDLEA: '07990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3889, 42.1524],
            [-72.2743, 42.1439],
            [-72.2701, 42.0925],
            [-72.2707, 42.0317],
            [-72.367, 42.0327],
            [-72.3889, 42.1524],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508040',
        NAME: 'Monson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114315467,
        AWATER: 1684806,
        INTPTLAT: '+42.0935962',
        INTPTLON: '-072.3214936',
        ELSDLEA: '',
        UNSDLEA: '08040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.3294, 41.3394],
            [-70.0976, 41.2997],
            [-69.9984, 41.363],
            [-69.9471, 41.2828],
            [-70.0123, 41.2284],
            [-70.257, 41.2728],
            [-70.3294, 41.3394],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508250',
        NAME: 'Nantucket School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119431671,
        AWATER: 153206925,
        INTPTLAT: '+41.2934161',
        INTPTLON: '-070.1021689',
        ELSDLEA: '',
        UNSDLEA: '08250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1166, 42.6266],
            [-72.0461, 42.6018],
            [-72.0014, 42.5466],
            [-72.1009, 42.4837],
            [-72.1856, 42.5504],
            [-72.1166, 42.6266],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508280',
        NAME: 'Narragansett School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145385142,
        AWATER: 2286331,
        INTPTLAT: '+42.5522551',
        INTPTLON: '-072.0992810',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7284, 42.4751],
            [-71.7022, 42.5339],
            [-71.679, 42.5304],
            [-71.6358, 42.5241],
            [-71.5433, 42.4665],
            [-71.4985, 42.4638],
            [-71.4643, 42.4503],
            [-71.4695, 42.4026],
            [-71.4727, 42.39],
            [-71.6043, 42.3977],
            [-71.6606, 42.4182],
            [-71.7184, 42.4103],
            [-71.7284, 42.4751],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508310',
        NAME: 'Nashoba School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167623402,
        AWATER: 3422336,
        INTPTLAT: '+42.4489538',
        INTPTLON: '-071.6117413',
        ELSDLEA: '',
        UNSDLEA: '08310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3777, 42.3147],
            [-71.3288, 42.3317],
            [-71.3273, 42.3137],
            [-71.3085, 42.2707],
            [-71.3324, 42.2477],
            [-71.3904, 42.2719],
            [-71.3777, 42.3147],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508340',
        NAME: 'Natick School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38729766,
        AWATER: 2606486,
        INTPTLAT: '+42.2848223',
        INTPTLON: '-071.3488109',
        ELSDLEA: '',
        UNSDLEA: '08340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2999, 42.2695],
            [-71.2957, 42.2725],
            [-71.2948, 42.2731],
            [-71.2277, 42.3155],
            [-71.1912, 42.2831],
            [-71.1882, 42.2804],
            [-71.2317, 42.2593],
            [-71.2324, 42.2585],
            [-71.2999, 42.2695],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508370',
        NAME: 'Needham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31821537,
        AWATER: 1117182,
        INTPTLAT: '+42.2815935',
        INTPTLON: '-071.2424143',
        ELSDLEA: '',
        UNSDLEA: '08370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9655, 41.7339],
            [-70.943, 41.7447],
            [-70.9172, 41.6636],
            [-70.906, 41.6238],
            [-70.9117, 41.5818],
            [-70.9697, 41.6487],
            [-70.9655, 41.7339],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508430',
        NAME: 'New Bedford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51791487,
        AWATER: 10708754,
        INTPTLAT: '+41.6613028',
        INTPTLON: '-070.9379126',
        ELSDLEA: '',
        UNSDLEA: '08430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9403, 42.8225],
            [-70.9042, 42.8339],
            [-70.81, 42.8186],
            [-70.8086, 42.8115],
            [-70.9174, 42.7875],
            [-70.9403, 42.8225],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508580',
        NAME: 'Newburyport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21621652,
        AWATER: 5985747,
        INTPTLAT: '+42.8123585',
        INTPTLON: '-070.8910953',
        ELSDLEA: '',
        UNSDLEA: '08580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2604, 42.3536],
            [-71.2171, 42.3666],
            [-71.1676, 42.3601],
            [-71.1568, 42.3302],
            [-71.1648, 42.3038],
            [-71.1912, 42.2831],
            [-71.2277, 42.3155],
            [-71.2699, 42.3281],
            [-71.2604, 42.3536],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508610',
        NAME: 'Newton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46198296,
        AWATER: 847272,
        INTPTLAT: '+42.3318764',
        INTPTLON: '-071.2084023',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1659, 42.7118],
            [-73.0595, 42.7084],
            [-73.0633, 42.6547],
            [-73.1698, 42.6602],
            [-73.1659, 42.7118],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508670',
        NAME: 'North Adams School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52696372,
        AWATER: 691991,
        INTPTLAT: '+42.6755506',
        INTPTLON: '-073.1181090',
        ELSDLEA: '',
        UNSDLEA: '08670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1341, 42.7215],
            [-71.1156, 42.7412],
            [-71.1086, 42.7353],
            [-71.0223, 42.6416],
            [-71.0588, 42.609],
            [-71.0761, 42.6041],
            [-71.1345, 42.6768],
            [-71.1341, 42.7215],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508700',
        NAME: 'North Andover School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68133506,
        AWATER: 3720499,
        INTPTLAT: '+42.6739092',
        INTPTLON: '-071.0913339',
        ELSDLEA: '',
        UNSDLEA: '08700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3813, 41.985],
            [-71.2883, 42.0144],
            [-71.2687, 41.9873],
            [-71.3815, 41.9148],
            [-71.3813, 41.985],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508730',
        NAME: 'North Attleborough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48883591,
        AWATER: 1362627,
        INTPTLAT: '+41.9725091',
        INTPTLON: '-071.3343971',
        ELSDLEA: '',
        UNSDLEA: '08730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0412, 42.3087],
            [-72.073, 42.2321],
            [-72.1113, 42.2385],
            [-72.1348, 42.2811],
            [-72.0412, 42.3087],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508760',
        NAME: 'North Brookfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55084069,
        AWATER: 1759511,
        INTPTLAT: '+42.2675643',
        INTPTLON: '-072.0668759',
        ELSDLEA: '',
        UNSDLEA: '08760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8987, 42.7114],
            [-71.8054, 42.709],
            [-71.7012, 42.7064],
            [-71.6304, 42.7047],
            [-71.543, 42.7027],
            [-71.5425, 42.7027],
            [-71.6605, 42.6351],
            [-71.6646, 42.6116],
            [-71.7753, 42.6367],
            [-71.8584, 42.6338],
            [-71.8987, 42.7114],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508790',
        NAME: 'North Middlesex School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204874714,
        AWATER: 3233801,
        INTPTLAT: '+42.6704475',
        INTPTLON: '-071.7139084',
        ELSDLEA: '',
        UNSDLEA: '08790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1354, 42.5992],
            [-71.0761, 42.6041],
            [-71.0588, 42.609],
            [-71.0352, 42.5704],
            [-71.071, 42.5559],
            [-71.1365, 42.5526],
            [-71.1354, 42.5992],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508820',
        NAME: 'North Reading School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34031841,
        AWATER: 924521,
        INTPTLAT: '+42.5782217',
        INTPTLON: '-071.0843981',
        ELSDLEA: '',
        UNSDLEA: '08820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7412, 42.3627],
            [-72.6721, 42.3714],
            [-72.634, 42.3512],
            [-72.6147, 42.2902],
            [-72.7367, 42.2842],
            [-72.7412, 42.3627],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508850',
        NAME: 'Northampton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88693161,
        AWATER: 3903664,
        INTPTLAT: '+42.3270445',
        INTPTLON: '-072.6746304',
        ELSDLEA: '',
        UNSDLEA: '08850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6913, 42.1631],
            [-71.6422, 42.1657],
            [-71.5926, 42.1142],
            [-71.68, 42.1034],
            [-71.6913, 42.1631],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2508940',
        NAME: 'Northbridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44726558,
        AWATER: 2037875,
        INTPTLAT: '+42.1282827',
        INTPTLON: '-071.6577721',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2593, 41.9742],
            [-71.1587, 42.0126],
            [-71.1009, 41.9932],
            [-71.1939, 41.9108],
            [-71.2082, 41.9089],
            [-71.2593, 41.9742],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509000',
        NAME: 'Norton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72033210,
        AWATER: 3885982,
        INTPTLAT: '+41.9653413',
        INTPTLON: '-071.1817803',
        ELSDLEA: '',
        UNSDLEA: '09000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8903, 42.1717],
            [-70.8388, 42.1948],
            [-70.7524, 42.1639],
            [-70.7769, 42.117],
            [-70.8035, 42.1117],
            [-70.8843, 42.1537],
            [-70.8903, 42.1717],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509030',
        NAME: 'Norwell School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54202670,
        AWATER: 812574,
        INTPTLAT: '+42.1645688',
        INTPTLON: '-070.8188439',
        ELSDLEA: '',
        UNSDLEA: '09030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2383, 42.1828],
            [-71.155, 42.197],
            [-71.178, 42.164],
            [-71.1945, 42.1499],
            [-71.2383, 42.1828],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509060',
        NAME: 'Norwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26853982,
        AWATER: 420005,
        INTPTLAT: '+42.1873602',
        INTPTLON: '-071.1959555',
        ELSDLEA: '',
        UNSDLEA: '09060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.917, 42.1909],
            [-71.8951, 42.1924],
            [-71.8314, 42.1644],
            [-71.8294, 42.1449],
            [-71.7972, 42.0992],
            [-71.83, 42.0927],
            [-71.8815, 42.0759],
            [-71.917, 42.1909],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509270',
        NAME: 'Oxford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68710281,
        AWATER: 2350562,
        INTPTLAT: '+42.1282605',
        INTPTLON: '-071.8671001',
        ELSDLEA: '',
        UNSDLEA: '09270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3955, 42.1858],
            [-72.3356, 42.2202],
            [-72.2212, 42.2453],
            [-72.2639, 42.1839],
            [-72.2743, 42.1439],
            [-72.3889, 42.1524],
            [-72.3953, 42.1848],
            [-72.3955, 42.1858],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509300',
        NAME: 'Palmer School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81786676,
        AWATER: 1019997,
        INTPTLAT: '+42.1877937',
        INTPTLON: '-072.3084690',
        ELSDLEA: '',
        UNSDLEA: '09300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0348, 42.5655],
            [-71.0027, 42.5633],
            [-70.9135, 42.5415],
            [-70.9499, 42.4948],
            [-70.9869, 42.5118],
            [-71.0348, 42.5655],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509360',
        NAME: 'Peabody School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41988100,
        AWATER: 1504340,
        INTPTLAT: '+42.5342793',
        INTPTLON: '-070.9697817',
        ELSDLEA: '',
        UNSDLEA: '09360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.8425, 42.0948],
            [-70.8035, 42.1117],
            [-70.7769, 42.117],
            [-70.7497, 42.097],
            [-70.7697, 42.0221],
            [-70.7993, 42.0136],
            [-70.8073, 42.0129],
            [-70.8334, 42.0196],
            [-70.8425, 42.0948],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509420',
        NAME: 'Pembroke School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56403968,
        AWATER: 4410943,
        INTPTLAT: '+42.0678041',
        INTPTLON: '-070.8057131',
        ELSDLEA: '',
        UNSDLEA: '09420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0536, 42.8331],
            [-71.0049, 42.8633],
            [-70.9403, 42.8225],
            [-70.9174, 42.7875],
            [-70.9597, 42.7538],
            [-71.0316, 42.718],
            [-71.0589, 42.7325],
            [-71.0536, 42.8331],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509450',
        NAME: 'Pentucket School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79754289,
        AWATER: 5657416,
        INTPTLAT: '+42.7955911',
        INTPTLON: '-070.9890946',
        ELSDLEA: '',
        UNSDLEA: '09450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6746, 42.7329],
            [-72.5659, 42.73],
            [-72.4584, 42.7268],
            [-72.4513, 42.7267],
            [-72.3261, 42.7228],
            [-72.283, 42.7215],
            [-72.272, 42.6747],
            [-72.3647, 42.6094],
            [-72.491, 42.604],
            [-72.5406, 42.6557],
            [-72.6206, 42.6469],
            [-72.6746, 42.7329],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509600',
        NAME: 'Pioneer Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292425499,
        AWATER: 3993113,
        INTPTLAT: '+42.6804239',
        INTPTLON: '-072.4566863',
        ELSDLEA: '',
        UNSDLEA: '09600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3117, 42.5046],
            [-73.1822, 42.4854],
            [-73.222, 42.4005],
            [-73.2953, 42.4119],
            [-73.3389, 42.4186],
            [-73.3117, 42.5046],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509630',
        NAME: 'Pittsfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104814385,
        AWATER: 5169948,
        INTPTLAT: '+42.4518397',
        INTPTLON: '-073.2606845',
        ELSDLEA: '',
        UNSDLEA: '09630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.6733, 42.0002],
            [-70.5986, 42.0137],
            [-70.5189, 41.9118],
            [-70.5374, 41.8108],
            [-70.6326, 41.7628],
            [-70.7177, 41.8015],
            [-70.6813, 41.8463],
            [-70.7535, 41.9332],
            [-70.6733, 42.0002],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509720',
        NAME: 'Plymouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249800380,
        AWATER: 97145039,
        INTPTLAT: '+41.8974305',
        INTPTLON: '-070.6246282',
        ELSDLEA: '',
        UNSDLEA: '09720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.1536, 42.0805],
            [-70.1475, 42.0567],
            [-70.1987, 42.0182],
            [-70.2502, 42.0634],
            [-70.1536, 42.0805],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509840',
        NAME: 'Provincetown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25047890,
        AWATER: 20232556,
        INTPTLAT: '+42.0569127',
        INTPTLON: '-070.1904878',
        ELSDLEA: '',
        UNSDLEA: '09840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0428, 42.277],
            [-70.9602, 42.2995],
            [-70.9377, 42.2775],
            [-70.968, 42.238],
            [-71.0495, 42.2068],
            [-71.0827, 42.2043],
            [-71.0428, 42.277],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509870',
        NAME: 'Quincy School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42914168,
        AWATER: 26772720,
        INTPTLAT: '+42.2610059',
        INTPTLON: '-071.0089876',
        ELSDLEA: '',
        UNSDLEA: '09870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1029, 42.2007],
            [-71.0827, 42.2043],
            [-71.0495, 42.2068],
            [-71.0217, 42.169],
            [-71.0351, 42.1356],
            [-71.0565, 42.1538],
            [-71.0666, 42.164],
            [-71.1029, 42.2007],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509930',
        NAME: 'Randolph School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25462931,
        AWATER: 1516030,
        INTPTLAT: '+42.1764458',
        INTPTLON: '-071.0515677',
        ELSDLEA: '',
        UNSDLEA: '09930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1365, 42.5526],
            [-71.071, 42.5559],
            [-71.0753, 42.5311],
            [-71.1007, 42.5023],
            [-71.1198, 42.5003],
            [-71.1355, 42.53],
            [-71.1365, 42.5526],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2509990',
        NAME: 'Reading School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25779078,
        AWATER: 47889,
        INTPTLAT: '+42.5351829',
        INTPTLON: '-071.1054234',
        ELSDLEA: '',
        UNSDLEA: '09990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0283, 42.4241],
            [-71.0258, 42.4448],
            [-70.9686, 42.4444],
            [-70.9508, 42.4443],
            [-70.9595, 42.4075],
            [-70.9861, 42.3881],
            [-71.0134, 42.3974],
            [-71.0332, 42.4142],
            [-71.0283, 42.4241],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510050',
        NAME: 'Revere School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14729746,
        AWATER: 11453555,
        INTPTLAT: '+42.4210055',
        INTPTLON: '-070.9903600',
        ELSDLEA: '',
        UNSDLEA: '10050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9249, 42.1576],
            [-70.8903, 42.1717],
            [-70.8843, 42.1537],
            [-70.8876, 42.088],
            [-70.9158, 42.1001],
            [-70.9408, 42.1513],
            [-70.9249, 42.1576],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510170',
        NAME: 'Rockland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25970986,
        AWATER: 217610,
        INTPTLAT: '+42.1292512',
        INTPTLON: '-070.9087358',
        ELSDLEA: '',
        UNSDLEA: '10170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.6396, 42.695],
            [-70.5679, 42.6185],
            [-70.6431, 42.6346],
            [-70.6396, 42.695],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510200',
        NAME: 'Rockport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18108068,
        AWATER: 27316870,
        INTPTLAT: '+42.6569520',
        INTPTLON: '-070.6046301',
        ELSDLEA: '',
        UNSDLEA: '10200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9499, 42.4948],
            [-70.9135, 42.5415],
            [-70.9084, 42.5456],
            [-70.7967, 42.5398],
            [-70.7436, 42.496],
            [-70.7676, 42.4846],
            [-70.899, 42.4847],
            [-70.934, 42.4908],
            [-70.9499, 42.4948],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510380',
        NAME: 'Salem School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21444686,
        AWATER: 25918980,
        INTPTLAT: '+42.5012804',
        INTPTLON: '-070.8975017',
        ELSDLEA: '',
        UNSDLEA: '10380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.5194, 41.7933],
            [-70.3836, 41.7397],
            [-70.4587, 41.664],
            [-70.5396, 41.6372],
            [-70.568, 41.6456],
            [-70.5194, 41.7933],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510470',
        NAME: 'Sandwich School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110702679,
        AWATER: 3807458,
        INTPTLAT: '+41.7132003',
        INTPTLON: '-070.4857371',
        ELSDLEA: '',
        UNSDLEA: '10470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0361, 42.5052],
            [-71.0116, 42.5017],
            [-70.9686, 42.4444],
            [-71.0258, 42.4448],
            [-71.0259, 42.4447],
            [-71.0535, 42.4759],
            [-71.0361, 42.5052],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510500',
        NAME: 'Saugus School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27969818,
        AWATER: 2592053,
        INTPTLAT: '+42.4684323',
        INTPTLON: '-071.0139456',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.827, 42.2008],
            [-70.751, 42.2931],
            [-70.6696, 42.1294],
            [-70.7524, 42.1639],
            [-70.8388, 42.1948],
            [-70.827, 42.2008],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510560',
        NAME: 'Scituate School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45673759,
        AWATER: 36756226,
        INTPTLAT: '+42.2050615',
        INTPTLON: '-070.7429781',
        ELSDLEA: '',
        UNSDLEA: '10560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3344, 41.8613],
            [-71.3387, 41.8984],
            [-71.3007, 41.9037],
            [-71.2925, 41.7749],
            [-71.3164, 41.7756],
            [-71.3178, 41.7761],
            [-71.3344, 41.8613],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510590',
        NAME: 'Seekonk School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47597843,
        AWATER: 628351,
        INTPTLAT: '+41.8421063',
        INTPTLON: '-071.3223094',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1945, 42.1499],
            [-71.178, 42.164],
            [-71.1492, 42.1386],
            [-71.1386, 42.0728],
            [-71.1687, 42.0612],
            [-71.1785, 42.0574],
            [-71.2388, 42.1064],
            [-71.1945, 42.1499],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510620',
        NAME: 'Sharon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60703371,
        AWATER: 2474008,
        INTPTLAT: '+42.1084634',
        INTPTLON: '-071.1851819',
        ELSDLEA: '',
        UNSDLEA: '10620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7644, 42.3129],
            [-71.6879, 42.3255],
            [-71.6701, 42.2788],
            [-71.6745, 42.2524],
            [-71.7312, 42.2414],
            [-71.7682, 42.3113],
            [-71.7644, 42.3129],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510770',
        NAME: 'Shrewsbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53686516,
        AWATER: 2642169,
        INTPTLAT: '+42.2847672',
        INTPTLON: '-071.7142277',
        ELSDLEA: '',
        UNSDLEA: '10770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1323, 42.4152],
            [-71.076, 42.3964],
            [-71.0735, 42.3918],
            [-71.073, 42.3733],
            [-71.1345, 42.4042],
            [-71.1323, 42.4152],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510890',
        NAME: 'Somerville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10676145,
        AWATER: 254133,
        INTPTLAT: '+42.3904453',
        INTPTLON: '-071.1010331',
        ELSDLEA: '',
        UNSDLEA: '10890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6092, 42.2855],
            [-72.535, 42.3042],
            [-72.5279, 42.3014],
            [-72.5464, 42.2165],
            [-72.5934, 42.2117],
            [-72.6092, 42.2855],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2510920',
        NAME: 'South Hadley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45877606,
        AWATER: 1773570,
        INTPTLAT: '+42.2562080',
        INTPTLON: '-072.5810300',
        ELSDLEA: '',
        UNSDLEA: '10920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0998, 42.0287],
            [-72.0421, 42.1116],
            [-71.9931, 42.0269],
            [-72.0998, 42.0287],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511010',
        NAME: 'Southbridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52525933,
        AWATER: 1614259,
        INTPTLAT: '+42.0597374',
        INTPTLON: '-072.0339083',
        ELSDLEA: '',
        UNSDLEA: '11010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4316, 42.2953],
            [-73.4313, 42.2961],
            [-73.4084, 42.1603],
            [-73.2873, 42.1591],
            [-73.2568, 42.2417],
            [-73.1621, 42.197],
            [-73.1722, 42.0433],
            [-73.2329, 42.0451],
            [-73.3407, 42.0483],
            [-73.4873, 42.0496],
            [-73.5081, 42.0863],
            [-73.4316, 42.2953],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511040',
        NAME: 'Southern Berkshire School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 448468034,
        AWATER: 9190123,
        INTPTLAT: '+42.1443035',
        INTPTLON: '-073.3388605',
        ELSDLEA: '',
        UNSDLEA: '11040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6215, 42.1238],
            [-72.516, 42.1608],
            [-72.4846, 42.1565],
            [-72.4712, 42.0897],
            [-72.5366, 42.0637],
            [-72.5894, 42.0723],
            [-72.5885, 42.0932],
            [-72.6215, 42.1238],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511130',
        NAME: 'Springfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82530128,
        AWATER: 3128140,
        INTPTLAT: '+42.1154540',
        INTPTLON: '-072.5399783',
        ELSDLEA: '',
        UNSDLEA: '11130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1198, 42.5003],
            [-71.1007, 42.5023],
            [-71.078, 42.474],
            [-71.0821, 42.444],
            [-71.0857, 42.4447],
            [-71.1194, 42.4534],
            [-71.1121, 42.4738],
            [-71.1198, 42.5003],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511220',
        NAME: 'Stoneham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15589602,
        AWATER: 1631885,
        INTPTLAT: '+42.4742082',
        INTPTLON: '-071.0976654',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1492, 42.1386],
            [-71.0666, 42.164],
            [-71.0565, 42.1538],
            [-71.0612, 42.1032],
            [-71.0802, 42.0957],
            [-71.1386, 42.0728],
            [-71.1492, 42.1386],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511250',
        NAME: 'Stoughton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41670918,
        AWATER: 969272,
        INTPTLAT: '+42.1188787',
        INTPTLON: '-071.1015702',
        ELSDLEA: '',
        UNSDLEA: '11250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8294, 42.1449],
            [-71.7235, 42.1915],
            [-71.6913, 42.1631],
            [-71.68, 42.1034],
            [-71.6967, 42.0953],
            [-71.7972, 42.0992],
            [-71.8294, 42.1449],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511400',
        NAME: 'Sutton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83935530,
        AWATER: 4199105,
        INTPTLAT: '+42.1320512',
        INTPTLON: '-071.7503180',
        ELSDLEA: '',
        UNSDLEA: '11400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.934, 42.4908],
            [-70.899, 42.4847],
            [-70.8386, 42.4555],
            [-70.9015, 42.4496],
            [-70.934, 42.4908],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511430',
        NAME: 'Swampscott School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7830625,
        AWATER: 9550188,
        INTPTLAT: '+42.4680592',
        INTPTLON: '-070.8912602',
        ELSDLEA: '',
        UNSDLEA: '11430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2925, 41.7749],
            [-71.1532, 41.7896],
            [-71.2083, 41.6905],
            [-71.2084, 41.6906],
            [-71.2844, 41.762],
            [-71.3164, 41.7756],
            [-71.2925, 41.7749],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511460',
        NAME: 'Swansea School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58776206,
        AWATER: 6715775,
        INTPTLAT: '+41.7581626',
        INTPTLON: '-071.2141812',
        ELSDLEA: '',
        UNSDLEA: '11460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1939, 41.9108],
            [-71.1009, 41.9932],
            [-71.0872, 41.9908],
            [-70.9929, 41.9064],
            [-70.9873, 41.9058],
            [-71.0075, 41.8385],
            [-71.1087, 41.8541],
            [-71.1939, 41.9108],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511520',
        NAME: 'Taunton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120958666,
        AWATER: 4430560,
        INTPTLAT: '+41.9033244',
        INTPTLON: '-071.0949874',
        ELSDLEA: '',
        UNSDLEA: '11520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2752, 42.6562],
            [-71.2561, 42.6567],
            [-71.165, 42.598],
            [-71.2111, 42.5733],
            [-71.2963, 42.6127],
            [-71.2752, 42.6562],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511580',
        NAME: 'Tewksbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53617311,
        AWATER: 1100893,
        INTPTLAT: '+42.6120881',
        INTPTLON: '-071.2278451',
        ELSDLEA: '',
        UNSDLEA: '11580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-70.9145, 42.8866],
              [-70.8156, 42.8723],
              [-70.8138, 42.8724],
              [-70.81, 42.8186],
              [-70.9042, 42.8339],
              [-70.9145, 42.8866],
              [-70.9145, 42.8866],
            ],
          ],
          [
            [
              [-70.9764, 42.6944],
              [-70.9597, 42.7538],
              [-70.9174, 42.7875],
              [-70.8086, 42.8115],
              [-70.7858, 42.7401],
              [-70.9486, 42.6807],
              [-70.9764, 42.6944],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511740',
        NAME: 'Triton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147533314,
        AWATER: 19276250,
        INTPTLAT: '+42.7768343',
        INTPTLON: '-070.8748945',
        ELSDLEA: '',
        UNSDLEA: '11740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4577, 42.7005],
            [-71.4323, 42.7],
            [-71.3866, 42.6988],
            [-71.3698, 42.6983],
            [-71.3689, 42.6618],
            [-71.3809, 42.649],
            [-71.4177, 42.6538],
            [-71.4935, 42.6174],
            [-71.4577, 42.7005],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511760',
        NAME: 'Tyngsborough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43447537,
        AWATER: 3393650,
        INTPTLAT: '+42.6677617',
        INTPTLON: '-071.4288204',
        ELSDLEA: '',
        UNSDLEA: '11760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6967, 42.0953],
            [-71.68, 42.1034],
            [-71.5926, 42.1142],
            [-71.592, 42.0637],
            [-71.5911, 42.0137],
            [-71.6063, 42.0134],
            [-71.6809, 42.0115],
            [-71.6967, 42.0953],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511850',
        NAME: 'Uxbridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76630018,
        AWATER: 1754295,
        INTPTLAT: '+42.0611658',
        INTPTLON: '-071.6436275',
        ELSDLEA: '',
        UNSDLEA: '11850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9198, 42.4914],
            [-71.842, 42.5147],
            [-71.7284, 42.4751],
            [-71.7184, 42.4103],
            [-71.726, 42.3903],
            [-71.7419, 42.3868],
            [-71.802, 42.3313],
            [-71.8841, 42.2814],
            [-71.9606, 42.2814],
            [-71.9895, 42.3102],
            [-72.0351, 42.4039],
            [-71.9198, 42.4914],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511880',
        NAME: 'Wachusett School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390896408,
        AWATER: 11934229,
        INTPTLAT: '+42.3967562',
        INTPTLON: '-071.8778271',
        ELSDLEA: '',
        UNSDLEA: '11880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1007, 42.5023],
            [-71.0753, 42.5311],
            [-71.0361, 42.5052],
            [-71.0535, 42.4759],
            [-71.078, 42.474],
            [-71.1007, 42.5023],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511910',
        NAME: 'Wakefield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19052009,
        AWATER: 1553870,
        INTPTLAT: '+42.5043600',
        INTPTLON: '-071.0640609',
        ELSDLEA: '',
        UNSDLEA: '11910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3038, 42.1495],
            [-71.2696, 42.2005],
            [-71.2515, 42.2026],
            [-71.2383, 42.1828],
            [-71.1945, 42.1499],
            [-71.2388, 42.1064],
            [-71.2803, 42.0885],
            [-71.3038, 42.1495],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2511970',
        NAME: 'Walpole School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52922101,
        AWATER: 1609308,
        INTPTLAT: '+42.1462862',
        INTPTLON: '-071.2556036',
        ELSDLEA: '',
        UNSDLEA: '11970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2672, 42.4244],
            [-71.2113, 42.4061],
            [-71.1942, 42.386],
            [-71.2171, 42.3666],
            [-71.2604, 42.3536],
            [-71.2828, 42.3997],
            [-71.2672, 42.4244],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512000',
        NAME: 'Waltham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32969222,
        AWATER: 2650828,
        INTPTLAT: '+42.3889211',
        INTPTLON: '-071.2422678',
        ELSDLEA: '',
        UNSDLEA: '12000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3559, 42.3034],
            [-72.3146, 42.3435],
            [-72.2887, 42.352],
            [-72.2108, 42.2945],
            [-72.2212, 42.2453],
            [-72.3356, 42.2202],
            [-72.3559, 42.3034],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512030',
        NAME: 'Ware School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88986363,
        AWATER: 14492979,
        INTPTLAT: '+42.2754618',
        INTPTLON: '-072.2801578',
        ELSDLEA: '',
        UNSDLEA: '12030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.7788, 41.7612],
            [-70.7718, 41.8089],
            [-70.7703, 41.8258],
            [-70.7177, 41.8015],
            [-70.6326, 41.7628],
            [-70.6766, 41.6911],
            [-70.7788, 41.7612],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512060',
        NAME: 'Wareham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92886436,
        AWATER: 27004883,
        INTPTLAT: '+41.7610398',
        INTPTLON: '-070.7017790',
        ELSDLEA: '',
        UNSDLEA: '12060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2212, 42.2453],
            [-72.2108, 42.2945],
            [-72.1348, 42.2811],
            [-72.1113, 42.2385],
            [-72.1418, 42.1609],
            [-72.2639, 42.1839],
            [-72.2212, 42.2453],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512100',
        NAME: 'Quaboag Regional School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124405364,
        AWATER: 1906980,
        INTPTLAT: '+42.2224240',
        INTPTLON: '-072.1853592',
        ELSDLEA: '',
        UNSDLEA: '12100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2171, 42.3666],
            [-71.1942, 42.386],
            [-71.1545, 42.3751],
            [-71.1435, 42.365],
            [-71.1676, 42.3601],
            [-71.2171, 42.3666],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512180',
        NAME: 'Watertown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10342581,
        AWATER: 318367,
        INTPTLAT: '+42.3694508',
        INTPTLON: '-071.1779249',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.39, 42.3652],
            [-71.3663, 42.4127],
            [-71.3345, 42.3961],
            [-71.3288, 42.3317],
            [-71.3777, 42.3147],
            [-71.3967, 42.3409],
            [-71.39, 42.3652],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512210',
        NAME: 'Wayland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38967003,
        AWATER: 2071082,
        INTPTLAT: '+42.3604268',
        INTPTLON: '-071.3610994',
        ELSDLEA: '',
        UNSDLEA: '12210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8815, 42.0759],
            [-71.83, 42.0927],
            [-71.8047, 42.0235],
            [-71.8833, 42.0243],
            [-71.8815, 42.0759],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512240',
        NAME: 'Webster School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32063121,
        AWATER: 5778755,
        INTPTLAT: '+42.0470748',
        INTPTLON: '-071.8458053',
        ELSDLEA: '',
        UNSDLEA: '12240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3273, 42.3137],
            [-71.2699, 42.3281],
            [-71.2277, 42.3155],
            [-71.2948, 42.2731],
            [-71.3085, 42.2707],
            [-71.3273, 42.3137],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512270',
        NAME: 'Wellesley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25940302,
        AWATER: 1306866,
        INTPTLAT: '+42.3059316',
        INTPTLON: '-071.2829170',
        ELSDLEA: '',
        UNSDLEA: '12270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7419, 42.3868],
            [-71.7644, 42.3129],
            [-71.7682, 42.3113],
            [-71.802, 42.3313],
            [-71.7419, 42.3868],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512390',
        NAME: 'West Boylston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33530030,
        AWATER: 2357873,
        INTPTLAT: '+42.3694173',
        INTPTLON: '-071.7850362',
        ELSDLEA: '',
        UNSDLEA: '12390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0695, 42.0497],
            [-70.9872, 42.0466],
            [-70.9796, 42.011],
            [-71.0547, 41.9851],
            [-71.0695, 42.0497],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512420',
        NAME: 'West Bridgewater School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39681854,
        AWATER: 913621,
        INTPTLAT: '+42.0216172',
        INTPTLON: '-071.0267170',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6848, 42.163],
            [-72.6273, 42.162],
            [-72.6215, 42.1238],
            [-72.5885, 42.0932],
            [-72.6949, 42.1059],
            [-72.6848, 42.163],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512510',
        NAME: 'West Springfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43287177,
        AWATER: 2093444,
        INTPTLAT: '+42.1254511',
        INTPTLON: '-072.6497341',
        ELSDLEA: '',
        UNSDLEA: '12510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6701, 42.2788],
            [-71.5801, 42.3059],
            [-71.5495, 42.2661],
            [-71.5993, 42.226],
            [-71.6444, 42.2266],
            [-71.6745, 42.2524],
            [-71.6701, 42.2788],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512600',
        NAME: 'Westborough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53294172,
        AWATER: 2228848,
        INTPTLAT: '+42.2684262',
        INTPTLON: '-071.6133087',
        ELSDLEA: '',
        UNSDLEA: '12600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.781, 42.1997],
            [-72.6869, 42.1834],
            [-72.6848, 42.163],
            [-72.6949, 42.1059],
            [-72.706, 42.0808],
            [-72.8539, 42.115],
            [-72.781, 42.1997],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512630',
        NAME: 'Westfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119807790,
        AWATER: 2753955,
        INTPTLAT: '+42.1388377',
        INTPTLON: '-072.7559114',
        ELSDLEA: '',
        UNSDLEA: '12630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4935, 42.6174],
            [-71.4177, 42.6538],
            [-71.3884, 42.5499],
            [-71.3996, 42.534],
            [-71.4285, 42.5288],
            [-71.498, 42.5769],
            [-71.4935, 42.6174],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512660',
        NAME: 'Westford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78352217,
        AWATER: 2893417,
        INTPTLAT: '+42.5855413',
        INTPTLON: '-071.4402500',
        ELSDLEA: '',
        UNSDLEA: '12660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3345, 42.3961],
            [-71.2828, 42.3997],
            [-71.2604, 42.3536],
            [-71.2699, 42.3281],
            [-71.3273, 42.3137],
            [-71.3288, 42.3317],
            [-71.3345, 42.3961],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512750',
        NAME: 'Weston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43575387,
        AWATER: 1316348,
        INTPTLAT: '+42.3605016',
        INTPTLON: '-071.3034335',
        ELSDLEA: '',
        UNSDLEA: '12750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0767, 41.6875],
            [-71.0041, 41.484],
            [-71.1164, 41.4846],
            [-71.1279, 41.5628],
            [-71.1385, 41.6036],
            [-71.0767, 41.6875],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512780',
        NAME: 'Westport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129103091,
        AWATER: 38252542,
        INTPTLAT: '+41.5797936',
        INTPTLON: '-071.0818218',
        ELSDLEA: '',
        UNSDLEA: '12780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2515, 42.2026],
            [-71.2324, 42.2585],
            [-71.2317, 42.2593],
            [-71.146, 42.2092],
            [-71.155, 42.197],
            [-71.2383, 42.1828],
            [-71.2515, 42.2026],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512810',
        NAME: 'Westwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28185145,
        AWATER: 738121,
        INTPTLAT: '+42.2196452',
        INTPTLON: '-071.2167679',
        ELSDLEA: '',
        UNSDLEA: '12810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9766, 42.1683],
            [-70.968, 42.238],
            [-70.9377, 42.2775],
            [-70.9141, 42.2888],
            [-70.9249, 42.1576],
            [-70.9408, 42.1513],
            [-70.9813, 42.1355],
            [-70.9766, 42.1683],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512840',
        NAME: 'Weymouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43491596,
        AWATER: 12460725,
        INTPTLAT: '+42.2066757',
        INTPTLON: '-070.9458452',
        ELSDLEA: '',
        UNSDLEA: '12840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9755, 42.0874],
            [-70.9158, 42.1001],
            [-70.8876, 42.088],
            [-70.8425, 42.0948],
            [-70.8334, 42.0196],
            [-70.8969, 42.0191],
            [-70.9752, 42.0712],
            [-70.9755, 42.0874],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2512930',
        NAME: 'Whitman-Hanson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56964260,
        AWATER: 1861106,
        INTPTLAT: '+42.0660005',
        INTPTLON: '-070.8940703',
        ELSDLEA: '',
        UNSDLEA: '12930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2111, 42.5733],
            [-71.165, 42.598],
            [-71.1354, 42.5992],
            [-71.1365, 42.5526],
            [-71.1355, 42.53],
            [-71.1701, 42.5156],
            [-71.205, 42.5445],
            [-71.2111, 42.5733],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2513050',
        NAME: 'Wilmington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43971812,
        AWATER: 464439,
        INTPTLAT: '+42.5646465',
        INTPTLON: '-071.1645158',
        ELSDLEA: '',
        UNSDLEA: '13050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1111, 42.7172],
            [-72.0784, 42.7162],
            [-71.9816, 42.7133],
            [-71.986, 42.63],
            [-72.0461, 42.6018],
            [-72.1166, 42.6266],
            [-72.1111, 42.7172],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2513080',
        NAME: 'Winchendon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111434197,
        AWATER: 2740860,
        INTPTLAT: '+42.6671888',
        INTPTLON: '-072.0460092',
        ELSDLEA: '',
        UNSDLEA: '13080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.1896, 42.4498],
            [-71.1121, 42.4738],
            [-71.1194, 42.4534],
            [-71.1498, 42.4358],
            [-71.1823, 42.4363],
            [-71.1896, 42.4498],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2513110',
        NAME: 'Winchester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15619468,
        AWATER: 812592,
        INTPTLAT: '+42.4527518',
        INTPTLON: '-071.1443188',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9861, 42.3881],
            [-70.9595, 42.4075],
            [-70.9179, 42.386],
            [-70.9861, 42.3881],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2513170',
        NAME: 'Winthrop School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5154886,
        AWATER: 16390637,
        INTPTLAT: '+42.3755413',
        INTPTLON: '-070.9706464',
        ELSDLEA: '',
        UNSDLEA: '13170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.2043, 42.4659],
            [-71.1701, 42.5156],
            [-71.1355, 42.53],
            [-71.1198, 42.5003],
            [-71.1121, 42.4738],
            [-71.1896, 42.4498],
            [-71.2043, 42.4659],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2513200',
        NAME: 'Woburn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32729714,
        AWATER: 802750,
        INTPTLAT: '+42.4887687',
        INTPTLON: '-071.1544382',
        ELSDLEA: '',
        UNSDLEA: '13200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8841, 42.2814],
            [-71.802, 42.3313],
            [-71.7682, 42.3113],
            [-71.7312, 42.2414],
            [-71.7335, 42.2381],
            [-71.797, 42.21],
            [-71.8566, 42.2267],
            [-71.8841, 42.2814],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2513230',
        NAME: 'Worcester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96790119,
        AWATER: 2810194,
        INTPTLAT: '+42.2694781',
        INTPTLON: '-071.8077831',
        ELSDLEA: '',
        UNSDLEA: '13230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-70.4933, 41.28],
              [-70.3282, 41.3802],
              [-70.2343, 41.3832],
              [-70.1487, 41.3452],
              [-70.0944, 41.4308],
              [-70.0065, 41.4339],
              [-69.9101, 41.3173],
              [-69.9103, 41.2268],
              [-69.9732, 41.1935],
              [-70.1097, 41.1898],
              [-70.3441, 41.2761],
              [-70.4933, 41.28],
            ],
            [
              [-70.3294, 41.3394],
              [-70.257, 41.2728],
              [-70.0123, 41.2284],
              [-69.9471, 41.2828],
              [-69.9984, 41.363],
              [-70.0976, 41.2997],
              [-70.3294, 41.3394],
            ],
          ],
          [
            [
              [-70.6695, 41.5133],
              [-70.528, 41.5475],
              [-70.4501, 41.5877],
              [-70.2754, 41.6251],
              [-70.1177, 41.6556],
              [-70.0942, 41.6082],
              [-70.2674, 41.5584],
              [-70.3952, 41.5567],
              [-70.5028, 41.5006],
              [-70.5598, 41.5093],
              [-70.65, 41.488],
              [-70.6695, 41.5133],
            ],
          ],
          [
            [
              [-70.9015, 42.4496],
              [-70.8386, 42.4555],
              [-70.7676, 42.4846],
              [-70.7436, 42.496],
              [-70.7336, 42.504],
              [-70.713, 42.5707],
              [-70.5679, 42.6185],
              [-70.6396, 42.695],
              [-70.709, 42.6909],
              [-70.7858, 42.7401],
              [-70.8086, 42.8115],
              [-70.81, 42.8186],
              [-70.8138, 42.8724],
              [-70.735, 42.8747],
              [-70.7026, 42.7242],
              [-70.5493, 42.7242],
              [-70.5021, 42.6246],
              [-70.6063, 42.5312],
              [-70.6904, 42.279],
              [-70.6084, 42.1385],
              [-70.2774, 42.1143],
              [-70.1725, 42.1345],
              [-70.0116, 42.0734],
              [-69.9291, 41.9785],
              [-69.8665, 41.817],
              [-69.8629, 41.6732],
              [-69.9466, 41.5122],
              [-70.0403, 41.466],
              [-70.1268, 41.5309],
              [-70.0683, 41.6065],
              [-69.9523, 41.6405],
              [-69.9284, 41.7225],
              [-69.9311, 41.8096],
              [-69.9521, 41.8806],
              [-69.9967, 41.9668],
              [-70.06, 42.0467],
              [-70.1536, 42.0805],
              [-70.2502, 42.0634],
              [-70.1987, 42.0182],
              [-70.1475, 42.0567],
              [-70.0853, 41.9564],
              [-70.041, 41.8758],
              [-70.0074, 41.7998],
              [-70.0231, 41.7882],
              [-70.1221, 41.7588],
              [-70.2611, 41.714],
              [-70.3836, 41.7397],
              [-70.5194, 41.7933],
              [-70.5374, 41.8108],
              [-70.5189, 41.9118],
              [-70.5986, 42.0137],
              [-70.6298, 42.0772],
              [-70.6696, 42.1294],
              [-70.751, 42.2931],
              [-70.8003, 42.2931],
              [-70.8512, 42.3725],
              [-70.8514, 42.3932],
              [-70.906, 42.4383],
              [-70.9015, 42.4496],
            ],
          ],
          [
            [
              [-70.9117, 41.5818],
              [-70.906, 41.6238],
              [-70.8423, 41.6245],
              [-70.7508, 41.6696],
              [-70.691, 41.6605],
              [-70.6596, 41.6592],
              [-70.6472, 41.5785],
              [-70.6975, 41.5272],
              [-70.7585, 41.5609],
              [-70.8643, 41.5074],
              [-70.9117, 41.5818],
            ],
          ],
          [
            [
              [-71.1164, 41.4846],
              [-71.0041, 41.484],
              [-70.9745, 41.4541],
              [-71.0519, 41.4167],
              [-71.0886, 41.4313],
              [-71.1164, 41.4846],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '25',
        SCSDLEA: '',
        GEOID: '2599997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 0,
        AWATER: 4005479391,
        INTPTLAT: '+41.8843837',
        INTPTLON: '-070.3594513',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
  ],
};

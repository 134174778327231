/* eslint-disable consistent-return */
import axios from 'axios';
import { API_ROOT } from '../config/appConfig';

export const getVisualization = async (id, jwt) => {
  try {
    const res = await axios.get(`${API_ROOT}/admin-backend/visualization/${id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

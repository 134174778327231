const breadcrumbs = {
  '/admin': [],
  '/admin/dashboard': [
    {
      id: 1,
      link: '/',
      name: 'Admin',
    },
    {
      id: 2,
      link: '/admin/dashboard',
      name: 'Manage Dashboard',
    },
  ],
  '/admin/survey': [
    {
      id: 3,
      link: '/',
      name: 'Admin',
    },
    {
      id: 4,
      link: '/admin/survey',
      name: 'Manage Survey Explorer',
    },
  ],
  '/admin/survey/new': [
    {
      id: 5,
      link: '/',
      name: 'Admin',
    },
    {
      id: 6,
      link: '/admin/survey',
      name: 'Manage Survey Explorer',
    },
    {
      id: 7,
      link: '/admin/survey/new',
      name: 'Create New Survey',
    },
  ],
  '/admin/survey/replace': [
    {
      id: 8,
      link: '/',
      name: 'Admin',
    },
    {
      id: 9,
      link: '/admin/survey',
      name: 'Manage Survey Explorer',
    },
    {
      id: 10,
      link: '/admin/survey/replace',
      name: 'Replace Data Set',
    },
  ],
  '/admin/dashboard/manager': [
    {
      id: 11,
      link: '/',
      name: 'Admin',
    },
    {
      id: 12,
      link: '/admin/dashboard',
      name: 'Manage Dashboard',
    },
    {
      id: 13,
      link: '/admin/dashboard/manager',
      name: 'Data Source Manager',
    },
  ],
  '/admin/dashboard/manager/add': [
    {
      id: 14,
      link: '/',
      name: 'Admin',
    },
    {
      id: 15,
      link: '/admin/dashboard',
      name: 'Manage Dashboard',
    },
    {
      id: 16,
      link: '/admin/dashboard/manager',
      name: 'Data Source Manager',
    },
    {
      id: 17,
      link: '/admin/dashboard/manager/add',
      name: 'Add New Data',
    },
  ],
  '/admin/dashboard/update': [
    {
      id: 18,
      link: '/',
      name: 'Admin',
    },
    {
      id: 19,
      link: '/admin/dashboard',
      name: 'Manage Dashboard',
    },
    {
      id: 20,
      link: '/admin/dashboard/update',
      name: 'Update Chart',
    },
  ],
  '/admin/manage-users': [
    {
      id: 1,
      link: '/',
      name: 'Admin',
    },
    {
      id: 2,
      link: '/admin/manage-users',
      name: 'Manage Users',
    },
  ],
  '/admin/manage-organizations': [
    {
      id: 1,
      link: '/',
      name: 'Admin',
    },
    {
      id: 2,
      link: '/admin/manage-organizations',
      name: 'Manage Organizations',
    },
  ],
};

export default breadcrumbs;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  copyVisualization,
  selectedVisualization,
} from '../../actions/visualization.action';
import Types from '../../types';
import modalMessages from '../../messages/modal.messages';
import { openModal } from '../../actions/modal.action';
import Spinner from '../Spinner';


const VisualizationActions = ({ id, isCopy, spinner }) => {
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.session.jwt);
  const existDashboardData = useSelector((state) => state.visualizations.existDashboardData);

  const copyVisualizationHandler = () => {
    dispatch(copyVisualization(id, jwt));
  };

  const convertToMainVisualizationHandler = () => {
    dispatch(selectedVisualization(id));
    dispatch(openModal(modalMessages('visualizationCopyIntoMain')));
  };

  return (
    <>
      <div className="Card-actions">
        <ul className="Card-actions-list">
          {
              !isCopy && (
                <li>
                  <a className="Card-link disabled" href="#tbd">
                    <span className="Card-action-icon">
                      <img src="/view-icon.svg" alt="View Survey" />
                    </span>
                    <span className="Card-action-name">View</span>
                  </a>
                </li>
              )
            }
          {
              !isCopy && (
                <li>
                  <button
                    className="Card-link"
                    type="button"
                    onClick={copyVisualizationHandler}
                    disabled={(spinner === Types.spinnerTypes.copyVisualization)}
                  >
                    <span className="Card-action-icon">
                      { (spinner === Types.spinnerTypes.copyVisualization) && <Spinner />}
                      { !(spinner === Types.spinnerTypes.copyVisualization)
                      && <img src="/copy-icon.svg" alt="Create a copy Visualization" />}
                    </span>
                    <span className="Card-action-name">Create a copy</span>
                  </button>
                </li>
              )
            }
          {
              isCopy && (
                <>
                  <li>
                    <span className="Card-link disabled" href="#tbd">
                      <span className="Card-action-icon">
                        <img src="/edit-icon.svg" alt="Edit Icon" />
                      </span>
                      <span className="Card-action-name">Edit</span>
                    </span>
                  </li>
                  <li>
                    <a className="Card-link disabled" href="#tbd">
                      <span className="Card-action-icon">
                        <img src="/view-icon.svg" alt="Preview Survey" />
                      </span>
                      <span className="Card-action-name">Preview</span>
                    </a>
                  </li>
                  <li>
                    <span className="Card-link disabled" href="#tbd">
                      <span className="Card-action-icon">
                        <img src="/delete-icon.svg" alt="Delete Survey" />
                      </span>
                      <span className="Card-action-name">Delete</span>
                    </span>
                  </li>
                  <li>
                    <button type="button" className={`Card-link ${existDashboardData ? '' : 'disabled'}`}>
                      <span className="Card-action-icon">
                        <img src="/update-icon.svg" alt="Update Chart" />
                      </span>
                      {
                        existDashboardData
                        && (
                        <Link to={`/admin/dashboard/update/${id}`}>
                          <span className="Card-action-name">Update Chart</span>
                        </Link>
                        )
                      }
                      {!existDashboardData
                          && <span className="Card-action-name">Update Chart</span>}
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="Card-link"
                      onClick={convertToMainVisualizationHandler}
                      disabled={(spinner === Types.spinnerTypes.convertCopyIntoVisualization)}

                    >
                      <span className="Card-action-icon">
                        { (spinner === Types.spinnerTypes.convertCopyIntoVisualization) && <Spinner />}
                        { !(spinner === Types.spinnerTypes.convertCopyIntoVisualization)
                        && <img src="/publish-icon.svg" alt="Convert to visualization" />}
                      </span>
                      <span className="Card-action-name">Convert to visualization</span>
                    </button>
                  </li>
                </>
              )
            }
        </ul>
      </div>
    </>
  );
};

VisualizationActions.propTypes = {
  isCopy: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};

export default VisualizationActions;

export const OH = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9705, 41.3867],
            [-81.8811, 41.4045],
            [-81.877, 41.3507],
            [-81.9713, 41.3491],
            [-81.9705, 41.3867],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904657',
        NAME: 'Olmsted Falls City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41456049,
        AWATER: 21953,
        INTPTLAT: '+41.3701677',
        INTPTLON: '-081.9324677',
        ELSDLEA: '',
        UNSDLEA: '04657',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1629, 41.4182],
            [-82.1041, 41.418],
            [-82.0678, 41.4183],
            [-82.069, 41.3517],
            [-82.1155, 41.3443],
            [-82.1658, 41.3523],
            [-82.1767, 41.3544],
            [-82.1629, 41.4182],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904394',
        NAME: 'Elyria City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66706156,
        AWATER: 770023,
        INTPTLAT: '+41.3832316',
        INTPTLON: '-082.1195718',
        ELSDLEA: '',
        UNSDLEA: '04394',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1803, 41.2375],
            [-82.1658, 41.3523],
            [-82.1155, 41.3443],
            [-82.0725, 41.1998],
            [-82.0743, 41.1365],
            [-82.1699, 41.1371],
            [-82.1803, 41.2375],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904816',
        NAME: 'Keystone Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161321939,
        AWATER: 1314929,
        INTPTLAT: '+41.2296649',
        INTPTLON: '-082.1234057',
        ELSDLEA: '',
        UNSDLEA: '04816',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5699, 40.565],
            [-81.5016, 40.5831],
            [-81.4304, 40.5304],
            [-81.5422, 40.499],
            [-81.5699, 40.565],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904389',
        NAME: 'Dover City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90932439,
        AWATER: 969526,
        INTPTLAT: '+40.5405215',
        INTPTLON: '-081.4918646',
        ELSDLEA: '',
        UNSDLEA: '04389',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.577, 40.6233],
            [-81.5106, 40.6504],
            [-81.5016, 40.5831],
            [-81.5699, 40.565],
            [-81.577, 40.6233],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905029',
        NAME: 'Strasburg-Franklin Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56059084,
        AWATER: 589246,
        INTPTLAT: '+40.5990662',
        INTPTLON: '-081.5438886',
        ELSDLEA: '',
        UNSDLEA: '05029',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4105, 40.905],
            [-83.3045, 40.9202],
            [-83.1127, 40.8919],
            [-83.1766, 40.8479],
            [-83.1883, 40.7698],
            [-83.1688, 40.7023],
            [-83.3047, 40.702],
            [-83.3758, 40.6507],
            [-83.4198, 40.6453],
            [-83.4778, 40.7301],
            [-83.4148, 40.7641],
            [-83.4105, 40.905],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910024',
        NAME: 'Upper Sandusky Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550137428,
        AWATER: 1516703,
        INTPTLAT: '+40.7961306',
        INTPTLON: '-083.2971084',
        ELSDLEA: '',
        UNSDLEA: '10024',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9499, 40.8195],
            [-82.9688, 40.7758],
            [-82.998, 40.8198],
            [-82.9499, 40.8195],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904368',
        NAME: 'Bucyrus City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14943943,
        AWATER: 1247,
        INTPTLAT: '+40.8043194',
        INTPTLON: '-082.9730556',
        ELSDLEA: '',
        UNSDLEA: '04368',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2399, 41.0652],
            [-83.1898, 41.0803],
            [-83.0736, 41.0808],
            [-83.0742, 40.9936],
            [-83.1127, 40.8919],
            [-83.3045, 40.9202],
            [-83.3212, 41.0061],
            [-83.2399, 41.0652],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905074',
        NAME: 'Mohawk Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 328324058,
        AWATER: 1396844,
        INTPTLAT: '+40.9900454',
        INTPTLON: '-083.1804410',
        ELSDLEA: '',
        UNSDLEA: '05074',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4887, 41.624],
            [-81.4582, 41.624],
            [-81.4446, 41.5968],
            [-81.488, 41.5882],
            [-81.4887, 41.624],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904508',
        NAME: 'Wickliffe City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12021458,
        AWATER: 55614,
        INTPTLAT: '+41.6063221',
        INTPTLON: '-081.4680280',
        ELSDLEA: '',
        UNSDLEA: '04508',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7652, 40.7865],
            [-81.6583, 40.8439],
            [-81.649, 40.7369],
            [-81.6492, 40.7301],
            [-81.7655, 40.7265],
            [-81.7652, 40.7865],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905054',
        NAME: 'Dalton Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111823321,
        AWATER: 221096,
        INTPTLAT: '+40.7841857',
        INTPTLON: '-081.7003553',
        ELSDLEA: '',
        UNSDLEA: '05054',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9075, 40.9012],
            [-81.7633, 40.9241],
            [-81.6669, 40.9023],
            [-81.6561, 40.8739],
            [-81.7632, 40.8846],
            [-81.8132, 40.8148],
            [-81.8773, 40.8138],
            [-81.9075, 40.9012],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905055',
        NAME: 'Green Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 135630484,
        AWATER: 312421,
        INTPTLAT: '+40.8677606',
        INTPTLON: '-081.7954591',
        ELSDLEA: '',
        UNSDLEA: '05055',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3217, 40.4781],
            [-81.2684, 40.4767],
            [-81.2737, 40.3455],
            [-81.3354, 40.2882],
            [-81.4098, 40.3039],
            [-81.3749, 40.4276],
            [-81.3217, 40.4781],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904377',
        NAME: 'Claymont City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186036905,
        AWATER: 118111,
        INTPTLAT: '+40.3647715',
        INTPTLON: '-081.3250713',
        ELSDLEA: '',
        UNSDLEA: '04377',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2664, 41.7358],
            [-81.2629, 41.7452],
            [-81.2354, 41.7441],
            [-81.2314, 41.7093],
            [-81.2611, 41.7041],
            [-81.2664, 41.7358],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910015',
        NAME: 'Painesville City Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12837518,
        AWATER: 338852,
        INTPTLAT: '+41.7242614',
        INTPTLON: '-081.2481223',
        ELSDLEA: '',
        UNSDLEA: '10015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7117, 41.2719],
            [-80.7112, 41.203],
            [-80.7886, 41.2032],
            [-80.7939, 41.2127],
            [-80.8108, 41.2577],
            [-80.7117, 41.2719],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905016',
        NAME: 'Howland Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48320227,
        AWATER: 0,
        INTPTLAT: '+41.2394332',
        INTPTLON: '-080.7508411',
        ELSDLEA: '',
        UNSDLEA: '05016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6156, 40.9879],
            [-80.519, 40.9874],
            [-80.5191, 40.9003],
            [-80.6184, 40.8999],
            [-80.6318, 40.9277],
            [-80.6318, 40.981],
            [-80.6156, 40.9879],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904837',
        NAME: 'Springfield Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 86404996,
        AWATER: 3853856,
        INTPTLAT: '+40.9470768',
        INTPTLON: '-080.5745012',
        ELSDLEA: '',
        UNSDLEA: '04837',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4886, 41.6315],
            [-81.3886, 41.7073],
            [-81.374, 41.6402],
            [-81.391, 41.5697],
            [-81.4878, 41.5701],
            [-81.4879, 41.5862],
            [-81.488, 41.5882],
            [-81.4446, 41.5968],
            [-81.4582, 41.624],
            [-81.4887, 41.624],
            [-81.4886, 41.6315],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904510',
        NAME: 'Willoughby-Eastlake City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79609384,
        AWATER: 927548,
        INTPTLAT: '+41.6186294',
        INTPTLON: '-081.4352612',
        ELSDLEA: '',
        UNSDLEA: '04510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1874, 40.1719],
            [-80.9776, 40.1466],
            [-81.0032, 40.061],
            [-80.8953, 39.9842],
            [-80.8975, 39.9513],
            [-80.8984, 39.9399],
            [-81.0511, 39.912],
            [-81.1166, 39.9576],
            [-81.1164, 40.081],
            [-81.1874, 40.1719],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904601',
        NAME: 'Union Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 375968928,
        AWATER: 8456824,
        INTPTLAT: '+40.0402377',
        INTPTLON: '-081.0409395',
        ELSDLEA: '',
        UNSDLEA: '04601',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2341, 39.9513],
            [-81.2259, 40.1701],
            [-81.1874, 40.1719],
            [-81.1164, 40.081],
            [-81.1166, 39.9576],
            [-81.0511, 39.912],
            [-81.1059, 39.8633],
            [-81.2223, 39.8675],
            [-81.2341, 39.9513],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904520',
        NAME: 'Barnesville Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315783118,
        AWATER: 8597655,
        INTPTLAT: '+39.9954112',
        INTPTLON: '-081.1716597',
        ELSDLEA: '',
        UNSDLEA: '04520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8984, 39.9399],
            [-80.8975, 39.9513],
            [-80.7395, 39.9784],
            [-80.8079, 39.9145],
            [-80.8984, 39.9399],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904600',
        NAME: 'Shadyside Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56502247,
        AWATER: 338188,
        INTPTLAT: '+39.9436541',
        INTPTLON: '-080.7990907',
        ELSDLEA: '',
        UNSDLEA: '04600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8624, 40.1588],
            [-80.708, 40.1433],
            [-80.7354, 40.0821],
            [-80.8598, 40.1165],
            [-80.8624, 40.1588],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904434',
        NAME: 'Martins Ferry City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67236461,
        AWATER: 238728,
        INTPTLAT: '+40.1177649',
        INTPTLON: '-080.7847646',
        ELSDLEA: '',
        UNSDLEA: '04434',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8953, 39.9842],
            [-80.8308, 40.0676],
            [-80.7325, 40.0559],
            [-80.7338, 40.0334],
            [-80.7395, 39.9784],
            [-80.8975, 39.9513],
            [-80.8953, 39.9842],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904357',
        NAME: 'Bellaire Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113861644,
        AWATER: 484240,
        INTPTLAT: '+39.9968675',
        INTPTLON: '-080.8184570',
        ELSDLEA: '',
        UNSDLEA: '04357',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5594, 41.062],
            [-80.519, 41.0829],
            [-80.519, 41.0615],
            [-80.5191, 41.0328],
            [-80.5661, 41.0386],
            [-80.5594, 41.062],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904833',
        NAME: 'Lowellville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14716166,
        AWATER: 282115,
        INTPTLAT: '+41.0498844',
        INTPTLON: '-080.5382210',
        ELSDLEA: '',
        UNSDLEA: '04833',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2044, 40.6426],
            [-81.1193, 40.6477],
            [-81.0434, 40.7274],
            [-80.9748, 40.727],
            [-80.9172, 40.7269],
            [-80.9181, 40.6435],
            [-80.8621, 40.5956],
            [-80.9209, 40.5563],
            [-80.9419, 40.4247],
            [-81.1398, 40.4318],
            [-81.1543, 40.5038],
            [-81.2664, 40.5297],
            [-81.2686, 40.5652],
            [-81.2044, 40.6426],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904527',
        NAME: 'Carrollton Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 740856361,
        AWATER: 6107772,
        INTPTLAT: '+40.5669647',
        INTPTLON: '-081.0467763',
        ELSDLEA: '',
        UNSDLEA: '04527',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5613, 39.9973],
            [-82.4802, 40.0261],
            [-82.3693, 40.0301],
            [-82.292, 39.9891],
            [-82.346, 39.9324],
            [-82.4692, 39.9272],
            [-82.5585, 39.9291],
            [-82.5613, 39.9973],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904799',
        NAME: 'Lakewood Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215850506,
        AWATER: 3927100,
        INTPTLAT: '+39.9798790',
        INTPTLON: '-082.4296594',
        ELSDLEA: '',
        UNSDLEA: '04799',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3004, 41.5195],
            [-83.2994, 41.6218],
            [-83.165, 41.6232],
            [-83.0489, 41.5748],
            [-83.0675, 41.4572],
            [-83.1926, 41.4584],
            [-83.3004, 41.5195],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904892',
        NAME: 'Benton-Carroll-Salem Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 285161761,
        AWATER: 10907132,
        INTPTLAT: '+41.5514784',
        INTPTLON: '-083.1743801',
        ELSDLEA: '',
        UNSDLEA: '04892',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4159, 41.6189],
            [-83.2994, 41.6218],
            [-83.3004, 41.5195],
            [-83.4146, 41.5002],
            [-83.4146, 41.5076],
            [-83.4157, 41.6019],
            [-83.4159, 41.6189],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904894',
        NAME: 'Genoa Area Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123546281,
        AWATER: 60439,
        INTPTLAT: '+41.5607949',
        INTPTLON: '-083.3521683',
        ELSDLEA: '',
        UNSDLEA: '04894',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4161, 41.3992],
            [-83.4146, 41.5002],
            [-83.3004, 41.5195],
            [-83.1926, 41.4584],
            [-83.2498, 41.4291],
            [-83.4161, 41.3992],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904957',
        NAME: 'Woodmore Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179744660,
        AWATER: 662755,
        INTPTLAT: '+41.4694661',
        INTPTLON: '-083.3265635',
        ELSDLEA: '',
        UNSDLEA: '04957',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0866, 40.9471],
            [-81.0867, 40.8863],
            [-81.1348, 40.9019],
            [-81.0866, 40.9471],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904349',
        NAME: 'Alliance City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31031332,
        AWATER: 305673,
        INTPTLAT: '+40.9113021',
        INTPTLON: '-081.0955223',
        ELSDLEA: '',
        UNSDLEA: '04349',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6318, 40.9277],
            [-80.6184, 40.8999],
            [-80.632, 40.8706],
            [-80.7293, 40.9004],
            [-80.6318, 40.9277],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904532',
        NAME: 'Columbiana Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41340443,
        AWATER: 969852,
        INTPTLAT: '+40.8961258',
        INTPTLON: '-080.6644473',
        ELSDLEA: '',
        UNSDLEA: '04532',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0988, 40.9955],
            [-81.0017, 40.9878],
            [-80.8573, 40.9879],
            [-80.8564, 40.93],
            [-80.8968, 40.8715],
            [-81.0178, 40.8138],
            [-81.0868, 40.8139],
            [-81.0867, 40.8863],
            [-81.0866, 40.9471],
            [-81.0988, 40.9881],
            [-81.0988, 40.9955],
          ],
          [
            [-81.0366, 40.9143],
            [-81.0128, 40.9076],
            [-81.0071, 40.9304],
            [-81.0232, 40.9302],
            [-81.0366, 40.9143],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904838',
        NAME: 'West Branch Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 285305200,
        AWATER: 3534433,
        INTPTLAT: '+40.9123111',
        INTPTLON: '-080.9898123',
        ELSDLEA: '',
        UNSDLEA: '04838',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4894, 41.1935],
            [-81.3919, 41.2029],
            [-81.3823, 41.2028],
            [-81.3921, 41.1357],
            [-81.453, 41.1244],
            [-81.4897, 41.1604],
            [-81.4894, 41.1935],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904483',
        NAME: 'Stow-Munroe Falls City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55426938,
        AWATER: 820554,
        INTPTLAT: '+41.1740864',
        INTPTLON: '-081.4323920',
        ELSDLEA: '',
        UNSDLEA: '04483',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3932, 41.0376],
            [-81.3929, 41.0662],
            [-81.393, 41.0663],
            [-81.3922, 41.1355],
            [-81.3003, 41.1341],
            [-81.3012, 41.0649],
            [-81.3027, 40.9885],
            [-81.3817, 40.9886],
            [-81.3932, 41.0376],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904919',
        NAME: 'Field Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110653840,
        AWATER: 7357831,
        INTPTLAT: '+41.0566968',
        INTPTLON: '-081.3388601',
        ELSDLEA: '',
        UNSDLEA: '04919',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1933, 41.1341],
            [-81.1927, 41.1774],
            [-81.0983, 41.1625],
            [-81.0026, 41.1927],
            [-81.0023, 41.1342],
            [-81.0024, 41.0621],
            [-81.0017, 40.9878],
            [-81.0988, 40.9955],
            [-81.1943, 41.0633],
            [-81.1933, 41.1341],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904922',
        NAME: 'Southeast Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 238885358,
        AWATER: 16968036,
        INTPTLAT: '+41.0935661',
        INTPTLON: '-081.0845457',
        ELSDLEA: '',
        UNSDLEA: '04922',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2275, 41.2532],
            [-84.1065, 41.3048],
            [-84.1126, 41.1665],
            [-84.1234, 41.1665],
            [-84.1278, 41.1664],
            [-84.1616, 41.1662],
            [-84.2282, 41.1659],
            [-84.2275, 41.2532],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904757',
        NAME: 'Holgate Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140288736,
        AWATER: 41927,
        INTPTLAT: '+41.2229177',
        INTPTLON: '-084.1586778',
        ELSDLEA: '',
        UNSDLEA: '04757',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1065, 41.3048],
            [-83.9947, 41.3712],
            [-83.8835, 41.3426],
            [-83.8834, 41.3353],
            [-83.8623, 41.2552],
            [-83.8811, 41.1531],
            [-84.1126, 41.1665],
            [-84.1065, 41.3048],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904759',
        NAME: 'Patrick Henry Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 377421610,
        AWATER: 107411,
        INTPTLAT: '+41.2615783',
        INTPTLON: '-083.9886894',
        ELSDLEA: '',
        UNSDLEA: '04759',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7327, 39.4814],
            [-81.6969, 39.5867],
            [-81.5693, 39.5269],
            [-81.5756, 39.4411],
            [-81.7327, 39.4814],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905051',
        NAME: 'Wolf Creek Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189029473,
        AWATER: 3442256,
        INTPTLAT: '+39.4927986',
        INTPTLON: '-081.6589089',
        ELSDLEA: '',
        UNSDLEA: '05051',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8696, 39.4513],
            [-81.7327, 39.4814],
            [-81.5756, 39.4411],
            [-81.5163, 39.454],
            [-81.4834, 39.3892],
            [-81.5591, 39.333],
            [-81.6834, 39.2711],
            [-81.7256, 39.2159],
            [-81.8537, 39.3182],
            [-81.8696, 39.4513],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910018',
        NAME: 'Warren Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 504386819,
        AWATER: 3923722,
        INTPTLAT: '+39.3843048',
        INTPTLON: '-081.7052986',
        ELSDLEA: '',
        UNSDLEA: '10018',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5591, 39.333],
            [-81.5652, 39.2762],
            [-81.6834, 39.2711],
            [-81.5591, 39.333],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904361',
        NAME: 'Belpre City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53275191,
        AWATER: 1551971,
        INTPTLAT: '+39.2966464',
        INTPTLON: '-081.6069171',
        ELSDLEA: '',
        UNSDLEA: '04361',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2206, 40.9293],
            [-84.1207, 40.9625],
            [-84.0144, 40.9671],
            [-83.9952, 40.915],
            [-84.0407, 40.8611],
            [-84.1096, 40.861],
            [-84.2527, 40.8598],
            [-84.2206, 40.9293],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904931',
        NAME: 'Columbus Grove Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189424064,
        AWATER: 455940,
        INTPTLAT: '+40.9078664',
        INTPTLON: '-084.1181978',
        ELSDLEA: '',
        UNSDLEA: '04931',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3421, 41.0424],
            [-84.3319, 41.1654],
            [-84.2282, 41.1659],
            [-84.1616, 41.1662],
            [-84.1614, 41.1514],
            [-84.1508, 41.1592],
            [-84.1456, 41.1418],
            [-84.1506, 41.1441],
            [-84.1603, 41.1224],
            [-84.1937, 41.0789],
            [-84.2272, 41.0702],
            [-84.2076, 41.045],
            [-84.2593, 40.991],
            [-84.3421, 41.0377],
            [-84.3421, 41.0424],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904932',
        NAME: 'Continental Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205755920,
        AWATER: 1257484,
        INTPTLAT: '+41.0957281',
        INTPTLON: '-084.2620371',
        ELSDLEA: '',
        UNSDLEA: '04932',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1126, 41.1665],
            [-83.8811, 41.1531],
            [-83.8905, 41.0764],
            [-83.9962, 41.0758],
            [-84.0542, 41.123],
            [-84.1456, 41.1418],
            [-84.1508, 41.1592],
            [-84.1126, 41.1665],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904935',
        NAME: 'Leipsic Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149896857,
        AWATER: 174512,
        INTPTLAT: '+41.1280383',
        INTPTLON: '-083.9816424',
        ELSDLEA: '',
        UNSDLEA: '04935',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.16, 41.0646],
            [-83.9961, 41.0635],
            [-84.0144, 40.9671],
            [-84.1207, 40.9625],
            [-84.1787, 41.0062],
            [-84.1783, 41.0354],
            [-84.2064, 41.0502],
            [-84.16, 41.0646],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904937',
        NAME: 'Ottawa-Glandorf Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157042885,
        AWATER: 717780,
        INTPTLAT: '+41.0052131',
        INTPTLON: '-084.0872944',
        ELSDLEA: '',
        UNSDLEA: '04937',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.465, 41.1189],
            [-81.453, 41.1244],
            [-81.3921, 41.1357],
            [-81.3922, 41.1355],
            [-81.393, 41.0663],
            [-81.4292, 41.0668],
            [-81.465, 41.1189],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904488',
        NAME: 'Tallmadge City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36539927,
        AWATER: 126623,
        INTPTLAT: '+41.1033636',
        INTPTLON: '-081.4221916',
        ELSDLEA: '',
        UNSDLEA: '04488',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7661, 41.3309],
            [-82.7364, 41.4336],
            [-82.6465, 41.4339],
            [-82.6324, 41.4282],
            [-82.6141, 41.3586],
            [-82.6895, 41.3135],
            [-82.7428, 41.3023],
            [-82.7661, 41.3309],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904681',
        NAME: 'Perkins Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124861322,
        AWATER: 627438,
        INTPTLAT: '+41.3678030',
        INTPTLON: '-082.7000742',
        ELSDLEA: '',
        UNSDLEA: '04681',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4439, 41.3572],
            [-82.3452, 41.3574],
            [-82.2673, 41.4108],
            [-82.2167, 41.3525],
            [-82.2684, 41.2571],
            [-82.3441, 41.2022],
            [-82.344, 41.2065],
            [-82.3423, 41.2836],
            [-82.4357, 41.2839],
            [-82.4439, 41.3572],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904815',
        NAME: 'Firelands Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228287531,
        AWATER: 1376657,
        INTPTLAT: '+41.3107708',
        INTPTLON: '-082.3249514',
        ELSDLEA: '',
        UNSDLEA: '04815',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8126, 39.9022],
            [-84.8123, 39.9273],
            [-84.6614, 39.9167],
            [-84.5962, 39.9167],
            [-84.595, 39.8256],
            [-84.6896, 39.8232],
            [-84.7036, 39.7436],
            [-84.8144, 39.7436],
            [-84.8126, 39.9022],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904927',
        NAME: 'National Trail Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 291523938,
        AWATER: 542111,
        INTPTLAT: '+39.8495736',
        INTPTLON: '-084.7136777',
        ELSDLEA: '',
        UNSDLEA: '04927',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8157, 39.4794],
            [-84.8152, 39.5487],
            [-84.7464, 39.567],
            [-84.6966, 39.5675],
            [-84.5915, 39.5675],
            [-84.5901, 39.4563],
            [-84.5903, 39.4418],
            [-84.6276, 39.406],
            [-84.8176, 39.3918],
            [-84.8157, 39.4794],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904615',
        NAME: 'Talawanda City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 354129829,
        AWATER: 1231045,
        INTPTLAT: '+39.4860489',
        INTPTLON: '-084.7089374',
        ELSDLEA: '',
        UNSDLEA: '04615',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9891, 38.8548],
            [-83.8738, 38.9512],
            [-83.8458, 38.8307],
            [-83.8987, 38.7923],
            [-83.9891, 38.8548],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904537',
        NAME: 'Georgetown Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140727553,
        AWATER: 52858,
        INTPTLAT: '+38.8654380',
        INTPTLON: '-083.8930152',
        ELSDLEA: '',
        UNSDLEA: '04537',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0429, 38.8472],
            [-83.9891, 38.8548],
            [-83.8987, 38.7923],
            [-83.8458, 38.8307],
            [-83.6949, 38.776],
            [-83.6962, 38.7617],
            [-83.7053, 38.6399],
            [-83.9039, 38.7682],
            [-83.9605, 38.7873],
            [-84.0286, 38.7739],
            [-84.0527, 38.7712],
            [-84.0429, 38.8472],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904607',
        NAME: 'Ripley-Union-Lewis-Huntington Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250942995,
        AWATER: 5938435,
        INTPTLAT: '+38.7538774',
        INTPTLON: '-083.8385447',
        ELSDLEA: '',
        UNSDLEA: '04607',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.65, 40.6681],
            [-81.6492, 40.6351],
            [-81.6697, 40.4439],
            [-81.8045, 40.4468],
            [-81.8787, 40.5959],
            [-81.8769, 40.6672],
            [-81.65, 40.6681],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904768',
        NAME: 'East Holmes Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 386800113,
        AWATER: 715422,
        INTPTLAT: '+40.5691651',
        INTPTLON: '-081.7557922',
        ELSDLEA: '',
        UNSDLEA: '04768',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2803, 41.7603],
            [-81.2466, 41.762],
            [-81.2629, 41.7452],
            [-81.2664, 41.7358],
            [-81.2803, 41.7603],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904536',
        NAME: 'Fairport Harbor Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4830960,
        AWATER: 278447,
        INTPTLAT: '+41.7517328',
        INTPTLON: '-081.2573959',
        ELSDLEA: '',
        UNSDLEA: '04536',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5878, 39.802],
            [-84.4831, 39.8093],
            [-84.4827, 39.7883],
            [-84.4814, 39.7228],
            [-84.4805, 39.671],
            [-84.6137, 39.6708],
            [-84.5878, 39.802],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904900',
        NAME: 'Twin Valley Community Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157375046,
        AWATER: 508265,
        INTPTLAT: '+39.7301297',
        INTPTLON: '-084.5425795',
        ELSDLEA: '',
        UNSDLEA: '04900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8148, 39.616],
            [-84.7464, 39.567],
            [-84.8152, 39.5487],
            [-84.8148, 39.616],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3906496',
        NAME: 'College Corner Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23906915,
        AWATER: 971808,
        INTPTLAT: '+39.5865828',
        INTPTLON: '-084.7864702',
        ELSDLEA: '',
        UNSDLEA: '06496',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1933, 41.4186],
            [-82.1051, 41.4375],
            [-82.1041, 41.418],
            [-82.1629, 41.4182],
            [-82.1933, 41.4186],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904813',
        NAME: 'Clearview Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10867108,
        AWATER: 60083,
        INTPTLAT: '+41.4264330',
        INTPTLON: '-082.1430247',
        ELSDLEA: '',
        UNSDLEA: '04813',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.456, 40.6587],
            [-84.3394, 40.6408],
            [-84.2711, 40.5331],
            [-84.3785, 40.4939],
            [-84.4552, 40.4816],
            [-84.4561, 40.6408],
            [-84.456, 40.6587],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904472',
        NAME: 'St. Marys City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209908956,
        AWATER: 568720,
        INTPTLAT: '+40.5638475',
        INTPTLON: '-084.3814835',
        ELSDLEA: '',
        UNSDLEA: '04472',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9939, 40.5279],
            [-83.8799, 40.5387],
            [-83.8562, 40.5185],
            [-83.7505, 40.4711],
            [-83.7301, 40.3966],
            [-83.8284, 40.3568],
            [-83.989, 40.3691],
            [-84.0167, 40.4976],
            [-83.9939, 40.5279],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904808',
        NAME: 'Indian Lake Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308061901,
        AWATER: 18738920,
        INTPTLAT: '+40.4510072',
        INTPTLON: '-083.8785119',
        ELSDLEA: '',
        UNSDLEA: '04808',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3984, 40.9033],
            [-84.341, 40.9036],
            [-84.2682, 40.8597],
            [-84.3034, 40.7721],
            [-84.4162, 40.7939],
            [-84.3984, 40.9033],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904388',
        NAME: 'Delphos City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137375849,
        AWATER: 781246,
        INTPTLAT: '+40.8290973',
        INTPTLON: '-084.3504397',
        ELSDLEA: '',
        UNSDLEA: '04388',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-82.6944, 41.4883],
              [-82.6869, 41.4933],
              [-82.6845, 41.48],
              [-82.6921, 41.4821],
              [-82.6944, 41.4883],
            ],
          ],
          [
            [
              [-82.7777, 41.4518],
              [-82.6465, 41.4339],
              [-82.7364, 41.4336],
              [-82.7777, 41.4518],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904474',
        NAME: 'Sandusky City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25205715,
        AWATER: 204266,
        INTPTLAT: '+41.4456249',
        INTPTLON: '-082.7108205',
        ELSDLEA: '',
        UNSDLEA: '04474',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7594, 40.9882],
            [-81.6885, 40.9886],
            [-81.6477, 40.9482],
            [-81.6669, 40.9023],
            [-81.7633, 40.9241],
            [-81.7634, 40.9313],
            [-81.7594, 40.9882],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905053',
        NAME: 'Chippewa Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77406960,
        AWATER: 519577,
        INTPTLAT: '+40.9507969',
        INTPTLON: '-081.7021741',
        ELSDLEA: '',
        UNSDLEA: '05053',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9932, 40.9011],
            [-81.9075, 40.9012],
            [-81.8773, 40.8138],
            [-82.0324, 40.7889],
            [-81.9932, 40.9011],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910032',
        NAME: 'Wooster City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107296685,
        AWATER: 408707,
        INTPTLAT: '+40.8401308',
        INTPTLON: '-081.9611616',
        ELSDLEA: '',
        UNSDLEA: '10032',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2711, 40.5331],
            [-84.2215, 40.5057],
            [-84.2306, 40.482],
            [-84.2508, 40.4818],
            [-84.3785, 40.4939],
            [-84.2711, 40.5331],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904596',
        NAME: 'New Knoxville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70181200,
        AWATER: 10159,
        INTPTLAT: '+40.5036538',
        INTPTLON: '-084.2881043',
        ELSDLEA: '',
        UNSDLEA: '04596',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3991, 40.9653],
            [-84.3984, 40.9033],
            [-84.4162, 40.7939],
            [-84.4562, 40.7283],
            [-84.5715, 40.728],
            [-84.572, 40.9028],
            [-84.5726, 40.9826],
            [-84.3991, 40.9653],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910022',
        NAME: 'Lincolnview Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 374956667,
        AWATER: 1126611,
        INTPTLAT: '+40.8695856',
        INTPTLON: '-084.4949639',
        ELSDLEA: '',
        UNSDLEA: '10022',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6889, 40.786],
            [-84.6672, 40.901],
            [-84.572, 40.9028],
            [-84.5715, 40.728],
            [-84.6892, 40.7277],
            [-84.6889, 40.786],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910023',
        NAME: 'Van Wert City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182384732,
        AWATER: 943244,
        INTPTLAT: '+40.8110057',
        INTPTLON: '-084.6226405',
        ELSDLEA: '',
        UNSDLEA: '10023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8025, 40.5722],
            [-84.8024, 40.5824],
            [-84.7655, 40.5822],
            [-84.7575, 40.4943],
            [-84.6571, 40.5235],
            [-84.5515, 40.4707],
            [-84.5621, 40.4586],
            [-84.695, 40.4338],
            [-84.8035, 40.4654],
            [-84.8025, 40.5722],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904531',
        NAME: 'Coldwater Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115264378,
        AWATER: 241164,
        INTPTLAT: '+40.4870478',
        INTPTLON: '-084.6983780',
        ELSDLEA: '',
        UNSDLEA: '04531',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8024, 40.7717],
            [-84.6889, 40.786],
            [-84.6892, 40.7277],
            [-84.5715, 40.728],
            [-84.4562, 40.7283],
            [-84.456, 40.6587],
            [-84.4561, 40.6408],
            [-84.5707, 40.6406],
            [-84.7655, 40.5822],
            [-84.8024, 40.5824],
            [-84.8024, 40.6748],
            [-84.8024, 40.7717],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904857',
        NAME: 'Parkway Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 414818037,
        AWATER: 714033,
        INTPTLAT: '+40.6836114',
        INTPTLON: '-084.6590108',
        ELSDLEA: '',
        UNSDLEA: '04857',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7351, 41.6024],
            [-82.7073, 41.6196],
            [-82.6812, 41.6113],
            [-82.6865, 41.587],
            [-82.7351, 41.6024],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904679',
        NAME: 'Kelleys Island Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11261421,
        AWATER: 150836,
        INTPTLAT: '+41.6026070',
        INTPTLON: '-082.7065059',
        ELSDLEA: '',
        UNSDLEA: '04679',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4574, 40.1073],
            [-82.3753, 40.103],
            [-82.3804, 40.0524],
            [-82.4794, 40.036],
            [-82.4574, 40.1073],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904445',
        NAME: 'Newark City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60254136,
        AWATER: 1255867,
        INTPTLAT: '+40.0755269',
        INTPTLON: '-082.4233896',
        ELSDLEA: '',
        UNSDLEA: '04445',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7943, 40.333],
            [-82.6442, 40.3744],
            [-82.551, 40.3165],
            [-82.5958, 40.2575],
            [-82.7507, 40.277],
            [-82.7943, 40.333],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904782',
        NAME: 'Centerburg Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167300064,
        AWATER: 487613,
        INTPTLAT: '+40.3126332',
        INTPTLON: '-082.6612718',
        ELSDLEA: '',
        UNSDLEA: '04782',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7675, 40.051],
            [-82.7628, 40.0883],
            [-82.7129, 40.0862],
            [-82.6688, 40.0515],
            [-82.7613, 39.9657],
            [-82.7958, 39.9847],
            [-82.793, 39.9875],
            [-82.8013, 39.9919],
            [-82.7675, 40.051],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904800',
        NAME: 'Licking Heights Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94444983,
        AWATER: 334874,
        INTPTLAT: '+40.0257230',
        INTPTLON: '-082.7351133',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2586, 39.9892],
            [-82.1948, 40.0207],
            [-81.976, 40.0141],
            [-81.9902, 39.9756],
            [-82.0289, 39.9428],
            [-82.1624, 39.9094],
            [-82.234, 39.9133],
            [-82.2586, 39.9892],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904888',
        NAME: 'West Muskingum Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 199641632,
        AWATER: 3289701,
        INTPTLAT: '+39.9749253',
        INTPTLON: '-082.1340392',
        ELSDLEA: '',
        UNSDLEA: '04888',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.227, 41.1729],
            [-83.1971, 41.2544],
            [-83.188, 41.277],
            [-83.0927, 41.2568],
            [-83.0741, 41.1971],
            [-83.1889, 41.1677],
            [-83.189, 41.1617],
            [-83.227, 41.1729],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910021',
        NAME: 'Old Fort Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120278017,
        AWATER: 1623091,
        INTPTLAT: '+41.2249704',
        INTPTLON: '-083.1548459',
        ELSDLEA: '',
        UNSDLEA: '10021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3996, 41.3486],
            [-81.3917, 41.3483],
            [-81.2986, 41.3473],
            [-81.2985, 41.2753],
            [-81.3918, 41.2767],
            [-81.3996, 41.3486],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904917',
        NAME: 'Aurora City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59872793,
        AWATER: 2968444,
        INTPTLAT: '+41.3015430',
        INTPTLON: '-081.3325080',
        ELSDLEA: '',
        UNSDLEA: '04917',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3929, 41.0662],
            [-81.3932, 41.0376],
            [-81.417, 41.046],
            [-81.3929, 41.0662],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905003',
        NAME: 'Mogadore Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6581888,
        AWATER: 121263,
        INTPTLAT: '+41.0523267',
        INTPTLON: '-081.3974672',
        ELSDLEA: '',
        UNSDLEA: '05003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3823, 41.2028],
            [-81.2994, 41.2024],
            [-81.3003, 41.1341],
            [-81.3922, 41.1355],
            [-81.3921, 41.1357],
            [-81.3823, 41.2028],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904416',
        NAME: 'Kent City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51045144,
        AWATER: 4829791,
        INTPTLAT: '+41.1707828',
        INTPTLON: '-081.3431488',
        ELSDLEA: '',
        UNSDLEA: '04416',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2633, 40.6859],
            [-84.1079, 40.6579],
            [-84.0392, 40.6439],
            [-84.058, 40.5713],
            [-83.9939, 40.5279],
            [-84.0167, 40.4976],
            [-84.1071, 40.4832],
            [-84.2215, 40.5057],
            [-84.2711, 40.5331],
            [-84.3394, 40.6408],
            [-84.2633, 40.6859],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904498',
        NAME: 'Wapakoneta City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378076430,
        AWATER: 712372,
        INTPTLAT: '+40.5776947',
        INTPTLON: '-084.1546639',
        ELSDLEA: '',
        UNSDLEA: '04498',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4162, 40.7939],
            [-84.3034, 40.7721],
            [-84.256, 40.7438],
            [-84.2633, 40.6859],
            [-84.3394, 40.6408],
            [-84.456, 40.6587],
            [-84.4562, 40.7283],
            [-84.4162, 40.7939],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904580',
        NAME: 'Spencerville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231461049,
        AWATER: 398211,
        INTPTLAT: '+40.7108528',
        INTPTLON: '-084.3651278',
        ELSDLEA: '',
        UNSDLEA: '04580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0392, 40.6439],
            [-83.9941, 40.6487],
            [-83.8802, 40.6447],
            [-83.8799, 40.5387],
            [-83.9939, 40.5279],
            [-84.058, 40.5713],
            [-84.0392, 40.6439],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904597',
        NAME: 'Waynesfield-Goshen Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162832039,
        AWATER: 36686,
        INTPTLAT: '+40.5910915',
        INTPTLON: '-083.9640369',
        ELSDLEA: '',
        UNSDLEA: '04597',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8802, 40.6447],
            [-83.8798, 40.7324],
            [-83.766, 40.7329],
            [-83.7305, 40.6894],
            [-83.7352, 40.6017],
            [-83.8562, 40.5185],
            [-83.8799, 40.5387],
            [-83.8802, 40.6447],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904752',
        NAME: 'Upper Scioto Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256076468,
        AWATER: 53706,
        INTPTLAT: '+40.6264231',
        INTPTLON: '-083.8245029',
        ELSDLEA: '',
        UNSDLEA: '04752',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9176, 38.7517],
            [-82.9479, 38.7408],
            [-82.9376, 38.7558],
            [-82.9176, 38.7517],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904446',
        NAME: 'New Boston Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2888865,
        AWATER: 76447,
        INTPTLAT: '+38.7505888',
        INTPTLON: '-082.9332499',
        ELSDLEA: '',
        UNSDLEA: '04446',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9077, 38.8316],
            [-82.9021, 38.9211],
            [-82.8065, 38.948],
            [-82.7534, 38.9454],
            [-82.7605, 38.8549],
            [-82.8024, 38.7701],
            [-82.9268, 38.7739],
            [-82.9077, 38.8316],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904962',
        NAME: 'Minford Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205158969,
        AWATER: 1121680,
        INTPTLAT: '+38.8553791',
        INTPTLON: '-082.8473763',
        ELSDLEA: '',
        UNSDLEA: '04962',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2682, 38.7099],
            [-83.2682, 38.7428],
            [-83.0094, 38.7923],
            [-83.0129, 38.7305],
            [-83.0309, 38.7255],
            [-83.1348, 38.632],
            [-83.2676, 38.6184],
            [-83.2682, 38.7099],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904965',
        NAME: 'Washington-Nile Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 286986211,
        AWATER: 3377227,
        INTPTLAT: '+38.7026815',
        INTPTLON: '-083.1772709',
        ELSDLEA: '',
        UNSDLEA: '04965',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9268, 38.7739],
            [-82.8024, 38.7701],
            [-82.8032, 38.7535],
            [-82.8739, 38.7448],
            [-82.9176, 38.7517],
            [-82.9376, 38.7558],
            [-82.9479, 38.7408],
            [-83.0116, 38.73],
            [-82.9268, 38.7739],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910027',
        NAME: 'Portsmouth City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39368948,
        AWATER: 1014378,
        INTPTLAT: '+38.7618666',
        INTPTLON: '-082.9094599',
        ELSDLEA: '',
        UNSDLEA: '10027',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4888, 40.0497],
            [-83.3949, 40.0572],
            [-83.2949, 40.1615],
            [-83.226, 40.156],
            [-83.1916, 40.1074],
            [-83.2609, 40.0028],
            [-83.3716, 39.9627],
            [-83.4846, 40.007],
            [-83.4888, 40.0497],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904826',
        NAME: 'Jonathan Alder Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 280527753,
        AWATER: 634600,
        INTPTLAT: '+40.0522440',
        INTPTLON: '-083.3347344',
        ELSDLEA: '',
        UNSDLEA: '04826',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2995, 41.2071],
            [-81.1928, 41.2021],
            [-81.1926, 41.1921],
            [-81.1927, 41.1774],
            [-81.1933, 41.1341],
            [-81.3003, 41.1341],
            [-81.2994, 41.2024],
            [-81.2995, 41.2071],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904468',
        NAME: 'Ravenna City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66883311,
        AWATER: 939361,
        INTPTLAT: '+41.1714225',
        INTPTLON: '-081.2489800',
        ELSDLEA: '',
        UNSDLEA: '04468',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0029, 41.2718],
            [-81.0028, 41.2295],
            [-81.0987, 41.2253],
            [-81.0029, 41.2718],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904566',
        NAME: 'Windham Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38020899,
        AWATER: 161066,
        INTPTLAT: '+41.2500445',
        INTPTLON: '-081.0575043',
        ELSDLEA: '',
        UNSDLEA: '04566',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3003, 41.1341],
            [-81.1933, 41.1341],
            [-81.1943, 41.0633],
            [-81.3012, 41.0649],
            [-81.3003, 41.1341],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904921',
        NAME: 'Rootstown Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69660121,
        AWATER: 1953287,
        INTPTLAT: '+41.1005447',
        INTPTLON: '-081.2453522',
        ELSDLEA: '',
        UNSDLEA: '04921',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7534, 38.9454],
            [-82.5664, 38.9323],
            [-82.5507, 39.0542],
            [-82.5505, 39.0551],
            [-82.4353, 39.0351],
            [-82.4356, 39.0315],
            [-82.4653, 38.8457],
            [-82.5744, 38.8535],
            [-82.5631, 38.7777],
            [-82.5667, 38.7485],
            [-82.6591, 38.7627],
            [-82.65, 38.8491],
            [-82.7605, 38.8549],
            [-82.7534, 38.9454],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904776',
        NAME: 'Oak Hill Union Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 415594429,
        AWATER: 1342889,
        INTPTLAT: '+38.9021564',
        INTPTLON: '-082.5886824',
        ELSDLEA: '',
        UNSDLEA: '04776',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7486, 39.3682],
            [-82.6344, 39.376],
            [-82.5145, 39.3958],
            [-82.2897, 39.3842],
            [-82.3147, 39.1166],
            [-82.3229, 39.0277],
            [-82.4356, 39.0315],
            [-82.4353, 39.0351],
            [-82.4247, 39.138],
            [-82.5403, 39.1445],
            [-82.6515, 39.1896],
            [-82.7629, 39.208],
            [-82.7486, 39.3682],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905039',
        NAME: 'Vinton County Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1069918889,
        AWATER: 6791893,
        INTPTLAT: '+39.2503718',
        INTPTLON: '-082.4854931',
        ELSDLEA: '',
        UNSDLEA: '05039',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.49, 38.4733],
            [-82.4877, 38.5053],
            [-82.3687, 38.4987],
            [-82.392, 38.4354],
            [-82.4951, 38.4152],
            [-82.49, 38.4733],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904529',
        NAME: 'Chesapeake Union Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77445860,
        AWATER: 1368943,
        INTPTLAT: '+38.4625444',
        INTPTLON: '-082.4501795',
        ELSDLEA: '',
        UNSDLEA: '04529',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.816, 38.5708],
            [-82.7662, 38.6447],
            [-82.6591, 38.7627],
            [-82.5667, 38.7485],
            [-82.5997, 38.6303],
            [-82.542, 38.5961],
            [-82.5464, 38.5376],
            [-82.652, 38.5141],
            [-82.681, 38.55],
            [-82.7164, 38.5527],
            [-82.816, 38.5708],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904794',
        NAME: 'Rock Hill Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351384657,
        AWATER: 3229964,
        INTPTLAT: '+38.6335453',
        INTPTLON: '-082.6515215',
        ELSDLEA: '',
        UNSDLEA: '04794',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6601, 38.5002],
            [-82.652, 38.5141],
            [-82.5464, 38.5376],
            [-82.542, 38.5961],
            [-82.4877, 38.5053],
            [-82.49, 38.4733],
            [-82.6133, 38.474],
            [-82.6601, 38.5002],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904792',
        NAME: 'Dawson-Bryant Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123855505,
        AWATER: 670970,
        INTPTLAT: '+38.5224430',
        INTPTLON: '-082.5504608',
        ELSDLEA: '',
        UNSDLEA: '04792',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3687, 38.4987],
            [-82.3617, 38.5852],
            [-82.2734, 38.5942],
            [-82.324, 38.4493],
            [-82.392, 38.4354],
            [-82.3687, 38.4987],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904793',
        NAME: 'Fairland Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95542552,
        AWATER: 1091061,
        INTPTLAT: '+38.5192804',
        INTPTLON: '-082.3389105',
        ELSDLEA: '',
        UNSDLEA: '04793',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5667, 38.7485],
            [-82.5631, 38.7777],
            [-82.4836, 38.7723],
            [-82.4418, 38.6806],
            [-82.3545, 38.6761],
            [-82.3617, 38.5852],
            [-82.3687, 38.4987],
            [-82.4877, 38.5053],
            [-82.542, 38.5961],
            [-82.5997, 38.6303],
            [-82.5667, 38.7485],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904796',
        NAME: 'Symmes Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 386916285,
        AWATER: 1918340,
        INTPTLAT: '+38.6304837',
        INTPTLON: '-082.4698194',
        ELSDLEA: '',
        UNSDLEA: '04796',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5903, 39.4418],
            [-84.536, 39.411],
            [-84.5854, 39.3562],
            [-84.6276, 39.406],
            [-84.5903, 39.4418],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904410',
        NAME: 'Hamilton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55524062,
        AWATER: 1009336,
        INTPTLAT: '+39.3944359',
        INTPTLON: '-084.5691484',
        ELSDLEA: '',
        UNSDLEA: '04410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8562, 40.5185],
            [-83.7352, 40.6017],
            [-83.6938, 40.5836],
            [-83.607, 40.4668],
            [-83.525, 40.4653],
            [-83.5145, 40.3869],
            [-83.5456, 40.2805],
            [-83.6688, 40.2453],
            [-83.7406, 40.2935],
            [-83.7301, 40.3966],
            [-83.7505, 40.4711],
            [-83.8562, 40.5185],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904807',
        NAME: 'Benjamin Logan Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 566198693,
        AWATER: 2432758,
        INTPTLAT: '+40.4156162',
        INTPTLON: '-083.6360056',
        ELSDLEA: '',
        UNSDLEA: '04807',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5027, 40.1261],
            [-83.4944, 40.2272],
            [-83.4039, 40.1994],
            [-83.2403, 40.2445],
            [-83.1713, 40.1981],
            [-83.226, 40.156],
            [-83.2949, 40.1615],
            [-83.3949, 40.0572],
            [-83.4888, 40.0497],
            [-83.5027, 40.1261],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905032',
        NAME: 'Fairbanks Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338637729,
        AWATER: 2985284,
        INTPTLAT: '+40.1572609',
        INTPTLON: '-083.3728742',
        ELSDLEA: '',
        UNSDLEA: '05032',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5145, 40.3869],
            [-83.3504, 40.3616],
            [-83.2494, 40.3046],
            [-83.2403, 40.2445],
            [-83.4039, 40.1994],
            [-83.4944, 40.2272],
            [-83.5456, 40.2805],
            [-83.5145, 40.3869],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904547',
        NAME: 'Marysville Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355573326,
        AWATER: 5384438,
        INTPTLAT: '+40.2897738',
        INTPTLON: '-083.4136892',
        ELSDLEA: '',
        UNSDLEA: '04547',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6561, 40.8739],
            [-81.6498, 40.874],
            [-81.6583, 40.8439],
            [-81.7652, 40.7865],
            [-81.8132, 40.8148],
            [-81.7632, 40.8846],
            [-81.6561, 40.8739],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904461',
        NAME: 'Orrville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66604779,
        AWATER: 156708,
        INTPTLAT: '+40.8463815',
        INTPTLON: '-081.7462302',
        ELSDLEA: '',
        UNSDLEA: '04461',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6498, 40.874],
            [-81.557, 40.8492],
            [-81.5229, 40.8218],
            [-81.5287, 40.7472],
            [-81.649, 40.7369],
            [-81.6583, 40.8439],
            [-81.6498, 40.874],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904995',
        NAME: 'Tuslaw Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115893899,
        AWATER: 249021,
        INTPTLAT: '+40.7897483',
        INTPTLON: '-081.5951197',
        ELSDLEA: '',
        UNSDLEA: '04995',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5709, 40.8089],
            [-82.5315, 40.7231],
            [-82.5707, 40.7249],
            [-82.5709, 40.8089],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904429',
        NAME: 'Mansfield City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47472500,
        AWATER: 94334,
        INTPTLAT: '+40.7640841',
        INTPTLON: '-082.5409745',
        ELSDLEA: '',
        UNSDLEA: '04429',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3784, 40.7342],
            [-82.3178, 40.7265],
            [-82.3846, 40.6385],
            [-82.4603, 40.6765],
            [-82.3784, 40.7342],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904944',
        NAME: 'Lucas Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102543607,
        AWATER: 498419,
        INTPTLAT: '+40.6894968',
        INTPTLON: '-082.4076022',
        ELSDLEA: '',
        UNSDLEA: '04944',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8834, 41.3353],
            [-83.7666, 41.3209],
            [-83.6596, 41.4369],
            [-83.5905, 41.4365],
            [-83.5582, 41.3485],
            [-83.6506, 41.3085],
            [-83.6528, 41.2404],
            [-83.7659, 41.244],
            [-83.8623, 41.2552],
            [-83.8834, 41.3353],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904363',
        NAME: 'Bowling Green City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 318780417,
        AWATER: 590962,
        INTPTLAT: '+41.3311788',
        INTPTLON: '-083.7183891',
        ELSDLEA: '',
        UNSDLEA: '04363',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.543, 41.6179],
            [-83.5056, 41.6178],
            [-83.4159, 41.6189],
            [-83.4157, 41.6019],
            [-83.5276, 41.6019],
            [-83.543, 41.6179],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905071',
        NAME: 'Northwood Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20023632,
        AWATER: 32671,
        INTPTLAT: '+41.6093853',
        INTPTLON: '-083.4799982',
        ELSDLEA: '',
        UNSDLEA: '05071',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5604, 40.9076],
            [-82.4961, 40.9835],
            [-82.4329, 40.9929],
            [-82.3333, 40.9929],
            [-82.3394, 40.9019],
            [-82.416, 40.9019],
            [-82.4168, 40.8146],
            [-82.571, 40.8138],
            [-82.5604, 40.9076],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904942',
        NAME: 'Crestview Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 267320135,
        AWATER: 1139116,
        INTPTLAT: '+40.9058734',
        INTPTLON: '-082.4632213',
        ELSDLEA: '',
        UNSDLEA: '04942',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.725, 40.8433],
            [-82.7149, 40.9429],
            [-82.5604, 40.9076],
            [-82.571, 40.8138],
            [-82.6866, 40.8137],
            [-82.725, 40.8433],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910009',
        NAME: 'Shelby City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154709190,
        AWATER: 761569,
        INTPTLAT: '+40.8720275',
        INTPTLON: '-082.6564265',
        ELSDLEA: '',
        UNSDLEA: '10009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4592, 41.4272],
            [-84.3417, 41.4276],
            [-84.2285, 41.4278],
            [-84.2274, 41.3013],
            [-84.3276, 41.2891],
            [-84.4576, 41.2816],
            [-84.4592, 41.4272],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904672',
        NAME: 'Northeastern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 293846044,
        AWATER: 1491512,
        INTPTLAT: '+41.3597877',
        INTPTLON: '-084.3478663',
        ELSDLEA: '',
        UNSDLEA: '04672',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7654, 40.7614],
            [-82.7636, 40.8433],
            [-82.725, 40.8433],
            [-82.6866, 40.8137],
            [-82.7266, 40.7554],
            [-82.7654, 40.7614],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910008',
        NAME: 'Crestline Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51164575,
        AWATER: 39535,
        INTPTLAT: '+40.7933926',
        INTPTLON: '-082.7310338',
        ELSDLEA: '',
        UNSDLEA: '10008',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0361, 40.0402],
            [-84.0229, 40.1839],
            [-84.0148, 40.2735],
            [-83.8414, 40.2633],
            [-83.7978, 40.1293],
            [-83.8054, 40.044],
            [-84.0361, 40.0402],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904619',
        NAME: 'Graham Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 469573231,
        AWATER: 2032149,
        INTPTLAT: '+40.1423910',
        INTPTLON: '-083.9182717',
        ELSDLEA: '',
        UNSDLEA: '04619',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2335, 41.4441],
            [-82.1301, 41.4852],
            [-82.1051, 41.4375],
            [-82.1933, 41.4186],
            [-82.2335, 41.4441],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904426',
        NAME: 'Lorain City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40743974,
        AWATER: 1102398,
        INTPTLAT: '+41.4481556',
        INTPTLON: '-082.1647999',
        ELSDLEA: '',
        UNSDLEA: '04426',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1301, 41.4852],
            [-82.0666, 41.5016],
            [-82.067, 41.4787],
            [-82.0678, 41.4183],
            [-82.1041, 41.418],
            [-82.1051, 41.4375],
            [-82.1301, 41.4852],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904476',
        NAME: 'Sheffield-Sheffield Lake City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34318644,
        AWATER: 254460,
        INTPTLAT: '+41.4611957',
        INTPTLON: '-082.0911386',
        ELSDLEA: '',
        UNSDLEA: '04476',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2522, 41.4367],
            [-82.2335, 41.4441],
            [-82.1933, 41.4186],
            [-82.1629, 41.4182],
            [-82.1767, 41.3544],
            [-82.2167, 41.3525],
            [-82.2673, 41.4108],
            [-82.2522, 41.4367],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904519',
        NAME: 'Amherst Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49910061,
        AWATER: 390314,
        INTPTLAT: '+41.3966776',
        INTPTLON: '-082.2167727',
        ELSDLEA: '',
        UNSDLEA: '04519',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0666, 41.5016],
            [-81.9683, 41.5052],
            [-81.9695, 41.478],
            [-82.067, 41.4787],
            [-82.0666, 41.5016],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904812',
        NAME: 'Avon Lake City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28745661,
        AWATER: 0,
        INTPTLAT: '+41.4944950',
        INTPTLON: '-082.0159376',
        ELSDLEA: '',
        UNSDLEA: '04812',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1155, 41.3443],
            [-82.069, 41.3517],
            [-81.9833, 41.3515],
            [-81.9725, 41.2748],
            [-81.9739, 41.1998],
            [-82.0725, 41.1998],
            [-82.1155, 41.3443],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904817',
        NAME: 'Midview Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160906031,
        AWATER: 983766,
        INTPTLAT: '+41.2841404',
        INTPTLON: '-082.0299676',
        ELSDLEA: '',
        UNSDLEA: '04817',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.471, 40.1791],
            [-84.4231, 40.2065],
            [-84.4043, 40.1975],
            [-84.4043, 40.1975],
            [-84.4056, 40.0947],
            [-84.4296, 40.0754],
            [-84.4533, 40.0901],
            [-84.471, 40.1791],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904522',
        NAME: 'Bradford Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62590233,
        AWATER: 658558,
        INTPTLAT: '+40.1453717',
        INTPTLON: '-084.4415328',
        ELSDLEA: '',
        UNSDLEA: '04522',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3082, 40.389],
            [-84.2524, 40.3538],
            [-84.3719, 40.2899],
            [-84.4341, 40.2921],
            [-84.4402, 40.3252],
            [-84.3082, 40.389],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904978',
        NAME: 'Fort Loramie Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112116861,
        AWATER: 3403536,
        INTPTLAT: '+40.3375533',
        INTPTLON: '-084.3486159',
        ELSDLEA: '',
        UNSDLEA: '04978',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4341, 40.2921],
            [-84.3719, 40.2899],
            [-84.4043, 40.1975],
            [-84.4231, 40.2065],
            [-84.4341, 40.2921],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904981',
        NAME: 'Russia Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56310327,
        AWATER: 121826,
        INTPTLAT: '+40.2476434',
        INTPTLON: '-084.4015687',
        ELSDLEA: '',
        UNSDLEA: '04981',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4836, 40.471],
            [-84.4552, 40.4816],
            [-84.3785, 40.4939],
            [-84.2508, 40.4818],
            [-84.3173, 40.4233],
            [-84.4266, 40.4221],
            [-84.4836, 40.471],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904595',
        NAME: 'New Bremen Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92471484,
        AWATER: 71080,
        INTPTLAT: '+40.4538423',
        INTPTLON: '-084.3703342',
        ELSDLEA: '',
        UNSDLEA: '04595',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5364, 40.9103],
            [-81.4198, 40.9176],
            [-81.4229, 40.8551],
            [-81.4455, 40.819],
            [-81.4927, 40.8205],
            [-81.5229, 40.8218],
            [-81.557, 40.8492],
            [-81.5364, 40.9103],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904985',
        NAME: 'Jackson Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92679658,
        AWATER: 1582575,
        INTPTLAT: '+40.8668070',
        INTPTLON: '-081.4830239',
        ELSDLEA: '',
        UNSDLEA: '04985',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.65, 40.6681],
            [-81.6492, 40.7301],
            [-81.649, 40.7369],
            [-81.5287, 40.7472],
            [-81.517, 40.7473],
            [-81.4302, 40.7296],
            [-81.431, 40.7151],
            [-81.5106, 40.6504],
            [-81.577, 40.6233],
            [-81.6492, 40.6351],
            [-81.65, 40.6681],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904984',
        NAME: 'Fairless Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 169111239,
        AWATER: 755835,
        INTPTLAT: '+40.6893666',
        INTPTLON: '-081.5555953',
        ELSDLEA: '',
        UNSDLEA: '04984',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3678, 39.1313],
            [-83.3564, 39.1847],
            [-83.3535, 39.1976],
            [-83.1511, 39.1879],
            [-83.1187, 39.0849],
            [-83.3089, 39.0413],
            [-83.3678, 39.1313],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904915',
        NAME: 'Western Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 304287139,
        AWATER: 739539,
        INTPTLAT: '+39.1191692',
        INTPTLON: '-083.2561481',
        ELSDLEA: '',
        UNSDLEA: '04915',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2306, 40.482],
            [-84.2215, 40.5057],
            [-84.1071, 40.4832],
            [-84.107, 40.4324],
            [-84.2306, 40.482],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904976',
        NAME: 'Botkins Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84080083,
        AWATER: 175181,
        INTPTLAT: '+40.4697338',
        INTPTLON: '-084.1663182',
        ELSDLEA: '',
        UNSDLEA: '04976',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1071, 40.4832],
            [-84.0167, 40.4976],
            [-83.989, 40.3691],
            [-84.0044, 40.3687],
            [-84.1037, 40.3757],
            [-84.107, 40.4324],
            [-84.1071, 40.4832],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910005',
        NAME: 'Jackson Center Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123058335,
        AWATER: 104604,
        INTPTLAT: '+40.4338517',
        INTPTLON: '-084.0457224',
        ELSDLEA: '',
        UNSDLEA: '10005',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4266, 40.4221],
            [-84.3173, 40.4233],
            [-84.3082, 40.389],
            [-84.4402, 40.3252],
            [-84.4726, 40.3291],
            [-84.4266, 40.4221],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904594',
        NAME: 'Minster Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78345360,
        AWATER: 30831,
        INTPTLAT: '+40.3892367',
        INTPTLON: '-084.3968101',
        ELSDLEA: '',
        UNSDLEA: '04594',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4043, 40.1975],
            [-84.3719, 40.2899],
            [-84.2524, 40.3538],
            [-84.2086, 40.3308],
            [-84.2178, 40.1954],
            [-84.3146, 40.1981],
            [-84.4043, 40.1975],
            [-84.4043, 40.1975],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904979',
        NAME: 'Hardin-Houston Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193844409,
        AWATER: 2013481,
        INTPTLAT: '+40.2605431',
        INTPTLON: '-084.2806488',
        ELSDLEA: '',
        UNSDLEA: '04979',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.806, 41.6961],
            [-84.6894, 41.6996],
            [-84.6893, 41.6216],
            [-84.6708, 41.5136],
            [-84.8045, 41.5129],
            [-84.8047, 41.5301],
            [-84.8058, 41.6164],
            [-84.806, 41.6961],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905062',
        NAME: 'Edon Northwest Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202708178,
        AWATER: 1091214,
        INTPTLAT: '+41.6057445',
        INTPTLON: '-084.7450152',
        ELSDLEA: '',
        UNSDLEA: '05062',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.479, 41.4331],
            [-84.469, 41.5358],
            [-84.3809, 41.572],
            [-84.3417, 41.4276],
            [-84.4592, 41.4272],
            [-84.479, 41.4331],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905065',
        NAME: 'Stryker Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130856635,
        AWATER: 387694,
        INTPTLAT: '+41.4891124',
        INTPTLON: '-084.4054238',
        ELSDLEA: '',
        UNSDLEA: '05065',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.547, 41.5936],
            [-84.4682, 41.5934],
            [-84.4664, 41.7041],
            [-84.3995, 41.7058],
            [-84.3813, 41.6167],
            [-84.3809, 41.572],
            [-84.469, 41.5358],
            [-84.5475, 41.5503],
            [-84.547, 41.5936],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905063',
        NAME: 'Millcreek-West Unity Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139790744,
        AWATER: 359152,
        INTPTLAT: '+41.6113122',
        INTPTLON: '-084.4463506',
        ELSDLEA: '',
        UNSDLEA: '05063',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6423, 41.5137],
            [-84.5475, 41.5503],
            [-84.469, 41.5358],
            [-84.479, 41.4331],
            [-84.6219, 41.4484],
            [-84.6423, 41.5137],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904367',
        NAME: 'Bryan City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153329209,
        AWATER: 523294,
        INTPTLAT: '+41.4825989',
        INTPTLON: '-084.5440753',
        ELSDLEA: '',
        UNSDLEA: '04367',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6894, 41.6996],
            [-84.5803, 41.7017],
            [-84.4664, 41.7041],
            [-84.4682, 41.5934],
            [-84.547, 41.5936],
            [-84.6893, 41.6216],
            [-84.6894, 41.6996],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905064',
        NAME: 'North Central Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 199546724,
        AWATER: 1364682,
        INTPTLAT: '+41.6584216',
        INTPTLON: '-084.5634914',
        ELSDLEA: '',
        UNSDLEA: '05064',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5089, 41.4242],
            [-81.4886, 41.4573],
            [-81.4805, 41.5012],
            [-81.472, 41.5012],
            [-81.3909, 41.5007],
            [-81.3914, 41.4549],
            [-81.4283, 41.4248],
            [-81.4888, 41.416],
            [-81.5089, 41.4242],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910016',
        NAME: 'Orange City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64200172,
        AWATER: 538417,
        INTPTLAT: '+41.4656416',
        INTPTLON: '-081.4451021',
        ELSDLEA: '',
        UNSDLEA: '10016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1023, 41.7143],
            [-81.0036, 41.7151],
            [-81.0028, 41.642],
            [-81.0028, 41.5643],
            [-81.1005, 41.5716],
            [-81.1014, 41.6415],
            [-81.1023, 41.7143],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904720',
        NAME: 'Ledgemont Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127974511,
        AWATER: 1502152,
        INTPTLAT: '+41.6523319',
        INTPTLON: '-081.0556929',
        ELSDLEA: '',
        UNSDLEA: '04720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.275, 41.4997],
            [-81.1899, 41.5004],
            [-81.1906, 41.4256],
            [-81.2977, 41.4251],
            [-81.2978, 41.4397],
            [-81.275, 41.4997],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904721',
        NAME: 'Newbury Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71791800,
        AWATER: 2297744,
        INTPTLAT: '+41.4547748',
        INTPTLON: '-081.2291804',
        ELSDLEA: '',
        UNSDLEA: '04721',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6238, 41.9457],
            [-80.5194, 41.9775],
            [-80.5194, 41.8496],
            [-80.5194, 41.7911],
            [-80.6237, 41.7901],
            [-80.6238, 41.9457],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904381',
        NAME: 'Conneaut Area City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151424004,
        AWATER: 137601,
        INTPTLAT: '+41.8782379',
        INTPTLON: '-080.5585868',
        ELSDLEA: '',
        UNSDLEA: '04381',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0725, 41.1998],
            [-81.9739, 41.1998],
            [-81.9725, 41.2748],
            [-81.8978, 41.2759],
            [-81.8781, 41.275],
            [-81.8783, 41.2003],
            [-81.9087, 41.136],
            [-82.0743, 41.1365],
            [-82.0725, 41.1998],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904847',
        NAME: 'Buckeye Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 182416851,
        AWATER: 282196,
        INTPTLAT: '+41.1899143',
        INTPTLON: '-081.9469307',
        ELSDLEA: '',
        UNSDLEA: '04847',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8781, 41.275],
            [-81.7851, 41.2764],
            [-81.7582, 41.2767],
            [-81.8009, 41.2015],
            [-81.8783, 41.2003],
            [-81.8781, 41.275],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904366',
        NAME: 'Brunswick City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67843815,
        AWATER: 95848,
        INTPTLAT: '+41.2404885',
        INTPTLON: '-081.8288212',
        ELSDLEA: '',
        UNSDLEA: '04366',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8203, 41.0624],
            [-81.7792, 41.0624],
            [-81.6876, 41.0622],
            [-81.6885, 40.9886],
            [-81.7594, 40.9882],
            [-81.7856, 40.9951],
            [-81.8203, 41.0624],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910029',
        NAME: 'Wadsworth City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82989065,
        AWATER: 30643,
        INTPTLAT: '+41.0307397',
        INTPTLON: '-081.7478449',
        ELSDLEA: '',
        UNSDLEA: '10029',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8096, 41.789],
            [-80.7134, 41.8331],
            [-80.6218, 41.714],
            [-80.6207, 41.6438],
            [-80.7963, 41.6256],
            [-80.9353, 41.6422],
            [-80.8096, 41.789],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904587',
        NAME: 'Jefferson Area Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329895213,
        AWATER: 2106930,
        INTPTLAT: '+41.7046840',
        INTPTLON: '-080.7528095',
        ELSDLEA: '',
        UNSDLEA: '04587',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7146, 39.7296],
            [-82.5976, 39.7729],
            [-82.5252, 39.7061],
            [-82.6337, 39.6383],
            [-82.7146, 39.7296],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904420',
        NAME: 'Lancaster City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149860258,
        AWATER: 527541,
        INTPTLAT: '+39.7109247',
        INTPTLON: '-082.6186969',
        ELSDLEA: '',
        UNSDLEA: '04420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8344, 39.6494],
            [-82.7853, 39.7339],
            [-82.7146, 39.7296],
            [-82.6337, 39.6383],
            [-82.6183, 39.5957],
            [-82.6201, 39.564],
            [-82.843, 39.5615],
            [-82.8344, 39.6494],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904684',
        NAME: 'Amanda-Clearcreek Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250658730,
        AWATER: 725929,
        INTPTLAT: '+39.6287573',
        INTPTLON: '-082.7376836',
        ELSDLEA: '',
        UNSDLEA: '04684',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6337, 39.6383],
            [-82.5252, 39.7061],
            [-82.4903, 39.6617],
            [-82.4961, 39.6028],
            [-82.6183, 39.5957],
            [-82.6337, 39.6383],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904685',
        NAME: 'Berne Union Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119060125,
        AWATER: 837615,
        INTPTLAT: '+39.6433392',
        INTPTLON: '-082.5542642',
        ELSDLEA: '',
        UNSDLEA: '04685',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7471, 39.827],
            [-82.7041, 39.8512],
            [-82.5967, 39.7912],
            [-82.5976, 39.7729],
            [-82.7146, 39.7296],
            [-82.7853, 39.7339],
            [-82.7915, 39.7343],
            [-82.7471, 39.827],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904686',
        NAME: 'Bloom-Carroll Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137858638,
        AWATER: 317440,
        INTPTLAT: '+39.7822341',
        INTPTLON: '-082.7063278',
        ELSDLEA: '',
        UNSDLEA: '04686',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8097, 39.9832],
            [-82.8083, 39.9833],
            [-82.8046, 39.9837],
            [-82.8003, 39.9842],
            [-82.7969, 39.9846],
            [-82.7958, 39.9847],
            [-82.7613, 39.9657],
            [-82.7688, 39.9391],
            [-82.8174, 39.9267],
            [-82.8248, 39.9271],
            [-82.8097, 39.9832],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904700',
        NAME: 'Reynoldsburg City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30276836,
        AWATER: 212686,
        INTPTLAT: '+39.9558884',
        INTPTLON: '-082.7980819',
        ELSDLEA: '',
        UNSDLEA: '04700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7688, 39.9391],
            [-82.7613, 39.9657],
            [-82.6688, 40.0515],
            [-82.5741, 40.0416],
            [-82.5613, 39.9973],
            [-82.5585, 39.9291],
            [-82.5921, 39.9186],
            [-82.6786, 39.8972],
            [-82.7688, 39.9391],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904804',
        NAME: 'Southwest Licking Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168488033,
        AWATER: 742384,
        INTPTLAT: '+39.9736233',
        INTPTLON: '-082.6563955',
        ELSDLEA: '',
        UNSDLEA: '04804',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1696, 39.7994],
            [-83.0131, 39.8044],
            [-82.9377, 39.801],
            [-82.8526, 39.7965],
            [-82.7915, 39.7343],
            [-82.7853, 39.7339],
            [-82.8344, 39.6494],
            [-82.9135, 39.6525],
            [-82.9803, 39.6552],
            [-83.0761, 39.7063],
            [-83.1696, 39.7994],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904909',
        NAME: 'Teays Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 394739414,
        AWATER: 2988101,
        INTPTLAT: '+39.7355840',
        INTPTLON: '-082.9567056',
        ELSDLEA: '',
        UNSDLEA: '04909',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.682, 41.5458],
            [-83.6064, 41.5803],
            [-83.588, 41.6009],
            [-83.5827, 41.4999],
            [-83.643, 41.4727],
            [-83.6954, 41.4924],
            [-83.682, 41.5458],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904558',
        NAME: 'Perrysburg Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71806128,
        AWATER: 2164953,
        INTPTLAT: '+41.5258171',
        INTPTLON: '-083.6349265',
        ELSDLEA: '',
        UNSDLEA: '04558',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.588, 41.6009],
            [-83.543, 41.6179],
            [-83.5276, 41.6019],
            [-83.5239, 41.5146],
            [-83.5827, 41.4999],
            [-83.588, 41.6009],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904560',
        NAME: 'Rossford Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66298957,
        AWATER: 1328692,
        INTPTLAT: '+41.5397930',
        INTPTLON: '-083.5606462',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.643, 41.4727],
            [-83.5827, 41.4999],
            [-83.5239, 41.5146],
            [-83.4146, 41.5076],
            [-83.4146, 41.5002],
            [-83.4161, 41.3992],
            [-83.4171, 41.3483],
            [-83.4456, 41.3411],
            [-83.5582, 41.3485],
            [-83.5905, 41.4365],
            [-83.6596, 41.4369],
            [-83.643, 41.4727],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905067',
        NAME: 'Eastwood Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 269655304,
        AWATER: 161923,
        INTPTLAT: '+41.4350075',
        INTPTLON: '-083.5065889',
        ELSDLEA: '',
        UNSDLEA: '05067',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9135, 39.6525],
            [-82.8344, 39.6494],
            [-82.843, 39.5615],
            [-82.6201, 39.564],
            [-82.6344, 39.376],
            [-82.7486, 39.3682],
            [-82.7474, 39.3819],
            [-82.75, 39.4681],
            [-82.9982, 39.4801],
            [-82.9926, 39.5064],
            [-82.9188, 39.5804],
            [-82.9135, 39.6525],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904908',
        NAME: 'Logan Elm Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 512222282,
        AWATER: 2452421,
        INTPTLAT: '+39.5032993',
        INTPTLON: '-082.7831436',
        ELSDLEA: '',
        UNSDLEA: '04908',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0793, 40.776],
            [-82.0324, 40.7889],
            [-81.8773, 40.8138],
            [-81.877, 40.7176],
            [-81.9954, 40.6519],
            [-82.1262, 40.7265],
            [-82.0793, 40.776],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905059',
        NAME: 'Triway Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247994768,
        AWATER: 1428538,
        INTPTLAT: '+40.7370294',
        INTPTLON: '-081.9822279',
        ELSDLEA: '',
        UNSDLEA: '05059',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7164, 38.5527],
            [-82.681, 38.55],
            [-82.652, 38.5141],
            [-82.6601, 38.5002],
            [-82.6654, 38.5058],
            [-82.7082, 38.5482],
            [-82.7164, 38.5527],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904414',
        NAME: 'Ironton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10403657,
        AWATER: 758437,
        INTPTLAT: '+38.5305212',
        INTPTLON: '-082.6763972',
        ELSDLEA: '',
        UNSDLEA: '04414',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.49, 38.4733],
            [-82.4951, 38.4152],
            [-82.5083, 38.4112],
            [-82.5935, 38.4218],
            [-82.6015, 38.4427],
            [-82.6133, 38.474],
            [-82.49, 38.4733],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904795',
        NAME: 'South Point Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71255359,
        AWATER: 1091086,
        INTPTLAT: '+38.4444805',
        INTPTLON: '-082.5573950',
        ELSDLEA: '',
        UNSDLEA: '04795',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8112, 41.1342],
            [-80.7383, 41.134],
            [-80.7111, 41.1308],
            [-80.7111, 41.0611],
            [-80.8095, 41.062],
            [-80.8112, 41.1342],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904829',
        NAME: 'Austintown Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61634834,
        AWATER: 3878354,
        INTPTLAT: '+41.0947202',
        INTPTLON: '-080.7607883',
        ELSDLEA: '',
        UNSDLEA: '04829',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8218, 41.0619],
            [-80.8095, 41.062],
            [-80.7111, 41.0611],
            [-80.705, 41.0615],
            [-80.6931, 40.9793],
            [-80.8211, 40.9882],
            [-80.8218, 41.0619],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904831',
        NAME: 'Canfield Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76296193,
        AWATER: 1005137,
        INTPTLAT: '+41.0225407',
        INTPTLON: '-080.7592994',
        ELSDLEA: '',
        UNSDLEA: '04831',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8573, 40.9879],
            [-80.8211, 40.9882],
            [-80.6931, 40.9793],
            [-80.6318, 40.981],
            [-80.6318, 40.9277],
            [-80.7293, 40.9004],
            [-80.7457, 40.9005],
            [-80.8071, 40.9009],
            [-80.8564, 40.93],
            [-80.8573, 40.9879],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904836',
        NAME: 'South Range Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137491697,
        AWATER: 3780205,
        INTPTLAT: '+40.9529739',
        INTPTLON: '-080.7518795',
        ELSDLEA: '',
        UNSDLEA: '04836',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0652, 39.5215],
            [-83.0611, 39.6066],
            [-82.9803, 39.6552],
            [-82.9135, 39.6525],
            [-82.9188, 39.5804],
            [-82.9926, 39.5064],
            [-83.0658, 39.5088],
            [-83.0652, 39.5215],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904376',
        NAME: 'Circleville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104986206,
        AWATER: 1308889,
        INTPTLAT: '+39.5813319',
        INTPTLON: '-082.9887756',
        ELSDLEA: '',
        UNSDLEA: '04376',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0024, 41.0621],
            [-80.8218, 41.0619],
            [-80.8211, 40.9882],
            [-80.8573, 40.9879],
            [-81.0017, 40.9878],
            [-81.0024, 41.0621],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904839',
        NAME: 'Western Reserve Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 121306543,
        AWATER: 5302335,
        INTPTLAT: '+41.0168066',
        INTPTLON: '-080.9202752',
        ELSDLEA: '',
        UNSDLEA: '04839',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9683, 41.5052],
            [-81.8736, 41.4832],
            [-81.8737, 41.4769],
            [-81.9695, 41.478],
            [-81.9683, 41.5052],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904354',
        NAME: 'Bay Village City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11825077,
        AWATER: 0,
        INTPTLAT: '+41.4852506',
        INTPTLON: '-081.9316697',
        ELSDLEA: '',
        UNSDLEA: '04354',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.517, 41.487],
            [-81.5168, 41.5014],
            [-81.4805, 41.5012],
            [-81.4886, 41.4573],
            [-81.532, 41.4645],
            [-81.517, 41.487],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904355',
        NAME: 'Beachwood City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11954714,
        AWATER: 21321,
        INTPTLAT: '+41.4785843',
        INTPTLON: '-081.5025848',
        ELSDLEA: '',
        UNSDLEA: '04355',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5901, 39.4563],
            [-84.505, 39.4328],
            [-84.536, 39.411],
            [-84.5903, 39.4418],
            [-84.5901, 39.4563],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904613',
        NAME: 'New Miami Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17862891,
        AWATER: 524304,
        INTPTLAT: '+39.4462017',
        INTPTLON: '-084.5449318',
        ELSDLEA: '',
        UNSDLEA: '04613',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6009, 41.0642],
            [-80.5684, 41.07],
            [-80.5594, 41.062],
            [-80.5661, 41.0386],
            [-80.6157, 41.0431],
            [-80.6009, 41.0642],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904485',
        NAME: 'Struthers City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13452688,
        AWATER: 500760,
        INTPTLAT: '+41.0516539',
        INTPTLON: '-080.5896406',
        ELSDLEA: '',
        UNSDLEA: '04485',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1914, 41.3476],
            [-81.1906, 41.4256],
            [-81.1899, 41.5004],
            [-81.1005, 41.5716],
            [-81.0985, 41.3475],
            [-81.1137, 41.3474],
            [-81.1914, 41.3476],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904716',
        NAME: 'Berkshire Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170265655,
        AWATER: 3842610,
        INTPTLAT: '+41.4432602',
        INTPTLON: '-081.1444646',
        ELSDLEA: '',
        UNSDLEA: '04716',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3914, 41.4549],
            [-81.3909, 41.5007],
            [-81.391, 41.5697],
            [-81.2959, 41.5699],
            [-81.275, 41.4997],
            [-81.2978, 41.4397],
            [-81.3914, 41.4549],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904722',
        NAME: 'West Geauga Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118744155,
        AWATER: 883844,
        INTPTLAT: '+41.5042318',
        INTPTLON: '-081.3330753',
        ELSDLEA: '',
        UNSDLEA: '04722',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8811, 41.4045],
            [-81.8754, 41.4176],
            [-81.8546, 41.42],
            [-81.7831, 41.4184],
            [-81.7851, 41.3507],
            [-81.877, 41.3507],
            [-81.8811, 41.4045],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904360',
        NAME: 'Berea City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55399594,
        AWATER: 309538,
        INTPTLAT: '+41.3816822',
        INTPTLON: '-081.8339802',
        ELSDLEA: '',
        UNSDLEA: '04360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3614, 39.5206],
            [-81.2627, 39.5768],
            [-81.0368, 39.5721],
            [-81.0387, 39.5399],
            [-81.1215, 39.4578],
            [-81.2117, 39.393],
            [-81.3043, 39.3708],
            [-81.364, 39.3652],
            [-81.3614, 39.5206],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905049',
        NAME: 'Frontier Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418172582,
        AWATER: 3054359,
        INTPTLAT: '+39.4877036',
        INTPTLON: '-081.2191852',
        ELSDLEA: '',
        UNSDLEA: '05049',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3614, 39.5206],
            [-81.364, 39.3652],
            [-81.3043, 39.3708],
            [-81.3713, 39.3419],
            [-81.4834, 39.3892],
            [-81.5163, 39.454],
            [-81.5005, 39.5211],
            [-81.3614, 39.5206],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910019',
        NAME: 'Marietta City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179871747,
        AWATER: 4748105,
        INTPTLAT: '+39.4477535',
        INTPTLON: '-081.4225059',
        ELSDLEA: '',
        UNSDLEA: '10019',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6432, 39.6657],
            [-81.5882, 39.587],
            [-81.3664, 39.6011],
            [-81.2627, 39.5768],
            [-81.3614, 39.5206],
            [-81.5005, 39.5211],
            [-81.5163, 39.454],
            [-81.5756, 39.4411],
            [-81.5693, 39.5269],
            [-81.6969, 39.5867],
            [-81.6432, 39.6657],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905048',
        NAME: 'Fort Frye Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347080817,
        AWATER: 4065095,
        INTPTLAT: '+39.5598985',
        INTPTLON: '-081.5132550',
        ELSDLEA: '',
        UNSDLEA: '05048',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9259, 40.2846],
            [-82.8671, 40.3552],
            [-82.7943, 40.333],
            [-82.7507, 40.277],
            [-82.7559, 40.2068],
            [-82.7713, 40.1264],
            [-82.8514, 40.1297],
            [-82.9127, 40.1796],
            [-82.9259, 40.2846],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904674',
        NAME: 'Big Walnut Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 272383394,
        AWATER: 7752251,
        INTPTLAT: '+40.2314516',
        INTPTLON: '-082.8300022',
        ELSDLEA: '',
        UNSDLEA: '04674',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.226, 40.156],
            [-83.1713, 40.1981],
            [-83.1292, 40.1797],
            [-83.0805, 40.1389],
            [-83.0729, 40.0838],
            [-83.082, 40.0614],
            [-83.1038, 40.0615],
            [-83.1119, 40.0634],
            [-83.1255, 40.067],
            [-83.1448, 40.0721],
            [-83.1464, 40.072],
            [-83.1556, 40.0759],
            [-83.1916, 40.1074],
            [-83.226, 40.156],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904702',
        NAME: 'Dublin City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104414176,
        AWATER: 2766812,
        INTPTLAT: '+40.1289880',
        INTPTLON: '-083.1402839',
        ELSDLEA: '',
        UNSDLEA: '04702',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.525, 40.4653],
            [-83.4184, 40.5052],
            [-83.2709, 40.5069],
            [-83.249, 40.4246],
            [-83.2494, 40.3046],
            [-83.3504, 40.3616],
            [-83.5145, 40.3869],
            [-83.525, 40.4653],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905033',
        NAME: 'North Union Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 408674328,
        AWATER: 3645709,
        INTPTLAT: '+40.4219454',
        INTPTLON: '-083.3619267',
        ELSDLEA: '',
        UNSDLEA: '05033',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6408, 40.4176],
            [-82.5439, 40.4139],
            [-82.4348, 40.4878],
            [-82.3233, 40.526],
            [-82.3237, 40.5207],
            [-82.3269, 40.4605],
            [-82.3683, 40.3146],
            [-82.4812, 40.2951],
            [-82.551, 40.3165],
            [-82.6442, 40.3744],
            [-82.6408, 40.4176],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910012',
        NAME: 'Mount Vernon City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372103466,
        AWATER: 2140851,
        INTPTLAT: '+40.3933593',
        INTPTLON: '-082.4532765',
        ELSDLEA: '',
        UNSDLEA: '10012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7344, 41.4186],
            [-81.7697, 41.4184],
            [-81.7694, 41.4411],
            [-81.7386, 41.4625],
            [-81.7344, 41.4186],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904365',
        NAME: 'Brooklyn City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11001680,
        AWATER: 94388,
        INTPTLAT: '+41.4347398',
        INTPTLON: '-081.7491795',
        ELSDLEA: '',
        UNSDLEA: '04365',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5912, 41.5161],
            [-81.556, 41.5452],
            [-81.5464, 41.5451],
            [-81.5168, 41.5014],
            [-81.517, 41.487],
            [-81.5936, 41.493],
            [-81.5912, 41.5161],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904379',
        NAME: 'Cleveland Heights-University Heights City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25158660,
        AWATER: 46953,
        INTPTLAT: '+41.5073137',
        INTPTLON: '-081.5602802',
        ELSDLEA: '',
        UNSDLEA: '04379',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.556, 41.5452],
            [-81.5912, 41.5161],
            [-81.5904, 41.545],
            [-81.556, 41.5452],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904390',
        NAME: 'East Cleveland City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9219969,
        AWATER: 19407,
        INTPTLAT: '+41.5324531',
        INTPTLON: '-081.5772226',
        ELSDLEA: '',
        UNSDLEA: '04390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8747, 41.4481],
            [-81.8745, 41.4499],
            [-81.832, 41.4632],
            [-81.8321, 41.4647],
            [-81.8191, 41.4569],
            [-81.8546, 41.42],
            [-81.8754, 41.4176],
            [-81.8747, 41.4481],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904397',
        NAME: 'Fairview Park City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11517881,
        AWATER: 0,
        INTPTLAT: '+41.4417673',
        INTPTLON: '-081.8528199',
        ELSDLEA: '',
        UNSDLEA: '04397',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1938, 40.8105],
            [-81.1936, 40.815],
            [-81.0868, 40.8139],
            [-81.0178, 40.8138],
            [-80.9748, 40.727],
            [-81.0434, 40.7274],
            [-81.1193, 40.6477],
            [-81.1986, 40.7292],
            [-81.1938, 40.8105],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904989',
        NAME: 'Minerva Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208397900,
        AWATER: 413855,
        INTPTLAT: '+40.7583375',
        INTPTLON: '-081.1047266',
        ELSDLEA: '',
        UNSDLEA: '04989',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8621, 40.5956],
            [-80.6703, 40.5825],
            [-80.668, 40.5825],
            [-80.6136, 40.4957],
            [-80.636, 40.4542],
            [-80.6114, 40.4371],
            [-80.6143, 40.4087],
            [-80.6755, 40.3841],
            [-80.8665, 40.3926],
            [-80.9419, 40.4247],
            [-80.9209, 40.5563],
            [-80.8621, 40.5956],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904779',
        NAME: 'Edison Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 538352749,
        AWATER: 2559901,
        INTPTLAT: '+40.4838265',
        INTPTLON: '-080.7691895',
        ELSDLEA: '',
        UNSDLEA: '04779',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6357, 41.4342],
            [-81.5679, 41.4243],
            [-81.5847, 41.3846],
            [-81.6357, 41.4342],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904404',
        NAME: 'Garfield Heights City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17846200,
        AWATER: 146545,
        INTPTLAT: '+41.4197755',
        INTPTLON: '-081.6039420',
        ELSDLEA: '',
        UNSDLEA: '04404',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.837, 41.4899],
            [-81.7686, 41.4916],
            [-81.8321, 41.4647],
            [-81.832, 41.4632],
            [-81.837, 41.4899],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904419',
        NAME: 'Lakewood City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14289680,
        AWATER: 47937,
        INTPTLAT: '+41.4828218',
        INTPTLON: '-081.8003650',
        ELSDLEA: '',
        UNSDLEA: '04419',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5874, 41.2776],
            [-81.5651, 41.2778],
            [-81.5241, 41.2702],
            [-81.4894, 41.1935],
            [-81.4897, 41.1604],
            [-81.5194, 41.1359],
            [-81.5874, 41.1366],
            [-81.5874, 41.2776],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904997',
        NAME: 'Woodridge Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106527522,
        AWATER: 322565,
        INTPTLAT: '+41.2068754',
        INTPTLON: '-081.5495430',
        ELSDLEA: '',
        UNSDLEA: '04997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6871, 41.128],
            [-81.5973, 41.136],
            [-81.5961, 41.136],
            [-81.5954, 41.136],
            [-81.5991, 41.0624],
            [-81.6699, 41.0623],
            [-81.6871, 41.128],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904998',
        NAME: 'Copley-Fairlawn City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57467861,
        AWATER: 928918,
        INTPTLAT: '+41.1006030',
        INTPTLON: '-081.6317025',
        ELSDLEA: '',
        UNSDLEA: '04998',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5325, 40.9733],
            [-81.5011, 40.9937],
            [-81.4163, 40.9885],
            [-81.4178, 40.9506],
            [-81.4198, 40.9176],
            [-81.5364, 40.9103],
            [-81.5325, 40.9733],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905001',
        NAME: 'Green Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 82357772,
        AWATER: 3831137,
        INTPTLAT: '+40.9538164',
        INTPTLON: '-081.4720417',
        ELSDLEA: '',
        UNSDLEA: '05001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.23, 41.6169],
            [-84.2285, 41.617],
            [-84.192, 41.4858],
            [-84.2115, 41.4858],
            [-84.2684, 41.5869],
            [-84.23, 41.6169],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904707',
        NAME: 'Pettisville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95258898,
        AWATER: 210296,
        INTPTLAT: '+41.5408899',
        INTPTLON: '-084.2237590',
        ELSDLEA: '',
        UNSDLEA: '04707',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1252, 41.4862],
            [-84.0765, 41.4861],
            [-83.9309, 41.5158],
            [-83.8829, 41.5159],
            [-83.8829, 41.4788],
            [-83.9264, 41.4173],
            [-84.0074, 41.3926],
            [-84.1151, 41.4138],
            [-84.1252, 41.4862],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904758',
        NAME: 'Liberty Center Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187381961,
        AWATER: 3370167,
        INTPTLAT: '+41.4541014',
        INTPTLON: '-084.0038356',
        ELSDLEA: '',
        UNSDLEA: '04758',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5998, 40.3376],
            [-84.5714, 40.3535],
            [-84.5193, 40.3162],
            [-84.4726, 40.3291],
            [-84.4402, 40.3252],
            [-84.4341, 40.2921],
            [-84.4231, 40.2065],
            [-84.471, 40.1791],
            [-84.5894, 40.1924],
            [-84.5998, 40.3376],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904563',
        NAME: 'Versailles Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196363260,
        AWATER: 479332,
        INTPTLAT: '+40.2568930',
        INTPTLON: '-084.5090658',
        ELSDLEA: '',
        UNSDLEA: '04563',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3173, 40.4233],
            [-84.2508, 40.4818],
            [-84.2306, 40.482],
            [-84.107, 40.4324],
            [-84.1037, 40.3757],
            [-84.2086, 40.3308],
            [-84.2524, 40.3538],
            [-84.3082, 40.389],
            [-84.3173, 40.4233],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910004',
        NAME: 'Anna Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176019334,
        AWATER: 677614,
        INTPTLAT: '+40.3997108',
        INTPTLON: '-084.2133438',
        ELSDLEA: '',
        UNSDLEA: '10004',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.692, 39.2714],
            [-83.6631, 39.2713],
            [-83.5151, 39.2567],
            [-83.4209, 39.177],
            [-83.4599, 39.08],
            [-83.7379, 39.1309],
            [-83.692, 39.2714],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904412',
        NAME: 'Hillsboro City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 384089178,
        AWATER: 8267399,
        INTPTLAT: '+39.1718894',
        INTPTLON: '-083.5944786',
        ELSDLEA: '',
        UNSDLEA: '04412',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8722, 39.0213],
            [-83.8193, 39.0202],
            [-83.673, 39.0204],
            [-83.6949, 38.776],
            [-83.8458, 38.8307],
            [-83.8738, 38.9512],
            [-83.8722, 39.0213],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904603',
        NAME: 'Eastern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 369342388,
        AWATER: 1451508,
        INTPTLAT: '+38.9125765',
        INTPTLON: '-083.7614028',
        ELSDLEA: '',
        UNSDLEA: '04603',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8193, 39.0202],
            [-83.7379, 39.1309],
            [-83.4599, 39.08],
            [-83.4209, 39.177],
            [-83.3564, 39.1847],
            [-83.3678, 39.1313],
            [-83.5223, 39.024],
            [-83.673, 39.0204],
            [-83.8193, 39.0202],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904761',
        NAME: 'Bright Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305017050,
        AWATER: 275223,
        INTPTLAT: '+39.0803044',
        INTPTLON: '-083.5995950',
        ELSDLEA: '',
        UNSDLEA: '04761',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6949, 38.776],
            [-83.673, 39.0204],
            [-83.5223, 39.024],
            [-83.3678, 39.1313],
            [-83.3089, 39.0413],
            [-83.2708, 39.0158],
            [-83.2682, 38.7428],
            [-83.2682, 38.7099],
            [-83.6962, 38.7617],
            [-83.6949, 38.776],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3906190',
        NAME: 'Adams County/Ohio Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1265705310,
        AWATER: 2450960,
        INTPTLAT: '+38.8866259',
        INTPTLON: '-083.4709530',
        ELSDLEA: '',
        UNSDLEA: '06190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9619, 40.553],
            [-82.8977, 40.5502],
            [-82.7732, 40.4704],
            [-82.8694, 40.4275],
            [-83.0168, 40.4843],
            [-82.9619, 40.553],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904879',
        NAME: 'Cardington-Lincoln Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183757379,
        AWATER: 33488,
        INTPTLAT: '+40.4884087',
        INTPTLON: '-082.8985888',
        ELSDLEA: '',
        UNSDLEA: '04879',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6428, 40.55],
            [-82.5974, 40.55],
            [-82.4516, 40.5508],
            [-82.4348, 40.4878],
            [-82.5439, 40.4139],
            [-82.6408, 40.4176],
            [-82.6428, 40.55],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904785',
        NAME: 'Fredericktown Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 213047483,
        AWATER: 3537351,
        INTPTLAT: '+40.4896239',
        INTPTLON: '-082.5510661',
        ELSDLEA: '',
        UNSDLEA: '04785',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1419, 40.4837],
            [-83.1076, 40.5714],
            [-83.1313, 40.6159],
            [-82.9757, 40.7035],
            [-82.8583, 40.705],
            [-82.8899, 40.6387],
            [-82.9619, 40.553],
            [-83.0168, 40.4843],
            [-83.1137, 40.437],
            [-83.1419, 40.4837],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904844',
        NAME: 'River Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314034797,
        AWATER: 193422,
        INTPTLAT: '+40.5918991',
        INTPTLON: '-083.0038041',
        ELSDLEA: '',
        UNSDLEA: '04844',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5679, 41.4243],
            [-81.5543, 41.4243],
            [-81.5274, 41.4242],
            [-81.5847, 41.3846],
            [-81.5679, 41.4243],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904430',
        NAME: 'Maple Heights City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13393037,
        AWATER: 809,
        INTPTLAT: '+41.4090472',
        INTPTLON: '-081.5628608',
        ELSDLEA: '',
        UNSDLEA: '04430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9703, 41.411],
            [-81.8747, 41.4481],
            [-81.8754, 41.4176],
            [-81.8811, 41.4045],
            [-81.9705, 41.3867],
            [-81.9703, 41.411],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904452',
        NAME: 'North Olmsted City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29833958,
        AWATER: 0,
        INTPTLAT: '+41.4153092',
        INTPTLON: '-081.9188674',
        ELSDLEA: '',
        UNSDLEA: '04452',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7851, 41.3507],
            [-81.7051, 41.3509],
            [-81.6705, 41.2773],
            [-81.685, 41.2771],
            [-81.7582, 41.2767],
            [-81.7851, 41.2764],
            [-81.7851, 41.3507],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904454',
        NAME: 'North Royalton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65723787,
        AWATER: 43393,
        INTPTLAT: '+41.3093896',
        INTPTLON: '-081.7381419',
        ELSDLEA: '',
        UNSDLEA: '04454',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4089, 39.2238],
            [-84.3831, 39.2173],
            [-84.3852, 39.1995],
            [-84.4135, 39.2203],
            [-84.4089, 39.2238],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904385',
        NAME: 'Deer Park Community City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6503705,
        AWATER: 0,
        INTPTLAT: '+39.2108985',
        INTPTLON: '-084.3965983',
        ELSDLEA: '',
        UNSDLEA: '04385',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4648, 39.236],
            [-84.444, 39.2355],
            [-84.4552, 39.2115],
            [-84.4658, 39.2179],
            [-84.4648, 39.236],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904423',
        NAME: 'Lockland Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4243514,
        AWATER: 0,
        INTPTLAT: '+39.2265076',
        INTPTLON: '-084.4554744',
        ELSDLEA: '',
        UNSDLEA: '04423',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.229, 41.6384],
            [-84.1124, 41.6405],
            [-84.0765, 41.4861],
            [-84.1252, 41.4862],
            [-84.192, 41.4858],
            [-84.2285, 41.617],
            [-84.229, 41.6384],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904564',
        NAME: 'Wauseon Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140986738,
        AWATER: 559267,
        INTPTLAT: '+41.5666592',
        INTPTLON: '-084.1424675',
        ELSDLEA: '',
        UNSDLEA: '04564',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2213, 41.7103],
            [-84.0767, 41.7149],
            [-83.9577, 41.718],
            [-83.8061, 41.7224],
            [-83.7814, 41.723],
            [-83.7798, 41.6582],
            [-83.807, 41.6579],
            [-83.9503, 41.5887],
            [-84.0063, 41.6565],
            [-84.1124, 41.6405],
            [-84.229, 41.6384],
            [-84.2213, 41.7103],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904705',
        NAME: 'Evergreen Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 336453459,
        AWATER: 1016313,
        INTPTLAT: '+41.6730026',
        INTPTLON: '-083.9880561',
        ELSDLEA: '',
        UNSDLEA: '04705',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1515, 40.7459],
            [-84.1087, 40.7648],
            [-84.0798, 40.7306],
            [-84.1083, 40.7095],
            [-84.1515, 40.7459],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904422',
        NAME: 'Lima City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22651097,
        AWATER: 389604,
        INTPTLAT: '+40.7396414',
        INTPTLON: '-084.1120755',
        ELSDLEA: '',
        UNSDLEA: '04422',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0407, 40.8611],
            [-83.9943, 40.818],
            [-84.0005, 40.7311],
            [-84.0798, 40.7306],
            [-84.1087, 40.7648],
            [-84.1096, 40.861],
            [-84.0407, 40.8611],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904576',
        NAME: 'Bath Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115241590,
        AWATER: 3660680,
        INTPTLAT: '+40.7970800',
        INTPTLON: '-084.0602144',
        ELSDLEA: '',
        UNSDLEA: '04576',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3237, 40.5207],
            [-82.1791, 40.5731],
            [-82.1621, 40.4554],
            [-82.1869, 40.4061],
            [-82.3269, 40.4605],
            [-82.3237, 40.5207],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904783',
        NAME: 'Danville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198154455,
        AWATER: 996240,
        INTPTLAT: '+40.4910828',
        INTPTLON: '-082.2428085',
        ELSDLEA: '',
        UNSDLEA: '04783',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8305, 41.2589],
            [-80.8108, 41.2577],
            [-80.7939, 41.2127],
            [-80.8193, 41.2023],
            [-80.8481, 41.2031],
            [-80.8305, 41.2589],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904499',
        NAME: 'Warren City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41075087,
        AWATER: 72664,
        INTPTLAT: '+41.2344031',
        INTPTLON: '-080.8208746',
        ELSDLEA: '',
        UNSDLEA: '04499',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0031, 41.4259],
            [-80.8109, 41.4252],
            [-80.811, 41.3487],
            [-80.9054, 41.3482],
            [-81.0032, 41.3479],
            [-81.0033, 41.3479],
            [-81.0031, 41.4259],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905011',
        NAME: 'Bristol Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138235556,
        AWATER: 165672,
        INTPTLAT: '+41.3766089',
        INTPTLON: '-080.9087140',
        ELSDLEA: '',
        UNSDLEA: '05011',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7135, 41.4998],
            [-80.7132, 41.4998],
            [-80.5192, 41.4999],
            [-80.5191, 41.4407],
            [-80.5191, 41.361],
            [-80.5191, 41.2729],
            [-80.6173, 41.2724],
            [-80.6178, 41.3503],
            [-80.6185, 41.4247],
            [-80.7124, 41.4253],
            [-80.7135, 41.4998],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905017',
        NAME: 'Joseph Badger Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 265028187,
        AWATER: 8791547,
        INTPTLAT: '+41.4054238',
        INTPTLON: '-080.5872878',
        ELSDLEA: '',
        UNSDLEA: '05017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7417, 41.166],
            [-80.712, 41.168],
            [-80.7328, 41.1511],
            [-80.7417, 41.166],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905022',
        NAME: 'McDonald Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5807550,
        AWATER: 0,
        INTPTLAT: '+41.1629210',
        INTPTLON: '-080.7265173',
        ELSDLEA: '',
        UNSDLEA: '05022',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0029, 41.2718],
            [-80.9059, 41.2722],
            [-80.825, 41.2721],
            [-80.8305, 41.2589],
            [-80.8481, 41.2031],
            [-80.906, 41.203],
            [-81.0026, 41.2026],
            [-81.0028, 41.2295],
            [-81.0029, 41.2718],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905024',
        NAME: 'LaBrae Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94096320,
        AWATER: 694068,
        INTPTLAT: '+41.2403256',
        INTPTLON: '-080.9250756',
        ELSDLEA: '',
        UNSDLEA: '05024',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5182, 39.304],
            [-84.5128, 39.3048],
            [-84.482, 39.2426],
            [-84.5003, 39.2291],
            [-84.518, 39.2341],
            [-84.5642, 39.2797],
            [-84.5182, 39.304],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904408',
        NAME: 'Winton Woods City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29916998,
        AWATER: 784082,
        INTPTLAT: '+39.2689782',
        INTPTLON: '-084.5188022',
        ELSDLEA: '',
        UNSDLEA: '04408',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5003, 39.2291],
            [-84.482, 39.2426],
            [-84.4648, 39.236],
            [-84.4658, 39.2179],
            [-84.4924, 39.2146],
            [-84.5003, 39.2291],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904514',
        NAME: 'Wyoming City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7347103,
        AWATER: 0,
        INTPTLAT: '+39.2293711',
        INTPTLON: '-084.4812977',
        ELSDLEA: '',
        UNSDLEA: '04514',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3921, 39.1365],
            [-84.3018, 39.1526],
            [-84.3042, 39.1324],
            [-84.3168, 39.0462],
            [-84.32, 39.0215],
            [-84.3693, 39.043],
            [-84.4235, 39.0519],
            [-84.3921, 39.1365],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904734',
        NAME: 'Forest Hills Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83610208,
        AWATER: 2638544,
        INTPTLAT: '+39.0906501',
        INTPTLON: '-084.3535555',
        ELSDLEA: '',
        UNSDLEA: '04734',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3905, 39.1929],
            [-84.37, 39.1915],
            [-84.3808, 39.1628],
            [-84.3905, 39.1929],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904428',
        NAME: 'Madeira City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8740397,
        AWATER: 12068,
        INTPTLAT: '+39.1851046',
        INTPTLON: '-084.3731061',
        ELSDLEA: '',
        UNSDLEA: '04428',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5684, 39.2223],
            [-84.5304, 39.2229],
            [-84.5311, 39.2168],
            [-84.5691, 39.206],
            [-84.5684, 39.2223],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904451',
        NAME: 'North College Hill City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5384804,
        AWATER: 0,
        INTPTLAT: '+39.2161793',
        INTPTLON: '-084.5527339',
        ELSDLEA: '',
        UNSDLEA: '04451',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9234, 39.0889],
            [-82.7947, 39.0841],
            [-82.8065, 38.948],
            [-82.9021, 38.9211],
            [-82.9556, 38.9547],
            [-82.9234, 39.0889],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904912',
        NAME: 'Eastern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 220705413,
        AWATER: 233780,
        INTPTLAT: '+39.0009429',
        INTPTLON: '-082.8645891',
        ELSDLEA: '',
        UNSDLEA: '04912',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8032, 38.7535],
            [-82.8024, 38.7701],
            [-82.7605, 38.8549],
            [-82.65, 38.8491],
            [-82.6591, 38.7627],
            [-82.7662, 38.6447],
            [-82.8085, 38.7017],
            [-82.8032, 38.7535],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904959',
        NAME: 'Bloom-Vernon Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215787013,
        AWATER: 627580,
        INTPTLAT: '+38.7712624',
        INTPTLON: '-082.7362032',
        ELSDLEA: '',
        UNSDLEA: '04959',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8746, 38.6827],
            [-82.8085, 38.7017],
            [-82.7662, 38.6447],
            [-82.816, 38.5708],
            [-82.8746, 38.6827],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904961',
        NAME: 'Green Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 99399798,
        AWATER: 2299744,
        INTPTLAT: '+38.6392429',
        INTPTLON: '-082.8062877',
        ELSDLEA: '',
        UNSDLEA: '04961',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0436, 38.9569],
            [-82.9556, 38.9547],
            [-82.9021, 38.9211],
            [-82.9077, 38.8316],
            [-83.0184, 38.834],
            [-83.0436, 38.9569],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904964',
        NAME: 'Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124650693,
        AWATER: 1384781,
        INTPTLAT: '+38.8976951',
        INTPTLON: '-082.9601845',
        ELSDLEA: '',
        UNSDLEA: '04964',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0184, 38.834],
            [-82.9077, 38.8316],
            [-82.9268, 38.7739],
            [-83.0116, 38.73],
            [-83.0129, 38.7305],
            [-83.0094, 38.7923],
            [-83.0184, 38.834],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910026',
        NAME: 'Clay Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55990158,
        AWATER: 1210055,
        INTPTLAT: '+38.7934794',
        INTPTLON: '-082.9670498',
        ELSDLEA: '',
        UNSDLEA: '10026',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8035, 41.2526],
            [-84.6893, 41.2524],
            [-84.65, 41.2526],
            [-84.6883, 41.1334],
            [-84.8035, 41.1359],
            [-84.8035, 41.2526],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904899',
        NAME: 'Antwerp Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166560253,
        AWATER: 1834272,
        INTPTLAT: '+41.1962196',
        INTPTLON: '-084.7208677',
        ELSDLEA: '',
        UNSDLEA: '04899',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8035, 41.1359],
            [-84.6883, 41.1334],
            [-84.4664, 41.0921],
            [-84.3421, 41.0424],
            [-84.3421, 41.0377],
            [-84.3801, 40.9689],
            [-84.3991, 40.9653],
            [-84.5726, 40.9826],
            [-84.8033, 40.9894],
            [-84.8035, 41.1359],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904903',
        NAME: 'Wayne Trace Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 456026623,
        AWATER: 52417,
        INTPTLAT: '+41.0454784',
        INTPTLON: '-084.6072511',
        ELSDLEA: '',
        UNSDLEA: '04903',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8144, 39.7266],
            [-84.8144, 39.7436],
            [-84.7036, 39.7436],
            [-84.6896, 39.8232],
            [-84.595, 39.8256],
            [-84.5878, 39.802],
            [-84.6137, 39.6708],
            [-84.7038, 39.6842],
            [-84.6966, 39.5675],
            [-84.7464, 39.567],
            [-84.8148, 39.616],
            [-84.8144, 39.7266],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904393',
        NAME: 'Eaton Community City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 298515595,
        AWATER: 1883026,
        INTPTLAT: '+39.7043677',
        INTPTLON: '-084.7087776',
        ELSDLEA: '',
        UNSDLEA: '04393',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5915, 39.5675],
            [-84.4791, 39.5738],
            [-84.4391, 39.4887],
            [-84.4255, 39.4732],
            [-84.4643, 39.4337],
            [-84.4745, 39.4297],
            [-84.505, 39.4328],
            [-84.5901, 39.4563],
            [-84.5915, 39.5675],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904609',
        NAME: 'Edgewood City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161841753,
        AWATER: 1017969,
        INTPTLAT: '+39.4953182',
        INTPTLON: '-084.5201958',
        ELSDLEA: '',
        UNSDLEA: '04609',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4805, 39.671],
            [-84.4814, 39.7228],
            [-84.3664, 39.7144],
            [-84.3339, 39.6582],
            [-84.3275, 39.6148],
            [-84.3652, 39.5895],
            [-84.4792, 39.591],
            [-84.4805, 39.671],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904874',
        NAME: 'Valley View Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158523951,
        AWATER: 420651,
        INTPTLAT: '+39.6514745',
        INTPTLON: '-084.4025699',
        ELSDLEA: '',
        UNSDLEA: '04874',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5962, 39.9167],
            [-84.5058, 39.9262],
            [-84.4454, 39.9263],
            [-84.4453, 39.8745],
            [-84.4831, 39.8093],
            [-84.5878, 39.802],
            [-84.595, 39.8256],
            [-84.5962, 39.9167],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904926',
        NAME: 'Tri-County North Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146927493,
        AWATER: 392382,
        INTPTLAT: '+39.8767392',
        INTPTLON: '-084.5221012',
        ELSDLEA: '',
        UNSDLEA: '04926',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6137, 39.6708],
            [-84.4805, 39.671],
            [-84.4792, 39.591],
            [-84.4791, 39.5738],
            [-84.5915, 39.5675],
            [-84.6966, 39.5675],
            [-84.7038, 39.6842],
            [-84.6137, 39.6708],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904928',
        NAME: 'Preble Shawnee Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208427990,
        AWATER: 1726991,
        INTPTLAT: '+39.6167796',
        INTPTLON: '-084.6012034',
        ELSDLEA: '',
        UNSDLEA: '04928',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4792, 39.591],
            [-84.3652, 39.5895],
            [-84.3647, 39.5438],
            [-84.4391, 39.4887],
            [-84.4791, 39.5738],
            [-84.4792, 39.591],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904612',
        NAME: 'Madison Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 81258375,
        AWATER: 432465,
        INTPTLAT: '+39.5568851',
        INTPTLON: '-084.4201583',
        ELSDLEA: '',
        UNSDLEA: '04612',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2524, 39.6954],
            [-83.2009, 39.8107],
            [-83.1696, 39.7994],
            [-83.0761, 39.7063],
            [-82.9803, 39.6552],
            [-83.0611, 39.6066],
            [-83.0652, 39.5215],
            [-83.2232, 39.5192],
            [-83.2612, 39.5819],
            [-83.2524, 39.6954],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904910',
        NAME: 'Westfall Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 510077605,
        AWATER: 7306328,
        INTPTLAT: '+39.6385324',
        INTPTLON: '-083.1374141',
        ELSDLEA: '',
        UNSDLEA: '04910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4736, 39.162],
            [-84.4353, 39.1701],
            [-84.4428, 39.1448],
            [-84.4736, 39.162],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904457',
        NAME: 'Norwood City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8134329,
        AWATER: 0,
        INTPTLAT: '+39.1604873',
        INTPTLON: '-084.4534509',
        ELSDLEA: '',
        UNSDLEA: '04457',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4552, 39.2115],
            [-84.444, 39.2355],
            [-84.4152, 39.2338],
            [-84.4089, 39.2238],
            [-84.4135, 39.2203],
            [-84.4552, 39.2115],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904469',
        NAME: 'Reading Community City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7840988,
        AWATER: 0,
        INTPTLAT: '+39.2238080',
        INTPTLON: '-084.4336570',
        ELSDLEA: '',
        UNSDLEA: '04469',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5074, 39.1643],
            [-84.5052, 39.1801],
            [-84.4913, 39.1895],
            [-84.4832, 39.163],
            [-84.5074, 39.1643],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904471',
        NAME: 'St. Bernard-Elmwood Place City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4835250,
        AWATER: 31671,
        INTPTLAT: '+39.1735260',
        INTPTLON: '-084.4932939',
        ELSDLEA: '',
        UNSDLEA: '04471',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3758, 40.6507],
            [-83.3047, 40.702],
            [-83.1688, 40.7023],
            [-83.1883, 40.7698],
            [-83.0828, 40.7029],
            [-83.0044, 40.7032],
            [-82.9757, 40.7035],
            [-83.1313, 40.6159],
            [-83.1591, 40.5893],
            [-83.3231, 40.6092],
            [-83.3758, 40.6507],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904843',
        NAME: 'Ridgedale Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315056421,
        AWATER: 169841,
        INTPTLAT: '+40.6659858',
        INTPTLON: '-083.1808866',
        ELSDLEA: '',
        UNSDLEA: '04843',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3394, 40.9019],
            [-82.1634, 40.8717],
            [-82.1452, 40.8359],
            [-82.3819, 40.7885],
            [-82.4168, 40.8146],
            [-82.416, 40.9019],
            [-82.3394, 40.9019],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904350',
        NAME: 'Ashland City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196822214,
        AWATER: 897968,
        INTPTLAT: '+40.8577795',
        INTPTLON: '-082.2976426',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3178, 40.7265],
            [-82.1273, 40.7268],
            [-82.1791, 40.5731],
            [-82.3237, 40.5207],
            [-82.3233, 40.526],
            [-82.3846, 40.6385],
            [-82.3178, 40.7265],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904546',
        NAME: 'Loudonville-Perrysville Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 303352206,
        AWATER: 3761408,
        INTPTLAT: '+40.6412564',
        INTPTLON: '-082.2559427',
        ELSDLEA: '',
        UNSDLEA: '04546',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0736, 41.0808],
            [-82.9607, 41.0811],
            [-82.9587, 40.9937],
            [-82.8296, 40.9996],
            [-82.7625, 40.9958],
            [-82.7149, 40.9429],
            [-82.725, 40.8433],
            [-82.7636, 40.8433],
            [-82.8225, 40.9079],
            [-82.9401, 40.9067],
            [-82.9779, 40.9866],
            [-83.0742, 40.9936],
            [-83.0736, 41.0808],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904650',
        NAME: 'Buckeye Central Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347333922,
        AWATER: 486947,
        INTPTLAT: '+40.9612604',
        INTPTLON: '-082.8878795',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.646, 41.1407],
            [-82.6389, 41.1452],
            [-82.5582, 41.148],
            [-82.5384, 41.1414],
            [-82.5366, 41.0665],
            [-82.4368, 41.0624],
            [-82.4329, 40.9929],
            [-82.4961, 40.9835],
            [-82.6312, 41.0054],
            [-82.646, 41.1407],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904773',
        NAME: 'South Central Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219996302,
        AWATER: 794479,
        INTPTLAT: '+41.0603009',
        INTPTLON: '-082.5523136',
        ELSDLEA: '',
        UNSDLEA: '04773',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6074, 40.6301],
            [-82.4918, 40.6715],
            [-82.4603, 40.6765],
            [-82.3846, 40.6385],
            [-82.3233, 40.526],
            [-82.4348, 40.4878],
            [-82.4516, 40.5508],
            [-82.5974, 40.55],
            [-82.6074, 40.6301],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904941',
        NAME: 'Clear Fork Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278359688,
        AWATER: 1573176,
        INTPTLAT: '+40.5897191',
        INTPTLON: '-082.4672184',
        ELSDLEA: '',
        UNSDLEA: '04941',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.571, 40.8138],
            [-82.4168, 40.8146],
            [-82.3819, 40.7885],
            [-82.3784, 40.7342],
            [-82.4603, 40.6765],
            [-82.4918, 40.6715],
            [-82.5315, 40.7231],
            [-82.5709, 40.8089],
            [-82.571, 40.8138],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904945',
        NAME: 'Madison Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 122014942,
        AWATER: 2846345,
        INTPTLAT: '+40.7627152',
        INTPTLON: '-082.4626740',
        ELSDLEA: '',
        UNSDLEA: '04945',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7625, 40.9958],
            [-82.6312, 41.0054],
            [-82.4961, 40.9835],
            [-82.5604, 40.9076],
            [-82.7149, 40.9429],
            [-82.7625, 40.9958],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904946',
        NAME: 'Plymouth-Shiloh Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168405580,
        AWATER: 875424,
        INTPTLAT: '+40.9595029',
        INTPTLON: '-082.6147559',
        ELSDLEA: '',
        UNSDLEA: '04946',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7266, 40.7554],
            [-82.6866, 40.8137],
            [-82.571, 40.8138],
            [-82.5709, 40.8089],
            [-82.5707, 40.7249],
            [-82.6891, 40.7187],
            [-82.7266, 40.7554],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904947',
        NAME: 'Ontario Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102538371,
        AWATER: 202263,
        INTPTLAT: '+40.7675980',
        INTPTLON: '-082.6415436',
        ELSDLEA: '',
        UNSDLEA: '04947',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4643, 39.4337],
            [-84.4255, 39.4732],
            [-84.338, 39.4782],
            [-84.3398, 39.4275],
            [-84.4643, 39.4337],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3900094',
        NAME: 'Monroe Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45665058,
        AWATER: 281991,
        INTPTLAT: '+39.4455733',
        INTPTLON: '-084.3718053',
        ELSDLEA: '',
        UNSDLEA: '00094',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2972, 39.2917],
            [-84.2627, 39.2884],
            [-84.2325, 39.2648],
            [-84.224, 39.232],
            [-84.3026, 39.234],
            [-84.3001, 39.2347],
            [-84.2972, 39.2917],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904427',
        NAME: 'Loveland City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40583367,
        AWATER: 617964,
        INTPTLAT: '+39.2586567',
        INTPTLON: '-084.2659167',
        ELSDLEA: '',
        UNSDLEA: '04427',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5128, 39.3048],
            [-84.4832, 39.3017],
            [-84.352, 39.3069],
            [-84.3341, 39.2933],
            [-84.4152, 39.2338],
            [-84.444, 39.2355],
            [-84.4648, 39.236],
            [-84.482, 39.2426],
            [-84.5128, 39.3048],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904467',
        NAME: 'Princeton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75025311,
        AWATER: 199206,
        INTPTLAT: '+39.2768363',
        INTPTLON: '-084.4232698',
        ELSDLEA: '',
        UNSDLEA: '04467',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.093, 39.2246],
            [-84.0461, 39.2575],
            [-84.0041, 39.3386],
            [-83.9008, 39.3478],
            [-83.8436, 39.2701],
            [-83.8657, 39.2473],
            [-83.9647, 39.2518],
            [-83.9975, 39.1929],
            [-84.093, 39.2246],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904638',
        NAME: 'Blanchester Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180338517,
        AWATER: 1558712,
        INTPTLAT: '+39.2775259',
        INTPTLON: '-083.9636550',
        ELSDLEA: '',
        UNSDLEA: '04638',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2678, 39.3998],
            [-84.1836, 39.3771],
            [-84.2627, 39.2884],
            [-84.2972, 39.2917],
            [-84.3052, 39.2919],
            [-84.2678, 39.3998],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905043',
        NAME: 'Kings Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54469239,
        AWATER: 1040795,
        INTPTLAT: '+39.3467868',
        INTPTLON: '-084.2526471',
        ELSDLEA: '',
        UNSDLEA: '05043',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1535, 39.5806],
            [-84.1133, 39.5887],
            [-84.0564, 39.5799],
            [-83.9794, 39.5441],
            [-84.0938, 39.4682],
            [-84.1529, 39.4789],
            [-84.1535, 39.5806],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905046',
        NAME: 'Wayne Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126892990,
        AWATER: 3432063,
        INTPTLAT: '+39.5307143',
        INTPTLON: '-084.0794917',
        ELSDLEA: '',
        UNSDLEA: '05046',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2741, 39.5626],
            [-82.1592, 39.5566],
            [-82.1674, 39.4663],
            [-82.191, 39.3787],
            [-82.2897, 39.3842],
            [-82.2741, 39.5626],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904444',
        NAME: 'Nelsonville-York City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195716263,
        AWATER: 1455834,
        INTPTLAT: '+39.4735819',
        INTPTLON: '-082.2201245',
        ELSDLEA: '',
        UNSDLEA: '04444',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5311, 39.2168],
            [-84.5304, 39.2229],
            [-84.518, 39.2341],
            [-84.5003, 39.2291],
            [-84.4924, 39.2146],
            [-84.5311, 39.2168],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904733',
        NAME: 'Finneytown Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9633657,
        AWATER: 0,
        INTPTLAT: '+39.2151279',
        INTPTLON: '-084.5128512',
        ELSDLEA: '',
        UNSDLEA: '04733',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6418, 39.305],
            [-84.6067, 39.3182],
            [-84.5182, 39.304],
            [-84.5642, 39.2797],
            [-84.5684, 39.2223],
            [-84.5691, 39.206],
            [-84.6105, 39.1707],
            [-84.6819, 39.211],
            [-84.6984, 39.2117],
            [-84.6418, 39.305],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904736',
        NAME: 'Northwest Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 132874095,
        AWATER: 1121583,
        INTPTLAT: '+39.2506803',
        INTPTLON: '-084.6165745',
        ELSDLEA: '',
        UNSDLEA: '04736',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3721, 39.1523],
            [-84.3025, 39.1679],
            [-84.3018, 39.1526],
            [-84.3921, 39.1365],
            [-84.3721, 39.1523],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904431',
        NAME: 'Mariemont City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9302458,
        AWATER: 447239,
        INTPTLAT: '+39.1435767',
        INTPTLON: '-084.3783180',
        ELSDLEA: '',
        UNSDLEA: '04431',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3852, 39.1995],
            [-84.3831, 39.2173],
            [-84.3001, 39.2347],
            [-84.3026, 39.234],
            [-84.3025, 39.1679],
            [-84.3721, 39.1523],
            [-84.3808, 39.1628],
            [-84.37, 39.1915],
            [-84.3905, 39.1929],
            [-84.3852, 39.1995],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904543',
        NAME: 'Indian Hill Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59340922,
        AWATER: 614629,
        INTPTLAT: '+39.1885572',
        INTPTLON: '-084.3360212',
        ELSDLEA: '',
        UNSDLEA: '04543',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8108, 40.2814],
            [-81.8217, 40.253],
            [-81.8706, 40.2511],
            [-81.8513, 40.2838],
            [-81.8108, 40.2814],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904382',
        NAME: 'Coshocton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26114483,
        AWATER: 710973,
        INTPTLAT: '+40.2708952',
        INTPTLON: '-081.8449620',
        ELSDLEA: '',
        UNSDLEA: '04382',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8108, 40.2814],
            [-81.7898, 40.3953],
            [-81.6807, 40.3701],
            [-81.6141, 40.4101],
            [-81.616, 40.3681],
            [-81.6491, 40.2958],
            [-81.6494, 40.1504],
            [-81.6844, 40.0991],
            [-81.8094, 40.1552],
            [-81.8217, 40.253],
            [-81.8108, 40.2814],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904647',
        NAME: 'Ridgewood Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 393449416,
        AWATER: 2863385,
        INTPTLAT: '+40.2649105',
        INTPTLON: '-081.7312810',
        ELSDLEA: '',
        UNSDLEA: '04647',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3269, 40.4605],
            [-82.1869, 40.4061],
            [-82.1959, 40.2391],
            [-82.2814, 40.2001],
            [-82.3683, 40.3146],
            [-82.3269, 40.4605],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910013',
        NAME: 'East Knox Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276059902,
        AWATER: 3139849,
        INTPTLAT: '+40.3359936',
        INTPTLON: '-082.2659671',
        ELSDLEA: '',
        UNSDLEA: '10013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4802, 40.0261],
            [-82.4794, 40.036],
            [-82.3804, 40.0524],
            [-82.3693, 40.0301],
            [-82.4802, 40.0261],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904411',
        NAME: 'Heath City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24835693,
        AWATER: 180193,
        INTPTLAT: '+40.0286721',
        INTPTLON: '-082.4307760',
        ELSDLEA: '',
        UNSDLEA: '04411',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5741, 40.0416],
            [-82.5673, 40.12],
            [-82.4574, 40.1073],
            [-82.4794, 40.036],
            [-82.4802, 40.0261],
            [-82.5613, 39.9973],
            [-82.5741, 40.0416],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904539',
        NAME: 'Granville Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102546008,
        AWATER: 709175,
        INTPTLAT: '+40.0623592',
        INTPTLON: '-082.5249958',
        ELSDLEA: '',
        UNSDLEA: '04539',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7507, 40.277],
            [-82.5958, 40.2575],
            [-82.551, 40.3165],
            [-82.4812, 40.2951],
            [-82.4674, 40.1871],
            [-82.5618, 40.1914],
            [-82.5673, 40.12],
            [-82.5741, 40.0416],
            [-82.6688, 40.0515],
            [-82.7129, 40.0862],
            [-82.6269, 40.1205],
            [-82.626, 40.1928],
            [-82.7559, 40.2068],
            [-82.7507, 40.277],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904803',
        NAME: 'Northridge Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 354093941,
        AWATER: 1208408,
        INTPTLAT: '+40.1921789',
        INTPTLON: '-082.6041600',
        ELSDLEA: '',
        UNSDLEA: '04803',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6324, 41.4282],
            [-82.6254, 41.4253],
            [-82.6235, 41.4235],
            [-82.5118, 41.3833],
            [-82.6141, 41.3586],
            [-82.6324, 41.4282],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904413',
        NAME: 'Huron City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54703457,
        AWATER: 1880463,
        INTPTLAT: '+41.3860107',
        INTPTLON: '-082.5862068',
        ELSDLEA: '',
        UNSDLEA: '04413',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9547, 41.358],
            [-82.953, 41.426],
            [-82.7777, 41.4518],
            [-82.7364, 41.4336],
            [-82.7661, 41.3309],
            [-82.9304, 41.3433],
            [-82.9547, 41.358],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904680',
        NAME: 'Margaretta Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188791241,
        AWATER: 5630453,
        INTPTLAT: '+41.3940179',
        INTPTLON: '-082.8431729',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5698, 41.2712],
            [-82.4357, 41.2839],
            [-82.3423, 41.2836],
            [-82.344, 41.2065],
            [-82.4659, 41.1416],
            [-82.5384, 41.1414],
            [-82.5582, 41.148],
            [-82.5698, 41.2712],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904774',
        NAME: 'Western Reserve Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 234178324,
        AWATER: 875426,
        INTPTLAT: '+41.2274594',
        INTPTLON: '-082.4819356',
        ELSDLEA: '',
        UNSDLEA: '04774',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9304, 41.3433],
            [-82.7661, 41.3309],
            [-82.7428, 41.3023],
            [-82.7774, 41.1881],
            [-82.9427, 41.1686],
            [-83.0074, 41.198],
            [-82.9255, 41.2895],
            [-82.9304, 41.3433],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904359',
        NAME: 'Bellevue City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297613002,
        AWATER: 863688,
        INTPTLAT: '+41.2478332',
        INTPTLON: '-082.8686303',
        ELSDLEA: '',
        UNSDLEA: '04359',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7774, 41.1881],
            [-82.7428, 41.3023],
            [-82.6895, 41.3135],
            [-82.6351, 41.2866],
            [-82.6389, 41.1452],
            [-82.646, 41.1407],
            [-82.7366, 41.1419],
            [-82.7774, 41.1881],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904771',
        NAME: 'Monroeville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161842111,
        AWATER: 772095,
        INTPTLAT: '+41.2242535',
        INTPTLON: '-082.6823448',
        ELSDLEA: '',
        UNSDLEA: '04771',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3381, 40.2143],
            [-81.3354, 40.2882],
            [-81.2737, 40.3455],
            [-81.1984, 40.3464],
            [-81.1398, 40.4318],
            [-80.9419, 40.4247],
            [-80.8665, 40.3926],
            [-80.8756, 40.2918],
            [-80.9192, 40.1608],
            [-80.9776, 40.1466],
            [-81.1874, 40.1719],
            [-81.2259, 40.1701],
            [-81.3381, 40.2143],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904524',
        NAME: 'Harrison Hills City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 939444302,
        AWATER: 19474780,
        INTPTLAT: '+40.2798310',
        INTPTLON: '-081.0788007',
        ELSDLEA: '',
        UNSDLEA: '04524',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2684, 40.4767],
            [-81.2664, 40.5297],
            [-81.1543, 40.5038],
            [-81.1398, 40.4318],
            [-81.1984, 40.3464],
            [-81.2737, 40.3455],
            [-81.2684, 40.4767],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904754',
        NAME: 'Conotton Valley Union Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176048209,
        AWATER: 5068376,
        INTPTLAT: '+40.4350425',
        INTPTLON: '-081.2108396',
        ELSDLEA: '',
        UNSDLEA: '04754',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8756, 40.2918],
            [-80.615, 40.2916],
            [-80.6818, 40.1856],
            [-80.708, 40.1433],
            [-80.8624, 40.1588],
            [-80.9192, 40.1608],
            [-80.8756, 40.2918],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904778',
        NAME: 'Buckeye Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 329735321,
        AWATER: 1989530,
        INTPTLAT: '+40.2282832',
        INTPTLON: '-080.7716543',
        ELSDLEA: '',
        UNSDLEA: '04778',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6351, 41.2866],
            [-82.5698, 41.2712],
            [-82.5582, 41.148],
            [-82.6389, 41.1452],
            [-82.6351, 41.2866],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904456',
        NAME: 'Norwalk City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81517947,
        AWATER: 990553,
        INTPTLAT: '+41.2070471',
        INTPTLON: '-082.5973332',
        ELSDLEA: '',
        UNSDLEA: '04456',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7366, 41.1419],
            [-82.646, 41.1407],
            [-82.6312, 41.0054],
            [-82.7625, 40.9958],
            [-82.8296, 40.9996],
            [-82.7366, 41.1419],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904509',
        NAME: 'Willard City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216537439,
        AWATER: 2593868,
        INTPTLAT: '+41.0646205',
        INTPTLON: '-082.7298275',
        ELSDLEA: '',
        UNSDLEA: '04509',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5384, 41.1414],
            [-82.4659, 41.1416],
            [-82.344, 41.2065],
            [-82.3441, 41.2022],
            [-82.3694, 41.1506],
            [-82.297, 41.0918],
            [-82.4368, 41.0624],
            [-82.5366, 41.0665],
            [-82.5384, 41.1414],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904772',
        NAME: 'New London Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214867890,
        AWATER: 1878080,
        INTPTLAT: '+41.1222782',
        INTPTLON: '-082.4136316',
        ELSDLEA: '',
        UNSDLEA: '04772',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.97, 41.4189],
            [-81.9695, 41.478],
            [-81.8737, 41.4769],
            [-81.8745, 41.4499],
            [-81.8747, 41.4481],
            [-81.9703, 41.411],
            [-81.97, 41.4189],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904506',
        NAME: 'Westlake City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41248937,
        AWATER: 9744,
        INTPTLAT: '+41.4495314',
        INTPTLON: '-081.9301623',
        ELSDLEA: '',
        UNSDLEA: '04506',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5954, 41.136],
            [-81.5874, 41.1366],
            [-81.5194, 41.1359],
            [-81.465, 41.1189],
            [-81.4292, 41.0668],
            [-81.4921, 41.0236],
            [-81.5713, 41.0237],
            [-81.5892, 41.0415],
            [-81.5991, 41.0624],
            [-81.5954, 41.136],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904348',
        NAME: 'Akron City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141911200,
        AWATER: 694773,
        INTPTLAT: '+41.0767230',
        INTPTLON: '-081.5200589',
        ELSDLEA: '',
        UNSDLEA: '04348',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6876, 41.0622],
            [-81.6699, 41.0623],
            [-81.5991, 41.0624],
            [-81.5892, 41.0415],
            [-81.608, 40.9946],
            [-81.6026, 40.9888],
            [-81.6477, 40.9482],
            [-81.6885, 40.9886],
            [-81.6876, 41.0622],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904455',
        NAME: 'Norton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64160516,
        AWATER: 852305,
        INTPTLAT: '+41.0142514',
        INTPTLON: '-081.6460987',
        ELSDLEA: '',
        UNSDLEA: '04455',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5713, 41.0237],
            [-81.4921, 41.0236],
            [-81.5011, 40.9937],
            [-81.5325, 40.9733],
            [-81.5909, 40.9859],
            [-81.6026, 40.9888],
            [-81.608, 40.9946],
            [-81.5713, 41.0237],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904999',
        NAME: 'Coventry Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30015450,
        AWATER: 3389266,
        INTPTLAT: '+41.0049718',
        INTPTLON: '-081.5539097',
        ELSDLEA: '',
        UNSDLEA: '04999',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.685, 41.2771],
            [-81.6705, 41.2773],
            [-81.5874, 41.2776],
            [-81.5874, 41.1366],
            [-81.5954, 41.136],
            [-81.5961, 41.136],
            [-81.5973, 41.136],
            [-81.6871, 41.128],
            [-81.685, 41.2771],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905005',
        NAME: 'Revere Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130689442,
        AWATER: 506985,
        INTPTLAT: '+41.2069602',
        INTPTLON: '-081.6315466',
        ELSDLEA: '',
        UNSDLEA: '05005',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4937, 39.3779],
            [-83.3334, 39.4288],
            [-83.2338, 39.3301],
            [-83.3448, 39.2514],
            [-83.3535, 39.1976],
            [-83.3564, 39.1847],
            [-83.4209, 39.177],
            [-83.5151, 39.2567],
            [-83.4569, 39.3152],
            [-83.4937, 39.3779],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904540',
        NAME: 'Greenfield Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 417521126,
        AWATER: 4683398,
        INTPTLAT: '+39.3068970',
        INTPTLON: '-083.3756515',
        ELSDLEA: '',
        UNSDLEA: '04540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3334, 39.4288],
            [-83.2232, 39.5192],
            [-83.0652, 39.5215],
            [-83.0658, 39.5088],
            [-83.119, 39.362],
            [-83.2338, 39.3301],
            [-83.3334, 39.4288],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904949',
        NAME: 'Adena Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332386370,
        AWATER: 35938,
        INTPTLAT: '+39.4425053',
        INTPTLON: '-083.1874278',
        ELSDLEA: '',
        UNSDLEA: '04949',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9839, 39.2168],
            [-82.9668, 39.2405],
            [-82.9419, 39.3264],
            [-82.8599, 39.3873],
            [-82.7474, 39.3819],
            [-82.7486, 39.3682],
            [-82.7629, 39.208],
            [-82.7859, 39.1688],
            [-82.9567, 39.178],
            [-82.9839, 39.2168],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904952',
        NAME: 'Southeastern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 353352741,
        AWATER: 3466707,
        INTPTLAT: '+39.2731632',
        INTPTLON: '-082.8474359',
        ELSDLEA: '',
        UNSDLEA: '04952',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0658, 39.5088],
            [-82.9926, 39.5064],
            [-82.9982, 39.4801],
            [-82.982, 39.3672],
            [-83.0058, 39.3037],
            [-83.0836, 39.3254],
            [-83.119, 39.362],
            [-83.0658, 39.5088],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904953',
        NAME: 'Union-Scioto Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162958543,
        AWATER: 1499128,
        INTPTLAT: '+39.4049871',
        INTPTLON: '-083.0361545',
        ELSDLEA: '',
        UNSDLEA: '04953',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6938, 40.5836],
            [-83.5155, 40.6151],
            [-83.4585, 40.5962],
            [-83.4184, 40.5052],
            [-83.525, 40.4653],
            [-83.607, 40.4668],
            [-83.6938, 40.5836],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904750',
        NAME: 'Ridgemont Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252622428,
        AWATER: 74193,
        INTPTLAT: '+40.5488545',
        INTPTLON: '-083.5475842',
        ELSDLEA: '',
        UNSDLEA: '04750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3026, 39.234],
            [-84.224, 39.232],
            [-84.173, 39.1775],
            [-84.26, 39.1351],
            [-84.3042, 39.1324],
            [-84.3018, 39.1526],
            [-84.3025, 39.1679],
            [-84.3026, 39.234],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904550',
        NAME: 'Milford Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79695343,
        AWATER: 1029586,
        INTPTLAT: '+39.1821561',
        INTPTLON: '-084.2563832',
        ELSDLEA: '',
        UNSDLEA: '04550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1507, 39.0122],
            [-84.1272, 39.0174],
            [-84.0256, 38.9844],
            [-84.0389, 38.8769],
            [-84.1563, 38.9149],
            [-84.1567, 39.0031],
            [-84.1507, 39.0122],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904631',
        NAME: 'Bethel-Tate Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125406127,
        AWATER: 2216368,
        INTPTLAT: '+38.9520235',
        INTPTLON: '-084.1015086',
        ELSDLEA: '',
        UNSDLEA: '04631',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.173, 39.1775],
            [-84.093, 39.2246],
            [-83.9975, 39.1929],
            [-84.0001, 39.1373],
            [-84.0051, 39.1303],
            [-84.1047, 39.0736],
            [-84.2185, 39.1151],
            [-84.26, 39.1351],
            [-84.173, 39.1775],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904632',
        NAME: 'Clermont Northeastern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195033059,
        AWATER: 1012839,
        INTPTLAT: '+39.1444628',
        INTPTLON: '-084.1182453',
        ELSDLEA: '',
        UNSDLEA: '04632',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1047, 39.0736],
            [-84.0051, 39.1303],
            [-84.0256, 38.9844],
            [-84.1272, 39.0174],
            [-84.1047, 39.0736],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904636',
        NAME: 'Williamsburg Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104043595,
        AWATER: 3085159,
        INTPTLAT: '+39.0584436',
        INTPTLON: '-084.0585534',
        ELSDLEA: '',
        UNSDLEA: '04636',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4745, 39.4297],
            [-84.4643, 39.4337],
            [-84.3398, 39.4275],
            [-84.3431, 39.3978],
            [-84.352, 39.3069],
            [-84.4832, 39.3017],
            [-84.4745, 39.4297],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904611',
        NAME: 'Lakota Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 162843530,
        AWATER: 126917,
        INTPTLAT: '+39.3659418',
        INTPTLON: '-084.4130897',
        ELSDLEA: '',
        UNSDLEA: '04611',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8199, 39.1614],
            [-84.8194, 39.3052],
            [-84.6418, 39.305],
            [-84.6984, 39.2117],
            [-84.8201, 39.1185],
            [-84.8199, 39.1614],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904738',
        NAME: 'Southwest Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171375711,
        AWATER: 4669602,
        INTPTLAT: '+39.2384189',
        INTPTLON: '-084.7559530',
        ELSDLEA: '',
        UNSDLEA: '04738',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8039, 41.3753],
            [-84.7569, 41.3716],
            [-84.69, 41.3395],
            [-84.6893, 41.2524],
            [-84.8035, 41.2526],
            [-84.8036, 41.2713],
            [-84.8039, 41.3753],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904541',
        NAME: 'Hicksville Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113556954,
        AWATER: 0,
        INTPTLAT: '+41.3068682',
        INTPTLON: '-084.7507597',
        ELSDLEA: '',
        UNSDLEA: '04541',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3276, 41.2891],
            [-84.2274, 41.3013],
            [-84.2275, 41.2532],
            [-84.2282, 41.1659],
            [-84.3319, 41.1654],
            [-84.3416, 41.2091],
            [-84.3276, 41.2891],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904670',
        NAME: 'Ayersville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132986519,
        AWATER: 1060319,
        INTPTLAT: '+41.2305043',
        INTPTLON: '-084.2759500',
        ELSDLEA: '',
        UNSDLEA: '04670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9499, 39.9683],
            [-82.9228, 39.9766],
            [-82.9253, 39.9474],
            [-82.9424, 39.9483],
            [-82.9499, 39.9683],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904362',
        NAME: 'Bexley City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6270560,
        AWATER: 50713,
        INTPTLAT: '+39.9653584',
        INTPTLON: '-082.9344792',
        ELSDLEA: '',
        UNSDLEA: '04362',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9127, 39.9792],
            [-82.8666, 39.9866],
            [-82.871, 39.951],
            [-82.9049, 39.9529],
            [-82.9127, 39.9792],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904507',
        NAME: 'Whitehall City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13673907,
        AWATER: 70613,
        INTPTLAT: '+39.9689678',
        INTPTLON: '-082.8839478',
        ELSDLEA: '',
        UNSDLEA: '04507',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8183, 40.8646],
            [-80.8071, 40.9009],
            [-80.7457, 40.9005],
            [-80.7462, 40.8133],
            [-80.8225, 40.8138],
            [-80.8183, 40.8646],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910007',
        NAME: 'Leetonia Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57050945,
        AWATER: 191940,
        INTPTLAT: '+40.8571977',
        INTPTLON: '-080.7737852',
        ELSDLEA: '',
        UNSDLEA: '10007',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0407, 40.8611],
            [-83.9952, 40.915],
            [-83.8804, 40.9204],
            [-83.8711, 40.8414],
            [-83.8801, 40.8199],
            [-83.9943, 40.818],
            [-84.0407, 40.8611],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904521',
        NAME: 'Bluffton Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137033254,
        AWATER: 790419,
        INTPTLAT: '+40.8654928',
        INTPTLON: '-083.9568103',
        ELSDLEA: '',
        UNSDLEA: '04521',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6898, 40.9652],
            [-83.6509, 40.9935],
            [-83.6125, 40.9933],
            [-83.6144, 40.8192],
            [-83.7277, 40.8485],
            [-83.6898, 40.9652],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904742',
        NAME: 'Arlington Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147744173,
        AWATER: 221996,
        INTPTLAT: '+40.8965532',
        INTPTLON: '-083.6578667',
        ELSDLEA: '',
        UNSDLEA: '04742',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8807, 41.0276],
            [-83.8524, 41.0402],
            [-83.8475, 40.9933],
            [-83.6898, 40.9652],
            [-83.7277, 40.8485],
            [-83.7663, 40.8193],
            [-83.8711, 40.8414],
            [-83.8804, 40.9204],
            [-83.8807, 41.0276],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904743',
        NAME: 'Cory-Rawson Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259376305,
        AWATER: 183965,
        INTPTLAT: '+40.9254825',
        INTPTLON: '-083.7926731',
        ELSDLEA: '',
        UNSDLEA: '04743',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6528, 41.2404],
            [-83.6506, 41.3085],
            [-83.5582, 41.3485],
            [-83.4456, 41.3411],
            [-83.4774, 41.2243],
            [-83.4786, 41.1661],
            [-83.5733, 41.1379],
            [-83.6248, 41.1675],
            [-83.6528, 41.2404],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905068',
        NAME: 'Elmwood Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277936416,
        AWATER: 236124,
        INTPTLAT: '+41.2554916',
        INTPTLON: '-083.5499913',
        ELSDLEA: '',
        UNSDLEA: '05068',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7271, 41.1685],
            [-83.6248, 41.1675],
            [-83.5733, 41.1379],
            [-83.557, 41.0503],
            [-83.6611, 41.0807],
            [-83.6982, 41.0808],
            [-83.7271, 41.1685],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910001',
        NAME: 'Van Buren Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124841647,
        AWATER: 342305,
        INTPTLAT: '+41.1201972',
        INTPTLON: '-083.6381284',
        ELSDLEA: '',
        UNSDLEA: '10001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2609, 40.0028],
            [-83.2417, 39.9571],
            [-83.2507, 39.9035],
            [-83.3362, 39.8999],
            [-83.3716, 39.9627],
            [-83.2609, 40.0028],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904825',
        NAME: 'Jefferson Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102227137,
        AWATER: 95018,
        INTPTLAT: '+39.9557530',
        INTPTLON: '-083.2952020',
        ELSDLEA: '',
        UNSDLEA: '04825',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4229, 40.8551],
            [-81.3673, 40.905],
            [-81.3037, 40.9133],
            [-81.3026, 40.9031],
            [-81.3081, 40.8291],
            [-81.4251, 40.8182],
            [-81.4455, 40.819],
            [-81.4229, 40.8551],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904993',
        NAME: 'Plain Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73805981,
        AWATER: 326490,
        INTPTLAT: '+40.8616028',
        INTPTLON: '-081.3602031',
        ELSDLEA: '',
        UNSDLEA: '04993',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6136, 40.4957],
            [-80.6114, 40.4371],
            [-80.636, 40.4542],
            [-80.6136, 40.4957],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904491',
        NAME: 'Toronto City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12439922,
        AWATER: 784723,
        INTPTLAT: '+40.4656204',
        INTPTLON: '-080.6155522',
        ELSDLEA: '',
        UNSDLEA: '04491',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9975, 39.1929],
            [-83.9647, 39.2518],
            [-83.8657, 39.2473],
            [-83.8679, 39.1254],
            [-84.0001, 39.1373],
            [-83.9975, 39.1929],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904604',
        NAME: 'Fayetteville-Perry Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146701839,
        AWATER: 765918,
        INTPTLAT: '+39.1881782',
        INTPTLON: '-083.9306418',
        ELSDLEA: '',
        UNSDLEA: '04604',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6638, 40.0042],
            [-84.5974, 40.0485],
            [-84.5069, 40.0059],
            [-84.5058, 39.9262],
            [-84.5962, 39.9167],
            [-84.6614, 39.9167],
            [-84.6638, 40.0042],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904663',
        NAME: 'Arcanum-Butler Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156235136,
        AWATER: 206675,
        INTPTLAT: '+39.9717429',
        INTPTLON: '-084.5844818',
        ELSDLEA: '',
        UNSDLEA: '04663',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8085, 40.1072],
            [-84.8037, 40.3101],
            [-84.8038, 40.3436],
            [-84.6929, 40.3377],
            [-84.6605, 40.3271],
            [-84.7095, 40.265],
            [-84.7003, 40.1784],
            [-84.8089, 40.0971],
            [-84.8085, 40.1072],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904667',
        NAME: 'Mississinawa Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205562108,
        AWATER: 478780,
        INTPTLAT: '+40.2353251',
        INTPTLON: '-084.7380991',
        ELSDLEA: '',
        UNSDLEA: '04667',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8123, 39.9273],
            [-84.8108, 40.0051],
            [-84.809, 40.0857],
            [-84.7128, 40.0838],
            [-84.6638, 40.0042],
            [-84.6614, 39.9167],
            [-84.8123, 39.9273],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904668',
        NAME: 'Tri-Village Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221448004,
        AWATER: 637059,
        INTPTLAT: '+40.0012953',
        INTPTLON: '-084.7393787',
        ELSDLEA: '',
        UNSDLEA: '04668',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0256, 38.9844],
            [-84.0051, 39.1303],
            [-84.0001, 39.1373],
            [-83.8679, 39.1254],
            [-83.8722, 39.0213],
            [-83.8738, 38.9512],
            [-83.9891, 38.8548],
            [-84.0429, 38.8472],
            [-84.0389, 38.8769],
            [-84.0256, 38.9844],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904606',
        NAME: 'Western Brown Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358921344,
        AWATER: 675072,
        INTPTLAT: '+39.0010174',
        INTPTLON: '-083.9498229',
        ELSDLEA: '',
        UNSDLEA: '04606',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2708, 39.0158],
            [-83.2121, 38.9601],
            [-83.0436, 38.9569],
            [-83.0184, 38.834],
            [-83.0094, 38.7923],
            [-83.2682, 38.7428],
            [-83.2708, 39.0158],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904963',
        NAME: 'Northwest Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 472501808,
        AWATER: 3233158,
        INTPTLAT: '+38.8675368',
        INTPTLON: '-083.1490729',
        ELSDLEA: '',
        UNSDLEA: '04963',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8739, 38.7448],
            [-82.8032, 38.7535],
            [-82.8085, 38.7017],
            [-82.8746, 38.6827],
            [-82.8739, 38.7448],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904966',
        NAME: 'Wheelersburg Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40807470,
        AWATER: 995452,
        INTPTLAT: '+38.7207133',
        INTPTLON: '-082.8423802',
        ELSDLEA: '',
        UNSDLEA: '04966',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1894, 39.7307],
            [-84.1645, 39.7311],
            [-84.1609, 39.7174],
            [-84.1894, 39.7307],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904458',
        NAME: 'Oakwood City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5682524,
        AWATER: 0,
        INTPTLAT: '+39.7202011',
        INTPTLON: '-084.1733285',
        ELSDLEA: '',
        UNSDLEA: '04458',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2615, 39.674],
            [-84.2522, 39.703],
            [-84.2353, 39.7032],
            [-84.2029, 39.6747],
            [-84.2053, 39.6572],
            [-84.2615, 39.674],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904505',
        NAME: 'West Carrollton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24976717,
        AWATER: 768805,
        INTPTLAT: '+39.6711944',
        INTPTLON: '-084.2376905',
        ELSDLEA: '',
        UNSDLEA: '04505',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4453, 39.8745],
            [-84.3551, 39.8322],
            [-84.3668, 39.7818],
            [-84.4827, 39.7883],
            [-84.4831, 39.8093],
            [-84.4453, 39.8745],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904867',
        NAME: 'Brookville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96570628,
        AWATER: 0,
        INTPTLAT: '+39.8271083',
        INTPTLON: '-084.4233123',
        ELSDLEA: '',
        UNSDLEA: '04867',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.189, 41.1617],
            [-83.1889, 41.1677],
            [-83.0741, 41.1971],
            [-83.0698, 41.1691],
            [-83.0736, 41.0808],
            [-83.1898, 41.0803],
            [-83.189, 41.1617],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904489',
        NAME: 'Tiffin City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106277626,
        AWATER: 646548,
        INTPTLAT: '+41.1275372',
        INTPTLON: '-083.1354450',
        ELSDLEA: '',
        UNSDLEA: '04489',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7305, 40.6894],
            [-83.5712, 40.7098],
            [-83.4778, 40.7301],
            [-83.4198, 40.6453],
            [-83.4585, 40.5962],
            [-83.5155, 40.6151],
            [-83.6938, 40.5836],
            [-83.7352, 40.6017],
            [-83.7305, 40.6894],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910025',
        NAME: 'Kenton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308398162,
        AWATER: 340053,
        INTPTLAT: '+40.6512662',
        INTPTLON: '-083.5570152',
        ELSDLEA: '',
        UNSDLEA: '10025',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6573, 40.0946],
            [-83.5027, 40.1261],
            [-83.4888, 40.0497],
            [-83.4846, 40.007],
            [-83.5127, 40.001],
            [-83.6528, 40.0321],
            [-83.6573, 40.0946],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904548',
        NAME: 'Mechanicsburg Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157857165,
        AWATER: 55289,
        INTPTLAT: '+40.0649895',
        INTPTLON: '-083.5645686',
        ELSDLEA: '',
        UNSDLEA: '04548',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2256, 39.834],
            [-84.176, 39.8346],
            [-84.1548, 39.827],
            [-84.1559, 39.8187],
            [-84.2256, 39.834],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904873',
        NAME: 'Northridge Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 15818580,
        AWATER: 215375,
        INTPTLAT: '+39.8181462',
        INTPTLON: '-084.1923167',
        ELSDLEA: '',
        UNSDLEA: '04873',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.176, 39.8346],
            [-84.1572, 39.8856],
            [-84.051, 39.8798],
            [-84.0537, 39.8505],
            [-84.1136, 39.8178],
            [-84.1548, 39.827],
            [-84.176, 39.8346],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904875',
        NAME: 'Huber Heights City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59258290,
        AWATER: 610429,
        INTPTLAT: '+39.8549914',
        INTPTLON: '-084.1178230',
        ELSDLEA: '',
        UNSDLEA: '04875',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2706, 39.8334],
            [-84.2256, 39.834],
            [-84.1559, 39.8187],
            [-84.1084, 39.799],
            [-84.0969, 39.7937],
            [-84.1106, 39.7513],
            [-84.1098, 39.7291],
            [-84.1609, 39.7174],
            [-84.1645, 39.7311],
            [-84.1894, 39.7307],
            [-84.2353, 39.7032],
            [-84.2522, 39.703],
            [-84.2953, 39.7403],
            [-84.2706, 39.8334],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904384',
        NAME: 'Dayton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125373713,
        AWATER: 1933337,
        INTPTLAT: '+39.7667641',
        INTPTLON: '-084.2019560',
        ELSDLEA: '',
        UNSDLEA: '04384',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2857, 39.5892],
            [-84.2848, 39.5891],
            [-84.2485, 39.5869],
            [-84.2713, 39.4842],
            [-84.3181, 39.4871],
            [-84.3321, 39.5488],
            [-84.2857, 39.5892],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904400',
        NAME: 'Franklin City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63740899,
        AWATER: 461830,
        INTPTLAT: '+39.5364762',
        INTPTLON: '-084.2954807',
        ELSDLEA: '',
        UNSDLEA: '04400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2325, 39.2648],
            [-84.0461, 39.2575],
            [-84.093, 39.2246],
            [-84.173, 39.1775],
            [-84.224, 39.232],
            [-84.2325, 39.2648],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904634',
        NAME: 'Goshen Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106569763,
        AWATER: 337210,
        INTPTLAT: '+39.2386358',
        INTPTLON: '-084.1495634',
        ELSDLEA: '',
        UNSDLEA: '04634',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2627, 39.2884],
            [-84.1836, 39.3771],
            [-84.104, 39.4196],
            [-84.0041, 39.3386],
            [-84.0461, 39.2575],
            [-84.2325, 39.2648],
            [-84.2627, 39.2884],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905044',
        NAME: 'Little Miami Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257866684,
        AWATER: 1190426,
        INTPTLAT: '+39.3339470',
        INTPTLON: '-084.1103469',
        ELSDLEA: '',
        UNSDLEA: '05044',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3398, 39.4275],
            [-84.338, 39.4782],
            [-84.3181, 39.4871],
            [-84.2713, 39.4842],
            [-84.1529, 39.4789],
            [-84.0938, 39.4682],
            [-84.104, 39.4196],
            [-84.1836, 39.3771],
            [-84.2678, 39.3998],
            [-84.3431, 39.3978],
            [-84.3398, 39.4275],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904421',
        NAME: 'Lebanon City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203154317,
        AWATER: 636922,
        INTPTLAT: '+39.4419163',
        INTPTLON: '-084.2133796',
        ELSDLEA: '',
        UNSDLEA: '04421',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1087, 39.6678],
            [-84.1071, 39.6825],
            [-83.9897, 39.675],
            [-84.0564, 39.5799],
            [-84.1133, 39.5887],
            [-84.1087, 39.6678],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904727',
        NAME: 'Bellbrook-Sugarcreek Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75550131,
        AWATER: 508076,
        INTPTLAT: '+39.6349419',
        INTPTLON: '-084.0680697',
        ELSDLEA: '',
        UNSDLEA: '04727',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3431, 39.3978],
            [-84.2678, 39.3998],
            [-84.3052, 39.2919],
            [-84.3341, 39.2933],
            [-84.352, 39.3069],
            [-84.3431, 39.3978],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905045',
        NAME: 'Mason City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64672223,
        AWATER: 53945,
        INTPTLAT: '+39.3507828',
        INTPTLON: '-084.3131979',
        ELSDLEA: '',
        UNSDLEA: '05045',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4391, 39.4887],
            [-84.3647, 39.5438],
            [-84.3321, 39.5488],
            [-84.3181, 39.4871],
            [-84.338, 39.4782],
            [-84.4255, 39.4732],
            [-84.4391, 39.4887],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904440',
        NAME: 'Middletown City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65967876,
        AWATER: 1222805,
        INTPTLAT: '+39.5030597',
        INTPTLON: '-084.3753944',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2983, 40.0958],
            [-84.2882, 40.096],
            [-84.2232, 40.0965],
            [-84.1842, 39.9974],
            [-84.2673, 39.9855],
            [-84.296, 40.0089],
            [-84.2983, 40.0958],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904492',
        NAME: 'Troy City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99832941,
        AWATER: 1342622,
        INTPTLAT: '+40.0441013',
        INTPTLON: '-084.2393753',
        ELSDLEA: '',
        UNSDLEA: '04492',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2673, 39.9855],
            [-84.1842, 39.9974],
            [-84.1674, 39.9743],
            [-84.1577, 39.923],
            [-84.2748, 39.9218],
            [-84.2673, 39.9855],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904561',
        NAME: 'Tipp City Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72813073,
        AWATER: 907421,
        INTPTLAT: '+39.9573498',
        INTPTLON: '-084.2185710',
        ELSDLEA: '',
        UNSDLEA: '04561',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4533, 40.0901],
            [-84.4296, 40.0754],
            [-84.4285, 40.0073],
            [-84.4265, 39.9426],
            [-84.4454, 39.9263],
            [-84.5058, 39.9262],
            [-84.5069, 40.0059],
            [-84.5974, 40.0485],
            [-84.4533, 40.0901],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904664',
        NAME: 'Franklin Monroe Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164921502,
        AWATER: 56015,
        INTPTLAT: '+40.0137208',
        INTPTLON: '-084.4773487',
        ELSDLEA: '',
        UNSDLEA: '04664',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1674, 39.9743],
            [-84.0429, 39.9669],
            [-84.051, 39.8798],
            [-84.1572, 39.8856],
            [-84.1577, 39.923],
            [-84.1674, 39.9743],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904861',
        NAME: 'Bethel Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86396579,
        AWATER: 621808,
        INTPTLAT: '+39.9242706',
        INTPTLON: '-084.1185359',
        ELSDLEA: '',
        UNSDLEA: '04861',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1365, 40.1905],
            [-84.1177, 40.1894],
            [-84.0229, 40.1839],
            [-84.0361, 40.0402],
            [-84.0412, 39.9827],
            [-84.0429, 39.9669],
            [-84.1674, 39.9743],
            [-84.1842, 39.9974],
            [-84.2232, 40.0965],
            [-84.1365, 40.1905],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904862',
        NAME: 'Miami East Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309123410,
        AWATER: 663022,
        INTPTLAT: '+40.0757984',
        INTPTLON: '-084.1069555',
        ELSDLEA: '',
        UNSDLEA: '04862',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4454, 39.9263],
            [-84.4265, 39.9426],
            [-84.3019, 39.9214],
            [-84.2256, 39.834],
            [-84.2706, 39.8334],
            [-84.3551, 39.8322],
            [-84.4453, 39.8745],
            [-84.4454, 39.9263],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904872',
        NAME: 'Northmont City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117423312,
        AWATER: 348634,
        INTPTLAT: '+39.8833124',
        INTPTLON: '-084.3510287',
        ELSDLEA: '',
        UNSDLEA: '04872',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0361, 40.0402],
            [-83.8054, 40.044],
            [-83.7985, 40.0268],
            [-83.8398, 39.9555],
            [-83.9049, 39.9447],
            [-84.0412, 39.9827],
            [-84.0361, 40.0402],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904626',
        NAME: 'Northwestern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 175358004,
        AWATER: 227478,
        INTPTLAT: '+39.9958554',
        INTPTLON: '-083.9199513',
        ELSDLEA: '',
        UNSDLEA: '04626',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1971, 41.2544],
            [-83.227, 41.1729],
            [-83.2648, 41.173],
            [-83.1971, 41.2544],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904969',
        NAME: 'Bettsville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42163275,
        AWATER: 33651,
        INTPTLAT: '+41.2194384',
        INTPTLON: '-083.2367830',
        ELSDLEA: '',
        UNSDLEA: '04969',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4209, 41.0793],
            [-83.3203, 41.0986],
            [-83.2399, 41.0652],
            [-83.3212, 41.0061],
            [-83.3886, 41.0141],
            [-83.4208, 41.0579],
            [-83.4209, 41.0793],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904971',
        NAME: 'New Riegel Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102194320,
        AWATER: 0,
        INTPTLAT: '+41.0531925',
        INTPTLON: '-083.3356680',
        ELSDLEA: '',
        UNSDLEA: '04971',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7117, 41.2719],
            [-80.7113, 41.3498],
            [-80.6178, 41.3503],
            [-80.6173, 41.2724],
            [-80.6168, 41.203],
            [-80.7112, 41.203],
            [-80.7117, 41.2719],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905015',
        NAME: 'Mathews Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128341737,
        AWATER: 270408,
        INTPTLAT: '+41.2798419',
        INTPTLON: '-080.6740953',
        ELSDLEA: '',
        UNSDLEA: '05015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.249, 40.4246],
            [-83.1137, 40.437],
            [-83.0168, 40.4843],
            [-82.8694, 40.4275],
            [-82.8671, 40.3552],
            [-82.9259, 40.2846],
            [-83.0257, 40.2886],
            [-83.0307, 40.3845],
            [-83.124, 40.33],
            [-83.0991, 40.2615],
            [-83.1292, 40.1797],
            [-83.1713, 40.1981],
            [-83.2403, 40.2445],
            [-83.2494, 40.3046],
            [-83.249, 40.4246],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904675',
        NAME: 'Buckeye Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 527778023,
        AWATER: 8190658,
        INTPTLAT: '+40.4074730',
        INTPTLON: '-082.9603337',
        ELSDLEA: '',
        UNSDLEA: '04675',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3995, 41.7058],
            [-84.3559, 41.7067],
            [-84.2213, 41.7103],
            [-84.229, 41.6384],
            [-84.2285, 41.617],
            [-84.23, 41.6169],
            [-84.3813, 41.6167],
            [-84.3995, 41.7058],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904706',
        NAME: 'Fayette Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143938854,
        AWATER: 690801,
        INTPTLAT: '+41.6599989',
        INTPTLON: '-084.3135379',
        ELSDLEA: '',
        UNSDLEA: '04706',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9503, 41.5887],
            [-83.807, 41.6579],
            [-83.7864, 41.6063],
            [-83.7863, 41.6043],
            [-83.8829, 41.5159],
            [-83.9309, 41.5158],
            [-83.9503, 41.5887],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904709',
        NAME: 'Swanton Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109680278,
        AWATER: 385072,
        INTPTLAT: '+41.5908430',
        INTPTLON: '-083.8673259',
        ELSDLEA: '',
        UNSDLEA: '04709',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1124, 41.6405],
            [-84.0063, 41.6565],
            [-83.9503, 41.5887],
            [-83.9309, 41.5158],
            [-84.0765, 41.4861],
            [-84.1124, 41.6405],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904708',
        NAME: 'Pike-Delta-York Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189769755,
        AWATER: 718060,
        INTPTLAT: '+41.5706149',
        INTPTLON: '-084.0295512',
        ELSDLEA: '',
        UNSDLEA: '04708',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4456, 41.3411],
            [-83.4171, 41.3483],
            [-83.3586, 41.3064],
            [-83.2544, 41.3413],
            [-83.188, 41.277],
            [-83.1971, 41.2544],
            [-83.2648, 41.173],
            [-83.382, 41.1666],
            [-83.4774, 41.2243],
            [-83.4456, 41.3411],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904956',
        NAME: 'Lakota Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 329115347,
        AWATER: 16245,
        INTPTLAT: '+41.2621123',
        INTPTLON: '-083.3315254',
        ELSDLEA: '',
        UNSDLEA: '04956',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0698, 41.1691],
            [-83.0074, 41.198],
            [-82.9427, 41.1686],
            [-82.7774, 41.1881],
            [-82.7366, 41.1419],
            [-82.8296, 40.9996],
            [-82.9587, 40.9937],
            [-82.9607, 41.0811],
            [-83.0736, 41.0808],
            [-83.0698, 41.1691],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904968',
        NAME: 'Seneca East Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403070910,
        AWATER: 95378,
        INTPTLAT: '+41.1029845',
        INTPTLON: '-082.9074149',
        ELSDLEA: '',
        UNSDLEA: '04968',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4211, 41.1273],
            [-83.382, 41.1666],
            [-83.2648, 41.173],
            [-83.227, 41.1729],
            [-83.189, 41.1617],
            [-83.1898, 41.0803],
            [-83.2399, 41.0652],
            [-83.3203, 41.0986],
            [-83.4209, 41.0793],
            [-83.4211, 41.1273],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904970',
        NAME: 'Hopewell-Loudon Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170869647,
        AWATER: 536520,
        INTPTLAT: '+41.1175248',
        INTPTLON: '-083.3004792',
        ELSDLEA: '',
        UNSDLEA: '04970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0927, 41.2568],
            [-83.0691, 41.3415],
            [-82.9547, 41.358],
            [-82.9304, 41.3433],
            [-82.9255, 41.2895],
            [-83.0074, 41.198],
            [-83.0698, 41.1691],
            [-83.0741, 41.1971],
            [-83.0927, 41.2568],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910020',
        NAME: 'Clyde-Green Springs Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172990720,
        AWATER: 689148,
        INTPTLAT: '+41.2789471',
        INTPTLON: '-082.9949360',
        ELSDLEA: '',
        UNSDLEA: '10020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7978, 40.1293],
            [-83.6776, 40.1115],
            [-83.6573, 40.0946],
            [-83.6528, 40.0321],
            [-83.7985, 40.0268],
            [-83.8054, 40.044],
            [-83.7978, 40.1293],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904494',
        NAME: 'Urbana City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136232068,
        AWATER: 588802,
        INTPTLAT: '+40.0746254',
        INTPTLON: '-083.7256572',
        ELSDLEA: '',
        UNSDLEA: '04494',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1324, 39.8188],
            [-82.014, 39.8448],
            [-82.0025, 39.9107],
            [-81.9168, 39.9384],
            [-81.8041, 39.9347],
            [-81.81, 39.8474],
            [-81.7129, 39.8435],
            [-81.6948, 39.8248],
            [-81.6974, 39.7556],
            [-82.0766, 39.771],
            [-82.1347, 39.7896],
            [-82.1324, 39.8188],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904884',
        NAME: 'Franklin Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491921874,
        AWATER: 5060379,
        INTPTLAT: '+39.8269231',
        INTPTLON: '-081.9080998',
        ELSDLEA: '',
        UNSDLEA: '04884',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2567, 39.6483],
            [-82.1421, 39.6912],
            [-82.0321, 39.6367],
            [-82.0475, 39.5508],
            [-82.1592, 39.5566],
            [-82.2741, 39.5626],
            [-82.2567, 39.6483],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904906',
        NAME: 'Southern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 222505806,
        AWATER: 1559821,
        INTPTLAT: '+39.6030774',
        INTPTLON: '-082.1370373',
        ELSDLEA: '',
        UNSDLEA: '04906',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1347, 39.7896],
            [-82.0766, 39.771],
            [-82.0242, 39.7247],
            [-82.0321, 39.6367],
            [-82.1421, 39.6912],
            [-82.1347, 39.7896],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904535',
        NAME: 'Crooksville Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114276486,
        AWATER: 1229944,
        INTPTLAT: '+39.7111942',
        INTPTLON: '-082.0840336',
        ELSDLEA: '',
        UNSDLEA: '04535',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5967, 39.7912],
            [-82.51, 39.8679],
            [-82.4694, 39.8659],
            [-82.3966, 39.8331],
            [-82.3661, 39.7425],
            [-82.3598, 39.6542],
            [-82.4903, 39.6617],
            [-82.5252, 39.7061],
            [-82.5976, 39.7729],
            [-82.5967, 39.7912],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904687',
        NAME: 'Fairfield Union Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256057100,
        AWATER: 2101915,
        INTPTLAT: '+39.7540398',
        INTPTLON: '-082.4629416',
        ELSDLEA: '',
        UNSDLEA: '04687',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6772, 41.5947],
            [-83.6064, 41.5803],
            [-83.682, 41.5458],
            [-83.6853, 41.5938],
            [-83.6772, 41.5947],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904436',
        NAME: 'Maumee City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21279364,
        AWATER: 1837106,
        INTPTLAT: '+41.5759061',
        INTPTLON: '-083.6507555',
        ELSDLEA: '',
        UNSDLEA: '04436',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7814, 41.723],
            [-83.6459, 41.7275],
            [-83.644, 41.677],
            [-83.6622, 41.6692],
            [-83.6924, 41.6611],
            [-83.7605, 41.6586],
            [-83.7798, 41.6582],
            [-83.7814, 41.723],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904487',
        NAME: 'Sylvania City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73979213,
        AWATER: 233741,
        INTPTLAT: '+41.6953634',
        INTPTLON: '-083.7142019',
        ELSDLEA: '',
        UNSDLEA: '04487',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8834, 39.5635],
            [-83.8234, 39.5673],
            [-83.7299, 39.5165],
            [-83.7611, 39.3662],
            [-83.7361, 39.2921],
            [-83.8436, 39.2701],
            [-83.9008, 39.3478],
            [-83.8834, 39.5635],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904511',
        NAME: 'Wilmington City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 414077733,
        AWATER: 2764244,
        INTPTLAT: '+39.4235412',
        INTPTLON: '-083.8097234',
        ELSDLEA: '',
        UNSDLEA: '04511',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8657, 39.2473],
            [-83.8436, 39.2701],
            [-83.7361, 39.2921],
            [-83.692, 39.2714],
            [-83.7379, 39.1309],
            [-83.8193, 39.0202],
            [-83.8722, 39.0213],
            [-83.8679, 39.1254],
            [-83.8657, 39.2473],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904763',
        NAME: 'Lynchburg-Clay Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 294209092,
        AWATER: 612118,
        INTPTLAT: '+39.1684270',
        INTPTLON: '-083.8007450',
        ELSDLEA: '',
        UNSDLEA: '04763',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3661, 39.7425],
            [-82.2482, 39.7433],
            [-82.17, 39.8207],
            [-82.1324, 39.8188],
            [-82.1347, 39.7896],
            [-82.1421, 39.6912],
            [-82.2567, 39.6483],
            [-82.3598, 39.6542],
            [-82.3661, 39.7425],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904447',
        NAME: 'New Lexington City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 247381407,
        AWATER: 4234082,
        INTPTLAT: '+39.7160086',
        INTPTLON: '-082.2467335',
        ELSDLEA: '',
        UNSDLEA: '04447',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2377, 40.7153],
            [-81.1986, 40.7292],
            [-81.1193, 40.6477],
            [-81.2044, 40.6426],
            [-81.2377, 40.7153],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904617',
        NAME: 'Brown Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82066719,
        AWATER: 2188642,
        INTPTLAT: '+40.6818525',
        INTPTLON: '-081.1860520',
        ELSDLEA: '',
        UNSDLEA: '04617',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3089, 40.8145],
            [-81.1938, 40.8105],
            [-81.1986, 40.7292],
            [-81.2377, 40.7153],
            [-81.2949, 40.7257],
            [-81.3089, 40.8145],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904991',
        NAME: 'Osnaburg Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89504574,
        AWATER: 13215,
        INTPTLAT: '+40.7722695',
        INTPTLON: '-081.2575873',
        ELSDLEA: '',
        UNSDLEA: '04991',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.825, 41.2721],
            [-80.7964, 41.3489],
            [-80.7113, 41.3498],
            [-80.7117, 41.2719],
            [-80.8108, 41.2577],
            [-80.8305, 41.2589],
            [-80.825, 41.2721],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905018',
        NAME: 'Lakeview Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64299797,
        AWATER: 8944007,
        INTPTLAT: '+41.3111886',
        INTPTLON: '-080.7462331',
        ELSDLEA: '',
        UNSDLEA: '05018',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8109, 41.4252],
            [-80.8104, 41.5006],
            [-80.7135, 41.4998],
            [-80.7124, 41.4253],
            [-80.6185, 41.4247],
            [-80.6178, 41.3503],
            [-80.7113, 41.3498],
            [-80.7964, 41.3489],
            [-80.811, 41.3487],
            [-80.8109, 41.4252],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905021',
        NAME: 'Maplewood Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180039214,
        AWATER: 22981683,
        INTPTLAT: '+41.4041362',
        INTPTLON: '-080.7307411',
        ELSDLEA: '',
        UNSDLEA: '05021',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0032, 41.3479],
            [-80.9054, 41.3482],
            [-80.9059, 41.2722],
            [-81.0029, 41.2718],
            [-81.0032, 41.3479],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905023',
        NAME: 'Southington Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68488936,
        AWATER: 96340,
        INTPTLAT: '+41.3185392',
        INTPTLON: '-080.9494197',
        ELSDLEA: '',
        UNSDLEA: '05023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0023, 41.1342],
            [-80.906, 41.1344],
            [-80.8112, 41.1342],
            [-80.8095, 41.062],
            [-80.8218, 41.0619],
            [-81.0024, 41.0621],
            [-81.0023, 41.1342],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904832',
        NAME: 'Jackson-Milton Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128100903,
        AWATER: 9552038,
        INTPTLAT: '+41.0855802',
        INTPTLON: '-080.9165276',
        ELSDLEA: '',
        UNSDLEA: '04832',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.191, 39.3787],
            [-82.1674, 39.4663],
            [-82.0566, 39.4537],
            [-82.0342, 39.2813],
            [-82.1838, 39.2887],
            [-82.191, 39.3787],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904352',
        NAME: 'Athens City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227366011,
        AWATER: 2810405,
        INTPTLAT: '+39.3692845',
        INTPTLON: '-082.1126576',
        ELSDLEA: '',
        UNSDLEA: '04352',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7299, 39.5165],
            [-83.6702, 39.5503],
            [-83.5763, 39.5445],
            [-83.5909, 39.3787],
            [-83.6631, 39.2713],
            [-83.692, 39.2714],
            [-83.7361, 39.2921],
            [-83.7611, 39.3662],
            [-83.7299, 39.5165],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904640',
        NAME: 'East Clinton Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334601927,
        AWATER: 984898,
        INTPTLAT: '+39.4211248',
        INTPTLON: '-083.6606258',
        ELSDLEA: '',
        UNSDLEA: '04640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4625, 39.5421],
            [-83.4434, 39.5567],
            [-83.4029, 39.5532],
            [-83.4389, 39.5196],
            [-83.4625, 39.5421],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910011',
        NAME: 'Washington Court House City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14037530,
        AWATER: 140261,
        INTPTLAT: '+39.5388600',
        INTPTLON: '-083.4279379',
        ELSDLEA: '',
        UNSDLEA: '10011',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3962, 40.7066],
            [-81.2949, 40.7257],
            [-81.2377, 40.7153],
            [-81.2044, 40.6426],
            [-81.2686, 40.5652],
            [-81.3191, 40.6155],
            [-81.4133, 40.6152],
            [-81.3962, 40.7066],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904994',
        NAME: 'Sandy Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187365223,
        AWATER: 125316,
        INTPTLAT: '+40.6502261',
        INTPTLON: '-081.3086261',
        ELSDLEA: '',
        UNSDLEA: '04994',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9954, 40.6519],
            [-81.877, 40.7176],
            [-81.8773, 40.8138],
            [-81.8132, 40.8148],
            [-81.7652, 40.7865],
            [-81.7655, 40.7265],
            [-81.6492, 40.7301],
            [-81.65, 40.6681],
            [-81.8769, 40.6672],
            [-81.8787, 40.5959],
            [-81.996, 40.5945],
            [-81.9954, 40.6519],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905058',
        NAME: 'Southeast Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 304725826,
        AWATER: 486061,
        INTPTLAT: '+40.7072307',
        INTPTLON: '-081.8273346',
        ELSDLEA: '',
        UNSDLEA: '05058',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2086, 40.3308],
            [-84.1037, 40.3757],
            [-84.0044, 40.3687],
            [-84.004, 40.3368],
            [-84.1248, 40.3029],
            [-84.1177, 40.1894],
            [-84.1365, 40.1905],
            [-84.2178, 40.1954],
            [-84.2086, 40.3308],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910003',
        NAME: 'Sidney City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167089545,
        AWATER: 1045066,
        INTPTLAT: '+40.2892035',
        INTPTLON: '-084.1316288',
        ELSDLEA: '',
        UNSDLEA: '10003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.004, 40.3368],
            [-84.0148, 40.2735],
            [-84.0229, 40.1839],
            [-84.1177, 40.1894],
            [-84.1248, 40.3029],
            [-84.004, 40.3368],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904977',
        NAME: 'Fairlawn Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145036854,
        AWATER: 212952,
        INTPTLAT: '+40.2637428',
        INTPTLON: '-084.0740088',
        ELSDLEA: '',
        UNSDLEA: '04977',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0678, 41.4183],
            [-81.97, 41.4189],
            [-81.9703, 41.411],
            [-81.9705, 41.3867],
            [-81.9713, 41.3491],
            [-81.9833, 41.3515],
            [-82.069, 41.3517],
            [-82.0678, 41.4183],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904453',
        NAME: 'North Ridgeville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60769312,
        AWATER: 363781,
        INTPTLAT: '+41.3860001',
        INTPTLON: '-082.0247110',
        ELSDLEA: '',
        UNSDLEA: '04453',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2167, 41.3525],
            [-82.1767, 41.3544],
            [-82.1658, 41.3523],
            [-82.1803, 41.2375],
            [-82.2684, 41.2571],
            [-82.2167, 41.3525],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904459',
        NAME: 'Oberlin City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93197028,
        AWATER: 520789,
        INTPTLAT: '+41.2988656',
        INTPTLON: '-082.2078103',
        ELSDLEA: '',
        UNSDLEA: '04459',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.067, 41.4787],
            [-81.9695, 41.478],
            [-81.97, 41.4189],
            [-82.0678, 41.4183],
            [-82.067, 41.4787],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904811',
        NAME: 'Avon Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53889971,
        AWATER: 150096,
        INTPTLAT: '+41.4451003',
        INTPTLON: '-082.0051085',
        ELSDLEA: '',
        UNSDLEA: '04811',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9833, 41.3515],
            [-81.9713, 41.3491],
            [-81.877, 41.3507],
            [-81.8978, 41.2759],
            [-81.9725, 41.2748],
            [-81.9833, 41.3515],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904814',
        NAME: 'Columbia Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64522353,
        AWATER: 543251,
        INTPTLAT: '+41.3005231',
        INTPTLON: '-081.9374185',
        ELSDLEA: '',
        UNSDLEA: '04814',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6748, 40.6551],
            [-80.6321, 40.655],
            [-80.6326, 40.6172],
            [-80.668, 40.5825],
            [-80.6703, 40.5825],
            [-80.6748, 40.6551],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904503',
        NAME: 'Wellsville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25253901,
        AWATER: 283513,
        INTPTLAT: '+40.6262121',
        INTPTLON: '-080.6594181',
        ELSDLEA: '',
        UNSDLEA: '04503',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3819, 40.7885],
            [-82.1452, 40.8359],
            [-82.0793, 40.776],
            [-82.1262, 40.7265],
            [-82.1273, 40.7268],
            [-82.3178, 40.7265],
            [-82.3784, 40.7342],
            [-82.3819, 40.7885],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904582',
        NAME: 'Hillsdale Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245388694,
        AWATER: 3733174,
        INTPTLAT: '+40.7752213',
        INTPTLON: '-082.2364400',
        ELSDLEA: '',
        UNSDLEA: '04582',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6669, 40.9023],
            [-81.6477, 40.9482],
            [-81.6026, 40.9888],
            [-81.5909, 40.9859],
            [-81.5364, 40.9103],
            [-81.557, 40.8492],
            [-81.6498, 40.874],
            [-81.6561, 40.8739],
            [-81.6669, 40.9023],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904990',
        NAME: 'Northwest Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 81914550,
        AWATER: 2075888,
        INTPTLAT: '+40.9065887',
        INTPTLON: '-081.6023566',
        ELSDLEA: '',
        UNSDLEA: '04990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4251, 40.8182],
            [-81.3081, 40.8291],
            [-81.3084, 40.8204],
            [-81.4268, 40.7888],
            [-81.4251, 40.8182],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904371',
        NAME: 'Canton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43026748,
        AWATER: 556812,
        INTPTLAT: '+40.8034893',
        INTPTLON: '-081.3763564',
        ELSDLEA: '',
        UNSDLEA: '04371',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3084, 40.8204],
            [-81.3081, 40.8291],
            [-81.3026, 40.9031],
            [-81.1885, 40.8994],
            [-81.1936, 40.815],
            [-81.1938, 40.8105],
            [-81.3089, 40.8145],
            [-81.3084, 40.8204],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904987',
        NAME: 'Louisville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95332286,
        AWATER: 180673,
        INTPTLAT: '+40.8573979',
        INTPTLON: '-081.2464853',
        ELSDLEA: '',
        UNSDLEA: '04987',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8045, 41.5129],
            [-84.6708, 41.5136],
            [-84.6423, 41.5137],
            [-84.6219, 41.4484],
            [-84.7569, 41.3716],
            [-84.8039, 41.3753],
            [-84.8045, 41.5129],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905061',
        NAME: 'Edgerton Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178375643,
        AWATER: 829345,
        INTPTLAT: '+41.4596189',
        INTPTLON: '-084.7206056',
        ELSDLEA: '',
        UNSDLEA: '05061',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6893, 41.6216],
            [-84.547, 41.5936],
            [-84.5475, 41.5503],
            [-84.6423, 41.5137],
            [-84.6708, 41.5136],
            [-84.6893, 41.6216],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904552',
        NAME: 'Montpelier Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117172014,
        AWATER: 981997,
        INTPTLAT: '+41.5682273',
        INTPTLON: '-084.6251245',
        ELSDLEA: '',
        UNSDLEA: '04552',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7569, 41.3716],
            [-84.6219, 41.4484],
            [-84.479, 41.4331],
            [-84.4592, 41.4272],
            [-84.4576, 41.2816],
            [-84.4572, 41.2535],
            [-84.65, 41.2526],
            [-84.6893, 41.2524],
            [-84.69, 41.3395],
            [-84.7569, 41.3716],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904671',
        NAME: 'Central Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 412730614,
        AWATER: 1335929,
        INTPTLAT: '+41.3451830',
        INTPTLON: '-084.5828449',
        ELSDLEA: '',
        UNSDLEA: '04671',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9961, 41.0635],
            [-83.9962, 41.0758],
            [-83.8905, 41.0764],
            [-83.8807, 41.0276],
            [-83.8804, 40.9204],
            [-83.9952, 40.915],
            [-84.0144, 40.9671],
            [-83.9961, 41.0635],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904939',
        NAME: 'Pandora-Gilboa Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175131347,
        AWATER: 665279,
        INTPTLAT: '+41.0000543',
        INTPTLON: '-083.9480624',
        ELSDLEA: '',
        UNSDLEA: '04939',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2633, 40.6859],
            [-84.256, 40.7438],
            [-84.1515, 40.7459],
            [-84.1083, 40.7095],
            [-84.1079, 40.6579],
            [-84.2633, 40.6859],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904579',
        NAME: 'Shawnee Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106010070,
        AWATER: 3328161,
        INTPTLAT: '+40.7006276',
        INTPTLON: '-084.1809743',
        ELSDLEA: '',
        UNSDLEA: '04579',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2682, 40.8597],
            [-84.2527, 40.8598],
            [-84.1096, 40.861],
            [-84.1087, 40.7648],
            [-84.1515, 40.7459],
            [-84.256, 40.7438],
            [-84.3034, 40.7721],
            [-84.2682, 40.8597],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904577',
        NAME: 'Elida Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174705402,
        AWATER: 743058,
        INTPTLAT: '+40.8042784',
        INTPTLON: '-084.1991081',
        ELSDLEA: '',
        UNSDLEA: '04577',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6513, 39.7169],
            [-83.2524, 39.6954],
            [-83.2612, 39.5819],
            [-83.2232, 39.5192],
            [-83.3334, 39.4288],
            [-83.4937, 39.3779],
            [-83.5909, 39.3787],
            [-83.5763, 39.5445],
            [-83.6702, 39.5503],
            [-83.6513, 39.7169],
          ],
          [
            [-83.4625, 39.5421],
            [-83.4389, 39.5196],
            [-83.4029, 39.5532],
            [-83.4434, 39.5567],
            [-83.4625, 39.5421],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910010',
        NAME: 'Miami Trace Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1035264215,
        AWATER: 1566688,
        INTPTLAT: '+39.5649841',
        INTPTLON: '-083.4499854',
        ELSDLEA: '',
        UNSDLEA: '10010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9897, 39.675],
            [-83.9666, 39.7717],
            [-83.946, 39.7849],
            [-83.8904, 39.7692],
            [-83.8069, 39.6871],
            [-83.8234, 39.5673],
            [-83.8834, 39.5635],
            [-83.9794, 39.5441],
            [-84.0564, 39.5799],
            [-83.9897, 39.675],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904515',
        NAME: 'Xenia Community City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 331345888,
        AWATER: 2405595,
        INTPTLAT: '+39.6534546',
        INTPTLON: '-083.9302904',
        ELSDLEA: '',
        UNSDLEA: '04515',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8069, 39.6871],
            [-83.7248, 39.7535],
            [-83.6472, 39.773],
            [-83.6513, 39.7169],
            [-83.6702, 39.5503],
            [-83.7299, 39.5165],
            [-83.8234, 39.5673],
            [-83.8069, 39.6871],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904726',
        NAME: 'Greeneview Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 291155985,
        AWATER: 1126980,
        INTPTLAT: '+39.6397995',
        INTPTLON: '-083.7412047',
        ELSDLEA: '',
        UNSDLEA: '04726',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5909, 39.3787],
            [-83.4937, 39.3779],
            [-83.4569, 39.3152],
            [-83.5151, 39.2567],
            [-83.6631, 39.2713],
            [-83.5909, 39.3787],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904762',
        NAME: 'Fairfield Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157736274,
        AWATER: 29004,
        INTPTLAT: '+39.3185432',
        INTPTLON: '-083.5549393',
        ELSDLEA: '',
        UNSDLEA: '04762',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0938, 39.4682],
            [-83.9794, 39.5441],
            [-83.8834, 39.5635],
            [-83.9008, 39.3478],
            [-84.0041, 39.3386],
            [-84.104, 39.4196],
            [-84.0938, 39.4682],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904639',
        NAME: 'Clinton-Massie Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 307942293,
        AWATER: 11987862,
        INTPTLAT: '+39.4535677',
        INTPTLON: '-083.9768463',
        ELSDLEA: '',
        UNSDLEA: '04639',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6962, 38.7617],
            [-83.2682, 38.7099],
            [-83.2676, 38.6184],
            [-83.5208, 38.7031],
            [-83.6449, 38.6363],
            [-83.7053, 38.6399],
            [-83.6962, 38.7617],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3900537',
        NAME: 'Manchester Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 296080925,
        AWATER: 3716167,
        INTPTLAT: '+38.7147464',
        INTPTLON: '-083.5926534',
        ELSDLEA: '',
        UNSDLEA: '00537',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5241, 41.2702],
            [-81.4589, 41.2768],
            [-81.3918, 41.2767],
            [-81.3919, 41.2029],
            [-81.4894, 41.1935],
            [-81.5241, 41.2702],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905002',
        NAME: 'Hudson City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76621286,
        AWATER: 706336,
        INTPTLAT: '+41.2402614',
        INTPTLON: '-081.4481937',
        ELSDLEA: '',
        UNSDLEA: '05002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6321, 40.655],
            [-80.519, 40.6541],
            [-80.519, 40.6388],
            [-80.6326, 40.6172],
            [-80.6321, 40.655],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904391',
        NAME: 'East Liverpool City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35125612,
        AWATER: 678099,
        INTPTLAT: '+40.6416366',
        INTPTLON: '-080.5826223',
        ELSDLEA: '',
        UNSDLEA: '04391',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4198, 40.9176],
            [-81.4178, 40.9506],
            [-81.3673, 40.905],
            [-81.4229, 40.8551],
            [-81.4198, 40.9176],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904450',
        NAME: 'North Canton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39913251,
        AWATER: 0,
        INTPTLAT: '+40.9055830',
        INTPTLON: '-081.3972919',
        ELSDLEA: '',
        UNSDLEA: '04450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3037, 40.9133],
            [-81.2788, 40.9884],
            [-81.0988, 40.9881],
            [-81.0866, 40.9471],
            [-81.1348, 40.9019],
            [-81.0867, 40.8863],
            [-81.0868, 40.8139],
            [-81.1936, 40.815],
            [-81.1885, 40.8994],
            [-81.3026, 40.9031],
            [-81.3037, 40.9133],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904988',
        NAME: 'Marlington Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229384649,
        AWATER: 7178213,
        INTPTLAT: '+40.9152206',
        INTPTLON: '-081.1773317',
        ELSDLEA: '',
        UNSDLEA: '04988',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4692, 39.9272],
            [-82.346, 39.9324],
            [-82.292, 39.9891],
            [-82.2586, 39.9892],
            [-82.234, 39.9133],
            [-82.1624, 39.9094],
            [-82.17, 39.8207],
            [-82.2482, 39.7433],
            [-82.3661, 39.7425],
            [-82.3966, 39.8331],
            [-82.4694, 39.8659],
            [-82.4692, 39.9272],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904905',
        NAME: 'Northern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 442469642,
        AWATER: 4473051,
        INTPTLAT: '+39.8485275',
        INTPTLON: '-082.3067795',
        ELSDLEA: '',
        UNSDLEA: '04905',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8537, 40.1007],
            [-82.838, 40.1067],
            [-82.8536, 40.1074],
            [-82.8514, 40.1297],
            [-82.7713, 40.1264],
            [-82.7628, 40.0883],
            [-82.7675, 40.051],
            [-82.815, 40.0532],
            [-82.8303, 40.0539],
            [-82.8435, 40.0544],
            [-82.8584, 40.0551],
            [-82.8622, 40.0552],
            [-82.8636, 40.0566],
            [-82.8692, 40.0603],
            [-82.8695, 40.0604],
            [-82.8694, 40.0665],
            [-82.8729, 40.0684],
            [-82.8537, 40.1007],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904699',
        NAME: 'New Albany-Plain Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59390460,
        AWATER: 784635,
        INTPTLAT: '+40.0887199',
        INTPTLON: '-082.8054140',
        ELSDLEA: '',
        UNSDLEA: '04699',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3804, 40.0524],
            [-82.3753, 40.103],
            [-82.2817, 40.1698],
            [-82.1871, 40.1669],
            [-82.1948, 40.0207],
            [-82.2586, 39.9892],
            [-82.292, 39.9891],
            [-82.3693, 40.0301],
            [-82.3804, 40.0524],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904801',
        NAME: 'Licking Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277610517,
        AWATER: 2115762,
        INTPTLAT: '+40.0897147',
        INTPTLON: '-082.2771298',
        ELSDLEA: '',
        UNSDLEA: '04801',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4812, 40.2951],
            [-82.3683, 40.3146],
            [-82.2814, 40.2001],
            [-82.2817, 40.1698],
            [-82.3753, 40.103],
            [-82.4574, 40.1073],
            [-82.5673, 40.12],
            [-82.5618, 40.1914],
            [-82.4674, 40.1871],
            [-82.4812, 40.2951],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904802',
        NAME: 'North Fork Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 346906372,
        AWATER: 1976266,
        INTPTLAT: '+40.2060510',
        INTPTLON: '-082.4155514',
        ELSDLEA: '',
        UNSDLEA: '04802',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2814, 40.2001],
            [-82.1959, 40.2391],
            [-82.1869, 40.4061],
            [-82.1621, 40.4554],
            [-81.8949, 40.4206],
            [-81.8045, 40.4468],
            [-81.7898, 40.3953],
            [-81.8108, 40.2814],
            [-81.8513, 40.2838],
            [-81.8706, 40.2511],
            [-81.8217, 40.253],
            [-81.8094, 40.1552],
            [-81.8099, 40.1472],
            [-81.9404, 40.1878],
            [-82.0912, 40.1985],
            [-82.1871, 40.1669],
            [-82.2817, 40.1698],
            [-82.2814, 40.2001],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904648',
        NAME: 'River View Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 969114017,
        AWATER: 5287850,
        INTPTLAT: '+40.2997255',
        INTPTLON: '-082.0222742',
        ELSDLEA: '',
        UNSDLEA: '04648',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7559, 40.2068],
            [-82.626, 40.1928],
            [-82.6269, 40.1205],
            [-82.7129, 40.0862],
            [-82.7628, 40.0883],
            [-82.7713, 40.1264],
            [-82.7559, 40.2068],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904798',
        NAME: 'Johnstown-Monroe Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133312422,
        AWATER: 549333,
        INTPTLAT: '+40.1505118',
        INTPTLON: '-082.6966326',
        ELSDLEA: '',
        UNSDLEA: '04798',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4585, 40.5962],
            [-83.4198, 40.6453],
            [-83.3758, 40.6507],
            [-83.3231, 40.6092],
            [-83.1591, 40.5893],
            [-83.1448, 40.5643],
            [-83.1419, 40.4837],
            [-83.1137, 40.437],
            [-83.249, 40.4246],
            [-83.2709, 40.5069],
            [-83.4184, 40.5052],
            [-83.4585, 40.5962],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904841',
        NAME: 'Elgin Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343048723,
        AWATER: 330073,
        INTPTLAT: '+40.5441568',
        INTPTLON: '-083.2801936',
        ELSDLEA: '',
        UNSDLEA: '04841',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0257, 40.2886],
            [-83.0991, 40.2615],
            [-83.124, 40.33],
            [-83.0307, 40.3845],
            [-83.0257, 40.2886],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904387',
        NAME: 'Delaware City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90596182,
        AWATER: 2691053,
        INTPTLAT: '+40.3166285',
        INTPTLON: '-083.0728812',
        ELSDLEA: '',
        UNSDLEA: '04387',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0991, 40.2615],
            [-83.0257, 40.2886],
            [-82.9259, 40.2846],
            [-82.9127, 40.1796],
            [-82.9713, 40.1349],
            [-83.0805, 40.1389],
            [-83.1292, 40.1797],
            [-83.0991, 40.2615],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904676',
        NAME: 'Olentangy Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233144910,
        AWATER: 13969066,
        INTPTLAT: '+40.2069691',
        INTPTLON: '-083.0178867',
        ELSDLEA: '',
        UNSDLEA: '04676',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1407, 38.8561],
            [-82.2216, 38.7872],
            [-82.1867, 38.6686],
            [-82.2586, 38.6717],
            [-82.2597, 38.7592],
            [-82.3471, 38.7634],
            [-82.3384, 38.8521],
            [-82.1407, 38.8561],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904403',
        NAME: 'Gallipolis City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253698073,
        AWATER: 5005929,
        INTPTLAT: '+38.8097714',
        INTPTLON: '-082.2789163',
        ELSDLEA: '',
        UNSDLEA: '04403',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5229, 40.8218],
            [-81.4927, 40.8205],
            [-81.517, 40.7473],
            [-81.5287, 40.7472],
            [-81.5229, 40.8218],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904435',
        NAME: 'Massillon City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34649118,
        AWATER: 364068,
        INTPTLAT: '+40.7913157',
        INTPTLON: '-081.5322488',
        ELSDLEA: '',
        UNSDLEA: '04435',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4302, 40.7296],
            [-81.4268, 40.7888],
            [-81.3084, 40.8204],
            [-81.3089, 40.8145],
            [-81.2949, 40.7257],
            [-81.3962, 40.7066],
            [-81.431, 40.7151],
            [-81.4302, 40.7296],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904983',
        NAME: 'Canton Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94351535,
        AWATER: 69017,
        INTPTLAT: '+40.7456056',
        INTPTLON: '-081.3592664',
        ELSDLEA: '',
        UNSDLEA: '04983',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4927, 40.8205],
            [-81.4455, 40.819],
            [-81.4251, 40.8182],
            [-81.4268, 40.7888],
            [-81.4302, 40.7296],
            [-81.517, 40.7473],
            [-81.4927, 40.8205],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904992',
        NAME: 'Perry Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 62218887,
        AWATER: 492383,
        INTPTLAT: '+40.7731117',
        INTPTLON: '-081.4634280',
        ELSDLEA: '',
        UNSDLEA: '04992',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8598, 40.1165],
            [-80.7354, 40.0821],
            [-80.7325, 40.0559],
            [-80.8308, 40.0676],
            [-80.8598, 40.1165],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904523',
        NAME: 'Bridgeport Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42252080,
        AWATER: 382750,
        INTPTLAT: '+40.0833564',
        INTPTLON: '-080.7970212',
        ELSDLEA: '',
        UNSDLEA: '04523',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9776, 40.1466],
            [-80.9192, 40.1608],
            [-80.8624, 40.1588],
            [-80.8598, 40.1165],
            [-80.8308, 40.0676],
            [-80.8953, 39.9842],
            [-81.0032, 40.061],
            [-80.9776, 40.1466],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904599',
        NAME: 'St. Clairsville-Richland City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200078692,
        AWATER: 2134142,
        INTPTLAT: '+40.0802068',
        INTPTLON: '-080.9109293',
        ELSDLEA: '',
        UNSDLEA: '04599',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6141, 40.4101],
            [-81.5422, 40.499],
            [-81.4304, 40.5304],
            [-81.402, 40.508],
            [-81.3829, 40.4512],
            [-81.5516, 40.3666],
            [-81.616, 40.3681],
            [-81.6141, 40.4101],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904448',
        NAME: 'New Philadelphia City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183615074,
        AWATER: 1079468,
        INTPTLAT: '+40.4436677',
        INTPTLON: '-081.5047111',
        ELSDLEA: '',
        UNSDLEA: '04448',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5516, 40.3666],
            [-81.3829, 40.4512],
            [-81.402, 40.508],
            [-81.3217, 40.4781],
            [-81.3749, 40.4276],
            [-81.4098, 40.3039],
            [-81.3354, 40.2882],
            [-81.3381, 40.2143],
            [-81.487, 40.2179],
            [-81.505, 40.2185],
            [-81.5113, 40.2187],
            [-81.518, 40.2188],
            [-81.5613, 40.2876],
            [-81.5516, 40.3666],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905028',
        NAME: 'Indian Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 354735792,
        AWATER: 3766314,
        INTPTLAT: '+40.3267382',
        INTPTLON: '-081.4416047',
        ELSDLEA: '',
        UNSDLEA: '05028',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.431, 40.7151],
            [-81.3962, 40.7066],
            [-81.4133, 40.6152],
            [-81.3191, 40.6155],
            [-81.2686, 40.5652],
            [-81.2664, 40.5297],
            [-81.2684, 40.4767],
            [-81.3217, 40.4781],
            [-81.402, 40.508],
            [-81.4304, 40.5304],
            [-81.5016, 40.5831],
            [-81.5106, 40.6504],
            [-81.431, 40.7151],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905030',
        NAME: 'Tuscarawas Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244385516,
        AWATER: 2232635,
        INTPTLAT: '+40.5885344',
        INTPTLON: '-081.3964050',
        ELSDLEA: '',
        UNSDLEA: '05030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4921, 41.0236],
            [-81.4292, 41.0668],
            [-81.393, 41.0663],
            [-81.3929, 41.0662],
            [-81.417, 41.046],
            [-81.3932, 41.0376],
            [-81.3817, 40.9886],
            [-81.4163, 40.9885],
            [-81.5011, 40.9937],
            [-81.4921, 41.0236],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905006',
        NAME: 'Springfield Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 49971348,
        AWATER: 1516624,
        INTPTLAT: '+41.0065131',
        INTPTLON: '-081.4385475',
        ELSDLEA: '',
        UNSDLEA: '05006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1127, 40.8919],
            [-83.0742, 40.9936],
            [-82.9779, 40.9866],
            [-82.9401, 40.9067],
            [-82.9499, 40.8195],
            [-82.998, 40.8198],
            [-82.9688, 40.7758],
            [-83.0044, 40.7032],
            [-83.0828, 40.7029],
            [-83.1883, 40.7698],
            [-83.1766, 40.8479],
            [-83.1127, 40.8919],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904652',
        NAME: 'Wynford Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 433174117,
        AWATER: 1301416,
        INTPTLAT: '+40.8410717',
        INTPTLON: '-083.0351277',
        ELSDLEA: '',
        UNSDLEA: '04652',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9688, 40.7758],
            [-82.9499, 40.8195],
            [-82.9401, 40.9067],
            [-82.8225, 40.9079],
            [-82.7636, 40.8433],
            [-82.7654, 40.7614],
            [-82.8582, 40.7124],
            [-82.8583, 40.705],
            [-82.9757, 40.7035],
            [-83.0044, 40.7032],
            [-82.9688, 40.7758],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904651',
        NAME: 'Colonel Crawford Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 281401113,
        AWATER: 413330,
        INTPTLAT: '+40.8060647',
        INTPTLON: '-082.8793741',
        ELSDLEA: '',
        UNSDLEA: '04651',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.906, 41.203],
            [-80.8481, 41.2031],
            [-80.8193, 41.2023],
            [-80.8112, 41.1342],
            [-80.906, 41.1344],
            [-80.906, 41.203],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905020',
        NAME: 'Lordstown Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60141185,
        AWATER: 7771,
        INTPTLAT: '+41.1709757',
        INTPTLON: '-080.8662706',
        ELSDLEA: '',
        UNSDLEA: '05020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6733, 41.1338],
            [-80.6168, 41.1334],
            [-80.519, 41.1334],
            [-80.519, 41.1251],
            [-80.519, 41.0829],
            [-80.5594, 41.062],
            [-80.5684, 41.07],
            [-80.5773, 41.0904],
            [-80.6128, 41.091],
            [-80.6009, 41.0642],
            [-80.6157, 41.0431],
            [-80.6289, 41.0509],
            [-80.705, 41.0615],
            [-80.7111, 41.0611],
            [-80.7111, 41.1308],
            [-80.6733, 41.1338],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904516',
        NAME: 'Youngstown City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 116552318,
        AWATER: 1829348,
        INTPTLAT: '+41.0979663',
        INTPTLON: '-080.6204229',
        ELSDLEA: '',
        UNSDLEA: '04516',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0026, 41.1927],
            [-81.0026, 41.2026],
            [-80.906, 41.203],
            [-80.906, 41.1344],
            [-81.0023, 41.1342],
            [-81.0026, 41.1927],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904556',
        NAME: 'Newton Falls Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58123587,
        AWATER: 535262,
        INTPTLAT: '+41.1693300',
        INTPTLON: '-080.9481745',
        ELSDLEA: '',
        UNSDLEA: '04556',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7112, 41.203],
            [-80.6168, 41.203],
            [-80.6168, 41.1334],
            [-80.6733, 41.1338],
            [-80.7107, 41.1809],
            [-80.7112, 41.203],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905019',
        NAME: 'Liberty Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46968556,
        AWATER: 909347,
        INTPTLAT: '+41.1742523',
        INTPTLON: '-080.6591891',
        ELSDLEA: '',
        UNSDLEA: '05019',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4161, 41.3992],
            [-83.2498, 41.4291],
            [-83.2544, 41.3413],
            [-83.3586, 41.3064],
            [-83.4171, 41.3483],
            [-83.4161, 41.3992],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904538',
        NAME: 'Gibsonburg Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149615028,
        AWATER: 1482050,
        INTPTLAT: '+41.3644357',
        INTPTLON: '-083.3271702',
        ELSDLEA: '',
        UNSDLEA: '04538',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8111, 41.565],
            [-82.7307, 41.5032],
            [-82.8508, 41.4995],
            [-82.8111, 41.565],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904893',
        NAME: 'Danbury Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47086958,
        AWATER: 3318703,
        INTPTLAT: '+41.5196059',
        INTPTLON: '-082.7945682',
        ELSDLEA: '',
        UNSDLEA: '04893',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8356, 41.7108],
            [-82.814, 41.7237],
            [-82.8088, 41.7081],
            [-82.8356, 41.7108],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904896',
        NAME: 'North Bass Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2766097,
        AWATER: 182974,
        INTPTLAT: '+41.7135386',
        INTPTLON: '-082.8260021',
        ELSDLEA: '',
        UNSDLEA: '04896',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5276, 41.6019],
            [-83.4157, 41.6019],
            [-83.4146, 41.5076],
            [-83.5239, 41.5146],
            [-83.5276, 41.6019],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905069',
        NAME: 'Lake Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 95152037,
        AWATER: 434685,
        INTPTLAT: '+41.5516076',
        INTPTLON: '-083.4679595',
        ELSDLEA: '',
        UNSDLEA: '05069',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6184, 40.8999],
            [-80.5191, 40.9003],
            [-80.519, 40.8513],
            [-80.519, 40.7838],
            [-80.574, 40.8129],
            [-80.632, 40.8706],
            [-80.6184, 40.8999],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904392',
        NAME: 'East Palestine City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78787184,
        AWATER: 449132,
        INTPTLAT: '+40.8456992',
        INTPTLON: '-080.5610800',
        ELSDLEA: '',
        UNSDLEA: '04392',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8225, 40.8138],
            [-80.7462, 40.8133],
            [-80.7146, 40.8132],
            [-80.747, 40.7265],
            [-80.816, 40.7558],
            [-80.8225, 40.8138],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904545',
        NAME: 'Lisbon Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63922461,
        AWATER: 96360,
        INTPTLAT: '+40.7781009',
        INTPTLON: '-080.7644322',
        ELSDLEA: '',
        UNSDLEA: '04545',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7457, 40.9005],
            [-80.7293, 40.9004],
            [-80.632, 40.8706],
            [-80.574, 40.8129],
            [-80.7146, 40.8132],
            [-80.7462, 40.8133],
            [-80.7457, 40.9005],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904643',
        NAME: 'Crestview Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 98171034,
        AWATER: 190235,
        INTPTLAT: '+40.8452932',
        INTPTLON: '-080.6663566',
        ELSDLEA: '',
        UNSDLEA: '04643',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0178, 40.8138],
            [-80.8968, 40.8715],
            [-80.8183, 40.8646],
            [-80.8225, 40.8138],
            [-80.816, 40.7558],
            [-80.9172, 40.7269],
            [-80.9748, 40.727],
            [-81.0178, 40.8138],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904645',
        NAME: 'United Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208304742,
        AWATER: 2411560,
        INTPTLAT: '+40.7977157',
        INTPTLON: '-080.9010212',
        ELSDLEA: '',
        UNSDLEA: '04645',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8564, 40.93],
            [-80.8071, 40.9009],
            [-80.8183, 40.8646],
            [-80.8968, 40.8715],
            [-80.8564, 40.93],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910006',
        NAME: 'Salem City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46971575,
        AWATER: 49665,
        INTPTLAT: '+40.8980790',
        INTPTLON: '-080.8525556',
        ELSDLEA: '',
        UNSDLEA: '10006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2417, 39.9571],
            [-83.1171, 39.9806],
            [-83.1133, 39.9859],
            [-83.1121, 39.9861],
            [-83.1116, 39.986],
            [-83.1112, 39.9814],
            [-83.1098, 39.9802],
            [-83.0929, 39.9925],
            [-83.0923, 39.9927],
            [-83.0917, 39.9929],
            [-83.0916, 39.993],
            [-83.0858, 39.9949],
            [-83.1025, 39.9477],
            [-83.0178, 39.8809],
            [-83.0113, 39.8237],
            [-83.0165, 39.8114],
            [-83.0131, 39.8044],
            [-83.1696, 39.7994],
            [-83.2009, 39.8107],
            [-83.2507, 39.9035],
            [-83.2417, 39.9571],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904480',
        NAME: 'South-Western City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 304517012,
        AWATER: 4755710,
        INTPTLAT: '+39.8862604',
        INTPTLON: '-083.1252210',
        ELSDLEA: '',
        UNSDLEA: '04480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.644, 41.677],
            [-83.6289, 41.6701],
            [-83.6622, 41.6692],
            [-83.644, 41.677],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904821',
        NAME: 'Ottawa Hills Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4854030,
        AWATER: 41563,
        INTPTLAT: '+41.6700562',
        INTPTLON: '-083.6413065',
        ELSDLEA: '',
        UNSDLEA: '04821',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4656, 41.6892],
            [-83.3359, 41.7061],
            [-83.165, 41.6232],
            [-83.2994, 41.6218],
            [-83.4159, 41.6189],
            [-83.5056, 41.6178],
            [-83.4656, 41.6892],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904460',
        NAME: 'Oregon City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156002952,
        AWATER: 2113385,
        INTPTLAT: '+41.6565293',
        INTPTLON: '-083.3611341',
        ELSDLEA: '',
        UNSDLEA: '04460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.6924, 41.6611],
              [-83.6622, 41.6692],
              [-83.6289, 41.6701],
              [-83.4673, 41.7324],
              [-83.4554, 41.7328],
              [-83.4656, 41.6892],
              [-83.5056, 41.6178],
              [-83.543, 41.6179],
              [-83.588, 41.6009],
              [-83.6064, 41.5803],
              [-83.6772, 41.5947],
              [-83.6924, 41.6611],
            ],
          ],
          [
            [
              [-83.807, 41.6579],
              [-83.7798, 41.6582],
              [-83.7605, 41.6586],
              [-83.7864, 41.6063],
              [-83.807, 41.6579],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904490',
        NAME: 'Toledo City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 173226504,
        AWATER: 8047323,
        INTPTLAT: '+41.6518147',
        INTPTLON: '-083.6022233',
        ELSDLEA: '',
        UNSDLEA: '04490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8829, 41.5159],
            [-83.7863, 41.6043],
            [-83.6853, 41.5938],
            [-83.682, 41.5458],
            [-83.6954, 41.4924],
            [-83.8261, 41.4737],
            [-83.8829, 41.4788],
            [-83.8829, 41.5159],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904820',
        NAME: 'Anthony Wayne Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185255377,
        AWATER: 2987100,
        INTPTLAT: '+41.5268151',
        INTPTLON: '-083.7833184',
        ELSDLEA: '',
        UNSDLEA: '04820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6459, 41.7275],
            [-83.6457, 41.7275],
            [-83.5345, 41.7307],
            [-83.4673, 41.7324],
            [-83.6289, 41.6701],
            [-83.644, 41.677],
            [-83.6459, 41.7275],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904823',
        NAME: 'Washington Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47476614,
        AWATER: 1304508,
        INTPTLAT: '+41.7155199',
        INTPTLON: '-083.5144486',
        ELSDLEA: '',
        UNSDLEA: '04823',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8829, 41.4788],
            [-83.8261, 41.4737],
            [-83.6954, 41.4924],
            [-83.643, 41.4727],
            [-83.6596, 41.4369],
            [-83.7666, 41.3209],
            [-83.8834, 41.3353],
            [-83.8835, 41.3426],
            [-83.9264, 41.4173],
            [-83.8829, 41.4788],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905072',
        NAME: 'Otsego Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259056097,
        AWATER: 5654333,
        INTPTLAT: '+41.4086326',
        INTPTLON: '-083.7918821',
        ELSDLEA: '',
        UNSDLEA: '05072',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2609, 40.0028],
            [-83.1916, 40.1074],
            [-83.1556, 40.0759],
            [-83.1255, 40.067],
            [-83.1119, 40.0634],
            [-83.1038, 40.0615],
            [-83.0794, 39.9974],
            [-83.0858, 39.9949],
            [-83.0916, 39.993],
            [-83.0917, 39.9929],
            [-83.0923, 39.9927],
            [-83.0929, 39.9925],
            [-83.1098, 39.9802],
            [-83.1112, 39.9814],
            [-83.1116, 39.986],
            [-83.1121, 39.9861],
            [-83.1133, 39.9859],
            [-83.115, 39.9857],
            [-83.1189, 39.9841],
            [-83.1171, 39.9806],
            [-83.2417, 39.9571],
            [-83.2609, 40.0028],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904701',
        NAME: 'Hilliard City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150822950,
        AWATER: 2056327,
        INTPTLAT: '+40.0205081',
        INTPTLON: '-083.1774386',
        ELSDLEA: '',
        UNSDLEA: '04701',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8033, 40.9894],
            [-84.5726, 40.9826],
            [-84.572, 40.9028],
            [-84.6672, 40.901],
            [-84.6889, 40.786],
            [-84.8024, 40.7717],
            [-84.8023, 40.8347],
            [-84.8029, 40.9226],
            [-84.8033, 40.9894],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905035',
        NAME: 'Crestview Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 330959052,
        AWATER: 629768,
        INTPTLAT: '+40.8950958',
        INTPTLON: '-084.7146567',
        ELSDLEA: '',
        UNSDLEA: '05035',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6398, 39.7528],
            [-81.4097, 39.7545],
            [-81.3545, 39.6847],
            [-81.3664, 39.6011],
            [-81.5882, 39.587],
            [-81.6432, 39.6657],
            [-81.6398, 39.7528],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904525',
        NAME: 'Caldwell Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403799436,
        AWATER: 1903251,
        INTPTLAT: '+39.6834553',
        INTPTLON: '-081.5107994',
        ELSDLEA: '',
        UNSDLEA: '04525',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4686, 41.3841],
            [-82.2522, 41.4367],
            [-82.2673, 41.4108],
            [-82.3452, 41.3574],
            [-82.4439, 41.3572],
            [-82.4686, 41.3841],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904682',
        NAME: 'Vermilion Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76046861,
        AWATER: 617264,
        INTPTLAT: '+41.3920840',
        INTPTLON: '-082.3774545',
        ELSDLEA: '',
        UNSDLEA: '04682',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3813, 41.6167],
            [-84.23, 41.6169],
            [-84.2684, 41.5869],
            [-84.2115, 41.4858],
            [-84.2285, 41.4278],
            [-84.3417, 41.4276],
            [-84.3809, 41.572],
            [-84.3813, 41.6167],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904704',
        NAME: 'Archbold Area Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201444117,
        AWATER: 1061068,
        INTPTLAT: '+41.5267994',
        INTPTLON: '-084.3025572',
        ELSDLEA: '',
        UNSDLEA: '04704',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7863, 41.6043],
            [-83.7864, 41.6063],
            [-83.7605, 41.6586],
            [-83.6924, 41.6611],
            [-83.6772, 41.5947],
            [-83.6853, 41.5938],
            [-83.7863, 41.6043],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904822',
        NAME: 'Springfield Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 57847773,
        AWATER: 137475,
        INTPTLAT: '+41.6195135',
        INTPTLON: '-083.7298103',
        ELSDLEA: '',
        UNSDLEA: '04822',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3652, 39.5895],
            [-84.3275, 39.6148],
            [-84.2857, 39.5892],
            [-84.3321, 39.5488],
            [-84.3647, 39.5438],
            [-84.3652, 39.5895],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905041',
        NAME: 'Carlisle Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27951023,
        AWATER: 1282048,
        INTPTLAT: '+39.5834520',
        INTPTLON: '-084.3306567',
        ELSDLEA: '',
        UNSDLEA: '05041',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5194, 41.1359],
            [-81.4897, 41.1604],
            [-81.453, 41.1244],
            [-81.465, 41.1189],
            [-81.5194, 41.1359],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904383',
        NAME: 'Cuyahoga Falls City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24782635,
        AWATER: 551703,
        INTPTLAT: '+41.1414569',
        INTPTLON: '-081.4796720',
        ELSDLEA: '',
        UNSDLEA: '04383',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7328, 41.1511],
            [-80.712, 41.168],
            [-80.7107, 41.1809],
            [-80.6733, 41.1338],
            [-80.7111, 41.1308],
            [-80.7383, 41.134],
            [-80.7328, 41.1511],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904406',
        NAME: 'Girard City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17182228,
        AWATER: 123731,
        INTPTLAT: '+41.1507489',
        INTPTLON: '-080.7034534',
        ELSDLEA: '',
        UNSDLEA: '04406',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.616, 40.3681],
            [-81.5516, 40.3666],
            [-81.5613, 40.2876],
            [-81.518, 40.2188],
            [-81.5481, 40.1651],
            [-81.6494, 40.1504],
            [-81.6491, 40.2958],
            [-81.616, 40.3681],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904554',
        NAME: 'Newcomerstown Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204414781,
        AWATER: 1405393,
        INTPTLAT: '+40.2522946',
        INTPTLON: '-081.5958815',
        ELSDLEA: '',
        UNSDLEA: '04554',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8045, 40.4468],
            [-81.6697, 40.4439],
            [-81.6492, 40.6351],
            [-81.577, 40.6233],
            [-81.5699, 40.565],
            [-81.5422, 40.499],
            [-81.6141, 40.4101],
            [-81.6807, 40.3701],
            [-81.7898, 40.3953],
            [-81.8045, 40.4468],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905027',
        NAME: 'Garaway Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282425163,
        AWATER: 112934,
        INTPTLAT: '+40.4916735',
        INTPTLON: '-081.6498774',
        ELSDLEA: '',
        UNSDLEA: '05027',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8264, 41.6848],
            [-82.8042, 41.6894],
            [-82.806, 41.6715],
            [-82.8264, 41.6848],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904895',
        NAME: 'Middle Bass Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3633591,
        AWATER: 0,
        INTPTLAT: '+41.6847433',
        INTPTLON: '-082.8113694',
        ELSDLEA: '',
        UNSDLEA: '04895',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8439, 41.6459],
            [-82.8351, 41.6564],
            [-82.8127, 41.6448],
            [-82.8352, 41.6292],
            [-82.8439, 41.6459],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904897',
        NAME: 'Put-in-Bay Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6371915,
        AWATER: 0,
        INTPTLAT: '+41.6478871',
        INTPTLON: '-082.8236346',
        ELSDLEA: '',
        UNSDLEA: '04897',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8665, 40.3926],
            [-80.6755, 40.3841],
            [-80.6108, 40.3409],
            [-80.615, 40.2916],
            [-80.8756, 40.2918],
            [-80.8665, 40.3926],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904780',
        NAME: 'Indian Creek Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189620033,
        AWATER: 1211933,
        INTPTLAT: '+40.3304963',
        INTPTLON: '-080.7423200',
        ELSDLEA: '',
        UNSDLEA: '04780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6168, 41.203],
            [-80.5191, 41.203],
            [-80.519, 41.1878],
            [-80.519, 41.1334],
            [-80.6168, 41.1334],
            [-80.6168, 41.203],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904542',
        NAME: 'Hubbard Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64369912,
        AWATER: 283245,
        INTPTLAT: '+41.1668751',
        INTPTLON: '-080.5674430',
        ELSDLEA: '',
        UNSDLEA: '04542',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8193, 41.2023],
            [-80.7939, 41.2127],
            [-80.7886, 41.2032],
            [-80.7417, 41.166],
            [-80.7328, 41.1511],
            [-80.7383, 41.134],
            [-80.8112, 41.1342],
            [-80.8193, 41.2023],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905025',
        NAME: 'Weathersfield Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30553466,
        AWATER: 3237503,
        INTPTLAT: '+41.1572256',
        INTPTLON: '-080.7923856',
        ELSDLEA: '',
        UNSDLEA: '05025',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7886, 41.2032],
            [-80.7112, 41.203],
            [-80.7107, 41.1809],
            [-80.712, 41.168],
            [-80.7417, 41.166],
            [-80.7886, 41.2032],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904449',
        NAME: 'Niles City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22514148,
        AWATER: 14622,
        INTPTLAT: '+41.1865364',
        INTPTLON: '-080.7486728',
        ELSDLEA: '',
        UNSDLEA: '04449',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1005, 41.5716],
            [-81.0028, 41.5643],
            [-81.0031, 41.5017],
            [-81.0031, 41.4259],
            [-81.0033, 41.3479],
            [-81.0985, 41.3475],
            [-81.1005, 41.5716],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904717',
        NAME: 'Cardinal Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201305782,
        AWATER: 2869161,
        INTPTLAT: '+41.4518196',
        INTPTLON: '-081.0367034',
        ELSDLEA: '',
        UNSDLEA: '04717',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3545, 39.6847],
            [-81.2223, 39.8675],
            [-81.1059, 39.8633],
            [-81.0511, 39.912],
            [-80.8984, 39.9399],
            [-80.8079, 39.9145],
            [-80.7931, 39.8638],
            [-80.8699, 39.7636],
            [-80.8335, 39.7208],
            [-80.8804, 39.6207],
            [-80.9438, 39.6069],
            [-81.0387, 39.5399],
            [-81.0368, 39.5721],
            [-81.2627, 39.5768],
            [-81.3664, 39.6011],
            [-81.3545, 39.6847],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904865',
        NAME: 'Switzerland of Ohio Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1406286623,
        AWATER: 7767997,
        INTPTLAT: '+39.7394243',
        INTPTLON: '-081.0640568',
        ELSDLEA: '',
        UNSDLEA: '04865',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6974, 39.7556],
            [-81.6948, 39.8248],
            [-81.5803, 39.8391],
            [-81.5786, 39.8977],
            [-81.4349, 39.8779],
            [-81.3671, 39.9503],
            [-81.2341, 39.9513],
            [-81.2223, 39.8675],
            [-81.3545, 39.6847],
            [-81.4097, 39.7545],
            [-81.6398, 39.7528],
            [-81.6974, 39.7556],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904890',
        NAME: 'Noble Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 605337842,
        AWATER: 12250188,
        INTPTLAT: '+39.8310127',
        INTPTLON: '-081.4367352',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5909, 40.9859],
            [-81.5325, 40.9733],
            [-81.5364, 40.9103],
            [-81.5909, 40.9859],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905000',
        NAME: 'Manchester Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 33934415,
        AWATER: 2520468,
        INTPTLAT: '+40.9466295',
        INTPTLON: '-081.5646091',
        ELSDLEA: '',
        UNSDLEA: '05000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5981, 41.3512],
            [-81.5852, 41.3511],
            [-81.489, 41.3498],
            [-81.4589, 41.2768],
            [-81.5241, 41.2702],
            [-81.5651, 41.2778],
            [-81.5981, 41.3512],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905004',
        NAME: 'Nordonia Hills City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72640644,
        AWATER: 150066,
        INTPTLAT: '+41.3067782',
        INTPTLON: '-081.5270903',
        ELSDLEA: '',
        UNSDLEA: '05004',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.489, 41.3498],
            [-81.3996, 41.3486],
            [-81.3918, 41.2767],
            [-81.4589, 41.2768],
            [-81.489, 41.3498],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905007',
        NAME: 'Twinsburg City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58565457,
        AWATER: 280713,
        INTPTLAT: '+41.3150429',
        INTPTLON: '-081.4376078',
        ELSDLEA: '',
        UNSDLEA: '05007',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5892, 41.0415],
            [-81.5713, 41.0237],
            [-81.608, 40.9946],
            [-81.5892, 41.0415],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904353',
        NAME: 'Barberton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21148585,
        AWATER: 406197,
        INTPTLAT: '+41.0178299',
        INTPTLON: '-081.6018429',
        ELSDLEA: '',
        UNSDLEA: '04353',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5684, 41.07],
            [-80.6009, 41.0642],
            [-80.6128, 41.091],
            [-80.5773, 41.0904],
            [-80.5684, 41.07],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904370',
        NAME: 'Campbell City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9270626,
        AWATER: 81982,
        INTPTLAT: '+41.0779840',
        INTPTLON: '-080.5923588',
        ELSDLEA: '',
        UNSDLEA: '04370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.705, 41.0615],
            [-80.6289, 41.0509],
            [-80.6156, 40.9879],
            [-80.6318, 40.981],
            [-80.6931, 40.9793],
            [-80.705, 41.0615],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904830',
        NAME: 'Boardman Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62469880,
        AWATER: 1013762,
        INTPTLAT: '+41.0171072',
        INTPTLON: '-080.6672242',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6289, 41.0509],
            [-80.6157, 41.0431],
            [-80.5661, 41.0386],
            [-80.5191, 41.0328],
            [-80.519, 40.9874],
            [-80.6156, 40.9879],
            [-80.6289, 41.0509],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904834',
        NAME: 'Poland Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46192991,
        AWATER: 595290,
        INTPTLAT: '+41.0137893',
        INTPTLON: '-080.5780863',
        ELSDLEA: '',
        UNSDLEA: '04834',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8518, 39.8734],
            [-82.8518, 39.8777],
            [-82.8502, 39.8871],
            [-82.8444, 39.8868],
            [-82.8144, 39.8982],
            [-82.8157, 39.8867],
            [-82.8165, 39.878],
            [-82.7471, 39.827],
            [-82.7915, 39.7343],
            [-82.8526, 39.7965],
            [-82.8518, 39.8734],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904694',
        NAME: 'Canal Winchester Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79678952,
        AWATER: 517160,
        INTPTLAT: '+39.8180716',
        INTPTLON: '-082.7895649',
        ELSDLEA: '',
        UNSDLEA: '04694',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0805, 40.1389],
            [-82.9713, 40.1349],
            [-82.9746, 40.1053],
            [-83.0729, 40.0838],
            [-83.0805, 40.1389],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904513',
        NAME: 'Worthington City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49405075,
        AWATER: 535690,
        INTPTLAT: '+40.1104453',
        INTPTLON: '-083.0213550',
        ELSDLEA: '',
        UNSDLEA: '04513',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8248, 39.9271],
            [-82.8174, 39.9267],
            [-82.8099, 39.9116],
            [-82.8044, 39.9075],
            [-82.8144, 39.8982],
            [-82.8444, 39.8868],
            [-82.8502, 39.8871],
            [-82.8518, 39.8777],
            [-82.8518, 39.8734],
            [-82.8526, 39.7965],
            [-82.9377, 39.801],
            [-82.9353, 39.823],
            [-82.935, 39.8267],
            [-82.9348, 39.8301],
            [-82.9344, 39.8408],
            [-82.9492, 39.8897],
            [-82.8248, 39.9271],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904697',
        NAME: 'Groveport Madison Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104247393,
        AWATER: 2124976,
        INTPTLAT: '+39.8621231',
        INTPTLON: '-082.8860069',
        ELSDLEA: '',
        UNSDLEA: '04697',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-82.793, 39.9875],
              [-82.7958, 39.9847],
              [-82.7969, 39.9846],
              [-82.793, 39.9875],
            ],
          ],
          [
            [
              [-82.9033, 40.0573],
              [-82.8637, 40.0553],
              [-82.8622, 40.0552],
              [-82.8584, 40.0551],
              [-82.8435, 40.0544],
              [-82.8303, 40.0539],
              [-82.815, 40.0532],
              [-82.7675, 40.051],
              [-82.8013, 39.9919],
              [-82.9033, 40.0573],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904696',
        NAME: 'Gahanna-Jefferson City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70143857,
        AWATER: 1076059,
        INTPTLAT: '+40.0256985',
        INTPTLON: '-082.8455561',
        ELSDLEA: '',
        UNSDLEA: '04696',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.747, 40.7265],
            [-80.7146, 40.8132],
            [-80.574, 40.8129],
            [-80.519, 40.7838],
            [-80.519, 40.7337],
            [-80.519, 40.6541],
            [-80.6321, 40.655],
            [-80.6748, 40.6551],
            [-80.747, 40.7265],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904642',
        NAME: 'Beaver Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 288216238,
        AWATER: 1167754,
        INTPTLAT: '+40.7306210',
        INTPTLON: '-080.6292553',
        ELSDLEA: '',
        UNSDLEA: '04642',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9609, 39.1887],
            [-81.7561, 39.1806],
            [-81.7473, 39.0954],
            [-81.7933, 39.0404],
            [-81.9722, 39.0408],
            [-81.9609, 39.1887],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904851',
        NAME: 'Eastern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 299543480,
        AWATER: 1850607,
        INTPTLAT: '+39.1150907',
        INTPTLON: '-081.8734504',
        ELSDLEA: '',
        UNSDLEA: '04851',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3229, 39.0277],
            [-82.3147, 39.1166],
            [-82.2013, 39.1109],
            [-82.1922, 39.1997],
            [-81.9609, 39.1887],
            [-81.9722, 39.0408],
            [-82.0005, 39.0268],
            [-82.099, 38.9584],
            [-82.0955, 39.0028],
            [-82.3229, 39.0277],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904852',
        NAME: 'Meigs Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 514805227,
        AWATER: 624389,
        INTPTLAT: '+39.0955995',
        INTPTLON: '-082.1189414',
        ELSDLEA: '',
        UNSDLEA: '04852',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9722, 39.0408],
            [-81.7933, 39.0404],
            [-81.7818, 38.9649],
            [-81.9086, 38.8785],
            [-81.9006, 38.9377],
            [-82.0005, 39.0268],
            [-81.9722, 39.0408],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904853',
        NAME: 'Southern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 202137639,
        AWATER: 5034701,
        INTPTLAT: '+38.9833672',
        INTPTLON: '-081.8781745',
        ELSDLEA: '',
        UNSDLEA: '04853',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0031, 41.5017],
            [-80.8104, 41.5006],
            [-80.8109, 41.4252],
            [-81.0031, 41.4259],
            [-81.0031, 41.5017],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905009',
        NAME: 'Bloomfield-Mespo Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130743541,
        AWATER: 18851,
        INTPTLAT: '+41.4659629',
        INTPTLON: '-080.9136220',
        ELSDLEA: '',
        UNSDLEA: '05009',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6173, 41.2724],
            [-80.5191, 41.2729],
            [-80.5191, 41.268],
            [-80.5191, 41.2431],
            [-80.5189, 41.2194],
            [-80.5192, 41.2085],
            [-80.5191, 41.203],
            [-80.6168, 41.203],
            [-80.6173, 41.2724],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905012',
        NAME: 'Brookfield Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63348013,
        AWATER: 157314,
        INTPTLAT: '+41.2363015',
        INTPTLON: '-080.5618679',
        ELSDLEA: '',
        UNSDLEA: '05012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9054, 41.3482],
            [-80.811, 41.3487],
            [-80.7964, 41.3489],
            [-80.825, 41.2721],
            [-80.9059, 41.2722],
            [-80.9054, 41.3482],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905013',
        NAME: 'Champion Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68142904,
        AWATER: 0,
        INTPTLAT: '+41.3110423',
        INTPTLON: '-080.8575116',
        ELSDLEA: '',
        UNSDLEA: '05013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2985, 41.2753],
            [-81.2986, 41.3473],
            [-81.1914, 41.3476],
            [-81.1137, 41.3474],
            [-81.1921, 41.2739],
            [-81.1928, 41.2021],
            [-81.2995, 41.2071],
            [-81.2985, 41.2753],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904918',
        NAME: 'Crestwood Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189615219,
        AWATER: 2447339,
        INTPTLAT: '+41.2857080',
        INTPTLON: '-081.2226704',
        ELSDLEA: '',
        UNSDLEA: '04918',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1928, 41.2021],
            [-81.1921, 41.2739],
            [-81.1137, 41.3474],
            [-81.0985, 41.3475],
            [-81.0033, 41.3479],
            [-81.0032, 41.3479],
            [-81.0029, 41.2718],
            [-81.0987, 41.2253],
            [-81.1926, 41.1921],
            [-81.1928, 41.2021],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904920',
        NAME: 'James A. Garfield Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139181035,
        AWATER: 952838,
        INTPTLAT: '+41.2793312',
        INTPTLON: '-081.0970655',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3918, 41.2767],
            [-81.2985, 41.2753],
            [-81.2995, 41.2071],
            [-81.2994, 41.2024],
            [-81.3823, 41.2028],
            [-81.3919, 41.2029],
            [-81.3918, 41.2767],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904923',
        NAME: 'Streetsboro City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60695131,
        AWATER: 2267710,
        INTPTLAT: '+41.2396076',
        INTPTLON: '-081.3458624',
        ELSDLEA: '',
        UNSDLEA: '04923',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3012, 41.0649],
            [-81.1943, 41.0633],
            [-81.0988, 40.9955],
            [-81.0988, 40.9881],
            [-81.2788, 40.9884],
            [-81.3027, 40.9885],
            [-81.3012, 41.0649],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904924',
        NAME: 'Waterloo Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143962976,
        AWATER: 1586086,
        INTPTLAT: '+41.0297257',
        INTPTLON: '-081.1999880',
        ELSDLEA: '',
        UNSDLEA: '04924',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4163, 40.9885],
            [-81.3817, 40.9886],
            [-81.3027, 40.9885],
            [-81.2788, 40.9884],
            [-81.3037, 40.9133],
            [-81.3673, 40.905],
            [-81.4178, 40.9506],
            [-81.4163, 40.9885],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904986',
        NAME: 'Lake Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 69000035,
        AWATER: 1063789,
        INTPTLAT: '+40.9548461',
        INTPTLON: '-081.3479556',
        ELSDLEA: '',
        UNSDLEA: '04986',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2338, 39.3301],
            [-83.119, 39.362],
            [-83.0836, 39.3254],
            [-83.145, 39.1876],
            [-83.1511, 39.1879],
            [-83.3535, 39.1976],
            [-83.3448, 39.2514],
            [-83.2338, 39.3301],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904951',
        NAME: 'Paint Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 281187933,
        AWATER: 314506,
        INTPTLAT: '+39.2613942',
        INTPTLON: '-083.2042072',
        ELSDLEA: '',
        UNSDLEA: '04951',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9982, 39.4801],
            [-82.75, 39.4681],
            [-82.7474, 39.3819],
            [-82.8599, 39.3873],
            [-82.9419, 39.3264],
            [-82.982, 39.3672],
            [-82.9982, 39.4801],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904954',
        NAME: 'Zane Trace Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267560524,
        AWATER: 1662564,
        INTPTLAT: '+39.4090157',
        INTPTLON: '-082.8752394',
        ELSDLEA: '',
        UNSDLEA: '04954',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8582, 40.7124],
            [-82.7654, 40.7614],
            [-82.7266, 40.7554],
            [-82.6891, 40.7187],
            [-82.7187, 40.6972],
            [-82.8582, 40.7124],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904402',
        NAME: 'Galion City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75131159,
        AWATER: 227539,
        INTPTLAT: '+40.7281977',
        INTPTLON: '-082.7718652',
        ELSDLEA: '',
        UNSDLEA: '04402',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8694, 40.4275],
            [-82.7732, 40.4704],
            [-82.7295, 40.5511],
            [-82.6428, 40.55],
            [-82.6408, 40.4176],
            [-82.6442, 40.3744],
            [-82.7943, 40.333],
            [-82.8671, 40.3552],
            [-82.8694, 40.4275],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904880',
        NAME: 'Highland Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 310205250,
        AWATER: 371285,
        INTPTLAT: '+40.4256548',
        INTPTLON: '-082.7290712',
        ELSDLEA: '',
        UNSDLEA: '04880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8899, 40.6387],
            [-82.7477, 40.5953],
            [-82.7295, 40.5511],
            [-82.7732, 40.4704],
            [-82.8977, 40.5502],
            [-82.9619, 40.553],
            [-82.8899, 40.6387],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904553',
        NAME: 'Mount Gilead Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198223487,
        AWATER: 340062,
        INTPTLAT: '+40.5566541',
        INTPTLON: '-082.8324170',
        ELSDLEA: '',
        UNSDLEA: '04553',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8583, 40.705],
            [-82.8582, 40.7124],
            [-82.7187, 40.6972],
            [-82.6074, 40.6301],
            [-82.5974, 40.55],
            [-82.6428, 40.55],
            [-82.7295, 40.5511],
            [-82.7477, 40.5953],
            [-82.8899, 40.6387],
            [-82.8583, 40.705],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904881',
        NAME: 'Northmor Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261451222,
        AWATER: 1029205,
        INTPTLAT: '+40.6315714',
        INTPTLON: '-082.7352131',
        ELSDLEA: '',
        UNSDLEA: '04881',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7187, 40.6972],
            [-82.6891, 40.7187],
            [-82.5707, 40.7249],
            [-82.5315, 40.7231],
            [-82.4918, 40.6715],
            [-82.6074, 40.6301],
            [-82.7187, 40.6972],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904943',
        NAME: 'Lexington Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131673842,
        AWATER: 4547114,
        INTPTLAT: '+40.6826260',
        INTPTLON: '-082.6051397',
        ELSDLEA: '',
        UNSDLEA: '04943',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7688, 39.9391],
            [-82.6786, 39.8972],
            [-82.7041, 39.8512],
            [-82.7471, 39.827],
            [-82.8165, 39.878],
            [-82.8157, 39.8867],
            [-82.8144, 39.8982],
            [-82.8044, 39.9075],
            [-82.8099, 39.9116],
            [-82.8174, 39.9267],
            [-82.7688, 39.9391],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904689',
        NAME: 'Pickerington Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98556052,
        AWATER: 328388,
        INTPTLAT: '+39.8886065',
        INTPTLON: '-082.7419271',
        ELSDLEA: '',
        UNSDLEA: '04689',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6472, 39.773],
            [-83.588, 39.7688],
            [-83.5546, 39.8809],
            [-83.5409, 39.9162],
            [-83.3362, 39.8999],
            [-83.2507, 39.9035],
            [-83.2009, 39.8107],
            [-83.2524, 39.6954],
            [-83.6513, 39.7169],
            [-83.6472, 39.773],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904827',
        NAME: 'Madison-Plains Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 640352013,
        AWATER: 588612,
        INTPTLAT: '+39.7977833',
        INTPTLON: '-083.4189206',
        ELSDLEA: '',
        UNSDLEA: '04827',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.0858, 39.9949],
              [-83.0794, 39.9974],
              [-83.0681, 39.9913],
              [-83.0575, 39.9771],
              [-83.0211, 39.9719],
              [-83.0262, 39.9839],
              [-83.0559, 39.9893],
              [-83.082, 40.0614],
              [-83.0729, 40.0838],
              [-82.9746, 40.1053],
              [-82.9392, 40.0871],
              [-82.9033, 40.0573],
              [-82.8013, 39.9919],
              [-82.793, 39.9875],
              [-82.7969, 39.9846],
              [-82.8003, 39.9842],
              [-82.8046, 39.9837],
              [-82.8083, 39.9833],
              [-82.8097, 39.9832],
              [-82.8248, 39.9271],
              [-82.9492, 39.8897],
              [-83.0178, 39.8809],
              [-83.1025, 39.9477],
              [-83.0858, 39.9949],
            ],
            [
              [-82.9499, 39.9683],
              [-82.9424, 39.9483],
              [-82.9253, 39.9474],
              [-82.9228, 39.9766],
              [-82.9499, 39.9683],
            ],
            [
              [-82.9127, 39.9792],
              [-82.9049, 39.9529],
              [-82.871, 39.951],
              [-82.8666, 39.9866],
              [-82.9127, 39.9792],
            ],
          ],
          [
            [
              [-83.1189, 39.9841],
              [-83.115, 39.9857],
              [-83.1133, 39.9859],
              [-83.1171, 39.9806],
              [-83.1189, 39.9841],
            ],
          ],
          [
            [
              [-82.8537, 40.1007],
              [-82.8536, 40.1074],
              [-82.838, 40.1067],
              [-82.8537, 40.1007],
            ],
          ],
          [
            [
              [-82.8636, 40.0566],
              [-82.8622, 40.0552],
              [-82.8637, 40.0553],
              [-82.8636, 40.0566],
            ],
          ],
          [
            [
              [-83.0113, 39.8237],
              [-82.9344, 39.8408],
              [-82.9348, 39.8301],
              [-83.0165, 39.8114],
              [-83.0113, 39.8237],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904380',
        NAME: 'Columbus City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 340394266,
        AWATER: 8631976,
        INTPTLAT: '+39.9814177',
        INTPTLON: '-082.9723053',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0681, 39.9913],
            [-83.0559, 39.9893],
            [-83.0262, 39.9839],
            [-83.0211, 39.9719],
            [-83.0575, 39.9771],
            [-83.0681, 39.9913],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904407',
        NAME: 'Grandview Heights City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4065646,
        AWATER: 29222,
        INTPTLAT: '+39.9804532',
        INTPTLON: '-083.0443517',
        ELSDLEA: '',
        UNSDLEA: '04407',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.082, 40.0614],
            [-83.0559, 39.9893],
            [-83.0681, 39.9913],
            [-83.0794, 39.9974],
            [-83.1038, 40.0615],
            [-83.082, 40.0614],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904493',
        NAME: 'Upper Arlington City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26081535,
        AWATER: 559473,
        INTPTLAT: '+40.0265568',
        INTPTLON: '-083.0708921',
        ELSDLEA: '',
        UNSDLEA: '04493',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.0165, 39.8114],
              [-82.9348, 39.8301],
              [-82.935, 39.8267],
              [-82.9353, 39.823],
              [-82.9377, 39.801],
              [-83.0131, 39.8044],
              [-83.0165, 39.8114],
            ],
          ],
          [
            [
              [-83.0178, 39.8809],
              [-82.9492, 39.8897],
              [-82.9344, 39.8408],
              [-83.0113, 39.8237],
              [-83.0178, 39.8809],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904695',
        NAME: 'Hamilton Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47431906,
        AWATER: 2844436,
        INTPTLAT: '+39.8400324',
        INTPTLON: '-082.9762665',
        ELSDLEA: '',
        UNSDLEA: '04695',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6306, 40.0018],
            [-81.5352, 40.01],
            [-81.3671, 39.9503],
            [-81.4349, 39.8779],
            [-81.5786, 39.8977],
            [-81.5803, 39.8391],
            [-81.6948, 39.8248],
            [-81.7129, 39.8435],
            [-81.6306, 40.0018],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904730',
        NAME: 'Rolling Hills Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324178507,
        AWATER: 6238240,
        INTPTLAT: '+39.9289099',
        INTPTLON: '-081.5438938',
        ELSDLEA: '',
        UNSDLEA: '04730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9146, 39.9747],
            [-81.8182, 40.0093],
            [-81.8099, 40.1472],
            [-81.8094, 40.1552],
            [-81.6844, 40.0991],
            [-81.6306, 40.0018],
            [-81.7129, 39.8435],
            [-81.81, 39.8474],
            [-81.8041, 39.9347],
            [-81.9168, 39.9384],
            [-81.9146, 39.9747],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904883',
        NAME: 'East Muskingum Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 494324790,
        AWATER: 1882643,
        INTPTLAT: '+39.9989748',
        INTPTLON: '-081.7534930',
        ELSDLEA: '',
        UNSDLEA: '04883',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1871, 40.1669],
            [-82.0912, 40.1985],
            [-81.9404, 40.1878],
            [-81.8099, 40.1472],
            [-81.8182, 40.0093],
            [-81.9146, 39.9747],
            [-81.9902, 39.9756],
            [-81.976, 40.0141],
            [-82.1948, 40.0207],
            [-82.1871, 40.1669],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904887',
        NAME: 'Tri-Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 590277752,
        AWATER: 8410189,
        INTPTLAT: '+40.0890398',
        INTPTLON: '-082.0066862',
        ELSDLEA: '',
        UNSDLEA: '04887',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0766, 39.771],
            [-81.6974, 39.7556],
            [-81.6398, 39.7528],
            [-81.6432, 39.6657],
            [-81.6969, 39.5867],
            [-81.7327, 39.4814],
            [-81.8696, 39.4513],
            [-82.027, 39.4586],
            [-82.0529, 39.4919],
            [-82.0475, 39.5508],
            [-82.0321, 39.6367],
            [-82.0242, 39.7247],
            [-82.0766, 39.771],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904877',
        NAME: 'Morgan Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 988203887,
        AWATER: 13471792,
        INTPTLAT: '+39.6249458',
        INTPTLON: '-081.8616967',
        ELSDLEA: '',
        UNSDLEA: '04877',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0289, 39.9428],
            [-81.9902, 39.9756],
            [-81.9146, 39.9747],
            [-81.9168, 39.9384],
            [-82.0025, 39.9107],
            [-82.0289, 39.9428],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904517',
        NAME: 'Zanesville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44814197,
        AWATER: 1244460,
        INTPTLAT: '+39.9475194',
        INTPTLON: '-081.9741643',
        ELSDLEA: '',
        UNSDLEA: '04517',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1624, 39.9094],
            [-82.0289, 39.9428],
            [-82.0025, 39.9107],
            [-82.014, 39.8448],
            [-82.1324, 39.8188],
            [-82.17, 39.8207],
            [-82.1624, 39.9094],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904885',
        NAME: 'Maysville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137285485,
        AWATER: 1022176,
        INTPTLAT: '+39.8856459',
        INTPTLON: '-082.0855019',
        ELSDLEA: '',
        UNSDLEA: '04885',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4356, 39.0315],
            [-82.3229, 39.0277],
            [-82.0955, 39.0028],
            [-82.099, 38.9584],
            [-82.1407, 38.8561],
            [-82.3384, 38.8521],
            [-82.3471, 38.7634],
            [-82.2597, 38.7592],
            [-82.2586, 38.6717],
            [-82.1867, 38.6686],
            [-82.219, 38.5917],
            [-82.2734, 38.5942],
            [-82.3617, 38.5852],
            [-82.3545, 38.6761],
            [-82.4418, 38.6806],
            [-82.4836, 38.7723],
            [-82.5631, 38.7777],
            [-82.5744, 38.8535],
            [-82.4653, 38.8457],
            [-82.4356, 39.0315],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3906568',
        NAME: 'Gallia County Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 984893663,
        AWATER: 6953125,
        INTPTLAT: '+38.9203894',
        INTPTLON: '-082.2171963',
        ELSDLEA: '',
        UNSDLEA: '06568',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6786, 39.8972],
            [-82.5921, 39.9186],
            [-82.51, 39.8679],
            [-82.5967, 39.7912],
            [-82.7041, 39.8512],
            [-82.6786, 39.8972],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904688',
        NAME: 'Liberty Union-Thurston Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138479981,
        AWATER: 0,
        INTPTLAT: '+39.8540165',
        INTPTLON: '-082.6111269',
        ELSDLEA: '',
        UNSDLEA: '04688',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.144, 40.9274],
            [-82.0786, 40.9912],
            [-81.9932, 40.9011],
            [-82.0324, 40.7889],
            [-82.0793, 40.776],
            [-82.1452, 40.8359],
            [-82.1634, 40.8717],
            [-82.144, 40.9274],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910033',
        NAME: 'Northwestern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 237552694,
        AWATER: 885515,
        INTPTLAT: '+40.8848183',
        INTPTLON: '-082.0750228',
        ELSDLEA: '',
        UNSDLEA: '10033',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2498, 41.4291],
            [-83.1926, 41.4584],
            [-83.0675, 41.4572],
            [-83.0386, 41.4575],
            [-82.953, 41.426],
            [-82.9547, 41.358],
            [-83.0691, 41.3415],
            [-83.0927, 41.2568],
            [-83.188, 41.277],
            [-83.2544, 41.3413],
            [-83.2498, 41.4291],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904401',
        NAME: 'Fremont City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358861958,
        AWATER: 8248593,
        INTPTLAT: '+41.3800744',
        INTPTLON: '-083.1096963',
        ELSDLEA: '',
        UNSDLEA: '04401',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5921, 39.9186],
            [-82.5585, 39.9291],
            [-82.4692, 39.9272],
            [-82.4694, 39.8659],
            [-82.51, 39.8679],
            [-82.5921, 39.9186],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904690',
        NAME: 'Walnut Township Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61852920,
        AWATER: 5913343,
        INTPTLAT: '+39.8946027',
        INTPTLON: '-082.5281696',
        ELSDLEA: '',
        UNSDLEA: '04690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.297, 41.0918],
            [-82.2702, 41.0793],
            [-82.2719, 40.9928],
            [-82.144, 40.9274],
            [-82.1634, 40.8717],
            [-82.3394, 40.9019],
            [-82.3333, 40.9929],
            [-82.4329, 40.9929],
            [-82.4368, 41.0624],
            [-82.297, 41.0918],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904583',
        NAME: 'Mapleton Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258677324,
        AWATER: 1788979,
        INTPTLAT: '+40.9750665',
        INTPTLON: '-082.2960916',
        ELSDLEA: '',
        UNSDLEA: '04583',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2702, 41.0793],
            [-82.1699, 41.1371],
            [-82.0743, 41.1365],
            [-82.0786, 40.9937],
            [-82.0786, 40.9912],
            [-82.144, 40.9274],
            [-82.2719, 40.9928],
            [-82.2702, 41.0793],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904846',
        NAME: 'Black River Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292781406,
        AWATER: 2421758,
        INTPTLAT: '+41.0532085',
        INTPTLON: '-082.1768631',
        ELSDLEA: '',
        UNSDLEA: '04846',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1791, 40.5731],
            [-82.1273, 40.7268],
            [-82.1262, 40.7265],
            [-81.9954, 40.6519],
            [-81.996, 40.5945],
            [-81.8787, 40.5959],
            [-81.8045, 40.4468],
            [-81.8949, 40.4206],
            [-82.1621, 40.4554],
            [-82.1791, 40.5731],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904769',
        NAME: 'West Holmes Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 625816793,
        AWATER: 2773855,
        INTPTLAT: '+40.5392934',
        INTPTLON: '-082.0129217',
        ELSDLEA: '',
        UNSDLEA: '04769',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2959, 41.5699],
            [-81.2947, 41.64],
            [-81.2626, 41.6313],
            [-81.1014, 41.6415],
            [-81.1005, 41.5716],
            [-81.1899, 41.5004],
            [-81.275, 41.4997],
            [-81.2959, 41.5699],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904718',
        NAME: 'Chardon Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192139940,
        AWATER: 2692098,
        INTPTLAT: '+41.5793173',
        INTPTLON: '-081.2061876',
        ELSDLEA: '',
        UNSDLEA: '04718',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2908, 41.7575],
            [-81.2803, 41.7603],
            [-81.2664, 41.7358],
            [-81.2611, 41.7041],
            [-81.2314, 41.7093],
            [-81.2354, 41.7441],
            [-81.2629, 41.7452],
            [-81.2466, 41.762],
            [-81.1911, 41.7844],
            [-81.1027, 41.7344],
            [-81.1023, 41.7143],
            [-81.1014, 41.6415],
            [-81.2626, 41.6313],
            [-81.2908, 41.7575],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910014',
        NAME: 'Riverside Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163527561,
        AWATER: 1596917,
        INTPTLAT: '+41.6928607',
        INTPTLON: '-081.1939692',
        ELSDLEA: '',
        UNSDLEA: '10014',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7122, 39.1304],
            [-84.6803, 39.1156],
            [-84.6105, 39.1707],
            [-84.5691, 39.206],
            [-84.5311, 39.2168],
            [-84.4924, 39.2146],
            [-84.4658, 39.2179],
            [-84.4552, 39.2115],
            [-84.4135, 39.2203],
            [-84.3852, 39.1995],
            [-84.3905, 39.1929],
            [-84.3808, 39.1628],
            [-84.3721, 39.1523],
            [-84.3921, 39.1365],
            [-84.4235, 39.0519],
            [-84.4263, 39.053],
            [-84.4339, 39.0962],
            [-84.441, 39.1096],
            [-84.4941, 39.1029],
            [-84.5062, 39.0952],
            [-84.5371, 39.0977],
            [-84.572, 39.0824],
            [-84.6238, 39.0744],
            [-84.7122, 39.1304],
          ],
          [
            [-84.5074, 39.1643],
            [-84.4832, 39.163],
            [-84.4913, 39.1895],
            [-84.5052, 39.1801],
            [-84.5074, 39.1643],
          ],
          [
            [-84.4736, 39.162],
            [-84.4428, 39.1448],
            [-84.4353, 39.1701],
            [-84.4736, 39.162],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904375',
        NAME: 'Cincinnati City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230940277,
        AWATER: 4183158,
        INTPTLAT: '+39.1447627',
        INTPTLON: '-084.5035592',
        ELSDLEA: '',
        UNSDLEA: '04375',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5642, 39.2797],
            [-84.518, 39.2341],
            [-84.5304, 39.2229],
            [-84.5684, 39.2223],
            [-84.5642, 39.2797],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904441',
        NAME: 'Mount Healthy City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21224613,
        AWATER: 151,
        INTPTLAT: '+39.2479810',
        INTPTLON: '-084.5522360',
        ELSDLEA: '',
        UNSDLEA: '04441',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3341, 39.2933],
            [-84.3052, 39.2919],
            [-84.2972, 39.2917],
            [-84.3001, 39.2347],
            [-84.3831, 39.2173],
            [-84.4089, 39.2238],
            [-84.4152, 39.2338],
            [-84.3341, 39.2933],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904486',
        NAME: 'Sycamore Community City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44486527,
        AWATER: 232427,
        INTPTLAT: '+39.2532948',
        INTPTLON: '-084.3560929',
        ELSDLEA: '',
        UNSDLEA: '04486',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6819, 39.211],
            [-84.6105, 39.1707],
            [-84.6803, 39.1156],
            [-84.6819, 39.211],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904737',
        NAME: 'Oak Hills Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71339713,
        AWATER: 7860,
        INTPTLAT: '+39.1396678',
        INTPTLON: '-084.6492478',
        ELSDLEA: '',
        UNSDLEA: '04737',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8201, 39.1185],
            [-84.6984, 39.2117],
            [-84.6819, 39.211],
            [-84.6803, 39.1156],
            [-84.7122, 39.1304],
            [-84.8202, 39.1055],
            [-84.8201, 39.1185],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904739',
        NAME: 'Three Rivers Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58965637,
        AWATER: 2401126,
        INTPTLAT: '+39.1649902',
        INTPTLON: '-084.7400248',
        ELSDLEA: '',
        UNSDLEA: '04739',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-81.0987, 41.2253],
              [-81.0028, 41.2295],
              [-81.0026, 41.2026],
              [-81.0026, 41.1927],
              [-81.0983, 41.1625],
              [-81.1927, 41.1774],
              [-81.1926, 41.1921],
              [-81.0987, 41.2253],
            ],
          ],
          [
            [
              [-83.4554, 41.7328],
              [-83.4216, 41.7337],
              [-83.1125, 41.9594],
              [-83.0693, 41.8642],
              [-82.6811, 41.6763],
              [-82.3983, 41.6765],
              [-81.7092, 42],
              [-81.6864, 42],
              [-81.2498, 42.2164],
              [-80.5199, 42.3271],
              [-80.5194, 41.9775],
              [-80.6238, 41.9457],
              [-80.7782, 41.9079],
              [-80.9034, 41.868],
              [-81.0021, 41.8549],
              [-81.1051, 41.8186],
              [-81.1911, 41.7844],
              [-81.2466, 41.762],
              [-81.2803, 41.7603],
              [-81.2908, 41.7575],
              [-81.3886, 41.7073],
              [-81.4886, 41.6315],
              [-81.55, 41.5993],
              [-81.7686, 41.4916],
              [-81.837, 41.4899],
              [-81.8736, 41.4832],
              [-81.9683, 41.5052],
              [-82.0666, 41.5016],
              [-82.1301, 41.4852],
              [-82.2335, 41.4441],
              [-82.2522, 41.4367],
              [-82.4686, 41.3841],
              [-82.5118, 41.3833],
              [-82.6235, 41.4235],
              [-82.6254, 41.4253],
              [-82.6324, 41.4282],
              [-82.6465, 41.4339],
              [-82.7777, 41.4518],
              [-82.953, 41.426],
              [-83.0386, 41.4575],
              [-82.8508, 41.4995],
              [-82.7307, 41.5032],
              [-82.8111, 41.565],
              [-82.9123, 41.5163],
              [-83.0489, 41.5748],
              [-83.165, 41.6232],
              [-83.3359, 41.7061],
              [-83.4656, 41.6892],
              [-83.4554, 41.7328],
            ],
            [
              [-82.8439, 41.6459],
              [-82.8352, 41.6292],
              [-82.8127, 41.6448],
              [-82.8351, 41.6564],
              [-82.8439, 41.6459],
            ],
            [
              [-82.8356, 41.7108],
              [-82.8088, 41.7081],
              [-82.814, 41.7237],
              [-82.8356, 41.7108],
            ],
            [
              [-82.8264, 41.6848],
              [-82.806, 41.6715],
              [-82.8042, 41.6894],
              [-82.8264, 41.6848],
            ],
            [
              [-82.7351, 41.6024],
              [-82.6865, 41.587],
              [-82.6812, 41.6113],
              [-82.7073, 41.6196],
              [-82.7351, 41.6024],
            ],
            [
              [-82.6944, 41.4883],
              [-82.6921, 41.4821],
              [-82.6845, 41.48],
              [-82.6869, 41.4933],
              [-82.6944, 41.4883],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3999997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 81958511,
        AWATER: 9231008537,
        INTPTLAT: '+41.1925178',
        INTPTLON: '-081.1906558',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0489, 41.5748],
            [-82.9123, 41.5163],
            [-82.8111, 41.565],
            [-82.8508, 41.4995],
            [-83.0386, 41.4575],
            [-83.0675, 41.4572],
            [-83.0489, 41.5748],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904465',
        NAME: 'Port Clinton City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 114144596,
        AWATER: 12875696,
        INTPTLAT: '+41.5193096',
        INTPTLON: '-082.9735670',
        ELSDLEA: '',
        UNSDLEA: '04465',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0366, 40.9143],
            [-81.0232, 40.9302],
            [-81.0071, 40.9304],
            [-81.0128, 40.9076],
            [-81.0366, 40.9143],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904835',
        NAME: 'Sebring Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5353917,
        AWATER: 30863,
        INTPTLAT: '+40.9208610',
        INTPTLON: '-081.0230848',
        ELSDLEA: '',
        UNSDLEA: '04835',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6201, 39.564],
            [-82.6183, 39.5957],
            [-82.4961, 39.6028],
            [-82.4903, 39.6617],
            [-82.3598, 39.6542],
            [-82.2567, 39.6483],
            [-82.2741, 39.5626],
            [-82.2897, 39.3842],
            [-82.5145, 39.3958],
            [-82.6344, 39.376],
            [-82.6201, 39.564],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904424',
        NAME: 'Logan-Hocking Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 816388352,
        AWATER: 5380926,
        INTPTLAT: '+39.5110239',
        INTPTLON: '-082.4402998',
        ELSDLEA: '',
        UNSDLEA: '04424',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2897, 39.3842],
            [-82.191, 39.3787],
            [-82.1838, 39.2887],
            [-82.0342, 39.2813],
            [-81.9538, 39.2778],
            [-81.9609, 39.1887],
            [-82.1922, 39.1997],
            [-82.2013, 39.1109],
            [-82.3147, 39.1166],
            [-82.2897, 39.3842],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904590',
        NAME: 'Alexander Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 448596968,
        AWATER: 2489183,
        INTPTLAT: '+39.2412242',
        INTPTLON: '-082.1533294',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3441, 41.2022],
            [-82.2684, 41.2571],
            [-82.1803, 41.2375],
            [-82.1699, 41.1371],
            [-82.2702, 41.0793],
            [-82.297, 41.0918],
            [-82.3694, 41.1506],
            [-82.3441, 41.2022],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904565',
        NAME: 'Wellington Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174461450,
        AWATER: 1430495,
        INTPTLAT: '+41.1848798',
        INTPTLON: '-082.2568315',
        ELSDLEA: '',
        UNSDLEA: '04565',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6141, 41.3586],
            [-82.5118, 41.3833],
            [-82.4686, 41.3841],
            [-82.4439, 41.3572],
            [-82.4357, 41.2839],
            [-82.5698, 41.2712],
            [-82.6351, 41.2866],
            [-82.6895, 41.3135],
            [-82.6141, 41.3586],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904678',
        NAME: 'Edison Local (formerly Berlin-Milan) School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 176181186,
        AWATER: 1095369,
        INTPTLAT: '+41.3132091',
        INTPTLON: '-082.5498158',
        ELSDLEA: '',
        UNSDLEA: '04678',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0005, 40.7311],
            [-83.9943, 40.818],
            [-83.8801, 40.8199],
            [-83.8798, 40.7324],
            [-83.8802, 40.6447],
            [-83.9941, 40.6487],
            [-84.0005, 40.7311],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904575',
        NAME: 'Allen East Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187161181,
        AWATER: 1145855,
        INTPTLAT: '+40.7229684',
        INTPTLON: '-083.9552778',
        ELSDLEA: '',
        UNSDLEA: '04575',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1083, 40.7095],
            [-84.0798, 40.7306],
            [-84.0005, 40.7311],
            [-83.9941, 40.6487],
            [-84.0392, 40.6439],
            [-84.1079, 40.6579],
            [-84.1083, 40.7095],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904578',
        NAME: 'Perry Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 86903907,
        AWATER: 355254,
        INTPTLAT: '+40.6751125',
        INTPTLON: '-084.0414238',
        ELSDLEA: '',
        UNSDLEA: '04578',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3886, 41.7073],
            [-81.2908, 41.7575],
            [-81.2626, 41.6313],
            [-81.2947, 41.64],
            [-81.374, 41.6402],
            [-81.3886, 41.7073],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904549',
        NAME: 'Mentor Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88904539,
        AWATER: 3652775,
        INTPTLAT: '+41.6834834',
        INTPTLON: '-081.3334548',
        ELSDLEA: '',
        UNSDLEA: '04549',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.374, 41.6402],
            [-81.2947, 41.64],
            [-81.2959, 41.5699],
            [-81.391, 41.5697],
            [-81.374, 41.6402],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904787',
        NAME: 'Kirtland Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63101668,
        AWATER: 568716,
        INTPTLAT: '+41.6102693',
        INTPTLON: '-081.3420958',
        ELSDLEA: '',
        UNSDLEA: '04787',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.695, 40.4338],
            [-84.5621, 40.4586],
            [-84.5714, 40.3535],
            [-84.5998, 40.3376],
            [-84.6605, 40.3271],
            [-84.6929, 40.3377],
            [-84.695, 40.4338],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904858',
        NAME: 'St. Henry Consolidated Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127172931,
        AWATER: 287591,
        INTPTLAT: '+40.3994943',
        INTPTLON: '-084.6195929',
        ELSDLEA: '',
        UNSDLEA: '04858',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7655, 40.5822],
            [-84.5707, 40.6406],
            [-84.4561, 40.6408],
            [-84.4552, 40.4816],
            [-84.4836, 40.471],
            [-84.5515, 40.4707],
            [-84.6571, 40.5235],
            [-84.7575, 40.4943],
            [-84.7655, 40.5822],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910030',
        NAME: 'Celina City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 352758426,
        AWATER: 27153191,
        INTPTLAT: '+40.5557419',
        INTPTLON: '-084.5987250',
        ELSDLEA: '',
        UNSDLEA: '10030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8035, 40.4654],
            [-84.695, 40.4338],
            [-84.6929, 40.3377],
            [-84.8038, 40.3436],
            [-84.8035, 40.4654],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904859',
        NAME: 'Fort Recovery Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157641628,
        AWATER: 239927,
        INTPTLAT: '+40.4004860',
        INTPTLON: '-084.7503828',
        ELSDLEA: '',
        UNSDLEA: '04859',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5621, 40.4586],
            [-84.5515, 40.4707],
            [-84.4836, 40.471],
            [-84.4266, 40.4221],
            [-84.4726, 40.3291],
            [-84.5193, 40.3162],
            [-84.5714, 40.3535],
            [-84.5621, 40.4586],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910031',
        NAME: 'Marion Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138796551,
        AWATER: 52181,
        INTPTLAT: '+40.3992684',
        INTPTLON: '-084.5041268',
        ELSDLEA: '',
        UNSDLEA: '10031',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9049, 39.9447],
            [-83.8398, 39.9555],
            [-83.8241, 39.9545],
            [-83.854, 39.9285],
            [-83.8019, 39.8942],
            [-83.7532, 39.9501],
            [-83.7085, 39.8813],
            [-83.7842, 39.8643],
            [-83.8964, 39.9074],
            [-83.9049, 39.9447],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904628',
        NAME: 'Clark-Shawnee Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95904129,
        AWATER: 627824,
        INTPTLAT: '+39.8815294',
        INTPTLON: '-083.7415759',
        ELSDLEA: '',
        UNSDLEA: '04628',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8241, 39.9545],
            [-83.7532, 39.9501],
            [-83.8019, 39.8942],
            [-83.854, 39.9285],
            [-83.8241, 39.9545],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904481',
        NAME: 'Springfield City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44517629,
        AWATER: 66658,
        INTPTLAT: '+39.9292663',
        INTPTLON: '-083.8011934',
        ELSDLEA: '',
        UNSDLEA: '04481',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0373, 39.8495],
            [-83.8964, 39.9074],
            [-83.7842, 39.8643],
            [-83.7895, 39.8209],
            [-83.8483, 39.8239],
            [-83.9413, 39.8288],
            [-84.0373, 39.8495],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904623',
        NAME: 'Greenon Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117896101,
        AWATER: 737409,
        INTPTLAT: '+39.8644434',
        INTPTLON: '-083.8930773',
        ELSDLEA: '',
        UNSDLEA: '04623',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9034, 41.868],
            [-80.7782, 41.9079],
            [-80.7134, 41.8331],
            [-80.8096, 41.789],
            [-80.8985, 41.7892],
            [-80.9034, 41.868],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904351',
        NAME: 'Ashtabula Area City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158656612,
        AWATER: 547586,
        INTPTLAT: '+41.8363729',
        INTPTLON: '-080.8117689',
        ELSDLEA: '',
        UNSDLEA: '04351',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7782, 41.9079],
            [-80.6238, 41.9457],
            [-80.6237, 41.7901],
            [-80.5194, 41.7911],
            [-80.5194, 41.7147],
            [-80.6218, 41.714],
            [-80.7134, 41.8331],
            [-80.7782, 41.9079],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904585',
        NAME: 'Buckeye Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 251831962,
        AWATER: 453836,
        INTPTLAT: '+41.8294641',
        INTPTLON: '-080.6426709',
        ELSDLEA: '',
        UNSDLEA: '04585',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.7963, 41.6256],
            [-80.6207, 41.6438],
            [-80.6218, 41.714],
            [-80.5194, 41.7147],
            [-80.5194, 41.5785],
            [-80.5192, 41.4999],
            [-80.7132, 41.4998],
            [-80.7132, 41.5713],
            [-80.7963, 41.6256],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904588',
        NAME: 'Pymatuning Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370606926,
        AWATER: 14043524,
        INTPTLAT: '+41.5953574',
        INTPTLON: '-080.6279229',
        ELSDLEA: '',
        UNSDLEA: '04588',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0028, 41.5643],
            [-81.0028, 41.642],
            [-80.9353, 41.6422],
            [-80.7963, 41.6256],
            [-80.7132, 41.5713],
            [-80.7132, 41.4998],
            [-80.7135, 41.4998],
            [-80.8104, 41.5006],
            [-81.0031, 41.5017],
            [-81.0028, 41.5643],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904586',
        NAME: 'Grand Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 314893829,
        AWATER: 1188667,
        INTPTLAT: '+41.5671453',
        INTPTLON: '-080.8752795',
        ELSDLEA: '',
        UNSDLEA: '04586',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0021, 41.8549],
            [-80.9034, 41.868],
            [-80.8985, 41.7892],
            [-80.8096, 41.789],
            [-80.9353, 41.6422],
            [-81.0028, 41.642],
            [-81.0036, 41.7151],
            [-81.0021, 41.8549],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904405',
        NAME: 'Geneva Area City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240699807,
        AWATER: 661106,
        INTPTLAT: '+41.7560441',
        INTPTLON: '-080.9319236',
        ELSDLEA: '',
        UNSDLEA: '04405',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0429, 39.9669],
            [-84.0412, 39.9827],
            [-83.9049, 39.9447],
            [-83.8964, 39.9074],
            [-84.0373, 39.8495],
            [-84.0537, 39.8505],
            [-84.051, 39.8798],
            [-84.0429, 39.9669],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904624',
        NAME: 'Tecumseh Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110244436,
        AWATER: 1588837,
        INTPTLAT: '+39.9219161',
        INTPTLON: '-083.9943346',
        ELSDLEA: '',
        UNSDLEA: '04624',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3146, 40.1981],
            [-84.2178, 40.1954],
            [-84.1365, 40.1905],
            [-84.2232, 40.0965],
            [-84.2882, 40.096],
            [-84.3146, 40.1981],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904464',
        NAME: 'Piqua City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132789151,
        AWATER: 1642568,
        INTPTLAT: '+40.1572876',
        INTPTLON: '-084.2314226',
        ELSDLEA: '',
        UNSDLEA: '04464',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4285, 40.0073],
            [-84.296, 40.0089],
            [-84.2673, 39.9855],
            [-84.2748, 39.9218],
            [-84.3019, 39.9214],
            [-84.4265, 39.9426],
            [-84.4285, 40.0073],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904551',
        NAME: 'Milton-Union Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118122438,
        AWATER: 833236,
        INTPTLAT: '+39.9658213',
        INTPTLON: '-084.3444252',
        ELSDLEA: '',
        UNSDLEA: '04551',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2185, 39.1151],
            [-84.1047, 39.0736],
            [-84.1272, 39.0174],
            [-84.1507, 39.0122],
            [-84.2185, 39.1151],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904630',
        NAME: 'Batavia Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63936119,
        AWATER: 4554875,
        INTPTLAT: '+39.0634430',
        INTPTLON: '-084.1682683',
        ELSDLEA: '',
        UNSDLEA: '04630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3168, 39.0462],
            [-84.1567, 39.0031],
            [-84.1563, 38.9149],
            [-84.2273, 38.8213],
            [-84.2304, 38.8274],
            [-84.2319, 38.8749],
            [-84.32, 39.0215],
            [-84.3168, 39.0462],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904555',
        NAME: 'New Richmond Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 164369968,
        AWATER: 2759174,
        INTPTLAT: '+38.9461405',
        INTPTLON: '-084.2213673',
        ELSDLEA: '',
        UNSDLEA: '04555',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1563, 38.9149],
            [-84.0389, 38.8769],
            [-84.0429, 38.8472],
            [-84.0527, 38.7712],
            [-84.2273, 38.8213],
            [-84.1563, 38.9149],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904633',
        NAME: 'Felicity-Franklin Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 162476364,
        AWATER: 3262308,
        INTPTLAT: '+38.8340996',
        INTPTLON: '-084.1229549',
        ELSDLEA: '',
        UNSDLEA: '04633',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3042, 39.1324],
            [-84.26, 39.1351],
            [-84.2185, 39.1151],
            [-84.1507, 39.0122],
            [-84.1567, 39.0031],
            [-84.3168, 39.0462],
            [-84.3042, 39.1324],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904635',
        NAME: 'West Clermont Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123423429,
        AWATER: 575579,
        INTPTLAT: '+39.0595697',
        INTPTLON: '-084.2387434',
        ELSDLEA: '',
        UNSDLEA: '04635',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1911, 41.7844],
            [-81.1051, 41.8186],
            [-81.1027, 41.7344],
            [-81.1911, 41.7844],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904790',
        NAME: 'Perry Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 60405749,
        AWATER: 292655,
        INTPTLAT: '+41.7639385',
        INTPTLON: '-081.1431044',
        ELSDLEA: '',
        UNSDLEA: '04790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8284, 40.3568],
            [-83.7301, 40.3966],
            [-83.7406, 40.2935],
            [-83.8303, 40.2997],
            [-83.8284, 40.3568],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904358',
        NAME: 'Bellefontaine City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80711934,
        AWATER: 272878,
        INTPTLAT: '+40.3423108',
        INTPTLON: '-083.7822073',
        ELSDLEA: '',
        UNSDLEA: '04358',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8089, 40.0971],
            [-84.7003, 40.1784],
            [-84.5894, 40.1924],
            [-84.471, 40.1791],
            [-84.4533, 40.0901],
            [-84.5974, 40.0485],
            [-84.6638, 40.0042],
            [-84.7128, 40.0838],
            [-84.809, 40.0857],
            [-84.8089, 40.0971],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904409',
        NAME: 'Greenville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 326477536,
        AWATER: 1554653,
        INTPTLAT: '+40.1159528',
        INTPTLON: '-084.6190661',
        ELSDLEA: '',
        UNSDLEA: '04409',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6605, 40.3271],
            [-84.5998, 40.3376],
            [-84.5894, 40.1924],
            [-84.7003, 40.1784],
            [-84.7095, 40.265],
            [-84.6605, 40.3271],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904662',
        NAME: 'Ansonia Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 168726775,
        AWATER: 497532,
        INTPTLAT: '+40.2415093',
        INTPTLON: '-084.6288215',
        ELSDLEA: '',
        UNSDLEA: '04662',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4296, 40.0754],
            [-84.4056, 40.0947],
            [-84.2983, 40.0958],
            [-84.296, 40.0089],
            [-84.4285, 40.0073],
            [-84.4296, 40.0754],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904863',
        NAME: 'Newton Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100734586,
        AWATER: 843703,
        INTPTLAT: '+40.0440104',
        INTPTLON: '-084.3554935',
        ELSDLEA: '',
        UNSDLEA: '04863',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4043, 40.1975],
            [-84.3146, 40.1981],
            [-84.2882, 40.096],
            [-84.2983, 40.0958],
            [-84.4056, 40.0947],
            [-84.4043, 40.1975],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904533',
        NAME: 'Covington Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92396039,
        AWATER: 726043,
        INTPTLAT: '+40.1359635',
        INTPTLON: '-084.3566888',
        ELSDLEA: '',
        UNSDLEA: '04533',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.65, 41.2526],
            [-84.4572, 41.2535],
            [-84.3416, 41.2091],
            [-84.3319, 41.1654],
            [-84.3421, 41.0424],
            [-84.4664, 41.0921],
            [-84.6883, 41.1334],
            [-84.65, 41.2526],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904557',
        NAME: 'Paulding Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 457496814,
        AWATER: 4376236,
        INTPTLAT: '+41.1543277',
        INTPTLON: '-084.5000286',
        ELSDLEA: '',
        UNSDLEA: '04557',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3991, 40.9653],
            [-84.3801, 40.9689],
            [-84.3421, 41.0377],
            [-84.2593, 40.991],
            [-84.2634, 40.9717],
            [-84.341, 40.9036],
            [-84.3984, 40.9033],
            [-84.3991, 40.9653],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904938',
        NAME: 'Ottoville Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110835447,
        AWATER: 165839,
        INTPTLAT: '+40.9648574',
        INTPTLON: '-084.3296460',
        ELSDLEA: '',
        UNSDLEA: '04938',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4576, 41.2816],
            [-84.3276, 41.2891],
            [-84.3416, 41.2091],
            [-84.4572, 41.2535],
            [-84.4576, 41.2816],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904386',
        NAME: 'Defiance City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84785355,
        AWATER: 3155944,
        INTPTLAT: '+41.2527378',
        INTPTLON: '-084.3981420',
        ELSDLEA: '',
        UNSDLEA: '04386',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7831, 41.4184],
            [-81.7697, 41.4184],
            [-81.7344, 41.4186],
            [-81.6856, 41.4194],
            [-81.6633, 41.4066],
            [-81.6608, 41.3511],
            [-81.7051, 41.3509],
            [-81.7851, 41.3507],
            [-81.7831, 41.4184],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904463',
        NAME: 'Parma City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75324873,
        AWATER: 144481,
        INTPTLAT: '+41.3837975',
        INTPTLON: '-081.7250730',
        ELSDLEA: '',
        UNSDLEA: '04463',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2115, 41.4858],
            [-84.192, 41.4858],
            [-84.1252, 41.4862],
            [-84.1151, 41.4138],
            [-84.0074, 41.3926],
            [-83.9264, 41.4173],
            [-83.8835, 41.3426],
            [-83.9947, 41.3712],
            [-84.1065, 41.3048],
            [-84.2275, 41.2532],
            [-84.2274, 41.3013],
            [-84.2285, 41.4278],
            [-84.2115, 41.4858],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904443',
        NAME: 'Napoleon Area City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334652469,
        AWATER: 4818770,
        INTPTLAT: '+41.3740379',
        INTPTLON: '-084.1070899',
        ELSDLEA: '',
        UNSDLEA: '04443',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3886, 41.0141],
            [-83.3212, 41.0061],
            [-83.3045, 40.9202],
            [-83.4105, 40.905],
            [-83.4569, 40.9266],
            [-83.3886, 41.0141],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904526',
        NAME: 'Carey Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129565133,
        AWATER: 128837,
        INTPTLAT: '+40.9549665',
        INTPTLON: '-083.3748790',
        ELSDLEA: '',
        UNSDLEA: '04526',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8737, 41.4769],
            [-81.8736, 41.4832],
            [-81.837, 41.4899],
            [-81.832, 41.4632],
            [-81.8745, 41.4499],
            [-81.8737, 41.4769],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904470',
        NAME: 'Rocky River City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12670993,
        AWATER: 30903,
        INTPTLAT: '+41.4685929',
        INTPTLON: '-081.8560938',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5936, 41.493],
            [-81.517, 41.487],
            [-81.532, 41.4645],
            [-81.5456, 41.4572],
            [-81.5936, 41.493],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904475',
        NAME: 'Shaker Heights City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17126419,
        AWATER: 109426,
        INTPTLAT: '+41.4763430',
        INTPTLON: '-081.5518674',
        ELSDLEA: '',
        UNSDLEA: '04475',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9713, 40.1349],
            [-82.9127, 40.1796],
            [-82.8514, 40.1297],
            [-82.8536, 40.1074],
            [-82.8537, 40.1007],
            [-82.8729, 40.0684],
            [-82.8694, 40.0665],
            [-82.8695, 40.0604],
            [-82.8692, 40.0603],
            [-82.8636, 40.0566],
            [-82.8637, 40.0553],
            [-82.9033, 40.0573],
            [-82.9392, 40.0871],
            [-82.9746, 40.1053],
            [-82.9713, 40.1349],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904504',
        NAME: 'Westerville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90949590,
        AWATER: 6242827,
        INTPTLAT: '+40.1175167',
        INTPTLON: '-082.9095168',
        ELSDLEA: '',
        UNSDLEA: '04504',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.341, 40.9036],
            [-84.2634, 40.9717],
            [-84.2206, 40.9293],
            [-84.2527, 40.8598],
            [-84.2682, 40.8597],
            [-84.341, 40.9036],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904933',
        NAME: 'Jennings Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69608843,
        AWATER: 693476,
        INTPTLAT: '+40.9015169',
        INTPTLON: '-084.2829164',
        ELSDLEA: '',
        UNSDLEA: '04933',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.1616, 41.1662],
              [-84.1278, 41.1664],
              [-84.1234, 41.1665],
              [-84.1126, 41.1665],
              [-84.1508, 41.1592],
              [-84.1614, 41.1514],
              [-84.1616, 41.1662],
            ],
          ],
          [
            [
              [-84.1937, 41.0789],
              [-84.1603, 41.1224],
              [-84.1506, 41.1441],
              [-84.1456, 41.1418],
              [-84.0542, 41.123],
              [-83.9962, 41.0758],
              [-83.9961, 41.0635],
              [-84.16, 41.0646],
              [-84.2064, 41.0502],
              [-84.2076, 41.045],
              [-84.2272, 41.0702],
              [-84.1937, 41.0789],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904936',
        NAME: 'Miller City-New Cleveland Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 118271722,
        AWATER: 86138,
        INTPTLAT: '+41.0979874',
        INTPTLON: '-084.1020854',
        ELSDLEA: '',
        UNSDLEA: '04936',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2593, 40.991],
            [-84.2076, 41.045],
            [-84.2064, 41.0502],
            [-84.1783, 41.0354],
            [-84.1787, 41.0062],
            [-84.1207, 40.9625],
            [-84.2206, 40.9293],
            [-84.2634, 40.9717],
            [-84.2593, 40.991],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904934',
        NAME: 'Kalida Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100172396,
        AWATER: 398270,
        INTPTLAT: '+40.9784108',
        INTPTLON: '-084.1983447',
        ELSDLEA: '',
        UNSDLEA: '04934',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8811, 41.1531],
            [-83.8623, 41.2552],
            [-83.7659, 41.244],
            [-83.7271, 41.1685],
            [-83.6982, 41.0808],
            [-83.8524, 41.0402],
            [-83.8807, 41.0276],
            [-83.8905, 41.0764],
            [-83.8811, 41.1531],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904745',
        NAME: 'McComb Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262352962,
        AWATER: 200619,
        INTPTLAT: '+41.1429815',
        INTPTLON: '-083.8080767',
        ELSDLEA: '',
        UNSDLEA: '04745',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9413, 39.8288],
            [-83.8483, 39.8239],
            [-83.8904, 39.7692],
            [-83.946, 39.7849],
            [-83.9413, 39.8288],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904567',
        NAME: 'Yellow Springs Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43940331,
        AWATER: 216749,
        INTPTLAT: '+39.8007812',
        INTPTLON: '-083.8985904',
        ELSDLEA: '',
        UNSDLEA: '04567',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7895, 39.8209],
            [-83.7842, 39.8643],
            [-83.7085, 39.8813],
            [-83.5546, 39.8809],
            [-83.588, 39.7688],
            [-83.6472, 39.773],
            [-83.7248, 39.7535],
            [-83.7895, 39.8209],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904627',
        NAME: 'Southeastern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 204858587,
        AWATER: 555890,
        INTPTLAT: '+39.8254093',
        INTPTLON: '-083.6789383',
        ELSDLEA: '',
        UNSDLEA: '04627',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8904, 39.7692],
            [-83.8483, 39.8239],
            [-83.7895, 39.8209],
            [-83.7248, 39.7535],
            [-83.8069, 39.6871],
            [-83.8904, 39.7692],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904725',
        NAME: 'Cedar Cliff Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125979104,
        AWATER: 540650,
        INTPTLAT: '+39.7583699',
        INTPTLON: '-083.8097053',
        ELSDLEA: '',
        UNSDLEA: '04725',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1511, 39.1879],
            [-83.145, 39.1876],
            [-83.0166, 39.1809],
            [-82.9839, 39.2168],
            [-82.9567, 39.178],
            [-82.7859, 39.1688],
            [-82.7947, 39.0841],
            [-82.9234, 39.0889],
            [-83.1187, 39.0849],
            [-83.1511, 39.1879],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904914',
        NAME: 'Waverly City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305472878,
        AWATER: 4145798,
        INTPTLAT: '+39.1365321',
        INTPTLON: '-082.9734320',
        ELSDLEA: '',
        UNSDLEA: '04914',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5327, 41.5454],
            [-81.5273, 41.5454],
            [-81.4881, 41.5415],
            [-81.472, 41.5012],
            [-81.4805, 41.5012],
            [-81.5168, 41.5014],
            [-81.5464, 41.5451],
            [-81.5327, 41.5454],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904479',
        NAME: 'South Euclid-Lyndhurst City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23532410,
        AWATER: 14635,
        INTPTLAT: '+41.5214948',
        INTPTLON: '-081.5084830',
        ELSDLEA: '',
        UNSDLEA: '04479',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5456, 41.4572],
            [-81.532, 41.4645],
            [-81.4886, 41.4573],
            [-81.5089, 41.4242],
            [-81.5274, 41.4242],
            [-81.5543, 41.4243],
            [-81.5456, 41.4572],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904500',
        NAME: 'Warrensville Heights City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19634957,
        AWATER: 48494,
        INTPTLAT: '+41.4412720',
        INTPTLON: '-081.5188995',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6633, 41.4066],
            [-81.5981, 41.3512],
            [-81.6608, 41.3511],
            [-81.6633, 41.4066],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904656',
        NAME: 'Independence Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24705919,
        AWATER: 265651,
        INTPTLAT: '+41.3800125',
        INTPTLON: '-081.6332899',
        ELSDLEA: '',
        UNSDLEA: '04656',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4879, 41.5862],
            [-81.4878, 41.5701],
            [-81.4881, 41.5415],
            [-81.5273, 41.5454],
            [-81.4879, 41.5862],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904659',
        NAME: 'Richmond Heights Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10736858,
        AWATER: 18869,
        INTPTLAT: '+41.5606460',
        INTPTLON: '-081.5077000',
        ELSDLEA: '',
        UNSDLEA: '04659',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0566, 39.4537],
            [-82.0529, 39.4919],
            [-82.027, 39.4586],
            [-81.8696, 39.4513],
            [-81.8537, 39.3182],
            [-81.7256, 39.2159],
            [-81.7561, 39.1806],
            [-81.9609, 39.1887],
            [-81.9538, 39.2778],
            [-82.0342, 39.2813],
            [-82.0566, 39.4537],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904591',
        NAME: 'Federal Hocking Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 531012713,
        AWATER: 4951392,
        INTPTLAT: '+39.3163813',
        INTPTLON: '-081.9094764',
        ELSDLEA: '',
        UNSDLEA: '04591',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1592, 39.5566],
            [-82.0475, 39.5508],
            [-82.0529, 39.4919],
            [-82.0566, 39.4537],
            [-82.1674, 39.4663],
            [-82.1592, 39.5566],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904592',
        NAME: 'Trimble Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98775978,
        AWATER: 1089211,
        INTPTLAT: '+39.5058220',
        INTPTLON: '-082.1056624',
        ELSDLEA: '',
        UNSDLEA: '04592',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3089, 39.0413],
            [-83.1187, 39.0849],
            [-82.9234, 39.0889],
            [-82.9556, 38.9547],
            [-83.0436, 38.9569],
            [-83.2121, 38.9601],
            [-83.2708, 39.0158],
            [-83.3089, 39.0413],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904913',
        NAME: 'Scioto Valley Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 368593288,
        AWATER: 4516014,
        INTPTLAT: '+39.0238787',
        INTPTLON: '-083.0776267',
        ELSDLEA: '',
        UNSDLEA: '04913',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7663, 40.8193],
            [-83.7277, 40.8485],
            [-83.6144, 40.8192],
            [-83.5712, 40.7098],
            [-83.7305, 40.6894],
            [-83.766, 40.7329],
            [-83.7663, 40.8193],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904749',
        NAME: 'Hardin Northern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229121259,
        AWATER: 158690,
        INTPTLAT: '+40.7703561',
        INTPTLON: '-083.6616948',
        ELSDLEA: '',
        UNSDLEA: '04749',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4774, 41.2243],
            [-83.382, 41.1666],
            [-83.4211, 41.1273],
            [-83.4311, 41.1503],
            [-83.4786, 41.1661],
            [-83.4774, 41.2243],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904399',
        NAME: 'Fostoria City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57221054,
        AWATER: 72915,
        INTPTLAT: '+41.1845134',
        INTPTLON: '-083.4400238',
        ELSDLEA: '',
        UNSDLEA: '04399',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6528, 41.2404],
            [-83.6248, 41.1675],
            [-83.7271, 41.1685],
            [-83.7659, 41.244],
            [-83.6528, 41.2404],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905070',
        NAME: 'North Baltimore Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96086603,
        AWATER: 222168,
        INTPTLAT: '+41.2047939',
        INTPTLON: '-083.6977709',
        ELSDLEA: '',
        UNSDLEA: '05070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8524, 41.0402],
            [-83.6982, 41.0808],
            [-83.6611, 41.0807],
            [-83.6509, 40.9935],
            [-83.6898, 40.9652],
            [-83.8475, 40.9933],
            [-83.8524, 41.0402],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904744',
        NAME: 'Liberty-Benton Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127492082,
        AWATER: 150082,
        INTPTLAT: '+41.0250153',
        INTPTLON: '-083.7460675',
        ELSDLEA: '',
        UNSDLEA: '04744',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5733, 41.1379],
            [-83.4786, 41.1661],
            [-83.4311, 41.1503],
            [-83.4211, 41.1273],
            [-83.4209, 41.0793],
            [-83.4208, 41.0579],
            [-83.5349, 41.0303],
            [-83.557, 41.0503],
            [-83.5733, 41.1379],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910002',
        NAME: 'Arcadia Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156729492,
        AWATER: 1010307,
        INTPTLAT: '+41.1004672',
        INTPTLON: '-083.5026936',
        ELSDLEA: '',
        UNSDLEA: '10002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4888, 41.416],
            [-81.4283, 41.4248],
            [-81.3919, 41.4019],
            [-81.3917, 41.3483],
            [-81.3996, 41.3486],
            [-81.489, 41.3498],
            [-81.4888, 41.416],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904660',
        NAME: 'Solon City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58522242,
        AWATER: 483969,
        INTPTLAT: '+41.3832616',
        INTPTLON: '-081.4442489',
        ELSDLEA: '',
        UNSDLEA: '04660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.5847, 41.3846],
            [-81.5274, 41.4242],
            [-81.5089, 41.4242],
            [-81.4888, 41.416],
            [-81.489, 41.3498],
            [-81.5852, 41.3511],
            [-81.5847, 41.3846],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910017',
        NAME: 'Bedford City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51035222,
        AWATER: 293039,
        INTPTLAT: '+41.3812232',
        INTPTLON: '-081.5292264',
        ELSDLEA: '',
        UNSDLEA: '10017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8801, 40.8199],
            [-83.8711, 40.8414],
            [-83.7663, 40.8193],
            [-83.766, 40.7329],
            [-83.8798, 40.7324],
            [-83.8801, 40.8199],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904518',
        NAME: 'Ada Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111059819,
        AWATER: 4559,
        INTPTLAT: '+40.7845072',
        INTPTLON: '-083.8333899',
        ELSDLEA: '',
        UNSDLEA: '04518',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1591, 40.5893],
            [-83.1313, 40.6159],
            [-83.1076, 40.5714],
            [-83.1448, 40.5643],
            [-83.1591, 40.5893],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904433',
        NAME: 'Marion City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22553511,
        AWATER: 143219,
        INTPTLAT: '+40.5892988',
        INTPTLON: '-083.1295963',
        ELSDLEA: '',
        UNSDLEA: '04433',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1448, 40.5643],
            [-83.1076, 40.5714],
            [-83.1419, 40.4837],
            [-83.1448, 40.5643],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904842',
        NAME: 'Pleasant Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91262032,
        AWATER: 92795,
        INTPTLAT: '+40.5270829',
        INTPTLON: '-083.1275460',
        ELSDLEA: '',
        UNSDLEA: '04842',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5547, 40.9925],
            [-83.5349, 41.0303],
            [-83.4208, 41.0579],
            [-83.3886, 41.0141],
            [-83.4569, 40.9266],
            [-83.5547, 40.9925],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904747',
        NAME: 'Vanlue Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123039066,
        AWATER: 112383,
        INTPTLAT: '+40.9955131',
        INTPTLON: '-083.4898670',
        ELSDLEA: '',
        UNSDLEA: '04747',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6611, 41.0807],
            [-83.557, 41.0503],
            [-83.5349, 41.0303],
            [-83.5547, 40.9925],
            [-83.6125, 40.9933],
            [-83.6509, 40.9935],
            [-83.6611, 41.0807],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910000',
        NAME: 'Findlay City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79096780,
        AWATER: 3611817,
        INTPTLAT: '+41.0310544',
        INTPTLON: '-083.6139371',
        ELSDLEA: '',
        UNSDLEA: '10000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6125, 40.9933],
            [-83.5547, 40.9925],
            [-83.4569, 40.9266],
            [-83.4105, 40.905],
            [-83.4148, 40.7641],
            [-83.4778, 40.7301],
            [-83.5712, 40.7098],
            [-83.6144, 40.8192],
            [-83.6125, 40.9933],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904751',
        NAME: 'Riverdale Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370242371,
        AWATER: 136057,
        INTPTLAT: '+40.8426656',
        INTPTLON: '-083.5179517',
        ELSDLEA: '',
        UNSDLEA: '04751',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1136, 39.8178],
            [-84.0537, 39.8505],
            [-84.0373, 39.8495],
            [-83.9413, 39.8288],
            [-83.946, 39.7849],
            [-83.9666, 39.7717],
            [-84.0978, 39.7802],
            [-84.0969, 39.7937],
            [-84.1084, 39.799],
            [-84.1136, 39.8178],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904396',
        NAME: 'Fairborn City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100509764,
        AWATER: 989243,
        INTPTLAT: '+39.8108006',
        INTPTLON: '-084.0208018',
        ELSDLEA: '',
        UNSDLEA: '04396',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2353, 39.7032],
            [-84.1894, 39.7307],
            [-84.1609, 39.7174],
            [-84.1098, 39.7291],
            [-84.1071, 39.6825],
            [-84.1087, 39.6678],
            [-84.2029, 39.6747],
            [-84.2353, 39.7032],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904418',
        NAME: 'Kettering City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56396216,
        AWATER: 393668,
        INTPTLAT: '+39.6982219',
        INTPTLON: '-084.1604589',
        ELSDLEA: '',
        UNSDLEA: '04418',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3019, 39.9214],
            [-84.2748, 39.9218],
            [-84.1577, 39.923],
            [-84.1572, 39.8856],
            [-84.176, 39.8346],
            [-84.2256, 39.834],
            [-84.3019, 39.9214],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904495',
        NAME: 'Vandalia-Butler City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94526920,
        AWATER: 928491,
        INTPTLAT: '+39.8837780',
        INTPTLON: '-084.2251540',
        ELSDLEA: '',
        UNSDLEA: '04495',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3475, 39.7374],
            [-84.2953, 39.7403],
            [-84.2522, 39.703],
            [-84.2615, 39.674],
            [-84.3339, 39.6582],
            [-84.3664, 39.7144],
            [-84.3475, 39.7374],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904868',
        NAME: 'Jefferson Township Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75961755,
        AWATER: 110244,
        INTPTLAT: '+39.7128797',
        INTPTLON: '-084.3153255',
        ELSDLEA: '',
        UNSDLEA: '04868',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4827, 39.7883],
            [-84.3668, 39.7818],
            [-84.3475, 39.7374],
            [-84.3664, 39.7144],
            [-84.4814, 39.7228],
            [-84.4827, 39.7883],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904871',
        NAME: 'New Lebanon Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75290643,
        AWATER: 0,
        INTPTLAT: '+39.7515954',
        INTPTLON: '-084.4211501',
        ELSDLEA: '',
        UNSDLEA: '04871',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2485, 39.5869],
            [-84.2109, 39.5846],
            [-84.1535, 39.5806],
            [-84.1529, 39.4789],
            [-84.2713, 39.4842],
            [-84.2485, 39.5869],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905042',
        NAME: 'Springboro Community City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98486241,
        AWATER: 31232,
        INTPTLAT: '+39.5287728',
        INTPTLON: '-084.2042175',
        ELSDLEA: '',
        UNSDLEA: '05042',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6067, 39.3182],
            [-84.5854, 39.3562],
            [-84.536, 39.411],
            [-84.505, 39.4328],
            [-84.4745, 39.4297],
            [-84.4832, 39.3017],
            [-84.5128, 39.3048],
            [-84.5182, 39.304],
            [-84.6067, 39.3182],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904610',
        NAME: 'Fairfield City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90609177,
        AWATER: 813308,
        INTPTLAT: '+39.3597180',
        INTPTLON: '-084.5310545',
        ELSDLEA: '',
        UNSDLEA: '04610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8176, 39.3918],
            [-84.6276, 39.406],
            [-84.5854, 39.3562],
            [-84.6067, 39.3182],
            [-84.6418, 39.305],
            [-84.8194, 39.3052],
            [-84.8176, 39.3918],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904614',
        NAME: 'Ross Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179929348,
        AWATER: 996485,
        INTPTLAT: '+39.3442502',
        INTPTLON: '-084.6933603',
        ELSDLEA: '',
        UNSDLEA: '04614',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7051, 41.3509],
            [-81.6608, 41.3511],
            [-81.5981, 41.3512],
            [-81.5651, 41.2778],
            [-81.5874, 41.2776],
            [-81.6705, 41.2773],
            [-81.7051, 41.3509],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904364',
        NAME: 'Brecksville-Broadview Heights City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73925548,
        AWATER: 334451,
        INTPTLAT: '+41.3138916',
        INTPTLON: '-081.6327008',
        ELSDLEA: '',
        UNSDLEA: '04364',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8546, 41.42],
            [-81.8191, 41.4569],
            [-81.8321, 41.4647],
            [-81.7686, 41.4916],
            [-81.55, 41.5993],
            [-81.5327, 41.5454],
            [-81.5464, 41.5451],
            [-81.556, 41.5452],
            [-81.5904, 41.545],
            [-81.5912, 41.5161],
            [-81.5936, 41.493],
            [-81.5456, 41.4572],
            [-81.5543, 41.4243],
            [-81.5679, 41.4243],
            [-81.6357, 41.4342],
            [-81.6856, 41.4194],
            [-81.7344, 41.4186],
            [-81.7386, 41.4625],
            [-81.7694, 41.4411],
            [-81.7697, 41.4184],
            [-81.7831, 41.4184],
            [-81.8546, 41.42],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904378',
        NAME: 'Cleveland Municipal School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204584560,
        AWATER: 1186671,
        INTPTLAT: '+41.4774519',
        INTPTLON: '-081.6789022',
        ELSDLEA: '',
        UNSDLEA: '04378',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.982, 39.3672],
            [-82.9419, 39.3264],
            [-82.9668, 39.2405],
            [-83.0058, 39.3037],
            [-82.982, 39.3672],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904374',
        NAME: 'Chillicothe City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54218337,
        AWATER: 1249752,
        INTPTLAT: '+39.3028997',
        INTPTLON: '-082.9762346',
        ELSDLEA: '',
        UNSDLEA: '04374',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0836, 39.3254],
            [-83.0058, 39.3037],
            [-82.9668, 39.2405],
            [-82.9839, 39.2168],
            [-83.0166, 39.1809],
            [-83.145, 39.1876],
            [-83.0836, 39.3254],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904950',
        NAME: 'Huntington Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154301263,
        AWATER: 139936,
        INTPTLAT: '+39.2318046',
        INTPTLON: '-083.0437006',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9172, 40.7269],
            [-80.816, 40.7558],
            [-80.747, 40.7265],
            [-80.6748, 40.6551],
            [-80.6703, 40.5825],
            [-80.8621, 40.5956],
            [-80.9181, 40.6435],
            [-80.9172, 40.7269],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904644',
        NAME: 'Southern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 256611549,
        AWATER: 1121709,
        INTPTLAT: '+40.6632328',
        INTPTLON: '-080.7928301',
        ELSDLEA: '',
        UNSDLEA: '04644',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6755, 40.3841],
            [-80.6143, 40.4087],
            [-80.6297, 40.395],
            [-80.6108, 40.3409],
            [-80.6755, 40.3841],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904482',
        NAME: 'Steubenville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18892177,
        AWATER: 832812,
        INTPTLAT: '+40.3701102',
        INTPTLON: '-080.6343391',
        ELSDLEA: '',
        UNSDLEA: '04482',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3919, 41.4019],
            [-81.2977, 41.4251],
            [-81.1906, 41.4256],
            [-81.1914, 41.3476],
            [-81.2986, 41.3473],
            [-81.3917, 41.3483],
            [-81.3919, 41.4019],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904719',
        NAME: 'Kenston Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136171716,
        AWATER: 6609357,
        INTPTLAT: '+41.3847514',
        INTPTLON: '-081.2857591',
        ELSDLEA: '',
        UNSDLEA: '04719',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.1051, 41.8186],
            [-81.0021, 41.8549],
            [-81.0036, 41.7151],
            [-81.1023, 41.7143],
            [-81.1027, 41.7344],
            [-81.1051, 41.8186],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904788',
        NAME: 'Madison Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 113281765,
        AWATER: 688011,
        INTPTLAT: '+41.7800596',
        INTPTLON: '-081.0595960',
        ELSDLEA: '',
        UNSDLEA: '04788',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.004, 40.3368],
            [-84.0044, 40.3687],
            [-83.989, 40.3691],
            [-83.8284, 40.3568],
            [-83.8303, 40.2997],
            [-83.8414, 40.2633],
            [-84.0148, 40.2735],
            [-84.004, 40.3368],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904809',
        NAME: 'Riverside Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160020455,
        AWATER: 130570,
        INTPTLAT: '+40.3180898',
        INTPTLON: '-083.9237646',
        ELSDLEA: '',
        UNSDLEA: '04809',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.55, 41.5993],
            [-81.4886, 41.6315],
            [-81.4887, 41.624],
            [-81.488, 41.5882],
            [-81.4879, 41.5862],
            [-81.5273, 41.5454],
            [-81.5327, 41.5454],
            [-81.55, 41.5993],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904395',
        NAME: 'Euclid City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27738807,
        AWATER: 128095,
        INTPTLAT: '+41.5910677',
        INTPTLON: '-081.5184748',
        ELSDLEA: '',
        UNSDLEA: '04395',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4881, 41.5415],
            [-81.4878, 41.5701],
            [-81.391, 41.5697],
            [-81.3909, 41.5007],
            [-81.472, 41.5012],
            [-81.4881, 41.5415],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904437',
        NAME: 'Mayfield City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57668809,
        AWATER: 378591,
        INTPTLAT: '+41.5363263',
        INTPTLON: '-081.4353769',
        ELSDLEA: '',
        UNSDLEA: '04437',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8978, 41.2759],
            [-81.877, 41.3507],
            [-81.7851, 41.3507],
            [-81.7851, 41.2764],
            [-81.8781, 41.275],
            [-81.8978, 41.2759],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904484',
        NAME: 'Strongsville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64989344,
        AWATER: 18240,
        INTPTLAT: '+41.3123978',
        INTPTLON: '-081.8324022',
        ELSDLEA: '',
        UNSDLEA: '04484',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.4283, 41.4248],
            [-81.3914, 41.4549],
            [-81.2978, 41.4397],
            [-81.2977, 41.4251],
            [-81.3919, 41.4019],
            [-81.4283, 41.4248],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904528',
        NAME: 'Chagrin Falls Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31088225,
        AWATER: 607231,
        INTPTLAT: '+41.4269913',
        INTPTLON: '-081.3824380',
        ELSDLEA: '',
        UNSDLEA: '04528',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6856, 41.4194],
            [-81.6357, 41.4342],
            [-81.5847, 41.3846],
            [-81.5852, 41.3511],
            [-81.5981, 41.3512],
            [-81.6633, 41.4066],
            [-81.6856, 41.4194],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904655',
        NAME: 'Cuyahoga Heights Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26550812,
        AWATER: 758807,
        INTPTLAT: '+41.4256965',
        INTPTLON: '-081.6587987',
        ELSDLEA: '',
        UNSDLEA: '04655',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8414, 40.2633],
            [-83.8303, 40.2997],
            [-83.7406, 40.2935],
            [-83.6688, 40.2453],
            [-83.6776, 40.1115],
            [-83.7978, 40.1293],
            [-83.8414, 40.2633],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904621',
        NAME: 'West Liberty-Salem Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233155392,
        AWATER: 292052,
        INTPTLAT: '+40.2151689',
        INTPTLON: '-083.7433499',
        ELSDLEA: '',
        UNSDLEA: '04621',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6688, 40.2453],
            [-83.5456, 40.2805],
            [-83.4944, 40.2272],
            [-83.5027, 40.1261],
            [-83.6573, 40.0946],
            [-83.6776, 40.1115],
            [-83.6688, 40.2453],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904620',
        NAME: 'Triad Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216075139,
        AWATER: 435462,
        INTPTLAT: '+40.1924164',
        INTPTLON: '-083.5993049',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7985, 40.0268],
            [-83.6528, 40.0321],
            [-83.5127, 40.001],
            [-83.5409, 39.9162],
            [-83.5546, 39.8809],
            [-83.7085, 39.8813],
            [-83.7532, 39.9501],
            [-83.8241, 39.9545],
            [-83.8398, 39.9555],
            [-83.7985, 40.0268],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904625',
        NAME: 'Northeastern Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 298908327,
        AWATER: 9244526,
        INTPTLAT: '+39.9576766',
        INTPTLON: '-083.6528819',
        ELSDLEA: '',
        UNSDLEA: '04625',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6494, 40.1504],
            [-81.5481, 40.1651],
            [-81.518, 40.2188],
            [-81.5113, 40.2187],
            [-81.505, 40.2185],
            [-81.487, 40.2179],
            [-81.5294, 40.1398],
            [-81.5352, 40.01],
            [-81.6306, 40.0018],
            [-81.6844, 40.0991],
            [-81.6494, 40.1504],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904369',
        NAME: 'Cambridge City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195361337,
        AWATER: 3833699,
        INTPTLAT: '+40.1076351',
        INTPTLON: '-081.5816003',
        ELSDLEA: '',
        UNSDLEA: '04369',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.487, 40.2179],
            [-81.3381, 40.2143],
            [-81.2259, 40.1701],
            [-81.2341, 39.9513],
            [-81.3671, 39.9503],
            [-81.5352, 40.01],
            [-81.5294, 40.1398],
            [-81.487, 40.2179],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3906968',
        NAME: 'East Guernsey Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 611942025,
        AWATER: 8245230,
        INTPTLAT: '+40.0818855',
        INTPTLON: '-081.3813626',
        ELSDLEA: '',
        UNSDLEA: '06968',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5127, 40.001],
            [-83.4846, 40.007],
            [-83.3716, 39.9627],
            [-83.3362, 39.8999],
            [-83.5409, 39.9162],
            [-83.5127, 40.001],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904425',
        NAME: 'London City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148238280,
        AWATER: 1177221,
        INTPTLAT: '+39.9285058',
        INTPTLON: '-083.4370219',
        ELSDLEA: '',
        UNSDLEA: '04425',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2109, 39.5846],
            [-84.2053, 39.6572],
            [-84.2029, 39.6747],
            [-84.1087, 39.6678],
            [-84.1133, 39.5887],
            [-84.1535, 39.5806],
            [-84.2109, 39.5846],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904373',
        NAME: 'Centerville City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80617801,
        AWATER: 225114,
        INTPTLAT: '+39.6259621',
        INTPTLON: '-084.1613043',
        ELSDLEA: '',
        UNSDLEA: '04373',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3339, 39.6582],
            [-84.2615, 39.674],
            [-84.2053, 39.6572],
            [-84.2109, 39.5846],
            [-84.2485, 39.5869],
            [-84.2848, 39.5891],
            [-84.2857, 39.5892],
            [-84.3275, 39.6148],
            [-84.3339, 39.6582],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904439',
        NAME: 'Miamisburg City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76435601,
        AWATER: 740982,
        INTPTLAT: '+39.6289494',
        INTPTLON: '-084.2680959',
        ELSDLEA: '',
        UNSDLEA: '04439',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1106, 39.7513],
            [-84.0978, 39.7802],
            [-83.9666, 39.7717],
            [-83.9897, 39.675],
            [-84.1071, 39.6825],
            [-84.1098, 39.7291],
            [-84.1106, 39.7513],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904724',
        NAME: 'Beavercreek City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121057527,
        AWATER: 1126884,
        INTPTLAT: '+39.7285992',
        INTPTLON: '-084.0394757',
        ELSDLEA: '',
        UNSDLEA: '04724',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3551, 39.8322],
            [-84.2706, 39.8334],
            [-84.2953, 39.7403],
            [-84.3475, 39.7374],
            [-84.3668, 39.7818],
            [-84.3551, 39.8322],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904869',
        NAME: 'Trotwood-Madison City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79942201,
        AWATER: 36571,
        INTPTLAT: '+39.7918607',
        INTPTLON: '-084.3218364',
        ELSDLEA: '',
        UNSDLEA: '04869',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.0969, 39.7937],
              [-84.0978, 39.7802],
              [-84.1106, 39.7513],
              [-84.0969, 39.7937],
            ],
          ],
          [
            [
              [-84.1559, 39.8187],
              [-84.1548, 39.827],
              [-84.1136, 39.8178],
              [-84.1084, 39.799],
              [-84.1559, 39.8187],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904870',
        NAME: 'Mad River Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24247055,
        AWATER: 127778,
        INTPTLAT: '+39.7700733',
        INTPTLON: '-084.1143609',
        ELSDLEA: '',
        UNSDLEA: '04870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9087, 41.136],
            [-81.8783, 41.2003],
            [-81.8009, 41.2015],
            [-81.7792, 41.0624],
            [-81.8203, 41.0624],
            [-81.9087, 41.136],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904438',
        NAME: 'Medina City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 124857795,
        AWATER: 853275,
        INTPTLAT: '+41.1317725',
        INTPTLON: '-081.8389180',
        ELSDLEA: '',
        UNSDLEA: '04438',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8009, 41.2015],
            [-81.7582, 41.2767],
            [-81.685, 41.2771],
            [-81.6871, 41.128],
            [-81.6699, 41.0623],
            [-81.6876, 41.0622],
            [-81.7792, 41.0624],
            [-81.8009, 41.2015],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904849',
        NAME: 'Highland Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 202550407,
        AWATER: 740034,
        INTPTLAT: '+41.1671986',
        INTPTLON: '-081.7348383',
        ELSDLEA: '',
        UNSDLEA: '04849',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0786, 40.9937],
            [-81.8076, 40.9882],
            [-81.7634, 40.9313],
            [-81.7633, 40.9241],
            [-81.9075, 40.9012],
            [-81.9932, 40.9011],
            [-82.0786, 40.9912],
            [-82.0786, 40.9937],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3905056',
        NAME: 'Norwayne Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'E',
        FUNCSTAT: 'E',
        ALAND: 201892587,
        AWATER: 339344,
        INTPTLAT: '+40.9522872',
        INTPTLON: '-081.9133595',
        ELSDLEA: '',
        UNSDLEA: '05056',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.0743, 41.1365],
            [-81.9087, 41.136],
            [-81.8203, 41.0624],
            [-81.7856, 40.9951],
            [-81.8076, 40.9882],
            [-82.0786, 40.9937],
            [-82.0743, 41.1365],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904848',
        NAME: 'Cloverleaf Local School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 286266468,
        AWATER: 1638259,
        INTPTLAT: '+41.0570605',
        INTPTLON: '-081.9522315',
        ELSDLEA: '',
        UNSDLEA: '04848',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8076, 40.9882],
            [-81.7856, 40.9951],
            [-81.7594, 40.9882],
            [-81.7634, 40.9313],
            [-81.8076, 40.9882],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3910028',
        NAME: 'Rittman Exempted Village School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26590228,
        AWATER: 175142,
        INTPTLAT: '+40.9578708',
        INTPTLON: '-081.7932905',
        ELSDLEA: '',
        UNSDLEA: '10028',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7947, 39.0841],
            [-82.7859, 39.1688],
            [-82.7629, 39.208],
            [-82.6515, 39.1896],
            [-82.6609, 39.1012],
            [-82.5505, 39.0551],
            [-82.5507, 39.0542],
            [-82.5664, 38.9323],
            [-82.7534, 38.9454],
            [-82.8065, 38.948],
            [-82.7947, 39.0841],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904415',
        NAME: 'Jackson City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 467390828,
        AWATER: 1393452,
        INTPTLAT: '+39.0459700',
        INTPTLON: '-082.6906865',
        ELSDLEA: '',
        UNSDLEA: '04415',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6515, 39.1896],
            [-82.5403, 39.1445],
            [-82.4247, 39.138],
            [-82.4353, 39.0351],
            [-82.5505, 39.0551],
            [-82.6609, 39.1012],
            [-82.6515, 39.1896],
          ],
        ],
      },
      properties: {
        STATEFP: '39',
        SCSDLEA: '',
        GEOID: '3904502',
        NAME: 'Wellston City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 220192308,
        AWATER: 540943,
        INTPTLAT: '+39.1124293',
        INTPTLON: '-082.5424087',
        ELSDLEA: '',
        UNSDLEA: '04502',
      },
    },
  ],
};

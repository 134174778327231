export const MI = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9079, 43.855],
            [-82.894, 43.8914],
            [-82.8289, 43.915],
            [-82.8135, 43.9086],
            [-82.8037, 43.8977],
            [-82.7988, 43.8869],
            [-82.7988, 43.8797],
            [-82.803, 43.876],
            [-82.8229, 43.8682],
            [-82.8876, 43.8419],
            [-82.9079, 43.855],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600009',
        NAME: 'Bloomfield Township School District 7F',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41843232,
        AWATER: 506966,
        INTPTLAT: '+43.8825026',
        INTPTLON: '-082.8566631',
        ELSDLEA: '00009',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8875, 43.8383],
            [-82.8876, 43.8419],
            [-82.8229, 43.8682],
            [-82.8215, 43.8287],
            [-82.8613, 43.8278],
            [-82.8919, 43.8272],
            [-82.9016, 43.8196],
            [-82.8875, 43.8383],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600010',
        NAME: 'Sigel Township School District 3F',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18384354,
        AWATER: 18386,
        INTPTLAT: '+43.8352335',
        INTPTLON: '-082.8516043',
        ELSDLEA: '00010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.803, 43.876],
            [-82.8067, 43.8219],
            [-82.812, 43.8248],
            [-82.8215, 43.8287],
            [-82.8229, 43.8682],
            [-82.803, 43.876],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600011',
        NAME: 'Sigel Township School District 4F',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20380562,
        AWATER: 3651,
        INTPTLAT: '+43.8473664',
        INTPTLON: '-082.7974309',
        ELSDLEA: '00011',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6815, 43.8155],
            [-85.6033, 43.7286],
            [-85.6812, 43.6914],
            [-85.8003, 43.7275],
            [-85.8003, 43.8142],
            [-85.6815, 43.8155],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600105',
        NAME: 'Big Jackson School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145576676,
        AWATER: 8193169,
        INTPTLAT: '+43.7700521',
        INTPTLON: '-085.7235760',
        ELSDLEA: '00105',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0437, 47.044],
            [-88.0445, 46.8096],
            [-88.0447, 46.7654],
            [-88.2981, 46.765],
            [-88.2997, 46.9788],
            [-88.0437, 47.044],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603270',
        NAME: 'Arvon Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 321067360,
        AWATER: 210662532,
        INTPTLAT: '+46.8903912',
        INTPTLON: '-088.1650678',
        ELSDLEA: '03270',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1121, 47.2233],
            [-87.1127, 47.818],
            [-86.7671, 47.6787],
            [-86.7672, 47.1363],
            [-86.765, 46.2457],
            [-86.8653, 46.2457],
            [-86.8663, 46.3906],
            [-87.1165, 46.3912],
            [-87.1164, 46.4203],
            [-87.1121, 47.2233],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603660',
        NAME: 'Autrain-Onota Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391722255,
        AWATER: 3697604725,
        INTPTLAT: '+46.5309581',
        INTPTLON: '-087.0157820',
        ELSDLEA: '03660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2062, 42.3054],
            [-86.2023, 42.3318],
            [-86.1776, 42.3318],
            [-86.1771, 42.2871],
            [-86.2062, 42.3054],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603960',
        NAME: 'Bangor Township School District 8',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10336576,
        AWATER: 126827,
        INTPTLAT: '+42.3019595',
        INTPTLON: '-086.1798425',
        ELSDLEA: '03960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1044, 42.8934],
            [-85.0996, 42.9076],
            [-85.0573, 42.8791],
            [-85.0589, 42.8643],
            [-85.1044, 42.8934],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2605100',
        NAME: 'Berlin Township School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15685941,
        AWATER: 91931,
        INTPTLAT: '+42.8933205',
        INTPTLON: '-085.0836850',
        ELSDLEA: '05100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5808, 45.8253],
            [-84.3498, 45.775],
            [-84.4246, 45.7157],
            [-84.5084, 45.7378],
            [-84.6381, 45.8248],
            [-84.5808, 45.8253],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606300',
        NAME: 'Bois Blanc Pines School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90946602,
        AWATER: 40616692,
        INTPTLAT: '+45.7707609',
        INTPTLON: '-084.4779367',
        ELSDLEA: '06300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.994, 43.8958],
            [-82.9975, 43.8957],
            [-82.9844, 43.8999],
            [-82.9444, 43.8973],
            [-82.9754, 43.853],
            [-83.0024, 43.8666],
            [-82.994, 43.8958],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2609630',
        NAME: 'Church School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19893116,
        AWATER: 72333,
        INTPTLAT: '+43.8766031',
        INTPTLON: '-082.9712211',
        ELSDLEA: '09630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0024, 43.8666],
            [-82.9754, 43.853],
            [-82.972, 43.8249],
            [-83.0154, 43.8233],
            [-83.0024, 43.8666],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610230',
        NAME: 'Colfax Township School District 1F',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14420976,
        AWATER: 6519,
        INTPTLAT: '+43.8419966',
        INTPTLON: '-082.9969893',
        ELSDLEA: '10230',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.119, 43.0323],
            [-85.1147, 43.0341],
            [-85.0849, 43.0324],
            [-85.0848, 42.9949],
            [-85.119, 43.0323],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612660',
        NAME: 'Easton Township School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11379444,
        AWATER: 2980,
        INTPTLAT: '+43.0199361',
        INTPTLON: '-085.0962798',
        ELSDLEA: '12660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9329, 46.9677],
            [-88.806, 46.9821],
            [-88.7426, 46.982],
            [-88.7635, 46.8516],
            [-88.7635, 46.8083],
            [-88.9323, 46.8081],
            [-88.9325, 46.8943],
            [-88.9329, 46.9677],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2613140',
        NAME: 'Elm River Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236370323,
        AWATER: 5156707,
        INTPTLAT: '+46.8926152',
        INTPTLON: '-088.8439789',
        ELSDLEA: '13140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.094, 44.6848],
            [-85.0888, 44.7282],
            [-84.9712, 44.7274],
            [-84.9721, 44.6842],
            [-85.094, 44.6848],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2613680',
        NAME: 'Excelsior Township School District 1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46551183,
        AWATER: 1080675,
        INTPTLAT: '+44.7055076',
        INTPTLON: '-085.0253716',
        ELSDLEA: '13680',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2411, 42.5347],
            [-86.2026, 42.506],
            [-86.2454, 42.5059],
            [-86.2411, 42.5347],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615510',
        NAME: 'Ganges School District 4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11443427,
        AWATER: 40347,
        INTPTLAT: '+42.5128738',
        INTPTLON: '-086.2230072',
        ELSDLEA: '15510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.106, 47.2494],
            [-88.1096, 47.3301],
            [-88.1091, 47.4382],
            [-87.9201, 47.4384],
            [-87.92, 47.4952],
            [-87.7716, 47.4948],
            [-87.5669, 47.4538],
            [-87.5662, 47.3877],
            [-87.8616, 47.3783],
            [-87.9251, 47.3225],
            [-88.106, 47.2494],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616560',
        NAME: 'Grant Township School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308738094,
        AWATER: 216336829,
        INTPTLAT: '+47.3975544',
        INTPTLON: '-087.8951691',
        ELSDLEA: '16560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4075, 42.1796],
            [-86.3754, 42.1863],
            [-86.3707, 42.1681],
            [-86.3894, 42.1574],
            [-86.4075, 42.1796],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617340',
        NAME: 'Hagar Township School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6656551,
        AWATER: 102195,
        INTPTLAT: '+42.1722803',
        INTPTLON: '-086.3940823',
        ELSDLEA: '17340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0849, 43.0324],
            [-85.0617, 43.0325],
            [-85.0644, 42.9947],
            [-85.0848, 42.9949],
            [-85.0849, 43.0324],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619290',
        NAME: 'Ionia Township School District 2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7290440,
        AWATER: 14205,
        INTPTLAT: '+43.0111167',
        INTPTLON: '-085.0697418',
        ELSDLEA: '19290',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9273, 42.3632],
            [-84.806, 42.3635],
            [-84.8074, 42.3342],
            [-84.8476, 42.24],
            [-84.9273, 42.3632],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622830',
        NAME: 'Mar Lee School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96289585,
        AWATER: 912337,
        INTPTLAT: '+42.3095694',
        INTPTLON: '-084.8786292',
        ELSDLEA: '22830',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1124, 46.1582],
            [-84.9259, 46.1584],
            [-84.9255, 46.1133],
            [-84.9247, 45.9547],
            [-84.7791, 45.9547],
            [-84.717, 45.8486],
            [-84.7263, 45.8275],
            [-84.9202, 45.9209],
            [-84.9997, 46.0012],
            [-85.113, 46.0375],
            [-85.1124, 46.1582],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624540',
        NAME: 'Moran Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 330352474,
        AWATER: 60613902,
        INTPTLAT: '+46.0190676',
        INTPTLON: '-084.9475130',
        ELSDLEA: '24540',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4879, 41.9408],
            [-85.4342, 41.9452],
            [-85.3946, 41.8973],
            [-85.4194, 41.8779],
            [-85.429, 41.8757],
            [-85.4828, 41.9263],
            [-85.4879, 41.9408],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626100',
        NAME: 'Nottawa Community School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42727869,
        AWATER: 2575668,
        INTPTLAT: '+41.9270813',
        INTPTLON: '-085.4388239',
        ELSDLEA: '26100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8071, 42.7048],
            [-84.7789, 42.7047],
            [-84.7591, 42.6836],
            [-84.8071, 42.7048],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626430',
        NAME: 'Oneida Township School District 3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7345470,
        AWATER: 0,
        INTPTLAT: '+42.7046355',
        INTPTLON: '-084.7711885',
        ELSDLEA: '26430',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.075, 43.1202],
            [-84.9959, 43.1342],
            [-84.9224, 43.0763],
            [-84.9273, 43.0763],
            [-85.075, 43.1202],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627390',
        NAME: 'Palo Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53767475,
        AWATER: 121286,
        INTPTLAT: '+43.1069671',
        INTPTLON: '-084.9976876',
        ELSDLEA: '27390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5035, 47.222],
            [-87.5057, 46.679],
            [-87.6137, 46.6647],
            [-87.6763, 46.7075],
            [-87.8651, 46.7082],
            [-87.9178, 46.8086],
            [-88.0445, 46.8096],
            [-88.0437, 47.044],
            [-88.0461, 47.1997],
            [-88.0466, 47.2187],
            [-87.5035, 47.222],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629190',
        NAME: 'Powell Township Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 512363782,
        AWATER: 1748933214,
        INTPTLAT: '+46.8654606',
        INTPTLON: '-087.7753403',
        ELSDLEA: '29190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8613, 43.8278],
            [-82.8215, 43.8287],
            [-82.812, 43.8248],
            [-82.8067, 43.8219],
            [-82.8457, 43.7813],
            [-82.8507, 43.7811],
            [-82.8613, 43.8278],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2631830',
        NAME: 'Sigel Township School District 6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16648196,
        AWATER: 122067,
        INTPTLAT: '+43.8105108',
        INTPTLON: '-082.8307279',
        ELSDLEA: '31830',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4087, 42.0552],
            [-86.3774, 42.0218],
            [-86.389, 42.0215],
            [-86.3996, 42.0161],
            [-86.4087, 42.0552],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632070',
        NAME: 'Sodus Township School District 5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6558943,
        AWATER: 407706,
        INTPTLAT: '+42.0352654',
        INTPTLON: '-086.3893092',
        ELSDLEA: '32070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9333, 47.0313],
            [-88.8881, 47.1012],
            [-88.6826, 47.2143],
            [-88.6157, 47.127],
            [-88.8064, 47.0254],
            [-88.806, 46.9821],
            [-88.9329, 46.9677],
            [-88.9333, 47.0313],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632970',
        NAME: 'Stanton Township Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317110065,
        AWATER: 3432671,
        INTPTLAT: '+47.0910879',
        INTPTLON: '-088.7791470',
        ELSDLEA: '32970',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9016, 43.8196],
            [-82.8919, 43.8272],
            [-82.8613, 43.8278],
            [-82.8507, 43.7811],
            [-82.8503, 43.7704],
            [-82.9056, 43.7979],
            [-82.9016, 43.8196],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634860',
        NAME: 'Verona Township School District 1F',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17687451,
        AWATER: 8368,
        INTPTLAT: '+43.7987892',
        INTPTLON: '-082.8706566',
        ELSDLEA: '34860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.616, 46.1598],
            [-87.4229, 46.1592],
            [-87.3052, 46.0239],
            [-87.3678, 45.9853],
            [-87.4925, 45.9855],
            [-87.617, 45.986],
            [-87.616, 46.1598],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635790',
        NAME: 'Wells Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388611721,
        AWATER: 1842021,
        INTPTLAT: '+46.0637982',
        INTPTLON: '-087.4813604',
        ELSDLEA: '35790',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.9173, 41.7855],
              [-84.8492, 41.7964],
              [-84.8252, 41.7779],
              [-84.8251, 41.76],
              [-84.9177, 41.7597],
              [-84.9173, 41.7855],
            ],
          ],
          [
            [
              [-84.9423, 42.0514],
              [-84.9095, 42.0724],
              [-84.8951, 42.066],
              [-84.7483, 41.9805],
              [-84.7484, 41.9344],
              [-84.7629, 41.9324],
              [-84.9175, 41.789],
              [-84.9321, 41.9144],
              [-84.9423, 42.0514],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629250',
        NAME: 'Quincy Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 275010277,
        AWATER: 4219110,
        INTPTLAT: '+41.9193965',
        INTPTLON: '-084.8683970',
        ELSDLEA: '',
        UNSDLEA: '29250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1901, 43.0912],
            [-83.0849, 43.1525],
            [-83.0439, 43.1356],
            [-82.9939, 43.1191],
            [-82.9914, 42.9822],
            [-83.1067, 42.976],
            [-83.1958, 42.9735],
            [-83.1901, 43.0912],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619100',
        NAME: 'Imlay City Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266488845,
        AWATER: 2977357,
        INTPTLAT: '+43.0576257',
        INTPTLON: '-083.0804804',
        ELSDLEA: '',
        UNSDLEA: '19100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4925, 45.9855],
            [-87.3678, 45.9853],
            [-87.3256, 45.8697],
            [-87.2639, 45.8117],
            [-87.2417, 45.6232],
            [-87.3266, 45.6231],
            [-87.3887, 45.638],
            [-87.4486, 45.7248],
            [-87.449, 45.8985],
            [-87.4925, 45.9855],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604020',
        NAME: 'Bark River-Harris School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 459781981,
        AWATER: 411821,
        INTPTLAT: '+45.7922299',
        INTPTLON: '-087.3759284',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2053, 43.1178],
            [-86.0973, 43.0779],
            [-86.0363, 43.1144],
            [-86.0265, 43.0239],
            [-86.2135, 43.0778],
            [-86.2053, 43.1178],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632550',
        NAME: 'Spring Lake Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83206094,
        AWATER: 7738640,
        INTPTLAT: '+43.0682098',
        INTPTLON: '-086.1096710',
        ELSDLEA: '',
        UNSDLEA: '32550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8452, 43.3284],
            [-84.8452, 43.3338],
            [-84.6669, 43.3209],
            [-84.6019, 43.3462],
            [-84.5036, 43.3352],
            [-84.4344, 43.3211],
            [-84.4294, 43.3211],
            [-84.4049, 43.3211],
            [-84.369, 43.2486],
            [-84.5327, 43.2046],
            [-84.5778, 43.1792],
            [-84.7622, 43.2341],
            [-84.8452, 43.3284],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619580',
        NAME: 'Ithaca Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 400837027,
        AWATER: 1166489,
        INTPTLAT: '+43.2699254',
        INTPTLON: '-084.5918668',
        ELSDLEA: '',
        UNSDLEA: '19580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8574, 43.6134],
            [-83.8484, 43.6586],
            [-83.6999, 43.6118],
            [-83.6994, 43.5947],
            [-83.6995, 43.5912],
            [-83.6995, 43.589],
            [-83.6997, 43.5799],
            [-83.8574, 43.6134],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2613530',
        NAME: 'Essexville-Hampton Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47805675,
        AWATER: 14338408,
        INTPTLAT: '+43.6195302',
        INTPTLON: '-083.7899930',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4271, 43.3752],
            [-86.2189, 43.4691],
            [-86.1968, 43.3828],
            [-86.3462, 43.3559],
            [-86.3841, 43.2985],
            [-86.4271, 43.3752],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636300',
        NAME: 'Whitehall School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149355519,
        AWATER: 13412693,
        INTPTLAT: '+43.3877564',
        INTPTLON: '-086.3017862',
        ELSDLEA: '',
        UNSDLEA: '36300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4313, 43.158],
            [-85.3522, 43.2694],
            [-85.1918, 43.2783],
            [-85.1133, 43.185],
            [-85.1082, 43.1707],
            [-85.135, 43.1198],
            [-85.4067, 43.1216],
            [-85.4313, 43.158],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617160',
        NAME: 'Greenville Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332999423,
        AWATER: 15307304,
        INTPTLAT: '+43.1923965',
        INTPTLON: '-085.2650715',
        ELSDLEA: '',
        UNSDLEA: '17160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6229, 43.0206],
            [-85.5451, 43.0282],
            [-85.4803, 43.0357],
            [-85.4414, 42.9725],
            [-85.4494, 42.8781],
            [-85.548, 42.898],
            [-85.5691, 42.92],
            [-85.5896, 42.9411],
            [-85.5906, 42.9627],
            [-85.6229, 43.0206],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614610',
        NAME: 'Forest Hills Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153133980,
        AWATER: 4305516,
        INTPTLAT: '+42.9579957',
        INTPTLON: '-085.5183449',
        ELSDLEA: '',
        UNSDLEA: '14610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6624, 43.0214],
            [-85.6609, 43.029],
            [-85.6229, 43.0206],
            [-85.5906, 42.9627],
            [-85.6298, 42.9579],
            [-85.6297, 42.941],
            [-85.5896, 42.9411],
            [-85.5691, 42.92],
            [-85.6231, 42.8895],
            [-85.6232, 42.8905],
            [-85.6349, 42.8921],
            [-85.666, 42.913],
            [-85.686, 42.9225],
            [-85.7071, 42.942],
            [-85.7201, 42.9388],
            [-85.7515, 42.9793],
            [-85.6624, 43.0214],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616440',
        NAME: 'Grand Rapids Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109367986,
        AWATER: 2230185,
        INTPTLAT: '+42.9641316',
        INTPTLON: '-085.6585188',
        ELSDLEA: '',
        UNSDLEA: '16440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.674, 42.8551],
            [-85.674, 42.8624],
            [-85.6457, 42.885],
            [-85.6349, 42.8921],
            [-85.6232, 42.8905],
            [-85.6231, 42.8895],
            [-85.5691, 42.92],
            [-85.548, 42.898],
            [-85.6339, 42.8263],
            [-85.674, 42.8551],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620340',
        NAME: 'Kentwood Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62434389,
        AWATER: 122891,
        INTPTLAT: '+42.8706135',
        INTPTLON: '-085.6084281',
        ELSDLEA: '',
        UNSDLEA: '20340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6609, 43.029],
            [-85.6338, 43.0607],
            [-85.5451, 43.0282],
            [-85.6229, 43.0206],
            [-85.6609, 43.029],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625950',
        NAME: 'Northview Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34195701,
        AWATER: 2229447,
        INTPTLAT: '+43.0334843',
        INTPTLON: '-085.5934178',
        ELSDLEA: '',
        UNSDLEA: '25950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2908, 43.5086],
            [-84.2227, 43.5396],
            [-84.1761, 43.4819],
            [-84.0966, 43.4521],
            [-84.1312, 43.3868],
            [-84.2899, 43.3106],
            [-84.2908, 43.5086],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618180',
        NAME: 'Hemlock Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218626400,
        AWATER: 3021564,
        INTPTLAT: '+43.4260469',
        INTPTLON: '-084.2140511',
        ELSDLEA: '',
        UNSDLEA: '18180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6356, 45.6371],
            [-87.3887, 45.638],
            [-87.3266, 45.6231],
            [-87.3275, 45.5512],
            [-87.4496, 45.507],
            [-87.6355, 45.5075],
            [-87.6356, 45.594],
            [-87.6356, 45.6371],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608010',
        NAME: 'Carney-Nadeau Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283900780,
        AWATER: 236484,
        INTPTLAT: '+45.5792120',
        INTPTLON: '-087.4964489',
        ELSDLEA: '',
        UNSDLEA: '08010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.71, 45.2485],
            [-87.7078, 45.2586],
            [-87.5294, 45.2461],
            [-87.4558, 45.338],
            [-87.1958, 45.3334],
            [-87.4057, 45.202],
            [-87.4428, 45.0765],
            [-87.6612, 45.1091],
            [-87.7418, 45.1971],
            [-87.71, 45.2485],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623550',
        NAME: 'Menominee Area Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225886379,
        AWATER: 380746376,
        INTPTLAT: '+45.2824501',
        INTPTLON: '-087.4549396',
        ELSDLEA: '',
        UNSDLEA: '23550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.167, 43.7404],
            [-83.875, 43.7239],
            [-83.875, 43.6963],
            [-83.8574, 43.6134],
            [-83.6997, 43.5799],
            [-83.6995, 43.5658],
            [-83.7733, 43.4931],
            [-83.856, 43.4939],
            [-84.0143, 43.5168],
            [-84.0594, 43.5674],
            [-84.1681, 43.5689],
            [-84.167, 43.7404],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604260',
        NAME: 'Bay City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 578758472,
        AWATER: 33190504,
        INTPTLAT: '+43.6167647',
        INTPTLON: '-083.9620376',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.5628, 43.476],
              [-83.5526, 43.4761],
              [-83.5626, 43.466],
              [-83.5628, 43.476],
            ],
          ],
          [
            [
              [-83.5711, 43.48],
              [-83.5628, 43.476],
              [-83.5711, 43.4761],
              [-83.5711, 43.48],
            ],
          ],
          [
            [
              [-83.5766, 43.4659],
              [-83.5711, 43.4659],
              [-83.5668, 43.4659],
              [-83.5667, 43.4593],
              [-83.5766, 43.4659],
            ],
          ],
          [
            [
              [-83.6695, 43.3961],
              [-83.6598, 43.3957],
              [-83.6694, 43.3928],
              [-83.6695, 43.3961],
            ],
          ],
          [
            [
              [-83.856, 43.4939],
              [-83.7733, 43.4931],
              [-83.6995, 43.5658],
              [-83.6271, 43.5015],
              [-83.5711, 43.48],
              [-83.5766, 43.4659],
              [-83.6598, 43.3957],
              [-83.6695, 43.3961],
              [-83.8568, 43.4505],
              [-83.856, 43.4939],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629520',
        NAME: 'Reese Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219342665,
        AWATER: 244695,
        INTPTLAT: '+43.4737445',
        INTPTLON: '-083.7081649',
        ELSDLEA: '',
        UNSDLEA: '29520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2899, 43.3106],
            [-84.1312, 43.3868],
            [-84.0695, 43.3077],
            [-84.1021, 43.2595],
            [-84.2488, 43.2445],
            [-84.2891, 43.2808],
            [-84.2899, 43.3106],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632640',
        NAME: 'St. Charles Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177402370,
        AWATER: 3812511,
        INTPTLAT: '+43.3002055',
        INTPTLON: '-084.1634282',
        ELSDLEA: '',
        UNSDLEA: '32640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0405, 44.0912],
            [-85.9404, 44.1649],
            [-85.8212, 44.1642],
            [-85.7428, 44.1471],
            [-85.8029, 44.0888],
            [-85.8014, 43.9877],
            [-85.6819, 43.988],
            [-85.6815, 43.8155],
            [-85.8003, 43.8142],
            [-85.8003, 43.7275],
            [-85.9001, 43.6977],
            [-85.9188, 43.8146],
            [-85.9981, 43.8151],
            [-86.0387, 43.9025],
            [-86.0405, 44.0912],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603810',
        NAME: 'Baldwin Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 931883211,
        AWATER: 25436471,
        INTPTLAT: '+43.9503361',
        INTPTLON: '-085.8707460',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1168, 45.9216],
            [-88.1175, 45.9425],
            [-87.9917, 45.9426],
            [-87.9441, 45.8985],
            [-87.9422, 45.7577],
            [-88.0583, 45.7806],
            [-88.1325, 45.8242],
            [-88.1168, 45.9216],
          ],
          [
            [-88.0981, 45.8196],
            [-88.037, 45.8054],
            [-88.0378, 45.8387],
            [-88.0877, 45.8479],
            [-88.0981, 45.8196],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606720',
        NAME: 'Breitung Township Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178585190,
        AWATER: 10045576,
        INTPTLAT: '+45.8689252',
        INTPTLON: '-088.0045207',
        ELSDLEA: '',
        UNSDLEA: '06720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2189, 43.4691],
            [-86.2182, 43.4739],
            [-86.1592, 43.4977],
            [-86.0995, 43.497],
            [-85.9796, 43.3375],
            [-85.9597, 43.2934],
            [-86.0201, 43.2934],
            [-86.1438, 43.2944],
            [-86.1968, 43.3828],
            [-86.2189, 43.4691],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618510',
        NAME: 'Holton Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242024943,
        AWATER: 11183678,
        INTPTLAT: '+43.3868858',
        INTPTLON: '-086.1116868',
        ELSDLEA: '',
        UNSDLEA: '18510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3211, 43.2034],
            [-86.2598, 43.2052],
            [-86.2254, 43.1908],
            [-86.2052, 43.1902],
            [-86.2059, 43.1469],
            [-86.2719, 43.1184],
            [-86.3211, 43.2034],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624120',
        NAME: 'Mona Shores Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49977965,
        AWATER: 2717687,
        INTPTLAT: '+43.1707311',
        INTPTLON: '-086.2512074',
        ELSDLEA: '',
        UNSDLEA: '24120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3437, 43.2357],
            [-86.3265, 43.2349],
            [-86.2726, 43.2442],
            [-86.2501, 43.2594],
            [-86.2237, 43.2545],
            [-86.2052, 43.205],
            [-86.2052, 43.1902],
            [-86.2254, 43.1908],
            [-86.2245, 43.2159],
            [-86.2598, 43.2052],
            [-86.3211, 43.2034],
            [-86.3437, 43.2357],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624840',
        NAME: 'Muskegon City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32493496,
        AWATER: 12034970,
        INTPTLAT: '+43.2260837',
        INTPTLON: '-086.2617211',
        ELSDLEA: '',
        UNSDLEA: '24840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.144, 43.2711],
            [-86.1438, 43.2944],
            [-86.0201, 43.2934],
            [-86.0279, 43.2061],
            [-86.1067, 43.2057],
            [-86.1449, 43.2052],
            [-86.144, 43.2711],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626220',
        NAME: 'Oakridge Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87171546,
        AWATER: 10115783,
        INTPTLAT: '+43.2568371',
        INTPTLON: '-086.0825214',
        ELSDLEA: '',
        UNSDLEA: '26220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3841, 43.2985],
            [-86.3462, 43.3559],
            [-86.1968, 43.3828],
            [-86.1438, 43.2944],
            [-86.144, 43.2711],
            [-86.2237, 43.2545],
            [-86.2501, 43.2594],
            [-86.3265, 43.2349],
            [-86.3437, 43.2357],
            [-86.3841, 43.2985],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629540',
        NAME: 'Reeths-Puffer Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192973267,
        AWATER: 4301232,
        INTPTLAT: '+43.3058371',
        INTPTLON: '-086.2500182',
        ELSDLEA: '',
        UNSDLEA: '29540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7881, 43.9012],
            [-84.7065, 43.9666],
            [-84.6756, 43.9377],
            [-84.6065, 43.9018],
            [-84.606, 43.8152],
            [-84.7075, 43.7449],
            [-84.7823, 43.738],
            [-84.7776, 43.7495],
            [-84.7876, 43.7381],
            [-84.823, 43.7492],
            [-84.7881, 43.9012],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2609750',
        NAME: 'Clare Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 321784314,
        AWATER: 2700789,
        INTPTLAT: '+43.8378325',
        INTPTLON: '-084.7171680',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7075, 43.7449],
            [-84.606, 43.8152],
            [-84.454, 43.8143],
            [-84.4883, 43.6405],
            [-84.6079, 43.6146],
            [-84.608, 43.6407],
            [-84.7075, 43.7449],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610200',
        NAME: 'Coleman Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 294392885,
        AWATER: 5039891,
        INTPTLAT: '+43.7205411',
        INTPTLON: '-084.5585986',
        ELSDLEA: '',
        UNSDLEA: '10200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0885, 43.8422],
            [-85.0879, 43.9872],
            [-84.9681, 43.9879],
            [-84.8487, 43.9875],
            [-84.7881, 43.9012],
            [-84.823, 43.7492],
            [-84.818, 43.7349],
            [-84.8226, 43.7347],
            [-84.8485, 43.7273],
            [-84.8985, 43.7305],
            [-84.8788, 43.7524],
            [-84.9691, 43.8138],
            [-85.0885, 43.8422],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614100',
        NAME: 'Farwell Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 463520661,
        AWATER: 14810104,
        INTPTLAT: '+43.8797383',
        INTPTLON: '-084.9310104',
        ELSDLEA: '',
        UNSDLEA: '14100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6777, 43.4584],
            [-84.5484, 43.593],
            [-84.4885, 43.5751],
            [-84.5397, 43.5535],
            [-84.5036, 43.3352],
            [-84.6019, 43.3462],
            [-84.6019, 43.3477],
            [-84.6019, 43.3496],
            [-84.6777, 43.4584],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632880',
        NAME: 'St. Louis Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222212650,
        AWATER: 2532661,
        INTPTLAT: '+43.4588640',
        INTPTLON: '-084.5783590',
        ELSDLEA: '',
        UNSDLEA: '32880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1802, 41.8959],
            [-84.1087, 41.8984],
            [-84.0744, 41.9843],
            [-83.9296, 41.9128],
            [-83.9814, 41.818],
            [-84.0031, 41.8412],
            [-84.0142, 41.8862],
            [-84.0713, 41.8632],
            [-84.1599, 41.8657],
            [-84.1802, 41.8959],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2601950',
        NAME: 'Adrian City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177788290,
        AWATER: 1424796,
        INTPTLAT: '+41.9137500',
        INTPTLON: '-084.0387613',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8498, 42.0086],
            [-83.842, 42.0813],
            [-83.8035, 42.0819],
            [-83.7721, 42.0176],
            [-83.7615, 41.9516],
            [-83.7704, 41.9369],
            [-83.8479, 41.9214],
            [-83.8961, 41.9352],
            [-83.8498, 42.0086],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606930',
        NAME: 'Britton-Macon Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136125902,
        AWATER: 71530,
        INTPTLAT: '+41.9986041',
        INTPTLON: '-083.8179227',
        ELSDLEA: '',
        UNSDLEA: '06930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0981, 45.8196],
            [-88.0877, 45.8479],
            [-88.0378, 45.8387],
            [-88.037, 45.8054],
            [-88.0981, 45.8196],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619410',
        NAME: 'Iron Mountain Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18141806,
        AWATER: 1734586,
        INTPTLAT: '+45.8277282',
        INTPTLON: '-088.0615742',
        ELSDLEA: '',
        UNSDLEA: '19410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1174, 46.2466],
            [-87.9503, 46.247],
            [-87.6159, 46.2468],
            [-87.616, 46.1598],
            [-87.617, 45.986],
            [-87.6969, 45.8988],
            [-87.7423, 45.9425],
            [-87.9917, 45.9426],
            [-88.1175, 45.9425],
            [-88.1174, 46.2466],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630480',
        NAME: 'North Dickinson County Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1294205296,
        AWATER: 18361471,
        INTPTLAT: '+46.1060879',
        INTPTLON: '-087.9024565',
        ELSDLEA: '',
        UNSDLEA: '30480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1388, 42.4652],
            [-86.0127, 42.4843],
            [-86.0127, 42.4803],
            [-86.0028, 42.4586],
            [-85.9125, 42.4194],
            [-85.9163, 42.3103],
            [-85.9657, 42.286],
            [-86.0333, 42.3791],
            [-86.1102, 42.3968],
            [-86.1388, 42.4652],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606270',
        NAME: 'Bloomingdale Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229535682,
        AWATER: 6480799,
        INTPTLAT: '+42.4053220',
        INTPTLON: '-086.0152700',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9125, 42.4194],
            [-85.8097, 42.4205],
            [-85.7657, 42.3136],
            [-85.9163, 42.3103],
            [-85.9125, 42.4194],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616050',
        NAME: 'Gobles Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142786266,
        AWATER: 3913292,
        INTPTLAT: '+42.3519652',
        INTPTLON: '-085.8372996',
        ELSDLEA: '',
        UNSDLEA: '16050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8985, 42.6518],
            [-85.8219, 42.7678],
            [-85.7825, 42.7682],
            [-85.7415, 42.7683],
            [-85.6822, 42.6812],
            [-85.7022, 42.602],
            [-85.717, 42.5878],
            [-85.7709, 42.548],
            [-85.781, 42.5948],
            [-85.8985, 42.6518],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618570',
        NAME: 'Hopkins Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244706709,
        AWATER: 1768756,
        INTPTLAT: '+42.6580269',
        INTPTLON: '-085.7706403',
        ELSDLEA: '',
        UNSDLEA: '18570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1145, 42.943],
            [-86.0242, 42.9719],
            [-85.943, 42.9432],
            [-85.9064, 42.7678],
            [-85.8613, 42.7675],
            [-85.9394, 42.7389],
            [-86.0626, 42.7688],
            [-86.0662, 42.8016],
            [-86.0414, 42.8969],
            [-86.1145, 42.943],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636660',
        NAME: 'Zeeland Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246155109,
        AWATER: 539175,
        INTPTLAT: '+42.8461871',
        INTPTLON: '-085.9944890',
        ELSDLEA: '',
        UNSDLEA: '36660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5267, 42.5994],
            [-85.4267, 42.5952],
            [-85.3378, 42.5371],
            [-85.3227, 42.537],
            [-85.3177, 42.537],
            [-85.2345, 42.5153],
            [-85.2535, 42.4344],
            [-85.4423, 42.4495],
            [-85.5434, 42.4362],
            [-85.5336, 42.5305],
            [-85.5377, 42.5342],
            [-85.541, 42.5342],
            [-85.5238, 42.545],
            [-85.5119, 42.5501],
            [-85.5435, 42.5948],
            [-85.5267, 42.5994],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611910',
        NAME: 'Delton-Kellogg School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273777619,
        AWATER: 29666179,
        INTPTLAT: '+42.5060679',
        INTPTLON: '-085.4172287',
        ELSDLEA: '',
        UNSDLEA: '11910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7709, 42.548],
            [-85.717, 42.5878],
            [-85.7022, 42.602],
            [-85.5435, 42.5948],
            [-85.5119, 42.5501],
            [-85.5238, 42.545],
            [-85.541, 42.5342],
            [-85.5377, 42.5342],
            [-85.5336, 42.5305],
            [-85.6615, 42.4862],
            [-85.7256, 42.5079],
            [-85.7709, 42.548],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622980',
        NAME: 'Martin Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157620807,
        AWATER: 2977692,
        INTPTLAT: '+42.5464499',
        INTPTLON: '-085.6379568',
        ELSDLEA: '',
        UNSDLEA: '22980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2071, 42.7468],
            [-86.1956, 42.7433],
            [-86.1342, 42.6455],
            [-86.1645, 42.6366],
            [-86.2279, 42.6146],
            [-86.2071, 42.7468],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630960',
        NAME: 'Saugatuck Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54237168,
        AWATER: 3044162,
        INTPTLAT: '+42.6714786',
        INTPTLON: '-086.1865841',
        ELSDLEA: '',
        UNSDLEA: '30960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2598, 43.2052],
            [-86.2245, 43.2159],
            [-86.2254, 43.1908],
            [-86.2598, 43.2052],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624870',
        NAME: 'Muskegon Heights School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8189182,
        AWATER: 2456,
        INTPTLAT: '+43.2019212',
        INTPTLON: '-086.2409625',
        ELSDLEA: '',
        UNSDLEA: '24870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7669, 44.8567],
            [-82.4087, 44.8581],
            [-82.3295, 44.5128],
            [-83.3187, 44.5117],
            [-83.3153, 44.5983],
            [-83.7668, 44.5958],
            [-83.7663, 44.6828],
            [-83.6457, 44.6829],
            [-83.6459, 44.7703],
            [-83.7677, 44.7701],
            [-83.7669, 44.8567],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602160',
        NAME: 'Alcona Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 988752562,
        AWATER: 2878940730,
        INTPTLAT: '+44.6934054',
        INTPTLON: '-083.2770563',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7704, 41.9369],
            [-83.7476, 41.8358],
            [-83.7621, 41.8211],
            [-83.8479, 41.9214],
            [-83.7704, 41.9369],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611730',
        NAME: 'Deerfield Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72137885,
        AWATER: 459132,
        INTPTLAT: '+41.8904336',
        INTPTLON: '-083.7982180',
        ELSDLEA: '',
        UNSDLEA: '11730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3735, 42.1461],
            [-84.3641, 42.146],
            [-84.1529, 42.156],
            [-84.098, 42.101],
            [-84.1265, 42.0717],
            [-84.2186, 42.0594],
            [-84.257, 42.0561],
            [-84.2805, 42.0302],
            [-84.4201, 42.0723],
            [-84.3735, 42.1461],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606990',
        NAME: 'Columbia School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215632166,
        AWATER: 17216836,
        INTPTLAT: '+42.1020683',
        INTPTLON: '-084.2748299',
        ELSDLEA: '',
        UNSDLEA: '06990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6339, 42.8263],
            [-85.548, 42.898],
            [-85.4494, 42.8781],
            [-85.3388, 42.7906],
            [-85.5456, 42.7681],
            [-85.575, 42.7393],
            [-85.6443, 42.7682],
            [-85.6339, 42.8263],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607620',
        NAME: 'Caledonia Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239449426,
        AWATER: 4890780,
        INTPTLAT: '+42.8144731',
        INTPTLON: '-085.5105577',
        ELSDLEA: '',
        UNSDLEA: '07620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7415, 42.7683],
            [-85.6443, 42.7682],
            [-85.575, 42.7393],
            [-85.5267, 42.5994],
            [-85.5435, 42.5948],
            [-85.7022, 42.602],
            [-85.6822, 42.6812],
            [-85.7415, 42.7683],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635550',
        NAME: 'Wayland Union Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 225844912,
        AWATER: 6004255,
        INTPTLAT: '+42.6769327',
        INTPTLON: '-085.6342523',
        ELSDLEA: '',
        UNSDLEA: '35550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.9112, 42.7339],
              [-84.8963, 42.7375],
              [-84.8815, 42.7041],
              [-84.9237, 42.7195],
              [-84.9112, 42.7339],
            ],
          ],
          [
            [
              [-84.9349, 42.6699],
              [-84.8956, 42.6552],
              [-84.9053, 42.648],
              [-84.9422, 42.659],
              [-84.9349, 42.6699],
            ],
          ],
          [
            [
              [-85.3101, 42.7906],
              [-85.2724, 42.857],
              [-85.1341, 42.9007],
              [-85.1044, 42.8934],
              [-85.0589, 42.8643],
              [-84.9162, 42.7705],
              [-84.9382, 42.7121],
              [-84.9349, 42.6699],
              [-85.025, 42.7128],
              [-85.1538, 42.6507],
              [-85.3082, 42.7479],
              [-85.2895, 42.7615],
              [-85.3101, 42.7906],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620980',
        NAME: 'Lakewood Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 554190847,
        AWATER: 4526186,
        INTPTLAT: '+42.7738664',
        INTPTLON: '-085.1244460',
        ELSDLEA: '',
        UNSDLEA: '20980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4803, 43.0357],
            [-85.4111, 43.0687],
            [-85.3124, 43.0541],
            [-85.2671, 42.9873],
            [-85.3118, 42.9343],
            [-85.2724, 42.857],
            [-85.3101, 42.7906],
            [-85.3388, 42.7906],
            [-85.4494, 42.8781],
            [-85.4414, 42.9725],
            [-85.4803, 43.0357],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622050',
        NAME: 'Lowell Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339764910,
        AWATER: 8558828,
        INTPTLAT: '+42.9413545',
        INTPTLON: '-085.3664421',
        ELSDLEA: '',
        UNSDLEA: '22050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7823, 42.8481],
            [-85.7131, 42.8553],
            [-85.674, 42.8551],
            [-85.6339, 42.8263],
            [-85.6443, 42.7682],
            [-85.7415, 42.7683],
            [-85.7825, 42.7682],
            [-85.7823, 42.8481],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607560',
        NAME: 'Byron Center Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105199925,
        AWATER: 173367,
        INTPTLAT: '+42.8059305',
        INTPTLON: '-085.7160163',
        ELSDLEA: '',
        UNSDLEA: '07560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.673, 43.2938],
            [-85.6411, 43.294],
            [-85.5463, 43.278],
            [-85.4436, 43.2943],
            [-85.3522, 43.2694],
            [-85.4313, 43.158],
            [-85.4509, 43.1543],
            [-85.6058, 43.1681],
            [-85.6387, 43.205],
            [-85.673, 43.2938],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608520',
        NAME: 'Cedar Springs Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 283022891,
        AWATER: 8019289,
        INTPTLAT: '+43.2276460',
        INTPTLON: '-085.5010413',
        ELSDLEA: '',
        UNSDLEA: '08520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.9277, 43.5973],
              [-84.9074, 43.5827],
              [-84.9121, 43.5827],
              [-84.9275, 43.5752],
              [-84.9277, 43.5973],
            ],
          ],
          [
            [
              [-84.9278, 43.602],
              [-84.9248, 43.6043],
              [-84.9224, 43.6043],
              [-84.9277, 43.5984],
              [-84.9278, 43.602],
            ],
          ],
          [
            [
              [-84.8985, 43.7305],
              [-84.8485, 43.7273],
              [-84.823, 43.7274],
              [-84.8226, 43.7347],
              [-84.818, 43.7349],
              [-84.8685, 43.6486],
              [-84.9079, 43.6136],
              [-84.8985, 43.7305],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604290',
        NAME: 'Beal City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122424808,
        AWATER: 1047643,
        INTPTLAT: '+43.6769132',
        INTPTLON: '-084.8955143',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4716, 42.2013],
            [-85.4707, 42.2156],
            [-85.4619, 42.2195],
            [-85.2972, 42.2727],
            [-85.2378, 42.2466],
            [-85.2281, 42.2465],
            [-85.2375, 42.2242],
            [-85.3241, 42.1581],
            [-85.4716, 42.2013],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610020',
        NAME: 'Climax-Scotts Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157618176,
        AWATER: 1114720,
        INTPTLAT: '+42.2102165',
        INTPTLON: '-085.3636147',
        ELSDLEA: '',
        UNSDLEA: '10020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.943, 42.9432],
            [-85.8761, 42.9579],
            [-85.8636, 42.9464],
            [-85.807, 42.8709],
            [-85.7823, 42.8481],
            [-85.7825, 42.7682],
            [-85.8219, 42.7678],
            [-85.8613, 42.7675],
            [-85.9064, 42.7678],
            [-85.943, 42.9432],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618840',
        NAME: 'Hudsonville Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204966615,
        AWATER: 272849,
        INTPTLAT: '+42.8550909',
        INTPTLON: '-085.8745444',
        ELSDLEA: '',
        UNSDLEA: '18840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7256, 42.5079],
            [-85.6615, 42.4862],
            [-85.6388, 42.3328],
            [-85.7314, 42.2955],
            [-85.7658, 42.3025],
            [-85.7657, 42.3136],
            [-85.8097, 42.4205],
            [-85.7256, 42.5079],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627060',
        NAME: 'Otsego Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210427673,
        AWATER: 2246396,
        INTPTLAT: '+42.4103049',
        INTPTLON: '-085.7197776',
        ELSDLEA: '',
        UNSDLEA: '27060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0127, 42.4803],
            [-86.0127, 42.4843],
            [-85.9763, 42.5781],
            [-86.0157, 42.623],
            [-85.8985, 42.6518],
            [-85.781, 42.5948],
            [-85.7709, 42.548],
            [-85.7256, 42.5079],
            [-85.8097, 42.4205],
            [-85.9125, 42.4194],
            [-86.0028, 42.4586],
            [-86.0127, 42.4803],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602220',
        NAME: 'Allegan Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 385970000,
        AWATER: 18987968,
        INTPTLAT: '+42.5354046',
        INTPTLON: '-085.8891925',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6615, 42.4862],
            [-85.5336, 42.5305],
            [-85.5434, 42.4362],
            [-85.5321, 42.3908],
            [-85.629, 42.3329],
            [-85.6388, 42.3328],
            [-85.6615, 42.4862],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628530',
        NAME: 'Plainwell Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171317508,
        AWATER: 1663195,
        INTPTLAT: '+42.4370479',
        INTPTLON: '-085.6001670',
        ELSDLEA: '',
        UNSDLEA: '28530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3649, 43.6705],
            [-85.3444, 43.7703],
            [-85.2151, 43.785],
            [-85.0885, 43.8422],
            [-84.9691, 43.8138],
            [-84.8788, 43.7524],
            [-84.8985, 43.7305],
            [-84.9079, 43.6136],
            [-84.9129, 43.6121],
            [-84.9181, 43.6042],
            [-84.9224, 43.6043],
            [-84.9248, 43.6043],
            [-84.9278, 43.602],
            [-84.9277, 43.5984],
            [-84.9277, 43.5973],
            [-84.9275, 43.5752],
            [-85.0659, 43.5708],
            [-85.1397, 43.4736],
            [-85.2046, 43.5542],
            [-85.3238, 43.5543],
            [-85.3649, 43.6705],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2609560',
        NAME: 'Chippewa Hills School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 921982502,
        AWATER: 31812873,
        INTPTLAT: '+43.6711525',
        INTPTLON: '-085.1369454',
        ELSDLEA: '',
        UNSDLEA: '09560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1439, 43.4443],
            [-85.1397, 43.4736],
            [-85.0659, 43.5708],
            [-84.9275, 43.5752],
            [-84.9121, 43.5827],
            [-84.9022, 43.5753],
            [-84.8968, 43.5532],
            [-84.9362, 43.5251],
            [-84.9304, 43.4956],
            [-84.9655, 43.4661],
            [-84.9649, 43.3288],
            [-85.1239, 43.3615],
            [-85.1439, 43.4443],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612960',
        NAME: 'Montabella Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 379588685,
        AWATER: 2717678,
        INTPTLAT: '+43.4623906',
        INTPTLON: '-085.0332702',
        ELSDLEA: '',
        UNSDLEA: '12960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5622, 43.6421],
            [-85.3649, 43.6705],
            [-85.3238, 43.5543],
            [-85.3279, 43.4639],
            [-85.4429, 43.4322],
            [-85.5624, 43.4682],
            [-85.6213, 43.4899],
            [-85.5628, 43.512],
            [-85.5622, 43.6421],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624600',
        NAME: 'Morley Stanwood Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 409952909,
        AWATER: 8983901,
        INTPTLAT: '+43.5510674',
        INTPTLON: '-085.4539971',
        ELSDLEA: '',
        UNSDLEA: '24600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3036, 42.3453],
            [-86.2241, 42.3315],
            [-86.2334, 42.2502],
            [-86.2624, 42.2364],
            [-86.3646, 42.2431],
            [-86.3036, 42.3453],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610980',
        NAME: 'Covert Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95139108,
        AWATER: 148292,
        INTPTLAT: '+42.2902927',
        INTPTLON: '-086.2774848',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2229, 42.0715],
            [-86.2228, 42.1211],
            [-86.1081, 42.1283],
            [-86.0308, 42.0558],
            [-85.9916, 42.0167],
            [-85.9913, 41.991],
            [-86.0147, 41.9429],
            [-86.1563, 41.9274],
            [-86.2231, 41.9351],
            [-86.223, 41.9567],
            [-86.2229, 42.0715],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612150',
        NAME: 'Dowagiac Union Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299201078,
        AWATER: 13045912,
        INTPTLAT: '+42.0153845',
        INTPTLON: '-086.1267355',
        ELSDLEA: '',
        UNSDLEA: '12150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9856, 42.2841],
            [-85.9657, 42.286],
            [-85.9163, 42.3103],
            [-85.7657, 42.3136],
            [-85.7658, 42.3025],
            [-85.8001, 42.2592],
            [-85.8523, 42.2005],
            [-85.9167, 42.1649],
            [-85.985, 42.1642],
            [-85.9856, 42.2841],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627660',
        NAME: 'Paw Paw Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 198855859,
        AWATER: 6584954,
        INTPTLAT: '+42.2400038',
        INTPTLON: '-085.8929158',
        ELSDLEA: '',
        UNSDLEA: '27660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5306, 42.4902],
            [-84.3644, 42.5055],
            [-84.2855, 42.4754],
            [-84.3273, 42.4247],
            [-84.399, 42.4113],
            [-84.4138, 42.4004],
            [-84.5438, 42.4194],
            [-84.5911, 42.4241],
            [-84.5306, 42.4902],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621450',
        NAME: 'Leslie Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191232222,
        AWATER: 1052589,
        INTPTLAT: '+42.4531285',
        INTPTLON: '-084.4444285',
        ELSDLEA: '',
        UNSDLEA: '21450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.552, 42.4158],
            [-84.5466, 42.4194],
            [-84.5438, 42.4194],
            [-84.4138, 42.4004],
            [-84.399, 42.4113],
            [-84.3273, 42.4247],
            [-84.2847, 42.3517],
            [-84.3843, 42.2914],
            [-84.4426, 42.269],
            [-84.5226, 42.2691],
            [-84.5517, 42.3494],
            [-84.552, 42.4158],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626010',
        NAME: 'Northwest School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 266715562,
        AWATER: 4719071,
        INTPTLAT: '+42.3619508',
        INTPTLON: '-084.4355198',
        ELSDLEA: '',
        UNSDLEA: '26010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4411, 42.7411],
            [-84.3534, 42.7234],
            [-84.3982, 42.6616],
            [-84.4621, 42.6828],
            [-84.4411, 42.7411],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626280',
        NAME: 'Okemos Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58227632,
        AWATER: 647484,
        INTPTLAT: '+42.7032903',
        INTPTLON: '-084.4219961',
        ELSDLEA: '',
        UNSDLEA: '26280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2855, 42.4754],
            [-84.1801, 42.5111],
            [-84.1681, 42.569],
            [-84.0574, 42.4967],
            [-84.0151, 42.4239],
            [-84.166, 42.369],
            [-84.171, 42.3654],
            [-84.2216, 42.3395],
            [-84.2847, 42.3517],
            [-84.3273, 42.4247],
            [-84.2855, 42.4754],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633030',
        NAME: 'Stockbridge Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 324717703,
        AWATER: 8197160,
        INTPTLAT: '+42.4423813',
        INTPTLON: '-084.1792809',
        ELSDLEA: '',
        UNSDLEA: '33030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6139, 46.6064],
            [-87.4891, 46.5934],
            [-87.489, 46.506],
            [-87.4905, 46.3333],
            [-87.6151, 46.3336],
            [-87.634, 46.4707],
            [-87.6559, 46.5137],
            [-87.6139, 46.6064],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625020',
        NAME: 'Negaunee Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290598678,
        AWATER: 11710882,
        INTPTLAT: '+46.4635931',
        INTPTLON: '-087.5771990',
        ELSDLEA: '',
        UNSDLEA: '25020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.308, 43.4867],
              [-83.3027, 43.4832],
              [-83.3078, 43.4831],
              [-83.308, 43.4867],
            ],
          ],
          [
            [
              [-83.5668, 43.4659],
              [-83.5626, 43.466],
              [-83.5526, 43.4761],
              [-83.4047, 43.5678],
              [-83.3906, 43.5823],
              [-83.2418, 43.5556],
              [-83.2688, 43.4908],
              [-83.288, 43.4792],
              [-83.2872, 43.4544],
              [-83.3205, 43.4104],
              [-83.4629, 43.4078],
              [-83.5323, 43.4591],
              [-83.5667, 43.4593],
              [-83.5668, 43.4659],
            ],
            [
              [-83.3078, 43.4831],
              [-83.3027, 43.4832],
              [-83.3081, 43.4883],
              [-83.3132, 43.4902],
              [-83.3132, 43.483],
              [-83.3078, 43.4831],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608040',
        NAME: 'Caro Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296137101,
        AWATER: 8657255,
        INTPTLAT: '+43.4851923',
        INTPTLON: '-083.3803405',
        ELSDLEA: '',
        UNSDLEA: '08040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9738, 42.739],
            [-83.9411, 42.76],
            [-83.9361, 42.76],
            [-83.874, 42.7816],
            [-83.8638, 42.7592],
            [-83.7727, 42.5742],
            [-83.8712, 42.5202],
            [-84.0478, 42.5103],
            [-84.0543, 42.5682],
            [-83.9738, 42.739],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618720',
        NAME: 'Howell Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 401754635,
        AWATER: 9576787,
        INTPTLAT: '+42.6209237',
        INTPTLON: '-083.9337721',
        ELSDLEA: '',
        UNSDLEA: '18720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7928, 42.3737],
            [-83.7313, 42.3585],
            [-83.6626, 42.3618],
            [-83.6431, 42.3625],
            [-83.5865, 42.3341],
            [-83.6424, 42.3112],
            [-83.6596, 42.1924],
            [-83.6989, 42.1932],
            [-83.857, 42.2484],
            [-83.7928, 42.3737],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602820',
        NAME: 'Ann Arbor Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264555204,
        AWATER: 4023754,
        INTPTLAT: '+42.2879295',
        INTPTLON: '-083.7362970',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9459, 42.1407],
            [-83.9005, 42.2451],
            [-83.857, 42.2484],
            [-83.6989, 42.1932],
            [-83.6964, 42.1327],
            [-83.8035, 42.0819],
            [-83.842, 42.0813],
            [-83.8447, 42.0812],
            [-83.9459, 42.1407],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630660',
        NAME: 'Saline Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246713547,
        AWATER: 1211993,
        INTPTLAT: '+42.1654107',
        INTPTLON: '-083.8141835',
        ELSDLEA: '',
        UNSDLEA: '30660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4426, 42.269],
            [-84.3843, 42.2914],
            [-84.3648, 42.2369],
            [-84.3647, 42.2116],
            [-84.3987, 42.2092],
            [-84.3985, 42.1748],
            [-84.3645, 42.1896],
            [-84.3641, 42.146],
            [-84.3735, 42.1461],
            [-84.4805, 42.1601],
            [-84.4819, 42.1998],
            [-84.4426, 42.269],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619620',
        NAME: 'Jackson Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99247645,
        AWATER: 2026880,
        INTPTLAT: '+42.2144750',
        INTPTLON: '-084.4211786',
        ELSDLEA: '',
        UNSDLEA: '19620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3647, 42.2116],
            [-84.3645, 42.1896],
            [-84.3985, 42.1748],
            [-84.3987, 42.2092],
            [-84.3647, 42.2116],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634650',
        NAME: 'Vandercook Lake Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12834656,
        AWATER: 778385,
        INTPTLAT: '+42.1941920',
        INTPTLON: '-084.3855013',
        ELSDLEA: '',
        UNSDLEA: '34650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1141, 43.7341],
            [-86.3988, 43.7329],
            [-86.3073, 43.7531],
            [-86.2784, 43.7738],
            [-86.2584, 43.8175],
            [-86.1788, 43.8164],
            [-86.1577, 43.6712],
            [-86.178, 43.6424],
            [-87.1266, 43.6469],
            [-87.1141, 43.7341],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617860',
        NAME: 'Hart Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 358566560,
        AWATER: 494474223,
        INTPTLAT: '+43.6512491',
        INTPTLON: '-086.5389118',
        ELSDLEA: '',
        UNSDLEA: '17860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1266, 43.6469],
            [-86.178, 43.6424],
            [-86.1592, 43.4977],
            [-86.2182, 43.4739],
            [-86.3182, 43.5286],
            [-86.4986, 43.5456],
            [-86.4613, 43.4723],
            [-87.1403, 43.4714],
            [-87.1266, 43.6469],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2631320',
        NAME: 'Shelby Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 372597547,
        AWATER: 997803561,
        INTPTLAT: '+43.6141883',
        INTPTLON: '-086.5358261',
        ELSDLEA: '',
        UNSDLEA: '31320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9895, 46.6801],
            [-88.8645, 46.6792],
            [-88.864, 46.7518],
            [-88.9323, 46.8081],
            [-88.7635, 46.8083],
            [-88.7635, 46.8516],
            [-88.6792, 46.8517],
            [-88.6788, 46.5926],
            [-88.4908, 46.5934],
            [-88.4843, 46.8323],
            [-88.4512, 46.939],
            [-88.2997, 46.9788],
            [-88.2981, 46.765],
            [-88.0447, 46.7654],
            [-87.9915, 46.6791],
            [-88.1158, 46.6784],
            [-88.24, 46.6785],
            [-88.2412, 46.5497],
            [-88.3659, 46.5498],
            [-88.3658, 46.42],
            [-88.6168, 46.4201],
            [-88.8459, 46.4199],
            [-88.8474, 46.5926],
            [-88.9908, 46.5932],
            [-88.9895, 46.6801],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600018',
        NAME: "L'Anse Area Schools",
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1871368358,
        AWATER: 211094776,
        INTPTLAT: '+46.5638281',
        INTPTLON: '-088.7307210',
        ELSDLEA: '',
        UNSDLEA: '00018',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5522, 46.9385],
            [-88.4512, 46.939],
            [-88.4843, 46.8323],
            [-88.4908, 46.5934],
            [-88.6788, 46.5926],
            [-88.6792, 46.8517],
            [-88.5522, 46.9385],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603990',
        NAME: 'Baraga Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 531389232,
        AWATER: 7314304,
        INTPTLAT: '+46.7560992',
        INTPTLON: '-088.5784264',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4834, 48.0137],
            [-89.3375, 47.9743],
            [-88.6774, 48.2454],
            [-88.3699, 48.3061],
            [-87.1127, 47.818],
            [-87.1121, 47.2233],
            [-87.5035, 47.222],
            [-88.0466, 47.2187],
            [-88.0461, 47.1997],
            [-88.106, 47.2494],
            [-87.9251, 47.3225],
            [-87.8616, 47.3783],
            [-87.5662, 47.3877],
            [-87.5669, 47.4538],
            [-87.7716, 47.4948],
            [-87.92, 47.4952],
            [-87.9201, 47.4384],
            [-88.1091, 47.4382],
            [-88.1096, 47.3301],
            [-88.2991, 47.3153],
            [-88.4465, 47.1995],
            [-88.4893, 47.1994],
            [-88.5126, 47.2859],
            [-88.9489, 47.4771],
            [-89.6807, 47.7146],
            [-89.4834, 48.0137],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607690',
        NAME: 'Calumet Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1037555611,
        AWATER: 13827161560,
        INTPTLAT: '+47.4586941',
        INTPTLON: '-088.1623741',
        ELSDLEA: '',
        UNSDLEA: '07690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4893, 47.1994],
            [-88.4465, 47.1995],
            [-88.4035, 47.1164],
            [-88.5002, 47.0675],
            [-88.5096, 47.1138],
            [-88.4893, 47.1994],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626940',
        NAME: 'Dollar Bay-Tamarack City Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61615040,
        AWATER: 19927993,
        INTPTLAT: '+47.1341040',
        INTPTLON: '-088.4666085',
        ELSDLEA: '',
        UNSDLEA: '26940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7426, 46.982],
            [-88.637, 47.0255],
            [-88.5982, 47.1223],
            [-88.5096, 47.1138],
            [-88.5002, 47.0675],
            [-88.4954, 47.0545],
            [-88.5964, 47.0551],
            [-88.5522, 46.9385],
            [-88.6792, 46.8517],
            [-88.7635, 46.8516],
            [-88.7426, 46.982],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628890',
        NAME: 'Houghton-Portage Township Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246340155,
        AWATER: 11753929,
        INTPTLAT: '+46.9684959',
        INTPTLON: '-088.6351959',
        ELSDLEA: '',
        UNSDLEA: '28890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.6157, 47.127],
              [-88.5982, 47.1223],
              [-88.637, 47.0255],
              [-88.7426, 46.982],
              [-88.806, 46.9821],
              [-88.8064, 47.0254],
              [-88.6157, 47.127],
            ],
          ],
          [
            [
              [-88.9333, 47.0313],
              [-88.9329, 46.9677],
              [-88.9325, 46.8943],
              [-89.0596, 46.9899],
              [-88.9333, 47.0313],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2601890',
        NAME: 'Adams Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214774288,
        AWATER: 1675318,
        INTPTLAT: '+47.0466293',
        INTPTLON: '-088.7328412',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9489, 47.4771],
            [-88.5126, 47.2859],
            [-88.4893, 47.1994],
            [-88.5096, 47.1138],
            [-88.5982, 47.1223],
            [-88.6157, 47.127],
            [-88.6826, 47.2143],
            [-88.8881, 47.1012],
            [-88.9333, 47.0313],
            [-88.9489, 47.4771],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617550',
        NAME: 'Hancock Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108702023,
        AWATER: 737720380,
        INTPTLAT: '+47.2296976',
        INTPTLON: '-088.6216571',
        ELSDLEA: '',
        UNSDLEA: '17550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7403, 46.5921],
            [-89.7387, 46.6789],
            [-89.3644, 46.6795],
            [-89.3605, 46.5925],
            [-89.2402, 46.5925],
            [-89.2403, 46.6798],
            [-88.9895, 46.6801],
            [-88.9908, 46.5932],
            [-88.8474, 46.5926],
            [-88.8459, 46.4199],
            [-88.9912, 46.4197],
            [-88.9906, 46.3333],
            [-89.3654, 46.333],
            [-89.3653, 46.5056],
            [-89.7415, 46.5052],
            [-89.7403, 46.5921],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600014',
        NAME: 'Ewen-Trout Creek Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1747638687,
        AWATER: 37579038,
        INTPTLAT: '+46.5060245',
        INTPTLON: '-089.2513932',
        ELSDLEA: '',
        UNSDLEA: '00014',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4954, 47.0545],
            [-88.4512, 46.939],
            [-88.5522, 46.9385],
            [-88.5964, 47.0551],
            [-88.4954, 47.0545],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608880',
        NAME: 'Chassell Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125102290,
        AWATER: 8914140,
        INTPTLAT: '+46.9825681',
        INTPTLON: '-088.5342965',
        ELSDLEA: '',
        UNSDLEA: '08880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5416, 42.3328],
            [-85.536, 42.3328],
            [-85.4586, 42.3094],
            [-85.4619, 42.2195],
            [-85.4707, 42.2156],
            [-85.5505, 42.245],
            [-85.5416, 42.3328],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610590',
        NAME: 'Comstock Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64484801,
        AWATER: 2296963,
        INTPTLAT: '+42.2792561',
        INTPTLON: '-085.5022789',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5434, 42.4362],
            [-85.4423, 42.4495],
            [-85.2535, 42.4344],
            [-85.2217, 42.3989],
            [-85.298, 42.3517],
            [-85.4586, 42.3094],
            [-85.536, 42.3328],
            [-85.5321, 42.3908],
            [-85.5434, 42.4362],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617250',
        NAME: 'Gull Lake Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 262984132,
        AWATER: 14657246,
        INTPTLAT: '+42.3832742',
        INTPTLON: '-085.3889820',
        ELSDLEA: '',
        UNSDLEA: '17250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5321, 42.3908],
            [-85.536, 42.3328],
            [-85.5416, 42.3328],
            [-85.629, 42.3329],
            [-85.5321, 42.3908],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627420',
        NAME: 'Parchment School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37631329,
        AWATER: 286607,
        INTPTLAT: '+42.3445619',
        INTPTLON: '-085.5642967',
        ELSDLEA: '',
        UNSDLEA: '27420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6867, 42.208],
            [-85.5505, 42.245],
            [-85.4707, 42.2156],
            [-85.4716, 42.2013],
            [-85.5865, 42.1574],
            [-85.6672, 42.1865],
            [-85.6867, 42.208],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628950',
        NAME: 'Portage Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103982953,
        AWATER: 6955296,
        INTPTLAT: '+42.2051870',
        INTPTLON: '-085.5861310',
        ELSDLEA: '',
        UNSDLEA: '28950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3654, 46.333],
            [-88.9906, 46.3333],
            [-88.9909, 46.0974],
            [-89.1805, 46.1554],
            [-89.4906, 46.2154],
            [-89.4904, 46.3331],
            [-89.3654, 46.333],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635340',
        NAME: 'Watersmeet Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 659909373,
        AWATER: 59529267,
        INTPTLAT: '+46.2516551',
        INTPTLON: '-089.2107233',
        ELSDLEA: '',
        UNSDLEA: '35340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7617, 45.41],
            [-83.5203, 45.3475],
            [-83.5161, 45.2295],
            [-83.5811, 45.2053],
            [-83.8413, 45.2033],
            [-83.8812, 45.2021],
            [-83.8072, 45.2898],
            [-83.7617, 45.41],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629130',
        NAME: 'Posen Consolidated School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 398942015,
        AWATER: 17277489,
        INTPTLAT: '+45.2843185',
        INTPTLON: '-083.6684178',
        ELSDLEA: '',
        UNSDLEA: '29130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5401, 42.8566],
            [-84.4839, 42.8614],
            [-84.3599, 42.8256],
            [-84.3638, 42.792],
            [-84.4527, 42.7696],
            [-84.4836, 42.7848],
            [-84.5429, 42.8276],
            [-84.5401, 42.8566],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604170',
        NAME: 'Bath Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99933153,
        AWATER: 7404909,
        INTPTLAT: '+42.8288357',
        INTPTLON: '-084.4447756',
        ELSDLEA: '',
        UNSDLEA: '04170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1786, 42.584],
            [-84.1448, 42.5996],
            [-84.1655, 42.7501],
            [-84.1287, 42.7833],
            [-84.0984, 42.7845],
            [-84.0934, 42.7845],
            [-84.0892, 42.7845],
            [-83.9738, 42.739],
            [-84.0543, 42.5682],
            [-84.0478, 42.5103],
            [-84.0574, 42.4967],
            [-84.1681, 42.569],
            [-84.1786, 42.584],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614730',
        NAME: 'Fowlerville Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301019150,
        AWATER: 1633038,
        INTPTLAT: '+42.6563711',
        INTPTLON: '-084.0859905',
        ELSDLEA: '',
        UNSDLEA: '14730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4339, 42.9077],
            [-84.4339, 42.9132],
            [-84.434, 42.9178],
            [-84.3298, 42.9278],
            [-84.3132, 42.9234],
            [-84.2772, 42.8985],
            [-84.3599, 42.8256],
            [-84.4839, 42.8614],
            [-84.4339, 42.9077],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620550',
        NAME: 'Laingsburg Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106837873,
        AWATER: 11060647,
        INTPTLAT: '+42.8828839',
        INTPTLON: '-084.3713041',
        ELSDLEA: '',
        UNSDLEA: '20550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.1779, 42.9428],
              [-84.1735, 42.9398],
              [-84.1716, 42.937],
              [-84.1698, 42.9371],
              [-84.1632, 42.9274],
              [-84.1632, 42.9251],
              [-84.1631, 42.923],
              [-84.1529, 42.894],
              [-84.1528, 42.8901],
              [-84.1084, 42.8944],
              [-84.0709, 42.8504],
              [-84.0764, 42.8503],
              [-84.0867, 42.8465],
              [-84.0892, 42.7845],
              [-84.0934, 42.7845],
              [-84.0984, 42.7845],
              [-84.1287, 42.7833],
              [-84.1655, 42.7501],
              [-84.1744, 42.743],
              [-84.186, 42.7625],
              [-84.1694, 42.8128],
              [-84.2022, 42.9141],
              [-84.1779, 42.9428],
            ],
          ],
          [
            [
              [-84.2138, 42.7543],
              [-84.186, 42.7625],
              [-84.1795, 42.7393],
              [-84.2138, 42.7543],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624630',
        NAME: 'Morrice Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133118782,
        AWATER: 4472345,
        INTPTLAT: '+42.8434626',
        INTPTLON: '-084.1404769',
        ELSDLEA: '',
        UNSDLEA: '24630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.153, 42.2644],
            [-83.1, 42.2867],
            [-83.1143, 42.2569],
            [-83.153, 42.2644],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629760',
        NAME: 'River Rouge School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7331536,
        AWATER: 1536824,
        INTPTLAT: '+42.2743459',
        INTPTLON: '-083.1241616',
        ELSDLEA: '',
        UNSDLEA: '29760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.918, 42.422],
            [-82.9354, 42.4502],
            [-82.8699, 42.4512],
            [-82.7499, 42.4511],
            [-82.8302, 42.3735],
            [-82.923, 42.3522],
            [-82.918, 42.422],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625740',
        NAME: 'Grosse Pointe Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28673575,
        AWATER: 77381964,
        INTPTLAT: '+42.4443980',
        INTPTLON: '-082.8736621',
        ELSDLEA: '',
        UNSDLEA: '25740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3311, 45.1867],
            [-86.0781, 45.1112],
            [-85.8708, 44.9537],
            [-85.8735, 44.9004],
            [-85.7159, 44.9142],
            [-85.7188, 44.8626],
            [-85.8345, 44.7752],
            [-86.1799, 44.7783],
            [-86.7345, 44.7731],
            [-86.6863, 44.881],
            [-86.4999, 45.0808],
            [-86.3311, 45.1867],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616020',
        NAME: 'Glen Lake Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 395332647,
        AWATER: 1948294027,
        INTPTLAT: '+45.0097693',
        INTPTLON: '-086.1362990',
        ELSDLEA: '',
        UNSDLEA: '16020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8473, 44.858],
            [-84.5271, 44.8574],
            [-84.3718, 44.855],
            [-84.3718, 44.7689],
            [-84.3717, 44.6825],
            [-84.4864, 44.6839],
            [-84.4895, 44.5977],
            [-84.6692, 44.5987],
            [-84.6713, 44.5113],
            [-84.8509, 44.5111],
            [-84.8473, 44.858],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611030',
        NAME: 'Crawford AuSable Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1157293519,
        AWATER: 17289448,
        INTPTLAT: '+44.7172077',
        INTPTLON: '-084.6459674',
        ELSDLEA: '',
        UNSDLEA: '11030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2279, 42.6146],
            [-86.1645, 42.6366],
            [-86.1342, 42.6455],
            [-86.0157, 42.623],
            [-85.9763, 42.5781],
            [-86.0127, 42.4843],
            [-86.1388, 42.4652],
            [-86.2026, 42.506],
            [-86.2411, 42.5347],
            [-86.2279, 42.6146],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614230',
        NAME: 'Fennville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290310346,
        AWATER: 5234652,
        INTPTLAT: '+42.5558426',
        INTPTLON: '-086.1072802',
        ELSDLEA: '',
        UNSDLEA: '14230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0626, 42.7688],
            [-85.9394, 42.7389],
            [-85.8613, 42.7675],
            [-85.8219, 42.7678],
            [-85.8985, 42.6518],
            [-86.0157, 42.623],
            [-86.1342, 42.6455],
            [-86.1956, 42.7433],
            [-86.0626, 42.7688],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617400',
        NAME: 'Hamilton Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 330135737,
        AWATER: 2800814,
        INTPTLAT: '+42.6969367',
        INTPTLON: '-086.0088921',
        ELSDLEA: '',
        UNSDLEA: '17400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2102, 42.7713],
            [-86.0662, 42.8016],
            [-86.0626, 42.7688],
            [-86.1956, 42.7433],
            [-86.2071, 42.7468],
            [-86.2102, 42.7713],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618420',
        NAME: 'Holland City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42354117,
        AWATER: 2690537,
        INTPTLAT: '+42.7725503',
        INTPTLON: '-086.1215320',
        ELSDLEA: '',
        UNSDLEA: '18420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2454, 42.5059],
            [-86.2026, 42.506],
            [-86.1388, 42.4652],
            [-86.1102, 42.3968],
            [-86.2213, 42.3389],
            [-86.2241, 42.3315],
            [-86.3036, 42.3453],
            [-86.2454, 42.5059],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632300',
        NAME: 'South Haven Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 185257777,
        AWATER: 1080088,
        INTPTLAT: '+42.4154791',
        INTPTLON: '-086.2153287',
        ELSDLEA: '',
        UNSDLEA: '32300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.575, 42.7393],
            [-85.5456, 42.7681],
            [-85.3388, 42.7906],
            [-85.3101, 42.7906],
            [-85.2895, 42.7615],
            [-85.3082, 42.7479],
            [-85.4267, 42.5952],
            [-85.5267, 42.5994],
            [-85.575, 42.7393],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633810',
        NAME: 'Thornapple Kellogg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292391880,
        AWATER: 10785116,
        INTPTLAT: '+42.6973873',
        INTPTLON: '-085.4487100',
        ELSDLEA: '',
        UNSDLEA: '33810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8492, 41.7964],
            [-84.6408, 41.8108],
            [-84.5926, 41.8149],
            [-84.5195, 41.7598],
            [-84.5803, 41.7017],
            [-84.6894, 41.6996],
            [-84.806, 41.6961],
            [-84.8059, 41.7027],
            [-84.8059, 41.7602],
            [-84.8252, 41.7779],
            [-84.8492, 41.7964],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607710',
        NAME: 'Camden-Frontier School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 246278184,
        AWATER: 1960591,
        INTPTLAT: '+41.7566810',
        INTPTLON: '-084.6843937',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4009, 42.0137],
            [-85.4061, 42.0825],
            [-85.3082, 42.0715],
            [-85.2427, 42.0213],
            [-85.2345, 41.9758],
            [-85.2924, 41.8975],
            [-85.3946, 41.8973],
            [-85.4342, 41.9452],
            [-85.4009, 42.0137],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610410',
        NAME: 'Colon Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255296421,
        AWATER: 7976035,
        INTPTLAT: '+41.9813933',
        INTPTLON: '-085.3242183',
        ELSDLEA: '',
        UNSDLEA: '10410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2427, 42.0213],
            [-85.1584, 42.1018],
            [-85.1677, 42.1595],
            [-85.0603, 42.1818],
            [-85.0487, 42.1598],
            [-85.0356, 42.0585],
            [-85.1035, 41.9563],
            [-85.2345, 41.9758],
            [-85.2427, 42.0213],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634410',
        NAME: 'Union City Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255964908,
        AWATER: 4469539,
        INTPTLAT: '+42.0630676',
        INTPTLON: '-085.1347832',
        ELSDLEA: '',
        UNSDLEA: '34410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.632, 42.1149],
            [-84.4805, 42.1601],
            [-84.3735, 42.1461],
            [-84.4201, 42.0723],
            [-84.4496, 42.059],
            [-84.4927, 42.0726],
            [-84.6513, 42.0781],
            [-84.632, 42.1149],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617640',
        NAME: 'Hanover-Horton Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163173682,
        AWATER: 6101981,
        INTPTLAT: '+42.1087481',
        INTPTLON: '-084.5075431',
        ELSDLEA: '',
        UNSDLEA: '17640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5929, 41.9672],
            [-84.4927, 42.0726],
            [-84.4496, 42.059],
            [-84.4402, 41.9532],
            [-84.5154, 41.8982],
            [-84.5929, 41.9672],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625650',
        NAME: 'North Adams-Jerome Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165984495,
        AWATER: 2031900,
        INTPTLAT: '+41.9783210',
        INTPTLON: '-084.4870283',
        ELSDLEA: '',
        UNSDLEA: '25650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5154, 41.8982],
            [-84.4402, 41.9532],
            [-84.4157, 41.9351],
            [-84.3901, 41.8114],
            [-84.5195, 41.7598],
            [-84.5926, 41.8149],
            [-84.5154, 41.8982],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628500',
        NAME: 'Pittsford Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204781745,
        AWATER: 1450398,
        INTPTLAT: '+41.8427432',
        INTPTLON: '-084.4788731',
        ELSDLEA: '',
        UNSDLEA: '28500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7483, 41.9805],
            [-84.6756, 42.0718],
            [-84.6513, 42.0781],
            [-84.4927, 42.0726],
            [-84.5929, 41.9672],
            [-84.7484, 41.9344],
            [-84.7483, 41.9805],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619920',
        NAME: 'Jonesville Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 195384585,
        AWATER: 1688393,
        INTPTLAT: '+42.0062613',
        INTPTLON: '-084.6351576',
        ELSDLEA: '',
        UNSDLEA: '19920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6812, 43.6914],
            [-85.6033, 43.7286],
            [-85.4699, 43.7953],
            [-85.3848, 43.7993],
            [-85.3444, 43.7703],
            [-85.3649, 43.6705],
            [-85.5622, 43.6421],
            [-85.6812, 43.6914],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2605780',
        NAME: 'Big Rapids Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338851531,
        AWATER: 9667682,
        INTPTLAT: '+43.7043649',
        INTPTLON: '-085.4964670',
        ELSDLEA: '',
        UNSDLEA: '05780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.67, 43.0873],
            [-85.6701, 43.0945],
            [-85.6338, 43.0607],
            [-85.6609, 43.029],
            [-85.6624, 43.0214],
            [-85.67, 43.0873],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610620',
        NAME: 'Comstock Park Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24758225,
        AWATER: 1036914,
        INTPTLAT: '+43.0627291',
        INTPTLON: '-085.6572599',
        ELSDLEA: '',
        UNSDLEA: '10620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7484, 41.9344],
            [-84.5929, 41.9672],
            [-84.5154, 41.8982],
            [-84.5926, 41.8149],
            [-84.6408, 41.8108],
            [-84.7629, 41.9324],
            [-84.7484, 41.9344],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618390',
        NAME: 'Hillsdale Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175768141,
        AWATER: 5958443,
        INTPTLAT: '+41.8862976',
        INTPTLON: '-084.6226982',
        ELSDLEA: '',
        UNSDLEA: '18390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9872, 44.0817],
            [-84.9679, 44.1624],
            [-84.9522, 44.1621],
            [-84.6081, 44.1605],
            [-84.547, 44.1189],
            [-84.7065, 43.9666],
            [-84.7881, 43.9012],
            [-84.8487, 43.9875],
            [-84.9681, 43.9879],
            [-84.9872, 44.0817],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617820',
        NAME: 'Harrison Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 631022224,
        AWATER: 9605863,
        INTPTLAT: '+44.0501896',
        INTPTLON: '-084.7841687',
        ELSDLEA: '',
        UNSDLEA: '17820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3706, 44.5072],
            [-83.9131, 44.5089],
            [-83.8868, 44.5233],
            [-83.8866, 44.509],
            [-83.9444, 44.4218],
            [-83.9544, 44.3056],
            [-84.0848, 44.248],
            [-84.105, 44.1619],
            [-84.1664, 44.0831],
            [-84.2884, 44.0838],
            [-84.368, 44.1605],
            [-84.3696, 44.3341],
            [-84.3706, 44.5072],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635850',
        NAME: 'West Branch-Rose City Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1319243655,
        AWATER: 21186118,
        INTPTLAT: '+44.3281655',
        INTPTLON: '-084.1891148',
        ELSDLEA: '',
        UNSDLEA: '35850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7065, 43.9666],
            [-84.547, 44.1189],
            [-84.6081, 44.1605],
            [-84.368, 44.1605],
            [-84.2884, 44.0838],
            [-84.2877, 43.9976],
            [-84.466, 43.959],
            [-84.4849, 43.9081],
            [-84.6756, 43.9377],
            [-84.7065, 43.9666],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615990',
        NAME: 'Gladwin Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559259355,
        AWATER: 14864940,
        INTPTLAT: '+44.0394656',
        INTPTLON: '-084.4792314',
        ELSDLEA: '',
        UNSDLEA: '15990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2876, 45.1131],
            [-84.2285, 45.1813],
            [-84.1233, 45.185],
            [-84.1242, 45.0284],
            [-84.0123, 45.0297],
            [-84.01, 44.8557],
            [-84.1316, 44.8549],
            [-84.1325, 44.9421],
            [-84.293, 44.9415],
            [-84.2876, 45.1131],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603570',
        NAME: 'Atlanta Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 491407457,
        AWATER: 9265359,
        INTPTLAT: '+45.0116805',
        INTPTLON: '-084.1553823',
        ELSDLEA: '',
        UNSDLEA: '03570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1799, 44.7783],
            [-85.8345, 44.7752],
            [-85.8176, 44.5477],
            [-85.7974, 44.4978],
            [-85.8893, 44.4329],
            [-85.9395, 44.4262],
            [-86.0601, 44.4557],
            [-86.1812, 44.5],
            [-86.1809, 44.5179],
            [-86.1397, 44.5928],
            [-86.1783, 44.6587],
            [-86.1799, 44.7783],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604950',
        NAME: 'Benzie County Central School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 866474606,
        AWATER: 85714601,
        INTPTLAT: '+44.6200811',
        INTPTLON: '-085.9881170',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8345, 44.7752],
            [-85.7188, 44.8626],
            [-85.6053, 44.8479],
            [-85.5543, 45.0314],
            [-85.4807, 45.1203],
            [-85.4329, 45.0215],
            [-85.4431, 44.8598],
            [-85.4243, 44.769],
            [-85.4555, 44.6857],
            [-85.3334, 44.6851],
            [-85.394, 44.6418],
            [-85.5749, 44.6426],
            [-85.7375, 44.5998],
            [-85.8176, 44.5477],
            [-85.8345, 44.7752],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633870',
        NAME: 'Traverse City Area Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 690731066,
        AWATER: 350636388,
        INTPTLAT: '+44.7923079',
        INTPTLON: '-085.5865043',
        ELSDLEA: '',
        UNSDLEA: '33870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.105, 44.1619],
            [-83.9978, 44.1565],
            [-83.9674, 44.1274],
            [-83.9675, 44.113],
            [-83.9676, 44.1058],
            [-83.8367, 44.0234],
            [-83.8066, 43.9781],
            [-83.4838, 43.9767],
            [-83.7085, 43.8339],
            [-83.8941, 43.9033],
            [-84.166, 43.9335],
            [-84.1664, 44.0831],
            [-84.105, 44.1619],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632940',
        NAME: 'Standish-Sterling Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 551372693,
        AWATER: 322558780,
        INTPTLAT: '+43.9902914',
        INTPTLON: '-083.9210759',
        ELSDLEA: '',
        UNSDLEA: '32940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.171, 42.3654],
            [-84.166, 42.369],
            [-84.0151, 42.4239],
            [-83.9874, 42.424],
            [-83.9203, 42.2819],
            [-83.9355, 42.2467],
            [-84.1134, 42.2342],
            [-84.171, 42.3654],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608940',
        NAME: 'Chelsea School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310955286,
        AWATER: 14947791,
        INTPTLAT: '+42.3190099',
        INTPTLON: '-084.0488939',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3843, 42.2914],
            [-84.2847, 42.3517],
            [-84.2216, 42.3395],
            [-84.2845, 42.2543],
            [-84.3648, 42.2369],
            [-84.3843, 42.2914],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612540',
        NAME: 'East Jackson Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87407832,
        AWATER: 3961918,
        INTPTLAT: '+42.2996165',
        INTPTLON: '-084.3027838',
        ELSDLEA: '',
        UNSDLEA: '12540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3647, 42.2116],
            [-84.2491, 42.2004],
            [-84.1426, 42.1983],
            [-84.1529, 42.156],
            [-84.3641, 42.146],
            [-84.3645, 42.1896],
            [-84.3647, 42.2116],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624960',
        NAME: 'Napoleon Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 112982588,
        AWATER: 8662438,
        INTPTLAT: '+42.1704499',
        INTPTLON: '-084.2546994',
        ELSDLEA: '',
        UNSDLEA: '24960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7071, 42.942],
            [-85.686, 42.9225],
            [-85.7137, 42.9279],
            [-85.7071, 42.942],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616080',
        NAME: 'Godfrey-Lee Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3583018,
        AWATER: 117450,
        INTPTLAT: '+42.9323526',
        INTPTLON: '-085.6977639',
        ELSDLEA: '',
        UNSDLEA: '16080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8771, 42.9869],
            [-85.7853, 42.9869],
            [-85.7515, 42.9793],
            [-85.7201, 42.9388],
            [-85.7131, 42.8553],
            [-85.7823, 42.8481],
            [-85.807, 42.8709],
            [-85.7822, 42.9117],
            [-85.8636, 42.9464],
            [-85.8761, 42.9579],
            [-85.8771, 42.9869],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616470',
        NAME: 'Grandville Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99924498,
        AWATER: 3807534,
        INTPTLAT: '+42.9198409',
        INTPTLON: '-085.7771811',
        ELSDLEA: '',
        UNSDLEA: '16470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9796, 43.3375],
            [-85.8914, 43.3801],
            [-85.7698, 43.3778],
            [-85.6825, 43.3809],
            [-85.6411, 43.294],
            [-85.673, 43.2938],
            [-85.7904, 43.293],
            [-85.8433, 43.2495],
            [-85.8895, 43.2419],
            [-85.9597, 43.2934],
            [-85.9796, 43.3375],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616500',
        NAME: 'Grant Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 299348779,
        AWATER: 5221624,
        INTPTLAT: '+43.3255518',
        INTPTLON: '-085.8055530',
        ELSDLEA: '',
        UNSDLEA: '16500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8895, 43.2419],
            [-85.8433, 43.2495],
            [-85.7904, 43.293],
            [-85.673, 43.2938],
            [-85.6387, 43.205],
            [-85.8495, 43.1804],
            [-85.8895, 43.2419],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620310',
        NAME: 'Kent City Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174471430,
        AWATER: 1521027,
        INTPTLAT: '+43.2414307',
        INTPTLON: '-085.7613500',
        ELSDLEA: '',
        UNSDLEA: '20310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6058, 43.1681],
            [-85.4509, 43.1543],
            [-85.4313, 43.158],
            [-85.4067, 43.1216],
            [-85.4111, 43.0687],
            [-85.4803, 43.0357],
            [-85.5451, 43.0282],
            [-85.6338, 43.0607],
            [-85.6701, 43.0945],
            [-85.6058, 43.1681],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630030',
        NAME: 'Rockford Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217797590,
        AWATER: 8847554,
        INTPTLAT: '+43.1043786',
        INTPTLON: '-085.5155753',
        ELSDLEA: '',
        UNSDLEA: '30030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8495, 43.1693],
            [-85.8495, 43.1804],
            [-85.6387, 43.205],
            [-85.6058, 43.1681],
            [-85.6701, 43.0945],
            [-85.67, 43.0873],
            [-85.7387, 43.0878],
            [-85.7887, 43.0959],
            [-85.8495, 43.1693],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632370',
        NAME: 'Sparta Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 187693333,
        AWATER: 2943880,
        INTPTLAT: '+43.1519272',
        INTPTLON: '-085.7368340',
        ELSDLEA: '',
        UNSDLEA: '32370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8938, 45.4927],
            [-84.7303, 45.4644],
            [-84.73, 45.3889],
            [-84.8787, 45.428],
            [-84.8938, 45.4927],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621810',
        NAME: 'Alanson Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103261219,
        AWATER: 5465663,
        INTPTLAT: '+45.4574150',
        INTPTLON: '-084.8009640',
        ELSDLEA: '',
        UNSDLEA: '21810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4496, 42.059],
            [-84.4201, 42.0723],
            [-84.2805, 42.0302],
            [-84.2458, 41.9189],
            [-84.362, 41.9018],
            [-84.4157, 41.9351],
            [-84.4402, 41.9532],
            [-84.4496, 42.059],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2601920',
        NAME: 'Addison Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182344379,
        AWATER: 11566200,
        INTPTLAT: '+41.9843327',
        INTPTLON: '-084.3460816',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3343, 41.7597],
            [-84.2643, 41.8277],
            [-84.1833, 41.8325],
            [-84.0767, 41.7149],
            [-84.2213, 41.7103],
            [-84.3559, 41.7067],
            [-84.3343, 41.7597],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624570',
        NAME: 'Morenci Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190656154,
        AWATER: 351913,
        INTPTLAT: '+41.7602365',
        INTPTLON: '-084.2158682',
        ELSDLEA: '',
        UNSDLEA: '24570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1599, 41.8657],
            [-84.0713, 41.8632],
            [-84.0031, 41.8412],
            [-83.9814, 41.818],
            [-83.9577, 41.718],
            [-84.0767, 41.7149],
            [-84.1833, 41.8325],
            [-84.1599, 41.8657],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630780',
        NAME: 'Sand Creek Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202936531,
        AWATER: 280902,
        INTPTLAT: '+41.7951529',
        INTPTLON: '-084.0757783',
        ELSDLEA: '',
        UNSDLEA: '30780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0759, 42.0645],
            [-83.9664, 42.0427],
            [-83.8447, 42.0812],
            [-83.842, 42.0813],
            [-83.8498, 42.0086],
            [-83.8961, 41.9352],
            [-83.9296, 41.9128],
            [-84.0744, 41.9843],
            [-84.0759, 42.0645],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633720',
        NAME: 'Tecumseh Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201033755,
        AWATER: 1648120,
        INTPTLAT: '+42.0020394',
        INTPTLON: '-083.9566052',
        ELSDLEA: '',
        UNSDLEA: '33720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4774, 42.1245],
            [-86.4162, 42.1836],
            [-86.4075, 42.1796],
            [-86.3894, 42.1574],
            [-86.3006, 42.109],
            [-86.3006, 42.072],
            [-86.3774, 42.0218],
            [-86.4087, 42.0552],
            [-86.4774, 42.1245],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604830',
        NAME: 'Benton Harbor Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149735221,
        AWATER: 2546175,
        INTPTLAT: '+42.0982650',
        INTPTLON: '-086.3891525',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4466, 41.8707],
            [-86.3688, 41.7599],
            [-86.4146, 41.7597],
            [-86.5724, 41.7595],
            [-86.5417, 41.8348],
            [-86.4466, 41.8707],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615480',
        NAME: 'Galien Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127928887,
        AWATER: 1173329,
        INTPTLAT: '+41.7995913',
        INTPTLON: '-086.4799782',
        ELSDLEA: '',
        UNSDLEA: '15480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3139, 41.8995],
            [-86.2231, 41.9351],
            [-86.1563, 41.9274],
            [-86.1077, 41.8545],
            [-86.1859, 41.8116],
            [-86.3134, 41.7814],
            [-86.3139, 41.8995],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625560',
        NAME: 'Niles Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180575600,
        AWATER: 4456618,
        INTPTLAT: '+41.8592659',
        INTPTLON: '-086.2355160',
        ELSDLEA: '',
        UNSDLEA: '25560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5908, 41.9285],
            [-86.4945, 41.9268],
            [-86.4557, 41.9259],
            [-86.4169, 41.8995],
            [-86.4466, 41.8707],
            [-86.5417, 41.8348],
            [-86.5724, 41.7595],
            [-86.6688, 41.7597],
            [-86.6865, 41.8388],
            [-86.5908, 41.9285],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629790',
        NAME: 'River Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 208540941,
        AWATER: 1399672,
        INTPTLAT: '+41.8476364',
        INTPTLON: '-086.5780666',
        ELSDLEA: '',
        UNSDLEA: '29790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0515, 43.1362],
            [-85.8495, 43.1693],
            [-85.7887, 43.0959],
            [-85.8678, 43.0271],
            [-85.8167, 43.0127],
            [-85.7853, 42.9869],
            [-85.8771, 42.9869],
            [-86.0265, 43.0239],
            [-86.0363, 43.1144],
            [-86.0515, 43.1362],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610830',
        NAME: 'Coopersville Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282717166,
        AWATER: 2044166,
        INTPTLAT: '+43.0686115',
        INTPTLON: '-085.9154593',
        ELSDLEA: '',
        UNSDLEA: '10830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0995, 43.497],
            [-85.9205, 43.5971],
            [-85.8861, 43.5763],
            [-85.8011, 43.4964],
            [-85.8204, 43.4602],
            [-85.8405, 43.449],
            [-85.9002, 43.3914],
            [-85.8914, 43.3801],
            [-85.9796, 43.3375],
            [-86.0995, 43.497],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615150',
        NAME: 'Fremont Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 377172979,
        AWATER: 16825060,
        INTPTLAT: '+43.4657578',
        INTPTLON: '-085.9515969',
        ELSDLEA: '',
        UNSDLEA: '15150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2547, 42.6301],
            [-84.2452, 42.7296],
            [-84.2138, 42.7543],
            [-84.1795, 42.7393],
            [-84.1744, 42.743],
            [-84.1655, 42.7501],
            [-84.1448, 42.5996],
            [-84.1786, 42.584],
            [-84.2547, 42.6301],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635730',
        NAME: 'Webberville Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102311573,
        AWATER: 290515,
        INTPTLAT: '+42.6705545',
        INTPTLON: '-084.1847490',
        ELSDLEA: '',
        UNSDLEA: '35730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2182, 43.4739],
            [-86.2189, 43.4691],
            [-86.4271, 43.3752],
            [-86.4613, 43.4723],
            [-86.4986, 43.5456],
            [-86.3182, 43.5286],
            [-86.2182, 43.4739],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624180',
        NAME: 'Montague Area Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238071129,
        AWATER: 12585360,
        INTPTLAT: '+43.4799932',
        INTPTLON: '-086.3661485',
        ELSDLEA: '',
        UNSDLEA: '24180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2501, 43.2594],
            [-86.2726, 43.2442],
            [-86.3265, 43.2349],
            [-86.2501, 43.2594],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625800',
        NAME: 'North Muskegon Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4514409,
        AWATER: 6785610,
        INTPTLAT: '+43.2489350',
        INTPTLON: '-086.2873438',
        ELSDLEA: '',
        UNSDLEA: '25800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2237, 43.2545],
            [-86.144, 43.2711],
            [-86.1449, 43.2052],
            [-86.2052, 43.205],
            [-86.2237, 43.2545],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626760',
        NAME: 'Orchard View Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35446359,
        AWATER: 229628,
        INTPTLAT: '+43.2363908',
        INTPTLON: '-086.1777322',
        ELSDLEA: '',
        UNSDLEA: '26760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1067, 43.2057],
            [-86.0279, 43.2061],
            [-86.0201, 43.2934],
            [-85.9597, 43.2934],
            [-85.8895, 43.2419],
            [-85.8495, 43.1804],
            [-85.8495, 43.1693],
            [-86.0515, 43.1362],
            [-86.1067, 43.2057],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629370',
        NAME: 'Ravenna Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228206180,
        AWATER: 2704512,
        INTPTLAT: '+43.1985643',
        INTPTLON: '-085.9628236',
        ELSDLEA: '',
        UNSDLEA: '29370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7314, 42.2955],
            [-85.6388, 42.3328],
            [-85.629, 42.3329],
            [-85.5416, 42.3328],
            [-85.5505, 42.245],
            [-85.6867, 42.208],
            [-85.7314, 42.2955],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619950',
        NAME: 'Kalamazoo Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140991514,
        AWATER: 1861951,
        INTPTLAT: '+42.2782181',
        INTPTLON: '-085.6373187',
        ELSDLEA: '',
        UNSDLEA: '19950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.754, 42.1167],
            [-85.6672, 42.1865],
            [-85.5865, 42.1574],
            [-85.6023, 42.0702],
            [-85.7629, 42.0693],
            [-85.7634, 42.0983],
            [-85.754, 42.1167],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2631020',
        NAME: 'Schoolcraft Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129373821,
        AWATER: 2449516,
        INTPTLAT: '+42.1173118',
        INTPTLON: '-085.6759993',
        ELSDLEA: '',
        UNSDLEA: '31020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4586, 42.3094],
            [-85.298, 42.3517],
            [-85.2972, 42.2727],
            [-85.4619, 42.2195],
            [-85.4586, 42.3094],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615450',
        NAME: 'Galesburg-Augusta Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133768115,
        AWATER: 4617830,
        INTPTLAT: '+42.2889450',
        INTPTLON: '-085.3831665',
        ELSDLEA: '',
        UNSDLEA: '15450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9874, 42.424],
            [-83.7872, 42.4286],
            [-83.7928, 42.3737],
            [-83.857, 42.2484],
            [-83.9005, 42.2451],
            [-83.9355, 42.2467],
            [-83.9203, 42.2819],
            [-83.9874, 42.424],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612030',
        NAME: 'Dexter Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211860143,
        AWATER: 6057421,
        INTPTLAT: '+42.3620182',
        INTPTLON: '-083.8843371',
        ELSDLEA: '',
        UNSDLEA: '12030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0478, 42.5103],
            [-83.8712, 42.5202],
            [-83.7889, 42.4564],
            [-83.7872, 42.4286],
            [-83.9874, 42.424],
            [-84.0151, 42.4239],
            [-84.0574, 42.4967],
            [-84.0478, 42.5103],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628140',
        NAME: 'Pinckney Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 167969087,
        AWATER: 13891167,
        INTPTLAT: '+42.4665175',
        INTPTLON: '-083.9237432',
        ELSDLEA: '',
        UNSDLEA: '28140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7928, 42.3737],
            [-83.7872, 42.4286],
            [-83.7889, 42.4564],
            [-83.7402, 42.4394],
            [-83.6626, 42.3618],
            [-83.7313, 42.3585],
            [-83.7928, 42.3737],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636330',
        NAME: 'Whitmore Lake Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61536127,
        AWATER: 3291502,
        INTPTLAT: '+42.3913102',
        INTPTLON: '-083.7464743',
        ELSDLEA: '',
        UNSDLEA: '36330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1563, 41.9274],
            [-86.0147, 41.9429],
            [-85.9913, 41.991],
            [-85.8759, 41.9694],
            [-85.8383, 41.8971],
            [-85.9261, 41.8102],
            [-86.0689, 41.8467],
            [-86.1077, 41.8545],
            [-86.1563, 41.9274],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608430',
        NAME: 'Cassopolis Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 323022599,
        AWATER: 12257442,
        INTPTLAT: '+41.8904079',
        INTPTLON: '-085.9851438',
        ELSDLEA: '',
        UNSDLEA: '08430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.825, 41.7603],
            [-86.6865, 41.8388],
            [-86.6688, 41.7597],
            [-86.6997, 41.7598],
            [-86.825, 41.7603],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625140',
        NAME: 'New Buffalo Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59033484,
        AWATER: 683069,
        INTPTLAT: '+41.7882743',
        INTPTLON: '-086.7226201',
        ELSDLEA: '',
        UNSDLEA: '25140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4466, 41.8707],
            [-86.4169, 41.8995],
            [-86.3139, 41.8995],
            [-86.3134, 41.7814],
            [-86.313, 41.7603],
            [-86.3688, 41.7599],
            [-86.4466, 41.8707],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607140',
        NAME: 'Buchanan Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129101254,
        AWATER: 3543327,
        INTPTLAT: '+41.8379226',
        INTPTLON: '-086.3804502',
        ELSDLEA: '',
        UNSDLEA: '07140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.3453, 44.124],
              [-85.341, 44.1314],
              [-85.334, 44.1648],
              [-85.3064, 44.1169],
              [-85.2065, 44.1649],
              [-84.9679, 44.1624],
              [-84.9872, 44.0817],
              [-84.9681, 43.9879],
              [-85.0879, 43.9872],
              [-85.1381, 44.0083],
              [-85.2064, 44.0739],
              [-85.3256, 44.0734],
              [-85.3453, 44.124],
            ],
          ],
          [
            [
              [-85.4055, 44.1458],
              [-85.3775, 44.151],
              [-85.4097, 44.1168],
              [-85.4055, 44.1458],
            ],
          ],
          [
            [
              [-85.4135, 44.1168],
              [-85.4097, 44.1168],
              [-85.4015, 44.1123],
              [-85.4135, 44.1168],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622800',
        NAME: 'Marion Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426613252,
        AWATER: 3204445,
        INTPTLAT: '+44.0832291',
        INTPTLON: '-085.1214649',
        ELSDLEA: '',
        UNSDLEA: '22800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.723, 45.6655],
            [-84.6704, 45.6793],
            [-84.6086, 45.7668],
            [-84.4526, 45.6954],
            [-84.3531, 45.6994],
            [-84.2011, 45.7523],
            [-84.2043, 45.6262],
            [-84.252, 45.626],
            [-84.2482, 45.4548],
            [-84.4302, 45.4591],
            [-84.6095, 45.5276],
            [-84.7029, 45.5834],
            [-84.723, 45.6655],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608910',
        NAME: 'Cheboygan Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 629524957,
        AWATER: 253553180,
        INTPTLAT: '+45.6000995',
        INTPTLON: '-084.4354728',
        ELSDLEA: '',
        UNSDLEA: '08910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7342, 45.2311],
            [-84.7312, 45.3468],
            [-84.4864, 45.3739],
            [-84.4884, 45.2874],
            [-84.3687, 45.285],
            [-84.3666, 45.1987],
            [-84.3668, 45.1988],
            [-84.7344, 45.2023],
            [-84.7342, 45.2311],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636480',
        NAME: 'Wolverine Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 420192802,
        AWATER: 4226749,
        INTPTLAT: '+45.2644278',
        INTPTLON: '-084.5545039',
        ELSDLEA: '',
        UNSDLEA: '36480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.73, 45.3889],
            [-84.7303, 45.4644],
            [-84.61, 45.4622],
            [-84.6095, 45.5276],
            [-84.4302, 45.4591],
            [-84.4293, 45.3733],
            [-84.3687, 45.285],
            [-84.4884, 45.2874],
            [-84.4864, 45.3739],
            [-84.7312, 45.3468],
            [-84.73, 45.3889],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619170',
        NAME: 'Inland Lakes Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 348216867,
        AWATER: 71300848,
        INTPTLAT: '+45.4075232',
        INTPTLON: '-084.5532619',
        ELSDLEA: '',
        UNSDLEA: '19170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7287, 45.8101],
            [-84.7263, 45.8275],
            [-84.6381, 45.8248],
            [-84.5084, 45.7378],
            [-84.4246, 45.7157],
            [-84.3498, 45.775],
            [-84.5808, 45.8253],
            [-84.6425, 45.8963],
            [-84.609, 45.918],
            [-84.6129, 46.0983],
            [-84.4256, 46.0715],
            [-84.2388, 46.0715],
            [-84.2393, 46.0274],
            [-84.1142, 45.9852],
            [-84.1231, 45.7777],
            [-84.2011, 45.7523],
            [-84.3531, 45.6994],
            [-84.4526, 45.6954],
            [-84.6086, 45.7668],
            [-84.6735, 45.8006],
            [-84.7287, 45.8101],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621420',
        NAME: 'Les Cheneaux Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 315703487,
        AWATER: 1006237213,
        INTPTLAT: '+45.9220299',
        INTPTLON: '-084.4302599',
        ELSDLEA: '',
        UNSDLEA: '21420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9255, 46.1133],
            [-84.779, 46.1132],
            [-84.6761, 46.1579],
            [-84.6128, 46.1147],
            [-84.6129, 46.0983],
            [-84.609, 45.918],
            [-84.6425, 45.8963],
            [-84.717, 45.8486],
            [-84.7791, 45.9547],
            [-84.9247, 45.9547],
            [-84.9255, 46.1133],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600012',
        NAME: 'St. Ignace Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418012447,
        AWATER: 132591576,
        INTPTLAT: '+46.0345710',
        INTPTLON: '-084.7509671',
        ELSDLEA: '',
        UNSDLEA: '00012',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6425, 45.8963],
            [-84.5808, 45.8253],
            [-84.6381, 45.8248],
            [-84.7263, 45.8275],
            [-84.717, 45.8486],
            [-84.6425, 45.8963],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622230',
        NAME: 'Mackinac Island Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11275214,
        AWATER: 60044077,
        INTPTLAT: '+45.8784816',
        INTPTLON: '-084.6445415',
        ELSDLEA: '',
        UNSDLEA: '22230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3391, 42.6018],
            [-83.2486, 42.6199],
            [-83.199, 42.6138],
            [-83.2075, 42.5829],
            [-83.3474, 42.5654],
            [-83.3391, 42.6018],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606090',
        NAME: 'Bloomfield Hills School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60616854,
        AWATER: 4605138,
        INTPTLAT: '+42.5798286',
        INTPTLON: '-083.2788848',
        ELSDLEA: '',
        UNSDLEA: '06090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3846, 42.5277],
            [-83.3408, 42.5362],
            [-83.3199, 42.5074],
            [-83.3172, 42.4492],
            [-83.3657, 42.4408],
            [-83.4337, 42.4389],
            [-83.4366, 42.5041],
            [-83.3846, 42.5277],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614070',
        NAME: 'Farmington Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89888216,
        AWATER: 58353,
        INTPTLAT: '+42.4856480',
        INTPTLON: '-083.3759608',
        ELSDLEA: '',
        UNSDLEA: '14070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6706, 42.8716],
            [-83.5813, 42.8731],
            [-83.562, 42.8736],
            [-83.4727, 42.7888],
            [-83.5095, 42.6857],
            [-83.6541, 42.6963],
            [-83.6874, 42.8124],
            [-83.6706, 42.8716],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618450',
        NAME: 'Holly Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 243411413,
        AWATER: 10270610,
        INTPTLAT: '+42.7830707',
        INTPTLON: '-083.5838918',
        ELSDLEA: '',
        UNSDLEA: '18450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3289, 42.7059],
            [-83.2186, 42.7094],
            [-83.2106, 42.6499],
            [-83.2486, 42.6199],
            [-83.3391, 42.6018],
            [-83.3397, 42.6179],
            [-83.3289, 42.7059],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628740',
        NAME: 'Pontiac City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99369160,
        AWATER: 3884859,
        INTPTLAT: '+42.6603696',
        INTPTLON: '-083.2788607',
        ELSDLEA: '',
        UNSDLEA: '28740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.423, 42.6066],
            [-83.3397, 42.6179],
            [-83.3391, 42.6018],
            [-83.3474, 42.5654],
            [-83.3408, 42.5362],
            [-83.3846, 42.5277],
            [-83.423, 42.6066],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635820',
        NAME: 'West Bloomfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33834562,
        AWATER: 9550896,
        INTPTLAT: '+42.5764889',
        INTPTLON: '-083.3778893',
        ELSDLEA: '',
        UNSDLEA: '35820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6168, 46.4201],
            [-88.3658, 46.42],
            [-88.1166, 46.42],
            [-88.1174, 46.2466],
            [-88.1175, 45.9425],
            [-88.1168, 45.9216],
            [-88.3809, 45.9918],
            [-88.4927, 45.9925],
            [-88.4922, 46.2468],
            [-88.6165, 46.2461],
            [-88.6168, 46.4201],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611190',
        NAME: 'Forest Park School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1582261500,
        AWATER: 68981489,
        INTPTLAT: '+46.2063578',
        INTPTLON: '-088.3258257',
        ELSDLEA: '',
        UNSDLEA: '11190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2034, 42.5102],
            [-83.1637, 42.4747],
            [-83.202, 42.4767],
            [-83.2034, 42.5102],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2605010',
        NAME: 'Berkley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12453338,
        AWATER: 0,
        INTPTLAT: '+42.4908390',
        INTPTLON: '-083.1822597',
        ELSDLEA: '',
        UNSDLEA: '05010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1662, 42.5402],
            [-83.1269, 42.5412],
            [-83.1374, 42.5275],
            [-83.1662, 42.5402],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2609930',
        NAME: 'Clawson City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5723497,
        AWATER: 0,
        INTPTLAT: '+42.5366625',
        INTPTLON: '-083.1504875',
        ELSDLEA: '',
        UNSDLEA: '09930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6123, 45.0844],
            [-84.3677, 45.1126],
            [-84.2876, 45.1131],
            [-84.293, 44.9415],
            [-84.1325, 44.9421],
            [-84.1316, 44.8549],
            [-84.2523, 44.8557],
            [-84.2519, 44.7687],
            [-84.3718, 44.7689],
            [-84.3718, 44.855],
            [-84.5271, 44.8574],
            [-84.5325, 45.0218],
            [-84.6123, 45.0844],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619890',
        NAME: 'Johannesburg-Lewiston Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 747907901,
        AWATER: 20138852,
        INTPTLAT: '+44.9505534',
        INTPTLON: '-084.3829300',
        ELSDLEA: '',
        UNSDLEA: '19890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6969, 45.8988],
            [-87.617, 45.986],
            [-87.4925, 45.9855],
            [-87.449, 45.8985],
            [-87.4486, 45.7248],
            [-87.3887, 45.638],
            [-87.6356, 45.6371],
            [-87.6974, 45.6363],
            [-87.6969, 45.8988],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629220',
        NAME: 'North Central Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 701583747,
        AWATER: 2255543,
        INTPTLAT: '+45.8182428',
        INTPTLON: '-087.5530703',
        ELSDLEA: '',
        UNSDLEA: '29220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8034, 45.5383],
            [-87.7775, 45.5938],
            [-87.6356, 45.594],
            [-87.6355, 45.5075],
            [-87.4496, 45.507],
            [-87.3275, 45.5512],
            [-87.2585, 45.5501],
            [-87.1291, 45.5505],
            [-87.1278, 45.4095],
            [-87.1958, 45.3334],
            [-87.4558, 45.338],
            [-87.5294, 45.2461],
            [-87.7078, 45.2586],
            [-87.6478, 45.3406],
            [-87.8849, 45.3628],
            [-87.8129, 45.4643],
            [-87.8034, 45.5383],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633000',
        NAME: 'Stephenson Area Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1013120878,
        AWATER: 376818416,
        INTPTLAT: '+45.4268760',
        INTPTLON: '-087.4843762',
        ELSDLEA: '',
        UNSDLEA: '33000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1812, 44.5],
            [-86.0601, 44.4557],
            [-86.1111, 44.3727],
            [-86.1824, 44.3814],
            [-86.1812, 44.5],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604320',
        NAME: 'Bear Lake School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132932469,
        AWATER: 7847327,
        INTPTLAT: '+44.4456279',
        INTPTLON: '-086.1116860',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.6046, 41.8749],
              [-85.5998, 41.875],
              [-85.5996, 41.8677],
              [-85.6044, 41.8677],
              [-85.6045, 41.8708],
              [-85.6046, 41.8749],
            ],
          ],
          [
            [
              [-85.9261, 41.8102],
              [-85.8383, 41.8971],
              [-85.8185, 41.897],
              [-85.7896, 41.9177],
              [-85.7893, 41.9202],
              [-85.6086, 41.8677],
              [-85.6044, 41.8677],
              [-85.5652, 41.8388],
              [-85.5774, 41.8388],
              [-85.5847, 41.8388],
              [-85.6826, 41.759],
              [-85.774, 41.7591],
              [-85.8191, 41.7591],
              [-85.8776, 41.7593],
              [-85.9261, 41.7597],
              [-85.9261, 41.8102],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610750',
        NAME: 'Constantine Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244984303,
        AWATER: 9075481,
        INTPTLAT: '+41.8338850',
        INTPTLON: '-085.7665851',
        ELSDLEA: '',
        UNSDLEA: '10750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8772, 42.0839],
            [-85.7634, 42.0983],
            [-85.7629, 42.0693],
            [-85.7893, 41.9202],
            [-85.7896, 41.9177],
            [-85.8185, 41.897],
            [-85.8383, 41.8971],
            [-85.8759, 41.9694],
            [-85.9913, 41.991],
            [-85.9916, 42.0167],
            [-85.8772, 42.0839],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622740',
        NAME: 'Marcellus Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 258838850,
        AWATER: 9864628,
        INTPTLAT: '+42.0214275',
        INTPTLON: '-085.8608863',
        ELSDLEA: '',
        UNSDLEA: '22740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2343, 47.0476],
            [-84.8593, 46.8888],
            [-84.763, 46.6343],
            [-84.557, 46.4607],
            [-84.4756, 46.4529],
            [-84.4348, 46.3602],
            [-84.5097, 46.332],
            [-84.9893, 46.332],
            [-85.1135, 46.2747],
            [-85.1144, 46.419],
            [-84.9893, 46.4188],
            [-84.9904, 46.5444],
            [-85.028, 46.6764],
            [-84.9709, 46.7819],
            [-85.2371, 46.7664],
            [-85.2343, 47.0476],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606900',
        NAME: 'Brimley Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 726554331,
        AWATER: 1414395821,
        INTPTLAT: '+46.4663800',
        INTPTLON: '-084.8710885',
        ELSDLEA: '',
        UNSDLEA: '06900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3842, 46.274],
            [-84.3439, 46.2445],
            [-84.1081, 46.2412],
            [-84.0713, 46.1845],
            [-84.1432, 46.0275],
            [-84.2393, 46.0274],
            [-84.2388, 46.0715],
            [-84.4256, 46.0715],
            [-84.6129, 46.0983],
            [-84.6128, 46.1147],
            [-84.4883, 46.1583],
            [-84.4884, 46.2449],
            [-84.3842, 46.274],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628020',
        NAME: 'Pickford Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 546586432,
        AWATER: 83709848,
        INTPTLAT: '+46.1434260',
        INTPTLON: '-084.3112457',
        ELSDLEA: '',
        UNSDLEA: '28020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4756, 46.4529],
            [-84.4203, 46.5011],
            [-84.1112, 46.5041],
            [-84.1462, 46.4185],
            [-84.1081, 46.2412],
            [-84.3439, 46.2445],
            [-84.3842, 46.274],
            [-84.4348, 46.3602],
            [-84.4756, 46.4529],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630990',
        NAME: 'Sault Ste. Marie Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 561729461,
        AWATER: 139658668,
        INTPTLAT: '+46.3835767',
        INTPTLON: '-084.2747496',
        ELSDLEA: '',
        UNSDLEA: '30990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2371, 46.7664],
            [-84.9709, 46.7819],
            [-85.028, 46.6764],
            [-84.9904, 46.5444],
            [-84.9893, 46.4188],
            [-85.1144, 46.419],
            [-85.2391, 46.4195],
            [-85.2371, 46.7664],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636240',
        NAME: 'Whitefish Township Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 625472091,
        AWATER: 93150258,
        INTPTLAT: '+46.6062175',
        INTPTLON: '-085.1141883',
        ELSDLEA: '',
        UNSDLEA: '36240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.971, 42.5701],
            [-82.9208, 42.5688],
            [-82.9251, 42.5394],
            [-82.9687, 42.5162],
            [-82.969, 42.5236],
            [-82.9706, 42.5606],
            [-82.971, 42.5701],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614820',
        NAME: 'Fraser Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20999205,
        AWATER: 94094,
        INTPTLAT: '+42.5489887',
        INTPTLON: '-082.9476608',
        ELSDLEA: '',
        UNSDLEA: '14820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8282, 42.8703],
            [-82.6743, 42.8781],
            [-82.6817, 42.7559],
            [-82.7316, 42.7584],
            [-82.8615, 42.7661],
            [-82.8282, 42.8703],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629670',
        NAME: 'Richmond Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183895239,
        AWATER: 1064820,
        INTPTLAT: '+42.8196751',
        INTPTLON: '-082.7371803',
        ELSDLEA: '',
        UNSDLEA: '29670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0899, 43.2477],
            [-83.0007, 43.2992],
            [-82.9043, 43.3005],
            [-82.8314, 43.1677],
            [-82.9681, 43.1197],
            [-82.9939, 43.1191],
            [-83.0439, 43.1356],
            [-83.0849, 43.1525],
            [-83.0899, 43.2477],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607040',
        NAME: 'Brown City Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292112868,
        AWATER: 1630798,
        INTPTLAT: '+43.2105395',
        INTPTLON: '-082.9765932',
        ELSDLEA: '',
        UNSDLEA: '07040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7324, 43.4537],
            [-82.5557, 43.4737],
            [-82.5338, 43.3802],
            [-82.6674, 43.3615],
            [-82.7458, 43.343],
            [-82.7324, 43.4537],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608160',
        NAME: 'Carsonville-Port Sanilac School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 207776341,
        AWATER: 629776,
        INTPTLAT: '+43.4066671',
        INTPTLON: '-082.6385218',
        ELSDLEA: '',
        UNSDLEA: '08160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9948, 43.5478],
            [-82.8809, 43.5519],
            [-82.7324, 43.4537],
            [-82.7458, 43.343],
            [-82.7625, 43.3215],
            [-82.8514, 43.3232],
            [-82.8949, 43.3217],
            [-82.9876, 43.4026],
            [-82.9929, 43.5041],
            [-82.9948, 43.5478],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630840',
        NAME: 'Sandusky Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 396303229,
        AWATER: 208555,
        INTPTLAT: '+43.4290095',
        INTPTLON: '-082.8663331',
        ELSDLEA: '',
        UNSDLEA: '30840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7629, 42.0693],
            [-85.6023, 42.0702],
            [-85.5776, 42.0349],
            [-85.5619, 41.9694],
            [-85.6046, 41.8749],
            [-85.6045, 41.8708],
            [-85.6086, 41.8677],
            [-85.7893, 41.9202],
            [-85.7629, 42.0693],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633840',
        NAME: 'Three Rivers Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 294709147,
        AWATER: 14576472,
        INTPTLAT: '+41.9795526',
        INTPTLON: '-085.6759423',
        ELSDLEA: '',
        UNSDLEA: '33840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5847, 41.8388],
            [-85.5774, 41.8388],
            [-85.5652, 41.8388],
            [-85.5263, 41.8189],
            [-85.5258, 41.7594],
            [-85.6598, 41.7591],
            [-85.6826, 41.759],
            [-85.5847, 41.8388],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636120',
        NAME: 'White Pigeon Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141693723,
        AWATER: 10579755,
        INTPTLAT: '+41.7932622',
        INTPTLON: '-085.6717518',
        ELSDLEA: '',
        UNSDLEA: '36120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7428, 44.1471],
            [-85.4668, 44.1837],
            [-85.4055, 44.1458],
            [-85.4097, 44.1168],
            [-85.3775, 44.151],
            [-85.3557, 44.1591],
            [-85.3348, 44.1651],
            [-85.334, 44.1648],
            [-85.341, 44.1314],
            [-85.3453, 44.124],
            [-85.3256, 44.0734],
            [-85.3638, 43.9584],
            [-85.6648, 43.959],
            [-85.6819, 43.988],
            [-85.8014, 43.9877],
            [-85.8029, 44.0888],
            [-85.7428, 44.1471],
          ],
          [
            [-85.4097, 44.1168],
            [-85.4135, 44.1168],
            [-85.4015, 44.1123],
            [-85.4097, 44.1168],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628200',
        NAME: 'Pine River Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 777757806,
        AWATER: 6908181,
        INTPTLAT: '+44.0550212',
        INTPTLON: '-085.5509024',
        ELSDLEA: '',
        UNSDLEA: '28200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2795, 44.1756],
            [-86.143, 44.1702],
            [-86.0419, 44.1484],
            [-86.0405, 44.0912],
            [-86.0387, 43.9025],
            [-86.1379, 43.9603],
            [-86.1583, 43.8161],
            [-86.1788, 43.8164],
            [-86.2584, 43.8175],
            [-86.2342, 43.933],
            [-86.2795, 44.1756],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623160',
        NAME: 'Mason County Eastern District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 546561364,
        AWATER: 7577598,
        INTPTLAT: '+44.0275856',
        INTPTLON: '-086.1710642',
        ELSDLEA: '',
        UNSDLEA: '23160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1589, 42.8066],
            [-83.102, 42.8656],
            [-83.1021, 42.8694],
            [-83.1021, 42.87],
            [-82.9925, 42.8771],
            [-82.926, 42.7541],
            [-82.9455, 42.7211],
            [-83.0704, 42.7201],
            [-83.1536, 42.7953],
            [-83.1589, 42.8066],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630090',
        NAME: 'Romeo Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218907686,
        AWATER: 2800708,
        INTPTLAT: '+42.7906014',
        INTPTLON: '-083.0291191',
        ELSDLEA: '',
        UNSDLEA: '30090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9166, 42.9241],
            [-82.8464, 42.9249],
            [-82.8282, 42.8703],
            [-82.8615, 42.7661],
            [-82.926, 42.7541],
            [-82.9925, 42.8771],
            [-82.9166, 42.9241],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603240',
        NAME: 'Armada Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191714080,
        AWATER: 248154,
        INTPTLAT: '+42.8483674',
        INTPTLON: '-082.8912962',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8918, 42.6583],
            [-82.7873, 42.6973],
            [-82.8082, 42.6462],
            [-82.7818, 42.5719],
            [-82.8609, 42.5403],
            [-82.8847, 42.5402],
            [-82.8857, 42.5661],
            [-82.9036, 42.6193],
            [-82.8918, 42.6583],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621870',
        NAME: "L'Anse Creuse Public Schools",
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89444694,
        AWATER: 3396965,
        INTPTLAT: '+42.6201766',
        INTPTLON: '-082.8387914',
        ELSDLEA: '',
        UNSDLEA: '21870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7873, 42.6973],
            [-82.7316, 42.7584],
            [-82.6817, 42.7559],
            [-82.6748, 42.7078],
            [-82.6696, 42.7007],
            [-82.7083, 42.6842],
            [-82.8082, 42.6462],
            [-82.7873, 42.6973],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602790',
        NAME: 'Anchor Bay School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73844829,
        AWATER: 373400,
        INTPTLAT: '+42.7027712',
        INTPTLON: '-082.7356101',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5002, 47.0675],
            [-88.4035, 47.1164],
            [-88.4465, 47.1995],
            [-88.2991, 47.3153],
            [-88.1096, 47.3301],
            [-88.106, 47.2494],
            [-88.0461, 47.1997],
            [-88.0437, 47.044],
            [-88.2997, 46.9788],
            [-88.4512, 46.939],
            [-88.4954, 47.0545],
            [-88.5002, 47.0675],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620700',
        NAME: 'Lake Linden-Hubbell School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452422277,
        AWATER: 487110385,
        INTPTLAT: '+47.0725998',
        INTPTLON: '-088.2717389',
        ELSDLEA: '',
        UNSDLEA: '20700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9687, 42.5162],
            [-82.9251, 42.5394],
            [-82.9043, 42.5398],
            [-82.9181, 42.5026],
            [-82.9175, 42.4804],
            [-82.9672, 42.4802],
            [-82.9687, 42.5162],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630210',
        NAME: 'Roseville Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23611582,
        AWATER: 74007,
        INTPTLAT: '+42.5067504',
        INTPTLON: '-082.9363471',
        ELSDLEA: '',
        UNSDLEA: '30210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.917, 42.473],
            [-82.8739, 42.467],
            [-82.8699, 42.4512],
            [-82.9354, 42.4502],
            [-82.941, 42.4502],
            [-82.917, 42.473],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632220',
        NAME: 'South Lake Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10931216,
        AWATER: 503839,
        INTPTLAT: '+42.4600234',
        INTPTLON: '-082.9028495',
        ELSDLEA: '',
        UNSDLEA: '32220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9043, 42.5398],
            [-82.8847, 42.5402],
            [-82.8609, 42.5403],
            [-82.882, 42.5002],
            [-82.9181, 42.5026],
            [-82.9043, 42.5398],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632670',
        NAME: 'Lake Shore Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11405066,
        AWATER: 57975,
        INTPTLAT: '+42.5220505',
        INTPTLON: '-082.8938183',
        ELSDLEA: '',
        UNSDLEA: '32670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8459, 46.4199],
            [-88.6168, 46.4201],
            [-88.6165, 46.2461],
            [-88.4922, 46.2468],
            [-88.4927, 45.9925],
            [-88.6833, 46.0141],
            [-88.7821, 46.0162],
            [-88.9328, 46.0737],
            [-88.9909, 46.0974],
            [-88.9906, 46.3333],
            [-88.9912, 46.4197],
            [-88.8459, 46.4199],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632910',
        NAME: 'West Iron County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1438016959,
        AWATER: 47757569,
        INTPTLAT: '+46.2196502',
        INTPTLON: '-088.7490293',
        ELSDLEA: '',
        UNSDLEA: '32910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6969, 45.8988],
            [-87.6974, 45.6363],
            [-87.6356, 45.6371],
            [-87.6356, 45.594],
            [-87.7775, 45.5938],
            [-87.8028, 45.6667],
            [-87.8123, 45.7113],
            [-87.9422, 45.7577],
            [-87.9441, 45.8985],
            [-87.9917, 45.9426],
            [-87.7423, 45.9425],
            [-87.6969, 45.8988],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626040',
        NAME: 'Norway-Vulcan Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 620072529,
        AWATER: 11860676,
        INTPTLAT: '+45.8024586',
        INTPTLON: '-087.8131984',
        ELSDLEA: '',
        UNSDLEA: '26040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7823, 46.1588],
            [-86.7406, 46.1587],
            [-86.6156, 46.1587],
            [-86.6153, 46.2458],
            [-86.4281, 46.245],
            [-86.4271, 45.9838],
            [-86.4591, 45.8964],
            [-86.4608, 45.5844],
            [-86.4641, 45.3248],
            [-86.7541, 45.4436],
            [-86.872, 45.4439],
            [-86.8478, 45.5713],
            [-86.7029, 45.8244],
            [-86.7846, 45.8974],
            [-86.7823, 46.1588],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2605690',
        NAME: 'Big Bay de Noc School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1282990320,
        AWATER: 1201737987,
        INTPTLAT: '+45.7191095',
        INTPTLON: '-086.6701479',
        ELSDLEA: '',
        UNSDLEA: '05690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4229, 46.1592],
            [-87.3659, 46.2462],
            [-87.1162, 46.2459],
            [-87.1161, 46.1589],
            [-86.9908, 46.1589],
            [-86.9934, 45.9415],
            [-87.1376, 45.8982],
            [-87.3052, 46.0239],
            [-87.4229, 46.1592],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623830',
        NAME: 'Mid Peninsula School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 839002188,
        AWATER: 3170169,
        INTPTLAT: '+46.0899093',
        INTPTLON: '-087.1876611',
        ELSDLEA: '',
        UNSDLEA: '23830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3134, 41.7814],
            [-86.1859, 41.8116],
            [-86.1718, 41.7605],
            [-86.1787, 41.7605],
            [-86.313, 41.7603],
            [-86.3134, 41.7814],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606600',
        NAME: 'Brandywine Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47822139,
        AWATER: 777194,
        INTPTLAT: '+41.7816383',
        INTPTLON: '-086.2401843',
        ELSDLEA: '',
        UNSDLEA: '06600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.389, 42.0215],
            [-86.3774, 42.0218],
            [-86.3006, 42.072],
            [-86.2229, 42.0715],
            [-86.223, 41.9567],
            [-86.3351, 41.9658],
            [-86.389, 42.0215],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612810',
        NAME: 'Eau Claire Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126862284,
        AWATER: 2695441,
        INTPTLAT: '+42.0152442',
        INTPTLON: '-086.2917599',
        ELSDLEA: '',
        UNSDLEA: '12810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1859, 41.8116],
            [-86.1077, 41.8545],
            [-86.0689, 41.8467],
            [-85.9261, 41.8102],
            [-85.9261, 41.7597],
            [-86.0626, 41.7605],
            [-86.1718, 41.7605],
            [-86.1859, 41.8116],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612990',
        NAME: 'Edwardsburg Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158698397,
        AWATER: 6891545,
        INTPTLAT: '+41.7863685',
        INTPTLON: '-086.0574795',
        ELSDLEA: '',
        UNSDLEA: '12990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8074, 42.3342],
            [-84.7236, 42.3306],
            [-84.6812, 42.2621],
            [-84.7133, 42.1882],
            [-84.8475, 42.2248],
            [-84.8476, 42.24],
            [-84.8074, 42.3342],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602070',
        NAME: 'Albion Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166684230,
        AWATER: 2215217,
        INTPTLAT: '+42.2607867',
        INTPTLON: '-084.7681738',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.9944, 44.3934],
              [-84.8558, 44.422],
              [-84.6141, 44.4213],
              [-84.6124, 44.3344],
              [-84.3696, 44.3341],
              [-84.368, 44.1605],
              [-84.6081, 44.1605],
              [-84.9522, 44.1621],
              [-84.9935, 44.2927],
              [-84.9944, 44.3934],
            ],
          ],
          [
            [
              [-85.0447, 44.3225],
              [-85.0498, 44.3225],
              [-85.0547, 44.3226],
              [-85.0549, 44.3261],
              [-85.0447, 44.3225],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618600',
        NAME: 'Houghton Lake Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1116097380,
        AWATER: 100783964,
        INTPTLAT: '+44.2835095',
        INTPTLON: '-084.7013106',
        ELSDLEA: '',
        UNSDLEA: '18600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7938, 45.7497],
            [-84.6735, 45.8006],
            [-84.6086, 45.7668],
            [-84.6704, 45.6793],
            [-84.7938, 45.7497],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622260',
        NAME: 'Mackinaw City Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89569539,
        AWATER: 45870071,
        INTPTLAT: '+45.7436506',
        INTPTLON: '-084.6966042',
        ELSDLEA: '',
        UNSDLEA: '22260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1008, 45.3666],
            [-85.096, 45.3664],
            [-84.9924, 45.3681],
            [-84.9192, 45.4201],
            [-84.8787, 45.428],
            [-84.73, 45.3889],
            [-84.7312, 45.3468],
            [-84.7342, 45.2311],
            [-84.7964, 45.2026],
            [-84.9161, 45.2043],
            [-85.1015, 45.3549],
            [-85.1008, 45.3666],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627930',
        NAME: 'Petoskey Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 427354760,
        AWATER: 19799320,
        INTPTLAT: '+45.3125022',
        INTPTLON: '-084.8634944',
        ELSDLEA: '',
        UNSDLEA: '27930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9893, 46.5058],
            [-89.8643, 46.448],
            [-89.8636, 46.3327],
            [-89.7405, 46.333],
            [-89.7407, 46.2636],
            [-89.9292, 46.2997],
            [-89.9905, 46.3116],
            [-89.9899, 46.4195],
            [-90.1145, 46.4198],
            [-90.1141, 46.506],
            [-89.9893, 46.5058],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600006',
        NAME: 'Bessemer Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308868546,
        AWATER: 4042684,
        INTPTLAT: '+46.3914205',
        INTPTLON: '-089.9231208',
        ELSDLEA: '',
        UNSDLEA: '00006',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9893, 46.5058],
            [-89.989, 46.7],
            [-89.8877, 46.7658],
            [-89.8645, 46.5928],
            [-89.7403, 46.5921],
            [-89.7415, 46.5052],
            [-89.3653, 46.5056],
            [-89.3654, 46.333],
            [-89.4904, 46.3331],
            [-89.4906, 46.2154],
            [-89.5547, 46.2279],
            [-89.7407, 46.2636],
            [-89.7405, 46.333],
            [-89.8636, 46.3327],
            [-89.8643, 46.448],
            [-89.9893, 46.5058],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635010',
        NAME: 'Wakefield-Marenisco School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1292078067,
        AWATER: 42372179,
        INTPTLAT: '+46.4505755',
        INTPTLON: '-089.7143807',
        ELSDLEA: '',
        UNSDLEA: '35010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2696, 46.7982],
            [-90.1117, 47.042],
            [-90.0002, 46.9999],
            [-90.0002, 46.7655],
            [-89.8877, 46.7658],
            [-89.989, 46.7],
            [-89.9893, 46.5058],
            [-90.1141, 46.506],
            [-90.1145, 46.4198],
            [-89.9899, 46.4195],
            [-89.9905, 46.3116],
            [-90.1188, 46.3595],
            [-90.2162, 46.5013],
            [-90.4184, 46.5661],
            [-90.2696, 46.7982],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619470',
        NAME: 'Ironwood Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 592982079,
        AWATER: 863933697,
        INTPTLAT: '+46.6468337',
        INTPTLON: '-090.1148413',
        ELSDLEA: '',
        UNSDLEA: '19470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.178, 43.6424],
            [-86.1577, 43.6712],
            [-85.9202, 43.6846],
            [-85.9205, 43.5971],
            [-86.0995, 43.497],
            [-86.1592, 43.4977],
            [-86.178, 43.6424],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618270',
        NAME: 'Hesperia Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 308004280,
        AWATER: 29904071,
        INTPTLAT: '+43.6136772',
        INTPTLON: '-086.0544746',
        ELSDLEA: '',
        UNSDLEA: '18270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9202, 43.6846],
            [-85.9001, 43.6977],
            [-85.8003, 43.7275],
            [-85.6812, 43.6914],
            [-85.5622, 43.6421],
            [-85.5628, 43.512],
            [-85.6213, 43.4899],
            [-85.8011, 43.4964],
            [-85.8861, 43.5763],
            [-85.9205, 43.5971],
            [-85.9202, 43.6846],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636060',
        NAME: 'White Cloud Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 519196166,
        AWATER: 38147419,
        INTPTLAT: '+43.6054212',
        INTPTLON: '-085.7351339',
        ELSDLEA: '',
        UNSDLEA: '36060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8405, 43.449],
            [-85.8204, 43.4602],
            [-85.8011, 43.4964],
            [-85.6213, 43.4899],
            [-85.5624, 43.4682],
            [-85.6421, 43.3995],
            [-85.6825, 43.3809],
            [-85.7698, 43.3778],
            [-85.8914, 43.3801],
            [-85.9002, 43.3914],
            [-85.8405, 43.449],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625320',
        NAME: 'Newaygo Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216928297,
        AWATER: 16191536,
        INTPTLAT: '+43.4230285',
        INTPTLON: '-085.7187835',
        ELSDLEA: '',
        UNSDLEA: '25320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9959, 43.5694],
            [-82.7178, 43.6438],
            [-82.603, 43.6395],
            [-82.5557, 43.4737],
            [-82.7324, 43.4537],
            [-82.8809, 43.5519],
            [-82.9948, 43.5478],
            [-82.9959, 43.5694],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611700',
        NAME: 'Deckerville Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 454599143,
        AWATER: 451626,
        INTPTLAT: '+43.5625969',
        INTPTLON: '-082.7458636',
        ELSDLEA: '',
        UNSDLEA: '11700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7083, 42.6842],
            [-82.6696, 42.7007],
            [-82.5104, 42.6532],
            [-82.5248, 42.606],
            [-82.6431, 42.5543],
            [-82.7232, 42.4778],
            [-82.7083, 42.6842],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602190',
        NAME: 'Algonac Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110653105,
        AWATER: 134012353,
        INTPTLAT: '+42.6119166',
        INTPTLON: '-082.6412238',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6817, 42.7559],
            [-82.6743, 42.8781],
            [-82.6397, 42.8945],
            [-82.4698, 42.8775],
            [-82.4677, 42.7619],
            [-82.5104, 42.6532],
            [-82.6696, 42.7007],
            [-82.6748, 42.7078],
            [-82.6817, 42.7559],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612420',
        NAME: 'East China School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 323208433,
        AWATER: 13054939,
        INTPTLAT: '+42.7800288',
        INTPTLON: '-082.5687813',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6129, 43.1358],
            [-82.4938, 43.1409],
            [-82.4178, 43.004],
            [-82.4477, 42.9372],
            [-82.6217, 42.9393],
            [-82.664, 42.9603],
            [-82.6129, 43.1358],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628830',
        NAME: 'Port Huron Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 304590049,
        AWATER: 4993824,
        INTPTLAT: '+43.0175010',
        INTPTLON: '-082.5322872',
        ELSDLEA: '',
        UNSDLEA: '28830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8314, 43.1677],
            [-82.8208, 43.1651],
            [-82.7743, 43.2035],
            [-82.6816, 43.1484],
            [-82.6129, 43.1358],
            [-82.664, 42.9603],
            [-82.7806, 42.9857],
            [-82.8819, 43.0885],
            [-82.9681, 43.1197],
            [-82.8314, 43.1677],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636600',
        NAME: 'Yale Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403208618,
        AWATER: 2040686,
        INTPTLAT: '+43.0808357',
        INTPTLON: '-082.7461941',
        ELSDLEA: '',
        UNSDLEA: '36600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7938, 45.7497],
            [-84.6704, 45.6793],
            [-84.723, 45.6655],
            [-84.7029, 45.5834],
            [-84.6095, 45.5276],
            [-84.61, 45.4622],
            [-84.7303, 45.4644],
            [-84.8938, 45.4927],
            [-84.9754, 45.5365],
            [-85.0268, 45.6228],
            [-84.9758, 45.6833],
            [-84.8787, 45.7562],
            [-84.7938, 45.7497],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627720',
        NAME: 'Pellston Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 583773213,
        AWATER: 61255735,
        INTPTLAT: '+45.6088557',
        INTPTLON: '-084.8319344',
        ELSDLEA: '',
        UNSDLEA: '27720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2375, 42.2242],
            [-85.2281, 42.2465],
            [-85.179, 42.2985],
            [-85.1411, 42.3336],
            [-85.0627, 42.3336],
            [-85.0603, 42.1818],
            [-85.1677, 42.1595],
            [-85.2375, 42.2242],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617730',
        NAME: 'Harper Creek Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192054512,
        AWATER: 3223268,
        INTPTLAT: '+42.2490453',
        INTPTLON: '-085.1279426',
        ELSDLEA: '',
        UNSDLEA: '17730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0913, 45.0317],
            [-84.9793, 45.0312],
            [-84.8495, 45.0296],
            [-84.8491, 44.945],
            [-85.0605, 44.9782],
            [-85.0915, 44.9455],
            [-85.0913, 45.0317],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602040',
        NAME: 'Alba Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170885899,
        AWATER: 846450,
        INTPTLAT: '+45.0056689',
        INTPTLON: '-084.9548233',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1015, 45.3549],
            [-84.9161, 45.2043],
            [-84.9181, 45.1172],
            [-84.9496, 45.0826],
            [-84.9792, 45.0725],
            [-85.1743, 45.2863],
            [-85.1015, 45.3549],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606500',
        NAME: 'Boyne City Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 220504102,
        AWATER: 35272969,
        INTPTLAT: '+45.2256945',
        INTPTLON: '-085.0270748',
        ELSDLEA: '',
        UNSDLEA: '06500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1316, 44.8549],
            [-84.01, 44.8557],
            [-83.8885, 44.8565],
            [-83.7669, 44.8567],
            [-83.7677, 44.7701],
            [-83.6459, 44.7703],
            [-83.6457, 44.6829],
            [-83.7663, 44.6828],
            [-83.7668, 44.5958],
            [-83.887, 44.5954],
            [-83.8882, 44.6821],
            [-84.1302, 44.6812],
            [-84.1311, 44.7685],
            [-84.2519, 44.7687],
            [-84.2523, 44.8557],
            [-84.1316, 44.8549],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610560',
        NAME: 'Fairview Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 826087792,
        AWATER: 8840444,
        INTPTLAT: '+44.7427157',
        INTPTLON: '-083.9505087',
        ELSDLEA: '',
        UNSDLEA: '10560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1538, 42.6507],
            [-85.025, 42.7128],
            [-84.9349, 42.6699],
            [-84.9422, 42.659],
            [-84.9053, 42.648],
            [-84.8956, 42.6552],
            [-84.9795, 42.5637],
            [-85.1426, 42.516],
            [-85.1536, 42.5598],
            [-85.1538, 42.6507],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622620',
        NAME: 'Maple Valley Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334292176,
        AWATER: 2743530,
        INTPTLAT: '+42.6116448',
        INTPTLON: '-085.0559167',
        ELSDLEA: '',
        UNSDLEA: '22620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6459, 41.9325],
            [-83.5265, 41.9515],
            [-83.5027, 41.8606],
            [-83.5311, 41.8163],
            [-83.65, 41.8219],
            [-83.6459, 41.9325],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619050',
        NAME: 'Ida Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166699327,
        AWATER: 587737,
        INTPTLAT: '+41.8828834',
        INTPTLON: '-083.5622357',
        ELSDLEA: '',
        UNSDLEA: '19050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5311, 41.8163],
            [-83.5027, 41.8606],
            [-83.4129, 41.8256],
            [-83.4216, 41.7337],
            [-83.4554, 41.7328],
            [-83.4673, 41.7324],
            [-83.5345, 41.7307],
            [-83.5311, 41.8163],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623100',
        NAME: 'Mason Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83314661,
        AWATER: 15675741,
        INTPTLAT: '+41.7912330',
        INTPTLON: '-083.4860026',
        ELSDLEA: '',
        UNSDLEA: '23100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9311, 44.3111],
            [-86.2886, 44.3138],
            [-86.1832, 44.2737],
            [-86.143, 44.1702],
            [-86.2795, 44.1756],
            [-86.3303, 44.1529],
            [-86.9993, 44.1567],
            [-86.9311, 44.3111],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622410',
        NAME: 'Manistee Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190827006,
        AWATER: 867957776,
        INTPTLAT: '+44.1560346',
        INTPTLON: '-086.3999352',
        ELSDLEA: '',
        UNSDLEA: '22410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3786, 43.8263],
            [-86.431, 43.8194],
            [-86.4663, 43.9768],
            [-86.5149, 44.0483],
            [-86.4001, 44.0816],
            [-86.3786, 43.8263],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622200',
        NAME: 'Ludington Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178173585,
        AWATER: 27063709,
        INTPTLAT: '+43.9669775',
        INTPTLON: '-086.4242046',
        ELSDLEA: '',
        UNSDLEA: '22200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1788, 43.8164],
            [-86.1583, 43.8161],
            [-85.9981, 43.8151],
            [-85.9188, 43.8146],
            [-85.9001, 43.6977],
            [-85.9202, 43.6846],
            [-86.1577, 43.6712],
            [-86.1788, 43.8164],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635130',
        NAME: 'Walkerville Rural Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271766925,
        AWATER: 22910132,
        INTPTLAT: '+43.7539793',
        INTPTLON: '-086.0505413',
        ELSDLEA: '',
        UNSDLEA: '35130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.0931, 43.8201],
            [-86.431, 43.8194],
            [-86.3786, 43.8263],
            [-86.2784, 43.7738],
            [-86.3073, 43.7531],
            [-86.3988, 43.7329],
            [-87.1141, 43.7341],
            [-87.0931, 43.8201],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627840',
        NAME: 'Pentwater Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89777602,
        AWATER: 518128869,
        INTPTLAT: '+43.7331522',
        INTPTLON: '-086.4733531',
        ELSDLEA: '',
        UNSDLEA: '27840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9939, 43.1191],
            [-82.9681, 43.1197],
            [-82.8819, 43.0885],
            [-82.7806, 42.9857],
            [-82.8464, 42.9249],
            [-82.9166, 42.9241],
            [-82.9914, 42.9822],
            [-82.9939, 43.1191],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607800',
        NAME: 'Capac Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274932097,
        AWATER: 1615435,
        INTPTLAT: '+43.0198743',
        INTPTLON: '-082.9005019',
        ELSDLEA: '',
        UNSDLEA: '07800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1958, 42.9735],
            [-83.1067, 42.976],
            [-83.1521, 42.902],
            [-83.2629, 42.9094],
            [-83.1958, 42.9735],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612240',
        NAME: 'Dryden Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88718221,
        AWATER: 2318926,
        INTPTLAT: '+42.9375613',
        INTPTLON: '-083.1740106',
        ELSDLEA: '',
        UNSDLEA: '12240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3517, 43.2352],
            [-83.223, 43.3248],
            [-83.1994, 43.3253],
            [-83.0899, 43.2477],
            [-83.0849, 43.1525],
            [-83.1901, 43.0912],
            [-83.3024, 43.1756],
            [-83.3517, 43.2352],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625680',
        NAME: 'North Branch Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322017873,
        AWATER: 4356851,
        INTPTLAT: '+43.2218537',
        INTPTLON: '-083.2180931',
        ELSDLEA: '',
        UNSDLEA: '25680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0713, 41.8632],
            [-84.0142, 41.8862],
            [-84.0031, 41.8412],
            [-84.0713, 41.8632],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622320',
        NAME: 'Madison School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32836389,
        AWATER: 45761,
        INTPTLAT: '+41.8661433',
        INTPTLON: '-084.0259536',
        ELSDLEA: '',
        UNSDLEA: '22320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7621, 41.8211],
            [-83.7476, 41.8358],
            [-83.669, 41.8221],
            [-83.6457, 41.7275],
            [-83.6459, 41.7275],
            [-83.7814, 41.723],
            [-83.8061, 41.7224],
            [-83.7621, 41.8211],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636270',
        NAME: 'Whiteford Agricultural School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110842064,
        AWATER: 1169834,
        INTPTLAT: '+41.7718088',
        INTPTLON: '-083.7203858',
        ELSDLEA: '',
        UNSDLEA: '36270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.3934, 43.8284],
              [-83.3566, 43.7562],
              [-83.3563, 43.7455],
              [-83.3561, 43.7381],
              [-83.3528, 43.637],
              [-83.4108, 43.5892],
              [-83.5553, 43.6238],
              [-83.6635, 43.5943],
              [-83.5398, 43.7086],
              [-83.4697, 43.7236],
              [-83.3934, 43.8284],
            ],
          ],
          [
            [
              [-83.8941, 43.9033],
              [-83.7085, 43.8339],
              [-83.6999, 43.6118],
              [-83.8484, 43.6586],
              [-83.875, 43.6963],
              [-83.875, 43.7239],
              [-83.9145, 43.7698],
              [-83.8941, 43.9033],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634440',
        NAME: 'Unionville-Sebewaing Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 305710117,
        AWATER: 392139920,
        INTPTLAT: '+43.6641929',
        INTPTLON: '-083.4962877',
        ELSDLEA: '',
        UNSDLEA: '34440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1994, 43.3253],
            [-83.1058, 43.389],
            [-83.1176, 43.4426],
            [-83.1095, 43.5011],
            [-82.9929, 43.5041],
            [-82.9876, 43.4026],
            [-82.8949, 43.3217],
            [-82.8946, 43.3043],
            [-82.9002, 43.3005],
            [-82.9043, 43.3005],
            [-83.0007, 43.2992],
            [-83.0899, 43.2477],
            [-83.1994, 43.3253],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622860',
        NAME: 'Marlette Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 375769048,
        AWATER: 2948848,
        INTPTLAT: '+43.3652873',
        INTPTLON: '-083.0528641',
        ELSDLEA: '',
        UNSDLEA: '22860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.3081, 43.4883],
              [-83.3027, 43.4832],
              [-83.308, 43.4867],
              [-83.3081, 43.4883],
            ],
          ],
          [
            [
              [-83.3081, 43.4883],
              [-83.308, 43.4867],
              [-83.3078, 43.4831],
              [-83.3132, 43.483],
              [-83.3132, 43.4902],
              [-83.3081, 43.4883],
            ],
          ],
          [
            [
              [-83.2872, 43.4544],
              [-83.288, 43.4792],
              [-83.2688, 43.4908],
              [-83.1176, 43.4426],
              [-83.1058, 43.389],
              [-83.1994, 43.3253],
              [-83.223, 43.3248],
              [-83.3205, 43.4104],
              [-83.2872, 43.4544],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620400',
        NAME: 'Kingston Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196811119,
        AWATER: 4541175,
        INTPTLAT: '+43.4196174',
        INTPTLON: '-083.2160546',
        ELSDLEA: '',
        UNSDLEA: '20400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5906, 42.9627],
            [-85.5896, 42.9411],
            [-85.6297, 42.941],
            [-85.6298, 42.9579],
            [-85.5906, 42.9627],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612480',
        NAME: 'East Grand Rapids Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8402865,
        AWATER: 1212235,
        INTPTLAT: '+42.9465811',
        INTPTLON: '-085.6067625',
        ELSDLEA: '',
        UNSDLEA: '12480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.666, 42.913],
            [-85.6349, 42.8921],
            [-85.6457, 42.885],
            [-85.6945, 42.8916],
            [-85.666, 42.913],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616110',
        NAME: 'Godwin Heights Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9639164,
        AWATER: 0,
        INTPTLAT: '+42.8988684',
        INTPTLON: '-085.6653431',
        ELSDLEA: '',
        UNSDLEA: '16110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6945, 42.8916],
            [-85.6457, 42.885],
            [-85.674, 42.8624],
            [-85.6945, 42.8916],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620160',
        NAME: 'Kelloggsville Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10489651,
        AWATER: 9422,
        INTPTLAT: '+42.8784370',
        INTPTLON: '-085.6721261',
        ELSDLEA: '',
        UNSDLEA: '20160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4323, 43.3089],
            [-85.4429, 43.4322],
            [-85.3279, 43.4639],
            [-85.3238, 43.5543],
            [-85.2046, 43.5542],
            [-85.1397, 43.4736],
            [-85.1439, 43.4443],
            [-85.1239, 43.3615],
            [-85.1918, 43.2783],
            [-85.3522, 43.2694],
            [-85.4436, 43.2943],
            [-85.4323, 43.3089],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620910',
        NAME: 'Lakeview Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 483370949,
        AWATER: 11555321,
        INTPTLAT: '+43.4023336',
        INTPTLON: '-085.2731004',
        ELSDLEA: '',
        UNSDLEA: '20910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6421, 43.3995],
            [-85.5624, 43.4682],
            [-85.4429, 43.4322],
            [-85.4323, 43.3089],
            [-85.4436, 43.2943],
            [-85.5463, 43.278],
            [-85.6411, 43.294],
            [-85.6825, 43.3809],
            [-85.6421, 43.3995],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633930',
        NAME: 'Tri County Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 318760492,
        AWATER: 10371890,
        INTPTLAT: '+43.3718605',
        INTPTLON: '-085.5234431',
        ELSDLEA: '',
        UNSDLEA: '33930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7201, 42.9388],
            [-85.7071, 42.942],
            [-85.7137, 42.9279],
            [-85.686, 42.9225],
            [-85.666, 42.913],
            [-85.6945, 42.8916],
            [-85.674, 42.8624],
            [-85.674, 42.8551],
            [-85.7131, 42.8553],
            [-85.7201, 42.9388],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636570',
        NAME: 'Wyoming Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28640887,
        AWATER: 320215,
        INTPTLAT: '+42.8994008',
        INTPTLON: '-085.7072379',
        ELSDLEA: '',
        UNSDLEA: '36570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6159, 46.2468],
            [-87.6151, 46.3336],
            [-87.4905, 46.3333],
            [-87.489, 46.506],
            [-87.3853, 46.5063],
            [-87.3652, 46.4197],
            [-87.1164, 46.4203],
            [-87.1165, 46.3912],
            [-87.1162, 46.2459],
            [-87.3659, 46.2462],
            [-87.4229, 46.1592],
            [-87.616, 46.1598],
            [-87.6159, 46.2468],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614690',
        NAME: 'Gwinn Area Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 906847715,
        AWATER: 15317834,
        INTPTLAT: '+46.3181777',
        INTPTLON: '-087.3730336',
        ELSDLEA: '',
        UNSDLEA: '14690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.8651, 46.7082],
              [-87.6763, 46.7075],
              [-87.6137, 46.6647],
              [-87.6139, 46.6064],
              [-87.6559, 46.5137],
              [-87.6974, 46.5212],
              [-87.6975, 46.4637],
              [-87.634, 46.4707],
              [-87.6151, 46.3336],
              [-87.6159, 46.2468],
              [-87.9503, 46.247],
              [-87.9499, 46.4064],
              [-87.9912, 46.4634],
              [-87.9922, 46.593],
              [-87.8651, 46.7082],
            ],
          ],
          [
            [
              [-88.1158, 46.6784],
              [-88.1166, 46.42],
              [-88.3658, 46.42],
              [-88.3659, 46.5498],
              [-88.2412, 46.5497],
              [-88.24, 46.6785],
              [-88.1158, 46.6784],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625400',
        NAME: 'N.I.C.E. Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1567470764,
        AWATER: 74201549,
        INTPTLAT: '+46.4841316',
        INTPTLON: '-087.8974983',
        ELSDLEA: '',
        UNSDLEA: '25400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1521, 42.902],
            [-83.1067, 42.976],
            [-82.9914, 42.9822],
            [-82.9166, 42.9241],
            [-82.9925, 42.8771],
            [-83.1021, 42.87],
            [-83.1521, 42.902],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602670',
        NAME: 'Almont Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148810418,
        AWATER: 608968,
        INTPTLAT: '+42.9196598',
        INTPTLON: '-083.0341562',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7806, 42.9857],
            [-82.664, 42.9603],
            [-82.6217, 42.9393],
            [-82.6397, 42.8945],
            [-82.6743, 42.8781],
            [-82.8282, 42.8703],
            [-82.8464, 42.9249],
            [-82.7806, 42.9857],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623490',
        NAME: 'Memphis Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153871401,
        AWATER: 422553,
        INTPTLAT: '+42.9199831',
        INTPTLON: '-082.7325176',
        ELSDLEA: '',
        UNSDLEA: '23490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9455, 42.7211],
            [-82.926, 42.7541],
            [-82.8615, 42.7661],
            [-82.7316, 42.7584],
            [-82.7873, 42.6973],
            [-82.8918, 42.6583],
            [-82.9441, 42.6796],
            [-82.9455, 42.7211],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625230',
        NAME: 'New Haven Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123328524,
        AWATER: 367921,
        INTPTLAT: '+42.7266076',
        INTPTLON: '-082.8449463',
        ELSDLEA: '',
        UNSDLEA: '25230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3528, 43.637],
            [-83.2157, 43.6471],
            [-83.2217, 43.6649],
            [-83.1793, 43.7313],
            [-83.1287, 43.7185],
            [-83.1185, 43.697],
            [-83.0074, 43.6417],
            [-82.9959, 43.5694],
            [-82.9948, 43.5478],
            [-82.9929, 43.5041],
            [-83.1095, 43.5011],
            [-83.1176, 43.4426],
            [-83.2688, 43.4908],
            [-83.2418, 43.5556],
            [-83.3906, 43.5823],
            [-83.4108, 43.5892],
            [-83.3528, 43.637],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608400',
        NAME: 'Cass City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 505423912,
        AWATER: 4610187,
        INTPTLAT: '+43.5819535',
        INTPTLON: '-083.1342697',
        ELSDLEA: '',
        UNSDLEA: '08400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6957, 43.2717],
            [-83.682, 43.3068],
            [-83.4616, 43.3208],
            [-83.4017, 43.2341],
            [-83.5808, 43.1943],
            [-83.6956, 43.2214],
            [-83.6957, 43.2717],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623910',
        NAME: 'Millington Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242567596,
        AWATER: 2629107,
        INTPTLAT: '+43.2593329',
        INTPTLON: '-083.5502496',
        ELSDLEA: '',
        UNSDLEA: '23910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.5711, 43.4761],
              [-83.5628, 43.476],
              [-83.5626, 43.466],
              [-83.5668, 43.4659],
              [-83.5711, 43.4659],
              [-83.5711, 43.4761],
            ],
          ],
          [
            [
              [-83.6995, 43.589],
              [-83.6635, 43.5943],
              [-83.5553, 43.6238],
              [-83.4108, 43.5892],
              [-83.3906, 43.5823],
              [-83.4047, 43.5678],
              [-83.5526, 43.4761],
              [-83.5628, 43.476],
              [-83.5711, 43.48],
              [-83.6271, 43.5015],
              [-83.6995, 43.5658],
              [-83.6997, 43.5799],
              [-83.6995, 43.589],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602010',
        NAME: 'Akron-Fairgrove Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238181374,
        AWATER: 505659,
        INTPTLAT: '+43.5451084',
        INTPTLON: '-083.5536946',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.5711, 43.48],
              [-83.5711, 43.4761],
              [-83.5711, 43.4659],
              [-83.5766, 43.4659],
              [-83.5711, 43.48],
            ],
          ],
          [
            [
              [-83.6694, 43.3928],
              [-83.6598, 43.3957],
              [-83.5766, 43.4659],
              [-83.5667, 43.4593],
              [-83.5323, 43.4591],
              [-83.4629, 43.4078],
              [-83.4616, 43.3208],
              [-83.682, 43.3068],
              [-83.6694, 43.3928],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634710',
        NAME: 'Vassar Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218290272,
        AWATER: 1768686,
        INTPTLAT: '+43.3759014',
        INTPTLON: '-083.5686496',
        ELSDLEA: '',
        UNSDLEA: '34710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.3566, 43.7562],
              [-83.3171, 43.7497],
              [-83.337, 43.7423],
              [-83.3563, 43.7455],
              [-83.3566, 43.7562],
            ],
          ],
          [
            [
              [-83.3561, 43.7381],
              [-83.337, 43.7423],
              [-83.1895, 43.7454],
              [-83.1793, 43.7313],
              [-83.2217, 43.6649],
              [-83.2157, 43.6471],
              [-83.3528, 43.637],
              [-83.3561, 43.7381],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627180',
        NAME: 'Owendale-Gagetown Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 158802495,
        AWATER: 284457,
        INTPTLAT: '+43.6920170',
        INTPTLON: '-083.2764068',
        ELSDLEA: '',
        UNSDLEA: '27180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4629, 43.4078],
            [-83.3205, 43.4104],
            [-83.223, 43.3248],
            [-83.3517, 43.2352],
            [-83.4017, 43.2341],
            [-83.4616, 43.3208],
            [-83.4629, 43.4078],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623280',
        NAME: 'Mayville Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239971665,
        AWATER: 3912185,
        INTPTLAT: '+43.3327475',
        INTPTLON: '-083.3579696',
        ELSDLEA: '',
        UNSDLEA: '23280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4067, 43.1216],
            [-85.135, 43.1198],
            [-85.1151, 43.12],
            [-85.1147, 43.0341],
            [-85.119, 43.0323],
            [-85.1859, 43.0089],
            [-85.2671, 42.9873],
            [-85.3124, 43.0541],
            [-85.4111, 43.0687],
            [-85.4067, 43.1216],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604530',
        NAME: 'Belding Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250724205,
        AWATER: 11000334,
        INTPTLAT: '+43.0735575',
        INTPTLON: '-085.2493546',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6559, 46.5137],
            [-87.634, 46.4707],
            [-87.6975, 46.4637],
            [-87.6974, 46.5212],
            [-87.6559, 46.5137],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619530',
        NAME: 'Ishpeming Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24279414,
        AWATER: 2445862,
        INTPTLAT: '+46.4889381',
        INTPTLON: '-087.6671307',
        ELSDLEA: '',
        UNSDLEA: '19530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7887, 43.0959],
            [-85.7387, 43.0878],
            [-85.67, 43.0873],
            [-85.6624, 43.0214],
            [-85.7515, 42.9793],
            [-85.7853, 42.9869],
            [-85.8167, 43.0127],
            [-85.8678, 43.0271],
            [-85.7887, 43.0959],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620280',
        NAME: 'Kenowa Hills Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121195285,
        AWATER: 518180,
        INTPTLAT: '+43.0364717',
        INTPTLON: '-085.7573279',
        ELSDLEA: '',
        UNSDLEA: '20280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.574, 42.9683],
            [-83.4569, 42.9686],
            [-83.4119, 42.9102],
            [-83.562, 42.8736],
            [-83.5813, 42.8731],
            [-83.574, 42.9683],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616320',
        NAME: 'Goodrich Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120085850,
        AWATER: 5309864,
        INTPTLAT: '+42.9155298',
        INTPTLON: '-083.4997755',
        ELSDLEA: '',
        UNSDLEA: '16320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9792, 45.0725],
            [-84.9496, 45.0826],
            [-84.9181, 45.1172],
            [-84.7816, 45.116],
            [-84.6123, 45.0844],
            [-84.5325, 45.0218],
            [-84.5271, 44.8574],
            [-84.8473, 44.858],
            [-84.8491, 44.945],
            [-84.8495, 45.0296],
            [-84.9793, 45.0312],
            [-84.9792, 45.0725],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615730',
        NAME: 'Gaylord Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 700990749,
        AWATER: 20623426,
        INTPTLAT: '+44.9957979',
        INTPTLON: '-084.7205910',
        ELSDLEA: '',
        UNSDLEA: '15730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3024, 44.9462],
            [-85.2249, 44.9171],
            [-85.0915, 44.9455],
            [-85.0605, 44.9782],
            [-84.8491, 44.945],
            [-84.8473, 44.858],
            [-84.9689, 44.8589],
            [-85.0102, 44.8006],
            [-85.1527, 44.8589],
            [-85.3276, 44.8508],
            [-85.3024, 44.9462],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622350',
        NAME: 'Mancelona Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 356200278,
        AWATER: 20264406,
        INTPTLAT: '+44.8947554',
        INTPTLON: '-085.0632923',
        ELSDLEA: '',
        UNSDLEA: '22350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2227, 45.2056],
            [-85.1743, 45.2863],
            [-84.9792, 45.0725],
            [-84.9793, 45.0312],
            [-85.0913, 45.0317],
            [-85.2025, 45.1178],
            [-85.2227, 45.2056],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612560',
        NAME: 'East Jordan Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273802817,
        AWATER: 25660831,
        INTPTLAT: '+45.1452189',
        INTPTLON: '-085.1162046',
        ELSDLEA: '',
        UNSDLEA: '12560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6532, 42.9819],
            [-83.6354, 43.0332],
            [-83.6153, 43.0263],
            [-83.5948, 42.9974],
            [-83.5838, 42.9704],
            [-83.6614, 42.9601],
            [-83.6532, 42.9819],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603540',
        NAME: 'Atherton Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23571517,
        AWATER: 32063,
        INTPTLAT: '+42.9839793',
        INTPTLON: '-083.6217324',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7266, 43.0972],
            [-83.6821, 43.105],
            [-83.6659, 43.0761],
            [-83.719, 43.0827],
            [-83.7266, 43.0972],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604500',
        NAME: 'Beecher Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12989484,
        AWATER: 48650,
        INTPTLAT: '+43.0898469',
        INTPTLON: '-083.6966646',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6827, 42.6956],
            [-83.6541, 42.6963],
            [-83.5095, 42.6857],
            [-83.4646, 42.6793],
            [-83.4732, 42.6464],
            [-83.5574, 42.5311],
            [-83.6708, 42.5196],
            [-83.6842, 42.5654],
            [-83.6827, 42.6956],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618990',
        NAME: 'Huron Valley Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239953702,
        AWATER: 17493970,
        INTPTLAT: '+42.6255577',
        INTPTLON: '-083.5961564',
        ELSDLEA: '',
        UNSDLEA: '18990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7727, 42.5742],
            [-83.6842, 42.5654],
            [-83.6708, 42.5196],
            [-83.7402, 42.4394],
            [-83.7889, 42.4564],
            [-83.8712, 42.5202],
            [-83.7727, 42.5742],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606870',
        NAME: 'Brighton Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137566369,
        AWATER: 11568114,
        INTPTLAT: '+42.5181209',
        INTPTLON: '-083.7614046',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6829, 42.9815],
            [-83.6532, 42.9819],
            [-83.6614, 42.9601],
            [-83.6828, 42.9598],
            [-83.6829, 42.9815],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604740',
        NAME: 'Bendle Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5364830,
        AWATER: 5055,
        INTPTLAT: '+42.9711943',
        INTPTLON: '-083.6675355',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6153, 43.0263],
            [-83.5761, 43.0336],
            [-83.5948, 42.9974],
            [-83.6153, 43.0263],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604800',
        NAME: 'Bentley Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14132416,
        AWATER: 0,
        INTPTLAT: '+43.0157603',
        INTPTLON: '-083.6000592',
        ELSDLEA: '',
        UNSDLEA: '04800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9291, 43.1328],
            [-83.8285, 43.1255],
            [-83.784, 43.1114],
            [-83.7836, 43.0969],
            [-83.7591, 43.046],
            [-83.8124, 43.0156],
            [-83.9284, 43.0247],
            [-83.9287, 43.0804],
            [-83.9291, 43.1328],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614550',
        NAME: 'Flushing Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140081697,
        AWATER: 1550255,
        INTPTLAT: '+43.0677598',
        INTPTLON: '-083.8583082',
        ELSDLEA: '',
        UNSDLEA: '14550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.5851, 43.1582],
              [-83.5897, 43.1544],
              [-83.5947, 43.1543],
              [-83.5851, 43.1582],
            ],
          ],
          [
            [
              [-83.5946, 43.1508],
              [-83.5896, 43.1509],
              [-83.5792, 43.1365],
              [-83.5883, 43.1069],
              [-83.6373, 43.1118],
              [-83.5946, 43.1508],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615750',
        NAME: 'Genesee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15033151,
        AWATER: 1470362,
        INTPTLAT: '+43.1195457',
        INTPTLON: '-083.6085207',
        ELSDLEA: '',
        UNSDLEA: '15750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3718, 44.7689],
            [-84.2519, 44.7687],
            [-84.1311, 44.7685],
            [-84.1302, 44.6812],
            [-83.8882, 44.6821],
            [-83.887, 44.5954],
            [-83.8868, 44.5233],
            [-83.9131, 44.5089],
            [-84.3706, 44.5072],
            [-84.3717, 44.6825],
            [-84.3718, 44.7689],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624000',
        NAME: 'Mio-AuSable Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 916862982,
        AWATER: 6373509,
        INTPTLAT: '+44.6144915',
        INTPTLON: '-084.1578001',
        ELSDLEA: '',
        UNSDLEA: '24000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8651, 46.1584],
            [-85.6131, 46.1589],
            [-85.6135, 46.245],
            [-85.1131, 46.2457],
            [-85.1124, 46.1582],
            [-85.113, 46.0375],
            [-84.9997, 46.0012],
            [-84.9202, 45.9209],
            [-84.7263, 45.8275],
            [-84.7287, 45.8101],
            [-85.1251, 45.8664],
            [-85.2396, 45.8707],
            [-85.5701, 45.8832],
            [-85.8682, 45.8198],
            [-85.8651, 46.1584],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615600',
        NAME: 'Engadine Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1127652371,
        AWATER: 1445436876,
        INTPTLAT: '+46.0700510',
        INTPTLON: '-085.2907193',
        ELSDLEA: '',
        UNSDLEA: '15600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2153, 42.899],
            [-86.1145, 42.943],
            [-86.0414, 42.8969],
            [-86.0662, 42.8016],
            [-86.2102, 42.7713],
            [-86.2153, 42.899],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635910',
        NAME: 'West Ottawa Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183747642,
        AWATER: 5786005,
        INTPTLAT: '+42.8539022',
        INTPTLON: '-086.1347193',
        ELSDLEA: '',
        UNSDLEA: '35910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9441, 42.6796],
            [-82.8918, 42.6583],
            [-82.9036, 42.6193],
            [-82.911, 42.5694],
            [-82.9208, 42.5688],
            [-82.971, 42.5701],
            [-82.9441, 42.6796],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2609570',
        NAME: 'Chippewa Valley Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 71165926,
        AWATER: 317609,
        INTPTLAT: '+42.6248210',
        INTPTLON: '-082.9332451',
        ELSDLEA: '',
        UNSDLEA: '09570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9181, 42.5026],
            [-82.882, 42.5002],
            [-82.8739, 42.467],
            [-82.917, 42.473],
            [-82.9175, 42.4804],
            [-82.9181, 42.5026],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620880',
        NAME: 'Lakeview Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10063124,
        AWATER: 227734,
        INTPTLAT: '+42.4864126',
        INTPTLON: '-082.9027719',
        ELSDLEA: '',
        UNSDLEA: '20880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9036, 42.6193],
            [-82.8857, 42.5661],
            [-82.911, 42.5694],
            [-82.9036, 42.6193],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624690',
        NAME: 'Mount Clemens Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15530549,
        AWATER: 409958,
        INTPTLAT: '+42.5957668',
        INTPTLON: '-082.8830418',
        ELSDLEA: '',
        UNSDLEA: '24690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1536, 42.7953],
            [-83.0704, 42.7201],
            [-83.0914, 42.6242],
            [-83.1207, 42.6233],
            [-83.2106, 42.6499],
            [-83.2186, 42.7094],
            [-83.1536, 42.7953],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629940',
        NAME: 'Rochester Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159930285,
        AWATER: 2792299,
        INTPTLAT: '+42.7019977',
        INTPTLON: '-083.1452266',
        ELSDLEA: '',
        UNSDLEA: '29940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0704, 42.7201],
            [-82.9455, 42.7211],
            [-82.9441, 42.6796],
            [-82.971, 42.5701],
            [-82.9706, 42.5606],
            [-83.0896, 42.5851],
            [-83.0914, 42.6242],
            [-83.0704, 42.7201],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634470',
        NAME: 'Utica Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171572217,
        AWATER: 2968380,
        INTPTLAT: '+42.6461116',
        INTPTLON: '-083.0281777',
        ELSDLEA: '',
        UNSDLEA: '34470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0896, 42.5851],
            [-82.9706, 42.5606],
            [-82.969, 42.5236],
            [-83.0065, 42.4999],
            [-83.0666, 42.4836],
            [-83.0848, 42.4838],
            [-83.0855, 42.5016],
            [-83.1159, 42.5412],
            [-83.0896, 42.5851],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635190',
        NAME: 'Warren Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78149487,
        AWATER: 334871,
        INTPTLAT: '+42.5349303',
        INTPTLON: '-083.0454193',
        ELSDLEA: '',
        UNSDLEA: '35190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1681, 43.5689],
            [-84.0594, 43.5674],
            [-84.0143, 43.5168],
            [-84.0245, 43.4808],
            [-84.0792, 43.4518],
            [-84.0966, 43.4521],
            [-84.1761, 43.4819],
            [-84.1706, 43.482],
            [-84.1681, 43.5689],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615060',
        NAME: 'Freeland Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119362421,
        AWATER: 1342654,
        INTPTLAT: '+43.5141536',
        INTPTLON: '-084.1060875',
        ELSDLEA: '',
        UNSDLEA: '15060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.369, 43.2486],
            [-84.2878, 43.2296],
            [-84.3266, 43.1865],
            [-84.3786, 43.1723],
            [-84.373, 43.1543],
            [-84.4935, 43.1286],
            [-84.5235, 43.1393],
            [-84.5138, 43.1613],
            [-84.5327, 43.2046],
            [-84.369, 43.2486],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603480',
        NAME: 'Ashley Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155052527,
        AWATER: 523855,
        INTPTLAT: '+43.1970872',
        INTPTLON: '-084.4140310',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9332, 43.3079],
            [-83.8154, 43.3071],
            [-83.6957, 43.2717],
            [-83.6956, 43.2214],
            [-83.8144, 43.2146],
            [-83.9321, 43.2204],
            [-83.9332, 43.3079],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2605820',
        NAME: 'Birch Run Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165837854,
        AWATER: 664278,
        INTPTLAT: '+43.2581413',
        INTPTLON: '-083.8234294',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9563, 43.4798],
            [-83.9159, 43.4584],
            [-83.9556, 43.4437],
            [-83.9563, 43.4798],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608070',
        NAME: 'Carrollton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8623937,
        AWATER: 482521,
        INTPTLAT: '+43.4593114',
        INTPTLON: '-083.9391379',
        ELSDLEA: '',
        UNSDLEA: '08070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8144, 43.2146],
            [-83.6956, 43.2214],
            [-83.5808, 43.1943],
            [-83.585, 43.1654],
            [-83.5852, 43.1654],
            [-83.59, 43.1654],
            [-83.7111, 43.1633],
            [-83.784, 43.1114],
            [-83.8285, 43.1255],
            [-83.8144, 43.2146],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610110',
        NAME: 'Clio Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153148649,
        AWATER: 493164,
        INTPTLAT: '+43.1829004',
        INTPTLON: '-083.7227148',
        ELSDLEA: '',
        UNSDLEA: '10110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7836, 43.0969],
            [-83.7266, 43.0972],
            [-83.719, 43.0827],
            [-83.7333, 43.0462],
            [-83.7591, 43.046],
            [-83.7836, 43.0969],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635970',
        NAME: 'Westwood Heights Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25481360,
        AWATER: 18890,
        INTPTLAT: '+43.0679431',
        INTPTLON: '-083.7570081',
        ELSDLEA: '',
        UNSDLEA: '35970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3163, 45.0352],
            [-85.0913, 45.0317],
            [-85.0915, 44.9455],
            [-85.2249, 44.9171],
            [-85.3024, 44.9462],
            [-85.3163, 45.0352],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604620',
        NAME: 'Bellaire Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166878816,
        AWATER: 27079122,
        INTPTLAT: '+44.9834015',
        INTPTLON: '-085.1998423',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9161, 45.2043],
            [-84.7964, 45.2026],
            [-84.7816, 45.116],
            [-84.9181, 45.1172],
            [-84.9161, 45.2043],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606510',
        NAME: 'Boyne Falls Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138624705,
        AWATER: 2305380,
        INTPTLAT: '+45.1437858',
        INTPTLON: '-084.8798672',
        ELSDLEA: '',
        UNSDLEA: '06510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1008, 45.3666],
            [-85.1015, 45.3549],
            [-85.1743, 45.2863],
            [-85.2227, 45.2056],
            [-85.3864, 45.1973],
            [-85.3887, 45.2076],
            [-85.3714, 45.2718],
            [-85.2511, 45.3394],
            [-85.1008, 45.3666],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608730',
        NAME: 'Charlevoix Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212155476,
        AWATER: 25522555,
        INTPTLAT: '+45.2805079',
        INTPTLON: '-085.2451722',
        ELSDLEA: '',
        UNSDLEA: '08730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3698, 45.1215],
            [-85.2025, 45.1178],
            [-85.0913, 45.0317],
            [-85.3163, 45.0352],
            [-85.3712, 45.0627],
            [-85.3698, 45.1215],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608610',
        NAME: 'Central Lake Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144663851,
        AWATER: 17095322,
        INTPTLAT: '+45.0680596',
        INTPTLON: '-085.2382649',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.6584, 45.0402],
              [-85.674, 45.0402],
              [-85.6791, 45.0438],
              [-85.6584, 45.0402],
            ],
          ],
          [
            [
              [-85.6791, 45.0438],
              [-85.6788, 45.0402],
              [-85.7159, 44.9142],
              [-85.8735, 44.9004],
              [-85.8708, 44.9537],
              [-86.0781, 45.1112],
              [-86.0466, 45.1715],
              [-85.8044, 45.0745],
              [-85.6791, 45.0438],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621390',
        NAME: 'Leland Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174671897,
        AWATER: 275158172,
        INTPTLAT: '+45.1238930',
        INTPTLON: '-085.9799661',
        ELSDLEA: '',
        UNSDLEA: '21390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2059, 43.1469],
            [-86.2052, 43.1902],
            [-86.2052, 43.205],
            [-86.1449, 43.2052],
            [-86.1067, 43.2057],
            [-86.0515, 43.1362],
            [-86.0363, 43.1144],
            [-86.0973, 43.0779],
            [-86.2053, 43.1178],
            [-86.2059, 43.1469],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615390',
        NAME: 'Fruitport Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123375141,
        AWATER: 1325926,
        INTPTLAT: '+43.1431772',
        INTPTLON: '-086.1361298',
        ELSDLEA: '',
        UNSDLEA: '15390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0265, 43.0239],
            [-85.8771, 42.9869],
            [-85.8761, 42.9579],
            [-85.943, 42.9432],
            [-86.0242, 42.9719],
            [-86.0265, 43.0239],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602550',
        NAME: 'Allendale Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80414453,
        AWATER: 2395845,
        INTPTLAT: '+42.9775937',
        INTPTLON: '-085.9585688',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.6791, 45.0438],
              [-85.674, 45.0402],
              [-85.6788, 45.0402],
              [-85.6791, 45.0438],
            ],
          ],
          [
            [
              [-86.4608, 45.5844],
              [-85.8558, 45.5784],
              [-85.7501, 45.5487],
              [-85.6251, 45.4546],
              [-85.476, 45.264],
              [-85.4589, 45.2066],
              [-85.4807, 45.1203],
              [-85.5543, 45.0314],
              [-85.6584, 45.0402],
              [-85.6791, 45.0438],
              [-85.8044, 45.0745],
              [-86.0466, 45.1715],
              [-86.0781, 45.1112],
              [-86.3311, 45.1867],
              [-86.2501, 45.2357],
              [-86.4641, 45.3248],
              [-86.4608, 45.5844],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625920',
        NAME: 'Northport Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123137874,
        AWATER: 3321822477,
        INTPTLAT: '+45.4159580',
        INTPTLON: '-085.8502457',
        ELSDLEA: '',
        UNSDLEA: '25920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0095, 45.4946],
            [-83.9077, 45.4849],
            [-83.7617, 45.41],
            [-83.8072, 45.2898],
            [-83.8812, 45.2021],
            [-84.0038, 45.1988],
            [-84.0095, 45.4946],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630060',
        NAME: 'Rogers City Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 480857441,
        AWATER: 7118542,
        INTPTLAT: '+45.3462719',
        INTPTLON: '-083.9070795',
        ELSDLEA: '',
        UNSDLEA: '30060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.7776, 43.7495],
              [-84.7823, 43.738],
              [-84.7876, 43.7381],
              [-84.7776, 43.7495],
            ],
          ],
          [
            [
              [-84.8485, 43.7273],
              [-84.8226, 43.7347],
              [-84.823, 43.7274],
              [-84.8485, 43.7273],
            ],
          ],
          [
            [
              [-84.9121, 43.5827],
              [-84.9074, 43.5827],
              [-84.9022, 43.5753],
              [-84.9121, 43.5827],
            ],
          ],
          [
            [
              [-84.9277, 43.5984],
              [-84.9224, 43.6043],
              [-84.9181, 43.6042],
              [-84.9129, 43.6121],
              [-84.9079, 43.6136],
              [-84.8685, 43.6486],
              [-84.818, 43.7349],
              [-84.823, 43.7492],
              [-84.7876, 43.7381],
              [-84.7823, 43.738],
              [-84.7075, 43.7449],
              [-84.608, 43.6407],
              [-84.6881, 43.6407],
              [-84.7475, 43.5371],
              [-84.8968, 43.5532],
              [-84.9022, 43.5753],
              [-84.9074, 43.5827],
              [-84.9277, 43.5973],
              [-84.9277, 43.5984],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624750',
        NAME: 'Mount Pleasant City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 343439320,
        AWATER: 1949910,
        INTPTLAT: '+43.6349401',
        INTPTLON: '-084.7639347',
        ELSDLEA: '',
        UNSDLEA: '24750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0867, 42.8465],
            [-84.0764, 42.8503],
            [-84.0709, 42.8504],
            [-83.9273, 42.8713],
            [-83.8418, 42.8465],
            [-83.874, 42.7816],
            [-83.9361, 42.76],
            [-83.9411, 42.76],
            [-83.9738, 42.739],
            [-84.0892, 42.7845],
            [-84.0867, 42.8465],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607530',
        NAME: 'Byron Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184369610,
        AWATER: 3231486,
        INTPTLAT: '+42.8160688',
        INTPTLON: '-083.9735306',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.742, 42.9587],
              [-83.6829, 42.9815],
              [-83.6828, 42.9598],
              [-83.7228, 42.905],
              [-83.77, 42.9114],
              [-83.742, 42.9587],
            ],
          ],
          [
            [
              [-83.7591, 43.046],
              [-83.7333, 43.0462],
              [-83.7435, 42.9755],
              [-83.8124, 43.0156],
              [-83.7591, 43.046],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607890',
        NAME: 'Carman-Ainsworth Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64296170,
        AWATER: 332594,
        INTPTLAT: '+43.0111210',
        INTPTLON: '-083.7783477',
        ELSDLEA: '',
        UNSDLEA: '07890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5761, 43.0336],
            [-83.5785, 43.1086],
            [-83.574, 43.1087],
            [-83.459, 43.0899],
            [-83.4569, 42.9686],
            [-83.574, 42.9683],
            [-83.5838, 42.9704],
            [-83.5948, 42.9974],
            [-83.5761, 43.0336],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611430',
        NAME: 'Davison Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157346179,
        AWATER: 1160395,
        INTPTLAT: '+43.0277710',
        INTPTLON: '-083.5133682',
        ELSDLEA: '',
        UNSDLEA: '11430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7435, 42.9755],
            [-83.7333, 43.0462],
            [-83.719, 43.0827],
            [-83.6659, 43.0761],
            [-83.6354, 43.0332],
            [-83.6532, 42.9819],
            [-83.6829, 42.9815],
            [-83.742, 42.9587],
            [-83.7435, 42.9755],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614520',
        NAME: 'Flint City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83204522,
        AWATER: 1713864,
        INTPTLAT: '+43.0267945',
        INTPTLON: '-083.6891914',
        ELSDLEA: '',
        UNSDLEA: '14520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4017, 43.2341],
            [-83.3517, 43.2352],
            [-83.3024, 43.1756],
            [-83.459, 43.0899],
            [-83.574, 43.1087],
            [-83.5792, 43.1365],
            [-83.5896, 43.1509],
            [-83.5897, 43.1544],
            [-83.5851, 43.1582],
            [-83.585, 43.1654],
            [-83.5808, 43.1943],
            [-83.4017, 43.2341],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620940',
        NAME: 'Lakeville Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228327662,
        AWATER: 9816427,
        INTPTLAT: '+43.1691534',
        INTPTLON: '-083.4583866',
        ELSDLEA: '',
        UNSDLEA: '20940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8418, 42.8465],
            [-83.7969, 42.899],
            [-83.7739, 42.8847],
            [-83.7581, 42.8185],
            [-83.7662, 42.737],
            [-83.8638, 42.7592],
            [-83.874, 42.7816],
            [-83.8418, 42.8465],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621690',
        NAME: 'Linden Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117335493,
        AWATER: 7323984,
        INTPTLAT: '+42.8072446',
        INTPTLON: '-083.8246821',
        ELSDLEA: '',
        UNSDLEA: '21690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.5947, 43.1543],
              [-83.5897, 43.1544],
              [-83.5896, 43.1509],
              [-83.5946, 43.1508],
              [-83.5947, 43.1543],
            ],
          ],
          [
            [
              [-83.5792, 43.1365],
              [-83.574, 43.1087],
              [-83.5785, 43.1086],
              [-83.5883, 43.1069],
              [-83.5792, 43.1365],
            ],
          ],
          [
            [
              [-83.784, 43.1114],
              [-83.7111, 43.1633],
              [-83.59, 43.1654],
              [-83.5852, 43.1654],
              [-83.585, 43.1654],
              [-83.5851, 43.1582],
              [-83.5947, 43.1543],
              [-83.5946, 43.1508],
              [-83.6373, 43.1118],
              [-83.6821, 43.105],
              [-83.7266, 43.0972],
              [-83.7836, 43.0969],
              [-83.784, 43.1114],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624720',
        NAME: 'Mount Morris Consolidated School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73850756,
        AWATER: 390623,
        INTPTLAT: '+43.1316170',
        INTPTLON: '-083.6789495',
        ELSDLEA: '',
        UNSDLEA: '24720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2401, 46.5053],
            [-85.8649, 46.5048],
            [-85.8549, 47.3055],
            [-85.2343, 47.0476],
            [-85.2371, 46.7664],
            [-85.2391, 46.4195],
            [-85.1144, 46.419],
            [-85.1135, 46.2747],
            [-85.1131, 46.2457],
            [-85.6135, 46.245],
            [-85.6131, 46.1589],
            [-85.8651, 46.1584],
            [-85.8646, 46.3317],
            [-86.2409, 46.3316],
            [-86.2401, 46.5053],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625350',
        NAME: 'Tahquamenon Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3194661281,
        AWATER: 2678241999,
        INTPTLAT: '+46.6568412',
        INTPTLON: '-085.5946445',
        ELSDLEA: '',
        UNSDLEA: '25350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2217, 42.3989],
            [-85.203, 42.4081],
            [-85.1411, 42.3336],
            [-85.179, 42.2985],
            [-85.2378, 42.2466],
            [-85.2972, 42.2727],
            [-85.298, 42.3517],
            [-85.2217, 42.3989],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600005',
        NAME: 'Battle Creek Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107127179,
        AWATER: 1907106,
        INTPTLAT: '+42.3315007',
        INTPTLON: '-085.2306005',
        ELSDLEA: '',
        UNSDLEA: '00005',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0627, 42.3336],
            [-85.0485, 42.3558],
            [-85.0054, 42.3771],
            [-84.9273, 42.3632],
            [-84.8476, 42.24],
            [-84.8475, 42.2248],
            [-84.987, 42.1741],
            [-85.0487, 42.1598],
            [-85.0603, 42.1818],
            [-85.0627, 42.3336],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622970',
        NAME: 'Marshall Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 264798677,
        AWATER: 5515689,
        INTPTLAT: '+42.2617703',
        INTPTLON: '-084.9720470',
        ELSDLEA: '',
        UNSDLEA: '22970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4119, 42.9102],
            [-83.4068, 42.9031],
            [-83.3867, 42.8887],
            [-83.3564, 42.8824],
            [-83.3333, 42.8024],
            [-83.3528, 42.7947],
            [-83.4727, 42.7888],
            [-83.562, 42.8736],
            [-83.4119, 42.9102],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606570',
        NAME: 'Brandon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138938325,
        AWATER: 4646808,
        INTPTLAT: '+42.8408603',
        INTPTLON: '-083.4439952',
        ELSDLEA: '',
        UNSDLEA: '06570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4727, 42.7888],
            [-83.3528, 42.7947],
            [-83.3289, 42.7059],
            [-83.4646, 42.6793],
            [-83.5095, 42.6857],
            [-83.4727, 42.7888],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2609900',
        NAME: 'Clarkston Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144419597,
        AWATER: 6205884,
        INTPTLAT: '+42.7412908',
        INTPTLON: '-083.4269192',
        ELSDLEA: '',
        UNSDLEA: '09900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7581, 42.8185],
            [-83.6874, 42.8124],
            [-83.6541, 42.6963],
            [-83.6827, 42.6956],
            [-83.7662, 42.737],
            [-83.7581, 42.8185],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614250',
        NAME: 'Fenton Area Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90722935,
        AWATER: 5974087,
        INTPTLAT: '+42.7531413',
        INTPTLON: '-083.7151430',
        ELSDLEA: '',
        UNSDLEA: '14250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3528, 42.7947],
            [-83.3333, 42.8024],
            [-83.1589, 42.8066],
            [-83.1536, 42.7953],
            [-83.2186, 42.7094],
            [-83.3289, 42.7059],
            [-83.3528, 42.7947],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620730',
        NAME: 'Lake Orion Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121610247,
        AWATER: 7980358,
        INTPTLAT: '+42.7576640',
        INTPTLON: '-083.2624307',
        ELSDLEA: '',
        UNSDLEA: '20730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5359, 42.4942],
            [-83.4366, 42.5041],
            [-83.4337, 42.4389],
            [-83.5335, 42.4573],
            [-83.5359, 42.4942],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626130',
        NAME: 'Novi Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47287763,
        AWATER: 462707,
        INTPTLAT: '+42.4776300',
        INTPTLON: '-083.4855641',
        ELSDLEA: '',
        UNSDLEA: '26130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.202, 42.4767],
            [-83.1637, 42.4747],
            [-83.1637, 42.4743],
            [-83.1615, 42.4458],
            [-83.2103, 42.4446],
            [-83.202, 42.4767],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626190',
        NAME: 'Oak Park City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11276553,
        AWATER: 0,
        INTPTLAT: '+42.4587914',
        INTPTLON: '-083.1870572',
        ELSDLEA: '',
        UNSDLEA: '26190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3564, 42.8824],
            [-83.2629, 42.9094],
            [-83.1521, 42.902],
            [-83.1021, 42.87],
            [-83.1021, 42.8694],
            [-83.102, 42.8656],
            [-83.1589, 42.8066],
            [-83.3333, 42.8024],
            [-83.3564, 42.8824],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627240',
        NAME: 'Oxford Area Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 193220945,
        AWATER: 7308886,
        INTPTLAT: '+42.8499134',
        INTPTLON: '-083.2405940',
        ELSDLEA: '',
        UNSDLEA: '27240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1761, 42.5399],
            [-83.1662, 42.5402],
            [-83.1374, 42.5275],
            [-83.1269, 42.5412],
            [-83.1165, 42.5412],
            [-83.117, 42.502],
            [-83.1107, 42.476],
            [-83.1237, 42.4757],
            [-83.1637, 42.4743],
            [-83.1637, 42.4747],
            [-83.2034, 42.5102],
            [-83.1761, 42.5399],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630300',
        NAME: 'Royal Oak City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31803326,
        AWATER: 12002,
        INTPTLAT: '+42.5089951',
        INTPTLON: '-083.1546100',
        ELSDLEA: '',
        UNSDLEA: '30300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7402, 42.4394],
            [-83.6708, 42.5196],
            [-83.5574, 42.5311],
            [-83.5359, 42.4942],
            [-83.5335, 42.4573],
            [-83.5629, 42.3909],
            [-83.6431, 42.3625],
            [-83.6626, 42.3618],
            [-83.7402, 42.4394],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632250',
        NAME: 'South Lyon Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 202779940,
        AWATER: 4450747,
        INTPTLAT: '+42.4479401',
        INTPTLON: '-083.6355462',
        ELSDLEA: '',
        UNSDLEA: '32250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4732, 42.6464],
            [-83.423, 42.6066],
            [-83.3846, 42.5277],
            [-83.4366, 42.5041],
            [-83.5359, 42.4942],
            [-83.5574, 42.5311],
            [-83.4732, 42.6464],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635160',
        NAME: 'Walled Lake Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 128515459,
        AWATER: 12838203,
        INTPTLAT: '+42.5593235',
        INTPTLON: '-083.4699015',
        ELSDLEA: '',
        UNSDLEA: '35160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4646, 42.6793],
            [-83.3289, 42.7059],
            [-83.3397, 42.6179],
            [-83.423, 42.6066],
            [-83.4732, 42.6464],
            [-83.4646, 42.6793],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635310',
        NAME: 'Waterford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88460270,
        AWATER: 14380145,
        INTPTLAT: '+42.6595589',
        INTPTLON: '-083.3937836',
        ELSDLEA: '',
        UNSDLEA: '35310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8636, 43.0393],
            [-84.8141, 43.076],
            [-84.8084, 43.002],
            [-84.7151, 42.9507],
            [-84.7052, 42.9507],
            [-84.7006, 42.9507],
            [-84.6918, 42.9222],
            [-84.7199, 42.8859],
            [-84.7704, 42.8571],
            [-84.8701, 42.9006],
            [-84.8868, 42.9596],
            [-84.8868, 42.9615],
            [-84.8868, 42.9633],
            [-84.8636, 43.0393],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627960',
        NAME: 'Pewamo-Westphalia Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210737989,
        AWATER: 1278350,
        INTPTLAT: '+42.9517725',
        INTPTLON: '-084.8108438',
        ELSDLEA: '',
        UNSDLEA: '27960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.6374, 42.806],
              [-84.6028, 42.7843],
              [-84.6175, 42.7699],
              [-84.6374, 42.806],
            ],
          ],
          [
            [
              [-84.6175, 42.7619],
              [-84.6219, 42.6885],
              [-84.6423, 42.6713],
              [-84.6766, 42.6691],
              [-84.6175, 42.7619],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635520',
        NAME: 'Waverly Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42609774,
        AWATER: 1129455,
        INTPTLAT: '+42.7355052',
        INTPTLON: '-084.6242545',
        ELSDLEA: '',
        UNSDLEA: '35520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2106, 42.6499],
            [-83.1207, 42.6233],
            [-83.199, 42.6138],
            [-83.2486, 42.6199],
            [-83.2106, 42.6499],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603690',
        NAME: 'Avondale School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31498904,
        AWATER: 61543,
        INTPTLAT: '+42.6302577',
        INTPTLON: '-083.1890826',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3474, 42.5654],
            [-83.2075, 42.5829],
            [-83.1761, 42.5399],
            [-83.2034, 42.5102],
            [-83.3199, 42.5074],
            [-83.3408, 42.5362],
            [-83.3474, 42.5654],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2605850',
        NAME: 'Birmingham City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 62871542,
        AWATER: 1010903,
        INTPTLAT: '+42.5395627',
        INTPTLON: '-083.2586992',
        ELSDLEA: '',
        UNSDLEA: '05850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1637, 42.4743],
            [-83.1237, 42.4757],
            [-83.1123, 42.4468],
            [-83.1615, 42.4458],
            [-83.1637, 42.4743],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614280',
        NAME: 'Ferndale Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12008542,
        AWATER: 0,
        INTPTLAT: '+42.4605498',
        INTPTLON: '-083.1432804',
        ELSDLEA: '',
        UNSDLEA: '14280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1237, 42.4757],
            [-83.1107, 42.476],
            [-83.0845, 42.4763],
            [-83.0834, 42.4472],
            [-83.1123, 42.4468],
            [-83.1237, 42.4757],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618030',
        NAME: 'Hazel Park City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9740543,
        AWATER: 0,
        INTPTLAT: '+42.4622243',
        INTPTLON: '-083.1024313',
        ELSDLEA: '',
        UNSDLEA: '18030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1165, 42.5412],
            [-83.1159, 42.5412],
            [-83.0855, 42.5016],
            [-83.117, 42.502],
            [-83.1165, 42.5412],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621120',
        NAME: 'Lamphere Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13332900,
        AWATER: 0,
        INTPTLAT: '+42.5189243',
        INTPTLON: '-083.1051631',
        ELSDLEA: '',
        UNSDLEA: '21120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0855, 42.5016],
            [-83.0848, 42.4838],
            [-83.0845, 42.4763],
            [-83.1107, 42.476],
            [-83.117, 42.502],
            [-83.0855, 42.5016],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622290',
        NAME: 'Madison Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6156629,
        AWATER: 0,
        INTPTLAT: '+42.4881371',
        INTPTLON: '-083.0997835',
        ELSDLEA: '',
        UNSDLEA: '22290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3199, 42.5074],
            [-83.2034, 42.5102],
            [-83.202, 42.4767],
            [-83.2103, 42.4446],
            [-83.2878, 42.4427],
            [-83.3121, 42.4421],
            [-83.3172, 42.4492],
            [-83.3199, 42.5074],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632310',
        NAME: 'Southfield Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67936853,
        AWATER: 14814,
        INTPTLAT: '+42.4772493',
        INTPTLON: '-083.2625479',
        ELSDLEA: '',
        UNSDLEA: '32310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.199, 42.6138],
            [-83.1207, 42.6233],
            [-83.0914, 42.6242],
            [-83.0896, 42.5851],
            [-83.1159, 42.5412],
            [-83.1165, 42.5412],
            [-83.1269, 42.5412],
            [-83.1662, 42.5402],
            [-83.1761, 42.5399],
            [-83.2075, 42.5829],
            [-83.199, 42.6138],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634260',
        NAME: 'Troy School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68619897,
        AWATER: 415720,
        INTPTLAT: '+42.5838824',
        INTPTLON: '-083.1455329',
        ELSDLEA: '',
        UNSDLEA: '34260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.3362, 44.2813],
              [-85.2553, 44.256],
              [-85.2453, 44.2632],
              [-85.2403, 44.2631],
              [-85.0598, 44.3227],
              [-85.0595, 44.3082],
              [-85.0545, 44.3081],
              [-85.0547, 44.3189],
              [-85.0498, 44.3225],
              [-85.0447, 44.3225],
              [-84.9935, 44.2927],
              [-84.9522, 44.1621],
              [-84.9679, 44.1624],
              [-85.2065, 44.1649],
              [-85.3064, 44.1169],
              [-85.334, 44.1648],
              [-85.3348, 44.1651],
              [-85.3362, 44.2813],
            ],
          ],
          [
            [
              [-85.0549, 44.3261],
              [-85.0547, 44.3226],
              [-85.0598, 44.3227],
              [-85.0549, 44.3261],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623310',
        NAME: 'McBain Rural Agricultural Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 459413439,
        AWATER: 2139404,
        INTPTLAT: '+44.2157866',
        INTPTLON: '-085.1409502',
        ELSDLEA: '',
        UNSDLEA: '23310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6756, 43.9377],
            [-84.4849, 43.9081],
            [-84.466, 43.959],
            [-84.2877, 43.9976],
            [-84.2884, 44.0838],
            [-84.1664, 44.0831],
            [-84.166, 43.9335],
            [-84.2871, 43.9129],
            [-84.2882, 43.8276],
            [-84.454, 43.8143],
            [-84.606, 43.8152],
            [-84.6065, 43.9018],
            [-84.6756, 43.9377],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604440',
        NAME: 'Beaverton Rural Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 566314031,
        AWATER: 15930293,
        INTPTLAT: '+43.9223510',
        INTPTLON: '-084.3748729',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3638, 43.9584],
            [-85.3256, 44.0734],
            [-85.2064, 44.0739],
            [-85.1381, 44.0083],
            [-85.0879, 43.9872],
            [-85.0885, 43.8422],
            [-85.2151, 43.785],
            [-85.3444, 43.7703],
            [-85.3848, 43.7993],
            [-85.3252, 43.9153],
            [-85.3638, 43.9584],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2613560',
        NAME: 'Evart Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 510161962,
        AWATER: 9980795,
        INTPTLAT: '+43.9181261',
        INTPTLON: '-085.2328170',
        ELSDLEA: '',
        UNSDLEA: '13560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1185, 43.697],
            [-82.9614, 43.7455],
            [-82.9056, 43.7979],
            [-82.8503, 43.7704],
            [-82.7008, 43.7309],
            [-82.7178, 43.6438],
            [-82.9959, 43.5694],
            [-83.0074, 43.6417],
            [-83.1185, 43.697],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634380',
        NAME: 'Ubly Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 444690222,
        AWATER: 319804,
        INTPTLAT: '+43.6897419',
        INTPTLON: '-082.8885138',
        ELSDLEA: '',
        UNSDLEA: '34380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.095, 43.7784],
            [-83.0732, 43.8934],
            [-83.0629, 43.8937],
            [-83.0531, 43.9041],
            [-82.9975, 43.8957],
            [-82.994, 43.8958],
            [-83.0024, 43.8666],
            [-83.0154, 43.8233],
            [-82.972, 43.8249],
            [-82.9754, 43.853],
            [-82.9444, 43.8973],
            [-82.894, 43.8914],
            [-82.9079, 43.855],
            [-82.8876, 43.8419],
            [-82.8875, 43.8383],
            [-82.9016, 43.8196],
            [-82.9056, 43.7979],
            [-82.9614, 43.7455],
            [-83.1185, 43.697],
            [-83.1287, 43.7185],
            [-83.095, 43.7784],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600017',
        NAME: 'Bad Axe Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232319399,
        AWATER: 453672,
        INTPTLAT: '+43.8033427',
        INTPTLON: '-083.0109234',
        ELSDLEA: '',
        UNSDLEA: '00017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.846, 43.4662],
            [-84.6777, 43.4584],
            [-84.6019, 43.3496],
            [-84.6019, 43.3477],
            [-84.6019, 43.3462],
            [-84.6669, 43.3209],
            [-84.8452, 43.3338],
            [-84.846, 43.4662],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602640',
        NAME: 'Alma Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261342929,
        AWATER: 2802572,
        INTPTLAT: '+43.3831961',
        INTPTLON: '-084.7426195',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4302, 45.4591],
            [-84.2482, 45.4548],
            [-84.252, 45.626],
            [-84.2043, 45.6262],
            [-84.1122, 45.5074],
            [-84.0095, 45.4946],
            [-84.0038, 45.1988],
            [-84.3666, 45.1987],
            [-84.3687, 45.285],
            [-84.4293, 45.3733],
            [-84.4302, 45.4591],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626400',
        NAME: 'Onaway Area Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1027232132,
        AWATER: 31860005,
        INTPTLAT: '+45.3742823',
        INTPTLON: '-084.1949471',
        ELSDLEA: '',
        UNSDLEA: '26400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7964, 45.2026],
            [-84.7342, 45.2311],
            [-84.7344, 45.2023],
            [-84.3668, 45.1988],
            [-84.3677, 45.1126],
            [-84.6123, 45.0844],
            [-84.7816, 45.116],
            [-84.7964, 45.2026],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634620',
        NAME: 'Vanderbilt Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 348066799,
        AWATER: 4167780,
        INTPTLAT: '+45.1511339',
        INTPTLON: '-084.5732837',
        ELSDLEA: '',
        UNSDLEA: '34620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4885, 43.5751],
            [-84.3697, 43.538],
            [-84.3784, 43.4739],
            [-84.4049, 43.3211],
            [-84.4294, 43.3211],
            [-84.4344, 43.3211],
            [-84.5036, 43.3352],
            [-84.5397, 43.5535],
            [-84.4885, 43.5751],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606630',
        NAME: 'Breckenridge Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 333014075,
        AWATER: 2392025,
        INTPTLAT: '+43.4587464',
        INTPTLON: '-084.4397075',
        ELSDLEA: '',
        UNSDLEA: '06630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3784, 43.4739],
            [-84.3697, 43.538],
            [-84.2908, 43.5086],
            [-84.2899, 43.3106],
            [-84.2891, 43.2808],
            [-84.2878, 43.2296],
            [-84.369, 43.2486],
            [-84.4049, 43.3211],
            [-84.3784, 43.4739],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623610',
        NAME: 'Merrill Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 260453615,
        AWATER: 893643,
        INTPTLAT: '+43.3806225',
        INTPTLON: '-084.3206034',
        ELSDLEA: '',
        UNSDLEA: '23610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9362, 43.5251],
            [-84.8968, 43.5532],
            [-84.7475, 43.5371],
            [-84.6881, 43.6407],
            [-84.608, 43.6407],
            [-84.6079, 43.6146],
            [-84.5484, 43.593],
            [-84.6777, 43.4584],
            [-84.846, 43.4662],
            [-84.9304, 43.4956],
            [-84.9362, 43.5251],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2631380',
        NAME: 'Shepherd Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290179368,
        AWATER: 1041656,
        INTPTLAT: '+43.5314949',
        INTPTLON: '-084.7173631',
        ELSDLEA: '',
        UNSDLEA: '31380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3709, 41.9553],
            [-83.2256, 42.0491],
            [-83.1859, 42.0294],
            [-83.3335, 41.9065],
            [-83.3709, 41.9553],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619800',
        NAME: 'Jefferson Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90241613,
        AWATER: 9082861,
        INTPTLAT: '+41.9756313',
        INTPTLON: '-083.2798437',
        ELSDLEA: '',
        UNSDLEA: '19800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5186, 42.0288],
            [-83.3881, 42.0069],
            [-83.3709, 41.9553],
            [-83.3335, 41.9065],
            [-83.4129, 41.8256],
            [-83.5027, 41.8606],
            [-83.5265, 41.9515],
            [-83.5186, 42.0288],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624150',
        NAME: 'Monroe Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211522913,
        AWATER: 7782324,
        INTPTLAT: '+41.9373158',
        INTPTLON: '-083.4422926',
        ELSDLEA: '',
        UNSDLEA: '24150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7615, 41.9516],
            [-83.6459, 41.9325],
            [-83.65, 41.8219],
            [-83.669, 41.8221],
            [-83.7476, 41.8358],
            [-83.7704, 41.9369],
            [-83.7615, 41.9516],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633120',
        NAME: 'Summerfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108942084,
        AWATER: 904656,
        INTPTLAT: '+41.8826610',
        INTPTLON: '-083.7036799',
        ELSDLEA: '',
        UNSDLEA: '33120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6788, 45.0402],
            [-85.674, 45.0402],
            [-85.6584, 45.0402],
            [-85.5543, 45.0314],
            [-85.6053, 44.8479],
            [-85.7188, 44.8626],
            [-85.7159, 44.9142],
            [-85.6788, 45.0402],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633360',
        NAME: 'Suttons Bay Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123499625,
        AWATER: 90412357,
        INTPTLAT: '+44.9439904',
        INTPTLON: '-085.6410419',
        ELSDLEA: '',
        UNSDLEA: '33360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3043, 42.116],
            [-83.2567, 42.1092],
            [-83.2685, 42.0742],
            [-83.3301, 42.0941],
            [-83.3043, 42.116],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607080',
        NAME: 'Flat Rock Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18881911,
        AWATER: 658082,
        INTPTLAT: '+42.0977923',
        INTPTLON: '-083.2863966',
        ELSDLEA: '',
        UNSDLEA: '07080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2748, 42.3569],
            [-83.266, 42.357],
            [-83.1671, 42.2896],
            [-83.2177, 42.2772],
            [-83.2711, 42.2817],
            [-83.2864, 42.3122],
            [-83.2748, 42.3569],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611600',
        NAME: 'Dearborn City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68394159,
        AWATER: 675440,
        INTPTLAT: '+42.3158735',
        INTPTLON: '-083.2171750',
        ELSDLEA: '',
        UNSDLEA: '11600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2878, 42.4427],
            [-83.2103, 42.4446],
            [-83.1615, 42.4458],
            [-83.1123, 42.4468],
            [-83.0834, 42.4472],
            [-83.0345, 42.4479],
            [-82.9857, 42.4493],
            [-82.9413, 42.4502],
            [-82.918, 42.422],
            [-82.923, 42.3522],
            [-83.1, 42.2867],
            [-83.153, 42.2644],
            [-83.1609, 42.2552],
            [-83.1699, 42.2646],
            [-83.1671, 42.2896],
            [-83.266, 42.357],
            [-83.2759, 42.3858],
            [-83.2878, 42.4427],
          ],
          [
            [-83.0736, 42.3986],
            [-83.0547, 42.3796],
            [-83.0425, 42.4044],
            [-83.0763, 42.4019],
            [-83.0894, 42.4182],
            [-83.1219, 42.4175],
            [-83.1021, 42.3883],
            [-83.0736, 42.3986],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612000',
        NAME: 'Detroit City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359544439,
        AWATER: 10745113,
        INTPTLAT: '+42.3830375',
        INTPTLON: '-083.1022365',
        ELSDLEA: '',
        UNSDLEA: '12000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2685, 42.0742],
            [-83.2567, 42.1092],
            [-83.216, 42.1264],
            [-83.1792, 42.1127],
            [-83.134, 42.0843],
            [-83.144, 42.0279],
            [-83.1859, 42.0294],
            [-83.2256, 42.0491],
            [-83.2685, 42.0742],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615870',
        NAME: 'Gibraltar School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40927722,
        AWATER: 24226855,
        INTPTLAT: '+42.0759287',
        INTPTLON: '-083.2004101',
        ELSDLEA: '',
        UNSDLEA: '15870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6595, 42.1872],
            [-83.5736, 42.2134],
            [-83.4945, 42.1209],
            [-83.5002, 42.0864],
            [-83.6389, 42.084],
            [-83.6595, 42.1872],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621570',
        NAME: 'Lincoln Consolidated School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152421249,
        AWATER: 2543492,
        INTPTLAT: '+42.1475003',
        INTPTLON: '-083.5802322',
        ELSDLEA: '',
        UNSDLEA: '21570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1946, 42.2588],
            [-83.1699, 42.2646],
            [-83.1609, 42.2552],
            [-83.1563, 42.2344],
            [-83.1812, 42.2211],
            [-83.2006, 42.2282],
            [-83.1946, 42.2588],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621600',
        NAME: 'Lincoln Park Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15165330,
        AWATER: 20489,
        INTPTLAT: '+42.2432907',
        INTPTLON: '-083.1812577',
        ELSDLEA: '',
        UNSDLEA: '21600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4337, 42.4389],
            [-83.3657, 42.4408],
            [-83.3159, 42.4205],
            [-83.3146, 42.3919],
            [-83.3128, 42.3552],
            [-83.3122, 42.3406],
            [-83.3384, 42.3393],
            [-83.4292, 42.3313],
            [-83.4331, 42.4249],
            [-83.4337, 42.4389],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621840',
        NAME: 'Livonia Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101006933,
        AWATER: 436578,
        INTPTLAT: '+42.3863643',
        INTPTLON: '-083.3755439',
        ELSDLEA: '',
        UNSDLEA: '21840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3096, 42.2827],
            [-83.2705, 42.2691],
            [-83.2319, 42.2699],
            [-83.2299, 42.2286],
            [-83.2281, 42.1843],
            [-83.3063, 42.1881],
            [-83.319, 42.268],
            [-83.3096, 42.2827],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633540',
        NAME: 'Taylor School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67010323,
        AWATER: 109140,
        INTPTLAT: '+42.2226675',
        INTPTLON: '-083.2787209',
        ELSDLEA: '',
        UNSDLEA: '33540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2172, 42.1553],
            [-83.1682, 42.1554],
            [-83.1792, 42.1127],
            [-83.216, 42.1264],
            [-83.2172, 42.1553],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633900',
        NAME: 'Trenton Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15860498,
        AWATER: 573390,
        INTPTLAT: '+42.1351157',
        INTPTLON: '-083.1957699',
        ELSDLEA: '',
        UNSDLEA: '33900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5446, 42.2862],
            [-83.4942, 42.2855],
            [-83.4254, 42.2493],
            [-83.4232, 42.1794],
            [-83.4511, 42.121],
            [-83.4945, 42.1209],
            [-83.5736, 42.2134],
            [-83.5446, 42.2862],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634560',
        NAME: 'Van Buren Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140756502,
        AWATER: 6252064,
        INTPTLAT: '+42.2064310',
        INTPTLON: '-083.4869050',
        ELSDLEA: '',
        UNSDLEA: '34560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3255, 42.1809],
            [-83.3063, 42.1881],
            [-83.2281, 42.1843],
            [-83.2181, 42.1844],
            [-83.2172, 42.1553],
            [-83.216, 42.1264],
            [-83.2567, 42.1092],
            [-83.3043, 42.116],
            [-83.3255, 42.1809],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636485',
        NAME: 'Woodhaven-Brownstown School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55318927,
        AWATER: 83966,
        INTPTLAT: '+42.1459547',
        INTPTLON: '-083.2712506',
        ELSDLEA: '',
        UNSDLEA: '36485',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4431, 44.8598],
            [-85.3712, 45.0627],
            [-85.3163, 45.0352],
            [-85.3024, 44.9462],
            [-85.3276, 44.8508],
            [-85.3334, 44.6851],
            [-85.4555, 44.6857],
            [-85.4243, 44.769],
            [-85.4431, 44.8598],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2613050',
        NAME: 'Elk Rapids Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259297217,
        AWATER: 75374420,
        INTPTLAT: '+44.8472155',
        INTPTLON: '-085.3637973',
        ELSDLEA: '',
        UNSDLEA: '13050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4144, 44.5486],
            [-85.394, 44.6418],
            [-85.3334, 44.6851],
            [-85.2119, 44.6848],
            [-85.2119, 44.5623],
            [-85.1547, 44.5111],
            [-85.2132, 44.5114],
            [-85.3048, 44.5122],
            [-85.4566, 44.5123],
            [-85.4144, 44.5486],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614570',
        NAME: 'Forest Area Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293315300,
        AWATER: 5790672,
        INTPTLAT: '+44.5911673',
        INTPTLON: '-085.3024312',
        ELSDLEA: '',
        UNSDLEA: '14570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6821, 43.105],
            [-83.6373, 43.1118],
            [-83.5883, 43.1069],
            [-83.5785, 43.1086],
            [-83.5761, 43.0336],
            [-83.6153, 43.0263],
            [-83.6354, 43.0332],
            [-83.6659, 43.0761],
            [-83.6821, 43.105],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620070',
        NAME: 'Kearsley Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53330383,
        AWATER: 1567602,
        INTPTLAT: '+43.0731935',
        INTPTLON: '-083.6067556',
        ELSDLEA: '',
        UNSDLEA: '20070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3864, 45.1973],
            [-85.2227, 45.2056],
            [-85.2025, 45.1178],
            [-85.3698, 45.1215],
            [-85.3864, 45.1973],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2613110',
        NAME: 'Ellsworth Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115578421,
        AWATER: 2708007,
        INTPTLAT: '+45.1668966',
        INTPTLON: '-085.2920719',
        ELSDLEA: '',
        UNSDLEA: '13110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0146, 43.1486],
            [-85.0293, 43.1702],
            [-84.9653, 43.1938],
            [-84.9251, 43.3103],
            [-84.8452, 43.3284],
            [-84.7622, 43.2341],
            [-84.759, 43.0854],
            [-84.8141, 43.076],
            [-84.8636, 43.0393],
            [-84.9224, 43.0763],
            [-84.9959, 43.1342],
            [-85.0146, 43.1486],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608130',
        NAME: 'Carson City-Crystal Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390368730,
        AWATER: 5029429,
        INTPTLAT: '+43.1804911',
        INTPTLON: '-084.8695460',
        ELSDLEA: '',
        UNSDLEA: '08130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8141, 43.076],
            [-84.759, 43.0854],
            [-84.7093, 43.065],
            [-84.6748, 43.0539],
            [-84.7095, 43.0238],
            [-84.685, 43.0163],
            [-84.6654, 43.0089],
            [-84.6698, 42.987],
            [-84.7006, 42.9507],
            [-84.7052, 42.9507],
            [-84.7151, 42.9507],
            [-84.8084, 43.002],
            [-84.8141, 43.076],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614700',
        NAME: 'Fowler Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138765888,
        AWATER: 478663,
        INTPTLAT: '+43.0205233',
        INTPTLON: '-084.7346032',
        ELSDLEA: '',
        UNSDLEA: '14700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0573, 42.8791],
            [-85.0066, 42.9364],
            [-84.8868, 42.9633],
            [-84.8868, 42.9615],
            [-84.8868, 42.9596],
            [-84.8701, 42.9006],
            [-84.7704, 42.8571],
            [-84.7788, 42.786],
            [-84.8889, 42.778],
            [-84.9162, 42.7705],
            [-85.0589, 42.8643],
            [-85.0573, 42.8791],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629120',
        NAME: 'Portland Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282588835,
        AWATER: 6532108,
        INTPTLAT: '+42.8483643',
        INTPTLON: '-084.9068380',
        ELSDLEA: '',
        UNSDLEA: '29120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7345, 44.7731],
            [-86.1799, 44.7783],
            [-86.1783, 44.6587],
            [-86.1397, 44.5928],
            [-86.1809, 44.5179],
            [-86.8469, 44.5194],
            [-86.7345, 44.7731],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614790',
        NAME: 'Frankfort-Elberta Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 99879784,
        AWATER: 1312787662,
        INTPTLAT: '+44.6636776',
        INTPTLON: '-086.2575003',
        ELSDLEA: '',
        UNSDLEA: '14790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2711, 42.2817],
            [-83.2177, 42.2772],
            [-83.2216, 42.2725],
            [-83.2319, 42.2699],
            [-83.2705, 42.2691],
            [-83.2711, 42.2817],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611610',
        NAME: 'Dearborn Heights School District 7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5657443,
        AWATER: 85,
        INTPTLAT: '+42.2759227',
        INTPTLON: '-083.2466934',
        ELSDLEA: '',
        UNSDLEA: '11610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3103, 42.2993],
            [-83.2968, 42.3119],
            [-83.2864, 42.3122],
            [-83.2711, 42.2817],
            [-83.2705, 42.2691],
            [-83.3096, 42.2827],
            [-83.3103, 42.2993],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611640',
        NAME: 'Westwood Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9546222,
        AWATER: 1610,
        INTPTLAT: '+42.2875924',
        INTPTLON: '-083.2919840',
        ELSDLEA: '',
        UNSDLEA: '11640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1609, 42.2552],
            [-83.153, 42.2644],
            [-83.1143, 42.2569],
            [-83.1272, 42.2348],
            [-83.1563, 42.2344],
            [-83.1609, 42.2552],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612930',
        NAME: 'Ecorse Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6890213,
        AWATER: 2244925,
        INTPTLAT: '+42.2495635',
        INTPTLON: '-083.1403182',
        ELSDLEA: '',
        UNSDLEA: '12930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6217, 42.9393],
            [-82.4477, 42.9372],
            [-82.4698, 42.8775],
            [-82.6397, 42.8945],
            [-82.6217, 42.9393],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623040',
        NAME: 'Marysville Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86631450,
        AWATER: 2885377,
        INTPTLAT: '+42.8972590',
        INTPTLON: '-082.5458386',
        ELSDLEA: '',
        UNSDLEA: '23040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4836, 42.7848],
            [-84.4527, 42.7696],
            [-84.4411, 42.7411],
            [-84.4621, 42.6828],
            [-84.4841, 42.6828],
            [-84.4986, 42.6829],
            [-84.4836, 42.7848],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612600',
        NAME: 'East Lansing School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36485091,
        AWATER: 219902,
        INTPTLAT: '+42.7364314',
        INTPTLON: '-084.4800042',
        ELSDLEA: '',
        UNSDLEA: '12600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3384, 42.3393],
            [-83.3122, 42.3406],
            [-83.3109, 42.3117],
            [-83.3384, 42.3393],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615540',
        NAME: 'Garden City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15355377,
        AWATER: 0,
        INTPTLAT: '+42.3245054',
        INTPTLON: '-083.3412792',
        ELSDLEA: '',
        UNSDLEA: '15540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1792, 42.1127],
            [-83.1682, 42.1554],
            [-83.1544, 42.1854],
            [-83.1315, 42.2028],
            [-83.134, 42.0843],
            [-83.1792, 42.1127],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617220',
        NAME: 'Grosse Ile Township Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23889863,
        AWATER: 24459690,
        INTPTLAT: '+42.1298962',
        INTPTLON: '-083.1507699',
        ELSDLEA: '',
        UNSDLEA: '17220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0736, 42.3986],
            [-83.0763, 42.4019],
            [-83.0425, 42.4044],
            [-83.0547, 42.3796],
            [-83.0736, 42.3986],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617520',
        NAME: 'Hamtramck Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5401832,
        AWATER: 0,
        INTPTLAT: '+42.3953866',
        INTPTLON: '-083.0559508',
        ELSDLEA: '',
        UNSDLEA: '17520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9413, 42.4502],
            [-82.941, 42.4502],
            [-82.9354, 42.4502],
            [-82.918, 42.422],
            [-82.9413, 42.4502],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617760',
        NAME: 'Harper Woods City Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4546194,
        AWATER: 0,
        INTPTLAT: '+42.4366901',
        INTPTLON: '-082.9334390',
        ELSDLEA: '',
        UNSDLEA: '17760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0763, 42.4019],
            [-83.0736, 42.3986],
            [-83.1021, 42.3883],
            [-83.1219, 42.4175],
            [-83.0894, 42.4182],
            [-83.0763, 42.4019],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618330',
        NAME: 'Highland Park City Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7694279,
        AWATER: 0,
        INTPTLAT: '+42.4052277',
        INTPTLON: '-083.0976472',
        ELSDLEA: '',
        UNSDLEA: '18330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3103, 42.2993],
            [-83.3096, 42.2827],
            [-83.319, 42.268],
            [-83.3414, 42.2676],
            [-83.3103, 42.2993],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619140',
        NAME: 'Inkster City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8491054,
        AWATER: 0,
        INTPTLAT: '+42.2860717',
        INTPTLON: '-083.3249128',
        ELSDLEA: '',
        UNSDLEA: '19140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3638, 42.792],
            [-84.3155, 42.766],
            [-84.3534, 42.7234],
            [-84.4411, 42.7411],
            [-84.4527, 42.7696],
            [-84.3638, 42.792],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617940',
        NAME: 'Haslett Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 49906323,
        AWATER: 2416139,
        INTPTLAT: '+42.7631383',
        INTPTLON: '-084.3793607',
        ELSDLEA: '',
        UNSDLEA: '17940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7675, 42.4216],
            [-84.5911, 42.4241],
            [-84.5438, 42.4194],
            [-84.5466, 42.4194],
            [-84.552, 42.4158],
            [-84.5517, 42.3494],
            [-84.6738, 42.2622],
            [-84.6812, 42.2621],
            [-84.7236, 42.3306],
            [-84.8074, 42.3342],
            [-84.806, 42.3635],
            [-84.797, 42.3734],
            [-84.7675, 42.4216],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632610',
        NAME: 'Springport Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257704907,
        AWATER: 4849827,
        INTPTLAT: '+42.3568987',
        INTPTLON: '-084.6814298',
        ELSDLEA: '',
        UNSDLEA: '32610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9795, 42.5637],
            [-84.8956, 42.6552],
            [-84.9349, 42.6699],
            [-84.9382, 42.7121],
            [-84.9237, 42.7195],
            [-84.8815, 42.7041],
            [-84.8071, 42.7048],
            [-84.7591, 42.6836],
            [-84.7492, 42.6829],
            [-84.7781, 42.6549],
            [-84.7062, 42.6691],
            [-84.7026, 42.6691],
            [-84.7013, 42.6691],
            [-84.681, 42.6549],
            [-84.686, 42.6328],
            [-84.7579, 42.5093],
            [-84.9595, 42.5314],
            [-84.9795, 42.5637],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608770',
        NAME: 'Charlotte Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339519350,
        AWATER: 844815,
        INTPTLAT: '+42.6042516',
        INTPTLON: '-084.8268716',
        ELSDLEA: '',
        UNSDLEA: '08770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3024, 43.1756],
            [-83.1901, 43.0912],
            [-83.1958, 42.9735],
            [-83.2629, 42.9094],
            [-83.3564, 42.8824],
            [-83.3867, 42.8887],
            [-83.4068, 42.9031],
            [-83.4119, 42.9102],
            [-83.4569, 42.9686],
            [-83.459, 43.0899],
            [-83.3024, 43.1756],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621180',
        NAME: 'Lapeer Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 504835107,
        AWATER: 23434917,
        INTPTLAT: '+43.0331098',
        INTPTLON: '-083.3164244',
        ELSDLEA: '',
        UNSDLEA: '21180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2216, 42.2725],
            [-83.2177, 42.2772],
            [-83.1671, 42.2896],
            [-83.1699, 42.2646],
            [-83.1946, 42.2588],
            [-83.2216, 42.2725],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623460',
        NAME: 'Melvindale-North Allen Park School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14508371,
        AWATER: 165580,
        INTPTLAT: '+42.2786701',
        INTPTLON: '-083.1909625',
        ELSDLEA: '',
        UNSDLEA: '23460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3121, 42.4421],
            [-83.2878, 42.4427],
            [-83.2759, 42.3858],
            [-83.3146, 42.3919],
            [-83.3159, 42.4205],
            [-83.3121, 42.4421],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629460',
        NAME: 'Redford Union School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14540088,
        AWATER: 4823,
        INTPTLAT: '+42.4153483',
        INTPTLON: '-083.2983531',
        ELSDLEA: '',
        UNSDLEA: '29460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2181, 42.1844],
            [-83.1796, 42.185],
            [-83.1544, 42.1854],
            [-83.1682, 42.1554],
            [-83.2172, 42.1553],
            [-83.2181, 42.1844],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629910',
        NAME: 'Riverview Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14334172,
        AWATER: 277594,
        INTPTLAT: '+42.1738498',
        INTPTLON: '-083.1916345',
        ELSDLEA: '',
        UNSDLEA: '29910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4254, 42.2493],
            [-83.3414, 42.2676],
            [-83.319, 42.268],
            [-83.3063, 42.1881],
            [-83.3255, 42.1809],
            [-83.4232, 42.1794],
            [-83.4254, 42.2493],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630120',
        NAME: 'Romulus Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 90248012,
        AWATER: 890045,
        INTPTLAT: '+42.2285999',
        INTPTLON: '-083.3614903',
        ELSDLEA: '',
        UNSDLEA: '30120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6516, 42.8354],
            [-84.5401, 42.8566],
            [-84.5429, 42.8276],
            [-84.6028, 42.7843],
            [-84.6374, 42.806],
            [-84.6516, 42.8354],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611550',
        NAME: 'DeWitt Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 47031815,
        AWATER: 615160,
        INTPTLAT: '+42.8284454',
        INTPTLON: '-084.5923022',
        ELSDLEA: '',
        UNSDLEA: '11550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7622, 43.2341],
            [-84.5778, 43.1792],
            [-84.5327, 43.2046],
            [-84.5138, 43.1613],
            [-84.5235, 43.1393],
            [-84.6352, 43.1256],
            [-84.6748, 43.0539],
            [-84.7093, 43.065],
            [-84.759, 43.0854],
            [-84.7622, 43.2341],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615420',
        NAME: 'Fulton Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 267093022,
        AWATER: 3049380,
        INTPTLAT: '+43.1566529',
        INTPTLON: '-084.6738053',
        ELSDLEA: '',
        UNSDLEA: '15420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9162, 42.7705],
            [-84.8889, 42.778],
            [-84.7788, 42.786],
            [-84.7704, 42.8571],
            [-84.7199, 42.8859],
            [-84.7199, 42.8715],
            [-84.7201, 42.8643],
            [-84.6516, 42.8354],
            [-84.6374, 42.806],
            [-84.6175, 42.7699],
            [-84.6175, 42.7619],
            [-84.6766, 42.6691],
            [-84.7013, 42.6691],
            [-84.7026, 42.6691],
            [-84.7062, 42.6691],
            [-84.7492, 42.6829],
            [-84.7591, 42.6836],
            [-84.7789, 42.7047],
            [-84.8071, 42.7048],
            [-84.8815, 42.7041],
            [-84.8963, 42.7375],
            [-84.9112, 42.7339],
            [-84.9237, 42.7195],
            [-84.9382, 42.7121],
            [-84.9162, 42.7705],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616410',
        NAME: 'Grand Ledge Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 307686927,
        AWATER: 2818366,
        INTPTLAT: '+42.7632956',
        INTPTLON: '-084.7524595',
        ELSDLEA: '',
        UNSDLEA: '16410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7201, 42.8643],
            [-84.7199, 42.8715],
            [-84.7199, 42.8859],
            [-84.6918, 42.9222],
            [-84.7006, 42.9507],
            [-84.6698, 42.987],
            [-84.6654, 43.0089],
            [-84.685, 43.0163],
            [-84.7095, 43.0238],
            [-84.6748, 43.0539],
            [-84.6352, 43.1256],
            [-84.5235, 43.1393],
            [-84.4935, 43.1286],
            [-84.4889, 43.1286],
            [-84.4837, 43.1216],
            [-84.434, 42.9178],
            [-84.4339, 42.9132],
            [-84.4339, 42.9077],
            [-84.4839, 42.8614],
            [-84.5401, 42.8566],
            [-84.6516, 42.8354],
            [-84.7201, 42.8643],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632820',
        NAME: 'St. Johns Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 527877747,
        AWATER: 1730947,
        INTPTLAT: '+42.9797211',
        INTPTLON: '-084.5734344',
        ELSDLEA: '',
        UNSDLEA: '32820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6219, 42.6885],
            [-84.6175, 42.7619],
            [-84.6175, 42.7699],
            [-84.6028, 42.7843],
            [-84.5429, 42.8276],
            [-84.4836, 42.7848],
            [-84.4986, 42.6829],
            [-84.6219, 42.6885],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621150',
        NAME: 'Lansing Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131839206,
        AWATER: 2221607,
        INTPTLAT: '+42.7390050',
        INTPTLON: '-084.5521996',
        ELSDLEA: '',
        UNSDLEA: '21150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1426, 42.516],
            [-84.9795, 42.5637],
            [-84.9595, 42.5314],
            [-85.0054, 42.3771],
            [-85.0485, 42.3558],
            [-85.0535, 42.4216],
            [-85.1654, 42.4497],
            [-85.1426, 42.516],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604650',
        NAME: 'Bellevue Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214716430,
        AWATER: 3158354,
        INTPTLAT: '+42.4604341',
        INTPTLON: '-085.0547432',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3276, 44.8508],
            [-85.1527, 44.8589],
            [-85.0102, 44.8006],
            [-84.9689, 44.8589],
            [-84.8473, 44.858],
            [-84.8509, 44.5111],
            [-85.1547, 44.5111],
            [-85.2119, 44.5623],
            [-85.2119, 44.6848],
            [-85.3334, 44.6851],
            [-85.3276, 44.8508],
          ],
          [
            [-85.094, 44.6848],
            [-84.9721, 44.6842],
            [-84.9712, 44.7274],
            [-85.0888, 44.7282],
            [-85.094, 44.6848],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620050',
        NAME: 'Kalkaska Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1129402357,
        AWATER: 23919727,
        INTPTLAT: '+44.6061406',
        INTPTLON: '-084.9634416',
        ELSDLEA: '',
        UNSDLEA: '20050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2719, 43.1184],
            [-86.2059, 43.1469],
            [-86.2053, 43.1178],
            [-86.2135, 43.0778],
            [-86.0265, 43.0239],
            [-86.0242, 42.9719],
            [-86.1145, 42.943],
            [-86.2153, 42.899],
            [-86.2719, 43.1184],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616380',
        NAME: 'Grand Haven Area Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222408712,
        AWATER: 9706499,
        INTPTLAT: '+43.0117378',
        INTPTLON: '-086.1618240',
        ELSDLEA: '',
        UNSDLEA: '16380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8636, 42.9464],
            [-85.7822, 42.9117],
            [-85.807, 42.8709],
            [-85.8636, 42.9464],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619830',
        NAME: 'Jenison Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36656191,
        AWATER: 2282194,
        INTPTLAT: '+42.9092107',
        INTPTLON: '-085.8194916',
        ELSDLEA: '',
        UNSDLEA: '19830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6812, 42.2621],
            [-84.6738, 42.2622],
            [-84.6123, 42.2109],
            [-84.4819, 42.1998],
            [-84.4805, 42.1601],
            [-84.632, 42.1149],
            [-84.6513, 42.0781],
            [-84.6756, 42.0718],
            [-84.6896, 42.1069],
            [-84.712, 42.1672],
            [-84.713, 42.1846],
            [-84.7133, 42.1882],
            [-84.6812, 42.2621],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610650',
        NAME: 'Concord Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 177814562,
        AWATER: 3299197,
        INTPTLAT: '+42.1656661',
        INTPTLON: '-084.6260137',
        ELSDLEA: '',
        UNSDLEA: '10650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3648, 42.2369],
            [-84.2845, 42.2543],
            [-84.2491, 42.2004],
            [-84.3647, 42.2116],
            [-84.3648, 42.2369],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623790',
        NAME: 'Michigan Center School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37955869,
        AWATER: 3268943,
        INTPTLAT: '+42.2293241',
        INTPTLON: '-084.3015944',
        ELSDLEA: '',
        UNSDLEA: '23790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6738, 42.2622],
            [-84.5517, 42.3494],
            [-84.5226, 42.2691],
            [-84.4426, 42.269],
            [-84.4819, 42.1998],
            [-84.6123, 42.2109],
            [-84.6738, 42.2622],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635940',
        NAME: 'Western School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 173871820,
        AWATER: 1226234,
        INTPTLAT: '+42.2641997',
        INTPTLON: '-084.5672484',
        ELSDLEA: '',
        UNSDLEA: '35940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.179, 42.2985],
            [-85.2281, 42.2465],
            [-85.2378, 42.2466],
            [-85.179, 42.2985],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620850',
        NAME: 'Lakeview School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39342566,
        AWATER: 1557172,
        INTPTLAT: '+42.2808862',
        INTPTLON: '-085.2129035',
        ELSDLEA: '',
        UNSDLEA: '20850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3146, 42.3919],
            [-83.2759, 42.3858],
            [-83.266, 42.357],
            [-83.2748, 42.3569],
            [-83.3128, 42.3552],
            [-83.3146, 42.3919],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632280',
        NAME: 'South Redford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14073842,
        AWATER: 4066,
        INTPTLAT: '+42.3733604',
        INTPTLON: '-083.2927594',
        ELSDLEA: '',
        UNSDLEA: '32280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2299, 42.2286],
            [-83.2006, 42.2282],
            [-83.1812, 42.2211],
            [-83.1796, 42.185],
            [-83.2181, 42.1844],
            [-83.2281, 42.1843],
            [-83.2299, 42.2286],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632340',
        NAME: 'Southgate Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 18703427,
        AWATER: 12035,
        INTPTLAT: '+42.2049746',
        INTPTLON: '-083.2065107',
        ELSDLEA: '',
        UNSDLEA: '32340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.645, 44.3663],
            [-83.381, 44.337],
            [-82.2899, 44.3387],
            [-82.2499, 44.1588],
            [-83.1853, 44.1684],
            [-83.2691, 44.1154],
            [-83.5862, 44.1145],
            [-83.6161, 44.1288],
            [-83.7313, 44.1619],
            [-83.6832, 44.2492],
            [-83.7636, 44.2844],
            [-83.645, 44.3663],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633510',
        NAME: 'Tawas Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 434128135,
        AWATER: 2024280925,
        INTPTLAT: '+44.3358920',
        INTPTLON: '-083.3460943',
        ELSDLEA: '',
        UNSDLEA: '33510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8367, 44.024],
            [-83.8303, 44.0246],
            [-83.8068, 44.0842],
            [-83.696, 44.0887],
            [-83.6161, 44.1288],
            [-83.5862, 44.1145],
            [-83.2691, 44.1154],
            [-83.4838, 43.9767],
            [-83.8066, 43.9781],
            [-83.8367, 44.0234],
            [-83.8367, 44.024],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603600',
        NAME: 'Au Gres-Sims School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176765588,
        AWATER: 341779426,
        INTPTLAT: '+44.0511733',
        INTPTLON: '-083.5816052',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.9675, 44.1094],
              [-83.9675, 44.113],
              [-83.9674, 44.1274],
              [-83.8682, 44.1619],
              [-83.7313, 44.1619],
              [-83.6161, 44.1288],
              [-83.696, 44.0887],
              [-83.8068, 44.0842],
              [-83.8303, 44.0246],
              [-83.8367, 44.024],
              [-83.8367, 44.0234],
              [-83.9676, 44.1058],
              [-83.9675, 44.1094],
            ],
          ],
          [
            [
              [-83.9675, 44.113],
              [-83.9675, 44.1094],
              [-83.9676, 44.1058],
              [-83.9675, 44.113],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603060',
        NAME: 'Arenac Eastern School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 224094627,
        AWATER: 4215595,
        INTPTLAT: '+44.1089733',
        INTPTLON: '-083.8018794',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0065, 42.4999],
            [-83.0063, 42.485],
            [-83.0347, 42.4531],
            [-83.0666, 42.4836],
            [-83.0065, 42.4999],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608580',
        NAME: 'Center Line Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13190662,
        AWATER: 0,
        INTPTLAT: '+42.4819528',
        INTPTLON: '-083.0334329',
        ELSDLEA: '',
        UNSDLEA: '08580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9208, 42.5688],
            [-82.911, 42.5694],
            [-82.8857, 42.5661],
            [-82.8847, 42.5402],
            [-82.9043, 42.5398],
            [-82.9251, 42.5394],
            [-82.9208, 42.5688],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610080',
        NAME: 'Clintondale Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9979006,
        AWATER: 80038,
        INTPTLAT: '+42.5542261',
        INTPTLON: '-082.9027213',
        ELSDLEA: '',
        UNSDLEA: '10080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1082, 42.157],
            [-85.985, 42.1642],
            [-85.9167, 42.1649],
            [-85.8772, 42.0839],
            [-85.9916, 42.0167],
            [-86.0308, 42.0558],
            [-86.1081, 42.1283],
            [-86.1082, 42.157],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611670',
        NAME: 'Decatur Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206026572,
        AWATER: 4551230,
        INTPTLAT: '+42.1021021',
        INTPTLON: '-085.9991228',
        ELSDLEA: '',
        UNSDLEA: '11670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1563, 42.2344],
            [-83.1272, 42.2348],
            [-83.1315, 42.2028],
            [-83.1544, 42.1854],
            [-83.1796, 42.185],
            [-83.1812, 42.2211],
            [-83.1563, 42.2344],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636540',
        NAME: 'Wyandotte City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13708642,
        AWATER: 4414263,
        INTPTLAT: '+42.2106735',
        INTPTLON: '-083.1572083',
        ELSDLEA: '',
        UNSDLEA: '36540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5865, 42.1574],
            [-85.4716, 42.2013],
            [-85.3241, 42.1581],
            [-85.3194, 42.1151],
            [-85.3082, 42.0715],
            [-85.4061, 42.0825],
            [-85.5776, 42.0349],
            [-85.6023, 42.0702],
            [-85.5865, 42.1574],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634950',
        NAME: 'Vicksburg Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 268090452,
        AWATER: 11793453,
        INTPTLAT: '+42.1211946',
        INTPTLON: '-085.4656947',
        ELSDLEA: '',
        UNSDLEA: '34950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3946, 41.8973],
            [-85.2924, 41.8975],
            [-85.2921, 41.8181],
            [-85.4194, 41.8779],
            [-85.3946, 41.8973],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607410',
        NAME: 'Burr Oak Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65528549,
        AWATER: 1267669,
        INTPTLAT: '+41.8659481',
        INTPTLON: '-085.3388795',
        ELSDLEA: '',
        UNSDLEA: '07410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8211, 44.2513],
            [-85.6653, 44.3102],
            [-85.6103, 44.3178],
            [-85.58, 44.3097],
            [-85.4602, 44.339],
            [-85.3371, 44.3393],
            [-85.3362, 44.2813],
            [-85.3348, 44.1651],
            [-85.3557, 44.1591],
            [-85.3775, 44.151],
            [-85.4055, 44.1458],
            [-85.4668, 44.1837],
            [-85.7428, 44.1471],
            [-85.8212, 44.1642],
            [-85.8211, 44.2513],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607590',
        NAME: 'Cadillac Area Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 638490575,
        AWATER: 17990417,
        INTPTLAT: '+44.2440320',
        INTPTLON: '-085.5653238',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1832, 44.2737],
            [-86.1019, 44.2995],
            [-86.1111, 44.3727],
            [-86.0601, 44.4557],
            [-85.9395, 44.4262],
            [-85.8209, 44.3525],
            [-85.8211, 44.2513],
            [-85.8212, 44.1642],
            [-85.9404, 44.1649],
            [-86.0405, 44.0912],
            [-86.0419, 44.1484],
            [-86.143, 44.1702],
            [-86.1832, 44.2737],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620010',
        NAME: 'Kaleva Norman Dickson School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 748739012,
        AWATER: 12948744,
        INTPTLAT: '+44.2747940',
        INTPTLON: '-085.9750087',
        ELSDLEA: '',
        UNSDLEA: '20010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-86.0387, 43.9025],
              [-85.9981, 43.8151],
              [-86.1583, 43.8161],
              [-86.1379, 43.9603],
              [-86.0387, 43.9025],
            ],
          ],
          [
            [
              [-86.9993, 44.1567],
              [-86.3303, 44.1529],
              [-86.2795, 44.1756],
              [-86.2342, 43.933],
              [-86.2584, 43.8175],
              [-86.2784, 43.7738],
              [-86.3786, 43.8263],
              [-86.4001, 44.0816],
              [-86.5149, 44.0483],
              [-86.4663, 43.9768],
              [-86.431, 43.8194],
              [-87.0931, 43.8201],
              [-86.9993, 44.1567],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623130',
        NAME: 'Mason County Central Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 586951603,
        AWATER: 1762785039,
        INTPTLAT: '+44.0819018',
        INTPTLON: '-086.4495977',
        ELSDLEA: '',
        UNSDLEA: '23130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6819, 43.988],
            [-85.6648, 43.959],
            [-85.3638, 43.9584],
            [-85.3252, 43.9153],
            [-85.3848, 43.7993],
            [-85.4699, 43.7953],
            [-85.6033, 43.7286],
            [-85.6815, 43.8155],
            [-85.6819, 43.988],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629490',
        NAME: 'Reed City Area Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 555126300,
        AWATER: 4516706,
        INTPTLAT: '+43.8618661',
        INTPTLON: '-085.5182656',
        ELSDLEA: '',
        UNSDLEA: '29490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5776, 42.0349],
            [-85.4061, 42.0825],
            [-85.4009, 42.0137],
            [-85.4342, 41.9452],
            [-85.4879, 41.9408],
            [-85.5619, 41.9694],
            [-85.5776, 42.0349],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623520',
        NAME: 'Mendon Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140090531,
        AWATER: 4445299,
        INTPTLAT: '+42.0156463',
        INTPTLON: '-085.4736736',
        ELSDLEA: '',
        UNSDLEA: '23520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.6045, 41.8708],
              [-85.6044, 41.8677],
              [-85.6086, 41.8677],
              [-85.6045, 41.8708],
            ],
          ],
          [
            [
              [-85.5619, 41.9694],
              [-85.4879, 41.9408],
              [-85.4828, 41.9263],
              [-85.429, 41.8757],
              [-85.5263, 41.8189],
              [-85.5652, 41.8388],
              [-85.6044, 41.8677],
              [-85.5996, 41.8677],
              [-85.5998, 41.875],
              [-85.6046, 41.8749],
              [-85.5619, 41.9694],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608670',
        NAME: 'Centreville Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 155310574,
        AWATER: 6535852,
        INTPTLAT: '+41.9057312',
        INTPTLON: '-085.5323498',
        ELSDLEA: '',
        UNSDLEA: '08670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2799, 43.0145],
            [-84.2656, 43.0786],
            [-84.231, 43.1155],
            [-84.0382, 43.1017],
            [-84.0422, 43.093],
            [-84.0501, 43.0903],
            [-84.1285, 43.0719],
            [-84.1631, 42.923],
            [-84.1632, 42.9251],
            [-84.1632, 42.9274],
            [-84.1698, 42.9371],
            [-84.1716, 42.937],
            [-84.1735, 42.9398],
            [-84.1779, 42.9428],
            [-84.2022, 42.9141],
            [-84.2772, 42.8985],
            [-84.3132, 42.9234],
            [-84.3093, 42.9405],
            [-84.3094, 42.9437],
            [-84.2799, 43.0145],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627210',
        NAME: 'Owosso Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270657770,
        AWATER: 4073819,
        INTPTLAT: '+43.0232855',
        INTPTLON: '-084.2126228',
        ELSDLEA: '',
        UNSDLEA: '27210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2439, 43.9765],
            [-83.2575, 43.9279],
            [-83.3288, 43.9006],
            [-83.2439, 43.9765],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608280',
        NAME: 'Caseville Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24807102,
        AWATER: 738620,
        INTPTLAT: '+43.9441484',
        INTPTLON: '-083.2513448',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1631, 42.923],
            [-84.1285, 43.0719],
            [-84.0501, 43.0903],
            [-84.0422, 43.093],
            [-84.0382, 43.1017],
            [-84.0381, 43.1017],
            [-83.9432, 43.0589],
            [-83.9287, 43.0804],
            [-83.9284, 43.0247],
            [-83.9285, 43.0093],
            [-84.1084, 42.8944],
            [-84.1528, 42.8901],
            [-84.1529, 42.894],
            [-84.1631, 42.923],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610860',
        NAME: 'Corunna Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259912177,
        AWATER: 1550193,
        INTPTLAT: '+42.9976578',
        INTPTLON: '-084.0566006',
        ELSDLEA: '',
        UNSDLEA: '10860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5263, 41.8189],
            [-85.429, 41.8757],
            [-85.4194, 41.8779],
            [-85.2921, 41.8181],
            [-85.2922, 41.7599],
            [-85.4866, 41.7593],
            [-85.5258, 41.7594],
            [-85.5263, 41.8189],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633090',
        NAME: 'Sturgis Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 171668273,
        AWATER: 4897887,
        INTPTLAT: '+41.8028372',
        INTPTLON: '-085.4145721',
        ELSDLEA: '',
        UNSDLEA: '33090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4942, 42.2855],
            [-83.4292, 42.3313],
            [-83.3384, 42.3393],
            [-83.3109, 42.3117],
            [-83.2968, 42.3119],
            [-83.3103, 42.2993],
            [-83.3414, 42.2676],
            [-83.4254, 42.2493],
            [-83.4942, 42.2855],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600015',
        NAME: 'Wayne-Westland Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72346036,
        AWATER: 59004,
        INTPTLAT: '+42.2917718',
        INTPTLON: '-083.3996200',
        ELSDLEA: '',
        UNSDLEA: '00015',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1165, 46.3912],
            [-86.8663, 46.3906],
            [-86.8653, 46.2457],
            [-86.765, 46.2457],
            [-86.7406, 46.1587],
            [-86.7823, 46.1588],
            [-86.9908, 46.1589],
            [-87.1161, 46.1589],
            [-87.1162, 46.2459],
            [-87.1165, 46.3912],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600019',
        NAME: 'Superior Central Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 587187057,
        AWATER: 6577195,
        INTPTLAT: '+46.2689777',
        INTPTLON: '-086.9583586',
        ELSDLEA: '',
        UNSDLEA: '00019',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3615, 47.5185],
            [-85.8549, 47.3055],
            [-85.8649, 46.5048],
            [-86.2401, 46.5053],
            [-86.365, 46.5059],
            [-86.3615, 47.5185],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607470',
        NAME: 'Burt Township School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 596948249,
        AWATER: 3268575965,
        INTPTLAT: '+46.6618448',
        INTPTLON: '-086.1716963',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9868, 42.4783],
            [-82.9672, 42.4802],
            [-82.9175, 42.4804],
            [-82.917, 42.473],
            [-82.941, 42.4502],
            [-82.9413, 42.4502],
            [-82.9857, 42.4493],
            [-82.9868, 42.4783],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612450',
        NAME: 'East Detroit Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15850307,
        AWATER: 5331,
        INTPTLAT: '+42.4660175',
        INTPTLON: '-082.9553717',
        ELSDLEA: '',
        UNSDLEA: '12450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0848, 42.4838],
            [-83.0666, 42.4836],
            [-83.0347, 42.4531],
            [-83.0345, 42.4479],
            [-83.0834, 42.4472],
            [-83.0845, 42.4763],
            [-83.0848, 42.4838],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614460',
        NAME: 'Fitzgerald Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12000377,
        AWATER: 0,
        INTPTLAT: '+42.4621485',
        INTPTLON: '-083.0638435',
        ELSDLEA: '',
        UNSDLEA: '14460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4232, 42.1794],
            [-83.3255, 42.1809],
            [-83.3043, 42.116],
            [-83.3301, 42.0941],
            [-83.4511, 42.121],
            [-83.4232, 42.1794],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618930',
        NAME: 'Huron School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100503406,
        AWATER: 1010839,
        INTPTLAT: '+42.1332568',
        INTPTLON: '-083.3786942',
        ELSDLEA: '',
        UNSDLEA: '18930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.669, 41.8221],
            [-83.65, 41.8219],
            [-83.5311, 41.8163],
            [-83.5345, 41.7307],
            [-83.6457, 41.7275],
            [-83.669, 41.8221],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604470',
        NAME: 'Bedford Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102365531,
        AWATER: 596590,
        INTPTLAT: '+41.7729858',
        INTPTLON: '-083.5965196',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8682, 45.8198],
            [-85.5701, 45.8832],
            [-85.2396, 45.8707],
            [-85.2214, 45.7828],
            [-85.3076, 45.625],
            [-85.2832, 45.5502],
            [-85.1023, 45.3974],
            [-85.096, 45.3664],
            [-85.1008, 45.3666],
            [-85.2511, 45.3394],
            [-85.3714, 45.2718],
            [-85.3887, 45.2076],
            [-85.4589, 45.2066],
            [-85.476, 45.264],
            [-85.6251, 45.4546],
            [-85.7501, 45.5487],
            [-85.8558, 45.5784],
            [-85.8682, 45.8198],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2604350',
        NAME: 'Beaver Island Community School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188271389,
        AWATER: 2430765263,
        INTPTLAT: '+45.6006736',
        INTPTLON: '-085.5109990',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2393, 46.0274],
            [-84.1432, 46.0275],
            [-84.0713, 46.1845],
            [-83.9554, 46.0572],
            [-83.8262, 46.1193],
            [-83.5715, 46.106],
            [-83.4336, 45.9989],
            [-83.6369, 45.7739],
            [-84.1231, 45.7777],
            [-84.1142, 45.9852],
            [-84.2393, 46.0274],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611970',
        NAME: 'DeTour Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 588297429,
        AWATER: 1209454953,
        INTPTLAT: '+45.9563283',
        INTPTLON: '-083.8350977',
        ELSDLEA: '',
        UNSDLEA: '11970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1135, 46.2747],
            [-84.9893, 46.332],
            [-84.5097, 46.332],
            [-84.4348, 46.3602],
            [-84.3842, 46.274],
            [-84.4884, 46.2449],
            [-84.4883, 46.1583],
            [-84.6128, 46.1147],
            [-84.6761, 46.1579],
            [-84.779, 46.1132],
            [-84.9255, 46.1133],
            [-84.9259, 46.1584],
            [-85.1124, 46.1582],
            [-85.1131, 46.2457],
            [-85.1135, 46.2747],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630360',
        NAME: 'Rudyard Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1025255910,
        AWATER: 14261081,
        INTPTLAT: '+46.2241958',
        INTPTLON: '-084.7867885',
        ELSDLEA: '',
        UNSDLEA: '30360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.875, 43.6963],
            [-83.8484, 43.6586],
            [-83.8574, 43.6134],
            [-83.875, 43.6963],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603900',
        NAME: 'Bangor Township Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36544528,
        AWATER: 18369467,
        INTPTLAT: '+43.6446843',
        INTPTLON: '-083.8955870',
        ELSDLEA: '',
        UNSDLEA: '03900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9167, 42.1649],
            [-85.8523, 42.2005],
            [-85.754, 42.1167],
            [-85.7634, 42.0983],
            [-85.8772, 42.0839],
            [-85.9167, 42.1649],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621240',
        NAME: 'Lawton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108939492,
        AWATER: 4062413,
        INTPTLAT: '+42.1382734',
        INTPTLON: '-085.8421079',
        ELSDLEA: '',
        UNSDLEA: '21240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-83.3563, 43.7455],
              [-83.337, 43.7423],
              [-83.3561, 43.7381],
              [-83.3563, 43.7455],
            ],
          ],
          [
            [
              [-83.3288, 43.9006],
              [-83.2575, 43.9279],
              [-83.2439, 43.9765],
              [-83.1262, 43.9978],
              [-83.1342, 43.935],
              [-83.1241, 43.9353],
              [-83.1, 43.9429],
              [-83.0842, 43.9289],
              [-83.079, 43.922],
              [-83.0933, 43.9105],
              [-83.0732, 43.8934],
              [-83.095, 43.7784],
              [-83.1287, 43.7185],
              [-83.1793, 43.7313],
              [-83.1895, 43.7454],
              [-83.337, 43.7423],
              [-83.3171, 43.7497],
              [-83.3566, 43.7562],
              [-83.3934, 43.8284],
              [-83.3288, 43.9006],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2613090',
        NAME: 'Elkton-Pigeon-Bay Port Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 510214718,
        AWATER: 1464296,
        INTPTLAT: '+43.8389935',
        INTPTLON: '-083.2110774',
        ELSDLEA: '',
        UNSDLEA: '13090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1262, 43.9978],
            [-82.9648, 44.0684],
            [-82.8489, 44.0394],
            [-82.8538, 44.0198],
            [-82.8204, 43.9408],
            [-82.8289, 43.915],
            [-82.894, 43.8914],
            [-82.9444, 43.8973],
            [-82.9844, 43.8999],
            [-82.9975, 43.8957],
            [-83.0531, 43.9041],
            [-83.0629, 43.8937],
            [-83.0732, 43.8934],
            [-83.0933, 43.9105],
            [-83.079, 43.922],
            [-83.0842, 43.9289],
            [-83.1, 43.9429],
            [-83.1241, 43.9353],
            [-83.1342, 43.935],
            [-83.1262, 43.9978],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625780',
        NAME: 'North Huron School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339884627,
        AWATER: 2191064,
        INTPTLAT: '+43.9758238',
        INTPTLON: '-082.9787818',
        ELSDLEA: '',
        UNSDLEA: '25780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.394, 44.6418],
            [-85.4144, 44.5486],
            [-85.4566, 44.5123],
            [-85.5763, 44.4838],
            [-85.7375, 44.5998],
            [-85.5749, 44.6426],
            [-85.394, 44.6418],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620380',
        NAME: 'Kingsley Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 311154382,
        AWATER: 8442965,
        INTPTLAT: '+44.5744783',
        INTPTLON: '-085.5517899',
        ELSDLEA: '',
        UNSDLEA: '20380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.5777, 44.4532],
            [-85.5763, 44.4838],
            [-85.4566, 44.5123],
            [-85.3048, 44.5122],
            [-85.2132, 44.5114],
            [-85.2768, 44.4832],
            [-85.2975, 44.397],
            [-85.3371, 44.3393],
            [-85.4602, 44.339],
            [-85.5777, 44.4532],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622500',
        NAME: 'Manton Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 376146217,
        AWATER: 1387927,
        INTPTLAT: '+44.4330806',
        INTPTLON: '-085.3949857',
        ELSDLEA: '',
        UNSDLEA: '22500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8176, 44.5477],
            [-85.7375, 44.5998],
            [-85.5763, 44.4838],
            [-85.5777, 44.4532],
            [-85.6915, 44.4391],
            [-85.7368, 44.4862],
            [-85.7974, 44.4978],
            [-85.8176, 44.5477],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607170',
        NAME: 'Buckley Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183398199,
        AWATER: 857068,
        INTPTLAT: '+44.5085816',
        INTPTLON: '-085.6911608',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.681, 42.6549],
            [-84.6423, 42.6713],
            [-84.6219, 42.6885],
            [-84.4986, 42.6829],
            [-84.4841, 42.6828],
            [-84.4837, 42.6257],
            [-84.5983, 42.5968],
            [-84.686, 42.6328],
            [-84.681, 42.6549],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618480',
        NAME: 'Holt Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82206652,
        AWATER: 1294139,
        INTPTLAT: '+42.6463056',
        INTPTLON: '-084.5757454',
        ELSDLEA: '',
        UNSDLEA: '18480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3644, 42.5055],
            [-84.3446, 42.6296],
            [-84.2547, 42.6301],
            [-84.1786, 42.584],
            [-84.1681, 42.569],
            [-84.1801, 42.5111],
            [-84.2855, 42.4754],
            [-84.3644, 42.5055],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611400',
        NAME: 'Dansville Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227244312,
        AWATER: 406972,
        INTPTLAT: '+42.5592193',
        INTPTLON: '-084.2762842',
        ELSDLEA: '',
        UNSDLEA: '11400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5983, 42.5968],
            [-84.4837, 42.6257],
            [-84.4841, 42.6828],
            [-84.4621, 42.6828],
            [-84.3982, 42.6616],
            [-84.3446, 42.6296],
            [-84.3644, 42.5055],
            [-84.5306, 42.4902],
            [-84.5983, 42.5968],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623070',
        NAME: 'Mason Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 279061500,
        AWATER: 1017583,
        INTPTLAT: '+42.5806399',
        INTPTLON: '-084.4618860',
        ELSDLEA: '',
        UNSDLEA: '23070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2236, 42.2434],
            [-86.1086, 42.2439],
            [-86.1082, 42.157],
            [-86.1081, 42.1283],
            [-86.2228, 42.1211],
            [-86.2236, 42.2434],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617880',
        NAME: 'Hartford Public School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141377099,
        AWATER: 2436896,
        INTPTLAT: '+42.1819476',
        INTPTLON: '-086.1629444',
        ELSDLEA: '',
        UNSDLEA: '17880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3006, 42.109],
            [-86.2624, 42.2364],
            [-86.2334, 42.2502],
            [-86.2236, 42.2434],
            [-86.2228, 42.1211],
            [-86.2229, 42.0715],
            [-86.3006, 42.072],
            [-86.3006, 42.109],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635460',
        NAME: 'Watervliet School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93943961,
        AWATER: 3660203,
        INTPTLAT: '+42.1479025',
        INTPTLON: '-086.2550244',
        ELSDLEA: '',
        UNSDLEA: '35460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2334, 42.2502],
            [-86.2241, 42.3315],
            [-86.2213, 42.3389],
            [-86.1102, 42.3968],
            [-86.0333, 42.3791],
            [-85.9657, 42.286],
            [-85.9856, 42.2841],
            [-86.1086, 42.2439],
            [-86.2236, 42.2434],
            [-86.2334, 42.2502],
          ],
          [
            [-86.2062, 42.3054],
            [-86.1771, 42.2871],
            [-86.1776, 42.3318],
            [-86.2023, 42.3318],
            [-86.2062, 42.3054],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603870',
        NAME: 'Bangor Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218703508,
        AWATER: 4231015,
        INTPTLAT: '+42.3131136',
        INTPTLON: '-086.1020043',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-82.7988, 43.8869],
              [-82.7936, 43.887],
              [-82.7988, 43.8797],
              [-82.7988, 43.8869],
            ],
          ],
          [
            [
              [-82.8538, 44.0198],
              [-82.8489, 44.0394],
              [-82.7341, 43.9836],
              [-82.6849, 43.9046],
              [-82.7936, 43.887],
              [-82.8037, 43.8977],
              [-82.8135, 43.9086],
              [-82.8289, 43.915],
              [-82.8204, 43.9408],
              [-82.8538, 44.0198],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628810',
        NAME: 'Port Hope Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 131743907,
        AWATER: 226295,
        INTPTLAT: '+43.9541885',
        INTPTLON: '-082.7759077',
        ELSDLEA: '',
        UNSDLEA: '28810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.166, 43.9335],
            [-83.8941, 43.9033],
            [-83.9145, 43.7698],
            [-83.875, 43.7239],
            [-84.167, 43.7404],
            [-84.1673, 43.8259],
            [-84.2882, 43.8276],
            [-84.2871, 43.9129],
            [-84.166, 43.9335],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628170',
        NAME: 'Pinconning Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 497372729,
        AWATER: 38999789,
        INTPTLAT: '+43.8325727',
        INTPTLON: '-084.0754310',
        ELSDLEA: '',
        UNSDLEA: '28170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.3266, 45.6231],
              [-87.2417, 45.6232],
              [-87.2639, 45.8117],
              [-87.0305, 45.8298],
              [-87.0538, 45.6982],
              [-87.1602, 45.6548],
              [-87.2585, 45.5501],
              [-87.3275, 45.5512],
              [-87.3266, 45.6231],
            ],
          ],
          [
            [
              [-87.3678, 45.9853],
              [-87.3052, 46.0239],
              [-87.1376, 45.8982],
              [-87.3256, 45.8697],
              [-87.3678, 45.9853],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2613500',
        NAME: 'Escanaba Area Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 467933842,
        AWATER: 51790483,
        INTPTLAT: '+45.7797541',
        INTPTLON: '-087.2121808',
        ELSDLEA: '',
        UNSDLEA: '13500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7579, 42.5093],
            [-84.686, 42.6328],
            [-84.5983, 42.5968],
            [-84.5306, 42.4902],
            [-84.5911, 42.4241],
            [-84.7675, 42.4216],
            [-84.7579, 42.5093],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612690',
        NAME: 'Eaton Rapids Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 307428334,
        AWATER: 2816512,
        INTPTLAT: '+42.5157665',
        INTPTLON: '-084.6579405',
        ELSDLEA: '',
        UNSDLEA: '12690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3534, 42.7234],
            [-84.3155, 42.766],
            [-84.2452, 42.7296],
            [-84.2547, 42.6301],
            [-84.3446, 42.6296],
            [-84.3982, 42.6616],
            [-84.3534, 42.7234],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636420',
        NAME: 'Williamston Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152379157,
        AWATER: 1021225,
        INTPTLAT: '+42.6882106',
        INTPTLON: '-084.2973412',
        ELSDLEA: '',
        UNSDLEA: '36420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.0547, 44.3226],
              [-85.0498, 44.3225],
              [-85.0547, 44.3189],
              [-85.0545, 44.3081],
              [-85.0595, 44.3082],
              [-85.0598, 44.3227],
              [-85.0547, 44.3226],
            ],
          ],
          [
            [
              [-85.3371, 44.3393],
              [-85.2975, 44.397],
              [-85.2768, 44.4832],
              [-85.2132, 44.5114],
              [-85.1547, 44.5111],
              [-84.8509, 44.5111],
              [-84.8558, 44.422],
              [-84.9944, 44.3934],
              [-84.9935, 44.2927],
              [-85.0447, 44.3225],
              [-85.0549, 44.3261],
              [-85.0598, 44.3227],
              [-85.2403, 44.2631],
              [-85.2453, 44.2632],
              [-85.2553, 44.256],
              [-85.3362, 44.2813],
              [-85.3371, 44.3393],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620610',
        NAME: 'Lake City Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 672976905,
        AWATER: 16533377,
        INTPTLAT: '+44.4062461',
        INTPTLON: '-085.1063198',
        ELSDLEA: '',
        UNSDLEA: '20610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-82.882, 42.5002],
              [-82.8609, 42.5403],
              [-82.7818, 42.5719],
              [-82.8082, 42.6462],
              [-82.7083, 42.6842],
              [-82.7232, 42.4778],
              [-82.7499, 42.4511],
              [-82.8699, 42.4512],
              [-82.8739, 42.467],
              [-82.882, 42.5002],
            ],
          ],
          [
            [
              [-83.4129, 41.8256],
              [-83.3335, 41.9065],
              [-83.1859, 42.0294],
              [-83.144, 42.0279],
              [-83.1125, 41.9594],
              [-83.4216, 41.7337],
              [-83.4129, 41.8256],
            ],
          ],
          [
            [
              [-83.7085, 43.8339],
              [-83.4838, 43.9767],
              [-83.2691, 44.1154],
              [-83.1853, 44.1684],
              [-82.2499, 44.1588],
              [-82.123, 43.5908],
              [-82.4178, 43.004],
              [-82.4938, 43.1409],
              [-82.5338, 43.3802],
              [-82.5557, 43.4737],
              [-82.603, 43.6395],
              [-82.6146, 43.7782],
              [-82.6849, 43.9046],
              [-82.7341, 43.9836],
              [-82.8489, 44.0394],
              [-82.9648, 44.0684],
              [-83.1262, 43.9978],
              [-83.2439, 43.9765],
              [-83.3288, 43.9006],
              [-83.3934, 43.8284],
              [-83.4697, 43.7236],
              [-83.5398, 43.7086],
              [-83.6635, 43.5943],
              [-83.6995, 43.589],
              [-83.6995, 43.5912],
              [-83.6994, 43.5947],
              [-83.6999, 43.6118],
              [-83.7085, 43.8339],
            ],
          ],
          [
            [
              [-84.2011, 45.7523],
              [-84.1231, 45.7777],
              [-83.6369, 45.7739],
              [-83.484, 45.7719],
              [-82.5206, 45.3359],
              [-82.4906, 45.2085],
              [-83.3885, 45.2071],
              [-83.3814, 45.2695],
              [-83.5203, 45.3475],
              [-83.7617, 45.41],
              [-83.9077, 45.4849],
              [-84.0095, 45.4946],
              [-84.1122, 45.5074],
              [-84.2043, 45.6262],
              [-84.2011, 45.7523],
            ],
          ],
          [
            [
              [-85.2396, 45.8707],
              [-85.1251, 45.8664],
              [-84.7287, 45.8101],
              [-84.6735, 45.8006],
              [-84.7938, 45.7497],
              [-84.8787, 45.7562],
              [-84.9758, 45.6833],
              [-85.119, 45.5748],
              [-85.0416, 45.4365],
              [-84.9192, 45.4201],
              [-84.9924, 45.3681],
              [-85.096, 45.3664],
              [-85.1023, 45.3974],
              [-85.2832, 45.5502],
              [-85.3076, 45.625],
              [-85.2214, 45.7828],
              [-85.2396, 45.8707],
            ],
          ],
          [
            [
              [-85.4589, 45.2066],
              [-85.3887, 45.2076],
              [-85.3864, 45.1973],
              [-85.3698, 45.1215],
              [-85.3712, 45.0627],
              [-85.4431, 44.8598],
              [-85.4329, 45.0215],
              [-85.4807, 45.1203],
              [-85.4589, 45.2066],
            ],
          ],
          [
            [
              [-87.0199, 42.4935],
              [-87.1472, 43.3799],
              [-87.1403, 43.4714],
              [-86.4613, 43.4723],
              [-86.4271, 43.3752],
              [-86.3841, 43.2985],
              [-86.3437, 43.2357],
              [-86.3211, 43.2034],
              [-86.2719, 43.1184],
              [-86.2153, 42.899],
              [-86.2102, 42.7713],
              [-86.2071, 42.7468],
              [-86.2279, 42.6146],
              [-86.2411, 42.5347],
              [-86.2454, 42.5059],
              [-86.3036, 42.3453],
              [-86.3646, 42.2431],
              [-86.4162, 42.1836],
              [-86.4774, 42.1245],
              [-86.521, 42.0536],
              [-86.5628, 41.9857],
              [-86.5908, 41.9285],
              [-86.6865, 41.8388],
              [-86.825, 41.7603],
              [-86.9333, 41.761],
              [-87.2078, 41.761],
              [-87.0199, 42.4935],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2699997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 76626,
        AWATER: 23541910091,
        INTPTLAT: '+43.6990473',
        INTPTLON: '-083.5069481',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.186, 42.7625],
              [-84.1744, 42.743],
              [-84.1795, 42.7393],
              [-84.186, 42.7625],
            ],
          ],
          [
            [
              [-84.3599, 42.8256],
              [-84.2772, 42.8985],
              [-84.2022, 42.9141],
              [-84.1694, 42.8128],
              [-84.186, 42.7625],
              [-84.2138, 42.7543],
              [-84.2452, 42.7296],
              [-84.3155, 42.766],
              [-84.3638, 42.792],
              [-84.3599, 42.8256],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627900',
        NAME: 'Perry Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179927215,
        AWATER: 5907177,
        INTPTLAT: '+42.8178549',
        INTPTLON: '-084.2584923',
        ELSDLEA: '',
        UNSDLEA: '27900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5002, 42.0864],
            [-83.4945, 42.1209],
            [-83.4511, 42.121],
            [-83.3301, 42.0941],
            [-83.2685, 42.0742],
            [-83.2256, 42.0491],
            [-83.3709, 41.9553],
            [-83.3881, 42.0069],
            [-83.5186, 42.0288],
            [-83.5188, 42.0356],
            [-83.5002, 42.0864],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2601980',
        NAME: 'Airport Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214222272,
        AWATER: 1322491,
        INTPTLAT: '+42.0466849',
        INTPTLON: '-083.3751113',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7721, 42.0176],
            [-83.5188, 42.0356],
            [-83.5186, 42.0288],
            [-83.5265, 41.9515],
            [-83.6459, 41.9325],
            [-83.7615, 41.9516],
            [-83.7721, 42.0176],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612300',
        NAME: 'Dundee Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 188018729,
        AWATER: 1500997,
        INTPTLAT: '+41.9811201',
        INTPTLON: '-083.6401908',
        ELSDLEA: '',
        UNSDLEA: '12300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.3256, 45.8697],
            [-87.1376, 45.8982],
            [-86.9934, 45.9415],
            [-87.0305, 45.8298],
            [-87.2639, 45.8117],
            [-87.3256, 45.8697],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615970',
        NAME: 'Gladstone Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229250196,
        AWATER: 13900422,
        INTPTLAT: '+45.8624502',
        INTPTLON: '-087.1328319',
        ELSDLEA: '',
        UNSDLEA: '15970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2585, 45.5501],
            [-87.1602, 45.6548],
            [-87.0538, 45.6982],
            [-87.0305, 45.8298],
            [-86.9934, 45.9415],
            [-86.9908, 46.1589],
            [-86.7823, 46.1588],
            [-86.7846, 45.8974],
            [-86.7029, 45.8244],
            [-86.8478, 45.5713],
            [-86.872, 45.4439],
            [-87.1172, 45.4233],
            [-87.1278, 45.4095],
            [-87.1291, 45.5505],
            [-87.2585, 45.5501],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629340',
        NAME: 'Rapid River Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 758493209,
        AWATER: 873257743,
        INTPTLAT: '+45.7704310',
        INTPTLON: '-086.9134545',
        ELSDLEA: '',
        UNSDLEA: '29340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9312, 43.1947],
            [-83.9321, 43.2204],
            [-83.8144, 43.2146],
            [-83.8285, 43.1255],
            [-83.9291, 43.1328],
            [-83.9312, 43.1947],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624420',
        NAME: 'Montrose Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 91190828,
        AWATER: 1809628,
        INTPTLAT: '+43.1739397',
        INTPTLON: '-083.8771020',
        ELSDLEA: '',
        UNSDLEA: '24420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4837, 43.1216],
            [-84.4889, 43.1286],
            [-84.4935, 43.1286],
            [-84.373, 43.1543],
            [-84.3786, 43.1723],
            [-84.3266, 43.1865],
            [-84.231, 43.1155],
            [-84.2656, 43.0786],
            [-84.2799, 43.0145],
            [-84.3094, 42.9437],
            [-84.3093, 42.9405],
            [-84.3132, 42.9234],
            [-84.3298, 42.9278],
            [-84.434, 42.9178],
            [-84.4837, 43.1216],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627150',
        NAME: 'Ovid-Elsie Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 391701197,
        AWATER: 2779556,
        INTPTLAT: '+43.0515156',
        INTPTLON: '-084.3786732',
        ELSDLEA: '',
        UNSDLEA: '27150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0966, 43.4521],
            [-84.0792, 43.4518],
            [-83.9949, 43.3923],
            [-83.9762, 43.3817],
            [-84.0516, 43.3075],
            [-84.0695, 43.3077],
            [-84.1312, 43.3868],
            [-84.0966, 43.4521],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633410',
        NAME: 'Swan Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80603648,
        AWATER: 7546269,
        INTPTLAT: '+43.3828173',
        INTPTLON: '-084.0669760',
        ELSDLEA: '',
        UNSDLEA: '33410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3266, 43.1865],
            [-84.2878, 43.2296],
            [-84.2891, 43.2808],
            [-84.2488, 43.2445],
            [-84.1021, 43.2595],
            [-84.0695, 43.3077],
            [-84.0516, 43.3075],
            [-83.9332, 43.3079],
            [-83.9321, 43.2204],
            [-83.9312, 43.1947],
            [-84.045, 43.2044],
            [-84.0402, 43.1234],
            [-84.0381, 43.1017],
            [-84.0382, 43.1017],
            [-84.231, 43.1155],
            [-84.3266, 43.1865],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2609150',
        NAME: 'Chesaning Union Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 421450805,
        AWATER: 2632483,
        INTPTLAT: '+43.1996029',
        INTPTLON: '-084.1263903',
        ELSDLEA: '',
        UNSDLEA: '09150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9395, 44.4262],
            [-85.8893, 44.4329],
            [-85.7974, 44.4978],
            [-85.7368, 44.4862],
            [-85.6915, 44.4391],
            [-85.5777, 44.4532],
            [-85.4602, 44.339],
            [-85.58, 44.3097],
            [-85.6103, 44.3178],
            [-85.6653, 44.3102],
            [-85.8211, 44.2513],
            [-85.8209, 44.3525],
            [-85.9395, 44.4262],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623670',
        NAME: 'Mesick Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 508662566,
        AWATER: 8673144,
        INTPTLAT: '+44.3849254',
        INTPTLON: '-085.7049736',
        ELSDLEA: '',
        UNSDLEA: '23670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3082, 42.7479],
            [-85.1538, 42.6507],
            [-85.1536, 42.5598],
            [-85.1426, 42.516],
            [-85.1654, 42.4497],
            [-85.1899, 42.4352],
            [-85.203, 42.4081],
            [-85.2217, 42.3989],
            [-85.2535, 42.4344],
            [-85.2345, 42.5153],
            [-85.3177, 42.537],
            [-85.3227, 42.537],
            [-85.3378, 42.5371],
            [-85.4267, 42.5952],
            [-85.3082, 42.7479],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617970',
        NAME: 'Hastings Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 439954186,
        AWATER: 11392687,
        INTPTLAT: '+42.5918963',
        INTPTLON: '-085.2802110',
        ELSDLEA: '',
        UNSDLEA: '17970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1899, 42.4352],
            [-85.1654, 42.4497],
            [-85.0535, 42.4216],
            [-85.0485, 42.3558],
            [-85.0627, 42.3336],
            [-85.1411, 42.3336],
            [-85.203, 42.4081],
            [-85.1899, 42.4352],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627810',
        NAME: 'Pennfield School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106117276,
        AWATER: 2815589,
        INTPTLAT: '+42.3818180',
        INTPTLON: '-085.1137813',
        ELSDLEA: '',
        UNSDLEA: '27810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1426, 42.1983],
            [-84.1134, 42.2342],
            [-83.9355, 42.2467],
            [-83.9005, 42.2451],
            [-83.9459, 42.1407],
            [-84.098, 42.101],
            [-84.1529, 42.156],
            [-84.1426, 42.1983],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622380',
        NAME: 'Manchester Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241828869,
        AWATER: 3313482,
        INTPTLAT: '+42.1701229',
        INTPTLON: '-084.0452024',
        ELSDLEA: '',
        UNSDLEA: '22380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6989, 42.1932],
            [-83.6596, 42.1924],
            [-83.6595, 42.1872],
            [-83.6389, 42.084],
            [-83.5002, 42.0864],
            [-83.5188, 42.0356],
            [-83.7721, 42.0176],
            [-83.8035, 42.0819],
            [-83.6964, 42.1327],
            [-83.6989, 42.1932],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623850',
        NAME: 'Milan Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216608633,
        AWATER: 1037846,
        INTPTLAT: '+42.0814421',
        INTPTLON: '-083.6659692',
        ELSDLEA: '',
        UNSDLEA: '23850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2216, 42.3395],
            [-84.171, 42.3654],
            [-84.1134, 42.2342],
            [-84.1426, 42.1983],
            [-84.2491, 42.2004],
            [-84.2845, 42.2543],
            [-84.2216, 42.3395],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616830',
        NAME: 'Grass Lake Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161807017,
        AWATER: 5819214,
        INTPTLAT: '+42.2678971',
        INTPTLON: '-084.1965959',
        ELSDLEA: '',
        UNSDLEA: '16830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5865, 42.3341],
            [-83.5446, 42.2862],
            [-83.5736, 42.2134],
            [-83.6595, 42.1872],
            [-83.6596, 42.1924],
            [-83.6424, 42.3112],
            [-83.5865, 42.3341],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636630',
        NAME: 'Ypsilanti Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102191270,
        AWATER: 2421755,
        INTPTLAT: '+42.2644913',
        INTPTLON: '-083.6069901',
        ELSDLEA: '',
        UNSDLEA: '36630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3668, 45.1988],
            [-84.3666, 45.1987],
            [-84.0038, 45.1988],
            [-83.8812, 45.2021],
            [-83.8413, 45.2033],
            [-83.8089, 45.0011],
            [-83.8894, 44.9728],
            [-83.8885, 44.8565],
            [-84.01, 44.8557],
            [-84.0123, 45.0297],
            [-84.1242, 45.0284],
            [-84.1233, 45.185],
            [-84.2285, 45.1813],
            [-84.2876, 45.1131],
            [-84.3677, 45.1126],
            [-84.3668, 45.1988],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618360',
        NAME: 'Hillman Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 732252573,
        AWATER: 33972401,
        INTPTLAT: '+45.0692339',
        INTPTLON: '-084.0430604',
        ELSDLEA: '',
        UNSDLEA: '18360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.5335, 42.4573],
            [-83.4337, 42.4389],
            [-83.4331, 42.4249],
            [-83.5629, 42.3909],
            [-83.5335, 42.4573],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625980',
        NAME: 'Northville Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 66003890,
        AWATER: 1053051,
        INTPTLAT: '+42.4252044',
        INTPTLON: '-083.5092953',
        ELSDLEA: '',
        UNSDLEA: '25980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6431, 42.3625],
            [-83.5629, 42.3909],
            [-83.4331, 42.4249],
            [-83.4292, 42.3313],
            [-83.4942, 42.2855],
            [-83.5446, 42.2862],
            [-83.5865, 42.3341],
            [-83.6431, 42.3625],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2628560',
        NAME: 'Plymouth-Canton Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156157728,
        AWATER: 555436,
        INTPTLAT: '+42.3469691',
        INTPTLON: '-083.5167233',
        ELSDLEA: '',
        UNSDLEA: '28560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8413, 45.2033],
            [-83.5811, 45.2053],
            [-83.5161, 45.2295],
            [-83.5203, 45.3475],
            [-83.3814, 45.2695],
            [-83.3885, 45.2071],
            [-82.4906, 45.2085],
            [-82.4087, 44.8581],
            [-83.7669, 44.8567],
            [-83.8885, 44.8565],
            [-83.8894, 44.9728],
            [-83.8089, 45.0011],
            [-83.8413, 45.2033],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602730',
        NAME: 'Alpena Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1496787871,
        AWATER: 2921129583,
        INTPTLAT: '+44.9119122',
        INTPTLON: '-083.3287039',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9762, 43.3817],
            [-83.8566, 43.4145],
            [-83.8154, 43.3071],
            [-83.9332, 43.3079],
            [-84.0516, 43.3075],
            [-83.9762, 43.3817],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606780',
        NAME: 'Bridgeport-Spaulding Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163146356,
        AWATER: 5452940,
        INTPTLAT: '+43.3474753',
        INTPTLON: '-083.9087516',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8566, 43.4145],
            [-83.8572, 43.4289],
            [-83.8571, 43.4361],
            [-83.857, 43.443],
            [-83.8568, 43.4505],
            [-83.6695, 43.3961],
            [-83.6694, 43.3928],
            [-83.682, 43.3068],
            [-83.6957, 43.2717],
            [-83.8154, 43.3071],
            [-83.8566, 43.4145],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2614760',
        NAME: 'Frankenmuth School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190244123,
        AWATER: 698174,
        INTPTLAT: '+43.3575621',
        INTPTLON: '-083.7485103',
        ELSDLEA: '',
        UNSDLEA: '14760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3172, 42.4492],
            [-83.3121, 42.4421],
            [-83.3159, 42.4205],
            [-83.3657, 42.4408],
            [-83.3172, 42.4492],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2609840',
        NAME: 'Clarenceville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9585895,
        AWATER: 0,
        INTPTLAT: '+42.4375705',
        INTPTLON: '-083.3365987',
        ELSDLEA: '',
        UNSDLEA: '09840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3128, 42.3552],
            [-83.2748, 42.3569],
            [-83.2864, 42.3122],
            [-83.2968, 42.3119],
            [-83.3109, 42.3117],
            [-83.3122, 42.3406],
            [-83.3128, 42.3552],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600016',
        NAME: 'Crestwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14069440,
        AWATER: 0,
        INTPTLAT: '+42.3360258',
        INTPTLON: '-083.2936955',
        ELSDLEA: '',
        UNSDLEA: '00016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9312, 43.1947],
            [-83.9291, 43.1328],
            [-83.9287, 43.0804],
            [-83.9432, 43.0589],
            [-84.0381, 43.1017],
            [-84.0402, 43.1234],
            [-84.045, 43.2044],
            [-83.9312, 43.1947],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2625290',
        NAME: 'New Lothrop Area Public School',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148124655,
        AWATER: 1192116,
        INTPTLAT: '+43.1392105',
        INTPTLON: '-083.9978847',
        ELSDLEA: '',
        UNSDLEA: '25290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0143, 43.5168],
            [-83.856, 43.4939],
            [-83.8568, 43.4505],
            [-83.857, 43.443],
            [-83.8571, 43.4361],
            [-83.8572, 43.4289],
            [-83.8566, 43.4145],
            [-83.9762, 43.3817],
            [-83.9949, 43.3923],
            [-83.9556, 43.4437],
            [-83.9159, 43.4584],
            [-83.9563, 43.4798],
            [-84.0245, 43.4808],
            [-84.0143, 43.5168],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630390',
        NAME: 'Saginaw City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122691703,
        AWATER: 4815066,
        INTPTLAT: '+43.4172713',
        INTPTLON: '-083.8989320',
        ELSDLEA: '',
        UNSDLEA: '30390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0245, 43.4808],
            [-83.9563, 43.4798],
            [-83.9556, 43.4437],
            [-83.9949, 43.3923],
            [-84.0792, 43.4518],
            [-84.0245, 43.4808],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630450',
        NAME: 'Saginaw Township Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64101089,
        AWATER: 988855,
        INTPTLAT: '+43.4472140',
        INTPTLON: '-084.0216453',
        ELSDLEA: '',
        UNSDLEA: '30450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8866, 44.509],
            [-83.7662, 44.5102],
            [-83.7645, 44.4234],
            [-83.645, 44.3663],
            [-83.7636, 44.2844],
            [-83.9544, 44.3056],
            [-83.9444, 44.4218],
            [-83.8866, 44.509],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617370',
        NAME: 'Hale Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390161958,
        AWATER: 15265730,
        INTPTLAT: '+44.3957580',
        INTPTLON: '-083.8148385',
        ELSDLEA: '',
        UNSDLEA: '17370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.887, 44.5954],
            [-83.7668, 44.5958],
            [-83.3153, 44.5983],
            [-83.3187, 44.5117],
            [-82.3295, 44.5128],
            [-82.2899, 44.3387],
            [-83.381, 44.337],
            [-83.645, 44.3663],
            [-83.7645, 44.4234],
            [-83.7662, 44.5102],
            [-83.8866, 44.509],
            [-83.8868, 44.5233],
            [-83.887, 44.5954],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626970',
        NAME: 'Oscoda Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 896726930,
        AWATER: 1602705615,
        INTPTLAT: '+44.4073387',
        INTPTLON: '-083.3204196',
        ELSDLEA: '',
        UNSDLEA: '26970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9544, 44.3056],
            [-83.7636, 44.2844],
            [-83.6832, 44.2492],
            [-83.7313, 44.1619],
            [-83.8682, 44.1619],
            [-83.9674, 44.1274],
            [-83.9978, 44.1565],
            [-84.105, 44.1619],
            [-84.0848, 44.248],
            [-83.9544, 44.3056],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2636390',
        NAME: 'Whittemore-Prescott Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 421810443,
        AWATER: 6303632,
        INTPTLAT: '+44.2158194',
        INTPTLON: '-083.8944050',
        ELSDLEA: '',
        UNSDLEA: '36390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.8638, 42.7592],
            [-83.7662, 42.737],
            [-83.6827, 42.6956],
            [-83.6842, 42.5654],
            [-83.7727, 42.5742],
            [-83.8638, 42.7592],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617910',
        NAME: 'Hartland Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236003954,
        AWATER: 6974671,
        INTPTLAT: '+42.6626145',
        INTPTLON: '-083.7718750',
        ELSDLEA: '',
        UNSDLEA: '17910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2319, 42.2699],
            [-83.2216, 42.2725],
            [-83.1946, 42.2588],
            [-83.2006, 42.2282],
            [-83.2299, 42.2286],
            [-83.2319, 42.2699],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2602520',
        NAME: 'Allen Park Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 9654879,
        AWATER: 39623,
        INTPTLAT: '+42.2489389',
        INTPTLON: '-083.2167222',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9758, 45.6833],
            [-85.0268, 45.6228],
            [-84.9754, 45.5365],
            [-84.8938, 45.4927],
            [-84.8787, 45.428],
            [-84.9192, 45.4201],
            [-85.0416, 45.4365],
            [-85.119, 45.5748],
            [-84.9758, 45.6833],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2617700',
        NAME: 'Harbor Springs School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297649311,
        AWATER: 1016179,
        INTPTLAT: '+45.5218751',
        INTPTLON: '-084.9907770',
        ELSDLEA: '',
        UNSDLEA: '17700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.0347, 42.4531],
            [-83.0063, 42.485],
            [-82.9868, 42.4783],
            [-82.9857, 42.4493],
            [-83.0345, 42.4479],
            [-83.0347, 42.4531],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634680',
        NAME: 'Van Dyke Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12711565,
        AWATER: 2622,
        INTPTLAT: '+42.4640696',
        INTPTLON: '-083.0081556',
        ELSDLEA: '',
        UNSDLEA: '34680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.969, 42.5236],
            [-82.9687, 42.5162],
            [-82.9672, 42.4802],
            [-82.9868, 42.4783],
            [-83.0063, 42.485],
            [-83.0065, 42.4999],
            [-82.969, 42.5236],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635220',
        NAME: 'Warren Woods Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12875698,
        AWATER: 2276,
        INTPTLAT: '+42.4999433',
        INTPTLON: '-082.9850915',
        ELSDLEA: '',
        UNSDLEA: '35220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.454, 43.8143],
            [-84.2882, 43.8276],
            [-84.2891, 43.7128],
            [-84.3692, 43.6171],
            [-84.4883, 43.6405],
            [-84.454, 43.8143],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623580',
        NAME: 'Meridian Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 237514814,
        AWATER: 11569953,
        INTPTLAT: '+43.7377224',
        INTPTLON: '-084.3781368',
        ELSDLEA: '',
        UNSDLEA: '23580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3692, 43.6171],
            [-84.2891, 43.7128],
            [-84.2882, 43.8276],
            [-84.1673, 43.8259],
            [-84.167, 43.7404],
            [-84.1681, 43.5689],
            [-84.1706, 43.482],
            [-84.1761, 43.4819],
            [-84.2227, 43.5396],
            [-84.238, 43.5976],
            [-84.3692, 43.6171],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623820',
        NAME: 'Midland Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351471688,
        AWATER: 7688397,
        INTPTLAT: '+43.6769781',
        INTPTLON: '-084.2375149',
        ELSDLEA: '',
        UNSDLEA: '23820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6079, 43.6146],
            [-84.4883, 43.6405],
            [-84.3692, 43.6171],
            [-84.238, 43.5976],
            [-84.2227, 43.5396],
            [-84.2908, 43.5086],
            [-84.3697, 43.538],
            [-84.4885, 43.5751],
            [-84.5484, 43.593],
            [-84.6079, 43.6146],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2607320',
        NAME: 'Bullock Creek School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194418467,
        AWATER: 2462849,
        INTPTLAT: '+43.5895736',
        INTPTLON: '-084.3996271',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-82.8037, 43.8977],
              [-82.7936, 43.887],
              [-82.7988, 43.8869],
              [-82.8037, 43.8977],
            ],
          ],
          [
            [
              [-82.8507, 43.7811],
              [-82.8457, 43.7813],
              [-82.8067, 43.8219],
              [-82.803, 43.876],
              [-82.7988, 43.8797],
              [-82.7936, 43.887],
              [-82.6849, 43.9046],
              [-82.6146, 43.7782],
              [-82.603, 43.6395],
              [-82.7178, 43.6438],
              [-82.7008, 43.7309],
              [-82.8503, 43.7704],
              [-82.8507, 43.7811],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600007',
        NAME: 'Harbor Beach Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 310229039,
        AWATER: 420624,
        INTPTLAT: '+43.7762571',
        INTPTLON: '-082.6966110',
        ELSDLEA: '',
        UNSDLEA: '00007',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7625, 43.3215],
            [-82.7458, 43.343],
            [-82.6674, 43.3615],
            [-82.5338, 43.3802],
            [-82.4938, 43.1409],
            [-82.6129, 43.1358],
            [-82.6816, 43.1484],
            [-82.7743, 43.2035],
            [-82.7625, 43.3215],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2611140',
        NAME: 'Croswell-Lexington Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 432323651,
        AWATER: 1749827,
        INTPTLAT: '+43.2439177',
        INTPTLON: '-082.6347222',
        ELSDLEA: '',
        UNSDLEA: '11140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9043, 43.3005],
            [-82.9002, 43.3005],
            [-82.8946, 43.3043],
            [-82.8949, 43.3217],
            [-82.8514, 43.3232],
            [-82.7625, 43.3215],
            [-82.7743, 43.2035],
            [-82.8208, 43.1651],
            [-82.8314, 43.1677],
            [-82.9043, 43.3005],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2627690',
        NAME: 'Peck Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 172327210,
        AWATER: 28020,
        INTPTLAT: '+43.2636547',
        INTPTLON: '-082.8121536',
        ELSDLEA: '',
        UNSDLEA: '27690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6828, 42.9598],
            [-83.6614, 42.9601],
            [-83.5838, 42.9704],
            [-83.574, 42.9683],
            [-83.5813, 42.8731],
            [-83.6706, 42.8716],
            [-83.7228, 42.905],
            [-83.6828, 42.9598],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2616350',
        NAME: 'Grand Blanc Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100240262,
        AWATER: 636942,
        INTPTLAT: '+42.9193699',
        INTPTLON: '-083.6364015',
        ELSDLEA: '',
        UNSDLEA: '16350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9285, 43.0093],
            [-83.9273, 42.8713],
            [-84.0709, 42.8504],
            [-84.1084, 42.8944],
            [-83.9285, 43.0093],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2612330',
        NAME: 'Durand Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163274148,
        AWATER: 2260294,
        INTPTLAT: '+42.9159494',
        INTPTLON: '-083.9932607',
        ELSDLEA: '',
        UNSDLEA: '12330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7969, 42.899],
            [-83.77, 42.9114],
            [-83.7228, 42.905],
            [-83.6706, 42.8716],
            [-83.6874, 42.8124],
            [-83.7581, 42.8185],
            [-83.7739, 42.8847],
            [-83.7969, 42.899],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620670',
        NAME: 'Lake Fenton Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60476535,
        AWATER: 6499659,
        INTPTLAT: '+42.8672354',
        INTPTLON: '-083.7260466',
        ELSDLEA: '',
        UNSDLEA: '20670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9285, 43.0093],
            [-83.9284, 43.0247],
            [-83.8124, 43.0156],
            [-83.7435, 42.9755],
            [-83.742, 42.9587],
            [-83.77, 42.9114],
            [-83.7969, 42.899],
            [-83.8418, 42.8465],
            [-83.9273, 42.8713],
            [-83.9285, 43.0093],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633420',
        NAME: 'Swartz Creek Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184852197,
        AWATER: 262722,
        INTPTLAT: '+42.9379751',
        INTPTLON: '-083.8360951',
        ELSDLEA: '',
        UNSDLEA: '33420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3194, 42.1151],
            [-85.3241, 42.1581],
            [-85.2375, 42.2242],
            [-85.1677, 42.1595],
            [-85.1584, 42.1018],
            [-85.2427, 42.0213],
            [-85.3082, 42.0715],
            [-85.3194, 42.1151],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2603510',
        NAME: 'Athens Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191067599,
        AWATER: 1872344,
        INTPTLAT: '+42.1237707',
        INTPTLON: '-085.2458726',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1035, 41.9563],
            [-85.0356, 42.0585],
            [-84.9423, 42.0514],
            [-84.9321, 41.9144],
            [-84.9175, 41.789],
            [-84.9173, 41.7855],
            [-84.9177, 41.7597],
            [-85.0588, 41.7596],
            [-85.0589, 41.9052],
            [-85.1035, 41.9563],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610140',
        NAME: 'Coldwater Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 354146541,
        AWATER: 19229868,
        INTPTLAT: '+41.9121998',
        INTPTLON: '-084.9972719',
        ELSDLEA: '',
        UNSDLEA: '10140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8951, 42.066],
            [-84.6896, 42.1069],
            [-84.6756, 42.0718],
            [-84.7483, 41.9805],
            [-84.8951, 42.066],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621750',
        NAME: 'Litchfield Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148723643,
        AWATER: 727258,
        INTPTLAT: '+42.0383171',
        INTPTLON: '-084.7863762',
        ELSDLEA: '',
        UNSDLEA: '21750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0487, 42.1598],
            [-84.987, 42.1741],
            [-84.9095, 42.0724],
            [-84.9423, 42.0514],
            [-85.0356, 42.0585],
            [-85.0487, 42.1598],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2633750',
        NAME: 'Tekonsha Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130150133,
        AWATER: 2738263,
        INTPTLAT: '+42.1120663',
        INTPTLON: '-084.9903530',
        ELSDLEA: '',
        UNSDLEA: '33750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2924, 41.8975],
            [-85.2345, 41.9758],
            [-85.1035, 41.9563],
            [-85.0589, 41.9052],
            [-85.0588, 41.7596],
            [-85.084, 41.7597],
            [-85.1968, 41.7596],
            [-85.2922, 41.7599],
            [-85.2921, 41.8181],
            [-85.2924, 41.8975],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606960',
        NAME: 'Bronson Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388102966,
        AWATER: 4938127,
        INTPTLAT: '+41.8574057',
        INTPTLON: '-085.1699374',
        ELSDLEA: '',
        UNSDLEA: '06960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.987, 42.1741],
            [-84.8475, 42.2248],
            [-84.7133, 42.1882],
            [-84.713, 42.1846],
            [-84.712, 42.1672],
            [-84.6896, 42.1069],
            [-84.8951, 42.066],
            [-84.9095, 42.0724],
            [-84.987, 42.1741],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618540',
        NAME: 'Homer Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241884244,
        AWATER: 2067258,
        INTPTLAT: '+42.1393326',
        INTPTLON: '-084.8198314',
        ELSDLEA: '',
        UNSDLEA: '18540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.8059, 41.7602],
              [-84.8251, 41.76],
              [-84.8252, 41.7779],
              [-84.8059, 41.7602],
            ],
          ],
          [
            [
              [-84.7629, 41.9324],
              [-84.6408, 41.8108],
              [-84.8492, 41.7964],
              [-84.9173, 41.7855],
              [-84.9175, 41.789],
              [-84.7629, 41.9324],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629400',
        NAME: 'Reading Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200845748,
        AWATER: 3801290,
        INTPTLAT: '+41.8433161',
        INTPTLON: '-084.7767855',
        ELSDLEA: '',
        UNSDLEA: '29400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9571, 47.2911],
            [-89.6807, 47.7146],
            [-88.9489, 47.4771],
            [-88.9333, 47.0313],
            [-89.0596, 46.9899],
            [-88.9325, 46.8943],
            [-88.9323, 46.8081],
            [-88.864, 46.7518],
            [-88.8645, 46.6792],
            [-88.9895, 46.6801],
            [-89.2403, 46.6798],
            [-89.2402, 46.5925],
            [-89.3605, 46.5925],
            [-89.3644, 46.6795],
            [-89.7387, 46.6789],
            [-89.7403, 46.5921],
            [-89.8645, 46.5928],
            [-89.8877, 46.7658],
            [-90.0002, 46.7655],
            [-90.0002, 46.9999],
            [-90.1117, 47.042],
            [-89.9571, 47.2911],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626550',
        NAME: 'Ontonagon Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1764246763,
        AWATER: 6255930160,
        INTPTLAT: '+46.8395681',
        INTPTLON: '-089.5032333',
        ELSDLEA: '',
        UNSDLEA: '26550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.8509, 44.5111],
            [-84.6713, 44.5113],
            [-84.6692, 44.5987],
            [-84.4895, 44.5977],
            [-84.4864, 44.6839],
            [-84.3717, 44.6825],
            [-84.3706, 44.5072],
            [-84.3696, 44.3341],
            [-84.6124, 44.3344],
            [-84.6141, 44.4213],
            [-84.8558, 44.422],
            [-84.8509, 44.5111],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2615830',
        NAME: 'Gerrish-Higgins School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 828244336,
        AWATER: 61554922,
        INTPTLAT: '+44.4818178',
        INTPTLON: '-084.5299114',
        ELSDLEA: '',
        UNSDLEA: '15830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7671, 47.6787],
            [-86.3615, 47.5185],
            [-86.365, 46.5059],
            [-86.3653, 46.3319],
            [-86.6154, 46.3333],
            [-86.6153, 46.2458],
            [-86.6156, 46.1587],
            [-86.7406, 46.1587],
            [-86.765, 46.2457],
            [-86.7672, 47.1363],
            [-86.7671, 47.6787],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2624810',
        NAME: 'Munising Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 795917071,
        AWATER: 3730778743,
        INTPTLAT: '+46.5580045',
        INTPTLON: '-086.6799766',
        ELSDLEA: '',
        UNSDLEA: '24810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.365, 46.5059],
            [-86.2401, 46.5053],
            [-86.2409, 46.3316],
            [-85.8646, 46.3317],
            [-85.8651, 46.1584],
            [-85.8682, 45.8198],
            [-85.8558, 45.5784],
            [-86.4608, 45.5844],
            [-86.4591, 45.8964],
            [-86.4271, 45.9838],
            [-86.4281, 46.245],
            [-86.6153, 46.2458],
            [-86.6154, 46.3333],
            [-86.3653, 46.3319],
            [-86.365, 46.5059],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2622470',
        NAME: 'Manistique Area Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2169985018,
        AWATER: 1821192173,
        INTPTLAT: '+45.9868991',
        INTPTLON: '-086.1734159',
        ELSDLEA: '',
        UNSDLEA: '22470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1239, 43.3615],
            [-84.9649, 43.3288],
            [-84.9251, 43.3103],
            [-84.9653, 43.1938],
            [-85.0293, 43.1702],
            [-85.0146, 43.1486],
            [-84.9959, 43.1342],
            [-85.075, 43.1202],
            [-85.1151, 43.12],
            [-85.135, 43.1198],
            [-85.1082, 43.1707],
            [-85.1133, 43.185],
            [-85.1918, 43.2783],
            [-85.1239, 43.3615],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2608640',
        NAME: 'Central Montcalm Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 380551967,
        AWATER: 8080045,
        INTPTLAT: '+43.2538890',
        INTPTLON: '-085.0636844',
        ELSDLEA: '',
        UNSDLEA: '08640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2671, 42.9873],
            [-85.1859, 43.0089],
            [-85.0996, 42.9076],
            [-85.1341, 42.9007],
            [-85.2724, 42.857],
            [-85.3118, 42.9343],
            [-85.2671, 42.9873],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2630930',
        NAME: 'Saranac Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183885220,
        AWATER: 2826452,
        INTPTLAT: '+42.9300510',
        INTPTLON: '-085.2069820',
        ELSDLEA: '',
        UNSDLEA: '30930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9595, 42.5314],
            [-84.7579, 42.5093],
            [-84.7675, 42.4216],
            [-84.797, 42.3734],
            [-84.806, 42.3635],
            [-84.9273, 42.3632],
            [-85.0054, 42.3771],
            [-84.9595, 42.5314],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626370',
        NAME: 'Olivet Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278759266,
        AWATER: 1818656,
        INTPTLAT: '+42.4412023',
        INTPTLON: '-084.8938371',
        ELSDLEA: '',
        UNSDLEA: '26370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-84.6766, 42.6691],
              [-84.6423, 42.6713],
              [-84.681, 42.6549],
              [-84.7013, 42.6691],
              [-84.6766, 42.6691],
            ],
          ],
          [
            [
              [-84.7781, 42.6549],
              [-84.7492, 42.6829],
              [-84.7062, 42.6691],
              [-84.7781, 42.6549],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629160',
        NAME: 'Potterville Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40426701,
        AWATER: 867481,
        INTPTLAT: '+42.6442935',
        INTPTLON: '-084.7312159',
        ELSDLEA: '',
        UNSDLEA: '29160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9304, 43.4956],
            [-84.846, 43.4662],
            [-84.8452, 43.3338],
            [-84.8452, 43.3284],
            [-84.9251, 43.3103],
            [-84.9649, 43.3288],
            [-84.9655, 43.4661],
            [-84.9304, 43.4956],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2634920',
        NAME: 'Vestaburg Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 173923689,
        AWATER: 1622868,
        INTPTLAT: '+43.4046260',
        INTPTLON: '-084.9103437',
        ELSDLEA: '',
        UNSDLEA: '34920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9814, 41.818],
            [-83.9296, 41.9128],
            [-83.8961, 41.9352],
            [-83.8479, 41.9214],
            [-83.7621, 41.8211],
            [-83.8061, 41.7224],
            [-83.9577, 41.718],
            [-83.9814, 41.818],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606000',
        NAME: 'Blissfield Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 309326408,
        AWATER: 955621,
        INTPTLAT: '+41.8090229',
        INTPTLON: '-083.8755977',
        ELSDLEA: '',
        UNSDLEA: '06000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1265, 42.0717],
            [-84.098, 42.101],
            [-83.9459, 42.1407],
            [-83.8447, 42.0812],
            [-83.9664, 42.0427],
            [-84.0759, 42.0645],
            [-84.1265, 42.0717],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610050',
        NAME: 'Clinton Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136831792,
        AWATER: 2198482,
        INTPTLAT: '+42.0836035',
        INTPTLON: '-083.9776565',
        ELSDLEA: '',
        UNSDLEA: '10050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4157, 41.9351],
            [-84.362, 41.9018],
            [-84.2458, 41.9189],
            [-84.1802, 41.8959],
            [-84.1599, 41.8657],
            [-84.1833, 41.8325],
            [-84.2643, 41.8277],
            [-84.3343, 41.7597],
            [-84.3901, 41.8114],
            [-84.4157, 41.9351],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2618810',
        NAME: 'Hudson Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 223566632,
        AWATER: 3276838,
        INTPTLAT: '+41.8629573',
        INTPTLON: '-084.3047636',
        ELSDLEA: '',
        UNSDLEA: '18810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.257, 42.0561],
            [-84.2186, 42.0594],
            [-84.1265, 42.0717],
            [-84.0759, 42.0645],
            [-84.0744, 41.9843],
            [-84.1087, 41.8984],
            [-84.1802, 41.8959],
            [-84.2458, 41.9189],
            [-84.2805, 42.0302],
            [-84.257, 42.0561],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626520',
        NAME: 'Onsted Community Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 236781625,
        AWATER: 10398519,
        INTPTLAT: '+41.9938359',
        INTPTLON: '-084.1794447',
        ELSDLEA: '',
        UNSDLEA: '26520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5195, 41.7598],
            [-84.3901, 41.8114],
            [-84.3343, 41.7597],
            [-84.3559, 41.7067],
            [-84.3995, 41.7058],
            [-84.4664, 41.7041],
            [-84.5803, 41.7017],
            [-84.5195, 41.7598],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2635040',
        NAME: 'Waldron Area Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151943801,
        AWATER: 945423,
        INTPTLAT: '+41.7458628',
        INTPTLON: '-084.4287389',
        ELSDLEA: '',
        UNSDLEA: '35040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-85.0996, 42.9076],
              [-85.1044, 42.8934],
              [-85.1341, 42.9007],
              [-85.0996, 42.9076],
            ],
          ],
          [
            [
              [-85.119, 43.0323],
              [-85.0848, 42.9949],
              [-85.0644, 42.9947],
              [-85.0617, 43.0325],
              [-85.0849, 43.0324],
              [-85.1147, 43.0341],
              [-85.1151, 43.12],
              [-85.075, 43.1202],
              [-84.9273, 43.0763],
              [-84.9224, 43.0763],
              [-84.8636, 43.0393],
              [-84.8868, 42.9633],
              [-85.0066, 42.9364],
              [-85.0573, 42.8791],
              [-85.0996, 42.9076],
              [-85.1859, 43.0089],
              [-85.119, 43.0323],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2619250',
        NAME: 'Ionia Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 342824314,
        AWATER: 4919566,
        INTPTLAT: '+43.0059557',
        INTPTLON: '-085.0096429',
        ELSDLEA: '',
        UNSDLEA: '19250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8001, 42.2592],
            [-85.7658, 42.3025],
            [-85.7314, 42.2955],
            [-85.6867, 42.208],
            [-85.6672, 42.1865],
            [-85.754, 42.1167],
            [-85.8523, 42.2005],
            [-85.8001, 42.2592],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2623250',
        NAME: 'Mattawan Consolidated School',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136985743,
        AWATER: 3971280,
        INTPTLAT: '+42.2032742',
        INTPTLON: '-085.7517501',
        ELSDLEA: '',
        UNSDLEA: '23250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.3646, 42.2431],
            [-86.2624, 42.2364],
            [-86.3006, 42.109],
            [-86.3894, 42.1574],
            [-86.3707, 42.1681],
            [-86.3754, 42.1863],
            [-86.4075, 42.1796],
            [-86.4162, 42.1836],
            [-86.3646, 42.2431],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2610380',
        NAME: 'Coloma Community Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107287027,
        AWATER: 3769477,
        INTPTLAT: '+42.1902546',
        INTPTLON: '-086.3285585',
        ELSDLEA: '',
        UNSDLEA: '10380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1086, 42.2439],
            [-85.9856, 42.2841],
            [-85.985, 42.1642],
            [-86.1082, 42.157],
            [-86.1086, 42.2439],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2621210',
        NAME: 'Lawrence Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122740938,
        AWATER: 3410745,
        INTPTLAT: '+42.2169212',
        INTPTLON: '-086.0441061',
        ELSDLEA: '',
        UNSDLEA: '21210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5908, 41.9285],
            [-86.5628, 41.9857],
            [-86.4945, 41.9268],
            [-86.5908, 41.9285],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2606840',
        NAME: 'Bridgman Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50616701,
        AWATER: 151086,
        INTPTLAT: '+41.9419641',
        INTPTLON: '-086.5441522',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.521, 42.0536],
            [-86.4153, 42.0146],
            [-86.4557, 41.9259],
            [-86.4945, 41.9268],
            [-86.5628, 41.9857],
            [-86.521, 42.0536],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2620820',
        NAME: 'Lakeshore School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94997341,
        AWATER: 1011886,
        INTPTLAT: '+41.9927803',
        INTPTLON: '-086.4899815',
        ELSDLEA: '',
        UNSDLEA: '20820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.521, 42.0536],
            [-86.4774, 42.1245],
            [-86.4087, 42.0552],
            [-86.3996, 42.0161],
            [-86.4153, 42.0146],
            [-86.521, 42.0536],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2632850',
        NAME: 'St. Joseph Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41909757,
        AWATER: 1929824,
        INTPTLAT: '+42.0607758',
        INTPTLON: '-086.4568430',
        ELSDLEA: '',
        UNSDLEA: '32850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6137, 46.6647],
            [-87.5057, 46.679],
            [-87.5035, 47.222],
            [-87.1121, 47.2233],
            [-87.1164, 46.4203],
            [-87.3652, 46.4197],
            [-87.3853, 46.5063],
            [-87.489, 46.506],
            [-87.4891, 46.5934],
            [-87.6139, 46.6064],
            [-87.6137, 46.6647],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2600013',
        NAME: 'Marquette Area Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329278844,
        AWATER: 2304501026,
        INTPTLAT: '+46.7207110',
        INTPTLON: '-087.4117425',
        ELSDLEA: '',
        UNSDLEA: '00013',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1166, 46.42],
            [-88.1158, 46.6784],
            [-87.9915, 46.6791],
            [-88.0447, 46.7654],
            [-88.0445, 46.8096],
            [-87.9178, 46.8086],
            [-87.8651, 46.7082],
            [-87.9922, 46.593],
            [-87.9912, 46.4634],
            [-87.9499, 46.4064],
            [-87.9503, 46.247],
            [-88.1174, 46.2466],
            [-88.1166, 46.42],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2629580',
        NAME: 'Republic-Michigamme Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 702785925,
        AWATER: 45093330,
        INTPTLAT: '+46.5137723',
        INTPTLON: '-088.0152854',
        ELSDLEA: '',
        UNSDLEA: '29580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4153, 42.0146],
            [-86.3996, 42.0161],
            [-86.389, 42.0215],
            [-86.3351, 41.9658],
            [-86.223, 41.9567],
            [-86.2231, 41.9351],
            [-86.3139, 41.8995],
            [-86.4169, 41.8995],
            [-86.4557, 41.9259],
            [-86.4153, 42.0146],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2605430',
        NAME: 'Berrien Springs Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146651279,
        AWATER: 5834482,
        INTPTLAT: '+41.9496918',
        INTPTLON: '-086.3648808',
        ELSDLEA: '',
        UNSDLEA: '05430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.8469, 44.5194],
            [-86.1809, 44.5179],
            [-86.1812, 44.5],
            [-86.1824, 44.3814],
            [-86.1111, 44.3727],
            [-86.1019, 44.2995],
            [-86.1832, 44.2737],
            [-86.2886, 44.3138],
            [-86.9311, 44.3111],
            [-86.8469, 44.5194],
          ],
        ],
      },
      properties: {
        STATEFP: '26',
        SCSDLEA: '',
        GEOID: '2626490',
        NAME: 'Onekama Consolidated Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 200643569,
        AWATER: 1164294933,
        INTPTLAT: '+44.3303075',
        INTPTLON: '-086.2806795',
        ELSDLEA: '',
        UNSDLEA: '26490',
      },
    },
  ],
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './style.css';

const Button = ({
  buttonText,
  buttonLink,
  theme,
  isRoute,
  weight,
  size,
  icon,
  onClick,
  redirect,
  disabled,
}) => {
  const onClickHandler = () => {
    onClick();
  };

  if (redirect) {
    if (isRoute) {
      return (
        <Link className={`btn btn-custom ${theme} ${weight} ${size}`} to={buttonLink}>
          {icon && <img className="btn-icon" src={icon} alt="Button Icon" />}
          <span className="btn-txt">{buttonText}</span>
        </Link>
      );
    }

    if (!isRoute) {
      return (
        <a className={`btn btn-custom ${theme} ${weight} ${size}`} href={buttonLink}>
          {icon && <img className="btn-icon" src={icon} alt="Button Icon" />}
          <span className="btn-txt">{buttonText}</span>
        </a>
      );
    }
  }

  if (!redirect) {
    return (
      <button
        className={`btn btn-custom ${theme} ${weight} ${size}`}
        onClick={onClickHandler}
        type="button"
        disabled={disabled}
      >
        {icon && <img className="btn-icon" src={icon} alt="Button Icon" />}
        <span className="btn-txt">{buttonText}</span>
      </button>
    );
  }

  return null;
};

Button.propTypes = {
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  theme: PropTypes.string,
  isRoute: PropTypes.bool,
  weight: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  redirect: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  isRoute: false,
  weight: 'regular',
  theme: null,
  icon: null,
  size: 'md',
  onClick: () => {},
  redirect: false,
  buttonLink: '#',
  disabled: false,
};

export default Button;

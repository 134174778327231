export const TN = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9851, 35.5282],
            [-85.0067, 35.4648],
            [-85.0192, 35.4622],
            [-85.0394, 35.4851],
            [-85.0225, 35.4902],
            [-85.0109, 35.5191],
            [-84.9851, 35.5282],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47143',
        GEOID: '4747143',
        NAME: 'Rhea County School District in Dayton',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 19820183,
        AWATER: 601110,
        INTPTLAT: '+35.4921258',
        INTPTLON: '-085.0118644',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2755, 35.9728],
            [-83.2613, 35.9692],
            [-83.2534, 35.9712],
            [-83.2476, 35.9727],
            [-83.2454, 35.9723],
            [-83.2214, 35.9753],
            [-83.2191, 35.9754],
            [-83.2076, 35.9753],
            [-83.2078, 35.9864],
            [-83.191, 35.9788],
            [-83.1751, 35.9773],
            [-83.1726, 35.9595],
            [-83.1947, 35.9366],
            [-83.2067, 35.9473],
            [-83.2041, 35.9663],
            [-83.2199, 35.9754],
            [-83.2418, 35.9725],
            [-83.2496, 35.9677],
            [-83.2734, 35.9696],
            [-83.2755, 35.9728],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47029',
        GEOID: '4747029',
        NAME: 'Cocke County School District in Newport',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 14319881,
        AWATER: 0,
        INTPTLAT: '+35.9613606',
        INTPTLON: '-083.1976748',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.656, 35.4685],
            [-84.6214, 35.459],
            [-84.6027, 35.4768],
            [-84.5754, 35.4913],
            [-84.5716, 35.4282],
            [-84.5875, 35.4219],
            [-84.6434, 35.4406],
            [-84.656, 35.4685],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47107',
        GEOID: '4747107',
        NAME: 'McMinn County School District in Athens',
        LSAD: '00',
        LOGRADE: '10',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 40234450,
        AWATER: 0,
        INTPTLAT: '+35.4573109',
        INTPTLON: '-084.6041530',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5444, 35.3238],
            [-84.5393, 35.3393],
            [-84.5289, 35.3532],
            [-84.5169, 35.352],
            [-84.5194, 35.3313],
            [-84.5292, 35.3124],
            [-84.5444, 35.3238],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47108',
        GEOID: '4747108',
        NAME: 'McMinn County School District in Etowah',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 7561698,
        AWATER: 0,
        INTPTLAT: '+35.3384163',
        INTPTLON: '-084.5280838',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4168, 36.2485],
            [-86.3212, 36.2617],
            [-86.2465, 36.2397],
            [-86.2686, 36.1575],
            [-86.39, 36.1903],
            [-86.4168, 36.2485],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47189',
        GEOID: '4747189',
        NAME: 'Wilson County School District in Lebanon',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 127439590,
        AWATER: 10591,
        INTPTLAT: '+36.2141551',
        INTPTLON: '-086.3144271',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1213, 35.4106],
            [-86.1215, 35.4107],
            [-86.0935, 35.4592],
            [-86.0522, 35.4448],
            [-86.1213, 35.4106],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47031',
        GEOID: '4747031',
        NAME: 'Coffee County School District in Manchester',
        LSAD: '00',
        LOGRADE: '10',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 33660103,
        AWATER: 17785,
        INTPTLAT: '+35.4595494',
        INTPTLON: '-086.0825394',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5258, 35.6242],
            [-84.4868, 35.6584],
            [-84.3661, 35.647],
            [-84.3975, 35.5549],
            [-84.4711, 35.5167],
            [-84.5224, 35.6078],
            [-84.5224, 35.6186],
            [-84.5258, 35.6242],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47123',
        GEOID: '4747123',
        NAME: 'Monroe County School District in Sweetwater',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 163406731,
        AWATER: 6377,
        INTPTLAT: '+35.6030282',
        INTPTLON: '-084.4437669',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9944, 36.401],
            [-83.0203, 36.3938],
            [-83.0073, 36.4141],
            [-82.9944, 36.401],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47073',
        GEOID: '4747073',
        NAME: 'Hawkins County School District in Rogersville',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 8793013,
        AWATER: 0,
        INTPTLAT: '+36.4079657',
        INTPTLON: '-083.0037857',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4082, 35.6804],
            [-88.366, 35.6683],
            [-88.3706, 35.6384],
            [-88.4226, 35.6239],
            [-88.4098, 35.6535],
            [-88.4082, 35.6804],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47077',
        GEOID: '4747077',
        NAME: 'Henderson County School District in Lexington',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 31939348,
        AWATER: 485317,
        INTPTLAT: '+35.6554456',
        INTPTLON: '-088.4107165',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9133, 35.9404],
            [-86.8666, 35.9428],
            [-86.8014, 35.9198],
            [-86.8366, 35.886],
            [-86.9044, 35.9065],
            [-86.9133, 35.9404],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47187',
        GEOID: '4747187',
        NAME: 'Williamson County School District in Franklin',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 51974306,
        AWATER: 343015,
        INTPTLAT: '+35.9262759',
        INTPTLON: '-086.8548995',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4755, 35.9017],
            [-86.3969, 35.9196],
            [-86.347, 35.899],
            [-86.3466, 35.8344],
            [-86.3908, 35.7768],
            [-86.4792, 35.8092],
            [-86.4755, 35.9017],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47149',
        GEOID: '4747149',
        NAME: 'Rutherford County School District in Murfreesboro',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 145991261,
        AWATER: 387261,
        INTPTLAT: '+35.8521638',
        INTPTLON: '-086.4160223',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0656, 35.8515],
            [-89.0066, 35.8327],
            [-89.0679, 35.7613],
            [-89.1736, 35.7365],
            [-89.1897, 35.7427],
            [-89.1563, 35.8231],
            [-89.0656, 35.8515],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47033',
        GEOID: '4747033',
        NAME: 'Crockett County School District in Alamo',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 130019883,
        AWATER: 838,
        INTPTLAT: '+35.7929468',
        INTPTLON: '-089.1000683',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0679, 35.7613],
            [-88.9683, 35.7175],
            [-89.0695, 35.6927],
            [-89.1736, 35.7365],
            [-89.0679, 35.7613],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47034',
        GEOID: '4747034',
        NAME: 'Crockett County School District in Bells',
        LSAD: '00',
        LOGRADE: '06',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 84540360,
        AWATER: 137778,
        INTPTLAT: '+35.7162840',
        INTPTLON: '-089.0699909',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1688, 36.085],
            [-84.1441, 36.1206],
            [-84.1167, 36.1197],
            [-84.0961, 36.0919],
            [-84.1141, 36.0843],
            [-84.1458, 36.0566],
            [-84.1688, 36.085],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47001',
        GEOID: '4747001',
        NAME: 'Anderson County School District in Clinton',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 29621441,
        AWATER: 1527971,
        INTPTLAT: '+36.0973382',
        INTPTLON: '-084.1289452',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3902, 36.3013],
            [-88.3465, 36.3379],
            [-88.2633, 36.3072],
            [-88.2744, 36.2752],
            [-88.361, 36.2642],
            [-88.3858, 36.2796],
            [-88.3902, 36.3013],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '47079',
        GEOID: '4747079',
        NAME: 'Henry County School District in Paris',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: 'A',
        FUNCSTAT: 'F',
        ALAND: 65254054,
        AWATER: 165437,
        INTPTLAT: '+36.2984916',
        INTPTLON: '-088.3247053',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0656, 35.8515],
            [-89.0066, 35.8327],
            [-89.0679, 35.7613],
            [-89.1736, 35.7365],
            [-89.1897, 35.7427],
            [-89.1563, 35.8231],
            [-89.0656, 35.8515],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700030',
        NAME: 'Alamo Town School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130019883,
        AWATER: 838,
        INTPTLAT: '+35.7929468',
        INTPTLON: '-089.1000683',
        ELSDLEA: '00030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.656, 35.4685],
            [-84.6214, 35.459],
            [-84.6027, 35.4768],
            [-84.5754, 35.4913],
            [-84.5716, 35.4282],
            [-84.5875, 35.4219],
            [-84.6434, 35.4406],
            [-84.656, 35.4685],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700120',
        NAME: 'Athens City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '09',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40234450,
        AWATER: 0,
        INTPTLAT: '+35.4573109',
        INTPTLON: '-084.6041530',
        ELSDLEA: '00120',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0679, 35.7613],
            [-88.9683, 35.7175],
            [-89.0695, 35.6927],
            [-89.1736, 35.7365],
            [-89.0679, 35.7613],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700210',
        NAME: 'Bells City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '05',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84540360,
        AWATER: 137778,
        INTPTLAT: '+35.7162840',
        INTPTLON: '-089.0699909',
        ELSDLEA: '00210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1688, 36.085],
            [-84.1441, 36.1206],
            [-84.1167, 36.1197],
            [-84.0961, 36.0919],
            [-84.1141, 36.0843],
            [-84.1458, 36.0566],
            [-84.1688, 36.085],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700720',
        NAME: 'Clinton City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 29621441,
        AWATER: 1527971,
        INTPTLAT: '+36.0973382',
        INTPTLON: '-084.1289452',
        ELSDLEA: '00720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9851, 35.5282],
            [-85.0067, 35.4648],
            [-85.0192, 35.4622],
            [-85.0394, 35.4851],
            [-85.0225, 35.4902],
            [-85.0109, 35.5191],
            [-84.9851, 35.5282],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700930',
        NAME: 'Dayton City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19820183,
        AWATER: 601110,
        INTPTLAT: '+35.4921258',
        INTPTLON: '-085.0118644',
        ELSDLEA: '00930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5444, 35.3238],
            [-84.5393, 35.3393],
            [-84.5289, 35.3532],
            [-84.5169, 35.352],
            [-84.5194, 35.3313],
            [-84.5292, 35.3124],
            [-84.5444, 35.3238],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701140',
        NAME: 'Etowah City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 7561698,
        AWATER: 0,
        INTPTLAT: '+35.3384163',
        INTPTLON: '-084.5280838',
        ELSDLEA: '01140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9133, 35.9404],
            [-86.8666, 35.9428],
            [-86.8014, 35.9198],
            [-86.8366, 35.886],
            [-86.9044, 35.9065],
            [-86.9133, 35.9404],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701260',
        NAME: 'Franklin Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51974306,
        AWATER: 343015,
        INTPTLAT: '+35.9262759',
        INTPTLON: '-086.8548995',
        ELSDLEA: '01260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4168, 36.2485],
            [-86.3212, 36.2617],
            [-86.2465, 36.2397],
            [-86.2686, 36.1575],
            [-86.39, 36.1903],
            [-86.4168, 36.2485],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702370',
        NAME: 'Lebanon Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127439590,
        AWATER: 10591,
        INTPTLAT: '+36.2141551',
        INTPTLON: '-086.3144271',
        ELSDLEA: '02370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4082, 35.6804],
            [-88.366, 35.6683],
            [-88.3706, 35.6384],
            [-88.4226, 35.6239],
            [-88.4098, 35.6535],
            [-88.4082, 35.6804],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702460',
        NAME: 'Lexington City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31939348,
        AWATER: 485317,
        INTPTLAT: '+35.6554456',
        INTPTLON: '-088.4107165',
        ELSDLEA: '02460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1213, 35.4106],
            [-86.1215, 35.4107],
            [-86.0935, 35.4592],
            [-86.0522, 35.4448],
            [-86.1213, 35.4106],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702610',
        NAME: 'Manchester City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '09',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33660103,
        AWATER: 17785,
        INTPTLAT: '+35.4595494',
        INTPTLON: '-086.0825394',
        ELSDLEA: '02610',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.4755, 35.9017],
            [-86.3969, 35.9196],
            [-86.347, 35.899],
            [-86.3466, 35.8344],
            [-86.3908, 35.7768],
            [-86.4792, 35.8092],
            [-86.4755, 35.9017],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703150',
        NAME: 'Murfreesboro City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145991261,
        AWATER: 387261,
        INTPTLAT: '+35.8521638',
        INTPTLON: '-086.4160223',
        ELSDLEA: '03150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2755, 35.9728],
            [-83.2613, 35.9692],
            [-83.2534, 35.9712],
            [-83.2476, 35.9727],
            [-83.2454, 35.9723],
            [-83.2214, 35.9753],
            [-83.2191, 35.9754],
            [-83.2076, 35.9753],
            [-83.2078, 35.9864],
            [-83.191, 35.9788],
            [-83.1751, 35.9773],
            [-83.1726, 35.9595],
            [-83.1947, 35.9366],
            [-83.2067, 35.9473],
            [-83.2041, 35.9663],
            [-83.2199, 35.9754],
            [-83.2418, 35.9725],
            [-83.2496, 35.9677],
            [-83.2734, 35.9696],
            [-83.2755, 35.9728],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703210',
        NAME: 'Newport City Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14319881,
        AWATER: 0,
        INTPTLAT: '+35.9613606',
        INTPTLON: '-083.1976748',
        ELSDLEA: '03210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3902, 36.3013],
            [-88.3465, 36.3379],
            [-88.2633, 36.3072],
            [-88.2744, 36.2752],
            [-88.361, 36.2642],
            [-88.3858, 36.2796],
            [-88.3902, 36.3013],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703360',
        NAME: 'Paris City Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65254054,
        AWATER: 165437,
        INTPTLAT: '+36.2984916',
        INTPTLON: '-088.3247053',
        ELSDLEA: '03360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.9944, 36.401],
            [-83.0203, 36.3938],
            [-83.0073, 36.4141],
            [-82.9944, 36.401],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703660',
        NAME: 'Rogersville Town Elementary School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8793013,
        AWATER: 0,
        INTPTLAT: '+36.4079657',
        INTPTLON: '-083.0037857',
        ELSDLEA: '03660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5258, 35.6242],
            [-84.4868, 35.6584],
            [-84.3661, 35.647],
            [-84.3975, 35.5549],
            [-84.4711, 35.5167],
            [-84.5224, 35.6078],
            [-84.5224, 35.6186],
            [-84.5258, 35.6242],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704050',
        NAME: 'Sweetwater City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163406731,
        AWATER: 6377,
        INTPTLAT: '+35.6030282',
        INTPTLON: '-084.4437669',
        ELSDLEA: '04050',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2553, 36.2891],
            [-83.1517, 36.3182],
            [-83.0823, 36.2423],
            [-83.1661, 36.1809],
            [-83.2346, 36.0851],
            [-83.2937, 36.1382],
            [-83.4671, 36.1747],
            [-83.3552, 36.2826],
            [-83.2553, 36.2891],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700001',
        NAME: 'Hamblen County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 417437884,
        AWATER: 37890943,
        INTPTLAT: '+36.2183967',
        INTPTLON: '-083.2660711',
        ELSDLEA: '',
        UNSDLEA: '00001',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0068, 35.8496],
            [-83.9931, 35.8812],
            [-83.9637, 35.869],
            [-83.9437, 35.872],
            [-83.956, 35.7818],
            [-83.9673, 35.769],
            [-83.9656, 35.7658],
            [-83.983, 35.7665],
            [-83.9985, 35.7687],
            [-84.0022, 35.7689],
            [-84.008, 35.7687],
            [-84.0117, 35.768],
            [-84.0068, 35.8496],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700060',
        NAME: 'Alcoa City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37727670,
        AWATER: 2383886,
        INTPTLAT: '+35.8069849',
        INTPTLON: '-083.9730400',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7972, 36.6349],
            [-87.4363, 36.6408],
            [-87.4796, 36.5511],
            [-87.6261, 36.5431],
            [-87.6949, 36.554],
            [-87.7972, 36.6349],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700078',
        NAME: 'Fort Campbell Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 275411034,
        AWATER: 490282,
        INTPTLAT: '+36.5957358',
        INTPTLON: '-087.6010400',
        ELSDLEA: '',
        UNSDLEA: '00078',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.441, 36.1635],
            [-84.3727, 36.2168],
            [-84.2275, 36.2449],
            [-84.0696, 36.231],
            [-84.0043, 36.2704],
            [-83.9417, 36.1864],
            [-84.094, 36.0689],
            [-84.1579, 35.9913],
            [-84.2208, 36.059],
            [-84.3254, 36.0055],
            [-84.3418, 36.049],
            [-84.441, 36.1635],
          ],
          [
            [-84.1688, 36.085],
            [-84.1458, 36.0566],
            [-84.1141, 36.0843],
            [-84.0961, 36.0919],
            [-84.1167, 36.1197],
            [-84.1441, 36.1206],
            [-84.1688, 36.085],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700090',
        NAME: 'Anderson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 729663057,
        AWATER: 10871441,
        INTPTLAT: '+36.1454415',
        INTPTLON: '-084.1876563',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2094, 35.0265],
            [-90.1736, 35.1181],
            [-90.0757, 35.1281],
            [-90.0686, 35.1531],
            [-90.0976, 35.3006],
            [-90.0618, 35.3862],
            [-90.0543, 35.3893],
            [-90.0416, 35.3966],
            [-89.6328, 35.3758],
            [-89.6443, 34.9953],
            [-89.7243, 34.9948],
            [-90.3026, 34.9957],
            [-90.2094, 35.0265],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700148',
        NAME: 'Shelby County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1925262558,
        AWATER: 26191746,
        INTPTLAT: '+35.1852323',
        INTPTLON: '-089.8867258',
        ELSDLEA: '',
        UNSDLEA: '00148',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6394, 35.6859],
            [-86.5431, 35.6976],
            [-86.4137, 35.6232],
            [-86.3443, 35.6637],
            [-86.2452, 35.6319],
            [-86.2576, 35.4125],
            [-86.4217, 35.3193],
            [-86.5253, 35.3547],
            [-86.5995, 35.365],
            [-86.6582, 35.4353],
            [-86.6394, 35.6859],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700180',
        NAME: 'Bedford County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1226717421,
        AWATER: 2983130,
        INTPTLAT: '+35.5136604',
        INTPTLON: '-086.4582939',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2162, 35.9048],
            [-88.2126, 36.1203],
            [-88.0829, 36.2604],
            [-88.0877, 36.3592],
            [-87.9909, 36.3601],
            [-87.9807, 36.3533],
            [-87.9496, 36.2432],
            [-87.919, 36.13],
            [-88.0008, 36.0281],
            [-88.0129, 35.9466],
            [-87.9258, 35.9304],
            [-87.9627, 35.8409],
            [-87.9707, 35.8157],
            [-88.0336, 35.8437],
            [-88.1779, 35.8458],
            [-88.2162, 35.9048],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700240',
        NAME: 'Benton County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1021240555,
        AWATER: 108591318,
        INTPTLAT: '+36.0692530',
        INTPTLON: '-088.0712118',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4238, 35.5674],
            [-85.2654, 35.7212],
            [-85.2541, 35.7656],
            [-84.9161, 35.7619],
            [-85.1078, 35.5714],
            [-85.1352, 35.4586],
            [-85.2259, 35.3543],
            [-85.4033, 35.4923],
            [-85.4238, 35.5674],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700270',
        NAME: 'Bledsoe County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1052635818,
        AWATER: 838360,
        INTPTLAT: '+35.5936683',
        INTPTLON: '-085.2059790',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1883, 35.6105],
            [-84.1517, 35.6275],
            [-84.1663, 35.8051],
            [-84.0068, 35.8496],
            [-84.0117, 35.768],
            [-83.9554, 35.7242],
            [-83.9178, 35.7695],
            [-83.956, 35.7818],
            [-83.9437, 35.872],
            [-83.8535, 35.8411],
            [-83.7943, 35.8871],
            [-83.7052, 35.7116],
            [-83.6628, 35.6906],
            [-83.663, 35.5691],
            [-83.7717, 35.5621],
            [-83.9527, 35.4607],
            [-83.9611, 35.4637],
            [-84.0962, 35.5755],
            [-84.1883, 35.6105],
          ],
          [
            [-83.9931, 35.8812],
            [-83.9926, 35.8797],
            [-83.9651, 35.8673],
            [-83.9637, 35.869],
            [-83.9931, 35.8812],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700300',
        NAME: 'Blount County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1367428011,
        AWATER: 17924198,
        INTPTLAT: '+35.6665197',
        INTPTLON: '-083.9263240',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9463, 35.2877],
            [-84.8602, 35.3501],
            [-84.7036, 35.241],
            [-84.6999, 35.171],
            [-84.7759, 34.9879],
            [-84.8105, 34.9876],
            [-84.977, 34.9877],
            [-85.0236, 35.1251],
            [-84.9463, 35.2877],
          ],
          [
            [-84.959, 35.1488],
            [-84.867, 35.1256],
            [-84.8136, 35.1893],
            [-84.905, 35.2068],
            [-84.959, 35.1488],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700330',
        NAME: 'Bradley County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 781838518,
        AWATER: 6940059,
        INTPTLAT: '+35.0711481',
        INTPTLON: '-084.9411688',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.2636, 36.5956],
            [-82.2434, 36.5957],
            [-82.1461, 36.5947],
            [-82.096, 36.5947],
            [-82.2666, 36.5007],
            [-82.2636, 36.5956],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700360',
        NAME: 'Bristol City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80996411,
        AWATER: 370551,
        INTPTLAT: '+36.5585486',
        INTPTLON: '-082.2170009',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2613, 36.5918],
            [-84.2273, 36.592],
            [-83.9876, 36.5895],
            [-83.9056, 36.42],
            [-84.0043, 36.2704],
            [-84.0696, 36.231],
            [-84.2275, 36.2449],
            [-84.3727, 36.2168],
            [-84.3299, 36.2851],
            [-84.3499, 36.4663],
            [-84.2596, 36.514],
            [-84.2613, 36.5918],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700420',
        NAME: 'Campbell County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1243580189,
        AWATER: 46494677,
        INTPTLAT: '+36.4015922',
        INTPTLON: '-084.1592495',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1532, 35.9544],
            [-86.0148, 35.9611],
            [-85.8852, 35.8397],
            [-85.9849, 35.7346],
            [-85.9851, 35.6601],
            [-86.1437, 35.6617],
            [-86.2071, 35.7038],
            [-86.1532, 35.9544],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700450',
        NAME: 'Cannon County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 687991717,
        AWATER: 156872,
        INTPTLAT: '+35.8083940',
        INTPTLON: '-086.0624044',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3294, 36.2867],
            [-82.3228, 36.304],
            [-82.3137, 36.2953],
            [-82.2874, 36.2974],
            [-82.2018, 36.3551],
            [-82.2432, 36.3574],
            [-82.3033, 36.2992],
            [-82.322, 36.3064],
            [-82.3149, 36.3269],
            [-82.3036, 36.3596],
            [-82.3, 36.3718],
            [-82.2999, 36.3965],
            [-82.1881, 36.4006],
            [-81.9838, 36.5083],
            [-81.9515, 36.4739],
            [-82.0441, 36.3637],
            [-81.933, 36.2649],
            [-82.0331, 36.1204],
            [-82.0804, 36.103],
            [-82.2221, 36.1569],
            [-82.2564, 36.2332],
            [-82.3405, 36.2528],
            [-82.3294, 36.2867],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700510',
        NAME: 'Carter County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 858410652,
        AWATER: 16242593,
        INTPTLAT: '+36.2847441',
        INTPTLON: '-082.1265932',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.1204, 36.4555],
            [-86.9881, 36.3692],
            [-86.9132, 36.3826],
            [-86.9744, 36.2499],
            [-86.9977, 36.1285],
            [-87.0541, 36.0455],
            [-87.1826, 36.0497],
            [-87.1874, 36.2152],
            [-87.1566, 36.3077],
            [-87.2865, 36.3219],
            [-87.2805, 36.3545],
            [-87.1204, 36.4555],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700570',
        NAME: 'Cheatham County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 783308290,
        AWATER: 11964158,
        INTPTLAT: '+36.2551687',
        INTPTLON: '-087.1008077',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8416, 35.4278],
            [-88.6759, 35.4892],
            [-88.6136, 35.5881],
            [-88.5054, 35.512],
            [-88.4146, 35.4823],
            [-88.3608, 35.419],
            [-88.3626, 35.381],
            [-88.5718, 35.3821],
            [-88.6409, 35.361],
            [-88.7198, 35.2475],
            [-88.7818, 35.2476],
            [-88.8416, 35.4278],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700600',
        NAME: 'Chester County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 740052030,
        AWATER: 575590,
        INTPTLAT: '+35.4202530',
        INTPTLON: '-088.6113533',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.9876, 36.5895],
            [-83.9308, 36.5877],
            [-83.6908, 36.5826],
            [-83.6754, 36.6008],
            [-83.4721, 36.5973],
            [-83.3983, 36.5349],
            [-83.3868, 36.4132],
            [-83.466, 36.3692],
            [-83.6674, 36.3444],
            [-83.7662, 36.3992],
            [-83.9056, 36.42],
            [-83.9876, 36.5895],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700630',
        NAME: 'Claiborne County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1125572374,
        AWATER: 18099460,
        INTPTLAT: '+36.5015422',
        INTPTLON: '-083.6607216',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7887, 36.622],
            [-85.4364, 36.6187],
            [-85.296, 36.6257],
            [-85.2763, 36.6268],
            [-85.2849, 36.5313],
            [-85.4388, 36.5011],
            [-85.4954, 36.4031],
            [-85.576, 36.4716],
            [-85.7041, 36.5224],
            [-85.8139, 36.4995],
            [-85.7887, 36.622],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700660',
        NAME: 'Clay County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 612626437,
        AWATER: 59071078,
        INTPTLAT: '+36.5457881',
        INTPTLON: '-085.5458160',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.959, 35.1488],
            [-84.905, 35.2068],
            [-84.8136, 35.1893],
            [-84.867, 35.1256],
            [-84.959, 35.1488],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700690',
        NAME: 'Cleveland City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69650631,
        AWATER: 19082,
        INTPTLAT: '+35.1821006',
        INTPTLON: '-084.8709686',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.3108, 35.8958],
            [-83.2346, 36.0851],
            [-83.1661, 36.1809],
            [-82.8985, 35.9451],
            [-82.8966, 35.8785],
            [-82.9616, 35.7911],
            [-83.0787, 35.7895],
            [-83.2555, 35.715],
            [-83.2636, 35.819],
            [-83.3108, 35.8958],
          ],
          [
            [-83.2755, 35.9728],
            [-83.2734, 35.9696],
            [-83.2496, 35.9677],
            [-83.2418, 35.9725],
            [-83.2199, 35.9754],
            [-83.2041, 35.9663],
            [-83.2067, 35.9473],
            [-83.1947, 35.9366],
            [-83.1726, 35.9595],
            [-83.1751, 35.9773],
            [-83.191, 35.9788],
            [-83.2078, 35.9864],
            [-83.2076, 35.9753],
            [-83.2191, 35.9754],
            [-83.2214, 35.9753],
            [-83.2454, 35.9723],
            [-83.2476, 35.9727],
            [-83.2534, 35.9712],
            [-83.2613, 35.9692],
            [-83.2755, 35.9728],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700750',
        NAME: 'Cocke County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1111253245,
        AWATER: 22116606,
        INTPTLAT: '+35.9161980',
        INTPTLON: '-083.1192242',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2576, 35.4125],
            [-86.2452, 35.6319],
            [-86.2071, 35.7038],
            [-86.1437, 35.6617],
            [-85.9851, 35.6601],
            [-85.877, 35.5241],
            [-85.8811, 35.3488],
            [-85.9136, 35.2892],
            [-86.0365, 35.3437],
            [-86.1819, 35.3517],
            [-86.1213, 35.4106],
            [-86.0522, 35.4448],
            [-86.0935, 35.4592],
            [-86.1215, 35.4107],
            [-86.2517, 35.404],
            [-86.2576, 35.4125],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700780',
        NAME: 'Coffee County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1026493481,
        AWATER: 14227108,
        INTPTLAT: '+35.3984984',
        INTPTLON: '-085.9744527',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.0066, 35.8327],
              [-88.9387, 35.8142],
              [-88.9346, 35.8115],
              [-88.9162, 35.7973],
              [-88.9683, 35.7175],
              [-89.0679, 35.7613],
              [-89.0066, 35.8327],
            ],
          ],
          [
            [
              [-89.3428, 35.8809],
              [-89.2802, 35.8864],
              [-89.1888, 35.9998],
              [-89.136, 35.9678],
              [-89.0656, 35.8515],
              [-89.1563, 35.8231],
              [-89.1897, 35.7427],
              [-89.2712, 35.7549],
              [-89.356, 35.8176],
              [-89.3428, 35.8809],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700850',
        NAME: 'Crockett County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 473171169,
        AWATER: 390618,
        INTPTLAT: '+35.8433854',
        INTPTLON: '-089.1539467',
        ELSDLEA: '',
        UNSDLEA: '00850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2694, 35.7934],
            [-85.2225, 35.8774],
            [-85.2642, 35.9792],
            [-85.2358, 36.1058],
            [-85.1002, 36.1386],
            [-84.9078, 36.1563],
            [-84.7207, 35.9949],
            [-84.6806, 35.9085],
            [-84.7819, 35.825],
            [-84.9161, 35.7619],
            [-85.2541, 35.7656],
            [-85.2535, 35.7669],
            [-85.2727, 35.7877],
            [-85.2694, 35.7934],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700900',
        NAME: 'Cumberland County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1763849866,
        AWATER: 9823729,
        INTPTLAT: '+35.9523984',
        INTPTLON: '-084.9947614',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1792, 35.8168],
            [-88.1779, 35.8458],
            [-88.0336, 35.8437],
            [-87.9707, 35.8157],
            [-88.0348, 35.7173],
            [-88.0358, 35.6218],
            [-87.9746, 35.521],
            [-88.0077, 35.4234],
            [-88.0247, 35.3923],
            [-88.1897, 35.3843],
            [-88.2417, 35.4233],
            [-88.2431, 35.5289],
            [-88.1905, 35.6091],
            [-88.1792, 35.8168],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700960',
        NAME: 'Decatur County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 864654132,
        AWATER: 28588648,
        INTPTLAT: '+35.6031362',
        INTPTLON: '-088.1102917',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.0597, 36.086],
            [-85.8074, 36.1316],
            [-85.776, 36.0783],
            [-85.6446, 36.0151],
            [-85.6449, 35.8895],
            [-85.6821, 35.8313],
            [-85.8852, 35.8397],
            [-86.0148, 35.9611],
            [-86.0597, 36.086],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4700990',
        NAME: 'DeKalb County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 788261385,
        AWATER: 63808936,
        INTPTLAT: '+35.9864712',
        INTPTLON: '-085.8349139',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5668, 36.1779],
            [-87.5135, 36.3347],
            [-87.2865, 36.3219],
            [-87.1566, 36.3077],
            [-87.1874, 36.2152],
            [-87.1826, 36.0497],
            [-87.2042, 35.9592],
            [-87.5345, 35.9931],
            [-87.5668, 36.1779],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701020',
        NAME: 'Dickson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1268824237,
        AWATER: 3689043,
        INTPTLAT: '+36.1455325',
        INTPTLON: '-087.3641546',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7331, 36.0006],
            [-89.6854, 36.0459],
            [-89.5931, 36.1297],
            [-89.6139, 36.1767],
            [-89.6295, 36.1854],
            [-89.483, 36.2127],
            [-89.1545, 36.2047],
            [-89.1634, 36.026],
            [-89.1888, 35.9998],
            [-89.2802, 35.8864],
            [-89.3428, 35.8809],
            [-89.407, 35.9466],
            [-89.4909, 35.9477],
            [-89.6448, 35.9044],
            [-89.7331, 36.0006],
          ],
          [
            [-89.4286, 36.0418],
            [-89.3522, 36.018],
            [-89.3269, 36.0611],
            [-89.4156, 36.0648],
            [-89.4286, 36.0418],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701050',
        NAME: 'Dyer County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1284593582,
        AWATER: 36479194,
        INTPTLAT: '+36.0447113',
        INTPTLON: '-089.4452310',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4286, 36.0418],
            [-89.4156, 36.0648],
            [-89.3269, 36.0611],
            [-89.3522, 36.018],
            [-89.4286, 36.0418],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701080',
        NAME: 'Dyersburg City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42329274,
        AWATER: 298857,
        INTPTLAT: '+36.0435078',
        INTPTLON: '-089.3797963',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.322, 36.3064],
            [-82.3033, 36.2992],
            [-82.2432, 36.3574],
            [-82.2018, 36.3551],
            [-82.2874, 36.2974],
            [-82.3137, 36.2953],
            [-82.3228, 36.304],
            [-82.322, 36.3064],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701110',
        NAME: 'Elizabethton City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25300716,
        AWATER: 414295,
        INTPTLAT: '+36.3362068',
        INTPTLON: '-082.2325293',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6328, 35.3758],
            [-89.4742, 35.403],
            [-89.1839, 35.3971],
            [-89.1983, 34.9945],
            [-89.3527, 34.9939],
            [-89.6443, 34.9953],
            [-89.6328, 35.3758],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701170',
        NAME: 'Fayette County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1825374169,
        AWATER: 3774866,
        INTPTLAT: '+35.1969933',
        INTPTLON: '-089.4138027',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6086, 35.1567],
            [-86.5505, 35.1554],
            [-86.5817, 35.1124],
            [-86.6086, 35.1567],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701200',
        NAME: 'Fayetteville City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25132136,
        AWATER: 0,
        INTPTLAT: '+35.1489894',
        INTPTLON: '-086.5636195',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.1204, 36.4072],
            [-85.0439, 36.5588],
            [-84.9431, 36.5813],
            [-84.7318, 36.5247],
            [-84.6591, 36.3952],
            [-84.7008, 36.3708],
            [-84.7979, 36.2969],
            [-84.8661, 36.2929],
            [-84.9078, 36.1563],
            [-85.1002, 36.1386],
            [-85.1196, 36.1447],
            [-85.0825, 36.1962],
            [-85.1222, 36.3046],
            [-85.1204, 36.4072],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701230',
        NAME: 'Fentress County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1291421150,
        AWATER: 829331,
        INTPTLAT: '+36.3698878',
        INTPTLON: '-084.9377640',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2615, 35.3336],
            [-86.2008, 35.3354],
            [-86.1895, 35.3388],
            [-86.1819, 35.3517],
            [-86.0365, 35.3437],
            [-85.9136, 35.2892],
            [-85.873, 35.2234],
            [-85.8639, 34.9883],
            [-86.3113, 34.9911],
            [-86.3188, 34.9912],
            [-86.3182, 35.127],
            [-86.2615, 35.3336],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701290',
        NAME: 'Franklin County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1436108253,
        AWATER: 55079773,
        INTPTLAT: '+35.1559259',
        INTPTLON: '-086.0992032',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8481, 36.1337],
            [-88.6927, 36.0627],
            [-88.696, 35.9936],
            [-88.8335, 35.9787],
            [-88.9051, 36.0316],
            [-88.8481, 36.1337],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701390',
        NAME: 'Bradford Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206542782,
        AWATER: 350983,
        INTPTLAT: '+36.0582294',
        INTPTLON: '-088.7994973',
        ELSDLEA: '',
        UNSDLEA: '01390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.9346, 35.8115],
              [-88.893, 35.7966],
              [-88.9162, 35.7973],
              [-88.9346, 35.8115],
            ],
          ],
          [
            [
              [-89.1545, 36.2047],
              [-88.9598, 36.2228],
              [-88.8481, 36.1337],
              [-88.9051, 36.0316],
              [-89.072, 36.0043],
              [-89.0622, 35.9172],
              [-88.8803, 35.8826],
              [-88.8415, 35.9137],
              [-88.7021, 35.8616],
              [-88.7068, 35.7911],
              [-88.8676, 35.7957],
              [-88.8853, 35.8428],
              [-88.9387, 35.8142],
              [-89.0066, 35.8327],
              [-89.0656, 35.8515],
              [-89.136, 35.9678],
              [-89.1888, 35.9998],
              [-89.1634, 36.026],
              [-89.1545, 36.2047],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701400',
        NAME: 'Gibson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 868145432,
        AWATER: 318537,
        INTPTLAT: '+36.1076173',
        INTPTLON: '-089.0845563',
        ELSDLEA: '',
        UNSDLEA: '01400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2066, 35.4332],
            [-87.1209, 35.4557],
            [-86.9608, 35.417],
            [-86.9022, 35.3163],
            [-86.8283, 35.2637],
            [-86.8363, 34.9919],
            [-87.2108, 34.999],
            [-87.2241, 34.9994],
            [-87.2066, 35.4332],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701410',
        NAME: 'Giles County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1582295000,
        AWATER: 642572,
        INTPTLAT: '+35.2027228',
        INTPTLON: '-087.0353190',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.7327, 36.1646],
            [-83.6674, 36.3444],
            [-83.466, 36.3692],
            [-83.3868, 36.4132],
            [-83.2806, 36.3947],
            [-83.2553, 36.2891],
            [-83.3552, 36.2826],
            [-83.4671, 36.1747],
            [-83.5978, 36.1642],
            [-83.6675, 36.0806],
            [-83.7327, 36.1646],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701440',
        NAME: 'Grainger County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 726729365,
        AWATER: 56637201,
        INTPTLAT: '+36.2774634',
        INTPTLON: '-083.5094926',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1661, 36.1809],
            [-83.0823, 36.2423],
            [-82.8405, 36.3707],
            [-82.7026, 36.4104],
            [-82.6296, 36.4115],
            [-82.6478, 36.1464],
            [-82.5951, 36.0962],
            [-82.6029, 36.0398],
            [-82.6364, 36.0659],
            [-82.7759, 36.0008],
            [-82.7875, 35.9522],
            [-82.8985, 35.9451],
            [-83.1661, 36.1809],
          ],
          [
            [-82.798, 36.1543],
            [-82.8819, 36.1734],
            [-82.8708, 36.1404],
            [-82.798, 36.1543],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701470',
        NAME: 'Greene County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1567033846,
        AWATER: 5099735,
        INTPTLAT: '+36.1795140',
        INTPTLON: '-082.8474854',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8819, 36.1734],
            [-82.798, 36.1543],
            [-82.8708, 36.1404],
            [-82.8819, 36.1734],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701500',
        NAME: 'Greeneville City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44312380,
        AWATER: 0,
        INTPTLAT: '+36.1680797',
        INTPTLON: '-082.8197399',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.9136, 35.2892],
            [-85.8811, 35.3488],
            [-85.877, 35.5241],
            [-85.6073, 35.5317],
            [-85.4856, 35.4064],
            [-85.5586, 35.3193],
            [-85.6904, 35.2861],
            [-85.7401, 35.2153],
            [-85.873, 35.2234],
            [-85.9136, 35.2892],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701530',
        NAME: 'Grundy County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 933549065,
        AWATER: 1752165,
        INTPTLAT: '+35.3933560',
        INTPTLON: '-085.7103893',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.3871, 35.1472],
            [-85.2259, 35.3543],
            [-85.1352, 35.4586],
            [-85.0163, 35.4093],
            [-84.9463, 35.2877],
            [-85.0236, 35.1251],
            [-84.977, 34.9877],
            [-84.9799, 34.9876],
            [-85.2651, 34.9851],
            [-85.3639, 34.9834],
            [-85.4741, 34.983],
            [-85.3855, 35.0432],
            [-85.3871, 35.1472],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701590',
        NAME: 'Hamilton County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1404937551,
        AWATER: 86586076,
        INTPTLAT: '+35.1889605',
        INTPTLON: '-085.1531341',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.4721, 36.5973],
            [-82.9851, 36.5937],
            [-82.8304, 36.5938],
            [-82.9505, 36.543],
            [-83.0108, 36.5637],
            [-83.1194, 36.5143],
            [-83.2806, 36.3947],
            [-83.3868, 36.4132],
            [-83.3983, 36.5349],
            [-83.4721, 36.5973],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701620',
        NAME: 'Hancock County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 575820509,
        AWATER: 2984310,
        INTPTLAT: '+36.5214195',
        INTPTLON: '-083.2274526',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1839, 35.3971],
            [-89.0789, 35.4314],
            [-88.8416, 35.4278],
            [-88.7818, 35.2476],
            [-88.7866, 34.9953],
            [-88.8231, 34.9952],
            [-89.0171, 34.995],
            [-89.1983, 34.9945],
            [-89.1839, 35.3971],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701650',
        NAME: 'Hardeman County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1729454850,
        AWATER: 6823705,
        INTPTLAT: '+35.2187853',
        INTPTLON: '-088.9886970',
        ELSDLEA: '',
        UNSDLEA: '01650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3626, 35.381],
            [-88.3608, 35.419],
            [-88.2417, 35.4233],
            [-88.1897, 35.3843],
            [-88.0247, 35.3923],
            [-87.9816, 35.2957],
            [-87.9849, 35.0059],
            [-88.2001, 34.9956],
            [-88.3635, 34.9955],
            [-88.3805, 34.9956],
            [-88.3626, 35.381],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701680',
        NAME: 'Hardin County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1495289678,
        AWATER: 49111069,
        INTPTLAT: '+35.2018926',
        INTPTLON: '-088.1856958',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.2806, 36.3947],
            [-83.1194, 36.5143],
            [-83.0108, 36.5637],
            [-82.9505, 36.543],
            [-82.8304, 36.5938],
            [-82.6092, 36.5941],
            [-82.6113, 36.5345],
            [-82.6108, 36.5279],
            [-82.6523, 36.4822],
            [-82.6807, 36.4318],
            [-82.7026, 36.4104],
            [-82.8405, 36.3707],
            [-83.0823, 36.2423],
            [-83.1517, 36.3182],
            [-83.2553, 36.2891],
            [-83.2806, 36.3947],
          ],
          [
            [-83.0203, 36.3938],
            [-82.9944, 36.401],
            [-83.0073, 36.4141],
            [-83.0203, 36.3938],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701740',
        NAME: 'Hawkins County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1241014231,
        AWATER: 32447078,
        INTPTLAT: '+36.4522106',
        INTPTLON: '-082.9313857',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.356, 35.8176],
            [-89.2712, 35.7549],
            [-89.1897, 35.7427],
            [-89.1736, 35.7365],
            [-89.0695, 35.6927],
            [-89.0789, 35.4314],
            [-89.1839, 35.3971],
            [-89.4742, 35.403],
            [-89.4699, 35.5466],
            [-89.5021, 35.5806],
            [-89.4018, 35.8227],
            [-89.356, 35.8176],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701770',
        NAME: 'Haywood County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1380737639,
        AWATER: 2472509,
        INTPTLAT: '+35.5866898',
        INTPTLON: '-089.2825359',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6136, 35.5881],
            [-88.6069, 35.7894],
            [-88.5069, 35.8236],
            [-88.1792, 35.8168],
            [-88.1905, 35.6091],
            [-88.2431, 35.5289],
            [-88.2417, 35.4233],
            [-88.3608, 35.419],
            [-88.4146, 35.4823],
            [-88.5054, 35.512],
            [-88.6136, 35.5881],
          ],
          [
            [-88.4082, 35.6804],
            [-88.4098, 35.6535],
            [-88.4226, 35.6239],
            [-88.3706, 35.6384],
            [-88.366, 35.6683],
            [-88.4082, 35.6804],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701800',
        NAME: 'Henderson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1314877346,
        AWATER: 14734024,
        INTPTLAT: '+35.6263618',
        INTPTLON: '-088.3950757',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5164, 36.5016],
            [-88.4891, 36.5012],
            [-88.0533, 36.4971],
            [-88.0585, 36.4392],
            [-87.9909, 36.3601],
            [-88.0877, 36.3592],
            [-88.0829, 36.2604],
            [-88.2126, 36.1203],
            [-88.2862, 36.1476],
            [-88.3812, 36.1488],
            [-88.5303, 36.152],
            [-88.5164, 36.5016],
          ],
          [
            [-88.3902, 36.3013],
            [-88.3858, 36.2796],
            [-88.361, 36.2642],
            [-88.2744, 36.2752],
            [-88.2633, 36.3072],
            [-88.3465, 36.3379],
            [-88.3902, 36.3013],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701830',
        NAME: 'Henry County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1389905527,
        AWATER: 81577444,
        INTPTLAT: '+36.3102304',
        INTPTLON: '-088.2612084',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7168, 35.8387],
            [-87.707, 35.9059],
            [-87.5345, 35.9931],
            [-87.2042, 35.9592],
            [-87.2151, 35.8507],
            [-87.2341, 35.7247],
            [-87.3392, 35.6591],
            [-87.4596, 35.6151],
            [-87.5688, 35.6523],
            [-87.6582, 35.6093],
            [-87.7437, 35.664],
            [-87.7168, 35.8387],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701860',
        NAME: 'Hickman County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1586315572,
        AWATER: 382861,
        INTPTLAT: '+35.8022905',
        INTPTLON: '-087.4671007',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2862, 36.1476],
            [-88.2126, 36.1203],
            [-88.2162, 35.9048],
            [-88.3263, 35.9672],
            [-88.3365, 36.0379],
            [-88.2862, 36.1476],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701890',
        NAME: 'Hollow Rock-Bruceton Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 244961572,
        AWATER: 257801,
        INTPTLAT: '+36.0154408',
        INTPTLON: '-088.2653531',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5924, 36.3677],
            [-87.5135, 36.3347],
            [-87.5668, 36.1779],
            [-87.7985, 36.2448],
            [-87.9496, 36.2432],
            [-87.9807, 36.3533],
            [-87.8218, 36.3287],
            [-87.7155, 36.3682],
            [-87.5924, 36.3677],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701920',
        NAME: 'Houston County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 518749052,
        AWATER: 17312555,
        INTPTLAT: '+36.2857824',
        INTPTLON: '-087.7055983',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8853, 35.8428],
            [-88.8676, 35.7957],
            [-88.893, 35.7966],
            [-88.9346, 35.8115],
            [-88.9387, 35.8142],
            [-88.8853, 35.8428],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701950',
        NAME: 'Humboldt City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 24223568,
        AWATER: 20689,
        INTPTLAT: '+35.8273948',
        INTPTLON: '-088.9044774',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9496, 36.2432],
            [-87.7985, 36.2448],
            [-87.5668, 36.1779],
            [-87.5345, 35.9931],
            [-87.707, 35.9059],
            [-87.7168, 35.8387],
            [-87.8742, 35.813],
            [-87.9627, 35.8409],
            [-87.9258, 35.9304],
            [-88.0129, 35.9466],
            [-88.0008, 36.0281],
            [-87.919, 36.13],
            [-87.9496, 36.2432],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4701980',
        NAME: 'Humphreys County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1374665923,
        AWATER: 67186918,
        INTPTLAT: '+36.0408218',
        INTPTLON: '-087.7904526',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5034, 36.0514],
            [-88.4336, 36.0565],
            [-88.3812, 36.1488],
            [-88.2862, 36.1476],
            [-88.3365, 36.0379],
            [-88.3263, 35.9672],
            [-88.3946, 35.9682],
            [-88.475, 35.906],
            [-88.5251, 35.9569],
            [-88.5034, 36.0514],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702010',
        NAME: 'Huntingdon Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 317242485,
        AWATER: 198905,
        INTPTLAT: '+36.0216333',
        INTPTLON: '-088.4030249',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.828, 36.4118],
            [-85.8139, 36.4995],
            [-85.7041, 36.5224],
            [-85.576, 36.4716],
            [-85.4954, 36.4031],
            [-85.4951, 36.3023],
            [-85.5126, 36.2569],
            [-85.6342, 36.2133],
            [-85.7801, 36.238],
            [-85.8465, 36.2882],
            [-85.828, 36.4118],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702070',
        NAME: 'Jackson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 799338036,
        AWATER: 28227391,
        INTPTLAT: '+36.3542410',
        INTPTLON: '-085.6740945',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6675, 36.0806],
            [-83.5978, 36.1642],
            [-83.4671, 36.1747],
            [-83.2937, 36.1382],
            [-83.2346, 36.0851],
            [-83.3108, 35.8958],
            [-83.4749, 35.9229],
            [-83.6733, 36.0385],
            [-83.6675, 36.0806],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702100',
        NAME: 'Jefferson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 711854966,
        AWATER: 102190541,
        INTPTLAT: '+36.0484787',
        INTPTLON: '-083.4409664',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5233, 36.4343],
            [-82.523, 36.4343],
            [-82.5206, 36.4347],
            [-82.4393, 36.388],
            [-82.3611, 36.4026],
            [-82.3585, 36.4024],
            [-82.3511, 36.4019],
            [-82.3481, 36.4012],
            [-82.3471, 36.3999],
            [-82.3463, 36.3989],
            [-82.3, 36.3718],
            [-82.3036, 36.3596],
            [-82.321, 36.3555],
            [-82.3149, 36.3269],
            [-82.322, 36.3064],
            [-82.3228, 36.304],
            [-82.3294, 36.2867],
            [-82.3938, 36.2732],
            [-82.4147, 36.3681],
            [-82.5233, 36.4343],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702130',
        NAME: 'Johnson City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98092412,
        AWATER: 579361,
        INTPTLAT: '+36.3342537',
        INTPTLON: '-082.3776486',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9838, 36.5083],
            [-81.8267, 36.6142],
            [-81.6469, 36.6119],
            [-81.6774, 36.5882],
            [-81.7286, 36.3912],
            [-81.9181, 36.2871],
            [-81.933, 36.2649],
            [-82.0441, 36.3637],
            [-81.9515, 36.4739],
            [-81.9838, 36.5083],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702160',
        NAME: 'Johnson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 773046196,
        AWATER: 11001626,
        INTPTLAT: '+36.4532029',
        INTPTLON: '-081.8612467',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6108, 36.5279],
            [-82.6113, 36.5345],
            [-82.6092, 36.5941],
            [-82.5711, 36.5946],
            [-82.4714, 36.5704],
            [-82.5197, 36.4348],
            [-82.5206, 36.4347],
            [-82.523, 36.4343],
            [-82.5453, 36.4586],
            [-82.6523, 36.4822],
            [-82.6108, 36.5279],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702190',
        NAME: 'Kingsport City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 138843600,
        AWATER: 2518765,
        INTPTLAT: '+36.5254170',
        INTPTLON: '-082.5478726',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.1579, 35.9913],
            [-84.094, 36.0689],
            [-83.9417, 36.1864],
            [-83.7327, 36.1646],
            [-83.6675, 36.0806],
            [-83.6733, 36.0385],
            [-83.6546, 35.9719],
            [-83.7943, 35.8871],
            [-83.8535, 35.8411],
            [-83.9437, 35.872],
            [-83.9637, 35.869],
            [-83.9651, 35.8673],
            [-83.9926, 35.8797],
            [-83.9931, 35.8812],
            [-84.0068, 35.8496],
            [-84.1663, 35.8051],
            [-84.2634, 35.8969],
            [-84.1579, 35.9913],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702220',
        NAME: 'Knox County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1316273990,
        AWATER: 45712672,
        INTPTLAT: '+35.9927265',
        INTPTLON: '-083.9377209',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7007, 36.2473],
            [-89.5364, 36.2728],
            [-89.545, 36.3368],
            [-89.5196, 36.4687],
            [-89.5392, 36.4979],
            [-89.4854, 36.4975],
            [-89.4173, 36.499],
            [-89.3461, 36.5032],
            [-89.3601, 36.4093],
            [-89.4437, 36.2366],
            [-89.483, 36.2127],
            [-89.6295, 36.1854],
            [-89.7004, 36.2318],
            [-89.7007, 36.2473],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702280',
        NAME: 'Lake County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 429381245,
        AWATER: 72799687,
        INTPTLAT: '+36.3339054',
        INTPTLON: '-089.4855365',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9446, 35.7412],
            [-89.8209, 35.7569],
            [-89.7017, 35.8242],
            [-89.6448, 35.9044],
            [-89.4909, 35.9477],
            [-89.407, 35.9466],
            [-89.3428, 35.8809],
            [-89.356, 35.8176],
            [-89.4018, 35.8227],
            [-89.5021, 35.5806],
            [-89.613, 35.6432],
            [-89.7931, 35.6374],
            [-89.9098, 35.5379],
            [-89.9249, 35.6104],
            [-89.9446, 35.7412],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702310',
        NAME: 'Lauderdale County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1222389175,
        AWATER: 92233934,
        INTPTLAT: '+35.7629507',
        INTPTLON: '-089.6277318',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5754, 35.3985],
            [-87.2945, 35.4447],
            [-87.2066, 35.4332],
            [-87.2241, 34.9994],
            [-87.606, 35.0034],
            [-87.5778, 35.1594],
            [-87.5754, 35.3985],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702340',
        NAME: 'Lawrence County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1598356717,
        AWATER: 2228109,
        INTPTLAT: '+35.2204764',
        INTPTLON: '-087.3965460',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.2924, 35.7956],
            [-84.302, 35.7977],
            [-84.2626, 35.8382],
            [-84.2489, 35.7946],
            [-84.2924, 35.7956],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702400',
        NAME: 'Lenoir City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21868425,
        AWATER: 66634,
        INTPTLAT: '+35.8108279',
        INTPTLON: '-084.2792426',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6582, 35.6093],
            [-87.5688, 35.6523],
            [-87.4596, 35.6151],
            [-87.3392, 35.6591],
            [-87.3523, 35.5846],
            [-87.2899, 35.548],
            [-87.2945, 35.4447],
            [-87.5754, 35.3985],
            [-87.6459, 35.4727],
            [-87.7176, 35.4833],
            [-87.6582, 35.6093],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702430',
        NAME: 'Lewis County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 730608074,
        AWATER: 1031592,
        INTPTLAT: '+35.5232441',
        INTPTLON: '-087.4969827',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.8283, 35.2637],
            [-86.7469, 35.2529],
            [-86.6026, 35.327],
            [-86.5995, 35.365],
            [-86.5253, 35.3547],
            [-86.3922, 35.2354],
            [-86.3701, 35.136],
            [-86.3182, 35.127],
            [-86.3188, 34.9912],
            [-86.7836, 34.9919],
            [-86.8363, 34.9919],
            [-86.8283, 35.2637],
          ],
          [
            [-86.6086, 35.1567],
            [-86.5817, 35.1124],
            [-86.5505, 35.1554],
            [-86.6086, 35.1567],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702490',
        NAME: 'Lincoln County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1452037336,
        AWATER: 990351,
        INTPTLAT: '+35.1797204',
        INTPTLON: '-086.5749425',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.54, 35.6705],
            [-84.338, 35.8391],
            [-84.3302, 35.8921],
            [-84.2634, 35.8969],
            [-84.1663, 35.8051],
            [-84.1517, 35.6275],
            [-84.1883, 35.6105],
            [-84.2995, 35.6577],
            [-84.3661, 35.647],
            [-84.4868, 35.6584],
            [-84.5258, 35.6242],
            [-84.5845, 35.6444],
            [-84.54, 35.6705],
          ],
          [
            [-84.302, 35.7977],
            [-84.2924, 35.7956],
            [-84.2489, 35.7946],
            [-84.2626, 35.8382],
            [-84.302, 35.7977],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702520',
        NAME: 'Loudon County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 571890408,
        AWATER: 46859436,
        INTPTLAT: '+35.7374500',
        INTPTLON: '-084.3162040',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2284, 36.4875],
            [-86.2054, 36.6402],
            [-85.9764, 36.6285],
            [-85.7887, 36.622],
            [-85.8139, 36.4995],
            [-85.828, 36.4118],
            [-85.9789, 36.4261],
            [-86.1683, 36.4443],
            [-86.2284, 36.4875],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702550',
        NAME: 'Macon County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 795498386,
        AWATER: 246690,
        INTPTLAT: '+36.5377574',
        INTPTLON: '-086.0009592',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0695, 35.6927],
            [-88.9683, 35.7175],
            [-88.9162, 35.7973],
            [-88.893, 35.7966],
            [-88.8676, 35.7957],
            [-88.7068, 35.7911],
            [-88.6069, 35.7894],
            [-88.6136, 35.5881],
            [-88.6759, 35.4892],
            [-88.8416, 35.4278],
            [-89.0789, 35.4314],
            [-89.0695, 35.6927],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702580',
        NAME: 'Jackson-Madison Consolidated School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1442905034,
        AWATER: 3930429,
        INTPTLAT: '+35.6060563',
        INTPTLON: '-088.8334238',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.873, 35.2234],
            [-85.7401, 35.2153],
            [-85.6904, 35.2861],
            [-85.5586, 35.3193],
            [-85.4441, 35.2626],
            [-85.3871, 35.1472],
            [-85.3855, 35.0432],
            [-85.4741, 34.983],
            [-85.6052, 34.9847],
            [-85.7022, 34.9864],
            [-85.7372, 35.0158],
            [-85.7579, 34.9872],
            [-85.8639, 34.9883],
            [-85.873, 35.2234],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702640',
        NAME: 'Marion County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1277116297,
        AWATER: 36501982,
        INTPTLAT: '+35.1334215',
        INTPTLON: '-085.6183990',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9608, 35.417],
            [-86.8496, 35.5255],
            [-86.8119, 35.6964],
            [-86.782, 35.7066],
            [-86.6862, 35.7101],
            [-86.6394, 35.6859],
            [-86.6582, 35.4353],
            [-86.5995, 35.365],
            [-86.6026, 35.327],
            [-86.7469, 35.2529],
            [-86.8283, 35.2637],
            [-86.9022, 35.3163],
            [-86.9608, 35.417],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702670',
        NAME: 'Marshall County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 972442313,
        AWATER: 1801922,
        INTPTLAT: '+35.4683866',
        INTPTLON: '-086.7658862',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0117, 35.768],
            [-84.008, 35.7687],
            [-84.0022, 35.7689],
            [-83.9985, 35.7687],
            [-83.983, 35.7665],
            [-83.9656, 35.7658],
            [-83.9673, 35.769],
            [-83.956, 35.7818],
            [-83.9178, 35.7695],
            [-83.9554, 35.7242],
            [-84.0117, 35.768],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702700',
        NAME: 'Maryville City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41876757,
        AWATER: 0,
        INTPTLAT: '+35.7467455',
        INTPTLON: '-083.9774438',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.3392, 35.6591],
            [-87.2341, 35.7247],
            [-87.2151, 35.8507],
            [-86.782, 35.7066],
            [-86.8119, 35.6964],
            [-86.8496, 35.5255],
            [-86.9608, 35.417],
            [-87.1209, 35.4557],
            [-87.2066, 35.4332],
            [-87.2945, 35.4447],
            [-87.2899, 35.548],
            [-87.3523, 35.5846],
            [-87.3392, 35.6591],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702760',
        NAME: 'Maury County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1588021146,
        AWATER: 6319196,
        INTPTLAT: '+35.6156963',
        INTPTLON: '-087.0777632',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5962, 36.1163],
            [-88.5303, 36.152],
            [-88.3812, 36.1488],
            [-88.4336, 36.0565],
            [-88.5034, 36.0514],
            [-88.5962, 36.1163],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702790',
        NAME: 'McKenzie Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142755505,
        AWATER: 477378,
        INTPTLAT: '+36.0988349',
        INTPTLON: '-088.4846701',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.6199, 35.6447],
            [-84.5845, 35.6444],
            [-84.5258, 35.6242],
            [-84.5224, 35.6186],
            [-84.5224, 35.6078],
            [-84.4711, 35.5167],
            [-84.4092, 35.3984],
            [-84.4105, 35.3306],
            [-84.4955, 35.2855],
            [-84.6674, 35.268],
            [-84.7036, 35.241],
            [-84.8602, 35.3501],
            [-84.8055, 35.4486],
            [-84.6199, 35.6447],
          ],
          [
            [-84.656, 35.4685],
            [-84.6434, 35.4406],
            [-84.5875, 35.4219],
            [-84.5716, 35.4282],
            [-84.5754, 35.4913],
            [-84.6027, 35.4768],
            [-84.6214, 35.459],
            [-84.656, 35.4685],
          ],
          [
            [-84.5444, 35.3238],
            [-84.5292, 35.3124],
            [-84.5194, 35.3313],
            [-84.5169, 35.352],
            [-84.5289, 35.3532],
            [-84.5393, 35.3393],
            [-84.5444, 35.3238],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702820',
        NAME: 'McMinn County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1066212895,
        AWATER: 5340572,
        INTPTLAT: '+35.3472456',
        INTPTLON: '-084.7394079',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7818, 35.2476],
            [-88.7198, 35.2475],
            [-88.6409, 35.361],
            [-88.5718, 35.3821],
            [-88.3626, 35.381],
            [-88.3805, 34.9956],
            [-88.473, 34.996],
            [-88.531, 34.9958],
            [-88.7866, 34.9953],
            [-88.7818, 35.2476],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702880',
        NAME: 'McNairy County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1457754792,
        AWATER: 1994987,
        INTPTLAT: '+35.1752280',
        INTPTLON: '-088.5636295',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.0163, 35.4093],
            [-84.915, 35.4408],
            [-84.7824, 35.5948],
            [-84.7818, 35.6739],
            [-84.7239, 35.7529],
            [-84.623, 35.7118],
            [-84.6199, 35.6447],
            [-84.8055, 35.4486],
            [-84.8602, 35.3501],
            [-84.9463, 35.2877],
            [-85.0163, 35.4093],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702910',
        NAME: 'Meigs County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 505379349,
        AWATER: 55995294,
        INTPTLAT: '+35.5033973',
        INTPTLON: '-084.8238876',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8335, 35.9787],
            [-88.696, 35.9936],
            [-88.7021, 35.8616],
            [-88.8415, 35.9137],
            [-88.8335, 35.9787],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4702970',
        NAME: 'Milan City Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170835268,
        AWATER: 1275921,
        INTPTLAT: '+35.9378286',
        INTPTLON: '-088.7612648',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.4711, 35.5167],
            [-84.3975, 35.5549],
            [-84.3661, 35.647],
            [-84.2995, 35.6577],
            [-84.1883, 35.6105],
            [-84.0962, 35.5755],
            [-83.9611, 35.4637],
            [-84.0147, 35.412],
            [-84.0291, 35.2922],
            [-84.107, 35.2514],
            [-84.2923, 35.2067],
            [-84.4955, 35.2855],
            [-84.4105, 35.3306],
            [-84.4092, 35.3984],
            [-84.4711, 35.5167],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703000',
        NAME: 'Monroe County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1482712329,
        AWATER: 44000708,
        INTPTLAT: '+35.4200525',
        INTPTLON: '-084.2348469',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.6261, 36.5431],
            [-87.4796, 36.5511],
            [-87.4363, 36.6408],
            [-87.336, 36.6417],
            [-87.115, 36.6424],
            [-87.1204, 36.4555],
            [-87.2805, 36.3545],
            [-87.2865, 36.3219],
            [-87.5135, 36.3347],
            [-87.5924, 36.3677],
            [-87.6261, 36.5431],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703030',
        NAME: 'Montgomery County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1222282189,
        AWATER: 11890999,
        INTPTLAT: '+36.4881484',
        INTPTLON: '-087.3554104',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5253, 35.3547],
            [-86.4217, 35.3193],
            [-86.2576, 35.4125],
            [-86.2517, 35.404],
            [-86.257, 35.4036],
            [-86.2681, 35.3866],
            [-86.2615, 35.3336],
            [-86.3182, 35.127],
            [-86.3701, 35.136],
            [-86.3922, 35.2354],
            [-86.5253, 35.3547],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703060',
        NAME: 'Moore County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334684864,
        AWATER: 3093435,
        INTPTLAT: '+35.2888885',
        INTPTLON: '-086.3586840',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7008, 36.3708],
            [-84.5687, 36.2371],
            [-84.441, 36.1635],
            [-84.3418, 36.049],
            [-84.4461, 35.986],
            [-84.5457, 35.9689],
            [-84.6806, 35.9085],
            [-84.7207, 35.9949],
            [-84.9078, 36.1563],
            [-84.8661, 36.2929],
            [-84.7979, 36.2969],
            [-84.7008, 36.3708],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703090',
        NAME: 'Morgan County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1352427852,
        AWATER: 823017,
        INTPTLAT: '+36.1386970',
        INTPTLON: '-084.6392616',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.9132, 36.3826],
            [-86.8241, 36.3513],
            [-86.7548, 36.4055],
            [-86.7211, 36.3421],
            [-86.5943, 36.2451],
            [-86.5156, 36.1006],
            [-86.6189, 35.9682],
            [-86.7909, 36.0367],
            [-87.0541, 36.0455],
            [-86.9977, 36.1285],
            [-86.9744, 36.2499],
            [-86.9132, 36.3826],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703180',
        NAME: 'Nashville-Davidson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1304946912,
        AWATER: 56655850,
        INTPTLAT: '+36.1691287',
        INTPTLON: '-086.7847898',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3254, 36.0055],
            [-84.2208, 36.059],
            [-84.1579, 35.9913],
            [-84.2634, 35.8969],
            [-84.3302, 35.8921],
            [-84.4187, 35.9458],
            [-84.3254, 36.0055],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703240',
        NAME: 'Oak Ridge City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 220856772,
        AWATER: 12181042,
        INTPTLAT: '+35.9664826',
        INTPTLON: '-084.2905489',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3461, 36.5032],
            [-88.8993, 36.5023],
            [-88.8365, 36.5026],
            [-88.8346, 36.5026],
            [-88.8273, 36.5026],
            [-88.8154, 36.4225],
            [-88.9502, 36.4092],
            [-88.9598, 36.2228],
            [-89.1545, 36.2047],
            [-89.483, 36.2127],
            [-89.4437, 36.2366],
            [-89.3601, 36.4093],
            [-89.3461, 36.5032],
          ],
          [
            [-89.0994, 36.4513],
            [-89.0892, 36.4307],
            [-89.0492, 36.4108],
            [-89.0011, 36.4136],
            [-89.0541, 36.4488],
            [-89.0994, 36.4513],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703270',
        NAME: 'Obion County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1380791603,
        AWATER: 27414650,
        INTPTLAT: '+36.3581714',
        INTPTLON: '-089.1498791',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.5363, 36.5096],
            [-84.5, 36.5271],
            [-84.4903, 36.4936],
            [-84.5178, 36.4842],
            [-84.5363, 36.5096],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703300',
        NAME: 'Oneida Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16179268,
        AWATER: 236090,
        INTPTLAT: '+36.5019353',
        INTPTLON: '-084.5097753',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.4954, 36.4031],
            [-85.4388, 36.5011],
            [-85.2849, 36.5313],
            [-85.1503, 36.4601],
            [-85.1204, 36.4072],
            [-85.1222, 36.3046],
            [-85.0825, 36.1962],
            [-85.1196, 36.1447],
            [-85.2042, 36.1525],
            [-85.4194, 36.2361],
            [-85.4951, 36.3023],
            [-85.4954, 36.4031],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703330',
        NAME: 'Overton County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1122721249,
        AWATER: 3492728,
        INTPTLAT: '+36.3448504',
        INTPTLON: '-085.2830756',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9707, 35.8157],
            [-87.9627, 35.8409],
            [-87.8742, 35.813],
            [-87.7168, 35.8387],
            [-87.7437, 35.664],
            [-87.6582, 35.6093],
            [-87.7176, 35.4833],
            [-87.9732, 35.46],
            [-88.0077, 35.4234],
            [-87.9746, 35.521],
            [-88.0358, 35.6218],
            [-88.0348, 35.7173],
            [-87.9707, 35.8157],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703390',
        NAME: 'Perry County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1074195267,
        AWATER: 21037070,
        INTPTLAT: '+35.6637491',
        INTPTLON: '-087.8693294',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.2763, 36.6268],
            [-84.9748, 36.6155],
            [-84.7854, 36.6034],
            [-84.7318, 36.5247],
            [-84.9431, 36.5813],
            [-85.0439, 36.5588],
            [-85.1204, 36.4072],
            [-85.1503, 36.4601],
            [-85.2849, 36.5313],
            [-85.2763, 36.6268],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703420',
        NAME: 'Pickett County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 422113187,
        AWATER: 29822859,
        INTPTLAT: '+36.5593638',
        INTPTLON: '-085.0757410',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7036, 35.241],
            [-84.6674, 35.268],
            [-84.4955, 35.2855],
            [-84.2923, 35.2067],
            [-84.3219, 34.9884],
            [-84.6215, 34.9883],
            [-84.7759, 34.9879],
            [-84.6999, 35.171],
            [-84.7036, 35.241],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703450',
        NAME: 'Polk County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1125794510,
        AWATER: 19834175,
        INTPTLAT: '+35.1094371',
        INTPTLON: '-084.5411124',
        ELSDLEA: '',
        UNSDLEA: '03450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7801, 36.238],
            [-85.6342, 36.2133],
            [-85.5126, 36.2569],
            [-85.4951, 36.3023],
            [-85.4194, 36.2361],
            [-85.2042, 36.1525],
            [-85.1196, 36.1447],
            [-85.1002, 36.1386],
            [-85.2358, 36.1058],
            [-85.2642, 35.9792],
            [-85.3566, 36.0459],
            [-85.5077, 36.082],
            [-85.6446, 36.0151],
            [-85.776, 36.0783],
            [-85.8074, 36.1316],
            [-85.7801, 36.238],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703480',
        NAME: 'Putnam County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1038860605,
        AWATER: 3777709,
        INTPTLAT: '+36.1409404',
        INTPTLON: '-085.4961801',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.9161, 35.7619],
            [-84.7819, 35.825],
            [-84.7239, 35.7529],
            [-84.7818, 35.6739],
            [-84.7824, 35.5948],
            [-84.915, 35.4408],
            [-85.0163, 35.4093],
            [-85.1352, 35.4586],
            [-85.1078, 35.5714],
            [-84.9161, 35.7619],
          ],
          [
            [-84.9851, 35.5282],
            [-85.0109, 35.5191],
            [-85.0225, 35.4902],
            [-85.0394, 35.4851],
            [-85.0192, 35.4622],
            [-85.0067, 35.4648],
            [-84.9851, 35.5282],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703510',
        NAME: 'Rhea County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 797002837,
        AWATER: 53862252,
        INTPTLAT: '+35.6005870',
        INTPTLON: '-084.9495522',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.7022, 34.9864],
            [-85.7579, 34.9872],
            [-85.7372, 35.0158],
            [-85.7022, 34.9864],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703540',
        NAME: 'Richard City Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13111068,
        AWATER: 0,
        INTPTLAT: '+35.0005260',
        INTPTLON: '-085.7360435',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7819, 35.825],
            [-84.6806, 35.9085],
            [-84.5457, 35.9689],
            [-84.4461, 35.986],
            [-84.3418, 36.049],
            [-84.3254, 36.0055],
            [-84.4187, 35.9458],
            [-84.3302, 35.8921],
            [-84.338, 35.8391],
            [-84.54, 35.6705],
            [-84.5845, 35.6444],
            [-84.6199, 35.6447],
            [-84.623, 35.7118],
            [-84.7239, 35.7529],
            [-84.7819, 35.825],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703590',
        NAME: 'Roane County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 827384379,
        AWATER: 83896750,
        INTPTLAT: '+35.8308410',
        INTPTLON: '-084.5499473',
        ELSDLEA: '',
        UNSDLEA: '03590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.115, 36.6424],
            [-87.0608, 36.643],
            [-86.7633, 36.649],
            [-86.5639, 36.6336],
            [-86.6951, 36.4213],
            [-86.7548, 36.4055],
            [-86.8241, 36.3513],
            [-86.9132, 36.3826],
            [-86.9881, 36.3692],
            [-87.1204, 36.4555],
            [-87.115, 36.6424],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703600',
        NAME: 'Robertson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1233581423,
        AWATER: 451691,
        INTPTLAT: '+36.5275304',
        INTPTLON: '-086.8693774',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.6189, 35.9682],
            [-86.5156, 36.1006],
            [-86.3263, 35.985],
            [-86.1532, 35.9544],
            [-86.2071, 35.7038],
            [-86.2452, 35.6319],
            [-86.3443, 35.6637],
            [-86.4137, 35.6232],
            [-86.5431, 35.6976],
            [-86.6394, 35.6859],
            [-86.6862, 35.7101],
            [-86.6133, 35.7903],
            [-86.6189, 35.9682],
          ],
          [
            [-86.4755, 35.9017],
            [-86.4792, 35.8092],
            [-86.3908, 35.7768],
            [-86.3466, 35.8344],
            [-86.347, 35.899],
            [-86.3969, 35.9196],
            [-86.4755, 35.9017],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703690',
        NAME: 'Rutherford County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1458147510,
        AWATER: 11755171,
        INTPTLAT: '+35.7408992',
        INTPTLON: '-086.2897076',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.7854, 36.6034],
            [-84.7785, 36.6033],
            [-84.2613, 36.5918],
            [-84.2596, 36.514],
            [-84.3499, 36.4663],
            [-84.3299, 36.2851],
            [-84.3727, 36.2168],
            [-84.441, 36.1635],
            [-84.5687, 36.2371],
            [-84.7008, 36.3708],
            [-84.6591, 36.3952],
            [-84.7318, 36.5247],
            [-84.7854, 36.6034],
          ],
          [
            [-84.5363, 36.5096],
            [-84.5178, 36.4842],
            [-84.4903, 36.4936],
            [-84.5, 36.5271],
            [-84.5363, 36.5096],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703720',
        NAME: 'Scott County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1362448019,
        AWATER: 2178164,
        INTPTLAT: '+36.4360711',
        INTPTLON: '-084.5055479',
        ELSDLEA: '',
        UNSDLEA: '03720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6073, 35.5317],
            [-85.5575, 35.533],
            [-85.4238, 35.5674],
            [-85.4033, 35.4923],
            [-85.2259, 35.3543],
            [-85.3871, 35.1472],
            [-85.4441, 35.2626],
            [-85.5586, 35.3193],
            [-85.4856, 35.4064],
            [-85.6073, 35.5317],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703750',
        NAME: 'Sequatchie County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 688550858,
        AWATER: 498314,
        INTPTLAT: '+35.3723582',
        INTPTLON: '-085.4103223',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6733, 36.0385],
            [-83.4749, 35.9229],
            [-83.3108, 35.8958],
            [-83.2636, 35.819],
            [-83.2555, 35.715],
            [-83.2542, 35.6958],
            [-83.4983, 35.563],
            [-83.663, 35.5691],
            [-83.6628, 35.6906],
            [-83.7052, 35.7116],
            [-83.7943, 35.8871],
            [-83.6546, 35.9719],
            [-83.6733, 36.0385],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703780',
        NAME: 'Sevier County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1534597705,
        AWATER: 13491682,
        INTPTLAT: '+35.8018095',
        INTPTLON: '-083.5156955',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.1368, 36.2959],
            [-85.9789, 36.4261],
            [-85.828, 36.4118],
            [-85.8465, 36.2882],
            [-85.7801, 36.238],
            [-85.8074, 36.1316],
            [-86.0597, 36.086],
            [-86.1368, 36.2959],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703870',
        NAME: 'Smith County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 814004435,
        AWATER: 28706410,
        INTPTLAT: '+36.2532006',
        INTPTLON: '-085.9564507',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.475, 35.906],
            [-88.3946, 35.9682],
            [-88.3263, 35.9672],
            [-88.2162, 35.9048],
            [-88.1779, 35.8458],
            [-88.1792, 35.8168],
            [-88.5069, 35.8236],
            [-88.475, 35.906],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703900',
        NAME: 'South Carroll Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302278678,
        AWATER: 353070,
        INTPTLAT: '+35.8696963',
        INTPTLON: '-088.3517977',
        ELSDLEA: '',
        UNSDLEA: '03900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0705, 36.6783],
            [-87.8495, 36.6637],
            [-87.7972, 36.6349],
            [-87.6949, 36.554],
            [-87.6261, 36.5431],
            [-87.5924, 36.3677],
            [-87.7155, 36.3682],
            [-87.8218, 36.3287],
            [-87.9807, 36.3533],
            [-87.9909, 36.3601],
            [-88.0585, 36.4392],
            [-88.0533, 36.4971],
            [-88.0327, 36.537],
            [-88.0705, 36.6783],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703960',
        NAME: 'Stewart County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1088407051,
        AWATER: 86927849,
        INTPTLAT: '+36.4942735',
        INTPTLON: '-087.8560759',
        ELSDLEA: '',
        UNSDLEA: '03960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-82.5711, 36.5946],
              [-82.2941, 36.5957],
              [-82.2636, 36.5956],
              [-82.2666, 36.5007],
              [-82.096, 36.5947],
              [-81.8267, 36.6142],
              [-81.9838, 36.5083],
              [-82.1881, 36.4006],
              [-82.2999, 36.3965],
              [-82.3463, 36.3989],
              [-82.3471, 36.3999],
              [-82.3481, 36.4012],
              [-82.3511, 36.4019],
              [-82.3585, 36.4024],
              [-82.3611, 36.4026],
              [-82.4384, 36.4456],
              [-82.5197, 36.4348],
              [-82.4714, 36.5704],
              [-82.5711, 36.5946],
            ],
          ],
          [
            [
              [-82.6523, 36.4822],
              [-82.5453, 36.4586],
              [-82.523, 36.4343],
              [-82.5233, 36.4343],
              [-82.6807, 36.4318],
              [-82.6523, 36.4822],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4703990',
        NAME: 'Sullivan County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 862521056,
        AWATER: 39418207,
        INTPTLAT: '+36.4504035',
        INTPTLON: '-082.4852861',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.7548, 36.4055],
            [-86.6951, 36.4213],
            [-86.5639, 36.6336],
            [-86.4114, 36.6509],
            [-86.2054, 36.6402],
            [-86.2284, 36.4875],
            [-86.2833, 36.3475],
            [-86.3904, 36.3102],
            [-86.4517, 36.3356],
            [-86.5943, 36.2451],
            [-86.7211, 36.3421],
            [-86.7548, 36.4055],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704020',
        NAME: 'Sumner County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1371031078,
        AWATER: 35892221,
        INTPTLAT: '+36.4734430',
        INTPTLON: '-086.4597975',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.9098, 35.5379],
              [-89.7931, 35.6374],
              [-89.613, 35.6432],
              [-89.5021, 35.5806],
              [-89.4699, 35.5466],
              [-89.4742, 35.403],
              [-89.6328, 35.3758],
              [-90.0416, 35.3966],
              [-90.0504, 35.5159],
              [-89.9895, 35.5601],
              [-89.9098, 35.5379],
            ],
          ],
          [
            [
              [-90.1403, 35.4366],
              [-90.0543, 35.3893],
              [-90.0618, 35.3862],
              [-90.075, 35.3842],
              [-90.1403, 35.4366],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704080',
        NAME: 'Tipton County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1187267201,
        AWATER: 38609227,
        INTPTLAT: '+35.5002588',
        INTPTLON: '-089.7668030',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9051, 36.0316],
            [-88.8335, 35.9787],
            [-88.8415, 35.9137],
            [-88.8803, 35.8826],
            [-89.0622, 35.9172],
            [-89.072, 36.0043],
            [-88.9051, 36.0316],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704100',
        NAME: 'Trenton Special School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 291359453,
        AWATER: 375317,
        INTPTLAT: '+35.9567515',
        INTPTLON: '-088.9461746',
        ELSDLEA: '',
        UNSDLEA: '04100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2284, 36.4875],
            [-86.1683, 36.4443],
            [-85.9789, 36.4261],
            [-86.1368, 36.2959],
            [-86.2833, 36.3475],
            [-86.2284, 36.4875],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704170',
        NAME: 'Trousdale County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 295758960,
        AWATER: 6345337,
        INTPTLAT: '+36.3930297',
        INTPTLON: '-086.1566909',
        ELSDLEA: '',
        UNSDLEA: '04170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.2681, 35.3866],
            [-86.2674, 35.3933],
            [-86.257, 35.4036],
            [-86.2517, 35.404],
            [-86.1215, 35.4107],
            [-86.1213, 35.4106],
            [-86.1819, 35.3517],
            [-86.1895, 35.3388],
            [-86.2008, 35.3354],
            [-86.2615, 35.3336],
            [-86.2681, 35.3866],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704200',
        NAME: 'Tullahoma City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50853665,
        AWATER: 237712,
        INTPTLAT: '+35.3756970',
        INTPTLON: '-086.2172085',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6029, 36.0398],
            [-82.5951, 36.0962],
            [-82.5276, 36.149],
            [-82.4373, 36.1547],
            [-82.3405, 36.2528],
            [-82.2564, 36.2332],
            [-82.2221, 36.1569],
            [-82.4167, 36.0728],
            [-82.5054, 35.9777],
            [-82.6109, 35.9674],
            [-82.6029, 36.0398],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704230',
        NAME: 'Unicoi County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 482165204,
        AWATER: 850687,
        INTPTLAT: '+36.1002156',
        INTPTLON: '-082.4182654',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0994, 36.4513],
            [-89.0541, 36.4488],
            [-89.0011, 36.4136],
            [-89.0492, 36.4108],
            [-89.0892, 36.4307],
            [-89.0994, 36.4513],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704260',
        NAME: 'Union City School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 30529814,
        AWATER: 19384,
        INTPTLAT: '+36.4256717',
        INTPTLON: '-089.0460533',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.0043, 36.2704],
            [-83.9056, 36.42],
            [-83.7662, 36.3992],
            [-83.6674, 36.3444],
            [-83.7327, 36.1646],
            [-83.9417, 36.1864],
            [-84.0043, 36.2704],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704290',
        NAME: 'Union County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 578990166,
        AWATER: 61092920,
        INTPTLAT: '+36.2841401',
        INTPTLON: '-083.8360878',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6029, 35.795],
            [-85.4494, 35.8216],
            [-85.2694, 35.7934],
            [-85.2727, 35.7877],
            [-85.2535, 35.7669],
            [-85.2541, 35.7656],
            [-85.2654, 35.7212],
            [-85.4238, 35.5674],
            [-85.5575, 35.533],
            [-85.5946, 35.6179],
            [-85.6029, 35.795],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704320',
        NAME: 'Van Buren County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 708142403,
        AWATER: 2893455,
        INTPTLAT: '+35.6992335',
        INTPTLON: '-085.4584092',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.8852, 35.8397],
            [-85.6821, 35.8313],
            [-85.6029, 35.795],
            [-85.5946, 35.6179],
            [-85.5575, 35.533],
            [-85.6073, 35.5317],
            [-85.877, 35.5241],
            [-85.9851, 35.6601],
            [-85.9849, 35.7346],
            [-85.8852, 35.8397],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704350',
        NAME: 'Warren County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1120635693,
        AWATER: 3508325,
        INTPTLAT: '+35.6782498',
        INTPTLON: '-085.7773428',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-82.321, 36.3555],
              [-82.3036, 36.3596],
              [-82.3149, 36.3269],
              [-82.321, 36.3555],
            ],
          ],
          [
            [
              [-82.3463, 36.3989],
              [-82.2999, 36.3965],
              [-82.3, 36.3718],
              [-82.3463, 36.3989],
            ],
          ],
          [
            [
              [-82.5206, 36.4347],
              [-82.5197, 36.4348],
              [-82.4384, 36.4456],
              [-82.3611, 36.4026],
              [-82.4393, 36.388],
              [-82.5206, 36.4347],
            ],
          ],
          [
            [
              [-82.6807, 36.4318],
              [-82.5233, 36.4343],
              [-82.4147, 36.3681],
              [-82.3938, 36.2732],
              [-82.3294, 36.2867],
              [-82.3405, 36.2528],
              [-82.4373, 36.1547],
              [-82.5276, 36.149],
              [-82.5951, 36.0962],
              [-82.6478, 36.1464],
              [-82.6296, 36.4115],
              [-82.7026, 36.4104],
              [-82.6807, 36.4318],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704380',
        NAME: 'Washington County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 747444877,
        AWATER: 8055953,
        INTPTLAT: '+36.2820493',
        INTPTLON: '-082.5237126',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0247, 35.3923],
            [-88.0077, 35.4234],
            [-87.9732, 35.46],
            [-87.7176, 35.4833],
            [-87.6459, 35.4727],
            [-87.5754, 35.3985],
            [-87.5778, 35.1594],
            [-87.606, 35.0034],
            [-87.9849, 35.0059],
            [-87.9816, 35.2957],
            [-88.0247, 35.3923],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704440',
        NAME: 'Wayne County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1901321676,
        AWATER: 4023416,
        INTPTLAT: '+35.2428856',
        INTPTLON: '-087.8198497',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8273, 36.5026],
            [-88.8168, 36.5027],
            [-88.5164, 36.5016],
            [-88.5303, 36.152],
            [-88.5962, 36.1163],
            [-88.6927, 36.0627],
            [-88.8481, 36.1337],
            [-88.9598, 36.2228],
            [-88.9502, 36.4092],
            [-88.8154, 36.4225],
            [-88.8273, 36.5026],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704470',
        NAME: 'Weakley County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1503105587,
        AWATER: 3709396,
        INTPTLAT: '+36.3033855',
        INTPTLON: '-088.7208026',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7021, 35.8616],
            [-88.696, 35.9936],
            [-88.6927, 36.0627],
            [-88.5962, 36.1163],
            [-88.5034, 36.0514],
            [-88.5251, 35.9569],
            [-88.475, 35.906],
            [-88.5069, 35.8236],
            [-88.6069, 35.7894],
            [-88.7068, 35.7911],
            [-88.7021, 35.8616],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704490',
        NAME: 'West Carroll Special District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 544848925,
        AWATER: 860457,
        INTPTLAT: '+35.9400103',
        INTPTLON: '-088.6195995',
        ELSDLEA: '',
        UNSDLEA: '04490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6821, 35.8313],
            [-85.6449, 35.8895],
            [-85.6446, 36.0151],
            [-85.5077, 36.082],
            [-85.3566, 36.0459],
            [-85.2642, 35.9792],
            [-85.2225, 35.8774],
            [-85.2694, 35.7934],
            [-85.4494, 35.8216],
            [-85.6029, 35.795],
            [-85.6821, 35.8313],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704500',
        NAME: 'White County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 975592099,
        AWATER: 7113368,
        INTPTLAT: '+35.9270621',
        INTPTLON: '-085.4557657',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2151, 35.8507],
            [-87.2042, 35.9592],
            [-87.1826, 36.0497],
            [-87.0541, 36.0455],
            [-86.7909, 36.0367],
            [-86.6189, 35.9682],
            [-86.6133, 35.7903],
            [-86.6862, 35.7101],
            [-86.782, 35.7066],
            [-87.2151, 35.8507],
          ],
          [
            [-86.9133, 35.9404],
            [-86.9044, 35.9065],
            [-86.8366, 35.886],
            [-86.8014, 35.9198],
            [-86.8666, 35.9428],
            [-86.9133, 35.9404],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704530',
        NAME: 'Williamson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1456961790,
        AWATER: 2669736,
        INTPTLAT: '+35.8948446',
        INTPTLON: '-086.8978858',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.5943, 36.2451],
            [-86.4517, 36.3356],
            [-86.3904, 36.3102],
            [-86.2833, 36.3475],
            [-86.1368, 36.2959],
            [-86.0597, 36.086],
            [-86.0148, 35.9611],
            [-86.1532, 35.9544],
            [-86.3263, 35.985],
            [-86.5156, 36.1006],
            [-86.5943, 36.2451],
          ],
          [
            [-86.4168, 36.2485],
            [-86.39, 36.1903],
            [-86.2686, 36.1575],
            [-86.2465, 36.2397],
            [-86.3212, 36.2617],
            [-86.4168, 36.2485],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4704550',
        NAME: 'Wilson County School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1351613650,
        AWATER: 31991772,
        INTPTLAT: '+36.1484757',
        INTPTLON: '-086.2902097',
        ELSDLEA: '',
        UNSDLEA: '04550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-90.1071, 35.3229],
              [-90.1064, 35.3254],
              [-90.075, 35.3842],
              [-90.0618, 35.3862],
              [-90.0976, 35.3006],
              [-90.0686, 35.1531],
              [-90.1071, 35.3229],
            ],
          ],
          [
            [
              [-90.3001, 35.0303],
              [-90.2094, 35.0265],
              [-90.3026, 34.9957],
              [-90.3093, 34.9957],
              [-90.3001, 35.0303],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '47',
        SCSDLEA: '',
        GEOID: '4799997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 51482025,
        AWATER: 30252785,
        INTPTLAT: '+35.2786801',
        INTPTLON: '-090.1354970',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
  ],
};

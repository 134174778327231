import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const REPORT_SORTS = [
  {
    sortName: 'dateCreated',
    direction: true,
    label: 'Date created (oldest)',
  },
  {
    sortName: 'dateCreated',
    direction: false,
    label: 'Date created (newest)',
  },
  {
    sortName: 'dateUpdated',
    direction: true,
    label: 'Last edited (oldest)',
  },
  {
    sortName: 'DATEUPDATED',
    direction: false,
    label: 'Last edited (newest)',
  },
  {
    sortName: 'lastOpened',
    direction: true,
    label: 'Last opened (oldest)',
  },
  {
    sortName: 'lastOpened',
    direction: false,
    label: 'Last opened (newest)',
  },
  {
    sortName: 'name',
    direction: true,
    label: 'By Name A>Z',
  },
  {
    sortName: 'name',
    direction: false,
    label: 'By Name Z>A',
  },
];

const ReportSorter = ({ handleSortChange }) => {
  const [currentSort, setCurrentSort] = useState('');

  const onSortChange = ({ sortName, direction, label }) => {
    setCurrentSort(label);
    handleSortChange(sortName, direction);
  };
  return (
    <div className="d-flex justify-content-between w-100">
      <DropdownButton variant="secondary" size="md" title={`Order by ${currentSort}`}>
        {REPORT_SORTS.map((sort) => (
          <Dropdown.Item key={shortid.generate()} onClick={() => onSortChange(sort)}>
            {sort.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

ReportSorter.displayName = 'ReportSorter';

ReportSorter.propTypes = {
  handleSortChange: PropTypes.func.isRequired,
};

export default ReportSorter;

/* eslint-disable no-param-reassign */
import types from '../types';

const initialState = {
  loading: false,
  users: [],
  error: '',
  saveError: '',
  sortField: 'uname',
  sortDirection: 'asc',
  pageNumber: 0,
  totalSize: 10,
  isProcessingBatch: false,
  batchProgress: 0,
  batchErrors: [],
  exportFile: '',
  isProcessingExport: false,
  canGetList: false,
  activeCheck: null,
  usersCreators: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.USERS_BY_EMAIL_LIST_PENDING:
    case types.reducerTypes.USERS_BY_EMAIL_LIST_ERROR:
      return {
        ...state,
        usersCreators: [],
      };
    case types.reducerTypes.USERS_BY_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        usersCreators: action.payload,
      };
    case types.reducerTypes.USERS_LIST_PENDING:
      return {
        ...state,
        loading: true,
        users: [],
        error: '',
      };
    case types.reducerTypes.USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.results,
        sortField: action.payload.sortField,
        sortDirection: action.payload.sortDirection,
        pageNumber: action.payload.pageNumber,
        totalSize: action.payload.totalSize,
      };
    case types.reducerTypes.USERS_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.reducerTypes.USERS_SAVE_PENDING:
      return {
        ...state,
        loading: true,
        canGetList: false,
        saveError: '',
      };
    case types.reducerTypes.USERS_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        canGetList: true,
      };
    case types.reducerTypes.USERS_SAVE_CLEAR:
      return {
        ...state,
        loading: false,
        canGetList: false,
        saveError: null,
      };
    case types.reducerTypes.USERS_SAVE_ERROR:
      return {
        ...state,
        loading: false,
        canGetList: false,
        saveError: action.payload,
      };
    case types.reducerTypes.USERS_DELETE_PENDING:
      return {
        ...state,
        canGetList: false,
        loading: true,
      };
    case types.reducerTypes.USERS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        canGetList: true,
      };
    case types.reducerTypes.USERS_DELETE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.reducerTypes.USERS_BATCH_PENDING:
      return {
        ...state,
        canGetList: false,
        isProcessingBatch: true,
        batchProgress: 0,
        batchErrors: [],
      };
    case types.reducerTypes.USERS_BATCH_ERROR:
      return {
        ...state,
        batchErrors: [
          ...state.batchErrors,
          { username: action.payload.username, message: action.payload.message },
        ],
      };
    case types.reducerTypes.USERS_BATCH_PROGRESS:
      return {
        ...state,
        batchProgress: action.batchProgress,
      };
    case types.reducerTypes.USERS_BATCH_SUCCESS:
      return {
        ...state,
        batchProgress: 100,
        isProcessingBatch: false,
        canGetList: true,
      };
    case types.reducerTypes.USERS_BATCH_RESET:
      return {
        ...state,
        isProcessingBatch: false,
        batchProgress: 0,
        batchErrors: [],
      };
    case types.reducerTypes.USERS_EXPORT_PENDING:
      return {
        ...state,
        exportFile: '',
        isProcessingExport: true,
      };
    case types.reducerTypes.USERS_EXPORT_ERROR:
      return {
        ...state,
        exportFile: '',
        isProcessingExport: false,
        error: action.payload,
      };
    case types.reducerTypes.USERS_EXPORT_SUCCESS:
      return {
        ...state,
        exportFile: action.payload,
        isProcessingExport: false,
      };
    case types.reducerTypes.CHECK_TOKEN_PENDING:
      return {
        ...state,
        activeCheck: null,
      };
    case types.reducerTypes.CHECK_TOKEN_ERROR:
      return {
        ...state,
        activeCheck: action.payload,
      };
    case types.reducerTypes.CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        activeCheck: action.payload,
      };
    default:
  }
  return state;
};

export default userReducer;

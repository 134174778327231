export const SD = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8839, 45.2396],
            [-96.8726, 45.2538],
            [-96.7603, 45.3119],
            [-96.6889, 45.3916],
            [-96.6781, 45.4104],
            [-96.5748, 45.391],
            [-96.5555, 45.3842],
            [-96.5235, 45.2762],
            [-96.453, 45.2761],
            [-96.4521, 45.1499],
            [-96.6, 45.1071],
            [-96.7522, 45.1063],
            [-96.8227, 45.1212],
            [-96.8235, 45.208],
            [-96.8839, 45.2396],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4600002',
        NAME: 'Milbank School District 25-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 763202671,
        AWATER: 9489418,
        INTPTLAT: '+45.2316984',
        INTPTLON: '-096.6572029',
        ELSDLEA: '',
        UNSDLEA: '00002',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1247, 45.4461],
            [-97.0875, 45.4572],
            [-97.0202, 45.4785],
            [-96.7632, 45.5195],
            [-96.6781, 45.4104],
            [-96.6889, 45.3916],
            [-96.7603, 45.3119],
            [-96.8726, 45.2538],
            [-96.8839, 45.2396],
            [-96.945, 45.2398],
            [-96.9331, 45.3264],
            [-96.9797, 45.3835],
            [-97.1034, 45.4285],
            [-97.155, 45.3845],
            [-97.1247, 45.4461],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4600003',
        NAME: 'Wilmot School District 54-7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 570146410,
        AWATER: 12768413,
        INTPTLAT: '+45.4039262',
        INTPTLON: '-096.8846168',
        ELSDLEA: '',
        UNSDLEA: '00003',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.4094, 43.8847],
              [-97.3995, 43.8775],
              [-97.4094, 43.8775],
              [-97.4094, 43.8847],
            ],
          ],
          [
            [
              [-97.8495, 44.1952],
              [-97.7142, 44.1949],
              [-97.481, 44.195],
              [-97.4219, 44.1677],
              [-97.3701, 44.044],
              [-97.3697, 43.9133],
              [-97.4094, 43.8847],
              [-97.4094, 43.8775],
              [-97.5095, 43.8339],
              [-97.5296, 43.8338],
              [-97.6087, 43.8039],
              [-97.6487, 43.8104],
              [-97.6687, 43.8185],
              [-97.7375, 43.841],
              [-97.7809, 43.8933],
              [-97.7708, 43.9295],
              [-97.7909, 43.9657],
              [-97.8313, 44.0238],
              [-97.8214, 44.0742],
              [-97.8415, 44.0742],
              [-97.8495, 44.1952],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4600025',
        NAME: 'Howard School District 48-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1373468228,
        AWATER: 2541167,
        INTPTLAT: '+44.0156124',
        INTPTLON: '-097.5996534',
        ELSDLEA: '',
        UNSDLEA: '00025',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7664, 42.8741],
            [-96.7267, 42.9322],
            [-96.6678, 43.1272],
            [-96.6678, 43.131],
            [-96.6386, 43.1343],
            [-96.544, 43.1561],
            [-96.4691, 43.1847],
            [-96.4664, 43.1624],
            [-96.4366, 43.1198],
            [-96.5137, 43.047],
            [-96.5385, 42.9088],
            [-96.6513, 42.9091],
            [-96.7664, 42.8591],
            [-96.7664, 42.8741],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4600028',
        NAME: 'Alcester-Hudson School District 61-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 500860834,
        AWATER: 331830,
        INTPTLAT: '+43.0184245',
        INTPTLON: '-096.5985124',
        ELSDLEA: '',
        UNSDLEA: '00028',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8846, 44.8044],
            [-96.8752, 44.819],
            [-96.8845, 44.8262],
            [-96.8844, 44.8335],
            [-96.8843, 44.877],
            [-96.8825, 44.9045],
            [-96.8622, 44.9117],
            [-96.8821, 44.9624],
            [-96.8823, 44.9769],
            [-96.6997, 44.9768],
            [-96.669, 44.9192],
            [-96.4518, 44.8911],
            [-96.4516, 44.8487],
            [-96.4515, 44.6668],
            [-96.6927, 44.6085],
            [-96.6926, 44.5941],
            [-96.7535, 44.6306],
            [-96.7535, 44.652],
            [-96.8848, 44.6886],
            [-96.8846, 44.8044],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4600036',
        NAME: 'Deuel School District 19-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1026994904,
        AWATER: 30879577,
        INTPTLAT: '+44.7848534',
        INTPTLON: '-096.6801474',
        ELSDLEA: '',
        UNSDLEA: '00036',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.3522, 45.9363],
            [-98.2783, 45.9361],
            [-98.0081, 45.9359],
            [-97.5326, 45.9352],
            [-97.4839, 45.7612],
            [-97.3723, 45.7332],
            [-97.3625, 45.6817],
            [-97.4629, 45.689],
            [-97.5052, 45.5904],
            [-97.5052, 45.5878],
            [-97.5573, 45.6747],
            [-97.7309, 45.675],
            [-97.8342, 45.7188],
            [-97.948, 45.69],
            [-98.0206, 45.7696],
            [-98.1036, 45.7627],
            [-98.274, 45.7629],
            [-98.3524, 45.7594],
            [-98.3522, 45.9363],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4600041',
        NAME: 'Britton-Hecla School District 45-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1648375790,
        AWATER: 65279901,
        INTPTLAT: '+45.8130411',
        INTPTLON: '-097.8649186',
        ELSDLEA: '',
        UNSDLEA: '00041',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.4048, 44.8977],
            [-100.3076, 44.8981],
            [-100.062, 44.8977],
            [-99.9398, 44.8976],
            [-99.8179, 44.8975],
            [-99.673, 44.8975],
            [-99.6963, 44.5916],
            [-99.8487, 44.6209],
            [-99.8559, 44.5337],
            [-99.8253, 44.4983],
            [-99.9063, 44.3676],
            [-100.1487, 44.3676],
            [-100.1082, 44.4882],
            [-100.1495, 44.5483],
            [-100.5265, 44.5474],
            [-100.6401, 44.5837],
            [-100.6138, 44.7071],
            [-100.7176, 44.7709],
            [-100.5435, 44.7611],
            [-100.4187, 44.8434],
            [-100.4048, 44.8977],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4600042',
        NAME: 'Agar-Blunt-Onida School District 58-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3006904759,
        AWATER: 164877194,
        INTPTLAT: '+44.6853039',
        INTPTLON: '-100.1219680',
        ELSDLEA: '',
        UNSDLEA: '00042',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.274, 45.7629],
            [-98.1036, 45.7627],
            [-98.0827, 45.6756],
            [-98.0108, 45.632],
            [-97.9797, 45.5453],
            [-97.8575, 45.4572],
            [-97.8377, 45.4571],
            [-97.7347, 45.457],
            [-97.7147, 45.4498],
            [-97.6737, 45.4423],
            [-97.664, 45.2612],
            [-97.6684, 45.1736],
            [-97.6782, 45.1521],
            [-97.9436, 45.1385],
            [-98.0227, 45.1322],
            [-98.1039, 45.2123],
            [-98.1346, 45.2201],
            [-98.2277, 45.2773],
            [-98.2888, 45.3282],
            [-98.2989, 45.3427],
            [-98.278, 45.386],
            [-98.2885, 45.502],
            [-98.2474, 45.5308],
            [-98.2576, 45.5308],
            [-98.309, 45.5242],
            [-98.3089, 45.5382],
            [-98.404, 45.6764],
            [-98.274, 45.7629],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4600045',
        NAME: 'Groton Area School District 06-6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2226758290,
        AWATER: 36383079,
        INTPTLAT: '+45.4071257',
        INTPTLON: '-098.0300925',
        ELSDLEA: '',
        UNSDLEA: '00045',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.8072, 44.7219],
            [-98.7055, 44.7791],
            [-98.5644, 44.8072],
            [-98.2711, 44.8063],
            [-98.2408, 44.7194],
            [-98.1601, 44.7192],
            [-98.1194, 44.6321],
            [-98.1395, 44.6177],
            [-98.2102, 44.5965],
            [-98.2244, 44.5227],
            [-98.214, 44.501],
            [-98.3162, 44.4576],
            [-98.3566, 44.472],
            [-98.4584, 44.5014],
            [-98.524, 44.5755],
            [-98.575, 44.5902],
            [-98.7057, 44.612],
            [-98.8072, 44.7219],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4600046',
        NAME: 'Hitchcock-Tulare School District 56-6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1241719298,
        AWATER: 10710650,
        INTPTLAT: '+44.6547502',
        INTPTLON: '-098.4429833',
        ELSDLEA: '',
        UNSDLEA: '00046',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7965, 43.4028],
              [-96.787, 43.4027],
              [-96.7869, 43.3955],
              [-96.7965, 43.4028],
            ],
          ],
          [
            [
              [-96.8064, 43.4174],
              [-96.7968, 43.4173],
              [-96.7967, 43.411],
              [-96.8064, 43.4174],
            ],
          ],
          [
            [
              [-96.8903, 43.5231],
              [-96.8404, 43.5257],
              [-96.824, 43.4989],
              [-96.824, 43.4983],
              [-96.824, 43.4973],
              [-96.8239, 43.4963],
              [-96.8239, 43.4955],
              [-96.8239, 43.4937],
              [-96.8063, 43.4922],
              [-96.7966, 43.4254],
              [-96.8064, 43.4174],
              [-96.8761, 43.4902],
              [-96.8705, 43.5041],
              [-96.8903, 43.5222],
              [-96.8903, 43.5231],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4600052',
        NAME: 'Tea Area School District 41-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58594544,
        AWATER: 49018,
        INTPTLAT: '+43.4652955',
        INTPTLON: '-096.8411811',
        ELSDLEA: '',
        UNSDLEA: '00052',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.5326, 45.9352],
            [-97.3119, 45.9357],
            [-97.075, 45.9358],
            [-96.8523, 45.9359],
            [-96.8547, 45.8125],
            [-96.7923, 45.7032],
            [-96.6991, 45.722],
            [-96.8386, 45.6475],
            [-96.8355, 45.5861],
            [-96.7632, 45.5195],
            [-97.0202, 45.4785],
            [-97.0875, 45.4572],
            [-97.1247, 45.4461],
            [-97.2267, 45.4714],
            [-97.2269, 45.4858],
            [-97.2269, 45.4929],
            [-97.2269, 45.5147],
            [-97.227, 45.5255],
            [-97.2591, 45.5582],
            [-97.3098, 45.58],
            [-97.3098, 45.5807],
            [-97.3098, 45.584],
            [-97.33, 45.5881],
            [-97.3625, 45.6817],
            [-97.3723, 45.7332],
            [-97.4839, 45.7612],
            [-97.5326, 45.9352],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4600053',
        NAME: 'Sisseton School District 54-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2121985125,
        AWATER: 83563566,
        INTPTLAT: '+45.7262132',
        INTPTLON: '-097.1137032',
        ELSDLEA: '',
        UNSDLEA: '00053',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.2585, 43.8484],
              [-97.2489, 43.8483],
              [-97.2586, 43.8405],
              [-97.2585, 43.8484],
            ],
          ],
          [
            [
              [-97.6087, 43.8039],
              [-97.5296, 43.8338],
              [-97.5095, 43.8339],
              [-97.4094, 43.8775],
              [-97.3995, 43.8775],
              [-97.4094, 43.8847],
              [-97.3697, 43.9133],
              [-97.3291, 43.8703],
              [-97.2792, 43.8484],
              [-97.2586, 43.8405],
              [-97.3097, 43.7748],
              [-97.2879, 43.6592],
              [-97.3079, 43.6667],
              [-97.3783, 43.6302],
              [-97.4284, 43.6446],
              [-97.6065, 43.6733],
              [-97.6087, 43.8039],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4601026',
        NAME: 'McCook Central School District 43-7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 573379705,
        AWATER: 1774566,
        INTPTLAT: '+43.7550989',
        INTPTLON: '-097.4268276',
        ELSDLEA: '',
        UNSDLEA: '01026',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.2593, 44.304],
              [-97.2549, 44.2971],
              [-97.2594, 44.2971],
              [-97.2593, 44.304],
            ],
          ],
          [
            [
              [-97.2594, 44.2934],
              [-97.2549, 44.2971],
              [-97.2396, 44.2827],
              [-97.2508, 44.2827],
              [-97.2594, 44.2934],
            ],
          ],
          [
            [
              [-97.2594, 44.2971],
              [-97.2594, 44.2934],
              [-97.2708, 44.2934],
              [-97.2594, 44.2971],
            ],
          ],
          [
            [
              [-97.4516, 44.2385],
              [-97.2708, 44.2894],
              [-97.2508, 44.2827],
              [-97.2396, 44.2827],
              [-97.2099, 44.2614],
              [-97.1099, 44.1958],
              [-97.1295, 44.1089],
              [-97.2995, 44.0446],
              [-97.3701, 44.044],
              [-97.4219, 44.1677],
              [-97.481, 44.195],
              [-97.4516, 44.2385],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4601027',
        NAME: 'Oldham-Ramona School District 39-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496145076,
        AWATER: 957422,
        INTPTLAT: '+44.1743543',
        INTPTLON: '-097.2812464',
        ELSDLEA: '',
        UNSDLEA: '01027',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2436, 45.5803],
            [-100.3379, 45.5945],
            [-100.3373, 45.6632],
            [-100.3132, 45.6812],
            [-100.1877, 45.6845],
            [-99.9343, 45.637],
            [-99.8673, 45.6187],
            [-99.7846, 45.6078],
            [-99.7694, 45.6005],
            [-99.7801, 45.5215],
            [-99.8837, 45.3409],
            [-99.8732, 45.3044],
            [-99.9348, 45.2899],
            [-99.9556, 45.2465],
            [-100.2602, 45.2468],
            [-100.3167, 45.3179],
            [-100.2742, 45.3818],
            [-100.3766, 45.5076],
            [-100.2436, 45.5803],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4601028',
        NAME: 'Selby School District 62-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1619652644,
        AWATER: 70576284,
        INTPTLAT: '+45.4789451',
        INTPTLON: '-100.0812990',
        ELSDLEA: '',
        UNSDLEA: '01028',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7249, 45.6782],
            [-98.601, 45.6778],
            [-98.5804, 45.6196],
            [-98.4975, 45.619],
            [-98.404, 45.6764],
            [-98.3089, 45.5382],
            [-98.309, 45.5242],
            [-98.2576, 45.5308],
            [-98.2474, 45.5308],
            [-98.2885, 45.502],
            [-98.278, 45.386],
            [-98.2989, 45.3427],
            [-98.4644, 45.3652],
            [-98.4852, 45.3795],
            [-98.5058, 45.3725],
            [-98.5265, 45.3801],
            [-98.5985, 45.4166],
            [-98.7222, 45.3886],
            [-98.725, 45.5913],
            [-98.7249, 45.6782],
          ],
          [
            [-98.58, 45.6632],
            [-98.5697, 45.663],
            [-98.5386, 45.6627],
            [-98.58, 45.6632],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4602070',
        NAME: 'Aberdeen School District 06-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1084897152,
        AWATER: 4475593,
        INTPTLAT: '+45.4960291',
        INTPTLON: '-098.5049772',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8511, 43.8499],
            [-97.7809, 43.8933],
            [-97.7375, 43.841],
            [-97.6687, 43.8185],
            [-97.6487, 43.8104],
            [-97.6087, 43.8039],
            [-97.6065, 43.6733],
            [-97.6751, 43.659],
            [-97.7065, 43.536],
            [-97.7265, 43.5359],
            [-97.7663, 43.5289],
            [-97.7592, 43.4998],
            [-97.8563, 43.4996],
            [-97.8664, 43.5576],
            [-97.8896, 43.7609],
            [-97.8511, 43.8499],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4602640',
        NAME: 'Hanson School District 30-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 664228398,
        AWATER: 3023719,
        INTPTLAT: '+43.6950350',
        INTPTLON: '-097.7574439',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.2594, 44.2971],
              [-97.2549, 44.2971],
              [-97.2594, 44.2934],
              [-97.2594, 44.2971],
            ],
          ],
          [
            [
              [-97.2708, 44.2894],
              [-97.2708, 44.2934],
              [-97.2594, 44.2934],
              [-97.2508, 44.2827],
              [-97.2708, 44.2894],
            ],
          ],
          [
            [
              [-97.29, 44.5434],
              [-97.0669, 44.5431],
              [-97.0078, 44.4847],
              [-97.0376, 44.4415],
              [-97.0275, 44.3982],
              [-97.0592, 44.2464],
              [-97.0493, 44.1958],
              [-97.1099, 44.1958],
              [-97.2099, 44.2614],
              [-97.2396, 44.2827],
              [-97.2549, 44.2971],
              [-97.2593, 44.304],
              [-97.2507, 44.3116],
              [-97.2492, 44.4704],
              [-97.29, 44.5434],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4603720',
        NAME: 'Arlington School District 38-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 567721764,
        AWATER: 32137504,
        INTPTLAT: '+44.3911624',
        INTPTLON: '-097.1389205',
        ELSDLEA: '',
        UNSDLEA: '03720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2828, 43.3989],
            [-98.1794, 43.3838],
            [-98.1696, 43.3581],
            [-98.2664, 43.2901],
            [-98.253, 43.2409],
            [-98.2826, 43.2306],
            [-98.3475, 43.2275],
            [-98.3574, 43.2346],
            [-98.5448, 43.3316],
            [-98.5761, 43.3416],
            [-98.2828, 43.3989],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4603780',
        NAME: 'Armour School District 21-1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 334788849,
        AWATER: 914247,
        INTPTLAT: '+43.3183540',
        INTPTLON: '-098.3500466',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2057, 43.9674],
            [-98.1515, 43.9739],
            [-98.1616, 43.9896],
            [-98.1617, 43.9967],
            [-98.172, 43.9967],
            [-98.1397, 44.0252],
            [-98.1306, 44.0251],
            [-98.0768, 44.0542],
            [-98.1106, 44.1554],
            [-98.1106, 44.162],
            [-98.0854, 44.1958],
            [-98.064, 44.1957],
            [-98.0438, 44.1955],
            [-97.8495, 44.1952],
            [-97.8415, 44.0742],
            [-97.8214, 44.0742],
            [-97.8313, 44.0238],
            [-97.7909, 43.9657],
            [-97.7708, 43.9295],
            [-97.7809, 43.8933],
            [-97.8511, 43.8499],
            [-98.0974, 43.8511],
            [-98.1269, 43.8182],
            [-98.1377, 43.8417],
            [-98.1469, 43.8417],
            [-98.1675, 43.8419],
            [-98.1776, 43.8419],
            [-98.1921, 43.8511],
            [-98.2419, 43.8586],
            [-98.2057, 43.9674],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4603932',
        NAME: 'Sanborn Central School District 55-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1022879121,
        AWATER: 2270133,
        INTPTLAT: '+44.0026636',
        INTPTLON: '-098.0013024',
        ELSDLEA: '',
        UNSDLEA: '03932',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1684, 42.9817],
            [-98.1685, 42.9888],
            [-98.1033, 43.1116],
            [-98.0926, 43.1254],
            [-98.0915, 43.1397],
            [-97.97, 43.0749],
            [-97.9858, 42.9784],
            [-97.991, 42.945],
            [-98.0108, 42.8946],
            [-98.0107, 42.8743],
            [-98.1302, 42.8223],
            [-98.1531, 42.839],
            [-98.1608, 42.9088],
            [-98.1684, 42.9817],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4604270',
        NAME: 'Avon School District 04-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 463881269,
        AWATER: 1179343,
        INTPTLAT: '+42.9720589',
        INTPTLON: '-098.0840478',
        ELSDLEA: '',
        UNSDLEA: '04270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6415, 43.7542],
            [-96.6711, 43.7142],
            [-96.7113, 43.6742],
            [-96.7714, 43.6598],
            [-96.8313, 43.7836],
            [-96.7396, 43.8044],
            [-96.6415, 43.7542],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4604680',
        NAME: 'Baltic School District 49-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 139470874,
        AWATER: 1042508,
        INTPTLAT: '+43.7396083',
        INTPTLON: '-096.7356366',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0579, 44.9976],
            [-104.04, 45.2129],
            [-103.5081, 45.2128],
            [-103.5077, 44.9523],
            [-103.6908, 44.9493],
            [-103.7122, 44.7661],
            [-103.5674, 44.6041],
            [-103.6885, 44.5611],
            [-104.0118, 44.6043],
            [-104.0559, 44.571],
            [-104.0579, 44.9976],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4605610',
        NAME: 'Belle Fourche School District 09-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2441278970,
        AWATER: 30559963,
        INTPTLAT: '+44.9310489',
        INTPTLON: '-103.8337001',
        ELSDLEA: '',
        UNSDLEA: '05610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.1108, 43.3899],
            [-101.2282, 43.3892],
            [-101.2281, 42.9981],
            [-101.4264, 42.9972],
            [-101.4956, 42.9971],
            [-101.5142, 42.9971],
            [-102.0825, 42.9994],
            [-102.1089, 43.1293],
            [-102.1108, 43.3899],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4606240',
        NAME: 'Bennett County School District 03-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3068383008,
        AWATER: 15010866,
        INTPTLAT: '+43.1869121',
        INTPTLON: '-101.6771810',
        ELSDLEA: '',
        UNSDLEA: '06240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.8848, 43.0982],
              [-96.8753, 43.0982],
              [-96.8848, 43.0911],
              [-96.8848, 43.0982],
            ],
          ],
          [
            [
              [-96.9396, 42.9675],
              [-96.8848, 43.0837],
              [-96.8753, 43.0982],
              [-96.8848, 43.0982],
              [-96.8562, 43.2073],
              [-96.836, 43.2289],
              [-96.8357, 43.2357],
              [-96.8061, 43.2288],
              [-96.7963, 43.2354],
              [-96.7469, 43.221],
              [-96.7271, 43.1997],
              [-96.6679, 43.1418],
              [-96.6678, 43.131],
              [-96.6678, 43.1272],
              [-96.7267, 42.9322],
              [-96.7664, 42.8741],
              [-96.8254, 42.91],
              [-96.8152, 42.917],
              [-96.8353, 42.9245],
              [-96.8697, 42.9174],
              [-96.8746, 42.9174],
              [-96.9239, 42.9351],
              [-96.9396, 42.9675],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4606360',
        NAME: 'Beresford School District 61-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 530670996,
        AWATER: 189949,
        INTPTLAT: '+43.0473489',
        INTPTLON: '-096.7905610',
        ELSDLEA: '',
        UNSDLEA: '06360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5555, 45.3842],
            [-96.453, 45.2761],
            [-96.5235, 45.2762],
            [-96.5555, 45.3842],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4606960',
        NAME: 'Big Stone City School District 25-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41959076,
        AWATER: 11537389,
        INTPTLAT: '+45.3194075',
        INTPTLON: '-096.5032107',
        ELSDLEA: '',
        UNSDLEA: '06960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.9573, 45.2129],
            [-102.9502, 45.6472],
            [-102.457, 45.6461],
            [-102.4566, 45.7321],
            [-102.5545, 45.7357],
            [-102.5698, 45.8196],
            [-102.4457, 45.8197],
            [-102.3683, 45.7324],
            [-102.2101, 45.732],
            [-102.1694, 45.5519],
            [-102.2104, 45.5305],
            [-102.2928, 45.5305],
            [-102.262, 45.4292],
            [-102.227, 45.3288],
            [-102.2827, 45.2997],
            [-102.3441, 45.2126],
            [-102.4679, 45.2128],
            [-102.4679, 45.126],
            [-102.7129, 45.1257],
            [-102.7154, 45.039],
            [-102.9574, 45.0396],
            [-102.9573, 45.2129],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4607050',
        NAME: 'Bison School District 52-1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3427224414,
        AWATER: 27531965,
        INTPTLAT: '+45.4265364',
        INTPTLON: '-102.6094513',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.0107, 42.8743],
            [-98.0108, 42.8946],
            [-97.991, 42.945],
            [-97.9858, 42.9784],
            [-97.97, 43.0749],
            [-97.9205, 43.1257],
            [-97.8131, 43.1118],
            [-97.6947, 43.0104],
            [-97.6302, 42.9376],
            [-97.6354, 42.8515],
            [-97.7207, 42.8473],
            [-97.7794, 42.851],
            [-97.9035, 42.8008],
            [-98.0131, 42.7622],
            [-98.1302, 42.8223],
            [-98.0107, 42.8743],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4607400',
        NAME: 'Bon Homme School District 04-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 774538838,
        AWATER: 44666174,
        INTPTLAT: '+42.9408173',
        INTPTLON: '-097.8462216',
        ELSDLEA: '',
        UNSDLEA: '07400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1783, 43.0824],
            [-99.0604, 43.1396],
            [-99.0815, 43.2122],
            [-98.9951, 43.2702],
            [-98.8777, 43.2266],
            [-98.8688, 43.162],
            [-98.7734, 43.1459],
            [-98.6458, 43.0713],
            [-98.6455, 42.9985],
            [-99.1583, 42.9984],
            [-99.1783, 43.0824],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4607670',
        NAME: 'South Central School District 26-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 767654185,
        AWATER: 51570306,
        INTPTLAT: '+43.0921823',
        INTPTLON: '-098.9179288',
        ELSDLEA: '',
        UNSDLEA: '07670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7801, 45.5215],
            [-99.7694, 45.6005],
            [-99.7077, 45.5934],
            [-99.5849, 45.55],
            [-99.5027, 45.4773],
            [-99.504, 45.2453],
            [-99.5225, 45.2453],
            [-99.6679, 45.2456],
            [-99.6678, 45.3325],
            [-99.77, 45.3619],
            [-99.8732, 45.3044],
            [-99.8837, 45.3409],
            [-99.7801, 45.5215],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4607800',
        NAME: 'Bowdle School District 22-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 696089492,
        AWATER: 16510848,
        INTPTLAT: '+45.3979478',
        INTPTLON: '-099.6572623',
        ELSDLEA: '',
        UNSDLEA: '07800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7113, 43.6742],
            [-96.6711, 43.6814],
            [-96.4534, 43.6459],
            [-96.4533, 43.5004],
            [-96.5992, 43.5005],
            [-96.6192, 43.5004],
            [-96.6514, 43.5041],
            [-96.7113, 43.6742],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4607950',
        NAME: 'Brandon Valley School District 49-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325436917,
        AWATER: 1211933,
        INTPTLAT: '+43.5850120',
        INTPTLON: '-096.5738292',
        ELSDLEA: '',
        UNSDLEA: '07950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7295, 44.1671],
              [-96.7194, 44.1671],
              [-96.7295, 44.1597],
              [-96.7295, 44.1671],
            ],
          ],
          [
            [
              [-96.9284, 44.1957],
              [-96.8996, 44.2537],
              [-96.8387, 44.3482],
              [-96.8379, 44.4639],
              [-96.7665, 44.514],
              [-96.767, 44.4851],
              [-96.7271, 44.4055],
              [-96.6465, 44.3691],
              [-96.5978, 44.3111],
              [-96.5981, 44.3038],
              [-96.7074, 44.2536],
              [-96.708, 44.1954],
              [-96.7295, 44.1671],
              [-96.7496, 44.1598],
              [-96.7595, 44.1525],
              [-96.8089, 44.1455],
              [-96.8495, 44.1528],
              [-96.8596, 44.1673],
              [-96.8892, 44.1817],
              [-96.8999, 44.1957],
              [-96.9094, 44.1957],
              [-96.9284, 44.1957],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4608520',
        NAME: 'Brookings School District 05-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 497393226,
        AWATER: 4669944,
        INTPTLAT: '+44.3188084',
        INTPTLON: '-096.7656476',
        ELSDLEA: '',
        UNSDLEA: '08520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0455, 45.9453],
            [-103.2451, 45.9452],
            [-102.9538, 45.945],
            [-102.9421, 45.945],
            [-102.9502, 45.6472],
            [-102.9573, 45.2129],
            [-103.5081, 45.2128],
            [-104.04, 45.2129],
            [-104.041, 45.4698],
            [-104.0442, 45.882],
            [-104.0455, 45.9453],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4609300',
        NAME: 'Harding County School District 31-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6919138401,
        AWATER: 16956320,
        INTPTLAT: '+45.5966116',
        INTPTLON: '-103.4738669',
        ELSDLEA: '',
        UNSDLEA: '09300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.297, 43.4556],
            [-99.1529, 43.4269],
            [-98.9951, 43.2702],
            [-99.0815, 43.2122],
            [-99.0604, 43.1396],
            [-99.1783, 43.0824],
            [-99.1583, 42.9984],
            [-99.2545, 42.9981],
            [-99.3555, 42.9982],
            [-99.3468, 43.2124],
            [-99.2969, 43.2775],
            [-99.3268, 43.3853],
            [-99.297, 43.4556],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4609512',
        NAME: 'Burke School District 26-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 875746526,
        AWATER: 32914989,
        INTPTLAT: '+43.2015128',
        INTPTLON: '-099.2089925',
        ELSDLEA: '',
        UNSDLEA: '09512',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.1495, 43.5434],
              [-97.1393, 43.5361],
              [-97.1495, 43.5362],
              [-97.1495, 43.5434],
            ],
          ],
          [
            [
              [-97.1694, 43.5435],
              [-97.1643, 43.5435],
              [-97.1495, 43.5362],
              [-97.1494, 43.5216],
              [-97.1595, 43.5216],
              [-97.1695, 43.5327],
              [-97.1694, 43.5435],
            ],
          ],
          [
            [
              [-97.3881, 43.5867],
              [-97.3779, 43.6157],
              [-97.3781, 43.623],
              [-97.3783, 43.6302],
              [-97.3079, 43.6667],
              [-97.2879, 43.6592],
              [-97.2779, 43.6591],
              [-97.268, 43.6591],
              [-97.168, 43.6303],
              [-97.1483, 43.5865],
              [-97.1694, 43.5435],
              [-97.1793, 43.5435],
              [-97.2585, 43.5143],
              [-97.3085, 43.5073],
              [-97.3682, 43.4998],
              [-97.3878, 43.5724],
              [-97.3881, 43.5867],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4610320',
        NAME: 'Canistota School District 43-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253256860,
        AWATER: 1923073,
        INTPTLAT: '+43.5827141',
        INTPTLON: '-097.2785481',
        ELSDLEA: '',
        UNSDLEA: '10320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.6192, 43.5004],
              [-96.5992, 43.5005],
              [-96.6005, 43.4531],
              [-96.6192, 43.5004],
            ],
          ],
          [
            [
              [-96.7869, 43.2647],
              [-96.767, 43.3013],
              [-96.767, 43.3158],
              [-96.7572, 43.3158],
              [-96.7173, 43.3301],
              [-96.7274, 43.3377],
              [-96.7573, 43.3377],
              [-96.7494, 43.3665],
              [-96.7472, 43.368],
              [-96.7567, 43.3882],
              [-96.7075, 43.3881],
              [-96.7075, 43.381],
              [-96.5995, 43.4455],
              [-96.5217, 43.3868],
              [-96.555, 43.26],
              [-96.4691, 43.1847],
              [-96.544, 43.1561],
              [-96.6386, 43.1343],
              [-96.6678, 43.131],
              [-96.6679, 43.1418],
              [-96.7271, 43.1997],
              [-96.7469, 43.221],
              [-96.7963, 43.2354],
              [-96.7869, 43.2647],
            ],
          ],
          [
            [
              [-96.8357, 43.2357],
              [-96.836, 43.2289],
              [-96.8458, 43.2289],
              [-96.8357, 43.2357],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4610560',
        NAME: 'Canton School District 41-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 503237810,
        AWATER: 533126,
        INTPTLAT: '+43.2768384',
        INTPTLON: '-096.6427575',
        ELSDLEA: '',
        UNSDLEA: '10560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.189, 44.7581],
            [-97.127, 44.8033],
            [-97.0159, 44.8042],
            [-96.9341, 44.8044],
            [-96.9252, 44.8044],
            [-96.8846, 44.8044],
            [-96.8848, 44.6886],
            [-97.0444, 44.6301],
            [-97.189, 44.7581],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4611280',
        NAME: 'Castlewood School District 28-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 331006904,
        AWATER: 5422228,
        INTPTLAT: '+44.7295807',
        INTPTLON: '-097.0199652',
        ELSDLEA: '',
        UNSDLEA: '11280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.8848, 43.0911],
              [-96.8753, 43.0982],
              [-96.8848, 43.0837],
              [-96.8848, 43.0911],
            ],
          ],
          [
            [
              [-97.0322, 43.1631],
              [-96.9642, 43.25],
              [-96.9543, 43.2499],
              [-96.8562, 43.2073],
              [-96.8848, 43.0982],
              [-96.8848, 43.0911],
              [-96.8848, 43.0837],
              [-96.9396, 42.9675],
              [-97.0617, 43.0761],
              [-97.0322, 43.1631],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4611760',
        NAME: 'Centerville School District 60-1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359378527,
        AWATER: 122431,
        INTPTLAT: '+43.1225450',
        INTPTLON: '-096.9484526',
        ELSDLEA: '',
        UNSDLEA: '11760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5766, 44.1924],
            [-99.3002, 44.1948],
            [-99.1668, 44.1878],
            [-99.1675, 44.02],
            [-99.1083, 43.9341],
            [-99.1527, 43.7586],
            [-99.1512, 43.5207],
            [-99.2979, 43.4999],
            [-99.3754, 43.6569],
            [-99.5344, 43.6301],
            [-99.6234, 43.6299],
            [-99.6236, 43.7424],
            [-99.7433, 43.7484],
            [-99.7435, 43.8469],
            [-99.5258, 43.8468],
            [-99.5256, 43.9262],
            [-99.6202, 43.9921],
            [-99.5113, 44.055],
            [-99.5766, 44.1924],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4612000',
        NAME: 'Chamberlain School District 07-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2250037007,
        AWATER: 157263888,
        INTPTLAT: '+43.8579357',
        INTPTLON: '-099.3716788',
        ELSDLEA: '',
        UNSDLEA: '12000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1388, 43.8408],
            [-97.1291, 43.848],
            [-97.1191, 43.935],
            [-96.9891, 43.9351],
            [-97.0092, 44.0221],
            [-96.9041, 44.0297],
            [-96.9088, 43.9787],
            [-96.8491, 43.9208],
            [-96.8391, 43.9063],
            [-96.9105, 43.8264],
            [-96.9998, 43.834],
            [-97.0694, 43.7907],
            [-97.129, 43.812],
            [-97.129, 43.8298],
            [-97.1386, 43.8333],
            [-97.1388, 43.8408],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4612300',
        NAME: 'Chester School District 39-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 355049710,
        AWATER: 10219180,
        INTPTLAT: '+43.8989571',
        INTPTLON: '-096.9991269',
        ELSDLEA: '',
        UNSDLEA: '12300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9436, 45.1385],
            [-97.6782, 45.1521],
            [-97.5448, 45.1448],
            [-97.5145, 45.068],
            [-97.4941, 45.0214],
            [-97.4942, 44.8913],
            [-97.5415, 44.8331],
            [-97.5366, 44.8111],
            [-97.7351, 44.7181],
            [-97.9772, 44.7187],
            [-97.9822, 45.059],
            [-97.9436, 45.1385],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4612940',
        NAME: 'Clark School District 12-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1601483565,
        AWATER: 17705795,
        INTPTLAT: '+44.9425166',
        INTPTLON: '-097.7586712',
        ELSDLEA: '',
        UNSDLEA: '12940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.4856, 44],
              [-96.4532, 43.9927],
              [-96.4532, 43.9636],
              [-96.4856, 44],
            ],
          ],
          [
            [
              [-96.708, 44.1954],
              [-96.6394, 44.1955],
              [-96.7194, 44.1671],
              [-96.7295, 44.1671],
              [-96.708, 44.1954],
            ],
          ],
          [
            [
              [-96.7595, 44.1525],
              [-96.7496, 44.1525],
              [-96.7496, 44.1598],
              [-96.7295, 44.1671],
              [-96.7295, 44.1597],
              [-96.7294, 44.1304],
              [-96.7495, 44.1305],
              [-96.7595, 44.1525],
            ],
          ],
          [
            [
              [-96.9088, 43.9787],
              [-96.9041, 44.0297],
              [-96.849, 44.0658],
              [-96.8495, 44.1528],
              [-96.8089, 44.1455],
              [-96.7887, 44.109],
              [-96.7486, 44.037],
              [-96.5876, 44.0218],
              [-96.597, 43.9352],
              [-96.6683, 43.9279],
              [-96.8391, 43.9063],
              [-96.8491, 43.9208],
              [-96.9088, 43.9787],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4614100',
        NAME: 'Colman-Egan School District 50-5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 416242869,
        AWATER: 1016481,
        INTPTLAT: '+44.0163546',
        INTPTLON: '-096.7387111',
        ELSDLEA: '',
        UNSDLEA: '14100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-99.633, 43.3424],
              [-99.6379, 43.2775],
              [-99.5932, 43.1834],
              [-99.534, 43.1689],
              [-99.534, 42.998],
              [-99.8499, 42.998],
              [-99.8898, 43.1692],
              [-99.8893, 43.2561],
              [-99.633, 43.3424],
            ],
          ],
          [
            [
              [-100.6127, 43.4705],
              [-100.5697, 43.5946],
              [-100.4709, 43.6456],
              [-100.5498, 43.7377],
              [-100.339, 43.7169],
              [-100.1997, 43.6982],
              [-100.2001, 43.6306],
              [-100.1509, 43.5723],
              [-100.1154, 43.494],
              [-100.1154, 43.4879],
              [-100.1171, 43.4299],
              [-100.2142, 43.3903],
              [-100.5729, 43.3901],
              [-100.6127, 43.4705],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4614130',
        NAME: 'Colome Consolidated School District 59-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2084457137,
        AWATER: 5561370,
        INTPTLAT: '+43.5572148',
        INTPTLON: '-100.3643434',
        ELSDLEA: '',
        UNSDLEA: '14130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6482, 43.4991],
            [-98.2712, 43.4987],
            [-98.2333, 43.498],
            [-98.182, 43.456],
            [-98.1794, 43.3838],
            [-98.2828, 43.3989],
            [-98.5761, 43.3416],
            [-98.6049, 43.3767],
            [-98.6665, 43.4053],
            [-98.6482, 43.4991],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4616230',
        NAME: 'Corsica School District 21-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496209143,
        AWATER: 2962101,
        INTPTLAT: '+43.4350202',
        INTPTLON: '-098.4204733',
        ELSDLEA: '',
        UNSDLEA: '16230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.8167, 43.8548],
            [-103.817, 43.9131],
            [-103.6762, 43.9133],
            [-103.6761, 43.8553],
            [-103.3383, 43.8564],
            [-103.3384, 43.8832],
            [-103.2996, 43.9128],
            [-103.0795, 43.8976],
            [-103.0796, 43.8567],
            [-102.8989, 43.8563],
            [-102.6876, 43.8556],
            [-102.7077, 43.7895],
            [-102.8104, 43.6881],
            [-102.9025, 43.6749],
            [-103.0011, 43.6058],
            [-103.0008, 43.4768],
            [-103.2231, 43.4771],
            [-103.2231, 43.5496],
            [-103.342, 43.5496],
            [-103.3411, 43.6078],
            [-103.4607, 43.6075],
            [-103.5793, 43.4777],
            [-103.7383, 43.4779],
            [-103.8159, 43.4779],
            [-103.8167, 43.8548],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4616950',
        NAME: 'Custer School District 16-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3115975805,
        AWATER: 5041313,
        INTPTLAT: '+43.7039844',
        INTPTLON: '-103.3060889',
        ELSDLEA: '',
        UNSDLEA: '16950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.4711, 44.5439],
              [-97.4611, 44.5439],
              [-97.451, 44.4992],
              [-97.4711, 44.5439],
            ],
          ],
          [
            [
              [-97.8036, 44.5444],
              [-97.7238, 44.5443],
              [-97.7133, 44.5442],
              [-97.6217, 44.5279],
              [-97.5529, 44.5508],
              [-97.5339, 44.544],
              [-97.4813, 44.4773],
              [-97.451, 44.4916],
              [-97.4406, 44.4701],
              [-97.4611, 44.4122],
              [-97.4516, 44.2385],
              [-97.481, 44.195],
              [-97.7142, 44.1949],
              [-97.6928, 44.354],
              [-97.6725, 44.3976],
              [-97.7528, 44.4554],
              [-97.7834, 44.4619],
              [-97.7932, 44.5044],
              [-97.8036, 44.5444],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4617850',
        NAME: 'De Smet School District 38-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 770063439,
        AWATER: 40281307,
        INTPTLAT: '+44.3687279',
        INTPTLON: '-097.6020290',
        ELSDLEA: '',
        UNSDLEA: '17850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.6711, 43.7142],
              [-96.6711, 43.6814],
              [-96.7113, 43.6742],
              [-96.6711, 43.7142],
            ],
          ],
          [
            [
              [-96.9105, 43.8264],
              [-96.8391, 43.9063],
              [-96.6683, 43.9279],
              [-96.5373, 43.8695],
              [-96.4533, 43.8912],
              [-96.4531, 43.8051],
              [-96.5143, 43.8207],
              [-96.5517, 43.7612],
              [-96.6415, 43.7542],
              [-96.7396, 43.8044],
              [-96.8313, 43.7836],
              [-96.9105, 43.8264],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4618120',
        NAME: 'Dell Rapids School District 49-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 434933415,
        AWATER: 646975,
        INTPTLAT: '+43.8394266',
        INTPTLON: '-096.6753911',
        ELSDLEA: '',
        UNSDLEA: '18120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2889, 44.9962],
            [-98.1662, 45.0823],
            [-98.125, 45.0883],
            [-98.1252, 45.0963],
            [-98.074, 45.1181],
            [-98.0227, 45.1322],
            [-97.9436, 45.1385],
            [-97.9822, 45.059],
            [-97.9772, 44.7187],
            [-98.0181, 44.6172],
            [-98.1194, 44.6321],
            [-98.1601, 44.7192],
            [-98.2408, 44.7194],
            [-98.2711, 44.8063],
            [-98.2365, 44.8352],
            [-98.2889, 44.9962],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4619170',
        NAME: 'Doland School District 56-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1112772965,
        AWATER: 833227,
        INTPTLAT: '+44.8669471',
        INTPTLON: '-098.1143228',
        ELSDLEA: '',
        UNSDLEA: '19170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.9298, 44.1406],
            [-102.9698, 44.082],
            [-103.1208, 44.0371],
            [-103.1312, 44.0674],
            [-103.1513, 44.1408],
            [-103.1916, 44.1408],
            [-103.2115, 44.1409],
            [-103.0707, 44.1654],
            [-103.0906, 44.3432],
            [-102.9692, 44.3437],
            [-102.9699, 44.1406],
            [-102.9298, 44.1406],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4619410',
        NAME: 'Douglas School District 51-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 389863246,
        AWATER: 530943,
        INTPTLAT: '+44.1816345',
        INTPTLON: '-103.0492963',
        ELSDLEA: '',
        UNSDLEA: '19410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.9994, 45.2992],
            [-101.855, 45.299],
            [-101.8548, 45.3856],
            [-101.7582, 45.3859],
            [-101.7489, 45.4688],
            [-101.6549, 45.3995],
            [-101.47, 45.4074],
            [-101.3638, 45.2695],
            [-101.5068, 45.2769],
            [-101.4869, 45.2103],
            [-101.5011, 44.9938],
            [-101.3799, 44.994],
            [-101.38, 44.6903],
            [-101.3974, 44.6568],
            [-101.531, 44.5679],
            [-101.715, 44.5795],
            [-101.7807, 44.5364],
            [-102.0004, 44.5117],
            [-102.0004, 44.5173],
            [-101.988, 44.864],
            [-101.9068, 44.8638],
            [-101.9073, 45.0385],
            [-102, 45.0818],
            [-101.9994, 45.2992],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4619450',
        NAME: 'Dupree School District 64-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3892182097,
        AWATER: 12681450,
        INTPTLAT: '+44.9278123',
        INTPTLON: '-101.7423657',
        ELSDLEA: '',
        UNSDLEA: '19450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.9469, 44.1683],
            [-100.3672, 44.1687],
            [-100.3662, 43.847],
            [-100.339, 43.7169],
            [-100.5498, 43.7377],
            [-100.6515, 43.695],
            [-100.987, 43.8491],
            [-101.0643, 43.8411],
            [-101.0641, 43.9949],
            [-101.0067, 43.9949],
            [-100.9469, 44.1683],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4619580',
        NAME: 'Jones County School District 37-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2449646775,
        AWATER: 3079970,
        INTPTLAT: '+43.9519896',
        INTPTLON: '-100.6861386',
        ELSDLEA: '',
        UNSDLEA: '19580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.4869, 45.2103],
            [-100.2677, 45.2126],
            [-100.3255, 45.0993],
            [-100.3158, 45.0156],
            [-100.4239, 44.99],
            [-100.4048, 44.8977],
            [-100.4187, 44.8434],
            [-100.5435, 44.7611],
            [-100.7176, 44.7709],
            [-100.8298, 44.7832],
            [-101.0226, 44.7242],
            [-101.1368, 44.7471],
            [-101.2295, 44.6923],
            [-101.3974, 44.6568],
            [-101.38, 44.6903],
            [-101.3799, 44.994],
            [-101.5011, 44.9938],
            [-101.4869, 45.2103],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4620100',
        NAME: 'Eagle Butte School District 20-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3988745014,
        AWATER: 274012194,
        INTPTLAT: '+44.9889787',
        INTPTLON: '-100.9446751',
        ELSDLEA: '',
        UNSDLEA: '20100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0548, 43.4778],
            [-103.8159, 43.4779],
            [-103.7383, 43.4779],
            [-103.6587, 43.4197],
            [-103.6426, 43.224],
            [-103.5433, 43.1297],
            [-103.5434, 43.0009],
            [-104.053, 43.0006],
            [-104.0548, 43.4778],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4620850',
        NAME: 'Edgemont School District 23-1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1841739345,
        AWATER: 1475524,
        INTPTLAT: '+43.2427932',
        INTPTLON: '-103.8393203',
        ELSDLEA: '',
        UNSDLEA: '20850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0555, 43.8535],
            [-103.8167, 43.8548],
            [-103.8159, 43.4779],
            [-104.0548, 43.4778],
            [-104.0548, 43.5033],
            [-104.0555, 43.8535],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4621300',
        NAME: 'Elk Mountain School District 16-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 800659930,
        AWATER: 284681,
        INTPTLAT: '+43.6808511',
        INTPTLON: '-103.9338162',
        ELSDLEA: '',
        UNSDLEA: '21300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.6333, 42.5402],
              [-96.6112, 42.5551],
              [-96.6125, 42.5066],
              [-96.6293, 42.5194],
              [-96.6333, 42.5331],
              [-96.6333, 42.5402],
            ],
          ],
          [
            [
              [-96.7568, 42.8027],
              [-96.7664, 42.8591],
              [-96.6513, 42.9091],
              [-96.5385, 42.9088],
              [-96.5413, 42.9024],
              [-96.6356, 42.7408],
              [-96.5015, 42.5902],
              [-96.6112, 42.5551],
              [-96.6502, 42.5619],
              [-96.6877, 42.6531],
              [-96.7926, 42.6659],
              [-96.8062, 42.7042],
              [-96.7568, 42.8027],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4621340',
        NAME: 'Elk Point-Jefferson School District 61-7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 549835358,
        AWATER: 9323219,
        INTPTLAT: '+42.7262444',
        INTPTLON: '-096.6588311',
        ELSDLEA: '',
        UNSDLEA: '21340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5981, 44.3038],
            [-96.5978, 44.3111],
            [-96.6465, 44.3691],
            [-96.452, 44.4128],
            [-96.4522, 44.3717],
            [-96.4528, 44.1968],
            [-96.4528, 44.1693],
            [-96.5491, 44.1529],
            [-96.6394, 44.1955],
            [-96.708, 44.1954],
            [-96.7074, 44.2536],
            [-96.5981, 44.3038],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4621390',
        NAME: 'Elkton School District 05-3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388764092,
        AWATER: 121,
        INTPTLAT: '+44.2780552',
        INTPTLON: '-096.5542214',
        ELSDLEA: '',
        UNSDLEA: '21390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.7244, 45.9387],
              [-98.3522, 45.9363],
              [-98.3524, 45.7594],
              [-98.274, 45.7629],
              [-98.404, 45.6764],
              [-98.4975, 45.619],
              [-98.5804, 45.6196],
              [-98.601, 45.6778],
              [-98.7249, 45.6782],
              [-98.7244, 45.9387],
            ],
          ],
          [
            [
              [-98.58, 45.6632],
              [-98.5386, 45.6627],
              [-98.5697, 45.663],
              [-98.58, 45.6632],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4621400',
        NAME: 'Frederick Area School District 06-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 906288873,
        AWATER: 5169683,
        INTPTLAT: '+45.8093838',
        INTPTLON: '-098.5298030',
        ELSDLEA: '',
        UNSDLEA: '21400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.699, 43.4291],
              [-97.6787, 43.4006],
              [-97.6987, 43.4004],
              [-97.699, 43.4291],
            ],
          ],
          [
            [
              [-97.7663, 43.5289],
              [-97.7265, 43.5359],
              [-97.7065, 43.536],
              [-97.6751, 43.659],
              [-97.6065, 43.6733],
              [-97.4284, 43.6446],
              [-97.3783, 43.6302],
              [-97.3781, 43.623],
              [-97.3779, 43.6157],
              [-97.3881, 43.5867],
              [-97.3878, 43.5724],
              [-97.3682, 43.4998],
              [-97.3926, 43.4998],
              [-97.4895, 43.444],
              [-97.6383, 43.4651],
              [-97.699, 43.4291],
              [-97.7194, 43.436],
              [-97.7196, 43.4435],
              [-97.7592, 43.4998],
              [-97.7663, 43.5289],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4621420',
        NAME: 'Bridgewater-Emery School District 30-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 593517723,
        AWATER: 1978834,
        INTPTLAT: '+43.5503585',
        INTPTLON: '-097.5594937',
        ELSDLEA: '',
        UNSDLEA: '21420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0444, 44.6301],
            [-96.8848, 44.6886],
            [-96.7535, 44.652],
            [-96.7535, 44.6306],
            [-96.7536, 44.6232],
            [-96.7234, 44.5653],
            [-96.7315, 44.5584],
            [-96.7665, 44.514],
            [-96.8379, 44.4639],
            [-96.9074, 44.4707],
            [-96.938, 44.4924],
            [-97.0078, 44.4847],
            [-97.0669, 44.5431],
            [-97.0444, 44.6301],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4622410',
        NAME: 'Estelline School District 28-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 435905203,
        AWATER: 2919239,
        INTPTLAT: '+44.5787816',
        INTPTLON: '-096.8974955',
        ELSDLEA: '',
        UNSDLEA: '22410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2056, 43.6001],
            [-98.0461, 43.5789],
            [-97.9969, 43.6074],
            [-97.8869, 43.6155],
            [-97.8664, 43.5576],
            [-97.8563, 43.4996],
            [-97.9946, 43.5202],
            [-98.0039, 43.5203],
            [-98.0246, 43.5203],
            [-98.1824, 43.5052],
            [-98.2024, 43.5053],
            [-98.2056, 43.6001],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4622500',
        NAME: 'Ethan School District 17-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273132576,
        AWATER: 233248,
        INTPTLAT: '+43.5484473',
        INTPTLON: '-098.0326947',
        ELSDLEA: '',
        UNSDLEA: '22500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7389, 45.9408],
            [-99.63, 45.9411],
            [-99.2223, 45.94],
            [-99.2835, 45.8106],
            [-99.2729, 45.7232],
            [-99.3452, 45.6799],
            [-99.5111, 45.6798],
            [-99.5941, 45.5931],
            [-99.7077, 45.5934],
            [-99.7694, 45.6005],
            [-99.7846, 45.6078],
            [-99.8673, 45.6187],
            [-99.9343, 45.637],
            [-99.9343, 45.6582],
            [-99.9016, 45.7843],
            [-99.8408, 45.8109],
            [-99.8347, 45.8981],
            [-99.7389, 45.9408],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4622560',
        NAME: 'Eureka School District 44-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1582339005,
        AWATER: 19376536,
        INTPTLAT: '+45.7888588',
        INTPTLON: '-099.5833879',
        ELSDLEA: '',
        UNSDLEA: '22560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.7154, 45.039],
            [-102.7129, 45.1257],
            [-102.4679, 45.126],
            [-102.4679, 45.2128],
            [-102.3441, 45.2126],
            [-102.2827, 45.2997],
            [-101.9994, 45.2992],
            [-102, 45.0818],
            [-101.9073, 45.0385],
            [-101.9068, 44.8638],
            [-101.988, 44.864],
            [-102.0004, 44.5173],
            [-102.241, 44.518],
            [-102.1423, 44.6899],
            [-102.0004, 44.69],
            [-102.0001, 44.9507],
            [-102.715, 44.9507],
            [-102.7154, 45.039],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4622940',
        NAME: 'Faith School District 46-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2228687475,
        AWATER: 4870615,
        INTPTLAT: '+45.0145080',
        INTPTLON: '-102.2682283',
        ELSDLEA: '',
        UNSDLEA: '22940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.5225, 45.2453],
            [-99.504, 45.2453],
            [-99.216, 45.2449],
            [-99.021, 45.2306],
            [-99.0218, 45.158],
            [-98.7987, 45.0563],
            [-98.7779, 44.9837],
            [-98.7776, 44.897],
            [-98.9879, 44.8668],
            [-99.0888, 44.8154],
            [-99.1909, 44.8091],
            [-99.3118, 44.8225],
            [-99.3529, 44.8972],
            [-99.5727, 44.8973],
            [-99.5734, 45.1734],
            [-99.5225, 45.2453],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4624030',
        NAME: 'Faulkton School District 24-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2329163973,
        AWATER: 51334878,
        INTPTLAT: '+45.0328541',
        INTPTLON: '-099.2187661',
        ELSDLEA: '',
        UNSDLEA: '24030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7496, 44.1598],
              [-96.7496, 44.1525],
              [-96.7595, 44.1525],
              [-96.7496, 44.1598],
            ],
          ],
          [
            [
              [-96.8089, 44.1455],
              [-96.7595, 44.1525],
              [-96.7495, 44.1305],
              [-96.7294, 44.1304],
              [-96.7295, 44.1597],
              [-96.7194, 44.1671],
              [-96.6394, 44.1955],
              [-96.5491, 44.1529],
              [-96.4528, 44.1693],
              [-96.4532, 44.0144],
              [-96.4533, 44.007],
              [-96.4532, 43.9927],
              [-96.4856, 44],
              [-96.4532, 43.9636],
              [-96.4534, 43.9203],
              [-96.4534, 43.9135],
              [-96.4533, 43.8912],
              [-96.5373, 43.8695],
              [-96.6683, 43.9279],
              [-96.597, 43.9352],
              [-96.5876, 44.0218],
              [-96.7486, 44.037],
              [-96.7887, 44.109],
              [-96.8089, 44.1455],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4624390',
        NAME: 'Flandreau School District 50-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 580832214,
        AWATER: 1022848,
        INTPTLAT: '+44.0499753',
        INTPTLON: '-096.5905004',
        ELSDLEA: '',
        UNSDLEA: '24390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4123, 45.1229],
            [-97.3851, 45.1686],
            [-97.2402, 45.1512],
            [-97.2263, 45.1518],
            [-97.2055, 45.1518],
            [-97.1953, 45.1517],
            [-97.1542, 45.1151],
            [-97.1543, 45.1082],
            [-97.1543, 45.1005],
            [-97.2393, 45.0134],
            [-97.361, 44.9344],
            [-97.3715, 44.9344],
            [-97.3761, 44.9413],
            [-97.3918, 44.9779],
            [-97.4942, 45.0428],
            [-97.4123, 45.1229],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4624540',
        NAME: 'Florence School District 14-1',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339338850,
        AWATER: 23980509,
        INTPTLAT: '+45.0719670',
        INTPTLON: '-097.3204316',
        ELSDLEA: '',
        UNSDLEA: '24540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.1552, 44.4513],
            [-101.1545, 44.6899],
            [-101.1368, 44.7471],
            [-101.0226, 44.7242],
            [-100.8298, 44.7832],
            [-100.7176, 44.7709],
            [-100.6138, 44.7071],
            [-100.6401, 44.5837],
            [-100.5265, 44.5474],
            [-100.5758, 44.4917],
            [-100.3864, 44.44],
            [-100.3654, 44.3555],
            [-100.0853, 44.3085],
            [-99.8942, 44.2371],
            [-99.9382, 44.1952],
            [-100.3672, 44.1946],
            [-100.3672, 44.1687],
            [-100.9469, 44.1683],
            [-101.1668, 44.1681],
            [-101.1552, 44.4513],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4624850',
        NAME: 'Stanley County School District 57-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3741062104,
        AWATER: 188383615,
        INTPTLAT: '+44.4155468',
        INTPTLON: '-100.7491628',
        ELSDLEA: '',
        UNSDLEA: '24850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6787, 43.4006],
            [-97.699, 43.4291],
            [-97.6383, 43.4651],
            [-97.4895, 43.444],
            [-97.3926, 43.4998],
            [-97.3597, 43.4153],
            [-97.2402, 43.3136],
            [-97.2303, 43.3066],
            [-97.2215, 43.2924],
            [-97.2599, 43.2851],
            [-97.2599, 43.2781],
            [-97.2796, 43.2708],
            [-97.2993, 43.2708],
            [-97.3993, 43.1983],
            [-97.4192, 43.2344],
            [-97.4791, 43.2769],
            [-97.4894, 43.2993],
            [-97.5397, 43.3204],
            [-97.6886, 43.3576],
            [-97.6787, 43.4006],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4625500',
        NAME: 'Freeman School District 33-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 616596552,
        AWATER: 2220059,
        INTPTLAT: '+43.3611626',
        INTPTLON: '-097.4709944',
        ELSDLEA: '',
        UNSDLEA: '25500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.6711, 43.7142],
            [-96.6415, 43.7542],
            [-96.5517, 43.7612],
            [-96.5143, 43.8207],
            [-96.4531, 43.8051],
            [-96.4534, 43.6824],
            [-96.4534, 43.6459],
            [-96.6711, 43.6814],
            [-96.6711, 43.7142],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4626370',
        NAME: 'Garretson School District 49-4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 227741260,
        AWATER: 811683,
        INTPTLAT: '+43.7249970',
        INTPTLON: '-096.5421664',
        ELSDLEA: '',
        UNSDLEA: '26370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.269, 42.9816],
            [-97.1606, 43.0104],
            [-97.1308, 42.8408],
            [-97.1217, 42.8363],
            [-97.116, 42.8225],
            [-97.1604, 42.7997],
            [-97.1721, 42.8029],
            [-97.2124, 42.8118],
            [-97.2181, 42.8288],
            [-97.2182, 42.8312],
            [-97.2496, 42.8555],
            [-97.269, 42.9816],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4626490',
        NAME: 'Gayville-Volin School District 63-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184069195,
        AWATER: 3270214,
        INTPTLAT: '+42.9168978',
        INTPTLON: '-097.1961608',
        ELSDLEA: '',
        UNSDLEA: '26490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.0109, 44.9413],
              [-100.011, 44.9268],
              [-100.0519, 44.934],
              [-100.0109, 44.9413],
            ],
          ],
          [
            [
              [-99.8583, 45.1151],
              [-99.8277, 45.057],
              [-99.8179, 44.8975],
              [-99.9398, 44.8976],
              [-99.9399, 44.9846],
              [-100.0626, 44.9847],
              [-100.0417, 45.0717],
              [-99.8583, 45.1151],
            ],
          ],
          [
            [
              [-100.314, 45.0074],
              [-100.1939, 45.0209],
              [-100.0626, 44.9847],
              [-100.0519, 44.934],
              [-100.062, 44.8977],
              [-100.3076, 44.8981],
              [-100.314, 45.0074],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4626970',
        NAME: 'Gettysburg School District 53-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 574607759,
        AWATER: 2469494,
        INTPTLAT: '+44.9919870',
        INTPTLON: '-100.0318813',
        ELSDLEA: '',
        UNSDLEA: '26970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7522, 45.1063],
            [-96.6, 45.1071],
            [-96.4521, 45.1499],
            [-96.4522, 45.0883],
            [-96.4518, 44.8911],
            [-96.669, 44.9192],
            [-96.6997, 44.9768],
            [-96.8823, 44.9769],
            [-96.8828, 45.0492],
            [-96.7522, 45.1063],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4629340',
        NAME: 'Grant-Deuel School District 25-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 661024095,
        AWATER: 4654119,
        INTPTLAT: '+45.0150288',
        INTPTLON: '-096.6294631',
        ELSDLEA: '',
        UNSDLEA: '29340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.633, 43.3424],
            [-99.5339, 43.3426],
            [-99.5344, 43.6301],
            [-99.3754, 43.6569],
            [-99.2979, 43.4999],
            [-99.297, 43.4556],
            [-99.3268, 43.3853],
            [-99.2969, 43.2775],
            [-99.3468, 43.2124],
            [-99.3555, 42.9982],
            [-99.534, 42.998],
            [-99.534, 43.1689],
            [-99.5932, 43.1834],
            [-99.6379, 43.2775],
            [-99.633, 43.3424],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4629880',
        NAME: 'Gregory School District 26-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1312378650,
        AWATER: 23439193,
        INTPTLAT: '+43.3293315',
        INTPTLON: '-099.4444602',
        ELSDLEA: '',
        UNSDLEA: '29880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0011, 44.5109],
            [-102.0004, 44.5117],
            [-101.7807, 44.5364],
            [-101.715, 44.5795],
            [-101.531, 44.5679],
            [-101.3974, 44.6568],
            [-101.2295, 44.6923],
            [-101.1368, 44.7471],
            [-101.1545, 44.6899],
            [-101.1552, 44.4513],
            [-101.2865, 44.3435],
            [-101.2869, 44.0813],
            [-101.4061, 44.0812],
            [-101.4073, 43.9951],
            [-101.6013, 43.9076],
            [-101.7802, 43.9515],
            [-101.8996, 43.9517],
            [-102.0195, 43.9078],
            [-102.0073, 43.9945],
            [-102.0011, 44.5109],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4630490',
        NAME: 'Haakon School District 27-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4265031329,
        AWATER: 41611080,
        INTPTLAT: '+44.2828847',
        INTPTLON: '-101.5954603',
        ELSDLEA: '',
        UNSDLEA: '30490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.4913, 44.804],
            [-97.3803, 44.8401],
            [-97.3702, 44.8401],
            [-97.3498, 44.8474],
            [-97.2486, 44.7607],
            [-97.189, 44.7581],
            [-97.0444, 44.6301],
            [-97.0669, 44.5431],
            [-97.29, 44.5434],
            [-97.4611, 44.5439],
            [-97.4711, 44.5439],
            [-97.5339, 44.544],
            [-97.5529, 44.5508],
            [-97.4922, 44.6306],
            [-97.4913, 44.804],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4630800',
        NAME: 'Hamlin School District 28-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 875991028,
        AWATER: 67732968,
        INTPTLAT: '+44.6529157',
        INTPTLON: '-097.3202295',
        ELSDLEA: '',
        UNSDLEA: '30800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7173, 43.3301],
              [-96.7274, 43.3301],
              [-96.7274, 43.332],
              [-96.7274, 43.3377],
              [-96.7173, 43.3301],
            ],
          ],
          [
            [
              [-96.8063, 43.4922],
              [-96.6514, 43.5041],
              [-96.6192, 43.5004],
              [-96.6005, 43.4531],
              [-96.5995, 43.4455],
              [-96.7075, 43.381],
              [-96.7075, 43.3881],
              [-96.7567, 43.3882],
              [-96.7869, 43.3955],
              [-96.787, 43.4027],
              [-96.7965, 43.4028],
              [-96.7967, 43.411],
              [-96.7968, 43.4173],
              [-96.7966, 43.4254],
              [-96.8063, 43.4922],
            ],
            [
              [-96.787, 43.4027],
              [-96.7872, 43.4131],
              [-96.7871, 43.4104],
              [-96.787, 43.4027],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4631350',
        NAME: 'Harrisburg School District 41-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 182333246,
        AWATER: 380098,
        INTPTLAT: '+43.4370417',
        INTPTLON: '-096.7022591',
        ELSDLEA: '',
        UNSDLEA: '31350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1483, 43.5865],
            [-97.168, 43.6303],
            [-97.0698, 43.7324],
            [-96.9506, 43.7178],
            [-96.8708, 43.5872],
            [-96.811, 43.5874],
            [-96.8307, 43.5399],
            [-96.8407, 43.5329],
            [-96.841, 43.5323],
            [-96.8903, 43.5231],
            [-96.8903, 43.5222],
            [-96.9103, 43.5],
            [-96.9304, 43.5],
            [-96.9702, 43.4998],
            [-97.0789, 43.5433],
            [-97.0891, 43.5505],
            [-97.1393, 43.5361],
            [-97.1495, 43.5434],
            [-97.1643, 43.5435],
            [-97.1694, 43.5435],
            [-97.1483, 43.5865],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4631710',
        NAME: 'West Central School District 49-7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 421175301,
        AWATER: 6933572,
        INTPTLAT: '+43.6125854',
        INTPTLON: '-096.9999306',
        ELSDLEA: '',
        UNSDLEA: '31710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.3703, 44.8475],
              [-97.3498, 44.8474],
              [-97.3702, 44.8401],
              [-97.3703, 44.8475],
            ],
          ],
          [
            [
              [-97.4941, 45.0214],
              [-97.4942, 45.0428],
              [-97.3918, 44.9779],
              [-97.3761, 44.9413],
              [-97.3715, 44.9344],
              [-97.361, 44.9344],
              [-97.3703, 44.8475],
              [-97.3803, 44.8401],
              [-97.4913, 44.804],
              [-97.5366, 44.8111],
              [-97.5415, 44.8331],
              [-97.4942, 44.8913],
              [-97.4941, 45.0214],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4632340',
        NAME: 'Henry School District 14-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252355769,
        AWATER: 7222774,
        INTPTLAT: '+44.8986358',
        INTPTLON: '-097.4374185',
        ELSDLEA: '',
        UNSDLEA: '32340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.0879, 45.9422],
            [-100.0676, 45.9421],
            [-99.984, 45.9419],
            [-99.7389, 45.9408],
            [-99.8347, 45.8981],
            [-99.8408, 45.8109],
            [-99.9016, 45.7843],
            [-99.9343, 45.6582],
            [-100.212, 45.7107],
            [-100.2131, 45.8694],
            [-100.0879, 45.9422],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4632430',
        NAME: 'Herreid School District 10-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 707346724,
        AWATER: 2962608,
        INTPTLAT: '+45.8092579',
        INTPTLON: '-100.0322169',
        ELSDLEA: '',
        UNSDLEA: '32430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0546, 44.1411],
            [-103.4525, 44.1408],
            [-103.4588, 43.8972],
            [-103.3384, 43.8832],
            [-103.3383, 43.8564],
            [-103.6761, 43.8553],
            [-103.6762, 43.9133],
            [-103.817, 43.9131],
            [-103.8167, 43.8548],
            [-104.0555, 43.8535],
            [-104.0546, 44.1411],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4633360',
        NAME: 'Hill City School District 51-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1490516420,
        AWATER: 6209121,
        INTPTLAT: '+43.9945705',
        INTPTLON: '-103.7479896',
        ELSDLEA: '',
        UNSDLEA: '33360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.7077, 45.5934],
            [-99.5941, 45.5931],
            [-99.5111, 45.6798],
            [-99.3452, 45.6799],
            [-99.2729, 45.7232],
            [-99.1536, 45.5926],
            [-99.175, 45.3681],
            [-99.216, 45.2449],
            [-99.504, 45.2453],
            [-99.5027, 45.4773],
            [-99.5849, 45.55],
            [-99.7077, 45.5934],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4634440',
        NAME: 'Edmunds Central School District 22-5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1309310118,
        AWATER: 39008638,
        INTPTLAT: '+45.4762218',
        INTPTLON: '-099.3868548',
        ELSDLEA: '',
        UNSDLEA: '34440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.7383, 43.4779],
            [-103.5793, 43.4777],
            [-103.4607, 43.6075],
            [-103.3411, 43.6078],
            [-103.342, 43.5496],
            [-103.2231, 43.5496],
            [-103.2231, 43.4771],
            [-103.0008, 43.4768],
            [-103.0009, 43.3906],
            [-103.1444, 43.3904],
            [-103.2179, 43.3035],
            [-103.3463, 43.3036],
            [-103.466, 43.2456],
            [-103.4637, 43.1296],
            [-103.5433, 43.1297],
            [-103.6426, 43.224],
            [-103.6587, 43.4197],
            [-103.7383, 43.4779],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4634480',
        NAME: 'Hot Springs School District 23-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1505948467,
        AWATER: 21364646,
        INTPTLAT: '+43.3843485',
        INTPTLON: '-103.4188377',
        ELSDLEA: '',
        UNSDLEA: '34480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.0519, 44.934],
              [-100.0626, 44.9847],
              [-99.9399, 44.9846],
              [-99.9398, 44.8976],
              [-100.062, 44.8977],
              [-100.0519, 44.934],
            ],
            [
              [-100.0519, 44.934],
              [-100.011, 44.9268],
              [-100.0109, 44.9413],
              [-100.0519, 44.934],
            ],
          ],
          [
            [
              [-100.2677, 45.2126],
              [-100.2602, 45.2468],
              [-99.9556, 45.2465],
              [-99.9348, 45.2899],
              [-99.8732, 45.3044],
              [-99.77, 45.3619],
              [-99.6678, 45.3325],
              [-99.6679, 45.2456],
              [-99.5225, 45.2453],
              [-99.5734, 45.1734],
              [-99.5727, 44.8973],
              [-99.673, 44.8975],
              [-99.8179, 44.8975],
              [-99.8277, 45.057],
              [-99.8583, 45.1151],
              [-100.0417, 45.0717],
              [-100.0626, 44.9847],
              [-100.1939, 45.0209],
              [-100.314, 45.0074],
              [-100.3076, 44.8981],
              [-100.4048, 44.8977],
              [-100.4239, 44.99],
              [-100.3158, 45.0156],
              [-100.3255, 45.0993],
              [-100.2677, 45.2126],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4634600',
        NAME: 'Hoven School District 53-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1842278997,
        AWATER: 104907468,
        INTPTLAT: '+45.1313247',
        INTPTLON: '-099.9740458',
        ELSDLEA: '',
        UNSDLEA: '34600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.0742, 44.21],
              [-98.0438, 44.1955],
              [-98.064, 44.1957],
              [-98.0742, 44.21],
            ],
          ],
          [
            [
              [-98.3055, 44.4072],
              [-98.2956, 44.3997],
              [-98.3055, 44.3997],
              [-98.3055, 44.4072],
            ],
          ],
          [
            [
              [-98.3971, 44.3126],
              [-98.4175, 44.3126],
              [-98.4381, 44.356],
              [-98.3973, 44.3486],
              [-98.3464, 44.3996],
              [-98.3055, 44.4072],
              [-98.3158, 44.3997],
              [-98.3055, 44.3997],
              [-98.2852, 44.3916],
              [-98.2852, 44.4142],
              [-98.3257, 44.4432],
              [-98.3162, 44.4576],
              [-98.214, 44.501],
              [-98.2244, 44.5227],
              [-98.2102, 44.5965],
              [-98.1395, 44.6177],
              [-98.1194, 44.6321],
              [-98.0181, 44.6172],
              [-97.9778, 44.5592],
              [-98.0018, 44.5138],
              [-98.0731, 44.478],
              [-98.0531, 44.4566],
              [-97.9933, 44.3398],
              [-97.9935, 44.3109],
              [-98.0234, 44.2823],
              [-98.0743, 44.2167],
              [-98.0942, 44.1959],
              [-98.271, 44.1779],
              [-98.4577, 44.1967],
              [-98.3971, 44.3126],
            ],
            [
              [-98.3971, 44.3126],
              [-98.3666, 44.3197],
              [-98.3972, 44.327],
              [-98.3971, 44.3126],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4635480',
        NAME: 'Huron School District 02-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1110328127,
        AWATER: 14394580,
        INTPTLAT: '+44.3684013',
        INTPTLON: '-098.1966096',
        ELSDLEA: '',
        UNSDLEA: '35480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1536, 45.5926],
            [-98.725, 45.5913],
            [-98.7222, 45.3886],
            [-98.7627, 45.3166],
            [-98.7224, 45.2727],
            [-98.8763, 45.2448],
            [-98.8973, 45.2448],
            [-99.021, 45.2306],
            [-99.216, 45.2449],
            [-99.175, 45.3681],
            [-99.1536, 45.5926],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4636060',
        NAME: 'Ipswich School District 22-6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1374404974,
        AWATER: 11767617,
        INTPTLAT: '+45.4042536',
        INTPTLON: '-098.9574179',
        ELSDLEA: '',
        UNSDLEA: '36060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.0531, 44.4566],
              [-98.0731, 44.478],
              [-98.0018, 44.5138],
              [-97.9778, 44.5592],
              [-97.8036, 44.5444],
              [-97.7932, 44.5044],
              [-97.7834, 44.4619],
              [-97.7528, 44.4554],
              [-97.6725, 44.3976],
              [-97.6928, 44.354],
              [-97.7142, 44.1949],
              [-97.8495, 44.1952],
              [-98.0438, 44.1955],
              [-98.0742, 44.21],
              [-98.0743, 44.2167],
              [-98.0234, 44.2823],
              [-97.9935, 44.3109],
              [-97.9933, 44.3398],
              [-98.0531, 44.4566],
            ],
          ],
          [
            [
              [-98.0743, 44.2167],
              [-98.0742, 44.21],
              [-98.064, 44.1957],
              [-98.0854, 44.1958],
              [-98.0942, 44.1959],
              [-98.0743, 44.2167],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4636150',
        NAME: 'Iroquois School District 02-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 974875471,
        AWATER: 748204,
        INTPTLAT: '+44.3610841',
        INTPTLON: '-097.8740011',
        ELSDLEA: '',
        UNSDLEA: '36150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.6112, 42.5551],
              [-96.5015, 42.5902],
              [-96.4988, 42.561],
              [-96.4455, 42.4906],
              [-96.481, 42.4797],
              [-96.5413, 42.5189],
              [-96.5992, 42.5046],
              [-96.6125, 42.5066],
              [-96.6112, 42.5551],
            ],
          ],
          [
            [
              [-96.6502, 42.5619],
              [-96.6112, 42.5551],
              [-96.6333, 42.5402],
              [-96.6502, 42.5619],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4636990',
        NAME: 'Dakota Valley School District 61-8',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69423496,
        AWATER: 6928698,
        INTPTLAT: '+42.5285210',
        INTPTLON: '-096.5251372',
        ELSDLEA: '',
        UNSDLEA: '36990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.8274, 43.9352],
              [-98.7879, 43.935],
              [-98.8275, 43.9281],
              [-98.8274, 43.9352],
            ],
          ],
          [
            [
              [-99.1675, 44.02],
              [-99.0459, 44.0208],
              [-99.0485, 43.9346],
              [-98.8371, 43.9351],
              [-98.8275, 43.9281],
              [-98.8075, 43.921],
              [-98.7944, 43.5859],
              [-98.9133, 43.5859],
              [-98.9329, 43.5283],
              [-99.0321, 43.4996],
              [-99.1512, 43.5207],
              [-99.1527, 43.7586],
              [-99.1083, 43.9341],
              [-99.1675, 44.02],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4638220',
        NAME: 'Kimball School District 07-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1223376306,
        AWATER: 4930092,
        INTPTLAT: '+43.7473900',
        INTPTLON: '-098.9837179',
        ELSDLEA: '',
        UNSDLEA: '38220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5453, 43.2787],
            [-98.5448, 43.3316],
            [-98.3574, 43.2346],
            [-98.3574, 43.2273],
            [-98.3475, 43.2275],
            [-98.3823, 43.1765],
            [-98.4022, 43.1582],
            [-98.4517, 43.093],
            [-98.4985, 42.9986],
            [-98.6455, 42.9985],
            [-98.6458, 43.0713],
            [-98.6826, 43.1074],
            [-98.6646, 43.1992],
            [-98.5453, 43.2787],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4639540',
        NAME: 'Andes Central School District 11-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 486335514,
        AWATER: 43141775,
        INTPTLAT: '+43.1457058',
        INTPTLON: '-098.5354437',
        ELSDLEA: '',
        UNSDLEA: '39540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3701, 44.044],
            [-97.2995, 44.0446],
            [-97.1295, 44.1089],
            [-97.0591, 44.1235],
            [-97.0092, 44.0221],
            [-96.9891, 43.9351],
            [-97.1191, 43.935],
            [-97.1291, 43.848],
            [-97.1388, 43.8408],
            [-97.1386, 43.8333],
            [-97.129, 43.8298],
            [-97.129, 43.812],
            [-97.1692, 43.8327],
            [-97.2489, 43.8483],
            [-97.2585, 43.8484],
            [-97.2792, 43.8484],
            [-97.3291, 43.8703],
            [-97.3697, 43.9133],
            [-97.3701, 44.044],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4639600',
        NAME: 'Madison Central School District 39-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 629866184,
        AWATER: 20162514,
        INTPTLAT: '+43.9766766',
        INTPTLON: '-097.1920347',
        ELSDLEA: '',
        UNSDLEA: '39600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.4611, 44.4122],
              [-97.4406, 44.4701],
              [-97.451, 44.4916],
              [-97.451, 44.4992],
              [-97.4611, 44.5439],
              [-97.29, 44.5434],
              [-97.2492, 44.4704],
              [-97.2507, 44.3116],
              [-97.2593, 44.304],
              [-97.2594, 44.2971],
              [-97.2708, 44.2934],
              [-97.2708, 44.2894],
              [-97.4516, 44.2385],
              [-97.4611, 44.4122],
            ],
          ],
          [
            [
              [-97.5339, 44.544],
              [-97.4711, 44.5439],
              [-97.451, 44.4992],
              [-97.451, 44.4916],
              [-97.4813, 44.4773],
              [-97.5339, 44.544],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4639990',
        NAME: 'Lake Preston School District 38-3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 488537453,
        AWATER: 24605558,
        INTPTLAT: '+44.4047669',
        INTPTLON: '-097.3603882',
        ELSDLEA: '',
        UNSDLEA: '39990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.3098, 45.584],
              [-97.3098, 45.5807],
              [-97.3155, 45.5808],
              [-97.3098, 45.584],
            ],
          ],
          [
            [
              [-97.3246, 45.4569],
              [-97.2889, 45.4495],
              [-97.3086, 45.4417],
              [-97.3246, 45.4569],
            ],
          ],
          [
            [
              [-97.3625, 45.6817],
              [-97.33, 45.5881],
              [-97.4941, 45.5582],
              [-97.5052, 45.5904],
              [-97.4629, 45.689],
              [-97.3625, 45.6817],
            ],
          ],
          [
            [
              [-98.0827, 45.6756],
              [-98.1036, 45.7627],
              [-98.0206, 45.7696],
              [-97.948, 45.69],
              [-97.8342, 45.7188],
              [-97.7309, 45.675],
              [-97.5573, 45.6747],
              [-97.5052, 45.5878],
              [-97.4941, 45.5582],
              [-97.6107, 45.5292],
              [-97.7347, 45.457],
              [-97.8377, 45.4571],
              [-97.8575, 45.4572],
              [-97.9797, 45.5453],
              [-98.0108, 45.632],
              [-98.0827, 45.6756],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4640860',
        NAME: 'Langford School District 45-5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1052065624,
        AWATER: 48129187,
        INTPTLAT: '+45.6002556',
        INTPTLON: '-097.7397211',
        ELSDLEA: '',
        UNSDLEA: '40860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0555, 44.316],
            [-103.9304, 44.3444],
            [-103.8499, 44.4309],
            [-103.6476, 44.4157],
            [-103.5743, 44.3442],
            [-103.5744, 44.2561],
            [-103.453, 44.2566],
            [-103.4526, 44.1695],
            [-103.4525, 44.1408],
            [-104.0546, 44.1411],
            [-104.0545, 44.1804],
            [-104.0555, 44.316],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4641300',
        NAME: 'Lead-Deadwood School District 40-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1117639582,
        AWATER: 60271,
        INTPTLAT: '+44.2657118',
        INTPTLON: '-103.7624994',
        ELSDLEA: '',
        UNSDLEA: '41300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.9421, 45.945],
            [-101.9986, 45.9447],
            [-101.7212, 45.9443],
            [-101.8244, 45.8908],
            [-101.8245, 45.8187],
            [-101.7003, 45.8188],
            [-101.717, 45.5313],
            [-101.7489, 45.4688],
            [-101.7582, 45.3859],
            [-101.8548, 45.3856],
            [-101.855, 45.299],
            [-101.9994, 45.2992],
            [-102.2827, 45.2997],
            [-102.227, 45.3288],
            [-102.262, 45.4292],
            [-102.2928, 45.5305],
            [-102.2104, 45.5305],
            [-102.1694, 45.5519],
            [-102.2101, 45.732],
            [-102.3683, 45.7324],
            [-102.4457, 45.8197],
            [-102.5698, 45.8196],
            [-102.5545, 45.7357],
            [-102.4566, 45.7321],
            [-102.457, 45.6461],
            [-102.9502, 45.6472],
            [-102.9421, 45.945],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4641520',
        NAME: 'Lemmon School District 52-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4239841383,
        AWATER: 24901303,
        INTPTLAT: '+45.7752570',
        INTPTLON: '-102.0058757',
        ELSDLEA: '',
        UNSDLEA: '41520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.787, 43.4027],
              [-96.7871, 43.4104],
              [-96.7872, 43.4131],
              [-96.787, 43.4027],
            ],
          ],
          [
            [
              [-96.8064, 43.4174],
              [-96.7966, 43.4254],
              [-96.7968, 43.4173],
              [-96.8064, 43.4174],
            ],
          ],
          [
            [
              [-96.8307, 43.5399],
              [-96.8371, 43.5329],
              [-96.8394, 43.5329],
              [-96.8407, 43.5329],
              [-96.8307, 43.5399],
            ],
          ],
          [
            [
              [-96.841, 43.5323],
              [-96.8404, 43.5257],
              [-96.8903, 43.5231],
              [-96.841, 43.5323],
            ],
          ],
          [
            [
              [-96.9702, 43.4998],
              [-96.9304, 43.5],
              [-96.9103, 43.5],
              [-96.8903, 43.5222],
              [-96.8705, 43.5041],
              [-96.8761, 43.4902],
              [-96.8064, 43.4174],
              [-96.7967, 43.411],
              [-96.7965, 43.4028],
              [-96.7869, 43.3955],
              [-96.7567, 43.3882],
              [-96.7472, 43.368],
              [-96.7494, 43.3665],
              [-96.7573, 43.3377],
              [-96.7274, 43.3377],
              [-96.7274, 43.332],
              [-96.7274, 43.3301],
              [-96.7173, 43.3301],
              [-96.7572, 43.3158],
              [-96.767, 43.3158],
              [-96.767, 43.3013],
              [-96.7869, 43.2647],
              [-96.7963, 43.2354],
              [-96.8061, 43.2288],
              [-96.8357, 43.2357],
              [-96.8458, 43.2289],
              [-96.836, 43.2289],
              [-96.8562, 43.2073],
              [-96.9543, 43.2499],
              [-96.9642, 43.25],
              [-96.9838, 43.2872],
              [-96.9536, 43.2873],
              [-97.0333, 43.3442],
              [-96.9702, 43.4998],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4641550',
        NAME: 'Lennox School District 41-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 496931641,
        AWATER: 89604,
        INTPTLAT: '+43.3580236',
        INTPTLON: '-096.8925120',
        ELSDLEA: '',
        UNSDLEA: '41550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2223, 45.94],
            [-99.0058, 45.9397],
            [-98.7244, 45.9387],
            [-98.7249, 45.6782],
            [-98.725, 45.5913],
            [-99.1536, 45.5926],
            [-99.2729, 45.7232],
            [-99.2835, 45.8106],
            [-99.2223, 45.94],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4641640',
        NAME: 'Leola School District 44-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1526262960,
        AWATER: 20820980,
        INTPTLAT: '+45.8030631',
        INTPTLON: '-099.0168732',
        ELSDLEA: '',
        UNSDLEA: '41640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.9382, 44.1952],
            [-99.8845, 44.1296],
            [-99.7687, 44.1357],
            [-99.6545, 44.1017],
            [-99.5766, 44.1924],
            [-99.5113, 44.055],
            [-99.6202, 43.9921],
            [-99.5256, 43.9262],
            [-99.5258, 43.8468],
            [-99.7435, 43.8469],
            [-99.7433, 43.7484],
            [-99.8378, 43.6891],
            [-100.1997, 43.6982],
            [-100.339, 43.7169],
            [-100.3662, 43.847],
            [-100.3672, 44.1687],
            [-100.3672, 44.1946],
            [-99.9382, 44.1952],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4644770',
        NAME: 'Lyman School District 42-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3048936384,
        AWATER: 103803484,
        INTPTLAT: '+43.9559181',
        INTPTLON: '-099.9936853',
        ELSDLEA: '',
        UNSDLEA: '44770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.0694, 43.7907],
            [-96.9998, 43.834],
            [-96.9105, 43.8264],
            [-96.8313, 43.7836],
            [-96.7714, 43.6598],
            [-96.811, 43.5874],
            [-96.8708, 43.5872],
            [-96.9506, 43.7178],
            [-97.0698, 43.7324],
            [-97.0694, 43.7907],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4644940',
        NAME: 'Tri-Valley School District 49-6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 380061900,
        AWATER: 2081746,
        INTPTLAT: '+43.7249735',
        INTPTLON: '-096.9006036',
        ELSDLEA: '',
        UNSDLEA: '44940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.1695, 43.5327],
              [-97.1595, 43.5216],
              [-97.1696, 43.5216],
              [-97.1695, 43.5327],
            ],
          ],
          [
            [
              [-97.2993, 43.2708],
              [-97.2796, 43.2708],
              [-97.2941, 43.2563],
              [-97.2993, 43.2708],
            ],
          ],
          [
            [
              [-97.3926, 43.4998],
              [-97.3682, 43.4998],
              [-97.3085, 43.5073],
              [-97.2585, 43.5143],
              [-97.1793, 43.5435],
              [-97.1794, 43.5216],
              [-97.1895, 43.5288],
              [-97.1996, 43.5288],
              [-97.1715, 43.4448],
              [-97.2014, 43.4522],
              [-97.1815, 43.4232],
              [-97.2301, 43.3136],
              [-97.2402, 43.3136],
              [-97.3597, 43.4153],
              [-97.3926, 43.4998],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4645450',
        NAME: 'Marion School District 60-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 285684544,
        AWATER: 25628,
        INTPTLAT: '+43.4382929',
        INTPTLON: '-097.2704535',
        ELSDLEA: '',
        UNSDLEA: '45450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.7212, 45.9443],
            [-101.0798, 45.9436],
            [-101.099, 45.731],
            [-101.1001, 45.4726],
            [-101.3469, 45.4726],
            [-101.3467, 45.5592],
            [-101.6549, 45.5591],
            [-101.717, 45.5313],
            [-101.7003, 45.8188],
            [-101.8245, 45.8187],
            [-101.8244, 45.8908],
            [-101.7212, 45.9443],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4646260',
        NAME: 'McIntosh School District 15-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2372771028,
        AWATER: 8615070,
        INTPTLAT: '+45.7558757',
        INTPTLON: '-101.4270719',
        ELSDLEA: '',
        UNSDLEA: '46260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0798, 45.9436],
            [-100.7938, 45.9438],
            [-100.5119, 45.9437],
            [-100.5035, 45.9437],
            [-100.4994, 45.9437],
            [-100.387, 45.8691],
            [-100.3231, 45.7309],
            [-100.7305, 45.7317],
            [-100.7305, 45.5598],
            [-100.7305, 45.4726],
            [-101.1001, 45.4726],
            [-101.099, 45.731],
            [-101.0798, 45.9436],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4646380',
        NAME: 'McLaughlin School District 15-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2003014369,
        AWATER: 55359876,
        INTPTLAT: '+45.7571813',
        INTPTLON: '-100.7898834',
        ELSDLEA: '',
        UNSDLEA: '46380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.708, 43.3063],
            [-97.6983, 43.3287],
            [-97.7081, 43.3501],
            [-97.6886, 43.3576],
            [-97.5397, 43.3204],
            [-97.4894, 43.2993],
            [-97.4791, 43.2769],
            [-97.4192, 43.2344],
            [-97.3993, 43.1983],
            [-97.3777, 43.1695],
            [-97.3777, 43.1585],
            [-97.4391, 43.0867],
            [-97.4778, 43.0965],
            [-97.5185, 43.1322],
            [-97.6572, 43.169],
            [-97.7235, 43.2561],
            [-97.708, 43.3063],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4647100',
        NAME: 'Menno School District 33-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 464655289,
        AWATER: 437869,
        INTPTLAT: '+43.2193677',
        INTPTLON: '-097.5484436',
        ELSDLEA: '',
        UNSDLEA: '47100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.3118, 44.8225],
            [-99.1909, 44.8091],
            [-99.0888, 44.8154],
            [-98.9879, 44.8668],
            [-98.9073, 44.809],
            [-98.8256, 44.8092],
            [-98.8072, 44.7219],
            [-98.7057, 44.612],
            [-98.7013, 44.4721],
            [-98.7822, 44.3777],
            [-98.7013, 44.3563],
            [-98.7005, 44.1967],
            [-99.1668, 44.1878],
            [-99.3002, 44.1948],
            [-99.3118, 44.8225],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4647942',
        NAME: 'Miller Area School District 29-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3099623350,
        AWATER: 8605912,
        INTPTLAT: '+44.4945848',
        INTPTLON: '-099.0126736',
        ELSDLEA: '',
        UNSDLEA: '47942',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.1921, 43.8511],
              [-98.1776, 43.8419],
              [-98.1777, 43.8341],
              [-98.1921, 43.8511],
            ],
          ],
          [
            [
              [-98.1563, 43.6212],
              [-98.1754, 43.7022],
              [-98.1967, 43.8047],
              [-98.1777, 43.8341],
              [-98.1377, 43.8417],
              [-98.1269, 43.8182],
              [-98.0974, 43.8511],
              [-97.8511, 43.8499],
              [-97.8896, 43.7609],
              [-97.8664, 43.5576],
              [-97.8869, 43.6155],
              [-97.9969, 43.6074],
              [-98.0461, 43.5789],
              [-98.2056, 43.6001],
              [-98.1563, 43.6212],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4648390',
        NAME: 'Mitchell School District 17-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 685360624,
        AWATER: 3839927,
        INTPTLAT: '+43.7231956',
        INTPTLON: '-098.0198748',
        ELSDLEA: '',
        UNSDLEA: '48390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.2585, 43.8484],
              [-97.2586, 43.8405],
              [-97.2792, 43.8484],
              [-97.2585, 43.8484],
            ],
          ],
          [
            [
              [-97.3097, 43.7748],
              [-97.2586, 43.8405],
              [-97.2489, 43.8483],
              [-97.1692, 43.8327],
              [-97.129, 43.812],
              [-97.0694, 43.7907],
              [-97.0698, 43.7324],
              [-97.168, 43.6303],
              [-97.268, 43.6591],
              [-97.2779, 43.6591],
              [-97.2879, 43.6592],
              [-97.3097, 43.7748],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4648780',
        NAME: 'Montrose School District 43-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 320559818,
        AWATER: 1298375,
        INTPTLAT: '+43.7370580',
        INTPTLON: '-097.1886279',
        ELSDLEA: '',
        UNSDLEA: '48780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.1776, 43.8419],
              [-98.1675, 43.8419],
              [-98.1469, 43.8417],
              [-98.1377, 43.8417],
              [-98.1777, 43.8341],
              [-98.1776, 43.8419],
            ],
          ],
          [
            [
              [-98.3258, 43.6735],
              [-98.3252, 43.8511],
              [-98.2419, 43.8586],
              [-98.1921, 43.8511],
              [-98.1777, 43.8341],
              [-98.1967, 43.8047],
              [-98.1754, 43.7022],
              [-98.1563, 43.6212],
              [-98.2056, 43.6001],
              [-98.2024, 43.5053],
              [-98.2333, 43.498],
              [-98.2712, 43.4987],
              [-98.3145, 43.5865],
              [-98.3359, 43.659],
              [-98.3258, 43.6735],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4649650',
        NAME: 'Mount Vernon School District 17-3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 434507440,
        AWATER: 122332,
        INTPTLAT: '+43.6914235',
        INTPTLON: '-098.2454056',
        ELSDLEA: '',
        UNSDLEA: '49650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.9698, 44.082],
            [-102.9298, 44.1406],
            [-102.5478, 44.1402],
            [-102.5078, 44.0814],
            [-102.5584, 44.0236],
            [-102.4735, 43.9797],
            [-102.6876, 43.8556],
            [-102.8989, 43.8563],
            [-102.8991, 43.9768],
            [-102.9698, 44.082],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4650670',
        NAME: 'New Underwood School District 51-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 919919090,
        AWATER: 456854,
        INTPTLAT: '+44.0101002',
        INTPTLON: '-102.7236280',
        ELSDLEA: '',
        UNSDLEA: '50670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.5081, 45.2128],
            [-102.9573, 45.2129],
            [-102.9574, 45.0396],
            [-102.9641, 44.6043],
            [-103.5674, 44.6041],
            [-103.7122, 44.7661],
            [-103.6908, 44.9493],
            [-103.5077, 44.9523],
            [-103.5081, 45.2128],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4650850',
        NAME: 'Newell School District 09-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3414864620,
        AWATER: 12324057,
        INTPTLAT: '+44.8946488',
        INTPTLON: '-103.3346902',
        ELSDLEA: '',
        UNSDLEA: '50850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.021, 45.2306],
            [-98.8973, 45.2448],
            [-98.8763, 45.2448],
            [-98.7224, 45.2727],
            [-98.5986, 45.2719],
            [-98.5478, 45.2573],
            [-98.5267, 45.2503],
            [-98.2277, 45.2411],
            [-98.2277, 45.2773],
            [-98.1346, 45.2201],
            [-98.1039, 45.2123],
            [-98.0227, 45.1322],
            [-98.074, 45.1181],
            [-98.1252, 45.0963],
            [-98.125, 45.0883],
            [-98.1662, 45.0823],
            [-98.2889, 44.9962],
            [-98.4818, 44.9694],
            [-98.6966, 44.984],
            [-98.7779, 44.9837],
            [-98.7987, 45.0563],
            [-99.0218, 45.158],
            [-99.021, 45.2306],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4651750',
        NAME: 'Northwestern Area School District 56-7',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1720066725,
        AWATER: 14227663,
        INTPTLAT: '+45.1366290',
        INTPTLON: '-098.5288451',
        ELSDLEA: '',
        UNSDLEA: '51750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.5433, 43.1297],
            [-103.4637, 43.1296],
            [-103.466, 43.2456],
            [-103.3463, 43.3036],
            [-103.2179, 43.3035],
            [-103.1444, 43.3904],
            [-103.0009, 43.3906],
            [-103.0009, 43.0007],
            [-103.5051, 43.0008],
            [-103.5434, 43.0009],
            [-103.5433, 43.1297],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4652770',
        NAME: 'Oelrichs School District 23-3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1455764545,
        AWATER: 1558427,
        INTPTLAT: '+43.1660427',
        INTPTLON: '-103.2342805',
        ELSDLEA: '',
        UNSDLEA: '52770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.1643, 43.5435],
              [-97.1495, 43.5434],
              [-97.1495, 43.5362],
              [-97.1643, 43.5435],
            ],
          ],
          [
            [
              [-97.1793, 43.5435],
              [-97.1694, 43.5435],
              [-97.1695, 43.5327],
              [-97.1696, 43.5216],
              [-97.1794, 43.5216],
              [-97.1793, 43.5435],
            ],
          ],
          [
            [
              [-97.1815, 43.4232],
              [-97.2014, 43.4522],
              [-97.1715, 43.4448],
              [-97.1996, 43.5288],
              [-97.1895, 43.5288],
              [-97.1794, 43.5216],
              [-97.1696, 43.5216],
              [-97.1595, 43.5216],
              [-97.1494, 43.5216],
              [-97.1495, 43.5362],
              [-97.1393, 43.5361],
              [-97.0891, 43.5505],
              [-97.0789, 43.5433],
              [-96.9702, 43.4998],
              [-97.0333, 43.3442],
              [-97.2303, 43.3066],
              [-97.2402, 43.3136],
              [-97.2301, 43.3136],
              [-97.1815, 43.4232],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4654270',
        NAME: 'Parker School District 60-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357029374,
        AWATER: 204043,
        INTPTLAT: '+43.4384671',
        INTPTLON: '-097.0919736',
        ELSDLEA: '',
        UNSDLEA: '54270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.2333, 43.498],
            [-98.2024, 43.5053],
            [-98.1824, 43.5052],
            [-98.0246, 43.5203],
            [-98.0039, 43.5203],
            [-97.9946, 43.5202],
            [-97.8563, 43.4996],
            [-97.7592, 43.4998],
            [-97.7196, 43.4435],
            [-97.7194, 43.436],
            [-97.699, 43.4291],
            [-97.6987, 43.4004],
            [-97.6787, 43.4006],
            [-97.6886, 43.3576],
            [-97.7081, 43.3501],
            [-97.7622, 43.3284],
            [-97.7773, 43.3101],
            [-97.8171, 43.3141],
            [-98.1011, 43.2975],
            [-98.1696, 43.3581],
            [-98.1794, 43.3838],
            [-98.182, 43.456],
            [-98.2333, 43.498],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4654300',
        NAME: 'Parkston School District 33-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 795267495,
        AWATER: 1125516,
        INTPTLAT: '+43.4123654',
        INTPTLON: '-097.9553543',
        ELSDLEA: '',
        UNSDLEA: '54300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.5265, 44.5474],
            [-100.1495, 44.5483],
            [-100.1082, 44.4882],
            [-100.1487, 44.3676],
            [-99.9063, 44.3676],
            [-99.7854, 44.3675],
            [-99.7857, 44.2368],
            [-99.8942, 44.2371],
            [-100.0853, 44.3085],
            [-100.3654, 44.3555],
            [-100.3864, 44.44],
            [-100.5758, 44.4917],
            [-100.5265, 44.5474],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4655260',
        NAME: 'Pierre School District 32-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 823241019,
        AWATER: 98030692,
        INTPTLAT: '+44.4036750',
        INTPTLON: '-100.1831495',
        ELSDLEA: '',
        UNSDLEA: '55260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.6304, 43.8705],
            [-98.6305, 43.8779],
            [-98.5508, 43.9367],
            [-98.4318, 43.937],
            [-98.3315, 43.9377],
            [-98.3315, 43.9302],
            [-98.3252, 43.8511],
            [-98.3258, 43.6735],
            [-98.4433, 43.6726],
            [-98.5545, 43.636],
            [-98.645, 43.7595],
            [-98.6304, 43.8705],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4655710',
        NAME: 'Plankinton School District 01-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 716757108,
        AWATER: 1685116,
        INTPTLAT: '+43.7980116',
        INTPTLON: '-098.4761762',
        ELSDLEA: '',
        UNSDLEA: '55710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.4525, 44.1408],
            [-103.4526, 44.1695],
            [-103.2996, 44.1783],
            [-103.2115, 44.1409],
            [-103.1916, 44.1408],
            [-103.1513, 44.1408],
            [-103.1312, 44.0674],
            [-103.1208, 44.0371],
            [-102.9698, 44.082],
            [-102.8991, 43.9768],
            [-102.8989, 43.8563],
            [-103.0796, 43.8567],
            [-103.0795, 43.8976],
            [-103.2996, 43.9128],
            [-103.3384, 43.8832],
            [-103.4588, 43.8972],
            [-103.4525, 44.1408],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4659820',
        NAME: 'Rapid City School District 51-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1085660522,
        AWATER: 861339,
        INTPTLAT: '+43.9943044',
        INTPTLON: '-103.2059107',
        ELSDLEA: '',
        UNSDLEA: '59820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7779, 44.9837],
            [-98.6966, 44.984],
            [-98.4818, 44.9694],
            [-98.2889, 44.9962],
            [-98.2365, 44.8352],
            [-98.2711, 44.8063],
            [-98.5644, 44.8072],
            [-98.7055, 44.7791],
            [-98.8072, 44.7219],
            [-98.8256, 44.8092],
            [-98.9073, 44.809],
            [-98.9879, 44.8668],
            [-98.7776, 44.897],
            [-98.7779, 44.9837],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4660450',
        NAME: 'Redfield School District 56-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1060357542,
        AWATER: 4638375,
        INTPTLAT: '+44.8725406',
        INTPTLON: '-098.5947576',
        ELSDLEA: '',
        UNSDLEA: '60450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8547, 45.8125],
            [-96.8523, 45.9359],
            [-96.7638, 45.9358],
            [-96.5637, 45.9352],
            [-96.5831, 45.82],
            [-96.6574, 45.7426],
            [-96.6991, 45.722],
            [-96.7923, 45.7032],
            [-96.8547, 45.8125],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4663360',
        NAME: 'Rosholt School District 54-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 432050316,
        AWATER: 16930927,
        INTPTLAT: '+45.8417327',
        INTPTLON: '-096.7196123',
        ELSDLEA: '',
        UNSDLEA: '63360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.8999, 44.1957],
              [-96.8892, 44.1817],
              [-96.9001, 44.1818],
              [-96.8999, 44.1957],
            ],
          ],
          [
            [
              [-97.1099, 44.1958],
              [-97.0493, 44.1958],
              [-96.9284, 44.1957],
              [-96.9094, 44.1957],
              [-96.9289, 44.1745],
              [-96.9001, 44.1818],
              [-96.8892, 44.1817],
              [-96.8596, 44.1673],
              [-96.8495, 44.1528],
              [-96.849, 44.0658],
              [-96.9041, 44.0297],
              [-97.0092, 44.0221],
              [-97.0591, 44.1235],
              [-97.1295, 44.1089],
              [-97.1099, 44.1958],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4664140',
        NAME: 'Rutland School District 39-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 321257521,
        AWATER: 2807348,
        INTPTLAT: '+44.1177945',
        INTPTLON: '-096.9794810',
        ELSDLEA: '',
        UNSDLEA: '64140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.8262, 43.2635],
            [-97.8164, 43.2635],
            [-97.8171, 43.3141],
            [-97.7773, 43.3101],
            [-97.7622, 43.3284],
            [-97.7081, 43.3501],
            [-97.6983, 43.3287],
            [-97.708, 43.3063],
            [-97.7235, 43.2561],
            [-97.6572, 43.169],
            [-97.5185, 43.1322],
            [-97.4778, 43.0965],
            [-97.4822, 43.0278],
            [-97.5764, 43.0103],
            [-97.6154, 42.9433],
            [-97.62, 42.9376],
            [-97.6302, 42.9376],
            [-97.6947, 43.0104],
            [-97.8131, 43.1118],
            [-97.9205, 43.1257],
            [-97.8262, 43.2635],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4665180',
        NAME: 'Scotland School District 04-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 666925260,
        AWATER: 1121163,
        INTPTLAT: '+43.1317610',
        INTPTLON: '-097.6916753',
        ELSDLEA: '',
        UNSDLEA: '65180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0009, 43.3906],
            [-103.0008, 43.4768],
            [-103.0011, 43.6058],
            [-102.9025, 43.6749],
            [-102.8104, 43.6881],
            [-102.1752, 43.6878],
            [-102.1392, 43.7009],
            [-102.1391, 43.4767],
            [-102.1108, 43.3899],
            [-102.1089, 43.1293],
            [-102.0825, 42.9994],
            [-102.7128, 43],
            [-102.7428, 43],
            [-102.7589, 43],
            [-103.0009, 43.0007],
            [-103.0009, 43.3906],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4665460',
        NAME: 'Shannon County School District 65-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5422998821,
        AWATER: 7126221,
        INTPTLAT: '+43.3336299',
        INTPTLON: '-102.5616990',
        ELSDLEA: '',
        UNSDLEA: '65460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.8407, 43.5329],
            [-96.8394, 43.5329],
            [-96.8371, 43.5329],
            [-96.8307, 43.5399],
            [-96.811, 43.5874],
            [-96.7714, 43.6598],
            [-96.7113, 43.6742],
            [-96.6514, 43.5041],
            [-96.8063, 43.4922],
            [-96.8239, 43.4937],
            [-96.8239, 43.4955],
            [-96.8239, 43.4963],
            [-96.824, 43.4973],
            [-96.824, 43.4983],
            [-96.824, 43.4989],
            [-96.8404, 43.5257],
            [-96.841, 43.5323],
            [-96.8407, 43.5329],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4666270',
        NAME: 'Sioux Falls School District 49-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191434106,
        AWATER: 1510465,
        INTPTLAT: '+43.5544923',
        INTPTLON: '-096.7447999',
        ELSDLEA: '',
        UNSDLEA: '66270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.9094, 44.1957],
              [-96.8999, 44.1957],
              [-96.9001, 44.1818],
              [-96.9289, 44.1745],
              [-96.9094, 44.1957],
            ],
          ],
          [
            [
              [-97.0275, 44.3982],
              [-97.0376, 44.4415],
              [-97.0078, 44.4847],
              [-96.938, 44.4924],
              [-96.9074, 44.4707],
              [-96.8379, 44.4639],
              [-96.8387, 44.3482],
              [-96.8996, 44.2537],
              [-96.9284, 44.1957],
              [-97.0493, 44.1958],
              [-97.0592, 44.2464],
              [-97.0275, 44.3982],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4666300',
        NAME: 'Sioux Valley School District 05-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 472107227,
        AWATER: 7256027,
        INTPTLAT: '+44.3335741',
        INTPTLON: '-096.9390572',
        ELSDLEA: '',
        UNSDLEA: '66300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0559, 44.571],
            [-104.0118, 44.6043],
            [-103.6885, 44.5611],
            [-103.6291, 44.546],
            [-103.6476, 44.4157],
            [-103.8499, 44.4309],
            [-103.9304, 44.3444],
            [-104.0555, 44.316],
            [-104.0559, 44.571],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4666930',
        NAME: 'Spearfish School District 40-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 733056343,
        AWATER: 462318,
        INTPTLAT: '+44.4661143',
        INTPTLON: '-103.8923772',
        ELSDLEA: '',
        UNSDLEA: '66930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.5545, 43.636],
            [-98.4433, 43.6726],
            [-98.3258, 43.6735],
            [-98.3359, 43.659],
            [-98.3145, 43.5865],
            [-98.2712, 43.4987],
            [-98.6482, 43.4991],
            [-98.6763, 43.4991],
            [-98.686, 43.5426],
            [-98.6744, 43.6065],
            [-98.5545, 43.636],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4669540',
        NAME: 'Stickney School District 01-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 492073768,
        AWATER: 104262,
        INTPTLAT: '+43.5780433',
        INTPTLON: '-098.4698292',
        ELSDLEA: '',
        UNSDLEA: '69540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.6885, 44.5611],
            [-103.5674, 44.6041],
            [-102.9641, 44.6043],
            [-102.9574, 45.0396],
            [-102.7154, 45.039],
            [-102.715, 44.9507],
            [-102.0001, 44.9507],
            [-102.0004, 44.69],
            [-102.1423, 44.6899],
            [-102.241, 44.518],
            [-102.0004, 44.5173],
            [-102.0004, 44.5117],
            [-102.0011, 44.5109],
            [-102.1125, 44.4359],
            [-102.2844, 44.4412],
            [-102.3416, 44.3488],
            [-102.388, 44.1402],
            [-102.5478, 44.1402],
            [-102.9298, 44.1406],
            [-102.9699, 44.1406],
            [-102.9692, 44.3437],
            [-103.0906, 44.3432],
            [-103.0707, 44.1654],
            [-103.2115, 44.1409],
            [-103.2996, 44.1783],
            [-103.4526, 44.1695],
            [-103.453, 44.2566],
            [-103.5744, 44.2561],
            [-103.5743, 44.3442],
            [-103.6476, 44.4157],
            [-103.6291, 44.546],
            [-103.6885, 44.5611],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4669930',
        NAME: 'Meade School District 46-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 8045894449,
        AWATER: 27432301,
        INTPTLAT: '+44.5521014',
        INTPTLON: '-102.7605759',
        ELSDLEA: '',
        UNSDLEA: '69930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1859, 45.3411],
            [-97.155, 45.3845],
            [-97.1034, 45.4285],
            [-96.9797, 45.3835],
            [-96.9331, 45.3264],
            [-96.945, 45.2398],
            [-97.0616, 45.1853],
            [-97.0924, 45.1807],
            [-97.1742, 45.1735],
            [-97.1856, 45.1883],
            [-97.1859, 45.3411],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4670140',
        NAME: 'Summit School District 54-6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 400340582,
        AWATER: 4848245,
        INTPTLAT: '+45.2897586',
        INTPTLON: '-097.0721141',
        ELSDLEA: '',
        UNSDLEA: '70140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.7489, 45.4688],
            [-101.717, 45.5313],
            [-101.6549, 45.5591],
            [-101.3467, 45.5592],
            [-101.3469, 45.4726],
            [-101.1001, 45.4726],
            [-100.7305, 45.4726],
            [-100.7305, 45.5598],
            [-100.4754, 45.5594],
            [-100.3766, 45.5076],
            [-100.2742, 45.3818],
            [-100.3167, 45.3179],
            [-100.2602, 45.2468],
            [-100.2677, 45.2126],
            [-101.4869, 45.2103],
            [-101.5068, 45.2769],
            [-101.3638, 45.2695],
            [-101.47, 45.4074],
            [-101.6549, 45.3995],
            [-101.7489, 45.4688],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4671880',
        NAME: 'Timber Lake School District 20-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3129147897,
        AWATER: 124963002,
        INTPTLAT: '+45.3784087',
        INTPTLON: '-100.9341249',
        ELSDLEA: '',
        UNSDLEA: '71880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.2282, 43.3892],
            [-100.5729, 43.3901],
            [-100.2142, 43.3903],
            [-100.2138, 43.1284],
            [-100.1984, 42.9987],
            [-100.8474, 42.9983],
            [-101.2281, 42.9981],
            [-101.2282, 43.3892],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4672090',
        NAME: 'Todd County School District 66-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3596101701,
        AWATER: 5854544,
        INTPTLAT: '+43.2087936',
        INTPTLON: '-100.7076636',
        ELSDLEA: '',
        UNSDLEA: '72090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.1696, 43.3581],
            [-98.1011, 43.2975],
            [-97.8171, 43.3141],
            [-97.8164, 43.2635],
            [-97.8262, 43.2635],
            [-97.9205, 43.1257],
            [-97.97, 43.0749],
            [-98.0915, 43.1397],
            [-98.148, 43.1401],
            [-98.2278, 43.231],
            [-98.2381, 43.2365],
            [-98.253, 43.2409],
            [-98.2664, 43.2901],
            [-98.1696, 43.3581],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4672450',
        NAME: 'Tripp-Delmont School District 33-5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 649979234,
        AWATER: 239474,
        INTPTLAT: '+43.2326353',
        INTPTLON: '-098.0480328',
        ELSDLEA: '',
        UNSDLEA: '72450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.1012, 42.7937],
              [-97.0816, 42.83],
              [-96.9239, 42.9351],
              [-96.8746, 42.9174],
              [-96.8697, 42.9174],
              [-96.8353, 42.9245],
              [-96.8152, 42.917],
              [-96.8254, 42.91],
              [-96.7664, 42.8741],
              [-96.7664, 42.8591],
              [-96.7568, 42.8027],
              [-96.8062, 42.7042],
              [-96.9622, 42.7347],
              [-96.9611, 42.7374],
              [-97.0158, 42.7613],
              [-97.1011, 42.7697],
              [-97.1012, 42.7937],
            ],
          ],
          [
            [
              [-97.116, 42.8225],
              [-97.1012, 42.7937],
              [-97.1371, 42.7792],
              [-97.1604, 42.7997],
              [-97.116, 42.8225],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4674370',
        NAME: 'Vermillion School District 13-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 451432343,
        AWATER: 12411467,
        INTPTLAT: '+42.8205955',
        INTPTLON: '-096.9183715',
        ELSDLEA: '',
        UNSDLEA: '74370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3993, 43.1983],
            [-97.2993, 43.2708],
            [-97.2941, 43.2563],
            [-97.2796, 43.2708],
            [-97.2599, 43.2781],
            [-97.2599, 43.2851],
            [-97.2215, 43.2924],
            [-97.2303, 43.3066],
            [-97.0333, 43.3442],
            [-96.9536, 43.2873],
            [-96.9838, 43.2872],
            [-96.9642, 43.25],
            [-97.0322, 43.1631],
            [-97.0617, 43.0761],
            [-97.1508, 43.1371],
            [-97.2984, 43.155],
            [-97.3777, 43.1585],
            [-97.3777, 43.1695],
            [-97.3993, 43.1983],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4674520',
        NAME: 'Viborg Hurley 60-6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 533564456,
        AWATER: 1502230,
        INTPTLAT: '+43.2247224',
        INTPTLON: '-097.1511149',
        ELSDLEA: '',
        UNSDLEA: '74520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.3574, 43.2346],
              [-98.3475, 43.2275],
              [-98.3574, 43.2273],
              [-98.3574, 43.2346],
            ],
          ],
          [
            [
              [-98.4517, 43.093],
              [-98.4022, 43.1582],
              [-98.3823, 43.1765],
              [-98.3475, 43.2275],
              [-98.2826, 43.2306],
              [-98.253, 43.2409],
              [-98.2381, 43.2365],
              [-98.2278, 43.231],
              [-98.148, 43.1401],
              [-98.0915, 43.1397],
              [-98.0926, 43.1254],
              [-98.1033, 43.1116],
              [-98.1685, 42.9888],
              [-98.1684, 42.9817],
              [-98.1608, 42.9088],
              [-98.1531, 42.839],
              [-98.1825, 42.84],
              [-98.1867, 42.8409],
              [-98.2177, 42.8523],
              [-98.4687, 42.9494],
              [-98.4985, 42.9986],
              [-98.4517, 43.093],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4675420',
        NAME: 'Wagner School District 11-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 845020900,
        AWATER: 9480567,
        INTPTLAT: '+43.0446528',
        INTPTLON: '-098.2984059',
        ELSDLEA: '',
        UNSDLEA: '75420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.3231, 45.7309],
            [-100.3132, 45.6812],
            [-100.3373, 45.6632],
            [-100.4064, 45.65],
            [-100.4754, 45.5594],
            [-100.7305, 45.5598],
            [-100.7305, 45.7317],
            [-100.3231, 45.7309],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4675600',
        NAME: 'Smee School District 15-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 449201276,
        AWATER: 70012020,
        INTPTLAT: '+45.6495988',
        INTPTLON: '-100.5546399',
        ELSDLEA: '',
        UNSDLEA: '75600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.6876, 43.8556],
            [-102.4735, 43.9797],
            [-102.5584, 44.0236],
            [-102.5078, 44.0814],
            [-102.5478, 44.1402],
            [-102.388, 44.1402],
            [-102.3416, 44.3488],
            [-102.2844, 44.4412],
            [-102.1125, 44.4359],
            [-102.0011, 44.5109],
            [-102.0073, 43.9945],
            [-102.0195, 43.9078],
            [-102.0189, 43.7086],
            [-102.1392, 43.7009],
            [-102.1752, 43.6878],
            [-102.8104, 43.6881],
            [-102.7077, 43.7895],
            [-102.6876, 43.8556],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4675660',
        NAME: 'Wall School District 51-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3403924732,
        AWATER: 11961056,
        INTPTLAT: '+44.0097727',
        INTPTLON: '-102.2984169',
        ELSDLEA: '',
        UNSDLEA: '75660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-98.7222, 45.3886],
            [-98.5985, 45.4166],
            [-98.5265, 45.3801],
            [-98.5058, 45.3725],
            [-98.4852, 45.3795],
            [-98.4644, 45.3652],
            [-98.2989, 45.3427],
            [-98.2888, 45.3282],
            [-98.2277, 45.2773],
            [-98.2277, 45.2411],
            [-98.5267, 45.2503],
            [-98.5478, 45.2573],
            [-98.5986, 45.2719],
            [-98.7224, 45.2727],
            [-98.7627, 45.3166],
            [-98.7222, 45.3886],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4676020',
        NAME: 'Warner School District 06-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 486790368,
        AWATER: 748646,
        INTPTLAT: '+45.3179338',
        INTPTLON: '-098.5090900',
        ELSDLEA: '',
        UNSDLEA: '76020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.8938, 44.8191],
              [-96.8752, 44.819],
              [-96.8846, 44.8044],
              [-96.8938, 44.8191],
            ],
          ],
          [
            [
              [-96.8844, 44.8335],
              [-96.8845, 44.8262],
              [-96.8938, 44.8191],
              [-96.9045, 44.8191],
              [-96.8844, 44.8335],
            ],
          ],
          [
            [
              [-96.9045, 44.8191],
              [-96.9252, 44.8044],
              [-96.9341, 44.8044],
              [-96.9045, 44.8191],
            ],
          ],
          [
            [
              [-96.8821, 44.9624],
              [-96.8622, 44.9117],
              [-96.8825, 44.9045],
              [-96.8843, 44.877],
              [-96.8821, 44.9624],
            ],
          ],
          [
            [
              [-97.361, 44.9344],
              [-97.2393, 45.0134],
              [-97.1543, 45.1005],
              [-97.1442, 45.1082],
              [-97.1543, 45.1082],
              [-97.1542, 45.1151],
              [-97.1553, 45.1371],
              [-97.1742, 45.1517],
              [-97.1742, 45.1735],
              [-97.0924, 45.1807],
              [-97.0349, 45.0931],
              [-97.0452, 44.9543],
              [-96.9751, 44.8627],
              [-97.0159, 44.8042],
              [-97.127, 44.8033],
              [-97.189, 44.7581],
              [-97.2486, 44.7607],
              [-97.3498, 44.8474],
              [-97.3703, 44.8475],
              [-97.361, 44.9344],
            ],
          ],
          [
            [
              [-97.3703, 44.8475],
              [-97.3702, 44.8401],
              [-97.3803, 44.8401],
              [-97.3703, 44.8475],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4676620',
        NAME: 'Watertown School District 14-4',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 799021187,
        AWATER: 45276269,
        INTPTLAT: '+44.9377739',
        INTPTLON: '-097.1556141',
        ELSDLEA: '',
        UNSDLEA: '76620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.3086, 45.4417],
            [-97.2889, 45.4495],
            [-97.2267, 45.4714],
            [-97.1247, 45.4461],
            [-97.155, 45.3845],
            [-97.1859, 45.3411],
            [-97.1856, 45.1883],
            [-97.1742, 45.1735],
            [-97.1742, 45.1517],
            [-97.1953, 45.1517],
            [-97.2055, 45.1518],
            [-97.2263, 45.1518],
            [-97.2402, 45.1512],
            [-97.3851, 45.1686],
            [-97.366, 45.3988],
            [-97.3086, 45.4417],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4676680',
        NAME: 'Waubay School District 18-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 468943128,
        AWATER: 46730785,
        INTPTLAT: '+45.3050862',
        INTPTLON: '-097.2666501',
        ELSDLEA: '',
        UNSDLEA: '76680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.8845, 44.8262],
              [-96.8752, 44.819],
              [-96.8938, 44.8191],
              [-96.8845, 44.8262],
            ],
          ],
          [
            [
              [-96.9045, 44.8191],
              [-96.8938, 44.8191],
              [-96.8846, 44.8044],
              [-96.9252, 44.8044],
              [-96.9045, 44.8191],
            ],
          ],
          [
            [
              [-97.0924, 45.1807],
              [-97.0616, 45.1853],
              [-96.945, 45.2398],
              [-96.8839, 45.2396],
              [-96.8235, 45.208],
              [-96.8227, 45.1212],
              [-96.7522, 45.1063],
              [-96.8828, 45.0492],
              [-96.8823, 44.9769],
              [-96.8821, 44.9624],
              [-96.8843, 44.877],
              [-96.8844, 44.8335],
              [-96.9045, 44.8191],
              [-96.9341, 44.8044],
              [-97.0159, 44.8042],
              [-96.9751, 44.8627],
              [-97.0452, 44.9543],
              [-97.0349, 45.0931],
              [-97.0924, 45.1807],
            ],
          ],
          [
            [
              [-97.1543, 45.1082],
              [-97.1442, 45.1082],
              [-97.1543, 45.1005],
              [-97.1543, 45.1082],
            ],
          ],
          [
            [
              [-97.1953, 45.1517],
              [-97.1742, 45.1517],
              [-97.1553, 45.1371],
              [-97.1542, 45.1151],
              [-97.1953, 45.1517],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4676740',
        NAME: 'Waverly School District 14-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 644201877,
        AWATER: 4273599,
        INTPTLAT: '+45.0588837',
        INTPTLON: '-096.9534366',
        ELSDLEA: '',
        UNSDLEA: '76740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.5052, 45.5904],
              [-97.4941, 45.5582],
              [-97.5052, 45.5878],
              [-97.5052, 45.5904],
            ],
          ],
          [
            [
              [-97.4941, 45.5582],
              [-97.33, 45.5881],
              [-97.3098, 45.584],
              [-97.3155, 45.5808],
              [-97.3098, 45.5807],
              [-97.3098, 45.58],
              [-97.2591, 45.5582],
              [-97.227, 45.5255],
              [-97.2269, 45.5147],
              [-97.2269, 45.4929],
              [-97.2269, 45.4858],
              [-97.2267, 45.4714],
              [-97.2889, 45.4495],
              [-97.3246, 45.4569],
              [-97.3086, 45.4417],
              [-97.366, 45.3988],
              [-97.3851, 45.1686],
              [-97.4123, 45.1229],
              [-97.4942, 45.0428],
              [-97.4941, 45.0214],
              [-97.5145, 45.068],
              [-97.5448, 45.1448],
              [-97.6782, 45.1521],
              [-97.6684, 45.1736],
              [-97.664, 45.2612],
              [-97.6737, 45.4423],
              [-97.7147, 45.4498],
              [-97.7347, 45.457],
              [-97.6107, 45.5292],
              [-97.4941, 45.5582],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4676990',
        NAME: 'Webster School District 18-5',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1181492937,
        AWATER: 94145403,
        INTPTLAT: '+45.3470192',
        INTPTLON: '-097.4954696',
        ELSDLEA: '',
        UNSDLEA: '76990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.1668, 44.1878],
            [-98.7005, 44.1967],
            [-98.5993, 44.2476],
            [-98.5284, 44.2257],
            [-98.4577, 44.1967],
            [-98.271, 44.1779],
            [-98.3321, 44.1701],
            [-98.3629, 44.0028],
            [-98.4318, 43.937],
            [-98.5508, 43.9367],
            [-98.6305, 43.8779],
            [-98.6881, 43.9358],
            [-98.7879, 43.935],
            [-98.8274, 43.9352],
            [-98.8371, 43.9351],
            [-99.0485, 43.9346],
            [-99.0459, 44.0208],
            [-99.1675, 44.02],
            [-99.1668, 44.1878],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4677460',
        NAME: 'Wessington Springs School District 36-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1915415786,
        AWATER: 17462382,
        INTPTLAT: '+44.0651914',
        INTPTLON: '-098.7688515',
        ELSDLEA: '',
        UNSDLEA: '77460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-96.7234, 44.5653],
              [-96.7234, 44.5584],
              [-96.7315, 44.5584],
              [-96.7234, 44.5653],
            ],
          ],
          [
            [
              [-96.7536, 44.6232],
              [-96.7535, 44.6306],
              [-96.6926, 44.5941],
              [-96.6927, 44.6085],
              [-96.4515, 44.6668],
              [-96.4516, 44.6313],
              [-96.452, 44.4128],
              [-96.6465, 44.3691],
              [-96.7271, 44.4055],
              [-96.767, 44.4851],
              [-96.7665, 44.514],
              [-96.7315, 44.5584],
              [-96.7234, 44.5584],
              [-96.7234, 44.5653],
              [-96.7536, 44.6232],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4678300',
        NAME: 'Deubrook School District 05-6',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 637993716,
        AWATER: 10367479,
        INTPTLAT: '+44.5213401',
        INTPTLON: '-096.5927750',
        ELSDLEA: '',
        UNSDLEA: '78300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.8275, 43.9281],
              [-98.7879, 43.935],
              [-98.6881, 43.9358],
              [-98.6305, 43.8779],
              [-98.6304, 43.8705],
              [-98.645, 43.7595],
              [-98.5545, 43.636],
              [-98.6744, 43.6065],
              [-98.686, 43.5426],
              [-98.6763, 43.4991],
              [-98.7946, 43.4992],
              [-98.7944, 43.5859],
              [-98.8075, 43.921],
              [-98.8275, 43.9281],
            ],
          ],
          [
            [
              [-98.8371, 43.9351],
              [-98.8274, 43.9352],
              [-98.8275, 43.9281],
              [-98.8371, 43.9351],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4678510',
        NAME: 'White Lake School District 01-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 627622538,
        AWATER: 9412001,
        INTPTLAT: '+43.7238261',
        INTPTLON: '-098.7156145',
        ELSDLEA: '',
        UNSDLEA: '78510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-101.0643, 43.8411],
            [-100.987, 43.8491],
            [-100.6515, 43.695],
            [-100.5498, 43.7377],
            [-100.4709, 43.6456],
            [-100.5697, 43.5946],
            [-100.6127, 43.4705],
            [-100.5729, 43.3901],
            [-101.2282, 43.3892],
            [-101.2283, 43.795],
            [-101.0643, 43.8411],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4678570',
        NAME: 'White River School District 47-1',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2361208752,
        AWATER: 6408113,
        INTPTLAT: '+43.6037798',
        INTPTLON: '-100.9254395',
        ELSDLEA: '',
        UNSDLEA: '78570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.9772, 44.7187],
            [-97.7351, 44.7181],
            [-97.5366, 44.8111],
            [-97.4913, 44.804],
            [-97.4922, 44.6306],
            [-97.5529, 44.5508],
            [-97.6217, 44.5279],
            [-97.7133, 44.5442],
            [-97.7238, 44.5443],
            [-97.8036, 44.5444],
            [-97.9778, 44.5592],
            [-98.0181, 44.6172],
            [-97.9772, 44.7187],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4679350',
        NAME: 'Willow Lake School District 12-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 778961982,
        AWATER: 3308172,
        INTPTLAT: '+44.6412461',
        INTPTLON: '-097.7248025',
        ELSDLEA: '',
        UNSDLEA: '79350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-100.2001, 43.6306],
            [-100.1997, 43.6982],
            [-99.8378, 43.6891],
            [-99.7433, 43.7484],
            [-99.6236, 43.7424],
            [-99.6234, 43.6299],
            [-99.5344, 43.6301],
            [-99.5339, 43.3426],
            [-99.633, 43.3424],
            [-99.8893, 43.2561],
            [-99.8898, 43.1692],
            [-99.8499, 42.998],
            [-100.1984, 42.9987],
            [-100.2138, 43.1284],
            [-100.2142, 43.3903],
            [-100.1171, 43.4299],
            [-100.1154, 43.4879],
            [-100.1154, 43.494],
            [-100.1509, 43.5723],
            [-100.2001, 43.6306],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4679710',
        NAME: 'Winner School District 59-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3060420094,
        AWATER: 10267478,
        INTPTLAT: '+43.3931499',
        INTPTLON: '-099.9289149',
        ELSDLEA: '',
        UNSDLEA: '79710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.3055, 44.4072],
              [-98.3055, 44.3997],
              [-98.3158, 44.3997],
              [-98.3055, 44.4072],
            ],
          ],
          [
            [
              [-98.3972, 44.327],
              [-98.3666, 44.3197],
              [-98.3971, 44.3126],
              [-98.3972, 44.327],
            ],
          ],
          [
            [
              [-98.7057, 44.612],
              [-98.575, 44.5902],
              [-98.524, 44.5755],
              [-98.4584, 44.5014],
              [-98.3566, 44.472],
              [-98.3162, 44.4576],
              [-98.3257, 44.4432],
              [-98.2852, 44.4142],
              [-98.2852, 44.3916],
              [-98.3055, 44.3997],
              [-98.2956, 44.3997],
              [-98.3055, 44.4072],
              [-98.3464, 44.3996],
              [-98.3973, 44.3486],
              [-98.4381, 44.356],
              [-98.4175, 44.3126],
              [-98.3971, 44.3126],
              [-98.4577, 44.1967],
              [-98.5284, 44.2257],
              [-98.5993, 44.2476],
              [-98.7005, 44.1967],
              [-98.7013, 44.3563],
              [-98.7822, 44.3777],
              [-98.7013, 44.4721],
              [-98.7057, 44.612],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4680100',
        NAME: 'Wolsey-Wessington School District 02-6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1041769004,
        AWATER: 456927,
        INTPTLAT: '+44.4080053',
        INTPTLON: '-098.5700645',
        ELSDLEA: '',
        UNSDLEA: '80100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-98.172, 43.9967],
              [-98.1617, 43.9967],
              [-98.1616, 43.9896],
              [-98.172, 43.9967],
            ],
          ],
          [
            [
              [-98.3629, 44.0028],
              [-98.3321, 44.1701],
              [-98.271, 44.1779],
              [-98.0942, 44.1959],
              [-98.0854, 44.1958],
              [-98.1106, 44.162],
              [-98.1106, 44.1554],
              [-98.0768, 44.0542],
              [-98.1306, 44.0251],
              [-98.1397, 44.0252],
              [-98.172, 43.9967],
              [-98.1616, 43.9896],
              [-98.1515, 43.9739],
              [-98.2057, 43.9674],
              [-98.2419, 43.8586],
              [-98.3252, 43.8511],
              [-98.3315, 43.9302],
              [-98.3315, 43.9377],
              [-98.4318, 43.937],
              [-98.3629, 44.0028],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4680190',
        NAME: 'Woonsocket School District 55-4',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 568334051,
        AWATER: 1782325,
        INTPTLAT: '+44.0479035',
        INTPTLON: '-098.2383278',
        ELSDLEA: '',
        UNSDLEA: '80190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.6302, 42.9376],
            [-97.62, 42.9376],
            [-97.6154, 42.9433],
            [-97.5764, 43.0103],
            [-97.4822, 43.0278],
            [-97.4778, 43.0965],
            [-97.4391, 43.0867],
            [-97.269, 42.9816],
            [-97.2496, 42.8555],
            [-97.2579, 42.8537],
            [-97.2665, 42.8527],
            [-97.2768, 42.8539],
            [-97.2786, 42.8542],
            [-97.283, 42.8544],
            [-97.2875, 42.8552],
            [-97.3068, 42.8676],
            [-97.327, 42.8609],
            [-97.6354, 42.8515],
            [-97.6302, 42.9376],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4680430',
        NAME: 'Yankton School District 63-3',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 535614278,
        AWATER: 24232760,
        INTPTLAT: '+42.9473002',
        INTPTLON: '-097.4467875',
        ELSDLEA: '',
        UNSDLEA: '80430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.1392, 43.7009],
            [-102.0189, 43.7086],
            [-102.0195, 43.9078],
            [-101.8996, 43.9517],
            [-101.7802, 43.9515],
            [-101.6013, 43.9076],
            [-101.4073, 43.9951],
            [-101.4061, 44.0812],
            [-101.2869, 44.0813],
            [-101.2865, 44.3435],
            [-101.1552, 44.4513],
            [-101.1668, 44.1681],
            [-100.9469, 44.1683],
            [-101.0067, 43.9949],
            [-101.0641, 43.9949],
            [-101.0643, 43.8411],
            [-101.2283, 43.795],
            [-101.2282, 43.3892],
            [-102.1108, 43.3899],
            [-102.1391, 43.4767],
            [-102.1392, 43.7009],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4680437',
        NAME: 'Kadoka School District 35-2',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5339192290,
        AWATER: 20003309,
        INTPTLAT: '+43.7353031',
        INTPTLON: '-101.5624193',
        ELSDLEA: '',
        UNSDLEA: '80437',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.2979, 43.4999],
            [-99.1512, 43.5207],
            [-99.0321, 43.4996],
            [-98.9329, 43.5283],
            [-98.9133, 43.5859],
            [-98.7944, 43.5859],
            [-98.7946, 43.4992],
            [-98.6763, 43.4991],
            [-98.6482, 43.4991],
            [-98.6665, 43.4053],
            [-98.6049, 43.3767],
            [-98.5761, 43.3416],
            [-98.5448, 43.3316],
            [-98.5453, 43.2787],
            [-98.6646, 43.1992],
            [-98.6826, 43.1074],
            [-98.6458, 43.0713],
            [-98.7734, 43.1459],
            [-98.8688, 43.162],
            [-98.8777, 43.2266],
            [-98.9951, 43.2702],
            [-99.1529, 43.4269],
            [-99.297, 43.4556],
            [-99.2979, 43.4999],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4680438',
        NAME: 'Platte-Geddes School District 11-5',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1541877225,
        AWATER: 95404056,
        INTPTLAT: '+43.3622714',
        INTPTLON: '-098.8544325',
        ELSDLEA: '',
        UNSDLEA: '80438',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-97.1371, 42.7792],
              [-97.1012, 42.7937],
              [-97.1011, 42.7697],
              [-97.1371, 42.7792],
            ],
          ],
          [
            [
              [-97.3777, 43.1585],
              [-97.2984, 43.155],
              [-97.1508, 43.1371],
              [-97.0617, 43.0761],
              [-96.9396, 42.9675],
              [-96.9239, 42.9351],
              [-97.0816, 42.83],
              [-97.1012, 42.7937],
              [-97.116, 42.8225],
              [-97.1217, 42.8363],
              [-97.1308, 42.8408],
              [-97.1606, 43.0104],
              [-97.269, 42.9816],
              [-97.4391, 43.0867],
              [-97.3777, 43.1585],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4680439',
        NAME: 'Irene-Wakonda School District 13-3',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 733478295,
        AWATER: 1246567,
        INTPTLAT: '+43.0189296',
        INTPTLON: '-097.1724292',
        ELSDLEA: '',
        UNSDLEA: '80439',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-99.8942, 44.2371],
            [-99.7857, 44.2368],
            [-99.7854, 44.3675],
            [-99.9063, 44.3676],
            [-99.8253, 44.4983],
            [-99.8559, 44.5337],
            [-99.8487, 44.6209],
            [-99.6963, 44.5916],
            [-99.673, 44.8975],
            [-99.5727, 44.8973],
            [-99.3529, 44.8972],
            [-99.3118, 44.8225],
            [-99.3002, 44.1948],
            [-99.5766, 44.1924],
            [-99.6545, 44.1017],
            [-99.7687, 44.1357],
            [-99.8845, 44.1296],
            [-99.9382, 44.1952],
            [-99.8942, 44.2371],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4680440',
        NAME: 'Highmore-Harrold School District 34-2',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2869131782,
        AWATER: 69156788,
        INTPTLAT: '+44.4761756',
        INTPTLON: '-099.5528307',
        ELSDLEA: '',
        UNSDLEA: '80440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-100.4754, 45.5594],
              [-100.4064, 45.65],
              [-100.3373, 45.6632],
              [-100.3379, 45.5945],
              [-100.2436, 45.5803],
              [-100.3766, 45.5076],
              [-100.4754, 45.5594],
            ],
          ],
          [
            [
              [-100.4994, 45.9437],
              [-100.0879, 45.9422],
              [-100.2131, 45.8694],
              [-100.212, 45.7107],
              [-99.9343, 45.6582],
              [-99.9343, 45.637],
              [-100.1877, 45.6845],
              [-100.3132, 45.6812],
              [-100.3231, 45.7309],
              [-100.387, 45.8691],
              [-100.4994, 45.9437],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '46',
        SCSDLEA: '',
        GEOID: '4680441',
        NAME: 'Mobridge-Pollock School District 62-6',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 591338870,
        AWATER: 99177176,
        INTPTLAT: '+45.7289010',
        INTPTLON: '-100.2803542',
        ELSDLEA: '',
        UNSDLEA: '80441',
      },
    },
  ],
};

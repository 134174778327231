/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SurveyQuestion from '../SurveyQuestion';
import './styles.css';

const SurveySections = ({ sectionName, surveyQuestions, onEditModeClick, onQuestionClick }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(-1);

  return (
    <Card className="survey-info-card">
      <Card.Header className="survey-info-card-header" onClick={onEditModeClick}>
        {sectionName}
        <span>
          <FontAwesomeIcon size="lg" className="ml-1 mr-5" icon={faSortDown} />
        </span>
      </Card.Header>
      {surveyQuestions?.questions?.map((question) => (
        <SurveyQuestion
          key={question?.id}
          question={question}
          onSelectedQuestion={(id) => {
            onQuestionClick();
            onEditModeClick();
            setSelectedQuestion(id);
          }}
          isSelected={question.id === selectedQuestion}
        />
      ))}
    </Card>
  );
};

SurveySections.propTypes = {
  sectionName: PropTypes.string.isRequired,
  onEditModeClick: PropTypes.func.isRequired,
  onQuestionClick: PropTypes.func.isRequired,
  surveyQuestions: PropTypes.instanceOf(Object).isRequired,
};

SurveySections.defaultProps = {};

export default SurveySections;

import React, { useRef, useState } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap';

const RenameReportModal = ({ open, currentName, onRequestToClose, onConfirm }) => {
  const [reportName, setReportName] = useState(currentName);
  const inputRef = useRef();

  const handleRenameButtonClick = (e) => {
    e.preventDefault();
    onConfirm(reportName);
  };

  const handleClose = () => {
    setReportName(currentName);
    onRequestToClose();
  };

  return (
    <Modal centered show={open} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title as="h2" className="font-weight-normal">
          Rename report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-3 pb-4">
        <FormControl
          ref={inputRef}
          type="text"
          placeholder="[Name - Date - Referral]"
          aria-label="Report name input"
          value={reportName}
          onChange={(e) => setReportName(e.currentTarget.value)}
        />
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={inputRef.current?.value.length === 0} onClick={handleRenameButtonClick}>
          Rename
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RenameReportModal;

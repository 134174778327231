import React from 'react';
import './styles.css';

export default function TermsAndConditions() {
  return (
    <div className="container-scroll">
      <p className="c29">
        <span className="c7 c30">
          Benchmarking Tool (Bento) Site Terms and Conditions of Use and Privacy and Information
          Security Policy
        </span>
      </p>

      <p>Last Updated: 08/31/2020</p>
      <p className="c9">
        <span className="c0">
          Welcome to The Benchmarking Tool (“Bento” or “Tool”) website (“Site”).{' '}
        </span>
      </p>
      <p className="c9">
        <span>
          This Tool was developed by Kitamba Inc. (the “Bento Team”, “we” or “us”) in collaboration
          with the RAND Corporation (“RAND”), to provide authorized educators and policy makers with
          access to high-quality survey and opinion information from America’s K-12 teachers and
          school leaders, as well as access to aggregated educational outcomes data. The information
          contained within this Tool is intended to empower those working to support K-12 education
          to create data visualizations and use this data in decision-making, ultimately to improve
          educational outcomes for all students, and in particular underserved students. There is no
          individual student data included in or accessible through this Tool.{' '}
        </span>
      </p>
      <p className="c16">
        <span className="c1">ACCEPTANCE OF THE SITE TERMS &amp; CONDITIONS</span>
      </p>
      <p className="c9">
        <span className="c0">
          Your access to this Tool must be authorized by the Bento Team. The terms and conditions
          under which you may use the Tool and Site are set forth below and, including the Site
          Privacy Policy set forth below, are referred to collectively as the “Site Terms &amp;
          Conditions.”
        </span>
      </p>
      <p className="c9">
        <span className="c0">
          The Site Terms and Conditions are entered into by and between you and the Bento Team. By
          accessing the Site or using the Tool, you agree to be bound by the agreement set forth in
          the Site Terms &amp; Conditions. Please read the Site Terms &amp; Conditions carefully.{' '}
        </span>
      </p>
      <p className="c16">
        <span className="c1">CHANGES TO THE SITE TERMS &amp; CONDITIONS</span>
      </p>
      <p className="c9">
        <span className="c0">
          We may revise and update the Site Terms &amp; Conditions from time to time in our sole
          discretion. All changes are effective immediately when we post them and apply to all
          access to and use of the Site thereafter.
        </span>
      </p>
      <p className="c9">
        <span className="c0">
          Your continued use of the Site following the posting of revised Site Terms &amp;
          Conditions means that you accept and agree to the changes. You are expected to check the
          Site Terms &amp; Conditions periodically for updates because your continued use of the
          Tool or Site signifies your acceptance of any changes to the agreement described in the
          Site Terms &amp; Conditions. The date this page was last updated is [08/31/2020].{' '}
        </span>
      </p>
      <p className="c16">
        <span className="c1">ACCESS TO THE SITE AND ACCOUNT SECURITY</span>
      </p>
      <p className="c9">
        <span className="c0">
          Use of the Tool and access to the Site is intended for only persons or organizations who
          have been authorized by the Bento Team. You may have to complete a registration process or
          create a profile. Completion of the process will create an account with a username and
          password or other identifier that is unique to you. If you are careless with it, others
          may be able to access the information. You agree to provide accurate, current and complete
          information at all times. You also agree that you will review, maintain, correct, and
          update such information in a timely manner to maintain its accuracy and completeness by
          using the means allowed for the relevant information or, when appropriate, by contacting
          us. If you provide (or we have reasonable grounds to believe that you provided) any
          information that is inaccurate, not current, or incomplete, we may suspend or terminate
          your access to the Tool and the Site, in addition to exercising all rights and remedies
          allowed by law.{' '}
        </span>
      </p>
      <p className="c9">
        <span className="c0">
          You represent and warrant that you will take all reasonable precautions to preserve the
          confidentiality of your personal login information and to prevent its unauthorized use.
          You agree that all uses of the username you create during a registration or similar
          process will be attributed to and legally bind you and may be relied upon by us and our
          agents, affiliates, and other third parties with whom we work in order to provide the
          Tool, Site services or pursue our mission (including but not limited to our and their
          respective affiliates, officers, employees and agents), as being a use made by you, even
          if someone else used your identifier.{' '}
        </span>
      </p>
      <p className="c16">
        <span className="c1">PROPER USE OF SITE/COMPLIANCE WITH LAW</span>
      </p>
      <p className="c9">
        <span className="c0">
          <b>Purpose and Use of Tool.</b> The purpose of the Tool is to provide you a method to
          explore survey data on opinions of educators nationwide, as well as publicly available
          education and demographics data. The Tool empowers you to create and export data
          visualizations that may be published and incorporated in internal or public reports and
          presentations. Through use of the Tool, it is the Bento Team’s hope that education
          practitioners, researchers, funders, and others will better understand and, ultimately,
          improve education and educational outcomes for K-12 students.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          We will review anonymous user data to assess which features and functionality are most
          valuable to users and to make improvements to the Tool.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          The Bento Team would like for this Tool to be as valuable to those working to improve
          public education as possible. If you need additional user accounts for your organization,
          please contact us at <a href="mailto:bento@mgt.us">bento@mgt.us</a>.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          All authorized users must use the Tool, the Site and data derived from the Tool
          responsibly in accordance with applicable law. You may not use this Tool, the Site and
          data derived from the Tool for any purpose that is unlawful, that is intended to damage
          this Tool, or that is inconsistent with or prohibited by the Site Terms &amp; Conditions.
          We may, at any time, in our exclusive discretion and without notice, terminate, suspend or
          restrict your access to this Tool or any of its components. In particular, and without
          limitation, if you violate the Site Terms &amp; Conditions, we reserve the right to seek
          all remedies available at law and in equity, including suspending or blocking your access
          to this Tool and the Site.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          <b>Data Use</b>. You may use data derived from the Tool solely for the research purposes
          as described in these Site Terms &amp; Conditions. You may not license, sell or otherwise
          transfer ownership of this data to a third party. Data export and link-sharing features
          are to be used for non-commercial purposes only.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          As a user of this Site, it is your responsibility to verify, either directly in the
          “Methodology” section of the Site or on the linked source website, whether the data is
          fully or partially owned by third parties and/or whether additional restrictions may
          apply, and to contact the owner of the data before incorporating it in your work in order
          to secure the necessary permissions. The Bento Team in no way represents or warrants that
          it owns or controls all rights in all data, and the Bento Team will not be liable to any
          Site user for any claims brought against them by third parties in connection with the use
          of any data.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          The Bento Team may add, change, improve, or update the data on the Site without notice.
          The Bento Team reserves its exclusive right in its sole discretion to alter, limit, or
          discontinue all or part of the Site and/or any data. Under no circumstances shall the
          Bento Team be liable for any loss, damage, liability, or expense suffered which is claimed
          to result from use of the Tool, Site or data, including without limitation, any fault,
          error, omission, interruption, or delay. Use of the Tool, Site, data, or any component
          thereof is at your sole risk.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          We make every effort to minimize disruption caused by technical errors. However, some
          material on the Site may have been created or structured in files or formats which are not
          error-free, and it cannot be guaranteed that the Site will not be interrupted or otherwise
          affected by such problems. The Bento Team accepts no responsibility with regard to such
          problems (failure of performance, computer virus, communication line failure, alteration
          of content, etc.) incurred as a result of using the Tool, Site, or any link to external
          websites. See further under the headings “Disclaimer of Warranties” and “Limitation of
          Liability” below.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          <b>Survey Data Use.</b> With respect to the RAND survey data contained within the site:{' '}
        </span>
      </p>

      <ul className="c6 lst-kix_list_4-0 start">
        <li className="c5">
          <span className="c0">
            You agree to use these datasets solely for research or statistical purposes and not for
            investigation of specific survey respondents.
          </span>
        </li>
        <li className="c5">
          <span className="c0">
            You agree that you will make no attempts to identify study participants, to make no use
            of the identity of any study participant(s) discovered intentionally or inadvertently,
            and to advise the American Educators Panel (AEP) of any such discovery ({' '}
            <a href="mailto:aepdata@rand.org">aepdata@rand.org</a> ).
          </span>
        </li>
        <li className="c5">
          <span className="c0">
            You acknowledge that RAND, the original collector of the data, as well as the relevant
            funding agency/agencies bear no responsibility for use of the data or for
            interpretations or inferences based upon such uses.
          </span>
        </li>
        <li className="c5">
          <span className="c0">
            You agree to report immediately to the AEP at{' '}
            <a href="mailto:aepdata@rand.org">aepdata@rand.org</a> any discovery of flaws or errors
            in the data or documentation.
          </span>
        </li>
      </ul>

      <p className="c9">
        <span className="c0">
          <b>Access to Resources.</b> Access to certain resources are managed by the Bento Team.
          Access to portions and functionality of the Tool or Site may be limited based on
          role-based permissions linked to your access credentials.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          {' '}
          <b>Interpretation of and Responsible Use of Data.</b> We require users to exercise
          appropriate caution when interpreting data included in this Tool, particularly when data
          is based on small samples sizes. There are training materials included in this Tool to
          help users with responsible use of data.{' '}
        </span>
      </p>

      <p className="c16">
        <span className="c1">LINKS TO OTHER SITES</span>
      </p>

      <p className="c9">
        <span className="c0">
          This Tool contains links to websites not operated by the Bento Team, Inc. (“third-party
          websites”). Your use of third-party websites is at your own risk and will be governed by
          the terms and conditions posted on that site. No one from the Bento Team assumes any
          responsibility for third-party websites (including the accuracy of their content or
          materials) or services offered on third-party websites, even if someone from the Bento
          Team is quoted or leaves a comment on such a third-party website. Websites external to
          this Tool have their own privacy policies and may independently collect data or solicit
          personal information. The privacy and security policies and procedures described for this
          Tool do not apply to any third-party links. We suggest contacting third-party sites
          directly for information on their privacy and security policies.
        </span>
      </p>

      <p className="c16">
        <span className="c1">USER SUBMISSIONS</span>
      </p>

      <p className="c9">
        <span className="c0">
          With respect to any design, idea, concept, suggestion, or other information you
          communicate to us, electronically, telephonically, in writing or otherwise, including
          under the “Feedback” section of the Site (“User Submissions”), you automatically, and
          without further action, grant the Bento Team a royalty-free, perpetual, irrevocable,
          non-exclusive, world-wide license to use, reproduce, publish, display, distribute,
          transmit, transfer, modify, adapt, perform, license, sell, create derivative works from,
          decompile, disassemble, and reverse engineer that User Submission, or any part of that
          User Submission, in whole or in part, for any purpose, and in any form, medium, or
          technology.
        </span>
      </p>

      <p className="c16">
        <span className="c1">DISCLAIMER OF WARRANTIES</span>
      </p>

      <p className="c9">
        <span className="c0">
          You understand that we cannot and do not guarantee or warrant that files available for
          downloading from the internet or the Site will be free of viruses or other destructive
          code. You are responsible for implementing sufficient procedures and checkpoints to
          satisfy your particular requirements for anti-virus protection and accuracy of data input
          and output, and for maintaining a means external to the Site for any reconstruction of any
          lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR
          DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY
          HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR
          OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR YOUR DOWNLOADING OF ANY MATERIAL
          POSTED ON THE SITE, OR ON ANY WEBSITE LINKED TO IT.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          YOUR USE OF THE SITE AND ITS CONTENT IS AT YOUR OWN RISK. THE SITE AND ITS CONTENT ARE
          PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
          EITHER EXPRESS OR IMPLIED. NO ONE FROM THE BENTO TEAM NOR ANY PERSON ASSOCIATED WITH
          ANYONE FROM THE BENTO TEAM MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
          COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SITE.
          WITHOUT LIMITING THE FOREGOING, NO ONE FROM THE BENTO TEAM NOR ANYONE ASSOCIATED WITH
          ANYONE FROM THE BENTO TEAM REPRESENTS OR WARRANTS THAT THE SITE OR ITS CONTENT WILL BE
          ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE
          SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS, OR THAT THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE BENTO TEAM HEREBY DISCLAIMS ALL WARRANTIES OF
          ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED
          TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR
          PURPOSE.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER
          APPLICABLE LAW.
        </span>
      </p>

      <p className="c16">
        <span className="c1">LIMITATION OF LIABILITY</span>
      </p>

      <p className="c9">
        <span className="c0">
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL ANYONE FROM THE BENTO TEAM, ITS
          AFFILIATES, OR THEIR RESPECTIVE LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS,
          OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR
          IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY WEBSITES LINKED TO IT, ANY
          CONTENT ON THE SITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
          INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
          BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER
          CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF
          FORESEEABLE.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER
          APPLICABLE LAW.
        </span>
      </p>

      <p className="c16">
        <span className="c1">INDEMNIFICATION</span>
      </p>

      <p className="c9">
        <span className="c0">
          You agree to defend, indemnify, and hold harmless the Bento Team, its affiliates,
          licensors, and service providers, and each of their respective officers, directors,
          employees, contractors, agents, licensors, suppliers, successors, and assigns from and
          against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or
          fees (including reasonable attorneys' fees) arising out of or relating to your violation
          of the Site Terms &amp; Conditions or your use of the Site, including, but not limited to,
          your User Submissions, any use of the Site's content or data, other than as expressly
          authorized in the Site Terms &amp; Conditions, or your use of any information obtained
          from the Site.
        </span>
      </p>

      <p className="c16">
        <span className="c1">PRIVACY POLICY</span>
      </p>

      <p className="c9">
        <span className="c0">
          The Bento Team is committed to protecting the privacy of visitors to this Tool. We are
          also committed to protecting the privacy and security of all information collected by this
          Tool.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          This Privacy Policy is an integral part of the Site Terms &amp; Conditions under which you
          may use this Tool. Your use of this Tool constitutes agreement with the Site Terms &amp;
          Conditions.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          <b>Children Under the Age of 13.</b>Our Site is not intended for children under 13 years
          of age. No one under age 13 may provide any personal information to or on the Site. We do
          not knowingly collect personal information from children under 13. If you are under 13, do
          not use or provide any information on this Site or register on the Site, or provide any
          information about yourself to us, including your name, address, telephone number, email
          address, or any screen name or user name you may use. If we learn that we have collected
          or received personal information from a child under 13 without verification of parental
          consent, we will delete that information. If you believe we might have any information
          from or about a child under 13, please contact us at{' '}
          <a href="mailto:bento@mgt.us">bento@mgt.us</a>.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          The only personally identifiable information we obtain from you is such information that
          you voluntarily transmit to us, including name and email address. We will disclose
          personal information only when required by law or in the good-faith belief that such
          action is necessary to conform to the law, comply with a legal process served on us or
          cooperate with the investigations of purported unlawful activities. We will never sell
          your information.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          If you are a California resident, please read our “Privacy Notice for California
          Residents” below.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          We use reports from Google Analytics to help us analyze how users use this Tool. Google
          Analytics uses “cookies,” which are small text files placed on the computer of Tool users
          and which collect standard internet log information and Tool use information in an
          anonymous form. No personally identifiable information is collected about you unless you
          explicitly submit such information to us. The Site uses cookies to improve user
          experience. By continuing to use the Site, you are agreeing to our use of cookies.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          Cookies store information that a website may need in order to personalize your experience
          and gather website statistical data. Any time you visit the Site to browse or to read or
          download information, the following information is collected and stored: the name of the
          domain and host from which you access the Internet; the Internet protocol (IP) address of
          the computer you are using; the browser software you use and your operating system; the
          date and time you access the Site; and the Internet address of the website from which you
          linked directly to the Site. This information is used to measure the number of visitors to
          areas of the Site, and to help us make the Site more useful and interesting to our users.
          We use information from cookies in the aggregate to analyze for trends and statistics—we
          do not track individuals, only instances of entry onto the Site.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          The anonymous information generated by Google Analytics cookies about use of this Tool is
          transmitted to Google, not to the Bento Team. Google Analytics then compiles this
          anonymous information into statistical reports on aggregate user activity for this Tool
          only. Those reports are used by us to evaluate how this Tool is used by users so that we
          can better understand the interests of Tool users and make improvements to this Tool.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          For further information about how Google Analytics collects and processes data, see{' '}
          <a href="https://www.google.com/policies/privacy/partners/">
            https://www.google.com/policies/privacy/partners/
          </a>
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          The Tool will also log user activity, the data from which will be aggregated to evaluate
          how this Tool is used by users so that we can better understand the interests of Tool
          users and make improvements to this Tool. Data collected will also be used to address
          customer service requests and user experience issues.{' '}
        </span>
      </p>

      <p className="c16">
        <span className="c1">Privacy Notice for California Residents</span>
      </p>

      <p className="c9">
        <span className="c0">
          This Privacy Notice for California Residents contains disclosures required by the
          California Consumer Privacy Act (“CCPA”), is only relevant to residents of California, and
          applies only to the collection or other use of personal information that is subject to the
          CCPA. “Personal information,” as used in this Privacy Notice for California Residents, is
          defined as provided in the CCPA, and includes any information that identifies, relates to,
          describes, is reasonably capable of being associated with, or could reasonably be linked,
          directly or indirectly, with a particular consumer or household.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          In the past twelve (12) months, we have collected the following categories of personal
          information about California residents:
        </span>
      </p>
      <table className="table-toc">
        <tbody>
          <tr>
            <td>
              <b>Category</b>
            </td>
            <td>
              <b>Examples</b>
            </td>
          </tr>
          <tr>
            <td>A. Identifiers.</td>
            <td>A real name, Internet Protocol address, or email address.</td>
          </tr>
          <tr>
            <td>
              B. Personal information categories listed in the California Customer Records statute
              (Cal. Civ. Code § 1798.80(e)).
            </td>
            <td>
              A name <br /> Some personal information included in this category may overlap with
              other categories.{' '}
            </td>
          </tr>
          <tr>
            <td>
              C. Protected classNameification characteristics under California or federal law.
            </td>
            <td> </td>
          </tr>
          <tr>
            <td> D. Internet or other similar network activity.</td>
            <td>Information on a consumer's interaction with a website or application </td>
          </tr>
        </tbody>
      </table>

      <p className="c9">
        <span className="c0">
          We may use or disclose the personal information we collect for one or more of the
          following purposes:{' '}
        </span>
      </p>

      <ul className="c6 lst-kix_list_4-0 start">
        <li className="c5">
          <span className="c0">
            To fulfill or meet the reason you provided the information. For example, if you share
            your name and contact information to ask a question about our products or services, we
            will use that personal information to respond to your inquiry.{' '}
          </span>
        </li>
        <li className="c5">
          <span className="c0">
            To provide, support, personalize, and develop the Site, products, and services.
          </span>
        </li>
        <li className="c5">
          <span className="c0">
            To create, maintain, customize, and secure your account with us.
          </span>
        </li>
        <li className="c5">
          <span className="c0">
            To provide you with support and to respond to your inquiries, including to investigate
            and address your concerns and monitor and improve our responses.
          </span>
        </li>
        <li className="c5">
          <span className="c0">
            To personalize your Site experience and to deliver content relevant to your interests.
          </span>
        </li>
      </ul>

      <p className="c9">
        <span className="c0">
          We will not collect additional categories of personal information or use the personal
          information we collected for materially different, unrelated, or incompatible purposes
          without providing you notice.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          We do not sell your personal information to third parties. We will disclose personal
          information only when required by law or in the good-faith belief that such action is
          necessary to conform to the law, comply with a legal process served on us or cooperate
          with the investigations of purported unlawful activities.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          In the past twelve (12) months, we have not sold any personal information we collect to
          third parties, and we have shared the categories of personal information we collect as set
          forth above.
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          The CCPA provides a California consumer the following rights, subject to certain
          exceptions and limitations:
        </span>
      </p>

      <ul className="c6 lst-kix_list_4-0 start">
        <li className="c5">
          <span className="c0">
            The right to request (a) the categories and specific pieces of personal information we
            collect, use, disclose, and sell about you, (b) the categories of sources from which we
            collected your personal information, (c) the purposes for which we collect or sell your
            personal information, (d) the categories of your personal information (if any) that we
            have either sold or disclosed for a business purpose, and (e) the categories of third
            parties with which we have shared personal information;
          </span>
        </li>
        <li className="c5">
          <span className="c0">
            For certain categories of personal information, the right to request a list of what
            personal information (if any) we disclosed to third parties for their own direct
            marketing purposes in the past twelve (12) months and the names and addresses of those
            third parties;
          </span>
        </li>
        <li className="c5">
          <span className="c0">
            The right to request that we delete the personal information we have collected from you
            or maintain about you;
          </span>
        </li>
        <li className="c5">
          <span className="c0">
            We do not sell your personal information, so we do not offer an opt-out; and
          </span>
        </li>
        <li className="c5">
          <span className="c0">
            The right not to receive discriminatory treatment for the exercise of the privacy rights
            conferred by the CCPA.
          </span>
        </li>
      </ul>

      <p className="c9">
        <span className="c0">
          You may submit requests relating to your exercise of rights under the CCPA to us by email
          at <a href="mailto:bento@mgt.us">bento@mgt.us</a>.
        </span>
      </p>

      <p className="c16">
        <span className="c1">Additional Information for Residents of the EEA</span>
      </p>

      <p className="c9">
        <span className="c0">
          If you are a resident of the European Economic Area (“EEA”) accessing the Site, you may
          have certain rights under the EU General Data Protection Regulation (“GDPR”) in respect of
          the personal information that we hold about you. These rights include the ability:.
        </span>
      </p>

      <p className="c9">
        <span className="c0">To request access to your personal information;</span>
      </p>

      <p className="c9">
        <span className="c0">
          To request rectification of inaccurate or incomplete information;
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          To request erasure of your information (a “right to be forgotten”);
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          To restrict the processing of your information in certain circumstances;
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          To object to our use of your information, such as where we have considered such use to be
          necessary for our legitimate interests;
        </span>
      </p>

      <p className="c9">
        <span className="c0">Where relevant, to request the portability of your information;</span>
      </p>

      <p className="c9">
        <span className="c0">
          Where you have given consent to the processing of your personal information, to withdraw
          your consent; and
        </span>
      </p>

      <p className="c9">
        <span className="c0">To lodge a complaint with the competent supervisory authority.</span>
      </p>

      <p className="c9">
        <span className="c0">
          If you have any questions about this Privacy Policy, comments or enquiries regarding the
          collection, processing, and storage of your personal information by the Bento Team, or to
          exercise your rights under GDPR, please contact us via email at{' '}
          <a href="mailto:bento@mgt.us">bento@mgt.us</a> . The Bento Team will handle all such
          requests in accordance with applicable data protection and privacy laws.
        </span>
      </p>

      <p className="c16">
        <span className="c1">ADDITIONAL RIGHTS AND RESTRICTIONS</span>
      </p>

      <p className="c9">
        <span className="c0">
          (1) You may not assign, transfer, subcontract, or delegate any of your rights, duties, or
          obligations under the Site Terms &amp; Conditions.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          (2) We reserve all rights relating to this Tool not expressly granted to us by the Site
          Terms &amp; Conditions.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          (3) If a court of competent jurisdiction determines that any clause or provision of the
          Site Terms &amp; Conditions is illegal, invalid or unenforceable under present or future
          law, such clause or provision shall be deemed to be deleted and severed from the Site
          Terms &amp; Conditions without affecting the enforceability of all remaining clauses or
          provisions.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          (4) No waiver by any member of the Bento Team of any provision hereof, and no failure by
          any member of the Bento Team to exercise any of its rights or remedies hereunder, will be
          deemed to constitute a waiver of such provision, right, or remedy in the future, or of any
          other provision, right, or remedy hereunder.{' '}
        </span>
      </p>

      <p className="c9">
        <span className="c0">
          (5) The Site Terms and Conditions, as modified from time to time by the Bento Team and
          accepted by you in accordance with the provisions hereof, constitute the entire agreement
          between you and the Bento Team with respect to your use of the Tool and Site and
          supersedes all prior and contemporaneous understandings, agreements, representations, and
          warranties, both written and oral, regarding the Tool and the Site.
        </span>
      </p>

      <p className="c16">
        <span className="c1">GOVERNING LAW</span>
      </p>

      <p className="c9">
        <span className="c0">
          The Site Terms &amp; Conditions will be governed by and construed in accordance with the
          laws of the State of New York, without giving effect to the principles of conflicts of
          law.
        </span>
      </p>

      <p className="c16">
        <span className="c1">FOR MORE INFORMATION</span>
      </p>

      <p className="c9">
        <span className="c0">
          If you have any questions regarding the Site Terms &amp; Conditions, please contact us by
          sending an email to <a href="mailto:bento@mgt.us">bento@mgt.us</a>.{' '}
        </span>
      </p>
    </div>
  );
}

/* eslint-disable no-param-reassign */
import types from '../types';

const initialState = {
  error: '',
  data: null,
  loading: true,
  deleted: false,
  copiedId: null,
};

const reportDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.REPORT_DETAILS_GET_PENDING:
      return {
        ...state,
        loading: true,
        deleted: false,
        copiedId: null,
      };
    case types.reducerTypes.REPORT_DETAILS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.reducerTypes.REPORT_DETAILS_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.REPORT_DETAILS_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.REPORT_DETAILS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.reducerTypes.REPORT_DETAILS_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.reducerTypes.REPORT_DELETE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.REPORT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
      };
    case types.reducerTypes.REPORT_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.reducerTypes.REPORT_ITEM_DELETE_PENDING:
      return {
        ...state,
      };
    case types.reducerTypes.REPORT_ITEM_DELETE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: [...state.data.items.filter((i) => i.id !== action.payload)],
        },
      };
    case types.reducerTypes.REPORT_ITEM_DELETE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.reducerTypes.REPORT_COPY_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.reducerTypes.REPORT_COPY_SUCCESS:
      return {
        ...state,
        loading: false,
        copiedId: action.payload.id,
      };
    case types.reducerTypes.REPORT_COPY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reportDetailsReducer;

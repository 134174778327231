export const CT = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.451, 41.4337],
            [-72.4337, 41.424],
            [-72.4257, 41.4008],
            [-72.3826, 41.3825],
            [-72.3691, 41.3423],
            [-72.4255, 41.3242],
            [-72.4727, 41.3342],
            [-72.5139, 41.3464],
            [-72.5241, 41.3762],
            [-72.451, 41.4337],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '03630',
        GEOID: '903630',
        NAME: 'Regional High School District 04',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103517061,
        AWATER: 7459342,
        INTPTLAT: '+41.3763804',
        INTPTLON: '-072.4608355',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0869, 41.2766],
            [-73.0679, 41.301],
            [-73.0407, 41.3139],
            [-73.0391, 41.3267],
            [-73.0472, 41.3605],
            [-73.0475, 41.3958],
            [-73.0417, 41.4209],
            [-73.0258, 41.4596],
            [-73.0018, 41.4712],
            [-72.9524, 41.4646],
            [-72.948, 41.4641],
            [-72.9549, 41.3945],
            [-72.9729, 41.3474],
            [-72.998, 41.3141],
            [-72.9891, 41.3133],
            [-72.9921, 41.2449],
            [-73.0869, 41.2766],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '03660',
        GEOID: '903660',
        NAME: 'Regional High School District 05',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147890073,
        AWATER: 2584498,
        INTPTLAT: '+41.3635379',
        INTPTLON: '-073.0038921',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5054, 41.647],
            [-72.4522, 41.6888],
            [-72.4601, 41.7273],
            [-72.4228, 41.732],
            [-72.4103, 41.7655],
            [-72.3289, 41.7388],
            [-72.3565, 41.7015],
            [-72.3342, 41.6436],
            [-72.3245, 41.6186],
            [-72.4106, 41.6018],
            [-72.4667, 41.5839],
            [-72.5054, 41.647],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '03750',
        GEOID: '903750',
        NAME: 'Regional High School District 08',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 196158057,
        AWATER: 1987545,
        INTPTLAT: '+41.6644949',
        INTPTLON: '-072.4113823',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4704, 41.3269],
            [-73.4243, 41.3353],
            [-73.3477, 41.3502],
            [-73.3252, 41.3223],
            [-73.3059, 41.3323],
            [-73.2744, 41.2938],
            [-73.2486, 41.232],
            [-73.329, 41.2134],
            [-73.3688, 41.275],
            [-73.4301, 41.2543],
            [-73.4382, 41.2652],
            [-73.4704, 41.3269],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '03780',
        GEOID: '903780',
        NAME: 'Regional High School District 09',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152607026,
        AWATER: 4655380,
        INTPTLAT: '+41.2881408',
        INTPTLON: '-073.3553307',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1646, 41.8349],
            [-72.114, 41.8317],
            [-72.0924, 41.8317],
            [-72.0447, 41.8315],
            [-72.0145, 41.805],
            [-72.0229, 41.7596],
            [-72.0526, 41.7314],
            [-72.0621, 41.6522],
            [-72.1198, 41.6618],
            [-72.12, 41.6633],
            [-72.112, 41.7459],
            [-72.1563, 41.7535],
            [-72.1646, 41.8349],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '03570',
        GEOID: '903570',
        NAME: 'Regional High School District 11',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 163559341,
        AWATER: 1536941,
        INTPTLAT: '+41.7597414',
        INTPTLON: '-072.0895315',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3101, 41.8361],
            [-72.3097, 41.8378],
            [-72.3069, 41.9296],
            [-72.3068, 41.9406],
            [-72.2349, 41.9576],
            [-72.217, 41.9582],
            [-72.1517, 41.9593],
            [-72.114, 41.8317],
            [-72.1646, 41.8349],
            [-72.1563, 41.7535],
            [-72.2526, 41.7282],
            [-72.3101, 41.8361],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '00005',
        GEOID: '900005',
        NAME: 'Regional High School District 19',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 302134910,
        AWATER: 5055984,
        INTPTLAT: '+41.8616318',
        INTPTLON: '-072.2239694',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5116, 41.7479],
            [-73.5079, 41.7915],
            [-73.4966, 41.9217],
            [-73.4883, 42.03],
            [-73.4873, 42.0496],
            [-73.3407, 42.0483],
            [-73.2329, 42.0451],
            [-73.2436, 41.994],
            [-73.263, 41.9162],
            [-73.3014, 41.779],
            [-73.3869, 41.781],
            [-73.3788, 41.6994],
            [-73.3821, 41.6833],
            [-73.5069, 41.6658],
            [-73.5181, 41.6667],
            [-73.5116, 41.7479],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '03600',
        GEOID: '903600',
        NAME: 'Regional High School District 01',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 681157265,
        AWATER: 13653911,
        INTPTLAT: '+41.8823963',
        INTPTLON: '-073.3768438',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2436, 41.994],
            [-73.2329, 42.0451],
            [-73.1722, 42.0433],
            [-73.1272, 42.0421],
            [-73.0533, 42.0399],
            [-73.0087, 42.0389],
            [-73.0295, 41.9666],
            [-72.8871, 41.9731],
            [-72.9059, 41.9217],
            [-72.9406, 41.8952],
            [-72.949, 41.8064],
            [-73.0767, 41.7985],
            [-73.0535, 41.8873],
            [-73.034, 41.9663],
            [-73.1515, 41.9597],
            [-73.165, 41.9132],
            [-73.263, 41.9162],
            [-73.2436, 41.994],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '03720',
        GEOID: '903720',
        NAME: 'Regional High School District 07',
        LSAD: '00',
        LOGRADE: '07',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388873397,
        AWATER: 16080791,
        INTPTLAT: '+41.9699218',
        INTPTLON: '-073.1784731',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4228, 41.732],
            [-72.4103, 41.7655],
            [-72.3289, 41.7388],
            [-72.3565, 41.7015],
            [-72.4228, 41.732],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900030',
        NAME: 'Andover School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40012483,
        AWATER: 635900,
        INTPTLAT: '+41.7329821',
        INTPTLON: '-072.3746584',
        ELSDLEA: '00030',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.217, 41.9582],
            [-72.1517, 41.9593],
            [-72.114, 41.8317],
            [-72.1646, 41.8349],
            [-72.2177, 41.8352],
            [-72.217, 41.9582],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900090',
        NAME: 'Ashford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100392661,
        AWATER: 1832771,
        INTPTLAT: '+41.8971227',
        INTPTLON: '-072.1714268',
        ELSDLEA: '00090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.034, 41.9663],
            [-73.0295, 41.9666],
            [-72.8871, 41.9731],
            [-72.9059, 41.9217],
            [-72.9406, 41.8952],
            [-73.0535, 41.8873],
            [-73.034, 41.9663],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900150',
        NAME: 'Barkhamsted School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93896808,
        AWATER: 6671465,
        INTPTLAT: '+41.9357077',
        INTPTLON: '-072.9729214',
        ELSDLEA: '00150',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0417, 41.4209],
            [-73.0258, 41.4596],
            [-73.0018, 41.4712],
            [-72.9524, 41.4646],
            [-72.948, 41.4641],
            [-72.9549, 41.3945],
            [-73.0475, 41.3958],
            [-73.0417, 41.4209],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900240',
        NAME: 'Bethany School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54669007,
        AWATER: 996204,
        INTPTLAT: '+41.4229640',
        INTPTLON: '-072.9941871',
        ELSDLEA: '00240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2276, 41.5187],
            [-72.2191, 41.5388],
            [-72.194, 41.5904],
            [-72.135, 41.5695],
            [-72.1354, 41.504],
            [-72.2276, 41.5187],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900390',
        NAME: 'Bozrah School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51708106,
        AWATER: 723331,
        INTPTLAT: '+41.5450583',
        INTPTLON: '-072.1752280',
        ELSDLEA: '00390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0145, 41.805],
            [-71.9062, 41.8268],
            [-71.9172, 41.7597],
            [-71.9139, 41.7423],
            [-72.0229, 41.7596],
            [-72.0145, 41.805],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900570',
        NAME: 'Brooklyn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75454825,
        AWATER: 282000,
        INTPTLAT: '+41.7872432',
        INTPTLON: '-071.9574014',
        ELSDLEA: '00570',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3574, 42.0026],
            [-73.2436, 41.994],
            [-73.263, 41.9162],
            [-73.3618, 41.9184],
            [-73.3574, 42.0026],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900630',
        NAME: 'Canaan School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85242557,
        AWATER: 836406,
        INTPTLAT: '+41.9629594',
        INTPTLON: '-073.3071433',
        ELSDLEA: '00630',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0621, 41.6522],
            [-72.0526, 41.7314],
            [-72.0229, 41.7596],
            [-71.9139, 41.7423],
            [-71.955, 41.6348],
            [-71.9753, 41.6381],
            [-72.0312, 41.6472],
            [-72.0621, 41.6522],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900660',
        NAME: 'Canterbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 103456496,
        AWATER: 683775,
        INTPTLAT: '+41.6939604',
        INTPTLON: '-072.0091181',
        ELSDLEA: '00660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1646, 41.8349],
            [-72.114, 41.8317],
            [-72.0924, 41.8317],
            [-72.1026, 41.7444],
            [-72.112, 41.7459],
            [-72.1563, 41.7535],
            [-72.1646, 41.8349],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900720',
        NAME: 'Chaplin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50316502,
        AWATER: 352486,
        INTPTLAT: '+41.7908895',
        INTPTLON: '-072.1295395',
        ELSDLEA: '00720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.451, 41.4337],
            [-72.4337, 41.424],
            [-72.4257, 41.4008],
            [-72.5241, 41.3762],
            [-72.451, 41.4337],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900780',
        NAME: 'Chester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41573118,
        AWATER: 2010913,
        INTPTLAT: '+41.4117284',
        INTPTLON: '-072.4871458',
        ELSDLEA: '00780',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1272, 42.0421],
            [-73.0533, 42.0399],
            [-73.0087, 42.0389],
            [-73.0295, 41.9666],
            [-73.034, 41.9663],
            [-73.1515, 41.9597],
            [-73.1272, 42.0421],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900870',
        NAME: 'Colebrook School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81666028,
        AWATER: 3603195,
        INTPTLAT: '+42.0053723',
        INTPTLON: '-073.0637316',
        ELSDLEA: '00870',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3565, 41.7015],
            [-72.3289, 41.7388],
            [-72.2466, 41.7229],
            [-72.2396, 41.7142],
            [-72.3342, 41.6436],
            [-72.3565, 41.7015],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900900',
        NAME: 'Columbia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 55355050,
        AWATER: 1587988,
        INTPTLAT: '+41.6937069',
        INTPTLON: '-072.3056713',
        ELSDLEA: '00900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4101, 41.7883],
            [-73.3618, 41.9184],
            [-73.263, 41.9162],
            [-73.3014, 41.779],
            [-73.3869, 41.781],
            [-73.4101, 41.7883],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900930',
        NAME: 'Cornwall School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 119297398,
        AWATER: 656787,
        INTPTLAT: '+41.8496485',
        INTPTLON: '-073.3300949',
        ELSDLEA: '00930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5241, 41.3762],
            [-72.4257, 41.4008],
            [-72.3826, 41.3825],
            [-72.4727, 41.3342],
            [-72.5139, 41.3464],
            [-72.5241, 41.3762],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901080',
        NAME: 'Deep River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35001768,
        AWATER: 1684244,
        INTPTLAT: '+41.3678191',
        INTPTLON: '-072.4774422',
        ELSDLEA: '01080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1517, 41.9593],
            [-72.0992, 41.9589],
            [-72.044, 41.9146],
            [-72.0447, 41.8315],
            [-72.0924, 41.8317],
            [-72.114, 41.8317],
            [-72.1517, 41.9593],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901380',
        NAME: 'Eastford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74904508,
        AWATER: 891568,
        INTPTLAT: '+41.8955903',
        INTPTLON: '-072.0933042',
        ELSDLEA: '01380',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3688, 41.275],
            [-73.3252, 41.3223],
            [-73.3059, 41.3323],
            [-73.2744, 41.2938],
            [-73.2486, 41.232],
            [-73.329, 41.2134],
            [-73.3688, 41.275],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901410',
        NAME: 'Easton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70999223,
        AWATER: 3231994,
        INTPTLAT: '+41.2649447',
        INTPTLON: '-073.3004792',
        ELSDLEA: '01410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3826, 41.3825],
            [-72.3691, 41.3423],
            [-72.4255, 41.3242],
            [-72.4727, 41.3342],
            [-72.3826, 41.3825],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901500',
        NAME: 'Essex School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26942175,
        AWATER: 3764185,
        INTPTLAT: '+41.3473533',
        INTPTLON: '-072.4124122',
        ELSDLEA: '01500',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.1575, 41.6576],
            [-72.12, 41.6633],
            [-72.1198, 41.6618],
            [-72.1041, 41.6008],
            [-72.135, 41.5695],
            [-72.194, 41.5904],
            [-72.1575, 41.6576],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901590',
        NAME: 'Franklin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51049946,
        AWATER: 222178,
        INTPTLAT: '+41.6212115',
        INTPTLON: '-072.1426091',
        ELSDLEA: '01590',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0924, 41.8317],
            [-72.0447, 41.8315],
            [-72.0145, 41.805],
            [-72.0229, 41.7596],
            [-72.0526, 41.7314],
            [-72.1026, 41.7444],
            [-72.0924, 41.8317],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901890',
        NAME: 'Hampton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64988483,
        AWATER: 1052941,
        INTPTLAT: '+41.7830301',
        INTPTLON: '-072.0645718',
        ELSDLEA: '01890',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0087, 42.0389],
            [-72.8636, 42.0377],
            [-72.8871, 41.9731],
            [-73.0295, 41.9666],
            [-73.0087, 42.0389],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901950',
        NAME: 'Hartland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85674886,
        AWATER: 3932194,
        INTPTLAT: '+42.0012054',
        INTPTLON: '-072.9540266',
        ELSDLEA: '01950',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4601, 41.7273],
            [-72.4228, 41.732],
            [-72.3565, 41.7015],
            [-72.3342, 41.6436],
            [-72.3245, 41.6186],
            [-72.4106, 41.6018],
            [-72.4522, 41.6888],
            [-72.4601, 41.7273],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902010',
        NAME: 'Hebron School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95666377,
        AWATER: 907164,
        INTPTLAT: '+41.6583378',
        INTPTLON: '-072.3934727',
        ELSDLEA: '02010',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5116, 41.7479],
            [-73.5079, 41.7915],
            [-73.4101, 41.7883],
            [-73.3869, 41.781],
            [-73.3788, 41.6994],
            [-73.3821, 41.6833],
            [-73.5069, 41.6658],
            [-73.5181, 41.6667],
            [-73.5116, 41.7479],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902040',
        NAME: 'Kent School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125744050,
        AWATER: 2745773,
        INTPTLAT: '+41.7297820',
        INTPTLON: '-073.4541400',
        ELSDLEA: '02040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0479, 41.5948],
            [-72.0312, 41.6472],
            [-71.9753, 41.6381],
            [-71.9892, 41.5656],
            [-72.0461, 41.556],
            [-72.0479, 41.5948],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902190',
        NAME: 'Lisbon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42200264,
        AWATER: 1018780,
        INTPTLAT: '+41.6027656',
        INTPTLON: '-072.0122612',
        ELSDLEA: '02190',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3101, 41.8361],
            [-72.2177, 41.8352],
            [-72.1646, 41.8349],
            [-72.1563, 41.7535],
            [-72.2526, 41.7282],
            [-72.3101, 41.8361],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902340',
        NAME: 'Mansfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 115511961,
        AWATER: 2640719,
        INTPTLAT: '+41.7892725',
        INTPTLON: '-072.2284642',
        ELSDLEA: '02340',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5054, 41.647],
            [-72.4522, 41.6888],
            [-72.4106, 41.6018],
            [-72.4667, 41.5839],
            [-72.5054, 41.647],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902370',
        NAME: 'Marlborough School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60479197,
        AWATER: 444481,
        INTPTLAT: '+41.6364390',
        INTPTLON: '-072.4540018',
        ELSDLEA: '02370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0535, 41.8873],
            [-72.9406, 41.8952],
            [-72.949, 41.8064],
            [-73.0767, 41.7985],
            [-73.0535, 41.8873],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902760',
        NAME: 'New Hartford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95927215,
        AWATER: 2940726,
        INTPTLAT: '+41.8410525',
        INTPTLON: '-072.9986640',
        ELSDLEA: '02760',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2436, 41.994],
            [-73.2329, 42.0451],
            [-73.1722, 42.0433],
            [-73.1272, 42.0421],
            [-73.1515, 41.9597],
            [-73.165, 41.9132],
            [-73.263, 41.9162],
            [-73.2436, 41.994],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902940',
        NAME: 'Norfolk School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 117383346,
        AWATER: 2865405,
        INTPTLAT: '+41.9699218',
        INTPTLON: '-073.1784731',
        ELSDLEA: '02940',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3407, 42.0483],
            [-73.2329, 42.0451],
            [-73.2436, 41.994],
            [-73.3574, 42.0026],
            [-73.3407, 42.0483],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903000',
        NAME: 'North Canaan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50417655,
        AWATER: 15763,
        INTPTLAT: '+42.0222208',
        INTPTLON: '-073.2956407',
        ELSDLEA: '03000',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0869, 41.2766],
            [-73.0679, 41.301],
            [-73.0407, 41.3139],
            [-72.998, 41.3141],
            [-72.9891, 41.3133],
            [-72.9921, 41.2449],
            [-73.0869, 41.2766],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903210',
        NAME: 'Orange School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44498737,
        AWATER: 616353,
        INTPTLAT: '+41.2849513',
        INTPTLON: '-073.0246368',
        ELSDLEA: '03210',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.044, 41.9146],
            [-71.9338, 41.9169],
            [-71.9174, 41.8692],
            [-71.9062, 41.8268],
            [-72.0145, 41.805],
            [-72.0447, 41.8315],
            [-72.044, 41.9146],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903360',
        NAME: 'Pomfret School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104451273,
        AWATER: 647536,
        INTPTLAT: '+41.8680405',
        INTPTLON: '-071.9722603',
        ELSDLEA: '03360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.081, 41.4921],
            [-72.0461, 41.556],
            [-71.9892, 41.5656],
            [-71.9029, 41.5203],
            [-71.9566, 41.4874],
            [-72.07, 41.4636],
            [-72.081, 41.4921],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903420',
        NAME: 'Preston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79848698,
        AWATER: 2360639,
        INTPTLAT: '+41.5186523',
        INTPTLON: '-072.0065973',
        ELSDLEA: '03420',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4704, 41.3269],
            [-73.4243, 41.3353],
            [-73.3477, 41.3502],
            [-73.3252, 41.3223],
            [-73.3688, 41.275],
            [-73.4301, 41.2543],
            [-73.4382, 41.2652],
            [-73.4704, 41.3269],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903510',
        NAME: 'Redding School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81607803,
        AWATER: 1423386,
        INTPTLAT: '+41.3054148',
        INTPTLON: '-073.3925837',
        ELSDLEA: '03510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3229, 41.5181],
            [-72.2191, 41.5388],
            [-72.2276, 41.5187],
            [-72.242, 41.4432],
            [-72.2766, 41.4404],
            [-72.3055, 41.4363],
            [-72.3229, 41.5181],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903900',
        NAME: 'Salem School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74924890,
        AWATER: 2172864,
        INTPTLAT: '+41.4893505',
        INTPTLON: '-072.2583804',
        ELSDLEA: '03900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4883, 42.03],
            [-73.4873, 42.0496],
            [-73.3407, 42.0483],
            [-73.3574, 42.0026],
            [-73.3618, 41.9184],
            [-73.4966, 41.9217],
            [-73.4883, 42.03],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903930',
        NAME: 'Salisbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148254582,
        AWATER: 7341173,
        INTPTLAT: '+41.9862260',
        INTPTLON: '-073.4252735',
        ELSDLEA: '03930',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.112, 41.7459],
            [-72.1026, 41.7444],
            [-72.0526, 41.7314],
            [-72.0621, 41.6522],
            [-72.1198, 41.6618],
            [-72.12, 41.6633],
            [-72.112, 41.7459],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903960',
        NAME: 'Scotland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48254356,
        AWATER: 131514,
        INTPTLAT: '+41.6921799',
        INTPTLON: '-072.0996602',
        ELSDLEA: '03960',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4966, 41.9217],
            [-73.3618, 41.9184],
            [-73.4101, 41.7883],
            [-73.5079, 41.7915],
            [-73.4966, 41.9217],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904020',
        NAME: 'Sharon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 152201023,
        AWATER: 2058009,
        INTPTLAT: '+41.8607627',
        INTPTLON: '-073.4492152',
        ELSDLEA: '04020',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.521, 41.6198],
            [-73.5181, 41.6667],
            [-73.5069, 41.6658],
            [-73.4506, 41.5351],
            [-73.5307, 41.5202],
            [-73.521, 41.6198],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904080',
        NAME: 'Sherman School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56699464,
        AWATER: 3915523,
        INTPTLAT: '+41.5812374',
        INTPTLON: '-073.5015702',
        ELSDLEA: '04080',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0621, 41.6522],
            [-72.0312, 41.6472],
            [-72.0479, 41.5948],
            [-72.1041, 41.6008],
            [-72.1198, 41.6618],
            [-72.0621, 41.6522],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904260',
        NAME: 'Sprague School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34294919,
        AWATER: 1491928,
        INTPTLAT: '+41.6355702',
        INTPTLON: '-072.0775268',
        ELSDLEA: '04260',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8579, 41.6442],
            [-71.8387, 41.7688],
            [-71.7913, 41.7745],
            [-71.7897, 41.7246],
            [-71.7872, 41.6561],
            [-71.7877, 41.6399],
            [-71.8579, 41.6442],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904350',
        NAME: 'Sterling School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70499869,
        AWATER: 171521,
        INTPTLAT: '+41.7012000',
        INTPTLON: '-071.8159882',
        ELSDLEA: '04350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2051, 42.0311],
            [-72.1988, 42.031],
            [-72.1357, 42.0302],
            [-72.1022, 42.0288],
            [-72.0992, 41.9589],
            [-72.1517, 41.9593],
            [-72.217, 41.9582],
            [-72.2349, 41.9576],
            [-72.2051, 42.0311],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904650',
        NAME: 'Union School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74734451,
        AWATER: 2514116,
        INTPTLAT: '+41.9998845',
        INTPTLON: '-072.1622435',
        ELSDLEA: '04650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.8844, 41.6412],
            [-71.8579, 41.6442],
            [-71.7877, 41.6399],
            [-71.7914, 41.5458],
            [-71.7932, 41.5057],
            [-71.8634, 41.5149],
            [-71.8844, 41.6412],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904710',
        NAME: 'Voluntown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100908167,
        AWATER: 2152833,
        INTPTLAT: '+41.5791925',
        INTPTLON: '-071.8312276',
        ELSDLEA: '04710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3069, 41.9296],
            [-72.3068, 41.9406],
            [-72.2349, 41.9576],
            [-72.217, 41.9582],
            [-72.2177, 41.8352],
            [-72.3101, 41.8361],
            [-72.3097, 41.8378],
            [-72.3069, 41.9296],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905100',
        NAME: 'Willington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86230288,
        AWATER: 582494,
        INTPTLAT: '+41.8972671',
        INTPTLON: '-072.2518477',
        ELSDLEA: '05100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0475, 41.3958],
            [-72.9549, 41.3945],
            [-72.9729, 41.3474],
            [-72.998, 41.3141],
            [-73.0407, 41.3139],
            [-73.0391, 41.3267],
            [-73.0472, 41.3605],
            [-73.0475, 41.3958],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905310',
        NAME: 'Woodbridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '06',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48722329,
        AWATER: 971941,
        INTPTLAT: '+41.3635379',
        INTPTLON: '-073.0038921',
        ELSDLEA: '05310',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0998, 42.0287],
            [-71.9931, 42.0269],
            [-71.963, 42.0262],
            [-71.9318, 41.9316],
            [-71.9338, 41.9169],
            [-72.044, 41.9146],
            [-72.0992, 41.9589],
            [-72.1022, 42.0288],
            [-72.0998, 42.0287],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905370',
        NAME: 'Woodstock School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157070494,
        AWATER: 3070576,
        INTPTLAT: '+41.9662084',
        INTPTLON: '-072.0227864',
        ELSDLEA: '05370',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0472, 41.3605],
            [-73.0391, 41.3267],
            [-73.1024, 41.3529],
            [-73.0472, 41.3605],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900060',
        NAME: 'Ansonia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 15588605,
        AWATER: 435200,
        INTPTLAT: '+41.3442494',
        INTPTLON: '-073.0698254',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9212, 41.8071],
            [-72.8702, 41.8161],
            [-72.7986, 41.8249],
            [-72.7972, 41.8047],
            [-72.7967, 41.7588],
            [-72.9085, 41.776],
            [-72.9212, 41.8071],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900120',
        NAME: 'Avon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59966480,
        AWATER: 1134933,
        INTPTLAT: '+41.7871585',
        INTPTLON: '-072.8520463',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8402, 41.5691],
            [-72.8142, 41.6457],
            [-72.7504, 41.6502],
            [-72.7153, 41.6527],
            [-72.7139, 41.6277],
            [-72.7145, 41.6043],
            [-72.7522, 41.5789],
            [-72.8402, 41.5691],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900210',
        NAME: 'Berlin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68210069,
        AWATER: 1682133,
        INTPTLAT: '+41.6054768',
        INTPTLON: '-072.7784592',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.406, 41.4282],
            [-73.379, 41.4205],
            [-73.3477, 41.3502],
            [-73.4243, 41.3353],
            [-73.406, 41.4282],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900270',
        NAME: 'Bethel School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 43742347,
        AWATER: 100790,
        INTPTLAT: '+41.3697777',
        INTPTLON: '-073.3895032',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7986, 41.8249],
            [-72.7606, 41.9057],
            [-72.7378, 41.8924],
            [-72.6861, 41.8029],
            [-72.7176, 41.8066],
            [-72.7972, 41.8047],
            [-72.7986, 41.8249],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900330',
        NAME: 'Bloomfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67562257,
        AWATER: 430687,
        INTPTLAT: '+41.8449305',
        INTPTLON: '-072.7409509',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4189, 41.8066],
            [-72.4103, 41.7655],
            [-72.4228, 41.732],
            [-72.4601, 41.7273],
            [-72.4639, 41.7456],
            [-72.4735, 41.7983],
            [-72.4189, 41.8066],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900360',
        NAME: 'Bolton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37314557,
        AWATER: 766251,
        INTPTLAT: '+41.7645891',
        INTPTLON: '-072.4375753',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8238, 41.3183],
            [-72.7461, 41.3106],
            [-72.7352, 41.2521],
            [-72.8603, 41.2442],
            [-72.8238, 41.3183],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900420',
        NAME: 'Branford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56564590,
        AWATER: 15933670,
        INTPTLAT: '+41.2799516',
        INTPTLON: '-072.7859518',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1576, 41.2284],
            [-73.1658, 41.1543],
            [-73.2326, 41.1425],
            [-73.2442, 41.2266],
            [-73.1576, 41.2284],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900450',
        NAME: 'Bridgeport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41591056,
        AWATER: 8721419,
        INTPTLAT: '+41.1873933',
        INTPTLON: '-073.1957567',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9985, 41.7125],
            [-72.8983, 41.7232],
            [-72.8929, 41.6967],
            [-72.8834, 41.6495],
            [-72.9385, 41.6443],
            [-72.9832, 41.6396],
            [-72.9985, 41.7125],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900510',
        NAME: 'Bristol School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 68392863,
        AWATER: 1046762,
        INTPTLAT: '+41.6815776',
        INTPTLON: '-072.9407487',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4415, 41.4913],
            [-73.387, 41.5148],
            [-73.3316, 41.4727],
            [-73.379, 41.4205],
            [-73.406, 41.4282],
            [-73.4269, 41.4638],
            [-73.4415, 41.4913],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900540',
        NAME: 'Brookfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51211652,
        AWATER: 1543916,
        INTPTLAT: '+41.4698845',
        INTPTLON: '-073.3936666',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9406, 41.8952],
            [-72.9059, 41.9217],
            [-72.8766, 41.9208],
            [-72.8702, 41.8161],
            [-72.9212, 41.8071],
            [-72.949, 41.8064],
            [-72.9406, 41.8952],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900690',
        NAME: 'Canton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 63676553,
        AWATER: 1137667,
        INTPTLAT: '+41.8587259',
        INTPTLON: '-072.9108619',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9568, 41.5553],
            [-72.9463, 41.5568],
            [-72.8527, 41.5463],
            [-72.8508, 41.5165],
            [-72.8867, 41.448],
            [-72.948, 41.4641],
            [-72.9524, 41.4646],
            [-72.9694, 41.5302],
            [-72.9568, 41.5553],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900750',
        NAME: 'Cheshire School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 85674797,
        AWATER: 765695,
        INTPTLAT: '+41.5118273',
        INTPTLON: '-072.9036174',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5857, 41.3099],
            [-72.512, 41.3416],
            [-72.4834, 41.2623],
            [-72.5359, 41.2546],
            [-72.5857, 41.3099],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900810',
        NAME: 'Clinton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41975454,
        AWATER: 7283328,
        INTPTLAT: '+41.2951299',
        INTPTLON: '-072.5300394',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4667, 41.5839],
            [-72.4106, 41.6018],
            [-72.3245, 41.6186],
            [-72.2191, 41.5388],
            [-72.3229, 41.5181],
            [-72.4308, 41.5246],
            [-72.4667, 41.5839],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900840',
        NAME: 'Colchester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 126865607,
        AWATER: 1865056,
        INTPTLAT: '+41.5572706',
        INTPTLON: '-072.3442470',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4215, 41.8255],
            [-72.3097, 41.8378],
            [-72.3101, 41.8361],
            [-72.2526, 41.7282],
            [-72.2466, 41.7229],
            [-72.3289, 41.7388],
            [-72.4103, 41.7655],
            [-72.4189, 41.8066],
            [-72.4215, 41.8255],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900960',
        NAME: 'Coventry School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97308018,
        AWATER: 1744459,
        INTPTLAT: '+41.7801380',
        INTPTLON: '-072.3439434',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7139, 41.6277],
            [-72.6278, 41.6402],
            [-72.6305, 41.6333],
            [-72.6488, 41.5752],
            [-72.7145, 41.6043],
            [-72.7139, 41.6277],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '900990',
        NAME: 'Cromwell School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32251181,
        AWATER: 1291345,
        INTPTLAT: '+41.6166566',
        INTPTLON: '-072.6615859',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5371, 41.4397],
            [-73.4269, 41.4638],
            [-73.406, 41.4282],
            [-73.4243, 41.3353],
            [-73.4704, 41.3269],
            [-73.5436, 41.3751],
            [-73.5371, 41.4397],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901020',
        NAME: 'Danbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108636759,
        AWATER: 5813522,
        INTPTLAT: '+41.3987408',
        INTPTLON: '-073.4781032',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5008, 41.1144],
            [-73.4746, 41.1147],
            [-73.4565, 41.0204],
            [-73.4565, 40.9838],
            [-73.4961, 40.9755],
            [-73.5008, 41.1144],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901050',
        NAME: 'Darien School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32774485,
        AWATER: 28026885,
        INTPTLAT: '+41.0521498',
        INTPTLON: '-073.4797138',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1024, 41.3529],
            [-73.0391, 41.3267],
            [-73.0407, 41.3139],
            [-73.0679, 41.301],
            [-73.1247, 41.3453],
            [-73.1024, 41.3529],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901110',
        NAME: 'Derby School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13094126,
        AWATER: 906368,
        INTPTLAT: '+41.3261239',
        INTPTLON: '-073.0825739',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7629, 41.9859],
            [-72.6876, 41.9481],
            [-72.6959, 41.9224],
            [-72.7378, 41.8924],
            [-72.7606, 41.9057],
            [-72.7854, 41.918],
            [-72.7629, 41.9859],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901170',
        NAME: 'East Granby School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45491075,
        AWATER: 261880,
        INTPTLAT: '+41.9297190',
        INTPTLON: '-072.7453784',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4804, 41.5195],
            [-72.4308, 41.5246],
            [-72.3229, 41.5181],
            [-72.3055, 41.4363],
            [-72.4337, 41.424],
            [-72.451, 41.4337],
            [-72.4804, 41.5195],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901200',
        NAME: 'East Haddam School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140516010,
        AWATER: 6033781,
        INTPTLAT: '+41.4766614',
        INTPTLON: '-072.3921201',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5442, 41.6427],
            [-72.5054, 41.647],
            [-72.4667, 41.5839],
            [-72.4308, 41.5246],
            [-72.4804, 41.5195],
            [-72.5549, 41.511],
            [-72.5608, 41.554],
            [-72.5442, 41.6427],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901230',
        NAME: 'East Hampton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92333261,
        AWATER: 3227660,
        INTPTLAT: '+41.5677366',
        INTPTLON: '-072.5095441',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.646, 41.7976],
            [-72.5835, 41.8006],
            [-72.5712, 41.7338],
            [-72.6363, 41.7249],
            [-72.6478, 41.7279],
            [-72.646, 41.7976],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901260',
        NAME: 'East Hartford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46610797,
        AWATER: 1904156,
        INTPTLAT: '+41.7606953',
        INTPTLON: '-072.6076462',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8996, 41.2477],
            [-72.8603, 41.3366],
            [-72.817, 41.3639],
            [-72.8238, 41.3183],
            [-72.8603, 41.2442],
            [-72.8996, 41.2477],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901290',
        NAME: 'East Haven School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31846046,
        AWATER: 3116162,
        INTPTLAT: '+41.2934528',
        INTPTLON: '-072.8589937',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2766, 41.4404],
            [-72.242, 41.4432],
            [-72.2193, 41.4121],
            [-72.1845, 41.276],
            [-72.2485, 41.2759],
            [-72.2766, 41.4404],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901320',
        NAME: 'East Lyme School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88116424,
        AWATER: 20647824,
        INTPTLAT: '+41.3563451',
        INTPTLON: '-072.2301735',
        ELSDLEA: '',
        UNSDLEA: '01320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6234, 41.9343],
            [-72.5133, 41.9454],
            [-72.5173, 41.8699],
            [-72.6193, 41.8683],
            [-72.6217, 41.9046],
            [-72.6234, 41.9343],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901350',
        NAME: 'East Windsor School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67989003,
        AWATER: 1466611,
        INTPTLAT: '+41.9040094',
        INTPTLON: '-072.5644729',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5173, 41.8699],
            [-72.5133, 41.9454],
            [-72.4927, 41.9475],
            [-72.4067, 41.9572],
            [-72.3068, 41.9406],
            [-72.3069, 41.9296],
            [-72.428, 41.8746],
            [-72.4968, 41.8606],
            [-72.5173, 41.8699],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901440',
        NAME: 'Ellington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88215282,
        AWATER: 1380866,
        INTPTLAT: '+41.9141491',
        INTPTLON: '-072.4455676',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6234, 41.9343],
            [-72.614, 41.9435],
            [-72.6069, 42.025],
            [-72.5316, 42.034],
            [-72.5092, 42.0341],
            [-72.4927, 41.9475],
            [-72.5133, 41.9454],
            [-72.6234, 41.9343],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901470',
        NAME: 'Enfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86175199,
        AWATER: 2442455,
        INTPTLAT: '+41.9839930',
        INTPTLON: '-072.5555531',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.329, 41.2134],
            [-73.2486, 41.232],
            [-73.2442, 41.2266],
            [-73.2326, 41.1425],
            [-73.2977, 41.1238],
            [-73.3385, 41.1945],
            [-73.329, 41.2134],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901530',
        NAME: 'Fairfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 77447722,
        AWATER: 3827261,
        INTPTLAT: '+41.1759399',
        INTPTLON: '-073.2720460',
        ELSDLEA: '',
        UNSDLEA: '01530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9085, 41.776],
            [-72.7967, 41.7588],
            [-72.7622, 41.7183],
            [-72.7616, 41.7133],
            [-72.8154, 41.6878],
            [-72.8929, 41.6967],
            [-72.8983, 41.7232],
            [-72.9085, 41.776],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901560',
        NAME: 'Farmington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72704776,
        AWATER: 1937236,
        INTPTLAT: '+41.7304396',
        INTPTLON: '-072.8429010',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6363, 41.7249],
            [-72.5712, 41.7338],
            [-72.4639, 41.7456],
            [-72.4601, 41.7273],
            [-72.4522, 41.6888],
            [-72.5054, 41.647],
            [-72.5442, 41.6427],
            [-72.6305, 41.6333],
            [-72.6278, 41.6402],
            [-72.6157, 41.6881],
            [-72.6363, 41.7249],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901620',
        NAME: 'Glastonbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 132788239,
        AWATER: 2376020,
        INTPTLAT: '+41.6918834',
        INTPTLON: '-072.5521735',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8871, 41.9731],
            [-72.8636, 42.0377],
            [-72.7747, 42.0022],
            [-72.7629, 41.9859],
            [-72.7854, 41.918],
            [-72.8766, 41.9208],
            [-72.9059, 41.9217],
            [-72.8871, 41.9731],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901680',
        NAME: 'Granby School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105355858,
        AWATER: 350302,
        INTPTLAT: '+41.9714802',
        INTPTLON: '-072.8301588',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6339, 41.144],
            [-73.5683, 41.0597],
            [-73.5537, 40.9635],
            [-73.5622, 40.9617],
            [-73.6129, 40.9509],
            [-73.6576, 40.9855],
            [-73.6667, 41.0255],
            [-73.7063, 41.0741],
            [-73.6339, 41.144],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901710',
        NAME: 'Greenwich School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 123574542,
        AWATER: 50755176,
        INTPTLAT: '+41.0453658',
        INTPTLON: '-073.6340183',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9753, 41.6381],
            [-71.955, 41.6348],
            [-71.8844, 41.6412],
            [-71.8634, 41.5149],
            [-71.9029, 41.5203],
            [-71.9892, 41.5656],
            [-71.9753, 41.6381],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901740',
        NAME: 'Griswold School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89897142,
        AWATER: 6104029,
        INTPTLAT: '+41.5847725',
        INTPTLON: '-071.9210907',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0958, 41.4004],
            [-71.9613, 41.3979],
            [-71.9791, 41.3],
            [-72.0501, 41.2766],
            [-72.0551, 41.2766],
            [-72.0929, 41.387],
            [-72.0958, 41.4004],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901770',
        NAME: 'Groton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80545640,
        AWATER: 36735037,
        INTPTLAT: '+41.3477177',
        INTPTLON: '-072.0313446',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7206, 41.4274],
            [-72.6787, 41.4338],
            [-72.6545, 41.2657],
            [-72.7352, 41.2521],
            [-72.7461, 41.3106],
            [-72.7206, 41.4274],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901800',
        NAME: 'Guilford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121946607,
        AWATER: 6845068,
        INTPTLAT: '+41.3390267',
        INTPTLON: '-072.7060885',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9549, 41.3945],
            [-72.948, 41.4641],
            [-72.8867, 41.448],
            [-72.8514, 41.4344],
            [-72.8888, 41.3326],
            [-72.9729, 41.3474],
            [-72.9549, 41.3945],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901860',
        NAME: 'Hamden School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84544680,
        AWATER: 1735227,
        INTPTLAT: '+41.3973285',
        INTPTLON: '-072.9222256',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7176, 41.8066],
            [-72.6861, 41.8029],
            [-72.6456, 41.8058],
            [-72.646, 41.7976],
            [-72.6478, 41.7279],
            [-72.7059, 41.7243],
            [-72.7146, 41.7236],
            [-72.7176, 41.8066],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '901920',
        NAME: 'Hartford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45016120,
        AWATER: 1748078,
        INTPTLAT: '+41.7659331',
        INTPTLON: '-072.6815789',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9174, 41.8692],
            [-71.7968, 41.9044],
            [-71.7947, 41.8544],
            [-71.7913, 41.7745],
            [-71.8387, 41.7688],
            [-71.9172, 41.7597],
            [-71.9062, 41.8268],
            [-71.9174, 41.8692],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902070',
        NAME: 'Killingly School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 125232036,
        AWATER: 3912896,
        INTPTLAT: '+41.8299020',
        INTPTLON: '-071.8490975',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3342, 41.6436],
            [-72.2396, 41.7142],
            [-72.1575, 41.6576],
            [-72.194, 41.5904],
            [-72.2191, 41.5388],
            [-72.3245, 41.6186],
            [-72.3342, 41.6436],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902130',
        NAME: 'Lebanon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140113252,
        AWATER: 2986571,
        INTPTLAT: '+41.6329550',
        INTPTLON: '-072.2400896',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.07, 41.4636],
            [-71.9566, 41.4874],
            [-71.9433, 41.4336],
            [-71.9613, 41.3979],
            [-72.0958, 41.4004],
            [-72.0956, 41.4253],
            [-72.07, 41.4636],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902160',
        NAME: 'Ledyard School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98986800,
        AWATER: 4617664,
        INTPTLAT: '+41.4311315',
        INTPTLON: '-072.0203029',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2018, 41.79],
            [-73.1198, 41.7756],
            [-73.0684, 41.7071],
            [-73.1414, 41.6727],
            [-73.2755, 41.6952],
            [-73.2943, 41.7087],
            [-73.2986, 41.779],
            [-73.2018, 41.79],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902220',
        NAME: 'Litchfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 145294838,
        AWATER: 1832522,
        INTPTLAT: '+41.7409368',
        INTPTLON: '-073.1927510',
        ELSDLEA: '',
        UNSDLEA: '02220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6787, 41.4338],
            [-72.6537, 41.4381],
            [-72.5857, 41.3099],
            [-72.5359, 41.2546],
            [-72.6545, 41.2657],
            [-72.6787, 41.4338],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902280',
        NAME: 'Madison School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93622105,
        AWATER: 1251401,
        INTPTLAT: '+41.3444810',
        INTPTLON: '-072.6245213',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5835, 41.8006],
            [-72.5054, 41.8203],
            [-72.4735, 41.7983],
            [-72.4639, 41.7456],
            [-72.5712, 41.7338],
            [-72.5835, 41.8006],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902310',
        NAME: 'Manchester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 70972793,
        AWATER: 720300,
        INTPTLAT: '+41.7752402',
        INTPTLON: '-072.5244340',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8527, 41.5463],
            [-72.8402, 41.5691],
            [-72.7522, 41.5789],
            [-72.7489, 41.5443],
            [-72.7447, 41.4977],
            [-72.8508, 41.5165],
            [-72.8527, 41.5463],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902400',
        NAME: 'Meriden School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61623149,
        AWATER: 940406,
        INTPTLAT: '+41.5367771',
        INTPTLON: '-072.7945433',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7145, 41.6043],
            [-72.6488, 41.5752],
            [-72.5608, 41.554],
            [-72.5549, 41.511],
            [-72.6337, 41.5002],
            [-72.7489, 41.5443],
            [-72.7522, 41.5789],
            [-72.7145, 41.6043],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902490',
        NAME: 'Middletown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 106235847,
        AWATER: 3488231,
        INTPTLAT: '+41.5486107',
        INTPTLON: '-072.6567838',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0868, 41.2546],
            [-73.0869, 41.2766],
            [-72.9921, 41.2449],
            [-72.9867, 41.2354],
            [-73.1078, 41.1686],
            [-73.0868, 41.2546],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902520',
        NAME: 'Milford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57437876,
        AWATER: 10222854,
        INTPTLAT: '+41.2250861',
        INTPTLON: '-073.0611101',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1833, 41.3917],
            [-73.1571, 41.3767],
            [-73.2048, 41.2994],
            [-73.2744, 41.2938],
            [-73.3059, 41.3323],
            [-73.1833, 41.3917],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902550',
        NAME: 'Monroe School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67522882,
        AWATER: 519193,
        INTPTLAT: '+41.3392358',
        INTPTLON: '-073.2228275',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.242, 41.4432],
            [-72.2276, 41.5187],
            [-72.1354, 41.504],
            [-72.081, 41.4921],
            [-72.07, 41.4636],
            [-72.0956, 41.4253],
            [-72.2193, 41.4121],
            [-72.242, 41.4432],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902580',
        NAME: 'Montville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 108660909,
        AWATER: 5787929,
        INTPTLAT: '+41.4638999',
        INTPTLON: '-072.1555701',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0701, 41.5143],
            [-73.0182, 41.5198],
            [-73.0018, 41.4712],
            [-73.0258, 41.4596],
            [-73.0774, 41.4652],
            [-73.1042, 41.4894],
            [-73.0701, 41.5143],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902640',
        NAME: 'Naugatuck School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42219854,
        AWATER: 240894,
        INTPTLAT: '+41.4884782',
        INTPTLON: '-073.0519199',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8154, 41.6878],
            [-72.7616, 41.7133],
            [-72.7504, 41.6502],
            [-72.8142, 41.6457],
            [-72.8226, 41.6557],
            [-72.8154, 41.6878],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902670',
        NAME: 'New Britain School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34588218,
        AWATER: 188305,
        INTPTLAT: '+41.6765545',
        INTPTLON: '-072.7861613',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5128, 41.1994],
            [-73.4849, 41.2117],
            [-73.4489, 41.1538],
            [-73.4746, 41.1147],
            [-73.5008, 41.1144],
            [-73.5548, 41.1799],
            [-73.5128, 41.1994],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902700',
        NAME: 'New Canaan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 57484086,
        AWATER: 851635,
        INTPTLAT: '+41.1603930',
        INTPTLON: '-073.5001238',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.531, 41.5172],
            [-73.5307, 41.5202],
            [-73.4506, 41.5351],
            [-73.4415, 41.4913],
            [-73.4269, 41.4638],
            [-73.5371, 41.4397],
            [-73.531, 41.5172],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902730',
        NAME: 'New Fairfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52946507,
        AWATER: 12202531,
        INTPTLAT: '+41.4803182',
        INTPTLON: '-073.4868629',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9729, 41.3474],
            [-72.8888, 41.3326],
            [-72.8603, 41.3366],
            [-72.8996, 41.2477],
            [-72.9385, 41.281],
            [-72.9891, 41.3133],
            [-72.998, 41.3141],
            [-72.9729, 41.3474],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902790',
        NAME: 'New Haven School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 48407373,
        AWATER: 3739487,
        INTPTLAT: '+41.3108088',
        INTPTLON: '-072.9249530',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.0929, 41.387],
            [-72.0551, 41.2766],
            [-72.0891, 41.2765],
            [-72.0929, 41.387],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902820',
        NAME: 'New London School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14558135,
        AWATER: 12911245,
        INTPTLAT: '+41.3329572',
        INTPTLON: '-072.0961765',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5069, 41.6658],
            [-73.3821, 41.6833],
            [-73.3431, 41.5659],
            [-73.387, 41.5148],
            [-73.4415, 41.4913],
            [-73.4506, 41.5351],
            [-73.5069, 41.6658],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902850',
        NAME: 'New Milford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 159476241,
        AWATER: 5587410,
        INTPTLAT: '+41.6046401',
        INTPTLON: '-073.4211295',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7622, 41.7183],
            [-72.7146, 41.7236],
            [-72.7059, 41.7243],
            [-72.706, 41.6688],
            [-72.7153, 41.6527],
            [-72.7504, 41.6502],
            [-72.7616, 41.7133],
            [-72.7622, 41.7183],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902880',
        NAME: 'Newington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34034088,
        AWATER: 0,
        INTPTLAT: '+41.6869934',
        INTPTLON: '-072.7308386',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.379, 41.4205],
            [-73.3316, 41.4727],
            [-73.3105, 41.4688],
            [-73.2077, 41.4205],
            [-73.1833, 41.3917],
            [-73.3059, 41.3323],
            [-73.3252, 41.3223],
            [-73.3477, 41.3502],
            [-73.379, 41.4205],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902910',
        NAME: 'Newtown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 149345309,
        AWATER: 3639482,
        INTPTLAT: '+41.3994806',
        INTPTLON: '-073.2939682',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.817, 41.3639],
            [-72.8177, 41.3994],
            [-72.7461, 41.4233],
            [-72.7206, 41.4274],
            [-72.7461, 41.3106],
            [-72.8238, 41.3183],
            [-72.817, 41.3639],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '902970',
        NAME: 'North Branford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64123373,
        AWATER: 4939189,
        INTPTLAT: '+41.3638391',
        INTPTLON: '-072.7848682',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8514, 41.4344],
            [-72.8177, 41.3994],
            [-72.817, 41.3639],
            [-72.8603, 41.3366],
            [-72.8888, 41.3326],
            [-72.8514, 41.4344],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903030',
        NAME: 'North Haven School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53974696,
        AWATER: 714453,
        INTPTLAT: '+41.3814591',
        INTPTLON: '-072.8563689',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9029, 41.5203],
            [-71.8634, 41.5149],
            [-71.7932, 41.5057],
            [-71.7977, 41.4169],
            [-71.8425, 41.4087],
            [-71.9433, 41.4336],
            [-71.9566, 41.4874],
            [-71.9029, 41.5203],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903060',
        NAME: 'North Stonington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140501850,
        AWATER: 1868223,
        INTPTLAT: '+41.4709693',
        INTPTLON: '-071.8725254',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4746, 41.1147],
            [-73.4489, 41.1538],
            [-73.3871, 41.1705],
            [-73.3838, 41.0288],
            [-73.4565, 41.0204],
            [-73.4746, 41.1147],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903090',
        NAME: 'Norwalk School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59275297,
        AWATER: 34928497,
        INTPTLAT: '+41.0927388',
        INTPTLON: '-073.4197955',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.135, 41.5695],
            [-72.1041, 41.6008],
            [-72.0479, 41.5948],
            [-72.0461, 41.556],
            [-72.081, 41.4921],
            [-72.1354, 41.504],
            [-72.135, 41.5695],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903120',
        NAME: 'Norwich School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72680184,
        AWATER: 3483834,
        INTPTLAT: '+41.5478603',
        INTPTLON: '-072.0894880',
        ELSDLEA: '',
        UNSDLEA: '03120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4255, 41.3242],
            [-72.3691, 41.3423],
            [-72.3335, 41.2722],
            [-72.4182, 41.2579],
            [-72.4255, 41.3242],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903180',
        NAME: 'Old Saybrook School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39003139,
        AWATER: 16915074,
        INTPTLAT: '+41.2935043',
        INTPTLON: '-072.3835936',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2077, 41.4205],
            [-73.1042, 41.4894],
            [-73.0774, 41.4652],
            [-73.0836, 41.4038],
            [-73.1476, 41.3677],
            [-73.1571, 41.3767],
            [-73.1833, 41.3917],
            [-73.2077, 41.4205],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903240',
        NAME: 'Oxford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84803121,
        AWATER: 1531057,
        INTPTLAT: '+41.4440006',
        INTPTLON: '-073.1479992',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9139, 41.7423],
            [-71.9172, 41.7597],
            [-71.8387, 41.7688],
            [-71.8579, 41.6442],
            [-71.8844, 41.6412],
            [-71.955, 41.6348],
            [-71.9139, 41.7423],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903270',
        NAME: 'Plainfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109787814,
        AWATER: 1536598,
        INTPTLAT: '+41.6974568',
        INTPTLON: '-071.8968484',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8929, 41.6967],
            [-72.8154, 41.6878],
            [-72.8226, 41.6557],
            [-72.8834, 41.6495],
            [-72.8929, 41.6967],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903300',
        NAME: 'Plainville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25150142,
        AWATER: 181509,
        INTPTLAT: '+41.6739923',
        INTPTLON: '-072.8544338',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0553, 41.7082],
            [-72.9985, 41.7125],
            [-72.9832, 41.6396],
            [-73.0186, 41.6141],
            [-73.0504, 41.6097],
            [-73.0553, 41.7082],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903330',
        NAME: 'Plymouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56639055,
        AWATER: 1211420,
        INTPTLAT: '+41.6663915',
        INTPTLON: '-073.0265164',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6305, 41.6333],
            [-72.5442, 41.6427],
            [-72.5608, 41.554],
            [-72.6488, 41.5752],
            [-72.6305, 41.6333],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903390',
        NAME: 'Portland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60476329,
        AWATER: 3896302,
        INTPTLAT: '+41.5988341',
        INTPTLON: '-072.5890710',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9318, 41.9316],
            [-71.7979, 41.9324],
            [-71.7978, 41.9286],
            [-71.7968, 41.9044],
            [-71.9174, 41.8692],
            [-71.9338, 41.9169],
            [-71.9318, 41.9316],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903480',
        NAME: 'Putnam School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52588042,
        AWATER: 282511,
        INTPTLAT: '+41.9080271',
        INTPTLON: '-071.8704519',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-73.1414, 41.6727],
              [-73.1415, 41.6727],
              [-73.1708, 41.6692],
              [-73.2614, 41.6646],
              [-73.2755, 41.6952],
              [-73.1414, 41.6727],
            ],
          ],
          [
            [
              [-73.3869, 41.781],
              [-73.3014, 41.779],
              [-73.263, 41.9162],
              [-73.165, 41.9132],
              [-73.1707, 41.8943],
              [-73.2018, 41.79],
              [-73.2986, 41.779],
              [-73.2943, 41.7087],
              [-73.3788, 41.6994],
              [-73.3869, 41.781],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903515',
        NAME: 'Regional School District 06',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226070221,
        AWATER: 10795541,
        INTPTLAT: '+41.7841909',
        INTPTLON: '-073.2633746',
        ELSDLEA: '',
        UNSDLEA: '03515',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1198, 41.7756],
            [-73.0767, 41.7985],
            [-72.949, 41.8064],
            [-72.9212, 41.8071],
            [-72.9085, 41.776],
            [-72.8983, 41.7232],
            [-72.9985, 41.7125],
            [-73.0553, 41.7082],
            [-73.0684, 41.7071],
            [-73.1198, 41.7756],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903520',
        NAME: 'Regional School District 10',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 156798550,
        AWATER: 2621649,
        INTPTLAT: '+41.7455672',
        INTPTLON: '-073.0090755',
        ELSDLEA: '',
        UNSDLEA: '03520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3821, 41.6833],
            [-73.3788, 41.6994],
            [-73.2943, 41.7087],
            [-73.2755, 41.6952],
            [-73.2614, 41.6646],
            [-73.2523, 41.5121],
            [-73.3105, 41.4688],
            [-73.3316, 41.4727],
            [-73.387, 41.5148],
            [-73.3431, 41.5659],
            [-73.3821, 41.6833],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903530',
        NAME: 'Regional School District 12',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209186764,
        AWATER: 3990474,
        INTPTLAT: '+41.5967270',
        INTPTLON: '-073.3126713',
        ELSDLEA: '',
        UNSDLEA: '03530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7489, 41.5443],
            [-72.6337, 41.5002],
            [-72.6537, 41.4381],
            [-72.6787, 41.4338],
            [-72.7206, 41.4274],
            [-72.7461, 41.4233],
            [-72.7447, 41.4977],
            [-72.7489, 41.5443],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903535',
        NAME: 'Regional School District 13',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 94036566,
        AWATER: 1977021,
        INTPTLAT: '+41.4814597',
        INTPTLON: '-072.7038074',
        ELSDLEA: '',
        UNSDLEA: '03535',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2614, 41.6646],
            [-73.1708, 41.6692],
            [-73.145, 41.5578],
            [-73.2523, 41.5121],
            [-73.2614, 41.6646],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903536',
        NAME: 'Regional School District 14',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144453472,
        AWATER: 1373070,
        INTPTLAT: '+41.5811196',
        INTPTLON: '-073.2156526',
        ELSDLEA: '',
        UNSDLEA: '03536',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2523, 41.5121],
            [-73.145, 41.5578],
            [-73.0939, 41.5702],
            [-73.0701, 41.5143],
            [-73.1042, 41.4894],
            [-73.2077, 41.4205],
            [-73.3105, 41.4688],
            [-73.2523, 41.5121],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903537',
        NAME: 'Regional School District 15',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146976739,
        AWATER: 4575805,
        INTPTLAT: '+41.4919338',
        INTPTLON: '-073.1938387',
        ELSDLEA: '',
        UNSDLEA: '03537',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-73.0182, 41.5198],
              [-72.9694, 41.5302],
              [-72.9524, 41.4646],
              [-73.0018, 41.4712],
              [-73.0182, 41.5198],
            ],
          ],
          [
            [
              [-73.0774, 41.4652],
              [-73.0258, 41.4596],
              [-73.0417, 41.4209],
              [-73.0836, 41.4038],
              [-73.0774, 41.4652],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903538',
        NAME: 'Regional School District 16',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 61953674,
        AWATER: 928115,
        INTPTLAT: '+41.5084337',
        INTPTLON: '-072.9709463',
        ELSDLEA: '',
        UNSDLEA: '03538',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6537, 41.4381],
            [-72.6337, 41.5002],
            [-72.5549, 41.511],
            [-72.4804, 41.5195],
            [-72.451, 41.4337],
            [-72.5241, 41.3762],
            [-72.5139, 41.3464],
            [-72.512, 41.3416],
            [-72.5857, 41.3099],
            [-72.6537, 41.4381],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903539',
        NAME: 'Regional School District 17',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205302633,
        AWATER: 7245067,
        INTPTLAT: '+41.4300230',
        INTPTLON: '-072.5595966',
        ELSDLEA: '',
        UNSDLEA: '03539',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3055, 41.4363],
            [-72.2766, 41.4404],
            [-72.2485, 41.2759],
            [-72.3335, 41.2722],
            [-72.3691, 41.3423],
            [-72.3826, 41.3825],
            [-72.4257, 41.4008],
            [-72.4337, 41.424],
            [-72.3055, 41.4363],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903540',
        NAME: 'Regional School District 18',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141968967,
        AWATER: 21948593,
        INTPTLAT: '+41.3623864',
        INTPTLON: '-072.3237538',
        ELSDLEA: '',
        UNSDLEA: '03540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.5499, 41.3015],
            [-73.5437, 41.3743],
            [-73.5436, 41.3751],
            [-73.4704, 41.3269],
            [-73.4382, 41.2652],
            [-73.5025, 41.2372],
            [-73.5499, 41.3015],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903810',
        NAME: 'Ridgefield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 89286536,
        AWATER: 1289098,
        INTPTLAT: '+41.3108030',
        INTPTLON: '-073.4998370',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7153, 41.6527],
            [-72.706, 41.6688],
            [-72.6157, 41.6881],
            [-72.6278, 41.6402],
            [-72.7139, 41.6277],
            [-72.7153, 41.6527],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903840',
        NAME: 'Rocky Hill School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34867959,
        AWATER: 997339,
        INTPTLAT: '+41.6566243',
        INTPTLON: '-072.6617787',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0836, 41.4038],
            [-73.0417, 41.4209],
            [-73.0475, 41.3958],
            [-73.0472, 41.3605],
            [-73.1024, 41.3529],
            [-73.1247, 41.3453],
            [-73.1476, 41.3677],
            [-73.0836, 41.4038],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '903990',
        NAME: 'Seymour School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37600679,
        AWATER: 1190093,
        INTPTLAT: '+41.3851224',
        INTPTLON: '-073.0835250',
        ELSDLEA: '',
        UNSDLEA: '03990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1571, 41.3767],
            [-73.1476, 41.3677],
            [-73.1247, 41.3453],
            [-73.0679, 41.301],
            [-73.0869, 41.2766],
            [-73.0868, 41.2546],
            [-73.1469, 41.2554],
            [-73.2048, 41.2994],
            [-73.1571, 41.3767],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904050',
        NAME: 'Shelton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 79320515,
        AWATER: 3307317,
        INTPTLAT: '+41.3045752',
        INTPTLON: '-073.1392176',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8766, 41.9208],
            [-72.7854, 41.918],
            [-72.7606, 41.9057],
            [-72.7986, 41.8249],
            [-72.8702, 41.8161],
            [-72.8766, 41.9208],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904110',
        NAME: 'Simsbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87864364,
        AWATER: 970732,
        INTPTLAT: '+41.8763123',
        INTPTLON: '-072.8292909',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5092, 42.0341],
            [-72.4582, 42.0341],
            [-72.3974, 42.0331],
            [-72.4067, 41.9572],
            [-72.4927, 41.9475],
            [-72.5092, 42.0341],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904140',
        NAME: 'Somers School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73474047,
        AWATER: 304027,
        INTPTLAT: '+41.9926329',
        INTPTLON: '-072.4537254',
        ELSDLEA: '',
        UNSDLEA: '04140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6456, 41.8058],
            [-72.6193, 41.8683],
            [-72.5173, 41.8699],
            [-72.4968, 41.8606],
            [-72.5054, 41.8203],
            [-72.5835, 41.8006],
            [-72.646, 41.7976],
            [-72.6456, 41.8058],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904170',
        NAME: 'South Windsor School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72682373,
        AWATER: 1572283,
        INTPTLAT: '+41.8380902',
        INTPTLON: '-072.5778358',
        ELSDLEA: '',
        UNSDLEA: '04170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9463, 41.5568],
            [-72.9385, 41.6443],
            [-72.8834, 41.6495],
            [-72.8226, 41.6557],
            [-72.8142, 41.6457],
            [-72.8402, 41.5691],
            [-72.8527, 41.5463],
            [-72.9463, 41.5568],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904230',
        NAME: 'Southington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 92987510,
        AWATER: 1893244,
        INTPTLAT: '+41.6068770',
        INTPTLON: '-072.8787842',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4067, 41.9572],
            [-72.3974, 42.0331],
            [-72.367, 42.0327],
            [-72.2707, 42.0317],
            [-72.2051, 42.0311],
            [-72.2349, 41.9576],
            [-72.3068, 41.9406],
            [-72.4067, 41.9572],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904290',
        NAME: 'Stafford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150316547,
        AWATER: 1868958,
        INTPTLAT: '+41.9838788',
        INTPTLON: '-072.3075745',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6288, 41.1464],
            [-73.5548, 41.1799],
            [-73.5008, 41.1144],
            [-73.4961, 40.9755],
            [-73.5278, 40.9689],
            [-73.5537, 40.9635],
            [-73.5683, 41.0597],
            [-73.6339, 41.144],
            [-73.6288, 41.1464],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904320',
        NAME: 'Stamford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 97430572,
        AWATER: 37323740,
        INTPTLAT: '+41.0798566',
        INTPTLON: '-073.5460278',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.9613, 41.3979],
            [-71.9433, 41.4336],
            [-71.8425, 41.4087],
            [-71.8294, 41.3424],
            [-71.9073, 41.3045],
            [-71.9791, 41.3],
            [-71.9613, 41.3979],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904380',
        NAME: 'Stonington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 100118015,
        AWATER: 29600203,
        INTPTLAT: '+41.3635133',
        INTPTLON: '-071.9113673',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1576, 41.2284],
            [-73.1469, 41.2554],
            [-73.0868, 41.2546],
            [-73.1078, 41.1686],
            [-73.1658, 41.1543],
            [-73.1576, 41.2284],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904440',
        NAME: 'Stratford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45291198,
        AWATER: 6294548,
        INTPTLAT: '+41.2056875',
        INTPTLON: '-073.1279855',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7747, 42.0022],
            [-72.7141, 42.0369],
            [-72.6079, 42.0308],
            [-72.6069, 42.025],
            [-72.614, 41.9435],
            [-72.6876, 41.9481],
            [-72.7629, 41.9859],
            [-72.7747, 42.0022],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904470',
        NAME: 'Suffield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 109460411,
        AWATER: 1756912,
        INTPTLAT: '+41.9999149',
        INTPTLON: '-072.6787509',
        ELSDLEA: '',
        UNSDLEA: '04470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1414, 41.6727],
            [-73.0684, 41.7071],
            [-73.0553, 41.7082],
            [-73.0504, 41.6097],
            [-73.058, 41.6067],
            [-73.1415, 41.6727],
            [-73.1414, 41.6727],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904500',
        NAME: 'Thomaston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31049993,
        AWATER: 561788,
        INTPTLAT: '+41.6648900',
        INTPTLON: '-073.0958461',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.963, 42.0262],
            [-71.8833, 42.0243],
            [-71.8047, 42.0235],
            [-71.7992, 42.0081],
            [-71.7979, 41.9324],
            [-71.9318, 41.9316],
            [-71.963, 42.0262],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904530',
        NAME: 'Thompson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 121481274,
        AWATER: 4542520,
        INTPTLAT: '+41.9902867',
        INTPTLON: '-071.8670243',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.3069, 41.9296],
            [-72.3097, 41.8378],
            [-72.4215, 41.8255],
            [-72.428, 41.8746],
            [-72.3069, 41.9296],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904560',
        NAME: 'Tolland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102633514,
        AWATER: 1630211,
        INTPTLAT: '+41.8830194',
        INTPTLON: '-072.3588694',
        ELSDLEA: '',
        UNSDLEA: '04560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1707, 41.8943],
            [-73.0535, 41.8873],
            [-73.0767, 41.7985],
            [-73.1198, 41.7756],
            [-73.2018, 41.79],
            [-73.1707, 41.8943],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904590',
        NAME: 'Torrington School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102974225,
        AWATER: 1449259,
        INTPTLAT: '+41.8340524',
        INTPTLON: '-073.1314132',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.2744, 41.2938],
            [-73.2048, 41.2994],
            [-73.1469, 41.2554],
            [-73.1576, 41.2284],
            [-73.2442, 41.2266],
            [-73.2486, 41.232],
            [-73.2744, 41.2938],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904620',
        NAME: 'Trumbull School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 60189176,
        AWATER: 623554,
        INTPTLAT: '+41.2728914',
        INTPTLON: '-073.2118988',
        ELSDLEA: '',
        UNSDLEA: '04620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5054, 41.8203],
            [-72.4968, 41.8606],
            [-72.428, 41.8746],
            [-72.4215, 41.8255],
            [-72.4189, 41.8066],
            [-72.4735, 41.7983],
            [-72.5054, 41.8203],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904680',
        NAME: 'Vernon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 45840558,
        AWATER: 970592,
        INTPTLAT: '+41.8375605',
        INTPTLON: '-072.4590404',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.8508, 41.5165],
            [-72.7447, 41.4977],
            [-72.7461, 41.4233],
            [-72.8177, 41.3994],
            [-72.8514, 41.4344],
            [-72.8867, 41.448],
            [-72.8508, 41.5165],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904740',
        NAME: 'Wallingford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 101113339,
        AWATER: 2149220,
        INTPTLAT: '+41.4586575',
        INTPTLON: '-072.8045163',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.0939, 41.5702],
            [-73.058, 41.6067],
            [-73.0504, 41.6097],
            [-73.0186, 41.6141],
            [-72.9568, 41.5553],
            [-72.9694, 41.5302],
            [-73.0182, 41.5198],
            [-73.0701, 41.5143],
            [-73.0939, 41.5702],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904830',
        NAME: 'Waterbury School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73880017,
        AWATER: 1086045,
        INTPTLAT: '+41.5585001',
        INTPTLON: '-073.0366845',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2193, 41.4121],
            [-72.0956, 41.4253],
            [-72.0958, 41.4004],
            [-72.0929, 41.387],
            [-72.0891, 41.2765],
            [-72.1845, 41.276],
            [-72.2193, 41.4121],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904860',
        NAME: 'Waterford School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84693961,
        AWATER: 30656490,
        INTPTLAT: '+41.3516858',
        INTPTLON: '-072.1468340',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.1708, 41.6692],
            [-73.1415, 41.6727],
            [-73.058, 41.6067],
            [-73.0939, 41.5702],
            [-73.145, 41.5578],
            [-73.1708, 41.6692],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904890',
        NAME: 'Watertown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 75121154,
        AWATER: 1312297,
        INTPTLAT: '+41.6172455',
        INTPTLON: '-073.1143616',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7972, 41.8047],
            [-72.7176, 41.8066],
            [-72.7146, 41.7236],
            [-72.7622, 41.7183],
            [-72.7967, 41.7588],
            [-72.7972, 41.8047],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904920',
        NAME: 'West Hartford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 56558781,
        AWATER: 1097890,
        INTPTLAT: '+41.7665678',
        INTPTLON: '-072.7549436',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9921, 41.2449],
            [-72.9891, 41.3133],
            [-72.9385, 41.281],
            [-72.9867, 41.2354],
            [-72.9921, 41.2449],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904950',
        NAME: 'West Haven School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27834890,
        AWATER: 439572,
        INTPTLAT: '+41.2721217',
        INTPTLON: '-072.9674977',
        ELSDLEA: '',
        UNSDLEA: '04950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5139, 41.3464],
            [-72.4727, 41.3342],
            [-72.4255, 41.3242],
            [-72.4182, 41.2579],
            [-72.4834, 41.2623],
            [-72.512, 41.3416],
            [-72.5139, 41.3464],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '904980',
        NAME: 'Westbrook School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40843931,
        AWATER: 14954928,
        INTPTLAT: '+41.3036945',
        INTPTLON: '-072.4780169',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4301, 41.2543],
            [-73.3688, 41.275],
            [-73.329, 41.2134],
            [-73.3385, 41.1945],
            [-73.3796, 41.1858],
            [-73.4301, 41.2543],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905010',
        NAME: 'Weston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51211664,
        AWATER: 2333476,
        INTPTLAT: '+41.2274128',
        INTPTLON: '-073.3670614',
        ELSDLEA: '',
        UNSDLEA: '05010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3871, 41.1705],
            [-73.3796, 41.1858],
            [-73.3385, 41.1945],
            [-73.2977, 41.1238],
            [-73.3838, 41.0288],
            [-73.3871, 41.1705],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905040',
        NAME: 'Westport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51698908,
        AWATER: 34946433,
        INTPTLAT: '+41.1208312',
        INTPTLON: '-073.3434638',
        ELSDLEA: '',
        UNSDLEA: '05040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7059, 41.7243],
            [-72.6478, 41.7279],
            [-72.6363, 41.7249],
            [-72.6157, 41.6881],
            [-72.706, 41.6688],
            [-72.7059, 41.7243],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905070',
        NAME: 'Wethersfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31862545,
        AWATER: 2071388,
        INTPTLAT: '+41.7024837',
        INTPTLON: '-072.6693014',
        ELSDLEA: '',
        UNSDLEA: '05070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4382, 41.2652],
            [-73.4301, 41.2543],
            [-73.3796, 41.1858],
            [-73.3871, 41.1705],
            [-73.4489, 41.1538],
            [-73.4849, 41.2117],
            [-73.5025, 41.2372],
            [-73.4382, 41.2652],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905130',
        NAME: 'Wilton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69376752,
        AWATER: 1276100,
        INTPTLAT: '+41.2112775',
        INTPTLON: '-073.4409743',
        ELSDLEA: '',
        UNSDLEA: '05130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.165, 41.9132],
            [-73.1515, 41.9597],
            [-73.034, 41.9663],
            [-73.0535, 41.8873],
            [-73.1707, 41.8943],
            [-73.165, 41.9132],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905160',
        NAME: 'Winchester School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 84206119,
        AWATER: 3393593,
        INTPTLAT: '+41.9270315',
        INTPTLON: '-073.1098569',
        ELSDLEA: '',
        UNSDLEA: '05160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.2526, 41.7282],
            [-72.1563, 41.7535],
            [-72.112, 41.7459],
            [-72.12, 41.6633],
            [-72.1575, 41.6576],
            [-72.2396, 41.7142],
            [-72.2466, 41.7229],
            [-72.2526, 41.7282],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905190',
        NAME: 'Windham School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69306943,
        AWATER: 2375851,
        INTPTLAT: '+41.7144745',
        INTPTLON: '-072.1686670',
        ELSDLEA: '',
        UNSDLEA: '05190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.7378, 41.8924],
            [-72.6959, 41.9224],
            [-72.6217, 41.9046],
            [-72.6193, 41.8683],
            [-72.6456, 41.8058],
            [-72.6861, 41.8029],
            [-72.7378, 41.8924],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905220',
        NAME: 'Windsor School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76414981,
        AWATER: 3777893,
        INTPTLAT: '+41.8710365',
        INTPTLON: '-072.6750825',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.6959, 41.9224],
            [-72.6876, 41.9481],
            [-72.614, 41.9435],
            [-72.6234, 41.9343],
            [-72.6217, 41.9046],
            [-72.6959, 41.9224],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905250',
        NAME: 'Windsor Locks School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23371105,
        AWATER: 885163,
        INTPTLAT: '+41.9277901',
        INTPTLON: '-072.6516158',
        ELSDLEA: '',
        UNSDLEA: '05250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.9832, 41.6396],
            [-72.9385, 41.6443],
            [-72.9463, 41.5568],
            [-72.9568, 41.5553],
            [-73.0186, 41.6141],
            [-72.9832, 41.6396],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '905280',
        NAME: 'Wolcott School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 52925229,
        AWATER: 1725753,
        INTPTLAT: '+41.5947920',
        INTPTLON: '-072.9685135',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4565, 41.0204],
            [-73.3838, 41.0288],
            [-73.2977, 41.1238],
            [-73.2326, 41.1425],
            [-73.1658, 41.1543],
            [-73.1078, 41.1686],
            [-72.9867, 41.2354],
            [-72.9385, 41.281],
            [-72.8996, 41.2477],
            [-72.8603, 41.2442],
            [-72.7352, 41.2521],
            [-72.6545, 41.2657],
            [-72.5359, 41.2546],
            [-72.4834, 41.2623],
            [-72.4182, 41.2579],
            [-72.3335, 41.2722],
            [-72.2485, 41.2759],
            [-72.1845, 41.276],
            [-72.0891, 41.2765],
            [-72.0551, 41.2766],
            [-72.0501, 41.2766],
            [-72.1056, 41.2574],
            [-72.4023, 41.2016],
            [-72.4764, 41.1875],
            [-72.691, 41.1465],
            [-72.6947, 41.1458],
            [-72.8866, 41.109],
            [-72.9103, 41.1044],
            [-72.9793, 41.091],
            [-73.0245, 41.081],
            [-73.0519, 41.0743],
            [-73.1474, 41.0509],
            [-73.1775, 41.0435],
            [-73.2476, 41.0263],
            [-73.2966, 41.0168],
            [-73.4319, 40.989],
            [-73.4565, 40.9838],
            [-73.4565, 41.0204],
          ],
        ],
      },
      properties: {
        STATEFP: '09',
        SCSDLEA: '',
        GEOID: '999997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 0,
        AWATER: 1095331974,
        INTPTLAT: '+41.1666065',
        INTPTLON: '-072.8424645',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
  ],
};

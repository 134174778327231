/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import SectionTitle from '../Components/SectionTitle';
import Wizard from '../Components/Wizard';
import { getMedatada } from '../api/surveys';
import { setWizard, setReplaceData } from '../actions/wizard.action';

const ReplaceDataSet = () => {
  const jwt = useSelector((state) => state.session.jwt) || '';
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [surveyTitle, setSurveyTitle] = useState('...');

  const getMedatadaInfo = async () => {
    const replaceSurvey = await getMedatada(id, jwt);
    if (!replaceSurvey) {
      history.push('/');
    }
    const { title } = replaceSurvey.Data;
    setSurveyTitle(title);
    const parentId = replaceSurvey.Parent;
    const orgId = replaceSurvey.Organization;
    dispatch(setReplaceData(id, parentId, orgId));
  };

  useEffect(() => {
    getMedatadaInfo();
    dispatch(setWizard('replaceDataSet'));
  }, []);

  return (
    <div className="ReplaceDataSet">
      <SectionTitle title={`Replace Survey ${surveyTitle} Data Set`} />
      <Wizard />
    </div>
  );
};

export default ReplaceDataSet;

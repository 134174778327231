import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const CheckboxRow = ({
  variableName,
  name,
  onChangeHandler,
  parent,
  id,
  checked,
  dataName,
}) => (
  <div className="CheckboxRow">
    <div className="CheckboxRow-data">
      <div className="CheckboxRow-checkbox">
        <label className="CheckboxRow-label" htmlFor={`id-${id + name}`}>
          <input
            type="radio"
            name={name}
            id={`id-${id + name}`}
            data-id={id}
            onChange={onChangeHandler}
            checked={checked}
            data-metadataid={parent}
            data-identifier={variableName}
            data-name={dataName}
          />
        </label>
      </div>
      <div className="Checkbox-info">
        <div className="Checkbox-infoTitle">
            Variable name:
          {' '}
          <span className="Checkbox-info-data">{variableName}</span>
        </div>
      </div>
    </div>
  </div>
);

CheckboxRow.propTypes = {
  variableName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  parent: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  dataName: PropTypes.string,
};

CheckboxRow.defaultProps = {
  dataName: null,
};

export default CheckboxRow;

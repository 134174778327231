export const WI = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9595, 43.1924],
            [-87.9249, 43.1921],
            [-87.8926, 43.192],
            [-87.9014, 43.1332],
            [-87.9083, 43.1348],
            [-87.91, 43.1046],
            [-87.9466, 43.1598],
            [-87.9508, 43.163],
            [-87.9595, 43.1924],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '05490',
        GEOID: '5505490',
        NAME: 'Nicolet Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41688891,
        AWATER: 989299,
        INTPTLAT: '+43.1582822',
        INTPTLON: '-087.9223420',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.528, 43.3705],
            [-88.5281, 43.3778],
            [-88.5322, 43.4115],
            [-88.4405, 43.4569],
            [-88.4007, 43.4568],
            [-88.4012, 43.4004],
            [-88.3004, 43.2814],
            [-88.2254, 43.2655],
            [-88.2218, 43.2655],
            [-88.251, 43.2368],
            [-88.257, 43.233],
            [-88.2588, 43.233],
            [-88.2618, 43.231],
            [-88.2723, 43.1932],
            [-88.3001, 43.1931],
            [-88.3106, 43.193],
            [-88.3992, 43.1942],
            [-88.4141, 43.1946],
            [-88.418, 43.1947],
            [-88.461, 43.2572],
            [-88.5495, 43.2394],
            [-88.5667, 43.2407],
            [-88.528, 43.3705],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '06120',
        GEOID: '5506120',
        NAME: 'Hartford Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 463510570,
        AWATER: 5730482,
        INTPTLAT: '+43.3026643',
        INTPTLON: '-088.4041009',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0452, 45.8177],
            [-90.0429, 45.8972],
            [-90.044, 45.9819],
            [-89.9288, 45.982],
            [-89.9285, 46.0688],
            [-89.9288, 46.1127],
            [-89.9288, 46.2428],
            [-89.9292, 46.2997],
            [-89.7407, 46.2636],
            [-89.5547, 46.2279],
            [-89.5546, 46.0689],
            [-89.6164, 45.9825],
            [-89.5702, 45.9823],
            [-89.549, 45.8266],
            [-89.5489, 45.8177],
            [-89.5904, 45.7324],
            [-89.7958, 45.7321],
            [-89.9216, 45.7324],
            [-90.0435, 45.7301],
            [-90.0452, 45.8177],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '09630',
        GEOID: '5509630',
        NAME: 'Lakeland Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1598612793,
        AWATER: 326366196,
        INTPTLAT: '+45.9792573',
        INTPTLON: '-089.7817706',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.418, 43.1947],
            [-88.4141, 43.1946],
            [-88.3992, 43.1942],
            [-88.3106, 43.193],
            [-88.3001, 43.1931],
            [-88.2723, 43.1932],
            [-88.2662, 43.1933],
            [-88.2683, 43.1344],
            [-88.2451, 43.1051],
            [-88.3035, 43.1031],
            [-88.3037, 43.0734],
            [-88.3634, 43.0617],
            [-88.4222, 43.0902],
            [-88.4213, 43.1207],
            [-88.418, 43.1947],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '06180',
        GEOID: '5506180',
        NAME: 'Arrowhead Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 160784176,
        AWATER: 14382926,
        INTPTLAT: '+43.1325835',
        INTPTLON: '-088.3472323',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3069, 42.8216],
            [-88.3066, 42.837],
            [-88.2457, 42.842],
            [-88.2212, 42.8421],
            [-88.1883, 42.8501],
            [-88.1839, 42.8325],
            [-88.13, 42.8089],
            [-88.1298, 42.8104],
            [-88.0699, 42.8433],
            [-88.041, 42.8434],
            [-88.0681, 42.8072],
            [-88.0619, 42.7638],
            [-88.0802, 42.7491],
            [-88.1111, 42.7572],
            [-88.1109, 42.7334],
            [-88.2868, 42.726],
            [-88.3081, 42.7555],
            [-88.3069, 42.8216],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '15600',
        GEOID: '5515600',
        NAME: 'Waterford Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216975436,
        AWATER: 6620114,
        INTPTLAT: '+42.7858566',
        INTPTLON: '-088.1943753',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3053, 42.5527],
            [-88.2468, 42.553],
            [-88.1488, 42.5826],
            [-88.1389, 42.5827],
            [-88.1301, 42.5827],
            [-88.1302, 42.5537],
            [-88.1301, 42.5392],
            [-88.1097, 42.519],
            [-88.1097, 42.4956],
            [-88.1986, 42.4951],
            [-88.1995, 42.4951],
            [-88.2654, 42.4947],
            [-88.28, 42.4948],
            [-88.3047, 42.4946],
            [-88.3053, 42.5527],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '16770',
        GEOID: '5516770',
        NAME: 'Wilmot Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113143303,
        AWATER: 11750472,
        INTPTLAT: '+42.5248930',
        INTPTLON: '-088.2102065',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7711, 42.5653],
            [-88.6995, 42.5458],
            [-88.6536, 42.5575],
            [-88.5651, 42.5865],
            [-88.5617, 42.5632],
            [-88.5407, 42.5485],
            [-88.4824, 42.5638],
            [-88.4469, 42.4947],
            [-88.5502, 42.495],
            [-88.5598, 42.495],
            [-88.6708, 42.4945],
            [-88.7365, 42.4928],
            [-88.7765, 42.4919],
            [-88.7711, 42.5653],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '15480',
        GEOID: '5515480',
        NAME: 'Big Foot Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 190153094,
        AWATER: 282991,
        INTPTLAT: '+42.5292539',
        INTPTLON: '-088.6305416',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1882, 42.6115],
            [-88.1107, 42.6694],
            [-88.0705, 42.6564],
            [-88.027, 42.6696],
            [-87.9531, 42.6696],
            [-87.9529, 42.577],
            [-87.9498, 42.4946],
            [-87.9716, 42.4948],
            [-88.0621, 42.4955],
            [-88.1097, 42.4956],
            [-88.1097, 42.519],
            [-88.1301, 42.5392],
            [-88.1302, 42.5537],
            [-88.1301, 42.5827],
            [-88.1389, 42.5827],
            [-88.1488, 42.5826],
            [-88.2468, 42.553],
            [-88.3053, 42.5527],
            [-88.3054, 42.5671],
            [-88.3058, 42.6035],
            [-88.1882, 42.6115],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '13290',
        GEOID: '5513290',
        NAME: 'Westosha Central High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359831215,
        AWATER: 4124703,
        INTPTLAT: '+42.5896365',
        INTPTLON: '-088.0810770',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1111, 42.7572],
            [-88.0802, 42.7491],
            [-88.0619, 42.7638],
            [-88.0681, 42.8072],
            [-88.041, 42.8434],
            [-87.9813, 42.8436],
            [-87.952, 42.8435],
            [-87.954, 42.7568],
            [-87.9531, 42.6696],
            [-88.027, 42.6696],
            [-88.0705, 42.6564],
            [-88.1107, 42.6694],
            [-88.1109, 42.7334],
            [-88.1111, 42.7572],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '15150',
        GEOID: '5515150',
        NAME: 'Union Grove Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 216242758,
        AWATER: 708243,
        INTPTLAT: '+42.7428744',
        INTPTLON: '-088.0242420',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5309, 42.6123],
            [-88.408, 42.6773],
            [-88.3058, 42.6035],
            [-88.3054, 42.5671],
            [-88.3053, 42.5527],
            [-88.3047, 42.4946],
            [-88.355, 42.4946],
            [-88.4131, 42.4946],
            [-88.4469, 42.4947],
            [-88.4824, 42.5638],
            [-88.4371, 42.5664],
            [-88.4628, 42.5765],
            [-88.5193, 42.5717],
            [-88.5023, 42.5918],
            [-88.4643, 42.605],
            [-88.512, 42.5971],
            [-88.5309, 42.6123],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '07650',
        GEOID: '5507650',
        NAME: 'Lake Geneva-Genoa City Union High School District',
        LSAD: '00',
        LOGRADE: '09',
        HIGRADE: '12',
        MTFCC: 'G5410',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 229885901,
        AWATER: 2572092,
        INTPTLAT: '+42.5714416',
        INTPTLON: '-088.4012429',
        ELSDLEA: '',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4213, 43.1207],
            [-88.3874, 43.1242],
            [-88.3627, 43.1056],
            [-88.3634, 43.0617],
            [-88.4222, 43.0902],
            [-88.4213, 43.1207],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500013',
        NAME: 'Lake Country School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22493899,
        AWATER: 3796141,
        INTPTLAT: '+43.0912488',
        INTPTLON: '-088.3839178',
        ELSDLEA: '00013',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1877, 42.5389],
            [-88.1794, 42.5453],
            [-88.1292, 42.525],
            [-88.1097, 42.519],
            [-88.1097, 42.4956],
            [-88.1986, 42.4951],
            [-88.1877, 42.5389],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500052',
        NAME: 'Trevor-Wilmot Consolidated School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26332978,
        AWATER: 2427811,
        INTPTLAT: '+42.5164333',
        INTPTLON: '-088.1622174',
        ELSDLEA: '00052',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9288, 46.2428],
            [-89.9292, 46.2997],
            [-89.7407, 46.2636],
            [-89.5547, 46.2279],
            [-89.5546, 46.0689],
            [-89.6164, 45.9825],
            [-89.6799, 46.0546],
            [-89.762, 46.0431],
            [-89.9285, 46.0688],
            [-89.9288, 46.1127],
            [-89.9288, 46.2428],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501530',
        NAME: 'North Lakeland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 554398378,
        AWATER: 134514240,
        INTPTLAT: '+46.1574191',
        INTPTLON: '-089.7314842',
        ELSDLEA: '01530',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1107, 42.6694],
            [-88.0747, 42.6697],
            [-88.0705, 42.6564],
            [-88.0706, 42.5827],
            [-88.1301, 42.5827],
            [-88.1389, 42.5827],
            [-88.1488, 42.5826],
            [-88.1882, 42.6115],
            [-88.1107, 42.6694],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501650',
        NAME: 'Brighton No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86714096,
        AWATER: 504038,
        INTPTLAT: '+42.6257033',
        INTPTLON: '-088.1116211',
        ELSDLEA: '01650',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0705, 42.5776],
            [-87.9529, 42.577],
            [-87.9498, 42.4946],
            [-87.9716, 42.4948],
            [-88.0621, 42.4955],
            [-88.0705, 42.5776],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501710',
        NAME: 'Bristol No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87094902,
        AWATER: 602329,
        INTPTLAT: '+42.5360397',
        INTPTLON: '-088.0052847',
        ELSDLEA: '01710',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1111, 42.7572],
            [-88.0802, 42.7491],
            [-88.0714, 42.7167],
            [-88.0747, 42.6697],
            [-88.1107, 42.6694],
            [-88.1109, 42.7334],
            [-88.1111, 42.7572],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503750',
        NAME: 'Dover No. 1 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28714576,
        AWATER: 57788,
        INTPTLAT: '+42.7090016',
        INTPTLON: '-088.1030461',
        ELSDLEA: '03750',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4192, 43.2818],
            [-88.4192, 43.2819],
            [-88.3313, 43.2511],
            [-88.3116, 43.2204],
            [-88.3106, 43.193],
            [-88.3992, 43.1942],
            [-88.4141, 43.1946],
            [-88.4192, 43.2818],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504410',
        NAME: 'Erin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59478573,
        AWATER: 861745,
        INTPTLAT: '+43.2220050',
        INTPTLON: '-088.3546160',
        ELSDLEA: '04410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9285, 46.0688],
            [-89.762, 46.0431],
            [-89.7566, 45.8985],
            [-90.0429, 45.8972],
            [-90.044, 45.9819],
            [-89.9288, 45.982],
            [-89.9285, 46.0688],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504620',
        NAME: 'Lac du Flambeau No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256545547,
        AWATER: 74079838,
        INTPTLAT: '+45.9687732',
        INTPTLON: '-089.8790086',
        ELSDLEA: '04620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5651, 42.5865],
            [-88.5617, 42.5632],
            [-88.5407, 42.5485],
            [-88.5361, 42.5383],
            [-88.6536, 42.5575],
            [-88.5651, 42.5865],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504720',
        NAME: 'Fontana Joint No. 8 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28002951,
        AWATER: 136861,
        INTPTLAT: '+42.5674561',
        INTPTLON: '-088.6007429',
        ELSDLEA: '04720',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9595, 43.1924],
            [-87.9249, 43.1921],
            [-87.9122, 43.155],
            [-87.9466, 43.1598],
            [-87.9508, 43.163],
            [-87.9595, 43.1924],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504770',
        NAME: 'Maple Dale-Indian Hill School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13480046,
        AWATER: 461085,
        INTPTLAT: '+43.1734798',
        INTPTLON: '-087.9338487',
        ELSDLEA: '04770',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9249, 43.1921],
            [-87.8926, 43.192],
            [-87.9014, 43.1332],
            [-87.9083, 43.1348],
            [-87.9122, 43.155],
            [-87.9249, 43.1921],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504800',
        NAME: 'Fox Point Joint No. 2 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13480533,
        AWATER: 0,
        INTPTLAT: '+43.1687796',
        INTPTLON: '-087.9015746',
        ELSDLEA: '04800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5023, 42.5918],
            [-88.4643, 42.605],
            [-88.4628, 42.5765],
            [-88.5193, 42.5717],
            [-88.5023, 42.5918],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505100',
        NAME: 'Geneva Joint No. 4 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10571313,
        AWATER: 0,
        INTPTLAT: '+42.5855927',
        INTPTLON: '-088.4849627',
        ELSDLEA: '05100',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.403, 42.5239],
            [-88.3054, 42.5671],
            [-88.3053, 42.5527],
            [-88.3047, 42.4946],
            [-88.355, 42.4946],
            [-88.4131, 42.4946],
            [-88.403, 42.5239],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505130',
        NAME: 'Genoa City Joint No. 2 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 46541608,
        AWATER: 898563,
        INTPTLAT: '+42.5277201',
        INTPTLON: '-088.3519418',
        ELSDLEA: '05130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9466, 43.1598],
            [-87.9122, 43.155],
            [-87.9083, 43.1348],
            [-87.91, 43.1046],
            [-87.9466, 43.1598],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505460',
        NAME: 'Glendale-River Hills School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14728312,
        AWATER: 528214,
        INTPTLAT: '+43.1270711',
        INTPTLON: '-087.9305517',
        ELSDLEA: '05460',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.4484, 43.3614],
              [-88.4012, 43.4004],
              [-88.3004, 43.2814],
              [-88.3313, 43.2511],
              [-88.4192, 43.2819],
              [-88.4484, 43.3614],
            ],
          ],
          [
            [
              [-88.4479, 43.2822],
              [-88.4192, 43.2818],
              [-88.4141, 43.1946],
              [-88.418, 43.1947],
              [-88.461, 43.2572],
              [-88.4479, 43.2822],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506090',
        NAME: 'Hartford Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 129458121,
        AWATER: 2231177,
        INTPTLAT: '+43.3087472',
        INTPTLON: '-088.3821927',
        ELSDLEA: '06090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3627, 43.1056],
            [-88.308, 43.109],
            [-88.3037, 43.1051],
            [-88.3035, 43.1031],
            [-88.3037, 43.0734],
            [-88.3634, 43.0617],
            [-88.3627, 43.1056],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506140',
        NAME: 'Hartland-Lakeside Joint No. 3 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21768304,
        AWATER: 1827962,
        INTPTLAT: '+43.0861033',
        INTPTLON: '-088.3362365',
        ELSDLEA: '06140',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4405, 43.4569],
            [-88.4007, 43.4568],
            [-88.4012, 43.4004],
            [-88.4484, 43.3614],
            [-88.478, 43.3706],
            [-88.528, 43.3705],
            [-88.5281, 43.3778],
            [-88.5322, 43.4115],
            [-88.4405, 43.4569],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506390',
        NAME: 'Herman No. 22 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 95143380,
        AWATER: 108907,
        INTPTLAT: '+43.4067048',
        INTPTLON: '-088.4701565',
        ELSDLEA: '06390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5309, 42.6123],
            [-88.408, 42.6773],
            [-88.3058, 42.6035],
            [-88.3054, 42.5671],
            [-88.403, 42.5239],
            [-88.4371, 42.5664],
            [-88.4628, 42.5765],
            [-88.4643, 42.605],
            [-88.512, 42.5971],
            [-88.5309, 42.6123],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507620',
        NAME: 'Lake Geneva Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 136309974,
        AWATER: 1634395,
        INTPTLAT: '+42.6003738',
        INTPTLON: '-088.4018364',
        ELSDLEA: '07620',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4824, 42.5638],
            [-88.4371, 42.5664],
            [-88.403, 42.5239],
            [-88.4131, 42.4946],
            [-88.4469, 42.4947],
            [-88.4824, 42.5638],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508040',
        NAME: 'Linn Joint No. 4 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 36463006,
        AWATER: 39134,
        INTPTLAT: '+42.5341718',
        INTPTLON: '-088.4531197',
        ELSDLEA: '08040',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5361, 42.5383],
            [-88.5407, 42.5485],
            [-88.4824, 42.5638],
            [-88.4469, 42.4947],
            [-88.5502, 42.495],
            [-88.5361, 42.5383],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508070',
        NAME: 'Linn Joint No. 6 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 41149769,
        AWATER: 0,
        INTPTLAT: '+42.5203275',
        INTPTLON: '-088.5035370',
        ELSDLEA: '08070',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3037, 43.1051],
            [-88.2683, 43.1344],
            [-88.2451, 43.1051],
            [-88.3035, 43.1031],
            [-88.3037, 43.1051],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508130',
        NAME: 'Richmond School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17118192,
        AWATER: 63562,
        INTPTLAT: '+43.1125673',
        INTPTLON: '-088.2702870',
        ELSDLEA: '08130',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.372, 43.1369],
            [-88.3516, 43.1417],
            [-88.308, 43.109],
            [-88.3627, 43.1056],
            [-88.3874, 43.1242],
            [-88.372, 43.1369],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509240',
        NAME: 'Swallow School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12332352,
        AWATER: 2574764,
        INTPTLAT: '+43.1240835',
        INTPTLON: '-088.3498073',
        ELSDLEA: '09240',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3992, 43.1942],
            [-88.3106, 43.193],
            [-88.3001, 43.1931],
            [-88.3516, 43.1417],
            [-88.372, 43.1369],
            [-88.3992, 43.1942],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509300',
        NAME: 'North Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 31454960,
        AWATER: 1308573,
        INTPTLAT: '+43.1783671',
        INTPTLON: '-088.3556479',
        ELSDLEA: '09300',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3001, 43.1931],
            [-88.2723, 43.1932],
            [-88.2662, 43.1933],
            [-88.2683, 43.1344],
            [-88.3037, 43.1051],
            [-88.308, 43.109],
            [-88.3516, 43.1417],
            [-88.3001, 43.1931],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509360',
        NAME: 'Merton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 32738332,
        AWATER: 1432917,
        INTPTLAT: '+43.1564781',
        INTPTLON: '-088.2995391',
        ELSDLEA: '09360',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.418, 43.1947],
            [-88.4141, 43.1946],
            [-88.3992, 43.1942],
            [-88.372, 43.1369],
            [-88.3874, 43.1242],
            [-88.4213, 43.1207],
            [-88.418, 43.1947],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509390',
        NAME: 'Stone Bank School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22878137,
        AWATER: 3379007,
        INTPTLAT: '+43.1544354',
        INTPTLON: '-088.4061662',
        ELSDLEA: '09390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0452, 45.8177],
            [-90.0429, 45.8972],
            [-89.7566, 45.8985],
            [-89.6733, 45.8179],
            [-89.5489, 45.8177],
            [-89.5904, 45.7324],
            [-89.7958, 45.7321],
            [-89.9216, 45.7324],
            [-90.0435, 45.7301],
            [-90.0452, 45.8177],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509690',
        NAME: 'Minocqua Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 554780926,
        AWATER: 73908374,
        INTPTLAT: '+45.7912619',
        INTPTLON: '-089.8350514',
        ELSDLEA: '09690',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.528, 43.3705],
            [-88.478, 43.3706],
            [-88.4479, 43.2822],
            [-88.461, 43.2572],
            [-88.5495, 43.2394],
            [-88.5667, 43.2407],
            [-88.528, 43.3705],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510410',
        NAME: 'Neosho Joint No. 3 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 81793541,
        AWATER: 1290762,
        INTPTLAT: '+43.2908940',
        INTPTLON: '-088.5063958',
        ELSDLEA: '10410',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.13, 42.8089],
            [-88.1298, 42.8104],
            [-88.0681, 42.8072],
            [-88.0619, 42.7638],
            [-88.0802, 42.7491],
            [-88.1111, 42.7572],
            [-88.13, 42.8089],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510800',
        NAME: 'Norway Joint No. 7 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 53073915,
        AWATER: 153037,
        INTPTLAT: '+42.7793753',
        INTPTLON: '-088.1247369',
        ELSDLEA: '10800',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0705, 42.6564],
            [-88.027, 42.6696],
            [-87.9531, 42.6696],
            [-87.9529, 42.577],
            [-88.0705, 42.5776],
            [-88.0706, 42.5827],
            [-88.0705, 42.6564],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511400',
        NAME: 'Paris Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 93026530,
        AWATER: 6123,
        INTPTLAT: '+42.6166264',
        INTPTLON: '-088.0132233',
        ELSDLEA: '11400',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3053, 42.5527],
            [-88.2468, 42.553],
            [-88.1877, 42.5389],
            [-88.1986, 42.4951],
            [-88.1995, 42.4951],
            [-88.2654, 42.4947],
            [-88.2357, 42.5311],
            [-88.2755, 42.5309],
            [-88.28, 42.4948],
            [-88.3047, 42.4946],
            [-88.3053, 42.5527],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512390',
        NAME: 'Randall Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 42641006,
        AWATER: 1693653,
        INTPTLAT: '+42.5419156',
        INTPTLON: '-088.2721406',
        ELSDLEA: '12390',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.041, 42.8434],
            [-87.9813, 42.8436],
            [-87.952, 42.8435],
            [-87.954, 42.7568],
            [-88.0619, 42.7638],
            [-88.0681, 42.8072],
            [-88.041, 42.8434],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512480',
        NAME: 'Raymond No. 14 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80375403,
        AWATER: 340856,
        INTPTLAT: '+42.7944816',
        INTPTLON: '-087.9977440',
        ELSDLEA: '12480',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0699, 42.8433],
            [-88.041, 42.8434],
            [-88.0681, 42.8072],
            [-88.1298, 42.8104],
            [-88.0699, 42.8433],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512510',
        NAME: 'North Cape School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21053504,
        AWATER: 37551,
        INTPTLAT: '+42.8184219',
        INTPTLON: '-088.0925319',
        ELSDLEA: '12510',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.257, 43.2369],
              [-88.251, 43.2368],
              [-88.257, 43.233],
              [-88.257, 43.2369],
            ],
          ],
          [
            [
              [-88.3004, 43.2814],
              [-88.2254, 43.2655],
              [-88.257, 43.2369],
              [-88.2588, 43.233],
              [-88.2618, 43.231],
              [-88.3116, 43.2204],
              [-88.3313, 43.2511],
              [-88.3004, 43.2814],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512880',
        NAME: 'Friess Lake School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27199974,
        AWATER: 597758,
        INTPTLAT: '+43.2578204',
        INTPTLON: '-088.2848731',
        ELSDLEA: '12880',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.257, 43.2369],
              [-88.257, 43.233],
              [-88.2588, 43.233],
              [-88.257, 43.2369],
            ],
          ],
          [
            [
              [-88.3116, 43.2204],
              [-88.2618, 43.231],
              [-88.2723, 43.1932],
              [-88.3001, 43.1931],
              [-88.3106, 43.193],
              [-88.3116, 43.2204],
            ],
          ],
          [
            [
              [-88.2254, 43.2655],
              [-88.2218, 43.2655],
              [-88.251, 43.2368],
              [-88.257, 43.2369],
              [-88.2254, 43.2655],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512900',
        NAME: 'Richfield Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37318648,
        AWATER: 436730,
        INTPTLAT: '+43.2104528',
        INTPTLON: '-088.2850391',
        ELSDLEA: '12900',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.478, 43.3706],
            [-88.4484, 43.3614],
            [-88.4192, 43.2819],
            [-88.4192, 43.2818],
            [-88.4479, 43.2822],
            [-88.478, 43.3706],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513200',
        NAME: 'Rubicon Joint No. 6 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33118333,
        AWATER: 203403,
        INTPTLAT: '+43.3174975',
        INTPTLON: '-088.4510467',
        ELSDLEA: '13200',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1389, 42.5827],
            [-88.1301, 42.5827],
            [-88.0706, 42.5827],
            [-88.0705, 42.5776],
            [-88.0621, 42.4955],
            [-88.1097, 42.4956],
            [-88.1097, 42.519],
            [-88.1292, 42.525],
            [-88.1301, 42.5392],
            [-88.1302, 42.5537],
            [-88.1416, 42.5567],
            [-88.1389, 42.5827],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513350',
        NAME: 'Salem School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44187216,
        AWATER: 2585186,
        INTPTLAT: '+42.5404296',
        INTPTLON: '-088.0862173',
        ELSDLEA: '13350',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7711, 42.5653],
            [-88.6995, 42.5458],
            [-88.6708, 42.4945],
            [-88.7365, 42.4928],
            [-88.7765, 42.4919],
            [-88.7711, 42.5653],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513560',
        NAME: 'Sharon Joint No. 11 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51122116,
        AWATER: 0,
        INTPTLAT: '+42.5225972',
        INTPTLON: '-088.7300744',
        ELSDLEA: '13560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1416, 42.5567],
            [-88.1302, 42.5537],
            [-88.1301, 42.5392],
            [-88.1292, 42.525],
            [-88.1794, 42.5453],
            [-88.1416, 42.5567],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513860',
        NAME: 'Silver Lake Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 10297540,
        AWATER: 2864200,
        INTPTLAT: '+42.5428405',
        INTPTLON: '-088.1462921',
        ELSDLEA: '13860',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2654, 42.4947],
            [-88.28, 42.4948],
            [-88.2755, 42.5309],
            [-88.2357, 42.5311],
            [-88.2654, 42.4947],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515090',
        NAME: 'Twin Lakes No. 4 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6788438,
        AWATER: 4064458,
        INTPTLAT: '+42.5277814',
        INTPTLON: '-088.2607767',
        ELSDLEA: '15090',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0714, 42.7167],
            [-88.027, 42.6696],
            [-88.0705, 42.6564],
            [-88.0747, 42.6697],
            [-88.0714, 42.7167],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515180',
        NAME: 'Union Grove Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25271493,
        AWATER: 33375,
        INTPTLAT: '+42.6869300',
        INTPTLON: '-088.0487005',
        ELSDLEA: '15180',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6995, 42.5458],
            [-88.6536, 42.5575],
            [-88.5361, 42.5383],
            [-88.5502, 42.495],
            [-88.5598, 42.495],
            [-88.6708, 42.4945],
            [-88.6995, 42.5458],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515450',
        NAME: 'Walworth Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 69878258,
        AWATER: 146130,
        INTPTLAT: '+42.5185281',
        INTPTLON: '-088.6284094',
        ELSDLEA: '15450',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3069, 42.8216],
            [-88.1839, 42.8325],
            [-88.13, 42.8089],
            [-88.1111, 42.7572],
            [-88.1109, 42.7334],
            [-88.2868, 42.726],
            [-88.3081, 42.7555],
            [-88.3069, 42.8216],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515660',
        NAME: 'Waterford Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120653920,
        AWATER: 5365171,
        INTPTLAT: '+42.7714176',
        INTPTLON: '-088.2260407',
        ELSDLEA: '15660',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3066, 42.837],
            [-88.2457, 42.842],
            [-88.2212, 42.8421],
            [-88.1883, 42.8501],
            [-88.1839, 42.8325],
            [-88.3069, 42.8216],
            [-88.3066, 42.837],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515700',
        NAME: 'Washington-Caldwell School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22194097,
        AWATER: 1064355,
        INTPTLAT: '+42.8240275',
        INTPTLON: '-088.2189641',
        ELSDLEA: '15700',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1882, 42.6115],
            [-88.1488, 42.5826],
            [-88.1389, 42.5827],
            [-88.1416, 42.5567],
            [-88.1794, 42.5453],
            [-88.1877, 42.5389],
            [-88.2468, 42.553],
            [-88.3053, 42.5527],
            [-88.3054, 42.5671],
            [-88.3058, 42.6035],
            [-88.1882, 42.6115],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516560',
        NAME: 'Wheatland Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80780513,
        AWATER: 1127377,
        INTPTLAT: '+42.5799391',
        INTPTLON: '-088.2300479',
        ELSDLEA: '16560',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.762, 46.0431],
            [-89.6799, 46.0546],
            [-89.6164, 45.9825],
            [-89.5702, 45.9823],
            [-89.549, 45.8266],
            [-89.5489, 45.8177],
            [-89.6733, 45.8179],
            [-89.7566, 45.8985],
            [-89.762, 46.0431],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5517160',
        NAME: 'Woodruff Joint No. 1 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232887942,
        AWATER: 43863744,
        INTPTLAT: '+45.9285099',
        INTPTLON: '-089.6670299',
        ELSDLEA: '17160',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0802, 42.7491],
            [-88.0619, 42.7638],
            [-87.954, 42.7568],
            [-87.9531, 42.6696],
            [-88.027, 42.6696],
            [-88.0714, 42.7167],
            [-88.0802, 42.7491],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5517220',
        NAME: 'Yorkville Joint No. 2 School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '08',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 76992585,
        AWATER: 276224,
        INTPTLAT: '+42.7200975',
        INTPTLON: '-088.0013886',
        ELSDLEA: '17220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.261, 42.9273],
            [-90.1545, 42.9166],
            [-90.0149, 42.9176],
            [-89.9947, 42.7888],
            [-90.2056, 42.7619],
            [-90.3086, 42.8141],
            [-90.261, 42.9273],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509660',
        NAME: 'Mineral Point School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 347710120,
        AWATER: 250893,
        INTPTLAT: '+42.8510666',
        INTPTLON: '-090.1497347',
        ELSDLEA: '',
        UNSDLEA: '09660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6223, 42.6699],
            [-90.5439, 42.644],
            [-90.3969, 42.6674],
            [-90.2999, 42.639],
            [-90.3084, 42.6099],
            [-90.3576, 42.6022],
            [-90.3623, 42.6021],
            [-90.4334, 42.5694],
            [-90.5439, 42.5805],
            [-90.6611, 42.5742],
            [-90.6223, 42.6699],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503030',
        NAME: 'Cuba City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204746264,
        AWATER: 258432,
        INTPTLAT: '+42.6209709',
        INTPTLON: '-090.4944397',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-90.2549, 42.6574],
              [-90.2549, 42.6533],
              [-90.2599, 42.6534],
              [-90.2549, 42.6574],
            ],
          ],
          [
            [
              [-90.3478, 42.5253],
              [-90.3426, 42.5253],
              [-90.3427, 42.5212],
              [-90.3478, 42.5253],
            ],
          ],
          [
            [
              [-90.3084, 42.6099],
              [-90.2999, 42.639],
              [-90.2696, 42.6462],
              [-90.2696, 42.6534],
              [-90.2599, 42.6534],
              [-90.2549, 42.6533],
              [-90.2448, 42.646],
              [-90.1183, 42.5809],
              [-90.0738, 42.5229],
              [-89.9807, 42.5064],
              [-90.1871, 42.508],
              [-90.3086, 42.5074],
              [-90.3427, 42.5212],
              [-90.3426, 42.5253],
              [-90.3478, 42.5277],
              [-90.3084, 42.6099],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513830',
        NAME: 'Shullsburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 249685608,
        AWATER: 0,
        INTPTLAT: '+42.5611970',
        INTPTLON: '-090.2049044',
        ELSDLEA: '',
        UNSDLEA: '13830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0651, 44.5097],
            [-90.8012, 44.5962],
            [-90.8017, 44.4585],
            [-90.7643, 44.4223],
            [-90.9447, 44.3778],
            [-91.0439, 44.4359],
            [-91.0651, 44.5097],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500210',
        NAME: 'Alma Center School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 359290768,
        AWATER: 843284,
        INTPTLAT: '+44.4860642',
        INTPTLON: '-090.9093804',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5023, 44.509],
            [-90.3157, 44.5128],
            [-90.1798, 44.5117],
            [-90.1208, 44.5622],
            [-90.024, 44.4817],
            [-90.0654, 44.3945],
            [-90.0726, 44.2925],
            [-90.0567, 44.249],
            [-90.1923, 44.249],
            [-90.318, 44.2487],
            [-90.4374, 44.2919],
            [-90.5583, 44.3352],
            [-90.5587, 44.4222],
            [-90.3976, 44.4233],
            [-90.393, 44.4953],
            [-90.5023, 44.509],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511790',
        NAME: 'Pittsville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 928693437,
        AWATER: 14756690,
        INTPTLAT: '+44.3935064',
        INTPTLON: '-090.2768746',
        ELSDLEA: '',
        UNSDLEA: '11790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9659, 43.3677],
            [-87.8839, 43.3388],
            [-87.903, 43.2804],
            [-87.9642, 43.2797],
            [-87.9659, 43.3677],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505610',
        NAME: 'Grafton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 54813736,
        AWATER: 889155,
        INTPTLAT: '+43.3223155',
        INTPTLON: '-087.9277005',
        ELSDLEA: '',
        UNSDLEA: '05610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1398, 44.513],
            [-88.0556, 44.4648],
            [-88.0571, 44.4626],
            [-88.1398, 44.513],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500540',
        NAME: 'Ashwaubenon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26947958,
        AWATER: 1304471,
        INTPTLAT: '+44.4867358',
        INTPTLON: '-088.0856163',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1905, 44.5868],
            [-88.1311, 44.6769],
            [-88.0449, 44.6774],
            [-87.9937, 44.6775],
            [-88.0295, 44.5574],
            [-88.1052, 44.5424],
            [-88.1901, 44.5844],
            [-88.1905, 44.5868],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506630',
        NAME: 'Howard-Suamico School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 137557751,
        AWATER: 454644,
        INTPTLAT: '+44.6114190',
        INTPTLON: '-088.0746407',
        ELSDLEA: '',
        UNSDLEA: '06630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.116, 43.4601],
            [-89.0768, 43.4722],
            [-88.9554, 43.4522],
            [-88.9548, 43.4173],
            [-89.0203, 43.3713],
            [-89.1599, 43.3697],
            [-89.116, 43.4601],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504530',
        NAME: 'Fall River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122624202,
        AWATER: 2687970,
        INTPTLAT: '+43.4174173',
        INTPTLON: '-089.0554960',
        ELSDLEA: '',
        UNSDLEA: '04530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4118, 43.2941],
            [-89.2597, 43.3589],
            [-89.2446, 43.3592],
            [-89.2362, 43.2823],
            [-89.2441, 43.1957],
            [-89.3061, 43.143],
            [-89.3629, 43.1473],
            [-89.3622, 43.1618],
            [-89.4472, 43.2576],
            [-89.4118, 43.2941],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503180',
        NAME: 'DeForest Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 234935167,
        AWATER: 1713324,
        INTPTLAT: '+43.2454573',
        INTPTLON: '-089.3115659',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5348, 43.4149],
            [-89.4808, 43.4567],
            [-89.3638, 43.4625],
            [-89.3101, 43.4557],
            [-89.2597, 43.3589],
            [-89.4118, 43.2941],
            [-89.5348, 43.4149],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512120',
        NAME: 'Poynette School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 243128869,
        AWATER: 5569271,
        INTPTLAT: '+43.3906335',
        INTPTLON: '-089.3921479',
        ELSDLEA: '',
        UNSDLEA: '12120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0572, 43.5596],
            [-89.0368, 43.633],
            [-88.9991, 43.6222],
            [-88.9971, 43.6223],
            [-88.9942, 43.6208],
            [-88.9544, 43.5408],
            [-88.8861, 43.5207],
            [-88.9554, 43.4522],
            [-89.0768, 43.4722],
            [-89.0773, 43.4794],
            [-89.0572, 43.5596],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512420',
        NAME: 'Randolph School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 153352432,
        AWATER: 25249640,
        INTPTLAT: '+43.5243864',
        INTPTLON: '-088.9934228',
        ELSDLEA: '',
        UNSDLEA: '12420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6007, 43.3805],
            [-89.5348, 43.4149],
            [-89.4118, 43.2941],
            [-89.4472, 43.2576],
            [-89.5703, 43.2024],
            [-89.5911, 43.2023],
            [-89.6301, 43.3587],
            [-89.6007, 43.3805],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508190',
        NAME: 'Lodi School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 237040865,
        AWATER: 18552544,
        INTPTLAT: '+43.3088880',
        INTPTLON: '-089.5315929',
        ELSDLEA: '',
        UNSDLEA: '08190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3101, 43.4557],
            [-89.3151, 43.4665],
            [-89.2129, 43.494],
            [-89.116, 43.4601],
            [-89.1599, 43.3697],
            [-89.2274, 43.3589],
            [-89.2446, 43.3592],
            [-89.2597, 43.3589],
            [-89.3101, 43.4557],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512990',
        NAME: 'Rio Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194400774,
        AWATER: 3640096,
        INTPTLAT: '+43.4305977',
        INTPTLON: '-089.2124999',
        ELSDLEA: '',
        UNSDLEA: '12990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0633, 43.2789],
            [-87.9642, 43.2797],
            [-87.903, 43.2804],
            [-87.8926, 43.192],
            [-87.9249, 43.1921],
            [-87.9595, 43.1924],
            [-87.9943, 43.1925],
            [-88.0634, 43.1921],
            [-88.0633, 43.2789],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509130',
        NAME: 'Mequon-Thiensville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 122924319,
        AWATER: 1826665,
        INTPTLAT: '+43.2349194',
        INTPTLON: '-087.9826589',
        ELSDLEA: '',
        UNSDLEA: '09130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9044, 44.1584],
            [-90.6531, 44.1596],
            [-90.6508, 43.9283],
            [-90.598, 43.9051],
            [-90.691, 43.8378],
            [-90.8718, 43.8233],
            [-90.885, 43.8669],
            [-90.9129, 44.0715],
            [-90.9044, 44.1584],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514160',
        NAME: 'Sparta Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 734704734,
        AWATER: 1069113,
        INTPTLAT: '+44.0025924',
        INTPTLON: '-090.7805756',
        ELSDLEA: '',
        UNSDLEA: '14160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.691, 43.8378],
            [-90.598, 43.9051],
            [-90.5518, 43.8708],
            [-90.4338, 43.8661],
            [-90.472, 43.7418],
            [-90.4599, 43.729],
            [-90.46, 43.7259],
            [-90.5735, 43.6523],
            [-90.6707, 43.6818],
            [-90.691, 43.8378],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510770',
        NAME: 'Norwalk-Ontario-Wilton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402306543,
        AWATER: 439127,
        INTPTLAT: '+43.7889785',
        INTPTLON: '-090.5774231',
        ELSDLEA: '',
        UNSDLEA: '10770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6686, 43.4074],
            [-90.5885, 43.4664],
            [-90.4671, 43.4666],
            [-90.452, 43.5171],
            [-90.3309, 43.546],
            [-90.29, 43.4566],
            [-90.3301, 43.3799],
            [-90.2469, 43.2857],
            [-90.2467, 43.2773],
            [-90.2515, 43.2671],
            [-90.2709, 43.1963],
            [-90.331, 43.2309],
            [-90.331, 43.2342],
            [-90.4607, 43.2499],
            [-90.6661, 43.3108],
            [-90.6686, 43.4074],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512960',
        NAME: 'Richland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 677421327,
        AWATER: 1302481,
        INTPTLAT: '+43.3738439',
        INTPTLON: '-090.4656254',
        ELSDLEA: '',
        UNSDLEA: '12960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.4192, 42.508],
              [-89.4192, 42.5008],
              [-89.444, 42.501],
              [-89.4192, 42.508],
            ],
          ],
          [
            [
              [-89.5053, 42.6354],
              [-89.5011, 42.6538],
              [-89.4132, 42.6803],
              [-89.3485, 42.7088],
              [-89.3177, 42.6982],
              [-89.3238, 42.5857],
              [-89.2627, 42.4982],
              [-89.41, 42.5007],
              [-89.5053, 42.6354],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501740',
        NAME: 'Brodhead School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261799985,
        AWATER: 1758624,
        INTPTLAT: '+42.5917732',
        INTPTLON: '-089.3859692',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3692, 42.838],
            [-89.3101, 42.8458],
            [-89.2151, 42.8173],
            [-89.1113, 42.7553],
            [-89.1001, 42.75],
            [-89.1309, 42.7169],
            [-89.3001, 42.7035],
            [-89.3177, 42.6982],
            [-89.3485, 42.7088],
            [-89.3692, 42.838],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504440',
        NAME: 'Evansville Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256838133,
        AWATER: 780655,
        INTPTLAT: '+42.7612331',
        INTPTLON: '-089.2678618',
        ELSDLEA: '',
        UNSDLEA: '04440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9699, 42.8465],
            [-88.8718, 42.8298],
            [-88.7772, 42.6856],
            [-88.7766, 42.6675],
            [-88.9339, 42.6705],
            [-88.9791, 42.7291],
            [-89.1001, 42.75],
            [-89.1113, 42.7553],
            [-88.9699, 42.8465],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509570',
        NAME: 'Milton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301936905,
        AWATER: 3066127,
        INTPTLAT: '+42.7501241',
        INTPTLON: '-088.9257112',
        ELSDLEA: '',
        UNSDLEA: '09570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.298, 42.9687],
            [-89.2203, 43.0268],
            [-89.1796, 43.0428],
            [-89.1343, 43.0105],
            [-89.1028, 42.9341],
            [-89.2151, 42.8173],
            [-89.3101, 42.8458],
            [-89.298, 42.9687],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514550',
        NAME: 'Stoughton Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 276702095,
        AWATER: 16031208,
        INTPTLAT: '+42.9282581',
        INTPTLON: '-089.2152244',
        ELSDLEA: '',
        UNSDLEA: '14550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0033, 42.565],
            [-89.0021, 42.5624],
            [-88.9634, 42.5398],
            [-88.964, 42.5178],
            [-88.9752, 42.5203],
            [-88.9922, 42.5032],
            [-89.0013, 42.5033],
            [-89.0013, 42.496],
            [-89.0116, 42.4961],
            [-89.0708, 42.4963],
            [-89.0033, 42.565],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501050',
        NAME: 'Beloit School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 40920186,
        AWATER: 859586,
        INTPTLAT: '+42.5220465',
        INTPTLON: '-089.0210718',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1028, 42.9341],
            [-88.9844, 42.9165],
            [-88.9699, 42.8465],
            [-89.1113, 42.7553],
            [-89.2151, 42.8173],
            [-89.1028, 42.9341],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504110',
        NAME: 'Edgerton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 219099636,
        AWATER: 8599825,
        INTPTLAT: '+42.8393224',
        INTPTLON: '-089.0929948',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1309, 42.7169],
            [-89.1001, 42.75],
            [-88.9791, 42.7291],
            [-88.9339, 42.6705],
            [-89.0051, 42.587],
            [-89.0052, 42.5841],
            [-89.0112, 42.5842],
            [-89.1102, 42.5846],
            [-89.1309, 42.7169],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507020',
        NAME: 'Janesville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218087898,
        AWATER: 3627647,
        INTPTLAT: '+42.6593765',
        INTPTLON: '-089.0341350',
        ELSDLEA: '',
        UNSDLEA: '07020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0821, 44.4611],
            [-88.991, 44.5684],
            [-88.8903, 44.5752],
            [-88.7975, 44.5027],
            [-88.8598, 44.3815],
            [-88.9812, 44.4029],
            [-89.0821, 44.4611],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508550',
        NAME: 'Manawa School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282288528,
        AWATER: 4822698,
        INTPTLAT: '+44.4865448',
        INTPTLON: '-088.9304077',
        ELSDLEA: '',
        UNSDLEA: '08550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0923, 44.6577],
            [-88.977, 44.6798],
            [-88.8988, 44.7758],
            [-88.8584, 44.7965],
            [-88.7737, 44.7228],
            [-88.8903, 44.5752],
            [-88.991, 44.5684],
            [-89.0923, 44.6577],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508730',
        NAME: 'Marion School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 326776046,
        AWATER: 3085048,
        INTPTLAT: '+44.6709974',
        INTPTLON: '-088.9157333',
        ELSDLEA: '',
        UNSDLEA: '08730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8861, 43.5207],
            [-88.7479, 43.5278],
            [-88.7217, 43.4447],
            [-88.8915, 43.35],
            [-88.9497, 43.4048],
            [-88.9548, 43.4173],
            [-88.9554, 43.4522],
            [-88.8861, 43.5207],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500960',
        NAME: 'Beaver Dam School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 284663872,
        AWATER: 604374,
        INTPTLAT: '+43.4623952',
        INTPTLON: '-088.8425630',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.5708, 43.4115],
              [-88.5322, 43.4115],
              [-88.5281, 43.3778],
              [-88.5419, 43.3779],
              [-88.5459, 43.3852],
              [-88.5518, 43.3889],
              [-88.5518, 43.3925],
              [-88.5615, 43.3998],
              [-88.5708, 43.4115],
            ],
          ],
          [
            [
              [-88.7479, 43.5278],
              [-88.6445, 43.5234],
              [-88.5817, 43.4114],
              [-88.6315, 43.3806],
              [-88.7217, 43.4447],
              [-88.7479, 43.5278],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506570',
        NAME: 'Horicon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133018341,
        AWATER: 5905586,
        INTPTLAT: '+43.4572018',
        INTPTLON: '-088.6526281',
        ELSDLEA: '',
        UNSDLEA: '06570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.6835, 43.2993],
              [-88.6561, 43.2916],
              [-88.6835, 43.2948],
              [-88.6835, 43.2993],
            ],
          ],
          [
            [
              [-88.8915, 43.35],
              [-88.7217, 43.4447],
              [-88.6315, 43.3806],
              [-88.6652, 43.3538],
              [-88.6807, 43.328],
              [-88.6836, 43.3065],
              [-88.8141, 43.2853],
              [-88.8917, 43.3033],
              [-88.8915, 43.35],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507260',
        NAME: 'Dodgeland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215512777,
        AWATER: 2271767,
        INTPTLAT: '+43.3542673',
        INTPTLON: '-088.7601433',
        ELSDLEA: '',
        UNSDLEA: '07260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9844, 42.9165],
            [-88.9339, 42.9717],
            [-88.9348, 43.0007],
            [-88.6602, 42.9378],
            [-88.66, 42.9016],
            [-88.8718, 42.8298],
            [-88.9699, 42.8465],
            [-88.9844, 42.9165],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504740',
        NAME: 'Fort Atkinson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 253010544,
        AWATER: 44291743,
        INTPTLAT: '+42.9123780',
        INTPTLON: '-088.8566825',
        ELSDLEA: '',
        UNSDLEA: '04740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.1987, 44.5865],
              [-88.1905, 44.5868],
              [-88.1901, 44.5844],
              [-88.1987, 44.5865],
            ],
          ],
          [
            [
              [-88.5042, 44.5897],
              [-88.3859, 44.617],
              [-88.1987, 44.5865],
              [-88.1903, 44.5067],
              [-88.2306, 44.4467],
              [-88.4504, 44.3876],
              [-88.4756, 44.3877],
              [-88.4583, 44.4233],
              [-88.5042, 44.5897],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513530',
        NAME: 'Seymour Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 433808792,
        AWATER: 525674,
        INTPTLAT: '+44.5106189',
        INTPTLON: '-088.3563812',
        ELSDLEA: '',
        UNSDLEA: '13530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4279, 44.9809],
            [-90.1976, 44.9924],
            [-90.1292, 44.9453],
            [-90.1296, 44.9309],
            [-90.4342, 44.9442],
            [-90.4279, 44.9809],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500030',
        NAME: 'Abbotsford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107448740,
        AWATER: 81812,
        INTPTLAT: '+44.9675297',
        INTPTLON: '-090.2822683',
        ELSDLEA: '',
        UNSDLEA: '00030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3983, 44.7997],
            [-90.1989, 44.815],
            [-90.1748, 44.7672],
            [-90.2185, 44.6853],
            [-90.3363, 44.6851],
            [-90.3983, 44.7997],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514190',
        NAME: 'Spencer School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 209513460,
        AWATER: 707478,
        INTPTLAT: '+44.7514338',
        INTPTLON: '-090.2830144',
        ELSDLEA: '',
        UNSDLEA: '14190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8012, 44.5962],
            [-90.8009, 44.6525],
            [-90.5711, 44.6836],
            [-90.501, 44.6327],
            [-90.5023, 44.509],
            [-90.393, 44.4953],
            [-90.3976, 44.4233],
            [-90.5587, 44.4222],
            [-90.7643, 44.4223],
            [-90.8017, 44.4585],
            [-90.8012, 44.5962],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510350',
        NAME: 'Neillsville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 717228850,
        AWATER: 7788015,
        INTPTLAT: '+44.5443964',
        INTPTLON: '-090.6327808',
        ELSDLEA: '',
        UNSDLEA: '10350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5645, 42.6829],
            [-89.4671, 42.7771],
            [-89.4378, 42.8227],
            [-89.4087, 42.8137],
            [-89.3692, 42.838],
            [-89.3485, 42.7088],
            [-89.4132, 42.6803],
            [-89.5011, 42.6538],
            [-89.5252, 42.6536],
            [-89.5645, 42.6829],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500120',
        NAME: 'Albany School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 183211136,
        AWATER: 328309,
        INTPTLAT: '+42.7304062',
        INTPTLON: '-089.4380687',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2505, 45.3098],
            [-92.0804, 45.2528],
            [-92.0791, 45.2092],
            [-92.1566, 45.1652],
            [-92.1773, 45.1654],
            [-92.2817, 45.1411],
            [-92.3328, 45.1653],
            [-92.3438, 45.1692],
            [-92.3438, 45.1733],
            [-92.3379, 45.2903],
            [-92.2505, 45.3098],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502610',
        NAME: 'Clear Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277873476,
        AWATER: 1139600,
        INTPTLAT: '+45.2286022',
        INTPTLON: '-092.2301007',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1432, 44.9448],
            [-92.1354, 44.9486],
            [-92.1356, 44.9587],
            [-92.0861, 44.9664],
            [-92.0657, 44.9664],
            [-92.0606, 44.9664],
            [-91.9755, 44.9446],
            [-91.8464, 44.9908],
            [-91.7726, 44.9149],
            [-91.7917, 44.7854],
            [-91.7815, 44.7629],
            [-91.913, 44.6981],
            [-92.0246, 44.7566],
            [-92.0325, 44.8149],
            [-92.1363, 44.8438],
            [-92.1363, 44.853],
            [-92.1363, 44.8578],
            [-92.1432, 44.9448],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509090',
        NAME: 'Menomonie Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 640650367,
        AWATER: 16599312,
        INTPTLAT: '+44.8624999',
        INTPTLON: '-091.9346635',
        ELSDLEA: '',
        UNSDLEA: '09090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.1357, 44.9662],
              [-92.0861, 44.9664],
              [-92.1356, 44.9587],
              [-92.1357, 44.9662],
            ],
          ],
          [
            [
              [-92.1566, 45.1652],
              [-92.0791, 45.2092],
              [-92.0342, 45.1569],
              [-91.9115, 45.1653],
              [-91.8949, 45.0454],
              [-91.8464, 44.9908],
              [-91.9755, 44.9446],
              [-92.0606, 44.9664],
              [-92.0657, 44.9664],
              [-92.0861, 44.9664],
              [-92.1059, 45.1147],
              [-92.1773, 45.1654],
              [-92.1566, 45.1652],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501590',
        NAME: 'Boyceville Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 415173350,
        AWATER: 2031004,
        INTPTLAT: '+45.0732125',
        INTPTLON: '-091.9909561',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0641, 45.4347],
            [-91.9715, 45.431],
            [-91.8683, 45.5167],
            [-91.7656, 45.4452],
            [-91.7878, 45.3242],
            [-91.7889, 45.1942],
            [-91.789, 45.1823],
            [-91.789, 45.1752],
            [-91.9115, 45.1653],
            [-91.8908, 45.2833],
            [-91.9971, 45.3492],
            [-92.0641, 45.4347],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500870',
        NAME: 'Barron Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 466342695,
        AWATER: 1151349,
        INTPTLAT: '+45.3460046',
        INTPTLON: '-091.8706082',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6661, 45.1463],
            [-91.6468, 45.1585],
            [-91.4639, 45.2207],
            [-91.3072, 45.1907],
            [-91.2829, 45.1199],
            [-91.3686, 45.1202],
            [-91.4074, 45.0457],
            [-91.5701, 44.987],
            [-91.6661, 45.1463],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501350',
        NAME: 'Bloomer School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426179630,
        AWATER: 5926623,
        INTPTLAT: '+45.1148716',
        INTPTLON: '-091.4914990',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0334, 45.3239],
            [-91.9971, 45.3492],
            [-91.8908, 45.2833],
            [-91.9115, 45.1653],
            [-92.0342, 45.1569],
            [-92.0791, 45.2092],
            [-92.0804, 45.2528],
            [-92.0334, 45.3239],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512180',
        NAME: 'Prairie Farm School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230154513,
        AWATER: 575112,
        INTPTLAT: '+45.2404244',
        INTPTLON: '-091.9760636',
        ELSDLEA: '',
        UNSDLEA: '12180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9115, 45.1653],
            [-91.789, 45.1752],
            [-91.6976, 45.1508],
            [-91.6661, 45.1463],
            [-91.5701, 44.987],
            [-91.6312, 44.9432],
            [-91.7726, 44.9149],
            [-91.8464, 44.9908],
            [-91.8949, 45.0454],
            [-91.9115, 45.1653],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502790',
        NAME: 'Colfax School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 470542440,
        AWATER: 4431126,
        INTPTLAT: '+45.0619053',
        INTPTLON: '-091.7484734',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1102, 42.5846],
            [-89.0112, 42.5842],
            [-89.0033, 42.565],
            [-89.0708, 42.4963],
            [-89.0795, 42.4963],
            [-89.1206, 42.497],
            [-89.1299, 42.4971],
            [-89.1102, 42.5846],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501080',
        NAME: 'Beloit Turner School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80658533,
        AWATER: 1852386,
        INTPTLAT: '+42.5690095',
        INTPTLON: '-089.0896657',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4938, 42.9286],
            [-89.4856, 42.9324],
            [-89.4855, 42.9434],
            [-89.4466, 42.9794],
            [-89.3286, 43.0263],
            [-89.298, 42.9687],
            [-89.3101, 42.8458],
            [-89.3692, 42.838],
            [-89.4087, 42.8137],
            [-89.4378, 42.8227],
            [-89.4938, 42.9286],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511100',
        NAME: 'Oregon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 220030260,
        AWATER: 1771270,
        INTPTLAT: '+42.9184249',
        INTPTLON: '-089.3917282',
        ELSDLEA: '',
        UNSDLEA: '11100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3177, 42.6982],
            [-89.3001, 42.7035],
            [-89.1309, 42.7169],
            [-89.1102, 42.5846],
            [-89.1299, 42.4971],
            [-89.2627, 42.4982],
            [-89.3238, 42.5857],
            [-89.3177, 42.6982],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511130',
        NAME: 'Parkview School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325683053,
        AWATER: 637541,
        INTPTLAT: '+42.6135073',
        INTPTLON: '-089.2209473',
        ELSDLEA: '',
        UNSDLEA: '11130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5735, 43.6523],
            [-90.46, 43.7259],
            [-90.4599, 43.729],
            [-90.472, 43.7418],
            [-90.3065, 43.6927],
            [-90.3111, 43.554],
            [-90.3309, 43.546],
            [-90.452, 43.5171],
            [-90.5275, 43.5977],
            [-90.5735, 43.6523],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506480',
        NAME: 'Hillsboro School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 366265814,
        AWATER: 304321,
        INTPTLAT: '+43.6331510',
        INTPTLON: '-090.4256914',
        ELSDLEA: '',
        UNSDLEA: '06480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6662, 43.2897],
            [-90.6661, 43.3032],
            [-90.6661, 43.3079],
            [-90.6661, 43.3108],
            [-90.4607, 43.2499],
            [-90.331, 43.2342],
            [-90.331, 43.2309],
            [-90.2709, 43.1963],
            [-90.2707, 43.1193],
            [-90.4128, 43.1184],
            [-90.4924, 43.0751],
            [-90.5298, 43.0751],
            [-90.5391, 43.0751],
            [-90.6346, 43.1509],
            [-90.6662, 43.2897],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510140',
        NAME: 'Riverdale School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 493834987,
        AWATER: 13744501,
        INTPTLAT: '+43.1974365',
        INTPTLON: '-090.4747250',
        ELSDLEA: '',
        UNSDLEA: '10140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4504, 44.3876],
            [-88.2306, 44.4467],
            [-88.1914, 44.3922],
            [-88.2223, 44.3572],
            [-88.3434, 44.3287],
            [-88.4046, 44.3291],
            [-88.4504, 44.3876],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504920',
        NAME: 'Freedom Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 194748525,
        AWATER: 1558,
        INTPTLAT: '+44.3822600',
        INTPTLON: '-088.3073628',
        ELSDLEA: '',
        UNSDLEA: '04920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3435, 44.2832],
            [-88.2933, 44.2749],
            [-88.3435, 44.2772],
            [-88.3435, 44.2832],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508160',
        NAME: 'Little Chute Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6903941,
        AWATER: 725302,
        INTPTLAT: '+44.2845023',
        INTPTLON: '-088.3123466',
        ELSDLEA: '',
        UNSDLEA: '08160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6555, 44.3891],
            [-88.4756, 44.3877],
            [-88.4504, 44.3876],
            [-88.4046, 44.3291],
            [-88.4968, 44.2441],
            [-88.6184, 44.2437],
            [-88.7378, 44.3374],
            [-88.6555, 44.3891],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506600',
        NAME: 'Hortonville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 278922135,
        AWATER: 2169842,
        INTPTLAT: '+44.3264382',
        INTPTLON: '-088.5593175',
        ELSDLEA: '',
        UNSDLEA: '06600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3477, 43.912],
            [-91.2496, 43.9093],
            [-91.1717, 43.8675],
            [-91.1499, 43.7968],
            [-91.0311, 43.7674],
            [-91.0307, 43.7616],
            [-91.1497, 43.6797],
            [-91.2681, 43.7015],
            [-91.2679, 43.7116],
            [-91.2441, 43.7747],
            [-91.3434, 43.9046],
            [-91.3477, 43.912],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507530',
        NAME: 'La Crosse School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214948775,
        AWATER: 51699410,
        INTPTLAT: '+43.7861887',
        INTPTLON: '-091.1942604',
        ELSDLEA: '',
        UNSDLEA: '07530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4252, 43.9843],
            [-91.3221, 43.9845],
            [-91.3075, 44.0649],
            [-91.2083, 44.0726],
            [-91.1116, 43.9709],
            [-91.1796, 43.9033],
            [-91.2496, 43.9093],
            [-91.3477, 43.912],
            [-91.4252, 43.9843],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506540',
        NAME: 'Holmen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 232737071,
        AWATER: 24240442,
        INTPTLAT: '+44.0175084',
        INTPTLON: '-091.2634714',
        ELSDLEA: '',
        UNSDLEA: '06540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1296, 44.9309],
            [-90.1292, 44.9453],
            [-90.0353, 44.9438],
            [-89.9466, 45.0168],
            [-89.9162, 44.9948],
            [-89.8966, 44.83],
            [-89.9116, 44.8154],
            [-89.9271, 44.8079],
            [-89.9272, 44.8113],
            [-89.9272, 44.8154],
            [-89.9421, 44.8153],
            [-90.1266, 44.9097],
            [-90.1296, 44.9309],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504080',
        NAME: 'Edgar School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 210807547,
        AWATER: 35177,
        INTPTLAT: '+44.9119516',
        INTPTLON: '-089.9823589',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6595, 44.8584],
            [-89.6033, 44.9595],
            [-89.4919, 44.9672],
            [-89.4922, 45.0323],
            [-89.3459, 45.0299],
            [-89.3469, 44.9864],
            [-89.3978, 44.8287],
            [-89.6499, 44.8289],
            [-89.6595, 44.8584],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513170',
        NAME: 'D.C. Everest Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402162566,
        AWATER: 4276984,
        INTPTLAT: '+44.9169617',
        INTPTLON: '-089.4718575',
        ELSDLEA: '',
        UNSDLEA: '13170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.426, 45.1191],
            [-89.3453, 45.1187],
            [-89.3459, 45.0299],
            [-89.4922, 45.0323],
            [-89.4919, 44.9672],
            [-89.6033, 44.9595],
            [-89.6595, 44.8584],
            [-89.7274, 44.8578],
            [-89.8033, 44.944],
            [-89.8445, 45.0617],
            [-89.8445, 45.1201],
            [-89.426, 45.1191],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515900',
        NAME: 'Wausau School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 660487622,
        AWATER: 9229642,
        INTPTLAT: '+45.0236521',
        INTPTLON: '-089.6303479',
        ELSDLEA: '',
        UNSDLEA: '15900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0321, 43.976],
            [-90.9276, 44.0181],
            [-90.9129, 44.0715],
            [-90.885, 43.8669],
            [-90.9739, 43.7728],
            [-90.9788, 43.7971],
            [-90.979, 43.8079],
            [-90.9872, 43.8122],
            [-91.0047, 43.8369],
            [-91.0321, 43.976],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500780',
        NAME: 'Bangor School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 238249602,
        AWATER: 3689,
        INTPTLAT: '+43.9106561',
        INTPTLON: '-090.9615730',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1525, 44.1706],
            [-91.0329, 44.2144],
            [-90.9044, 44.1584],
            [-90.9129, 44.0715],
            [-90.9276, 44.0181],
            [-91.0321, 43.976],
            [-91.1116, 43.9709],
            [-91.2083, 44.0726],
            [-91.1525, 44.1706],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509000',
        NAME: 'Melrose-Mindoro School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 498719275,
        AWATER: 4694356,
        INTPTLAT: '+44.0930579',
        INTPTLON: '-091.0513985',
        ELSDLEA: '',
        UNSDLEA: '09000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2496, 43.9093],
            [-91.1796, 43.9033],
            [-91.1717, 43.8675],
            [-91.2496, 43.9093],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511010',
        NAME: 'Onalaska School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34169153,
        AWATER: 2029823,
        INTPTLAT: '+43.8863010',
        INTPTLON: '-091.1968897',
        ELSDLEA: '',
        UNSDLEA: '11010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1497, 43.6797],
            [-91.0307, 43.7616],
            [-91.0006, 43.7507],
            [-91.0104, 43.7467],
            [-90.845, 43.7353],
            [-90.79, 43.6752],
            [-90.6907, 43.6605],
            [-90.7604, 43.5812],
            [-91.0671, 43.6449],
            [-91.1478, 43.5936],
            [-91.1497, 43.6797],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516410',
        NAME: 'Westby Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 441182753,
        AWATER: 404936,
        INTPTLAT: '+43.6860499',
        INTPTLON: '-090.9502623',
        ELSDLEA: '',
        UNSDLEA: '16410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.926, 45.3786],
              [-88.8026, 45.3782],
              [-88.8028, 45.2799],
              [-88.9268, 45.3028],
              [-88.926, 45.3786],
            ],
          ],
          [
            [
              [-89.4243, 45.3802],
              [-89.2997, 45.3795],
              [-89.2216, 45.2776],
              [-88.9268, 45.2917],
              [-88.9307, 45.1178],
              [-88.9821, 44.983],
              [-89.1016, 44.9726],
              [-89.3469, 44.9864],
              [-89.3459, 45.0299],
              [-89.3453, 45.1187],
              [-89.426, 45.1191],
              [-89.4243, 45.3802],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500360',
        NAME: 'Antigo School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1392978276,
        AWATER: 8993700,
        INTPTLAT: '+45.1770646',
        INTPTLON: '-089.1643695',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2368, 45.5544],
            [-89.0466, 45.5515],
            [-89.0463, 45.4648],
            [-88.9231, 45.4651],
            [-88.926, 45.3786],
            [-88.9268, 45.3028],
            [-88.9268, 45.2917],
            [-89.2216, 45.2776],
            [-89.2997, 45.3795],
            [-89.3015, 45.4694],
            [-89.4248, 45.4673],
            [-89.4283, 45.5554],
            [-89.2368, 45.5544],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504170',
        NAME: 'Elcho School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 787997901,
        AWATER: 47009299,
        INTPTLAT: '+45.4134913',
        INTPTLON: '-089.1558421',
        ELSDLEA: '',
        UNSDLEA: '04170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9058, 45.0328],
            [-89.8445, 45.0617],
            [-89.8033, 44.944],
            [-89.7274, 44.8578],
            [-89.8966, 44.83],
            [-89.9162, 44.9948],
            [-89.9466, 45.0168],
            [-89.9058, 45.0328],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508670',
        NAME: 'Marathon City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270395038,
        AWATER: 217681,
        INTPTLAT: '+44.9266561',
        INTPTLON: '-089.8350147',
        ELSDLEA: '',
        UNSDLEA: '08670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.9272, 44.8113],
              [-89.9271, 44.8079],
              [-89.9372, 44.8079],
              [-89.9272, 44.8113],
            ],
          ],
          [
            [
              [-90.1266, 44.9097],
              [-89.9421, 44.8153],
              [-89.9372, 44.8079],
              [-89.9263, 44.7136],
              [-89.9467, 44.7135],
              [-90.0446, 44.7058],
              [-90.0655, 44.6998],
              [-90.1748, 44.7672],
              [-90.1989, 44.815],
              [-90.1266, 44.9097],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514580',
        NAME: 'Stratford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 294301540,
        AWATER: 5299857,
        INTPTLAT: '+44.8121607',
        INTPTLON: '-090.0706065',
        ELSDLEA: '',
        UNSDLEA: '14580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3673, 44.6501],
            [-91.3241, 44.6794],
            [-91.2614, 44.6834],
            [-91.1656, 44.597],
            [-91.0436, 44.5967],
            [-90.9828, 44.6832],
            [-90.9834, 44.7708],
            [-90.9215, 44.7704],
            [-90.8004, 44.7708],
            [-90.8009, 44.6525],
            [-90.8012, 44.5962],
            [-91.0651, 44.5097],
            [-91.2735, 44.4724],
            [-91.3172, 44.5048],
            [-91.3673, 44.6501],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511220',
        NAME: 'Osseo-Fairchild School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 755504068,
        AWATER: 1949920,
        INTPTLAT: '+44.6953574',
        INTPTLON: '-090.9464997',
        ELSDLEA: '',
        UNSDLEA: '11220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.358, 44.2908],
            [-91.3581, 44.3129],
            [-91.1871, 44.3295],
            [-91.1648, 44.4188],
            [-91.0841, 44.4103],
            [-91.0265, 44.2885],
            [-91.0329, 44.2144],
            [-91.1525, 44.1706],
            [-91.3636, 44.2075],
            [-91.358, 44.2908],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500016',
        NAME: 'Blair-Taylor School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 461635863,
        AWATER: 325618,
        INTPTLAT: '+44.2900447',
        INTPTLON: '-091.1728190',
        ELSDLEA: '',
        UNSDLEA: '00016',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7308, 44.3192],
            [-91.7005, 44.3494],
            [-91.6903, 44.3633],
            [-91.6572, 44.4066],
            [-91.6456, 44.4066],
            [-91.6411, 44.4029],
            [-91.6297, 44.4029],
            [-91.6145, 44.41],
            [-91.5904, 44.4221],
            [-91.5953, 44.4003],
            [-91.5827, 44.3803],
            [-91.5827, 44.3745],
            [-91.5784, 44.3703],
            [-91.491, 44.3106],
            [-91.358, 44.2908],
            [-91.3636, 44.2075],
            [-91.4823, 44.1515],
            [-91.5404, 44.0882],
            [-91.601, 44.211],
            [-91.7155, 44.2836],
            [-91.7308, 44.3192],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500420',
        NAME: 'Arcadia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 522695547,
        AWATER: 799651,
        INTPTLAT: '+44.2485820',
        INTPTLON: '-091.5310028',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5289, 44.513],
            [-91.5291, 44.4724],
            [-91.5904, 44.4221],
            [-91.6145, 44.41],
            [-91.6297, 44.4029],
            [-91.6411, 44.4029],
            [-91.6456, 44.4066],
            [-91.6572, 44.4066],
            [-91.7415, 44.4029],
            [-91.8267, 44.4227],
            [-91.7716, 44.5096],
            [-91.5289, 44.513],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505370',
        NAME: 'Gilmanton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245568104,
        AWATER: 1708266,
        INTPTLAT: '+44.4499507',
        INTPTLON: '-091.6695091',
        ELSDLEA: '',
        UNSDLEA: '05370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3172, 44.5048],
            [-91.2735, 44.4724],
            [-91.0651, 44.5097],
            [-91.0439, 44.4359],
            [-91.0841, 44.4103],
            [-91.1648, 44.4188],
            [-91.1871, 44.3295],
            [-91.3581, 44.3129],
            [-91.3883, 44.4966],
            [-91.3172, 44.5048],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516650',
        NAME: 'Whitehall School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 356915314,
        AWATER: 113164,
        INTPTLAT: '+44.4217159',
        INTPTLON: '-091.2435468',
        ELSDLEA: '',
        UNSDLEA: '16650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1746, 45.6386],
            [-91.0499, 45.6389],
            [-91.0496, 45.4924],
            [-91.0128, 45.4301],
            [-91.0852, 45.3779],
            [-91.1736, 45.3781],
            [-91.1746, 45.6386],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507590',
        NAME: 'Ladysmith School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290006990,
        AWATER: 4014168,
        INTPTLAT: '+45.5190649',
        INTPTLON: '-091.1263162',
        ELSDLEA: '',
        UNSDLEA: '07590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6787, 45.3778],
            [-90.6473, 45.3776],
            [-90.5555, 45.3774],
            [-90.1863, 45.3815],
            [-90.1658, 45.4345],
            [-90.0425, 45.4339],
            [-90.0423, 45.3814],
            [-90.0464, 45.2515],
            [-90.1678, 45.2948],
            [-90.5565, 45.2909],
            [-90.6793, 45.2912],
            [-90.6787, 45.3778],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512780',
        NAME: 'Rib Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 584515408,
        AWATER: 9071806,
        INTPTLAT: '+45.3312755',
        INTPTLON: '-090.3281018',
        ELSDLEA: '',
        UNSDLEA: '12780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3091, 45.2016],
            [-87.9649, 45.1725],
            [-87.8976, 45.124],
            [-87.9029, 44.9932],
            [-87.9125, 44.9931],
            [-88.1212, 45.0236],
            [-88.2414, 45.062],
            [-88.3126, 45.1138],
            [-88.3091, 45.2016],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502760',
        NAME: 'Coleman School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 484932004,
        AWATER: 9401403,
        INTPTLAT: '+45.1066289',
        INTPTLON: '-088.0907156',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8186, 45.25],
            [-87.71, 45.2485],
            [-87.7418, 45.1971],
            [-87.6612, 45.1091],
            [-87.5948, 45.0946],
            [-87.6565, 44.9813],
            [-87.7096, 45.0844],
            [-87.8685, 45.1318],
            [-87.8186, 45.25],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508700',
        NAME: 'Marinette School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 251062915,
        AWATER: 5495178,
        INTPTLAT: '+45.1202464',
        INTPTLON: '-087.7351895',
        ELSDLEA: '',
        UNSDLEA: '08700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4261, 45.5493],
            [-88.2412, 45.5463],
            [-87.8034, 45.5383],
            [-87.8129, 45.4643],
            [-87.8849, 45.3628],
            [-87.6478, 45.3406],
            [-87.7078, 45.2586],
            [-87.71, 45.2485],
            [-87.8186, 45.25],
            [-87.8158, 45.3194],
            [-88.0604, 45.3389],
            [-88.0599, 45.3689],
            [-88.3058, 45.3746],
            [-88.4281, 45.377],
            [-88.4261, 45.5493],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515930',
        NAME: 'Wausaukee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1074296247,
        AWATER: 17100649,
        INTPTLAT: '+45.4493837',
        INTPTLON: '-088.0665283',
        ELSDLEA: '',
        UNSDLEA: '15930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1858, 43.096],
            [-88.0935, 43.1047],
            [-88.0775, 43.1047],
            [-88.0661, 43.0984],
            [-88.067, 43.0309],
            [-88.0777, 43.007],
            [-88.1515, 43.0171],
            [-88.1859, 43.068],
            [-88.1858, 43.096],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501770',
        NAME: 'Elmbrook School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 82815915,
        AWATER: 1071500,
        INTPTLAT: '+43.0624067',
        INTPTLON: '-088.1199460',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1822, 43.1926],
            [-88.0634, 43.1921],
            [-88.0656, 43.1191],
            [-88.0775, 43.1047],
            [-88.0935, 43.1047],
            [-88.1822, 43.1926],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509060',
        NAME: 'Menomonee Falls School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 58242506,
        AWATER: 496346,
        INTPTLAT: '+43.1605832',
        INTPTLON: '-088.1103353',
        ELSDLEA: '',
        UNSDLEA: '09060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.0656, 43.1191],
              [-88.066, 43.1042],
              [-88.0661, 43.0984],
              [-88.0775, 43.1047],
              [-88.0656, 43.1191],
            ],
          ],
          [
            [
              [-88.2662, 43.1933],
              [-88.1822, 43.1926],
              [-88.0935, 43.1047],
              [-88.1858, 43.096],
              [-88.2451, 43.1051],
              [-88.2683, 43.1344],
              [-88.2662, 43.1933],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506030',
        NAME: 'Hamilton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96969719,
        AWATER: 1248366,
        INTPTLAT: '+43.1397395',
        INTPTLON: '-088.1944022',
        ELSDLEA: '',
        UNSDLEA: '06030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4662, 42.9303],
            [-88.3463, 42.9377],
            [-88.189, 42.9302],
            [-88.1883, 42.8501],
            [-88.2212, 42.8421],
            [-88.2266, 42.8571],
            [-88.2464, 42.8565],
            [-88.2457, 42.842],
            [-88.3066, 42.837],
            [-88.4635, 42.8426],
            [-88.4662, 42.9303],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510060',
        NAME: 'Mukwonago School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 215066006,
        AWATER: 8671629,
        INTPTLAT: '+42.9032103',
        INTPTLON: '-088.3274432',
        ELSDLEA: '',
        UNSDLEA: '10060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5495, 43.2394],
            [-88.461, 43.2572],
            [-88.418, 43.1947],
            [-88.4213, 43.1207],
            [-88.4222, 43.0902],
            [-88.5416, 42.9887],
            [-88.5613, 42.9887],
            [-88.5709, 43.0323],
            [-88.6291, 43.1295],
            [-88.5495, 43.2394],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510890',
        NAME: 'Oconomowoc Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287903435,
        AWATER: 22856508,
        INTPTLAT: '+43.1381712',
        INTPTLON: '-088.5124366',
        ELSDLEA: '',
        UNSDLEA: '10890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0777, 43.007],
            [-88.067, 43.0309],
            [-88.0472, 43.031],
            [-88.0376, 42.981],
            [-88.0689, 42.9737],
            [-88.0777, 43.007],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516260',
        NAME: 'West Allis School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35870121,
        AWATER: 61582,
        INTPTLAT: '+43.0050788',
        INTPTLON: '-088.0290259',
        ELSDLEA: '',
        UNSDLEA: '16260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.4428, 45.0765],
            [-87.4057, 45.202],
            [-87.1958, 45.3334],
            [-87.1278, 45.4095],
            [-87.1172, 45.4233],
            [-86.9523, 45.2908],
            [-86.8677, 45.2834],
            [-87.0001, 45.1241],
            [-87.0164, 45.0255],
            [-87.4632, 45.0278],
            [-87.4428, 45.0765],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505220',
        NAME: 'Gibraltar Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 351573833,
        AWATER: 818784453,
        INTPTLAT: '+45.1788481',
        INTPTLON: '-087.1627096',
        ELSDLEA: '',
        UNSDLEA: '05220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9307, 45.1178],
            [-88.6412, 45.1173],
            [-88.4837, 45.1169],
            [-88.4884, 44.9435],
            [-88.4891, 44.8554],
            [-88.7138, 44.8565],
            [-88.7355, 44.9432],
            [-88.8592, 44.9429],
            [-88.9821, 44.983],
            [-88.9307, 45.1178],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509070',
        NAME: 'Menominee Indian School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 926201113,
        AWATER: 19038372,
        INTPTLAT: '+44.9913041',
        INTPTLON: '-088.6692512',
        ELSDLEA: '',
        UNSDLEA: '09070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2531, 45.3806],
            [-92.0334, 45.3239],
            [-92.0804, 45.2528],
            [-92.2505, 45.3098],
            [-92.2531, 45.3806],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502580',
        NAME: 'Clayton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 147419353,
        AWATER: 2240341,
        INTPTLAT: '+45.3226069',
        INTPTLON: '-092.1637383',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.155, 45.5535],
            [-92.155, 45.6795],
            [-92.0314, 45.6399],
            [-91.847, 45.6389],
            [-91.9082, 45.6101],
            [-91.8683, 45.5167],
            [-91.9715, 45.431],
            [-92.0641, 45.4347],
            [-92.155, 45.5535],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503090',
        NAME: 'Cumberland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 425075574,
        AWATER: 21103414,
        INTPTLAT: '+45.5628076',
        INTPTLON: '-092.0243885',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1568, 42.9882],
            [-91.0432, 43.0043],
            [-90.9006, 43.0679],
            [-90.8162, 43.018],
            [-90.7846, 42.9449],
            [-90.9027, 42.7955],
            [-91.0789, 42.815],
            [-91.1439, 42.9207],
            [-91.1568, 42.9882],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500017',
        NAME: 'River Ridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 544312565,
        AWATER: 21502951,
        INTPTLAT: '+42.9250370',
        INTPTLON: '-090.9591289',
        ELSDLEA: '',
        UNSDLEA: '00017',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0789, 42.815],
            [-90.9027, 42.7955],
            [-90.7957, 42.763],
            [-90.807, 42.7479],
            [-90.8069, 42.7452],
            [-90.8282, 42.6608],
            [-90.897, 42.6751],
            [-91.0513, 42.737],
            [-91.0789, 42.815],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502400',
        NAME: 'Cassville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 242839898,
        AWATER: 19189048,
        INTPTLAT: '+42.7509334',
        INTPTLON: '-090.9229845',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7846, 42.9449],
            [-90.5581, 42.8568],
            [-90.6388, 42.7537],
            [-90.6536, 42.7538],
            [-90.6622, 42.7574],
            [-90.7212, 42.7595],
            [-90.7957, 42.763],
            [-90.9027, 42.7955],
            [-90.7846, 42.9449],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507770',
        NAME: 'Lancaster Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 373136851,
        AWATER: 28413,
        INTPTLAT: '+42.8460660',
        INTPTLON: '-090.7521655',
        ELSDLEA: '',
        UNSDLEA: '07770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7656, 45.4452],
            [-91.5403, 45.4725],
            [-91.5404, 45.4652],
            [-91.5727, 45.3789],
            [-91.6843, 45.3758],
            [-91.7878, 45.3242],
            [-91.7656, 45.4452],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502130',
        NAME: 'Cameron School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 174806530,
        AWATER: 2925443,
        INTPTLAT: '+45.4127006',
        INTPTLON: '-091.6742514',
        ELSDLEA: '',
        UNSDLEA: '02130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.259, 45.4677],
            [-92.2374, 45.5112],
            [-92.218, 45.5253],
            [-92.1965, 45.5537],
            [-92.155, 45.5535],
            [-92.0641, 45.4347],
            [-91.9971, 45.3492],
            [-92.0334, 45.3239],
            [-92.2531, 45.3806],
            [-92.259, 45.4677],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515060',
        NAME: 'Turtle Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277132277,
        AWATER: 14330300,
        INTPTLAT: '+45.4227405',
        INTPTLON: '-092.1440291',
        ELSDLEA: '',
        UNSDLEA: '15060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3592, 45.2917],
            [-91.326, 45.2918],
            [-91.3064, 45.205],
            [-91.3072, 45.1907],
            [-91.4639, 45.2207],
            [-91.6468, 45.1585],
            [-91.6441, 45.1822],
            [-91.6403, 45.2185],
            [-91.4802, 45.2916],
            [-91.3592, 45.2917],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510440',
        NAME: 'New Auburn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 211629333,
        AWATER: 15192315,
        INTPTLAT: '+45.2279253',
        INTPTLON: '-091.4602257',
        ELSDLEA: '',
        UNSDLEA: '10440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4635, 42.8426],
            [-88.3066, 42.837],
            [-88.3069, 42.8216],
            [-88.3081, 42.7555],
            [-88.3854, 42.7062],
            [-88.494, 42.7202],
            [-88.5577, 42.8001],
            [-88.5573, 42.8138],
            [-88.5571, 42.8222],
            [-88.4635, 42.8426],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504020',
        NAME: 'East Troy Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 231597375,
        AWATER: 6610168,
        INTPTLAT: '+42.7880046',
        INTPTLON: '-088.4246852',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0503, 46.3324],
            [-91.6746, 46.3309],
            [-91.5514, 46.3744],
            [-91.5513, 46.157],
            [-91.5507, 46.0705],
            [-91.6742, 46.0704],
            [-92.0504, 46.0712],
            [-92.0503, 46.3324],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509720',
        NAME: 'Northwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1036992727,
        AWATER: 65044340,
        INTPTLAT: '+46.1878934',
        INTPTLON: '-091.7868474',
        ELSDLEA: '',
        UNSDLEA: '09720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0504, 46.0712],
            [-91.6742, 46.0704],
            [-91.6036, 45.9831],
            [-91.6037, 45.8136],
            [-91.5408, 45.7812],
            [-91.6624, 45.7607],
            [-91.8203, 45.6545],
            [-91.9142, 45.7834],
            [-92.1543, 45.816],
            [-92.1553, 45.86],
            [-92.1567, 45.9837],
            [-92.0506, 45.9839],
            [-92.0504, 46.0712],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514220',
        NAME: 'Spooner Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1271117086,
        AWATER: 81151247,
        INTPTLAT: '+45.9064724',
        INTPTLON: '-091.8657901',
        ELSDLEA: '',
        UNSDLEA: '14220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6054, 44.651],
            [-88.5159, 44.6491],
            [-88.5215, 44.7438],
            [-88.5215, 44.7501],
            [-88.4514, 44.8551],
            [-88.3264, 44.8103],
            [-88.3859, 44.617],
            [-88.5042, 44.5897],
            [-88.577, 44.5906],
            [-88.6054, 44.651],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501470',
        NAME: 'Bonduel School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388699809,
        AWATER: 6059629,
        INTPTLAT: '+44.7010428',
        INTPTLON: '-088.4556626',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4824, 43.0945],
            [-89.3839, 43.0813],
            [-89.4103, 43.141],
            [-89.3622, 43.1618],
            [-89.3629, 43.1473],
            [-89.3061, 43.143],
            [-89.2465, 43.0974],
            [-89.2468, 43.077],
            [-89.2567, 43.056],
            [-89.2567, 43.0525],
            [-89.3112, 43.0414],
            [-89.3281, 43.0862],
            [-89.3379, 43.0526],
            [-89.3272, 43.0421],
            [-89.3286, 43.0263],
            [-89.4466, 42.9794],
            [-89.5056, 43.0316],
            [-89.4824, 43.0945],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508520',
        NAME: 'Madison Metropolitan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 176825445,
        AWATER: 3623002,
        INTPTLAT: '+43.0701586',
        INTPTLON: '-089.3855973',
        ELSDLEA: '',
        UNSDLEA: '08520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3061, 43.143],
            [-89.2441, 43.1957],
            [-89.2362, 43.2823],
            [-89.1076, 43.2319],
            [-89.174, 43.109],
            [-89.2465, 43.0974],
            [-89.3061, 43.143],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514640',
        NAME: 'Sun Prairie Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203121159,
        AWATER: 764134,
        INTPTLAT: '+43.1866091',
        INTPTLON: '-089.2069296',
        ELSDLEA: '',
        UNSDLEA: '14640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4472, 43.2576],
            [-89.3622, 43.1618],
            [-89.4103, 43.141],
            [-89.4418, 43.1188],
            [-89.4815, 43.1192],
            [-89.5703, 43.2024],
            [-89.4472, 43.2576],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515810',
        NAME: 'Waunakee Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 141889118,
        AWATER: 2078555,
        INTPTLAT: '+43.1891330',
        INTPTLON: '-089.4560188',
        ELSDLEA: '',
        UNSDLEA: '15810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7251, 44.4144],
            [-87.6843, 44.4724],
            [-87.5424, 44.5453],
            [-87.4711, 44.5451],
            [-87.5365, 44.2841],
            [-87.6847, 44.3275],
            [-87.7251, 44.4144],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507410',
        NAME: 'Kewaunee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332476151,
        AWATER: 1283676,
        INTPTLAT: '+44.4246444',
        INTPTLON: '-087.5882533',
        ELSDLEA: '',
        UNSDLEA: '07410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3162, 44.541],
            [-92.2496, 44.5578],
            [-92.2409, 44.5578],
            [-92.2361, 44.5578],
            [-92.1352, 44.5609],
            [-92.0455, 44.51],
            [-92.0836, 44.4073],
            [-92.1381, 44.4244],
            [-92.2211, 44.4404],
            [-92.3162, 44.541],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511580',
        NAME: 'Pepin Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 203554801,
        AWATER: 35582771,
        INTPTLAT: '+44.4962604',
        INTPTLON: '-092.1842669',
        ELSDLEA: '',
        UNSDLEA: '11580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0464, 45.2515],
            [-90.0423, 45.3814],
            [-89.4879, 45.3805],
            [-89.4246, 45.4188],
            [-89.4243, 45.3802],
            [-89.426, 45.1191],
            [-89.8445, 45.1201],
            [-89.8445, 45.0617],
            [-89.9058, 45.0328],
            [-89.9645, 45.1202],
            [-90.0524, 45.1202],
            [-90.0464, 45.2515],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509210',
        NAME: 'Merrill Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1460372620,
        AWATER: 19261274,
        INTPTLAT: '+45.2488672',
        INTPTLON: '-089.7393605',
        ELSDLEA: '',
        UNSDLEA: '09210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5565, 45.2909],
            [-90.1678, 45.2948],
            [-90.0464, 45.2515],
            [-90.0524, 45.1202],
            [-90.1508, 45.1204],
            [-90.1975, 45.0336],
            [-90.4276, 45.0311],
            [-90.5571, 45.0311],
            [-90.6161, 45.0928],
            [-90.5565, 45.1627],
            [-90.5565, 45.2909],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508940',
        NAME: 'Medford Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1044008860,
        AWATER: 4031588,
        INTPTLAT: '+45.1691484',
        INTPTLON: '-090.3380959',
        ELSDLEA: '',
        UNSDLEA: '08940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8381, 43.2061],
            [-89.7402, 43.2338],
            [-89.6001, 43.1907],
            [-89.6705, 43.1622],
            [-89.6697, 43.075],
            [-89.8382, 43.0747],
            [-89.8889, 43.0809],
            [-89.8381, 43.2061],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501230',
        NAME: 'Wisconsin Heights School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 269048148,
        AWATER: 1838178,
        INTPTLAT: '+43.1544714',
        INTPTLON: '-089.7536407',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8636, 42.9155],
            [-89.8382, 43.0747],
            [-89.6697, 43.075],
            [-89.6016, 43.0312],
            [-89.603, 42.9412],
            [-89.6223, 42.8885],
            [-89.7711, 42.8721],
            [-89.8636, 42.9155],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509990',
        NAME: 'Mount Horeb Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 369743578,
        AWATER: 103862,
        INTPTLAT: '+42.9821704',
        INTPTLON: '-089.7348175',
        ELSDLEA: '',
        UNSDLEA: '09990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2446, 43.3592],
            [-89.2274, 43.3589],
            [-89.1599, 43.3697],
            [-89.0203, 43.3713],
            [-88.9548, 43.4173],
            [-88.9497, 43.4048],
            [-88.8915, 43.35],
            [-88.8917, 43.3033],
            [-89.0582, 43.2396],
            [-89.1076, 43.2319],
            [-89.2362, 43.2823],
            [-89.2446, 43.3592],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502820',
        NAME: 'Columbus School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 344545563,
        AWATER: 1734879,
        INTPTLAT: '+43.3267107',
        INTPTLON: '-089.0571227',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3435, 44.2772],
            [-88.2933, 44.2749],
            [-88.1629, 44.2414],
            [-88.1683, 44.2204],
            [-88.2091, 44.1982],
            [-88.3038, 44.1505],
            [-88.3635, 44.2043],
            [-88.3435, 44.2772],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507470',
        NAME: 'Kimberly Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 105805717,
        AWATER: 1176159,
        INTPTLAT: '+44.2188122',
        INTPTLON: '-088.2814943',
        ELSDLEA: '',
        UNSDLEA: '07470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8584, 44.7979],
            [-88.7138, 44.8565],
            [-88.4891, 44.8554],
            [-88.4514, 44.8551],
            [-88.5215, 44.7501],
            [-88.5215, 44.7438],
            [-88.5159, 44.6491],
            [-88.6054, 44.651],
            [-88.7737, 44.7228],
            [-88.8584, 44.7965],
            [-88.8584, 44.7979],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513620',
        NAME: 'Shawano School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 401821738,
        AWATER: 29182126,
        INTPTLAT: '+44.7691393',
        INTPTLON: '-088.6251440',
        ELSDLEA: '',
        UNSDLEA: '13620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8676, 43.8047],
            [-87.8652, 43.8078],
            [-87.8603, 43.8047],
            [-87.8007, 43.798],
            [-87.7899, 43.7601],
            [-87.8004, 43.6741],
            [-87.9296, 43.6602],
            [-87.9738, 43.6514],
            [-87.8676, 43.8047],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513680',
        NAME: 'Sheboygan Falls School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 144924537,
        AWATER: 1413398,
        INTPTLAT: '+43.7212613',
        INTPTLON: '-087.8703264',
        ELSDLEA: '',
        UNSDLEA: '13680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2618, 43.231],
            [-88.2588, 43.233],
            [-88.257, 43.233],
            [-88.251, 43.2368],
            [-88.2218, 43.2655],
            [-88.1816, 43.3099],
            [-88.1026, 43.3085],
            [-88.0633, 43.2789],
            [-88.0634, 43.1921],
            [-88.1822, 43.1926],
            [-88.2662, 43.1933],
            [-88.2723, 43.1932],
            [-88.2618, 43.231],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505160',
        NAME: 'Germantown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148033047,
        AWATER: 598297,
        INTPTLAT: '+43.2433848',
        INTPTLON: '-088.1497344',
        ELSDLEA: '',
        UNSDLEA: '05160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9238, 44.2903],
            [-91.7308, 44.3192],
            [-91.7155, 44.2836],
            [-91.601, 44.211],
            [-91.5404, 44.0882],
            [-91.559, 44.0253],
            [-91.8752, 44.2006],
            [-91.8925, 44.2363],
            [-91.9238, 44.2903],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502700',
        NAME: 'Cochrane-Fountain City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 394111025,
        AWATER: 56813506,
        INTPTLAT: '+44.1818990',
        INTPTLON: '-091.7139361',
        ELSDLEA: '',
        UNSDLEA: '02700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.0455, 44.51],
            [-91.9935, 44.4265],
            [-91.9546, 44.4446],
            [-91.8521, 44.4368],
            [-91.8267, 44.4227],
            [-91.7415, 44.4029],
            [-91.6572, 44.4066],
            [-91.6903, 44.3633],
            [-91.7005, 44.3494],
            [-91.7308, 44.3192],
            [-91.9238, 44.2903],
            [-91.96, 44.3598],
            [-92.0836, 44.4073],
            [-92.0455, 44.51],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500180',
        NAME: 'Alma School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 325571490,
        AWATER: 33169569,
        INTPTLAT: '+44.3831562',
        INTPTLON: '-091.8878276',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6833, 46.0141],
            [-88.4927, 45.9925],
            [-88.3809, 45.9918],
            [-88.1168, 45.9216],
            [-88.1325, 45.8242],
            [-88.0583, 45.7806],
            [-88.0592, 45.7131],
            [-88.2399, 45.7196],
            [-88.5828, 45.7226],
            [-88.6758, 45.7229],
            [-88.6755, 45.8963],
            [-88.6833, 46.0141],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504650',
        NAME: 'Florence School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1264230429,
        AWATER: 24111640,
        INTPTLAT: '+45.8718648',
        INTPTLON: '-088.4072338',
        ELSDLEA: '',
        UNSDLEA: '04650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-90.3478, 42.5277],
              [-90.3426, 42.5253],
              [-90.3478, 42.5253],
              [-90.3478, 42.5277],
            ],
          ],
          [
            [
              [-90.4334, 42.5694],
              [-90.3623, 42.6021],
              [-90.3576, 42.6022],
              [-90.3084, 42.6099],
              [-90.3478, 42.5277],
              [-90.3478, 42.5253],
              [-90.3427, 42.5212],
              [-90.3086, 42.5074],
              [-90.3819, 42.507],
              [-90.4264, 42.5072],
              [-90.4334, 42.5694],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501110',
        NAME: 'Benton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 83461053,
        AWATER: 0,
        INTPTLAT: '+42.5594143',
        INTPTLON: '-090.3699819',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6615, 42.568],
            [-90.6611, 42.5742],
            [-90.5439, 42.5805],
            [-90.4334, 42.5694],
            [-90.4264, 42.5072],
            [-90.5306, 42.5077],
            [-90.6428, 42.5085],
            [-90.6615, 42.568],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506300',
        NAME: 'Southwestern Wisconsin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148939444,
        AWATER: 6214993,
        INTPTLAT: '+42.5459496',
        INTPTLON: '-090.5353470',
        ELSDLEA: '',
        UNSDLEA: '06300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1183, 42.5809],
            [-89.9607, 42.6284],
            [-89.9283, 42.6617],
            [-89.7982, 42.6463],
            [-89.7695, 42.5548],
            [-89.7685, 42.5054],
            [-89.9066, 42.5058],
            [-89.9807, 42.5064],
            [-90.0738, 42.5229],
            [-90.1183, 42.5809],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505730',
        NAME: 'Black Hawk School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 345379800,
        AWATER: 171482,
        INTPTLAT: '+42.5712555',
        INTPTLON: '-089.9314352',
        ELSDLEA: '',
        UNSDLEA: '05730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2056, 42.7619],
            [-89.9947, 42.7888],
            [-89.9564, 42.7661],
            [-89.9283, 42.6617],
            [-89.9607, 42.6284],
            [-90.1183, 42.5809],
            [-90.2448, 42.646],
            [-90.2549, 42.6533],
            [-90.2549, 42.6574],
            [-90.2056, 42.7619],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503150',
        NAME: 'Darlington Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 414397475,
        AWATER: 1873695,
        INTPTLAT: '+42.7006657',
        INTPTLON: '-090.0885348',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-90.4276, 45.0311],
              [-90.1975, 45.0336],
              [-90.1976, 44.9924],
              [-90.4279, 44.9809],
              [-90.4276, 45.0311],
            ],
          ],
          [
            [
              [-90.4342, 44.9442],
              [-90.1296, 44.9309],
              [-90.1266, 44.9097],
              [-90.1989, 44.815],
              [-90.3983, 44.7997],
              [-90.4651, 44.8715],
              [-90.4342, 44.9442],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502730',
        NAME: 'Colby School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 426571648,
        AWATER: 864784,
        INTPTLAT: '+44.9132279',
        INTPTLON: '-090.3126854',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8004, 44.7708],
            [-90.679, 44.873],
            [-90.5462, 44.8515],
            [-90.5367, 44.8136],
            [-90.5711, 44.6836],
            [-90.8009, 44.6525],
            [-90.8004, 44.7708],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505970',
        NAME: 'Greenwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 384600356,
        AWATER: 4429933,
        INTPTLAT: '+44.7633534',
        INTPTLON: '-090.6692131',
        ELSDLEA: '',
        UNSDLEA: '05970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.603, 42.9412],
            [-89.5128, 42.9362],
            [-89.4938, 42.9286],
            [-89.4378, 42.8227],
            [-89.4671, 42.7771],
            [-89.5463, 42.7855],
            [-89.6025, 42.8718],
            [-89.6223, 42.8885],
            [-89.603, 42.9412],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500990',
        NAME: 'Belleville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184547715,
        AWATER: 1091978,
        INTPTLAT: '+42.8627105',
        INTPTLON: '-089.5256707',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7982, 42.6463],
            [-89.7248, 42.7087],
            [-89.5645, 42.6829],
            [-89.5252, 42.6536],
            [-89.5445, 42.6355],
            [-89.5638, 42.5026],
            [-89.7424, 42.5054],
            [-89.7685, 42.5054],
            [-89.7695, 42.5548],
            [-89.7982, 42.6463],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509840',
        NAME: 'Monroe School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410801853,
        AWATER: 357208,
        INTPTLAT: '+42.6002924',
        INTPTLON: '-089.6751217',
        ELSDLEA: '',
        UNSDLEA: '09840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7711, 42.8721],
            [-89.6223, 42.8885],
            [-89.6025, 42.8718],
            [-89.5463, 42.7855],
            [-89.7356, 42.7631],
            [-89.7598, 42.7846],
            [-89.7711, 42.8721],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510500',
        NAME: 'New Glarus School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206882323,
        AWATER: 3104,
        INTPTLAT: '+42.8310614',
        INTPTLON: '-089.6794317',
        ELSDLEA: '',
        UNSDLEA: '10500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5445, 42.6355],
            [-89.5252, 42.6536],
            [-89.5011, 42.6538],
            [-89.5053, 42.6354],
            [-89.41, 42.5007],
            [-89.4192, 42.5008],
            [-89.4192, 42.508],
            [-89.444, 42.501],
            [-89.4551, 42.5012],
            [-89.5638, 42.5026],
            [-89.5445, 42.6355],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507230',
        NAME: 'Juda School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140347907,
        AWATER: 18544,
        INTPTLAT: '+42.5667650',
        INTPTLON: '-089.4932468',
        ELSDLEA: '',
        UNSDLEA: '07230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2929, 46.4172],
            [-92.2916, 46.6341],
            [-92.2922, 46.6633],
            [-92.2124, 46.6499],
            [-92.0895, 46.7492],
            [-92.0203, 46.704],
            [-91.9312, 46.7421],
            [-91.9262, 46.4754],
            [-92.0503, 46.4178],
            [-92.0503, 46.3326],
            [-92.293, 46.3322],
            [-92.2929, 46.4172],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514670',
        NAME: 'Superior School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1013851356,
        AWATER: 59659475,
        INTPTLAT: '+46.5374770',
        INTPTLON: '-092.1165103',
        ELSDLEA: '',
        UNSDLEA: '14670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6107, 45.9364],
            [-92.4728, 45.973],
            [-92.4216, 46.0265],
            [-92.294, 46.0744],
            [-92.293, 46.3322],
            [-92.0503, 46.3326],
            [-92.0503, 46.3324],
            [-92.0504, 46.0712],
            [-92.0506, 45.9839],
            [-92.1567, 45.9837],
            [-92.1553, 45.86],
            [-92.2167, 45.8162],
            [-92.4466, 45.8312],
            [-92.6107, 45.9364],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516230',
        NAME: 'Webster School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1191843036,
        AWATER: 76353696,
        INTPTLAT: '+46.0620773',
        INTPTLON: '-092.2472701',
        ELSDLEA: '',
        UNSDLEA: '16230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9262, 46.4754],
            [-91.7386, 46.4743],
            [-91.6754, 46.4173],
            [-91.6746, 46.3309],
            [-92.0503, 46.3324],
            [-92.0503, 46.3326],
            [-92.0503, 46.4178],
            [-91.9262, 46.4754],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513980',
        NAME: 'Solon Springs School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402186433,
        AWATER: 8729709,
        INTPTLAT: '+46.3755430',
        INTPTLON: '-091.8441460',
        ELSDLEA: '',
        UNSDLEA: '13980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9307, 43.8778],
            [-87.7906, 43.892],
            [-87.733, 43.8884],
            [-87.7339, 43.8859],
            [-87.7343, 43.885],
            [-87.7353, 43.8825],
            [-87.7357, 43.8814],
            [-87.7361, 43.8796],
            [-87.736, 43.8776],
            [-87.7284, 43.8493],
            [-87.7607, 43.8374],
            [-87.7717, 43.8279],
            [-87.7499, 43.8094],
            [-87.7606, 43.8077],
            [-87.8007, 43.798],
            [-87.8603, 43.8047],
            [-87.8652, 43.8078],
            [-87.8676, 43.8047],
            [-87.9215, 43.8049],
            [-87.9222, 43.8549],
            [-87.9154, 43.8629],
            [-87.9307, 43.8778],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506660',
        NAME: 'Howards Grove School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133856397,
        AWATER: 718300,
        INTPTLAT: '+43.8555991',
        INTPTLON: '-087.8318238',
        ELSDLEA: '',
        UNSDLEA: '06660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.391, 43.5435],
            [-88.2605, 43.5501],
            [-88.1599, 43.6313],
            [-88.0401, 43.6306],
            [-88.0408, 43.4706],
            [-88.0407, 43.4545],
            [-88.3076, 43.4562],
            [-88.4007, 43.4568],
            [-88.391, 43.5435],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507380',
        NAME: 'Kewaskum School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 364283385,
        AWATER: 2954201,
        INTPTLAT: '+43.5305304',
        INTPTLON: '-088.2092881',
        ELSDLEA: '',
        UNSDLEA: '07380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1612, 43.8117],
            [-88.081, 43.7814],
            [-87.9215, 43.8049],
            [-87.8676, 43.8047],
            [-87.9738, 43.6514],
            [-88.0401, 43.6306],
            [-88.1599, 43.6313],
            [-88.1489, 43.7321],
            [-88.1609, 43.7829],
            [-88.1611, 43.7901],
            [-88.1611, 43.7973],
            [-88.1612, 43.8082],
            [-88.1612, 43.8117],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511940',
        NAME: 'Plymouth School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 320772138,
        AWATER: 2779387,
        INTPTLAT: '+43.7358606',
        INTPTLON: '-088.0335520',
        ELSDLEA: '',
        UNSDLEA: '11940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9319, 44.4442],
            [-87.8514, 44.4731],
            [-87.7251, 44.4144],
            [-87.6847, 44.3275],
            [-87.7918, 44.2402],
            [-87.9492, 44.2848],
            [-87.9481, 44.3424],
            [-87.9319, 44.4442],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503660',
        NAME: 'Denmark School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 363652466,
        AWATER: 3402786,
        INTPTLAT: '+44.3558043',
        INTPTLON: '-087.8293875',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8241, 44.6275],
            [-87.7364, 44.677],
            [-87.5902, 44.6755],
            [-87.5424, 44.5453],
            [-87.6843, 44.4724],
            [-87.7251, 44.4144],
            [-87.8514, 44.4731],
            [-87.8241, 44.6275],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508340',
        NAME: 'Luxemburg-Casco School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 446770850,
        AWATER: 35729,
        INTPTLAT: '+44.5600823',
        INTPTLON: '-087.7044623',
        ELSDLEA: '',
        UNSDLEA: '08340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0407, 43.4545],
            [-88.0408, 43.4706],
            [-87.8806, 43.5141],
            [-87.7954, 43.4848],
            [-87.8105, 43.4559],
            [-87.9205, 43.4558],
            [-87.9663, 43.3677],
            [-88.0401, 43.3673],
            [-88.0407, 43.4545],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504890',
        NAME: 'Northern Ozaukee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161230277,
        AWATER: 2082979,
        INTPTLAT: '+43.4481638',
        INTPTLON: '-087.9471221',
        ELSDLEA: '',
        UNSDLEA: '04890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8105, 43.4559],
            [-87.8839, 43.3388],
            [-87.9659, 43.3677],
            [-87.9663, 43.3677],
            [-87.9205, 43.4558],
            [-87.8105, 43.4559],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512000',
        NAME: 'Port Washington-Saukville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 78562267,
        AWATER: 2930018,
        INTPTLAT: '+43.4055418',
        INTPTLON: '-087.8884054',
        ELSDLEA: '',
        UNSDLEA: '12000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8141, 43.2853],
            [-88.6836, 43.3065],
            [-88.6835, 43.2993],
            [-88.6835, 43.2948],
            [-88.5667, 43.2407],
            [-88.5495, 43.2394],
            [-88.6291, 43.1295],
            [-88.5709, 43.0323],
            [-88.6398, 43.0486],
            [-88.782, 43.1311],
            [-88.8325, 43.1753],
            [-88.8906, 43.1979],
            [-88.8141, 43.2853],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515750',
        NAME: 'Watertown School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 428690031,
        AWATER: 5611657,
        INTPTLAT: '+43.1970404',
        INTPTLON: '-088.7078014',
        ELSDLEA: '',
        UNSDLEA: '15750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6807, 43.328],
            [-88.6652, 43.3538],
            [-88.6315, 43.3806],
            [-88.5817, 43.4114],
            [-88.5708, 43.4115],
            [-88.5615, 43.3998],
            [-88.5621, 43.3961],
            [-88.5575, 43.3923],
            [-88.5518, 43.3925],
            [-88.5518, 43.3889],
            [-88.5459, 43.3852],
            [-88.5419, 43.3779],
            [-88.528, 43.3705],
            [-88.5667, 43.2407],
            [-88.6835, 43.2948],
            [-88.6561, 43.2916],
            [-88.6835, 43.2993],
            [-88.6836, 43.3065],
            [-88.6807, 43.328],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506780',
        NAME: 'Hustisford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127554644,
        AWATER: 9292589,
        INTPTLAT: '+43.3297612',
        INTPTLON: '-088.6012713',
        ELSDLEA: '',
        UNSDLEA: '06780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.5419, 43.3779],
              [-88.5281, 43.3778],
              [-88.528, 43.3705],
              [-88.5419, 43.3779],
            ],
          ],
          [
            [
              [-88.5621, 43.3961],
              [-88.5615, 43.3998],
              [-88.5518, 43.3925],
              [-88.5575, 43.3923],
              [-88.5621, 43.3961],
            ],
          ],
          [
            [
              [-88.6351, 43.633],
              [-88.529, 43.6236],
              [-88.4405, 43.4569],
              [-88.5322, 43.4115],
              [-88.5708, 43.4115],
              [-88.5817, 43.4114],
              [-88.6445, 43.5234],
              [-88.6351, 43.633],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508880',
        NAME: 'Mayville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241451053,
        AWATER: 7536800,
        INTPTLAT: '+43.5260969',
        INTPTLON: '-088.5619149',
        ELSDLEA: '',
        UNSDLEA: '08880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.233, 45.0887],
            [-91.0568, 45.0821],
            [-91.0406, 44.8572],
            [-91.1408, 44.8569],
            [-91.2467, 44.857],
            [-91.2636, 44.9877],
            [-91.233, 45.0887],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502040',
        NAME: 'Cadott Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388596934,
        AWATER: 2002884,
        INTPTLAT: '+44.9699291',
        INTPTLON: '-091.1494885',
        ELSDLEA: '',
        UNSDLEA: '02040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9531, 42.6696],
            [-87.8056, 42.6682],
            [-87.8005, 42.4919],
            [-87.8032, 42.4926],
            [-87.8529, 42.4932],
            [-87.9498, 42.4946],
            [-87.9529, 42.577],
            [-87.9531, 42.6696],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507320',
        NAME: 'Kenosha School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 221606019,
        AWATER: 1020094,
        INTPTLAT: '+42.5803759',
        INTPTLON: '-087.8834344',
        ELSDLEA: '',
        UNSDLEA: '07320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.789, 45.1823],
            [-91.7889, 45.1942],
            [-91.7878, 45.3242],
            [-91.6843, 45.3758],
            [-91.5727, 45.3789],
            [-91.5404, 45.4652],
            [-91.419, 45.4648],
            [-91.3583, 45.4646],
            [-91.3592, 45.2917],
            [-91.4802, 45.2916],
            [-91.6403, 45.2185],
            [-91.6441, 45.1822],
            [-91.6468, 45.1585],
            [-91.6661, 45.1463],
            [-91.6976, 45.1508],
            [-91.789, 45.1752],
            [-91.789, 45.1823],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500061',
        NAME: 'Chetek-Weyerhaeuser Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 633895220,
        AWATER: 26860689,
        INTPTLAT: '+45.3137815',
        INTPTLON: '-091.5716344',
        ELSDLEA: '',
        UNSDLEA: '00061',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5233, 45.331],
            [-92.4636, 45.4102],
            [-92.3661, 45.4107],
            [-92.259, 45.4677],
            [-92.2531, 45.3806],
            [-92.2505, 45.3098],
            [-92.3379, 45.2903],
            [-92.3438, 45.1733],
            [-92.427, 45.1947],
            [-92.4269, 45.2019],
            [-92.5374, 45.2098],
            [-92.5233, 45.331],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500300',
        NAME: 'Amery School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 397492662,
        AWATER: 21101545,
        INTPTLAT: '+45.3202235',
        INTPTLON: '-092.3998540',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4559, 44.8805],
            [-92.4204, 44.9134],
            [-92.4215, 45.0647],
            [-92.3388, 45.0634],
            [-92.2832, 45.0413],
            [-92.2004, 44.9516],
            [-92.2173, 44.9017],
            [-92.4002, 44.8623],
            [-92.4559, 44.8805],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500720',
        NAME: 'Baldwin-Woodville Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300158115,
        AWATER: 762501,
        INTPTLAT: '+44.9564144',
        INTPTLON: '-092.3182728',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.7341, 45.1759],
              [-92.7338, 45.1669],
              [-92.7396, 45.167],
              [-92.7341, 45.1759],
            ],
          ],
          [
            [
              [-92.7557, 45.1671],
              [-92.7396, 45.167],
              [-92.7338, 45.1669],
              [-92.7341, 45.1759],
              [-92.7032, 45.1853],
              [-92.6716, 45.2065],
              [-92.6716, 45.1996],
              [-92.6626, 45.1955],
              [-92.6413, 45.1807],
              [-92.5903, 45.1874],
              [-92.6414, 45.0862],
              [-92.6499, 45.0536],
              [-92.7408, 45.0689],
              [-92.7924, 45.0789],
              [-92.7557, 45.1671],
            ],
            [
              [-92.7032, 45.1853],
              [-92.7032, 45.1814],
              [-92.693, 45.1814],
              [-92.7032, 45.1853],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514010',
        NAME: 'Somerset School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 150159220,
        AWATER: 4454133,
        INTPTLAT: '+45.1270539',
        INTPTLON: '-092.6856311',
        ELSDLEA: '',
        UNSDLEA: '14010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5374, 45.2098],
            [-92.4269, 45.2019],
            [-92.427, 45.1947],
            [-92.3438, 45.1733],
            [-92.3438, 45.1692],
            [-92.3328, 45.1653],
            [-92.2817, 45.1411],
            [-92.2815, 45.1355],
            [-92.3347, 45.0841],
            [-92.3352, 45.0706],
            [-92.3353, 45.0633],
            [-92.3388, 45.0634],
            [-92.4215, 45.0647],
            [-92.6197, 45.0357],
            [-92.6499, 45.0536],
            [-92.6414, 45.0862],
            [-92.5903, 45.1874],
            [-92.6413, 45.1807],
            [-92.6626, 45.1955],
            [-92.5374, 45.2098],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510620',
        NAME: 'New Richmond School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 388025161,
        AWATER: 8067342,
        INTPTLAT: '+45.1248140',
        INTPTLON: '-092.4899143',
        ELSDLEA: '',
        UNSDLEA: '10620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-92.7032, 45.1853],
              [-92.693, 45.1814],
              [-92.7032, 45.1814],
              [-92.7032, 45.1853],
            ],
          ],
          [
            [
              [-92.7523, 45.2169],
              [-92.7457, 45.296],
              [-92.7061, 45.3253],
              [-92.6745, 45.3829],
              [-92.6456, 45.3679],
              [-92.5233, 45.331],
              [-92.5374, 45.2098],
              [-92.6626, 45.1955],
              [-92.6716, 45.1996],
              [-92.6716, 45.2065],
              [-92.7032, 45.1853],
              [-92.7341, 45.1759],
              [-92.7396, 45.167],
              [-92.7557, 45.1671],
              [-92.7523, 45.2169],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511160',
        NAME: 'Osceola School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 277847133,
        AWATER: 14944441,
        INTPTLAT: '+45.2748456',
        INTPTLON: '-092.6417610',
        ELSDLEA: '',
        UNSDLEA: '11160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.189, 42.9302],
            [-88.0695, 42.9301],
            [-88.0696, 42.9238],
            [-88.0699, 42.8433],
            [-88.1298, 42.8104],
            [-88.13, 42.8089],
            [-88.1839, 42.8325],
            [-88.1883, 42.8501],
            [-88.189, 42.9302],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510170',
        NAME: 'Muskego-Norway School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 96389740,
        AWATER: 16039897,
        INTPTLAT: '+42.8713558',
        INTPTLON: '-088.1343199',
        ELSDLEA: '',
        UNSDLEA: '10170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.408, 42.6773],
            [-88.3854, 42.7062],
            [-88.3081, 42.7555],
            [-88.2868, 42.726],
            [-88.1109, 42.7334],
            [-88.1107, 42.6694],
            [-88.1882, 42.6115],
            [-88.3058, 42.6035],
            [-88.408, 42.6773],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501890',
        NAME: 'Burlington Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252507921,
        AWATER: 7055700,
        INTPTLAT: '+42.6783568',
        INTPTLON: '-088.2663104',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.3073, 44.7258],
            [-92.2669, 44.7121],
            [-92.1351, 44.6609],
            [-92.1302, 44.637],
            [-92.1352, 44.5609],
            [-92.2361, 44.5578],
            [-92.2409, 44.5578],
            [-92.2496, 44.5578],
            [-92.307, 44.5741],
            [-92.3073, 44.7258],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511880',
        NAME: 'Plum City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 212038961,
        AWATER: 781926,
        INTPTLAT: '+44.6282796',
        INTPTLON: '-092.2248351',
        ELSDLEA: '',
        UNSDLEA: '11880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1343, 43.0105],
            [-89.0113, 43.0749],
            [-88.977, 43.0379],
            [-88.9242, 43.0154],
            [-88.9348, 43.0007],
            [-88.9339, 42.9717],
            [-88.9844, 42.9165],
            [-89.1028, 42.9341],
            [-89.1343, 43.0105],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502100',
        NAME: 'Cambridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165263614,
        AWATER: 4681684,
        INTPTLAT: '+42.9844090',
        INTPTLON: '-089.0288391',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.6197, 45.0357],
            [-92.4215, 45.0647],
            [-92.4204, 44.9134],
            [-92.4559, 44.8805],
            [-92.497, 44.9492],
            [-92.6193, 44.942],
            [-92.6197, 45.0357],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506060',
        NAME: 'St. Croix Central School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 218875532,
        AWATER: 1353505,
        INTPTLAT: '+44.9805310',
        INTPTLON: '-092.5036856',
        ELSDLEA: '',
        UNSDLEA: '06060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9698, 44.1605],
            [-89.7171, 44.156],
            [-89.5977, 44.1561],
            [-89.598, 44.0685],
            [-89.5985, 43.7877],
            [-89.6589, 43.7693],
            [-89.8505, 43.7687],
            [-89.9807, 43.9056],
            [-89.9626, 43.9792],
            [-90.026, 44.0917],
            [-89.9698, 44.1605],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500060',
        NAME: 'Adams-Friendship Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1186683277,
        AWATER: 79350150,
        INTPTLAT: '+43.9749279',
        INTPTLON: '-089.7863688',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8551, 43.7132],
            [-88.8255, 43.7056],
            [-88.6906, 43.7498],
            [-88.6349, 43.6926],
            [-88.6351, 43.633],
            [-88.6445, 43.5234],
            [-88.7479, 43.5278],
            [-88.8861, 43.5207],
            [-88.9544, 43.5408],
            [-88.9942, 43.6208],
            [-88.9971, 43.6223],
            [-88.9991, 43.6222],
            [-88.8861, 43.6335],
            [-88.8551, 43.7132],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515870',
        NAME: 'Waupun School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 434369210,
        AWATER: 22043443,
        INTPTLAT: '+43.6265355',
        INTPTLON: '-088.7880838',
        ELSDLEA: '',
        UNSDLEA: '15870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.529, 43.6236],
            [-88.465, 43.6894],
            [-88.4323, 43.6894],
            [-88.391, 43.5435],
            [-88.4007, 43.4568],
            [-88.4405, 43.4569],
            [-88.529, 43.6236],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508220',
        NAME: 'Lomira School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 189401539,
        AWATER: 1425343,
        INTPTLAT: '+43.5736414',
        INTPTLON: '-088.4468667',
        ELSDLEA: '',
        UNSDLEA: '08220',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6349, 43.6926],
            [-88.5439, 43.78],
            [-88.5237, 43.7773],
            [-88.4665, 43.6972],
            [-88.465, 43.6894],
            [-88.529, 43.6236],
            [-88.6351, 43.633],
            [-88.6349, 43.6926],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510860',
        NAME: 'Oakfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 161693695,
        AWATER: 951605,
        INTPTLAT: '+43.6918434',
        INTPTLON: '-088.5513040',
        ELSDLEA: '',
        UNSDLEA: '10860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0582, 43.2396],
            [-88.8917, 43.3033],
            [-88.8141, 43.2853],
            [-88.8906, 43.1979],
            [-88.964, 43.1257],
            [-89.0121, 43.1106],
            [-89.0089, 43.2007],
            [-89.0582, 43.2396],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515720',
        NAME: 'Waterloo School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205590186,
        AWATER: 2821035,
        INTPTLAT: '+43.2122262',
        INTPTLON: '-088.9332599',
        ELSDLEA: '',
        UNSDLEA: '15720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3773, 44.6551],
            [-90.3363, 44.6851],
            [-90.2185, 44.6853],
            [-90.1748, 44.7672],
            [-90.0655, 44.6998],
            [-90.1208, 44.5622],
            [-90.1798, 44.5117],
            [-90.3157, 44.5128],
            [-90.316, 44.5849],
            [-90.3773, 44.6551],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508820',
        NAME: 'Marshfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 483464746,
        AWATER: 965717,
        INTPTLAT: '+44.6384782',
        INTPTLON: '-090.1899769',
        ELSDLEA: '',
        UNSDLEA: '08820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9241, 46.371],
            [-90.7983, 46.4143],
            [-90.5488, 46.414],
            [-90.5522, 46.2409],
            [-90.9248, 46.2418],
            [-90.9241, 46.371],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508970',
        NAME: 'Mellen School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 516617873,
        AWATER: 6656685,
        INTPTLAT: '+46.3209467',
        INTPTLON: '-090.7115829',
        ELSDLEA: '',
        UNSDLEA: '08970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1016, 44.9726],
            [-88.9821, 44.983],
            [-88.8592, 44.9429],
            [-88.8584, 44.7979],
            [-88.8584, 44.7965],
            [-88.8988, 44.7758],
            [-89.0722, 44.8113],
            [-89.1016, 44.9726],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501560',
        NAME: 'Bowler School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 323988801,
        AWATER: 533242,
        INTPTLAT: '+44.8747653',
        INTPTLON: '-088.9705946',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5813, 44.8945],
            [-91.3876, 44.8572],
            [-91.3451, 44.8151],
            [-91.4684, 44.8162],
            [-91.4071, 44.7711],
            [-91.3241, 44.6794],
            [-91.3673, 44.6501],
            [-91.5092, 44.6536],
            [-91.5145, 44.6539],
            [-91.5249, 44.6536],
            [-91.6756, 44.6837],
            [-91.6802, 44.7272],
            [-91.6904, 44.7438],
            [-91.6504, 44.8496],
            [-91.5813, 44.8945],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504050',
        NAME: 'Eau Claire Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 497348699,
        AWATER: 10303147,
        INTPTLAT: '+44.7519836',
        INTPTLON: '-091.5149983',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3451, 44.8151],
            [-91.4071, 44.7711],
            [-91.4684, 44.8162],
            [-91.3451, 44.8151],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500270',
        NAME: 'Altoona School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34738830,
        AWATER: 3559915,
        INTPTLAT: '+44.8032244',
        INTPTLON: '-091.3989998',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4071, 44.7711],
            [-91.3451, 44.8151],
            [-91.3876, 44.8572],
            [-91.2467, 44.857],
            [-91.1408, 44.8569],
            [-91.1653, 44.7567],
            [-91.2614, 44.6834],
            [-91.3241, 44.6794],
            [-91.4071, 44.7711],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504500',
        NAME: 'Fall Creek School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273205499,
        AWATER: 1448059,
        INTPTLAT: '+44.7807702',
        INTPTLON: '-091.2650059',
        ELSDLEA: '',
        UNSDLEA: '04500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0654, 44.3945],
            [-89.8901, 44.3948],
            [-89.8253, 44.3367],
            [-89.9634, 44.3364],
            [-90.0726, 44.2925],
            [-90.0654, 44.3945],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511970',
        NAME: 'Port Edwards School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143966834,
        AWATER: 17694656,
        INTPTLAT: '+44.3535250',
        INTPTLON: '-089.9695234',
        ELSDLEA: '',
        UNSDLEA: '11970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4007, 43.4568],
            [-88.3076, 43.4562],
            [-88.2686, 43.3693],
            [-88.1816, 43.3099],
            [-88.2218, 43.2655],
            [-88.2254, 43.2655],
            [-88.3004, 43.2814],
            [-88.4012, 43.4004],
            [-88.4007, 43.4568],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513950',
        NAME: 'Slinger School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 201482551,
        AWATER: 2992520,
        INTPTLAT: '+43.3655437',
        INTPTLON: '-088.2886884',
        ELSDLEA: '',
        UNSDLEA: '13950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2465, 43.8949],
            [-89.1682, 43.9536],
            [-89.0109, 43.889],
            [-89.0607, 43.8055],
            [-89.1278, 43.7702],
            [-89.2465, 43.8323],
            [-89.2465, 43.8949],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512300',
        NAME: 'Princeton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 226272954,
        AWATER: 7466406,
        INTPTLAT: '+43.8722125',
        INTPTLON: '-089.1346178',
        ELSDLEA: '',
        UNSDLEA: '12300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7799, 43.938],
            [-88.7444, 43.9457],
            [-88.654, 43.8946],
            [-88.8656, 43.7496],
            [-88.924, 43.7933],
            [-88.9059, 43.8078],
            [-88.906, 43.8144],
            [-88.9403, 43.8803],
            [-88.7799, 43.938],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513020',
        NAME: 'Ripon Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261986981,
        AWATER: 10082355,
        INTPTLAT: '+43.8613114',
        INTPTLON: '-088.8061798',
        ELSDLEA: '',
        UNSDLEA: '13020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1927, 43.5633],
            [-89.1719, 43.578],
            [-89.2014, 43.6285],
            [-89.0368, 43.633],
            [-89.0572, 43.5596],
            [-89.0773, 43.4794],
            [-89.0768, 43.4722],
            [-89.116, 43.4601],
            [-89.2129, 43.494],
            [-89.1927, 43.5633],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502070',
        NAME: 'Cambria-Friesland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217177039,
        AWATER: 976120,
        INTPTLAT: '+43.5609083',
        INTPTLON: '-089.1211696',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2465, 43.8949],
            [-89.2465, 43.8323],
            [-89.1278, 43.7702],
            [-89.239, 43.7665],
            [-89.2512, 43.6431],
            [-89.3652, 43.6426],
            [-89.4835, 43.7304],
            [-89.466, 43.8103],
            [-89.3699, 43.8105],
            [-89.3649, 43.8943],
            [-89.2465, 43.8949],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509870',
        NAME: 'Montello School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 442921842,
        AWATER: 16066379,
        INTPTLAT: '+43.7493900',
        INTPTLON: '-089.3230582',
        ELSDLEA: '',
        UNSDLEA: '09870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0462, 44.1044],
            [-88.9424, 44.1594],
            [-88.8857, 44.1107],
            [-88.8857, 44.1066],
            [-88.8863, 44.0309],
            [-88.7799, 43.938],
            [-88.9403, 43.8803],
            [-89.0109, 43.889],
            [-89.1682, 43.9536],
            [-89.1686, 43.9828],
            [-89.0851, 44.0122],
            [-89.0462, 44.1044],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501140',
        NAME: 'Berlin Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 513741819,
        AWATER: 20207797,
        INTPTLAT: '+43.9977566',
        INTPTLON: '-088.9668318',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0109, 43.889],
            [-88.9403, 43.8803],
            [-88.906, 43.8144],
            [-88.9147, 43.8144],
            [-88.9059, 43.8078],
            [-88.924, 43.7933],
            [-88.956, 43.8004],
            [-89.0607, 43.8055],
            [-89.0109, 43.889],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505880',
        NAME: 'Green Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 80070745,
        AWATER: 2141049,
        INTPTLAT: '+43.8412095',
        INTPTLON: '-088.9753837',
        ELSDLEA: '',
        UNSDLEA: '05880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0439, 44.4359],
            [-90.9447, 44.3778],
            [-90.7643, 44.4223],
            [-90.5587, 44.4222],
            [-90.5583, 44.3352],
            [-90.4374, 44.2919],
            [-90.4359, 44.1892],
            [-90.6531, 44.1596],
            [-90.9044, 44.1584],
            [-91.0329, 44.2144],
            [-91.0265, 44.2885],
            [-91.0841, 44.4103],
            [-91.0439, 44.4359],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501260',
        NAME: 'Black River Falls School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1169692335,
        AWATER: 15986096,
        INTPTLAT: '+44.2876822',
        INTPTLON: '-090.7726802',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0311, 43.7674],
            [-90.9739, 43.7728],
            [-90.885, 43.8669],
            [-90.8718, 43.8233],
            [-90.691, 43.8378],
            [-90.6707, 43.6818],
            [-90.6907, 43.6605],
            [-90.79, 43.6752],
            [-90.845, 43.7353],
            [-91.0104, 43.7467],
            [-91.0006, 43.7507],
            [-91.0307, 43.7616],
            [-91.0311, 43.7674],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502340',
        NAME: 'Cashton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 307681568,
        AWATER: 240376,
        INTPTLAT: '+43.7655133',
        INTPTLON: '-090.7939582',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.345, 44.5476],
            [-89.2707, 44.5478],
            [-89.2237, 44.4174],
            [-89.3143, 44.3355],
            [-89.4286, 44.4007],
            [-89.4207, 44.4821],
            [-89.345, 44.5476],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500330',
        NAME: 'Tomorrow River School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 254213533,
        AWATER: 4163951,
        INTPTLAT: '+44.4452760',
        INTPTLON: '-089.3262221',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8651, 44.6232],
            [-89.8451, 44.6849],
            [-89.4903, 44.6859],
            [-89.447, 44.5954],
            [-89.345, 44.5476],
            [-89.4207, 44.4821],
            [-89.4286, 44.4007],
            [-89.4565, 44.3665],
            [-89.6071, 44.3665],
            [-89.6678, 44.3955],
            [-89.7266, 44.5112],
            [-89.828, 44.5573],
            [-89.8754, 44.5949],
            [-89.8651, 44.6232],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514490',
        NAME: 'Stevens Point Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 951430112,
        AWATER: 44055056,
        INTPTLAT: '+44.5429432',
        INTPTLON: '-089.6034314',
        ELSDLEA: '',
        UNSDLEA: '14490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9339, 42.6705],
            [-88.7766, 42.6675],
            [-88.7711, 42.5653],
            [-88.7765, 42.4919],
            [-88.9519, 42.4953],
            [-89.0013, 42.496],
            [-89.0013, 42.5033],
            [-88.9922, 42.5032],
            [-88.9752, 42.5203],
            [-88.964, 42.5178],
            [-88.9634, 42.5398],
            [-89.0021, 42.5624],
            [-89.0033, 42.565],
            [-89.0112, 42.5842],
            [-89.0052, 42.5841],
            [-89.0051, 42.587],
            [-88.9339, 42.6705],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502640',
        NAME: 'Clinton Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287931523,
        AWATER: 678623,
        INTPTLAT: '+42.5775761',
        INTPTLON: '-088.8770846',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.66, 42.9016],
            [-88.6593, 42.8425],
            [-88.5571, 42.8222],
            [-88.5573, 42.8138],
            [-88.5577, 42.8001],
            [-88.6613, 42.7359],
            [-88.7772, 42.6856],
            [-88.8718, 42.8298],
            [-88.66, 42.9016],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516680',
        NAME: 'Whitewater School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 349330779,
        AWATER: 6723599,
        INTPTLAT: '+42.7762413',
        INTPTLON: '-088.7328418',
        ELSDLEA: '',
        UNSDLEA: '16680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5577, 42.8001],
            [-88.494, 42.7202],
            [-88.3854, 42.7062],
            [-88.408, 42.6773],
            [-88.5309, 42.6123],
            [-88.5687, 42.6266],
            [-88.6613, 42.7359],
            [-88.5577, 42.8001],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504290',
        NAME: 'Elkhorn Area School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 220204305,
        AWATER: 4744369,
        INTPTLAT: '+42.7010687',
        INTPTLON: '-088.5323657',
        ELSDLEA: '',
        UNSDLEA: '04290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.5613, 42.9887],
              [-88.5416, 42.9887],
              [-88.5613, 42.9745],
              [-88.5613, 42.9887],
            ],
          ],
          [
            [
              [-88.66, 42.9016],
              [-88.6602, 42.9378],
              [-88.5613, 42.9745],
              [-88.4662, 42.9303],
              [-88.4635, 42.8426],
              [-88.5571, 42.8222],
              [-88.6593, 42.8425],
              [-88.66, 42.9016],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511350',
        NAME: 'Palmyra-Eagle Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 207124067,
        AWATER: 2302909,
        INTPTLAT: '+42.8921795',
        INTPTLON: '-088.5650007',
        ELSDLEA: '',
        UNSDLEA: '11350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7766, 42.6675],
            [-88.7772, 42.6856],
            [-88.6613, 42.7359],
            [-88.5687, 42.6266],
            [-88.5651, 42.5865],
            [-88.6536, 42.5575],
            [-88.6995, 42.5458],
            [-88.7711, 42.5653],
            [-88.7766, 42.6675],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503640',
        NAME: 'Delavan-Darien School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248456172,
        AWATER: 9061066,
        INTPTLAT: '+42.6302531',
        INTPTLON: '-088.6954845',
        ELSDLEA: '',
        UNSDLEA: '03640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5651, 42.5865],
            [-88.5687, 42.6266],
            [-88.5309, 42.6123],
            [-88.512, 42.5971],
            [-88.5023, 42.5918],
            [-88.5193, 42.5717],
            [-88.5617, 42.5632],
            [-88.5651, 42.5865],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516740',
        NAME: 'Williams Bay School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 22733360,
        AWATER: 0,
        INTPTLAT: '+42.5921959',
        INTPTLON: '-088.5457402',
        ELSDLEA: '',
        UNSDLEA: '16740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1306, 44.3739],
            [-88.0571, 44.4626],
            [-88.0556, 44.4648],
            [-87.9319, 44.4442],
            [-87.9481, 44.3424],
            [-87.9492, 44.2848],
            [-87.9785, 44.2771],
            [-88.0092, 44.3575],
            [-88.1433, 44.3573],
            [-88.1443, 44.3572],
            [-88.1306, 44.3739],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503210',
        NAME: 'De Pere Unified School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 165261342,
        AWATER: 2530461,
        INTPTLAT: '+44.3788415',
        INTPTLON: '-088.0187167',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1398, 44.5175],
            [-88.1052, 44.5424],
            [-88.0295, 44.5574],
            [-87.9289, 44.5359],
            [-87.8241, 44.6275],
            [-87.8514, 44.4731],
            [-87.9319, 44.4442],
            [-88.0556, 44.4648],
            [-88.1398, 44.513],
            [-88.1398, 44.5175],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505820',
        NAME: 'Green Bay Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 239563136,
        AWATER: 3400768,
        INTPTLAT: '+44.5134838',
        INTPTLON: '-087.9416407',
        ELSDLEA: '',
        UNSDLEA: '05820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.3973, 44.8522],
            [-87.3366, 44.8079],
            [-87.3873, 44.7989],
            [-87.3973, 44.8522],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514610',
        NAME: 'Sturgeon Bay School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 20702513,
        AWATER: 4661297,
        INTPTLAT: '+44.8226756',
        INTPTLON: '-087.3698063',
        ELSDLEA: '',
        UNSDLEA: '14610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5142, 44.9795],
            [-87.4632, 45.0278],
            [-87.0164, 45.0255],
            [-87.2334, 44.7653],
            [-87.3366, 44.8079],
            [-87.3973, 44.8522],
            [-87.4281, 44.9371],
            [-87.5142, 44.9795],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513500',
        NAME: 'Sevastopol School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 301699285,
        AWATER: 364828661,
        INTPTLAT: '+44.9299799',
        INTPTLON: '-087.2619633',
        ELSDLEA: '',
        UNSDLEA: '13500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-86.872, 45.4439],
            [-86.7541, 45.4436],
            [-86.8677, 45.2834],
            [-86.9523, 45.2908],
            [-87.1172, 45.4233],
            [-86.872, 45.4439],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515570',
        NAME: 'Washington School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 65994275,
        AWATER: 256285826,
        INTPTLAT: '+45.3856416',
        INTPTLON: '-086.9257570',
        ELSDLEA: '',
        UNSDLEA: '15570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6624, 45.7607],
            [-91.5408, 45.7812],
            [-91.4192, 45.8121],
            [-91.4192, 45.7519],
            [-91.2963, 45.7259],
            [-91.2998, 45.6719],
            [-91.4196, 45.6378],
            [-91.419, 45.4648],
            [-91.5404, 45.4652],
            [-91.5403, 45.4725],
            [-91.5399, 45.5952],
            [-91.6624, 45.6672],
            [-91.6624, 45.7607],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501170',
        NAME: 'Birchwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 561470693,
        AWATER: 40612955,
        INTPTLAT: '+45.6635237',
        INTPTLON: '-091.4726085',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1544, 45.7256],
            [-92.1543, 45.816],
            [-91.9142, 45.7834],
            [-91.8203, 45.6545],
            [-91.847, 45.6389],
            [-92.0314, 45.6399],
            [-92.155, 45.6795],
            [-92.1544, 45.7256],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513710',
        NAME: 'Shell Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381024126,
        AWATER: 23136705,
        INTPTLAT: '+45.7177114',
        INTPTLON: '-091.9841573',
        ELSDLEA: '',
        UNSDLEA: '13710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9947, 44.2554],
            [-87.9785, 44.2771],
            [-87.9492, 44.2848],
            [-87.7918, 44.2402],
            [-87.7928, 44.1859],
            [-87.7719, 44.1532],
            [-87.8471, 44.1533],
            [-88.0104, 44.0847],
            [-87.9947, 44.2554],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512690',
        NAME: 'Reedsville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 287671353,
        AWATER: 1928094,
        INTPTLAT: '+44.1828418',
        INTPTLON: '-087.9108301',
        ELSDLEA: '',
        UNSDLEA: '12690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8976, 45.124],
            [-87.8685, 45.1318],
            [-87.7096, 45.0844],
            [-87.6565, 44.9813],
            [-87.7626, 44.9656],
            [-87.9029, 44.9932],
            [-87.8976, 45.124],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511610',
        NAME: 'Peshtigo School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 217172583,
        AWATER: 3908278,
        INTPTLAT: '+45.0382177',
        INTPTLON: '-087.8029294',
        ELSDLEA: '',
        UNSDLEA: '11610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2414, 45.062],
            [-88.1212, 45.0236],
            [-88.1446, 44.9376],
            [-88.0059, 44.8717],
            [-88.0191, 44.7897],
            [-87.9556, 44.7505],
            [-87.9879, 44.6776],
            [-87.9906, 44.6776],
            [-87.9937, 44.6775],
            [-88.0449, 44.6774],
            [-88.0564, 44.7501],
            [-88.1513, 44.7492],
            [-88.2502, 44.8095],
            [-88.2547, 44.9791],
            [-88.2414, 45.062],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510950',
        NAME: 'Oconto Falls School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 466653668,
        AWATER: 7885114,
        INTPTLAT: '+44.8652662',
        INTPTLON: '-088.1368470',
        ELSDLEA: '',
        UNSDLEA: '10950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6792, 45.2769],
            [-88.3091, 45.2602],
            [-88.3091, 45.2016],
            [-88.3126, 45.1138],
            [-88.2414, 45.062],
            [-88.2547, 44.9791],
            [-88.4884, 44.9435],
            [-88.4837, 45.1169],
            [-88.6412, 45.1173],
            [-88.6792, 45.2769],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514700',
        NAME: 'Suring School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 771610068,
        AWATER: 14282496,
        INTPTLAT: '+45.1237487',
        INTPTLON: '-088.4312940',
        ELSDLEA: '',
        UNSDLEA: '14700',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4884, 44.9435],
            [-88.2547, 44.9791],
            [-88.2502, 44.8095],
            [-88.3264, 44.8103],
            [-88.4514, 44.8551],
            [-88.4891, 44.8554],
            [-88.4884, 44.9435],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505250',
        NAME: 'Gillett School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 270058563,
        AWATER: 3795772,
        INTPTLAT: '+44.8995488',
        INTPTLON: '-088.3476860',
        ELSDLEA: '',
        UNSDLEA: '05250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0499, 45.6389],
            [-90.6787, 45.6383],
            [-90.6492, 45.6383],
            [-90.6473, 45.3776],
            [-90.6787, 45.3778],
            [-90.8636, 45.3786],
            [-90.9253, 45.2778],
            [-91.0489, 45.292],
            [-91.0852, 45.3779],
            [-91.0128, 45.4301],
            [-91.0496, 45.4924],
            [-91.0499, 45.6389],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514970',
        NAME: 'Flambeau School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1045503832,
        AWATER: 12572992,
        INTPTLAT: '+45.4807417',
        INTPTLON: '-090.8585726',
        ELSDLEA: '',
        UNSDLEA: '14970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6161, 45.0928],
            [-90.5571, 45.0311],
            [-90.4276, 45.0311],
            [-90.4279, 44.9809],
            [-90.4342, 44.9442],
            [-90.4651, 44.8715],
            [-90.5462, 44.8515],
            [-90.679, 44.873],
            [-90.6642, 44.9948],
            [-90.6994, 45.0313],
            [-90.6161, 45.0928],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511310',
        NAME: 'Owen-Withee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402269960,
        AWATER: 3725322,
        INTPTLAT: '+44.9698798',
        INTPTLON: '-090.5781084',
        ELSDLEA: '',
        UNSDLEA: '11310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6073, 44.3371],
            [-89.4477, 44.2474],
            [-89.365, 44.2387],
            [-89.3314, 44.1559],
            [-89.4423, 44.0687],
            [-89.598, 44.0685],
            [-89.5977, 44.1561],
            [-89.7171, 44.156],
            [-89.7247, 44.2477],
            [-89.6073, 44.3371],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511820',
        NAME: 'Tri-County Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 559337545,
        AWATER: 2508171,
        INTPTLAT: '+44.1929042',
        INTPTLON: '-089.5379633',
        ELSDLEA: '',
        UNSDLEA: '11820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.024, 44.4817],
            [-89.828, 44.5573],
            [-89.7266, 44.5112],
            [-89.6678, 44.3955],
            [-89.6071, 44.3665],
            [-89.6073, 44.3371],
            [-89.7247, 44.2477],
            [-89.8145, 44.249],
            [-89.8253, 44.3367],
            [-89.8901, 44.3948],
            [-90.0654, 44.3945],
            [-90.024, 44.4817],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5517070',
        NAME: 'Wisconsin Rapids School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 598093046,
        AWATER: 10221685,
        INTPTLAT: '+44.4164132',
        INTPTLON: '-089.8067769',
        ELSDLEA: '',
        UNSDLEA: '17070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3309, 43.546],
            [-90.3111, 43.554],
            [-90.1919, 43.5722],
            [-90.1921, 43.5516],
            [-90.073, 43.4675],
            [-90.0929, 43.3664],
            [-90.1427, 43.3663],
            [-90.1575, 43.3662],
            [-90.173, 43.3662],
            [-90.2462, 43.4631],
            [-90.2461, 43.4673],
            [-90.29, 43.4566],
            [-90.3309, 43.546],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516470',
        NAME: 'Weston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 257667692,
        AWATER: 454949,
        INTPTLAT: '+43.4724211',
        INTPTLON: '-090.1999249',
        ELSDLEA: '',
        UNSDLEA: '16470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8982, 43.3804],
            [-89.6009, 43.4241],
            [-89.6007, 43.3805],
            [-89.6301, 43.3587],
            [-89.5911, 43.2023],
            [-89.6001, 43.1907],
            [-89.7402, 43.2338],
            [-89.8381, 43.2061],
            [-89.9705, 43.224],
            [-90.0286, 43.3535],
            [-89.8982, 43.3804],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513410',
        NAME: 'Sauk Prairie School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 574931767,
        AWATER: 21304297,
        INTPTLAT: '+43.2993378',
        INTPTLON: '-089.7869798',
        ELSDLEA: '',
        UNSDLEA: '13410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.29, 43.4566],
            [-90.2461, 43.4673],
            [-90.2462, 43.4631],
            [-90.173, 43.3662],
            [-90.1575, 43.3662],
            [-90.1427, 43.3663],
            [-90.2469, 43.2857],
            [-90.3301, 43.3799],
            [-90.29, 43.4566],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506960',
        NAME: 'Ithaca School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228480137,
        AWATER: 44278,
        INTPTLAT: '+43.3525172',
        INTPTLON: '-090.2448835',
        ELSDLEA: '',
        UNSDLEA: '06960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2515, 43.2671],
            [-90.2467, 43.2773],
            [-90.2469, 43.2857],
            [-90.1427, 43.3663],
            [-90.0929, 43.3664],
            [-90.0286, 43.3535],
            [-89.9705, 43.224],
            [-89.8381, 43.2061],
            [-89.8889, 43.0809],
            [-89.9561, 43.0746],
            [-90.0952, 43.1212],
            [-90.2706, 43.0764],
            [-90.2707, 43.1193],
            [-90.2709, 43.1963],
            [-90.2515, 43.2671],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514250',
        NAME: 'River Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 747004646,
        AWATER: 19916317,
        INTPTLAT: '+43.2106321',
        INTPTLON: '-090.0910409',
        ELSDLEA: '',
        UNSDLEA: '14250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.8232, 43.5552],
            [-89.6595, 43.5674],
            [-89.5994, 43.558],
            [-89.6009, 43.4241],
            [-89.8982, 43.3804],
            [-89.8978, 43.507],
            [-89.8232, 43.5552],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500810',
        NAME: 'Baraboo School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 402147462,
        AWATER: 9348612,
        INTPTLAT: '+43.4782823',
        INTPTLON: '-089.7459306',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8837, 45.0751],
            [-90.6994, 45.0313],
            [-90.6642, 44.9948],
            [-90.679, 44.873],
            [-90.8004, 44.7708],
            [-90.9215, 44.7704],
            [-90.8596, 44.8576],
            [-90.8487, 44.9967],
            [-90.8837, 45.0751],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514820',
        NAME: 'Thorp School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 410904008,
        AWATER: 1771263,
        INTPTLAT: '+44.9237190',
        INTPTLON: '-090.7895073',
        ELSDLEA: '',
        UNSDLEA: '14820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0568, 45.0821],
            [-90.9999, 45.1303],
            [-90.8837, 45.0751],
            [-90.8487, 44.9967],
            [-90.8596, 44.8576],
            [-90.9215, 44.7704],
            [-90.9834, 44.7708],
            [-91.0406, 44.8572],
            [-91.0568, 45.0821],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514430',
        NAME: 'Stanley-Boyd Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 466528217,
        AWATER: 4079662,
        INTPTLAT: '+44.9623659',
        INTPTLON: '-090.9533251',
        ELSDLEA: '',
        UNSDLEA: '14430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2091, 44.1982],
            [-88.1683, 44.2204],
            [-88.1629, 44.2414],
            [-88.1526, 44.2413],
            [-88.0099, 44.2554],
            [-87.9947, 44.2554],
            [-88.0104, 44.0847],
            [-88.0429, 44.0664],
            [-88.0839, 44.11],
            [-88.1043, 44.1697],
            [-88.2091, 44.1982],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501680',
        NAME: 'Brillion School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 157897713,
        AWATER: 2048115,
        INTPTLAT: '+44.1843012',
        INTPTLON: '-088.0788906',
        ELSDLEA: '',
        UNSDLEA: '01680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3038, 44.1505],
            [-88.2091, 44.1982],
            [-88.1043, 44.1697],
            [-88.0839, 44.11],
            [-88.2289, 44.083],
            [-88.229, 44.0844],
            [-88.2327, 44.0844],
            [-88.3203, 44.1226],
            [-88.3038, 44.1505],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506450',
        NAME: 'Hilbert School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 142170114,
        AWATER: 747308,
        INTPTLAT: '+44.1373109',
        INTPTLON: '-088.1968089',
        ELSDLEA: '',
        UNSDLEA: '06450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4046, 44.3291],
            [-88.3434, 44.3287],
            [-88.3435, 44.2832],
            [-88.3435, 44.2772],
            [-88.3635, 44.2043],
            [-88.4044, 44.2093],
            [-88.4406, 44.2435],
            [-88.4968, 44.2441],
            [-88.4046, 44.3291],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500390',
        NAME: 'Appleton Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 113586861,
        AWATER: 1698950,
        INTPTLAT: '+44.2794052',
        INTPTLON: '-088.4137937',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.1278, 43.7702],
              [-89.0607, 43.8055],
              [-88.956, 43.8004],
              [-88.924, 43.7933],
              [-88.8656, 43.7496],
              [-88.8551, 43.7132],
              [-88.8861, 43.6335],
              [-88.9991, 43.6222],
              [-89.0368, 43.633],
              [-89.2014, 43.6285],
              [-89.2512, 43.6431],
              [-89.239, 43.7665],
              [-89.1278, 43.7702],
            ],
          ],
          [
            [
              [-88.9147, 43.8144],
              [-88.906, 43.8144],
              [-88.9059, 43.8078],
              [-88.9147, 43.8144],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508760',
        NAME: 'Markesan School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 435473461,
        AWATER: 63029972,
        INTPTLAT: '+43.7125461',
        INTPTLON: '-089.0434869',
        ELSDLEA: '',
        UNSDLEA: '08760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9564, 42.7661],
            [-89.7598, 42.7846],
            [-89.7356, 42.7631],
            [-89.7248, 42.7087],
            [-89.7982, 42.6463],
            [-89.9283, 42.6617],
            [-89.9564, 42.7661],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500450',
        NAME: 'Argyle School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 214496712,
        AWATER: 178373,
        INTPTLAT: '+42.7173740',
        INTPTLON: '-089.8505592',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4226, 42.8129],
            [-90.3086, 42.8141],
            [-90.2056, 42.7619],
            [-90.2549, 42.6574],
            [-90.2599, 42.6534],
            [-90.2696, 42.6534],
            [-90.2696, 42.6462],
            [-90.2999, 42.639],
            [-90.3969, 42.6674],
            [-90.4226, 42.8129],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501020',
        NAME: 'Belmont Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263444718,
        AWATER: 300026,
        INTPTLAT: '+42.7453556',
        INTPTLON: '-090.3052377',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0149, 42.9176],
            [-89.9561, 42.9157],
            [-89.8636, 42.9155],
            [-89.7711, 42.8721],
            [-89.7598, 42.7846],
            [-89.9564, 42.7661],
            [-89.9947, 42.7888],
            [-90.0149, 42.9176],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501310',
        NAME: 'Pecatonica Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292911722,
        AWATER: 72991,
        INTPTLAT: '+42.8249888',
        INTPTLON: '-089.9032876',
        ELSDLEA: '',
        UNSDLEA: '01310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.7606, 43.8077],
              [-87.7499, 43.8094],
              [-87.7717, 43.8279],
              [-87.7607, 43.8374],
              [-87.7284, 43.8493],
              [-87.7298, 43.8377],
              [-87.7297, 43.8311],
              [-87.7265, 43.8169],
              [-87.7265, 43.8147],
              [-87.7241, 43.6542],
              [-87.7491, 43.6461],
              [-87.7806, 43.681],
              [-87.7899, 43.7601],
              [-87.8007, 43.798],
              [-87.7606, 43.8077],
            ],
          ],
          [
            [
              [-87.7718, 43.9501],
              [-87.7203, 43.9316],
              [-87.733, 43.8884],
              [-87.7906, 43.892],
              [-87.7718, 43.9501],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513650',
        NAME: 'Sheboygan Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 130962229,
        AWATER: 946951,
        INTPTLAT: '+43.7889483',
        INTPTLON: '-087.7467139',
        ELSDLEA: '',
        UNSDLEA: '13650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9209, 43.6109],
            [-87.7694, 43.5944],
            [-87.7954, 43.4848],
            [-87.8806, 43.5141],
            [-87.9209, 43.6109],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502430',
        NAME: 'Cedar Grove-Belgium Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 98760080,
        AWATER: 434874,
        INTPTLAT: '+43.5509608',
        INTPTLON: '-087.8390018',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4218, 43.7095],
            [-88.281, 43.7504],
            [-88.2813, 43.8082],
            [-88.1612, 43.8082],
            [-88.1611, 43.7973],
            [-88.1611, 43.7901],
            [-88.1609, 43.7829],
            [-88.1489, 43.7321],
            [-88.1599, 43.6313],
            [-88.2605, 43.5501],
            [-88.391, 43.5435],
            [-88.4323, 43.6894],
            [-88.4218, 43.7095],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502160',
        NAME: 'Campbellsport School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 458100523,
        AWATER: 8270973,
        INTPTLAT: '+43.6632292',
        INTPTLON: '-088.2713100',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1023, 43.8917],
            [-87.9222, 43.8549],
            [-87.9215, 43.8049],
            [-88.081, 43.7814],
            [-88.1612, 43.8117],
            [-88.1023, 43.8917],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504260',
        NAME: 'Elkhart Lake-Glenbeulah School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146163439,
        AWATER: 5123446,
        INTPTLAT: '+43.8325461',
        INTPTLON: '-088.0436372',
        ELSDLEA: '',
        UNSDLEA: '04260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7899, 43.7601],
            [-87.7806, 43.681],
            [-87.7903, 43.6741],
            [-87.8004, 43.6741],
            [-87.7899, 43.7601],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507500',
        NAME: 'Kohler School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26503761,
        AWATER: 606402,
        INTPTLAT: '+43.7231306',
        INTPTLON: '-087.7870114',
        ELSDLEA: '',
        UNSDLEA: '07500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5782, 44.7759],
            [-92.4108, 44.8297],
            [-92.3073, 44.7258],
            [-92.307, 44.5741],
            [-92.2496, 44.5578],
            [-92.3162, 44.541],
            [-92.4111, 44.5601],
            [-92.5399, 44.5675],
            [-92.6202, 44.6342],
            [-92.6287, 44.7179],
            [-92.5782, 44.7759],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504320',
        NAME: 'Ellsworth Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 558334506,
        AWATER: 34973919,
        INTPTLAT: '+44.6780036',
        INTPTLON: '-092.4526748',
        ELSDLEA: '',
        UNSDLEA: '04320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4002, 44.8623],
            [-92.2173, 44.9017],
            [-92.2004, 44.9516],
            [-92.1357, 44.9662],
            [-92.1356, 44.9587],
            [-92.1354, 44.9486],
            [-92.1432, 44.9448],
            [-92.1363, 44.8578],
            [-92.1363, 44.853],
            [-92.1363, 44.8438],
            [-92.1567, 44.8433],
            [-92.1567, 44.847],
            [-92.2669, 44.7121],
            [-92.3073, 44.7258],
            [-92.4108, 44.8297],
            [-92.4002, 44.8623],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514340',
        NAME: 'Spring Valley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 284851148,
        AWATER: 2061570,
        INTPTLAT: '+44.8416352',
        INTPTLON: '-092.2506058',
        ELSDLEA: '',
        UNSDLEA: '14340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2614, 44.6834],
            [-91.1653, 44.7567],
            [-91.1408, 44.8569],
            [-91.0406, 44.8572],
            [-90.9834, 44.7708],
            [-90.9828, 44.6832],
            [-91.0436, 44.5967],
            [-91.1656, 44.597],
            [-91.2614, 44.6834],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500630',
        NAME: 'Augusta School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 417967595,
        AWATER: 5052629,
        INTPTLAT: '+44.7118118',
        INTPTLON: '-091.0960034',
        ELSDLEA: '',
        UNSDLEA: '00630',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7261, 44.6581],
            [-91.6902, 44.6837],
            [-91.679, 44.6837],
            [-91.6756, 44.6837],
            [-91.5249, 44.6536],
            [-91.5145, 44.6539],
            [-91.5092, 44.6536],
            [-91.5289, 44.513],
            [-91.7716, 44.5096],
            [-91.8267, 44.4227],
            [-91.8521, 44.4368],
            [-91.8428, 44.5966],
            [-91.7261, 44.6581],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509780',
        NAME: 'Mondovi School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 481377485,
        AWATER: 1313886,
        INTPTLAT: '+44.5757676',
        INTPTLON: '-091.6813016',
        ELSDLEA: '',
        UNSDLEA: '09780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.4406, 44.2435],
            [-88.4044, 44.2093],
            [-88.4341, 44.1936],
            [-88.4406, 44.2435],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509030',
        NAME: 'Menasha School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19528306,
        AWATER: 3012100,
        INTPTLAT: '+44.2190585',
        INTPTLON: '-088.4330852',
        ELSDLEA: '',
        UNSDLEA: '09030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9296, 43.6602],
            [-87.8004, 43.6741],
            [-87.7903, 43.6741],
            [-87.7806, 43.681],
            [-87.7491, 43.6461],
            [-87.7241, 43.6542],
            [-87.7694, 43.5944],
            [-87.9209, 43.6109],
            [-87.9296, 43.6602],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511070',
        NAME: 'Oostburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 102915803,
        AWATER: 637927,
        INTPTLAT: '+43.6413959',
        INTPTLON: '-087.8452108',
        ELSDLEA: '',
        UNSDLEA: '11070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0274, 45.1627],
            [-90.9259, 45.1626],
            [-90.9253, 45.2778],
            [-90.8636, 45.3786],
            [-90.6787, 45.3778],
            [-90.6793, 45.2912],
            [-90.5565, 45.2909],
            [-90.5565, 45.1627],
            [-90.6161, 45.0928],
            [-90.6994, 45.0313],
            [-90.8837, 45.0751],
            [-90.9999, 45.1303],
            [-91.007, 45.135],
            [-91.0274, 45.1627],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505280',
        NAME: 'Gilman School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 839174522,
        AWATER: 13848138,
        INTPTLAT: '+45.2206736',
        INTPTLON: '-090.7430537',
        ELSDLEA: '',
        UNSDLEA: '05280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.326, 45.2918],
            [-91.1736, 45.3781],
            [-91.0852, 45.3779],
            [-91.0489, 45.292],
            [-90.9253, 45.2778],
            [-90.9259, 45.1626],
            [-91.0274, 45.1627],
            [-91.1478, 45.2058],
            [-91.3064, 45.205],
            [-91.326, 45.2918],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507680',
        NAME: 'Lake Holcombe School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 452246755,
        AWATER: 19844511,
        INTPTLAT: '+45.2511317',
        INTPTLON: '-091.1234011',
        ELSDLEA: '',
        UNSDLEA: '07680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.847, 45.6389],
            [-91.8203, 45.6545],
            [-91.6624, 45.7607],
            [-91.6624, 45.6672],
            [-91.5399, 45.5952],
            [-91.5403, 45.4725],
            [-91.7656, 45.4452],
            [-91.8683, 45.5167],
            [-91.9082, 45.6101],
            [-91.847, 45.6389],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512810',
        NAME: 'Rice Lake Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 577781689,
        AWATER: 35882419,
        INTPTLAT: '+45.5593666',
        INTPTLON: '-091.7173764',
        ELSDLEA: '',
        UNSDLEA: '12810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6361, 44.5025],
            [-88.577, 44.5906],
            [-88.5042, 44.5897],
            [-88.4583, 44.4233],
            [-88.4756, 44.3877],
            [-88.6555, 44.3891],
            [-88.6361, 44.5025],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513770',
        NAME: 'Shiocton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 274825779,
        AWATER: 6157142,
        INTPTLAT: '+44.4712902',
        INTPTLON: '-088.5655162',
        ELSDLEA: '',
        UNSDLEA: '13770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1116, 43.9709],
            [-91.0321, 43.976],
            [-91.0047, 43.8369],
            [-90.9872, 43.8122],
            [-90.979, 43.8079],
            [-90.9788, 43.7971],
            [-90.9739, 43.7728],
            [-91.0311, 43.7674],
            [-91.1499, 43.7968],
            [-91.1717, 43.8675],
            [-91.1796, 43.9033],
            [-91.1116, 43.9709],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516350',
        NAME: 'West Salem School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245584831,
        AWATER: 2520305,
        INTPTLAT: '+43.8687892',
        INTPTLON: '-091.0903675',
        ELSDLEA: '',
        UNSDLEA: '16350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3652, 43.6426],
            [-89.2512, 43.6431],
            [-89.2014, 43.6285],
            [-89.1719, 43.578],
            [-89.1927, 43.5633],
            [-89.2129, 43.494],
            [-89.3151, 43.4665],
            [-89.3101, 43.4557],
            [-89.3638, 43.4625],
            [-89.3652, 43.6426],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511370',
        NAME: 'Pardeeville Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 230768978,
        AWATER: 7939959,
        INTPTLAT: '+43.5595225',
        INTPTLON: '-089.2890582',
        ELSDLEA: '',
        UNSDLEA: '11370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5629, 43.6938],
            [-89.4835, 43.7304],
            [-89.3652, 43.6426],
            [-89.3638, 43.4625],
            [-89.4808, 43.4567],
            [-89.5348, 43.4149],
            [-89.6007, 43.3805],
            [-89.6009, 43.4241],
            [-89.5994, 43.558],
            [-89.6595, 43.5674],
            [-89.6599, 43.6428],
            [-89.5629, 43.6938],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512060',
        NAME: 'Portage Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 524311304,
        AWATER: 33153447,
        INTPTLAT: '+43.5707259',
        INTPTLON: '-089.5140864',
        ELSDLEA: '',
        UNSDLEA: '12060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1975, 45.0336],
            [-90.1508, 45.1204],
            [-90.0524, 45.1202],
            [-89.9645, 45.1202],
            [-89.9058, 45.0328],
            [-89.9466, 45.0168],
            [-90.0353, 44.9438],
            [-90.1292, 44.9453],
            [-90.1976, 44.9924],
            [-90.1975, 45.0336],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500570',
        NAME: 'Athens School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 332412162,
        AWATER: 24545,
        INTPTLAT: '+45.0414473',
        INTPTLON: '-090.0701783',
        ELSDLEA: '',
        UNSDLEA: '00570',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.9421, 44.8153],
              [-89.9272, 44.8154],
              [-89.9272, 44.8113],
              [-89.9372, 44.8079],
              [-89.9421, 44.8153],
            ],
          ],
          [
            [
              [-89.9372, 44.8079],
              [-89.9271, 44.8079],
              [-89.9116, 44.8154],
              [-89.8966, 44.83],
              [-89.7274, 44.8578],
              [-89.6595, 44.8584],
              [-89.6499, 44.8289],
              [-89.3978, 44.8287],
              [-89.4272, 44.7713],
              [-89.4903, 44.6859],
              [-89.8451, 44.6849],
              [-89.9263, 44.7136],
              [-89.9372, 44.8079],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509960',
        NAME: 'Mosinee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 550524460,
        AWATER: 50197990,
        INTPTLAT: '+44.7668207',
        INTPTLON: '-089.6844736',
        ELSDLEA: '',
        UNSDLEA: '09960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4272, 44.7713],
            [-89.3978, 44.8287],
            [-89.3469, 44.9864],
            [-89.1016, 44.9726],
            [-89.0722, 44.8113],
            [-89.1634, 44.7688],
            [-89.1845, 44.681],
            [-89.3459, 44.6814],
            [-89.3464, 44.7627],
            [-89.4272, 44.7713],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5517100',
        NAME: 'Wittenberg-Birnamwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 662517900,
        AWATER: 5349453,
        INTPTLAT: '+44.8454130',
        INTPTLON: '-089.2343679',
        ELSDLEA: '',
        UNSDLEA: '17100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.524, 43.8937],
            [-88.4676, 43.8934],
            [-88.4691, 43.8065],
            [-88.5237, 43.7773],
            [-88.5439, 43.78],
            [-88.524, 43.8937],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510740',
        NAME: 'North Fond du Lac School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 72698417,
        AWATER: 488367,
        INTPTLAT: '+43.8284186',
        INTPTLON: '-088.5137876',
        ELSDLEA: '',
        UNSDLEA: '10740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4263, 45.8265],
            [-89.0478, 45.9244],
            [-89.0466, 45.5515],
            [-89.2368, 45.5544],
            [-89.1977, 45.7276],
            [-89.4255, 45.732],
            [-89.4263, 45.8265],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514850',
        NAME: 'Three Lakes School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 693864646,
        AWATER: 83835470,
        INTPTLAT: '+45.7540800',
        INTPTLON: '-089.2135884',
        ELSDLEA: '',
        UNSDLEA: '14850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0656, 43.1191],
            [-88.0634, 43.1921],
            [-87.9943, 43.1925],
            [-87.9508, 43.163],
            [-87.9466, 43.1598],
            [-87.91, 43.1046],
            [-87.9071, 43.1003],
            [-87.8706, 43.0819],
            [-87.8689, 42.9866],
            [-87.8832, 42.9591],
            [-87.8877, 42.93],
            [-87.9494, 42.9304],
            [-88.0277, 42.9737],
            [-88.0376, 42.981],
            [-88.0472, 43.031],
            [-88.066, 43.1042],
            [-88.0656, 43.1191],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509600',
        NAME: 'Milwaukee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 248812869,
        AWATER: 1670094,
        INTPTLAT: '+43.0633484',
        INTPTLON: '-087.9666952',
        ELSDLEA: '',
        UNSDLEA: '09600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0661, 43.0984],
            [-88.066, 43.1042],
            [-88.0472, 43.031],
            [-88.067, 43.0309],
            [-88.0661, 43.0984],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515990',
        NAME: 'Wauwatosa School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 34304292,
        AWATER: 9579,
        INTPTLAT: '+43.0631648',
        INTPTLON: '-088.0355835',
        ELSDLEA: '',
        UNSDLEA: '15990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1805, 46.1554],
            [-88.9909, 46.0974],
            [-88.9328, 46.0737],
            [-88.9332, 45.9821],
            [-89.0478, 45.9823],
            [-89.1815, 45.9824],
            [-89.1805, 46.1554],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511710',
        NAME: 'Phelps School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 240993053,
        AWATER: 39393063,
        INTPTLAT: '+46.0472213',
        INTPTLON: '-089.0671876',
        ELSDLEA: '',
        UNSDLEA: '11710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5391, 43.0751],
            [-90.5298, 43.0751],
            [-90.503, 42.96],
            [-90.5464, 42.8568],
            [-90.5581, 42.8568],
            [-90.7846, 42.9449],
            [-90.8162, 43.018],
            [-90.5391, 43.0751],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504590',
        NAME: 'Fennimore Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 381143711,
        AWATER: 0,
        INTPTLAT: '+42.9778862',
        INTPTLON: '-090.6371253',
        ELSDLEA: '',
        UNSDLEA: '04590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.8069, 42.7452],
            [-90.807, 42.7479],
            [-90.7957, 42.763],
            [-90.7212, 42.7595],
            [-90.6622, 42.7574],
            [-90.6536, 42.7538],
            [-90.6388, 42.7537],
            [-90.6227, 42.6779],
            [-90.6222, 42.6714],
            [-90.6223, 42.6699],
            [-90.6611, 42.5742],
            [-90.6615, 42.568],
            [-90.7202, 42.6408],
            [-90.8282, 42.6608],
            [-90.8069, 42.7452],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512090',
        NAME: 'Potosi School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166537535,
        AWATER: 37761821,
        INTPTLAT: '+42.6913119',
        INTPTLON: '-090.7015878',
        ELSDLEA: '',
        UNSDLEA: '12090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5142, 44.9795],
            [-87.4281, 44.9371],
            [-87.3973, 44.8522],
            [-87.3873, 44.7989],
            [-87.3366, 44.8079],
            [-87.2334, 44.7653],
            [-87.3015, 44.6758],
            [-87.3753, 44.6755],
            [-87.5902, 44.6755],
            [-87.7364, 44.677],
            [-87.7625, 44.7644],
            [-87.5513, 45.008],
            [-87.5142, 44.9795],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514130',
        NAME: 'Southern Door County School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 508307795,
        AWATER: 368886630,
        INTPTLAT: '+44.7980097',
        INTPTLON: '-087.5243750',
        ELSDLEA: '',
        UNSDLEA: '14130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2237, 44.4174],
            [-89.0821, 44.4611],
            [-88.9812, 44.4029],
            [-89.0664, 44.2282],
            [-89.2244, 44.2745],
            [-89.3143, 44.3355],
            [-89.2237, 44.4174],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515840',
        NAME: 'Waupaca School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 403774224,
        AWATER: 9261765,
        INTPTLAT: '+44.3430229',
        INTPTLON: '-089.1366669',
        ELSDLEA: '',
        UNSDLEA: '15840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8598, 44.3815],
            [-88.7975, 44.5027],
            [-88.6361, 44.5025],
            [-88.6555, 44.3891],
            [-88.7378, 44.3374],
            [-88.6184, 44.2437],
            [-88.6265, 44.2435],
            [-88.6838, 44.2132],
            [-88.8048, 44.2385],
            [-88.8601, 44.3011],
            [-88.8598, 44.3815],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510590',
        NAME: 'New London School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390337106,
        AWATER: 8451578,
        INTPTLAT: '+44.3663447',
        INTPTLON: '-088.7492255',
        ELSDLEA: '',
        UNSDLEA: '10590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8903, 44.5752],
            [-88.7737, 44.7228],
            [-88.6054, 44.651],
            [-88.577, 44.5906],
            [-88.6361, 44.5025],
            [-88.7975, 44.5027],
            [-88.8903, 44.5752],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502670',
        NAME: 'Clintonville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 415301762,
        AWATER: 4930248,
        INTPTLAT: '+44.5995450',
        INTPTLON: '-088.7289162',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5711, 44.6836],
            [-90.5367, 44.8136],
            [-90.5462, 44.8515],
            [-90.4651, 44.8715],
            [-90.3983, 44.7997],
            [-90.3363, 44.6851],
            [-90.3773, 44.6551],
            [-90.501, 44.6327],
            [-90.5711, 44.6836],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508250',
        NAME: 'Loyal School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 296217528,
        AWATER: 510748,
        INTPTLAT: '+44.7477849',
        INTPTLON: '-090.4664107',
        ELSDLEA: '',
        UNSDLEA: '08250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-87.903, 43.2804],
              [-87.8839, 43.3388],
              [-87.8105, 43.4559],
              [-87.7954, 43.4848],
              [-87.7694, 43.5944],
              [-87.7241, 43.6542],
              [-87.7265, 43.8147],
              [-87.7265, 43.8169],
              [-87.7297, 43.8311],
              [-87.7298, 43.8377],
              [-87.7284, 43.8493],
              [-87.736, 43.8776],
              [-87.7361, 43.8796],
              [-87.7357, 43.8814],
              [-87.7353, 43.8825],
              [-87.7343, 43.885],
              [-87.7339, 43.8859],
              [-87.733, 43.8884],
              [-87.7203, 43.9316],
              [-87.6168, 44.1247],
              [-87.531, 44.166],
              [-87.5128, 44.2404],
              [-87.5365, 44.2841],
              [-87.4711, 44.5451],
              [-87.3753, 44.6755],
              [-87.3015, 44.6758],
              [-87.2334, 44.7653],
              [-87.0164, 45.0255],
              [-87.0001, 45.1241],
              [-86.8677, 45.2834],
              [-86.7541, 45.4436],
              [-86.4641, 45.3248],
              [-86.2501, 45.2357],
              [-86.3311, 45.1867],
              [-86.4999, 45.0808],
              [-86.6863, 44.881],
              [-86.7345, 44.7731],
              [-86.8469, 44.5194],
              [-86.9311, 44.3111],
              [-86.9993, 44.1567],
              [-87.0931, 43.8201],
              [-87.1141, 43.7341],
              [-87.1266, 43.6469],
              [-87.1403, 43.4714],
              [-87.1472, 43.3799],
              [-87.0199, 42.4935],
              [-87.8005, 42.4919],
              [-87.8056, 42.6682],
              [-87.7577, 42.7819],
              [-87.8263, 42.8422],
              [-87.8483, 42.8931],
              [-87.846, 42.9302],
              [-87.8481, 42.9662],
              [-87.8689, 42.9866],
              [-87.8706, 43.0819],
              [-87.8765, 43.0989],
              [-87.9014, 43.1332],
              [-87.8926, 43.192],
              [-87.903, 43.2804],
            ],
          ],
          [
            [
              [-87.9937, 44.6775],
              [-87.9906, 44.6776],
              [-87.9879, 44.6776],
              [-87.9556, 44.7505],
              [-87.7626, 44.9656],
              [-87.6565, 44.9813],
              [-87.5948, 45.0946],
              [-87.6612, 45.1091],
              [-87.4428, 45.0765],
              [-87.4632, 45.0278],
              [-87.5142, 44.9795],
              [-87.5513, 45.008],
              [-87.7625, 44.7644],
              [-87.7364, 44.677],
              [-87.8241, 44.6275],
              [-87.9289, 44.5359],
              [-88.0295, 44.5574],
              [-87.9937, 44.6775],
            ],
          ],
          [
            [
              [-88.512, 42.5971],
              [-88.4643, 42.605],
              [-88.5023, 42.5918],
              [-88.512, 42.5971],
            ],
          ],
          [
            [
              [-88.5162, 44.0633],
              [-88.4859, 44.0978],
              [-88.4341, 44.1936],
              [-88.4044, 44.2093],
              [-88.3635, 44.2043],
              [-88.3038, 44.1505],
              [-88.3203, 44.1226],
              [-88.3243, 44.0005],
              [-88.3149, 43.9377],
              [-88.3619, 43.8739],
              [-88.4691, 43.8065],
              [-88.4676, 43.8934],
              [-88.5357, 43.9909],
              [-88.5162, 44.0633],
            ],
          ],
          [
            [
              [-88.5617, 42.5632],
              [-88.5193, 42.5717],
              [-88.4628, 42.5765],
              [-88.4371, 42.5664],
              [-88.4824, 42.5638],
              [-88.5407, 42.5485],
              [-88.5617, 42.5632],
            ],
          ],
          [
            [
              [-88.6074, 44.0649],
              [-88.6426, 44.0546],
              [-88.6627, 44.0698],
              [-88.6784, 44.0741],
              [-88.6779, 44.0762],
              [-88.6727, 44.0795],
              [-88.6816, 44.0801],
              [-88.6833, 44.0792],
              [-88.6935, 44.0763],
              [-88.6943, 44.0765],
              [-88.6342, 44.0947],
              [-88.6074, 44.0649],
            ],
          ],
          [
            [
              [-89.4824, 43.0945],
              [-89.4418, 43.1188],
              [-89.4103, 43.141],
              [-89.3839, 43.0813],
              [-89.4824, 43.0945],
            ],
          ],
          [
            [
              [-91.5494, 46.8932],
              [-90.7952, 47.2443],
              [-90.6547, 47.3098],
              [-89.9571, 47.2911],
              [-90.1117, 47.042],
              [-90.2696, 46.7982],
              [-90.449, 46.7974],
              [-90.5499, 46.5836],
              [-90.7552, 46.6466],
              [-90.9254, 46.5873],
              [-90.8348, 46.6592],
              [-90.8596, 46.7743],
              [-90.7674, 46.8847],
              [-90.856, 46.9624],
              [-90.9688, 46.944],
              [-91.0519, 46.8822],
              [-91.5514, 46.7558],
              [-91.5494, 46.8932],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5599997',
        NAME: 'School District Not Defined',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'F',
        ALAND: 157365894,
        AWATER: 22460950725,
        INTPTLAT: '+43.9003967',
        INTPTLON: '-088.4511452',
        ELSDLEA: '',
        UNSDLEA: '99997',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3091, 45.2602],
            [-88.3058, 45.3746],
            [-88.0599, 45.3689],
            [-88.0604, 45.3389],
            [-87.8158, 45.3194],
            [-87.8186, 45.25],
            [-87.8685, 45.1318],
            [-87.8976, 45.124],
            [-87.9649, 45.1725],
            [-88.3091, 45.2016],
            [-88.3091, 45.2602],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502970',
        NAME: 'Crivitz School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 710060269,
        AWATER: 30426885,
        INTPTLAT: '+45.2807756',
        INTPTLON: '-088.0802643',
        ELSDLEA: '',
        UNSDLEA: '02970',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5828, 45.7226],
            [-88.2399, 45.7196],
            [-88.2412, 45.5463],
            [-88.4261, 45.5493],
            [-88.426, 45.6215],
            [-88.5516, 45.6217],
            [-88.5828, 45.7226],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505580',
        NAME: 'Goodman-Armstrong School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 406025572,
        AWATER: 6401267,
        INTPTLAT: '+45.6499084',
        INTPTLON: '-088.3935961',
        ELSDLEA: '',
        UNSDLEA: '05580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0583, 45.7806],
            [-87.9422, 45.7577],
            [-87.8123, 45.7113],
            [-87.8028, 45.6667],
            [-88.0589, 45.6846],
            [-88.0592, 45.7131],
            [-88.0583, 45.7806],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510680',
        NAME: 'Niagara School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 181068031,
        AWATER: 3694935,
        INTPTLAT: '+45.7216205',
        INTPTLON: '-087.9358049',
        ELSDLEA: '',
        UNSDLEA: '10680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9242, 43.0154],
            [-88.8162, 43.0649],
            [-88.6398, 43.0486],
            [-88.5709, 43.0323],
            [-88.5613, 42.9887],
            [-88.5613, 42.9745],
            [-88.6602, 42.9378],
            [-88.9348, 43.0007],
            [-88.9242, 43.0154],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507050',
        NAME: 'Jefferson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 272294394,
        AWATER: 3809527,
        INTPTLAT: '+43.0011974',
        INTPTLON: '-088.7500962',
        ELSDLEA: '',
        UNSDLEA: '07050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.782, 43.1311],
            [-88.6398, 43.0486],
            [-88.8162, 43.0649],
            [-88.782, 43.1311],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507170',
        NAME: 'Johnson Creek School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 107888082,
        AWATER: 1552261,
        INTPTLAT: '+43.0900748',
        INTPTLON: '-088.7251870',
        ELSDLEA: '',
        UNSDLEA: '07170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0121, 43.1106],
            [-88.964, 43.1257],
            [-88.8906, 43.1979],
            [-88.8325, 43.1753],
            [-88.782, 43.1311],
            [-88.8162, 43.0649],
            [-88.9242, 43.0154],
            [-88.977, 43.0379],
            [-89.0113, 43.0749],
            [-89.0121, 43.1106],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507710',
        NAME: 'Lake Mills Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 192447209,
        AWATER: 9172685,
        INTPTLAT: '+43.0965098',
        INTPTLON: '-088.9007259',
        ELSDLEA: '',
        UNSDLEA: '07710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5092, 44.6536],
            [-91.3673, 44.6501],
            [-91.3172, 44.5048],
            [-91.3883, 44.4966],
            [-91.5291, 44.4724],
            [-91.5289, 44.513],
            [-91.5092, 44.6536],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504200',
        NAME: 'Eleva-Strum School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 322703320,
        AWATER: 582434,
        INTPTLAT: '+44.5549230',
        INTPTLON: '-091.4253937',
        ELSDLEA: '',
        UNSDLEA: '04200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5404, 44.0882],
            [-91.4823, 44.1515],
            [-91.3636, 44.2075],
            [-91.1525, 44.1706],
            [-91.2083, 44.0726],
            [-91.3075, 44.0649],
            [-91.3221, 43.9845],
            [-91.4252, 43.9843],
            [-91.559, 44.0253],
            [-91.5404, 44.0882],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504960',
        NAME: 'Galesville-Ettrick-Trempealeau School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 464914669,
        AWATER: 21444339,
        INTPTLAT: '+44.0989228',
        INTPTLON: '-091.3424413',
        ELSDLEA: '',
        UNSDLEA: '04960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5904, 44.4221],
            [-91.5291, 44.4724],
            [-91.3883, 44.4966],
            [-91.3581, 44.3129],
            [-91.358, 44.2908],
            [-91.491, 44.3106],
            [-91.5784, 44.3703],
            [-91.5827, 44.3745],
            [-91.5827, 44.3803],
            [-91.5953, 44.4003],
            [-91.5904, 44.4221],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506810',
        NAME: 'Independence School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 252087047,
        AWATER: 738088,
        INTPTLAT: '+44.3833000',
        INTPTLON: '-091.4472405',
        ELSDLEA: '',
        UNSDLEA: '06810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7928, 44.1859],
            [-87.6398, 44.173],
            [-87.6168, 44.1247],
            [-87.7203, 43.9316],
            [-87.7718, 43.9501],
            [-87.7815, 43.9791],
            [-87.7719, 44.1532],
            [-87.7928, 44.1859],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508610',
        NAME: 'Manitowoc School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 233963548,
        AWATER: 2553484,
        INTPTLAT: '+44.0827652',
        INTPTLON: '-087.7139475',
        ELSDLEA: '',
        UNSDLEA: '08610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.7918, 44.2402],
            [-87.6847, 44.3275],
            [-87.5365, 44.2841],
            [-87.5128, 44.2404],
            [-87.6398, 44.173],
            [-87.7928, 44.1859],
            [-87.7918, 44.2402],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509750',
        NAME: 'Mishicot School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263203635,
        AWATER: 1718035,
        INTPTLAT: '+44.2476804',
        INTPTLON: '-087.6726073',
        ELSDLEA: '',
        UNSDLEA: '09750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.952, 42.8435],
            [-87.8263, 42.8422],
            [-87.7577, 42.7819],
            [-87.8056, 42.6682],
            [-87.9531, 42.6696],
            [-87.954, 42.7568],
            [-87.952, 42.8435],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512360',
        NAME: 'Racine School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 259673707,
        AWATER: 1771561,
        INTPTLAT: '+42.7540735',
        INTPTLON: '-087.8730834',
        ELSDLEA: '',
        UNSDLEA: '12360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6742, 46.0704],
            [-91.5507, 46.0705],
            [-91.5513, 46.157],
            [-90.9645, 46.1551],
            [-90.9246, 46.068],
            [-90.9252, 45.9811],
            [-91.0501, 45.9814],
            [-91.0495, 45.8999],
            [-91.398, 45.8709],
            [-91.4192, 45.8121],
            [-91.5408, 45.7812],
            [-91.6037, 45.8136],
            [-91.6036, 45.9831],
            [-91.6742, 46.0704],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506270',
        NAME: 'Hayward Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1386044834,
        AWATER: 200327846,
        INTPTLAT: '+46.0146554',
        INTPTLON: '-091.3252947',
        ELSDLEA: '',
        UNSDLEA: '06270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.4192, 45.8121],
            [-91.398, 45.8709],
            [-91.0495, 45.8999],
            [-91.0501, 45.9814],
            [-90.9252, 45.9811],
            [-90.677, 45.9816],
            [-90.6787, 45.9507],
            [-90.6778, 45.8448],
            [-90.6787, 45.6383],
            [-91.0499, 45.6389],
            [-91.1746, 45.6386],
            [-91.1739, 45.7256],
            [-91.2963, 45.7259],
            [-91.4192, 45.7519],
            [-91.4192, 45.8121],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516860',
        NAME: 'Winter School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1673745245,
        AWATER: 27541022,
        INTPTLAT: '+45.8017378',
        INTPTLON: '-090.9837653',
        ELSDLEA: '',
        UNSDLEA: '16860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5902, 44.6755],
            [-87.3753, 44.6755],
            [-87.4711, 44.5451],
            [-87.5424, 44.5453],
            [-87.5902, 44.6755],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500150',
        NAME: 'Algoma School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175923242,
        AWATER: 804172,
        INTPTLAT: '+44.6243267',
        INTPTLON: '-087.4956790',
        ELSDLEA: '',
        UNSDLEA: '00150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2963, 45.7259],
            [-91.1739, 45.7256],
            [-91.1746, 45.6386],
            [-91.1736, 45.3781],
            [-91.326, 45.2918],
            [-91.3592, 45.2917],
            [-91.3583, 45.4646],
            [-91.419, 45.4648],
            [-91.4196, 45.6378],
            [-91.2998, 45.6719],
            [-91.2963, 45.7259],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501830',
        NAME: 'Bruce School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 688391593,
        AWATER: 14550507,
        INTPTLAT: '+45.5199127',
        INTPTLON: '-091.2895183',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1921, 43.5516],
            [-90.1919, 43.5722],
            [-90.0722, 43.6417],
            [-89.9094, 43.6411],
            [-89.8232, 43.5552],
            [-89.8978, 43.507],
            [-89.8982, 43.3804],
            [-90.0286, 43.3535],
            [-90.0929, 43.3664],
            [-90.073, 43.4675],
            [-90.1921, 43.5516],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512660',
        NAME: 'Reedsburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 614395036,
        AWATER: 7610800,
        INTPTLAT: '+43.5179834',
        INTPTLON: '-090.0066286',
        ELSDLEA: '',
        UNSDLEA: '12660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9006, 43.0679],
            [-90.8093, 43.0977],
            [-90.8245, 43.1998],
            [-90.7428, 43.2922],
            [-90.6662, 43.2897],
            [-90.6346, 43.1509],
            [-90.5391, 43.0751],
            [-90.8162, 43.018],
            [-90.9006, 43.0679],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501500',
        NAME: 'Boscobel Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 447591305,
        AWATER: 7057196,
        INTPTLAT: '+43.1414295',
        INTPTLON: '-090.7217535',
        ELSDLEA: '',
        UNSDLEA: '01500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9919, 43.1515],
            [-90.9472, 43.1778],
            [-90.8638, 43.2267],
            [-90.8245, 43.1998],
            [-90.8093, 43.0977],
            [-90.9006, 43.0679],
            [-91.0432, 43.0043],
            [-90.9919, 43.1515],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516020',
        NAME: 'Wauzeka-Steuben School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 241737409,
        AWATER: 5592931,
        INTPTLAT: '+43.1137258',
        INTPTLON: '-090.9247552',
        ELSDLEA: '',
        UNSDLEA: '16020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-90.9885, 43.4237],
              [-90.9624, 43.4239],
              [-90.9547, 43.4239],
              [-90.9475, 43.4239],
              [-90.941, 43.3602],
              [-90.9885, 43.4237],
            ],
          ],
          [
            [
              [-91.1415, 43.163],
              [-91.0975, 43.2145],
              [-91.121, 43.3213],
              [-90.9567, 43.3439],
              [-90.8638, 43.2267],
              [-90.9472, 43.1778],
              [-90.978, 43.1979],
              [-91.1415, 43.163],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513470',
        NAME: 'Seneca School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 284514287,
        AWATER: 19669552,
        INTPTLAT: '+43.2701079',
        INTPTLON: '-090.9967287',
        ELSDLEA: '',
        UNSDLEA: '13470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1772, 43.0802],
            [-91.1415, 43.163],
            [-90.978, 43.1979],
            [-90.9472, 43.1778],
            [-90.9919, 43.1515],
            [-91.0432, 43.0043],
            [-91.1568, 42.9882],
            [-91.1772, 43.0802],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512150',
        NAME: 'Prairie du Chien Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 220134206,
        AWATER: 19090574,
        INTPTLAT: '+43.1015873',
        INTPTLON: '-091.1020005',
        ELSDLEA: '',
        UNSDLEA: '12150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3076, 43.4562],
            [-88.0407, 43.4545],
            [-88.0401, 43.3673],
            [-88.1026, 43.3085],
            [-88.1816, 43.3099],
            [-88.2686, 43.3693],
            [-88.3076, 43.4562],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516290',
        NAME: 'West Bend School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256625085,
        AWATER: 4101111,
        INTPTLAT: '+43.3906336',
        INTPTLON: '-088.1500232',
        ELSDLEA: '',
        UNSDLEA: '16290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.654, 43.8946],
            [-88.524, 43.8937],
            [-88.5439, 43.78],
            [-88.6349, 43.6926],
            [-88.6906, 43.7498],
            [-88.8255, 43.7056],
            [-88.8551, 43.7132],
            [-88.8656, 43.7496],
            [-88.654, 43.8946],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513100',
        NAME: 'Rosendale-Brandon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 339549125,
        AWATER: 3098899,
        INTPTLAT: '+43.7875906',
        INTPTLON: '-088.6634880',
        ELSDLEA: '',
        UNSDLEA: '13100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5237, 43.7773],
            [-88.4691, 43.8065],
            [-88.3619, 43.8739],
            [-88.2813, 43.8082],
            [-88.281, 43.7504],
            [-88.4218, 43.7095],
            [-88.4323, 43.6894],
            [-88.465, 43.6894],
            [-88.4665, 43.6972],
            [-88.5237, 43.7773],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504680',
        NAME: 'Fond du Lac School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 205705446,
        AWATER: 3625765,
        INTPTLAT: '+43.7735693',
        INTPTLON: '-088.3917916',
        ELSDLEA: '',
        UNSDLEA: '04680',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9268, 45.2917],
            [-88.9268, 45.3028],
            [-88.8028, 45.2799],
            [-88.8026, 45.3782],
            [-88.8023, 45.3782],
            [-88.6778, 45.3787],
            [-88.6792, 45.2769],
            [-88.6412, 45.1173],
            [-88.9307, 45.1178],
            [-88.9268, 45.2917],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516590',
        NAME: 'White Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 487616160,
        AWATER: 8082469,
        INTPTLAT: '+45.2272843',
        INTPTLON: '-088.7809722',
        ELSDLEA: '',
        UNSDLEA: '16590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1923, 44.249],
            [-90.0567, 44.249],
            [-89.9698, 44.1605],
            [-90.026, 44.0917],
            [-89.9626, 43.9792],
            [-89.9807, 43.9056],
            [-90.0191, 43.9056],
            [-90.0725, 43.9519],
            [-90.2417, 44.0103],
            [-90.3126, 44.0258],
            [-90.3123, 44.1111],
            [-90.1922, 44.1115],
            [-90.1923, 44.249],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510230',
        NAME: 'Necedah Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 553315006,
        AWATER: 59923229,
        INTPTLAT: '+44.0949206',
        INTPTLON: '-090.1263583',
        ELSDLEA: '',
        UNSDLEA: '10230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1916, 43.8185],
            [-90.0191, 43.9056],
            [-89.9807, 43.9056],
            [-89.8505, 43.7687],
            [-89.9009, 43.6651],
            [-89.9094, 43.6411],
            [-90.0722, 43.6417],
            [-90.1916, 43.7413],
            [-90.1916, 43.8185],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508850',
        NAME: 'Mauston School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 528502416,
        AWATER: 9909071,
        INTPTLAT: '+43.7586032',
        INTPTLON: '-090.0186455',
        ELSDLEA: '',
        UNSDLEA: '08850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0726, 44.2925],
            [-89.9634, 44.3364],
            [-89.8253, 44.3367],
            [-89.8145, 44.249],
            [-89.7247, 44.2477],
            [-89.7171, 44.156],
            [-89.9698, 44.1605],
            [-90.0567, 44.249],
            [-90.0726, 44.2925],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510380',
        NAME: 'Nekoosa School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378307514,
        AWATER: 43415309,
        INTPTLAT: '+44.2197014',
        INTPTLON: '-089.8969220',
        ELSDLEA: '',
        UNSDLEA: '10380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.5128, 44.2404],
            [-87.531, 44.166],
            [-87.6168, 44.1247],
            [-87.6398, 44.173],
            [-87.5128, 44.2404],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515120',
        NAME: 'Two Rivers School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73628683,
        AWATER: 2135015,
        INTPTLAT: '+44.1896041',
        INTPTLON: '-087.5665730',
        ELSDLEA: '',
        UNSDLEA: '15120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0429, 44.0664],
            [-88.0104, 44.0847],
            [-87.8471, 44.1533],
            [-87.7719, 44.1532],
            [-87.7815, 43.9791],
            [-88.004, 43.9937],
            [-88.0265, 44.0246],
            [-88.0429, 44.0664],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515210',
        NAME: 'Valders Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 297648346,
        AWATER: 3914594,
        INTPTLAT: '+44.0563249',
        INTPTLON: '-087.8793447',
        ELSDLEA: '',
        UNSDLEA: '15210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0478, 45.9823],
            [-88.9332, 45.9821],
            [-88.9328, 46.0737],
            [-88.7821, 46.0162],
            [-88.6833, 46.0141],
            [-88.6755, 45.8963],
            [-88.7996, 45.896],
            [-88.7994, 45.4646],
            [-88.8023, 45.3782],
            [-88.8026, 45.3782],
            [-88.926, 45.3786],
            [-88.9231, 45.4651],
            [-89.0463, 45.4648],
            [-89.0466, 45.5515],
            [-89.0478, 45.9244],
            [-89.0478, 45.9823],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502910',
        NAME: 'Crandon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1325395746,
        AWATER: 56875593,
        INTPTLAT: '+45.7340243',
        INTPTLON: '-088.8845830',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4423, 44.0687],
            [-89.3314, 44.1559],
            [-89.2891, 44.1255],
            [-89.0462, 44.1044],
            [-89.0851, 44.0122],
            [-89.1686, 43.9828],
            [-89.4297, 43.9823],
            [-89.4423, 44.0687],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515960',
        NAME: 'Wautoma Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 457362268,
        AWATER: 7934589,
        INTPTLAT: '+44.0505412',
        INTPTLON: '-089.2649678',
        ELSDLEA: '',
        UNSDLEA: '15960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0059, 44.8717],
            [-87.9125, 44.9931],
            [-87.9029, 44.9932],
            [-87.7626, 44.9656],
            [-87.9556, 44.7505],
            [-88.0191, 44.7897],
            [-88.0059, 44.8717],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510920',
        NAME: 'Oconto School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256345494,
        AWATER: 2094786,
        INTPTLAT: '+44.8692135',
        INTPTLON: '-087.9377272',
        ELSDLEA: '',
        UNSDLEA: '10920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7958, 45.7321],
            [-89.5904, 45.7324],
            [-89.5489, 45.8177],
            [-89.549, 45.8266],
            [-89.4263, 45.8265],
            [-89.4255, 45.732],
            [-89.1977, 45.7276],
            [-89.2368, 45.5544],
            [-89.4283, 45.5554],
            [-89.4248, 45.4673],
            [-89.3015, 45.4694],
            [-89.2997, 45.3795],
            [-89.4243, 45.3802],
            [-89.4246, 45.4188],
            [-89.4869, 45.5555],
            [-89.6723, 45.5557],
            [-89.6728, 45.645],
            [-89.7958, 45.645],
            [-89.7958, 45.7321],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512720',
        NAME: 'Rhinelander School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 945369648,
        AWATER: 75697552,
        INTPTLAT: '+45.6298764',
        INTPTLON: '-089.4728853',
        ELSDLEA: '',
        UNSDLEA: '12720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.0435, 45.7301],
            [-89.9216, 45.7324],
            [-89.9184, 45.4675],
            [-90.0425, 45.4339],
            [-90.1658, 45.4345],
            [-90.1863, 45.3815],
            [-90.5555, 45.3774],
            [-90.5143, 45.4488],
            [-90.4308, 45.4492],
            [-90.4328, 45.5943],
            [-90.1678, 45.643],
            [-90.0435, 45.6439],
            [-90.0435, 45.7301],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512210',
        NAME: 'Prentice School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1076587015,
        AWATER: 9201899,
        INTPTLAT: '+45.5317721',
        INTPTLON: '-090.1978675',
        ELSDLEA: '',
        UNSDLEA: '12210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0519, 46.8822],
            [-90.9688, 46.944],
            [-90.856, 46.9624],
            [-90.7674, 46.8847],
            [-90.8596, 46.7743],
            [-91.0513, 46.7747],
            [-91.0519, 46.8822],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500900',
        NAME: 'Bayfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 407185974,
        AWATER: 726297,
        INTPTLAT: '+46.8632457',
        INTPTLON: '-090.9011607',
        ELSDLEA: '',
        UNSDLEA: '00900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.5514, 46.7558],
            [-91.0519, 46.8822],
            [-91.0513, 46.7747],
            [-91.303, 46.6749],
            [-91.3037, 46.5886],
            [-91.5533, 46.5894],
            [-91.5514, 46.7558],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512030',
        NAME: 'South Shore School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 751774121,
        AWATER: 2855152,
        INTPTLAT: '+46.7331901',
        INTPTLON: '-091.2963304',
        ELSDLEA: '',
        UNSDLEA: '12030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3037, 46.5886],
            [-90.9254, 46.5873],
            [-90.7552, 46.6466],
            [-90.5499, 46.5836],
            [-90.5488, 46.414],
            [-90.7983, 46.4143],
            [-90.9241, 46.371],
            [-90.9244, 46.5022],
            [-91.0549, 46.5459],
            [-91.3048, 46.5465],
            [-91.3037, 46.5886],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500510',
        NAME: 'Ashland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 883876565,
        AWATER: 10827341,
        INTPTLAT: '+46.5134188',
        INTPTLON: '-090.8242084',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.7682, 43.5383],
            [-90.7469, 43.575],
            [-90.6386, 43.5421],
            [-90.5275, 43.5977],
            [-90.452, 43.5171],
            [-90.4671, 43.4666],
            [-90.5885, 43.4664],
            [-90.6686, 43.4074],
            [-90.8869, 43.4238],
            [-90.7682, 43.5383],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512540',
        NAME: 'Kickapoo Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 382260226,
        AWATER: 700955,
        INTPTLAT: '+43.4883296',
        INTPTLON: '-090.6429042',
        ELSDLEA: '',
        UNSDLEA: '12540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3065, 43.6927],
            [-90.1916, 43.7413],
            [-90.0722, 43.6417],
            [-90.1919, 43.5722],
            [-90.3111, 43.554],
            [-90.3065, 43.6927],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5517130',
        NAME: 'Wonewoc-Union Center School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245230727,
        AWATER: 1468706,
        INTPTLAT: '+43.6412265',
        INTPTLON: '-090.2084419',
        ELSDLEA: '',
        UNSDLEA: '17130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.4338, 43.8661],
            [-90.3274, 43.8607],
            [-90.1916, 43.8185],
            [-90.1916, 43.7413],
            [-90.3065, 43.6927],
            [-90.472, 43.7418],
            [-90.4338, 43.8661],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504380',
        NAME: 'Royall School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 290785501,
        AWATER: 145230,
        INTPTLAT: '+43.7801593',
        INTPTLON: '-090.3180810',
        ELSDLEA: '',
        UNSDLEA: '04380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2417, 44.0103],
            [-90.0725, 43.9519],
            [-90.0191, 43.9056],
            [-90.1916, 43.8185],
            [-90.3274, 43.8607],
            [-90.2518, 43.9115],
            [-90.2417, 44.0103],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510560',
        NAME: 'New Lisbon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 293962892,
        AWATER: 1353705,
        INTPTLAT: '+43.8980980',
        INTPTLON: '-090.2007010',
        ELSDLEA: '',
        UNSDLEA: '10560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6531, 44.1596],
            [-90.4359, 44.1892],
            [-90.4374, 44.2919],
            [-90.318, 44.2487],
            [-90.1923, 44.249],
            [-90.1922, 44.1115],
            [-90.3123, 44.1111],
            [-90.3126, 44.0258],
            [-90.2417, 44.0103],
            [-90.2518, 43.9115],
            [-90.3274, 43.8607],
            [-90.4338, 43.8661],
            [-90.5518, 43.8708],
            [-90.598, 43.9051],
            [-90.6508, 43.9283],
            [-90.6531, 44.1596],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514910',
        NAME: 'Tomah Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1182697138,
        AWATER: 32437382,
        INTPTLAT: '+44.0636176',
        INTPTLON: '-090.4293550',
        ELSDLEA: '',
        UNSDLEA: '14910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9094, 43.6411],
            [-89.9009, 43.6651],
            [-89.8505, 43.7687],
            [-89.6589, 43.7693],
            [-89.5629, 43.6938],
            [-89.6599, 43.6428],
            [-89.6595, 43.5674],
            [-89.8232, 43.5552],
            [-89.9094, 43.6411],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5517040',
        NAME: 'Wisconsin Dells School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 466918631,
        AWATER: 19913579,
        INTPTLAT: '+43.6714224',
        INTPTLON: '-089.7481343',
        ELSDLEA: '',
        UNSDLEA: '17040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6907, 43.6605],
            [-90.6707, 43.6818],
            [-90.5735, 43.6523],
            [-90.5275, 43.5977],
            [-90.6386, 43.5421],
            [-90.7469, 43.575],
            [-90.7604, 43.5812],
            [-90.6907, 43.6605],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507560',
        NAME: 'La Farge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 179097457,
        AWATER: 541810,
        INTPTLAT: '+43.6136139',
        INTPTLON: '-090.6459585',
        ELSDLEA: '',
        UNSDLEA: '07560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1478, 43.5936],
            [-91.0671, 43.6449],
            [-90.7604, 43.5812],
            [-90.7469, 43.575],
            [-90.7682, 43.5383],
            [-90.8869, 43.4238],
            [-90.9475, 43.4239],
            [-90.9547, 43.4239],
            [-90.9624, 43.4239],
            [-90.9885, 43.4237],
            [-91.0286, 43.4946],
            [-91.1478, 43.5936],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515360',
        NAME: 'Viroqua Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 499091101,
        AWATER: 892779,
        INTPTLAT: '+43.5345933',
        INTPTLON: '-090.9475706',
        ELSDLEA: '',
        UNSDLEA: '15360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9567, 43.3439],
            [-90.9551, 43.3456],
            [-90.952, 43.3478],
            [-90.9494, 43.3558],
            [-90.9509, 43.3576],
            [-90.941, 43.3602],
            [-90.9475, 43.4239],
            [-90.8869, 43.4238],
            [-90.6686, 43.4074],
            [-90.6661, 43.3108],
            [-90.6661, 43.3079],
            [-90.6661, 43.3032],
            [-90.6662, 43.2897],
            [-90.7428, 43.2922],
            [-90.8245, 43.1998],
            [-90.8638, 43.2267],
            [-90.9567, 43.3439],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504980',
        NAME: 'North Crawford School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 390611629,
        AWATER: 220488,
        INTPTLAT: '+43.3432584',
        INTPTLON: '-090.8053391',
        ELSDLEA: '',
        UNSDLEA: '04980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.2681, 43.7015],
            [-91.1497, 43.6797],
            [-91.1478, 43.5936],
            [-91.0286, 43.4946],
            [-90.9885, 43.4237],
            [-90.941, 43.3602],
            [-90.9509, 43.3576],
            [-90.9494, 43.3558],
            [-90.952, 43.3478],
            [-90.9551, 43.3456],
            [-90.9567, 43.3439],
            [-91.121, 43.3213],
            [-91.2148, 43.3659],
            [-91.2177, 43.5005],
            [-91.2687, 43.6153],
            [-91.2681, 43.7015],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503240',
        NAME: 'De Soto Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 417353080,
        AWATER: 80361711,
        INTPTLAT: '+43.4842240',
        INTPTLON: '-091.1362362',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.0722, 44.8113],
            [-88.8988, 44.7758],
            [-88.977, 44.6798],
            [-89.0923, 44.6577],
            [-89.1024, 44.6652],
            [-89.1845, 44.681],
            [-89.1634, 44.7688],
            [-89.0722, 44.8113],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514880',
        NAME: 'Tigerton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 250547282,
        AWATER: 270121,
        INTPTLAT: '+44.7350151',
        INTPTLON: '-089.0450830',
        ELSDLEA: '',
        UNSDLEA: '14880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.365, 44.2387],
            [-89.2244, 44.2745],
            [-89.0664, 44.2282],
            [-89.0065, 44.2135],
            [-88.9424, 44.1594],
            [-89.0462, 44.1044],
            [-89.2891, 44.1255],
            [-89.3314, 44.1559],
            [-89.365, 44.2387],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516710',
        NAME: 'Wild Rose School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 369653592,
        AWATER: 5990984,
        INTPTLAT: '+44.1844144',
        INTPTLON: '-089.1793292',
        ELSDLEA: '',
        UNSDLEA: '16710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.2707, 44.5478],
            [-89.1979, 44.6129],
            [-89.1978, 44.6163],
            [-89.1024, 44.6652],
            [-89.0923, 44.6577],
            [-88.991, 44.5684],
            [-89.0821, 44.4611],
            [-89.2237, 44.4174],
            [-89.2707, 44.5478],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506840',
        NAME: 'Iola-Scandinavia School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 338714885,
        AWATER: 6335717,
        INTPTLAT: '+44.5311076',
        INTPTLON: '-089.1305240',
        ELSDLEA: '',
        UNSDLEA: '06840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.9812, 44.4029],
            [-88.8598, 44.3815],
            [-88.8601, 44.3011],
            [-88.8048, 44.2385],
            [-88.8057, 44.1519],
            [-88.8857, 44.1107],
            [-88.9424, 44.1594],
            [-89.0065, 44.2135],
            [-89.0664, 44.2282],
            [-88.9812, 44.4029],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516500',
        NAME: 'Weyauwega-Fremont School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 357660513,
        AWATER: 44710279,
        INTPTLAT: '+44.2611250',
        INTPTLON: '-088.9207294',
        ELSDLEA: '',
        UNSDLEA: '16500',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.4272, 44.7713],
            [-89.3464, 44.7627],
            [-89.3459, 44.6814],
            [-89.1845, 44.681],
            [-89.1024, 44.6652],
            [-89.1978, 44.6163],
            [-89.1979, 44.6129],
            [-89.2707, 44.5478],
            [-89.345, 44.5476],
            [-89.447, 44.5954],
            [-89.4903, 44.6859],
            [-89.4272, 44.7713],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513140',
        NAME: 'Rosholt School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 395544773,
        AWATER: 5116060,
        INTPTLAT: '+44.6529145',
        INTPTLON: '-089.3379762',
        ELSDLEA: '',
        UNSDLEA: '13140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5488, 46.414],
            [-90.5499, 46.5836],
            [-90.449, 46.7974],
            [-90.2696, 46.7982],
            [-90.4184, 46.5661],
            [-90.2162, 46.5013],
            [-90.1188, 46.3595],
            [-89.9905, 46.3116],
            [-89.9292, 46.2997],
            [-89.9288, 46.2428],
            [-90.3009, 46.2405],
            [-90.3031, 46.1548],
            [-90.4263, 46.154],
            [-90.426, 46.2404],
            [-90.5522, 46.2409],
            [-90.5488, 46.414],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506750',
        NAME: 'Hurley School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1221136576,
        AWATER: 327314075,
        INTPTLAT: '+46.4450020',
        INTPTLON: '-090.3721005',
        ELSDLEA: '',
        UNSDLEA: '06750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.6312, 44.9432],
            [-91.5701, 44.987],
            [-91.4074, 45.0457],
            [-91.3686, 45.1202],
            [-91.2829, 45.1199],
            [-91.233, 45.0887],
            [-91.2636, 44.9877],
            [-91.2467, 44.857],
            [-91.3876, 44.8572],
            [-91.5813, 44.8945],
            [-91.6312, 44.9432],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502550',
        NAME: 'Chippewa Falls Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 553974830,
        AWATER: 34224747,
        INTPTLAT: '+44.9697343',
        INTPTLON: '-091.3919398',
        ELSDLEA: '',
        UNSDLEA: '02550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.7917, 44.7854],
            [-91.7726, 44.9149],
            [-91.6312, 44.9432],
            [-91.5813, 44.8945],
            [-91.6504, 44.8496],
            [-91.6904, 44.7438],
            [-91.7815, 44.7629],
            [-91.7917, 44.7854],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504230',
        NAME: 'Elk Mound Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222013361,
        AWATER: 2402802,
        INTPTLAT: '+44.8460423',
        INTPTLON: '-091.7011045',
        ELSDLEA: '',
        UNSDLEA: '04230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3072, 45.1907],
            [-91.3064, 45.205],
            [-91.1478, 45.2058],
            [-91.0274, 45.1627],
            [-91.007, 45.135],
            [-90.9999, 45.1303],
            [-91.0568, 45.0821],
            [-91.233, 45.0887],
            [-91.2829, 45.1199],
            [-91.3072, 45.1907],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502880',
        NAME: 'Cornell School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 255157124,
        AWATER: 6500791,
        INTPTLAT: '+45.1437603',
        INTPTLON: '-091.1537033',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1352, 44.5609],
            [-92.1302, 44.637],
            [-92.1351, 44.6609],
            [-92.0751, 44.6981],
            [-92.0246, 44.7566],
            [-91.913, 44.6981],
            [-91.7815, 44.7629],
            [-91.6904, 44.7438],
            [-91.6802, 44.7272],
            [-91.6756, 44.6837],
            [-91.679, 44.6837],
            [-91.6902, 44.6837],
            [-91.7261, 44.6581],
            [-91.8428, 44.5966],
            [-91.8521, 44.4368],
            [-91.9546, 44.4446],
            [-91.9935, 44.4265],
            [-92.0455, 44.51],
            [-92.1352, 44.5609],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503840',
        NAME: 'Durand School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 747223087,
        AWATER: 19729546,
        INTPTLAT: '+44.6128826',
        INTPTLON: '-091.9246491',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.1567, 44.847],
            [-92.1567, 44.8433],
            [-92.1363, 44.8438],
            [-92.0325, 44.8149],
            [-92.0246, 44.7566],
            [-92.0751, 44.6981],
            [-92.1351, 44.6609],
            [-92.2669, 44.7121],
            [-92.1567, 44.847],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504350',
        NAME: 'Elmwood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 245552899,
        AWATER: 651973,
        INTPTLAT: '+44.7481410',
        INTPTLON: '-092.1446558',
        ELSDLEA: '',
        UNSDLEA: '04350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6265, 44.2435],
            [-88.6184, 44.2437],
            [-88.4968, 44.2441],
            [-88.4406, 44.2435],
            [-88.4341, 44.1936],
            [-88.4859, 44.0978],
            [-88.5233, 44.1055],
            [-88.6265, 44.2435],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510320',
        NAME: 'Neenah School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 151742894,
        AWATER: 3916261,
        INTPTLAT: '+44.1867232',
        INTPTLON: '-088.5142124',
        ELSDLEA: '',
        UNSDLEA: '10320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6543, 43.9712],
            [-88.6426, 44.0546],
            [-88.6074, 44.0649],
            [-88.5233, 44.1055],
            [-88.4859, 44.0978],
            [-88.5162, 44.0633],
            [-88.5357, 43.9909],
            [-88.4676, 43.8934],
            [-88.524, 43.8937],
            [-88.654, 43.8946],
            [-88.7444, 43.9457],
            [-88.6543, 43.9712],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511190',
        NAME: 'Oshkosh Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 256605551,
        AWATER: 2457566,
        INTPTLAT: '+43.9804036',
        INTPTLON: '-088.5824695',
        ELSDLEA: '',
        UNSDLEA: '11190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8857, 44.1066],
            [-88.6943, 44.0765],
            [-88.6935, 44.0763],
            [-88.6833, 44.0792],
            [-88.6816, 44.0801],
            [-88.6727, 44.0795],
            [-88.6779, 44.0762],
            [-88.6784, 44.0741],
            [-88.6627, 44.0698],
            [-88.6426, 44.0546],
            [-88.6543, 43.9712],
            [-88.7444, 43.9457],
            [-88.7799, 43.938],
            [-88.8863, 44.0309],
            [-88.8857, 44.1066],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510980',
        NAME: 'Omro School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235235944,
        AWATER: 7987560,
        INTPTLAT: '+44.0268546',
        INTPTLON: '-088.7602325',
        ELSDLEA: '',
        UNSDLEA: '10980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8857, 44.1107],
            [-88.8057, 44.1519],
            [-88.8048, 44.2385],
            [-88.6838, 44.2132],
            [-88.6265, 44.2435],
            [-88.5233, 44.1055],
            [-88.6074, 44.0649],
            [-88.6342, 44.0947],
            [-88.6943, 44.0765],
            [-88.8857, 44.1066],
            [-88.8857, 44.1107],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516830',
        NAME: 'Winneconne Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 263078584,
        AWATER: 45197627,
        INTPTLAT: '+44.1518104',
        INTPTLON: '-088.6969514',
        ELSDLEA: '',
        UNSDLEA: '16830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.6755, 45.8963],
            [-88.6758, 45.7229],
            [-88.5828, 45.7226],
            [-88.5516, 45.6217],
            [-88.6754, 45.4644],
            [-88.7994, 45.4646],
            [-88.7996, 45.896],
            [-88.6755, 45.8963],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507830',
        NAME: 'Laona School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 616667422,
        AWATER: 12666529,
        INTPTLAT: '+45.6651106',
        INTPTLON: '-088.6971194',
        ELSDLEA: '',
        UNSDLEA: '07830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7924, 45.0789],
            [-92.7408, 45.0689],
            [-92.6499, 45.0536],
            [-92.6197, 45.0357],
            [-92.6193, 44.942],
            [-92.7617, 44.898],
            [-92.7648, 44.9054],
            [-92.7924, 45.0789],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506690',
        NAME: 'Hudson School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 170230404,
        AWATER: 14343308,
        INTPTLAT: '+44.9962768',
        INTPTLON: '-092.7039314',
        ELSDLEA: '',
        UNSDLEA: '06690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6071, 44.3665],
            [-89.4565, 44.3665],
            [-89.4286, 44.4007],
            [-89.3143, 44.3355],
            [-89.2244, 44.2745],
            [-89.365, 44.2387],
            [-89.4477, 44.2474],
            [-89.6073, 44.3371],
            [-89.6071, 44.3665],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500240',
        NAME: 'Almond-Bancroft School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 282640430,
        AWATER: 1923609,
        INTPTLAT: '+44.3105251',
        INTPTLON: '-089.4235948',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.1208, 44.5622],
            [-90.0655, 44.6998],
            [-90.0446, 44.7058],
            [-89.9467, 44.7135],
            [-89.9263, 44.7136],
            [-89.8451, 44.6849],
            [-89.8651, 44.6232],
            [-89.8754, 44.5949],
            [-89.828, 44.5573],
            [-90.024, 44.4817],
            [-90.1208, 44.5622],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500600',
        NAME: 'Auburndale School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 394320798,
        AWATER: 4191970,
        INTPTLAT: '+44.6074835',
        INTPTLON: '-089.9793394',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9943, 43.1925],
            [-87.9595, 43.1924],
            [-87.9508, 43.163],
            [-87.9943, 43.1925],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501800',
        NAME: 'Brown Deer School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 11386133,
        AWATER: 0,
        INTPTLAT: '+43.1743106',
        INTPTLON: '-087.9749998',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8877, 42.93],
            [-87.8832, 42.9591],
            [-87.8481, 42.9662],
            [-87.846, 42.9302],
            [-87.8814, 42.93],
            [-87.8877, 42.93],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503060',
        NAME: 'Cudahy School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12340129,
        AWATER: 0,
        INTPTLAT: '+42.9467270',
        INTPTLON: '-087.8638844',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0696, 42.9238],
            [-88.0286, 42.9303],
            [-87.9692, 42.9304],
            [-87.9593, 42.9304],
            [-87.9813, 42.8436],
            [-88.041, 42.8434],
            [-88.0699, 42.8433],
            [-88.0696, 42.9238],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504830',
        NAME: 'Franklin Public School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 74628790,
        AWATER: 271953,
        INTPTLAT: '+42.8850557',
        INTPTLON: '-088.0159481',
        ELSDLEA: '',
        UNSDLEA: '04830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0183, 42.9542],
            [-87.9692, 42.9304],
            [-88.0286, 42.9303],
            [-88.0183, 42.9542],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505910',
        NAME: 'Greendale School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14406714,
        AWATER: 22101,
        INTPTLAT: '+42.9399929',
        INTPTLON: '-088.0008572',
        ELSDLEA: '',
        UNSDLEA: '05910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9216, 45.7324],
            [-89.7958, 45.7321],
            [-89.7958, 45.645],
            [-89.6728, 45.645],
            [-89.6723, 45.5557],
            [-89.4869, 45.5555],
            [-89.4246, 45.4188],
            [-89.4879, 45.3805],
            [-90.0423, 45.3814],
            [-90.0425, 45.4339],
            [-89.9184, 45.4675],
            [-89.9216, 45.7324],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514940',
        NAME: 'Tomahawk School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1023335087,
        AWATER: 79819752,
        INTPTLAT: '+45.5045307',
        INTPTLON: '-089.7464554',
        ELSDLEA: '',
        UNSDLEA: '14940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.3048, 46.5465],
            [-91.0549, 46.5459],
            [-90.9244, 46.5022],
            [-90.9241, 46.371],
            [-90.9248, 46.2418],
            [-90.9645, 46.1551],
            [-91.5513, 46.157],
            [-91.5514, 46.3744],
            [-91.5518, 46.4611],
            [-91.4271, 46.4595],
            [-91.3048, 46.5465],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503810',
        NAME: 'Drummond Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1834260770,
        AWATER: 70523622,
        INTPTLAT: '+46.3324978',
        INTPTLON: '-091.2159521',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.9312, 46.7421],
            [-91.8864, 46.7591],
            [-91.5494, 46.8932],
            [-91.5514, 46.7558],
            [-91.5533, 46.5894],
            [-91.3037, 46.5886],
            [-91.3048, 46.5465],
            [-91.4271, 46.4595],
            [-91.5518, 46.4611],
            [-91.5514, 46.3744],
            [-91.6746, 46.3309],
            [-91.6754, 46.4173],
            [-91.7386, 46.4743],
            [-91.9262, 46.4754],
            [-91.9312, 46.7421],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508640',
        NAME: 'Maple School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1129622880,
        AWATER: 353811376,
        INTPTLAT: '+46.6118827',
        INTPTLON: '-091.6764871',
        ELSDLEA: '',
        UNSDLEA: '08640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.0513, 46.7747],
            [-90.8596, 46.7743],
            [-90.8348, 46.6592],
            [-90.9254, 46.5873],
            [-91.3037, 46.5886],
            [-91.303, 46.6749],
            [-91.0513, 46.7747],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515540',
        NAME: 'Washburn School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 481505748,
        AWATER: 29295854,
        INTPTLAT: '+46.6684058',
        INTPTLON: '-091.0561670',
        ELSDLEA: '',
        UNSDLEA: '15540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0689, 42.9737],
            [-88.0376, 42.981],
            [-88.0277, 42.9737],
            [-88.0183, 42.9542],
            [-88.0286, 42.9303],
            [-88.0696, 42.9238],
            [-88.0695, 42.9301],
            [-88.0689, 42.9737],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506000',
        NAME: 'Whitnall School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21150346,
        AWATER: 31099,
        INTPTLAT: '+42.9506290',
        INTPTLON: '-088.0509493',
        ELSDLEA: '',
        UNSDLEA: '06000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9593, 42.9304],
            [-87.9494, 42.9304],
            [-87.8877, 42.93],
            [-87.8814, 42.93],
            [-87.8483, 42.8931],
            [-87.8263, 42.8422],
            [-87.952, 42.8435],
            [-87.9813, 42.8436],
            [-87.9593, 42.9304],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510830',
        NAME: 'Oak Creek-Franklin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 86247927,
        AWATER: 0,
        INTPTLAT: '+42.8798422',
        INTPTLON: '-087.9096672',
        ELSDLEA: '',
        UNSDLEA: '10830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8689, 42.9866],
            [-87.8481, 42.9662],
            [-87.8832, 42.9591],
            [-87.8689, 42.9866],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513260',
        NAME: 'St. Francis School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6608229,
        AWATER: 0,
        INTPTLAT: '+42.9716571',
        INTPTLON: '-087.8731040',
        ELSDLEA: '',
        UNSDLEA: '13260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9071, 43.1003],
            [-87.8765, 43.0989],
            [-87.8706, 43.0819],
            [-87.9071, 43.1003],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513800',
        NAME: 'Shorewood School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4128450,
        AWATER: 0,
        INTPTLAT: '+43.0913405',
        INTPTLON: '-087.8866678',
        ELSDLEA: '',
        UNSDLEA: '13800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.2815, 45.1355],
            [-92.2817, 45.1411],
            [-92.1773, 45.1654],
            [-92.1059, 45.1147],
            [-92.0861, 44.9664],
            [-92.1357, 44.9662],
            [-92.2004, 44.9516],
            [-92.2832, 45.0413],
            [-92.3388, 45.0634],
            [-92.3353, 45.0633],
            [-92.3352, 45.0706],
            [-92.3347, 45.0841],
            [-92.2815, 45.1355],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505520',
        NAME: 'Glenwood City School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 292992702,
        AWATER: 867107,
        INTPTLAT: '+45.0674946',
        INTPTLON: '-092.2088304',
        ELSDLEA: '',
        UNSDLEA: '05520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3243, 44.0005],
            [-88.2289, 44.083],
            [-88.0839, 44.11],
            [-88.0429, 44.0664],
            [-88.0265, 44.0246],
            [-88.0423, 44.0018],
            [-88.1226, 44.0018],
            [-88.2315, 43.9375],
            [-88.3149, 43.9377],
            [-88.3243, 44.0005],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502520',
        NAME: 'Chilton School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261121699,
        AWATER: 1963979,
        INTPTLAT: '+44.0260761',
        INTPTLON: '-088.1763118',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0423, 44.0018],
            [-88.0265, 44.0246],
            [-88.004, 43.9937],
            [-87.7815, 43.9791],
            [-87.7718, 43.9501],
            [-87.7906, 43.892],
            [-87.9307, 43.8778],
            [-87.9154, 43.8629],
            [-87.9222, 43.8549],
            [-88.1023, 43.8917],
            [-88.0423, 44.0018],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507440',
        NAME: 'Kiel Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 273458416,
        AWATER: 3311417,
        INTPTLAT: '+43.9189730',
        INTPTLON: '-087.9299333',
        ELSDLEA: '',
        UNSDLEA: '07440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3149, 43.9377],
            [-88.2315, 43.9375],
            [-88.1226, 44.0018],
            [-88.0423, 44.0018],
            [-88.1023, 43.8917],
            [-88.1612, 43.8117],
            [-88.1612, 43.8082],
            [-88.2813, 43.8082],
            [-88.3619, 43.8739],
            [-88.3149, 43.9377],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510530',
        NAME: 'New Holstein School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 329319408,
        AWATER: 1716631,
        INTPTLAT: '+43.8985523',
        INTPTLON: '-088.1866695',
        ELSDLEA: '',
        UNSDLEA: '10530',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.7356, 42.7631],
            [-89.5463, 42.7855],
            [-89.4671, 42.7771],
            [-89.5645, 42.6829],
            [-89.7248, 42.7087],
            [-89.7356, 42.7631],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509900',
        NAME: 'Monticello School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 166926726,
        AWATER: 41694,
        INTPTLAT: '+42.7328489',
        INTPTLON: '-089.6108346',
        ELSDLEA: '',
        UNSDLEA: '09900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6589, 43.7693],
            [-89.5985, 43.7877],
            [-89.598, 44.0685],
            [-89.4423, 44.0687],
            [-89.4297, 43.9823],
            [-89.1686, 43.9828],
            [-89.1682, 43.9536],
            [-89.2465, 43.8949],
            [-89.3649, 43.8943],
            [-89.3699, 43.8105],
            [-89.466, 43.8103],
            [-89.4835, 43.7304],
            [-89.5629, 43.6938],
            [-89.6589, 43.7693],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516440',
        NAME: 'Westfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 716154473,
        AWATER: 7665902,
        INTPTLAT: '+43.9035982',
        INTPTLON: '-089.4614554',
        ELSDLEA: '',
        UNSDLEA: '16440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.8814, 42.93],
            [-87.846, 42.9302],
            [-87.8483, 42.8931],
            [-87.8814, 42.93],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514040',
        NAME: 'South Milwaukee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 12428168,
        AWATER: 19701,
        INTPTLAT: '+42.9126431',
        INTPTLON: '-087.8623115',
        ELSDLEA: '',
        UNSDLEA: '14040',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.9083, 43.1348],
            [-87.9014, 43.1332],
            [-87.8765, 43.0989],
            [-87.9071, 43.1003],
            [-87.91, 43.1046],
            [-87.9083, 43.1348],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516620',
        NAME: 'Whitefish Bay School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5505396,
        AWATER: 0,
        INTPTLAT: '+43.1132471',
        INTPTLON: '-087.9005561',
        ELSDLEA: '',
        UNSDLEA: '16620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0277, 42.9737],
            [-87.9494, 42.9304],
            [-87.9593, 42.9304],
            [-87.9692, 42.9304],
            [-88.0183, 42.9542],
            [-88.0277, 42.9737],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505940',
        NAME: 'Greenfield School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19109933,
        AWATER: 0,
        INTPTLAT: '+42.9595511',
        INTPTLON: '-087.9807141',
        ELSDLEA: '',
        UNSDLEA: '05940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3203, 44.1226],
            [-88.2327, 44.0844],
            [-88.229, 44.0844],
            [-88.2289, 44.083],
            [-88.3243, 44.0005],
            [-88.3203, 44.1226],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5514520',
        NAME: 'Stockbridge School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 73201700,
        AWATER: 145687,
        INTPTLAT: '+44.0784604',
        INTPTLON: '-088.2700424',
        ELSDLEA: '',
        UNSDLEA: '14520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2223, 44.3572],
            [-88.1914, 44.3922],
            [-88.1306, 44.3739],
            [-88.1443, 44.3572],
            [-88.1433, 44.3573],
            [-88.0092, 44.3575],
            [-87.9785, 44.2771],
            [-87.9947, 44.2554],
            [-88.0099, 44.2554],
            [-88.1526, 44.2413],
            [-88.2223, 44.3572],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5517190',
        NAME: 'Wrightstown Community School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206714332,
        AWATER: 843055,
        INTPTLAT: '+44.3061968',
        INTPTLON: '-088.1032687',
        ELSDLEA: '',
        UNSDLEA: '17190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2707, 43.1193],
            [-90.2706, 43.0764],
            [-90.2627, 43.0248],
            [-90.2713, 43.0184],
            [-90.3893, 43.0103],
            [-90.4924, 43.0751],
            [-90.4128, 43.1184],
            [-90.2707, 43.1193],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506420',
        NAME: 'Highland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 186952445,
        AWATER: 558155,
        INTPTLAT: '+43.0735233',
        INTPTLON: '-090.3487769',
        ELSDLEA: '',
        UNSDLEA: '06420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5581, 42.8568],
            [-90.5464, 42.8568],
            [-90.4226, 42.8129],
            [-90.3969, 42.6674],
            [-90.5439, 42.644],
            [-90.6223, 42.6699],
            [-90.6222, 42.6714],
            [-90.6227, 42.6779],
            [-90.6388, 42.7537],
            [-90.5581, 42.8568],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511850',
        NAME: 'Platteville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 378470349,
        AWATER: 27874,
        INTPTLAT: '+42.7441596',
        INTPTLON: '-090.5182215',
        ELSDLEA: '',
        UNSDLEA: '11850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2399, 45.7196],
            [-88.0592, 45.7131],
            [-88.0589, 45.6846],
            [-87.8028, 45.6667],
            [-87.7775, 45.5938],
            [-87.8034, 45.5383],
            [-88.2412, 45.5463],
            [-88.2399, 45.7196],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511550',
        NAME: 'Beecher-Dunbar-Pembine School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 566999144,
        AWATER: 7817785,
        INTPTLAT: '+45.6205781',
        INTPTLON: '-088.0303145',
        ELSDLEA: '',
        UNSDLEA: '11550',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1212, 45.0236],
            [-87.9125, 44.9931],
            [-88.0059, 44.8717],
            [-88.1446, 44.9376],
            [-88.1212, 45.0236],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507860',
        NAME: 'Lena School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 228116328,
        AWATER: 17407,
        INTPTLAT: '+44.9573489',
        INTPTLON: '-088.0299355',
        ELSDLEA: '',
        UNSDLEA: '07860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.7994, 45.4646],
            [-88.6754, 45.4644],
            [-88.5516, 45.6217],
            [-88.426, 45.6215],
            [-88.4261, 45.5493],
            [-88.4281, 45.377],
            [-88.3058, 45.3746],
            [-88.3091, 45.2602],
            [-88.6792, 45.2769],
            [-88.6778, 45.3787],
            [-88.8023, 45.3782],
            [-88.7994, 45.4646],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515390',
        NAME: 'Wabeno Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 883830741,
        AWATER: 23820894,
        INTPTLAT: '+45.4099026',
        INTPTLON: '-088.5487733',
        ELSDLEA: '',
        UNSDLEA: '15390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8592, 44.9429],
            [-88.7355, 44.9432],
            [-88.7138, 44.8565],
            [-88.8584, 44.7979],
            [-88.8592, 44.9429],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500056',
        NAME: 'Gresham School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 146125010,
        AWATER: 2599959,
        INTPTLAT: '+44.8755032',
        INTPTLON: '-088.7977302',
        ELSDLEA: '',
        UNSDLEA: '00056',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1026, 43.3085],
            [-88.0401, 43.3673],
            [-87.9663, 43.3677],
            [-87.9659, 43.3677],
            [-87.9642, 43.2797],
            [-88.0633, 43.2789],
            [-88.1026, 43.3085],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5502460',
        NAME: 'Cedarburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87522746,
        AWATER: 566878,
        INTPTLAT: '+43.3159327',
        INTPTLON: '-088.0153921',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.5547, 46.2279],
            [-89.4906, 46.2154],
            [-89.1805, 46.1554],
            [-89.1815, 45.9824],
            [-89.0478, 45.9823],
            [-89.0478, 45.9244],
            [-89.4263, 45.8265],
            [-89.549, 45.8266],
            [-89.5702, 45.9823],
            [-89.6164, 45.9825],
            [-89.5546, 46.0689],
            [-89.5547, 46.2279],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503860',
        NAME: 'Northland Pines School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1063804031,
        AWATER: 164201617,
        INTPTLAT: '+46.0173183',
        INTPTLON: '-089.3632943',
        ELSDLEA: '',
        UNSDLEA: '03860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4466, 45.8312],
            [-92.2167, 45.8162],
            [-92.1553, 45.86],
            [-92.1543, 45.816],
            [-92.1544, 45.7256],
            [-92.4871, 45.7361],
            [-92.4466, 45.8312],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513890',
        NAME: 'Siren School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 261473526,
        AWATER: 23946720,
        INTPTLAT: '+45.7835539',
        INTPTLON: '-092.2969740',
        ELSDLEA: '',
        UNSDLEA: '13890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.804, 45.7498],
            [-92.7125, 45.8917],
            [-92.6608, 45.9221],
            [-92.6107, 45.9364],
            [-92.4466, 45.8312],
            [-92.4871, 45.7361],
            [-92.5688, 45.7289],
            [-92.616, 45.643],
            [-92.7759, 45.6434],
            [-92.8871, 45.6441],
            [-92.804, 45.7498],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505670',
        NAME: 'Grantsburg School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 638475521,
        AWATER: 24034354,
        INTPTLAT: '+45.7771931',
        INTPTLON: '-092.6544211',
        ELSDLEA: '',
        UNSDLEA: '05670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.2766, 42.9608],
            [-90.2713, 43.0184],
            [-90.2627, 43.0248],
            [-90.2706, 43.0764],
            [-90.0952, 43.1212],
            [-89.9561, 43.0746],
            [-89.9561, 42.9157],
            [-90.0149, 42.9176],
            [-90.1545, 42.9166],
            [-90.261, 42.9273],
            [-90.2766, 42.9608],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503690',
        NAME: 'Dodgeville School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 494995913,
        AWATER: 1001172,
        INTPTLAT: '+43.0085870',
        INTPTLON: '-090.1231231',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.3031, 46.1548],
            [-90.3009, 46.2405],
            [-89.9288, 46.2428],
            [-89.9288, 46.1127],
            [-90.1137, 46.1117],
            [-90.3007, 46.053],
            [-90.3031, 46.1548],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509150',
        NAME: 'Mercer School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 435910864,
        AWATER: 42562876,
        INTPTLAT: '+46.1559177',
        INTPTLON: '-090.1324241',
        ELSDLEA: '',
        UNSDLEA: '09150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3434, 44.3287],
            [-88.2223, 44.3572],
            [-88.1526, 44.2413],
            [-88.1629, 44.2414],
            [-88.2933, 44.2749],
            [-88.3435, 44.2832],
            [-88.3434, 44.3287],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5507290',
        NAME: 'Kaukauna Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104132839,
        AWATER: 3268447,
        INTPTLAT: '+44.2864965',
        INTPTLON: '-088.2485458',
        ELSDLEA: '',
        UNSDLEA: '07290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-88.1987, 44.5865],
              [-88.1901, 44.5844],
              [-88.1052, 44.5424],
              [-88.1398, 44.5175],
              [-88.1903, 44.5067],
              [-88.1987, 44.5865],
            ],
          ],
          [
            [
              [-88.3264, 44.8103],
              [-88.2502, 44.8095],
              [-88.1513, 44.7492],
              [-88.0564, 44.7501],
              [-88.0449, 44.6774],
              [-88.1311, 44.6769],
              [-88.1905, 44.5868],
              [-88.1987, 44.5865],
              [-88.3859, 44.617],
              [-88.3264, 44.8103],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512330',
        NAME: 'Pulaski Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 476596654,
        AWATER: 443287,
        INTPTLAT: '+44.6766175',
        INTPTLON: '-088.2225471',
        ELSDLEA: '',
        UNSDLEA: '12330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1903, 44.5067],
            [-88.1398, 44.5175],
            [-88.1398, 44.513],
            [-88.0571, 44.4626],
            [-88.1306, 44.3739],
            [-88.1914, 44.3922],
            [-88.2306, 44.4467],
            [-88.1903, 44.5067],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5516320',
        NAME: 'West De Pere School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 120136384,
        AWATER: 2809454,
        INTPTLAT: '+44.4389543',
        INTPTLON: '-088.1444714',
        ELSDLEA: '',
        UNSDLEA: '16320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.0401, 43.6306],
            [-87.9738, 43.6514],
            [-87.9296, 43.6602],
            [-87.9209, 43.6109],
            [-87.8806, 43.5141],
            [-88.0408, 43.4706],
            [-88.0401, 43.6306],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512450',
        NAME: 'Random Lake School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 235405580,
        AWATER: 2072436,
        INTPTLAT: '+43.5645151',
        INTPTLON: '-087.9909689',
        ELSDLEA: '',
        UNSDLEA: '12450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5298, 43.0751],
            [-90.4924, 43.0751],
            [-90.3893, 43.0103],
            [-90.2713, 43.0184],
            [-90.2766, 42.9608],
            [-90.261, 42.9273],
            [-90.3086, 42.8141],
            [-90.4226, 42.8129],
            [-90.5464, 42.8568],
            [-90.503, 42.96],
            [-90.5298, 43.0751],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5506870',
        NAME: 'Iowa-Grant School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 423606206,
        AWATER: 344171,
        INTPTLAT: '+42.9365901',
        INTPTLON: '-090.4154699',
        ELSDLEA: '',
        UNSDLEA: '06870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9561, 43.0746],
            [-89.8889, 43.0809],
            [-89.8382, 43.0747],
            [-89.8636, 42.9155],
            [-89.9561, 42.9157],
            [-89.9561, 43.0746],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500840',
        NAME: 'Barneveld School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 175324599,
        AWATER: 63154,
        INTPTLAT: '+42.9842831',
        INTPTLON: '-089.9138764',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-90.6787, 45.9507],
              [-90.5413, 45.9817],
              [-90.3021, 45.9813],
              [-90.3007, 46.053],
              [-90.1137, 46.1117],
              [-89.9288, 46.1127],
              [-89.9285, 46.0688],
              [-89.9288, 45.982],
              [-90.044, 45.9819],
              [-90.0429, 45.8972],
              [-90.0452, 45.8177],
              [-90.4912, 45.809],
              [-90.4906, 45.8516],
              [-90.6778, 45.8448],
              [-90.6787, 45.9507],
            ],
          ],
          [
            [
              [-90.9645, 46.1551],
              [-90.9248, 46.2418],
              [-90.5522, 46.2409],
              [-90.426, 46.2404],
              [-90.4263, 46.154],
              [-90.4267, 46.0678],
              [-90.9246, 46.068],
              [-90.9645, 46.1551],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500058',
        NAME: 'Chequamegon School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1825229695,
        AWATER: 85758090,
        INTPTLAT: '+45.9281986',
        INTPTLON: '-090.2013540',
        ELSDLEA: '',
        UNSDLEA: '00058',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.5613, 42.9745],
            [-88.5416, 42.9887],
            [-88.4222, 43.0902],
            [-88.3634, 43.0617],
            [-88.3037, 43.0734],
            [-88.3038, 43.0608],
            [-88.3463, 42.9377],
            [-88.4662, 42.9303],
            [-88.5613, 42.9745],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503510',
        NAME: 'Kettle Moraine School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 204554346,
        AWATER: 12327761,
        INTPTLAT: '+43.0008510',
        INTPTLON: '-088.4333962',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.1515, 43.0171],
            [-88.0777, 43.007],
            [-88.0689, 42.9737],
            [-88.0695, 42.9301],
            [-88.189, 42.9302],
            [-88.1515, 43.0171],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510470',
        NAME: 'New Berlin School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 88526361,
        AWATER: 1025253,
        INTPTLAT: '+42.9727348',
        INTPTLON: '-088.1309806',
        ELSDLEA: '',
        UNSDLEA: '10470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3037, 43.0734],
            [-88.3035, 43.1031],
            [-88.2451, 43.1051],
            [-88.1858, 43.096],
            [-88.1859, 43.068],
            [-88.3038, 43.0608],
            [-88.3037, 43.0734],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511640',
        NAME: 'Pewaukee School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 35147367,
        AWATER: 5043309,
        INTPTLAT: '+43.0810170',
        INTPTLON: '-088.2450950',
        ELSDLEA: '',
        UNSDLEA: '11640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1076, 43.2319],
            [-89.0582, 43.2396],
            [-89.0089, 43.2007],
            [-89.0121, 43.1106],
            [-89.1691, 43.1087],
            [-89.174, 43.109],
            [-89.1076, 43.2319],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508790',
        NAME: 'Marshall School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 143971741,
        AWATER: 1478738,
        INTPTLAT: '+43.1386063',
        INTPTLON: '-089.0833435',
        ELSDLEA: '',
        UNSDLEA: '08790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-89.2567, 43.056],
              [-89.2468, 43.077],
              [-89.2465, 43.0974],
              [-89.174, 43.109],
              [-89.1691, 43.1087],
              [-89.1796, 43.0428],
              [-89.2203, 43.0268],
              [-89.247, 43.0314],
              [-89.2468, 43.0452],
              [-89.2567, 43.0525],
              [-89.2567, 43.056],
            ],
          ],
          [
            [
              [-89.3379, 43.0526],
              [-89.3281, 43.0862],
              [-89.3112, 43.0414],
              [-89.3272, 43.0421],
              [-89.3379, 43.0526],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509810',
        NAME: 'Monona Grove School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 64770526,
        AWATER: 346707,
        INTPTLAT: '+43.0604342',
        INTPTLON: '-089.2190689',
        ELSDLEA: '',
        UNSDLEA: '09810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6016, 43.0312],
            [-89.5056, 43.0316],
            [-89.4466, 42.9794],
            [-89.4855, 42.9434],
            [-89.4856, 42.9324],
            [-89.4938, 42.9286],
            [-89.5128, 42.9362],
            [-89.603, 42.9412],
            [-89.6016, 43.0312],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515330',
        NAME: 'Verona Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 148018277,
        AWATER: 676014,
        INTPTLAT: '+42.9826756',
        INTPTLON: '-089.5404430',
        ELSDLEA: '',
        UNSDLEA: '15330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1796, 43.0428],
            [-89.1691, 43.1087],
            [-89.0121, 43.1106],
            [-89.0113, 43.0749],
            [-89.1343, 43.0105],
            [-89.1796, 43.0428],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5503270',
        NAME: 'Deerfield Community School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 104925826,
        AWATER: 393296,
        INTPTLAT: '+43.0652240',
        INTPTLON: '-089.0829432',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3286, 43.0263],
            [-89.3272, 43.0421],
            [-89.3112, 43.0414],
            [-89.2567, 43.0525],
            [-89.2468, 43.0452],
            [-89.247, 43.0314],
            [-89.2203, 43.0268],
            [-89.298, 42.9687],
            [-89.3286, 43.0263],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508910',
        NAME: 'McFarland School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 50147534,
        AWATER: 12062876,
        INTPTLAT: '+43.0109580',
        INTPTLON: '-089.2801493',
        ELSDLEA: '',
        UNSDLEA: '08910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.6001, 43.1907],
            [-89.5911, 43.2023],
            [-89.5703, 43.2024],
            [-89.4815, 43.1192],
            [-89.4418, 43.1188],
            [-89.4824, 43.0945],
            [-89.5056, 43.0316],
            [-89.6016, 43.0312],
            [-89.6697, 43.075],
            [-89.6705, 43.1622],
            [-89.6001, 43.1907],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5509510',
        NAME: 'Middleton-Cross Plains School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 206911427,
        AWATER: 1222621,
        INTPTLAT: '+43.1146969',
        INTPTLON: '-089.5826418',
        ELSDLEA: '',
        UNSDLEA: '09510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.501, 44.6327],
            [-90.3773, 44.6551],
            [-90.316, 44.5849],
            [-90.3157, 44.5128],
            [-90.5023, 44.509],
            [-90.501, 44.6327],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5505640',
        NAME: 'Granton Area School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 191003010,
        AWATER: 648133,
        INTPTLAT: '+44.5748876',
        INTPTLON: '-090.4262295',
        ELSDLEA: '',
        UNSDLEA: '05640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.3038, 43.0608],
            [-88.1859, 43.068],
            [-88.1515, 43.0171],
            [-88.189, 42.9302],
            [-88.3463, 42.9377],
            [-88.3038, 43.0608],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5515780',
        NAME: 'Waukesha School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 180669507,
        AWATER: 2439336,
        INTPTLAT: '+42.9947867',
        INTPTLON: '-088.2582042',
        ELSDLEA: '',
        UNSDLEA: '15780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2212, 42.8421],
            [-88.2457, 42.842],
            [-88.2464, 42.8565],
            [-88.2266, 42.8571],
            [-88.2212, 42.8421],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5510710',
        NAME: 'Norris School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 3191700,
        AWATER: 240578,
        INTPTLAT: '+42.8490292',
        INTPTLON: '-088.2311209',
        ELSDLEA: '',
        UNSDLEA: '10710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7759, 45.6434],
            [-92.616, 45.643],
            [-92.3459, 45.6042],
            [-92.2382, 45.6399],
            [-92.1961, 45.5825],
            [-92.1965, 45.5537],
            [-92.218, 45.5253],
            [-92.2838, 45.5539],
            [-92.5904, 45.5448],
            [-92.6208, 45.5561],
            [-92.6519, 45.614],
            [-92.7759, 45.6434],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5508280',
        NAME: 'Luck School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 300345137,
        AWATER: 12788746,
        INTPTLAT: '+45.5907013',
        INTPTLON: '-092.4272507',
        ELSDLEA: '',
        UNSDLEA: '08280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.5904, 45.5448],
            [-92.2838, 45.5539],
            [-92.218, 45.5253],
            [-92.2374, 45.5112],
            [-92.259, 45.4677],
            [-92.3661, 45.4107],
            [-92.4636, 45.4102],
            [-92.5392, 45.4135],
            [-92.5955, 45.4263],
            [-92.5904, 45.5448],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5500750',
        NAME: 'Unity School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 352898903,
        AWATER: 30229630,
        INTPTLAT: '+45.4905805',
        INTPTLON: '-092.4354855',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.8871, 45.6441],
            [-92.7759, 45.6434],
            [-92.6519, 45.614],
            [-92.6208, 45.5561],
            [-92.5904, 45.5448],
            [-92.5955, 45.4263],
            [-92.5392, 45.4135],
            [-92.4636, 45.4102],
            [-92.5233, 45.331],
            [-92.6456, 45.3679],
            [-92.6745, 45.3829],
            [-92.6466, 45.4416],
            [-92.7274, 45.5274],
            [-92.886, 45.6004],
            [-92.8871, 45.6441],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513230',
        NAME: 'St. Croix Falls School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 367146634,
        AWATER: 14369880,
        INTPTLAT: '+45.5057725',
        INTPTLON: '-092.6621290',
        ELSDLEA: '',
        UNSDLEA: '13230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.4871, 45.7361],
            [-92.1544, 45.7256],
            [-92.155, 45.6795],
            [-92.155, 45.5535],
            [-92.1965, 45.5537],
            [-92.1961, 45.5825],
            [-92.2382, 45.6399],
            [-92.3459, 45.6042],
            [-92.616, 45.643],
            [-92.5688, 45.7289],
            [-92.4871, 45.7361],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5504860',
        NAME: 'Frederic School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 418257154,
        AWATER: 13092031,
        INTPTLAT: '+45.6742841',
        INTPTLON: '-092.3577093',
        ELSDLEA: '',
        UNSDLEA: '04860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.9246, 46.068],
            [-90.4267, 46.0678],
            [-90.4263, 46.154],
            [-90.3031, 46.1548],
            [-90.3007, 46.053],
            [-90.3021, 45.9813],
            [-90.5413, 45.9817],
            [-90.6787, 45.9507],
            [-90.677, 45.9816],
            [-90.9252, 45.9811],
            [-90.9246, 46.068],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5501920',
        NAME: 'Butternut School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 597410603,
        AWATER: 5043445,
        INTPTLAT: '+46.0466102',
        INTPTLON: '-090.5638476',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6778, 45.8448],
            [-90.4906, 45.8516],
            [-90.4912, 45.809],
            [-90.0452, 45.8177],
            [-90.0435, 45.7301],
            [-90.0435, 45.6439],
            [-90.1678, 45.643],
            [-90.4328, 45.5943],
            [-90.4308, 45.4492],
            [-90.5143, 45.4488],
            [-90.5555, 45.3774],
            [-90.6473, 45.3776],
            [-90.6492, 45.6383],
            [-90.6787, 45.6383],
            [-90.6778, 45.8448],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5511730',
        NAME: 'Phillips School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1489823285,
        AWATER: 27675358,
        INTPTLAT: '+45.6720349',
        INTPTLON: '-090.4233941',
        ELSDLEA: '',
        UNSDLEA: '11730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7695, 44.862],
            [-92.7616, 44.8276],
            [-92.5782, 44.7759],
            [-92.6287, 44.7179],
            [-92.6202, 44.6342],
            [-92.732, 44.7143],
            [-92.8084, 44.7516],
            [-92.7695, 44.862],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5512240',
        NAME: 'Prescott School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 184045366,
        AWATER: 9655625,
        INTPTLAT: '+44.7457423',
        INTPTLON: '-092.6894257',
        ELSDLEA: '',
        UNSDLEA: '12240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.7648, 44.9054],
            [-92.7617, 44.898],
            [-92.6193, 44.942],
            [-92.497, 44.9492],
            [-92.4559, 44.8805],
            [-92.4002, 44.8623],
            [-92.4108, 44.8297],
            [-92.5782, 44.7759],
            [-92.7616, 44.8276],
            [-92.7695, 44.862],
            [-92.7648, 44.9054],
          ],
        ],
      },
      properties: {
        STATEFP: '55',
        SCSDLEA: '',
        GEOID: '5513050',
        NAME: 'River Falls School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 370034162,
        AWATER: 2237389,
        INTPTLAT: '+44.8597315',
        INTPTLON: '-092.5895203',
        ELSDLEA: '',
        UNSDLEA: '13050',
      },
    },
  ],
};

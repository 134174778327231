/* eslint-disable react/prop-types */
import { faPen, faExternalLinkAlt, faLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css';

const actionButtonDelay = { show: 1000, hide: 200 };

const ReportDetailsHeader = ({
  name,
  shared,
  onSaveCopy,
  onRename,
  onExport,
  onShare,
  onDelete,
}) => {
  return (
    <Row className="pl-4 pr-4 pt-4 pb-4 report-details-header">
      <Col xs="2" className="d-flex align-items-center">
        &lt;&nbsp;
        <Link to="/reports" className="back-to-reports-link">
          Back to Reports
        </Link>
      </Col>
      <Col xs="8" className="d-flex">
        <h1 className="text-center w-100 font-weight-normal">{name}</h1>
      </Col>
      <Col
        xs="2"
        className="d-flex justify-content-end align-items-center action-buttons-container"
      >
        {shared && <Button onClick={onSaveCopy}>Save Copy</Button>}
        {!shared && (
          <>
            <OverlayTrigger overlay={<Tooltip>Rename</Tooltip>} delay={actionButtonDelay}>
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faPen}
                size="lg"
                onClick={onRename}
              />
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Export</Tooltip>} delay={actionButtonDelay}>
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faExternalLinkAlt}
                size="lg"
                onClick={onExport}
              />
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Share</Tooltip>} delay={actionButtonDelay}>
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faLink}
                size="lg"
                onClick={onShare}
              />
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>} delay={actionButtonDelay}>
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faTrash}
                size="lg"
                onClick={onDelete}
              />
            </OverlayTrigger>
          </>
        )}
      </Col>
    </Row>
  );
};

export default ReportDetailsHeader;

export const VA = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4619, 37.7862],
            [-79.4375, 37.7951],
            [-79.4208, 37.789],
            [-79.4518, 37.7665],
            [-79.4619, 37.7862],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102220',
        NAME: 'Lexington City Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5400',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6467874,
        AWATER: 68429,
        INTPTLAT: '+37.7823316',
        INTPTLON: '-079.4443196',
        ELSDLEA: '02220',
        UNSDLEA: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0146, 38.3592],
            [-77.0148, 38.3324],
            [-77.0394, 38.3184],
            [-77.0479, 38.3526],
            [-77.0146, 38.3592],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100022',
        NAME: 'Dahlgren Department of Defense School District',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 10610320,
        AWATER: 514553,
        INTPTLAT: '+38.3363317',
        INTPTLON: '-077.0284422',
        ELSDLEA: '',
        UNSDLEA: '00022',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-77.2827, 38.5277],
              [-77.3153, 38.4788],
              [-77.3706, 38.5206],
              [-77.2827, 38.5277],
            ],
          ],
          [
            [
              [-77.5619, 38.601],
              [-77.4401, 38.6318],
              [-77.3716, 38.5215],
              [-77.48, 38.483],
              [-77.5609, 38.5271],
              [-77.5619, 38.601],
            ],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100023',
        NAME: 'Quantico Marine Corps Center School District',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: 'B',
        FUNCSTAT: 'E',
        ALAND: 235677343,
        AWATER: 5392855,
        INTPTLAT: '+38.5501212',
        INTPTLON: '-077.4620038',
        ELSDLEA: '',
        UNSDLEA: '00023',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9045, 37.3777],
            [-76.8948, 37.4276],
            [-76.742, 37.4671],
            [-76.7123, 37.4313],
            [-76.6587, 37.3801],
            [-76.7276, 37.3049],
            [-76.7399, 37.2739],
            [-76.6795, 37.2657],
            [-76.5914, 37.2147],
            [-76.6416, 37.1538],
            [-76.8832, 37.2231],
            [-76.9045, 37.3777],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100036',
        NAME: 'James City County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 368966992,
        AWATER: 95299777,
        INTPTLAT: '+37.3248367',
        INTPTLON: '-076.7778878',
        ELSDLEA: '',
        UNSDLEA: '00036',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2365, 37.8866],
            [-76.052, 37.9536],
            [-75.9527, 37.9068],
            [-75.8012, 37.9122],
            [-75.6243, 37.9942],
            [-75.1664, 38.0278],
            [-75.296, 37.8458],
            [-75.4171, 37.821],
            [-75.5418, 37.6061],
            [-75.5284, 37.5613],
            [-75.5974, 37.451],
            [-75.787, 37.4646],
            [-75.8352, 37.5542],
            [-76.1408, 37.5422],
            [-76.1517, 37.586],
            [-76.1757, 37.6716],
            [-76.2365, 37.8866],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100060',
        NAME: 'Accomack County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1164222686,
        AWATER: 2228759089,
        INTPTLAT: '+37.7659435',
        INTPTLON: '-075.7578073',
        ELSDLEA: '',
        UNSDLEA: '00060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.8392, 38.0476],
            [-78.7792, 38.0835],
            [-78.7494, 38.2066],
            [-78.6632, 38.2779],
            [-78.3698, 38.1848],
            [-78.2081, 38.1332],
            [-78.3066, 38.0064],
            [-78.4916, 37.7967],
            [-78.511, 37.7576],
            [-78.644, 37.7331],
            [-78.8392, 38.0476],
          ],
          [
            [-78.5236, 38.0225],
            [-78.4697, 38.0114],
            [-78.4473, 38.0461],
            [-78.4852, 38.069],
            [-78.5236, 38.0225],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100090',
        NAME: 'Albemarle County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1866869127,
        AWATER: 14032748,
        INTPTLAT: '+38.0241841',
        INTPTLON: '-078.5535056',
        ELSDLEA: '',
        UNSDLEA: '00090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1108, 38.8434],
            [-77.048, 38.8413],
            [-77.039, 38.7916],
            [-77.0414, 38.7854],
            [-77.1108, 38.8434],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100120',
        NAME: 'Alexandria City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38977689,
        AWATER: 1127171,
        INTPTLAT: '+38.8200709',
        INTPTLON: '-077.0840628',
        ELSDLEA: '',
        UNSDLEA: '00120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0561, 37.952],
            [-79.9354, 37.9544],
            [-79.8873, 37.893],
            [-79.6508, 37.8716],
            [-79.674, 37.7631],
            [-79.8167, 37.801],
            [-79.9176, 37.7018],
            [-80.0205, 37.6475],
            [-80.1444, 37.5966],
            [-80.221, 37.6278],
            [-80.2961, 37.6918],
            [-80.1622, 37.8751],
            [-80.0561, 37.952],
          ],
          [
            [-80.009, 37.7623],
            [-79.9782, 37.765],
            [-79.9671, 37.8034],
            [-79.9921, 37.7998],
            [-80.009, 37.7623],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100152',
        NAME: 'Alleghany County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1153734179,
        AWATER: 8510738,
        INTPTLAT: '+37.7879048',
        INTPTLON: '-080.0086690',
        ELSDLEA: '',
        UNSDLEA: '00152',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.132, 37.4547],
            [-77.9862, 37.4863],
            [-77.8998, 37.4754],
            [-77.8551, 37.4184],
            [-77.8776, 37.3662],
            [-77.7586, 37.2687],
            [-77.6506, 37.2651],
            [-77.7959, 37.1926],
            [-78.2312, 37.2962],
            [-78.235, 37.3681],
            [-78.132, 37.4547],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100180',
        NAME: 'Amelia County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 920142764,
        AWATER: 8424800,
        INTPTLAT: '+37.3319271',
        INTPTLON: '-077.9774619',
        ELSDLEA: '',
        UNSDLEA: '00180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4369, 37.617],
            [-79.345, 37.6611],
            [-79.2723, 37.7855],
            [-79.1719, 37.8031],
            [-79.0744, 37.7812],
            [-79.0674, 37.7203],
            [-78.9669, 37.677],
            [-78.8692, 37.5421],
            [-79.0185, 37.4299],
            [-79.0852, 37.3947],
            [-79.1833, 37.4659],
            [-79.3493, 37.5248],
            [-79.4369, 37.617],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100210',
        NAME: 'Amherst County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1227484902,
        AWATER: 12729826,
        INTPTLAT: '+37.6286103',
        INTPTLON: '-079.1542813',
        ELSDLEA: '',
        UNSDLEA: '00210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0185, 37.4299],
            [-78.8692, 37.5421],
            [-78.8252, 37.5525],
            [-78.6832, 37.429],
            [-78.5944, 37.398],
            [-78.6815, 37.2497],
            [-78.8242, 37.2054],
            [-78.9174, 37.2427],
            [-79.0185, 37.4299],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100240',
        NAME: 'Appomattox County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 863689281,
        AWATER: 3204692,
        INTPTLAT: '+37.3707253',
        INTPTLON: '-078.8109404',
        ELSDLEA: '',
        UNSDLEA: '00240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1198, 38.9343],
            [-77.048, 38.8413],
            [-77.1108, 38.8434],
            [-77.1497, 38.8757],
            [-77.1723, 38.8932],
            [-77.1198, 38.9343],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100270',
        NAME: 'Arlington County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 67318428,
        AWATER: 244142,
        INTPTLAT: '+38.8783374',
        INTPTLON: '-077.1007034',
        ELSDLEA: '',
        UNSDLEA: '00270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.5122, 38.1804],
            [-79.4337, 38.2295],
            [-79.3123, 38.4119],
            [-79.2243, 38.4778],
            [-78.7494, 38.2066],
            [-78.7792, 38.0835],
            [-78.8392, 38.0476],
            [-78.9023, 37.9514],
            [-79.0051, 37.8817],
            [-79.1574, 37.891],
            [-79.4824, 38.0861],
            [-79.5122, 38.1804],
          ],
          [
            [-79.1135, 38.1531],
            [-79.0498, 38.1211],
            [-79.0113, 38.1815],
            [-79.0974, 38.1845],
            [-79.1135, 38.1531],
          ],
          [
            [-78.9507, 38.0645],
            [-78.8722, 38.0396],
            [-78.8557, 38.0746],
            [-78.9035, 38.1],
            [-78.9507, 38.0645],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100300',
        NAME: 'Augusta County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2504602442,
        AWATER: 9972877,
        INTPTLAT: '+38.1719106',
        INTPTLON: '-079.1475571',
        ELSDLEA: '',
        UNSDLEA: '00300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9598, 38.0637],
            [-79.8973, 38.1934],
            [-79.7959, 38.2656],
            [-79.7062, 38.2212],
            [-79.5122, 38.1804],
            [-79.4824, 38.0861],
            [-79.6508, 37.8716],
            [-79.8873, 37.893],
            [-79.9354, 37.9544],
            [-80.0561, 37.952],
            [-79.9598, 38.0637],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100330',
        NAME: 'Bath County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1370514973,
        AWATER: 14044111,
        INTPTLAT: '+38.0683679',
        INTPTLON: '-079.7312032',
        ELSDLEA: '',
        UNSDLEA: '00330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.8476, 37.3092],
            [-79.6901, 37.4764],
            [-79.5937, 37.4489],
            [-79.4986, 37.5329],
            [-79.4369, 37.617],
            [-79.3493, 37.5248],
            [-79.1833, 37.4659],
            [-79.2538, 37.4472],
            [-79.2572, 37.3556],
            [-79.4429, 37.0559],
            [-79.4952, 37.022],
            [-79.5941, 37.0422],
            [-79.678, 37.1552],
            [-79.8471, 37.2255],
            [-79.8476, 37.3092],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100360',
        NAME: 'Bedford County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1968133960,
        AWATER: 42100195,
        INTPTLAT: '+37.3122229',
        INTPTLON: '-079.5270785',
        ELSDLEA: '',
        UNSDLEA: '00360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.2251, 37.2349],
            [-81.1041, 37.2806],
            [-80.9801, 37.2927],
            [-80.8546, 37.1475],
            [-80.9127, 37.0733],
            [-81.1143, 37.0226],
            [-81.2246, 37.0478],
            [-81.3794, 36.9524],
            [-81.4372, 37.0101],
            [-81.2614, 37.1148],
            [-81.2848, 37.2159],
            [-81.2251, 37.2349],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100390',
        NAME: 'Bland County Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 926515823,
        AWATER: 2585515,
        INTPTLAT: '+37.1306124',
        INTPTLON: '-081.1258532',
        ELSDLEA: '',
        UNSDLEA: '00390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.074, 37.4223],
            [-79.969, 37.5444],
            [-80.0205, 37.6475],
            [-79.9176, 37.7018],
            [-79.8167, 37.801],
            [-79.674, 37.7631],
            [-79.6836, 37.6617],
            [-79.4986, 37.5329],
            [-79.5937, 37.4489],
            [-79.6901, 37.4764],
            [-79.8476, 37.3092],
            [-80.074, 37.4223],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100420',
        NAME: 'Botetourt County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1401713911,
        AWATER: 12188503,
        INTPTLAT: '+37.5654811',
        INTPTLON: '-079.7975529',
        ELSDLEA: '',
        UNSDLEA: '00420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.1219, 36.6461],
            [-82.1461, 36.5947],
            [-82.2434, 36.5957],
            [-82.1219, 36.6461],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100450',
        NAME: 'Bristol City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 33745565,
        AWATER: 322409,
        INTPTLAT: '+36.6169541',
        INTPTLON: '-082.1575641',
        ELSDLEA: '',
        UNSDLEA: '00450',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0274, 36.7779],
            [-78.0036, 37.0228],
            [-77.8898, 36.9888],
            [-77.7655, 36.9747],
            [-77.658, 36.8945],
            [-77.6558, 36.7135],
            [-77.7671, 36.5454],
            [-77.8998, 36.5447],
            [-78.0462, 36.5442],
            [-78.0274, 36.7779],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100480',
        NAME: 'Brunswick County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1466384563,
        AWATER: 8314712,
        INTPTLAT: '+36.7642043',
        INTPTLON: '-077.8614832',
        ELSDLEA: '',
        UNSDLEA: '00480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3146, 37.2958],
            [-81.968, 37.538],
            [-81.9277, 37.5121],
            [-81.9966, 37.4767],
            [-81.8536, 37.2877],
            [-81.7401, 37.2378],
            [-81.9011, 37.1425],
            [-82.0118, 37.1124],
            [-82.0757, 37.0382],
            [-82.1487, 37.0417],
            [-82.1579, 37.1106],
            [-82.3146, 37.2958],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100510',
        NAME: 'Buchanan County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1302149875,
        AWATER: 2797157,
        INTPTLAT: '+37.2681198',
        INTPTLON: '-082.0381508',
        ELSDLEA: '',
        UNSDLEA: '00510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.644, 37.7331],
            [-78.511, 37.7576],
            [-78.4916, 37.7967],
            [-78.4056, 37.7353],
            [-78.2397, 37.6905],
            [-78.249, 37.6355],
            [-78.4651, 37.3397],
            [-78.5944, 37.398],
            [-78.6832, 37.429],
            [-78.8252, 37.5525],
            [-78.644, 37.7331],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100540',
        NAME: 'Buckingham County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1501303548,
        AWATER: 10099804,
        INTPTLAT: '+37.5739280',
        INTPTLON: '-078.5291687',
        ELSDLEA: '',
        UNSDLEA: '00540',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.3826, 37.7063],
            [-79.3748, 37.7393],
            [-79.3333, 37.7379],
            [-79.3599, 37.7024],
            [-79.3826, 37.7063],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100560',
        NAME: 'Buena Vista City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17350500,
        AWATER: 222803,
        INTPTLAT: '+37.7313646',
        INTPTLON: '-079.3566352',
        ELSDLEA: '',
        UNSDLEA: '00560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2572, 37.3556],
            [-79.1752, 37.3421],
            [-79.0852, 37.3947],
            [-79.0185, 37.4299],
            [-78.9174, 37.2427],
            [-78.8242, 37.2054],
            [-78.9046, 37.0223],
            [-79.0923, 37.0615],
            [-79.3322, 37.1256],
            [-79.4429, 37.0559],
            [-79.2572, 37.3556],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100600',
        NAME: 'Campbell County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1305049383,
        AWATER: 9002937,
        INTPTLAT: '+37.2101510',
        INTPTLON: '-079.0954285',
        ELSDLEA: '',
        UNSDLEA: '00600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6422, 37.9909],
            [-77.3703, 38.2466],
            [-77.3267, 38.2451],
            [-77.116, 38.1499],
            [-77.169, 38.081],
            [-77.0701, 37.9648],
            [-77.1486, 37.972],
            [-77.1814, 37.8917],
            [-77.2477, 37.8762],
            [-77.3452, 37.7894],
            [-77.4219, 37.8649],
            [-77.6422, 37.9909],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100660',
        NAME: 'Caroline County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1366270763,
        AWATER: 24228168,
        INTPTLAT: '+38.0303193',
        INTPTLON: '-077.3523482',
        ELSDLEA: '',
        UNSDLEA: '00660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.0424, 36.8064],
            [-80.7405, 36.879],
            [-80.634, 36.9318],
            [-80.4642, 36.7087],
            [-80.612, 36.5578],
            [-80.6329, 36.5586],
            [-80.838, 36.5591],
            [-80.9142, 36.6502],
            [-80.8773, 36.6922],
            [-80.9162, 36.6946],
            [-80.9323, 36.6716],
            [-81.0424, 36.8064],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100690',
        NAME: 'Carroll County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1229441999,
        AWATER: 7647531,
        INTPTLAT: '+36.7319275',
        INTPTLON: '-080.7278646',
        ELSDLEA: '',
        UNSDLEA: '00690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2497, 37.382],
            [-77.1773, 37.4906],
            [-77.0958, 37.4403],
            [-76.9045, 37.3777],
            [-76.8832, 37.2231],
            [-76.9724, 37.2485],
            [-77.1251, 37.3029],
            [-77.2707, 37.3208],
            [-77.2497, 37.382],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100720',
        NAME: 'Charles City County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 473493984,
        AWATER: 55490600,
        INTPTLAT: '+37.3610537',
        INTPTLON: '-077.0541700',
        ELSDLEA: '',
        UNSDLEA: '00720',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9046, 37.0223],
            [-78.8242, 37.2054],
            [-78.6815, 37.2497],
            [-78.6925, 37.2048],
            [-78.5471, 37.1504],
            [-78.4436, 37.0794],
            [-78.493, 36.8912],
            [-78.6485, 36.6979],
            [-78.6715, 36.858],
            [-78.7747, 36.981],
            [-78.9046, 37.0223],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100750',
        NAME: 'Charlotte County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1231001549,
        AWATER: 5824992,
        INTPTLAT: '+37.0090421',
        INTPTLON: '-078.6585682',
        ELSDLEA: '',
        UNSDLEA: '00750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.5236, 38.0225],
            [-78.4852, 38.069],
            [-78.4473, 38.0461],
            [-78.4697, 38.0114],
            [-78.5236, 38.0225],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100780',
        NAME: 'Charlottesville City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26516183,
        AWATER: 52974,
        INTPTLAT: '+38.0376579',
        INTPTLON: '-078.4853806',
        ELSDLEA: '',
        UNSDLEA: '00780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4204, 36.8663],
            [-76.3757, 36.7861],
            [-76.29, 36.822],
            [-76.2261, 36.8399],
            [-76.122, 36.6657],
            [-76.1224, 36.5506],
            [-76.3132, 36.5506],
            [-76.4915, 36.5506],
            [-76.4575, 36.8148],
            [-76.4204, 36.8663],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100810',
        NAME: 'Chesapeake City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 882668771,
        AWATER: 26057053,
        INTPTLAT: '+36.6793761',
        INTPTLON: '-076.3017884',
        ELSDLEA: '',
        UNSDLEA: '00810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8551, 37.4184],
            [-77.654, 37.5626],
            [-77.5961, 37.5557],
            [-77.5124, 37.4729],
            [-77.4208, 37.4471],
            [-77.3757, 37.3817],
            [-77.2497, 37.382],
            [-77.2707, 37.3208],
            [-77.284, 37.313],
            [-77.2964, 37.3109],
            [-77.2972, 37.3108],
            [-77.3345, 37.3125],
            [-77.3767, 37.245],
            [-77.3839, 37.2389],
            [-77.4037, 37.2373],
            [-77.3825, 37.2428],
            [-77.3792, 37.2676],
            [-77.3889, 37.2873],
            [-77.4144, 37.2609],
            [-77.4165, 37.2332],
            [-77.4475, 37.2227],
            [-77.5604, 37.2257],
            [-77.6506, 37.2651],
            [-77.7586, 37.2687],
            [-77.8776, 37.3662],
            [-77.8551, 37.4184],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100840',
        NAME: 'Chesterfield County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1096531675,
        AWATER: 35172847,
        INTPTLAT: '+37.3784338',
        INTPTLON: '-077.5858474',
        ELSDLEA: '',
        UNSDLEA: '00840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.1518, 39.0366],
            [-78.0332, 39.2646],
            [-77.8283, 39.1324],
            [-77.962, 39.0138],
            [-78.0053, 38.9803],
            [-78.1518, 39.0366],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100870',
        NAME: 'Clarke County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 455565957,
        AWATER: 5649744,
        INTPTLAT: '+39.1153814',
        INTPTLON: '-077.9908357',
        ELSDLEA: '',
        UNSDLEA: '00870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9909, 38.2736],
            [-76.9621, 38.257],
            [-76.9623, 38.2299],
            [-76.9909, 38.2736],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100930',
        NAME: 'Colonial Beach Town Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 4067290,
        AWATER: 773594,
        INTPTLAT: '+38.2562215',
        INTPTLON: '-076.9704797',
        ELSDLEA: '',
        UNSDLEA: '00930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4037, 37.2373],
            [-77.4165, 37.2332],
            [-77.4144, 37.2609],
            [-77.3889, 37.2873],
            [-77.3792, 37.2676],
            [-77.3825, 37.2428],
            [-77.4037, 37.2373],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100960',
        NAME: 'Colonial Heights City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19481043,
        AWATER: 757290,
        INTPTLAT: '+37.2616846',
        INTPTLON: '-077.3968043',
        ELSDLEA: '',
        UNSDLEA: '00960',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.009, 37.7623],
            [-79.9921, 37.7998],
            [-79.9671, 37.8034],
            [-79.9782, 37.765],
            [-80.009, 37.7623],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5100990',
        NAME: 'Covington City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 14163308,
        AWATER: 522024,
        INTPTLAT: '+37.7781426',
        INTPTLON: '-079.9860390',
        ELSDLEA: '',
        UNSDLEA: '00990',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.4756, 37.4229],
            [-80.2998, 37.5083],
            [-80.3285, 37.5643],
            [-80.221, 37.6278],
            [-80.1444, 37.5966],
            [-80.0205, 37.6475],
            [-79.969, 37.5444],
            [-80.074, 37.4223],
            [-80.262, 37.3407],
            [-80.3279, 37.3668],
            [-80.4334, 37.3149],
            [-80.4756, 37.4229],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101020',
        NAME: 'Craig County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 853490643,
        AWATER: 2797724,
        INTPTLAT: '+37.4736031',
        INTPTLON: '-080.2310507',
        ELSDLEA: '',
        UNSDLEA: '01020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.9355, 38.6959],
            [-77.8772, 38.5849],
            [-77.7355, 38.4131],
            [-77.6348, 38.4097],
            [-77.6187, 38.3678],
            [-77.7032, 38.3606],
            [-77.9086, 38.3818],
            [-78.0946, 38.3114],
            [-78.0964, 38.4046],
            [-78.232, 38.5326],
            [-78.1688, 38.5238],
            [-77.9355, 38.6959],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101050',
        NAME: 'Culpeper County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 982232458,
        AWATER: 8550409,
        INTPTLAT: '+38.4859303',
        INTPTLON: '-077.9564758',
        ELSDLEA: '',
        UNSDLEA: '01050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2397, 37.6905],
            [-78.1593, 37.7485],
            [-78.0723, 37.6574],
            [-78.132, 37.4547],
            [-78.235, 37.3681],
            [-78.3218, 37.3093],
            [-78.4651, 37.3397],
            [-78.249, 37.6355],
            [-78.2397, 37.6905],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101080',
        NAME: 'Cumberland County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 770419327,
        AWATER: 5931686,
        INTPTLAT: '+37.5201891',
        INTPTLON: '-078.2528364',
        ELSDLEA: '',
        UNSDLEA: '01080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.3427, 36.5414],
            [-79.4702, 36.5408],
            [-79.403, 36.6415],
            [-79.3311, 36.6018],
            [-79.3427, 36.5414],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101110',
        NAME: 'Danville City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 111287882,
        AWATER: 2538056,
        INTPTLAT: '+36.5833337',
        INTPTLON: '-079.4080707',
        ELSDLEA: '',
        UNSDLEA: '01110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.5538, 37.2029],
            [-82.3146, 37.2958],
            [-82.1579, 37.1106],
            [-82.1487, 37.0417],
            [-82.328, 36.9709],
            [-82.4674, 37.0221],
            [-82.5439, 37.125],
            [-82.5538, 37.2029],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101140',
        NAME: 'Dickenson County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 856108125,
        AWATER: 8073687,
        INTPTLAT: '+37.1370640',
        INTPTLON: '-082.3492305',
        ELSDLEA: '',
        UNSDLEA: '01140',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7959, 37.1926],
            [-77.6506, 37.2651],
            [-77.5604, 37.2257],
            [-77.4475, 37.2227],
            [-77.3992, 37.1709],
            [-77.3985, 36.993],
            [-77.619, 36.8778],
            [-77.658, 36.8945],
            [-77.7655, 36.9747],
            [-77.8898, 36.9888],
            [-77.9003, 37.1439],
            [-77.7959, 37.1926],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101170',
        NAME: 'Dinwiddie County Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1304626416,
        AWATER: 8999965,
        INTPTLAT: '+37.0734984',
        INTPTLON: '-077.6354922',
        ELSDLEA: '',
        UNSDLEA: '01170',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5639, 36.7031],
            [-77.5094, 36.7164],
            [-77.5092, 36.683],
            [-77.5565, 36.6749],
            [-77.5639, 36.7031],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101190',
        NAME: 'Emporia City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 17871525,
        AWATER: 165621,
        INTPTLAT: '+36.6961820',
        INTPTLON: '-077.5359753',
        ELSDLEA: '',
        UNSDLEA: '01190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.116, 38.1499],
            [-77.0617, 38.1616],
            [-77.0281, 38.0908],
            [-76.937, 38.0771],
            [-76.9005, 37.9821],
            [-76.6682, 37.7784],
            [-76.7511, 37.7286],
            [-76.8044, 37.7949],
            [-76.9426, 37.7848],
            [-77.0188, 37.8448],
            [-77.0701, 37.9648],
            [-77.169, 38.081],
            [-77.116, 38.1499],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101200',
        NAME: 'Essex County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 665858937,
        AWATER: 74793668,
        INTPTLAT: '+37.9394802',
        INTPTLON: '-076.9418713',
        ELSDLEA: '',
        UNSDLEA: '01200',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3349, 38.8537],
            [-77.3031, 38.8692],
            [-77.2706, 38.8661],
            [-77.2705, 38.8409],
            [-77.3162, 38.8364],
            [-77.3349, 38.8537],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101230',
        NAME: 'Fairfax City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 16162121,
        AWATER: 90056,
        INTPTLAT: '+38.8531833',
        INTPTLON: '-077.2990252',
        ELSDLEA: '',
        UNSDLEA: '01230',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5358, 38.8475],
            [-77.3283, 39.0577],
            [-77.2449, 38.9826],
            [-77.1198, 38.9343],
            [-77.1723, 38.8932],
            [-77.1897, 38.878],
            [-77.1497, 38.8757],
            [-77.1108, 38.8434],
            [-77.0414, 38.7854],
            [-77.0864, 38.7062],
            [-77.1297, 38.6347],
            [-77.2255, 38.6056],
            [-77.2213, 38.6504],
            [-77.5358, 38.8475],
          ],
          [
            [-77.3349, 38.8537],
            [-77.3162, 38.8364],
            [-77.2705, 38.8409],
            [-77.2706, 38.8661],
            [-77.3031, 38.8692],
            [-77.3349, 38.8537],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101260',
        NAME: 'Fairfax County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1012303733,
        AWATER: 39435616,
        INTPTLAT: '+38.8295203',
        INTPTLON: '-077.2732524',
        ELSDLEA: '',
        UNSDLEA: '01260',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1723, 38.8932],
            [-77.1497, 38.8757],
            [-77.1897, 38.878],
            [-77.1723, 38.8932],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101290',
        NAME: 'Falls Church City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 5311307,
        AWATER: 6,
        INTPTLAT: '+38.8847220',
        INTPTLON: '-077.1756027',
        ELSDLEA: '',
        UNSDLEA: '01290',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0053, 38.9803],
            [-77.962, 39.0138],
            [-77.6551, 38.9426],
            [-77.7154, 38.8322],
            [-77.5619, 38.601],
            [-77.5609, 38.5271],
            [-77.6348, 38.4097],
            [-77.7355, 38.4131],
            [-77.8772, 38.5849],
            [-77.9355, 38.6959],
            [-78.1306, 38.8649],
            [-78.0053, 38.9803],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101320',
        NAME: 'Fauquier County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1663460278,
        AWATER: 9694823,
        INTPTLAT: '+38.7440980',
        INTPTLON: '-077.8214837',
        ELSDLEA: '',
        UNSDLEA: '01320',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5439, 36.9846],
            [-80.3474, 37.0218],
            [-80.178, 37.1134],
            [-80.1281, 37.1229],
            [-80.1079, 37.0737],
            [-80.1458, 36.9496],
            [-80.2355, 36.8721],
            [-80.3854, 36.7987],
            [-80.4642, 36.7087],
            [-80.634, 36.9318],
            [-80.5439, 36.9846],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101350',
        NAME: 'Floyd County Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 986590282,
        AWATER: 2270014,
        INTPTLAT: '+36.9314381',
        INTPTLON: '-080.3503087',
        ELSDLEA: '',
        UNSDLEA: '01350',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4916, 37.7967],
            [-78.3066, 38.0064],
            [-78.0633, 37.9051],
            [-78.1593, 37.7485],
            [-78.2397, 37.6905],
            [-78.4056, 37.7353],
            [-78.4916, 37.7967],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101380',
        NAME: 'Fluvanna County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 740762872,
        AWATER: 10718670,
        INTPTLAT: '+37.8305710',
        INTPTLON: '-078.2834821',
        ELSDLEA: '',
        UNSDLEA: '01380',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9246, 36.7077],
            [-76.9097, 36.6482],
            [-76.9554, 36.6646],
            [-76.9246, 36.7077],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101410',
        NAME: 'Franklin City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21295862,
        AWATER: 369820,
        INTPTLAT: '+36.6840142',
        INTPTLON: '-076.9413956',
        ELSDLEA: '',
        UNSDLEA: '01410',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.1281, 37.1229],
            [-80.0084, 37.1726],
            [-79.9609, 37.138],
            [-79.8471, 37.2255],
            [-79.678, 37.1552],
            [-79.5941, 37.0422],
            [-79.6407, 36.8561],
            [-79.8021, 36.7921],
            [-79.9998, 36.8307],
            [-80.0415, 36.7944],
            [-80.0664, 36.8411],
            [-80.2355, 36.8721],
            [-80.1458, 36.9496],
            [-80.1079, 37.0737],
            [-80.1281, 37.1229],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101440',
        NAME: 'Franklin County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1788231299,
        AWATER: 54670666,
        INTPTLAT: '+36.9911503',
        INTPTLON: '-079.8826633',
        ELSDLEA: '',
        UNSDLEA: '01440',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.5081, 39.0886],
            [-78.4039, 39.1677],
            [-78.4194, 39.2575],
            [-78.3404, 39.3536],
            [-78.3471, 39.466],
            [-78.2288, 39.3912],
            [-78.0332, 39.2646],
            [-78.1518, 39.0366],
            [-78.3142, 39.008],
            [-78.408, 39.0919],
            [-78.4633, 39.0308],
            [-78.5441, 39.0567],
            [-78.5081, 39.0886],
          ],
          [
            [-78.2007, 39.1802],
            [-78.1709, 39.1438],
            [-78.137, 39.1845],
            [-78.1878, 39.2047],
            [-78.2007, 39.1802],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101470',
        NAME: 'Frederick County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1070958657,
        AWATER: 5194171,
        INTPTLAT: '+39.2036768',
        INTPTLON: '-078.2638283',
        ELSDLEA: '',
        UNSDLEA: '01470',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5303, 38.3092],
            [-77.4671, 38.3187],
            [-77.4471, 38.2846],
            [-77.5303, 38.3092],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101510',
        NAME: 'Fredericksburg City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 27042222,
        AWATER: 178255,
        INTPTLAT: '+38.2992718',
        INTPTLON: '-077.4866583',
        ELSDLEA: '',
        UNSDLEA: '01510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9323, 36.6716],
            [-80.9162, 36.6946],
            [-80.8773, 36.6922],
            [-80.9142, 36.6502],
            [-80.9323, 36.6716],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101560',
        NAME: 'Galax City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 21340444,
        AWATER: 103782,
        INTPTLAT: '+36.6656397',
        INTPTLON: '-080.9143083',
        ELSDLEA: '',
        UNSDLEA: '01560',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9801, 37.2927],
            [-80.8495, 37.3469],
            [-80.8595, 37.4295],
            [-80.8585, 37.4283],
            [-80.7701, 37.3724],
            [-80.5114, 37.4817],
            [-80.4756, 37.4229],
            [-80.4334, 37.3149],
            [-80.6069, 37.2449],
            [-80.8546, 37.1475],
            [-80.9801, 37.2927],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101590',
        NAME: 'Giles County Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 921560258,
        AWATER: 11925828,
        INTPTLAT: '+37.3119305',
        INTPTLON: '-080.7171889',
        ELSDLEA: '',
        UNSDLEA: '01590',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6541, 37.6032],
            [-76.4376, 37.5144],
            [-76.4447, 37.4185],
            [-76.3045, 37.2784],
            [-76.4469, 37.2346],
            [-76.5161, 37.2488],
            [-76.6587, 37.3801],
            [-76.7123, 37.4313],
            [-76.6674, 37.4555],
            [-76.6541, 37.6032],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101620',
        NAME: 'Gloucester County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 564116916,
        AWATER: 181754412,
        INTPTLAT: '+37.4035413',
        INTPTLON: '-076.5235051',
        ELSDLEA: '',
        UNSDLEA: '01620',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0633, 37.9051],
            [-77.9486, 37.8463],
            [-77.8889, 37.7608],
            [-77.7969, 37.7291],
            [-77.6301, 37.707],
            [-77.654, 37.5626],
            [-77.8144, 37.6064],
            [-77.9231, 37.6914],
            [-77.9834, 37.6247],
            [-78.0723, 37.6574],
            [-78.1593, 37.7485],
            [-78.0633, 37.9051],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101650',
        NAME: 'Goochland County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 728886866,
        AWATER: 21963100,
        INTPTLAT: '+37.7188141',
        INTPTLON: '-077.9176263',
        ELSDLEA: '',
        UNSDLEA: '01650',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.6469, 36.6119],
            [-81.6058, 36.6387],
            [-81.5251, 36.7078],
            [-81.2616, 36.7631],
            [-81.0974, 36.7689],
            [-81.0424, 36.8064],
            [-80.9323, 36.6716],
            [-80.9142, 36.6502],
            [-80.838, 36.5591],
            [-80.9017, 36.5618],
            [-81.3532, 36.5747],
            [-81.6774, 36.5882],
            [-81.6469, 36.6119],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101690',
        NAME: 'Grayson County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1145220897,
        AWATER: 9797891,
        INTPTLAT: '+36.6522288',
        INTPTLON: '-081.2153240',
        ELSDLEA: '',
        UNSDLEA: '01690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.486, 38.4215],
            [-78.4529, 38.4755],
            [-78.43, 38.3671],
            [-78.3651, 38.2924],
            [-78.287, 38.2715],
            [-78.3698, 38.1848],
            [-78.6632, 38.2779],
            [-78.5515, 38.3363],
            [-78.486, 38.4215],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101710',
        NAME: 'Greene County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 404411500,
        AWATER: 1706090,
        INTPTLAT: '+38.2979805',
        INTPTLON: '-078.4701629',
        ELSDLEA: '',
        UNSDLEA: '01710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.658, 36.8945],
            [-77.619, 36.8778],
            [-77.5104, 36.8451],
            [-77.49, 36.721],
            [-77.4301, 36.7077],
            [-77.3609, 36.5648],
            [-77.2969, 36.5448],
            [-77.7671, 36.5454],
            [-77.6558, 36.7135],
            [-77.658, 36.8945],
          ],
          [
            [-77.5639, 36.7031],
            [-77.5565, 36.6749],
            [-77.5092, 36.683],
            [-77.5094, 36.7164],
            [-77.5639, 36.7031],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101740',
        NAME: 'Greensville County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 764649954,
        AWATER: 4086108,
        INTPTLAT: '+36.6803363',
        INTPTLON: '-077.5602774',
        ELSDLEA: '',
        UNSDLEA: '01740',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.0923, 37.0615],
            [-78.9046, 37.0223],
            [-78.7747, 36.981],
            [-78.6715, 36.858],
            [-78.6485, 36.6979],
            [-78.6977, 36.6788],
            [-78.7341, 36.5419],
            [-78.7963, 36.5417],
            [-79.1379, 36.5417],
            [-79.2186, 36.5416],
            [-79.0923, 37.0615],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101770',
        NAME: 'Halifax County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2118209785,
        AWATER: 30531356,
        INTPTLAT: '+36.7664610',
        INTPTLON: '-078.9396142',
        ELSDLEA: '',
        UNSDLEA: '01770',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4355, 37.0949],
            [-76.3958, 37.1072],
            [-76.1349, 37.1176],
            [-76.1339, 37.0409],
            [-76.3323, 36.9661],
            [-76.3418, 36.9248],
            [-76.3844, 36.9511],
            [-76.4489, 37.0372],
            [-76.4355, 37.0949],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101800',
        NAME: 'Hampton City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 133308914,
        AWATER: 219683212,
        INTPTLAT: '+37.0480303',
        INTPTLON: '-076.2971486',
        ELSDLEA: '',
        UNSDLEA: '01800',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6873, 38.008],
            [-77.6422, 37.9909],
            [-77.4219, 37.8649],
            [-77.3452, 37.7894],
            [-77.2363, 37.7266],
            [-77.1237, 37.6269],
            [-77.2285, 37.5364],
            [-77.4087, 37.6147],
            [-77.4458, 37.684],
            [-77.6301, 37.707],
            [-77.7969, 37.7291],
            [-77.6873, 38.008],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101830',
        NAME: 'Hanover County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1213582999,
        AWATER: 13568383,
        INTPTLAT: '+37.7601655',
        INTPTLON: '-077.4909916',
        ELSDLEA: '',
        UNSDLEA: '01830',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9179, 38.3942],
            [-78.9006, 38.4592],
            [-78.8243, 38.4351],
            [-78.9179, 38.3942],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101860',
        NAME: 'Harrisonburg City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 44889777,
        AWATER: 149942,
        INTPTLAT: '+38.4362547',
        INTPTLON: '-078.8733028',
        ELSDLEA: '',
        UNSDLEA: '01860',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6301, 37.707],
            [-77.4458, 37.684],
            [-77.4087, 37.6147],
            [-77.2285, 37.5364],
            [-77.1773, 37.4906],
            [-77.2497, 37.382],
            [-77.3757, 37.3817],
            [-77.4208, 37.4471],
            [-77.4132, 37.5799],
            [-77.4768, 37.5985],
            [-77.5961, 37.5557],
            [-77.654, 37.5626],
            [-77.6301, 37.707],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101890',
        NAME: 'Henrico County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 605287712,
        AWATER: 28969984,
        INTPTLAT: '+37.4375205',
        INTPTLON: '-077.3003330',
        ELSDLEA: '',
        UNSDLEA: '01890',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0415, 36.7944],
            [-79.9998, 36.8307],
            [-79.8021, 36.7921],
            [-79.6407, 36.8561],
            [-79.7149, 36.5419],
            [-80.0273, 36.5425],
            [-80.0535, 36.5425],
            [-80.0944, 36.7796],
            [-80.0415, 36.7944],
          ],
          [
            [-79.9041, 36.6841],
            [-79.8593, 36.6617],
            [-79.8374, 36.7041],
            [-79.8748, 36.7152],
            [-79.9041, 36.6841],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101920',
        NAME: 'Henry County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 990258085,
        AWATER: 5281696,
        INTPTLAT: '+36.6206074',
        INTPTLON: '-079.9806728',
        ELSDLEA: '',
        UNSDLEA: '01920',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6491, 38.5915],
            [-79.5369, 38.5509],
            [-79.4766, 38.4572],
            [-79.3123, 38.4119],
            [-79.4337, 38.2295],
            [-79.5122, 38.1804],
            [-79.7062, 38.2212],
            [-79.7959, 38.2656],
            [-79.6897, 38.4315],
            [-79.6491, 38.5915],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101950',
        NAME: 'Highland County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1075249033,
        AWATER: 1805613,
        INTPTLAT: '+38.3662431',
        INTPTLON: '-079.5644718',
        ELSDLEA: '',
        UNSDLEA: '01950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3345, 37.3125],
            [-77.2972, 37.3108],
            [-77.2964, 37.3109],
            [-77.284, 37.313],
            [-77.2532, 37.2957],
            [-77.3224, 37.2654],
            [-77.3345, 37.3125],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5101980',
        NAME: 'Hopewell City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 26655077,
        AWATER: 1324078,
        INTPTLAT: '+37.2910098',
        INTPTLON: '-077.2989443',
        ELSDLEA: '',
        UNSDLEA: '01980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9246, 36.7077],
            [-76.8149, 36.9005],
            [-76.8497, 36.9962],
            [-76.7118, 37.0554],
            [-76.6416, 37.1538],
            [-76.6282, 37.0928],
            [-76.4453, 36.9419],
            [-76.5335, 36.9156],
            [-76.8976, 36.644],
            [-76.9097, 36.6482],
            [-76.9246, 36.7077],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102010',
        NAME: 'Isle of Wight County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 817464437,
        AWATER: 122256199,
        INTPTLAT: '+36.9014184',
        INTPTLON: '-076.7075688',
        ELSDLEA: '',
        UNSDLEA: '02010',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.0701, 37.9648],
            [-77.0188, 37.8448],
            [-76.9426, 37.7848],
            [-76.8044, 37.7949],
            [-76.7511, 37.7286],
            [-76.6541, 37.6032],
            [-76.6674, 37.4555],
            [-76.7123, 37.4313],
            [-76.742, 37.4671],
            [-76.7922, 37.5176],
            [-76.7995, 37.5747],
            [-76.9275, 37.6905],
            [-77.0333, 37.7208],
            [-77.1814, 37.8917],
            [-77.1486, 37.972],
            [-77.0701, 37.9648],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102070',
        NAME: 'King and Queen County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 816287771,
        AWATER: 28874439,
        INTPTLAT: '+37.7143718',
        INTPTLON: '-076.9018551',
        ELSDLEA: '',
        UNSDLEA: '02070',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2862, 38.347],
            [-77.0146, 38.3592],
            [-77.0479, 38.3526],
            [-77.0394, 38.3184],
            [-77.0148, 38.3324],
            [-76.9977, 38.278],
            [-77.0617, 38.1616],
            [-77.116, 38.1499],
            [-77.3267, 38.2451],
            [-77.2862, 38.347],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102100',
        NAME: 'King George County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 454650253,
        AWATER: 20517271,
        INTPTLAT: '+38.2770489',
        INTPTLON: '-077.1627693',
        ELSDLEA: '',
        UNSDLEA: '02100',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1814, 37.8917],
            [-77.0333, 37.7208],
            [-76.9275, 37.6905],
            [-76.7995, 37.5747],
            [-76.829, 37.5465],
            [-77.0397, 37.5873],
            [-77.1237, 37.6269],
            [-77.2363, 37.7266],
            [-77.3452, 37.7894],
            [-77.2477, 37.8762],
            [-77.1814, 37.8917],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102120',
        NAME: 'King William County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 696423501,
        AWATER: 26114844,
        INTPTLAT: '+37.7082604',
        INTPTLON: '-077.0910539',
        ELSDLEA: '',
        UNSDLEA: '02120',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6682, 37.7784],
            [-76.5078, 37.8388],
            [-76.4201, 37.8238],
            [-76.376, 37.7133],
            [-76.3084, 37.6698],
            [-76.1757, 37.6716],
            [-76.1517, 37.586],
            [-76.3682, 37.5943],
            [-76.5366, 37.6392],
            [-76.5872, 37.7371],
            [-76.6682, 37.7784],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102160',
        NAME: 'Lancaster County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 345223678,
        AWATER: 254112331,
        INTPTLAT: '+37.7048433',
        INTPTLON: '-076.4126628',
        ELSDLEA: '',
        UNSDLEA: '02160',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.461, 36.6649],
            [-83.1943, 36.7395],
            [-83.0728, 36.8545],
            [-82.8785, 36.8933],
            [-82.7713, 36.7999],
            [-82.8232, 36.7333],
            [-82.9475, 36.6758],
            [-82.9851, 36.5937],
            [-83.4721, 36.5973],
            [-83.6754, 36.6008],
            [-83.5324, 36.6647],
            [-83.461, 36.6649],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102190',
        NAME: 'Lee County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1127988664,
        AWATER: 4896425,
        INTPTLAT: '+36.7017206',
        INTPTLON: '-083.1301117',
        ELSDLEA: '',
        UNSDLEA: '02190',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.8283, 39.1324],
            [-77.7195, 39.3213],
            [-77.6772, 39.3245],
            [-77.5688, 39.3064],
            [-77.4588, 39.2203],
            [-77.5273, 39.147],
            [-77.4606, 39.0748],
            [-77.3283, 39.0577],
            [-77.5358, 38.8475],
            [-77.6551, 38.9426],
            [-77.962, 39.0138],
            [-77.8283, 39.1324],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102250',
        NAME: 'Loudoun County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1335804259,
        AWATER: 14448944,
        INTPTLAT: '+39.0812000',
        INTPTLON: '-077.6388976',
        ELSDLEA: '',
        UNSDLEA: '02250',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2081, 38.1332],
            [-78.1018, 38.1525],
            [-77.9548, 38.1174],
            [-77.8397, 38.106],
            [-77.6873, 38.008],
            [-77.7969, 37.7291],
            [-77.8889, 37.7608],
            [-77.9486, 37.8463],
            [-78.0633, 37.9051],
            [-78.3066, 38.0064],
            [-78.2081, 38.1332],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102280',
        NAME: 'Louisa County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1285413687,
        AWATER: 37737584,
        INTPTLAT: '+37.9727041',
        INTPTLON: '-077.9597950',
        ELSDLEA: '',
        UNSDLEA: '02280',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.4436, 37.0794],
            [-78.2394, 37.1201],
            [-78.1709, 37.0539],
            [-78.0036, 37.0228],
            [-78.0274, 36.7779],
            [-78.2415, 36.808],
            [-78.493, 36.8912],
            [-78.4436, 37.0794],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102310',
        NAME: 'Lunenburg County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1118063299,
        AWATER: 1795027,
        INTPTLAT: '+36.9460943',
        INTPTLON: '-078.2419852',
        ELSDLEA: '',
        UNSDLEA: '02310',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1833, 37.4659],
            [-79.0852, 37.3947],
            [-79.1752, 37.3421],
            [-79.2572, 37.3556],
            [-79.2538, 37.4472],
            [-79.1833, 37.4659],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102340',
        NAME: 'Lynchburg City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 127241541,
        AWATER: 1343203,
        INTPTLAT: '+37.3990161',
        INTPTLON: '-079.1954582',
        ELSDLEA: '',
        UNSDLEA: '02340',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4757, 38.7812],
            [-77.4593, 38.7726],
            [-77.4516, 38.7383],
            [-77.5015, 38.7384],
            [-77.4757, 38.7812],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102360',
        NAME: 'Manassas City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25594690,
        AWATER: 134901,
        INTPTLAT: '+38.7471720',
        INTPTLON: '-077.4845362',
        ELSDLEA: '',
        UNSDLEA: '02360',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.3383, 38.6273],
            [-78.232, 38.5326],
            [-78.0964, 38.4046],
            [-78.0946, 38.3114],
            [-78.2206, 38.2303],
            [-78.287, 38.2715],
            [-78.3651, 38.2924],
            [-78.43, 38.3671],
            [-78.4529, 38.4755],
            [-78.3383, 38.6273],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102370',
        NAME: 'Madison County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 830565358,
        AWATER: 3085774,
        INTPTLAT: '+38.4120586',
        INTPTLON: '-078.2769614',
        ELSDLEA: '',
        UNSDLEA: '02370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4593, 38.7726],
            [-77.4757, 38.7812],
            [-77.4207, 38.7622],
            [-77.4275, 38.7534],
            [-77.4593, 38.7726],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102390',
        NAME: 'Manassas Park City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 6575876,
        AWATER: 956,
        INTPTLAT: '+38.7689451',
        INTPTLON: '-077.4487670',
        ELSDLEA: '',
        UNSDLEA: '02390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.9041, 36.6841],
            [-79.8748, 36.7152],
            [-79.8374, 36.7041],
            [-79.8593, 36.6617],
            [-79.9041, 36.6841],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102400',
        NAME: 'Martinsville City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 28376605,
        AWATER: 133615,
        INTPTLAT: '+36.6835269',
        INTPTLON: '-079.8636475',
        ELSDLEA: '',
        UNSDLEA: '02400',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.4376, 37.5144],
            [-76.4041, 37.5355],
            [-76.1408, 37.5422],
            [-76.1363, 37.2821],
            [-76.3045, 37.2784],
            [-76.4447, 37.4185],
            [-76.4376, 37.5144],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102430',
        NAME: 'Mathews County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 222555391,
        AWATER: 430023700,
        INTPTLAT: '+37.4253479',
        INTPTLON: '-076.2688078',
        ELSDLEA: '',
        UNSDLEA: '02430',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6485, 36.6979],
            [-78.493, 36.8912],
            [-78.2415, 36.808],
            [-78.0274, 36.7779],
            [-78.0462, 36.5442],
            [-78.3239, 36.5438],
            [-78.457, 36.5425],
            [-78.7341, 36.5419],
            [-78.6977, 36.6788],
            [-78.6485, 36.6979],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102460',
        NAME: 'Mecklenburg County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1620003221,
        AWATER: 139190905,
        INTPTLAT: '+36.6872563',
        INTPTLON: '-078.3689592',
        ELSDLEA: '',
        UNSDLEA: '02460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7511, 37.7286],
            [-76.6682, 37.7784],
            [-76.5872, 37.7371],
            [-76.5366, 37.6392],
            [-76.3682, 37.5943],
            [-76.1517, 37.586],
            [-76.1408, 37.5422],
            [-76.4041, 37.5355],
            [-76.4376, 37.5144],
            [-76.6541, 37.6032],
            [-76.7511, 37.7286],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102490',
        NAME: 'Middlesex County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 337507715,
        AWATER: 208357022,
        INTPTLAT: '+37.6088443',
        INTPTLON: '-076.5201062',
        ELSDLEA: '',
        UNSDLEA: '02490',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.6069, 37.2449],
            [-80.4334, 37.3149],
            [-80.3279, 37.3668],
            [-80.262, 37.3407],
            [-80.1912, 37.2329],
            [-80.178, 37.1134],
            [-80.3474, 37.0218],
            [-80.5439, 36.9846],
            [-80.5801, 37.0896],
            [-80.5297, 37.1341],
            [-80.6069, 37.2449],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102520',
        NAME: 'Montgomery County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1001065265,
        AWATER: 5807436,
        INTPTLAT: '+37.1755344',
        INTPTLON: '-080.3877913',
        ELSDLEA: '',
        UNSDLEA: '02520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1574, 37.891],
            [-79.0051, 37.8817],
            [-78.9023, 37.9514],
            [-78.8392, 38.0476],
            [-78.644, 37.7331],
            [-78.8252, 37.5525],
            [-78.8692, 37.5421],
            [-78.9669, 37.677],
            [-79.0674, 37.7203],
            [-79.0744, 37.7812],
            [-79.1719, 37.8031],
            [-79.1574, 37.891],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102580',
        NAME: 'Nelson County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1219509578,
        AWATER: 8935054,
        INTPTLAT: '+37.7890795',
        INTPTLON: '-078.8834397',
        ELSDLEA: '',
        UNSDLEA: '02580',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1237, 37.6269],
            [-77.0397, 37.5873],
            [-76.829, 37.5465],
            [-76.7922, 37.5176],
            [-76.742, 37.4671],
            [-76.8948, 37.4276],
            [-76.9045, 37.3777],
            [-77.0958, 37.4403],
            [-77.1773, 37.4906],
            [-77.2285, 37.5364],
            [-77.1237, 37.6269],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102610',
        NAME: 'New Kent County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 543211673,
        AWATER: 35577224,
        INTPTLAT: '+37.5028186',
        INTPTLON: '-076.9828189',
        ELSDLEA: '',
        UNSDLEA: '02610',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6416, 37.1538],
            [-76.5914, 37.2147],
            [-76.4355, 37.0949],
            [-76.4489, 37.0372],
            [-76.3844, 36.9511],
            [-76.3996, 36.9309],
            [-76.4453, 36.9419],
            [-76.6282, 37.0928],
            [-76.6416, 37.1538],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102640',
        NAME: 'Newport News City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 178332756,
        AWATER: 131488033,
        INTPTLAT: '+37.0761659',
        INTPTLON: '-076.5219682',
        ELSDLEA: '',
        UNSDLEA: '02640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3418, 36.9248],
            [-76.3323, 36.9661],
            [-76.1339, 37.0409],
            [-76.2261, 36.8399],
            [-76.29, 36.822],
            [-76.3418, 36.9248],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102670',
        NAME: 'Norfolk City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 140080307,
        AWATER: 109452507,
        INTPTLAT: '+36.9230149',
        INTPTLON: '-076.2446413',
        ELSDLEA: '',
        UNSDLEA: '02670',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1408, 37.5422],
            [-75.8352, 37.5542],
            [-75.787, 37.4646],
            [-75.5974, 37.451],
            [-75.6444, 37.3423],
            [-75.7155, 37.2757],
            [-75.7446, 37.1823],
            [-75.8897, 37.0545],
            [-75.9095, 36.9907],
            [-76.1339, 37.0409],
            [-76.1349, 37.1176],
            [-76.1355, 37.192],
            [-76.1363, 37.2821],
            [-76.1408, 37.5422],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102710',
        NAME: 'Northampton County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 548279228,
        AWATER: 1511404265,
        INTPTLAT: '+37.3027754',
        INTPTLON: '-075.9240177',
        ELSDLEA: '',
        UNSDLEA: '02710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5164, 38.0347],
            [-76.4129, 37.9663],
            [-76.2361, 37.8885],
            [-76.2365, 37.8866],
            [-76.1757, 37.6716],
            [-76.3084, 37.6698],
            [-76.376, 37.7133],
            [-76.4201, 37.8238],
            [-76.5078, 37.8388],
            [-76.556, 37.9315],
            [-76.6342, 37.9666],
            [-76.5164, 38.0347],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102730',
        NAME: 'Northumberland County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 495425667,
        AWATER: 244310208,
        INTPTLAT: '+37.8569736',
        INTPTLON: '-076.3796872',
        ELSDLEA: '',
        UNSDLEA: '02730',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.6629, 36.9212],
            [-82.6164, 36.9567],
            [-82.5958, 36.9359],
            [-82.6222, 36.9278],
            [-82.6373, 36.9066],
            [-82.6629, 36.9212],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102760',
        NAME: 'Norton City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 19375326,
        AWATER: 89169,
        INTPTLAT: '+36.9315489',
        INTPTLON: '-082.6259960',
        ELSDLEA: '',
        UNSDLEA: '02760',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2394, 37.1201],
            [-78.2312, 37.2962],
            [-77.7959, 37.1926],
            [-77.9003, 37.1439],
            [-77.8898, 36.9888],
            [-78.0036, 37.0228],
            [-78.1709, 37.0539],
            [-78.2394, 37.1201],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102790',
        NAME: 'Nottoway County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 814265581,
        AWATER: 4297829,
        INTPTLAT: '+37.1411668',
        INTPTLON: '-078.0538655',
        ELSDLEA: '',
        UNSDLEA: '02790',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.287, 38.2715],
            [-78.2206, 38.2303],
            [-78.0946, 38.3114],
            [-77.9086, 38.3818],
            [-77.7032, 38.3606],
            [-77.9548, 38.1174],
            [-78.1018, 38.1525],
            [-78.2081, 38.1332],
            [-78.3698, 38.1848],
            [-78.287, 38.2715],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102820',
        NAME: 'Orange County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 882616763,
        AWATER: 6431171,
        INTPTLAT: '+38.2501930',
        INTPTLON: '-078.0096224',
        ELSDLEA: '',
        UNSDLEA: '02820',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6418, 38.6047],
            [-78.5488, 38.7387],
            [-78.5093, 38.7256],
            [-78.3867, 38.8302],
            [-78.2849, 38.7592],
            [-78.3383, 38.6273],
            [-78.4529, 38.4755],
            [-78.486, 38.4215],
            [-78.6913, 38.51],
            [-78.6418, 38.6047],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102850',
        NAME: 'Page County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 805020081,
        AWATER: 8431094,
        INTPTLAT: '+38.6232005',
        INTPTLON: '-078.4918796',
        ELSDLEA: '',
        UNSDLEA: '02850',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.4642, 36.7087],
            [-80.3854, 36.7987],
            [-80.2355, 36.8721],
            [-80.0664, 36.8411],
            [-80.0415, 36.7944],
            [-80.0944, 36.7796],
            [-80.0535, 36.5425],
            [-80.4401, 36.5506],
            [-80.571, 36.5562],
            [-80.612, 36.5578],
            [-80.4642, 36.7087],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102880',
        NAME: 'Patrick County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1251234938,
        AWATER: 7142556,
        INTPTLAT: '+36.6671277',
        INTPTLON: '-080.2864131',
        ELSDLEA: '',
        UNSDLEA: '02880',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4475, 37.2227],
            [-77.4165, 37.2332],
            [-77.4037, 37.2373],
            [-77.3839, 37.2389],
            [-77.3767, 37.245],
            [-77.3992, 37.1709],
            [-77.4475, 37.2227],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102910',
        NAME: 'Petersburg City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 59430127,
        AWATER: 614559,
        INTPTLAT: '+37.2047302',
        INTPTLON: '-077.3923681',
        ELSDLEA: '',
        UNSDLEA: '02910',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.6407, 36.8561],
            [-79.5941, 37.0422],
            [-79.4952, 37.022],
            [-79.4429, 37.0559],
            [-79.3322, 37.1256],
            [-79.0923, 37.0615],
            [-79.2186, 36.5416],
            [-79.3427, 36.5414],
            [-79.3311, 36.6018],
            [-79.403, 36.6415],
            [-79.4702, 36.5408],
            [-79.511, 36.5408],
            [-79.7149, 36.5419],
            [-79.6407, 36.8561],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102940',
        NAME: 'Pittsylvania County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2509525953,
        AWATER: 23857387,
        INTPTLAT: '+36.8217206',
        INTPTLON: '-079.3985019',
        ELSDLEA: '',
        UNSDLEA: '02940',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.1355, 37.192],
            [-76.1349, 37.1176],
            [-76.3958, 37.1072],
            [-76.3304, 37.191],
            [-76.1355, 37.192],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5102980',
        NAME: 'Poquoson City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 39850781,
        AWATER: 163450029,
        INTPTLAT: '+37.1283599',
        INTPTLON: '-076.3035337',
        ELSDLEA: '',
        UNSDLEA: '02980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.3996, 36.9309],
            [-76.3844, 36.9511],
            [-76.3418, 36.9248],
            [-76.29, 36.822],
            [-76.3757, 36.7861],
            [-76.4204, 36.8663],
            [-76.3996, 36.9309],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103000',
        NAME: 'Portsmouth City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 87547975,
        AWATER: 33488196,
        INTPTLAT: '+36.8596996',
        INTPTLON: '-076.3563133',
        ELSDLEA: '',
        UNSDLEA: '03000',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.0723, 37.6574],
            [-77.9834, 37.6247],
            [-77.9231, 37.6914],
            [-77.8144, 37.6064],
            [-77.654, 37.5626],
            [-77.8551, 37.4184],
            [-77.8998, 37.4754],
            [-77.9862, 37.4863],
            [-78.132, 37.4547],
            [-78.0723, 37.6574],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103030',
        NAME: 'Powhatan County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 674021190,
        AWATER: 5453788,
        INTPTLAT: '+37.5494041',
        INTPTLON: '-077.9128553',
        ELSDLEA: '',
        UNSDLEA: '03030',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.6815, 37.2497],
            [-78.5944, 37.398],
            [-78.4651, 37.3397],
            [-78.3218, 37.3093],
            [-78.235, 37.3681],
            [-78.2312, 37.2962],
            [-78.2394, 37.1201],
            [-78.4436, 37.0794],
            [-78.5471, 37.1504],
            [-78.6925, 37.2048],
            [-78.6815, 37.2497],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103060',
        NAME: 'Prince Edward County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 906385569,
        AWATER: 10040117,
        INTPTLAT: '+37.2248813',
        INTPTLON: '-078.4329574',
        ELSDLEA: '',
        UNSDLEA: '03060',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3992, 37.1709],
            [-77.3767, 37.245],
            [-77.3345, 37.3125],
            [-77.3224, 37.2654],
            [-77.2532, 37.2957],
            [-77.284, 37.313],
            [-77.2707, 37.3208],
            [-77.1251, 37.3029],
            [-76.9724, 37.2485],
            [-77.1554, 37.112],
            [-77.3985, 36.993],
            [-77.3992, 37.1709],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103090',
        NAME: 'Prince George County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 686758306,
        AWATER: 43184668,
        INTPTLAT: '+37.1873255',
        INTPTLON: '-077.2209931',
        ELSDLEA: '',
        UNSDLEA: '03090',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.6551, 38.9426],
            [-77.5358, 38.8475],
            [-77.2213, 38.6504],
            [-77.2255, 38.6056],
            [-77.2827, 38.5277],
            [-77.3706, 38.5206],
            [-77.3716, 38.5215],
            [-77.4401, 38.6318],
            [-77.5619, 38.601],
            [-77.7154, 38.8322],
            [-77.6551, 38.9426],
          ],
          [
            [-77.4593, 38.7726],
            [-77.4275, 38.7534],
            [-77.4207, 38.7622],
            [-77.4757, 38.7812],
            [-77.5015, 38.7384],
            [-77.4516, 38.7383],
            [-77.4593, 38.7726],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103130',
        NAME: 'Prince William County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 779205164,
        AWATER: 29473594,
        INTPTLAT: '+38.7126603',
        INTPTLON: '-077.4827107',
        ELSDLEA: '',
        UNSDLEA: '03130',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.8546, 37.1475],
            [-80.6069, 37.2449],
            [-80.5297, 37.1341],
            [-80.5964, 37.1189],
            [-80.5801, 37.0896],
            [-80.5439, 36.9846],
            [-80.634, 36.9318],
            [-80.7405, 36.879],
            [-80.8933, 37.0274],
            [-80.9127, 37.0733],
            [-80.8546, 37.1475],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103150',
        NAME: 'Pulaski County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 828438965,
        AWATER: 25204366,
        INTPTLAT: '+37.0633853',
        INTPTLON: '-080.7134439',
        ELSDLEA: '',
        UNSDLEA: '03150',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5297, 37.1341],
            [-80.5801, 37.0896],
            [-80.5964, 37.1189],
            [-80.5297, 37.1341],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103180',
        NAME: 'Radford City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 25567009,
        AWATER: 867992,
        INTPTLAT: '+37.1201161',
        INTPTLON: '-080.5591538',
        ELSDLEA: '',
        UNSDLEA: '03180',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.3383, 38.6273],
            [-78.2849, 38.7592],
            [-78.1956, 38.7806],
            [-78.1306, 38.8649],
            [-77.9355, 38.6959],
            [-78.1688, 38.5238],
            [-78.232, 38.5326],
            [-78.3383, 38.6273],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103210',
        NAME: 'Rappahannock County Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 689883410,
        AWATER: 2064342,
        INTPTLAT: '+38.6845215',
        INTPTLON: '-078.1688241',
        ELSDLEA: '',
        UNSDLEA: '03210',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5961, 37.5557],
            [-77.4768, 37.5985],
            [-77.4132, 37.5799],
            [-77.4208, 37.4471],
            [-77.5124, 37.4729],
            [-77.5961, 37.5557],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103240',
        NAME: 'Richmond City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 154942137,
        AWATER: 6879776,
        INTPTLAT: '+37.5313990',
        INTPTLON: '-077.4760085',
        ELSDLEA: '',
        UNSDLEA: '03240',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6342, 37.9666],
            [-76.556, 37.9315],
            [-76.5078, 37.8388],
            [-76.6682, 37.7784],
            [-76.9005, 37.9821],
            [-76.937, 38.0771],
            [-76.8771, 38.1244],
            [-76.7489, 37.9949],
            [-76.6342, 37.9666],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103270',
        NAME: 'Richmond County Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 495962797,
        AWATER: 64400138,
        INTPTLAT: '+37.9428935',
        INTPTLON: '-076.7305609',
        ELSDLEA: '',
        UNSDLEA: '03270',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0335, 37.2629],
            [-80.0222, 37.3083],
            [-79.9055, 37.3218],
            [-79.9581, 37.2128],
            [-80.0335, 37.2629],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103300',
        NAME: 'Roanoke City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 110141396,
        AWATER: 882886,
        INTPTLAT: '+37.2784580',
        INTPTLON: '-079.9581739',
        ELSDLEA: '',
        UNSDLEA: '03300',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.074, 37.4223],
            [-79.8476, 37.3092],
            [-79.8471, 37.2255],
            [-79.9609, 37.138],
            [-80.0084, 37.1726],
            [-80.1281, 37.1229],
            [-80.178, 37.1134],
            [-80.1912, 37.2329],
            [-80.262, 37.3407],
            [-80.074, 37.4223],
          ],
          [
            [-80.0335, 37.2629],
            [-79.9581, 37.2128],
            [-79.9055, 37.3218],
            [-80.0222, 37.3083],
            [-80.0842, 37.2975],
            [-80.0744, 37.2579],
            [-80.0335, 37.2629],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103330',
        NAME: 'Roanoke County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 649014935,
        AWATER: 1795151,
        INTPTLAT: '+37.3307920',
        INTPTLON: '-080.1912371',
        ELSDLEA: '',
        UNSDLEA: '03330',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.674, 37.7631],
            [-79.6508, 37.8716],
            [-79.4824, 38.0861],
            [-79.1574, 37.891],
            [-79.1719, 37.8031],
            [-79.2723, 37.7855],
            [-79.345, 37.6611],
            [-79.4369, 37.617],
            [-79.4986, 37.5329],
            [-79.6836, 37.6617],
            [-79.674, 37.7631],
          ],
          [
            [-79.4619, 37.7862],
            [-79.4518, 37.7665],
            [-79.4208, 37.789],
            [-79.4375, 37.7951],
            [-79.4619, 37.7862],
          ],
          [
            [-79.3826, 37.7063],
            [-79.3599, 37.7024],
            [-79.3333, 37.7379],
            [-79.3748, 37.7393],
            [-79.3826, 37.7063],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103370',
        NAME: 'Rockbridge County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1547664064,
        AWATER: 8845046,
        INTPTLAT: '+37.8145174',
        INTPTLON: '-079.4477537',
        ELSDLEA: '',
        UNSDLEA: '03370',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.2243, 38.4778],
            [-79.0573, 38.7614],
            [-78.9938, 38.85],
            [-78.8693, 38.763],
            [-78.6418, 38.6047],
            [-78.6913, 38.51],
            [-78.486, 38.4215],
            [-78.5515, 38.3363],
            [-78.6632, 38.2779],
            [-78.7494, 38.2066],
            [-79.2243, 38.4778],
          ],
          [
            [-78.9179, 38.3942],
            [-78.8243, 38.4351],
            [-78.9006, 38.4592],
            [-78.9179, 38.3942],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103390',
        NAME: 'Rockingham County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 2199284791,
        AWATER: 11120615,
        INTPTLAT: '+38.5075848',
        INTPTLON: '-078.8853213',
        ELSDLEA: '',
        UNSDLEA: '03390',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.4066, 36.8764],
            [-82.2986, 36.9054],
            [-82.328, 36.9709],
            [-82.1487, 37.0417],
            [-82.0757, 37.0382],
            [-82.0118, 37.1124],
            [-81.9011, 37.1425],
            [-81.7805, 36.9586],
            [-81.8368, 36.929],
            [-81.9467, 36.8649],
            [-82.0779, 36.855],
            [-82.1076, 36.7964],
            [-82.3321, 36.7094],
            [-82.4066, 36.8764],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103420',
        NAME: 'Russell County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1227192283,
        AWATER: 7593493,
        INTPTLAT: '+36.9334198',
        INTPTLON: '-082.0959342',
        ELSDLEA: '',
        UNSDLEA: '03420',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.0222, 37.3083],
            [-80.0335, 37.2629],
            [-80.0744, 37.2579],
            [-80.0842, 37.2975],
            [-80.0222, 37.3083],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103460',
        NAME: 'Salem City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 37400253,
        AWATER: 263080,
        INTPTLAT: '+37.2853329',
        INTPTLON: '-080.0552407',
        ELSDLEA: '',
        UNSDLEA: '03460',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.7713, 36.7999],
            [-82.6346, 36.8785],
            [-82.4066, 36.8764],
            [-82.3321, 36.7094],
            [-82.2941, 36.5957],
            [-82.5711, 36.5946],
            [-82.6092, 36.5941],
            [-82.8304, 36.5938],
            [-82.9851, 36.5937],
            [-82.9475, 36.6758],
            [-82.8232, 36.7333],
            [-82.7713, 36.7999],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103480',
        NAME: 'Scott County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1387008821,
        AWATER: 8060238,
        INTPTLAT: '+36.7127756',
        INTPTLON: '-082.6136238',
        ELSDLEA: '',
        UNSDLEA: '03480',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.5441, 39.0567],
            [-78.4633, 39.0308],
            [-78.408, 39.0919],
            [-78.3142, 39.008],
            [-78.3005, 38.9436],
            [-78.3867, 38.8302],
            [-78.5093, 38.7256],
            [-78.5488, 38.7387],
            [-78.6418, 38.6047],
            [-78.8693, 38.763],
            [-78.788, 38.8851],
            [-78.6805, 38.9253],
            [-78.5441, 39.0567],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103510',
        NAME: 'Shenandoah County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1316396213,
        AWATER: 8870600,
        INTPTLAT: '+38.8562040',
        INTPTLON: '-078.5739867',
        ELSDLEA: '',
        UNSDLEA: '03510',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.7805, 36.9586],
            [-81.683, 36.933],
            [-81.5769, 36.9895],
            [-81.4372, 37.0101],
            [-81.3794, 36.9524],
            [-81.3267, 36.8995],
            [-81.2616, 36.7631],
            [-81.5251, 36.7078],
            [-81.6058, 36.6387],
            [-81.6994, 36.7896],
            [-81.8368, 36.929],
            [-81.7805, 36.9586],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103520',
        NAME: 'Smyth County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1167904202,
        AWATER: 3519357,
        INTPTLAT: '+36.8423183',
        INTPTLON: '-081.5397861',
        ELSDLEA: '',
        UNSDLEA: '03520',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.4301, 36.7077],
            [-76.9535, 36.9444],
            [-76.8497, 36.9962],
            [-76.8149, 36.9005],
            [-76.9246, 36.7077],
            [-76.9554, 36.6646],
            [-76.9097, 36.6482],
            [-76.8976, 36.644],
            [-76.9159, 36.5521],
            [-76.916, 36.5438],
            [-77.1645, 36.5463],
            [-77.2969, 36.5448],
            [-77.3609, 36.5648],
            [-77.4301, 36.7077],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103600',
        NAME: 'Southampton County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1551739851,
        AWATER: 8242331,
        INTPTLAT: '+36.7201725',
        INTPTLON: '-077.1038556',
        ELSDLEA: '',
        UNSDLEA: '03600',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.9548, 38.1174],
            [-77.7032, 38.3606],
            [-77.6187, 38.3678],
            [-77.5303, 38.3092],
            [-77.4471, 38.2846],
            [-77.3703, 38.2466],
            [-77.6422, 37.9909],
            [-77.6873, 38.008],
            [-77.8397, 38.106],
            [-77.9548, 38.1174],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103640',
        NAME: 'Spotsylvania County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1039865449,
        AWATER: 33044472,
        INTPTLAT: '+38.1824314',
        INTPTLON: '-077.6572264',
        ELSDLEA: '',
        UNSDLEA: '03640',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.5609, 38.5271],
            [-77.48, 38.483],
            [-77.3716, 38.5215],
            [-77.3706, 38.5206],
            [-77.3153, 38.4788],
            [-77.2862, 38.347],
            [-77.3267, 38.2451],
            [-77.3703, 38.2466],
            [-77.4471, 38.2846],
            [-77.4671, 38.3187],
            [-77.5303, 38.3092],
            [-77.6187, 38.3678],
            [-77.6348, 38.4097],
            [-77.5609, 38.5271],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103660',
        NAME: 'Stafford County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 567201717,
        AWATER: 24627171,
        INTPTLAT: '+38.4050233',
        INTPTLON: '-077.4669469',
        ELSDLEA: '',
        UNSDLEA: '03660',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.1135, 38.1531],
            [-79.0974, 38.1845],
            [-79.0113, 38.1815],
            [-79.0498, 38.1211],
            [-79.1135, 38.1531],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103690',
        NAME: 'Staunton City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 51705587,
        AWATER: 153023,
        INTPTLAT: '+38.1580557',
        INTPTLON: '-079.0615010',
        ELSDLEA: '',
        UNSDLEA: '03690',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.8976, 36.644],
            [-76.5335, 36.9156],
            [-76.4453, 36.9419],
            [-76.3996, 36.9309],
            [-76.4204, 36.8663],
            [-76.4575, 36.8148],
            [-76.4915, 36.5506],
            [-76.5416, 36.5505],
            [-76.9159, 36.5521],
            [-76.8976, 36.644],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103710',
        NAME: 'Suffolk City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1036489945,
        AWATER: 74848803,
        INTPTLAT: '+36.6971573',
        INTPTLON: '-076.6347808',
        ELSDLEA: '',
        UNSDLEA: '03710',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9724, 37.2485],
            [-76.8832, 37.2231],
            [-76.6416, 37.1538],
            [-76.7118, 37.0554],
            [-76.8497, 36.9962],
            [-76.9535, 36.9444],
            [-76.9866, 37.0497],
            [-77.1554, 37.112],
            [-76.9724, 37.2485],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103750',
        NAME: 'Surry County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 722475755,
        AWATER: 81216907,
        INTPTLAT: '+37.1197611',
        INTPTLON: '-076.8801717',
        ELSDLEA: '',
        UNSDLEA: '03750',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.619, 36.8778],
            [-77.3985, 36.993],
            [-77.1554, 37.112],
            [-76.9866, 37.0497],
            [-76.9535, 36.9444],
            [-77.4301, 36.7077],
            [-77.49, 36.721],
            [-77.5104, 36.8451],
            [-77.619, 36.8778],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103780',
        NAME: 'Sussex County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1269664385,
        AWATER: 6755317,
        INTPTLAT: '+36.9266454',
        INTPTLON: '-077.2597319',
        ELSDLEA: '',
        UNSDLEA: '03780',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.9011, 37.1425],
            [-81.7401, 37.2378],
            [-81.6782, 37.2015],
            [-81.5606, 37.2067],
            [-81.3622, 37.3377],
            [-81.2251, 37.2349],
            [-81.2848, 37.2159],
            [-81.2614, 37.1148],
            [-81.4372, 37.0101],
            [-81.5769, 36.9895],
            [-81.683, 36.933],
            [-81.7805, 36.9586],
            [-81.9011, 37.1425],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103810',
        NAME: 'Tazewell County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1343806837,
        AWATER: 2913086,
        INTPTLAT: '+37.1253949',
        INTPTLON: '-081.5629237',
        ELSDLEA: '',
        UNSDLEA: '03810',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.2261, 36.8399],
            [-76.1339, 37.0409],
            [-75.9095, 36.9907],
            [-75.9268, 36.9365],
            [-75.8746, 36.7443],
            [-75.7975, 36.5509],
            [-76.1224, 36.5506],
            [-76.122, 36.6657],
            [-76.2261, 36.8399],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103840',
        NAME: 'Virginia Beach City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 646847466,
        AWATER: 641272088,
        INTPTLAT: '+36.7793496',
        INTPTLON: '-076.0236849',
        ELSDLEA: '',
        UNSDLEA: '03840',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.3142, 39.008],
            [-78.1518, 39.0366],
            [-78.0053, 38.9803],
            [-78.1306, 38.8649],
            [-78.1956, 38.7806],
            [-78.2849, 38.7592],
            [-78.3867, 38.8302],
            [-78.3005, 38.9436],
            [-78.3142, 39.008],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103870',
        NAME: 'Warren County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 553639842,
        AWATER: 8554784,
        INTPTLAT: '+38.9082214',
        INTPTLON: '-078.2075940',
        ELSDLEA: '',
        UNSDLEA: '03870',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.3321, 36.7094],
            [-82.1076, 36.7964],
            [-82.0779, 36.855],
            [-81.9467, 36.8649],
            [-81.8368, 36.929],
            [-81.6994, 36.7896],
            [-81.6058, 36.6387],
            [-81.6469, 36.6119],
            [-81.8267, 36.6142],
            [-82.096, 36.5947],
            [-82.1461, 36.5947],
            [-82.1219, 36.6461],
            [-82.2434, 36.5957],
            [-82.2636, 36.5956],
            [-82.2941, 36.5957],
            [-82.3321, 36.7094],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103900',
        NAME: 'Washington County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1452917761,
        AWATER: 12999433,
        INTPTLAT: '+36.7478314',
        INTPTLON: '-081.9503155',
        ELSDLEA: '',
        UNSDLEA: '03900',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.9507, 38.0645],
            [-78.9035, 38.1],
            [-78.8557, 38.0746],
            [-78.8722, 38.0396],
            [-78.9507, 38.0645],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103930',
        NAME: 'Waynesboro City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 38927994,
        AWATER: 410156,
        INTPTLAT: '+38.0671571',
        INTPTLON: '-078.9014197',
        ELSDLEA: '',
        UNSDLEA: '03930',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7995, 37.5747],
            [-76.7922, 37.5176],
            [-76.829, 37.5465],
            [-76.7995, 37.5747],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103950',
        NAME: 'West Point Town Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 13080624,
        AWATER: 4171187,
        INTPTLAT: '+37.5521908',
        INTPTLON: '-076.8018205',
        ELSDLEA: '',
        UNSDLEA: '03950',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.9977, 38.278],
            [-76.9909, 38.2736],
            [-76.9623, 38.2299],
            [-76.8689, 38.1714],
            [-76.6104, 38.1485],
            [-76.5164, 38.0347],
            [-76.6342, 37.9666],
            [-76.7489, 37.9949],
            [-76.8771, 38.1244],
            [-76.937, 38.0771],
            [-77.0281, 38.0908],
            [-77.0617, 38.1616],
            [-76.9977, 38.278],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5103980',
        NAME: 'Westmoreland County Public Schools',
        LSAD: '00',
        LOGRADE: 'KG',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 590008089,
        AWATER: 60328788,
        INTPTLAT: '+38.1093110',
        INTPTLON: '-076.8039326',
        ELSDLEA: '',
        UNSDLEA: '03980',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7276, 37.3049],
            [-76.6795, 37.2657],
            [-76.7399, 37.2739],
            [-76.7276, 37.3049],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5104020',
        NAME: 'Williamsburg City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23098592,
        AWATER: 416082,
        INTPTLAT: '+37.2694810',
        INTPTLON: '-076.7081925',
        ELSDLEA: '',
        UNSDLEA: '04020',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2007, 39.1802],
            [-78.1878, 39.2047],
            [-78.137, 39.1845],
            [-78.1709, 39.1438],
            [-78.2007, 39.1802],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5104050',
        NAME: 'Winchester City Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 23918428,
        AWATER: 53195,
        INTPTLAT: '+39.1738688',
        INTPTLON: '-078.1763562',
        ELSDLEA: '',
        UNSDLEA: '04050',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.8692, 36.9742],
            [-82.7428, 37.0428],
            [-82.6535, 37.151],
            [-82.5654, 37.1961],
            [-82.5538, 37.2029],
            [-82.5439, 37.125],
            [-82.4674, 37.0221],
            [-82.328, 36.9709],
            [-82.2986, 36.9054],
            [-82.4066, 36.8764],
            [-82.6346, 36.8785],
            [-82.7713, 36.7999],
            [-82.8785, 36.8933],
            [-82.8692, 36.9742],
          ],
          [
            [-82.6629, 36.9212],
            [-82.6373, 36.9066],
            [-82.6222, 36.9278],
            [-82.5958, 36.9359],
            [-82.6164, 36.9567],
            [-82.6629, 36.9212],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5104080',
        NAME: 'Wise County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1044260774,
        AWATER: 5277225,
        INTPTLAT: '+36.9745607',
        INTPTLON: '-082.6215601',
        ELSDLEA: '',
        UNSDLEA: '04080',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.9127, 37.0733],
            [-80.8933, 37.0274],
            [-80.7405, 36.879],
            [-81.0424, 36.8064],
            [-81.0974, 36.7689],
            [-81.2616, 36.7631],
            [-81.3267, 36.8995],
            [-81.3794, 36.9524],
            [-81.2246, 37.0478],
            [-81.1143, 37.0226],
            [-80.9127, 37.0733],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5104110',
        NAME: 'Wythe County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 1196123390,
        AWATER: 7310964,
        INTPTLAT: '+36.8998725',
        INTPTLON: '-081.0830053',
        ELSDLEA: '',
        UNSDLEA: '04110',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.6587, 37.3801],
            [-76.5161, 37.2488],
            [-76.4469, 37.2346],
            [-76.3045, 37.2784],
            [-76.1363, 37.2821],
            [-76.1355, 37.192],
            [-76.3304, 37.191],
            [-76.3958, 37.1072],
            [-76.4355, 37.0949],
            [-76.5914, 37.2147],
            [-76.6795, 37.2657],
            [-76.7276, 37.3049],
            [-76.6587, 37.3801],
          ],
        ],
      },
      properties: {
        STATEFP: '51',
        SCSDLEA: '',
        GEOID: '5104150',
        NAME: 'York County Public Schools',
        LSAD: '00',
        LOGRADE: 'PK',
        HIGRADE: '12',
        MTFCC: 'G5420',
        SDTYP: '',
        FUNCSTAT: 'E',
        ALAND: 271061637,
        AWATER: 285378318,
        INTPTLAT: '+37.1980627',
        INTPTLON: '-076.3938530',
        ELSDLEA: '',
        UNSDLEA: '04150',
      },
    },
  ],
};
